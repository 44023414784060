import { AuthState } from '@trent/store/auth-store/auth.state';
import { HttpHeaders } from '@angular/common/http';
import { Store } from '@ngxs/store';
import { environment } from '@trent/core/environments/environment';
import { FunctionName } from '@trent/core/config';
import { logger } from '@trentm/log/logger';

export class BaseHttpService {
  /** api name , eqals to firebase function name */
  apiName: FunctionName;

  token: string;

  /** Domain Url for firebase api services. */
  private get apiUrl() {
    // Function name has to be appened in the url ir emulator or production is running
    if (environment.emulator || environment.production) {
      if (this.apiName == null || this.apiName.trim().length === 0) {
        throw Error('Invalid API name. The name can not be emepty!');
      }
      return `${environment.functionUrl}/${this.apiName}`;
    }
    // it is dev tscriptD script running.
    return environment.functionUrl;
    // environment.production ?
    //    `${firebaseFuncUrl.production}/${this.apiName}` :
    //    firebaseFuncUrl.devleopment;
  }

  /**
   * @param segment : trailing segment of api url. (must start with '/')
   */
  public getApiUrl(segment: string) {
    if (!segment || segment[0] !== '/') {
      throw new Error(`Invalid get api url was called. segement must start with '/'. Provided: ${segment}`);
    }
    logger.log('[Base-Http] Function URL is: ' + `${this.apiUrl}${segment}`);
    return `${this.apiUrl}${segment}`;
  }

  constructor(protected store: Store) {
    this.store.select(AuthState.selectToken).subscribe(t => (this.token = t));
  }

  /** Authentication tooken to Firebase Functions */
  addBearerToken(hdr: HttpHeaders = null, token?: string) {
    if (!(hdr instanceof HttpHeaders) || hdr == null) {
      // logger.log('header created');
      hdr = new HttpHeaders();
      logger.log(hdr);
    }

    if (environment.emulator) {
      // hdr.set('mockUser', )
      const t = !!token ? token : this.token;
      hdr = hdr.set('Authorization', `Bearer ${t}`);
    } else {
      const t = !!token ? token : this.token;
      hdr = hdr.set('Authorization', `Bearer ${t}`);
    }
    hdr.set('Accept', 'application/json');
    hdr.set('Content-Type', 'application/json');
    // hdr.set('Content-Type', 'application/json');

    // Adding cors policy
    hdr.set('Access-Control-Allow-Origin', '*');

    // logger.log('after adding bearer', hdr);
    return hdr;
  }
}
