import 'reflect-metadata';
import { Exclude, Expose, instanceToInstance, plainToInstance, Type, instanceToPlain } from 'class-transformer';
import { TripType } from './trip-type';
import { IsEnum, validateSync, IsInt, IsNumber, IsBoolean, ValidatorOptions, ValidateNested, ValidateIf, IsDefined } from 'class-validator';
import { Term } from '../rental/term';
import { BaseModel } from '../base/base-model';
import { Address, provinceCodes } from '../address/address';
import { IValidationMsg, IValDbStatusResult, mergeValidation } from '../error-handling/validation-info';
import { isBefore, addHours, differenceInMinutes, addMinutes } from 'date-fns';
import { sanitizeDate, sanitizeDateIPoint, alphaChar } from '../utility';
import { TripActivityType } from './trip-activity-type';
import { TripTransaction } from './trip-transaction';
import { findDistance } from '../utility/distf';
import { ProductType } from '../product/product-type';
import { CompanyFleet } from '../company/company-fleet';
import { DbStatus } from '../base';
import { MapProvider } from '../map/map-provider';
import { DistanceQuery, DistanceCalculator } from '../map/distance-query';
import { MapLabel } from '@trent/models/map/map-label';
import { Delivery } from './delivery';
import { omitBy, isEqual, difference, isEmpty, pickBy, intersection, isDate } from 'lodash';
import { isEqual as isEqualD } from 'date-fns';
import { parseTripTransactionArray, cloneTripTransactionArray, parseTripTransaction } from './trip-helper';
import { Pickup } from './pickup';
import { cleanupUndefined, firestoreMap, toSentence } from '../utility/helper';
import { TripStatus } from './trip-status';
import { TransactionStatus } from './transaction-status';

import { EnumHelper } from '../utility/enum-helper';
import { BorderListItem, BorderList } from '../promo/border-list';
import { DriverList } from '../promo/driver-list';
import { VehicleList } from '../promo/promo-vehicle';
import { Hook } from './hook';
import { RouteResponse } from '../map/imap';
import { HOS } from './hos';
import { IPoint } from '../product-search/interfaces';
import { CompanyCarrier, CarrierCompanySummary } from '../company/company-carrier';
import { IDefaultCompanyData } from '../user/company-user-profile';
import { ItemStatus, PromoListType } from '../promo';
import { ITripGpsStatus } from './trip-gps-status';
import { LocationData } from '../location/loc-data';
import { Drop } from './drop';
import { logger } from '../log/logger';

export type TripValidationGroup = 'schedule' | 'advertise';
const schedule: TripValidationGroup = 'schedule';

export interface TripSummary {
  tid: string | number;
  rRevid: number;
  term: Term;
  rent: number;
  startAddress: Address;
  returnDestination: Address;
  isOneWay: boolean;
  startDate: Date;
  endDate: Date;
}

/** parses trip and array of trip transactions */
export const parseTrip = <T extends TripBase>(obj, clazz: new (...args: any[]) => T): T => {
  try {
    if (obj == null) { return null; }
    // obj = sanitizeDateIPoint(obj, ipointType);
    obj = sanitizeDateIPoint(obj);
    const tripTransactions = parseTripTransactionArray((<TripBase>obj).tripSequence);
    const m = plainToInstance<T, object>(clazz, obj as object);
    m.sanitize();
    m.tripSequence = tripTransactions;
    if (!!(obj as TripBase).gpsStatus) {
      m.gpsStatus = { ...(obj as TripBase).gpsStatus };
    }
    return m;
  } catch (error) {
    logger.log('Error happened during parse', error);
    return null;
  }
}


@Exclude()
export class TripBase extends BaseModel {
  public static readonly collectionName = 'trip';
  thumbNailImg: string;

  /** Property to track the current status of the GPS locations. Note: not stored in the db. Only used on the component pages */
  public gpsStatus: ITripGpsStatus;

  @Expose()
  @IsEnum(ProductType)
  productType = ProductType.trip;

  /** carrier company summary */
  @Expose()
  carrierCompanySummary: CarrierCompanySummary;  // VendorCompanySummary | 

  @Expose()
  @IsEnum(TripType, { message: 'Trip Type is required', groups: [schedule] })
  tripType = TripType.truckTrailer;

  @Expose()
  @IsEnum(Term, { message: 'Select Rental Term Option', groups: ['advertise'] })
  term: Term;

  @Expose()
  @IsNumber(undefined, { message: 'Enter valid rate', groups: ['advertise'] })
  rate: number;

  @Expose()
  @IsBoolean({ message: 'Is Waiting Paid', groups: ['advertise'] })
  isWaitingPaid = false;

  @ValidateIf(o => !!o.isWaitingPaid)
  @Expose()
  @IsInt({ message: 'Select Duration after which waiting will be paid', groups: ['advertise'] })
  waitingDuration: number;

  @ValidateIf(o => !!o.isWaitingPaid)
  @Expose()
  @IsNumber(undefined, { message: 'Enter waiting rate', groups: ['advertise'] })
  waitingRate: number;

  @Expose()
  @IsNumber(undefined, { message: 'Enter required driver experience', groups: ['advertise'] })
  minDriverExp: number;
  /** trip sequence of activities(hookup, pickUp, deliver, drop) */
  @Expose()
  @ValidateNested({ message: 'Upload Carrier Package', groups: [schedule] })
  @Type(() => TripTransaction)
  tripSequence: TripTransaction[];

  @Expose()
  @IsBoolean({ message: 'One Way/Return needs is required', groups: [schedule] })
  isOneWay = true;

  /** Driver Id or phone number. Id when driver exists as user. Driver phone util user does not exists.
   * Trip status remains created until driver signs as user. On driver signin trip updated to update drivrIdOrPhone
   * from phoneNo to driver id
   */
  @Expose()
  // @Length(11, 50, { message: 'Driver id is required', groups: [schedule] })
  // @IsPhoneNumber('ZZ', { message: 'A valid phone number with area code is required' })
  @IsDefined({ message: 'Driver needs to be defined', groups: [schedule] })
  driverIdOrPhone: string;

  @Expose()
  tripStatus: TripStatus;

  /** start address copy of first address of tripSequnce.
   *  property writen to db for server query. UI uses getter created on the fly
   *  */
  @Expose({ toPlainOnly: true })
  @ValidateNested({ message: 'Valid Address is required', groups: [schedule] })
  @Type(() => Address)
  get startAddress(): Address {
    if (this.tripSequence.length === 0 || !this.tripSequence[0].address) {
      return null;
    }
    return this.tripSequence[0].address;
  }
  /** get farthest point(based on crow fly distance) from starting location
   * property writen to db for server query. UI uses getter created on the fly
   */
  @Expose({ toPlainOnly: true })
  @ValidateNested({ message: 'Valid Address is required', groups: [schedule] })
  @Type(() => Address)
  get returnDestination(): Address {
    if (this.tripSequence.length > 0) {
      let i = 0;
      if (!!this.isOneWay) {
        i = this.tripSequence.length - 1;
      } else {
        let r = 0;
        this.tripSequence.forEach((e, j) => {
          if (!!e.address.geoLoc) {
            const o = this.tripSequence[0].address.geoLoc.geopoint;
            const d = e.address.geoLoc.geopoint;
            const x = findDistance(o.latitude, o.longitude, d.latitude, d.longitude);
            if (x > r) {
              r = x;
              i = j;
            }
          } else {
            return null;
          }
        });
      }
      return this.tripSequence[i].address;
    } else {
      return null;
    }
  }

  /** Initial distance provider. */
  mapProvider: MapProvider = MapProvider.Bing;

  estMph = 30; // property for UI only not for the dB
  /** tracking numbers which are yet to be delviered
   * used to provide dropdown list for delviery activity
   */
  openTrackingNos: { [key: string]: { pickedFrom: string, pickupTrailer: string } };

  /** route response updated when route is created, save different route segements
   * routeResponse is not save in dB. UI helper to avoid repeat API calls
    */
  routeResponse: { [key: string]: RouteResponse } = {};
  /** helper property, when true calls route by segement([1-2], [2-3]) when false calls one route ([1-2-3]) */
  multiMode = false;

  /** border used in the route format: 1-2: ambasador birdge: { ...iPoint} */
  borderUsed: { [key: string]: { [key: string]: BorderListItem } };

  /** GPS Log to debug trip gps */
  gpsLog: LocationData[] = [];
  /** Trucks used on the trip. Writen to db but UI  uses getter property created on the fly
   * Used to block truck from using on mutliple trips
   */
  @Expose({ toPlainOnly: true })
  get activeTrucks(): string[] {
    const hooks = this.tripSequence.filter(f => f.activity === TripActivityType.hookUp && !!f.truck); // add at trip root for db search
    const t = [];
    hooks.forEach(h => { t.push(h.truck); }); // add at trip root for db search

    return t;
  }
  /** Trailers used on the trip. Writen to db but UI  uses getter property created on the fly
 * Used to block truck from using on mutliple trips
 */
  @Expose({ toPlainOnly: true })
  get activeTrailers(): string[] {
    const hooks = this.tripSequence.filter(f => f.activity === TripActivityType.hookUp && !!f.trailer); // add at trip root for db search
    const t = [];
    hooks.forEach(h => { t.push(h.trailer); }); // add at trip root for db search

    return t;
  }
  /** Delvieries pending form previous trip. 
   * This blocks deliveries to be removed where the trailer has pending deliveries*/
  @Expose()
  pendingDeliveriesPreviousTrip: { [key: string]: { pickedFrom: string, pickupTrailer: string, pickedAt: Date } };

  @Expose({ toPlainOnly: true })
  get isExpired(): boolean {
    if (this.tripStatus === TripStatus.expired) {
      return true;
    } else {
      return false;
    }
  }
  @Expose({ toPlainOnly: true })
  get isOpen(): boolean {
    if (this.tripStatus < TripStatus.closed) {
      return true;
    } else {
      return false;
    }
  }
  /** All Delivery Tracking numbers in the trips. Returns array of string */
  get allDeliveryTNos() {
    const d = this.tripSequence.filter(f => !!f.delTrackingNos && !isEmpty(f.delTrackingNos));
    // const d = this.tripSequence.filter(f => f.activity === TripActivityType.delivery);
    const t: string[] = [];
    d.forEach(ele => {
      for (const key in ele.delTrackingNos) {
        if (ele.delTrackingNos.hasOwnProperty(key)) {
          t.push(key);
        }
      }
    });
    return t;
  }
  /** Cumulative time of the whole trip in hours rounded to 15 min intervals. */
  get cumulativeTime(): number {
    if (this.tripSequence.length > 0) {
      let c = (this.tripSequence[this.tripSequence.length - 1].arrivalTime.valueOf() -
        this.tripSequence[0].arrivalTime.valueOf()) / 1000 / 60 / 60;
      c = Math.round(c * 4) / 4;
      return c;
    } else {
      return null;
    }
  }

  /** Cumulative distace(miles) travelled in the whole trip. Rounded to mile */
  get cumulativeDist(): number {
    let d = 0;
    for (let i = 1; i < this.tripSequence.length; i++) {
      d = d + this.tripSequence[i].distance; // .estimatedTime(this, i).dist;
    }
    return Math.round(d);
  }
  /** Cumulative drivering time on the whole trip. Rounded to 15 min intervals */
  get cumulativeDriveTime(): number {
    let t = 0;
    for (let i = 1; i < this.tripSequence.length; i++) {
      // logger.log('accum time: ', t);
      t += this.getDrivingDuration(i);
    }
    return t; // Math.round(t * 2) / 2;
  }
  /** start date time for the trip, UI helper */
  get startDate(): Date {
    return (this.tripSequence.length > 0) ? this.tripSequence[0].clone().arrivalTimeBrowser : null;
  }
  /** end date time for the trip, UI helper */
  get endDate(): Date {
    logger.log(`[trip-endDate] arrivalTimeBrowser: ${this.tripSequence[this.tripSequence.length - 1].clone().arrivalTimeBrowser},
    arrivalTime: ${this.tripSequence[this.tripSequence.length - 1].arrivalTime}`)
    return (this.tripSequence.length > 0) ? this.tripSequence[this.tripSequence.length - 1].clone().arrivalTimeBrowser : null;
  }
  /** to be updated set to true for dev TODO: SS */
  get isEditable(): boolean {

    return true;
  }
  /** returns array of map labels for locations with valid address geoLoc*/
  get mapLabels(): MapLabel[] {
    const labels: MapLabel[] = [];
    const c = this.tripSequence.filter(f => !f.address || !f.address.geoLoc);
    if (c.length === 0) {
      this.tripSequence.forEach((e, i) => {
        // if (!!e.address.geoLoc) {
        if (!!e.address.geoLoc && (i === 0 || !isEqual(e.address.geoLoc, this.tripSequence[i - 1].address.geoLoc) ||
          this.tripSequence[i - 1].activity === TripActivityType.drop)) {
          // if (!!e.borderWayPoint) {
          //   for (const key in e.borderWayPoint) {
          //     if (e.borderWayPoint.hasOwnProperty(key)) {
          //       const element = e.borderWayPoint[key];

          //       labels.push({
          //         iPoint: element.iPoint,
          //         title: `${element.province}-${element.state}`,
          //         productType: ProductType.trip,
          //         index: i,
          //         startTime: e.arrivalTime
          //       });
          //     }
          //   }
          // }
          labels.push({
            iPoint: {
              latitude: Math.round(e.address.geoLoc.geopoint.latitude * 1000000) / 1000000,
              longitude: Math.round(e.address.geoLoc.geopoint.longitude * 1000000) / 1000000
            },
            title: alphaChar(65 + i),
            productType: ProductType.trip,
            addressFormated: e.address.addressFormated,
            country: e.address.country,
            index: i,
            startTime: e.arrivalTime,
            stateProv: e.address.stateProv
          });
        }
      });
      return labels;
    } else {
      return [];
    }
  }


  constructor() {
    super();
    this.tripSequence = [];
    this.minDriverExp = 0;
    this.isWaitingPaid = false;
  }

  /** parses trip and array of trip transactions */
  public static parse(obj) {
    return parseTrip(obj, TripBase);
    // try {
    //   if (obj == null) { return null; }
    //   // obj = sanitizeDateIPoint(obj, ipointType);
    //   obj = sanitizeDateIPoint(obj);
    //   const tripTransactions = parseTripTransactionArray((<TripBase>obj).tripSequence);
    //   const m = plainToInstance<TripBase, any>(TripBase, obj);
    //   m.sanitize();
    //   m.tripSequence = tripTransactions;
    //   if (!!(obj as TripBase).gpsStatus) {
    //     m.gpsStatus = { ...(obj as TripBase).gpsStatus };
    //   }
    //   return m;
    // } catch (error) {
    //   logger.log('Error happened during parse', error);
    //   return null;
    // }
  }
  public static parseTripArray = (obj: any[]): TripBase[] => {
    const r = !!obj ? obj.map(o => TripBase.parse(o)) : null;
    return r;
  }
  /** Get Driving duration at the given leg. */
  getDrivingDuration(i: number) {
    if (i <= 0 || i > this.tripSequence.length - 1) {
      return 0;
    }
    // curr arrival time - prev appointment time - prev dwell time
    return (this.tripSequence[i].arrivalTime.valueOf() - this.tripSequence[i - 1].apptDateTimeCalc.valueOf()) / 3600000
      - this.tripSequence[i - 1].dwellDuration;
  }
  /** santizes trip and array of trip transactions */
  sanitize() {
    super.sanitize();
    if (!!this.rate) { this.rate = +this.rate; }
    if (!!this.tripType) { this.tripType = +this.tripType; }
    if (!!this.term) { this.term = +this.term; }
    this.tripSequence.forEach(element => {
      element.address.sanitize();
      element.arrivalTime = sanitizeDate(element.arrivalTime);
      if (!!element.apptDateTime) {
        element.apptDateTime = sanitizeDate(element.apptDateTime);
      }
    });

    for (const key in this.pendingDeliveriesPreviousTrip) {
      if (this.pendingDeliveriesPreviousTrip.hasOwnProperty(key)) {
        const element = this.pendingDeliveriesPreviousTrip[key];
        element.pickedAt = sanitizeDate(element.pickedAt);

      }
    }
  }
  /** clones trip and array of trip trip transactions */
  clone() {
    const tTransactions = this.tripSequence;
    const t = instanceToInstance(this);
    t.sanitize();
    t.tripSequence = cloneTripTransactionArray(tTransactions);
    if (!!this.gpsStatus) {
      t.gpsStatus = { ...this.gpsStatus };
    }
    return t;
  }
  /** Analyze Trip to see if more information is needed or suggest the user
* to submit for approval.*/
  getValDbStatus(): IValDbStatusResult {
    const result: IValDbStatusResult = {
      pass: false,
      message: undefined,
      groupResult: {}
    };

    let x: { groupPass: boolean; message: string; };

    // Schedule
    const r = this.validateSyncGroup(schedule);
    x = { groupPass: null, message: '' };
    x.groupPass = Object.keys(r).length === 0;
    x.message = (x.groupPass) ? 'Manage Legal Information' : 'Legal information is required';
    result.groupResult[schedule] = x;

    // is it passed all the tests? (True means it failed here)
    result.pass = !Object.keys(result.groupResult).some((k) => !result.groupResult[k].groupPass);
    // passed.
    if (!result.pass) {
      if (this.dbStatus === DbStatus.Initial || this.dbStatus === DbStatus.ReleasedMod) {
        result.message = 'Publish record to make it available for bidding!';
      }
    } else {
      result.message = 'More information is required on the Trip Details!';
    }
    return result;
  }

  validateSyncGroup(group: TripValidationGroup): IValidationMsg {
    return this.validateSync({ groups: [group] });
  }

  validateSync(options?: ValidatorOptions,
    driverL?: DriverList, truckL?: VehicleList, trailerL?: VehicleList, orig?: TripBase): IValidationMsg {
    this.sanitize();
    const m = validateSync(this, options);
    const r = this.toValidationError(m);

    const tActivity = EnumHelper.getNamesAndValues(TripActivityType);
    // logger.log('tActivity', tActivity);

    if (this.tripSequence.length <= 1) {
      r['tripSequence'] = ['Trip needs to have atleast two scheduled legs!'];
    }
    for (let i = 1; i < this.tripSequence.length; i++) {
      if (isBefore(this.tripSequence[i].arrivalTime, this.tripSequence[i - 1].arrivalTime)) {
        r['tripSequence'] = ['Trip dates are out of order'];
      }
    }
    const currTripId = !!this.id ? this.id : null;
    // get driver Object from the driver Id
    const d = !!driverL ? pickBy(driverL.list, (value, key) => value.driverId === this.driverIdOrPhone
      || key === this.driverIdOrPhone) : null;
    const k = !!d ? Object.keys(d)[0] : null;

    if (!!this.driverIdOrPhone && !!k && (!!driverL.list[k].openTrip && Object.keys(driverL.list[k].openTrip)[0] !== currTripId)) {
      r['driverIdOrPhone'] = [`${driverL.list[k].name} blocked by trip ${Object.values(driverL.list[k].openTrip)[0].description}`];
    }
    if (!this.isOneWay && !isEqual(this.vehiclesDropped(PromoListType.promoTruck), this.activeTrucks)) {
      r['tripSequence'] = [`Incorrect Truck Drop`];
    }
    if (!this.isOneWay && !isEqual(this.vehiclesDropped(PromoListType.promoTrailer), this.activeTrailers)) {
      r['tripSequence'] = [`Incorrect Trailer Drop`];
    }
    const invSeqIdx = this.tripSequence.findIndex((f, j) => !isEmpty(f.validateSync(undefined, this, j, truckL, trailerL)));
    for (let i = 0; i < this.tripSequence.length; i++) {
      const ele = this.tripSequence[i];
      if (!!orig) {
        const before = orig.tripSequence[i];
        const after = this.tripSequence[i];

        if (!!this.transactionBlocked(i) && !this.compareTrans(before, after)) {
          r[`tripSequence ${i}`] = [`${alphaChar(65 + i)} activty is locked`];
        }
      }
      // validate if appointment will meet the arrival time

      if (!this.isApptFeasible(i)) {
        // tslint:disable-next-line:max-line-length
        r[`tripSequence ${i}`] = [`${alphaChar(65 + i)} transaction ${this.tripSequence[i].apptDateTime.toLocaleString()} appointment not feasible @ ${this.tripSequence[i].address.addressFormated}`];
      }
      // validate all trip transactions
      const sr = this.tripSequence[i].validateSync(undefined, this, i, truckL, trailerL);


      if (Object.keys(sr).length > 0 && !options.groups.includes('advertise')) {
        mergeValidation(r, sr, `Step-${alphaChar(65 + i)}`, `Step-${alphaChar(65 + i)}`);
      }
      const aName = tActivity.find(f => f.value === this.tripSequence[i].activity).name;
      if (invSeqIdx === -1 && !this.checkActivityIfAllowed(i)) {
        r[`tripSequence ${i}`] = [`${toSentence(aName)} not allowed at (${alphaChar(65 + i)})`];
      }
      // if index with zero distance is not first and is not drop switch then show route error 
      // tslint:disable-next-line:max-line-length
      if (invSeqIdx === -1 && i > 1 && ele.distance === 0 && this.tripSequence[i].address.geoLoc.geohash !== this.tripSequence[i - 1].address.geoLoc.geohash) {
        r['tripSequence'] = ['Route failed'];
      }
    }
    return r;
  }

  validateAddTransaction(): IValidationMsg {
    return this.validateSyncGroup(schedule);
  }

  tripSummary(): { destinations: string, mileage: number, nPicUps: number, nDeliveries: number } {
    let d = '';
    let m = 0;
    let nP = 0;
    let nD = 0;
    d = 'From: ' + this.tripSequence[0].address.streetName + ', ' + this.tripSequence[0].address.city +
      ', ' + this.tripSequence[0].address.stateProv;

    this.tripSequence.forEach((element, index) => {
      if (!!element.address.addressFormated && index !== 0) {
        d = d + ' to ' + element.address.streetName + ', ' + element.address.city + ', ' + element.address.stateProv;
      }
      if (index < this.tripSequence.length - 1 && !!element.address.addressFormated) {
        m = m + findDistance(element.address.lat, element.address.long,
          this.tripSequence[index + 1].address.lat, this.tripSequence[index + 1].address.long, 'miles');
      }
      nP = element.activity === TripActivityType.pickUp ? nP + 1 : nP;
      nD = element.activity === TripActivityType.delivery ? nD + 1 : nD;
    });
    // logger.log(d);

    return { destinations: d, mileage: m, nPicUps: nP, nDeliveries: nD };

  }

  /** 
   * @param Depricated Use   estRouteWithTraffic()
   * @param distanceCalc : For client, it is the map service. For funcitons, it will
   * Update the distance and ruations of the trip using a provided map service.
   * be a sepeate api. */
  async estTripTiming(distanceCalc: DistanceCalculator) {
    let drvDistance = 0, drvDuration = 0;
    for (let j = 1; j < this.tripSequence.length; j++) {
      const prev = this.tripSequence[j - 1];
      const curr = this.tripSequence[j];
      if (!prev.address.isLocationDefined || !curr.address.isLocationDefined) {
        continue; // return null;
      }
      const q: DistanceQuery = {
        provider: this.mapProvider, origins: [prev.address.geoLoc.geopoint],
        destinations: [curr.address.geoLoc.geopoint], travelMode: 'truck'
      };

      const result = await distanceCalc.getDistance(q);
      logger.log('distance matrix result', result);
      if (!!result) {
        drvDistance = result.distnace; // miles.
        drvDuration = result.time;
      } else {
        drvDistance = findDistance(prev.address.geoLoc.geopoint.latitude, prev.address.geoLoc.geopoint.longitude,
          curr.address.geoLoc.geopoint.latitude, curr.address.geoLoc.geopoint.longitude);
        drvDuration = result.distnace / this.estMph;
      }
      this.updateDistanceTime(j, drvDistance, drvDuration);
      logger.log('updateDistanceTime1', this.updateDistanceTime(j, drvDistance, drvDuration));
    }
  }
  /** validated if appointment is feasible based on the planned arrival time */
  isApptFeasible(i: number): boolean {
    if (!!this.tripSequence[i].apptDateTime) {
      if (this.tripSequence[i].arrivalTime.valueOf() > this.tripSequence[i].apptDateTime.valueOf()) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }


  /** Update trip transactions with driving time and driving duration
   * @deprecated used by distance matrix fn
   * @param idx transation index
   * @param drvDistance driving distance
   * @param drvDuration driving duration
   * @param updateForcast boolean
   */
  updateDistanceTime(idx: number, drvDistance: number, drvDuration: number, updateForcast = false) {
    logger.log(`Update Distance called - ${drvDistance} - ${drvDuration}`);

    if (idx <= 0 || idx > this.tripSequence.length - 1) {
      return;
    }
    const curr = this.tripSequence[idx];
    const prev = this.tripSequence[idx - 1];

    curr.distance = drvDistance;
    curr.drvDuration = drvDuration;

    // previous appt time + dwell duration + travelling time will defined the
    // estimated time of arrival at current location.
    if (prev.transactionStatus !== TransactionStatus.autoCompleted) {
      curr.arrivalTime = new Date(prev.apptDateTimeCalc);
    } else {
      curr.arrivalTime = new Date(prev.autoCompleteTime.leftAt);
    }
    curr.arrivalTime.setSeconds(curr.arrivalTime.getSeconds() + Math.round((prev.dwellDuration + drvDuration) * 3600)
      + curr.hos.offDuty * 3600);

    logger.log('curr.arrivalTime', curr.arrivalTime);
    // forcast for next step is only needed, if arrival time becomes later then
    if (updateForcast && curr.arrivalTime > curr.apptDateTime) {
      logger.error('Update Forcase is not implemented yet.');
    }
  }

  /** How much ratio of the total time/druation is taken by each leg of the journey.
   * UI helper
   */
  getDistanceDurationDetails(): {
    distanceR: number, durationR: number, driveDuration: number, totalDuration: number, distance: number, hosBreak: number
  }[] {
    let distanceR = 0, durationR = 0, driveDuration = 0,
      totalDuration = 0, distance = 0, hosBreak = 0;
    const r: {
      distanceR: number, durationR: number,
      driveDuration: number, totalDuration: number, distance: number, hosBreak: number
    }[] = [];

    const distanceTotal = this.cumulativeDist;
    const durationTotal = this.cumulativeDriveTime;
    // logger.log('cumulativeDriveTime', this.cumulativeDriveTime);

    // first entry is zero.
    r.push({ distanceR, durationR, driveDuration, totalDuration, distance, hosBreak });
    for (let idx = 1; idx < this.tripSequence.length; idx++) {
      const seq = this.tripSequence[idx];
      distanceR = distanceTotal > 0 ? seq.distance / distanceTotal : 0;
      // durationR = this.getDrivingDuration(idx) / durationTotal;
      durationR = durationTotal > 0 ? seq.drvDuration / durationTotal : 0;
      hosBreak = seq.hos.offDuty;
      // driveDuration = this.getDrivingDuration(idx) - seq.hos.offDuty;
      driveDuration = seq.drvDuration;
      totalDuration = (this.tripSequence[idx].apptDateTimeCalc.valueOf() -
        this.tripSequence[idx - 1].apptDateTimeCalc.valueOf()) / 3600000.0;
      distance = this.tripSequence[idx].distance;
      r.push({ distanceR, durationR, driveDuration, totalDuration, distance, hosBreak });
    }
    return r;
  }
  /** TODO: SS update Fn to use parameters for driving time(11) and onDuty time (13)
   * set HOS properties for the trip transaction
   * @param idx trip transaction index
   * @param drvDuration driving duration
   */
  hosRules(idx: number, drvDuration?: number) {
    let brk = 0;
    const curr = this.tripSequence[idx];
    const prev = this.tripSequence[idx - 1];
    // logger.log('curr', curr.hos);
    // logger.log('prev', prev.hos);


    let balance = prev.hos.balanceDrivingTime;
    const onD = prev.dwellDuration;
    drvDuration = !!drvDuration ? drvDuration : curr.drvDuration;
    curr.hos.commDrivingTime = !prev.hos.isReset ? prev.hos.commDrivingTime + drvDuration : drvDuration;
    logger.log(idx, drvDuration, balance, onD);

    if ((drvDuration + balance + onD) <= 13 && (drvDuration + balance) <= 11) {
      brk = 0;
    } else {
      const x = Math.floor((drvDuration + balance) / 11);
      const y = Math.floor((drvDuration + balance + onD) / 13);
      const c = x > y ? x : y;
      brk = 10 * c;
    }
    balance = (drvDuration + balance) % 11;
    logger.log('bal cal', balance);
    if (curr.hos.commDrivingTime > 60) { brk = brk + 24; balance = 0; curr.hos.isReset = true; }
    curr.hos.balanceDrivingTime = balance;
    curr.hos.offDuty = brk;
    // return { hosBreak: brk, hosBalance: balance, reset: reset };
  }

  /** sets the list of all the pickup tracking numbers for the trip upto given trip transaction index
   * @param currIndex current index
   *   */
  getTripTrackingNos(currIndex: number): { [key: string]: { pickedFrom: string } } {
    let t: { [key: string]: { pickedFrom: string } };
    let commTrackingNos: { [key: string]: { pickedFrom: string } };
    let p: TripTransaction[];
    p = this.tripSequence.filter((f, j) => f.activity === TripActivityType.pickUp && j < currIndex);
    (<Pickup[]>p).map(m => { if (!m.trackingNos || m.trackingNos.length === 0) { m.trackingNos = []; } });
    (<Pickup[]>p).forEach(e => e.trackingNos.forEach(g => {
      t = { [g]: { pickedFrom: `${e.address.addressFormattedL1}, ${e.address.city}` } };
      if (!commTrackingNos) {
        commTrackingNos = t;
      } else if (!!commTrackingNos || !(Object.keys(commTrackingNos).includes(g))) {
        const k = Object.keys(t)[0];
        const v = Object.values(t)[0];
        commTrackingNos[`${k}`] = v;
      } else {
        commTrackingNos = null;
        logger.log('no tracking nos to add');
      }
    }));
    return commTrackingNos;
  }
  /** returns list delivery tracking numbers in the trip 
   * @param currIndex  Current Trip Transaction Index
  */
  // getDeliveryTrackingNos(currIndex?: number): string[] {
  //   currIndex = !!currIndex ? currIndex : this.tripSequence.length;
  //   let p: TripTransaction[];
  //   let commDelTrackingNo: string[] = [];
  //   p = this.tripSequence.filter((f, j) => (f.activity === TripActivityType.delivery || f.activity === TripActivityType.drop)
  //     && j < currIndex);
  //   (<Delivery[]>p).forEach(e => {
  //     commDelTrackingNo = !!e.delTrackingNos ? commDelTrackingNo.concat(Object.keys(e.delTrackingNos)) : commDelTrackingNo;
  //   });
  //   return commDelTrackingNo;
  // }
  /** gets Open tracking Nos for given index */
  // getOpenTrackingNos(currIndex?: number): { [key: string]: { pickedFrom: string, pickupTrailer: string } } {
  //   currIndex = !!currIndex ? currIndex : this.tripSequence.length;


  //   /** delvery Tracking numbers exept for current */
  //   let deliveryTs: string[] = [];
  //   const o = {};
  //   for (let j = 0; j < this.tripSequence.length; j++) {
  //     const g = this.tripSequence[j];
  //     if (g.delTrackingNos && j !== currIndex) {
  //       deliveryTs = deliveryTs.concat(Object.keys(g.delTrackingNos));
  //     }

  //   }

  //   for (let i = 0; i < this.tripSequence.length; i++) {
  //     const ele = this.tripSequence[i];
  //     if (i < currIndex && !!ele.trackingNos) {
  //       ele.trackingNos.forEach(t => {
  //         if (!deliveryTs.includes(t)) {
  //           const pickupTrailer = this.getLastHook(i).lastHookTrailer;
  //           o[t] = {
  //             pickedFrom: `${ele.address.addressFormattedL1}, ${ele.address.city}`,
  //             pickupTrailer: pickupTrailer
  //           };
  //         }
  //       });
  //     }
  //   }
  //   return o;
  // }
  /** Returns dictionary of tracking number and picked from address string.
   * @param tNo selected tracting number
   * @param currIndex current trip transaction index
   * @param trailerL Trailer List
   */
  // createDelTrackingObj(tNo: string[], currIndex: number, trailerL: VehicleList): { [key: string]: { pickedFrom: string } } {
  //   let t: { [key: string]: { pickedFrom: string } };
  //   let delTNo: { [key: string]: { pickedFrom: string } };
  //   const p = this.getTripTrackingNos(currIndex);
  //   let q: { [key: string]: { pickedFrom: string } } = {};
  //   // get previous hooked trailer
  //   const prevHook: Hook = this.tripSequence.find((f, i) => f.activity === TripActivityType.hookUp && i < currIndex) as Hook;
  //   // get pending delivery from the trailer list
  //   q = trailerL.list[prevHook.trailer].pendingDelivery;
  //   const r: { [key: string]: { pickedFrom: string } } = { ...q, ...p };
  //   tNo.forEach(g => {
  //     if (!!r && !isEmpty(r) && Object.keys(r).includes(g)) {
  //       t = { [g]: { pickedFrom: r[g].pickedFrom } };
  //       if (!delTNo) {
  //         delTNo = t;
  //       } else if (!!delTNo || !(Object.keys(delTNo).includes(g))) {
  //         const k = Object.keys(t)[0];
  //         const v = Object.values(t)[0];
  //         delTNo[`${k}`] = v;
  //       } else {
  //         delTNo = null;
  //         logger.log('no tracking nos to add');
  //       }
  //     } else {
  //       delTNo = !delTNo ? {} : delTNo;
  //       delTNo[g] = { pickedFrom: 'no pickup ref' };
  //     }
  //   });
  //   return delTNo;
  // }

  /**
   * TODO: HG
   * custom function function defined which over writes the base class fn @param toFirebaseObj
   * to be refactored
   * @param timeStampCreate if null/undefined: no update,
   * If True: createdAt and updatedAt filed are set as time stamp.
   * If False: only updateAt field is updated with time stamp.
   * @param getGeoPointFn function to be used to update GeoPoint key, if present
   */
  public toFirebaseObj(timeStampCreate?: boolean,
    getGeoPointFn?: (obj: any) => any, ignore?: string[]) {

    // user class to plain
    const seq = this.tripSequence;
    const seqPlain = seq.map(s => s = <any>instanceToPlain(s));
    const tripPlain = <TripBase>instanceToPlain(this);
    tripPlain.tripSequence = seqPlain;

    const obj = cleanupUndefined(tripPlain);
    // cleanupUndefined(obj);

    // const a = (!!ignore && ignore.length > 0) ?
    //   Array.prototype.push.apply(BaseModel.getBaseIgnoreList(), ignore) :
    //   BaseModel.getBaseIgnoreList();

    const a = (!!ignore && ignore.length > 0) ? Array.prototype.push.apply(['id'], ignore) : ['id'];
    const o = firestoreMap(obj, a); // , getGeoPointFn);
    if (timeStampCreate != null) {
      // block for timestamp error at gps deployed functions
      // o.updatedAt = firebase.firestore.FieldValue.serverTimestamp();
      logger.log('updatedAt blocked at trip base');
      logger.log('timeStampCreate', timeStampCreate);

      if (timeStampCreate) {
        logger.log('createdAt blocked at trip base');
        // o.createdAt = firebase.firestore.FieldValue.serverTimestamp();
      }
    }
    return o;
  }
  /** Extracts tracking number from the pickup and deliveries and pushes in a array
   *  @param trackingNumbers: Array of tracking numbers used to server side search (array contains)
   *  trackingNumbers is written into db but it is not retrieved during read. Is created on the fly when db object is parsed.
  */
  @Expose({ toPlainOnly: true })
  get trackingNumbers(): string[] {
    let r: string[];
    this.tripSequence.forEach(ele => {
      if (r === undefined) {
        r = [];
      }
      if ((<Pickup>ele).trackingNos) {
        const p = (<Pickup>ele).trackingNos.filter(f => !r.includes(f));
        r = r.concat(p);
      }
      if (ele.delTrackingNos) {
        const d = Object.keys((<Delivery>ele).delTrackingNos).filter(f => !r.includes(f));
        r = r.concat(d);
      }
    });

    return r;
  }
  /**
   * Set properties when trip is created or updated. Called from server
   * @param trailerL Trailer List
   * @param vendorComp Carrier company summary
   * @param driverId driver ID
   */
  setTripProperties(trailerL: VehicleList, truckL: VehicleList, driverL: DriverList,
    vendorComp?: CompanyFleet | CompanyCarrier, oTrip?: TripBase) {
    this.tripStatus = this.getInitTripStatus(trailerL, truckL, driverL);
    this.setTransactionStatus(oTrip);

    if (!!vendorComp) {
      // this.vendorCompSummary = vendorComp.getVendorCompanySummary();
      this.carrierCompanySummary = (<CompanyCarrier>vendorComp).carrierCompanySummary;
    }
    // if trailer(s) used on the trip had pending deliveries from previous trip
    // sen pendingDelveriesPreviousTrip object to block these deliveries to be removed unless trailer is removed from the trip
    // or deliveries are set to expired
    // this.pendingDeliveriesPreviousTrip = {};
    this.activeTrailers.forEach(ele => {
      if (!!trailerL && !isEmpty(trailerL.list[ele].pendingDelivery)) {
        this.pendingDeliveriesPreviousTrip = this.pendingDeliveryFromTrailerToTrip(trailerL, ele);
      }
    });
    return this;
  }
  /** get intial trip status.
   * Trip is assigned to driver id or phone number(if driver id does not exist)
   * To be updated once twillo sms is impletment
   * all trip status set to assigned (for development only)
   */
  getInitTripStatus(trailerL: VehicleList, truckL: VehicleList, driverL: DriverList): TripStatus {
    let s: TripStatus = TripStatus.assigned;
    for (const truck of this.activeTrucks) {
      // truckL is undefined when trip is updated and truck is not changed
      if (!!truckL && truckL.list[truck].isPlaceHolder) {
        s = TripStatus.created;
      }
    }
    for (const trailer of this.activeTrailers) {
      // trailerL is undefined when trip is updated and trailer is not changed
      if (!!trailerL && trailerL.list[trailer].isPlaceHolder) {
        s = TripStatus.created;
      }
    }
    // driverL is undefined when trip is updated and driver is not changed
    if (!!driverL &&
      (driverL.list[this.driverIdOrPhone].isPlaceHolder || driverL.list[this.driverIdOrPhone].itemStatus !== ItemStatus.active)) {
      s = TripStatus.created;
    }
    return s;
  }
  /** set transaction status based on current and orginal trip status
   * @param oTrip| Orginal trip
   */
  setTransactionStatus(oTrip: TripBase) {
    for (let i = 0; i < this.tripSequence.length; i++) {
      const ele = this.tripSequence[i];
      if (!oTrip || oTrip.tripStatus === TripStatus.created) {
        if (this.tripStatus === TripStatus.created) {
          ele.transactionStatus = TransactionStatus.planned;
        } else if (this.tripStatus === TripStatus.assigned) {
          ele.transactionStatus = TransactionStatus.scheduled;
        }
        ele.initAutoCompleteProps();
        ele.eta = null;
      } else {
        // tslint:disable-next-line:max-line-length
        const oTrans = oTrip.tripSequence.find((f) => f.activity === ele.activity && f.address.geoLoc.geohash === ele.address.geoLoc.geohash);
        if (this.tripStatus === TripStatus.created) {
          ele.transactionStatus = oTrans ? oTrans.transactionStatus : TransactionStatus.planned;
        } else if (this.tripStatus === TripStatus.assigned) {
          ele.transactionStatus = oTrans ? oTrans.transactionStatus : TransactionStatus.scheduled;
        }
        if (ele.transactionStatus < TransactionStatus.arrived) {
          ele.initAutoCompleteProps();
        }
      }
    }
  }
  /**
   * returns pending delivery in the trailer which can be assigned to trip
   * @param trailerL Trailer List
   * @param trailer Trailer with pending delviery
   */
  pendingDeliveryFromTrailerToTrip(trailerL: VehicleList, trailer: string): {
    [key: string]: { pickedFrom: string; pickupTrailer: string; pickedAt: Date }
  } {
    const p = this.pendingDeliveriesPreviousTrip ? this.pendingDeliveriesPreviousTrip : {};
    if (!isEmpty(trailerL.list[trailer].pendingDelivery)) {
      for (const key in trailerL.list[trailer].pendingDelivery) {
        if (trailerL.list[trailer].pendingDelivery.hasOwnProperty(key)) {
          const g = trailerL.list[trailer].pendingDelivery[key];
          if (!this.allDeliveryTNos.includes(key)) {
            p[key] = { pickedFrom: g.pickedFrom, pickupTrailer: trailer, pickedAt: g.pickedAt };
          } else {
            p[key] = null;
          }
        }
      }
    }
    return p;
  }

  /** 
   * deletes pod picture(s) for given trip transaction. Called from server API
   * @param seqIndex trip transaction index
   * @param picIndex pod picture index
   */
  deletePod(seqIndex: number, picIndex?: number): TripBase | null {
    // delete one pod picture for the given trip transaction
    if (seqIndex !== null && seqIndex !== undefined && !!(<Delivery>this.tripSequence[seqIndex]).pod
      && picIndex !== null && picIndex !== undefined && !!(<Delivery>this.tripSequence[seqIndex]).pod[picIndex]) {
      (<Delivery>this.tripSequence[seqIndex]).pod.splice(picIndex, 1);
      return this;
      // delete all pod pictures for the given trip transaction
    } else if (seqIndex !== null && seqIndex !== undefined && picIndex == null && !!(<Delivery>this.tripSequence[seqIndex]).pod &&
      (<Delivery>this.tripSequence[seqIndex]).pod.length > 0) {
      (<Delivery>this.tripSequence[seqIndex]).pod = null;
      return this;
    } else {
      return null;
    }
  }
  /** @deprecated gets last truck and trailer hooked on the trip */
  getLastHook(index?: number): { lastHook: Hook, lastHookTruck: string, lastHookTrailer: string } {
    const lastIndex = index ? index : this.tripSequence.length - 1;
    const indexOfAllDrops = (arr: TripTransaction[], val: TripActivityType) => {
      return arr.reduce((acc, el, i) => (el.activity === val ? [...acc, i] : acc), []);
    };
    const iDrops = indexOfAllDrops(this.tripSequence.slice(0, lastIndex), TripActivityType.drop);
    const lastDropIdx = iDrops.length > 0 ? iDrops.pop() : -1;
    const lastHook = <Hook>this.tripSequence.slice(0, lastIndex).find(
      (h, ix) => h.activity === TripActivityType.hookUp && ix > lastDropIdx);
    return { lastHook, lastHookTruck: lastHook.truck, lastHookTrailer: lastHook.trailer };
  }
  /** compares before and after after change or removal of pickup and returns affected @param delvieries and trip transaction @param index
   * @param orig TripBase
   */
  getAffectedDeliveries(orig: TripBase): { affDel: Delivery[], affDelIndex: number[] } {
    // check matching deliveies for pickup changes
    const getAffectedDeliveries: Delivery[] = [];
    const getAffectedDelIndex: number[] = [];
    const origPickups = orig.tripSequence.filter(f => f.activity === TripActivityType.pickUp);
    const updatedPickups = this.tripSequence.filter(f => f.activity === TripActivityType.pickUp);

    let origTrackingNos: string[] = [];
    origPickups.forEach(e => origTrackingNos = origTrackingNos.concat(e.trackingNos));

    let updatedTrackingNos: string[] = [];
    updatedPickups.forEach(e => updatedTrackingNos = updatedTrackingNos.concat(e.trackingNos));
    const trackingNosRemoved = difference(origTrackingNos, updatedTrackingNos);

    trackingNosRemoved.forEach(ele => {
      const d: Delivery = orig.tripSequence.find(f => f.activity === TripActivityType.delivery
        && !!f.delTrackingNos && Object.keys(f.delTrackingNos)[0] === ele) as Delivery;
      const i = orig.tripSequence.findIndex(f => f.activity === TripActivityType.delivery
        && !!f.delTrackingNos && Object.keys(f.delTrackingNos)[0] === ele);
      if (!!d) {
        getAffectedDeliveries.push(d);
        getAffectedDelIndex.push(i);
      }
    });
    return { affDel: getAffectedDeliveries, affDelIndex: getAffectedDelIndex };
  }
  /** get Avaialable Activity for given trip index
   * @param tripSeqIndex: Trip Sequence Index
   * Hookup cannot be followed by another hookup, there must have drop before another hookup
   * Drop must be followed by hook unless it is last item of the trip sequence
   */
  getFilteredActivity(tripSeqIndex: number) {
    const a = TripActivityType;
    const precedingSeq = !!tripSeqIndex ? this.tripSequence.slice(0, tripSeqIndex) : this.tripSequence.slice();
    const procedingSeq = !!tripSeqIndex && !!this.tripSequence[tripSeqIndex + 1]
      ? this.tripSequence.slice(tripSeqIndex + 1) : null;

    const hooks = precedingSeq.filter(f => f.activity === TripActivityType.hookUp);
    const drops = precedingSeq.filter(f => f.activity === TripActivityType.drop);
    let filteredActivity = { ...a };
    filteredActivity = omitBy(filteredActivity, (value) => typeof value !== 'number') as any;

    // trip should start with hook
    if (tripSeqIndex === 0) {
      filteredActivity = pickBy(filteredActivity, (value) => value === TripActivityType.hookUp) as any;
      return filteredActivity;
    }
    // trip should end with drop
    if (tripSeqIndex === this.tripSequence.length - 1 && !this.isOneWay) {
      filteredActivity = pickBy(filteredActivity, (value) => value === TripActivityType.drop) as any;
      return filteredActivity;
    }
    // it hooked and next hook will be blocked
    if (hooks.length > drops.length) {
      filteredActivity = omitBy(filteredActivity, (value) => value === TripActivityType.hookUp) as any;
    }
    // Drop should be followed by hook
    if (tripSeqIndex > 0 && !!this.tripSequence[tripSeqIndex - 1]
      && this.tripSequence[tripSeqIndex - 1].activity === TripActivityType.drop) {
      // logger.log('No open hook, next transaction should be Hook');
      filteredActivity = pickBy(filteredActivity, (value) => value === TripActivityType.hookUp) as any;
      return filteredActivity;
    }
    // delivery is blocked when there is no open tracking no to be delivered
    let o = Object.keys(pickBy(this.openPickupTrackingNos, (value) => value.pickpIndex < tripSeqIndex));
    o = !this.tripSequence[tripSeqIndex].delTrackingNos ? o : o.concat(Object.keys(this.tripSequence[tripSeqIndex].delTrackingNos));
    if (o.length === 0) {
      filteredActivity = omitBy(filteredActivity, (value) => value === TripActivityType.delivery) as any;
    }
    //   if proceeding trip activities exists drop and hookup will be blocked for the current index
    if (!!procedingSeq && procedingSeq.length > 0 && procedingSeq[0].activity !== TripActivityType.hookUp) {
      filteredActivity = omitBy(filteredActivity, (value) => value === TripActivityType.hookUp ||
        value === TripActivityType.drop) as any;
      //   if current activity is pick for which there is delivery in the proceeding trip  block all option other than pick

      if (this.tripSequence[tripSeqIndex].activity === TripActivityType.pickUp) {
        const pickBlockedByDel = procedingSeq.filter(f => f.activity === TripActivityType.delivery
          && intersection(Object.keys(f.delTrackingNos), this.tripSequence[tripSeqIndex].trackingNos).length > 0);
        if (pickBlockedByDel.length > 0) {
          filteredActivity = pickBy(filteredActivity, (value) => value === TripActivityType.pickUp) as any;
        }
      }

    }
    return filteredActivity;
  }
  /** validates if acitvity at given index @param i is allowed */
  checkActivityIfAllowed(i: number) {
    const filteredActivity = this.getFilteredActivity(i);
    const fArray = Object.values(filteredActivity);
    // fArray = map(fArray, Number);
    // logger.log('filteredActivity', filteredActivity);
    // logger.log('fArray', fArray);
    if (fArray.includes(this.tripSequence[i].activity)) {
      return true;
    } else {
      return false;
    }
  }
  /** find the border used onRoute
   * assumes border crossing is on one of the Canadian province which is being eveluated
  */
  // function is not being used
  findBorderOnRoute(route: Microsoft.Maps.Directions.IRoute[],  // route: Microsoft.Maps.Directions.IRoute[]
    borders: { [key: string]: BorderListItem; }): { [key: string]: BorderListItem } | null {
    const cA = this.tripSequence[this.tripSequence.length - 2].address.country;
    const cB = this.tripSequence[this.tripSequence.length - 1].address.country;
    // country of two destination is same no border onRoute, return
    if (cA === cB) { return null; }
    let borderOnRoute: { [key: string]: BorderListItem } = {};
    const provStateA = this.tripSequence[this.tripSequence.length - 2].address.stateProv;
    const provStateB = this.tripSequence[this.tripSequence.length - 1].address.stateProv;
    let prov: string;
    if (provinceCodes.includes(provStateA) && !provinceCodes.includes(provStateB)) {
      prov = provStateA;
    } else if (!provinceCodes.includes(provStateA) && provinceCodes.includes(provStateB)) {
      prov = provStateB;
    } else {
      prov = null;
      return null;
    }
    logger.log('prov', prov);

    const filteredBorders = {};
    for (const k in borders) {
      if (borders.hasOwnProperty(k)) {
        const e = borders[k];
        if (e.province === prov) {
          filteredBorders[k] = e;
        }
      }
    }
    let lD = 1000000;
    for (const key in filteredBorders) {
      if (filteredBorders.hasOwnProperty(key)) {
        const a = filteredBorders[key];
        for (let i = 0; i < route[0].routeLegs[0].itineraryItems.length; i++) {
          const e = route[0].routeLegs[0].itineraryItems[i];
          const d = findDistance(a.iPoint.latitude, a.iPoint.longitude, e.coordinate.latitude, e.coordinate.longitude, 'km');
          if (d < lD) {
            lD = d;
            borderOnRoute = {};
            borderOnRoute[key] = a;
          }
        }
      }
    }
    logger.log('borderOnRoute', borderOnRoute);
    return borderOnRoute;
  }
  /**
   * Completes Trip transaction manually. 
   * @param seqIndex Trip Sequence Index
   */
  // Called from server to make the change
  // Called from UI to validate before making server call
  manuallyComplete(seqIndex?: number): TripBase | null {
    // null if trip is closed or expired
    if (this.tripStatus >= TripStatus.closed) {
      return null;
    }
    // null if trip transaction is autocomplete, already manually completed or expired
    if (seqIndex != null && !!this.tripSequence[seqIndex].transactionStatus &&
      this.tripSequence[seqIndex].transactionStatus >= TransactionStatus.autoCompleted) {
      return null;
    }
    // null all trip transaction are autocomplete, already manually completed or expired
    if (seqIndex == null) {
      const openTrans = this.tripSequence.filter(f => !f.transactionStatus || f.transactionStatus <= TransactionStatus.arrived);
      if (openTrans.length === 0) {
        return null;
      }
      this.tripSequence.forEach(e => {
        if (!e.transactionStatus || e.transactionStatus <= TransactionStatus.arrived) {
          e.transactionStatus = TransactionStatus.manuallyCompleted;
          e.autoCompleteTime = null;
        }
      });
    } else {
      this.tripSequence.forEach((ele, i) => {
        if (i <= seqIndex &&
          (ele.transactionStatus === TransactionStatus.scheduled || ele.transactionStatus === TransactionStatus.arrived)) {
          this.tripSequence[i].transactionStatus = TransactionStatus.manuallyCompleted;

        }
      });
      // this.tripSequence[seqIndex].transactionStatus = TransactionStatus.manuallyCompleted;
    }
    const closedTrans = this.tripSequence.filter(f => !!f.transactionStatus && f.transactionStatus >= TransactionStatus.arrived);
    if (closedTrans.length === this.tripSequence.length &&
      (this.isOneWay && this.tripSequence[this.tripSequence.length - 1].activity === TripActivityType.drop || !this.isOneWay)) {
      this.tripStatus = TripStatus.closed;
    }
    return this;
  }
  canManuallyComplete(defaultCompany?: IDefaultCompanyData): boolean {
    if (!defaultCompany || this.carrierCompanySummary.cid !== defaultCompany.cid) {
      return false;
    }
    if (this.tripStatus < TripStatus.assigned || this.tripStatus > TripStatus.closed) {
      return false;
    }
    return true;
  }
  canExpire(defaultCompany?: IDefaultCompanyData): boolean {
    if (!defaultCompany || this.carrierCompanySummary.cid !== defaultCompany.cid) {
      return false;
    }
    return true;
  }
  /**
   * Validates if trip can be reset. Check is trucks, trialers and diver on trip are still available
   * @param truckL Truck List
   * @param trailerL Trailer List
   * @param driverL Driver List
   */
  canRestTrip(truckL: VehicleList, trailerL: VehicleList, driverL: DriverList): boolean {
    for (const itr of this.activeTrucks) {

      if (!!truckL.list[itr].openTrip) {
        return false;
      }
    }
    for (const itr of this.activeTrailers) {
      if (!!trailerL.list[itr].openTrip) {
        return false;
      }
    }
    const d = pickBy(driverL.listForUI, (value) => value.driverId === this.driverIdOrPhone);
    const dKey = Object.keys(d)[0];
    if (!!d[dKey].openTrip) {
      return false;
    }
    return true;
  }
  /**
   * resets trip status to assigned and transaction status to scheduled
   */
  resetTrip(): TripBase {
    if (this.tripStatus === TripStatus.created || this.tripStatus === TripStatus.expired) {
      return null;
    }
    this.tripSequence.forEach(ele => {
      if (ele.transactionStatus > TransactionStatus.scheduled) {
        ele.transactionStatus = TransactionStatus.scheduled;
        if (!!ele.autoCompleteTime) {
          ele.autoCompleteTime = null;
        }
        if (!!ele.locStaticMap) {
          ele.locStaticMap = null;
        }
        if (!!ele.eta) {
          ele.eta = null;
        }
        if (!!ele.gpsData) {
          ele.gpsData = null;
        }
      }
    });
    this.tripStatus = TripStatus.assigned;
    return this;
  }
  // ** gets tracking numbers which are picked up during the trip but not delivered. */
  // get pendingDeliveriesAfterDrop(): { [key: string]: { [key: string]: { pickedFrom: string; updatedAt: Date } }; } {
  //   const p: { [key: string]: { [key: string]: { pickedFrom: string; updatedAt: Date } }; } = {};
  //   // for (let i = 0; i < this.tripSequence.length; i++) {
  //   //   const ele = this.tripSequence[i];
  //   //   if (ele.activity === TripActivityType.drop) {
  //   //     let c = this.getOpenTrackingNos(i);
  //   //     logger.log('getOpenTrackingNos', c);

  //   //     c = pickBy(c, (value, key) => !ele.delTrackingNos || !ele.delTrackingNos[key]);
  //   //     for (const k in c) {
  //   //       if (c.hasOwnProperty(k)) {
  //   //         const val = c[k];
  //   //         p[ele.trailer] = { [k]: { pickedFrom: val.pickedFrom, updatedAt: this.startDate } };
  //   //       }
  //   //     }
  //   //   }
  //   // }

  //   for (const key in this.openTrackingNos) {
  //     if (this.openTrackingNos.hasOwnProperty(key)) {
  //       const val = this.openTrackingNos[key];
  //       const pickupIndex = this.tripSequence.findIndex(f => f.trackingNos && f.trackingNos.includes(key));
  //       p[val.pickupTrailer] = { [key]: { pickedFrom: val.pickedFrom, updatedAt: this.startDate } };
  //     }
  //   }
  //   return p;

  // }
  /**
   * gets tracking number of with pending delviery in the trailer from previous trip.
   * returns object with key as tracking number, with reference address string (picked from)
   * @param index tripSequence index
   * @param trailerL Trailer List
   */
  getPrevDelvTrackingNos(index: number, trailerL: VehicleList): { [key: string]: { pickedFrom: string; } } {
    // the trip seq to current index
    let alreadyDeliveredTrackingNos: string[];
    if (!!index && !!trailerL) {
      const seqSlice = this.tripSequence.slice(0, index);
      seqSlice.forEach(e => {
        if (e.activity === TripActivityType.delivery) {
          alreadyDeliveredTrackingNos = Object.keys(e.delTrackingNos);

        }
      });
      const trailerUsed = seqSlice.find(f => f.activity === TripActivityType.hookUp).trailer;
      if (!!trailerUsed) {
        const deliveriesInTrailer = trailerL.list[trailerUsed].pendingDelivery;
        let stillPendingDeliveries: { [key: string]: { pickedFrom: string; } };
        if (!!alreadyDeliveredTrackingNos) {
          stillPendingDeliveries = omitBy(deliveriesInTrailer, (value, key) =>
            alreadyDeliveredTrackingNos.includes(key));
        } else { stillPendingDeliveries = trailerL.list[trailerUsed].pendingDelivery; }

        return stillPendingDeliveries;
        // return trailerL.list[trailerUsed].pendingDelivery;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }
  /** sets arrival time for trip transaction based on routeResponse(includes traffic time) and HOS rules */
  estRouteWithTraffic(useHOS: boolean) {     // useHOS: boolean = true
    if (!this.routeResponse) {
      return;
    }
    for (let i = 0; i < this.tripSequence.length - 1; i++) {
      if (this.transactionBlocked(i + 1)) {
        continue;
      }
      const curr = this.tripSequence[i + 1];
      const prev = this.tripSequence[i];
      const from = this.mapLabels[i];
      const to = this.mapLabels[i + 1];
      const s = this.getDictonaryKey([from, to]);
      if (!!s && !!this.routeResponse[s]) {
        // const r = this.routeResponse[s].route[0];
        // curr.distance = !!r ? r.routeLegs[0].summary.distance : null;
        // curr.drvDuration = !!r ? r.routeLegs[0].summary.timeWithTraffic / 3600 : null;
        const r = this.routeResponse[s];
        curr.distance = !!r ? r.travelDistance : null;
        curr.drvDuration = !!r ? r.travelDurationTraffic / 3600 : null;
        // this.hosRules(i + 1, curr.drvDuration);
        const h = !!useHOS ? HOS.getHosDurations(prev, curr) : null;
        curr.hos = !!h ? {
          offDuty: h.offDuty, balanceDrivingTime: h.drvSinceBreak, commDrivingTime: h.drvSinceReset, isReset: h.isReset
        } : { offDuty: 0, balanceDrivingTime: 0, commDrivingTime: 0, isReset: false };
        // previous appt time + dwell duration + travelling time will defined the
        // estimated time of arrival at current location.
        if (prev.transactionStatus !== TransactionStatus.autoCompleted) {
          curr.arrivalTime = new Date(prev.apptDateTimeCalc);
        } else {
          curr.arrivalTime = new Date(prev.autoCompleteTime.leftAt);
        }
        if (!!curr.hos) {
          curr.arrivalTime.setSeconds(curr.arrivalTime.getSeconds() + Math.round((prev.dwellDuration + curr.drvDuration) * 3600)
            + curr.hos.offDuty * 3600);
        } else {
          curr.arrivalTime.setSeconds(curr.arrivalTime.getSeconds() + Math.round((prev.dwellDuration + curr.drvDuration) * 3600));
        }
        // added to set the arrivalTimeBrowser based on the time zone
        curr.sanitize();
      }
    }
  }
  /**
   * Set the route response from the map service
   * @param r RouteResponse
   */
  setRouteResponse(r: RouteResponse) {

    let s: string = null;
    if (isEmpty(pickBy(this.routeResponse, (value) => value === r))) {
      s = this.getDictonaryKey(r.mapLabels);
      if (!!s) {
        this.routeResponse[s] = r;
      }
      logger.log('routeResponse', this.routeResponse);
    }
  }
  /** when transaction is deleted remove the key from routeResponse */
  resetRouteResponse() {
    if (this.mapLabels.length <= 1) {
      this.routeResponse = {};
    } else {
      for (let i = 0; i < this.mapLabels.length - 1; i++) {
        const from = this.mapLabels[i];
        const to = this.mapLabels[i + 1];
        const s = this.getDictonaryKey([from, to]);
        this.routeResponse = omitBy(this.routeResponse, (value, key) => key !== s);
      }
    }
  }
  /**  */
  getDictonaryKey(mapLabels: MapLabel[]) {
    let s: string = null;
    for (let i = 0; i < mapLabels.length; i++) {
      const from = mapLabels[i];
      const to = mapLabels[mapLabels.length - 1];
      if (!!from && !!to) {
        const fromIx = from.index;
        const toIx = to.index;

        if (i === 0) { s = `${fromIx}-`; } else {
          s = i < mapLabels.length - 1 ? s + `${fromIx}-` : s + `${toIx}`;
        }
      }
    }
    return s;
  }
  /** transaction block for change when curr and or higher transaction's  TransactionStatus is arrived or higher
   * return true when blocked
   * @param i Trasnaction Index
   */
  transactionBlocked(i: number) {
    const seqSlice = this.tripSequence.slice(i, this.tripSequence.length + 1);
    if (seqSlice.findIndex(f => f.transactionStatus >= TransactionStatus.arrived) !== -1) {
      return true;
    } else {
      return false;
    }
  }
  /** compare two transations and returns true if equal
   * @param before TripTransaction
   * @param after TripTransaction
   */
  compareTrans(before: TripTransaction, after: TripTransaction) {
    let b = instanceToPlain(parseTripTransaction(before));
    let a = instanceToPlain(parseTripTransaction(after));
    b = JSON.parse(JSON.stringify(b));
    a = JSON.parse(JSON.stringify(a));
    // set arrival time same
    if ((<TripTransaction>b).arrivalTime) {
      (<TripTransaction>a).arrivalTime = (<TripTransaction>b).arrivalTime;
    }
    const bool = isEqual(b, a);
    return bool;
  }
  /**
   * compares the updated transaction with existing and returns if new route is required
   * @param index current index
   * @param uTrans updated trasnaction
   */
  isRouteReq(index: number, uTrans: TripTransaction): boolean {
    const oTrans = this.tripSequence[index];
    let bool = false;
    if (!uTrans.address.geoLoc || !isEqual(uTrans.address.geoLoc, oTrans.address.geoLoc)) {
      bool = true;
    }
    if (!isEqualD(uTrans.arrivalTime, oTrans.arrivalTime)) {
      bool = true;
    }
    if (uTrans.dwellDuration !== oTrans.dwellDuration) {
      bool = true;
    }
    return bool;
  }
  getIsBroderCrossingAvialable(i: number): boolean {
    if (1 > 0 && this.tripSequence[i] && this.tripSequence[i].activity !== TripActivityType.wayPoint) {
      return false;
    }
    const before = !!this.tripSequence[i - 1] ? this.tripSequence[i - 1].address.country : null;
    const after = !!this.tripSequence[i + 1] ? this.tripSequence[i + 1].address.country : null;
    if (!!before && !!after && before !== after) {
      return true;
    } else {
      return false;
    }
  }
  onRouteBorder(borders: BorderList) {
    for (const key in this.routeResponse) {
      if (this.routeResponse.hasOwnProperty(key)) {
        if (key.split('-').length === 2) {
          const ele = this.routeResponse[key];
          if (ele.mapLabels.length === 2) {
            const from = ele.mapLabels[0];
            const to = ele.mapLabels[1];
            if (from.country !== 'border' && to.country !== 'border' && from.country !== to.country) {
              const canadianLoc = from.country === 'CA' ? from : to.country === 'CA' ? to : null;
              const filteredBorders = borders.getBrorderInProv(canadianLoc.stateProv);
              const iPoints: IPoint[] = [];
              for (const itr of ele.itineraryItems) {
                const iLat = itr.maneuverPoint.coordinates[0];
                const iLong = itr.maneuverPoint.coordinates[1];
                iPoints.push({ latitude: iLat, longitude: iLong });
              }
              if (!this.borderUsed) { this.borderUsed = {}; }
              this.borderUsed[key] = borders.findBorderOnRoute(filteredBorders, iPoints);
            }
          }
        }
      }
    }
    // logger.log('borderUsed', this.borderUsed);
  }
  /** get delivery by tracking number. returns matching delivery and index
   * @param t: Tracking number
   */
  getDeliveryByTrackingNo(t: string): { d: Delivery, i: number } {
    const d = this.tripSequence.find(f => f.activity === TripActivityType.delivery
      && Object.keys(f.delTrackingNos).includes(t)) as Delivery;
    const i = this.tripSequence.findIndex(f => f.activity === TripActivityType.delivery
      && Object.keys(f.delTrackingNos).includes(t));
    if (i === -1) {
      return null;
    }
    return { i, d };
  }
  isRouteReady(): boolean {
    if (!this.routeResponse || isEmpty(this.routeResponse)) {
      return false;
    }
    for (let i = 0; i < this.tripSequence.length - 1; i++) {
      const from = this.mapLabels[i];
      const to = this.mapLabels[i + 1];
      const s = this.getDictonaryKey([from, to]);
      if (!this.routeResponse[s] || isEmpty(this.routeResponse[s])) {
        return false;
      }
    }
    return true;
  }
  /**
 * based in email returns user id for logged in users email for anonomus users
 * @param email email (key)
 * @param tripTrackerL TripTrackerList
 */
  getDriversIdOrPhone(phone: string, driverL: DriverList): string {
    let driverIdOrPhone: string;
    const activeDrivers = pickBy(driverL.listForUI, (value) => value.itemStatus !== ItemStatus.inactive);
    if (!!driverL && !!phone && !!Object.keys(activeDrivers).includes(phone)) {
      if (!!activeDrivers[phone].driverId) {
        driverIdOrPhone = activeDrivers[phone].driverId;
      } else {
        driverIdOrPhone = phone;
      }
    }
    return driverIdOrPhone;
  }
  copyTrip(truckL: VehicleList, trailerL: VehicleList, driverL: DriverList): TripBase {
    const c = this.clone();
    c.id = null;
    c.tripStatus = null;
    c.driverIdOrPhone = Object.keys(pickBy(driverL.list, (value) => value.isPlaceHolder))[0];
    c.tripSequence[0].arrivalTimeBrowser = addHours(new Date(), 24);
    const diff = differenceInMinutes(c.tripSequence[0].arrivalTimeBrowser, this.tripSequence[0].arrivalTimeBrowser);

    for (let index = 0; index < c.tripSequence.length; index++) {
      const a = c.tripSequence[index];
      if (index > 0) {
        a.arrivalTimeBrowser = addMinutes(a.arrivalTimeBrowser, diff);
      }
      a.apptDateTime = null;
      a.isApptApp = false;
      a.autoCompleteTime = null;
      a.transactionStatus = null;
      a.referenceNos = null;
      a.eta = null;
      a.pod = null;
      a.gpsData = null;
      a.locStaticMap = null;
      if (a.activity === TripActivityType.hookUp) {
        a.truck = Object.keys(pickBy(truckL.list, (value) => value.isPlaceHolder))[0];
        a.trailer = Object.keys(pickBy(trailerL.list, (value) => value.isPlaceHolder))[0];
      }
      if (a.trackingNos) {
        a.trackingNos = [];
        this.tripSequence[index].trackingNos.forEach(() => {
          a.addTrackingNo();
        });
      }
      if (a.activity === TripActivityType.drop) {
        a.truck = Object.keys(pickBy(truckL.list, (value) => value.isPlaceHolder))[0];
        a.trailer = Object.keys(pickBy(trailerL.list, (value) => value.isPlaceHolder))[0];
      }
    }

    // set delivery Tracking numbers 
    for (let j = 0; j < c.tripSequence.length; j++) {
      const b = c.tripSequence[j];
      if (b.delTrackingNos) {
        for (const key in b.delTrackingNos) {
          if (b.delTrackingNos.hasOwnProperty(key)) {
            // from copyfrom trip find the pickIndex which had related delivery tno
            const px = this.tripSequence.findIndex(f => f.trackingNos && f.trackingNos.includes(key));
            // tno is array for pickup find related index of the tno being eveluated
            const tx = this.tripSequence[px].trackingNos.findIndex(f => f === key);
            // link delivery to pick with trasaction index px, link
            if (px !== -1 && px < j) {
              b.delTrackingNos[c.tripSequence[px].trackingNos[tx]] = c.openPickupTrackingNos[c.tripSequence[px].trackingNos[tx]];
              // b.delTrackingNos[c.tripSequence[px].trackingNos[tx]] = c.getOpenTrackingNos(j)[c.tripSequence[px].trackingNos[tx]];
            }

          }
        }
        b.delTrackingNos = pickBy(b.delTrackingNos, (value, key) => !this.allDeliveryTNos.includes(key));
      }
    }

    return c;
  }
  get tripDescription(): string {
    if (this.tripSequence.length === 0) {
      return null;
    }
    const rIndex = this.tripSequence.findIndex(f => f.address.geoLoc?.geohash === this.returnDestination.geoLoc?.geohash);
    const d = [this.returnDestination.city];
    const lastIndex = this.isOneWay ? this.tripSequence.length - 1 : this.tripSequence.length - 2;
    for (let index = 1; index < lastIndex + 1; index++) {
      const e = this.tripSequence[index];
      if (index < rIndex && (e.activity === TripActivityType.pickUp || e.activity === TripActivityType.delivery) && d.length < 3) {
        d.unshift(e.address.city);
        continue;
      }
      if (index > rIndex && (e.activity === TripActivityType.pickUp || e.activity === TripActivityType.delivery) && d.length < 3) {
        d.push(e.address.city);
        continue;
      }
    }
    return d.toString().replace(/,/gi, '-');
  }
  /** gets truck/trailer used in transaction  */
  getCurrentTruckTrailer(index: number, listType: PromoListType): string {
    if (this.tripSequence[index].activity === TripActivityType.drop) {
      return null;
    }
    const revereseSeq = this.tripSequence.slice(0, index + 1).reverse();
    const h = revereseSeq.find(f => f.activity === TripActivityType.hookUp);
    switch (listType) {
      case PromoListType.promoTruck:
        return h.truck;
      case PromoListType.promoTrailer:
        return h.trailer;
      default:
        return null;
    }
  }
  /** gets addres of the last hook activity
   * @param index |Trip sequence index
    */
  getLastHookAddress(index: number): Address {
    if (this.tripSequence[index].activity === TripActivityType.drop) {
      return null;
    }
    const revereseSeq = this.tripSequence.slice(0, index).reverse();
    const h = revereseSeq.find(f => f.activity === TripActivityType.hookUp);
    return h.address;
  }
  /** get truck trailer released after drop is completed
   * @param oTrip | Original Trip (downloaded from db)
   * @param this | Updated Trip (updated by gps location service or manually completed)
   */
  vehiclesReleased(oTrip: TripBase, listType: PromoListType): string[] {
    const vReleased = [];
    for (let i = 0; i < this.tripSequence.length; i++) {
      const uT = this.tripSequence[i];
      // find match drop in the oginal trip
      const oDrop = oTrip.tripSequence.find(f =>
        f.activity === TripActivityType.drop && f.address.geoLoc.geohash === uT.address.geoLoc.geohash);
      // tslint:disable-next-line:max-line-length
      if (uT.activity === TripActivityType.drop && uT.transactionStatus >= TransactionStatus.arrived && oDrop.transactionStatus <= uT.transactionStatus) {
        switch (listType) {
          case PromoListType.promoTruck:
            const nextHookTk = this.tripSequence.find((f, j) => f.activity === TripActivityType.hookUp && uT.truck === f.truck && j > i);
            if (!nextHookTk) {
              vReleased.push(uT.truck);
            }
            break;
          case PromoListType.promoTrailer:
            const nextHookTl = this.tripSequence.find((f, j) =>
              f.activity === TripActivityType.hookUp && uT.trailer === f.trailer && j > i);
            if (!nextHookTl) {
              vReleased.push(uT.trailer);
            }
            break;

          default:
            break;
        }
      }
    }
    return vReleased;
  }
  /**
   * compares trip to orignal trip return trailers number when pending delivery is cleared
   * Fn is called when trip is updated by gps or mannual completed and no other changes
   * @param oTrip Orginal Trip from dB
   */
  pendingDeliveryCleared(oTrip: TripBase): string[] {
    const c: string[] = [];
    for (let i = 0; i < this.tripSequence.length; i++) {
      const uT = this.tripSequence[i];
      if (!uT.delTrackingNos || isEmpty(uT.delTrackingNos)) {
        continue;
      }
      const oT = oTrip.tripSequence.find(f => f.activity === uT.activity && f.address.geoLoc.geohash === uT.address.geoLoc.geohash);
      if (uT.transactionStatus >= TransactionStatus.autoCompleted && oT.transactionStatus < TransactionStatus.autoCompleted) {
        const t = this.getCurrentTruckTrailer(i, PromoListType.promoTrailer);
        if (!!this.pendingDeliveriesPreviousTrip && Object.keys(this.pendingDeliveriesPreviousTrip).includes(t)) {
          c.push(this.getCurrentTruckTrailer(i, PromoListType.promoTrailer));
        }
      }
    }
    return c;
  }
  isTrackingOn() {
    if (this.tripStatus === TripStatus.assigned
      && (this.gpsStatus?.isBroadCasting || true && differenceInMinutes(new Date(), this.gpsStatus?.updatedAt) < 30)) {
      return true;
    }
    return false;
  }
  getOpenTrackingNumbers(currIndex: number): { [key: string]: { pickedFrom: string } } {
    const d = this.tripSequence.filter(f => !isEmpty(f.delTrackingNos));
    let deliveryTs: string[] = [];
    d.forEach(e => deliveryTs = deliveryTs.concat(Object.keys(e.delTrackingNos)));
    const o: { [key: string]: { pickedFrom: string } } = {};

    deliveryTs.forEach(e => {
      const p = this.tripSequence.find((g, j) => !!g.trackingNos && g.trackingNos.includes(e) && j < currIndex);
      p.trackingNos.forEach(h => {
        o[h] = { pickedFrom: `${p.address.addressFormattedL1}, ${p.address.city}` };
      });
    });
    return o;
  }
  getAffectedDeliveries2(trackingNo: string): { affDel: TripTransaction, index: number } {
    const affDel = this.tripSequence.find(f => f.delTrackingNos && Object.keys(f.delTrackingNos).includes(trackingNo));
    const index = this.tripSequence.findIndex(f => f.delTrackingNos && Object.keys(f.delTrackingNos).includes(trackingNo));
    return { affDel, index };

  }
  get openPickupTrackingNos(): { [key: string]: { pickedFrom: string, pickupTrailer: string, pickedAt: Date, pickpIndex: number } } {
    /** delvery Tracking numbers*/
    let deliveryTs: string[] = [];
    for (let j = 0; j < this.tripSequence.length; j++) {
      const g = this.tripSequence[j];
      if (g.delTrackingNos) {
        deliveryTs = deliveryTs.concat(Object.keys(g.delTrackingNos));
      }

    }
    const o: { [key: string]: { pickedFrom: string, pickupTrailer: string, pickedAt: Date, pickpIndex: number } } = {};

    for (let i = 0; i < this.tripSequence.length; i++) {
      const ele = this.tripSequence[i];
      if (!!ele.trackingNos) {
        ele.trackingNos.forEach(t => {
          if (!deliveryTs.includes(t)) {
            const pickupTrailer = this.getCurrentTruckTrailer(i, PromoListType.promoTrailer);
            // const pickupTrailer = this.getLastHook(i).lastHookTrailer;
            o[t] = {
              pickedFrom: `${ele.address.addressFormattedL1}, ${ele.address.city}`,
              pickupTrailer: pickupTrailer, pickedAt: ele.arrivalTime, pickpIndex: i
            };
          }
        });
      }
    }
    return o;
  }
  /** tracking number which are already in trailer when hooked and are not delivered in the trip, Trailer is moved from Terminal A to B */
  get trackingNosTrailerMove(): { [key: string]: { pickedFrom: string, pickupTrailer: string, pickedAt: Date } } {
    const t: { [key: string]: { pickedFrom: string, pickupTrailer: string, pickedAt: Date } } = {};
    const a = Object.keys(!!this.pendingDeliveriesPreviousTrip ? this.pendingDeliveriesPreviousTrip : {});
    const b = Object.keys(!!this.openPickupTrackingNos ? this.openPickupTrackingNos : {});
    a.forEach(e => {
      if (b.includes(e)) {
        t[e] = this.pendingDeliveriesPreviousTrip[e];
      }
    });
    return t;
  }
  /** returns updated tracking numbers by comparing updated trip to orginal trip
   * @param oTrip(original trip from dB)
   * @param this (updated trip)
   *   */
  getTrackingNosForUpdatedTrans(oTrip: TripBase): string[] {
    let r: string[] = [];
    for (let i = 0; i < this.tripSequence.length; i++) {
      const uT = instanceToPlain(cleanupUndefined(this.tripSequence[i]));
      const oT = instanceToPlain(cleanupUndefined(oTrip.tripSequence[i]));
      if (!isEqual(uT, oT)) {
        if ((<Pickup>uT).trackingNos) {
          const p = (<Pickup>uT).trackingNos.filter(f => !r.includes(f));
          r = r.concat(p);
        }
        if ((<Delivery>uT).delTrackingNos) {
          const d = Object.keys((<Delivery>uT).delTrackingNos).filter(f => !r.includes(f));
          r = r.concat(d);
        }
      }
    }
    return r;
  }
  /** returns added, updated, deleted, noChnage tracking numbers by comparing updated trip to orginal trip
   * @param oTrip(original trip from dB)
   * @param this (updated trip)
   *   */
  getTrackingNosAddedUpdatedDeleted(oTrip: TripBase): { added: string[], updated: string[], deleted: string[], noChange: string[] } {
    const oTrackingNumbers = oTrip.tripStatus > TripStatus.created ? oTrip.trackingNumbers : [];
    const added: string[] = this.trackingNumbers.filter(f => !oTrackingNumbers.includes(f));
    const deleted: string[] = oTrackingNumbers.filter(f => !this.trackingNumbers.includes(f));
    const updated: string[] = [];
    const noChange: string[] = [];
    if (oTrip.tripStatus > TripStatus.created) {
      for (const t of this.trackingNumbers) {
        let uP = this.tripSequence.find(f => f.trackingNos && f.trackingNos.includes(t));
        let oP = oTrip.tripSequence.find(f => f.trackingNos && f.trackingNos.includes(t));
        uP = <TripTransaction>instanceToPlain(cleanupUndefined(uP));
        oP = <TripTransaction>instanceToPlain(cleanupUndefined(oP));
        let uD = this.tripSequence.find(f => f.delTrackingNos && Object.keys(f.delTrackingNos).includes(t));
        let oD = oTrip.tripSequence.find(f => f.delTrackingNos && Object.keys(f.delTrackingNos).includes(t));
        uD = <TripTransaction>instanceToPlain(cleanupUndefined(uD));
        oD = <TripTransaction>instanceToPlain(cleanupUndefined(oD));
        if (!isEqual(uP, oP) || !isEqual(uD, oD)) {
          updated.push(t);
        } else {
          noChange.push(t);
        }
      }
    }
    return { added, updated, deleted, noChange };
  }

  trackingNumbersAfterIndex(index: number) {
    if (index === this.tripSequence.length - 1) {
      return [];
    }
    let t = [];
    const seqSlice = this.tripSequence.slice(index + 1);
    seqSlice.forEach(ele => {
      if (ele.trackingNos) {
        t = t.concat(ele.trackingNos);
      }
    });
    logger.log('trackingNumbersAfterIndex', t);
    return t;
  }
  expireTrip() {
    this.tripStatus = TripStatus.expired;
    for (const trans of this.tripSequence) {
      trans.transactionStatus = TransactionStatus.cancelled;
    }
  }
  /** return array of vehicles dropped in a trip
   * @param v | Vehicle Type
   */
  vehiclesDropped(v: PromoListType.promoTruck | PromoListType.promoTrailer) {
    const drops = this.tripSequence.filter(f => f.activity === TripActivityType.drop); // add at trip root for db search
    const t = [];
    switch (v) {
      case PromoListType.promoTruck:
        drops.forEach(h => { t.push(h.truck); }); // add at trip root for db search

        break;
      case PromoListType.promoTrailer:
        drops.forEach(h => { t.push(h.trailer); }); // add at trip root for db search

        break;

      default:
        logger.error('programming error, unreachable code');

        break;
    }
    return t;
  }
  /** return next Drop based on the trip sequence index @param index | Trip Sequence Index */
  nextDrop(index: number): TripTransaction {
    return this.tripSequence.slice(index).find(f => f.activity === TripActivityType.drop);
  }
}
