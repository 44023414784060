import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { logger } from '@trent/models/log/logger';
import { DelegateInspectionService } from '@trent/services/delegate-inspection.service';

@Component({
  selector: 'trent-unsubscribe-promotion',
  templateUrl: './unsubscribe-promotion.component.html',
  styleUrls: ['./unsubscribe-promotion.component.scss']
})
export class UnsubscribePromotionComponent implements OnInit {

  promotionLeadId : string;

  constructor(private router : Router, private delegateInspectionService : DelegateInspectionService, public aroute: ActivatedRoute) { }
  
  ngOnInit() {
    this.promotionLeadId = this.aroute.snapshot.params.lid;
    logger.log(this.promotionLeadId);
    this.updateUnSubscribeStatus();
  }

  updateUnSubscribeStatus() {
    //this.showLoading(10);
    const prom = this.delegateInspectionService.updateUnSubscribeStatus(this.promotionLeadId).toPromise();
    prom.then((r) => {
      //this.hideLoading();
    })
    .catch(err => {
      // this.hideLoading();
      // this.showAlert(readErrorMessage(err));
    });
  }

}
