import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AuthState } from './auth.state';
import { NgxsModule } from '@ngxs/store';

@NgModule({
  imports: [
    CommonModule,
    NgxsModule.forFeature([AuthState])
  ],
  providers: [
  ]
})
export class AuthStoreModule { }
