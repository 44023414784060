import { isNil } from 'lodash';
import { differenceInMinutes, format, addMinutes, differenceInHours, addHours } from 'date-fns';
import * as moment from 'moment-timezone';

/**
 * return date as string, Jul 23, 2020 8:52 AM
 * @param d Date
 */
export const shortDateTime = (d: Date) => {
  if (!!d) {
    return format(d, 'MMM dd, yyyy h:mm aaaa');
  } else {
    return null;
  }
};
/**
 * return date as string, Jul 23, 2020
 * @param d Date
 */
export const shortDate = (d: Date) => {
  if (!!d) {
    return format(d, 'MMM dd, yyyy');
  } else {
    return null;
  }
};

/** Start of the day, UTC Format */
export const estStartOfDay = (d?: Date): Date => {
  if (isNil(d)) {
    d = new Date();
  }
  // const utcStartOfDay = new Date(Date.UTC(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate()));
  const zonedStartOfDay = moment(d).tz('America/New_York');

  // const estR = addMinutes(utcStartOfDay, 0); //480); 
  return addHours(zonedStartOfDay.toDate(), 5);
};
/** Start of the day, UTC Format */
export const fixTimeOffset = (from: Date, to: Date):  Date => {
    const fromM = moment(from).tz('America/New_York');
    const toM = moment(to).tz('America/New_York');
    // const fromOffsetMinutes = moment.tz(from, 'America/New_York').utcOffset();
    // const toOffsetMinutes = moment.tz(to, 'America/New_York').utcOffset();
    let toUpdate = to;
    if(fromM.utcOffset() > toM.utcOffset()) {
      toUpdate = addHours(to, 1);
    } else if(fromM.utcOffset() < toM.utcOffset()) {
      toUpdate = addHours(to, -1);
    }
    return toUpdate;

  };
export const checkOffset = (from: Date, to: Date):  boolean => {
    const fromM = moment(from).tz('America/New_York');
    const toM = moment(to).tz('America/New_York');
    // const fromOffsetMinutes = moment.tz(from, 'America/New_York').utcOffset();
    // const toOffsetMinutes = moment.tz(to, 'America/New_York').utcOffset();
    if(fromM.utcOffset() === toM.utcOffset()) {
      return true;
    } 
    return false;

  };

export const expiresIn = (expiry: Date) => {
  const d = differenceInHours(expiry, new Date());
  const s1 = Math.round(d / 24).toString() + (Math.round(d / 24) > 1 ? ' days ' : ' day ');
  const s2 = (d % 24) > 0 ? (d % 24).toString() + (d % 24 > 1 ? ' hours' : ' hour') : '';
  return s1 + s2;
};

export class TimerHelper {

  /** if first first call to function should be made before setting up the interval. */
  public tryTimeZero = false;

  private nMax = 10;

  private interval = 100;

  private counter = 0;

  /** If counter is at its last value before interval will be cleared. */
  get isLastCall() { return this.counter === this.nMax; }

  private timeOutHandle;

  /** return time difference string in hours and minutes in 5 minute increments. 
   * @param dL: Date Later
   * @param dB: Date Before
   * @example     dB = new Date(2020, 1, 1); dL = addMinutes(dBefore, 53); returns 53 minutes
    */
  public static hourMinutesDifferenceString(dL: Date, dB: Date): string {
    let diffString: string;
    if (!dL || !dB) { return null; }

    const diff = differenceInMinutes(dL, dB);
    if (!diff || diff < 0) {
      return null;
    }
    const h = Math.floor(diff / 60);
    let m = Math.floor(diff % 60);
    m = Math.floor(m / 5) * 5;
    let hS: string; // hours string
    let mS: string; // minutes string
    if (h > 1) {
      hS = `${h} hours`;
    } else if (h === 1) {
      hS = `${h} hour`;
    } else {
      hS = null;
    }
    if (m > 1) {
      mS = `${m} mins`;
    } else if (m === 1) {
      mS = `${m} min`;
    } else {
      mS = null;
    }
    if (!!hS && !!mS) { diffString = `${hS} ${mS}`; }
    if (!!hS && !mS) { diffString = `${hS}`; }
    if (!hS && !!mS) { diffString = `${mS}`; }
    return diffString;
  }
  /** converts hours to hours and mins 0.5 converts 0h 30m */
  public static hoursToHrsMinsString(durationHours: number) {
    const duration = durationHours;
    if (!isNaN(duration)) {
      const h = Math.floor(duration);
      const m = Math.round((duration - h) * 60);
      if (m === 0) {
        return `${h}h`;
      } else {
        return `${h}h ${m}m`;
      }
    }
    return '';
  }
  public clearInterval() {
    if (!!this.timeOutHandle) {
      clearInterval(this.timeOutHandle);
      this.timeOutHandle = null;
    }
  }

  /**
   * @param interval : callback function frequency duration.
   * @param nMax : How many times the said function should be called.
   *  note: function will be termnated retgardless if callback return true.
   * @param callBack : function to be called every time.
   */
  setInterval(interval: number, nMax: number, callBack: () => boolean | Promise<boolean>) {

    /** try first call to function if user wants to try it. */
    if (this.tryTimeZero) {
      ++this.counter;
      if (callBack()) {
        return;
      }
    }
    this.interval = interval;
    this.nMax = nMax;

    this.timeOutHandle = setInterval(() => {
      // logger.log('set interval call execute, Counter: ', this.counter);
      ++this.counter;
      if (this.counter > this.nMax || callBack()) {
        // logger.log('limit exeeded, cancelling it.');
        clearTimeout(this.timeOutHandle);
      }
    }, this.interval);
  }
}
