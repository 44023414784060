import { ProductType } from '../product/product-type';
import { DbStatus } from '../base/db-status';
import { BidStatus } from './bid-status';
import { IKeyVal, getObjKey, getDirection, Paging } from '../observable-util/paging';
import { DeepCopy } from '../utility/deep-copy';
import { BidBase } from './bid-base';
import { Author } from './author';
import { isAfter, isBefore } from 'date-fns';
import { DbRule } from '../base/db-rule';
import { CollectionReference, limit, orderBy, query, Query, QueryConstraint, startAfter, where } from 'firebase/firestore';
import { isEmpty } from 'lodash';
import { TruckSummary } from '../product';
import { getProp } from '../utility/helper';


export interface BidParam {
  type?: ProductType;

  /** Parent Companies to be searched */
  cids?: string[];

  orderBy?: string;

  dbStatus?: DbStatus;

  bidStatus?: BidStatus;

  createdAt?: Date;

  rid?: string | number;

  openBids?: boolean;
  /** client only filter to filter expired bids which are not expired by cron function,
   *  where client request falls last cron run and expiry date time
   * (to be matched by server validation, to updates to expired bids*/
  filterOutExpired?: boolean;  //

  queryBy?: Author;

  bidIdForHistory?: string | number;

  author?: Author;

  maxRev?: number;

  vendorName?: string;
  /** vendor company id */
  vCid?: string;
  customerName?: string;
  /** customer company id */
  cCid?: string;
  /** createdBy Used to quary draft bids created by user who does not have company */
  createdByUid?: string;

  endDate?: Date;
  endDateClient?: Date;

  pid?: string;

  contractNumber?: number;

  vin?: string | number; // PT - added vin param to search the contracts

  creditUserId?: string | number; // Credit User Id

  salesPersonId?: string | number;

  minContract?: number

}
export const bidSearchOrderBy: IKeyVal[] = [
  // { key: 'Name', value: 'unitName' },
  // { key: 'bidStatus', value: `2` }, // waitingForCustomer = 2, // Counter Offer waiting for Customer Acceptance;
  { key: 'createdAt to Date', value: `createdAt desc` },
  // { key: 'Make', value: 'make' },
  // { key: 'Model year', value: 'modelYear desc' },
];
export const bidParamInit = (): BidParam => {
  return {
    type: <any>'*',
    cids: [],
    orderBy: 'createdAt',
    // dbStatus: DbStatus.Released,
    // bidStatus: <any>'*'
  };
};
/** Get the OR condition options. if there are more then one company id's are provided
 *  each cid makes up a unique OR condition query. */
export const getBidSearchOptOrChildren = (o: BidParam): { [key: string]: BidParam } => {
  if (!!o.cids && o.cids.length > 1) {
    const r: { [key: string]: BidParam } = {};
    o.cids.forEach((cid) => {
      const c = DeepCopy.copy(o);
      c.cids = [cid];
      r[getObjKey(c)] = c;
    });
    return r;
  }
  return undefined;
};
/** Server filtering of firebase query building of query  */
export const bidSearchServerQuery = (ref: CollectionReference, o: BidParam, p: Paging) => {
  const cons: QueryConstraint[] = [];
  const dbStatus = DbStatus;
  if (!!o.dbStatus) {
    cons.push(where('dbStatus', '==', o.dbStatus));
  }
  if (!!o.type && o.type !== <any>'*') {
    cons.push(where('productSummary.productType', '==', +o.type));
  }
  // if (o.cids?.length > 0) {
  //   console.log('o.cids[0]', o.cids[0]);
  //   if (o.queryBy === Author.vendor) {
  //     cons.push(where('vendorCompSummary.cid', '==', o.cids[0]);
  //   } else if (o.queryBy === Author.customer) {
  //     cons.push(where('customerCompSummary.cid', '==', o.cids[0]);
  //   }
  // }
  if (!!o.vCid) {
    cons.push(where('vendorCompSummary.cid', '==', o.vCid));
  }
  if (!!o.cCid) {
    cons.push(where('customerCompSummary.cid', '==', o.cCid));
  }
  if (!!o.pid) {
    cons.push(where('productSummary.pid', '==', o.pid));
  }
  if (!!o.createdByUid) {
    cons.push(where('createdByUid', '==', o.createdByUid));
    cons.push(where('dbStatus', '==', DbStatus.Initial));
  }
  if (o.hasOwnProperty('bidStatus') && o.bidStatus !== <any>'*') {
    cons.push(where('bidStatus', '==', +o.bidStatus));
  }
  if (!!o.rid) {
    cons.push(where('rentSummary.rid', '==', o.rid));
  }
  if (!!o.openBids) {
    // tslint:disable-next-line:max-line-length
    cons.push(where('bidStatus', '>', 0));
    cons.push(where('bidStatus', '<', 4));  // open bids BidStatus.waitingForVendor = 1 waitingForCustomer = 2 BidStatus.accepted = 3,
  }
  if (!!o.createdAt) {
    // tslint:disable-next-line:max-line-length
    cons.push(where('createdAt', '>', o.createdAt));  // open bids BidStatus.waitingForVendor = 1 waitingForCustomer = 2 BidStatus.accepted = 3,
  }
  if (!!o.endDate) {
    // tslint:disable-next-line:max-line-length
    cons.push(where('bidNegoTerms.endDate', '>', o.endDate));  // open bids BidStatus.waitingForVendor = 1 waitingForCustomer = 2 BidStatus.accepted = 3,
  }
  if (!!o.contractNumber) {
    cons.push(where('contractNo.number', '==', o.contractNumber));  // open bids BidStatus.waitingForVendor = 1 waitingForCustomer = 2 BidStatus.accepted = 3,
  }

  // PT - to filter the products using VIN
  if (!!o.vin) {
    cons.push(where('productSummary.vin', '==', o.vin));
  }
  if(!!o.salesPersonId){
    cons.push(where('customerAuthIds', 'array-contains', o.salesPersonId));
  }

  // KS - for Credit User
  if(!!o.creditUserId){ 
    cons.push(where('vendorAuthIds', 'array-contains', o.creditUserId)); 
  }

  if (!!o.orderBy ) {
    const { col, dir } = getDirection(bidSearchOrderBy, (!!o.orderBy) ? o.orderBy : 'createdAt desc');
    console.log('col', col);

    console.log('dir', dir);

    cons.push(orderBy(col, dir));
    if (!!p.lastDoc) {
      console.log('last doc: ', getProp(p.lastDoc, col));
      cons.push(startAfter(getProp(p.lastDoc, col)));
    }
  }

  // for Bid History
  if (!!o.author) {
    cons.push(where(`bidNegoTerms.author`, '==', o.author));
  }
  if (!!o.maxRev) {
    cons.push(where('revId', '<', o.maxRev));  // open bids BidStatus.waitingForVendor = 1 waitingForCustomer = 2 BidStatus.accepted = 3,
  }
  if (o.orderBy === 'revId desc') {
    const { col, dir } = getDirection(bidSearchOrderBy, (!!o.orderBy) ? o.orderBy : 'revId desc');
    cons.push(orderBy(col, dir));
    if (!!p.lastDoc) {
      console.log('last doc: ', getProp(p.lastDoc, col));
      cons.push(startAfter(getProp(p.lastDoc, col)));
    }
  }
  // if (o.orderBy === 'contractNo.number desc') {
  //   const { col, dir } = getDirection(bidSearchOrderBy, (!!o.orderBy) ? o.orderBy : 'contractNo.number desc');
  //   cons.push(orderBy(col, dir));
  //   if (!!p.lastDoc) {
  //     console.log('last doc: ', p.lastDoc[col]);
  //     cons.push(startAfter(p.lastDoc[col]));
  //   }
  // }
  cons.push(limit(p.size));
  return query(ref, ...cons);
};


export const bidSearchServerCountQuery = (ref: CollectionReference, o: BidParam) => {

  const cons: QueryConstraint[] = [];
  const dbStatus = DbStatus;
  if (!!o.dbStatus) {
    cons.push(where('dbStatus', '==', o.dbStatus));
  }
  if (!!o.type && o.type !== <any>'*') {
    cons.push(where('productSummary.productType', '==', +o.type));
  }
  // if (o.cids?.length > 0) {
  //   console.log('o.cids[0]', o.cids[0]);
  //   if (o.queryBy === Author.vendor) {
  //     cons.push(where('vendorCompSummary.cid', '==', o.cids[0]);
  //   } else if (o.queryBy === Author.customer) {
  //     cons.push(where('customerCompSummary.cid', '==', o.cids[0]);
  //   }
  // }
  if (!!o.vCid) {
    cons.push(where('vendorCompSummary.cid', '==', o.vCid));
  }
  if (!!o.cCid) {
    cons.push(where('customerCompSummary.cid', '==', o.cCid));
  }
  if (!!o.pid) {
    cons.push(where('productSummary.pid', '==', o.pid));
  }
  if (!!o.createdByUid) {
    cons.push(where('createdByUid', '==', o.createdByUid));
    cons.push(where('dbStatus', '==', DbStatus.Initial));
  }
  if (!!o.bidStatus && o.bidStatus !== <any>'*') {
    cons.push(where('bidStatus', '==', +o.bidStatus));
  }
  if (!!o.rid) {
    cons.push(where('rentSummary.rid', '==', o.rid));
  }
  if (!!o.openBids) {
    // tslint:disable-next-line:max-line-length
    cons.push(where('bidStatus', '>', 0));
    cons.push(where('bidStatus', '<', 4));  // open bids BidStatus.waitingForVendor = 1 waitingForCustomer = 2 BidStatus.accepted = 3,
  }
  if (!!o.createdAt) {
    // tslint:disable-next-line:max-line-length
    cons.push(where('createdAt', '>', o.createdAt));  // open bids BidStatus.waitingForVendor = 1 waitingForCustomer = 2 BidStatus.accepted = 3,
  }
  if (!!o.endDate) {
    // tslint:disable-next-line:max-line-length
    cons.push(where('bidNegoTerms.endDate', '>', o.endDate));  // open bids BidStatus.waitingForVendor = 1 waitingForCustomer = 2 BidStatus.accepted = 3,
  }
  if (!!o.contractNumber) {
    cons.push(where('contractNo.number', '==', o.contractNumber));  // open bids BidStatus.waitingForVendor = 1 waitingForCustomer = 2 BidStatus.accepted = 3,
  }

  // PT - to filter the products using VIN
  if (!!o.vin) {
    cons.push(where('productSummary.vin', '==', o.vin));
  }
  // KN - to filter Sales Person
  if(!!o.salesPersonId){
    cons.push(where('customerAuthIds', 'array-contains', o.salesPersonId));
  }
  // KN - to filter Credit User
  if(!!o.creditUserId){ 
    cons.push(where('vendorAuthIds', 'array-contains', o.creditUserId)); 
  }

  if (o.orderBy === 'createdAt desc') {
    const { col, dir } = getDirection(bidSearchOrderBy, (!!o.orderBy) ? o.orderBy : 'createdAt desc');
    console.log('col', col);

    console.log('dir', dir);

    cons.push(orderBy(col, dir));

  }

  // for Bid History
  if (!!o.author) {
    cons.push(where(`bidNegoTerms.author`, '==', o.author));
  }
  if (!!o.maxRev) {
    cons.push(where('revId', '<', o.maxRev));  // open bids BidStatus.waitingForVendor = 1 waitingForCustomer = 2 BidStatus.accepted = 3,
  }
  if (o.orderBy === 'revId desc') {
    const { col, dir } = getDirection(bidSearchOrderBy, (!!o.orderBy) ? o.orderBy : 'revId desc');
    cons.push(orderBy(col, dir));
  }
  return query(ref, ...cons);
};
/** Client Filtering of the data */
export const bidSearchClientFilter = (p: BidBase[], o: BidParam): BidBase[] => {
  // client filter to set bid expired status, not yet cleaned by cronJob
  if (o.queryBy === Author.vendor) {
    p.filter(f => f.bidStatus === BidStatus.waitingForVendor && isBefore(f.expiry, new Date()));
  }
  // p.map(e => {
  //   if ((e.bidStatus === BidStatus.waitingForVendor || e.bidStatus === BidStatus.waitingForCustomer) &&
  //     isAfter(new Date(), e.expiry)) { e.bidStatus = BidStatus.expired; e.isExpired = true; }
  // });
  // filter by dbStatus, for new companies the dbStatus will be set as draft
  if (!!o.dbStatus) {
    p = p.filter((val, idx) => val.dbStatus === +o.dbStatus);
  }
  // filter by type.
  if (o.type != null && <any>o.type !== '*') {
    p = p.filter((val, idx) => val.productSummary.productType === +o.type);
  }
  if (o.vCid != null) {
    p = p.filter((val, idx) => val.vendorCompSummary.cid === o.vCid);
  }
  if (o.cCid != null) {
    p = p.filter((val, idx) => !isEmpty(val.customerCompSummary) && val.customerCompSummary.cid === o.cCid);
  }
  if (o.createdByUid != null) {
    p = p.filter((val, idx) => val.createdByUid === o.createdByUid);
  }
  // filter by cid
  // if (o.cids?.length > 0) {
  //   if (o.queryBy === Author.vendor) {
  //     p = p.filter((val, idx) => o.cids.indexOf(`${val.vendorCompSummary.cid}`) > -1);
  //   } else if (o.queryBy === Author.customer) {
  //     p = p.filter((val, idx) => o.cids.indexOf(`${val.customerCompSummary.cid}`) > -1);
  //   }
  // }
  if (o.hasOwnProperty('bidStatus') && o.bidStatus !== <any>'*') {
    p = p.filter((val, idx) => val.bidStatus === +o.bidStatus);
  }
  if (!!o.rid) {
    p = p.filter((val, idx) => val.rentSummary.rid === o.rid);
  }
  if (!!o.openBids) {
    // client filter to set bid expired status, not yet cleaned by cronJob
    p = p.filter((val, idx) => val.bidStatus < 5); // open bids BidStatus.waitingForVendor = 1 waitingForCustomer = 2 BidStatus.accepted = 3
  }
  if (!!o.filterOutExpired) {
    p = p.filter((val, idx) => isBefore(val.expiry, new Date())); // client only filter
  }
  if (!!o.bidIdForHistory) {
    p = p.filter((val, idx) => DbRule.getRootId(val.id) === o.bidIdForHistory);
  }
  if (!!o.author) {
    p = p.filter((val, idx) => val.bidNegoTerms.author === o.author);
  }
  if (!!o.pid) {
    p = p.filter((val, idx) => val.productSummary.pid === o.pid);
  }
  if (!!o.endDate) {
    p = p.filter((val, idx) => isAfter(val.bidNegoTerms.endDate, o.endDate));
  }
  if (!!o.endDateClient) {
    p = p.filter((val, idx) => isAfter(val.bidNegoTerms.endDate, o.endDateClient));
  }
  if (!!o.maxRev) {
    p = p.filter((val, idx) => val.revId < o.maxRev);
  }
  if (!!o.contractNumber) {
    p = p.filter((val, idx) => val.contractNo?.number === o.contractNumber);
  }
  if (o.orderBy === 'createdAt desc') {
    p = p.sort((a, b) => b.createdAt.valueOf() - a.createdAt.valueOf());
  }

  // PT - to filter the products using VIN
  if (!!o.vin) {
    p = p.filter((val, idx) => (val.productSummary as TruckSummary).vin === o.vin);
  }
  // KN - to filter Sales Person
  if(!!o.salesPersonId){
    p = p.filter((val, idx) => ((val.customerAuthIds, o.salesPersonId)));
  }
  // KN - to filter Credit User
  if(!!o.creditUserId){ 
    p = p.filter((val, idx) => ((val.vendorAuthIds, o.creditUserId)));     
  }
  return p;
};
