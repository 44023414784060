import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { IInspectionPayload } from '@trent/models/inspection/i-inspection-payload';
import { Inspection } from '@trent/models/inspection/inspection';
import { InspectionData } from '@trent/models/inspection/inspection-data';
import { instanceToPlain } from 'class-transformer';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { BaseHttpService } from './base-http.service';
import { FirestoreService } from './firestore.service';
import { ContactUs } from '@trent/models/contact-us/contact-us';
import { PromotionResponseType } from '@trent/models/promotional-messages/promotion-response';

@Injectable()
export class DelegateInspectionService extends BaseHttpService {


  offset = new BehaviorSubject(null);
  infinite: Observable<any[]>;
  constructor(store: Store, private db: FirestoreService, private http: HttpClient) {
    super(store);
    this.apiName = 'gps';
  }


  /**
   * Get phone token for anonymous  user
   * @param data InspectionPayload, optional properties,  code, inpsectionData will be stripped
   */
  requestPhoneToken(data: IInspectionPayload, phone: string) {
    data.inspectionData = null;
    data.phoneCode = null;
    // const headers = this.addBearerToken(); // not requied for anonymous user
    // Server validation before creating the entry.
    return this.http.post<{ status: string }>(this.getApiUrl('/inspection-delegate/get-phone-token'),
      {
        data, phone
      },
      // { headers: headers }
    )
      .pipe(
        tap(r => console.log('response: ', r))
      );
  }
  /**
   * Updated by delegate
   *  @param data IInspectionPayload
   */
  public updateByDelegate(data: IInspectionPayload) {
    // inspectionId: string | number, i: InspectionData, action: InspectionStatus, phone: string
    data.inspectionData = instanceToPlain(data.inspectionData) as InspectionData;

    // Prepare the post data
    // const headers = this.addBearerToken();
    // Server validation before creating the entry.
    return this.http.post<{ id: string | number, data: Inspection }>(this.getApiUrl('/inspection-delegate/update-by-delegate'),
      {
        data,
      },
      // { headers: headers }
    )
      .pipe(
        tap(r => console.log('response: ', r))
      );
  }

   /**
   * @author - MKN
   * save user enquiry
   *  @param enquiry ContactUs
   */
   public saveEnquiry(enquiry: ContactUs) {
    enquiry = instanceToPlain(enquiry) as ContactUs;

    // Prepare the post data
    // const headers = this.addBearerToken();
    // Server validation before creating the entry.
    return this.http.post<{ id: string | number, data: Inspection }>(this.getApiUrl('/contact-us/save-enquiry'),
      {
        enquiry : enquiry,
      },
      // { headers: headers }
    )
      .pipe(
        tap(r => console.log('response: ', r))
      );
  }

  /**
   * @author - MKN
   * save user enquiry
   *  @param enquiry ContactUs
   */
  public savePromotionEnquiry(enquiry: ContactUs, promotionLeadId : string, promotionId  : string, promotionResponseType  : PromotionResponseType) {
    enquiry = instanceToPlain(enquiry) as ContactUs;

    // Prepare the post data
    // const headers = this.addBearerToken();
    // Server validation before creating the entry.
    return this.http.post<{ id: string | number, data: Inspection }>(this.getApiUrl('/contact-us/save-enquiry'),
      {
        enquiry : enquiry, promotionId, promotionLeadId, promotionResponseType
      },
      // { headers: headers }
    )
      .pipe(
        tap(r => console.log('response: ', r))
      );
  }

   /**
   * @author - MKN
   * save user enquiry
   *  @param enquiry ContactUs
   */
   public updateUnSubscribeStatus(promotionLeadId: string) {
    // Prepare the post data
    // const headers = this.addBearerToken();
    // Server validation before creating the entry.
    return this.http.post<{ id: string | number, data: Inspection }>(this.getApiUrl('/promotion-leads/unsubscribe'),
      {
        promotionLeadId,
      },
      // { headers: headers }
    )
      .pipe(
        tap(r => console.log('response: ', r))
      );
  }

}
