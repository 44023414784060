import { parse, parseISO } from 'date-fns';
import { isNumber, isArray, isObject, keysIn, filter, isDate } from 'lodash';
// import {isDate} from 'loadash/'
// import * as firebase from 'firebase/app';

import { IPoint } from '../product-search/interfaces';
import { LibSetting } from '../lib-setting';
import { IsDate } from 'class-validator';
import { phoneRegexString, phoneRegex } from './validation-helper';
import { Timestamp } from 'firebase/firestore';

// const validator = new Validator();


/** check if given properties is a getter only */
export const isGetterProp = (obj, key: string): boolean => {
  // logger.log('checking: ', key);
  let disc = Object.getOwnPropertyDescriptor(obj, key);
  let o = obj;
  while (disc == null) {
    o = Object.getPrototypeOf(o);
    if (o == null) {
      throw new Error('no key was found to check for getter!');
    }
    disc = Object.getOwnPropertyDescriptor(o, key);
  }

  return !(disc.writable || typeof (disc.set) === 'function');
};


/** Sanitize the number value from its string representation to number representation
 * '10' becomes 10
 * 10.2 becomes 10
 * 'xxx' remains 'xxx'
*/
export const sanitizeInt = (target: any, key: string) => {
  let v = target.key;
  Object.defineProperty(target, key, {
    get: () => v,
    set: (val) => v = isNaN(+val) ? val : Math.round(+val),
    enumerable: true,
    configurable: true
  });
};

/** Sanitize the number value from its string representation to number representation
 * '10' becomes 10
 * '10.2' becomes 10.2
 * 'xxx' remains 'xxx'
*/
export const sanitizeFloat = (target: any, key: string) => {
  let v = target.key;
  Object.defineProperty(target, key, {
    get: () => v,
    set: (val) => v = isNaN(+val) ? val : +val,
    enumerable: true,
    configurable: true
  });
};

/** Sanitize the number value from its string representation to number representation
 * '10' becomes 10
 * 10.2 becomes 10
 * 'xxx' remains 'xxx'
*/
export const sanitizeIonicDate = (target: any, key: string) => {
  let v = target.key;
  Object.defineProperty(target, key, {
    get: () => v,
    set: (x) => {
      v = sanitizeDate(x);
    },
    enumerable: true,
    configurable: true
  });
};

const parseDate = (d: string): Date => {
  try {
    if (typeof d === 'string') {
      return parseISO(d);
    } else {
      return null;
      // return parse(d, 'yyyy-MM-dd', new Date());
    }

    // return parse(d);
  } catch (error) {
    return null;
  }
};


/** Helper function to santize the date */
export const sanitizeDate = (v: any): Date => {
  // logger.log('date passed to sanitize is:', x);
  if (typeof (v) === 'undefined') { return undefined; }
  if (v == null) { return null; }

  /** Detect if it is date object with invalid time. */
  if (Object.prototype.toString.call(v) === '[object Date]') {
    // it is a date
    if (isNaN(v.getTime())) {  // d.valueOf() could also work
      // date is not valid
      return null;
    } else {
      // date is valid
      return v;
    }
  }

  // if date is a firebase timestamp object, use the toDate
  if (typeof (v.toDate) === 'function') { return v.toDate(); }

  // if data is firetstore time stamp object instead.
  if (isNumber(v.seconds) && isNumber(v.nanoseconds)) {
    const t = new Timestamp(v.seconds, v.nanoseconds);
    return t.toDate();
  }

  // If date is html5  format, use date-fns parse
  const d1 = parseDate(v);
  if (d1 != null) {
    return d1;
  }

  // If date is provided, set up as is
  if (isDate(v)) { return v; }

  // If value is passed as ISO string by the IONIC ( Production behaviour)
  // if (validator.isISO8601(v)) { return parse(v); }

  // if passed as an object with day/month/year values in it ( bug or intermediate Ionic behaviour)
  if (typeof (v) === 'object' && !!v.day && !!v.month && !!v.year) {
    // logger.log('sanitizing as an object');
    const d = v.day.value, m = v.month.value, y = v.year.value;
    if (d != null || m != null || y != null) {
      if (!isNaN(+d) && !isNaN(+m) && !isNaN(+y)) {
        return parseISO(`${y}-${m}-${d}`);
        // return parse(`${y}-${m}-${d}`);
      }
    }
  }
  return v;
};


// /**
//  * itterate through all of object properties and fix the firebase.TimeStamp objects. from snapshot object.
//  * @param o object whoes date properties need to be fixed.
//  */
// export const sanitizeDateIPoint = (data: any): any => {

//   // logger.log('date passed to sanitize is:', x);
//   if (typeof (data) === 'undefined') { return undefined; }
//   if (data == null) { return null; }

//   // if date is a firebase timestamp object, use the toDate
//   if (typeof (data.toDate) === 'function') { return data.toDate(); }

//   // firebase.firestore.Timestamp
//   if (isArray(data)) {
//     for (let i = 0; i < data.length; i++) {
//       data[i] = sanitizeDateIPoint(data[i]);
//     }
//     return data;
//   }

//   if (isObject(data)) {
//     const okeysUnFilt = keysIn(data);
//     const okeys = <string[]>filter(okeysUnFilt, (key) => typeof (data[key]) !== 'function' &&
//       data[key] != null); // remove the function keys

//     for (const k of okeys) {
//       if (isGetterProp(data, k)) {
//         // logger.log('prop: is a getter only: ', k);
//         sanitizeDateIPoint(data[k]); // can not assign as it is getter
//       } else {
//         // logger.log('GOT HREEERERE');
//         data[k] = sanitizeDateIPoint(data[k]);
//       }
//     }
//     return data;
//   }
//   return data;
// };


export type dataTypes = 'string' | 'number' | 'bigint' | 'boolean' | 'symbol' | 'undefined' | 'object' | 'function';
export const getUnfrozenCopy = <T>(o: T): T => {
  if (!Object.isFrozen(o)) {
    return o;
  }
  const types: dataTypes[] = [
    'bigint',
    'boolean',
    'function',
    'number',
    'string',
    'symbol',
    'undefined'
  ];
  if (types.includes(typeof o)) {
    return o;
  }
  if (isDate(o)) {
    return new Date(o as any) as unknown as T;
  }
  //  data = pickBy(data, identity) as T;
  if (isArray(o)) {
    const a = [];
    for (let i = 0; i < o.length; i++) {
      // data[i] = sanitizeDateIPoint(data[i], type);
      a[i] = getUnfrozenCopy(o[i]);
    }
    return a as unknown as T;
  }
  if (isObject(o)) {
    const okeys = keysIn(o);
    const r = {};
    for (const k of okeys) {
      r[k] = getUnfrozenCopy(o[k]);
    }
    return r as unknown as T;
  }
  return o;
};

/** Convert all Firebase GeoPoint class instances to IPoint and date snapshots to Date.
 * This should be used BEFORE Parse once data is received from database.
 * from firebase. This must be called BEFORE PARSE and not AFTER PARSE
 * @param data Data to be parsed
 * @param type type to be used to decipher latitude/longitude. This is needed because at client the type is : firebase.firestore.GeoPoint
 * and at function it is admin.firestore.GeoPoint. admin firebase can not be added at client app. so this type will be provided
 * at run time.
 * https://github.com/Microsoft/TypeScript/wiki/FAQ#why-cant-i-write-typeof-t-new-t-or-instanceof-t-in-my-generic-function
 */
export function sanitizeDateIPoint(data: any) {
  // export function sanitizeDateIPoint<T extends IPoint>(data: any, type?: { new(...args: any[]): T }) {
  if (Object.isFrozen(data) && isObject(data)) {
    return sanitizeDateIPoint(getUnfrozenCopy(data));
  }


  if (data == null || typeof data == 'undefined') {
    return data;
  }

  if (typeof (data) !== 'object') {
    return data;
  }

  // if date is a firebase timestamp object, use the toDate
  if (typeof (data.toDate) === 'function') { return data.toDate(); }

  if (LibSetting.geoPointType == null) {
    throw Error('LibSetting class function : set types were not defined. Did you forget to call LibSetting.setTypes');
  }

  // const ipointType = (!!type) ? type : firebase.firestore.GeoPoint;
  const ipointType = LibSetting.geoPointType;

  if (data instanceof ipointType) {
    const x = <IPoint>data;
    const o: IPoint = {
      latitude: x.latitude,
      longitude: x.longitude
    };
    return o;
  }
  if (isArray(data)) {
    for (let i = 0; i < data.length; i++) {
      // data[i] = sanitizeDateIPoint(data[i], type);
      data[i] = sanitizeDateIPoint(data[i]);
    }
    return data;
  }
  if (isObject(data)) {
    const okeys = keysIn(data);
    for (const k of okeys) {
      if (!isGetterProp(data, k)) {
        // data[k] = sanitizeDateIPoint(<any>data[k], type);
        data[k] = sanitizeDateIPoint(<any>data[k]);
      }
    }
  }
  return data;
}
/**
 * returns phone number string in +1 format 4168025151, 14168025151, 1-416-802-5151, +1(416)8025151 to +14168025151
 * @param phone string
 */
export function sanitizePhone(phone: string, withPlus?: boolean) {
  // phone = phone.replace(/[^0-9.]/, '');
  if (!phone) { return null; }
  if (!phoneRegex(phone)) {
    return phone;
  }
  const digitsOnly = [...phone].filter(e => isFinite(+e)).join('').replace(/ /g, '');
  if (typeof +digitsOnly !== 'number') { return phone; }
  const tenDigitFromRight = digitsOnly.substr(digitsOnly.length - 10, 11);
  return !withPlus ? `1${tenDigitFromRight}` : `+1${tenDigitFromRight}`;
}







