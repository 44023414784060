import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogData, MessageInfo } from '@trent/models/error-handling';
import { isArray } from 'lodash';

@Component({
  selector: 'trent-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {

  get list() {
    if (!!this.data && !!this.data.data && isArray(this.data.data.list) && this.data.data.list.length > 0) {
      return this.data.data.list;
    }
    return null;
  }

  showOk = false;
  get className() {
    return (!!this.data && !!this.data.data.msgCss) ? this.data.data.msgCss : 'primary';
  }

  get isConfirmBox() { return (this.data.boxType === 'confirm') ? true : false; }

  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData<MessageInfo>) { }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  isValidUrl(urlString){
    var urlPattern = new RegExp('^(https?:\\/\\/)?'+ // validate protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // validate domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // validate OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // validate port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // validate query string
    '(\\#[-a-z\\d_]*)?$','i'); // validate fragment locator
  return !!urlPattern.test(urlString);
 }


}
