import { PagingObesrvable } from './paging-obesrvable';
import { Subscription } from 'rxjs';
import { ISubscriptionNode } from './paging';
import { logger } from '../log/logger';

/** Class that keeps track of all the OR queries associated with a db Call.
 * to be stored inside the store instance (Not in the state of the store) */
export class PagingContainer<T, TParam> {

  private container: { [key: string]: ISubscriptionNode<T, TParam> } = {};


  getData(key: string) {
    return this.container[key];
  }

  addData(key: string, s: Subscription, p: PagingObesrvable<T, TParam>) {
    this.container[key] = { sub: s, p: p };
  }


  dispatchNextPagingUpdate(key: string) {
    const p = this.getData(key) && this.getData(key).p;
    if (!!p && !p.pData.full) {
      logger.log('Next page of data was requested');
      p.nextBatch();
    }
    if (p == null) {// debug
      throw Error('coding error! subscriber was not found !!!');
    }
  }


  /** Unsubscribe all of the subscriptioins */
  unsubscribeAll() {
    for (const k in this.container) {
      if (this.container.hasOwnProperty(k)) {
        this.unsubscribe(k);
      }
    }
    this.container = {};
  }

  /** unsubscribe a given key if present iin the container.*/
  unsubscribe(key: string) {
    const d = this.container[key];
    if (!!d) {
      d.p.unsubscribe();
      d.sub.unsubscribe();
    }
  }

}
