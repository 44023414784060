import { ITaskService } from './i-task.service';
import { Observable, combineLatest } from 'rxjs';
import { FirebaseRTDBService } from '../firebase-rtdb.service';
import { HttpClient } from '@angular/common/http';
import { tap, map, switchMap } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { BaseHttpService } from '@trent/services/base-http.service';
import { Injectable } from '@angular/core';
import { Task, ITaskIndex } from '@trent/models/sys/task';
import { PagingObesrvable } from '@trent/models/observable-util/paging-obesrvable';
import { ITaskParam, taskSerachServerQueryRTDB } from '@trent/models/sys/task-param';
import { Paging } from '@trent/models/observable-util/paging';
import { SingletonService } from '../singleton.service';
import { logger } from '@trentm/log/logger';

@Injectable()
export class TaskRTDBService extends BaseHttpService implements ITaskService {
  private get rtdb() { return this.sa.firebaseRTDBService; }

  constructor(store: Store, private http: HttpClient, private sa: SingletonService) {
    super(store);
    this.apiName = 'api';
  }
  public setTaskRead(task: Task, uid: string): Promise<void[]> {
    // const isReadUpdate = {};
    // isReadUpdate[`sendTo.${uid}.isRead`] = true;
    let taskIndexes: Partial<ITaskIndex>;
    const pAll: Promise<void>[] = [];
    task.sendTo[uid].isRead = true;
    if (task.sendTo[`${uid}`].isNew) {
      task.sendTo[uid].isNew = false;
      taskIndexes = task.updateTaskIndexes(uid);
      pAll.push(this.rtdb.update(`${Task.collectionName}/${task.id}/sendTo/${uid}`, {isNew: false, isRead: true   }));  // 
    } else {
      taskIndexes = task.updateTaskIndexes(uid);
      pAll.push(this.rtdb.update(`${Task.collectionName}/${task.id}/sendTo/${uid}`, { isRead: true }));
    }
    pAll.push(this.rtdb.update(`${Task.userCollectionName}/${uid}/${task.id}`, taskIndexes));
    return Promise.all(pAll);
  }
  public async setTaskSeen(task: Task, uid: string): Promise<void[]> {
    const isReadUpdate = {};
    isReadUpdate[`sendTo.${uid}.isNew`] = true;
    const pAll: Promise<void>[] = [];
    
    try {
      task.sendTo[uid].isNew = false;
      const taskIndexes = task.updateTaskIndexes(uid);
      pAll.push(this.rtdb.update(`${Task.collectionName}/${task.id}/sendTo/${uid}`, { isNew: false }));
      pAll.push(this.rtdb.update(`${Task.userCollectionName}/${uid}/${task.id}`, taskIndexes));
      return Promise.all(pAll);
    } catch (error) {
      logger.log(error);
      return null;
    }
  }
  public getTaskById(id: string | number): Observable<Task> {
    return this.rtdb.objWithInjectedId$<Task>('task', `${id}`);
  }
  public getTasks_PagingObservable(): PagingObesrvable<Task, ITaskParam> {
    const p1: PagingObesrvable<Task, ITaskParam> =
      new PagingObesrvable<Task, ITaskParam>(
        this.rtdb,
        (p: Paging, param?: ITaskParam) => this.getTasks_getBatch(p, param));
    return p1;
  }
  private getTasks_getBatch(p: Paging, param?: ITaskParam): Observable<{ [key: string]: Task }> {
    logger.log('task Server called with', p.offset, p.size);
    const _tasks$: Observable<Task>[] = [];
    return this.rtdb.listWithIdsInjected$<{ createdAt: number, id: string }>(`user-tasks/${param.sendTo}`
      // , ref => ref.orderByChild('createdAt').endAt(new Date().valueOf()).limitToLast(2)
      , ref => taskSerachServerQueryRTDB(ref, param, p)
    ).pipe(switchMap((d: { createdAt: number, id: string }[]) => {
      // tslint:disable-next-line:prefer-const
      let tasks$: Observable<Task[]>;
      for (let i = 0; i < d.length; i++) {
        const id = d[i].id;
        const t$ = this.rtdb.objWithInjectedId$<Task>('task', id);
        _tasks$.push(t$);
      }
      tasks$ = combineLatest(_tasks$);
      return tasks$
        .pipe(tap(arr => {
          if (arr == null || arr.length === 0) {
            logger.log('All data is recevied, Paging is FULL');
            p.full = true;
          } else {
            // HG TODO: Fix the usage of lastDoc in the server query functin insdie order-param.
            //            p.lastDoc = arr[arr.length - 1];
            p.lastDoc = arr[0];
            logger.log('lastDoc', p.lastDoc);

          }
        }),
          map(arr => {
            // logger.log('From Server, before mapping is applied: ', arr);

            // convert array to { [id: string] = CompanyFleet} object.
            const arrayAsObj = arr.reduce((acc, cur) => {
              const id = cur.id;
              const data = cur;
              logger.log('acc', acc);

              return { ...acc, [id]: data };
            }, {});

            // logger.log('after converting to array object dic ', arrayAsObj);
            return arrayAsObj;
          })
        );
    }
    ));
  }
}
