import { Length, Matches, validateSync, IsNumber, IsDefined, ValidatorOptions, ValidateIf } from 'class-validator';
import { plainToInstance, instanceToInstance, Exclude, Expose } from 'class-transformer';
import { IValidationMsg } from '../error-handling/validation-info';
import { IPointGeo, sanitizeIPointGeo } from '../product-search/interfaces';
import { sanitizeDateIPoint } from '../utility';
import { toValidationError } from '../utility/validation-helper';
import { LocaleType } from '../finance/currency/currency-type';


export const provinceCodes = ['AB', 'BC', 'MB', 'NB', 'NL', 'NS', 'ON', 'PE', 'QC', 'SK', 'NT', 'NU', 'YT'];
export const stateCodes = [
  'AL', 'AK', 'AS', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FM', 'FL', 'GA',
  'GU', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MH', 'MD', 'MA',
  'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND',
  'MP', 'OH', 'OK', 'OR', 'PW', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT',
  'VT', 'VI', 'VA', 'WA', 'WV', 'WI', 'WY'

];
export const regex = {
  js: {
    // tslint:disable-next-line:max-line-length

    nounPostalZipCode: /^([ABCEGHJKLMNPRSTVXY]{1}[0-9]{1}[A-Z]{1} *[0-9]{1}[A-Z]{1}[0-9]{1})|(^\d{4}\d$|^\d{4}\d-\d{4}$)$/,
  }
};


const gName = 'Address';
const geoLoc = 'GeoLoc';
const billingAddress = 'billingAddress';

@Exclude()
export class Address { // extends BaseModel

  public static readonly gName = gName;
  public static readonly geoLoc = geoLoc;
  public static readonly billingAddress = billingAddress;

  constructor() {
    // super();
  }

  @Expose()
  @Length(1, 254, { message: 'Street number/PO Box needs to be $constraint1-$constraint2 chars', groups: [gName] })
  streetNumber: string;   // Street Address, PO BOX


  @Expose()
  @Length(2, 254, { message: 'Street Name needs to be $$constraint1-$constraint2 chars', groups: [gName] })
  streetName: string;   // Street Address, PO BOX


  @Expose()
  unitNumber?: string;   // Appartment, suit, unit number Address, PO BOX


  @Expose()
  @Length(3, 254, { message: 'Enter valid city', groups: [gName, billingAddress] })
  city: string;


  @Expose()
  @Length(2, 2, { message: 'Enter Valid State/Province', groups: [gName, billingAddress] })
  stateProv: string;


  @Expose()
  @Matches(regex.js.nounPostalZipCode, { message: 'Enter valid Postal/Zip', groups: [gName, billingAddress] })
  postalZipCode: string;


  @Expose()
  @Length(2, 3, { message: 'Enter Valid Country CA/USA', groups: [gName, billingAddress] })
  country: string;

  @Expose()
  @IsNumber({ allowNaN: false, allowInfinity: false }, { message: 'invalid lat', groups: [gName] })
  lat: number;

  @Expose()
  @IsNumber({ allowNaN: false, allowInfinity: false }, { message: 'invalid lat', groups: [gName] })
  long: number;

  @Expose()
  @IsDefined({ message: 'invalid geo location', groups: [gName, geoLoc] })
  geoLoc: IPointGeo;

  @Expose()
  @Length(3, 254, { message: 'Addresss information is required', groups: [gName] })
  addressFormated: string;

  @Expose()
  utcOffset: number;
  @Expose()
  timezone: string;
  @Expose()
  zoneString?: string;

  // @Expose()
  // @ValidateIf(o => !!o.postBox)
  // @Length(0, 10, { message: 'Enter PO Box', groups: [gName] })
  // postBox?: string;

  // @Expose()
  // @Length(1, 254, { message: 'Enter valid address', groups: [billingAddress] })
  // addressLine1: string;
  // @Expose()
  // addressLine2: string;
  // @Expose()
  // @Length(2, 254, { message: 'Enter valid name', groups: [billingAddress] })
  // billingName: string;

  /** getter for line 1 */
  get addressFormattedL1() {
    const x = this.addressFormated;
    return x && x.split(',').length > 2 && x.substr(0, x.indexOf(',')).trim();
  }
  /** getter for line 2 */
  get addressFormattedL2() {
    const x = this.addressFormated;
    return x && x.split(',').length > 2 && x.substr(x.indexOf(',') + 1).trim();
  }
  get addressShort() {
    const x = this.addressFormated;
    return `${this.addressFormattedL1}, ${this.city}`;
  }


  get countryCode  ():'USA' | 'CA' {
    return this.country as 'USA' | 'CA';
  }

  get locale(): LocaleType {
    switch (this.country) {
      case 'US':
        return 'US';
      case 'CA':
        return 'CA';    
      default:
        throw Error(`Invalid locale. Locale is not defined when country is : ${this.country}`);
    }
  }
  get countryLong() {
    switch (this.country) {
      case 'US':
        return 'USA';
      case 'CA':
        return 'Canada';    
      default:
        throw Error(`Invalid locale. Locale is not defined when country is : ${this.country}`);
  }
}

  public static parse(obj) {
    if (obj == null) { return null; }
    const m = plainToInstance<Address, any>(Address, sanitizeDateIPoint(obj));
    m.sanitize();
    return m;
  }
  sanitize() {
    // super.sanitize();
    // if data was recieved from firebase, data was sent as GeoPoint that has _lat and _long properties
    // and Longitude/latitude are function getters. So IPoint is not satifisifed. Just copy the content.
    this.geoLoc = sanitizeIPointGeo(this.geoLoc);

    if (!isNaN(+this.lat) && !isNaN(+this.long)) {
      if (!this.geoLoc) {
        this.geoLoc = {
          geohash: undefined,
          geopoint: {
            latitude: this.lat,
            longitude: this.long
          }
        };
      }
    }

    // if (!!this.geoLoc && !!this.geoLoc.geopoint) {
    //   const g = this.geoLoc.geopoint;
    //   if (!isNumber(g.latitude) && isNumber((<any>g)._lat)) {
    //     g.latitude = (<any>g)._lat;
    //     delete (<any>g)._lat;
    //   }
    //   if (!isNumber(g.longitude) && isNumber((<any>g)._long)) {
    //     g.longitude = (<any>g)._long;
    //     delete (<any>g)._long;
    //   }
    // _lat: 43.6756056
    // _long: -79.82115579999999
    // }

  }

  clone() {
    const t = instanceToInstance(this);
    t.sanitize();
    return t;
  }

  validateSync(options: ValidatorOptions): IValidationMsg {
    const m = validateSync(this, options);
    const r = toValidationError(m);
    // if (!!this.addressLine2 && length(this.addressLine2, 0, 254)) {
    //   r['addressLine2'] = ['Enter valid address'];
    // }
    if (!!this.stateProv && !provinceCodes.includes(this.stateProv) && !stateCodes.includes(this.stateProv)) {
      r['stateProv'] = ['Enter valid state/prov'];
    }

    return r;
  }

  get isLocationDefined() {
    if (!!this.geoLoc && !!this.geoLoc.geopoint) {
      if (!isNaN(+this.geoLoc.geopoint.latitude) && !isNaN(+this.geoLoc.geopoint.longitude)) {
        return true;
      }
    }
    return false;
  }
  setAddressFormatted(){
    let s = '';
    if(!!this.streetNumber) {
      s = this.streetNumber + ','; 
    }
    if(!!this.streetName) {
      s = s + ' ' + this.streetName + ',';
    }
    if(!!this.city) {
      s = s + ' ' + this.city + ',';
    }
    if(this.stateProv) {
      s = s + ' ' + this.stateProv;
    }
    if(this.postalZipCode) {
      s = s + ' ' + this.postalZipCode + ',';
    }
    if(this.country) {
      s = s + ' ' + this.countryLong;
    }
    this.addressFormated = s;
  }
}
