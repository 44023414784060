import { PreloadingStrategy, Route } from '@angular/router';
import { Observable, EMPTY, timer } from 'rxjs';
import { flatMap } from 'rxjs/operators';
import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformServer } from '@angular/common';

declare const navigator;
/**
 * 
 * @param fastNetwrokOnly when true, only download on fast network. Otherwise route will be downlaoded at
 * the first oppertunity.
 * @param delay delay in millisecond before module is downloaded.
 */
export const preloadRoute = (fastNetwrokOnly = false, delay = 0) => {
  return { preload: true, fastNetwrokOnly, delay };
}

const isFastNetwork = (): boolean => {

  // Handle SSR
  if (navigator == null) {
    return false;
  }

  const conn = (navigator as any).connection;
  if (conn) {
    // Save-Data mode
    if (conn.saveData) {
      return false;
    }
    // 'slow-2g', '2g', '3g', or '4g'
    const effectiveType = conn.effectiveType || '';
    // 2G network
    if (effectiveType.includes('2g')) {
      return false;
    }
  }
  return true;
};

/**
 * if route has route data.preload = true &  delay is not specified, this route will be preloaded.
 * if route has route data.preload = true &  delay = true , the route will be preloaded only if fast network is available.
 */
@Injectable({ providedIn: 'root' })
export class CustomPreloadingStrategy implements PreloadingStrategy {

  constructor(@Inject(PLATFORM_ID) private platform: string) { }

  preload(route: Route, load: () => Observable<any>): Observable<any> {

    // at SSR, no need to run
    if(isPlatformServer(this.platform)) {
      return EMPTY;
    }

    // no preload specify, return empty
    if (route.data == null || !route.data.preload) {
      return EMPTY;
    }

    // preload specified, but only on FastNetwork
    if (route.data.fastNetwrokOnly && !isFastNetwork()) {
      return EMPTY;
    }

    // if delay 0, preload now.
    if (isNaN(+route.data.delay) || route.data.delay <= 0) {
      return load();
    }

    // load it with delay
    return timer(+route.data.delay).pipe(flatMap(_ => load()));
  }
}