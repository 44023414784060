import { BaseHttpService } from '@trent/services/base-http.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { FirestoreService } from '@trent/services/firestore.service';
import { tap, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { PtsEnquiry } from '@trent/models/pts/pts-enquiry';
import { PagingObesrvable } from '@trent/models/observable-util/paging-obesrvable';
import { IReviewsParam, reviewsSearchServerQuery } from '@trent/models/reviews/reviews-param';
import { Reviews } from '@trent/models/reviews/reviews';
import { Paging } from '@trent/models/observable-util/paging';
import { Observable } from 'rxjs';


@Injectable()
export class PtsService extends BaseHttpService {


  constructor(store: Store, private db: FirestoreService, private http: HttpClient) {
    super(store);
    this.apiName = 'api';
  }

  /// Create new Enquiry for PTS
  public createEnquiry(data: PtsEnquiry) {
    // Prepare the post data for create the pts Enquiry
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.token });
    return this.http.post<{ status: boolean }>(this.getApiUrl('/pts-enquiry/create'),
      // Server validation before creating the entry.
      {
        data: data
      },
      { headers: headers })
      .pipe(
        tap(res => console.log('response: ', res))
      );
  }

  /// Create new review for PTS page
  public createReview(data: Reviews) {
    // Prepare the post data for create the pts review
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.token });
    return this.http.post<{ status: boolean }>(this.getApiUrl('/review/create'),
      // Server validation before creating the entry.
      {
        data: data
      },
      { headers: headers })
      .pipe(
        tap(res => console.log('response: ', res))
      );
  }

  /// Update existing review for PTS page

  public updateReview(id: string | number, data: Reviews) {
    // Prepare the post data for update the pts review
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.token });
    return this.http.post<{ status: boolean }>(this.getApiUrl('/review/update'),
      // Server validation before creating the entry.
      {
        data: data,
        id: id
      },
      { headers: headers })
      .pipe(
        tap(res => console.log('response: ', res))
      );
  }

    /// Delete existing review for PTS page

    public deleteReview(id: string | number) {
      // Prepare the post data for update the pts review
      const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.token });
      return this.http.post<{ status: boolean }>(this.getApiUrl('/review/delete'),
        // Server validation before creating the entry.
        {
          id: id
        },
        { headers: headers })
        .pipe(
          tap(res => console.log('response: ', res))
        );
    }
  
  /**
 * Here get the data from db according reviewType
 * @returns 
 */
  public getAllReviews_PagingObservable() {
    const p: PagingObesrvable<Reviews, IReviewsParam> =
      new PagingObesrvable<Reviews, IReviewsParam>(this.db, this.getAllReviews_batch);
    return p;
  }
  private getAllReviews_batch(p: Paging, o: IReviewsParam): Observable<{ [key: string]: Reviews }> {
    // console.log('Cms Server called with', p.offset, p.size);
    const col = Reviews.collectionName;
    return this.db
      .colWithIdsInjectedNew$<Reviews>(col,
        ref => reviewsSearchServerQuery(ref, o, p))
      .pipe(
        tap(arr => {
          if (arr == null || arr.length === 0) {
            p.full = true;
          } else {
            p.lastDoc = arr[arr.length - 1];
          }
        }),
        map(arr => {
          return arr.reduce((acc, cur) => {
            const id = cur.id;
            const data = cur;
            return { ...acc, [id]: data };
          }, {});

        })
      );
  }

}