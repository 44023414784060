import { Order } from './order';
import { TransactionType } from '../invoice/invoice-type';
import { instanceToInstance, plainToInstance, Exclude, Expose } from 'class-transformer';
import { sanitizeDateIPoint } from '../../utility/sanitize-helper';

@Exclude()
export class OrderPay extends Order {

  @Expose()
  cCid: number | string;
  
  public static parse(obj) {
    if (obj == null) { return null; }
    // if data was recieved from firebase server, it may contain GeoPoint class that throw error
    // when using planToClass method. So simply the object aagin to get rid of GeoPoint class.
    obj = sanitizeDateIPoint(obj);
    // obj = instanceToPlain(obj);
    const m = plainToInstance<OrderPay, any>(OrderPay, sanitizeDateIPoint(obj));
    m.sanitize();
    return m;
  }
  constructor() {
    super();
    this.invoiceType = TransactionType.debit;
  }
  clone() {
    const t = instanceToInstance(this);
    t.sanitize();
    return t;
  }

  sanitize() {
    super.sanitize();
  }
}
