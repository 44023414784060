import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { tap, take } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { BaseGuard } from './base.guard';
import { IAuthCoreService } from '@trent/services/auth/iauth-core.service';
import { logger } from '@trentm/log/logger';

@Injectable({ providedIn: 'root' })
export class AuthGuard extends BaseGuard implements CanActivate {
  constructor(private router: Router, private auth: IAuthCoreService) {
    super();
    logger.log(`[AuthGuard] constructor called!`);

  }

  canActivate(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    return this.auth.isLoggedin$
      // return this.store.select(AuthState1.isLoggedIn)
      .pipe(
        take(1),
        tap((loggedin) => {
          logger.log(`[AuthGuard] CanActivate called -> Logged In? `, loggedin);
          if (!loggedin) {
            // logger.log('Logged in?: ', loggedin);
            const returnUrl = route['_routerState'].url;
            if (this.allowRedirect(returnUrl)) {
              this.resetCounter(returnUrl);
              if (returnUrl == null || returnUrl === '') {
                this.router.navigateByUrl('/login');
              } else {
                // logger.log('Auth Guard counter: ', this.counter[returnUrl]);
                this.router.navigate(['/login', {
                  returnUrl,
                  fromAuthGuard: true
                }]);
              }
            }
          }
        }));

  }
}
