import { CollectionReference, Query, query, QueryConstraint, where, orderBy, startAfter, limit } from 'firebase/firestore';
import { getDirection, IKeyVal, Paging } from '../observable-util/paging';
import { logger } from '../log/logger';
import { ProductType, TrailerMakeType, TruckMakeType, ProductSummary, TruckSummary, TrailerSummary, Trailer, ProductBase, Truck } from '../product';
import { IPoint } from '../product-search/interfaces';
import { distanceBetween, } from 'geofire-common';
import { SalesTruck } from './sales-truck';
import { SalesProductBase, SalesStatus } from './sales-product-base';
import { buildFilterString, Filter, Operator } from '../utility/filter-builder';
import { IAlgoliaQuery } from '@trent/services/algolia-search.service';
import { DbStatus } from '../base';

export interface SalesOptionParam {
	orderDirection?: 'desc' | 'asc';
	orderBy?: string;
	id?: string | number; // Sales option id
	sid?: string | number; // Store location id
	sidArray?: [string | number];
	pid?: string | number; // Product Id
	cid?: string | number; // Company Id
	isUsed?: boolean | '*';
	country?: string;
	countryArray?: [string];
	type?: ProductType;
	types?: [ProductType];
	isDayCab?: boolean | '*';
	make?: TruckMakeType | TrailerMakeType | '*';
	makesArray?: [TruckMakeType | TrailerMakeType];
	unitName?: string;
	modelYear?: number;
	modelArray?: [string];
	city?: string;
	cityArray?: string;
	center?: IPoint;
	radius?: number;
	truckType?: number;
	trailerType?: number;
	truckTypes?: [number];
	trailerTypes?: [number];
	searchText?: string;
	imageTypes?: [number];
	//Added for Sales op level 2
	imageType?: number;
	dbStatus?: DbStatus;
	status?: SalesStatus;
	storeLocationId?: string | number;
	priceRange?: { from: number, to: number };
	autoTraderId?: string | number;
	odometerRange?: { from: number, to: number };
	odometerUnits?: string;
}

export interface SalesOptionsBulkUpload {
	salesOption: SalesProductBase,
	productWholeSalePrice: number,
	productInfo: ProductBase,
}

export const salesOptionParamInit = (): SalesOptionParam => {
	return {
		orderBy: 'updatedAt',
		orderDirection: 'desc',
		// dbStatus: 3,
		// status: 1
	};
};

export const salesSearchOrderBy: IKeyVal[] = [
	{ key: 'Name', value: 'unitName' },
	{ key: 'createdAt to Date', value: `createdAt desc` },
	{ key: 'Make', value: 'make' },
	{ key: 'Model year', value: 'modelYear desc' },
];

/** Server filtering of firebase query building of query  */
export const salesOptionSearchServerQuery = (ref: CollectionReference, param: SalesOptionParam, p: Paging) => {
	let q: Query = <any>ref;
	const cons: QueryConstraint[] = [];
	cons.push(where('updatedAt', '<=', new Date()));
	cons.push(orderBy('updatedAt', 'desc'));

	if (!!param.dbStatus) {
		cons.push(where('dbStatus', '==', param.dbStatus));
	}

	if (!!param.status) {
		cons.push(where('status', '==', param.status));
	}

	if (!!p.lastDoc) {
		logger.log('Start After Query: ', p.lastDoc[param.orderBy]);
		cons.push(startAfter(p.lastDoc[param.orderBy]));
	}
	if (!!param.id && param.id !== 'x') {
		cons.push(where('id', '==', param.id));
	}
	if (!!param.cid && param.cid !== 'x') {
		cons.push(where('vendorCompSummary.cid', '==', param.cid));
	}
	if (!!param.pid && param.pid !== 'x') {
		cons.push(where('productSummary.pid', '==', param.pid));
	}
	if (!!param.type && param.type !== <any>'*') {
		cons.push(where('productSummary.productType', '==', +param.type));
	}
	if (!!param.country && param.country !== '*') {
		cons.push(where('storeLocationSummary.address.country', '==', param.country));
	}
	if (!!param.city && param.city !== '*') {
		cons.push(where('storeLocationSummary.address.city', '==', param.city));
	}
	if (typeof param.isDayCab === 'boolean') {
		cons.push(where('productSummary.isDayCab', '==', param.isDayCab));
	}
	if (!!param.make && param.make !== '*') {
		cons.push(where('productSummary.make', '==', param.make));
	}
	if (!!param.unitName && param.unitName !== '*') {
		cons.push(where('productSummary.unitName', '==', param.unitName));
	}
	if (!!param.modelYear) {
		cons.push(where('productSummary.modelYear', '==', param.modelYear));
	}
	if (param.orderBy == 'modelYear') {
		const { col, dir } = getDirection(salesSearchOrderBy, (!!param.orderDirection) ? param.orderBy : 'modelYear desc');
		cons.push(orderBy(col, dir));
		if (!!p.lastDoc) {
			cons.push(startAfter(p.lastDoc[col]));
		}
	}
	if (param.orderBy == 'mileage') {
		const { col, dir } = getDirection(salesSearchOrderBy, (!!param.orderBy) ? param.orderBy : 'mileage desc');
		if (!!p.lastDoc) {
			cons.push(startAfter(p.lastDoc[col]));
		}
	}
	if (param.orderBy == 'odometer') {
		const { col, dir } = getDirection(salesSearchOrderBy, (!!param.orderBy) ? param.orderBy : 'odometer desc');
		if (!!p.lastDoc) {
			cons.push(startAfter(p.lastDoc[col]));
		}
	}
	if (!!param.sid && param.sid !== 'x') {
		cons.push(where('storeLocationSummary.sid', '==', param.sid));
	}
	if (!!param.sidArray && param.sidArray.length > 0) {
		cons.push(where('storeLocationSummary.sid', 'in', param.sidArray));
	}
	if (!!param.truckType) {
		cons.push(where('productSummary.truckType', '==', param.truckType));
	}
	if (!!param.truckTypes && param.truckTypes.length > 0) {
		cons.push(where('productSummary.truckType', 'in', param.truckTypes));
	}
	if (!!param.trailerType) {
		cons.push(where('productSummary.trailerType', '==', param.trailerType));
	}
	if (!!param.trailerTypes && param.trailerTypes.length > 0) {
		cons.push(where('productSummary.trailerType', 'in', param.trailerTypes));
	}
	if (!!param.imageType) {
		cons.push(where('imageType', '==', param.imageType));
	}
	if (!!param.imageTypes && param.imageTypes.length > 0) {
		cons.push(where('imageType', 'in', param.imageTypes));
	}
	if (!!param.autoTraderId) {
		cons.push(where('autoTraderId', '==', param.autoTraderId));
	}
	cons.push(limit(p.size));
	q = query(ref, ...cons);
	return q;
};

export const generateFilterStringForAlgolia = (param: SalesOptionParam) => {
	const cons: Filter[] = [];

	if (!!param.id && param.id !== 'x') {
		cons.push({ attributeName: 'objectID', operator: Operator.COLON, value: param.id });
	}
	if (!!param.pid && param.pid !== 'x') {
		cons.push({ attributeName: 'productSummary.pid', operator: Operator.COLON, value: param.pid });
	}
	if (!!param.cid && param.cid !== 'x') {
		cons.push({ attributeName: 'vendorCompSummary.cid', operator: Operator.COLON, value: param.cid });
	}
	if (!!param.isUsed) {
		cons.push({ attributeName: 'productSummary.isUsed', operator: Operator.COLON, value: param.isUsed });
	}

	if (!!param.storeLocationId) {
		cons.push({ attributeName: 'storeLocationSummary.sid', operator: Operator.COLON, value: param.storeLocationId });
	}
	if (!!param.sidArray && param.sidArray.length > 0) {
		cons.push({ attributeName: 'storeLocationSummary.sid', operator: Operator.COLON, value: (param.sidArray as [string]) });
	}

	if (!!param.priceRange) {
		cons.push({ attributeName: 'price', operator: Operator.GREATER_THAN_EQ, value: param.priceRange.from });
		cons.push({ attributeName: 'price', operator: Operator.LESS_THAN_EQ, value: param.priceRange.to });
	}

	if (!!param.odometerRange) {
		cons.push({ attributeName: 'productSummary.odometer', operator: Operator.GREATER_THAN_EQ, value: param.odometerRange.from });
		cons.push({ attributeName: 'productSummary.odometer', operator: Operator.LESS_THAN_EQ, value: param.odometerRange.to });
	}

	if (!!param.type && param.type !== <any>'*') {
		cons.push({ attributeName: 'productSummary.productType', operator: Operator.EQUAL, value: param.type });
	}
	if (!!param.types && param.types.length > 0) {
		cons.push({ attributeName: 'productSummary.productType', operator: Operator.EQUAL, value: param.types });
	}
	if (!!param.country && param.country !== '*') {
		cons.push({ attributeName: 'storeLocationSummary.address.country', operator: Operator.COLON, value: param.country });
	}
	if (!!param.countryArray && param.countryArray.length > 0) {
		cons.push({ attributeName: 'storeLocationSummary.address.country', operator: Operator.COLON, value: (param.countryArray as [string]) });
	}
	if (!!param.cityArray && param.cityArray !== '*') {
		cons.push({ attributeName: 'storeLocationSummary.address.city', operator: Operator.COLON, value: param.cityArray });
	}
	if (typeof param.isDayCab === 'boolean') {
		cons.push({ attributeName: 'productSummary.isDayCab', operator: Operator.COLON, value: param.isDayCab });
	}



	if (!!param.makesArray && param.makesArray.length > 0) {
		cons.push({ attributeName: 'productSummary.make', operator: Operator.COLON, value: param.makesArray });
	}

	if (!!param.unitName && param.unitName !== '*') {
		cons.push({ attributeName: 'productSummary.unitName', operator: Operator.COLON, value: param.unitName });
	}

	if (!!param.sid && param.sid !== 'x') {
		cons.push({ attributeName: 'storeLocationSummary.sid', operator: Operator.COLON, value: param.sid });
	}

	if (!!param.truckType) {
		cons.push({ attributeName: 'productSummary.truckType', operator: Operator.EQUAL, value: param.truckType });
	}
	if (!!param.truckTypes && param.truckTypes.length > 0) {
		cons.push({ attributeName: 'productSummary.truckType', operator: Operator.EQUAL, value: param.truckTypes });
	}
	if (!!param.trailerType) {
		cons.push({ attributeName: 'productSummary.trailerType', operator: Operator.EQUAL, value: param.trailerType });
	}
	if (!!param.trailerTypes && param.trailerTypes.length > 0) {
		cons.push({ attributeName: 'productSummary.trailerType', operator: Operator.EQUAL, value: param.trailerTypes });
	}
	if (param.imageType != undefined) {
		cons.push({ attributeName: 'imageType', operator: Operator.EQUAL, value: param.imageType });
	}

	if (!!param.modelYear) {
		cons.push({ attributeName: 'productSummary.modelYear', operator: Operator.EQUAL, value: param.modelYear });
	}
	if (!!param.modelArray && param.modelArray.length > 0) {
		cons.push({ attributeName: 'productSummary.model', operator: Operator.COLON, value: (param.modelArray as [string]) });
	}

	// if (param.dbStatus != undefined) {
	// 	cons.push({ attributeName: 'dbStatus', operator: Operator.EQUAL, value: param.dbStatus});
	// }

	if (param.dbStatus != undefined) {
		cons.push({ attributeName: 'dbStatus', operator: Operator.EQUAL, value: param.dbStatus });
	}

	if (param.status != undefined) {
		cons.push({ attributeName: 'status', operator: Operator.EQUAL, value: param.status });
	}

	if (!!param.imageTypes && param.imageTypes.length > 0) {
		cons.push({ attributeName: 'imageType', operator: Operator.COLON, value: param.imageTypes });
	}
	if (!!param.autoTraderId) {
		cons.push({ attributeName: 'autoTraderId', operator: Operator.COLON, value: param.autoTraderId });
	}

	return buildFilterString(cons);

};

/** Search sales-options using algolia search API  */
export const salesOptionSearchAlgolia = (param: SalesOptionParam, p: Paging): { query: IAlgoliaQuery, indexName: string } => {
	const query: IAlgoliaQuery = {
		query: '',
		restrictSearchableAttributes: [],
		hitsPerPage: p.size as number,
		page: p.offset as number
	};
	let indexName = 'sales-options';

	if (!!param.make && param.make !== '*') {
		query.query += param.make;
		query.restrictSearchableAttributes.push('productSummary.make');
	}

	if (!!param.searchText) {
		query.query += param.searchText;
	}

	if (param.orderBy == 'modelYear desc') {
		indexName = 'sales-options-modelYear-desc';
	}
	if (param.orderBy == 'modelYear asc') {
		indexName = 'sales-options-modelYear-asc';
	}
	if (param.orderBy == 'odometer desc') {
		indexName = 'sales-options-odometer-desc';
	}
	if (param.orderBy == 'odometer asc') {
		indexName = 'sales-options-odometer-asc';
	}
	query.filters = generateFilterStringForAlgolia(param);


	return { query, indexName };
};

/** Client Filtering of the data */
export const salesOptionSearchClientFilter = (p: SalesProductBase[], o: SalesOptionParam): SalesProductBase[] => {

	if (!!o.dbStatus) {
		p = p.filter((val) => val.dbStatus === +o.dbStatus);
	}

	if (!!o.status) {
		p = p.filter((val) => val.status === +o.status);
	}

	// Filter by type
	if (o.type != null && <any>o.type !== '*') {
		p = p.filter((val) => val.productSummary.productType === +o.type);
	}
	// Filter by sales option by id
	if (o.id) {
		p = p.filter((val, idx) => val.id === o.id);
	}
	// Filter by product id
	if (o.pid) {
		p = p.filter((val, idx) => val.productSummary.pid === o.pid);
	}
	// Filter by vendor company id
	if (o.cid) {
		p = p.filter((val, idx) => val.vendorCompSummary.cid === o.cid);
	}
	// Filter by country
	if (o.country) {
		p = p.filter((val, idx) => val.storeLocationSummary.address.country === o.country);
	}
	// Filter by city
	if (o.city) {
		p = p.filter((val, idx) => val.storeLocationSummary.address.city === o.city);
	}
	// Filter by city array
	if (o.cityArray) {
		p = p.filter((val, idx) => o.cityArray.indexOf(val.storeLocationSummary.address.city) > -1);
	}
	// Filter by make
	if (!!o.make && o.make !== '*') {
		p = p.filter((val) => (val as SalesTruck).productSummary.make === o.make);
	}
	// Filter by unitName
	if (!!o.unitName && o.unitName !== '*') {
		p = p.filter((val) => (val as SalesTruck).productSummary.unitName === o.unitName);
	}
	// Filter by modelYear
	if (!!o.modelYear) {
		p = p.filter((val) => (val as SalesTruck).productSummary.modelYear === o.modelYear);
	}
	//   Filter by isDayCab
	if (typeof o.isDayCab === 'boolean') {
		p = p.filter((val) => (val as SalesTruck).productSummary.isDayCab === o.isDayCab);
	}
	// Filter by sid
	if (o.sid) {
		p = p.filter((val, idx) => val.storeLocationSummary.sid === o.sid);
	}
	// Filter by sid array
	if (o.sidArray) {
		p = p.filter((val, idx) => o.sidArray.indexOf(val.storeLocationSummary.sid) > -1);
	}
	if (!!o.center) {
		p = p.filter((val) => {
			const distanceInKm = distanceBetween([val.storeLocationSummary.address.geoLoc.geopoint.latitude, val.storeLocationSummary.address.geoLoc.geopoint.longitude],
				[o.center.latitude, o.center.longitude]);
			if (distanceInKm <= 1000) {
				return true;
			} else {
				return false;
			}
		});
	}
	// Filter by truck type
	if (o.truckType) {
		p = p.filter((val, idx) => (val.productSummary as TruckSummary).truckType === o.truckType);
	}
	// Filter by truck type array
	if (o.truckTypes) {
		p = p.filter((val, idx) => o.truckTypes.indexOf((val.productSummary as TruckSummary).truckType) > -1);
	}
	// Filter by trailer type
	if (o.trailerType) {
		p = p.filter((val, idx) => (val.productSummary as TrailerSummary).trailerType === o.trailerType);
	}
	// Filter by trailer type array
	if (o.trailerTypes) {
		p = p.filter((val, idx) => o.trailerTypes.indexOf((val.productSummary as TrailerSummary).trailerType) > -1);
	}
	// Filter by image type
	if (o.imageType) {
		p = p.filter((val) => val.imageType === o.imageType);
	}
	// Filter by image type array
	if (o.imageTypes) {
		p = p.filter((val) => o.imageTypes.indexOf(val.imageType) > -1);
	}
	// Order By Model Year
	if (o.orderBy === 'modelYear asc') {
		p = p.sort((a, b) => (b.productSummary as any).modelYear.valueOf() - (a.productSummary as any).modelYear.valueOf());
	}
	// Order By Model Year
	if (o.orderBy === 'modelYear desc') {
		p = p.sort((a, b) => (a.productSummary as any).modelYear.valueOf() - (b.productSummary as any).modelYear.valueOf());
	}
	// Order By odometer
	if (o.orderBy === 'odometer asc') {
		p = p.sort((a, b) => (b.productSummary as any).odometer.valueOf() - (a.productSummary as any).odometer.valueOf());
	}
	// Order By odometer
	if (o.orderBy === 'odometer desc') {
		p = p.sort((a, b) => (a.productSummary as any).odometer.valueOf() - (b.productSummary as any).odometer.valueOf());
	}
	// Filter by sales option by autoTraderId
	if (o.autoTraderId) {
		p = p.filter((val, idx) => val.autoTraderId === o.autoTraderId);
	}
	return p as SalesProductBase[];
};

export const getSalesOptionSearchOptOrChildren = (o: SalesOptionParam): { [key: string]: SalesOptionParam } => {

	return undefined;
};