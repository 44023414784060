import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { Store } from '@ngxs/store';
import { IPromoListParam, PromoListBase, PromoListType } from '@trent/models/promo';
import { PromoListByIdRequested, PromoListsRequested, PromoListState } from '@trent/store/promo-list-store';
import { map } from 'rxjs/operators';
import { VendorPortfolio, VendorPortfolioItem } from '@trent/models/promo/vendor-portfolio';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { isEqual, pickBy } from 'lodash';
import { firstChar } from '@trent/models/utility';
import { logger } from '@trent/models/log/logger';
import { Observable } from 'rxjs';
import { Subscription } from 'rxjs/internal/Subscription';

@Component({
  selector: 'trent-select-company',
  templateUrl: './select-company.component.html',
  styleUrls: ['./select-company.component.scss']
})
export class SelectCompanyComponent implements OnInit, OnDestroy  {

  @ViewChild('entryId', { static: false })
  entryId: ElementRef<HTMLInputElement>;

  @Input()
  param: IPromoListParam;
  @Input()
  showChips = true;
  @Input()
  defComp: string | number;
  @Input()
  inputLabel = 'Add Company(s)';

  @Input()
  selectedKey: string;

  _selectedKeys: string[];
  @Output()
  itemRemovedC = new EventEmitter<string>();
  sub: Subscription;

  @Input()
  set selectedKeys(v: string[]) {
    if (!!v) {
      this._selectedKeys = v;
      if (this.companyList) {
        this.getSeleted();
      }
      logger.log('selectedKeys', v);

    }
  }
  get selectedKeys() { return this._selectedKeys; }

  @Output()
  itemSelectedC = new EventEmitter<string>();


  separatorKeysCodes: number[] = [ENTER, COMMA];


  companyList: VendorPortfolio;
  filtered: { [key: string]: VendorPortfolioItem; };

  selected: any;
  activeList: { [key: string]: VendorPortfolioItem; };

  selectedCompanies: { [key: string]: VendorPortfolioItem } = {};

  @Input()
  isMasterList: boolean;
  
  //Display passed company list data on UI
  @Input()
  cList: { [key: string]: VendorPortfolioItem; };

  @Input()
  isEMode: boolean;

  constructor(private store: Store) { }


  ngOnInit() {
    if(this.cList){
      this.activeList = this.cList;
    }else{
      if(this.isMasterList){
        this.getMasterList();
      } else {
        this.getStoreData();
      } 
    }
  }


  ngOnDestroy(): void {
    if(this.sub)
      this.sub.unsubscribe();
  }

  getStoreData() {
    const pData = { size: 1, offset: 0, full: false };
    this.store.dispatch(new PromoListsRequested({ pData: pData, param: { ...this.param } }));
    const v$ = this.store.select(PromoListState.selectAllData)
      .pipe(map(clientFilterFn => clientFilterFn({ ...this.param })));
    this.sub = v$.subscribe(d => {
      if (d?.length > 0 && !isEqual(this.companyList, d[0])) {
        this.companyList = d[0] as VendorPortfolio;
        this.activeList = pickBy((d[0] as VendorPortfolio).list, (val, key) => !!val.isActive && key !== this.defComp);
      }
    });

  }
  getMasterList() {
    let m: VendorPortfolio;
    this.store.dispatch(new PromoListByIdRequested({ id: PromoListBase.masterCompListId }));
    const portfolioList$ = <Observable<PromoListBase>>this.store.select(PromoListState.selectDataById)
      .pipe(map(filterFn => filterFn(PromoListBase.masterCompListId)));
    this.sub = portfolioList$.subscribe(d => {
      if (d && !isEqual(this.companyList, d)) {
        this.companyList = d as VendorPortfolio;
        this.activeList = pickBy((d as VendorPortfolio).list, (val, key) =>  key !== this.defComp);
        logger.info('Master list', this.activeList);
      }
    });
  }

  _filter(v: any) {
    const value = !!v ? v.viewModel : [];
    // logger.log('value', value);
    this.filtered = {};
    for (const key in this.activeList) {
      if (this.activeList.hasOwnProperty(key)) {
        const element = this.activeList[key];
        if (typeof value === 'string' && element.uiDescription.toLocaleLowerCase().includes(value.toLowerCase())) {
          this.filtered[key] = this.activeList[key];
        }
      }
    }
  }
  displayFn(selected?: { [key: string]: VendorPortfolioItem; }): string | undefined {
    logger.log('[select-company-comp] selected', selected);
    if (!!selected) {
      return selected && selected.value ? selected.value.companyFriendlyName : undefined;
    }
  }
  optionSelected(option: { key: string, value: VendorPortfolioItem }) {
    this.emitFn(option.key);
    // blur will remove focus on the currently selected element
    this.entryId.nativeElement.blur();
  }
  enterBlur() {

  }
  remove(s: { key: string, value: VendorPortfolioItem }) {
    this.itemRemovedC.emit(s.key);
  }
  emitFn(k: string) {
    this.itemSelectedC.emit(k);
    this.entryId.nativeElement.value = null;

  }
  firstChar(s: string) { return firstChar(s); }

  /** get dictionary item based on string passed on by parent */
  getSeleted() {
    logger.log('tripTrackers', this.selectedKeys);
    for (const k in this.activeList) {
      if (this.activeList.hasOwnProperty(k)) {
        const element = this.activeList[k];
        if (!!this.selectedKeys && this.selectedKeys.length > 0) {
          this.selectedKeys.forEach(e => {
            this.selectedCompanies[k] = element;
          });
        }
      }
    }
    this.selectedCompanies = pickBy(this.selectedCompanies, (val, key) => this.selectedKeys.includes(key));
  }
  keySelectedFn(key: string) {
    this.selectedKey = key;
    this.itemSelectedC.emit(this.selectedKey);
  }
}
