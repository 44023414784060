import { isNumber, keys } from 'lodash';
// import { GeoPoint } from '@firebase';

export interface IPoint {
  latitude: number;
  longitude: number;
}

/** If given object is @interface IPoint */
export const isIPoint = (o): boolean => {
  const k = keys(o);
  if (!!k && k.length === 2 && isNumber(o.latitude) && isNumber(o.longitude)) {
    return true;
  }
  return false;
};

export const sanitizeIPoint = (obj: IPoint) => {
  obj.latitude = +obj.latitude;
  obj.longitude = +obj.longitude;
};

export interface IPointGeo {
  geohash: string;
  geopoint: IPoint;
}

export interface IMarker extends IPointGeo {
  label?: string;
}

export const sanitizeIPointGeo = (obj: IPointGeo) => {
  // if data was recieved from firebase, data was sent as GeoPoint that has _lat and _long properties
  // and Longitude/latitude are function getters. So IPoint is not satifisifed. Just copy the content.
  if (!!obj && !!obj.geopoint) {
    const g = obj.geopoint;
    if (!isNumber(g.latitude) && isNumber((<any>g)._lat)) {
      g.latitude = (<any>g)._lat;
      delete (<any>g)._lat;
    }
    if (!isNumber(g.longitude) && isNumber((<any>g)._long)) {
      g.longitude = (<any>g)._long;
      delete (<any>g)._long;
    }
    // _lat: 43.6756056
    // _long: -79.82115579999999
  }
  // logger.log('after sanitize: ', obj);
  return obj;
};



// /** Convert all GeoPoint class instances to IPoint. required before parsing data coming back
//  * from firebase. This must be called BEFORE PARSE and not AFTER PARSE
//   * @param data Data to be parsed
// tslint:disable-next-line:max-line-length
//   * @param type type to be used to decipher latitude/longitude. This is needed because at client the type is : firebase.firestore.GeoPoint
//   * and at function it is admin.firestore.GeoPoint. admin firebase can not be added at client app. so this type will be provided
//   * at run time.
//   * https://github.com/Microsoft/TypeScript/wiki/FAQ#why-cant-i-write-typeof-t-new-t-or-instanceof-t-in-my-generic-function
//   */
// export function sanitizeIPointAll<T extends IPoint>(data: any, type?: { new(...args: any[]): T }) {

//   if (typeof (data) !== 'object') {
//     return data;
//   }
//   const ipointType = (!!type) ? type : firebase.firestore.GeoPoint;
//   if (data instanceof ipointType) {
//     const x = <T>data;
//     const o: IPoint = {
//       latitude: x.latitude,
//       longitude: x.longitude
//     };
//     // only IPOINT is returned.
//     return o;
//     // logger.log('Yes it is GeoPoint');
//     // return sanitizeIPoint(<any>data);
//   }

//   if (isArray(data)) {
//     const x = [];
//     for (let i = 0; i < data.length; i++) {
//       data[i] = sanitizeIPointAll(data[i], type);
//     }
//     return x;
//   }
//   if (isObject(data)) {
//     const okeys = keysIn(data);
//     for (const k of okeys) {
//       if (k === 'geoLoc') {
//         logger.log('got here, key');
//       }
//       data[k] = sanitizeIPointAll(<any>data[k], type);
//     }
//   }
//   return data;
// }
