import { Exclude, instanceToInstance, plainToInstance, Expose } from 'class-transformer';
import { Equals, IsBoolean, isNumber, IsNumber, Length, Max, ValidateIf, validateSync, IsDefined } from 'class-validator';
import { IDurationArg, IMethodInfo, IPercentArg } from "../finance-input/fn-lib";
import { ITaxItem } from "./i-tax-item";
import { sanitizeDateIPoint } from '../../utility/sanitize-helper';
import { IValidationMsg } from '../../error-handling/validation-info';
import { toValidationError } from '../../utility/validation-helper';


export type LevyGroup = 'levyG' ;
const levyG: LevyGroup = 'levyG';


/** Class to set Fin Inout (@param ITaxItem @param IMethodInfo ) in DB and Model tax for use cases where custom tax requires to be implemented, e.g. rental in USA */
@Exclude()
export class Levy {
  public static readonly  levyG = 'levyG';
  /** Tax Name . e.g Sales Tax, HST */
  @Expose()
  @Length(1, 50, {groups: [levyG]})
  name: string;

  /** Is the tax or fee based on percent or duration */
  @Expose()
  @Equals('percent' || 'duration')
  funName: 'percent' | 'duration';

  @IsDefined({groups: [levyG]})
  get args(): number | IPercentArg | IDurationArg {
      switch (this.funName) {
        case 'percent':
          if(isNumber(this.rate)){
            return { rate: this.rate, initial: this.initial, min: this.min, max: this.max }; // round(+this.ratePercent/100, 4)
          } else {
            return undefined;
          }
        case 'duration':
          if(isNumber(this.amount) && this.timeUnit){
            return { amount: this.amount, timeUnit: this.timeUnit, initial: this.initial, min: this.min, max: this.max };
          } else {
            return undefined;
          }
        default:
          break;
      }
    
  };
  @IsDefined({groups: [levyG]})
  get iMethodInfo(): IMethodInfo {
    if(this.args) {
      return {
        funName: this.funName,
        args: this.args,
        isOptional: this.isOptional,
        isTaxable: this.isTaxable
      };
    } else {
      return undefined;
    }
  }
  get iTaxItem(): ITaxItem {
    return { ...this.iMethodInfo, name: this.name };
  }
  @Expose()
  @IsBoolean({groups: [levyG]})
  @ValidateIf(o => !o.name, {groups: [levyG]})
  isTaxable?: boolean;

  @Expose()
  @IsBoolean({groups: [levyG]})
  @ValidateIf(o => !o.name, {groups: [levyG]})
  isOptional?: boolean;

  // @Expose()
  // @ValidateIf(o => o.funName === 'percent')
  // @Length(1,5)
  // ratePercent: string;
  
  /** Percent Charges. It must be fractional. i.e. 5% must be input  0.05 */
  @Expose()
  @ValidateIf(o => o.funName === 'percent', {groups: [levyG]})
  @IsNumber({maxDecimalPlaces: 4}, {message: 'Max 4 decimal places', groups: [levyG]})
  @Max(.99, {message: 'Enter value < 1', groups: [levyG]} )
  rate: number;

  /** initial amount that will be added to the percent calculation */
  @Expose()
  @ValidateIf(o => !!o.isMinMaxInitialApplicable, {groups: [levyG]})
  @IsNumber(undefined, {groups: [levyG]})
  initial?: number;

  /** Min Cap, Minimum amount of charges that will be applied if calculated amount is less then this number. (to be < min)
    * Note: these calcs are done per pay period/Invoice Item and not the Gross at invoice level. 
  */
  @Expose()
  @ValidateIf(o => !!o.isMinMaxInitialApplicable, {groups: [levyG]})
  @IsNumber(undefined, {groups: [levyG]})
  min?: number;

  /** Max Cap, total amount calculated will be capped to this amount if provided. (to be > max)
 * Note: these calcs are done per pay period/Invoice Item  and not at the Gross at invoice level.
  */
  @Expose()
  @ValidateIf(o => !!o.isMinMaxInitialApplicable, {groups: [levyG]})
  @IsNumber(undefined, {groups: [levyG]})
  max?: number;

  /** Base Amount per day. */
  @ValidateIf(o => o.funName === 'duration', {groups: [levyG]})
  @Expose()
  @IsNumber(undefined, {groups: [levyG]})
  amount: number;


  @Expose()
  @IsNumber(undefined, {groups: [levyG]})
  @ValidateIf(o => o.funName === 'duration',{groups: [levyG]})
  timeUnit?: 'day' | 'hour';

  @Expose()
  @IsBoolean({groups: [levyG]})
  isMinMaxInitialApplicable: boolean = false;

  static initCustomTax(): Levy {
    const t = new Levy();
    t.isMinMaxInitialApplicable = false;
    t.funName = 'percent';
    return t;
  }
  static initPlaceholderTax(): Levy {
    const t = Levy.initCustomTax();
    t.name = 'TBD';
    t.rate = 0;
    return t;
  }


  public static parse(obj) {


    if (obj instanceof (Levy)) { return <Levy>obj; }

    const m = plainToInstance<Levy, any>(Levy, sanitizeDateIPoint(obj));
    m.sanitize();
    return m;
  }

  sanitize() {

    // super.sanitize();

  }
  clone() {
    const t = instanceToInstance(this);
    t.sanitize();
    return t;
  }

  validateSync(options?: any): IValidationMsg {

    const m = validateSync(this, options);
    const r = toValidationError(m);
    if(!this.args) {
      r['rate'] = ['Define Rate'];
    }


    return r;
  }
}