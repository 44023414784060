import { Injectable } from '@angular/core';
// import { ScriptStore } from "./script.store";
import { logger } from '@trentm/log/logger';

declare var document: any;

interface IPromiseData {
  resolve: (param: any) => any;
  reject: (param: any) => any;
}

export type ScriptStatus = 'init' | 'loading' | 'loaded' | 'not-found';

interface ScriptData {
  src: string;
  status: ScriptStatus;
}

export const ScriptStore: ScriptData[] = [];

@Injectable({ providedIn: 'root' })
export class ScriptService {

  private scripts: { [key: string]: ScriptData } = {};

  private promiseQueue: {
    [src: string]: Array<IPromiseData>
  } = {};

  constructor() {
  }

  // load(...scripts: string[]) {
  //   const promises: any[] = [];
  //   scripts.forEach((script) => promises.push(this.loadScript(script)));
  //   return Promise.all(promises);
  // }

  isScriptLoaded(src: string) {
    if (!!this.scripts[src] && this.scripts[src].status === 'loaded') {
      return true;
    }
    return false;
  }

  isScriptLoading(src: string) {

    if (!!this.scripts[src] && this.scripts[src].status === 'loading') {
      return true;
    }
    return false;
  }

  loadScript(src: string): Promise<ScriptData> {
    return new Promise((resolve, reject) => {
      if (!this.scripts[src]) {
        this.scripts[src] = { src: src, status: 'init' };
      }
      const s = this.scripts[src];
      // resolve if already loaded
      if (s.status === 'loaded') {
        logger.log('Script is already Loaded! ', src);
        resolve(s);
        return;
      }
      if (s.status === 'loading') {
        logger.log('Script is in the process of loading, need to wait. Storing the queue');
        if (!!this.promiseQueue[src]) {
          this.promiseQueue[src].push({ resolve, reject });
        } else {
          this.promiseQueue[src] = [{ resolve, reject }];
        }
      }
      if (s.status === 'init') {
        // load script
        s.status = 'loading';
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = this.scripts[src].src;
        if (script.readyState) {  // IE
          script.onreadystatechange = () => {
            if (script.readyState === 'loaded' || script.readyState === 'complete') {
              // s.loaded = true;
              s.status = 'loaded';
              logger.log('script state changed to : ', script.readyStte);
              script.onreadystatechange = null;
              // handle the waiting proise queue
              this.handlePromiseQueue(s.src, null, true);
              resolve(s);
            }
          };
        } else {  // Others
          script.onload = () => {
            // s.loaded = true;
            s.status = 'loaded';
            // handle the waiting proise queue
            this.handlePromiseQueue(s.src, null, true);
            // return the current promise all well.
            resolve(s);
            // 
          };
        }
        script.onerror = (error: any) => {
          logger.log('script load error happened!');
          // s.loaded = false;
          s.status = 'not-found';
          // handle the promise the queue
          this.handlePromiseQueue(s.src, null, false);
          reject(s);
          // resolve(s);
        };
        document.getElementsByTagName('head')[0].appendChild(script);
      }
    });
  }

  private handlePromiseQueue(src: string, param: any, isResolved: boolean) {
    // no pending promise.
    if (this.promiseQueue[src] == null) {
      return;
    }
    let promise: IPromiseData;

    while ((promise = this.promiseQueue[src].pop()) !== undefined) {
      if (isResolved) {
        promise.resolve(param);
      } else {
        promise.reject(param);
      }
    }
    this.promiseQueue[src] = null;
    delete this.promiseQueue[src];
    logger.log('Here');
  }

}
