import { Address } from './address';
import {geohashForLocation} from 'geofire-common';

// import * as geofirex from 'geofirex';
// import firebase from 'firebase/app';


export function googlePlaceToAddress(place): Address {
  const address = new Address();
  // TODO GeoFirex
  // const geo: any = {}; // geofirex.init(firebase);

  if (place === undefined || place === null) {
    return;
  }
  address.lat = place.geometry.location.lat();
  address.long = place.geometry.location.lng();
  address.addressFormated = place.formatted_address;
  const d = geohashForLocation([address.lat, address.long]);
  address.geoLoc = {
    geohash: d,
    geopoint: {
      latitude: address.lat,
      longitude: address.long
    }
  };

  for (let i = 0; place.address_components.length > i; i += 1) {
    if (place.address_components[i].types[0] === 'street_number') {
      address.streetNumber = place.address_components[i].long_name;
    }
    if (place.address_components[i].types[0] === 'route') {
      address.streetName = place.address_components[i].long_name;
    }
    if (place.address_components[i].types[0] === 'locality' || place.address_components[i].types.includes('sublocality_level_1') || place.address_components[i].types.includes('administrative_area_level_3')) {
      address.city = place.address_components[i].long_name;
    }
    if (place.address_components[i].types[0] === 'administrative_area_level_1') {
      address.stateProv = place.address_components[i].short_name;
    }
    if (place.address_components[i].types[0] === 'country') {
      address.country = place.address_components[i].short_name;
    }
    if (place.address_components[i].types[0] === 'postal_code') {
      address.postalZipCode = place.address_components[i].short_name;
    }
  }
  return address;
}
