import { Injectable } from '@angular/core';
import { ILocalStorage } from '@trent/models/local-storage/local-storage';
import { promiseWraper } from '@trent/models/utility';
import { LocalStorageCap } from './local-storage-cap.service';
import { LocalStorageCdvaWeb } from './local-storage-cdva-web.service';
import { Storage } from '@ionic/storage';
import { Platform } from '@ionic/angular';
import { logger } from '@trentm/log/logger';

/**
 * Do not use this service calss directly, USE @class LocalStorage
 */
@Injectable()
export class LocalStorageMobileWeb implements ILocalStorage {
  private storage: ILocalStorage;

  get(key: string): Promise<any> {
    return this.storage.get(key);

  }

  set(key: string, value: any): Promise<any> {
    return this.storage.set(key, value);
  }

  remove(key: string): Promise<any> {
    return this.storage.remove(key);
  }

  clear(): Promise<void> {
    return this.storage.clear();
  }
  // length(): Promise<number> {
  //   return this.storage.length();
  // }

  keys(): Promise<string[]> {
    return this.storage.keys();
  }

  debug() {
    try {
      logger.log('[home storage] setting to storage, k = 5');

      this.set('k', '5').then(() => {
        logger.log('[home storage] getting key from storage');
        this.get('k').then((v) => logger.log('[home storage] got key: ', v));
      });

    } catch (error) {
      logger.log('[home storage] ERROR: ', error);

    }
  }

  async debugAsync() {
    try {
      logger.log('[home storage] setting ASYNC to storage, k = 5');
      const s = await promiseWraper(this.set('k', '5'));
      if (s.success) {
        logger.log('[home storage] getting ASYNC key from storage');
        const r = await promiseWraper(this.get('k'));
        if (r.success) {
          logger.log('[home storage] ASYNC  got key: ', r.data);
        } else {
          logger.log('[home storage] ASYNC ERROR: getting data ', r.error);
        }
      } else {
        logger.log('[home storage] ASYNC ERROR setting data ', s.error);
      }

    } catch (error) {
      logger.log('[home storage] ASYNC asyn try block error', error);
    }
  }

  constructor(storage: Storage, platform: Platform) {
    if (platform.is('capacitor')) {
      this.storage = new LocalStorageCap();
    } else {
      this.storage = new LocalStorageCdvaWeb(storage, platform);
    }
  }

}
