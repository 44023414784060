import { RentalProductBase } from './rental-product-base';
import { ProductType } from '../product/product-type';
import { instanceToInstance, plainToInstance, Expose, Exclude, Type } from 'class-transformer';
import { IsBoolean, IsDefined, ValidatorOptions } from 'class-validator';
import { TruckSummary } from '../product/truck';
import { sanitizeDateIPoint } from '../utility';
import { MapLabel } from '../map/map-label';
import { IValidationMsg } from '../error-handling/validation-info';
import { Picture } from '../inspection/picture';
import { BidBase } from '../bid/bid-base';

@Exclude()
export class RentalTruck extends RentalProductBase {
  constructor() {
    super();
    this.productType = ProductType.truck;
    this.pictures = [];

  }
  @Expose()
  @IsDefined()
  productSummary: TruckSummary;

  // @Expose()
  // @Type(() => Picture)
  // pictures: Picture[];

  // @Expose()
  // @IsDefined()
  // picRef = PicRef.new; // property for user to add new picture or copy from previous rental or select no pictures

  // @Expose()
  // @IsBoolean()
  // hasPicture: boolean; // property to query dB if pictures exist. property entered by functions if picture array length > 0

  /**https://bingmapsv8samples.azurewebsites.net/Tools/SVG%20Pushpin%20Maker/SVG%20Pushpin%20Maker.html */


  // tslint:disable-next-line:max-line-length
  // icon = '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="53.2" height="63" viewBox="0 0 38 45" xml:space="preserve"><rect x="0" y="8" rx="8" ry="8" width="30" height="30" fill="{color}"/><circle cx="30" cy="8" r="8" fill="{color}"/><polygon fill="{color}" points="10,36 20,36 15,45 10,36"/><image x="4" y="12" width="22" height="22" xlink:href="data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2230%22%20height%3D%2230%22%20viewBox%3D%220%200%2048%2048%22%20xml%3Aspace%3D%22preserve%22%3E%3Cpath%20d%3D%22M0%200h48v48h-48z%22%20fill%3D%22none%22%2F%3E%3Cpath%20d%3D%22M8%2032c0%201.77.78%203.34%202%204.44v3.56c0%201.1.9%202%202%202h2c1.11%200%202-.9%202-2v-2h16v2c0%201.1.89%202%202%202h2c1.1%200%202-.9%202-2v-3.56c1.22-1.1%202-2.67%202-4.44v-20c0-7-7.16-8-16-8s-16%201-16%208v20zm7%202c-1.66%200-3-1.34-3-3s1.34-3%203-3%203%201.34%203%203-1.34%203-3%203zm18%200c-1.66%200-3-1.34-3-3s1.34-3%203-3%203%201.34%203%203-1.34%203-3%203zm3-12h-24v-10h24v10z%22%20style%3D%22fill%3A%23666666%22%2F%3E%3C%2Fsvg%3E"/><text x="30" y="12" style="font-size:12px;font-family:arial;fill:#ffffff;" text-anchor="middle">{text}</text></svg>'


  public static parse(obj) {
    if (obj == null) { return null; }
    // if data was recieved from firebase server, it may contain GeoPoint class that throw error
    // when using planToClass method. So simply the object aagin to get rid of GeoPoint class.
    obj = sanitizeDateIPoint(obj);
    // obj = instanceToPlain(obj);
    const m = plainToInstance<RentalTruck, any>(RentalTruck, obj);
    m.sanitize();
    m.isReplacementUnit = m.isReplacementUnit === undefined ? false : m.isReplacementUnit;
    // m.isLegacy = m.isLegacy === undefined ? false : m.isLegacy;
    return m;
  }
  clone() {
    const t = instanceToInstance(this);
    t.sanitize();
    // t.isLegacy = t.isLegacy === undefined ? false : t.isLegacy;
    return t;
  }
  /** Update all attached documents paths from /draft/ to /rel/ */
  // to be updated when file documents are are added to rent Option, like cab card for Truck
  //  updateFilePathsToRelease(): IFileMoveData[] {
  //   const R: IFileMoveData[] = [];

  //   return R;
  //  }

  sanitize() {
    super.sanitize();
  }
  validateSync(options?: ValidatorOptions): IValidationMsg {
    const r = super.validateSync(options);

    // if (this.pictures?.length === 0) {
    //   r['pictures'] = ['add pictures'];
    // }
    return r;
  }
  /**
* Get Product Summary.
*/
  public getTruckSummary() {
    return {
      productSummary: this.productSummary,
    };
  }
  getMapLabel(): MapLabel {
    return {
      iPoint: this.startLoc.geopoint,
      // title: this.createTitle(),
      title: 'T',
      id: this.id,
      // icon: this.icon,
      rent: this.getLowestRentString(),
      productType: this.productSummary.productType

    };
  }
  get summary() {
    return `${(this.productSummary.isDayCab) ? 'Day Cab Truck, ' : ' Sleeper Truck, '}
      ${(this.productSummary.isIRP) ? 'IRP Plated, ' : 'ON Plated, '}` +
      `${(this.productSummary.isAuto) ? 'Automatic Transmission' : 'Manual Transmission'}`;
  }
  get rentSummary() {
    return `${(this.isOneWay) ? `'Pick Up From: ' ${(this.startAddress)}` : `'Drop off at: ' ${(this.endAddress)}`}`;
  }
  createTitle() {
    let title: string;
    switch (this.productSummary.isDayCab) {
      case false:

        title = (this.productSummary.make.split(' '))[0] + ' ' + this.productSummary.modelYear + ' ' + 'Day-Cab Truck';
        break;
      case true:
        title = (this.productSummary.make.split(' '))[0] + ' ' + this.productSummary.modelYear + ' ' + 'Sleeper Truck';
        break;
      default:
        title = null;
        break;
    }
    return title;

  }
  get defaultImage() {
      // to be extended for nAxle
      return './assets/black-truck-icon.svg';
      // return './assets/dry_van_400_250.png';
      
  }
}
