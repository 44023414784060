<!-- This is a container div that wraps around all contents of this section or webpage. -->
<div>
    <!-- This div contains a background image and a banner title with the text "About Dixie Dixie Truck Parts". -->
    <div class="background-image banner-title-wrapper">
        <h2 class="banner-title c-white">About Dixie Truck Parts</h2>
        <!-- This div contains two links, one for Home and another for About Dixie Truck Parts. -->
    </div>
    <!-- This div contains the content for the section, such as text, images, or other components. -->
    <div class="ck-content-wrapper">
        <!-- This div contains a sub-page component with a given pageId 'dpc-about-us'. -->
        <div class="ck-content">
            <trent-sub-page [pageId]="'dpc-about-us'" class="sub-page"> </trent-sub-page>
        </div>
    </div>
    <!-- This unordered list contains a single list item and a link to explore more. -->
    <!-- <ul>
        <li><a href="#">EXPLORE MORE</a></li>
    </ul> -->
    <!-- This div contains a video component with a given source and the ability to play, pause and control volume. -->
    <!-- <div class="video-wrapper">
        <div class="ck-content">
            <trent-sub-page [pageId]="'dpc-about-us-video'" class="sub-page"> </trent-sub-page>
        </div>

    </div> -->
</div>