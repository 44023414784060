import { StateBase } from './../state-base';
import { State, StateContext, Action, Selector } from '@ngxs/store';
import { CompanyService } from '@trent/services/company.service';
import { map } from 'rxjs/operators';
import { noop } from 'rxjs';
import * as entity from '../entity.state';
import { parseCompany } from '@trent/models/company/company-helper';
import { Injectable } from '@angular/core';
import { CompanyBase } from '@trent/models/company';
import { getObjKey, Paging } from '@trent/models/observable-util/paging';
import { logger } from '@trentm/log/logger';
import { buildDataRequest, getRootLevelChildren, IDataLoadStatus, LoadStatus, updatePaging } from '@trent/models/observable-util/data-status';
import { companySearchClientFilter, getICompanyOptionOrChildren, ICompanyParam } from '@trent/models/company/company-param';
import { PagingContainer } from '@trent/models/observable-util/paging-container';


// #region State Model

export interface CompanyStateModel {

  // New approach with paging
  compByUserLoaded: boolean;
  compByUser: { [id: string]: CompanyBase };  // changed
  /** Which client side queries are complete */
  dataLoadStatus: {
    [key: string]: IDataLoadStatus<ICompanyParam>;
  };
  allCompaniesLoaded: boolean;
  companies: { [id: string]: CompanyBase }; // entity.EntityState<BidBase>;
  companyHistory: { [id: string]: CompanyBase }; // entity.EntityState<BidBase>;

  // Old approach withoug paging.
  // allCompaniesLoaded: boolean;
  // companies: entity.EntityState<CompanyFleet>;
}

function initCompanyState() {
  return {
    compByUserLoaded: false,
    compByUser: {},
    allCompaniesLoaded: false,
    // companies: entity.init<CompanyBase>(),
    dataLoadStatus: {},
    companies: {},
    companyHistory: {}
  };
}

// #endregion

// #region Actions for new data methods with paging

export class GetCompByUserRequested {
  static readonly type = '[Companies] Get Companies for user - requested ( initial page)';
  constructor(public payload: { pData: Paging; param: any }) { }
}

export class GetCompByUserRequestedNextBatch {
  static readonly type = '[Companies] Get Companies for user - requested (next page)';
  constructor(public payload: { pData: Paging; param: any }) { }
}

export class GetCompByUserLoaded {
  static readonly type = '[Companies] Get Companies for user - loaded (paged)';
  constructor(public payload: { data: { [id: string]: CompanyBase } }) { }
}

// #endregionyou l

// #region actions
// export class AllCompanysRequested {
//   static readonly type = '[Companies] All Companies Requested';
//   constructor(public payload: { uid: string | number }) { }
// }
// export class AllCompanysLoaded {
//   static readonly type = '[Companies] All Companies Loaded';
//   constructor(public payload: { companies: CompanyFleet[] }) { }
// }

export class CompanyUpdated {
  static readonly type = '[Companies] Company Updated';
  constructor(public payload: { cid: string | number, company: CompanyBase }) { }
}

export class CompanyRequested {
  static readonly type = '[Company] Request a single company';
  constructor(public payload: { id: string | number }) { }
}
export class CompanyLoaded {
  static readonly type = '[Company] Load a single compnay entity';
  constructor(public payload: { id: string | number, data: CompanyBase }) { }
}

export class CompanyStateReset {
  static readonly type = '[Company] Reset State';
}
export class CompanyRemoveById {
  static readonly type = '[Company] Remove by Id';
  constructor(public payload: { cid: string | number }) { }
}
export class CompaniesRequested {
  static readonly type = '[Company] All Companies Requested';
  constructor(public payload: { pData: Paging, param: ICompanyParam }) { }
}
export class CompaniesLoaded {
  static readonly type = '[Company] All Companies Loaded';
  constructor(public payload: {
    data: { [id: string]: CompanyBase }, // Data
    key: string,
  }) { }
}
export class CompaniesNextPageRequested {
  static readonly type = '[Company] All Companies requested - Next Page';
  constructor(public payload: { option: ICompanyParam }) { }
}
export class CompaniesUpdated {
  static readonly type = '[Company] Companies Updated';
  constructor(public payload: { id: string | number, data: CompanyBase }) { }
}

// #endregion

@State<CompanyStateModel>({
  name: 'company',
  defaults: initCompanyState()
})
@Injectable()
export class CompanyState extends StateBase {

  /** Container that keep all of the subscription related to gettting data. */
  dataSubData: PagingContainer<CompanyBase, ICompanyParam> = new PagingContainer();

  constructor(private companyService: CompanyService) {
    super();
  }

  // #region Selectors

  /** Get Company list (no draft/rev entires are not included) */
  @Selector()
  static selectAllCompaniesOld(state: CompanyStateModel) {

    if (state.compByUser == null || Object.keys(state.compByUser).length === 0) {
      return [];
    }

    // remove keys that have revision/draft ids, i.e that contain '~' in the id.
    const keys = Object.keys(state.compByUser).filter(k =>
      k.indexOf('/') === -1 && state.compByUser[k] != null);

    // object without the rev/draft.
    const filtered = keys.reduce((obj, k) => {
      obj[k] = state.compByUser[k];
      return obj;
    }, {});

    const output = Object.values(filtered).map(x => parseCompany(x));

    return output;

    // OLD without paging, obsolete
    // return entity.map(state.companies, CompanyFleet.parse);
  }

  @Selector()
  static selectCompanyById(state: CompanyStateModel) {
    // new approach, use dictionary of company.
    return entity.getByIdFn_new(state.compByUser, parseCompany);

    // OLD approach
    // return entity.getByIdFn(state.companies, CompanyFleet.parse);
  }
  @Selector()
  static selectAllCompanies(state: CompanyStateModel) {
    return (o: ICompanyParam): CompanyBase[] => {

      if (state?.companies == null || Object.keys(state.companies).length === 0) {
        return [];
      }
      // remove keys that have revision/draft ids, i.e that contain '~' in the id.
      const keys = Object.keys(state.companies).filter(k =>
        k.indexOf('/') === -1 && state.companies[k] != null);
      // object without the rev/draft.
        const filtered = keys.reduce((obj, k) => {
          obj[k] = state.companies[k];
          return obj;
        }, {});

      let output = Object.values(filtered).map(x => parseCompany(x));
      output = companySearchClientFilter(output, o) ;

      return output;
    };
  }

  // #endregion

  @Action(GetCompByUserRequested)
  getCompByUserRequested(context: StateContext<CompanyStateModel>, action: GetCompByUserRequested) {
    if (!context.getState().compByUserLoaded) {
      const o = this.companyService.getCompaniesByUser1(action.payload.pData, action.payload.param)
        .pipe(
          map(c => {
            context.dispatch(new GetCompByUserLoaded({ data: c }));
            return c;
          }));
      this.subscribe(o, (x) => noop(), GetCompByUserRequested.type);
    }
  }

  @Action(GetCompByUserRequestedNextBatch)
  getCompByUserRequestedNextBatch(context: StateContext<CompanyStateModel>, action: GetCompByUserRequestedNextBatch) {
    if (!context.getState().compByUserLoaded) {
      this.companyService.allCompaines.nextBatch(action.payload.pData);
    }
  }

  @Action(GetCompByUserLoaded)
  getCompByUserLoaded(context: StateContext<CompanyStateModel>, action: GetCompByUserLoaded) {
    const state = context.getState();
    context.patchState({
      compByUserLoaded: true,
      compByUser: { ...state.compByUser, ...action.payload.data },
    });
  }


  // /** Depreciated  */
  // @Action(AllCompanysRequested)
  // allCompanysRequested(context: StateContext<CompanyStateModel>, action: AllCompanysRequested) {
  //   if (!context.getState().allCompaniesLoaded) {
  //     const o = this.companyService.getAllCompanies(action.payload.uid)
  //       .pipe(
  //         map(c => {
  //           const companies = (!!c) ? c : [];
  //           // logger.log('map was called');
  //           context.dispatch(new AllCompanysLoaded({ companies }));
  //           return c;
  //         }
  //         ));
  //     this.subscribe(o, (x) => noop(), AllCompanysRequested.type);
  //   }
  // }

  // /** Depreciated  */
  // @Action(AllCompanysLoaded)
  // allCompanysLoaded(context: StateContext<CompanyStateModel>, action: AllCompanysLoaded) {
  //   const state = context.getState();
  //   context.patchState({
  //     allCompaniesLoaded: true,
  //     companies: entity.addMany(state.companies, action.payload.companies)
  //   });
  // }

  @Action(CompanyRequested)
  companyRequested(context: StateContext<CompanyStateModel>, action: CompanyRequested) {
    const state = context.getState();
    const id = action.payload.id;
    if (state.compByUser[action.payload.id] == null) {
      // logger.log('comp by id, not found in the store, gettting from server....');
      const s = this.companyService.getCompanyById(action.payload.id)
        .pipe(
          map(data => {
            // const c = CompanyFleet.parse(data);
            // logger.log('Company Data was recived from server', data);
            return context.dispatch(new CompanyLoaded({ id, data }));
          }
          )); // .subscribe(noop);
      this.subscribe(s, (x) => noop(), CompanyRequested.type);
    }

    // OLD Approach.

    // if (state.companies.data.find(x => !!x && x.id === action.payload.id) == null) {
    //   // logger.log(`Rerequested company id: [${action.payload.id}] is not in the store. sending request to server`);
    //   this.companyService.getCompanyById(action.payload.id)
    //     .pipe(
    //       map(data => {
    //         // const c = CompanyFleet.parse(data);
    //         // logger.log('Company Data was recived from server', data);
    //         return context.dispatch(new CompanyLoaded({ data }));
    //       }
    //       )).subscribe(noop);
    // } else {
    //   // logger.log(`Rerequested company id: [${action.payload.id}] is available in the store`);
    // }
  }

  @Action(CompanyLoaded)
  companyLoaded(context: StateContext<CompanyStateModel>, action: CompanyLoaded) {
    const state = context.getState();
    const c = {};
    c[action.payload.data?.id] = action.payload.data;
    context.patchState({
      compByUser: { ...state.compByUser, ...c }
      // compByUser: { ...state.compByUser, ...{ [action.payload.id]: action.payload.data } }
    });

    // OLD approach
    // context.patchState({
    //   companies: entity.addOne(state.companies, action.payload.data)
    // });
  }


  @Action(CompanyUpdated)
  companyUpdated(context: StateContext<CompanyStateModel>, action: CompanyUpdated) {
    const state = context.getState();
    let c = action.payload.company;
    if (typeof (c.toFirebaseObj()) === 'function') {
      c = c.toFirebaseObj();
    }
    context.patchState({
      compByUser: { ...state.compByUser, ...{ [action.payload.cid]: c } }
    });
  }


  @Action(CompanyStateReset)
  companyStateReset(context: StateContext<CompanyStateModel>, action: CompanyStateReset) {
    // unsubscribe the data
    this.clearSubscriptions();
    context.setState(initCompanyState());
  }
  @Action(CompanyRemoveById)
  dataByIdRemoved(context: StateContext<CompanyStateModel>, action: CompanyRemoveById) {
    const state = context.getState();
    if (Object.keys(state.compByUser).indexOf(`${action.payload.cid}`) > -1) {
      const currState = context.getState();
      const newData = { ...currState.compByUser };
      delete newData[action.payload.cid];
      context.patchState({ compByUser: newData });
      const state1 = context.getState();
      logger.log('[Company-State], item removed by id', action.payload.cid, state1);

    } else { logger.log('[Company-State], item to be removed id is not available in the store'); }
  }
  // #region All Records
  @Action(CompaniesRequested)
  dataRequested(context: StateContext<CompanyStateModel>, action: CompaniesRequested) {

    const oKey = getObjKey(action.payload.param);

    // Define function that return the data status object from the state.
    const getDataStatusStateFn = () => context.getState().dataLoadStatus;

    /** custom build the OR children query. */
    const buildOrQueryChildrenFn = (o: ICompanyParam) => getICompanyOptionOrChildren(o);

    // if data requested now, is already partially loaded by another query's child previously
    // but the number of the items are not enough (ex. as a child, it loaded only 5, but current
    // request ask for more, then next page of that query should be called instead.)
    const nextPageFn = (option: ICompanyParam) => {
      context.dispatch(new CompaniesNextPageRequested({ option }));
    };

    buildDataRequest(
      oKey, action.payload.param, action.payload.pData,
      getDataStatusStateFn,
      buildOrQueryChildrenFn,
      nextPageFn,
      (
        obj: { [key: string]: IDataLoadStatus<ICompanyParam> },
        set: { key: string, node: IDataLoadStatus<ICompanyParam> }[]
      ) => {

        if (!!obj) {
          // Patch the state.
          const state = context.getState();
          context.patchState({
            dataLoadStatus: { ...state.dataLoadStatus, ...obj }
          });
        }

        // Process the query.
        set.forEach((val) => {
          // some of the nodes are already loaded. Only process that are loading... status.
          if (val.node.loadStatus !== LoadStatus.Loading) {
            return;
          }
          // if this request is just overwriting a stall or pending request, unsubscribe that observable
          this.dataSubData.unsubscribe(val.key);

          // create the paging observable and call db.
          const p = this.companyService.getAllCompanies_PagingObservable();
          const prod$ = p.getData(action.payload.pData, val.node.param)
            .pipe(
              map(pickDrops => {
                // if (!val.node.param.pdIdForHistory) {
                context.dispatch(new CompaniesLoaded({
                  data: pickDrops as any,
                  key: val.key
                }));
                // } else {
                //   context.dispatch(new AllPickDropHistoryLoaded({
                //     data: pickDrops,
                //     key: val.key
                //   }));
                // }
                return pickDrops;
              }));
          const sub = this.subscribe(prod$, () => noop(), CompaniesRequested.type);
          // save the observable call
          this.dataSubData.addData(val.key, sub, p);
        });
      }
    );
  }
  @Action(CompaniesLoaded)
  companiesLoaded(context: StateContext<CompanyStateModel>, action: CompaniesLoaded) {
    const state = context.getState();
    const subData = this.dataSubData.getData(action.payload.key);
    const updatedLoadStatus = updatePaging(action.payload.key, state.dataLoadStatus, subData);
    context.patchState({
      allCompaniesLoaded: true,
      dataLoadStatus: updatedLoadStatus,
      companies: { ...state.companies, ...action.payload.data } // entity.addMany(state.bids, action.payload.bids)
    });
  }
  @Action(CompaniesNextPageRequested)
  allCompaniesNextPageRequested(context: StateContext<CompanyStateModel>, action: CompaniesNextPageRequested) {
    const oKey = getObjKey(action.payload.option);
    const state = context.getState();
    // find the node. can be parent or child
    const statusObj = state.dataLoadStatus[oKey];
    // if no parent, treat is
    if (statusObj.children == null) {
      this.dataSubData.dispatchNextPagingUpdate(oKey);
    } else {
      const children = getRootLevelChildren(oKey, state.dataLoadStatus);
      children.forEach(c => {
        this.dataSubData.dispatchNextPagingUpdate(c.key);
      });
    }
  }
}
