import { getRandomId } from './../../../../../../libs/models/utility/helper';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { IEventListener, EventService } from '@trent/services/event.service';
import { AlertBarData } from '@trent/models/UI/menu-data';
import { Subscription } from 'rxjs';
import { cleanListeners } from '@trent/models/utility/helper';
import { logger } from '@trent/models/log/logger';

@Component({
  selector: 'trent-alert-bar',
  templateUrl: './alert-bar.component.html',
  styleUrls: ['./alert-bar.component.scss']
})
export class AlertBarComponent implements OnInit, OnDestroy {

  msgDic: { [id: string]: AlertBarData } = {};

  messages: AlertBarData[] = [];

  public eventListners: IEventListener[] = [];
  sub: Subscription[] = [];

  constructor(private es: EventService) { }

  ngOnInit(): void {
    // back button dispaly request from page
    this.eventListners.push(
      this.es.listen<AlertBarData>(this.es.showAlertBar, (data) => {
        const id = getRandomId(this.msgDic);
        data.id = id;
        this.msgDic[id] = data;
        this.messages.push(data);
        if (typeof data.showCallBackFn === 'function') {
          data.showCallBackFn(id);
        }
      })
    );

    // hide message
    this.eventListners.push(
      this.es.listen<string>(this.es.hideAlertBar, (id) => this.hideAlert(id))
    );
  }

  hideAlert(id) {
    logger.log('[Alert Bar ] Hide Called');
    const row = this.msgDic[id];
    if (!!row) {
      if (typeof row.closeClickFn === 'function') {
        row.closeClickFn(id);
      }
      delete this.msgDic[id];
      this.messages = Object.values(this.msgDic);
    }
  }

  ngOnDestroy(): void {
    cleanListeners(this.eventListners, this.sub);
  }

  // getUrl(u) { return u.spl}
  getQueryParam(u: string) {
    const q = {};
    if (u.split('?').length > 0) {
      const p = u.split('?')[1].split('&');
      // eslint-disable-next-line guard-for-in
      for (const key in p) {
        const element = p[key].split('=');
        if (element.length > 1) {
          q[element[0]] = element[1];
        }
      }
    }
    return q;
  }
}
