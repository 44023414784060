import { StateBase } from '../state-base';
import { State, StateContext, Action, Selector } from '@ngxs/store';
import { map } from 'rxjs/operators';
import { noop } from 'rxjs';
import * as entity from '../entity.state';
import { Injectable } from '@angular/core';
import { getObjKey, Paging } from '@trent/models/observable-util/paging';
import { logger } from '@trentm/log/logger';
import { buildDataRequest, getRootLevelChildren, IDataLoadStatus, LoadStatus, updatePaging } from '@trent/models/observable-util/data-status';
import { PagingContainer } from '@trent/models/observable-util/paging-container';
import { StoreLocation } from '@trent/models/store-location/store-location';
import { StoreLocationService } from '@trent/services/store-location.service';
import { parseStoreLocation } from '@trent/models/store-location/store-location-helper';
import { getStoreLocationOptionOrChildren, StoreLocationParam, storeLocationSearchClientFilter } from '@trent/models/store-location/store-location-param';

// #region State Model
export interface StoreLocationStateModel {

  // New approach with paging
  storeLocationByUserLoaded: boolean;
  storeLocationByUser: { [id: string]: StoreLocation };  // changed

  /** Which client side queries are complete */
  dataLoadStatus: {
    [key: string]: IDataLoadStatus<StoreLocationParam>;
  };
  allStoreLocationsLoaded: boolean;
  storeLocations: { [id: string]: StoreLocation }; // entity.EntityState<BidBase>;
}

function initStoreLocationState() {
  return {
    storeLocationByUserLoaded: false,
    storeLocationByUser: {},
    allStoreLocationsLoaded: false,
    dataLoadStatus: {},
    storeLocations: {},
  };
}

// #endregion

// #region Actions for new data methods with paging

export class GetStoreLocationByUserRequested {
  static readonly type = '[StoreLocations] Get Store Locations for user - requested ( initial page)';
  constructor(public payload: { pData: Paging; param: any }) { }
}

export class StoreLocationUpdated {
  static readonly type = '[StoreLocations] Store Location Updated';
  constructor(public payload: { sid: string | number, storeLocation: StoreLocation }) { }
}

export class StoreLocationRequested {
  static readonly type = '[StoreLocation] Request a single store location';
  constructor(public payload: { id: string | number }) { }
}
export class StoreLocationLoaded {
  static readonly type = '[StoreLocation] Load a single store location entity';
  constructor(public payload: { id: string | number, data: StoreLocation }) { }
}

export class StoreLocationStateReset {
  static readonly type = '[StoreLocation] Reset State';
}
export class StoreLocationRemoveById {
  static readonly type = '[StoreLocation] Remove by Id';
  constructor(public payload: { sid: string | number }) { }
}
export class StoreLocationsRequested {
  static readonly type = '[StoreLocations] All Store Locations Requested';
  constructor(public payload: { pData: Paging, param: StoreLocationParam }) { }
}
export class StoreLocationsLoaded {
  static readonly type = '[StoreLocation] All Store Locations Loaded';
  constructor(public payload: {
    data: { [id: string]: StoreLocation }, // Data
    key: string,
  }) { }
}
export class StoreLocationsNextPageRequested {
  static readonly type = '[StoreLocation] All Store Locations requested - Next Page';
  constructor(public payload: { option: StoreLocationParam }) { }
}
export class StoreLocationsUpdated {
  static readonly type = '[StoreLocation] Store Locations Updated';
  constructor(public payload: { id: string | number, data: StoreLocation }) { }
}
export class GetStoreLocationByUserLoaded {
  static readonly type = '[StoreLocation] Get Store Locations for user - loaded (paged)';
  constructor(public payload: { data: { [id: string]: StoreLocation } }) { }
}

// #endregion

@State<StoreLocationStateModel>({
  name: 'storeLocation',
  defaults: initStoreLocationState()
})
@Injectable()
export class StoreLocationState extends StateBase {

  /** Container that keep all of the subscription related to gettting data. */
  dataSubData: PagingContainer<StoreLocation, StoreLocationParam> = new PagingContainer();

  constructor(private storeLocationService: StoreLocationService) {
    super();
  }

  // #region Selectors

  /** Get StoreLocation list (no draft/rev entires are not included) */
  @Selector()
  static selectAllStoreLocationsOld(state: StoreLocationStateModel) {

    // remove keys that have revision/draft ids, i.e that contain '~' in the id.
    const keys = Object.keys(state.storeLocationByUser).filter(k =>
      k.indexOf('/') === -1 && state.storeLocationByUser[k] != null);

    // object without the rev/draft.
    const filtered = keys.reduce((obj, k) => {
      obj[k] = state.storeLocationByUser[k];
      return obj;
    }, {});

    const output = Object.values(filtered).map(x => parseStoreLocation(x));

    return output;
  }

  @Selector()
  static selectStoreLocationById(state: StoreLocationStateModel) {
    // new approach, use dictionary of company.
    return entity.getByIdFn_new(state.storeLocationByUser, parseStoreLocation);
  }

  @Selector()
  static selectAllStoreLocations(state: StoreLocationStateModel) {
    return (o: StoreLocationParam): StoreLocation[] => {
      

      if (state?.storeLocations == null || Object.keys(state.storeLocations).length === 0) {
        return [];
      }

      // remove keys that have revision/draft ids, i.e that contain '~' in the id.
      const keys = Object.keys(state.storeLocations).filter(k =>
        k.indexOf('/') === -1 && state.storeLocations[k] != null);
        
      // object without the rev/draft.
      const filtered = keys.reduce((obj, k) => {
        obj[k] = state.storeLocations[k];
        return obj;
      }, {});

      let output = Object.values(filtered).map(x => parseStoreLocation(x));
      output = storeLocationSearchClientFilter(output, o);

      return output;
    };
  }

  // #endregion

  @Action(GetStoreLocationByUserLoaded)
  getStoreLocationByUserLoaded(context: StateContext<StoreLocationStateModel>, action: GetStoreLocationByUserLoaded) {
    const state = context.getState();
    context.patchState({
      storeLocationByUserLoaded: true,
      storeLocationByUser: { ...state.storeLocationByUser, ...action.payload.data },
    });
  }

  @Action(StoreLocationRequested)
  storeLocationRequested(context: StateContext<StoreLocationStateModel>, action: StoreLocationRequested) {
    const state = context.getState();
    const id = action.payload.id;
    if (state.storeLocationByUser[action.payload.id] == null) {
      // logger.log('comp by id, not found in the store, gettting from server....');
      const s = this.storeLocationService.gedStoreLocationById(action.payload.id)
        .pipe(
          map(data => {
            return context.dispatch(new StoreLocationLoaded({ id, data }));
          }
          ));
      this.subscribe(s, (x) => noop(), StoreLocationRequested.type);
    }
  }

  @Action(StoreLocationLoaded)
  storeLocationLoaded(context: StateContext<StoreLocationStateModel>, action: StoreLocationLoaded) {
    const state = context.getState();
    const c = {};
    c[action.payload.data?.id] = action.payload.data;
    context.patchState({
      storeLocationByUser: { ...state.storeLocationByUser, ...c }
    });
  }


  @Action(StoreLocationUpdated)
  storeLocationUpdated(context: StateContext<StoreLocationStateModel>, action: StoreLocationUpdated) {
    const state = context.getState();
    let c = action.payload.storeLocation;
    if (typeof (c.toFirebaseObj()) === 'function') {
      c = c.toFirebaseObj();
    }
    context.patchState({
      storeLocationByUser: { ...state.storeLocationByUser, ...{ [action.payload.sid]: c } }
    });
  }


  @Action(StoreLocationStateReset)
  storeLocationStateReset(context: StateContext<StoreLocationStateModel>, action: StoreLocationStateReset) {
    // unsubscribe the data
    this.clearSubscriptions();
    context.setState(initStoreLocationState());
  }

  @Action(StoreLocationRemoveById)
  dataByIdRemoved(context: StateContext<StoreLocationStateModel>, action: StoreLocationRemoveById) {
    const state = context.getState();
    if (Object.keys(state.storeLocationByUser).indexOf(`${action.payload.sid}`) > -1) {
      const currState = context.getState();
      const newData = { ...currState.storeLocationByUser };
      delete newData[action.payload.sid];
      context.patchState({ storeLocationByUser: newData });
      const state1 = context.getState();
      logger.log('[StoreLocation-State], item removed by id', action.payload.sid, state1);

    } else { logger.log('[StoreLocation-State], item to be removed id is not available in the store'); }
  }
  // #region All Records
  @Action(StoreLocationsRequested)
  dataRequested(context: StateContext<StoreLocationStateModel>, action: StoreLocationsRequested) {

    const oKey = getObjKey(action.payload.param);

    // Define function that return the data status object from the state.
    const getDataStatusStateFn = () => context.getState().dataLoadStatus;

    /** custom build the OR children query. */
    const buildOrQueryChildrenFn = (o: StoreLocationParam) => getStoreLocationOptionOrChildren(o);

    // if data requested now, is already partially loaded by another query's child previously
    // but the number of the items are not enough (ex. as a child, it loaded only 5, but current
    // request ask for more, then next page of that query should be called instead.)
    const nextPageFn = (option: StoreLocationParam) => {
      context.dispatch(new StoreLocationsNextPageRequested({ option }));
    };

    buildDataRequest(
      oKey, action.payload.param, action.payload.pData,
      getDataStatusStateFn,
      buildOrQueryChildrenFn,
      nextPageFn,
      (
        obj: { [key: string]: IDataLoadStatus<StoreLocationParam> },
        set: { key: string, node: IDataLoadStatus<StoreLocationParam> }[]
      ) => {

        if (!!obj) {
          // Patch the state.
          const state = context.getState();
          context.patchState({
            dataLoadStatus: { ...state.dataLoadStatus, ...obj }
          });
        }

        // Process the query.
        set.forEach((val) => {
          // some of the nodes are already loaded. Only process that are loading... status.
          if (val.node.loadStatus !== LoadStatus.Loading) {
            return;
          }
          // if this request is just overwriting a stall or pending request, unsubscribe that observable
          this.dataSubData.unsubscribe(val.key);

          // create the paging observable and call db.
          const p = this.storeLocationService.getAllStoreLocations_PagingObservable();
          const prod$ = p.getData(action.payload.pData, val.node.param)
            .pipe(
              map(pickDrops => {
                context.dispatch(new StoreLocationsLoaded({
                  data: pickDrops as any,
                  key: val.key
                }));
                return pickDrops;
              }));
          const sub = this.subscribe(prod$, () => noop(), StoreLocationRequested.type);
          // save the observable call
          this.dataSubData.addData(val.key, sub, p);
        });
      }
    );
  }

  @Action(StoreLocationsLoaded)
  storeLocationsLoaded(context: StateContext<StoreLocationStateModel>, action: StoreLocationsLoaded) {
    const state = context.getState();
    const subData = this.dataSubData.getData(action.payload.key);
    const updatedLoadStatus = updatePaging(action.payload.key, state.dataLoadStatus, subData);
    context.patchState({
      allStoreLocationsLoaded: true,
      dataLoadStatus: updatedLoadStatus,
      storeLocations: { ...state.storeLocations, ...action.payload.data } // entity.addMany(state.bids, action.payload.bids)
    });
  }

  @Action(StoreLocationsNextPageRequested)
  allCompaniesNextPageRequested(context: StateContext<StoreLocationStateModel>, action: StoreLocationsNextPageRequested) {
    const oKey = getObjKey(action.payload.option);
    const state = context.getState();
    // find the node. can be parent or child
    const statusObj = state.dataLoadStatus[oKey];
    // if no parent, treat is
    if (statusObj.children == null) {
      this.dataSubData.dispatchNextPagingUpdate(oKey);
    } else {
      const children = getRootLevelChildren(oKey, state.dataLoadStatus);
      children.forEach(c => {
        this.dataSubData.dispatchNextPagingUpdate(c.key);
      });
    }
  }
}
