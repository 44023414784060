import { NgModule } from '@angular/core';
import { GooglePLacesAutoDirective } from './google-places-auto.directive';
import { PhoneMaskDirective } from './phone-mask.directive';


@NgModule({
  imports:      [   ],
  declarations: [ GooglePLacesAutoDirective ],
  exports: [
    
    GooglePLacesAutoDirective
  ]
})
export class GooglePLacesAutoDirectiveModule { }
