import { DbStatus } from '../base/db-status';
import { IKeyVal, getObjKey, getDirection, Paging } from '../observable-util/paging';
import { DeepCopy } from '../utility/deep-copy';
import { isAfter, isBefore } from 'date-fns';
import { DbRule } from '../base/db-rule';
import { CollectionReference, limit, orderBy, query, Query, QueryConstraint, startAfter, where } from 'firebase/firestore';
import { SparePart, SparePartCategory, SparePartSortByCategory } from './spare-part';
import { logger } from '../log/logger';

/**
 * @author - MKN
 * Interface for query data from DB and client side filter
 */

export interface SparePartParam {
  category?: SparePartCategory;
  sortBy?: SparePartSortByCategory;
  orderBy?: string;
  dbStatus?: DbStatus;
  createdAt?: Date;
  sparePartIdForHistory?: string | number;
  sparePartId?: string | number;
  size?: [string];
  priceRange?: {from: number, to: number};
  categories?: [SparePartCategory];
  sid?: string | number; // Store location id
	sidArray?: [string | number];
  id?: string | number; // Spare Part Id
}

export const sparePartSearchOrderBy: IKeyVal[] = [
  { key: 'createdAt to Date', value: `createdAt desc` },
];

/** Get the OR condition options. if there are more then one company id's are provided
 *  each cid makes up a unique OR condition query. */
export const getSparePartSearchOptOrChildren = (o: SparePartParam): { [key: string]: SparePartParam } => {
  
  return undefined;
};
/** Server filtering of firebase query building of query  */
export const sparePartSearchServerQuery = (ref: CollectionReference, o: SparePartParam, p: Paging) => {

  const cons: QueryConstraint[] = [];
  const dbStatus = DbStatus;
  if (!!o.dbStatus) {
    cons.push(where('dbStatus', '==', o.dbStatus));
  }
  if (!!o.category && o.category !== <any>'*') {
    cons.push(where('category', '==', +o.category));
  }
  if (!!o.createdAt) {
    // tslint:disable-next-line:max-line-length
    cons.push(where('createdAt', '>', o.createdAt)); 
  }
  
  if (o.orderBy === 'createdAt desc') {
    const { col, dir } = getDirection(sparePartSearchOrderBy, (!!o.orderBy) ? o.orderBy : 'createdAt desc');
    cons.push(orderBy(col, dir));
    if (!!p.lastDoc) {
      console.log('last doc: ', p.lastDoc[col]);
      cons.push(startAfter(p.lastDoc[col]));
    }
  }

  if (o.orderBy === 'revId desc') {
    const { col, dir } = getDirection(sparePartSearchOrderBy, (!!o.orderBy) ? o.orderBy : 'revId desc');
    cons.push(orderBy(col, dir));
    if (!!p.lastDoc) {
      console.log('last doc: ', p.lastDoc[col]);
      cons.push(startAfter(p.lastDoc[col]));
    }
  }

  if (!!o.priceRange) {
      cons.push(where('price', '>=', o.priceRange.from));
      cons.push(where('price', '<=', o.priceRange.to));
	}

  if (!!o.categories && o.categories.length > 0) {
		cons.push(where('category', 'in', o.categories));
	}

  if (!!o.sid && o.sid !== 'x') {
		cons.push(where('storeLocationSummary.sid', '==', o.sid));
	}

	if (!!o.sidArray && o.sidArray.length > 0) {
		cons.push(where('storeLocationSummary.sid', 'in', o.sidArray));
	}

  if (!!o.id && o.id !== 'x') {
		cons.push(where('id', '==', o.id));
	}

  cons.push(limit(p.size));
  return query(ref, ...cons);
};

/** Client Filtering of the data */
export const sparePartSearchClientFilter = (p: SparePart[], o: SparePartParam): SparePart[] => {
  // client filter to set spare part expired status, not yet cleaned by cronJob
  
  // filter by dbStatus
  if (!!o.dbStatus) {
    p = p.filter((val, idx) => val.dbStatus === +o.dbStatus);
  }

  // filter by category.
  // if (o.category != null && <any>o.category !== '*') {
  //   p = p.filter((val, idx) => val.category === +o.category);
  // }
 
  if(o.orderBy === 'createdAt desc'){
    p = p.sort((a,b) => b.createdAt.valueOf() - a.createdAt.valueOf());
  }

  // Filter by categories array
	// if (o.categories) {
	// 	p = p.filter((val, idx) => o.categories.indexOf(val.category) > -1);
	// }

  // Filter by price
	if (o.priceRange) {
    p = p.filter((val, idx) => (val.price >= o.priceRange.from) && (val.price <= o.priceRange.to));
	}

  // Filter by sid
	if (o.sid) {
		p = p.filter((val, idx) => val.storeLocationSummary.sid === o.sid);
	}
  
	// Filter by sid array
	if (o.sidArray) {
		p = p.filter((val, idx) => o.sidArray.indexOf(val.storeLocationSummary.sid) > -1);
	}

   // Filter by Spare Part Id
	if (o.id) {
		p = p.filter((val, idx) => val.id === o.id);
	}
  return p;
};