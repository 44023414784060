import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RentalHeaderComponent } from './rental-header.component';
import { MaterialModule } from '../share-module/material.module';



@NgModule({
  declarations: [RentalHeaderComponent],
  imports: [
    CommonModule,
    MaterialModule
  ],
  exports: [
    RentalHeaderComponent
  ]
})
export class RentalHeaderModule { }
