import { IPymtInitiateParamB, ITransactionRefData } from '../finance/bambora/i-bambora';
import { plainToInstance, Exclude, Expose, instanceToInstance, instanceToPlain } from 'class-transformer';
import { TransactionType, TransactionTypeBambora } from './transaction-type';
import { sanitizeDate, cleanupUndefined, firestoreMap } from '../utility';
import { getTransactionRefData } from '../finance/bambora/i-bambora';
import { InvoiceItem } from '../finance/invoice/invoice-item';
import { IValidationMsg } from '../error-handling';
import { IPymtReturnData, PaymentAction } from '../finance/order/order-process-data';
import { sumBy } from 'lodash';
import { ITransactionSummaryBase, TransactionBase } from './transaction-base';
import { IPymtSchedule } from '../finance/invoice/i-item';

export interface TransactionSummary extends ITransactionRefData, ITransactionSummaryBase {
  /** firebase transaction record id */
  id: string | number;

  /** Bambora server transaction id */
  vendorTransId: string;

  approved: number;

  created: Date;

  /** order number to bambora is firestore document id of the Transaction */
  vendorOrderNumber: string;

  amount: number;

  payment_method: string;

  card: { card_type: string; last_four: string };

  type: TransactionTypeBambora;

  parentTransactionId?: string;
}


/**
 * https://dev.na.bambora.com/docs/references/payment_APIs/v1-0-5/
 * 
 */
@Exclude()
export class TransactionB extends TransactionBase {

  // static readonly collectionName = 'transaction';

  id: string | number;

  /** Bambora transaction id mapped to the response id. to ensure transcation id does not clash with 
   * firebase record id. */
  @Expose()
  transactionId: string;

  @Expose()
  transactionType: TransactionType;

  @Expose()
  authorizing_merchant_id: number;

  // @Expose()
  // approved: number;

  @Expose()
  message_id: number;

  @Expose()
  message: string;

  @Expose()
  auth_code: string;

  // @Expose()
  // created: Date;

  @Expose()
  order_number: string;

  @Expose()
  type: TransactionTypeBambora;  // string;

  @Expose()
  risk_score: number;

  @Expose()
  amount: number;

  @Expose()
  payment_method: string;

  @Expose()
  custom?: { ref1?: string; ref2?: string; ref3?: string; ref4?: string; ref5?: string; };

  @Expose()
  card: { card_type: string; last_four: string };

  get summary(): TransactionSummary {
    const r = this.getTransactionRefData();
    return {
      id: this.id,
      vendorTransId: this.transactionId,
      approved: this.approved,
      created: this.created,
      vendorOrderNumber: this.order_number,
      amount: this.amount,
      payment_method: this.payment_method,
      card: { ...this.card },
      type: this.type,
      parentTransactionId: r?.parentTransactionId,
      paymentService: this.paymentService
    };
  }

  static parse(obj: any) {
    if (obj == null) { return null; }
    const m = plainToInstance<TransactionB, any>(TransactionB, obj);

    /** Transaction ID is the id on the object. realigned to ensure it does not get overwritten
     * by firebase record id.
     */
    m.transactionId = obj.id;
    m.transactionType = TransactionType.TokenB;
    m.approved = +m.approved;
    return m;
  }

  /** Validate custom payment amount to the orignal transaction based on payment action
   * @param oT Orignal Transaction summary
   * @param iItems new invoice items beign generated for custom payment
   * @param action Payment Action
   */
  public static validateCustomPayment(oT: TransactionSummary, iItems: InvoiceItem[], action: PaymentAction): IValidationMsg {
    const r: IValidationMsg = {};
    const totalNewTranaction = sumBy(iItems, a => a.totalAmount);
    switch (action) {
      case 'return':
        if (totalNewTranaction > oT.amount) {
          r['return'] = [`Return amount cannot be more ${oT.amount}`];
        }
        break;
      case 'voidPayment':
        if (totalNewTranaction !== oT.amount) {
          r['return'] = [`Void payment amount should be ${oT.amount}`];
        }
        break;

      default:
        break;
    }
    return r;
  }
  public static validateReturnPayment(oT: TransactionSummary, amount: number, action: PaymentAction): IValidationMsg {
    const r: IValidationMsg = {};
    switch (action) {
      case 'return':
        if (amount > oT.amount) {
          r['return'] = [`Return amount cannot be more ${oT.amount}`];
        }
        break;
      case 'voidPayment':
        if (amount !== oT.amount) {
          r['return'] = [`Void payment amount should be ${oT.amount}`];
        }
        break;

      default:
        break;
    }
    return r;
  }

  public static createTransactionBForUI(d: IPymtReturnData, card: {card_type: string;
    last_four: string;}): TransactionB {
    const t = new TransactionB();
    const r = t.getTransactionRefData();

    t.id = null;
    t.transactionId = 'BamboraTransactionId ';
    t.approved = 1;
    t.created = new Date();
    t.order_number = 'NewFirebaseId';
    t.amount = d.returnAmount;
    t.payment_method = 'CC';
    t.card = card;
    t.type = 'R';
    t.paymentService = 'Bambora';

    return t;

  }
  constructor() {
    super();
    this.paymentService = 'Bambora';

  }
  clone() {
    const t = instanceToInstance(this);
    t.sanitize();
    return t;
  }
  sanitize() {
    // super.sanitize();
    this.created = sanitizeDate(this.created);
  }

  public getTransactionRefData() {
    const o = this as any;
    return getTransactionRefData(o as IPymtInitiateParamB);
  }

  public toFirebaseObj(timeStampCreate?: boolean,
    getGeoPointFn?: (obj: any) => any, ignore?: string[]) {

    // user class to plain
    let obj = instanceToPlain(this);
    obj = cleanupUndefined(obj);

    const a = (!!ignore && ignore.length > 0) ? Array.prototype.push.apply(['id'], ignore) : ['id'];
    const o = firestoreMap(obj, a);

    return o;
  }
  setTransactionBFromSummary(tSummary: TransactionSummary) {
    this.id = tSummary.id;
    this.approved = tSummary.approved;
    this.created = tSummary.created;
    this.order_number = tSummary.vendorOrderNumber;
    this.amount = tSummary.amount;
    this.payment_method = tSummary.payment_method;
    this.card = tSummary.card;
    this.custom = tSummary.parentTransactionId ? {ref4: tSummary.parentTransactionId} : null;
    this.paymentService = tSummary.paymentService;
    this.transactionId = tSummary.vendorTransId;
    this.type = tSummary.type;
  }
}

//   amount: 10
  // approved: "1"
  // auth_code: "TEST"
  // authorizing_merchant_id: 300207344
  // card:
  // address_match: 0
  // avs:
  // id: "U"
  // message: "Address information is unavailable."
  // processed: false
  // __proto__: Object
  // avs_result: "0"
  // card_type: "MC"
  // cvd_result: "1"
  // last_four: "1004"
  // postal_result: 0
  // __proto__: Object
  // created: "2019-10-09T20:38:46"
  // custom:
  // ref1: ""
  // ref2: ""
  // ref3: ""
  // ref4: ""
  // ref5: ""
  // __proto__: Object
  // id: "10000003"
  // links: Array(2)
  // 0: {rel: "void", href: "https://www.beanstream.com/api/v1/payments/10000003/void", method: "POST"}
  // 1: {rel: "return", href: "https://www.beanstream.com/api/v1/payments/10000003/returns", method: "POST"}
  // length: 2
  // __proto__: Array(0)
  // message: "Approved"
  // message_id: "1"
  // order_number: "order-abc1234"
  // payment_method: "CC"
  // risk_score: 0
  // type: "P"
