import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsModule } from '@ngxs/store';
import { BidState } from './bid.state';
// import { BidService } from '../../services/bid.service';

@NgModule({
    imports: [
      CommonModule,
      NgxsModule.forFeature([BidState])
    ],
    providers: [
       // BidService,
      ]
  })
  export class BidStoreModule { }
