import { PageHtml, PageHtmlSection } from "./page-html";

/**
 * Decorator that defines a section for a property. This decorator will store a static prototype object
 * dictionary for the properties whose html controls need to be auto generated in the UI Form
 * @param section - The section attributes.
 * @returns The property decorator function.
 */
export function sectionDef(section: PageHtmlSection): PropertyDecorator {
  return function (target: any, key: string | symbol) {
    // console.log(`section Dev called for property : ${key.toString()}`);
    target.constructor.prototype._section = target.constructor.prototype._section || {};

    const sectionName = key.toString();
    const classSection: PageHtmlSection = {
      ...section
    };
    target.constructor.prototype._section = {
      ...target.constructor.prototype._section,
      [sectionName]: classSection,
    };
  };
}

/**
 * it copies the global static (prototype) _section properties, that are built by the @sectionDef decorator, in to a local 
 * property (autoSection) of the instance with proper getter and setter values. 
 * Note: This function must be called from the constructor of the PageHtml and its extended classes.
 * @param clazz like @class PageHtml or its extended form.
 * @param this$ instance that need to be updated.
 */
export function globalToInstance<T extends PageHtml>(clazz: new () => T, this$: T) {
  Object.entries(clazz.prototype['_section']).forEach(([key, value]) => {
    const s = {
      ...(value as any),
      get val() {
        return this$[key]; //target[key];
      },
      set val(value) {
        // target[key] = value;
        this$[key] = value;
      }
    };
    this$.autoSection = {
      ...this$.autoSection,
      [key]: s
    };
  });

}