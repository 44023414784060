<div style="margin:35px 0;padding-bottom: 35px;">
    <div class="header">
        <h1>Our {{(activeTab === 1) ? 'Best Seller': 'Top Rated'}}</h1>
        <div class="tab-area">
            <button (click)="getCategoryData(1)" [ngClass]="{'active': activeTab === 1}">Best Sellers</button>
            <button (click)="getCategoryData(2)" [ngClass]="{'active': activeTab === 2}">Top Rated</button>
        </div>
    </div>
    <div class="cards">
        <ng-container *ngIf="list?.length > 0; else noData">
            <ng-container *ngFor="let item of list">
                <trent-spare-parts-card [item]="item"></trent-spare-parts-card>
            </ng-container>
        </ng-container>
        <ng-template #noData>
            <div class="card-div">
                No Data Found
            </div>
        </ng-template>
    </div>
</div>