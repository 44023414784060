import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { tap, take } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { AuthState } from '@trent/store/auth-store/auth.state';
import { BaseGuard } from './base.guard';
import { IAuthCoreService } from '@trent/services/auth/iauth-core.service';

@Injectable({ providedIn: 'root' })
export class AdminGuard extends BaseGuard implements CanActivate {

  constructor(private store: Store, private router: Router, private auth: IAuthCoreService) {
    super();
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    // logger.log('admin can activate called: ', route.toString());
    // logger.log('[Admin-Guard] Is Admin? ', this.auth.isAdmin);

    // return this.store.select(AuthState.isAdmin)
    return this.auth.isAdmin$
      .pipe(
        take(1),
        tap((isAdmin) => {
          console.log('[Admin-Guard] admin called : ', isAdmin);
          if (!isAdmin) {
            // logger.log('Admin Guard failed: You are not an admin');
            const returnUrl = route['_routerState'].url;
            if (this.allowRedirect(returnUrl)) {
              this.resetCounter(returnUrl);
              if (returnUrl == null || returnUrl === '') {
                return this.router.navigateByUrl('/login');
              } else {
                // logger.log('admin guard counter: ', this.counter[returnUrl]);
                return this.router.navigate(['/login', {
                  returnUrl,
                  fromAdminGuard: true
                }]);
              }
            }
          }
        }));
  }
}
