import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { IValidationMsg } from './validation-info';
import { keysIn, concat } from 'lodash';
import { MessagingPayload } from '@trent/models/fcm/fcm-type';
import { IPaymentErrorResponseB, IProfileCreateResponseErrorB } from '../finance/bambora/i-bambora';
import { OrderType } from '../finance/order/order-type';

export type cssIonic = 'danger' | 'success';
export type cssWeb = 'primary' | 'info' | 'warning';
export type cssMat = 'primary' | 'accent' | 'warn' | 'warning' | 'success' | 'info';
export type xPos = 'left' | 'center' | 'right';
export type yPos = 'top' | 'bottom';

/** Helper location tags telling where to display. Used to auto create the active data */
export type DialogLocType = 'info-right' | 'center-video' | 'info-center';

export interface DialogData<T> {
  data: T;
  boxType: 'alert' | 'confirm' | 'snackbar' | DialogLocType;
}


/** Class to display the error/ success messages to the user */
export class MessageInfo {

  /** danger / success /   */
  msgCss: cssMat; // cssIonic | cssWeb;

  header: string;

  description: string;

  list: string[] = [];

  /** Error trace for dev */
  trace?: string[];

  // additional for web.
  /** left / center / right */
  x: xPos = 'center';

  /** top / bottom  */
  y?: yPos = 'top';


  duration?: number;

  delay?: number;

  progress?: number;

  devError?: any;

  constructor(param?: {
    msgCss?: cssMat;
    header?: string;
    description?: string;
    list?: string[],
    devError?: any
  }) {
    if (!!param) {
      this.msgCss = param.msgCss;
      this.header = param.header;
      this.description = param.description;
      this.list = !!param.list ? param.list : [];
      this.devError = param.devError;
    }
  }



  public static getErrorParagraph(m: MessageInfo): string {
    let s = m.description;
    if (m.list != null && m.list.length > 0) {
      s += '<ul>';
      for (let i = 0; i < m.list.length; i++) {
        const ele = m.list[i];
        s += `<li>${ele}</li>`;
      }
      s += '</ul>';
    }
    return s;
  }
}

// #region Helper functions

export const fromFcbMessage = (payload: MessagingPayload): { messageInfo: MessageInfo } => {
  const m = new MessageInfo();
  m.header = payload.notification.title;
  m.msgCss = 'primary';
  m.description = payload.notification.body;;
  m.list = [];
  return { messageInfo: m };
}

/** concate all the errors from validation in a simple string list */
const getErrorList = (msg: IValidationMsg, delay?: number): string[] => {
  let a: string[] = [];
  keysIn(msg).forEach((val) => {
    a = concat(a, msg[val]);
  });
  return a;
};

// #endregion

// #region Server Message Builder Helper functions.

export const fromDataNotFound = (name: string, delay?: number): { messageInfo: MessageInfo } => {
  const m = new MessageInfo();
  m.header = `Data Not Found`;
  m.msgCss = 'warn';
  m.description = `Data was not found. It does not exist or Invalid input was provided (${name})`;
  m.list = [];
  m.delay = delay;
  return { messageInfo: m };
};

export const fromStorageMoveError = (): { messageInfo: MessageInfo } => {
  const m = new MessageInfo();
  m.header = `File Upload Error`;
  m.msgCss = 'warn';
  m.description = `Some or all files were not successfully moved during last operation.`;
  m.list = [];
  m.trace = [];
  return { messageInfo: m };
};

/**
 * Copy file routine error message Info object
 * @returns 
 */
export const fromStorageCopyError = (): { messageInfo: MessageInfo } => {
  const m = new MessageInfo();
  m.header = `File Copy Error`;
  m.msgCss = 'warn';
  m.description = `Some or all files were not successfully copied during last operation.`;
  m.list = [];
  m.trace = [];
  return { messageInfo: m };
};

export const fromTwilioTexSentError = (error: any): { messageInfo: MessageInfo } => {
  const m = new MessageInfo();
  m.header = `Texting Service Error`;
  m.msgCss = 'warn';
  m.description = `SMS Failure! There was an error sending the sms. Please check your input or 
  try after some time.`;
  m.list = [];
  m.trace = [];
  m.devError = error;
  return { messageInfo: m };
};

export const fromBamboraPaymentError = (error: IPaymentErrorResponseB): { messageInfo: MessageInfo } => {
  const m = new MessageInfo();
  m.header = `Payment processing failed!`;
  m.msgCss = 'warn';
  m.description = error.message;
  m.list = [];
  for (let idx = 0; idx < error.details.length; idx++) {
    const ele = error.details[idx];
    m.list.push(`${ele.field}: ${ele.message}`);
  }
  m.trace = [];
  m.devError = error;
  return { messageInfo: m };
};

export const fromBamboraProfileError = (error: IProfileCreateResponseErrorB): { messageInfo: MessageInfo } => {
  const m = new MessageInfo();
  m.header = `Payment processing failed!`;
  m.msgCss = 'warn';
  m.description = error.message;
  m.list = [];
  for (let idx = 0; idx < error?.details?.length; idx++) {
    const ele = error.details[idx];
    m.list.push(`${ele.field}: ${ele.message}`);
  }
  m.trace = [];
  m.devError = error;
  return { messageInfo: m };
};

export const fromBamboraPaymentApproved = (otype: OrderType, amount: number): { messageInfo: MessageInfo } => {
  const m = new MessageInfo();
  m.msgCss = 'success';
  const ot = OrderType;
  switch (otype) {
    case OrderType.paid:
      m.header = `Payment Processed!`;
      m.description = 'Your card payment was successfully processed.';
      break;
    case OrderType.preAuthorized:
      m.header = `Payment Authorized!`;
      m.description = `our card payment was successfully authorized for a payment of $${amount}.`;
      break;
    case OrderType.cancelled:
      m.header = `Payment Cancelled!`;
      m.description = `our card payment cancellation for amount of $${amount} was successfully processed.rized for a payment of .`;
      break;
    default:
      m.header = 'Order processing completed';
      m.description = `Order processing was completed (status: ${ot[otype]}) for a payment of $${amount}`;
      break;
  }
  m.list = [];
  m.trace = [];
  return { messageInfo: m };
};

export const fromBamboraPaymentRejected = (otype: OrderType, amount: number): { messageInfo: MessageInfo } => {
  const m = new MessageInfo();
  m.msgCss = 'warn';
  const ot = OrderType;
  switch (otype) {
    case OrderType.paid:
      m.header = `Payment Rejected!`;
      m.description = 'Sorry your card payment was rejected.';
      break;
    case OrderType.preAuthorized:
      m.header = `Payment Authorization Failed!`;
      m.description = `Your card authorization for an amount of $${amount} was rejected.`;
      break;
    case OrderType.cancelled:
      m.header = `Payment Cancelled!`;
      m.description = `our card payment cancellation for amount of $${amount} was rejected.`;
      break;
    default:
      m.header = 'Order processing completed';
      m.description = `Order processing was failed (status: ${ot[otype]}) for a payment of $${amount}`;
      break;
  }
  m.list = [];
  m.trace = [];
  return { messageInfo: m };
};



export const fromTwilioTextVerifyError = (error: any): { messageInfo: MessageInfo } => {
  const m = new MessageInfo();
  m.header = `Texting Service Error`;
  m.msgCss = 'warn';
  m.description = `SMS verification failure! There was an error validating the phone code. Please check your input or 
  try after some time.`;
  m.list = [];
  m.trace = [];
  m.devError = error;
  return { messageInfo: m };
};


export const fromFirebaseAuthError = (err): { messageInfo: MessageInfo } => {
  const m = new MessageInfo({
    msgCss: 'warn',
    header: 'Password change failed',
    description: err.message
  });
  return { messageInfo: m };
}


/** Build Message: When invalid or insufficient informatino was passed to server */
export const fromInvalidInput = (name: string, delay?: number): { messageInfo: MessageInfo } => {
  const m = new MessageInfo();
  m.header = `Invalid input`;
  m.msgCss = 'warn';
  m.description = `Invalid input was provided for ${name}`;
  m.list = [];
  m.delay = delay;
  return { messageInfo: m };
};

/** Build Message: When input failed the validation. */
export const fromFailedValidation = (v: IValidationMsg, delay?: number): { messageInfo: MessageInfo } => {
  const m = new MessageInfo();
  m.header = `Data Validation Error!`;
  m.msgCss = 'warn';
  m.description = `Please fix the errors listed below and try again`;
  m.list = getErrorList(v);
  m.delay = delay;
  return { messageInfo: m };
};

export const fromInvalidLogin = (delay?: number): { messageInfo: MessageInfo } => {
  const m = new MessageInfo();
  m.header = `Invalid Login!`;
  m.msgCss = 'warn';
  m.description = 'Your login credentials were rejected by the server! ' +
    'Please re-login and try again.';
  m.delay = delay;
  return { messageInfo: m };
};
/** Build service not available message at the functions. */
export const fromServiceNotAvailable = (name: string, devError?: any): { messageInfo: MessageInfo } => {
  const m = new MessageInfo();
  m.header = `Service Not Available!`;
  m.msgCss = 'warn';
  m.description = `We are sorry but ${name} service not available `;
  // m.delay = delay;
  return { messageInfo: m };
};

// #endregion

// #region Client Message reader functions.
const readErrorMessageStorage = (r, delay?: number): MessageInfo => {
  const m = new MessageInfo();
  m.header = `Storage Error!`;
  m.msgCss = 'warn';
  m.delay = delay;
  m.description = 'Unknown error has occoured at strorage.';
  // for future, update the switch statement as :
  // https://firebase.google.com/docs/storage/web/handle-errors
  const c = r.code.split('/')[1];
  switch (c) {
    case 'unauthorized':
      m.description = 'Authorization Error Occoured!<br/>' +
        'Please try re-login and try again.';
      break;
    default:
      m.description = `${c} Error Occoured!<br/>` +
        'Please try re-login and try again.';
      break;
  }
  return m;
};
/** Build message from the returned error from server. */
export const readErrorMessage = (r: HttpErrorResponse, delay?: number): MessageInfo => {
  if (!!r && !!r.error && r.error.messageInfo) {
    const m1 = new MessageInfo(r.error.messageInfo);
    m1.msgCss = 'warn';
    return m1;
  }
  // seperate storage error.
  const x: any = r;
  if (!!x.code && x.code.startsWith('storage')) {
    return readErrorMessageStorage(x, delay);
  }
  // if server function has already created it, just return
  let m: MessageInfo = r.error.messageInfo;
  if (!!m) { return <MessageInfo>m; }

  // not readily available, most likely off line or internal server errror!.
  m = new MessageInfo();
  m.msgCss = 'warn';
  const status = +r.status;
  switch (status) {
    case 403:
      m = fromInvalidLogin().messageInfo;
      break;
    default:
      m.header = 'Unknown Error!';
      m.description = 'Server could not be reached. Are you off-line?';
      break;
  }
  m.delay = delay;
  return m;
};

export const fromRedirectInfo = (msg?: string, delay?: number): MessageInfo => {
  // if server function has already created it, just return
  const m: MessageInfo = new MessageInfo();
  m.msgCss = 'primary';
  m.header = 'Redirect Message!';
  if (msg == null) { msg = 'You will be redirected shortly.'; }
  m.description = `${msg}`;
  m.delay = delay;
  return m;
};

export const fromUpdateSuccess = (name: string, delay?: number): MessageInfo => {
  // if server function has already created it, just return
  const m: MessageInfo = new MessageInfo();
  m.msgCss = 'success';
  m.header = 'Success!';
  m.description = `${name} Data was successfully updated.`;
  m.delay = delay;
  return m;
};
export const fromUpdateWarning = (name: string, delay?: number): MessageInfo => {
  // if server function has already created it, just return
  const m: MessageInfo = new MessageInfo();
  m.msgCss = 'warning';
  m.header = 'Warning Partial Update!';
  m.description = `${name} Data was partially updated.`;
  m.delay = delay;
  return m;
};
export const fromCreateSuccess = (name: string, delay?: number): MessageInfo => {
  // if server function has already created it, just return
  const m: MessageInfo = new MessageInfo();
  m.msgCss = 'success';
  m.header = 'Success!';
  m.description = `${name} data was successfully created/updated.`;
  m.delay = delay;
  return m;
};
export const fromCreateWarning = (name: string, delay?: number): MessageInfo => {
  // if server function has already created it, just return
  const m: MessageInfo = new MessageInfo();
  m.msgCss = 'warning';
  m.header = 'Warning Partial Creation!';
  m.description = `${name} data was partially created.`;
  m.delay = delay;
  return m;
};
export const fromError = (desc: string, hdr?: string, delay?: number): { messageInfo: MessageInfo } => {
  // if server function has already created it, just return
  const m: MessageInfo = new MessageInfo();
  m.msgCss = 'warn';
  m.header = (!!hdr) ? hdr : 'Error!';
  m.description = `${desc}`;
  m.delay = delay;
  return { messageInfo: m };
};
export const fromSuccess = (desc: string, hdr?: string, delay?: number): MessageInfo => {
  // if server function has already created it, just return
  const m: MessageInfo = new MessageInfo();
  m.msgCss = 'success';
  m.header = (!!hdr) ? hdr : 'Success!';
  m.description = `${desc}`;
  m.delay = delay;
  return m;
};

/** Build Message status 0 : network error ?? */
export const getError_unknownError = function (r: HttpErrorResponse, delay?: number): MessageInfo {
  if (!!r.error && !!r.error.messageInfo) {
    const m1 = new MessageInfo(r.error.messageInfo);
    m1.msgCss = 'warn';
    return m1;
  }
  const m = new MessageInfo();
  m.header = `Unknown Error!`;
  m.msgCss = 'warn';
  m.description = `An unknown error has occoured! <br/>Are you off line?`;
  m.list = [];
  m.delay = delay;
  if (!!r && !!r.error) {
    m.list.push(r.error);
  }
  if (!!r && !!r.message) {
    m.list.push(r.message);
  }
  return m;

};

export const fromCustomError = function (r: HttpErrorResponse, hdr: string, msg: string, delay?: number) {
  const m = new MessageInfo();
  m.header = hdr;
  m.msgCss = 'warn';
  m.description = msg;
  m.list = [];
  m.list.push(r.message);
  m.list.push(`[${r.status}]: ${r.statusText}`);
  m.delay = delay;
  return m;
};
export const fromFileUploadSuccess = (name: string, delay?: number, progress?: number): MessageInfo => {
  // if server function has already created it, just return
  const m: MessageInfo = new MessageInfo();
  m.msgCss = 'success';
  m.header = 'Success!';
  m.description = `${name} data was uccessfully created.`;
  m.delay = delay;
  m.progress = progress;
  return m;
};
// #endregion
export const fromNoUpdate = (delay?: number): MessageInfo => {
  // if server function has already created it, just return
  const m: MessageInfo = new MessageInfo();
  m.msgCss = 'warn';
  m.header = 'No Change detected';
  m.description = `Nothing was modfied on the form to update`;
  m.delay = delay;
  return m;
};

export const duplicateRecord = (sNo: string | number): MessageInfo => {
  const m: MessageInfo = new MessageInfo();
  m.msgCss = 'warn';
  m.header = 'Duplicate record';
  m.description = `${sNo} record is already available in the database.`;
  return m;
};
