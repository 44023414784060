import { Exclude, Expose } from 'class-transformer';
import { IsNumber } from 'class-validator';
import { sanitizeDate } from '../utility';

export type PaymentService = 'Bambora' | 'Manual';

export interface ITransactionSummaryBase {
  created: Date;
  amount: number;
  paymentService: PaymentService;
  /** Maps to Bambora server transaction id for Bambora payment; maps to user enter id enter by user form for Manual */
  vendorTransId: string,
  /** order number to bambora is firestore document id of the Transaction; Property kept same to make manual payments work*/
  vendorOrderNumber: string;

}
@Exclude()
export class TransactionBase {

  static readonly collectionName = 'transaction';

  id: string | number;

  @Expose()
  paymentService: PaymentService;


  @Expose()
  created: Date;


  @Expose()
  @IsNumber()
  amount: number;

  /** 
   * Approval status of payment transaction. 0 if the transaction is not approved. 1 if the transaction is approved.
   * Bambora property adopted for Transaction base. 
   * For manual transactions set at the server, so does not require client validation.
   */
  @Expose()
  approved: number;



  sanitize() {

    this.created = sanitizeDate(this.created);
  }
}
