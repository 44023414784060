import { BaseModel } from '../base/base-model';
import { Author } from './author';
import { NegoTermsBase } from '../nego-terms-base/nego-terms-base';
import { plainToInstance, instanceToInstance, Type } from 'class-transformer';
import { validateSync } from 'class-validator';
import { IValidationMsg } from '../error-handling/validation-info';
import { sanitizeDate, sanitizeDateIPoint } from '../utility/sanitize-helper';



export class BidHistory extends BaseModel {
  constructor() {
    super();
    this.bidSnapshot = new NegoTermsBase;
  }


  public static readonly collectionName = 'bid-history';

  bidId: string | number;
  @Type(() => NegoTermsBase)
  bidSnapshot: NegoTermsBase;
  comment?: string;
  rejectedBy?: Author; // should be removed




  /** Vendor or Custmer */
  updatedBy?: Author;
  createdBy: Author;

  /** User who made the change. */
  updatedById?: string;



  public static parse(obj) {
    if (obj == null) { return null; }
    const m = plainToInstance<BidHistory, any>(BidHistory, sanitizeDateIPoint(obj));
    m.sanitize();
    return m;
  }
  public static create(bidprev: BidHistory) {
    const h = new BidHistory();
    h.bidId = bidprev.bidId;
    h.bidSnapshot = bidprev.bidSnapshot;
    h.createdAt = bidprev.createdAt;
    h.updatedAt = bidprev.updatedAt;
    h.createdBy = bidprev.createdBy;
    h.updatedBy = bidprev.updatedBy;
    return h.toFirebaseObj();

  }
  sanitize() {
    super.sanitize();
    // if data was recieved from firebase, date is stored as snapshot.
    if (this.bidSnapshot != null) {
      this.bidSnapshot.createdAt = sanitizeDate(this.bidSnapshot.createdAt);
      this.bidSnapshot.updatedAt = sanitizeDate(this.bidSnapshot.updatedAt);
      this.bidSnapshot.startDate = sanitizeDate(this.bidSnapshot.startDate);
      this.bidSnapshot.endDate = sanitizeDate(this.bidSnapshot.endDate);
    }
    this.bidSnapshot.startDate = sanitizeDate(this.bidSnapshot.startDate);
    this.bidSnapshot.endDate = sanitizeDate(this.bidSnapshot.endDate);
  }

  clone() {
    const t = instanceToInstance(this);
    t.sanitize();
    return t;
  }

  validateSync(): IValidationMsg {
    const r = this.validateSyncBase(this);
    return r;
  }
}
