import { IsAlphanumeric, IsBoolean, IsDate, isEmpty, IsInt, IsNumber, Length, Matches, MaxLength, MinLength, ValidateIf, ValidatorOptions } from 'class-validator';
import { instanceToInstance, plainToInstance, Exclude, Expose, Type } from 'class-transformer';
import { IValidationMsg, IValDbStatusResult } from '../error-handling/validation-info';
import { sanitizeDate, sanitizeDateIPoint, sanitizeInt } from '../utility/sanitize-helper';
import { ValidateNested, IsDefined } from 'class-validator';
import { logger } from '../log/logger';
import { BaseModel } from '../base';

/**
 * @author - MKN
 * NetSuite API Response 
 */
export interface INetSuiteResult {    
  data: NetSuiteProductData[]; 
  error: any;
  success: boolean;
}

/**
 * @author - MKN
 * NetSuite API Product - Inventory Type 
 */
export enum InventoryType  {
   'fixetAsset'  =  'Fixed Asset',
   'inventory'  =  'Inventory',
}

/**
 * @author - MKN
 * NetSuite API Product - Odometer Units Type 
 */
export enum OdometerUnitsType  {
  'km'  =  'km',
  'mi'  =  'mi',
}

/**
 * @author - MKN
 * NetSuite API Product - Truck Internal Status
 */
export enum TruckInternalStatus  {
  'available'  =  'Available',
  'onHold'  =  'On Hold',
  'maintenance'  =  'Maintenance',
  'owned'  =  'Owned',
  'inTransit'  =  'In Transit',
  'returned'  =  'Returned',
}

const regex = {
  js: {
    // tslint:disable-next-line:max-line-length

    nounVin: /^(([a-h,A-H,j-n,J-N,p-z,P-Z,0-9]{9})([a-h,A-H,j-n,J-N,p,P,r-t,R-T,v-z,V-Z,0-9])([a-h,A-H,j-n,J-N,p-z,P-Z,0-9]{7}))$/,
  }
};

/** For netSuite product data for backup  */
@Exclude()
export class NetSuiteProductData extends BaseModel {

  constructor() {
    super();
  }

  @Expose()
  @Length(5, 20, { message: 'Owner Id must be $constraint1 - $constraint2 chars long'  }) 
  owner: string;

  @Expose()
  @MinLength(1, {  message: 'Subsidiary Id should be provided'  }) 
  i_subsidiary: string;

  @Expose()
  @MinLength(1, {  message: 'store location Id should be provided'  }) 
  i_location: string;

  @Expose()
  @MinLength(1, { message: 'stockId needs Minimum $constraint1 chars'})
  stockId: number;

  @Expose() 
  @Matches(regex.js.nounVin, { message: 'Invalid vin' })
  vin: string;
  
  @Expose()
  // @Length(5, 20, { message: 'originalStockNumber must be $constraint1 - $constraint2 chars long' }) 
  originalStockNumber: string;

  @Expose() 
  // @IsDefined({ message: 'Inventory Type must be defined'  }) 
  inventoryType: InventoryType;

  @Expose()
  @IsDefined({ message: 'truck_internal_status must be defined' }) 
  truck_internal_status: TruckInternalStatus;

  @Expose()
  odometer: number;

  @Expose()
  lastModifiedDate: Date;

  @Expose()
  odometerUnits: OdometerUnitsType;

  /** Convert all GeoPoint class instances to IPoint. required before parsing data coming back
   * from firebase. This must be called BEFORE PARSE and not AFTER PARSE
   * @param data Data to be parsed
   * @param type type to be used to decipher latitude/longitude. This is needed because at client the type is : firebase.firestore.GeoPoint
   * and at function it is admin.firestore.GeoPoint they are two different types.
   * https://github.com/Microsoft/TypeScript/wiki/FAQ#why-cant-i-write-typeof-t-new-t-or-instanceof-t-in-my-generic-function
   */
  public static parse(obj) {
    // public static parse<T extends IPoint>(obj, ipointType?: { new(...args: any[]): T }) {
    try {

      if (obj == null) { return null; }
      obj = sanitizeDateIPoint(obj);
      // obj = sanitizeDateIPoint(obj, ipointType);
      const m = plainToInstance<NetSuiteProductData, any>(NetSuiteProductData, sanitizeDateIPoint(obj));
      m.sanitize();
      //extra validation add here
      return m;
    } catch (error) {
      logger.log('Error happened during parse', error);
      return null;
    }
  }

  clone() {
    const t = instanceToInstance(this);
    t.sanitize();
    return t;
  }

  validateSync(options?: ValidatorOptions): IValidationMsg {
    const r = this.validateSyncBase(this, options);
    return r;
  }

  sanitize() {
    super.sanitize();
    //extra sanitize data
    this.lastModifiedDate = sanitizeDate(this.lastModifiedDate);
  }
}
