import { State, Selector, Action, StateContext } from '@ngxs/store';
import { map as loadashMap } from 'lodash';
import { map } from 'rxjs/operators';
import { noop, Subscription } from 'rxjs';
import { RentalService } from '@trent/services/rental.service';
import * as entity from '@trent/store/entity.state';
import { ProductType } from '../../models/product/product-type';
import { StateBase } from '../state-base';
import { RentOptionParam } from '@trent/models/rental/rent-option-param';
import { getObjKey } from '../../models/observable-util/paging';
import {
  IDataLoadStatus, buildDataRequest, LoadStatus,
  updatePaging, getRootLevelChildren
} from '../../models/observable-util/data-status';
import { rentOptionSearchClientFilter, getRentOptionSearchOptOrChildren } from '../../models/rental/rent-option-param';
import { PagingContainer } from '../../models/observable-util/paging-container';
import { RentalProductBase } from '@trent/models/rental/rental-product-base';
import { Paging } from '@trent/models/observable-util/paging';
import { parseRentalProduct } from '@trent/models/rental/rental-helper';
import { Injectable } from '@angular/core';


// #region State Model
export interface RentalProductStateModel {
  param?: RentOptionParam;
  paging: Paging;
  allRentalProductsLoaded: boolean;
  filteredRentalProductsLoaded: boolean;
  rentalProductsByProdIdLoaded: boolean;
  rentalProductsByCompIdLoaded: boolean;
  // rentalProducts: entity.EntityState<RentalProductBase>;
  allRentalProductsLoadStatus: {
    [key: string]: IDataLoadStatus<RentOptionParam>;
  };
  rentalProducts: { [id: string]: RentalProductBase };
}

// #endregion

// #region actions
export class AllRentalProductsRequested {
  static readonly type = '[RetalProducts] All Retal Products Requested';
  constructor(public payload: { pData: Paging, param: RentOptionParam }) { }
}
export class AllRentalProductsLoaded {
  static readonly type = '[RetalProducts] All Retal Products Loaded';
  constructor(public payload: {
    data: { [id: string]: RentalProductBase },
    key: string, // primary key)
  }) { }
}
export class AllRentalProductNextPageRequested {
  static readonly type = '[RetalProducts] All Retal Products Requested - Next Page';
  constructor(public payload: { param: RentOptionParam }) { }
}

export class RentalProductRequested {
  static readonly type = '[RetalProducts] Request a single entity';
  constructor(public payload: { id: string | number }) { }
}

export class RentalProductLoaded {
  static readonly type = '[RetalProducts] Load a single entity';
  constructor(public payload: { data: RentalProductBase }) { }
}
export class FilteredRentalProductsRequested {
  static readonly type = '[RetalProducts] Filtered Retal Products Requested';
  constructor(public payload: { p: ProductType, lat: number, long: number, rad: number, field: string }) { }
}
export class FilteredRentalProductsLoaded {
  static readonly type = '[RetalProducts] Filtered Retal Products Loaded';
  constructor(public payload: { rentalProducts: any[] }) { } // cannot assign RentalProductBase[] need to check with the boss man
}
export class RentalProductsRequestedByProdId {
  static readonly type = '[RetalProducts] Retal Products Requested by Product ID';
  constructor(public payload: { pData: Paging, param: RentOptionParam }) { }
}
export class RentalProductsByProdIdLoaded {
  static readonly type = '[RetalProducts] Retal Products by Product ID Loaded';
  // constructor(public payload: { rentalProducts: any[] }) { } // cannot assign RentalProductBase[] need to check with the boss man
  constructor(public payload: {
    data: { [id: string]: RentalProductBase },
    key: string, // primary key)
  }) { }
}
export class RentalProductsRequestedByCompId {
  static readonly type = '[RetalProducts] Retal Products Requested by Company ID';
  constructor(public payload: { cid: string | number }) { }
}
export class RentalProductsByCompIdLoaded {
  static readonly type = '[RetalProducts] Retal Products by Company ID Loaded';
  constructor(public payload: { rentalProducts: any[] }) { } // cannot assign RentalProductBase[] need to check with the boss man
}
export class RentalProductUpdated {
  static readonly type = '[RetalProducts] Retal Product Updated';
  constructor(public payload: { rid: string | number, rentalProduct: RentalProductBase }) { }
}
export class RentalProductStateReset {
  static readonly type = '[RetalProducts] Reset State';
}

// #endregion
function initRentalProductState(): RentalProductStateModel {
  return {
    param: null,
    paging: { size: 10, offset: 0, full: false },
    filteredRentalProductsLoaded: false,
    allRentalProductsLoaded: false,
    rentalProductsByProdIdLoaded: false,
    rentalProductsByCompIdLoaded: false,
    // rentalProducts: entity.init<RentalProductBase>(),
    allRentalProductsLoadStatus: {},
    rentalProducts: {}
  };
}

@State<RentalProductStateModel>({
  name: 'rentalProduct',
  defaults: initRentalProductState()
})
@Injectable()
export class RentalProductState extends StateBase {
  // allRentalProductPagingContainer:
  /** Container that keep all of the subscription related to gettting allRentalProducts. */
  allRentalProductSubData: PagingContainer<RentalProductBase, RentOptionParam> = new PagingContainer();
  allRentalProdReqSub: Subscription;

  constructor(private rentalService: RentalService) { super(); }
  // #region Static Selectors

  @Selector()
  static selectRentalAllProducts(state: RentalProductStateModel) {
    console.log('rental option Select-STORE', state.rentalProducts);
    return (o: RentOptionParam): RentalProductBase[] => {
      if (state.rentalProducts == null || Object.keys(state.rentalProducts).length === 0) {
        return [];
      }
      // remove keys that have revision/draft ids, i.e that contain '~' in the id.
      const keys = Object.keys(state.rentalProducts).filter(k =>
        k.indexOf('/') === -1 && state.rentalProducts[k] != null);

      // object without the rev/draft.
      const filtered = keys.reduce((obj, k) => {
        obj[k] = state.rentalProducts[k];
        return obj;
      }, {});
      let output: RentalProductBase[];

      output = Object.values(filtered).map(x => <RentalProductBase>parseRentalProduct(x));
      output = rentOptionSearchClientFilter(output, o);

      return output;
    };
  }

  @Selector()
  static selectProductById(state: RentalProductStateModel) {
    return entity.getByIdFn_new(state.rentalProducts, parseRentalProduct);
  }

  @Selector()
  static selectRentalProductsByProdId(state: RentalProductStateModel) {
    console.log('rental option Select-STORE', state.rentalProducts);

    return (productId: string | number): RentalProductBase[] => {
      return Object.values(state.rentalProducts)
        .filter(r => r.productId === productId)
        .map(m => <RentalProductBase>parseRentalProduct(m));
    };
  }
  @Selector()
  static selectRentalProductsByCompId(state: RentalProductStateModel) {
    console.log('rental option Select-STORE', state.rentalProducts);
    return (cid: string | number): RentalProductBase[] => {
      return Object.values(state.rentalProducts)
        .filter(p => p.cid === cid)
        .map(m => <RentalProductBase>parseRentalProduct(m));
    };
  }
  @Selector()
  static selectRentOptionParam(state: RentalProductStateModel) {
    return {
      ...state.param
    };
  }
  // #endregion

  // #region Custom Functions and subscriptions

  public clearSubscriptions() {
    if (!!this.allRentalProdReqSub) {
      this.allRentalProdReqSub.unsubscribe();
      this.allRentalProdReqSub = null;
    }
    this.allRentalProductSubData.unsubscribeAll();
    super.clearSubscriptions();
  }

  // #endregion
  @Action(AllRentalProductsRequested)
  allRentalProductsRequested(context: StateContext<RentalProductStateModel>, action: AllRentalProductsRequested) {
    const oKey = getObjKey(action.payload.param);

    // Define function that return the data status object from the state.
    const getDataStatusStateFn = () => context.getState().allRentalProductsLoadStatus;

    /** custom build the OR children query. */
    const buildOrQueryChildrenFn = (o: RentOptionParam) => getRentOptionSearchOptOrChildren(o);
    // if data requested now, is already partially loaded by another query's child previously
    // but the number of the items are not enough (ex. as a child, it loaded only 5, but current
    // request ask for more, then next page of that query should be called instead.)
    const nextPageFn = (param: RentOptionParam) => {
      context.dispatch(new AllRentalProductNextPageRequested({ param }));
    };
    buildDataRequest(
      oKey, action.payload.param, action.payload.pData,
      getDataStatusStateFn,
      buildOrQueryChildrenFn,
      nextPageFn,
      (
        obj: { [key: string]: IDataLoadStatus<RentOptionParam> },
        set: { key: string, node: IDataLoadStatus<RentOptionParam> }[]
      ) => {

        if (!!obj) {
          // Patch the state.
          const state = context.getState();
          context.patchState({
            allRentalProductsLoadStatus: { ...state.allRentalProductsLoadStatus, ...obj }
          });
        }

        // Process the query.
        set.forEach((val) => {
          // some of the nodes are already loaded. Only process that are loading... status.
          if (val.node.loadStatus !== LoadStatus.Loading) {
            return;
          }
          // if this request is just overwriting a stall or pending request, unsubscribe that observable
          this.allRentalProductSubData.unsubscribe(val.key);

          console.log('New Service sub created: ', val);


          // create the paging observable and call db.
          const r = this.rentalService.getAllRentalProducts1_PagingObservable();
          const rentalProd$ = r.getData(action.payload.pData, val.node.param)
            .pipe(
              map(rProducts => {
                context.dispatch(new AllRentalProductsLoaded({
                  data: rProducts,
                  key: val.key
                }));
                return rProducts;
              }));
          const sub = this.subscribe(rentalProd$, () => noop(), AllRentalProductsRequested.type);
          // save the observable call
          this.allRentalProductSubData.addData(val.key, sub, r);
        });
      }
    );
  }


  @Action(AllRentalProductsLoaded)
  allRentalProductsLoaded(context: StateContext<RentalProductStateModel>, action: AllRentalProductsLoaded) {
    // console.log('rent option loaded ', action.payload.data);
    const state = context.getState();
    const subData = this.allRentalProductSubData.getData(action.payload.key);
    const updatedLoadStatus = updatePaging(action.payload.key, state.allRentalProductsLoadStatus, subData);
    context.patchState({
      allRentalProductsLoaded: true,
      allRentalProductsLoadStatus: updatedLoadStatus,
      rentalProducts: { ...state.rentalProducts, ...action.payload.data }
    });
  }
  @Action(AllRentalProductNextPageRequested)
  allRentalProductNextPageRequested(context: StateContext<RentalProductStateModel>, action: AllRentalProductNextPageRequested) {
    const oKey = getObjKey(action.payload.param);
    const state = context.getState();
    // find the node. can be parent or child
    const statusObj = state.allRentalProductsLoadStatus[oKey];
    // if no parent, treat is
    if (statusObj.children == null) {
      this.allRentalProductSubData.dispatchNextPagingUpdate(oKey);
    } else {
      const children = getRootLevelChildren(oKey, state.allRentalProductsLoadStatus);
      children.forEach(c => {
        this.allRentalProductSubData.dispatchNextPagingUpdate(c.key);
      });
    }
  }
  @Action(RentalProductRequested)
  rentalProductRequested(context: StateContext<RentalProductStateModel>, action: RentalProductRequested) {
    const state = context.getState();
    if (Object.keys(state.rentalProducts).indexOf(`${action.payload.id}`) === -1) {
      // console.log('requested Rental-Product is not in the store. sending request to server', action.payload.id);
      this.rentalService.getRentalProductById(action.payload.id)
        .pipe(
          map(data => {
            // console.log('from server: retal map was called', data);
            return context.dispatch(new RentalProductLoaded({ data }));
          }
          )).subscribe(noop);
    } else { console.log('requested Rental-Product is available in the store'); }

  }
  @Action(RentalProductLoaded)
  rentalProductLoaded(context: StateContext<RentalProductStateModel>, action: RentalProductLoaded) {
    const state = context.getState();
    const r = {};
    r[action.payload?.data?.id] = action.payload.data;

    context.patchState({
      rentalProducts: { ...state.rentalProducts, ...r }
    });
  }
  // @Action(FilteredRentalProductsRequested)
  // filteredRentalProductsRequested(context: StateContext<RentalProductStateModel>, action: FilteredRentalProductsRequested) {
  //   if (!context.getState().filteredRentalProductsLoaded || !context.getState().allRentalProductsLoaded) {
  //     this.rentalService.withinRadRentalProducts(action.payload.p, action.payload.lat,
  //       action.payload.long, action.payload.rad, action.payload.field)
  //       .pipe(
  //         map(rentalProducts => {
  //           // console.log('map was called');
  //           return context.dispatch(new FilteredRentalProductsLoaded({ rentalProducts }));
  //         }
  //         )).subscribe(noop);
  //   }
  // }
  // @Action(FilteredRentalProductsLoaded)
  // filteredRentalProductsLoaded(context: StateContext<RentalProductStateModel>, action: FilteredRentalProductsLoaded) {
  //   console.log('rent option loaded ', action.payload.rentalProducts);
  //   const state = context.getState();
  //   context.patchState({
  //     filteredRentalProductsLoaded: true,
  //     rentalProducts: entity.addMany(state.rentalProducts, action.payload.rentalProducts)
  //   });
  // }
  // @Action(RentalProductsRequestedByProdId)
  // rentalProductsRequestedByProdId(context: StateContext<RentalProductStateModel>, action: RentalProductsRequestedByProdId) {
  //   const state = context.getState();
  //   if (Object.keys(state.rentalProducts).indexOf(`${action.payload.}`) === -1) {
  //     console.log('requested Rental-Product is not in the store. sending request to server', action.payload.pid);
  //     const r = this.rentalService.getRentalProductsByProdId_PagingObservable();
  //     const rentalProd$ = r.getData(action.payload.pData, val.node.param)

  //     .pipe(
  //       map(rProducts => {
  //         context.dispatch(new AllRentalProductsLoaded({
  //           data: rProducts,
  //           key: val.key
  //         }));
  //         return rProducts;
  //       ))
  //   }
  // if (!context.getState().rentalProductsByProdIdLoaded || !context.getState().allRentalProductsLoaded) {
  //   this.rentalService.getRentalProductsByProdId(action.payload.pid)
  //     .pipe(
  //       map(rentalProducts => {
  //         // console.log('map was called');
  //         return context.dispatch(new RentalProductsByProdIdLoaded({ rentalProducts }));
  //       }
  //       )).subscribe(noop);
  // }
  // }
  // @Action(RentalProductsByProdIdLoaded)
  // rentalProductsByProdIdLoaded(context: StateContext<RentalProductStateModel>, action: RentalProductsByProdIdLoaded) {
  //   console.log('rent option loaded ', action.payload.data);
  //   const state = context.getState();
  //   const subData = this.allRentalProductSubData.getData(action.payload.key);
  //   const updatedLoadStatus = updatePaging(action.payload.key, state.allRentalProductsLoadStatus, subData);
  //   context.patchState({
  //     // allRentalProductsLoaded: true,
  //     allRentalProductsLoadStatus: updatedLoadStatus,
  //     rentalProducts: { ...state.rentalProducts, ...action.payload.data }
  //   });
  // const r = {};
  // r[action.payload.rentalProducts] = action.payload.rentalProducts;

  // context.patchState({
  //   rentalProducts: { ...state.rentalProducts, ...r }

  // });
  // }
  // @Action(RentalProductsRequestedByCompId)
  // rentalProductsRequestedByCompId(context: StateContext<RentalProductStateModel>, action: RentalProductsRequestedByCompId) {
  //   if (!context.getState().rentalProductsByCompIdLoaded || !context.getState().allRentalProductsLoaded) {
  //     this.rentalService.getRentalProductsByCompId(action.payload.cid)
  //       .pipe(
  //         map(rentalProducts => {
  //           // console.log('map was called');
  //           return context.dispatch(new RentalProductsByCompIdLoaded({ rentalProducts }));
  //         }
  //         )).subscribe(noop);
  //   }
  // }
  // @Action(RentalProductsByCompIdLoaded)
  // rentalProductsByCompLoaded(context: StateContext<RentalProductStateModel>, action: RentalProductsByCompIdLoaded) {
  //   console.log('rent option loaded ', action.payload.rentalProducts);
  //   const state = context.getState();
  //   context.patchState({
  //     rentalProductsByCompIdLoaded: true,
  //     rentalProducts: entity.addMany(state.rentalProducts, action.payload.rentalProducts)
  //   });
  // }
  @Action(RentalProductStateReset)
  rentalProductStateReset(context: StateContext<RentalProductStateModel>, action: RentalProductStateReset) {
    // unsubscribe the data
    console.log('RentalProductStateReset action called');
    this.clearSubscriptions();
    context.setState(initRentalProductState());
  }
}
