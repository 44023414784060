
export const gpsStatusColName = 'gps-status';

export const copyGpsStatus = (obj: any) => {
  // tbd: parse the date before returning.
  const res = { ...obj };
  res.updatedAt = new Date(res.updatedAt);
  return res as ITripGpsStatus;
};

export enum GpsTrackingType {
  trip = 0,
  asset = 1
}

export interface IGeoFence {
  identifier?: string; //  "Home",
  radius: number; // 150,
  latitude: number;
  longitude: number;
  notifyOnEntry: boolean;
  notifyOnExit: boolean;
  notifyOnDwell: boolean;
  loiteringDelay: number; // 30000,  // 30 seconds
  extras?: {               // Optional arbitrary meta-data
    zone_id: string;
  };
}


export interface ITripGpsStatus {
  trackingType?: GpsTrackingType;
  cid?: string;
  isBroadCasting?: boolean;
  geoFence?: IGeoFence;
  type?: GpsTrackingType;
  updatedAt?: Date;
  logGps?: boolean;
  refreshRate?: boolean;
}