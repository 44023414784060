import { IEnviorment } from './ienviorment';
import { functionUrl, fireProject, gplusInfo } from './fb-env';
import { webSiteInfo } from './web-site-info/web-site-info';

/**
 * Production Server Locus Loop
 * Project: lloop_prod
 * Platform: web
 * Config: prod
 */
export const environment: IEnviorment = {
  production: true,
  emulator: false,
  fire: fireProject,
  googlePlus: gplusInfo,
  proj: 'locus-loop production',
  platform: 'web',
  functionUrl: functionUrl.production,
  webSite: webSiteInfo
   // ,
  // gpsPostUrl: functionUrl.production_LocusLoop
};
