<ng-container *ngIf="param">
  <div class="p16">
    <trent-rental-search #rentSearchCtrl (paramEmit)="searchRental($event)" [mo]="param" [isGlobalSearch]="false"></trent-rental-search>
  </div>
</ng-container>
<mat-dialog-actions class="d-flex d-j-end" style="padding: 0 16px 16px 16px!important;">
  <button mat-button (click)="dialogRef.close(param)">Cancel</button>
  <button mat-flat-button color="primary" (click)="updateOptions()" cdkFocusInitial>Search</button>
</mat-dialog-actions>

<ng-container *ngIf="!!eo && false">
  <!-- Pick up Address -->
  <mat-form-field class="full-width">
    <mat-label>Pick Up Address</mat-label>
    <input matInput appGooglePlaces id="p" type="text" name="pickup" [(ngModel)]="eo.startAddress.addressFormated"
      #pickupAddress_addressFormated="ngModel" (placeLoaded)="onPlaceLoaded($event)" [disabled]="!!eo.startAddress.geoLoc?.geohash">
    <span matSuffix *ngIf="!!eo.startAddress">
      <button mat-icon-button color="primary" type="button" (click)="remove()">
        <mat-icon>remove_circle</mat-icon>
      </button>
    </span>
    <mat-hint align="end">Search by address</mat-hint>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Reefer/Dry-van</mat-label>
    <input matInput name="refeerDryVan" #refeerDryVan1Ctrl="ngModel" [(ngModel)]="refeerDryVan" style="display: none">
    <div style="display: grid; align-self: bottom">
      <mat-radio-group aria-label="Select an option" name="refeerDryVan" #refeerDryVanCtrl="ngModel"
        [(ngModel)]="refeerDryVan" (change)="reeferDrySelect()">
        <mat-radio-button color="accent" [value]="'reefer'" style="padding-right: 10px">
          Reefer
        </mat-radio-button>
        <mat-radio-button color="accent" [value]="'dry-van'" style="padding-right: 10px">
          Dry-van
        </mat-radio-button>
        <mat-radio-button color="accent" [value]="'all'" style="padding-right: 10px">
          All
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </mat-form-field>
  <mat-form-field>
    <mat-label>No. of Axle</mat-label>
    <mat-select matNativeControl name="nAxle" #nAxleCtrl="ngModel" [(ngModel)]="nAxle" (change)="nAxleSelect()"> 
      <mat-option [value]="2">2-Axle</mat-option>
      <mat-option [value]="3">3-Axle</mat-option>
      <mat-option [value]="4">4-Axle</mat-option>
      <mat-option [value]="'*'">Any</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-dialog-actions align="end">
    <button mat-button (click)="dialogRef.close()">Cancel</button>
    <button mat-flat-button color="primary" (click)="updateOptions()" cdkFocusInitial>Search</button>
  </mat-dialog-actions>

</ng-container>
  <!-- <div class="menu-title">
  <span class="color-highlight">Rent Option Search</span>
  <h1>Options</h1>
  <a href="javascript:void(0)" class="menu-hide"><i class="fa fa-times"></i></a>
</div>
<div class="content" *ngIf="!!eo">
  <em class="color-highlight small">Select Asset Type</em>
  <div class="select-box select-box-2 bottom-10">
    <select [(ngModel)]="eo.type">
      <option value="*">All Assets</option>
      <ng-container *ngFor="let p of pType | enumPipe">
        <option [value]="p.key">{{p.value}}</option>
      </ng-container>
    </select>
  </div>
  <div class="input-simple-2 has-icon input-green bottom-15">
    <em class="color-highlight">Search Address</em>
    <input [(ngModel)]="searchTxt" id="txtHome" type="text" #searchbar name="nativeHomeInputBox"
      [ngModel]="nativeHomeInputBox" [(ngModel)]="searchInput" appGooglePlaces (placeLoaded)='onPlaceLoaded($event)'
      placeholder="Search Address">
  </div>
  {{eo.startaddress}}
  <div class="input-simple-2 has-icon input-green bottom-15">
    <em class="color-highlight">Search within radius(KM) </em>
    <input type="number" placeholder="100" name="radius" [(ngModel)]="eo.radius" />
  </div>
  <div class="input-simple-2 textarea has-icon">
    <a href="javascript:void(0)" (click)="showDateFilter()"
      class="uppercase bottom-15 ultrabold button button-rounded shadow-medium button-blue" *ngIf="!eo.isDateFilter">Search within Date Range</a>
  </div>
  <div class="input-simple-2 has-icon input-green bottom-15" *ngIf="!!eo.isDateFilter">
    <em class="color-highlight">Start Date<ul class="fa fa-times color-orange-dark bottom-0" href="javascript:void(0)" (click)="hideDateFilter()"></ul></em>
    <input type="date" placeholder="Date" name="startDate" [(ngModel)]="eo.searchStartDate" />
  </div>
  <div class="input-simple-2 has-icon input-green bottom-15" *ngIf="!!eo.isDateFilter">
    <em class="color-highlight">Return Date<ul class="fa fa-times color-orange-dark bottom-0" href="javascript:void(0)" (click)="hideDateFilter()"></ul></em>
    <input type="date" placeholder="Date" name="endDate" [(ngModel)]="eo.searchEndDate" />
  </div>
  <em class="color-highlight small">Select Company</em>
  <div class="select-box select-box-2 bottom-10">
    <select>
      <option value="all">All Companies</option>
      <option value="admin">Admin</option>
    </select>
  </div>
  <em class="color-highlight small">Order By</em>
  <div class="select-box select-box-2 bottom-10">
    <select [(ngModel)]="eo.orderBy">
      <ng-container *ngFor="let p of rentOptionSearchOrderBy">
        <option [value]="p.value">{{p.key}}</option>
      </ng-container>
    </select>
  </div>

  <div class="input-simple-2 textarea has-icon">
    <a href="javascript:void(0)" (click)="updateOptions()"
      class="uppercase bottom-15 ultrabold button button-rounded shadow-medium button-blue ">Update</a>
  </div>
  <div class="clear"></div>
</div> -->