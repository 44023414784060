import { Exclude, Expose, plainToInstance, instanceToInstance } from 'class-transformer';
import { PromoListBase, ListValidationGroup } from './promo-list-base';
import { ValidatorOptions, IsDefined, validateSync } from 'class-validator';
import { sanitizeDateIPoint, toValidationError } from '../utility';
import { IValidationMsg } from '../error-handling/validation-info';
import { PromoListItem, ItemValidationGroup } from './promo-list-item';
import { PromoListType } from './promo-list-type';
import { ItemStatus } from './item-status';
import { CarrierCompanySummary } from '../company/company-carrier';
import { UserProfile } from '../user/user-profile';
import { toSentence } from '../utility/helper';

const valueValidition: ItemValidationGroup = 'valueValidition';
const keyValidition: ItemValidationGroup = 'keyValidition';
@Exclude()
export class CarrierListItem extends PromoListItem {
  constructor() {
    super();
  }

  @IsDefined({ message: 'needs valid cid' })
  key: string;  // this is dictionary key, added in values for ui validation, saved in db as key

  @Expose()
  name: string;

  @Expose()
  legalName: string;
  // uiDescription?: string;

  @Expose({toClassOnly: true})
  get uiDescription() {
    return toSentence(`${this.name}(${this.legalName})`);
  }
  public static parse(obj) {
    if (obj == null) { return null; }
    const m = plainToInstance<CarrierListItem, any>(CarrierListItem, sanitizeDateIPoint(obj));
    // m.sanitize();
    return m;
  }
  public static carriersFromUserProfile(userProfile: UserProfile): { [key: string]: CarrierListItem} {
    const cList: { [key: string]: CarrierListItem} = {};
    for (const key in userProfile.notifiedFor) {
      if (userProfile.notifiedFor.hasOwnProperty(key)) {
        const ele = userProfile.notifiedFor[key];
        cList[key]  = CarrierListItem.parse(ele);
      }
    }
    return cList;
  }
  clone() {
    const t = instanceToInstance(this);
    // t.sanitize();
    return t;
  }
  validateSyncGroup(group?: ItemValidationGroup): IValidationMsg {
    return this.validateSync({ groups: [group] });
  }
  validateSync(options?: ValidatorOptions, list?: CarrierList): IValidationMsg {
    const r = validateSync(this, options);
    const m = toValidationError(r);
    if (!!list && !!list.isDuplicate(this.key)) {
      m['key'] = [`duplicate entry ${this.key}`];
    }
    return m;
  }
}

export type CarrierListValidationGroup = 'selectMode' | 'addMode';
const selectMode: CarrierListValidationGroup = 'selectMode';
const addMode: CarrierListValidationGroup = 'addMode';

/** List of Carriers registered for trip tracking promo */
@Exclude()
export class CarrierList extends PromoListBase {

  @Expose()
  list: { [key: string]: CarrierListItem };

  @IsDefined({ message: 'carrier needs to be defined', groups: [selectMode] })
  entry: { [key: string]: CarrierListItem };

  key: string; // binds to new item key to be added


  public static parse(obj) {
    if (obj == null) { return null; }
    const m = plainToInstance<CarrierList, any>(CarrierList, sanitizeDateIPoint(obj));
    m.sanitize();
    return m;
  }
  static createPromoList(entry: { [key: string]: CarrierListItem }, cid: number | string)
    : CarrierList {
    const r = new CarrierList();
    r.cid = cid;
    r.list = entry;
    r.listType = PromoListType.carrier;
    return r;
  }
  static updatePromoList(entry: { [key: string]: CarrierListItem }, carrierList: CarrierList): CarrierList {
    const k = Object.keys(entry)[0];
    if (!!k) {
      carrierList.list[k] = entry[k];
      return carrierList;
    } else {
      return null;
    }

  }
  validateSyncGroup(group: ListValidationGroup): IValidationMsg {
    return this.validateSync({ groups: [group] });
  }
  validateSync(options?: ValidatorOptions): IValidationMsg {

    const r = this.validateSyncBase(this, options);

    if (!!this.list && !!this.checkDuplicateKey() && this.checkDuplicateKey().length > 0) {
      r['entry'] = [` ${this.checkDuplicateKey().length} duplicate CID in the list, ${this.checkDuplicateKey()[0]}`];
    }
    return r;

  }
  clone() {
    const t = instanceToInstance(this);
    t.sanitize();
    return t;
  }
  addNewEntry(newEntry: { [key: string]: CarrierListItem }): CarrierList | null {
    const k = Object.keys(newEntry)[0];
    const v = Object.values(newEntry)[0];
    this.list[`${k}`] = v;
    return this;
  }
  isDuplicate(v: string): boolean {
    const a = Object.keys(this.list);
    return !!a.includes(v) ? true : false;
  }
  createNewEntry(v: string, itemStatus?: ItemStatus): { [key: string]: CarrierListItem } {
    let c: { [key: string]: CarrierListItem };
    c = <any>{ [v]: { name: 'namePlaceHolder', legalName: `placeholder${v}`, itemStatus: !!itemStatus ? itemStatus : ItemStatus.active } };
    return c;
  }
  checkDuplicateKey(): string[] {
    let duplicates: string[] = [];

    const findDuplicates = arr => arr.filter((item, index) => arr.indexOf(item) !== index);
    duplicates = !!this.list && Object.keys(this.list).length > 0 ? findDuplicates(Object.keys(this.list)) : null;
    return duplicates;
  }
  /** transform list from dB no change required for Carrier list */
  get listForUI(): { [key: string]: CarrierListItem; } {
    return this.list;
  }
}
