import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SocialShareComponent } from './social-share.component';
import {MatListModule} from '@angular/material/list';
import { MaterialModule } from '../share-module/material.module';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons/faWhatsapp';



@NgModule({
  declarations: [SocialShareComponent],
  imports: [
    CommonModule,
    MatListModule,
    MaterialModule
  ]
})
export class SocialShareModule {
  constructor(library: FaIconLibrary) {
    // https://github.com/FortAwesome/angular-fontawesome/blob/master/docs/usage/using-other-styles.md
    library.addIcons(faWhatsapp);
  }
}
