import { IsArray, IsBoolean, IsDate, IsDefined, IsEnum, isEnum, IsIn, IsNumber, Length, MaxLength, MinLength, ValidateIf, ValidateNested, ValidatorOptions } from 'class-validator';
import { Exclude, Expose, instanceToInstance, plainToInstance, Type } from 'class-transformer';
import { addDays, addYears, daysInWeek, differenceInDays, isAfter, isBefore, isDate, isEqual, startOfDay } from "date-fns";
import { logger } from "../log/logger";
import { F } from "@angular/cdk/keycodes";
import { BidBase } from "../bid/bid-base";
import { IValDbStatusResult, IValidationMsg } from "../error-handling/validation-info";
import { sanitizeDate, sanitizeDateIPoint } from "../utility/sanitize-helper";
import { BaseModel } from '../base';
import { FileInfo } from '../media/file-info';
import { Picture } from '../inspection/picture';
import { toPascalCase } from '../utility/helper';
import { StoreLocationSummary } from '../store-location/store-location';

/**
 * @author - MKN
 * Spare part Model and Interface 
 */

/**
 * @author - MKN
 * Interface spare part Sort by category
 */
export enum SparePartSortByCategory {
  BestSeller = 1,
  TopRated = 2,
}

/**
 * @author - MKN
 * Interface spare part category
 */
export enum SparePartCategory {
  TrailerParts = 1,
  Grilles = 2,
  Lightings = 3,
  SideMirrors = 4,
  Bezels = 5,
  SlackAdjuster = 6,
  Special = 7,
  Led = 8,
  ExhaustStack = 9,
  Suspension = 10,
  Valves = 11,
  BulbHarness = 12,
  CoolantTank = 13,
  Bumpers = 14,
  Combo = 15,
  AirDryer = 16,
  Accessories = 17,
  ShockAbsorbers = 18,
  LightTruck = 19,
  Fairing = 20,
  Switch = 21,
  BlowerMotor = 22,
}

/**
 * Group for Manage spare part form validation
 */
export type SparePartValidationGroup = 'general' | 'price' | 'pictures' | 'specification' | 'storeLocation';
const general: SparePartValidationGroup = 'general';
const price: SparePartValidationGroup = 'price';
const pictures: SparePartValidationGroup = 'pictures';
const specification: SparePartValidationGroup = 'specification';
const storeLocation: SparePartValidationGroup = 'storeLocation';

@Exclude()
export class SparePart extends BaseModel {

  public static readonly collectionName = 'spare-part';
  public static readonly sparePartImagePath = 'dpc/spare-part/pictures';


  constructor() {
    super();
    this.pictures = [];
    this.storeLocationSummary = { sid: '' };
  }


  // @Expose()
  // @IsEnum(SparePartCategory)
  // @IsDefined({ message: 'Category should be selected', groups: [general] })
  // category: SparePartCategory;

  @Expose()
  @Length(2, 150, { message: 'Name needs to be $constraint1-$constraint2 chars' , groups: [general]})
  @IsDefined({ message: 'Enter name, e.g. Slotted brake' , groups: [general]})
  name: string;

  @Expose()
  @Length(2, 250, { message: 'Description needs to be $constraint1-$constraint2 chars' , groups: [general]})
  @IsDefined({ message: 'Description should be provided' , groups: [general]})
  description: string;

  @Expose()
  @IsEnum(SparePartSortByCategory)
  @IsDefined({ message: 'SortBy Category should be selected', groups: [general] })
  sortBy: SparePartSortByCategory;

  @Expose()
  @Length(2, 250, { message: 'Sku Code needs to be $constraint1-$constraint2 chars', groups: [specification] })
  @IsDefined({ message: 'Sku Code info should be provided', groups: [specification] })
  skuCode: string;

  @Expose()
  @Length(2, 100, { message: 'Part Number needs to be $constraint1-$constraint2 chars', groups: [specification] })
  @IsDefined({ message: 'Part Number info should be provided', groups: [specification] })
  partNumber: string;
  
  @Expose()
  @Length(2, 150, { message: 'Fit for info needs to be $constraint1-$constraint2 chars', groups: [specification] })
  @IsDefined({ message: 'Fit for info should be provided', groups: [specification] })
  fitFor: string;

  @Expose()
  @IsNumber(undefined, { message: 'Price is required ', groups: [price]})
  price: number;

  //Manage to show hide price on UI
  @Expose()
  isPriceVisible: boolean = false;

  @Expose()
  @Type(() => Picture)
  @IsDefined({ message: 'pictures should be uploaded', groups: [pictures] })
  pictures: Picture[];

  @Expose()
	@IsDefined({ message: 'Store Location is required', groups: [storeLocation] })
	storeLocationSummary: StoreLocationSummary;

  @Expose()
  references: string;

  /**
   * @author - MKN
   * set Default Image path on UI
   */
  get defaultImage() {
    return './assets/tool.png';
  }

  /**
   * @author - MKN
   * parse spare part object
   */
  public static parse(obj) {
    if (obj == null) { return null; }
    const m = plainToInstance<SparePart, any>(SparePart, sanitizeDateIPoint(obj));
    m.sanitize();
    return m;
  }

   /**
   * @author - MKN
   * sanitize spare part object data
   */
  sanitize() {
    super.sanitize();
  }

   /**
   * @author - MKN
   * clone spare part object
   */
  clone() {
    const t = instanceToInstance(this);
    t.sanitize();
    return t;
  }
  
   /**
   * @author - MKN
   * Validate spare part object
   */
  validateSync(options?: ValidatorOptions): IValidationMsg {

    const r = this.validateSyncBase(this, options);
    if(this.pictures.length == 0){
      r['pictures'] = ['Picture must be required'];
    }
    return r;
  }

  /**
   * Validate group wise form on UI
   * @param group 
   * @returns 
   */
	validateSyncGroup(group: SparePartValidationGroup): IValidationMsg {
		return this.validateSync({ groups: [group] });
	}

  
  /**
   * manage left panel validation on UI
   * @returns 
   */
	getValDbStatus(): IValDbStatusResult {
		const result: IValDbStatusResult = {
			pass: false,
			message: undefined,
			groupResult: {}
		};

		let x: { groupPass: boolean; message: string; };

		// general
		let r = this.validateSyncGroup(general);
		x = { groupPass: null, message: '' };
    x.groupPass = Object.keys(r).length === 0;
		x.message = (x.groupPass) ? 'General Information Details' : 'General information is required';
		result.groupResult[general] = x;

    // storeLocation
		r = this.validateSyncGroup(storeLocation);
		x = { groupPass: null, message: '' };
		x.groupPass = (this.storeLocationSummary.sid) ? true : false;
		x.message = (x.groupPass) ? 'Store Location Information Details' : 'Store Location information is required';
		result.groupResult[storeLocation] = x;

		// price
		r = this.validateSyncGroup(price);
		x = { groupPass: null, message: '' };
		x.groupPass = Object.keys(r).length === 0;
		x.message = x.groupPass ? 'Manage Pricing' : 'Price is required';
		result.groupResult[price] = x;

		// pictures
		r = this.validateSyncGroup(pictures);
		x = { groupPass: null, message: '' };
		x.groupPass = this.pictures.length > 0;
		x.message = x.groupPass ? 'Product pictures' : 'Upload pictures';
		result.groupResult[pictures] = x;

		// specification
		r = this.validateSyncGroup(specification);
		x = { groupPass: null, message: '' };
		x.groupPass = Object.keys(r).length === 0;
		x.message = x.groupPass ? 'Product Specifications' : 'Specifications is required';
		result.groupResult[specification] = x;
		return result;
    
	}

   /**
   * @author - MKN
   * Update spare part object propery
   */
  updatePartDetails(d : SparePart){
    this.name = d.name;
    this.description = d.description;
    this.isPriceVisible = d.isPriceVisible;
    this.price = d.price;
    this.sortBy = d.sortBy;
    this.fitFor = d.fitFor;
    this.partNumber = d.partNumber;
    this.skuCode = d.skuCode;
    this.references = d.references;
  }
  
}