//#region **************************Helper Functions, Not Exported
// add zeros to the left
const pad = (s: string, size: number): string => {
  while (s.length < size) {
    s = '0' + s;
  }
  return s;
};

/** Change a character at a given index inside the string. */
const replaceAt = (str: string, index: number, replacement: string): string => {
  return str.substr(0, index) + replacement + str.substr(index + replacement.length);
};
// #endregion



/** Must be inline with IUserType . Helper Function , not exported.*/
const userTypeVal = {
  isCarrier: 0,
  isDriver: 1,
  isShipper: 2
};

// must be inline with UserTypeVal
export interface IUserType {
  isCarrier: boolean;
  isDriver: boolean;
  isShipper: boolean;
}

/** Get User Type from the given number saved in claim
 * Only true values are shown against agiven number.
 *  C    D   S   CD    CS    DS    CDS
 *  1    2   4   3     5     6     7
 *  @param bn : integer number representation from the user claim
 *  @return IUserType to be stored inside the user profile.
*/
export const getUserType = (bn: number): IUserType => {
  // default all types
  const utype: IUserType = {
    isCarrier: false,
    isDriver: false,
    isShipper: false
  };

  let n = (!isNaN(+bn)) ? +bn : bn;
  n = (isNaN(n)) ? 0 : n;

  // limit the max. this should never be needed.
  if (n > 1024) { n = 1024; }

  const ntypes = Object.keys(userTypeVal).length;
  // Binary representation.
  // tslint:disable-next-line:no-bitwise
  const bstr = pad((n >>> 0).toString(2), ntypes);

  for (const key in utype) {
    if (utype.hasOwnProperty(key)) {
      const bloc = userTypeVal[key];
      // binary number coefficients are counted from the right end.
      if (bstr[ntypes - bloc - 1] === '1') {
        utype[key] = true;
      }
    }
  }  
  return utype;
};


/**
 * 
 * @param utype user type from the profile
 * @returns equivelent number to be saved in the claim.
 */
export const getUserTypeNumber = (utype: IUserType): number => {
  const ntypes = Object.keys(userTypeVal).length;
  // Binary representation.
  let bstr = pad('', ntypes);

  for (const key in utype) {
    if (utype.hasOwnProperty(key)) {
      const flag: boolean = utype[key];
      const bloc = userTypeVal[key];
      // Location is from the right end.
      bstr = replaceAt(bstr, ntypes - bloc - 1, flag ? '1' : '0');
    }
  }
  return parseInt(bstr, 2);
};

