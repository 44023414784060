import { Equals, IsBoolean, isNumber, IsNumber, Length, Max, ValidateIf, validateSync, IsDefined, ValidateNested, IsDate, ValidatorOptions, isDefined, Matches } from 'class-validator';
import { Exclude, instanceToInstance, plainToInstance, Expose, Type } from 'class-transformer';
import { IValidationMsg } from '../error-handling';
import { sanitizeDate, sanitizeDateIPoint, toValidationError } from '../utility';
import { addYears, isAfter, lastDayOfMonth } from 'date-fns';
import { BaseModel } from '../base';
import { Address } from '../address/address';
import { PromoListBase } from '../promo';
import { logger } from '../log/logger';
import { NetSuiteProductData } from '../product/netsuite-product-data';
import { ProductSummary } from '../product/product-helper';

/**
 * @author - MKN
 * @purpose - NetSuite product sync error logs type
 */
export enum NetSuiteProductSyncErrorLogsType {
  openRentalError = 1,
  closedRentalError = 2,
  obsoleteRentalError = 3
}

/**
 * @author - MKN
 * @purpose - NetSuite product sync error logs type str
 */
export enum NetSuiteProductSyncErrorLogsStr {
  openRentalError = 'Open Rental Error',
  closedRentalError = 'Closed Rental Error',
  obsoleteRentalError = 'Obsolete Rental Error'
}

const regex = {
  js: {
    // tslint:disable-next-line:max-line-length

    nounVin: /^(([a-h,A-H,j-n,J-N,p-z,P-Z,0-9]{9})([a-h,A-H,j-n,J-N,p,P,r-t,R-T,v-z,V-Z,0-9])([a-h,A-H,j-n,J-N,p-z,P-Z,0-9]{7}))$/,
  }
};


/**
 * @author - MKN
 * Maintain subsidiary information 
 */
export interface ISubsidiaryLinkUp {
  sId: number;
  name: string;
}

/**
 * @author - MKN
 * @purpose - Maintain all subsidiary sync error logs
 */
@Exclude()
export class NetSuiteProductSyncLogs extends BaseModel {
  
  public static readonly collectionName = 'netsuite-product-sync-logs';

  @Expose() 
  @Matches(regex.js.nounVin, { message: 'Invalid vin' })
  vin: string;

  @Expose() 
  errorLogType: NetSuiteProductSyncErrorLogsType;

  @Expose() 
  subsidiaryId: ISubsidiaryLinkUp;
  
  @Expose() 
  syncTime: Date;

  @Expose()
  netSuiteProductData :  NetSuiteProductData;

  constructor() {
    super();
  }

  public static parse(obj) {
    if (obj instanceof (NetSuiteProductSyncLogs)) { return <NetSuiteProductSyncLogs>obj; }

    const m = plainToInstance<NetSuiteProductSyncLogs, any>(NetSuiteProductSyncLogs, sanitizeDateIPoint(obj));
    m.sanitize();
    return m;
  }

  sanitize() {
    // super.sanitize();
  }

  clone() {
    const t = instanceToInstance(this);
    t.sanitize();
    return t;
  }

  validateSync(options?: any): IValidationMsg {
    const r = this.validateSyncBase(this,options);
    return r;
  }

}
