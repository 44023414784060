import { BaseModel } from '@trent/models/base';
import { Injectable } from '@angular/core';
// tslint:disable-next-line:max-line-length
import { map, take, expand, takeWhile, mergeMap } from 'rxjs/operators';
import { Observable, firstValueFrom, from } from 'rxjs';
import { sanitizeDateIPoint } from '@trent/models/utility/sanitize-helper';
import { promiseWraper } from '@trent/models/utility/helper';
import { logger } from '@trentm/log/logger';

import { SetOptions, doc, collection, CollectionReference, docData, Firestore, query, collectionData, Query, serverTimestamp, GeoPoint, DocumentReference, DocumentData, setDoc, updateDoc, deleteDoc, writeBatch, orderBy, limit, addDoc, getCountFromServer } from '@angular/fire/firestore';
import { doc as docFb, getDoc as getDocFb, getDocFromCache, getFirestore, where } from 'firebase/firestore';
import { ZoneUtlService } from './zone-utl.service';
// export type QueryFunc <T = DocumentData> = (ref: CollectionReference<T>) => Query<T>;
export type QueryFunc = (ref: CollectionReference) => Query;

export interface IBatchSet<T> {
  ref: string; // CollectionPredicate<T>;
  data: T;
  id: string | number;
  updateIfExisting: boolean;
  opt?: SetOptions;
}

export interface DataWithID<T, IdType> {
  data: T;
  id: IdType;
}

@Injectable({ providedIn: 'root' })
export class FirestoreService {

  // TODO NG14
  // readonly geo = geofirex.init(firebase);

  // private _db: firebase.firestore.Firestore;

  // public get db() {
  //   if (this._db == null) {
  //     this._db = firebase.firestore();
  //   }
  //   return this._db;
  // }

  constructor(public firestore: Firestore, public zs: ZoneUtlService) {
    logger.log('Firestore Service constructor called');

  }



  /// **************
  /// Get a Reference
  /// **************

  // col<T>(ref: CollectionPredicate<T>, queryFn?: QueryFn): AngularFirestoreCollection<T> {
  //   return typeof ref === 'string' ? this.afs.collection<T>(ref, queryFn) : ref;
  // }

  private col<T = DocumentData>(ref: string, queryFn?: QueryFunc): Query<DocumentData> {
    const colRef = collection(this.firestore, ref);
    return (typeof queryFn === 'function') ? queryFn(colRef) : query(colRef);
  }

  doc<T>(ref: string): DocumentReference<DocumentData> {
    return doc(this.firestore, ref); // typeof ref === 'string' ? this.afs.doc<T>(ref) : ref;
  }

  /// **************
  /// Get Data
  /// ***************

  doc$<T>(ref: string): Observable<T> {
    return this.zs.zoneAwareOb$(
      docData(doc(this.firestore, ref))   //  this.doc(ref).snapshotChanges()
        .pipe(map(doc1 => sanitizeDateIPoint(doc1) as T)));
  }

  /** Traditional call to get the promises only. used for SSR. */
  async docP<T>(ref: string | number, idField?: string): Promise<T> {
    const docRef = await docFb(getFirestore(), `${ref}`);
    try {
      const docSnap = await getDocFb(docRef);
      const data = docSnap.data();
      if (typeof idField != undefined) {
        data[`${idField}`] = docSnap.id;
      }
      return data as T;
    } catch (error) {
      console.error(`No data in fb was found in collection ${ref}`);
    }
  }

  docWithInjectedId$<T>(ref: string, idField: string | number = 'id'): Observable<T> {
    return this.zs.zoneAwareOb$(
      docData(doc(this.firestore, ref), { idField: 'id' })   //  this.doc(ref).snapshotChanges()
        .pipe(map(doc1 => {
          if (!!idField && (idField as String).includes('/')) {
            doc1.id = idField;
          }
          return sanitizeDateIPoint(doc1) as T;
        })));
  }



  col$<T>(ref: string, queryFn?: QueryFunc): Observable<T[]> {
    const colRef = collection(this.firestore, ref);
    const q = (typeof queryFn === 'function') ? queryFn(colRef) : query(colRef);

    return this.zs.zoneAwareOb$(
      collectionData(q, { idField: 'id' }).pipe(map(actions => {
        return actions.map(a => sanitizeDateIPoint(a.payload.doc.data()) as T);
      })));
  }

  /// with Ids
  colWithIds$<T>(ref: string, queryFn?: (ref: CollectionReference) => Query) {
    const colRef = collection(this.firestore, ref);
    const q = (typeof queryFn === 'function') ? queryFn(colRef) : query(colRef);

    return this.zs.zoneAwareOb$(
      collectionData(q, { idField: 'id' }).pipe(map(actions => {
        return actions.map(a => {
          const data = sanitizeDateIPoint(a.payload.doc.data()) as T;
          const id: string | number = (data as any)?.id;
          return { data, id };
        });
      })));
    // return this.col(ref, queryFn).snapshotChanges().pipe(map(actions => {
    //   return actions.map(a => {
    //     const data: T = sanitizeDateIPoint(a.payload.doc.data());
    //     const id = a.payload.doc.id;
    //     return { id, data }; // ... data does not work.
    //   });
    // }));
  }

  colWithIdsInjected$<T>(ref: string, queryFn?: (ref: CollectionReference) => Query): Observable<T[]> {

    const colRef = collection(this.firestore, ref);
    const q = (typeof queryFn === 'function') ? queryFn(colRef) : query(colRef);

    return this.zs.zoneAwareOb$(
      collectionData(q, { idField: 'id' }).pipe(map(actions => {
        return actions.map(a => {
          return sanitizeDateIPoint(a);
        });
      })));

    // return this.col(ref, queryFn).snapshotChanges().pipe(map(actions => {
    //   return actions.map(a => {
    //     const data = a.payload.doc.data();
    //     const id = a.payload.doc.id;
    //     (<any>data).id = id;
    //     return sanitizeDateIPoint(data);
    //   });
    // }));
  }


  colWithIdsInjectedNew$<T>(path: string, queryFn?: (ref: CollectionReference) => Query): Observable<T[]> {

    const colRef = collection(this.firestore, path);
    const q = (typeof queryFn === 'function') ? queryFn(colRef) : query(colRef);

    return this.zs.zoneAwareOb$(
      collectionData(q, { idField: 'id' }).pipe(map(actions => {
        return actions.map(a => {
          // const data = a.payload.doc.data();
          // const id = a.payload.doc.id;
          // (<any>data).id = id;
          return sanitizeDateIPoint(a);
        });
      })));
  }
  getDocumentCount$<T>(path: string, queryFn?: (ref: CollectionReference) => Query): Observable<number> {

    const colRef = collection(this.firestore, path);
    const q = (typeof queryFn === 'function') ? queryFn(colRef) : query(colRef);

    // return getCountFromServer(q).then(snap => {
    //   return snap.data().count;
    // });
    const countObservable = from(getCountFromServer(q));
    return countObservable.pipe(map(r => r.data().count));


  }
  /// **************
  /// Write Data
  /// **************


  /// Firebase Server Timestamp
  get timestamp() {
    // return firebase.firestore.FieldValue.serverTimestamp();
    return serverTimestamp();
  }

  set<T>(ref: string, data: any, blockDate?: boolean, options?: SetOptions) {
    const timestamp = this.timestamp;
    const docRef = doc(this.firestore, ref);

    return (!!blockDate) ? setDoc(docRef, data, options) :
      setDoc(docRef, {
        ...data,
        updatedAt: timestamp,
        createdAt: timestamp
      }, options);

    // OLD compat @dep
    // return (!!blockDate) ? this.doc(ref).set(data, options) :
    //   this.doc(ref).set({
    //     ...data,
    //     updatedAt: timestamp,
    //     createdAt: timestamp
    //   }, options);
  }

  update<T>(ref: string, data: any) {
    const docRef = doc(this.firestore, ref);
    return updateDoc(docRef, {
      ...data,
      updatedAt: this.timestamp
    });

    // OLD
    // return this.doc(ref).update({
    //   ...data,
    //   updatedAt: this.timestamp
    // });
  }


  delete<T>(ref: string) {
    const docRef = doc(this.firestore, ref);
    return deleteDoc(docRef);
  }

  add<T>(ref: string, data) {
    const docRef = collection(this.firestore, ref);

    const timestamp = this.timestamp;
    return addDoc(docRef, {
      ...data,
      updatedAt: timestamp,
      createdAt: timestamp
    });

    // return  this.col(ref).add({
    //   ...data,
    //   updatedAt: timestamp,
    //   createdAt: timestamp
    // });
  }

  // async addWithId<T>(
  //   ref: CollectionPredicate<T>,
  //   data, id: string | number,
  //   updateIfExisting: boolean,
  //   opt?: SetOptions) {

  //   // If it is not updateing request, create must not overwrite an exsiting entry.
  //   if (!updateIfExisting) {
  //     const r = await promiseWraper(this.doc(`${ref}/${id}`).snapshotChanges().pipe(take(1)).toPromise());
  //     if (r.success && r.data.payload.exists) {
  //       throw Error(`Record already exists in the database. Can not insert with a duplicate key: ${id}`);
  //     }
  //   }

  //   const timestamp = this.timestamp;
  //   const o = !!opt ? opt : {};
  //   // logger.log(`[addWithId] ${ref} ${id}`);
  //   return this.col(ref).doc(`${id}`).set({
  //     ...data,
  //     updatedAt: timestamp,
  //     createdAt: timestamp
  //   }, o);
  // }

  geopoint(lat: number, lng: number) {
    return new GeoPoint(lat, lng);
  }


  // /// If doc exists update, otherwise set
  // upsert<T>(ref: DocPredicate<T>, data: any) {
  //   const doc1 = this.doc(ref).snapshotChanges().pipe(take(1)).toPromise();

  //   return doc1.then(snap => {
  //     return snap.payload.exists ? this.update(ref, data) : this.set(ref, data);
  //   });
  // }

  docId(col: string) {
    const docRef = doc(collection(this.firestore, col)) as DocumentReference;
    return docRef.id;

    //  const id = this.afs.createId();
    // return id;
  }
  /// **************
  /// Inspect Data
  /// **************


  // inspectDoc(ref: DocPredicate<any>): void {
  //   const tick = new Date().getTime()
  //   this.doc(ref).snapshotChanges().pipe(
  //     take(1)
  //     .tap(d => {
  //       const tock = new Date().getTime() - tick
  //       logger.log(`Loaded Document in ${tock}ms`, d)
  //     }))
  //     .subscribe();
  // }


  // inspectCol(ref: CollectionPredicate<any>): void {
  //   const tick = new Date().getTime()
  //   this.col(ref).snapshotChanges().pipe(
  //     take(1)
  //     .tap(c => {
  //       const tock = new Date().getTime() - tick
  //       logger.log(`Loaded Collection in ${tock}ms`, c)
  //     }))
  //     .subscribe()
  // }



  /// **************
  /// Create and read doc references
  /// **************

  // /// create a reference between two documents
  // connect(host: DocPredicate<any>, key: string, doc1: DocPredicate<any>) {
  //   return this.doc(host).update({ [key]: this.doc(doc1).ref });
  // }


  /// returns a documents references mapped to AngularFirestoreDocument
  // docWithRefs$<T>(ref: DocPredicate<T>) {
  //   return this.doc$(ref).pipe(map(doc => {
  //     for (const k of Object.keys(doc)) {
  //       if (doc[k] instanceof firebase.firestore.DocumentReference) {
  //         doc[k] = this.doc(doc[k].path);
  //       }
  //     }
  //     return doc;
  //   }));
  // }




  /// **************
  /// Atomic batch example
  /// **************

  async batchSet<T extends BaseModel>(data: IBatchSet<T>[]) {

    const batch = writeBatch(this.firestore); //   this.afs.firestore.batch();
    for (const d of data) {

      const timestamp = this.timestamp;
      // If it is not updating request, create must not overwrite an existing entry.
      if (!d.updateIfExisting) {
        // https://rxjs.dev/deprecations/to-promise
        const r = await promiseWraper(firstValueFrom(this.doc$<DocumentData>(d.ref))); //  doc(this.firestore, d.ref);  await promiseWraper(this.doc(`${d.ref}`).snapshotChanges().pipe(take(1)).toPromise());
        if (r.success && !!r?.data) { //r?.data?.payload.exists) {
          throw Error(`Record already exists in the database. Can not insert with a duplicate key: ${d.id}`);
        }
        d.data.createdAt = timestamp as any;
      } else {
        d.data.updatedAt = timestamp as any;
      }


      const o = !!d.opt ? d.opt : {};
      const x = doc(this.firestore, d.ref); //  this.afs.firestore.doc(`${d.ref}`); //  this.col(d.ref).doc(`${d.id}`);

      // const predicate: any = (typeof d.ref === 'string') ? this.afs.firestore.doc(`${d.ref}`) : d.ref;

      batch.set(
        x,
        {
          ...d.data as any,
          // updatedAt: timestamp,
          // createdAt: timestamp
        },
        o
      );
    }
    return batch.commit();
  }


  // /// Just an example, you will need to customize this method.
  // batchSetNotUsed<T>(d: { [path: string]: T }, options?: SetOptions) {
  //   const batch = this.afs.firestore.batch();

  //   for (const path in d) {
  //     if (d.hasOwnProperty(path)) {
  //       const dataRef = this.afs.firestore.doc(path);
  //       batch.set(dataRef, d[path], options);
  //     }
  //   }
  //   /// add your operations here
  //   // const itemDoc = this.afs.firestore.doc('items/myCoolItem');
  //   // const userDoc = this.afs.firestore.doc('users/userId');

  //   // const currentTime = this.timestamp;
  //   // batch.update(itemDoc, { timestamp: currentTime });
  //   // batch.update(userDoc, { timestamp: currentTime });
  //   /// commit operations
  //   return batch.commit();
  // }

  /// ****************************
  /// Delete Collections
  /// ***************************

  deleteCollection(path: string, batchSize: number): Observable<any> {

    const source = this.deleteBatch(path, batchSize);

    // expand will call deleteBatch recursively until the collection is deleted
    return this.zs.zoneAwareOb$(
      source.pipe(
        expand(() => this.deleteBatch(path, batchSize)),
        takeWhile(val => val > 0)
      ));

  }

  // Deletes documents as batched transaction
  private deleteBatch(path: string, batchSize: number): Observable<any> {
    logger.warn('[Firestore Delete Batch] Function is not tested!');
    const colRef = collection(this.firestore, path); //   this.co  this.afs.collection(path, ref => ref.orderBy('__name__').limit(batchSize));
    const q = query(colRef, orderBy('__name__'), limit(batchSize));

    return this.zs.zoneAwareOb$(
      collectionData(q, { idField: 'id' }).pipe(
        take(1),
        mergeMap(snapshot => {
          // Delete documents in a batch
          const batch = writeBatch(this.firestore); //  delete this.afs.firestore.batch();
          snapshot.forEach(doc1 => {
            if (typeof doc1?.id === 'string' || typeof doc1?.id === 'number') {
              batch.delete(doc(this.firestore, `${path}/${doc1.id}`)); //  doc1.payload.doc.ref);
            }
          });
          return from(batch.commit()).pipe(map(() => snapshot.length));
        })
      ));
  }
  async colCount$<T>(ref: string, queryFn?: QueryFunc): Promise<number> {
    const colRef = collection(this.firestore, ref);
    const q = (typeof queryFn === 'function') ? queryFn(colRef) : query(colRef);

    // const coll = collection(this.firestore, ref);
    // const q = query(coll, where("state", "==", "CA"));
    return (await getCountFromServer(q)).data().count;
  
  }
}
