import { DeepCopy } from '../utility/deep-copy';
import { getObjKey, Paging, getDirection, IKeyVal } from '../observable-util/paging';
import { CollectionReference, limit, orderBy, query, Query, QueryConstraint, startAfter, where } from 'firebase/firestore';
import { NetSuiteProductSyncErrorLogsType, NetSuiteProductSyncLogs } from './netsuite-product-sync-logs';

export interface INetSuiteProductSyncLogsParam {

  subsidiaryId?: number;
  errorLogsType?: NetSuiteProductSyncErrorLogsType;
  orderBy?: string;
  updatedAt?: Date;
  logId?: string | number;
}

export const netSuiteProductSyncLogsParamInit = (): INetSuiteProductSyncLogsParam => {
  return {
    orderBy: 'syncTime',
  };
};
/**  */
export const getNetSuiteProductSyncLogsOptOrChildren = (o: INetSuiteProductSyncLogsParam): { [key: string]: INetSuiteProductSyncLogsParam } => {
  
  return undefined;
};
/** Server filtering of firebase query building of query  */
export const netSuiteProductSyncLogsSearchServerQuery = (ref: CollectionReference, o: INetSuiteProductSyncLogsParam, p: Paging) => {
  const cons: QueryConstraint[] = [];

  if (!!o.subsidiaryId) {
    cons.push(where('subsidiaryId', '==', o.subsidiaryId));  // open bids BidStatus.waitingForVendor = 1 waitingForCustomer = 2 BidStatus.accepted = 3,
  }
  if (!!o.errorLogsType) {
    // tslint:disable-next-line:max-line-length
    cons.push(where('errorLogsType', '==', o.errorLogsType));  // open bids BidStatus.waitingForVendor = 1 waitingForCustomer = 2 BidStatus.accepted = 3,
  }

  cons.push(limit(p.size));
  return query(ref, ...cons);
};

/** Client Filtering of the data */
export const netSuiteProductSyncLogsSearchClientFilter = (p: NetSuiteProductSyncLogs[], o: INetSuiteProductSyncLogsParam) => {

  if (!!o.subsidiaryId) {
    p = p.filter((val, idx) => val.subsidiaryId.sId === o.subsidiaryId);
  }
  if (!!o.errorLogsType) {
    p = p.filter((val, idx) => val.errorLogType === o.errorLogsType);
  }
  if(o.orderBy === 'syncTime'){
    p = p.sort((a,b) => b.syncTime.valueOf() - a.syncTime.valueOf());
  }
  return p;
};


