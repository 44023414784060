import { ILogger, IAppLoggers, LogLevel } from './ilog';
import { appLoggersConsole } from './console-Logger';

export type LoggerType = 'gps' | 'finance' | 'sms' | 'critical';

/**
 * Default Logger is the console logger
 * at run time, function may replace by firebase looger or cloud looger
 */
let _applogger: IAppLoggers = appLoggersConsole;

let _logLevel: LogLevel = LogLevel.ALL;

/**
 * @param l can be a function/cloud/console logger. Default is console.
 * pass null for console logging.
 */
export const setDefaultLogger = (l: IAppLoggers = appLoggersConsole): void => {
  _applogger = l;
};

export const logger: ILogger = {

  log: (message?: any, ...arg: any[]) => {
    if (_logLevel <= LogLevel.DEBUG) {
      _applogger.default.log(message, ...arg);
    }
  },

  info: (message?: any, ...arg: any[]) => {
    if (_logLevel <= LogLevel.INFO) {
      _applogger.default.info(message, ...arg);
    }
  },

  warn: (message?: any, ...arg: any[]) => {
    if (_logLevel <= LogLevel.WARN) {
      _applogger.default.warn(message, ...arg);
    }
  },

  error: (message?: any, ...arg: any[]) => {
    if (_logLevel <= LogLevel.ERROR) {
      _applogger.default.error(message, ...arg);
    }
  },
};

// const getLoggerByName = (name: string) => _applogger.getLogger(name);

const customLogger: ILogger = {
  name: 'default',

  log: function (message?: any, ...arg: any[]) {
    if (_logLevel <= LogLevel.DEBUG) {
      _applogger.getLogger(this.name).log(message, ...arg);
    }
  },

  info: function (message?: any, ...arg: any[]) {
    if (_logLevel <= LogLevel.INFO) {
      _applogger.getLogger(this.name).info(message, ...arg);
    }
  },

  warn: function (message?: any, ...arg: any[]) {
    if (_logLevel <= LogLevel.WARN) {
      _applogger.getLogger(this.name).warn(message, ...arg);
    }
  },

  error: function (message?: any, ...arg: any[]) {
    if (_logLevel <= LogLevel.ERROR) {
      _applogger.getLogger(this.name).error(message, ...arg);
    }
  }
};


export const loggerFactory = {
  /** Get the current default logger. Same as 'logger' */
  default: logger,

  /** Build a new logger or get a previously created logger  */
  getLogger: (name: LoggerType): ILogger => ({ ...customLogger, name: name }),

  setLogLevel: (level: LogLevel) => {
    if (!isNaN(level)) {
      _logLevel = level;
     console.log('[LogLevel] was changed to: ', level);
    }
  },
};
