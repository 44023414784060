import { isNumber } from 'lodash';
import { InvoiceItem } from '@trent/models/finance/invoice/invoice-item';
import { Exclude, Expose } from 'class-transformer';
import { Min } from 'class-validator';
import { SpecialItemType } from './invoice-item-type';

@Exclude()
export class BaseFee {

  /** Default is custom. */
  @Expose()
  type: SpecialItemType; // = 'custom';

  @Expose()
  name: string;

  @Expose()
  @Min(0)
  amount = 0;

  @Exclude()
  isTaxable: boolean;

  get taxableAmount() {
    return this.isTaxable ? this.amount : 0;
  }

  constructor(a?: number) {
    if (isNumber(a)) {
      this.amount = a;
    }
  }
}


