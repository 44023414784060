import { sanitizeDate, sanitizeDateIPoint } from '../utility/sanitize-helper';
import { IsDate, validateSync, IsEnum, IsDefined, IsNumber, ValidateIf, ValidatorOptions, Length, Min, Matches, Max, ValidateNested, IsArray, IsBoolean, Equals } from 'class-validator';
import { plainToInstance, instanceToInstance, Exclude, Expose, Type } from 'class-transformer';
import { isDate, isNil } from 'lodash';
import { isAfter, isBefore, addDays, addWeeks, addMonths, isEqual, addYears, lastDayOfMonth } from 'date-fns';
import { isEqual as isEqualD } from 'date-fns';
import { Author } from '../bid/author';
import { RentalProductBase } from '../rental/rental-product-base';
import { NegoTermsResponse } from './nego-terms-response';
import { BidBase } from '../bid/bid-base';
import { RentOptionSummary } from '../rental/rent-option-summary';
import { BaseModel } from '../base/base-model';
import { BidStatus } from '../bid/bid-status';
import { IValidationMsg } from '../error-handling/validation-info';
import { ICcTokenData } from '../finance/payment-method/i-payment-method';
import { ICCard } from '../finance/payment-method/payment-profile';
import { vinRegex } from '../product/vehicle';
import { FileInfo } from '../media/file-info';
import { TermsType } from './terms-type';
import { SpecialItemTypeOptionalCombDb, SpecialItemTypeOptionalDb } from '../finance/invoice/invoice-item-type';
import { FinanceInput } from '../finance/finance-input/finance-input-handler';
import { ServiceType } from '../finance/service-fee/i-service-item';
import { logger } from '../log/logger';
import { toSentence } from '../utility/helper';
import { Address } from '../address/address';
import { TaxApplicable } from '../finance/tax/tax-applicable';
import { Levy } from '../finance/tax/levy';
import { BidDocuments } from '../bid/bid-documents';
import { IDurationArg, IPercentArg } from '../finance/finance-input/fn-lib';

export type BidContractDocType = 'signedDoc' | 'insCert' | 'supportingDocuments';// MKN - Added for signed copy and ins certificate
export type NegoTermsValidationGroup = 'comm' | 'agreement' | 'payment' | 'rejected' | 'replacement' | 'documents' | 'page1' | 'page2' | 'page3';;

/** commercial terms, rent/deposit/dates */
const comm: NegoTermsValidationGroup = 'comm';
const agreement: NegoTermsValidationGroup = 'agreement';
const payment: NegoTermsValidationGroup = 'payment';
const rejected: NegoTermsValidationGroup = 'rejected';
const replacement: NegoTermsValidationGroup = 'replacement';
const documents: NegoTermsValidationGroup = 'documents'; // MKN - Added for signed copy and ins certificate

@Exclude()
export class NegoTermsBase extends BaseModel {

  public static readonly gName = 'Address';

  constructor() {
    super();
    this.termsType = TermsType.negotiated;
  }
  @Expose()
  @IsEnum(TermsType)
  termsType: TermsType;

  // tslint:disable-next-line:max-line-length
  @ValidateIf(o => !!o.shouldValidate())
  @Expose()
  @IsDate({ message: 'Start date is required', groups: [comm] })
  startDate?: Date;

  // tslint:disable-next-line:max-line-length
  @ValidateIf(o => !!o.shouldValidate())
  @Expose()
  @IsDate({ message: 'End date is required', groups: [comm] })
  endDate?: Date;

  // @Expose()
  // @IsNumber()
  // rentPerDay?: number;

  // @Expose()
  // @IsNumber()
  rate: number;
  @ValidateIf(o => !!o.shouldValidate())
  @Expose()
  @Min(0, { groups: [comm] })
  deposit = 0;

  isRentAccepted = true;
  isDatesAccepted = true;
  isDepositAccepted = true;

  @Expose()
  @IsDefined({ groups: [comm, rejected] })
  author: Author;


  @Expose()
  @IsEnum(NegoTermsResponse, { message: 'Select Bid Response', groups: [comm, rejected] })
  bidResponse: NegoTermsResponse;

  /** Generic rental agreement */
  // tslint:disable-next-line:max-line-length
  @ValidateIf(o => !!o.shouldValidate())
  @Expose()
  @Length(1, 50, { message: 'Rental Agreement missing', groups: [agreement] })
  rentalAgreementId: string | number;

  /** Generic rental agreement revId*/
  // tslint:disable-next-line:max-line-length
  // @ValidateIf(o => !!o.shouldValidate())
  @Expose()
  @Min(0, { message: 'Rental Agreement error', groups: [agreement] })
  rentalAgreementRev: number;

  @Expose()
  @ValidateIf(o => o.termsType === TermsType.negotiated, { groups: [replacement] })
  @Length(3, 20, { message: 'Define Reference Contract Number', groups: [replacement] })
  replacementContractNo: string;

  // @ValidateIf(o => o.isReplacementContract===true)
  @Expose()
  @ValidateIf(o => o.termsType === TermsType.negotiated, { groups: [replacement] })
  @Matches(vinRegex.js.nounVin, { message: 'enter valid vin', groups: [replacement] })
  replacementVin: string;


  /** UI helper, true when user agrees to Terms */
  @Expose()
  @ValidateIf(o => o.termsType === TermsType.negotiated, { groups: [agreement] })
  @IsBoolean({ groups: [agreement] })
  @Equals(true,  { message: 'accept the terms to proceed', groups: [agreement] })
  signedTerms = false;

  /**
   * @author - MKN
   * @purpose - Vendor can upload singed copy and insurance certificate at time of approving bid request 
   *            and contract edit case
   */

  //  @Expose()
  //  @ValidateNested({ message: 'Upload signed document', groups : [documents]})
  //  @Type(() => FileInfo)
  /** @deprecated */
  signedDoc: FileInfo;

  //  @Expose()
  //  @ValidateNested({ message: 'Upload Insurance certificate', groups : [documents] })
  //  @Type(() => FileInfo)
  /** @deprecated */
  insCert: FileInfo;


  // @Expose()
  // @ValidateIf(o => o.insCert, { groups: [documents]})
  // @IsDate({ message: 'insurance start date is required', groups: [documents] })
  /** @deprecated */
  insCertStartDate: Date;


  // @Expose()
  // @ValidateIf(o => o.insCert, { groups: [documents]})
  // @IsDate({ message: 'insurance end date is required', groups: [documents] })
  /** @deprecated */
  insCertEndDate: Date;

  @Expose()
  @ValidateNested({ message: 'Upload Insurance certificate', groups: [documents] })
  @Type(() => BidDocuments)
  bidDocuments: BidDocuments;
  // @Expose({ toPlainOnly: true })
  // @IsArray()
  // @ValidateNested({ each: true })
  get addOns(): (SpecialItemTypeOptionalDb | SpecialItemTypeOptionalCombDb)[] {
    if (!this.addOnsAvailable) {
      this.addOnsAvailable = [];
    }
    const selected = this.addOnsAvailable.filter(f => f.isSelected).map(g => g.addOn);
    if (selected.length <= 1) {
      return selected;
      // return this.addOnsAvailable.filter(f => f.isSelected).map(g => g.addOn);
    } else {
      const a: SpecialItemTypeOptionalCombDb[] = [];
      if (selected.includes('gapInsurance')) {
        a.push('gapInsuranceComb');
      }
      if (selected.includes('waiverInsurance')) {
        a.push('waiverInsuranceComb');
      }
      if (selected.includes('walkAwayInsurance')) {
        a.push('walkAwayInsuranceComb');
      }
      return a;
    }
  }
  @Expose()
  @ValidateIf(o => o.taxApplicable === TaxApplicable.custom)
  @Type(() => Levy)
  @ValidateNested({ message: 'Define Tax Info' })
  taxItems: Levy[];

  @Expose()
  @IsEnum(TaxApplicable)
  taxApplicable: TaxApplicable = TaxApplicable.custom;


  @Expose()
  @IsArray()
  addOnsAvailable: { addOn: SpecialItemTypeOptionalDb, isSelected: boolean }[] = [];

  get insCertStartDateMinDate() {
    return addYears(this.startDate, -1);
  }

  get insCertStartDateMaxDate() {
    return lastDayOfMonth(addYears(this.startDate, 1));
  }


  get insCertEndDateMinDate() {
    return this.insCertStartDate;
  }

  get insCertEndDateMaxDate() {
    return lastDayOfMonth(addYears(this.startDate, 1));
  }


  /**
   * @author - MKN
   * @purpose - Check signed copy uploaded or not
   */
  //  @Expose()
  isSignedDocUploaded: boolean;

  /**
   * @author - MKN
   * @purpose - Check ins certificate uploaded or not
   */
  //  @Expose()
  isInsCertUploaded: boolean;

  // /** */
  // // tslint:disable-next-line:max-line-length
  // @ValidateIf(o => !!o.shouldValidate())
  // // @Expose()
  // @Length(1, 100, { message: 'Payment failed', groups: [payment] })
  // paymentToken: string;
  // /**
  //  * user name on payment card
  //  */
  // @ValidateIf(o => !!o.shouldValidate())
  // // @Expose()
  // @Length(1, 100, { message: 'Payment failed', groups: [payment] })
  // paymentUserName: string;

  ccData: ICcTokenData;
  pProfCard: ICCard;
  payMethod: 'cc' | 'pProfile' | 'manual'; //MKN - Sale Team Workflow - Sale person collect manual payment when he submit the bid

  public static parse(obj) {
    if (obj == null) { return null; }

    // if(isNil(obj.termsType)){
    //   obj.termsType = TermsType.negotiated;
    // }
    const m = plainToInstance<NegoTermsBase, any>(NegoTermsBase,
      sanitizeDateIPoint(obj));
    m.sanitize();
    return m;
  }
  get startDateIso(): string { return isDate(this.startDate) ? this.startDate.toISOString() : ''; }
  set startDateIso(val) { this.startDate = sanitizeDate(val); } // sanitizeIonicDatePicker(val); }

  get endDateIso(): string { return isDate(this.endDate) ? this.endDate.toISOString() : ''; }
  set endDateIso(val) { this.endDate = sanitizeDate(val); } // sanitizeIonicDatePicker(val); }

  sanitize() {
    super.sanitize();
    // if data was recieved from firebase, date is stored as snapshot.
    this.startDate = sanitizeDate(this.startDate);
    this.endDate = sanitizeDate(this.endDate);
    this.insCertStartDate = sanitizeDate(this.insCertStartDate);
    this.insCertEndDate = sanitizeDate(this.insCertEndDate);
  }

  clone() {
    const t = instanceToInstance(this);
    t.sanitize();
    return t;
  }
  validateSyncGroup(group: NegoTermsValidationGroup): IValidationMsg {
    return this.validateSync({ groups: [group] });
  }

  validateSync(options: ValidatorOptions, rentalProd?: RentalProductBase, bid?: BidBase, isContractChange = false): IValidationMsg {
    // availStartDate?: Date, availableEndDate?: Date
    // const r = this.validateSyncBase(this);
    // const m = validateSync(this);
    // const r = this.toValidationError(m);
    const r = this.validateSyncBase(this, options);
    if (this.termsType === TermsType.assignedByVendor) {
      return r;
    }

    if (!!rentalProd &&
      (this.bidResponse === NegoTermsResponse.acceptedByCustomer || this.bidResponse === NegoTermsResponse.acceptedByVendor)) {
      if (isDate(this.startDate)) {
        // expiry data must be in the future.
        if (isAfter(new Date(), this.startDate) && !isContractChange) {
          r['startDate'] = ['startDate date must be in future'];
        }
        // if (isAfter(rentalProd.availStartDate, this.startDate)) {
        //   r['startDate'] = ['startDate date must be available date range'];
        // }
        // if (isAfter(this.startDate, rentalProd.availEndDate)) {
        //   r['startDate'] = ['startDate date must be available date range'];
        // }
        if (!this.isDateInValidRange(rentalProd, this.startDate, bid)) {
          r['startDate'] = ['startDate date must be available date range'];
        }
      }
      if (isDate(this.endDate)) {
        if (isAfter(new Date(), this.endDate)) {
          r['endDate'] = ['endDate date must be in future'];
        }
        if (isAfter(this.startDate, this.endDate)) {
          r['endDate'] = ['endDate date must be after start date'];
        }

        // if (isAfter(this.endDate, rentalProd.availEndDate)) {
        //   r['endDate'] = ['endDate date must be available date range'];
        // }
        // if (isAfter(rentalProd.availStartDate, this.endDate)) {
        //   r['endDate'] = ['endDate date must be available date range'];
        // }
        if (!this.isDateInValidRange(rentalProd, this.endDate, bid)) {
          r['endDate'] = ['endDate date must be available date range'];
        }
      }

      if ((isDate(this.startDate) && isDate(this.endDate) && !this.isDateStraddleRanges(rentalProd, this.startDate, this.endDate, bid))) {
        r['endDate'] = ['start and end dates cannot straddle open date ranges'];
      }

      if (!!bid) {
        if (isBefore(bid.expiry, new Date())) {
          r['bidResponse'] = ['bid is expired'];
        }
      }
      if (isBefore(this.endDate, this.minTermEndDate(rentalProd.getRentOptionSummary()))) {
        r['endDate'] = ['date range does not meet available terms'];
      }
      if (isAfter(this.startDate, this.maxTermStartDate(rentalProd.getRentOptionSummary()))) {
        r['startDate'] = ['date range does not meet available terms'];
      }

      // if (!!bid && !bid.macthDateRange(this)) {
      //   r['bidResponse'] = ['bad response'];
      // }
      // if (!!bid && this.author === Author.vendor) {
      //   if (bid.compareBidNegoTerms(this) !== BidStatus.accepted && this.deposit === bid.bidNegoTerms.deposit) {
      //     r['bidResponse'] = ['invalid response'];
      //   }
      //   if (bid.compareBidNegoTerms(this) === BidStatus.waitingForCustomer && this.deposit === bid.bidNegoTerms.deposit) {
      //     r['bidResponse'] = ['bad response'];
      //   }
      //   if (bid.compareBidNegoTerms(this) !== BidStatus.waitingForCustomer && this.deposit !== bid.bidNegoTerms.deposit) {
      //     r['bidResponse'] = ['bad response'];
      //   }
      //   if (this.bidResponse !== NegoTermsResponse.acceptedByVendor) {
      //     r['bidResponse'] = ['bad response'];
      //   }
      // }
      // if (!!bid && this.author === Author.customer) {
      //   if (bid.compareBidNegoTerms(this) !== BidStatus.accepted && this.deposit === bid.bidNegoTerms.deposit) {
      //     r['bidResponse'] = ['invalid response'];
      //   }
      //   if (bid.compareBidNegoTerms(this) === BidStatus.waitingForVendor && this.deposit === bid.bidNegoTerms.deposit) {
      //     r['bidResponse'] = ['bad response'];
      //   }
      //   if (bid.compareBidNegoTerms(this) !== BidStatus.waitingForVendor && this.deposit !== bid.bidNegoTerms.deposit) {
      //     r['bidResponse'] = ['bad response'];
      //   }
      //   if (this.bidResponse !== NegoTermsResponse.acceptedByCustomer) {
      //     r['bidResponse'] = ['bad response'];
      //   }
      // }
    }
    // if (!!bid && this.author === Author.vendor) {
    //   if (this.bidResponse === NegoTermsResponse.rejectedByVendor
    //     && (!!this.startDate || !!this.endDate || !!this.deposit)) {
    //     r['bidResponse'] = ['bad response'];
    //   }
    // }
    // if (!!bid && this.author === Author.customer) {
    //   if (this.bidResponse === NegoTermsResponse.rejectedByCustomer
    //     && (!!this.startDate || !!this.endDate || !!this.deposit)) {
    //     r['bidResponse'] = ['bad response'];
    //   }
    // }
    if (!this.validateTermsToBidResponse(bid)) {
      r['bidResponse'] = ['bad response'];
    }
    return r;
  }
  /** check is dates selected are in available segements */
  isDateInValidRange(rentalP: RentalProductBase, d: Date, b?: BidBase): boolean {
    let openRange: { start: Date, end: Date }[];
    if (!b) { openRange = rentalP.openDateRange; } else {
      openRange = rentalP.getOpenRentalDates(b);
    }

    if (!!rentalP && !!d && openRange.length) {
      const bArray: boolean[] = [];
      for (let i = 0; i < openRange.length; i++) {
        if (d.valueOf() >= openRange[i].start.valueOf() && d.valueOf() <= openRange[i].end.valueOf()) {
          bArray.push(true);
        } else {
          bArray.push(false);
        }
      }
      if (bArray.includes(true)) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }
  /** check is dates selected straddle two different available segements */
  isDateStraddleRanges(rentalP: RentalProductBase, dFixed: Date, d: Date, b?: BidBase): boolean {
    let openRange: { start: Date, end: Date }[];
    if (!b) { openRange = rentalP.openDateRange; } else {
      openRange = rentalP.getOpenRentalDates(b);
    }

    if (!!rentalP && !!dFixed && !!d && openRange.length) {
      const ix = openRange.findIndex(f => dFixed.valueOf() >= f.start.valueOf() && dFixed.valueOf() <= f.end.valueOf());
      // console.log('dateRange', rentalP.openDateRange);
      // console.log('dFixed', dFixed);
      if (ix !== -1) {
        if (d.valueOf() >= openRange[ix].start.valueOf() && d.valueOf() <= openRange[ix].end.valueOf()) {
          console.log('d', d);
          return true;
        } else {
          return false;
        }
      } else { return true; }
    } else {
      return true;
    }
  }
  /** min end date based on the available term*/
  minTermEndDate(rentSummary: RentOptionSummary) {
    let d: Date;
    // if (this.startDate) {
    //   if (!!rentSummary.rateDaily) {
    //     d = this.startDate;
    //   } else if (!!rentSummary.rateWeekly && !rentSummary.rateDaily) {
    //     d = addDays(addWeeks(this.startDate, 1), -1);
    //   } else if (!!rentSummary.rateMonthly && !rentSummary.rateWeekly && !rentSummary.rateDaily) {
    //     d = addDays(addMonths(this.startDate, 1), -1);
    //   }
    // } else { d = new Date(); }
    const shortestTermPlan = rentSummary.rentalPlans.reduce((prev, current) => prev.rentalTerm < current.rentalTerm ? prev : current);
    d = addDays(this.startDate, shortestTermPlan.rentalTerm);
    return d;
  }
  /** max start date based on the available term*/
  maxTermStartDate(rentSummary: RentOptionSummary) {
    let d: Date;
    // if (this.endDate) {
    //   if (!!rentSummary.rateDaily) {
    //     d = this.endDate;
    //   } else if (!!rentSummary.rateWeekly && !rentSummary.rateDaily) {
    //     d = addDays(addWeeks(this.endDate, -1), 1);
    //   } else if (!!rentSummary.rateMonthly && !rentSummary.rateWeekly && !rentSummary.rateDaily) {
    //     d = addDays(addMonths(this.endDate, -1), 1);
    //   }
    // } else { d = new Date(); }
    const longestTermPlan = rentSummary.rentalPlans.reduce((prev, current) => prev.rentalTerm > current.rentalTerm ? prev : current);
    d = addDays(this.endDate, longestTermPlan.rentalTerm);
    return d;
  }
  shouldValidate() {

    if (this.bidResponse === NegoTermsResponse.acceptedByVendor) {
      return true;
    }
    if (this.bidResponse === NegoTermsResponse.counteredByVendor) {
      return true;
    }
    if (this.bidResponse === NegoTermsResponse.acceptedByCustomer) {
      return true;
    }
    if (this.bidResponse === NegoTermsResponse.counteredByCustomer) {
      return true;
    }
    return false;
  }
  /** returns  bid status based on @param  bidResponse*/
  getBidStatus(): BidStatus {
    switch (this.bidResponse) {
      case NegoTermsResponse.acceptedByVendor:
      case NegoTermsResponse.acceptedByCustomer:
        return BidStatus.accepted;
      case NegoTermsResponse.counteredByCustomer:
        return BidStatus.waitingForVendor;
      case NegoTermsResponse.counteredByVendor:
        return BidStatus.waitingForCustomer;
      case NegoTermsResponse.rejectedByVendor:
      case NegoTermsResponse.rejectedByCustomer:
        return BidStatus.rejected;
      default:
        throw new Error('[Nego-terms] invalid BidResponse');
    }
  }

  /** Validating terms to the bid response */
  validateTermsToBidResponse(bid?: BidBase): boolean {
    if (!bid) {
      return true;
    }
    const dbNegoTerms: NegoTermsBase = bid.bidNegoTerms;
    const b = this.compareTerms(dbNegoTerms);
    switch (this.bidResponse) {
      case NegoTermsResponse.acceptedByCustomer:
      case NegoTermsResponse.acceptedByVendor:
        if (!!dbNegoTerms && !b) {
          return false;
        }
        return true;
      case NegoTermsResponse.counteredByCustomer:
      case NegoTermsResponse.counteredByVendor:
        if (!!b) {
          return false;
        }
        return true;
      case NegoTermsResponse.rejectedByCustomer:
      case NegoTermsResponse.rejectedByVendor:
        if (this.startDate != null || this.endDate != null || this.deposit != null) {
          return false;
        }
        return true;
      default:
        throw new Error('[Nego-terms] invalid BidResponse');
    }
  }
  compareTerms(dbNegoTerms: NegoTermsBase): boolean {
    if (this.bidResponse === NegoTermsResponse.rejectedByVendor ||
      this.bidResponse === NegoTermsResponse.rejectedByCustomer) {
      return true;
    }
    if (this.startDate.valueOf() !== dbNegoTerms.startDate.valueOf()) {
      return false;
    }
    if (this.endDate.valueOf() !== dbNegoTerms.endDate.valueOf()) {
      return false;
    }
    if (this.deposit !== dbNegoTerms.deposit) {
      return false;
    }
    return true;

  }
  /**
   * 
   * @param em updated Terms
   * @returns @param t1: Term which can be modified; @param t2: Term which is additional 
   */
  getTermsForContractChange(em: NegoTermsBase, isContractStarted: boolean): { t1: NegoTermsBase, t2?: NegoTermsBase } {
    let t1: NegoTermsBase;
    let t2: NegoTermsBase;
    if (!isContractStarted) {
      t1 = em;
      return { t1 };
    } else {
      t1 = this.clone();
      t1.startDate = em.startDate;
      t2 = this.clone();
      t2.startDate = t1.endDate;
      t2.endDate = em.endDate;
      return { t1, t2 };
    }

  }
    /** @deprecated */ 
  validateInsCertDate(options: ValidatorOptions) {
    const r = this.validateSyncBase(options);

    if (!isDate(this.insCertStartDate)) {
      r['insCertStartDate'] = ['insurance start date should be date'];
    }

    if (!isDate(this.insCertEndDate)) {
      r['insCertEndDate'] = ['insurance end date should be date'];
    }

    if (isAfter(this.insCertStartDate, this.insCertEndDate)) {
      r['insCertStartDate'] = ['insurance start date should not greater than end date'];
    }

    return r;
  }
  initAddOns(service: ServiceType, taxJuris: string, isReplacementUnitCase? : boolean) {
    this.addOnsAvailable = [];
    if (!!FinanceInput?.fin) {
      const a = FinanceInput?.fin.specialFeeNN[service].jurisdiction[taxJuris];
      const k = Object.keys(FinanceInput.fin.specialFeeNN[service].jurisdiction[taxJuris]);
      const b = FinanceInput.fin.specialFeeNN[service].data;
      for (const ele of k) {
        const isOptional: boolean = FinanceInput.fin.specialFeeNN[service].data[ele][a[ele]].isOptional;
        if (isOptional && !ele.includes('Comb')) {
          //MKN - Replacement unit contract case de-select all addOns by default
          this.addOnsAvailable.push({ addOn: ele as SpecialItemTypeOptionalDb, isSelected: (isReplacementUnitCase ? false : true) }); 

        }
      }
      logger.info(`[nego-terms-base] addOns`, this.addOnsAvailable);
    }
  }
  // setAddOns(service: ServiceType, taxJuris: string, addOns?: SpecialItemTypeOptionalDb[]){
  //   this.initAddOns(service, taxJuris);
  //   addOns = !!addOns ? addOns : this.addOns;
  //   if(addOns?.length>0){
  //     for (const ele of this.addOns) {
  //       this.addOnsAvailable.find(e => e.addOn === ele).isSelected = true;
  //     }
  //   }
  // }
  getRateString(a: SpecialItemTypeOptionalDb, service: ServiceType, taxJuris: string) {
    if (!!FinanceInput?.fin) {
      const addOns = FinanceInput?.fin.specialFeeNN[service].jurisdiction[taxJuris];
      let args: number | IPercentArg | IDurationArg;
      let c: SpecialItemTypeOptionalCombDb;
      if (this.addOns.includes(a)) {
        args = FinanceInput.fin.specialFeeNN[service].data[a][addOns[a]].customer.args;
      } else {
        switch (a) {
          case 'gapInsurance':
            c = 'gapInsuranceComb';
            break;
          case 'walkAwayInsurance':
            c = 'walkAwayInsuranceComb';
            break;
          case 'waiverInsurance':
            c = 'waiverInsuranceComb';
            break;

          default:
            break;
        }
        args = FinanceInput.fin.specialFeeNN[service].data[c][addOns[c]].customer.args;

      }
      // MKN - Remove Insurance word -  PGDT -105
      a = <SpecialItemTypeOptionalDb> a.replace('Insurance','');
      return `${toSentence(a)} $${args}/day`;
    }
  }
  canDefaultTaxApply(cAddress: Address, vAddress: Address) {
    if (vAddress.country === 'US') {
      return false;
    }
    if (cAddress.country === 'US') {
      return false;
    }
    return true;
  }
}
