/** Purpose is to prevent circular redirect issues. */
export class BaseGuard {
  /** keep a track on how many times a redirect as called for a given url */
  counter: { [key: string]: number} = {};

  resetCounter(url) {
    setInterval(() => this.counter[url] = 0, 1000);
  }

  allowRedirect(url) {
    return (this.counter[url] == 0 || this.counter[url] == null );
  }
}