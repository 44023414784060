import { UtilityService } from './../utility.service';
import { Injectable } from '@angular/core';
import { isNil } from 'lodash';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { Store } from '@ngxs/store';
import { IUserAuthClaim } from '@trent/models/user/user-claim';
import { isAdminC, isBillingC, isBranchMgrC, isCountryMgrC, isCreditC, isEditorC, isEnterpriseMgrC, isMarketingC, isPurchasingC, isRegionMgrC, isSalesC, isSalesOperationsC, isServiceC, isYardPersonC } from '@trent/models/user/role';
import { logger } from '@trentm/log/logger';
import { sendEmailVerification, User, UserCredential } from '@angular/fire/auth';
import { Auth, authState, idToken } from '@angular/fire/auth';

@Injectable({ providedIn: 'root' })
export class AuthCoreService {

  private isLoggedinBS: BehaviorSubject<boolean>;
  public isLoggedin$: Observable<boolean>;
  public isLoggedin() { return this.isLoggedinBS.getValue(); }

  private isAdminBS: BehaviorSubject<boolean>;
  public isAdmin$: Observable<boolean>;
  public isAdmin() { return this.isAdminBS.getValue(); }


  private displayNameBS: BehaviorSubject<string>;
  public displayName$: Observable<string>;

  private isEditorBS: BehaviorSubject<boolean>;
  public isEditor$: Observable<boolean>;
  public isEditor() { return this.isEditorBS.getValue(); }

  private isCreditBS: BehaviorSubject<boolean>;
  public isCredit$: Observable<boolean>;
  public isCredit() { return this.isCreditBS.getValue(); }

  private isSalesBS: BehaviorSubject<boolean>;
  public isSales$: Observable<boolean>;
  public isSales() { return this.isSalesBS.getValue(); }

  private isServiceBS: BehaviorSubject<boolean>;
  public isService$: Observable<boolean>;
  public isService() { return this.isServiceBS.getValue(); }

  private isPurchasingBS: BehaviorSubject<boolean>;
  public isPurchasing$: Observable<boolean>;
  public isPurchasing() { return this.isPurchasingBS.getValue(); }

  private isBillingBS: BehaviorSubject<boolean>;
  public isBilling$: Observable<boolean>;
  public isBilling() { return this.isBillingBS.getValue(); }

  private isMarketingBS: BehaviorSubject<boolean>;
  public isMarketing$: Observable<boolean>;
  public isMarketing() { return this.isMarketingBS.getValue(); }

  private isSalesOperationsBS: BehaviorSubject<boolean>;
  public isSalesOperations$: Observable<boolean>;
  public isSalesOperations() { return this.isSalesOperationsBS.getValue(); }

  private isBranchMgrBS: BehaviorSubject<boolean>;
  public isBranchMgr$: Observable<boolean>;
  public isBranchMgr() { return this.isBranchMgrBS.getValue(); }

  private isRegionMgrBS: BehaviorSubject<boolean>;
  public isRegionMgr$: Observable<boolean>;
  public isRegionMgr() { return this.isRegionMgrBS.getValue(); }

  private isCountryMgrBS: BehaviorSubject<boolean>;
  public isCountryMgr$: Observable<boolean>;
  public isCountryMgr() { return this.isCountryMgrBS.getValue(); }

  private isEnterpriseMgrBS: BehaviorSubject<boolean>;
  public isEnterpriseMgr$: Observable<boolean>;
  public isEnterpriseMgr() { return this.isEnterpriseMgrBS.getValue(); }

  private isYardPersonBS: BehaviorSubject<boolean>;
  public isYardPerson$: Observable<boolean>;
  public isYardPerson() { return this.isYardPersonBS.getValue(); }

  get userId() {
    return (!!this.user) ? this.user.uid : null;
  }

  /** Auth Token Observable */
  get token$() { return idToken(this.auth); }
  token: string;

  user$: Observable<User>;
  user: User;

  authClaim: IUserAuthClaim = null;

  // data updated by other services to ensure singleton creation
  fcmToken: string;
  messaging: any; // do not declare to save initial load



  // Lazy Loaded References to ensure only singleton services are used
  // Because these services were lazy loaded, but their need is singleton.
  singletonContainer = {};


  constructor(public store: Store, public auth: Auth, public us: UtilityService) {
    logger.log('Auth-Core Service constructor called');

    this.setupObservables();

    // set up user, only needed at client.
    if (this.us.isPlatformBrowser) {

      this.user$.subscribe(u => {
        const islogged = u != null;
        logger.log('[auth-core] User changed. Is Logged in? : ', islogged);

        if (islogged !== this.isLoggedinBS.getValue()) {
          this.isLoggedinBS.next(islogged);
        }
        this.user = u;
        const newName = (u == null) ? '?' : u.displayName;
        if (newName !== this.displayNameBS.getValue()) {
          this.displayNameBS.next(newName);
        }
        logger.log('[auth-core] User changed. New User Name: ', newName);
      });

      this.token$.subscribe(t => this.token = t);

      // On updated Token Result.
      this.auth.onIdTokenChanged(async u => {
         if(!isNil(u)) {
          logger.info('[Auth-Core] user id token changed, onIdTokenChanged Call');
          const tokenResult = await u?.getIdTokenResult();
          console.log('IdToken', tokenResult?.authTime);
          this.authClaim = tokenResult.claims as any;
          if(!isNil((this.authClaim as any).cClaim)) {
            this.authClaim = (this.authClaim as any).cClaim as any;
          }           
          if (isAdminC(this.authClaim) !== this.isAdminBS.getValue()) {
            this.isAdminBS.next(!this.isAdminBS.getValue());
          }
          if ((isEditorC(this.authClaim) || isAdminC(this.authClaim)) !== this.isEditorBS.getValue()) {
            this.isEditorBS.next(!this.isEditorBS.getValue());
          }
          if ((isCreditC(this.authClaim) || isAdminC(this.authClaim)) !== this.isCreditBS.getValue()) {
            this.isCreditBS.next(!this.isCreditBS.getValue());
          }
          if ((isSalesC(this.authClaim) || isAdminC(this.authClaim)) !== this.isSalesBS.getValue()) {
            this.isSalesBS.next(!this.isSalesBS.getValue());
          }
          if ((isServiceC(this.authClaim) || isAdminC(this.authClaim)) !== this.isServiceBS.getValue()) {
            this.isServiceBS.next(!this.isServiceBS.getValue());
          }
          if ((isPurchasingC(this.authClaim) || isAdminC(this.authClaim)) !== this.isPurchasingBS.getValue()) {
            this.isPurchasingBS.next(!this.isPurchasingBS.getValue());
          }
          if ((isBillingC(this.authClaim) || isAdminC(this.authClaim)) !== this.isBillingBS.getValue()) {
            this.isBillingBS.next(!this.isBillingBS.getValue());
          }
          if ((isMarketingC(this.authClaim) || isAdminC(this.authClaim)) !== this.isMarketingBS.getValue()) {
            this.isMarketingBS.next(!this.isMarketingBS.getValue());
          }
          if ((isSalesOperationsC(this.authClaim) || isAdminC(this.authClaim)) !== this.isSalesOperationsBS.getValue()) {
            this.isSalesOperationsBS.next(!this.isSalesOperationsBS.getValue());
          }
          if ((isBranchMgrC(this.authClaim) || isAdminC(this.authClaim)) !== this.isBranchMgrBS.getValue()) {
            this.isBranchMgrBS.next(!this.isBranchMgrBS.getValue());
          }
          if ((isRegionMgrC(this.authClaim) || isAdminC(this.authClaim)) !== this.isRegionMgrBS.getValue()) {
            this.isRegionMgrBS.next(!this.isRegionMgrBS.getValue());
          }
          if ((isCountryMgrC(this.authClaim) || isAdminC(this.authClaim)) !== this.isCountryMgrBS.getValue()) {
            this.isCountryMgrBS.next(!this.isCountryMgrBS.getValue());
          }
          if ((isEnterpriseMgrC(this.authClaim) || isAdminC(this.authClaim)) !== this.isEnterpriseMgrBS.getValue()) {
            this.isEnterpriseMgrBS.next(!this.isEnterpriseMgrBS.getValue());
          }
          if ((isYardPersonC(this.authClaim) || isAdminC(this.authClaim)) !== this.isYardPersonBS.getValue()) {
            this.isYardPersonBS.next(!this.isYardPersonBS.getValue());
          }
         }

        //  OLD compat
        //  let newAuthClaim: IUserAuthClaim = null;
        // const exists = r != null && r.claims != null && r.claims.cClaim != null;
        // if (exists) {
        //   newAuthClaim = r.claims.cClaim;
        // }
        // this.authClaim = newAuthClaim;

        // // Only fire if anythingchanged
        // if (isAdminC(this.authClaim) !== this.isAdminBS.getValue()) {
        //   this.isAdminBS.next(!this.isAdminBS.getValue());
        // }
        // if (isEditorC(this.authClaim) !== this.isEditorBS.getValue()) {
        //   this.isEditorBS.next(!this.isEditorBS.getValue());
        // }
        
      });
    }
  }

  private setupObservables() {
    /** User for now is only defined on browser and not at server.  */
    // this.user$ = (this.us.isPlatformBrowser) ?  this.afAuth.authState : of(null);
    this.user$ = (this.us.isPlatformBrowser) ? authState(this.auth) : of(null);

    this.isLoggedinBS = new BehaviorSubject<boolean>(false);
    this.isLoggedin$ = this.isLoggedinBS.asObservable();

    this.isAdminBS = new BehaviorSubject<boolean>(false);
    this.isAdmin$ = this.isAdminBS.asObservable();

    this.displayNameBS = new BehaviorSubject<string>('?');
    this.displayName$ = this.displayNameBS.asObservable();

    this.isEditorBS = new BehaviorSubject<boolean>(false);
    this.isEditor$ = this.isEditorBS.asObservable();

    this.isCreditBS = new BehaviorSubject<boolean>(false);
    this.isCredit$ = this.isCreditBS.asObservable();

    this.isSalesBS = new BehaviorSubject<boolean>(false);
    this.isSales$ = this.isSalesBS.asObservable();

    this.isServiceBS = new BehaviorSubject<boolean>(false);
    this.isService$ = this.isServiceBS.asObservable();

    this.isPurchasingBS = new BehaviorSubject<boolean>(false);
    this.isPurchasing$ = this.isPurchasingBS.asObservable();
    
    this.isBillingBS = new BehaviorSubject<boolean>(false);
    this.isBilling$ = this.isBillingBS.asObservable();

    this.isMarketingBS = new BehaviorSubject<boolean>(false);
    this.isMarketing$ = this.isMarketingBS.asObservable();

    this.isSalesOperationsBS = new BehaviorSubject<boolean>(false);
    this.isSalesOperations$ = this.isSalesOperationsBS.asObservable();

    this.isBranchMgrBS = new BehaviorSubject<boolean>(false);
    this.isBranchMgr$ = this.isBranchMgrBS.asObservable();

    this.isRegionMgrBS = new BehaviorSubject<boolean>(false);
    this.isRegionMgr$ = this.isRegionMgrBS.asObservable();

    this.isCountryMgrBS = new BehaviorSubject<boolean>(false);
    this.isCountryMgr$ = this.isCountryMgrBS.asObservable();

    this.isEnterpriseMgrBS = new BehaviorSubject<boolean>(false);
    this.isEnterpriseMgr$ = this.isEnterpriseMgrBS.asObservable();

    this.isYardPersonBS = new BehaviorSubject<boolean>(false);
    this.isYardPerson$ = this.isYardPersonBS.asObservable();
  }

  async logout() {
    await this.auth.signOut(); //
  }

  async sendVerificationEmail(uc?: UserCredential) {
    const u = (!!uc && !!uc.user) ? uc.user : this.user;
    if (!!u) {
      sendEmailVerification(u)
        .then(() => logger.log('email verification mail sent'))
        .catch((err) => logger.log('Email verification failed, error: ', err));
    }
  }

  /** Debug only. */
  deleteUser() {
    if (!!this.user) {
      this.user.delete();
    }
  }

}
