import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { appRunningMode } from '@trent/models/sys/app-info';
import { ITripGpsStatus } from '@trent/models/trip/trip-gps-status';

/** contract abstract class to be provided in the module providers.
 * At run time, it will be replaced by corrosponding cordova or web version.
 */
@Injectable()
export abstract class IgeoLocationService {

  /**
   * If the application is running in the forground or background.
   */
  appMode$: Observable<appRunningMode>;

  location$: Observable<GeolocationPosition>;

  /** Is the current app / browser support geo location */
  isGeoLocSupported: boolean;

  constructor() { }

  getCurrentPosition: () => Promise<any>;

  startBroadcastLocation: (gpsStatus?: ITripGpsStatus) => Promise<void>;

  stopBroadcastingLocation: () => Promise<void>;

  setupBroadCasting: (gpsStatus?: ITripGpsStatus) => void;

  logConfigData: (config: object, platform: 'ios' | 'android') => Promise<void>;

  /** Is device is currently broadcasting the gps data. */
  isGpsBroadCasting: () => Promise<boolean>;

  /** Database flag that is true when GPS tracking is rquired currently ( trip is active etc.) */
  isGpsBroadCastingReqd: boolean;
}
