import { DbStatus } from '../base/db-status';
import { CollectionReference, Query, query, QueryConstraint, where, orderBy, startAfter, limit, endBefore, limitToLast } from 'firebase/firestore';
import { IKeyVal, Paging, getDirection } from '../observable-util/paging';
import { logger } from '../log/logger';
import { LegacyContractBase } from './legacy-contract-base';
import { PartOfMultipleUnits, Status1 } from './legacy-contract-enum';
import { IAlgoliaQuery } from '@trent/services/algolia-search.service';
import { buildFilterString, Filter, Operator } from '../utility/filter-builder';

export interface ILegacyContractParam {
  orderDirection?: 'desc' | 'asc';
  orderBy?: string;
  dbStatus?: DbStatus;
  uid?: string;
  leaseNo?: string | number;
  sNo?: number;//KN - added for getting legacy contracts based on sNo
  isObsolete?: boolean;//KN - added for getting legacy contracts based on isObsolete 
  vin?: string;
  masterContractId?: string;//MKN - added for getting data based on master contract id
  isIncomplete? :boolean;
  applicant?: string; //MKN - added for search filter
  status1? : Status1;//MKN - legacy contract field
  firstName?: string; //MKN - added for search filter
  lastName?: string; //MKN - added for search filter
  partOfMultipleUnits?: PartOfMultipleUnits; //MKN - added for search filter
  model?: string;
  make?: string;
  modelYear?: number;
  isPaginationRequired?: boolean;
  isSearchApplied?: boolean;
}

export const legacyContractParamInit = (): ILegacyContractParam => {
  return {
    orderBy: 'createdAt desc',
    isPaginationRequired: true,
    isSearchApplied: false,
  };
};

export const companyParamInit = (): ILegacyContractParam => {
  return {
    orderDirection: 'desc',
    orderBy: 'createdAt desc',
  };
};

export const legacyContractSearchOrderBy: IKeyVal[] = [

  { key: 'createdAt to Date', value: `createdAt desc` },
];

/** Server filtering of firebase query building of query  */
export const legacyContractSearchServerQuery = (ref: CollectionReference, param: ILegacyContractParam, p: Paging) => {
  let q: Query = <any>ref;
  const cons: QueryConstraint[] = [];

  if (!!param.leaseNo) {
    cons.push(where('leaseNo', '==', param.leaseNo));
  }

  if (!!param.sNo) {
    cons.push(where('sNo', '==', param.sNo));
  }

  if (!!param.isObsolete) {
    cons.push(where('isObsolete', '==', param.isObsolete));
  }
  
  if (!!param.masterContractId) {
    cons.push(where('masterContractId', '==', param.masterContractId));
  }

  if (!!param.applicant) {
    cons.push(where('customerCompSummary.applicant', '==', param.applicant));
  }

  if (!!param.firstName) {
    cons.push(where('customerCompSummary.firstName', '==', param.firstName));
  }

  if (!!param.lastName) {
    cons.push(where('customerCompSummary.lastName', '==', param.lastName));
  }

  if (!!param.partOfMultipleUnits) {
    cons.push(where('leaseSummary.partOfMultipleUnits', '==', param.partOfMultipleUnits));
  }

  if(param.isIncomplete != undefined){
    cons.push(where('isIncomplete', '==', param.isIncomplete));
  }

  if (!!param.status1) {
    cons.push(where('status1', '==', param.status1));
  }

  if (!!param.modelYear) {
    cons.push(where('lcProduct.modelYear', '==', param.modelYear));
  }

  if (!!param.make) {
    cons.push(where('lcProduct.make', '==', param.make));
  }

  if (!!param.model) {
    cons.push(where('lcProduct.model', '==', param.model));
  }

  if (!!param.vin) {
    cons.push(where('lcProduct.vin', '==', param.vin));
  }

  if(!!param.orderBy){
    const { col, dir } = getDirection(legacyContractSearchOrderBy, (!!param.orderBy) ? param.orderBy : 'createdAt desc');
    cons.push(orderBy(col, dir));

    if (p.nextClicked && p.startAt) {
      cons.push(startAfter(p.startAt));
      cons.push(limit(p.size));
    } else if (p.prevClicked && p.endBefore) {
      cons.push(endBefore(p.endBefore));
      cons.push(limitToLast(p.size));
    }else{
      cons.push(limit(p.size));
    }
  }

  // logger.log(cons, 'cons???????', ref);
  q = query(ref, ...cons);
  return q;
};

/** Client Filtering of the data */
export const legacyContractSearchClientFilter = (p: LegacyContractBase[], o: ILegacyContractParam): LegacyContractBase[] => {

  // filter by dbStatus, for new Legacy Contracts the dbStatus will be set as draft
  if (!!o.dbStatus) {
    p = p.filter((val) => val.dbStatus === +o.dbStatus);
  }
  // only used as client filter
  if (o.leaseNo) {
    p = p.filter((val) => val.leaseNo === o.leaseNo);
  }

  if (o.sNo) {
    p = p.filter((val) => val.sNo === o.sNo);
  }

  if (o.isObsolete) {
    p = p.filter((val) => val.isObsolete === o.isObsolete);
  }

  if (!!o.masterContractId) {
    p = p.filter((val) => val.masterContractId === o.masterContractId);
  }

  if (!!o.applicant) {
    p = p.filter((val) => val.customerCompSummary?.applicant === o.applicant);
  }

  if (!!o.firstName) {
    p = p.filter((val) => val.customerCompSummary?.firstName === o.firstName);
  }

  if (!!o.lastName) {
    p = p.filter((val) => val.customerCompSummary?.lastName === o.lastName);
  }

  if (!!o.partOfMultipleUnits) {
    p = p.filter((val) => val.leaseSummary?.partOfMultipleUnits === o.partOfMultipleUnits);
  }

  if(o.isIncomplete != undefined){
    p = p.filter((val) => val.isIncomplete === o.isIncomplete);
  }

  if (!!o.status1) {
    p = p.filter((val) => val.status1 === o.status1);
  }

  if (!!o.modelYear) {
    p = p.filter((val) => val.lcProduct?.modelYear === o.modelYear);
  }
  if (!!o.model) {
    p = p.filter((val) => val.lcProduct?.model === o.model);
  }
  if (!!o.make) {
    p = p.filter((val) => val.lcProduct?.make === o.make);
  }
  if (!!o.vin) {
    p = p.filter((val) => val.lcProduct?.vin === o.vin);
  }

  return p as LegacyContractBase[];
};

/** Server filtering of firebase query building of query  */
export const legacyContractSearchServerQueryWithCount = (ref: CollectionReference, o: ILegacyContractParam) => {

  const cons: QueryConstraint[] = [];

  if (!!o.leaseNo) {
    cons.push(where('leaseNo', '==', o.leaseNo));
  }

  if (!!o.sNo) {
    cons.push(where('sNo', '==', o.sNo));
  }

  if (!!o.isObsolete) {
    cons.push(where('isObsolete', '==', o.isObsolete));
  }

  if (!!o.masterContractId) {
    cons.push(where('masterContractId', '==', o.masterContractId));
  }

  if (!!o.applicant) {
    cons.push(where('customerCompSummary.applicant', '==', o.applicant));
  }

  if (!!o.firstName) {
    cons.push(where('customerCompSummary.firstName', '==', o.firstName));
  }

  if (!!o.lastName) {
    cons.push(where('customerCompSummary.lastName', '==', o.lastName));
  }

  if (!!o.partOfMultipleUnits) {
    cons.push(where('leaseSummary.partOfMultipleUnits', '==', o.partOfMultipleUnits));
  }

  if(o.isIncomplete != undefined){
    cons.push(where('isIncomplete', '==', o.isIncomplete));
  }

  if (!!o.status1) {
    cons.push(where('status1', '==', o.status1));
  }

  if (!!o.modelYear) {
    cons.push(where('lcProduct.modelYear', '==', o.modelYear));
  }

  if (!!o.make) {
    cons.push(where('lcProduct.make', '==', o.make));
  }

  if (!!o.model) {
    cons.push(where('lcProduct.model', '==', o.model));
  }

  if (!!o.vin) {
    cons.push(where('lcProduct.vin', '==', o.vin));
  }

  if(!!o.orderBy){
    const { col, dir } = getDirection(legacyContractSearchOrderBy, (!!o.orderBy) ? o.orderBy : 'createdAt desc');
    cons.push(orderBy(col, dir));
  }

  return query(ref, ...cons);
};

/** Search legacy-contract using algolia search API  */
export const legacyContractSearchAlgolia = (o: ILegacyContractParam, p: Paging): { query: IAlgoliaQuery, indexName: string} => {
	const cons: Filter[] = [];
	const query: IAlgoliaQuery = {
	  query: '',
    restrictSearchableAttributes: [],
	  hitsPerPage: p.size as number,
	  page: p.offset as number
	};
	let indexName = 'legacy-contract-base';

  if (!!o.leaseNo) {
    query.query +=o.leaseNo;
    query.restrictSearchableAttributes.push('leaseNo');
  }

  if (!!o.applicant) {
    query.query +=o.applicant;
    query.restrictSearchableAttributes.push('customerCompSummary.applicant');
  }

  if (!!o.firstName) {
    query.query +=o.firstName;
    query.restrictSearchableAttributes.push('customerCompSummary.firstName');
  }

  if (!!o.lastName) {
    query.query +=o.lastName;
    query.restrictSearchableAttributes.push('customerCompSummary.lastName');
  }

  if (!!o.partOfMultipleUnits) {
    cons.push({ attributeName: 'leaseSummary.partOfMultipleUnits', operator: Operator.COLON, value: o.partOfMultipleUnits});
  }

  if(o.isIncomplete != undefined){
    cons.push({ attributeName: 'isIncomplete', operator: Operator.COLON, value: o.isIncomplete});
  }

  if (!!o.modelYear) {
    cons.push({ attributeName: 'lcProduct.modelYear', operator: Operator.EQUAL, value: o.modelYear});
  }

  if (!!o.status1) {
    cons.push({ attributeName: 'status1', operator: Operator.COLON, value: o.status1});
  }

  if (!!o.make && o.make != 'Other') {
      cons.push({ attributeName: 'lcProduct.make', operator: Operator.COLON, value: o.make});
  }

  if (!!o.model) {
    cons.push({ attributeName: 'lcProduct.model', operator: Operator.COLON, value: o.model});
  }

  if (!!o.vin) {
    query.query +=o.vin;
    query.restrictSearchableAttributes.push('lcProduct.vin');
    query.restrictSearchableAttributes.push('lcProduct.vinLastSix');
  }

  if (o.orderBy == 'sNo desc') {
		indexName = 'legacy-contract-base-sNo-desc';
	}
	if (o.orderBy == 'sNo asc') {
		indexName = 'legacy-contract-base-sNo-asc';
	}

	query.filters = buildFilterString(cons);
	return { query, indexName };
  };

export const getILegacyContracts = (o: ILegacyContractParam): { [key: string]: ILegacyContractParam } => {

  return undefined;
};
