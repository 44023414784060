import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { RentalProductBase } from '@trent/models/rental/rental-product-base';
import { RentalTrailer } from '@trent/models/rental/rental-trailer';
import { Picture } from '@trent/models/inspection/picture';
import { MileageType } from '@trent/models/rental/milage-type';
import { logger } from '../../../../../../libs/models/log/logger';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ProductType } from '@trent/models/product';
import { UtilityService } from '@trent/services/utility.service';

@Component({
  selector: 'trent-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {

  mileageTypeEnum = MileageType;
  pTypeEnum = ProductType;
  // @Input()
  _rental: RentalProductBase;
  @Input()
  set rental(v: RentalProductBase) {
    if (!!v) {
      this._rental = v;
      // this.addPics();
    }
  }
  get rental() { return this._rental; }
  @Input()
  showMiniCard: boolean;

  @Output() emitShowOnMapById = new EventEmitter<string | number>();

  @Input()
  showReplaceActions: boolean;

  @Output()
  selectEmitter = new EventEmitter<string>();

  @Input()
  horizontalCarousel = false;
  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer, public us: UtilityService) {
    this.matIconRegistry.addSvgIcon('truckIcon', this.domSanitizer.bypassSecurityTrustResourceUrl(this.us.getFullUrl('assets/truck-semi.svg')));
    this.matIconRegistry.addSvgIcon('trailerIcon', this.domSanitizer.bypassSecurityTrustResourceUrl(this.us.getFullUrl('assets/dry_van.svg')));
  }
  ngOnInit() {
    logger.info('nothing to init');
  }
  tl(a) {
    return a as RentalTrailer;
  }
  addPics() {
    const pic1 = new Picture();
    pic1.path = 'gs://lloop-debug.appspot.com/temp/legoTruck.jpg';
    pic1.url = 'https://firebasestorage.googleapis.com/v0/b/lloop-debug.appspot.com/o/temp%2FlegoTrailer.jpg?alt=media&token=026dd803-1cfb-4643-9121-194b95314418';
    const pic2 = new Picture();
    pic2.path = 'gs://lloop-debug.appspot.com/temp/legoTrailer.jpg';
    pic2.url = 'https://firebasestorage.googleapis.com/v0/b/lloop-debug.appspot.com/o/temp%2FlegoTruck.jpg?alt=media&token=b644fd47-8894-4a23-b5ca-d2c3c835d5db';
    const pic3 = new Picture();
    pic3.path = 'gs://lloop-debug.appspot.com/temp/bg-long2-3-mid.jpg';
    pic3.url = 'https://firebasestorage.googleapis.com/v0/b/lloop-debug.appspot.com/o/temp%2Fbg-long2-3-mid.jpg?alt=media&token=fb13631e-9964-4c40-a4c8-d4ba5a95e3c7';
    (<RentalTrailer>this.rental).pictures = [pic1, pic2, pic3, pic1, pic2, pic3];
  }
  showLocation() {
    this.emitShowOnMapById.emit(this.rental.id);
  }
}
