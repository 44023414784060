import { Component, OnInit } from '@angular/core';
import { BasePage } from '@trent/models/UI/base.page';
import { Store } from '@ngxs/store';
import { DialogService } from '@trent/services/dialog/dialog.service';
import { EventService } from '@trent/services/event.service';
import { UtilityService } from '@trent/services/utility.service';
import { logger } from '@trent/models/log/logger';

@Component({
  selector: 'trent-app-link',
  templateUrl: './app-link.component.html',
  styleUrls: ['./app-link.component.scss']
})
export class AppLinkComponent extends BasePage<any> implements OnInit {

  constructor(store: Store,
    dialogService: DialogService,
    eventService: EventService,

  ) {
    super(store, dialogService, eventService);

  }

  ngOnInit(): void {
    this.title = 'Downlaod App';
    logger.log('AppLinkComponent');
    
  }

}
