import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { BaseHttpService } from './base-http.service';
import { FirestoreService } from './firestore.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { InvoicePay } from '@trent/models/finance/invoice/invoice-pay';
import { InvoiceRec } from '@trent/models/finance/invoice/invoice-rec';
import { TransactionType } from '@trent/models/finance/invoice/invoice-type';
import { Invoice } from '@trent/models/finance/invoice/invoice';
import { Paging } from '@trent/models/observable-util/paging';
import { IInvoiceParam, invoiceSerachServerQuery } from '@trent/models/finance/invoice/invoice-param';
import { PagingObesrvable } from '@trent/models/observable-util/paging-obesrvable';
import { Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { InvoiceItem } from '@trent/models/finance/invoice/invoice-item';
import { Order } from '@trent/models/finance/order/order';
import { instanceToPlain } from 'class-transformer';

@Injectable()
export class InvoiceService extends BaseHttpService {
  constructor(store: Store, private db: FirestoreService, private http: HttpClient) {
    super(store);
    this.apiName = 'api';
  }

  public addInvoiceItem(d: {invoiceItem: InvoiceItem, cInvId: string | number, vInvId: string | number}) {
    // Prepare the post data
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.token });
    return this.http.post<{ invoiceId: string | number, invoice: Invoice, order: Order }>(this.getApiUrl('/invoice/add-invoice-item'),
      // Server validation before creating the entry.
      {
        invoiceItem: instanceToPlain(d.invoiceItem),
        cInvId: d.cInvId,
        vInvId: d.vInvId
      },
      { headers: headers })
      .pipe(
        tap(r => console.log('response: ', r))
      );
  }
  public generatePeriodInvoice(d: {cOrderId: string | number, vOrderId: string | number, dueDate: Date}) {
    // Prepare the post data
    const headers = new HttpHeaders({ 
      'Authorization': 'Bearer ' + this.token
    });
    return this.http.post<{ url: string, path: string }>(this.getApiUrl('/invoice/generate-period-invoice'),
      // Server validation before creating the entry.
      {
        dueDate: instanceToPlain(d.dueDate),
        cOrderId: d.cOrderId,
        vOrderId: d.vOrderId,
      },
      { 
        headers: headers,
        // responseType: 'blob' as 'json'  // use this to request a blow download.
      })
      .pipe(
        tap(r => console.log('response: ', r))
      );
  }
  public getSecureUrl(dueDate: Date, orderId: string | number) {
    // Prepare the post data
    const headers = this.addBearerToken();
    return this.http.post<{ url: string }>(this.getApiUrl('/invoice/get-secure-url'),
      { 
        dueDate: instanceToPlain(dueDate),
        orderId
      
      },
      // tslint:disable-next-line:object-literal-shorthand
      { headers: headers })
      .pipe(tap(r => console.log('response: ', r)));
  }

  public getInvoiceById(id: string | number) {
    return this.db.docWithInjectedId$<Invoice>(
      `${Invoice.collectionName}/${id}`, id);
  }
// #region Paging Load

public getInvoices_PagingObservable() {
  const p: PagingObesrvable<Invoice, IInvoiceParam> =
    new PagingObesrvable<Invoice, IInvoiceParam>(this.db, this.getInvoices_getBatch);
  return p;
}

// public getOrders(p: Paging, param: IOrderParam) {
//   return this.orders.getData(p, param);
// }

/**
 * Get the next batch of the data from server.
 * @param p paging information.
 */
private getInvoices_getBatch(p: Paging, param: IInvoiceParam): Observable<{ [key: string]: Invoice }> {
  if (param.orderBy == null) { param.orderBy = 'updatedAt'; }
  if (param.orderDirection == null) { param.orderDirection = 'desc'; }

  return this.db
    .colWithIdsInjectedNew$<Invoice>(Invoice.collectionName, ref => invoiceSerachServerQuery(ref, param, p))
    .pipe(
      tap(arr => {
        if (arr == null || arr.length === 0) {
          console.log('All data is recevied, Paging is FULL');
          p.full = true;
        } else {
          // HG TODO: Fix the usage of lastDoc in the server query functin insdie order-param.
          p.lastDoc = arr[arr.length - 1];
        }
      }),
      map(arr => {
        // console.log('From Server, before mapping is applied: ', arr);

        // convert array to { [id: string] = CompanyFleet} object.
        const arrayAsObj = arr.reduce((acc, cur) => {
          const id = cur.id;
          const data = cur;
          return { ...acc, [id]: data };
        }, {});

        // console.log('after converting to array object dic ', arrayAsObj);
        return arrayAsObj;
      })
    );
}

// #endregion
}
