import { ProductBase, ProductSummaryBase } from './product-base';
import { ProductType } from './product-type';
// import { Driver, DriverSummary, ContractDriverSummary } from './driver';
import { Trailer, TrailerSummary, ContractTrailerSummary } from './trailer';
import { Truck, TruckSummary, ContractTruckSummary } from './truck';
import { RentalTrailer } from '../rental/rental-trailer';
import { RentalTruck } from '../rental/rental-truck';
import { Vehicle } from './vehicle';
import { SalesTrailer, SalesTruck } from '../sales-option';
import { MapLabel } from '../map/map-label';

export type ProductSummary = ProductSummaryBase | TruckSummary | TrailerSummary;
export type Product = Truck | Trailer;
export type ContractProductSummary = ContractTruckSummary | ContractTrailerSummary;

export const parseProductArray = (obj: any[]): ProductBase[] => {
  const s = !!obj ? obj.map(o => <ProductBase>parseProduct(o)) : null;
  return s;
};

export const parseProduct = (obj: any): ProductBase => {
  if (obj == null) { return null; }
  const p = <ProductBase>obj; // cast it to base, remember it is still a javascript obj at run time.

  switch (p.productType) {
    // case ProductType.driver:
    //   return Driver.parse(p);

    case ProductType.trailer:
      return Trailer.parse(p);

    case ProductType.truck:
      return Truck.parse(p);

    // case ProductType.promoTruck:
    // case ProductType.promoTrailer:
    // case ProductType.promoDriver:
    // case ProductType.promoFreightCustomer:
    //   return PromoProduct.parse(p);

    default:
      throw new Error(`Invalid Product type was provided. Type: ${p.productType} is invalid.`);
  }
};
/**
 * Check is if new pictures are added to the rentoption when compared product. Return true for added new pic(s).
 * @param oProd Original Product from bd
 * @param uRental new or updated Rental Trailer or Truck
 */
export const checkNewPicturesAdded = (oProd: Vehicle, uRental: RentalTrailer | RentalTruck): boolean => {
  for (const p of uRental.pictures) {
    const index = oProd.pictures.findIndex(f => f.path !== p.path);
    if (index > -1) {
      return true;
    }
  }
  return false;
};
/**
 * Copy picture from rental vehcile to procduct. Calleing fucntion should verify if new pictures are added.
 * @param oProd orginal Vehicle
 * @param uRental new or updated Rental Trailer or Truck
 */
export const addPictures = (oProd: Vehicle, uRental: RentalTrailer | RentalTruck) => {
  const uProd = (<Trailer>oProd).clone();
  // add picture for trucks and trailers only
  switch (oProd.productType) {

    case ProductType.trailer:
    case ProductType.truck:
      uProd.pictures = !!uProd.pictures ? uProd.pictures : [];
      for (const p of uRental.pictures) {
        const index = !!oProd.pictures ? oProd.pictures.findIndex(f => f.path === p.path) : -1;
        if (index === -1) {
          uProd.pictures.push(p);
        }
      }
      break;
    default:
      throw new Error(`Invalid Product type was provided. Type: ${oProd.productType} is invalid.`);
  }
  return uProd;
};
export const getProductString = (pType: ProductType) => {
  switch (pType) {
    case ProductType.truck:
      return 'Truck';
    case ProductType.trailer:
      return 'Trailer';

    default:
      throw new Error(`Product Type ${pType} is not implemented`);
  }
};
/** reset the state if files are not uploaded */
export const resetDoc = (em: Vehicle, m: Vehicle) => {
  switch (em.productType) {
    case ProductType.truck:
      em.vehicleReg = m.vehicleReg;
      em.safety = m.safety;
      (em as Truck).cabCard = (m as Truck).cabCard;
      // (em as Truck).truckFile = (m as Truck).truckFile;
      break;
    case ProductType.trailer:
      em.vehicleReg = m.vehicleReg;
      em.safety = m.safety;
      break;
    default:
      throw new Error(`Product Type ${em.productType} is not implemented`);
  }
};

/**
 * @author KS
 * @purpose Generate map labels list
 * @param list 
 * @returns 
 */
export const getMapLabelsAggList = (list): MapLabel[] => {
  const labels: MapLabel[] = [];
  const type = 'Product';

  for (const key in list) {
    if (Object.prototype.hasOwnProperty.call(list, key)) {
      const e = list[key];
      let actions: string;
      actions = '<a id="IdAction">Details</a>';
      labels.push({
        iPoint: {
          latitude: Math.round(e?.physicalLocationSummary?.address.geoLoc.geopoint.latitude * 1000000) / 1000000,
          longitude: Math.round(e?.physicalLocationSummary?.address.geoLoc.geopoint.longitude * 1000000) / 1000000
        },
        geohash: e.physicalLocationSummary?.address.geoLoc.geohash,
        title: `${e.unitName}`,
        id: e.id,
        desc: `${actions}`,
        clickLabel: [{ btnLabel: 'Details', emitterAction: 'mapClusterAction' }],
        actions: ['ClusterAction', 'IdAction']
      });
    }
  }
  return labels;
};

// export const parseProduct = <T extends IPoint >(obj: any, ipointType?: { new(...args: any[]): T }): ProductBase => {
//   if (obj == null) { return null; }
//   const p = <ProductBase>obj; // cast it to base, remember it is still a javascript obj at run time.

//   switch (p.productType) {
//     case ProductType.driver:
//       return Driver.parse(p, ipointType);

//     case ProductType.trailer:
//       return Trailer.parse(p, ipointType);

//     case ProductType.truck:
//       return Truck.parse(p, ipointType);

//     default:
//       throw new Error(`Invalid Product type was provided. Type: ${p.productType} is invalid.`);
//   }
// };


// export const getProductSummary = (product: Product): ProductSummary => {
//   let productSummary: ProductSummary = null;

//   switch (product.productType) {
//     case ProductType.truck:
//       product = <Truck>product;
//       productSummary = Truck.getTruckSummary(product);
//       console.log('productSummary', productSummary);
//       break;
//     case ProductType.trailer:
//       product = <Trailer>product;
//       productSummary = Trailer.getTrailerSummary(product);
//       console.log('productSummary', productSummary);
//       break;
//     case ProductType.driver:
//       product = <Driver>product;
//       productSummary = Driver.getDriverSummary(product);
//       console.log('productSummary', productSummary);
//       break;
//     default:
//       productSummary = null;
//   }
//   return productSummary;
