<ng-container>
	<h4 *ngIf="hideShow" class="mb-8 footer_header">HOURS OF OPERATION</h4>
	<form #form="ngForm" [ngClass]="{'f': hideShow}">
		<mat-form-field class="full-width footer_time" color="secondary">
			<mat-select name="store" #store="ngModel" [(ngModel)]="selectedStore"
				(selectionChange)="setStore(selectedStore); groupAndDisplayDays()">
				<mat-option *ngFor="let store of stores" [value]="store">
					<span *ngIf="!!store.name">{{store.name}}</span>
					<span *ngIf="!store.name">{{store.address.city}}</span>
				</mat-option>
			</mat-select>
		</mat-form-field>
	</form>
	<div class="table-container">
	<table class="timing-table mat-body footer_time" [ngClass]="{'bg-gray w-100': !hideShow}">
		<tr *ngFor="let t of timings">
			<th>
				<span class="text-capitalize">
					<strong>{{getDays(t.days)}}</strong>
				</span>
			</th>
			<td>
				<ng-container *ngIf="t?.timing?.start && t?.timing?.end; else noTiming">
					{{t?.timing?.start}} - {{t?.timing?.end}}
				</ng-container>
				<ng-template #noTiming>
					{{t.timing}}
				</ng-template>
			</td>
		</tr>
	</table>

	</div>
</ng-container>