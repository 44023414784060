import { Paging } from '../observable-util/paging';
import { PromoListBase } from './promo-list-base';
import { PromoListType } from './promo-list-type';
import { logger } from '../log/logger';
import { CollectionReference, limit, orderBy, query, Query, QueryConstraint, startAfter, where } from 'firebase/firestore';
import { VendorPortfolio } from './vendor-portfolio';

/** Search params for fetching the list by user settings. */
export interface IPromoListParam {
  cid?: number | string;
  orderDirection?: 'desc' | 'asc';
  orderBy?: string;
  type?: PromoListType;
  isMasterList?: boolean;
  companyWithAccess?: string;
}
/** Server filtering of firebase query building of query  */
// tslint:disable-next-line:max-line-length
export const promoListSerachServerQuery = (ref: CollectionReference, param: IPromoListParam, p: Paging) => {
  const cons: QueryConstraint[] = [];
  // cons.push(where('cid', '==', param.cid)
  //   .orderBy(param.orderBy, param.orderDirection);
  if (!!param.type) {
    cons.push(where('listType', '==', param.type));
  }
  if (!!param.cid) {
    cons.push(where('cid', '==', param.cid));
  }
  if (!!param.isMasterList) {
    cons.push(where('isMasterList', '==', param.isMasterList));
  }
  if (!!param.companyWithAccess) {
    cons.push(where('companyWithAccess', 'array-contains', param.companyWithAccess));
  }
  if (!!p.lastDoc) {
    logger.log('Start After Query: ', p.lastDoc[param.orderBy]);
    cons.push(startAfter(p.lastDoc[param.orderBy]));
  }
  cons.push(limit(p.size));
  return query(ref, ...cons);
};


/** Client side filtering of the orders. */
export const promoListSerachClientFilter = (p: PromoListBase[], o: IPromoListParam) => {

  if (!!o.cid) {
    p = p.filter(val => val.cid === o.cid);
  }
  if (!!o.type) {
    p = p.filter(val => val.listType === o.type);
  }
  if (!!o.isMasterList) {
    p = p.filter(val => (val as VendorPortfolio).isMasterList === o.isMasterList);
  }
  if (!!o.companyWithAccess) {
    p = p.filter(val => (val as VendorPortfolio).companiesWithAccess.includes(o.companyWithAccess));
  }

  return p;
};

/** Get the OR condition options. if there are more then one company id's are provided
 *  each cid makes up a unique OR condition query.
 * TBD function, see Pick-Drop imlementation */
export const getIPromoListsOptionOrChildren = (o: IPromoListParam): { [key: string]: IPromoListParam } => {
  // if (!!o.cids && o.cids.length > 1) {
  //   const r: { [key: string]: PickDropParam } = {};
  //   o.cids.forEach((cid) => {
  //     const c = DeepCopy.copy(o);
  //     c.cids = [cid];
  //     r[getObjKey(c)] = c;
  //   });
  //   return r;
  // }

  return undefined;
};
