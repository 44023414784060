import { ProductType } from '../product/product-type';
import { InspectionStatus } from './inspection-status';
import { Inspection } from './inspection';
import { DeepCopy } from '../utility/deep-copy';
import { getObjKey, Paging, getDirection, IKeyVal } from '../observable-util/paging';
import { DbRule } from '../base/db-rule';
import { UserEnum } from '../user/user-enum';
import { CollectionReference, limit, orderBy, query, Query, QueryConstraint, startAfter, where } from 'firebase/firestore';

export interface IInspectionParam {
  type?: ProductType;
  /** Parent Companies to be searched */
  cids?: string[];

  status?: InspectionStatus;

  orderBy?: string;

  queryBy?: UserEnum;

  pdIdForHistory?: string;

  maxRev?: number;

  updatedAt?: Date;

  delegateAction?: { dBPath: string, ph: string };

  bidId?: string | number;
}
export const pickDropSearchOrderBy: IKeyVal[] = [

  { key: 'updatedAt to Date', value: `updatedAt desc` },
];
export const inspectionParamInit = (): IInspectionParam => {
  return {
    type: <any>'*',
    status: <any>'*',
    cids: [],
    orderBy: 'updatedAt',
    // bidStatus: <any>'*'
  };
};
/** Get the OR condition options. if there are more then one company id's are provided
 *  each cid makes up a unique OR condition query. */
export const getInspectionOptOrChildren = (o: IInspectionParam): { [key: string]: IInspectionParam } => {
  if (!!o.cids && o.cids.length > 1) {
    const r: { [key: string]: IInspectionParam } = {};
    o.cids.forEach((cid) => {
      const c = DeepCopy.copy(o);
      c.cids = [cid];
      r[getObjKey(c)] = c;
    });
    return r;
  }

  return undefined;
};
/** Server filtering of firebase query building of query  */
export const inspectionSerachServerQuery = (ref: CollectionReference, o: IInspectionParam, p: Paging) => {
  const cons: QueryConstraint[] = [];
  if (o.cids?.length > 0) {
    console.log('o.cids[0]', o.cids[0]);
    console.log('o.queryBy', o.queryBy);
    if (o.queryBy === UserEnum.vendor) {
      cons.push(where('vendorCompanySummary.cid', '==', o.cids[0]));
    } else if (o.queryBy === UserEnum.customer) {
      cons.push(where('customerCompanySummary.cid', '==', o.cids[0]));
    }
  }
  if (o.status != null && o.status !== <any>'*') {
    cons.push(where('status', '==', o.status));
  }
  if (!!o.type && o.type !== <any>'*') {
    cons.push(where('productSummary.productType', '==', +o.type));
  }
  if (!!o.maxRev) {
    cons.push(where('revId', '<', o.maxRev));  // open bids BidStatus.waitingForVendor = 1 waitingForCustomer = 2 BidStatus.accepted = 3,
  }
  if (!!o.updatedAt) {
    // tslint:disable-next-line:max-line-length
    cons.push(where('updatedAt', '>', o.updatedAt));  // open bids BidStatus.waitingForVendor = 1 waitingForCustomer = 2 BidStatus.accepted = 3,
  }
  if (!!o.bidId) {
    cons.push(where('bidSummary.bidId', '==', o.bidId));
  }
  if (!!o.delegateAction?.dBPath && !!o.delegateAction?.ph) {
    cons.push(where(`${o.delegateAction?.dBPath}`, '==', o.delegateAction.ph));
  }
  if (o.orderBy === 'revId desc') {
    const { col, dir } = getDirection(pickDropSearchOrderBy, (!!o.orderBy) ? o.orderBy : 'revId desc');
    console.log('col', col);
    console.log('dir', dir);
    cons.push(orderBy(col, dir));
    if (!!p.lastDoc) {
      console.log('last doc: ', p.lastDoc[col]);
      cons.push(startAfter(p.lastDoc[col]));
    }
  }
  cons.push(limit(p.size));
  return query(ref, ...cons);
};
/** Client Filtering of the data */
export const inspectionSerachClientFilter = (p: Inspection[], o: IInspectionParam) => {

  // client set to PikcDrop expired status, not yet cleaned by cronJob
  console.log('pick-drop before reset expiry', p);

  // p.map(e => {
  //   e.implementExpiredPD();
  // });
  console.log('pick-drop after reset expiry', p);

  if (o.cids?.length > 0) {
    if (o.queryBy === UserEnum.vendor) {
      p = p.filter((val, idx) => val.vendorCompanySummary.cid === o.cids[0]);
    } else if (o.queryBy === UserEnum.customer) {
      p = p.filter((val, idx) => val.customerCompanySummary.cid === o.cids[0]);
    }
  }
  if (!!o.pdIdForHistory) {
    p = p.filter((val, idx) => DbRule.getRootId(val.id) === o.pdIdForHistory);
  }
  if (o.status != null && <any>o.status !== '*') {
    p = p.filter((val, idx) => val.status === +o.status);
  }
  // filter by type.
  if (o.type != null && <any>o.type !== '*') {
    p = p.filter((val, idx) => val.productSummary.productType === +o.type);
  }
  if (!!o.bidId) {
    p = p.filter((val, idx) => val.bidSummary.bidId === o.bidId);
  }
  if (!!o.delegateAction?.dBPath && o.delegateAction?.dBPath === 'vendorDropDelegate.phone') {
    p = p.filter((val, idx) => val.vendorDropDelegate.phone === o.delegateAction.ph);
  }
  if (!!o.delegateAction?.dBPath && o.delegateAction?.dBPath === 'customerPickDelegate.phone') {
    p = p.filter((val, idx) => val.customerPickDelegate.phone === o.delegateAction.ph);
  }
  if (!!o.delegateAction?.dBPath && o.delegateAction?.dBPath === 'customerDropDelegate.phone') {
    p = p.filter((val, idx) => val.customerDropDelegate.phone === o.delegateAction.ph);
  }
  if (!!o.delegateAction?.dBPath && o.delegateAction?.dBPath === 'vendorPickDelegate.phone') {
    p = p.filter((val, idx) => val.vendorPickDelegate.phone === o.delegateAction.ph);
  }
  return p;
};
/**
 * Set up quary parameters for inspection deligate based aynonmus user with given phone number
 * @param action InspectionStatus
 * @param bidId bid Id which is same as inspection id
 */
export const setParmForDelegate = (action: InspectionStatus, ph: string): IInspectionParam => {
  const dBPath = getDbPath(action);
  if (!!dBPath) {
    const p: IInspectionParam = {
      delegateAction: { dBPath, ph }
    };
    return p;
  } else {
    return null;
  }
};
const getDbPath = (action: InspectionStatus): string => {
  switch (action) {
    case InspectionStatus.customerPicked:
      return 'customerPickDelegate.phone';
    case InspectionStatus.vendorDropped:
      return 'vendorDropDelegate.phone';
    case InspectionStatus.customerDropped:
      return 'customerDropDelegate.phone';
    case InspectionStatus.vendorPicked:
      return 'vendorPickDelegate.phone';
    default:
      throw new Error(`[inspection-param] Programing error cannot getUserAction, invalid action type ${action}`);

  }
};
