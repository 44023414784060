import { Equals, IsBoolean, isNumber, IsNumber, Length, Max, ValidateIf, validateSync, IsDefined, ValidateNested, IsDate } from 'class-validator';
import { Exclude, instanceToInstance, plainToInstance, Expose, Type } from 'class-transformer';
import { FileInfo } from '../media/file-info';
import { IValidationMsg } from '../error-handling';
import { sanitizeDate, sanitizeDateIPoint, toValidationError } from '../utility';
import { addYears, isAfter, lastDayOfMonth } from 'date-fns';
import { BidBase } from './bid-base';
import { BaseModel } from '../base';

export type bidDocumentG = 'bidDocumentG';
const bidDocumentG: bidDocumentG = 'bidDocumentG';


/**
 *     @purpose - Vendor can upload singed copy and insurance certificate at time of approving bid request 
   *            and contract edit case
 */
@Exclude()
export class BidDocuments extends BaseModel {

  public static readonly bidDocumentG = 'bidDocumentG';

  constructor() {
    super();
    this.signedDoc = new FileInfo();
    this.insCert = new FileInfo();
    if(this.isSupportingDocumentsIncluded) {
      this.supportingDocuments = new FileInfo();
    }
  }

  @Expose()
  @ValidateNested({ message: 'Upload signed document', groups: [bidDocumentG] })
  @Type(() => FileInfo)
  signedDoc: FileInfo;

  @Expose()
  @ValidateNested({ message: 'Upload Insurance certificate', groups: [bidDocumentG] })
  @Type(() => FileInfo)
  insCert: FileInfo;

  /** Deal support documents like personal guaranty; co-sign */
  @Expose()
  @ValidateIf(o => o.isSupportingDocumentsIncluded)
  @ValidateNested({ message: 'Upload Insurance certificate', groups: [bidDocumentG] })
  @Type(() => FileInfo)
  supportingDocuments: FileInfo;

  @Expose()
  @IsBoolean()
  isSupportingDocumentsIncluded = true;


  @Expose()
  @ValidateIf(o => o.insCert, { groups: [bidDocumentG] })
  @IsDate({ message: 'insurance start date is required', groups: [bidDocumentG] })
  insCertStartDate: Date;


  @Expose()
  @ValidateIf(o => o.insCert, { groups: [bidDocumentG] })
  @IsDate({ message: 'insurance end date is required', groups: [bidDocumentG] })
  insCertEndDate: Date;

  insCertStartDateMinDate(bid: BidBase) { 
    return addYears(bid?.bidNegoTerms?.startDate, -1);
  }

  insCertStartDateMaxDate(bid: BidBase) { 
    return lastDayOfMonth(addYears(bid?.bidNegoTerms?.startDate, 1));
  }


  get insCertEndDateMinDate() { 
    return this.insCertStartDate;
  }

  insCertEndDateMaxDate(bid: BidBase) { 
    return lastDayOfMonth(addYears(bid?.bidNegoTerms?.startDate, 1));
  }

  public static parse(obj) {


    if (obj instanceof (BidDocuments)) { return <BidDocuments>obj; }

    const m = plainToInstance<BidDocuments, any>(BidDocuments, sanitizeDateIPoint(obj));
    m.sanitize();
    if (m.insCert == null) { m.insCert = new FileInfo(); }
    if (m.signedDoc == null) { m.signedDoc = new FileInfo(); }
    if (m.supportingDocuments == null && !!m.isSupportingDocumentsIncluded) { m.supportingDocuments = new FileInfo(); }
    return m;
  }

  sanitize() {

    // super.sanitize();
    this.insCertStartDate = sanitizeDate(this.insCertStartDate);
    this.insCertEndDate = sanitizeDate(this.insCertEndDate);

  }
  clone() {
    const t = instanceToInstance(this);
    t.sanitize();
    return t;
  }

  validateSync(options?: any): IValidationMsg {

    const r = this.validateSyncBase(this, options);

    if(isAfter(this.insCertStartDate, this.insCertEndDate)){
      r['insCertStartDate'] = ['insurance start date should not greater than end date'];
    }

    return r;
  }
  setPath(path: string) {
    this.signedDoc.path = path;
    this.insCert.path = path;
    this.supportingDocuments.path = path;
  }
}