import { AuthState } from '../store/auth-store/auth.state';
import { ValidatorOptions } from 'class-validator';
import { Injectable } from '@angular/core';
import { FirestoreService } from '@trent/services/firestore.service';
import { map, tap } from 'rxjs/operators';
import { instanceToPlain } from 'class-transformer';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { BaseHttpService } from '@trent/services/base-http.service';
import { Store } from '@ngxs/store';
import { logger } from '@trent/models/log/logger';
import { StoreLocation } from '@trent/models/store-location/store-location';
import { Paging } from '@trent/models/observable-util/paging';
import { PagingObesrvable } from '@trent/models/observable-util/paging-obesrvable';
import { Observable } from 'rxjs';
import { StoreLocationParam, storeLocationSearchServerQuery } from '@trent/models/store-location/store-location-param';

// https://angularfirebase.com/lessons/firestore-advanced-usage-angularfire/

@Injectable()
export class StoreLocationService extends BaseHttpService {

  constructor(store: Store, private db: FirestoreService, private http: HttpClient) {
    super(store);
    this.apiName = 'api';
  }

  public create(s: StoreLocation, option: ValidatorOptions) {
    // Prepare the post data
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + this.token });

    // Server validation before creating the entry.
    return this.http.post<{ storeLocation: StoreLocation }>(this.getApiUrl('/store-location/create'),
      {
        store: instanceToPlain(s),
        option: option
      },
      { headers: headers })
      .pipe(
        tap(r => logger.log('response: ', r)),
      );
  }

  public update(sid: string | number, s: StoreLocation, option?: ValidatorOptions) {
    // Prepare the post data
    const headers = this.addBearerToken();
    // Server validation before creating the entry.
    return this.http.post<{ sid: string | number, storeLocation: StoreLocation }>(this.getApiUrl('/store-location/update'),
      {
        sid: sid,
        store: instanceToPlain(s),
        option: option
      },
      { headers: headers })
      .pipe(
        tap(r => logger.log('response: ', r)),
      );
  }

  getNewDocId() {
    return this.db.docId(StoreLocation.collectionName);
  }
  

  public getAllStoreLocations() {

    // Get store locations
    const c = this.store.selectSnapshot(AuthState.userClaim);
    logger.log('default store is: ', Object.keys(c.cClaim.cRoles));
    return this.db.colWithIdsInjected$<StoreLocation>(StoreLocation.collectionName);
  }

  gedStoreLocationById(id: string | number) {
    return this.db.docWithInjectedId$<StoreLocation>(
      `${StoreLocation.collectionName}/${id}`, id);
  }

  /**
   * get all companies (admin request)
   */
  public getAllStoreLocations_PagingObservable() {
    const p$: PagingObesrvable<StoreLocation, StoreLocationParam> =
      new PagingObesrvable<StoreLocation, StoreLocationParam>(this.db,
        (p: Paging, param?: StoreLocationParam) => this.getAllStoreLocations_batch(p, param));
    return p$;
  }

  /**
  * Get the next batch of the data from server.
  * @param p paging information.
  */
  private getAllStoreLocations_batch(p: Paging, param: StoreLocationParam): Observable<{ [key: string]: StoreLocation }> {
    if (param.orderBy == null) { param.orderBy = 'updatedAt'; }
    if (param.orderDirection == null) { param.orderDirection = 'desc'; }
    return this.db
      .colWithIdsInjectedNew$<StoreLocation>(`${StoreLocation.collectionName}`, ref => storeLocationSearchServerQuery(ref, param, p))
      .pipe(
        tap(arr => {
          if (arr == null || arr.length === 0) {
            logger.log('All data is recevied, Paging is FULL');
            p.full = true;
          } else {
            p.lastDoc = arr[arr.length - 1];
          }
        }),
        map(arr => {
          // logger.log('From Server, before mapping is applied: ', arr);

          const arrayAsObj = arr.reduce((acc, cur) => {
            const id = cur.id;
            const data = cur;
            return { ...acc, [id]: data };
          }, {});

          // logger.log('after converting to array object dic ', arrayAsObj);
          return arrayAsObj;
        })
      );
  }
}
