
/**
 * Mileage type
 * @param Minimum 1 
 * @param Maximum 2 , 
 * @param Flexible 3
 * @param Unlimited 100
 */
export enum MileageType {

  /** Ex. say 10K is minimum. it will added to the bill regardless of actual usage. Overflow will be charged as well. */
  minimum = 1,

  /** say 10K is maximum, it is free (already built in the rent price). Additional will be charged.  */
  maximum = 2,

  /** Every milage used will be charged */
  flexible = 3,

  /** No extra charge for the milage. */
  unlimited = 100
}
