import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
// tslint:disable-next-line:max-line-length
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { BaseHttpService } from './base-http.service';
import { FirestoreService } from './firestore.service';
import { instanceToPlain } from 'class-transformer';
import { ValidatorOptions } from 'class-validator';
import { TaskType } from '@trent/models/sys/task-type';
import { Task } from '@trent/models/sys/task';
import { ListItem, PromoListType, PromoListBase, IPromoListParam, promoListSerachServerQuery } from '@trent/models/promo';
import { NotificationMessagePayload } from '@trent/models/fcm/fcm-type';
import { PagingObesrvable } from '@trent/models/observable-util/paging-obesrvable';
import { Paging } from '@trent/models/observable-util/paging';
import { logger } from '@trentm/log/logger';
import { ProductType } from '@trent/models/product';

@Injectable()
export class PromoListService extends BaseHttpService {

  // orders: PagingObesrvable<Order, IOrderParam>;

  constructor(store: Store, private db: FirestoreService, private http: HttpClient) {
    super(store);
    this.apiName = 'api';
    // this.orders = new PagingObesrvable<Order, IOrderParam>(this.db, this.getOrders_getBatch);

  }
  // *** promo app functions ***
  createPromoList(cid: number | string, p: { [key: string]: ListItem }, listType: PromoListType, option?: ValidatorOptions) {
    // Prepare the post data
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + this.token });
    return this.http.post<{ id: string | number, promoList: PromoListBase, key: string }>(this.getApiUrl('/promoList/promoListCreate'),
      // Server validation before creating the entry.
      {
        listPayload: instanceToPlain(p),
        // tslint:disable-next-line:object-literal-shorthand
        cid: cid,
        // tslint:disable-next-line:object-literal-shorthand
        listType: listType,
        // tslint:disable-next-line:object-literal-shorthand
        option: option
      },
      // tslint:disable-next-line:object-literal-shorthand
      { headers: headers })
      .pipe(
        tap(r => logger.log('response: ', r))
      );
  }
  /** update Promo List
   * @param lid: promo list ID
   * @param cid: company id
   * @param p: new/updated entry
   * @param option: validation group
   * @param editEntry: true when one of the list entry is beign updated
   */
  updatePromoList(lid: string | number, cid: number | string, p: { [key: string]: ListItem }, listType: PromoListType,
    option?: ValidatorOptions, editEntry?: boolean) {
    // Prepare the post data
    const headers = this.addBearerToken();
    // Server validation before creating the entry.
    // tslint:disable-next-line:max-line-length
    return this.http.post<{ id: string | number, promoList: PromoListBase, key: string }>(this.getApiUrl('/promoList/promoListUpdate'),
      {
        listPayload: instanceToPlain(p),
        // tslint:disable-next-line:object-literal-shorthand
        lid: lid,
        // tslint:disable-next-line:object-literal-shorthand
        cid: cid,
        // tslint:disable-next-line:object-literal-shorthand
        listType: listType,

        option: option,
        // tslint:disable-next-line:object-literal-shorthand
        editEntry: editEntry
      },
      // tslint:disable-next-line:object-literal-shorthand
      { headers: headers })
      .pipe(
        tap(r => logger.log('response: ', r))
      );
  }
  changeAccess(lid: string | number, cid: string, access: boolean) {
    const headers = this.addBearerToken();
    return this.http.post<{ id: string | number, data: PromoListBase }>(this.getApiUrl('/promoList/change-access'),
    {
      lid: lid,
      // tslint:disable-next-line:object-literal-shorthand
      cid: cid,
      // tslint:disable-next-line:object-literal-shorthand
      access: access,
    },
    // tslint:disable-next-line:object-literal-shorthand
    { headers: headers })
    .pipe(
      tap(r => logger.log('response: ', r))
    );
  }
  /** send message one or more users
   * @param cid: sender's company id
   * @param listId: DriverList id
   * @param key: dirverPhoneOrId
   * @param taskType: TaskType
   * @param customPayload: optional notification payload
   */
  sendMessageByPhoneNumber(cid: string | number, listId: string | number, key: string, taskType?: TaskType,
    customPayload?: NotificationMessagePayload) {

    // Prepare the post data
    const headers = this.addBearerToken();
    // Server validation before creating the entry.
    return this.http.post<{ smsRef: Date, taskRef: string }>(this.getApiUrl('/promoList/send-message-by-phone-number'),
      {
        // tslint:disable-next-line:object-literal-shorthand
        cid: cid,
        // tslint:disable-next-line:object-literal-shorthand
        listId: listId,
        // tslint:disable-next-line:object-literal-shorthand
        key: key,
        // tslint:disable-next-line:object-literal-shorthand
        taskType: taskType,
        // tslint:disable-next-line:object-literal-shorthand
        customPayload: customPayload,
      },
      // tslint:disable-next-line:object-literal-shorthand
      { headers: headers })
      .pipe(
        tap(r => logger.log('response: ', r))
      );
  }
  /**
   * 
   * @param key trailer number
   * @param trackingNo  Pickup Tracking Number
   * @param cid carrier company id
   */
  removePendingDelivery(key: string, tnos: string[], cid: string | number) {
    const headers = this.addBearerToken();
    // Server validation before creating the entry.
    return this.http.post<{ id: string, key: string, tL: PromoListBase, tnos: string[] }>(this.getApiUrl('/promoList/empty-trailer'),
      {
        cid,
        key,
        tnos
      },
      // tslint:disable-next-line:object-literal-shorthand
      { headers: headers })
      .pipe(
        tap(r => logger.log('response: ', r))
      );
  }

  public getPromoListById(id: string | number) {
    return this.db.docWithInjectedId$<PromoListBase>(`${PromoListBase.collectionName}/${id}`);
  }

  public getOrders_PagingObservable() {
    const p: PagingObesrvable<PromoListBase, IPromoListParam> =
      new PagingObesrvable<PromoListBase, IPromoListParam>(this.db, this.getPromoLists_getBatch);
    return p;
  }

  /**
   * Get the next batch of the data from server.
   * @param p paging information.
   */
  private getPromoLists_getBatch(p: Paging, param: IPromoListParam): Observable<{ [key: string]: PromoListBase }> {
    if (param.orderBy == null) { param.orderBy = 'updatedAt'; }
    if (param.orderDirection == null) { param.orderDirection = 'desc'; }
    return this.db
      .colWithIdsInjectedNew$<PromoListBase>(`${PromoListBase.collectionName}`, ref => promoListSerachServerQuery(ref, param, p))
      .pipe(
        tap(arr => {
          if (arr == null || arr.length === 0) {
            logger.log('All data is recevied, Paging is FULL');
            p.full = true;
          } else {
            // HG TODO: Fix the usage of lastDoc in the server query functin insdie order-param.
            p.lastDoc = arr[arr.length - 1];
          }
        }),
        map(arr => {
          // logger.log('From Server, before mapping is applied: ', arr);

          // convert array to { [id: string] = CompanyFleet} object.
          const arrayAsObj = arr.reduce((acc, cur) => {
            const id = cur.id;
            const data = cur;
            return { ...acc, [id]: data };
          }, {});

          // logger.log('after converting to array object dic ', arrayAsObj);
          return arrayAsObj;
        })
      );
  }
  // createBorderList() {
  //   const b = borderListData();
  //   this.db.set(`${PromoListBase.collectionName}/border-list`, b);
  // }

  /**
	 * @author KS
	 * Update Rental Aggregate List
	 * @param payload 
	 * @returns 
	 */
	public updateRentalAggregate(payload: { deletedIds: string[]; insertedIds: string[]; rentalProdType: PromoListType | ProductType; cid?: string | number }) {
		const headers = this.addBearerToken();
		// Server validation before creating the entry.
		return this.http.post(this.getApiUrl('/promoList/update-rental-agg'), payload,
			{ headers: headers })
			.pipe(
				tap(res => console.log('response: ', res))
			);
	}

	/**
	 * @author KS
	 * Update Product Aggregate List
	 * @param payload 
	 * @returns 
	 */
	public updateProductAggregate(payload: { deletedIds: string[]; insertedIds: string[]; rentalProdType: PromoListType | ProductType; cid?: string | number }) {
		const headers = this.addBearerToken();
		// Server validation before creating the entry.
		return this.http.post(this.getApiUrl('/promoList/update-product-agg'), payload,
			{ headers: headers })
			.pipe(
				tap(res => console.log('response: ', res))
			);
	}

	/**
	 * @author KS
	 * Update Company Aggregate List
	 * @param payload
	 * @returns 
	 */
	public updateCompanyAggregate(payload: { deletedIds: string[]; insertedIds: string[]; rentalProdType: PromoListType | ProductType; cid?: string | number }) {
		const headers = this.addBearerToken();
		// Server validation before creating the entry.
		return this.http.post(this.getApiUrl('/promoList/update-company-agg'), payload,
			{ headers: headers })
			.pipe(
				tap(res => console.log('response: ', res))
			);
	}

  /**
	 * TEST RENTAL
	 * @returns 
	 */
	public test() {
		const headers = this.addBearerToken();
		// Server validation before creating the entry.
		return this.http.post(this.getApiUrl('/promoList/create-agg-test'), {}, { headers: headers })
			.pipe(
				tap(res => console.log('response: ', res))
			);
	}

	/**
	 * TEST COMPANY
	 * @returns 
	 */
	public testCompany() {
		const headers = this.addBearerToken();
		// Server validation before creating the entry.
		return this.http.post(this.getApiUrl('/promoList/create-agg-test-company'), {}, { headers: headers })
			.pipe(
				tap(res => console.log('response: ', res))
			);
	}
}
