import { Exclude, Expose, instanceToInstance, plainToInstance } from 'class-transformer';
import { IsArray, IsDefined, IsEnum, ValidateNested, ValidatorOptions } from 'class-validator';
import { BaseModel } from '../base';
import { CustomerCompanySummary, VendorCompanySummary } from '../company';
import { ProductSummary } from '../product';
import { AddOnBase } from '../addon/add-on-base';
import { IValDbStatusResult, IValidationMsg } from '../error-handling';

export interface BusinessUserSummary {
	id: string | number;
	displayName: string;
	email: string;
}

export enum PriceQuoteStatus {
	Submitted = 1,
	Approved = 2,
	Rejected = 3,
	Cancelled = 4
}

export type PriceQuoteValidationGroup = 'general';
const general: PriceQuoteValidationGroup = 'general';

@Exclude()
export class PtsPriceQuote extends BaseModel {

	public static readonly collectionName = 'pts-price-quote';

	@Expose()
	@IsDefined({ message: 'Price is required', groups: [general] })
	priceQuoted: number;

	@Expose()
	discount: number;

	@Expose()
	businessUserSummary: BusinessUserSummary;

	@Expose()
	vendorCompSummary: VendorCompanySummary;

	@Expose()
	@IsDefined({ message: 'Customer company is required', groups: [general] })
	customerCompSummary: CustomerCompanySummary;

	@Expose()
	productSummary: ProductSummary;

	@Expose()
	@IsArray()
	@ValidateNested({ each: true })
	addOns: AddOnBase[];

	@Expose()
	@IsEnum(PriceQuoteStatus)
	status: PriceQuoteStatus;

	constructor() {
		super();
		this.addOns = [];
	}

	sanitize() {
		super.sanitize();
	}

	clone() {
		const t = instanceToInstance(this);
		t.sanitize();
		return t;
	}

	validateSync(options: ValidatorOptions) {
		const r = this.validateSyncBase(this);
		return r;
	}

	/**
   * Validate group wise form on UI
   * @param group 
   * @returns 
   */
	validateSyncGroup(group: PriceQuoteValidationGroup): IValidationMsg {
		return this.validateSync({ groups: [group] });
	}

	/**
	 * manage left panel validation on UI
	 * @returns 
	 */
	getValDbStatus(): IValDbStatusResult {
		const result: IValDbStatusResult = {
			pass: false,
			message: undefined,
			groupResult: {}
		};

		let x: { groupPass: boolean; message: string; };

		// general
		let r = this.validateSyncGroup(general);
		x = { groupPass: null, message: '' };
		x.groupPass = Object.keys(r).length === 0;
		x.message = (x.groupPass) ? 'General Information Details' : 'General information is required';
		result.groupResult[general] = x;
		return result;
	}

	updatePriceQuote(s: PtsPriceQuote) {
		this.priceQuoted = s.priceQuoted;
		this.discount = s.discount;
		this.businessUserSummary = s.businessUserSummary;
		this.vendorCompSummary = s.vendorCompSummary;
		this.customerCompSummary = s.customerCompSummary;
		this.productSummary = s.productSummary;
		this.addOns = s.addOns;
		this.status = s.status;
	}

	public static parse(obj) {
		if (obj == null) { return null; }
		const m = plainToInstance<PtsPriceQuote, any>(PtsPriceQuote, obj);
		m.sanitize();
		return m;
	}


	public static parsePriceQuoteArray = (obj: any[]): PtsPriceQuote[] => {
		const s = !!obj ? obj.map(o => <PtsPriceQuote>PtsPriceQuote.parse(o)) : null;
		return s;
	};
}