import { BaseModel } from '../base';
import { DbStatus } from '../base/db-status';
import { validateStr } from '../utility/validation-helper';
import { cleanupUndefined } from '../utility/helper';

/** Update Db crucial properties before creating a record in the db. */
export function addSysPropBeforeCreateClientS<T extends BaseModel>(d: T, uid: string, releaseOnSubmission: boolean = true) {
  let m = <T>d.toFirebaseObj();
  if (!!releaseOnSubmission) {
    m.dbStatus = DbStatus.Released;
    m.revId = 1;
  } else {
    m.dbStatus = DbStatus.Initial;
    m.revId = 0;
  }
  m.draftId = validateStr(m.draftId, 32);
  m.cid = validateStr(m.cid, 32);
  m.uid = validateStr(m.uid, 32);
  m = cleanupUndefined(m);
  m.createdByUid = uid;
  m.updatedByUid = m.createdByUid;
  return m;
}
/** Update Db crucial properties before creating a record in the db. */
export function addSysPropBeforeUpdateClientS<T extends BaseModel>(origM: T, d: T, uid: string, skipReleaseData: boolean = false) {
  //   TODO: HG custom function defined which over writes the base class fn toFirebaseObj needs to be refactored
  if (!skipReleaseData) {
    d.incrementRev();
  }

  let m = <T>d.toFirebaseObj(true);
  m.createdAt = origM.createdAt;
  m.createdByUid = origM.createdByUid;

  // if (!skipReleaseData) {
  //   m.dbStatus = origM.dbStatus;
  //   m.revId = origM.revId;
  //   m.draftId = origM.draftId;
  // }

  m.cid = validateStr(origM.cid, 32);
  m.uid = validateStr(origM.uid, 32);
  m = cleanupUndefined(m);

  m.updatedByUid = uid;

  return m;


}