import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsModule } from '@ngxs/store';
import { NetSuiteProductSyncLogsState } from './netsuite-product-sync-logs.state';
import { NetSuiteProductSyncLogsService } from '@trent/services/netsuite-product-sync-logs.service';


@NgModule({
    imports: [
      CommonModule,
      NgxsModule.forFeature([NetSuiteProductSyncLogsState])
    ],
    providers: [
      NetSuiteProductSyncLogsService,
    ]
  })
  export class NetSuiteProductSyncLogsStoreModule { }
