import { keysIn } from 'lodash';
import { Length, validateSync, ValidatorOptions } from 'class-validator';
import { Exclude, Expose,plainToInstance } from 'class-transformer';
import { CompanyValidationGroup } from '../company/company-base';
import { logger } from '../log/logger';
import { sanitizeDate, sanitizeDateIPoint, sanitizeFloat, toValidationError } from '../utility';
import { IValidationMsg } from '../error-handling/validation-info';

export interface IFileMoveData {
  /** Old Name */
  oldPath: string;

  /** New Name */
  newPath: string;

  /** Parent @interface FileInfo */
  file?: FileInfo;
}

export interface IFileCopyData {
  /** Old Name */
  oldPath: string;

  /** New Name */
  newPath: string;

  /** Parent @interface FileInfo */
  file?: FileInfo;

  customMetadata? : Object
}

const documents: CompanyValidationGroup = 'documents';

@Exclude()
export class FileInfo {

  /** Displayed name to the end user. this is not the name of the file stored at cloud storage. */
  @Expose()
  @Length(3, 96, { message: 'File name to be $constraint1 - $constraint2 chars long', groups: [documents] })
  name: string;


  @Expose()
  @Length(3, 512, { message: 'Path to be $constraint1 - $constraint2 chars long', groups: [documents] })
  path: string;


  @Expose()
  /// @Length(0, 512, { message: 'url Name must be $constraint1 - $constraint2 chars long', groups: [documents] })
  url?: string;

  localUrl: string;

  @Expose()
  uploadedOn: Date;

  /** Only a non-secure  file will need to save the url in the db. 
   * This prop is not saved in db. url */
  isSecureUrl = false;

  file: File;

  customMetadata: any;

  /**
   * Get the extension of the file string.
   * @param f input file
   */
  public static getFileExt(f: string) {
    const x = f.split('.').pop();
    return (x === f) ? '' : x;
  }

  public static getFNameSkipExt(f: string) {
    const ext = FileInfo.getFileExt(f);
    if (ext === '') {
      return f;
    } else {
      return f.split(`.${ext}`)[0];
    }
  }

  /** Security settings for the files in the class */
  public static updateFileSecurity(obj, flag: boolean) {
    if (obj instanceof FileInfo) {
      const f = <FileInfo>obj;
      // logger.log('FileInd flag updated for : ', f.name);
      f.isSecureUrl = flag;
      return;
    }
    if (Array.isArray(obj)) {
      for (let idx = 0; idx < obj.length; idx++) {
        FileInfo.updateFileSecurity(obj[idx], flag);
      }
    }
    if (typeof obj === 'object' && !(obj instanceof Date)) {
      keysIn(obj).forEach(k => {
        // logger.log('tyring file info update on ', ele);
        FileInfo.updateFileSecurity(obj[k], flag);
      });
    }
  }

  /**
   * change:  'filename  v20.pdf' to 'filename  v21.pdf'
   * @param f file name to be nex rev.
   */
  public static getNextRev(f: string) {
    // logger.log('Orig: ' + myString);
    const myRegexp = /[vV](\d+)\.{1}(.*)/gm;
    if (myRegexp.test(f)) {
      logger.log('found match');
      const x = f.replace(myRegexp, (match, $1, $2) => {
        return 'v' + (+$1 + 1) + '.' + $2;
      });
      // logger.log(x);
      return x;
    } else {
      const ext = FileInfo.getFileExt(f);
      if (ext === '') {
        return `${f} v1`;
      } else {
        return `${FileInfo.getFNameSkipExt(f)} v1.${ext}`;
      }
    }
  }

  // public static updatePathTempToRelease(f: FileInfo): IFileMoveData {
  //   return FileInfo.replaceFilePathup(f, 'temp', 'release');
  // }
  public static updatePathToDraft(f: FileInfo): IFileMoveData {
    return FileInfo.replaceFilePathup(f, 'temp', 'draft');
  }
  public static updatePathToRelease(f: FileInfo): IFileMoveData {
    let r = FileInfo.replaceFilePathup(f, 'temp', 'release');
    if (r == null) {
      r = FileInfo.replaceFilePathup(f, 'draft', 'release');
    }
    return r;
  }

  static replaceFilePathup(f: FileInfo, sTxt: string, dTxt: string): IFileMoveData {
    if (!!f && !!f.path && f.path.trim().length > 0) {
      const r: IFileMoveData = { oldPath: f.path, newPath: null, file: f };
      f.path = f.path.replace(`/${sTxt}/`, `/${dTxt}/`);
      r.newPath = f.path;
      return (r.newPath === r.oldPath) ? null : r;
    }
    return null;
  }

  static replaceFilePathCopyOp(f: string, sTxt: string, dTxt: string): IFileCopyData {
    if (!!f && f.trim().length > 0) {
      const r: IFileCopyData = { oldPath: f, newPath: null };
      f = f.replace(`/${sTxt}/`, `/${dTxt}/`);
      r.newPath = f;
      return (r.newPath === r.oldPath) ? null : r;
    }
    return null;
  }

  removeFile() {
    this.name = '';
    this.path = '';
    this.uploadedOn = null;
  }

    /**
   * @author - MKN
   * @purpose - sanitize date
   */
  
  sanitize() {
    this.uploadedOn = sanitizeDate(this.uploadedOn);
  }
  
  /**
   * @author - MKN
   * @purpose - parse fileInfo object
   */
  
  public static parse(obj) {
    try {
      if (obj == null) { return null; }
      if (obj instanceof FileInfo) { return obj; }
      const m = plainToInstance<FileInfo, any>(FileInfo, obj);
      m.sanitize();
      return m;
    } catch (error) {
      logger.error('Error happened during parse', error);
      return null;
    }
  }

    /**
   * @author - MKN
   * @purpose - validate Object
   */
  
  public validateSyncBase($this: any, options?: ValidatorOptions): IValidationMsg {
    const r = validateSync($this, options);
    const m = toValidationError(r);
    return m;
  }
  
    /**
   * @author - MKN
   * @purpose - validate data before saving in fire store
   */
  
  validateSync(options: ValidatorOptions, data : FileInfo): IValidationMsg {
    const r = this.validateSyncBase(this, options);
    if (!data.name) {
      r['missingDocument'] = ['Document is not uploaded. Please try again'];
    }
    if (!data.path) {
      r['storageFileMissing'] = ['Document is not uploaded on storage. Please try again'];
    }
    return r;
  }

}
