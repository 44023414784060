import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngxs/store';
import { StoreLocation } from '@trent/models/store-location/store-location';

@Component({
	selector: 'trent-footer-visit-store',
	templateUrl: './footer-visit-store.component.html',
	styleUrls: ['../footer-styles.scss']
})
export class FooterVisitStoreComponent {
	@Input() selectedStore: StoreLocation;
	@Output() map: EventEmitter<boolean> = new EventEmitter<boolean>();

	/**
	 * Class Constructor
	 * @param store Store
	 * @param dialog MatDialog
	 */
	constructor(
		public store: Store,
	) { }

	getDirections(store: StoreLocation) {
		if(!store) return;
		const url = `http://maps.google.com?q=${store.address.lat},${store.address.long}`;
		window.open(url, '_blank');
	}
}