import { Picture } from '../inspection/picture';
import { logger } from '../log/logger';

// ** Compares array of two picture arrays based on the url and returns add and or deleted array(s) of pictures  */
export function comparePicsBasedOnUrl(a: Picture[], b: Picture[]): { pAdded: Picture[], pDeleted: Picture[] } {
  const aa: string[] = [];
  const bb: string[] = [];
  const pAdded: Picture[] = [];
  const pDeleted: Picture[] = [];
  if (!a) { a = []; a.push(new Picture); }
  if (!b) { b = []; b.push(new Picture); }
  a.forEach(x => aa.push(x.url));
  b.forEach(x => bb.push(x.url));

  b.forEach(x => {
    if (aa.includes(x.url)) { } else { pDeleted.push(x); }
  });
  a.forEach(x => {
    if (bb.includes(x.url)) { } else { pAdded.push(x); }
  });

  return { pAdded: pAdded, pDeleted: pDeleted };
}
/**
 * Compars 2 picture arrays and checks if new pictures are added . Return true for added new pic(s) based on path.
 * @param oPictures Original Product from bd
 * @param uPictures new or updated Rental Trailer or Truck
 */
export const checkNewPicturesAdded = (oPictures: Picture[], uPictures: Picture[]): boolean => {
  const oPaths = oPictures?.length > 0 ? oPictures.map(e => e.path) : [];
  const uPaths = uPictures?.length > 0 ? uPictures.map(e => e.path) : [];
  for (const u of uPaths) {
    const flag = !oPaths.includes(u);
    if (flag) {
      return true;
    }
  }
  return false;
};
export const checkPicturesDeleted = (oPictures: Picture[], uPictures: Picture[]): boolean => {
  const oPaths = oPictures?.length > 0 ? oPictures.map(e => e.path) : [];
  const uPaths = uPictures?.length > 0 ? uPictures.map(e => e.path) : [];
  for (const o of oPaths) {
    const flag = !uPaths.includes(o);
    if (flag) {
      return true;
    }
  }
  return false;
};

export const hasPicArrayChanged = (oPictures: Picture[], uPictures: Picture[]): boolean => {
  return checkNewPicturesAdded(oPictures, uPictures) && checkPicturesDeleted(oPictures, uPictures);
}
export const mergedPics = (currPics: Picture[], refPics: Picture[], comment?: string): Picture[] => {
  const combinedPics = currPics.slice();
  const paths = combinedPics.map(e => e.path);
  refPics.forEach(f => {
    if (!paths.includes(f.path)) {
      if (comment) { f.comment = comment; }
      combinedPics.push(f);
    }
  });
  return combinedPics;
}
export const bytesToSize = (bytes: number) => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

  if (bytes === 0) {
    return '0 Byte';
  }
  // eslint-disable-next-line radix
  const i = parseInt(`${(Math.floor(Math.log(bytes) / Math.log(1024)))}`);
  return Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i];
}
export const compressImage = (originalImage: any, originalSize: number, quality: number, name: string, targetSize: number, watermarkRequired? : boolean): 
Promise<{compressedImageFile: File, compressedImageSrc: string, sizeString: string}> => {
  // showing the compressed image
  // const originalImage = document.querySelector('#originalImage') as any;

  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  let compressedImageBlob: Blob;
  let compressedImageFile: File;
  let compressedImageSrc: string;
  let sizeString: string;

  const resizingFactor = targetSize < originalSize ? targetSize / originalSize : 1;
  logger.log("resizingFactor ---- "+resizingFactor);

  canvas.width = originalImage.width;
  canvas.height = originalImage.height;

  context.drawImage(
    originalImage as any,
    0,
    0
  );

  if(watermarkRequired){
    var watermarkImage = new Image();
    watermarkImage.src = "assets/pride-truck-sales-logo.webp"; // Replace with your watermark image path
    return new Promise((resolve, reject) => {
      watermarkImage.onload = function() {
         // Define the position and size of the watermark
         var watermarkX = canvas.width - watermarkImage.width - 10;
         var watermarkY = canvas.height - watermarkImage.height - 10;
         watermarkImage.width = watermarkImage.naturalWidth;
         watermarkImage.height = watermarkImage.naturalHeight;
         // Draw the watermark image on the canvas
         context.drawImage(watermarkImage, watermarkX, watermarkY);
  
         canvas.toBlob(
          (blob) => {
            if (blob) {
              compressedImageBlob = blob;
              compressedImageFile = new File([blob], name, {
                type: blob.type
              });
              compressedImageSrc = URL.createObjectURL(compressedImageBlob);
              sizeString = bytesToSize(blob.size);
              resolve({ compressedImageFile, compressedImageSrc, sizeString });
            } else {
              reject('no blob exists');
            }
          },
          'image/webp',
          resizingFactor
        );
      };
    });
  }else{
    // reducing the quality of the image
    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          if (blob) {
            compressedImageBlob = blob;
            compressedImageFile = new File([blob], name, {
              type: blob.type
            });
            compressedImageSrc = URL.createObjectURL(compressedImageBlob);
            sizeString = bytesToSize(blob.size);
            resolve({ compressedImageFile, compressedImageSrc, sizeString });
          } else {
            reject('no blob exists');
          }
        },
        'image/webp',
        resizingFactor
      );
    })
  }
  
 
}