import { OrderType } from './../order/order-type';
import { cloneDeep, isNumber, round, keys, isNil, union, clone } from 'lodash';
import { TransactionType } from './invoice-type';
import { InvoiceItemType } from './invoice-item';
import { Mileage } from '@trent/models/inspection/mileage';
import { IPeriod } from '../finance-input/i-method';
import { FinanceInput } from '../finance-input/finance-input-handler';
import { ITaxItem } from '../tax/i-tax-item';

// Create our number formatter.
const formatUSD = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  currencySign: 'accounting'
  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});
const formatCA = new Intl.NumberFormat('en-CA',{
  style: 'currency',
  currency: 'CAD',
  currencySign: 'accounting'
  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

export const currencyFormat = (currency: 'USD' | 'CA', amount: number) => {
  if(!isNumber(amount)) {
    return '';
  }
  switch (currency) {
    case 'USD':
      return formatUSD.format(amount);
    case 'CA':
      return formatCA.format(amount);
    default:
      throw new Error(`Invalid currency format, '${currency}' is not supported`);
  }
};

// #region interfaces for printing / html or saving as pdf.
/**
 * To print out the detail break down of order/invoice items for 
 * pdf / html.
 */
export interface IItemDetail {
  name: string;
  quantity?: number;
  unitPrice?: number;
  amount: number;
  date?: Date;
  period?: {from: Date, to: Date};
  invoiceItemType?: InvoiceItemType;
  mileage?: Mileage;
  itemId?: number;
  isLocked?: boolean;
  isNewItem?: boolean;
  comments?: string;
  commentNumber?: number;
  tid?: string;
}
/**
 * To print out the detail break down of order/invoice items for 
 * pdf / html.
 */
export interface IInvoiceSummary {

  /** Payment Schedule Data. */
  pymtSch: IPymtSchedule;

  /** Details for the invoice items related to the summary payment */
  items: IItemDetail[];

  /** summary details for pending payments.  */
  pendingInfo: IItemDetail[];

  /** summary Details for completed payments */
  completedInfo: IItemDetail[];

  /** summary details for history items. */
  historyInfo: IItemDetail[];

  /** period copied from the rentinvoice items */
  period?: IPeriod;

  isGenerateInvoiceEnabled? : boolean;//MKN - For UI validation - To disable generate invoice button for future date period(After 1)
  
  commentInfo?: {comment: string, commentNumber}[];
}


export const getItemDetail = (item: IPriceItem, totalSuffix?: string, isNewItem?: boolean, tid?: string, taxItems?: ITaxItem[]): IItemDetail[] => {
  if (isNil(item)) {
    return [];
  }
  if(isNil(totalSuffix) || totalSuffix.length === 0) {
    totalSuffix = 'Total Amount';
  }
  const d: IItemDetail[] = [];
  if (item.totalAmount !== item.amount) {
    d.push({ name: 'Sub Total', amount: round(item.amount, 2) });
  }
  for (const key in item.tax) {
    if (Object.prototype.hasOwnProperty.call(item.tax, key)) {
      const element = item.tax[key];
      if (isNumber(element) && element !== 0) {
        const taxName = !taxItems ? FinanceInput.fin.taxCalcDb[key].name : key;
        d.push({ name: `Tax - ${taxName}`, amount: element });
      }
    }
  }
  const tRef = tid ? tid : null;
  d.push({ name: totalSuffix, amount: round(item.totalAmount, 2), isNewItem: isNewItem,  tid: tRef});


  return d;
};

// #endregion

export interface IPriceItem {

  name: string;

  tax: { [key: string]: number };

  // discount: number;

  quantity?: number;

  unitPrice?: number;

  /** Note quantity may be getter */
  amount?: number;

  totalAmount?: number;
}

export const initPriceItem = (initial: IPriceItem, fillUndefined = false): IPriceItem => {
  const filler = (fillUndefined) ? undefined : 0;
  let x: IPriceItem = initial; //  (!!initial) ? initial : {} as any;
  if (isNil(x)) {
    x = {
      name: '',
      tax: {},
      // discount: 0,
      amount: 0,
      totalAmount: 0,
      // serviceFee: new BaseFee()
    };

  }
  x.tax = fillUndefined ? undefined : {};
  // x.discount = filler;
  x.quantity = filler;
  x.amount = filler;
  x.totalAmount = filler;
  // x.serviceFee.amount = filler;
  return x;
};

const sum = (src, dest) => {
  if (src == null) {
    return dest;
  }
  if (dest == null) {
    dest = {};
  }
  for (const k of Object.keys(src)) {
    if (typeof src[k] === 'number') {
      if (typeof dest[k] === 'number') {
        dest[k] += src[k];
      } else {
        dest[k] = src[k];
      }
    }
  }
  return dest;
};

export const roundObj = (src) => {
  for (const k of Object.keys(src)) {
    if (typeof src[k] === 'number') {
      src[k] = round(src[k], FinanceInput.fin.rdigit);
    }
  }
};

export interface IPriceSummary {
  name: string;
  amount: number;
  taxableAmount: number;
  tax: { [key: string]: number };
  totalAmount: number;
}

export const initPriceSummary = (): IPriceSummary => {
  return {
    name: '',
    amount: 0,
    taxableAmount: 0,
    totalAmount: 0,
    tax: {}
  };
};

export const getPriceSummarySum = (items: IPriceSummary[]): IPriceSummary => {
  const x = items.filter(f => !isNil(f)).reduce((prev, curr) => {
    return {
      name: '?',
      amount: prev.amount + curr.amount,
      taxableAmount: prev.taxableAmount + curr.taxableAmount,
      totalAmount: prev.totalAmount + curr.totalAmount,
      tax: sum(clone(prev.tax), clone(curr.tax))
    };
  }, initPriceSummary());

  roundObj(x);
  return x;
};


const isGetterOnly = (obj, prop) => {
  return Object.getOwnPropertyDescriptor(obj, prop)
    .configurable;
};



export const roundPriceItem = (o: IPriceItem) => {
  roundObj(o.tax);
  // o.discount = round(o.discount, FinanceInput.fin.rdigit);
  if (isNumber(o.quantity) || isNumber(o.unitPrice)) {
    o.quantity = round(o.quantity, FinanceInput.fin.rdigit);
    o.unitPrice = round(o.unitPrice, FinanceInput.fin.rdigit);
  } else {
    o.amount = round(o.amount, FinanceInput.fin.rdigit);
  }
  o.totalAmount = round(o.totalAmount, FinanceInput.fin.rdigit);
  // o.serviceFee.amount = round(o.serviceFee.amount, FinanceInput.fin.rdigit);
};

export const clonePriceItem = (item: IPriceItem) => {
  const o: IPriceItem = {
    name: item.name,
    tax: cloneDeep(item.tax),
    // discount: item.discount,
    amount: item.amount,
    totalAmount: item.totalAmount,
    // serviceFee: item.serviceFee
  };

  if (isNumber(item.quantity) || isNumber(item.unitPrice)) {
    o.quantity = item.quantity;
    o.unitPrice = item.unitPrice;
  }
  return o;
};

export const addNum = (src: number, dest: number): number => {
  if (isNumber(dest) && isNumber(src)) {
    return dest + src;
  }
  return dest;
};


export const subtractNum = (left: number, right: number): number => {
  if (isNumber(right) && isNumber(left)) {
    return left - right;
  }
  return undefined;
};

export const isEmptyPriceItem = (item: IPriceItem) => {
  for (const x of Object.values(item)) {
    if (!(x === '' || x == null || x === 0 || isNaN(x) || (typeof x === 'object' && keys(x).length === 0))) {
      return false;
    }

  }
  return true;
};

export const getPriceItemSum = (src: IPriceItem[], des?: IPriceItem) => {
  const d = initPriceItem(des);
  for (const i of src) {
    d.tax = sum(i?.tax, d?.tax);
    // d.discount = addNum(i?.discount, d?.discount);
    if (isNumber(d?.quantity) || isNumber(d?.unitPrice)) {
      d.quantity = addNum(i?.quantity, d?.quantity);
      d.unitPrice = addNum(i?.unitPrice, d?.unitPrice);
    }
    // if (!isGetterOnly(d, 'amount')) {
    d.amount = addNum(i?.amount, d?.amount);
    // }
    d.totalAmount = addNum(i?.totalAmount, d?.totalAmount);
    // if (!!i.serviceFee) {
    //   d.serviceFee.amount = addNum(i?.serviceFee.amount, d?.serviceFee.amount);
    // }
  }
  roundPriceItem(d);
  return d;
};

export const subtractPriceItem = (l: IPriceItem, r: IPriceItem) => {
  const d = initPriceItem(null);
  d.amount = subtractNum(l.amount, r.amount);
  d.totalAmount = subtractNum(l.totalAmount, r.totalAmount);
  // d.serviceFee.amount = subtractNum(l.serviceFee.amount, r.serviceFee.amount);
  // d.discount = subtractNum(l.discount, r.discount);
  const taxKeys = union(keys(l.tax), keys(r.tax));
  if (taxKeys?.length > 0) {
    d.tax = {};
    for (const key of taxKeys) {
      d.tax[key] = subtractNum(
        isNumber(l.tax[key]) ? l.tax[key] : 0,
        isNumber(r.tax[key]) ? r.tax[key] : 0);
    }
  }
  // if (!!l.tax && !!r.tax) {
  //   d.tax = {};
  //   for (const key in l.tax) {
  //     if (Object.prototype.hasOwnProperty.call(l.tax, key)) {
  //       d.tax[key] = subtractNum(l.tax[key], r.tax[key]);
  //     }
  //   }
  // }
  d.quantity = subtractNum(l.quantity, r.quantity);
  d.unitPrice = subtractNum(l.unitPrice, r.unitPrice);
  roundPriceItem(d);
  return d;
};

export const addPriceItem = (l: IPriceItem, r: IPriceItem) => {
  const d = initPriceItem(null);
  d.amount = addNum(l.amount, r.amount);
  d.totalAmount = addNum(l.totalAmount, r.totalAmount);
  // if (!!l.serviceFee) {
  //   d.serviceFee.amount = addNum(l.serviceFee.amount, r.serviceFee.amount);
  // }
  // d.discount = addNum(l.discount, r.discount);
  const taxKeys = union(keys(l.tax), keys(r.tax));
  if (taxKeys?.length > 0) {
    d.tax = {};
    for (const key of taxKeys) {
      d.tax[key] = addNum(
        isNumber(l.tax[key]) ? l.tax[key] : 0,
        isNumber(r.tax[key]) ? r.tax[key] : 0);
    }
  }
  d.quantity = addNum(l.quantity, r.quantity);
  d.unitPrice = addNum(l.unitPrice, r.unitPrice);
  roundPriceItem(d);
  return d;
};


export const removeUnitPriceQuantity = (x: IPriceItem) => {
  if (!isNil(x)) {
    if (!isNil(x.quantity)) {
      delete x.quantity;
    }
    if (!isNil(x.unitPrice)) {
      delete x.unitPrice;
    }
  }
};

export enum ItemStatus {

  /** Initial state when some one bids etc. No contract in place and record is in draft. */
  quote = -10,

  /** The order is already pre-authorized. It will be automatically paid when the order contract
   * is processed. */
  preAuthorized = 0,

  /** Order here is now a binding contract. It can be paid.  */
  pay = 20,


  partialPaid = 30,

  /** Order is already paid. */
  paid = 50,

  /** Initial state when some one bids etc. No contract in place and record is in draft. */
  cancelled = 100,


  expired = 110

}

export enum InvoiceStatus {

  /**
   * Created before customer intend to make a deal but customer has not yet initiated
   * a payment. After a certain date or if say rental/item is no longer available
   * this type of Invoice will need to be cleaned up
   */
  quote = -10,


  /** Customer has agreed and he has pre authorized the invoice terms
   * Note: vendor can still refuse.
   */
  pending = 0,

  /** Vendor did not agree
   * Pre-Auth did not go through
   */
  cancelled = 10,


  partialPaid = 20,

  paid = 50,

  Complete = 100
}


/** Currency code on the orders. */
export enum CurrencyCode {
  CAD = 0,
  USD = 1
}

export interface IPymtSchedule {

  orderType: OrderType;

  dueDate: Date;

  paymentDate?: Date;

  pendingPymnt: IPriceSummary;

  completedPymt?: IPriceSummary;

  /** used in debit entries only. So only release this payment if the corresponding payment
   * from customer was received on the receivable order/invoice. */
  clearToProcess?: boolean;

  /** linked comma delimited number string that relate this payment sch to one or more invoice item ids. */
  invItemId: string;

  history?: IHistory[];

  comment?: string;

    /** path to accounting invoice generated from server; This locks @pymtSchedule from adding more items */
  accountingInvoicePath?: string;

  accountingInvoiceDate?: Date;

}

export interface IHistory {
  action: OrderType,

  paymentDate?: Date;

  payment?: IPriceSummary;

  /** Firebase document id for the transaction. it is same as Order id for the vendor. */
  tid?: string;
}
/** UI Helper for showing the line by line detail */
export interface IPayment {
  basePrice: number;
  serviceFee: number;
  tax: { [key: string]: number };
  totalAmount: number;
  dueDate: Date;
  from: Date;
  to: Date;
  orderId: string | number;
  paymentStatus: OrderType;
  completedPymt?: number;
  paymentDesc: string;
  invoiceType: TransactionType;
  showFilter?: boolean;
}
/** Coverts item Ids string (e.g. 1,2) to number array (e.g [1,2]) */
export const itemIdsToArray = (itemIds: string): number[] => {
  const itemIdArray = [];
    if (itemIds.includes(',')) {
      itemIdArray.concat(itemIds.split(',').map(n => +n));
    } else {
      itemIdArray.push(+itemIds);
    }
    return itemIdArray;

};
