import { IAuthCoreService } from '@trent/services/auth/iauth-core.service';
import { isDate } from 'lodash';
import { promiseWraper } from '@trent/models/utility';
import { CmsService } from '../../services/cms.service';
import { PageHtml } from './../../models/cms/page-html';
import { StateBase } from './../state-base';
import { State, StateContext, Action, Selector } from '@ngxs/store';
import { map } from 'rxjs/operators';
import { noop, Subscription } from 'rxjs';
import * as entity from '../entity.state';
import { LocalStorage } from '@trent/services/local-storage/local-storage.service';
import { Injectable } from '@angular/core';
import { logger } from '@trentm/log/logger';
import { ContractHtml } from '@trent/models/cms/contract-html';
import { cmsSearchClientFilter, ICmsParam } from '@trent/models/cms/cms-param';
import { buildDataRequest, IDataLoadStatus, LoadStatus, updatePaging } from '@trent/models/observable-util/data-status';
import { getObjKey, Paging } from '@trent/models/observable-util/paging';
import { PagingContainer } from '@trent/models/observable-util/paging-container';
import { parseCms } from '@trent/models/cms/cms-helper';


// #region State Model
export interface CmsStateModel {
  pageEditMode: boolean,
  // New approach with paging
  data: { [id: string]: PageHtml };  // changed

  allDataLoaded: boolean;
  allDataLoadStatus: {
    [key: string]: IDataLoadStatus<ICmsParam>;
  };
}

function initCmsState(): CmsStateModel {
  return {
    pageEditMode: false,
    data: {},
    allDataLoaded: false,
    allDataLoadStatus: {},
  };
}

// #endregion

// #region Actions for new data methods with paging
export class CmsTogglePageEdit {
  static readonly type = '[Cms] toggle page Edit';
  constructor() { }
}

export class CmsPageUpdated {
  static readonly type = '[Cms] page Updated';
  constructor(public payload: { id: string | number, data: PageHtml }) { }
}

export class CmsPageRequested {
  static readonly type = '[Cms] Request a single Page';
  constructor(public payload: { id: string | number, useCache?: boolean }) { }
}
export class CmsPageLoaded {
  static readonly type = '[Cms] Load a single page';
  constructor(public payload: { id: string | number, data: PageHtml }) { }
}

export class CmsStateReset {
  static readonly type = '[Cms] Reset State';
}


/**
* @author - Cm
* @purpose - Pts home screen
*/


export class CmsAllPageHtmlRequested {
  static readonly type = '[Cms] All PageHtml Requested';
  constructor(public payload: { pData: Paging, param: ICmsParam }) { }
}

export class CmsAllPageHtmlLoaded {
  static readonly type = '[Cms] All PageHtml Loaded';
  constructor(public payload: {
    data: { [id: string]: PageHtml },
    key: string,
  }) { }
}


export class CmsRemoveById {
  static readonly type = '[Cms] Remove by Id';
  constructor(public payload: { id: string | number }) { }
}

// #endregion

@State<CmsStateModel>({
  name: 'cmsState',
  defaults: initCmsState()
})

@Injectable()
export class CmsState extends StateBase {

  allCmsReqSub: Subscription;
  allCmsSubData: PagingContainer<PageHtml, ICmsParam> = new PagingContainer();


  constructor(private cms: CmsService, private storage: LocalStorage, private auth: IAuthCoreService) {
    super();
  }

  // #region Selectors

  @Selector()
  static selectPageEditMode(state: CmsStateModel) {
    return state.pageEditMode;
  }

  @Selector()
  static selectPageById(state: CmsStateModel) {
    /** use it if return is a class */
    // return entity.getByIdFn_new(state.data, PageHtml.parse);
    return entity.getByIdFn_new(state.data); // , PageHtml.parse); client must parse as it can be an extended class.
  }

  /**
 * @author - MKN
 * @purpose - Contract - selector - temp purpose
 */

  @Selector()
  static selectContractById(state: CmsStateModel) {
    /** use it if return is a class */
    return entity.getByIdFn_new(state.data, parseCms);
  }

  @Selector()
  static selectedAllPageHtml(state: CmsStateModel) {
    return (o: ICmsParam): PageHtml[] => {
      if (state.data == null || Object.keys(state.data).length === 0) {
        return [];
      }
      // remove keys that have revision/draft ids, i.e that contain '~' in the id.
      const keys = Object.keys(state.data).filter(k =>
        k.indexOf('/') === -1 && state.data[k] != null);

      // object without the rev/draft.
      const filtered = keys.reduce((obj, k) => {
        obj[k] = state.data[k];
        return obj;
      }, {});
      let output: PageHtml[];

      output = Object.values(filtered).map(x => parseCms(x));
      output = cmsSearchClientFilter(output, o);

      return output;
    };
  }


  // #endregion
  @Action(CmsTogglePageEdit)
  cmsTogglePageEdit(context: StateContext<CmsStateModel>) {
    context.patchState({ pageEditMode: !context.getState().pageEditMode });
  }

  @Action(CmsPageRequested)
  async cmsPageRequested(context: StateContext<CmsStateModel>, action: CmsPageRequested) {
    const state = context.getState();
    const id = action.payload.id;
    if (state.data[action.payload.id] == null) {
      // Check local storage.
      if (action.payload.useCache && !this.auth.isAdmin) {
        const p = await promiseWraper(PageHtml.getFromStorage(id, this.storage));
        if (p.success && !!p.data) {
          context.dispatch(new CmsPageLoaded({ id, data: p.data }));
          return;
        }
      }
      logger.log('[cms strage] data not found/skipped in local storage. Fetching db....');
      const s = this.cms.getPageHtmlById_db(action.payload.id)
        .pipe(
          map(data => {
            // const c = CompanyFleet.parse(data);
            // logger.log('Company Data was recived from server', data);
            return context.dispatch(new CmsPageLoaded({ id, data }));
          }
          )); // .subscribe(noop);
      this.subscribe(s, () => noop(), CmsPageRequested.type);
    }
  }

  @Action(CmsPageLoaded)
  cmsPageLoaded(context: StateContext<CmsStateModel>, action: CmsPageLoaded) {
    if (!!action.payload.data && !!action.payload.data.id) {

      const state = context.getState();
      const c = {};
      c[action.payload.data.id] = action.payload.data;
      context.patchState({
        data: { ...state.data, ...c }
        // compByUser: { ...state.compByUser, ...{ [action.payload.id]: action.payload.data } }
      });

      // Agreements are not to be saved in cache.
      if (action?.payload?.data?.isAgreement || action?.payload?.data?.isRevControlled) {
        return;
      } else {
        this.storage.set(`html-${action.payload.data.id}`, action.payload.data);
      }
    }
  }


  @Action(CmsPageUpdated)
  cmsPageUpdated(context: StateContext<CmsStateModel>, action: CmsPageUpdated) {
    const state = context.getState();
    let c = action.payload.data;
    if (typeof (c.toFirebaseObj()) === 'function') {
      c = c.toFirebaseObj();
    }
    context.patchState({
      data: { ...state.data, ...{ [action.payload.id]: c } }
    });
  }


  @Action(CmsStateReset)
  cmsStateReset() {
    // This is not needed as all html data is public. so ignore.
    // this.clearSubscriptions();
    // context.setState(initCmsState());
  }

  @Action(CmsAllPageHtmlRequested)
  cmsAllPageHtmlRequested(context: StateContext<CmsStateModel>, action: CmsAllPageHtmlRequested) {
    const oKey = getObjKey(action.payload.param);

    // Define function that return the data status object from the state.
    const getDataStatusStateFn = () => context.getState().allDataLoadStatus;

    /** custom build the OR children query. */
    const buildOrQueryChildrenFn = (o: ICmsParam) => undefined;
    // if data requested now, is already partially loaded by another query's child previously
    // but the number of the items are not enough (ex. as a child, it loaded only 5, but current
    // request ask for more, then next page of that query should be called instead.)
    const nextPageFn = (param: ICmsParam) => {
      //context.dispatch(new AllBidsNextPageRequested({ param }));
    };
    buildDataRequest(
      oKey, action.payload.param, action.payload.pData,
      getDataStatusStateFn,
      buildOrQueryChildrenFn,
      nextPageFn,
      (
        obj: { [key: string]: IDataLoadStatus<ICmsParam> },
        set: { key: string, node: IDataLoadStatus<ICmsParam> }[]
      ) => {
        if (!!obj) {
          // Patch the state.
          const state = context.getState();
          context.patchState({
            allDataLoadStatus: { ...state.allDataLoadStatus, ...obj }
          });
        }
        // Process the query.
        set.forEach((val) => {
          // some of the nodes are already loaded. Only process that are loading... status.
          if (val.node.loadStatus !== LoadStatus.Loading) {
            return;
          }
          // if this request is just overwriting a stall or pending request, unsubscribe that observable
          this.allCmsSubData.unsubscribe(val.key);

          // create the paging observable and call db.
          const b = this.cms.getAllCms_PagingObservable();

          const msg$ = b.getData(action.payload.pData, val.node.param)
            .pipe(
              map(d => {
                context.dispatch(new CmsAllPageHtmlLoaded({
                  data: d,
                  key: val.key
                }));
                return d;
              }));
          const sub = this.subscribe(msg$, () => noop(), CmsAllPageHtmlLoaded.type);
          this.allCmsSubData.addData(val.key, sub, b);
        }
        );
      });

  }

  @Action(CmsAllPageHtmlLoaded)
  cmsAllPageHtmlLoaded(context: StateContext<CmsStateModel>, action: CmsAllPageHtmlLoaded) {
    const state = context.getState();
    const subData = this.allCmsSubData.getData(action.payload.key);
    const updatedLoadStatus = updatePaging(action.payload.key, state.allDataLoadStatus, subData);
    context.patchState({
      allDataLoaded: true,
      allDataLoadStatus: updatedLoadStatus,
      data: { ...state.data, ...action.payload.data }
    });
  }

  @Action(CmsRemoveById)
  CmsRemoveById(context: StateContext<CmsStateModel>, action: CmsRemoveById) {
    const state = context.getState();
    if (Object.keys(state.data).indexOf(`${action.payload.id}`) > -1) {
      const currState = context.getState();
      const newData = { ...currState.data };
      delete newData[action.payload.id];
      context.patchState({ data: newData });
      const state1 = context.getState();
      logger.log('[Cms-State], item removed by id', action.payload.id, state1);

    } else { logger.log('[Cms-State], item to be removed id is not available in the store'); }
  }

  // #region Custom Functions and subscriptions
  public clearSubscriptions() {
    if (!!this.allCmsReqSub) {
      this.allCmsReqSub.unsubscribe();
      this.allCmsReqSub = null;
    }
    this.allCmsSubData.unsubscribeAll();
    super.clearSubscriptions();
  }



}
