import { round } from 'lodash';
import { methodFn } from '../finance-input/fn-lib';
import { PaymentMethod } from './i-payment-method';
import { InvoiceItem } from '../invoice/invoice-item';
import { FinanceInput } from '../finance-input/finance-input-handler';


/**
 * Calculate Transaction fee.
 * @param paymentMethod payment method to be used.
 * @param item Invoice Item on which the transaction is to be calculated.
 * @param customAmount Amount on which the fee has to be calculated. 
 *        If this is not provided. item.Amount will be used the the determination of the transaction fee.
 * @returns 
 */
export const calculateTransactionFee = (paymentMethod: PaymentMethod, item: InvoiceItem, customAmount?: number ): number => {
  const m =  FinanceInput.fin.transactionFeeDb[paymentMethod];
  return methodFn(m , item, customAmount);
};
