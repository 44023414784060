import { sanitizeDate, sanitizeDateIPoint } from './../utility/sanitize-helper';
import { TransactionStatus } from './transaction-status';
import { TripBase, parseTrip } from './trip-base';
import { LocationData } from '../location/loc-data';
import { IPoint } from '../product-search/interfaces';
import { findDistance } from '../utility/distf';
import { TripTransaction } from './trip-transaction';
import { TripStatus } from './trip-status';
import { parseTripTransactionArray } from './trip-helper';
import { plainToInstance, Exclude } from 'class-transformer';
import { LocStaticMap } from './loc-static-map';
import { addHours } from 'date-fns';
import { isEqual } from 'lodash';
import { Data } from '@angular/router';
import { logger } from '../log/logger';


@Exclude()
export class TripLocationAnalysis extends TripBase {

  private lastUpdatedStation: TripTransaction = null;

  public static parse(obj): TripLocationAnalysis {
    return parseTrip(obj, TripLocationAnalysis);
  }

  public updateTripTransactionIndexes() {
    for (let index = 0; index < this.tripSequence.length; index++) {
      const element = this.tripSequence[index];
      element.tripIndex = index;
    }
  }

  public isFirstStation(loc: TripTransaction) {
    return loc.tripIndex === 0;
  }

  public isLastStation(loc: TripTransaction) {
    return loc.tripIndex === this.tripSequence.length - 1;
  }

  /**
   * Find next consecitive stations to the given location.
   */
  public getConsecitives(loc: TripTransaction) {
    const A: TripTransaction[] = [];
    for (let i = loc.tripIndex + 1; i < this.tripSequence.length - 1; i++) {
      const t = this.tripSequence[i];
      if (t.address.geoLoc.geohash === loc.address.geoLoc.geohash) {
        A.push(t);
      } else {
        break;
      }
    }
    for (let i = loc.tripIndex - 1; i > 0; i--) {
      const t = this.tripSequence[i];
      if (t.address.geoLoc.geohash === loc.address.geoLoc.geohash) {
        A.push(t);
      } else {
        break;
      }
    }
    return A;

    // return this.tripSequence.filter(x =>
    //   x.tripIndex - loc.tripIndex === 1 &&
    //   loc.address.geoLoc.geohash === x.address.geoLoc.geohash);
    // !this.isLastStation(loc) &&
    //   loc.address.geoLoc.geohash === this.tripSequence[loc.tripIndex + 1].address.geoLoc.geohash;
  }

  get lastStation() {
    return (this.tripSequence?.length > 0) ?
      this.tripSequence[this.tripSequence.length - 1] : undefined;
  }

  get firstStation() {
    return (this.tripSequence?.length > 0) ?
      this.tripSequence[0] : undefined;
  }

  /** If any future station beyond the @param idx has already been arived or greater */
  public isFutureStationArrived(idx: number) {

    if (idx !== 0) {
      return false;
    }

    /** nothing can come after the last station */
    if (idx === this.tripSequence?.length - 1) {
      return false;
    }
    return this.tripSequence.findIndex(x =>
      x.tripIndex > idx && x.transactionStatus >= TransactionStatus.arrived) > -1;
  }

  /** add location to the sequence */
  public addLocation(currLoc: LocationData, s: TripTransaction) {
    s.gpsData = !!s.gpsData && s.gpsData.length > 0 ? s.gpsData : [];
    s.gpsData.push({ iPoint: currLoc.iPoint, timeStamp: currLoc.createdOn });
    this.lastUpdatedStation = s;
  }

  private updateTSeqTimeStamps() {
    for (const s of this.tripSequence) {
      if (s.gpsData?.length > 0) {
        s.gpsData.sort((a, b) => a.timeStamp.valueOf() - b.timeStamp.valueOf());

        s.autoCompleteTime = (!!s.autoCompleteTime) ? s.autoCompleteTime : {} as any;
        s.autoCompleteTime.enteredAt = s.gpsData[0].timeStamp;

        if (s.transactionStatus === TransactionStatus.autoCompleted) {
          s.autoCompleteTime.leftAt = s.gpsData[s.gpsData.length - 1].timeStamp;
        }
      }
    }
  }

  /** update all the consective station statues to the given station */
  private updateConsectiveStations(s: TripTransaction) {
    for (const cs of this.getConsecitives(s)) {
      cs.transactionStatus = s.transactionStatus;
      cs.gpsData = s.gpsData.slice();
    }
  }

  /**
  * If the given location insdie the geofence circle defined by station cetner and raidus.
  * @param loc : location to be evaluated.
  * @param station : center of geofence
  * @param rad  radius of geofence
  */
  public isLocationInsideRadius(loc: IPoint, station: IPoint, rad: number): boolean {
    const dist = findDistance(loc.latitude, loc.longitude, station.latitude, station.longitude);
    return dist <= rad;
  }

  /**
* Updates trip based on the GPS location data. Returns
* @param updatedTrip returns Updated trip
* @param updateReq returns Is Updated required
* @param uTransWMap returns updatedTransaction with map metadata
* @param oTrip TripBase
* @param tripLoc TripLocation[]
* @param locRadLimitIn min (inner) loc radius (km) used to decidie if truck is departed/entered from its location.
* @param locRadLimitOut max (outer) loc radius (km) used to decidie if truck is departed/entered from its location.
*/
  public gpsAutoUpdateTrip(tripLoc: LocationData[], locRadLimitIn: number = 0.2): { updateReq: boolean } {

    this.updateTripTransactionIndexes();
    let flag = false;
    const updateReqArray: boolean[] = [];
    for (let gx = 0; gx < tripLoc.length; gx++) {
      const currLoc = tripLoc[gx];

      const insideStations: TripTransaction[] = [];

      for (const s of this.tripSequence) {

        if (this.isLocationInsideRadius(currLoc, s.address.geoLoc.geopoint, locRadLimitIn)) {
          insideStations.push(s);

        } else {
          /** this station is outside of the current location. Any outside station, in the arrived status, must be marked auto complete.  */
          if (s.transactionStatus === TransactionStatus.arrived) {
            s.transactionStatus = TransactionStatus.autoCompleted;
            flag = true;
          }
        }
      }
      flag = this.analyzeAndUpdate(currLoc, insideStations) || flag;
    }

    this.updateTSeqTimeStamps();

    /** Mark trip complete if the end station is autoComplete. */
    if (this.lastStation?.transactionStatus === TransactionStatus.autoCompleted) {
      this.tripStatus = TripStatus.closed;
    }

    return { updateReq: flag };
  }

  /**
   * Go through all the stations and update it.
   * @param currLoc current location
   * @param insideStations list of stations where current location is inside the geofence circle.
   */
  private analyzeAndUpdate(currLoc: LocationData, insideStations: TripTransaction[]): boolean {
    let updateReq = false;
    let s1: TripTransaction = null;

    let isLocationInserted = false;

    if (insideStations.length > 0) {
      s1 = insideStations.find(x =>
        x.transactionStatus <= TransactionStatus.arrived
        && !this.isFutureStationArrived(x.tripIndex)
      );
      if (!!s1) {
        isLocationInserted = true;
        s1.transactionStatus = this.isLastStation(s1) ?
          s1.transactionStatus = TransactionStatus.autoCompleted :
          TransactionStatus.arrived;
        this.addLocation(currLoc, s1);
        this.updateConsectiveStations(s1);
        updateReq = true;
      }
    }

    if (!isLocationInserted && insideStations?.length > 0) {
      for (let index = insideStations.length - 1; index >= 0; index--) {
        const s = insideStations[index];
        if (s.transactionStatus === TransactionStatus.autoCompleted
          && !this.isLastStation(s)
          && !this.isFirstStation(s)
        ) {
          this.addLocation(currLoc, insideStations[0]);
          this.updateConsectiveStations(insideStations[0]);
          updateReq = true;
          break;
        }
      }
      this.lastUpdatedStation = null;
    }
    return updateReq;

  }

  /**
   * get transaction with need Static Map, based on if the transaction has pickup or delivery
   */
  getTransWithMap() {
    const transWMap: TripTransaction[] = [];
    for (const seq of this.tripSequence) {
      // get map param for waiting time report
      const pic = new LocStaticMap();
      seq.locStaticMap = pic.createLocMapImage(this.id, seq);
      if (!!seq.locStaticMap) {
        transWMap.push(seq);
      }
    }
    return transWMap;
  }

  /**
  * After autoComplete update ETA for the subsequent locations
  * @param this Updated TripLocationAnalysis
  * @param oTrip Orignal Trip
  */
  updateETA(oTrip: TripBase) {
    // Find the transaction which is auto complete by comparing before and after
    // As more than one transaction could updated during the auto complete, get the last changed transaction
    const autoC: TripTransaction = this.tripSequence.filter((f, i) =>
      (f.transactionStatus === TransactionStatus.arrived || f.transactionStatus === TransactionStatus.autoCompleted) &&
      (f.transactionStatus > oTrip.tripSequence[i].transactionStatus)).pop();
    const autoCIndex: number = this.tripSequence.findIndex(f => isEqual(f, autoC));
    // Find the ETA for the subsequent locations based on the actual departure from the autocompleted location and using the driving time
    // assume travel time is same what was used during the trip schedule.

    // Find the subsequent transactions
    if (autoC && autoCIndex > -1) {
      const seq = this.tripSequence;
      // Add driving duration, HOS hours and dwell duration to next location ETA
      for (let i = 0; i < seq.length; i++) {
        const ele = seq[i];
        if (i === autoCIndex + 1) {
          if (autoC.transactionStatus === TransactionStatus.autoCompleted) {
            ele.eta = addHours(autoC.autoCompleteTime.leftAt, (ele.drvDuration + ele.hos.offDuty));
          } else if (autoC.transactionStatus === TransactionStatus.arrived) {
            ele.eta = addHours(autoC.autoCompleteTime.enteredAt, (autoC.dwellDuration + ele.drvDuration + ele.hos.offDuty));
          }
        } else if (i > autoCIndex + 1) {
          ele.eta = addHours(seq[i - 1].eta, (seq[i - 1].dwellDuration + ele.drvDuration + ele.hos.offDuty));
        }
      }
    }
    return this;
  }
  /**
   * update eta for trip transaction following the last updated transaction
   * @param oTrip orginal trip from db
   */
  calculateETA(oTrip: TripBase) {
    this.updateTripTransactionIndexes();
    const lastUpdated = this.tripSequence.filter((f, i) => f.transactionStatus > oTrip.tripSequence[i].transactionStatus).pop();
    if (lastUpdated.transactionStatus === TransactionStatus.autoCompleted) {
      lastUpdated.departure = lastUpdated.autoCompleteTime.leftAt;
    } else if (lastUpdated.transactionStatus === TransactionStatus.arrived) {
      lastUpdated.departure = addHours(lastUpdated.autoCompleteTime.enteredAt, lastUpdated.dwellDuration);
    } else {
      logger.warn('[trip-location-analysis] programmatical error, invalid transactionStatus', lastUpdated.transactionStatus);
    }
    for (let i = lastUpdated.tripIndex + 1; i < this.tripSequence.length; i++) {
      const element = this.tripSequence[i];
      const prev = this.tripSequence[i - 1];
      element.eta = addHours(prev.departure, (element.drvDuration + element.hos.offDuty));
      element.departure = addHours(element.eta, element.dwellDuration);
    }
  }

}
