import { IsDefined, IsEnum, ValidateIf } from 'class-validator';
import { Exclude, Expose, Type, instanceToInstance } from 'class-transformer';
import { PhysicalLocationType, ProductType } from './product-type';
import { BaseModel } from '../base/base-model';
import { Task } from '../sys/task';
import { TaskType } from '../sys/task-type';
import { EnumHelper } from '../utility/enum-helper';
import { toSentence } from '../utility/helper';
import { TaskUtl } from '../sys/task-utl';
import { isNil, isNumber } from 'lodash';
import { DbStatus } from '../base/db-status';
import { StoreLocationSummary } from '../store-location/store-location';
import { ImageType } from '../sales-option';
import { Vehicle, VehicleValidationGroup } from './vehicle';
import { Address } from '../address/address';
import { CustomFirebaseMetadata, Picture } from '../inspection/picture';
import { Trailer } from './trailer';
import { Truck } from './truck';
import { OdometerUnitsType } from './netsuite-product-data';
import { FileInfo, IFileCopyData } from '../media/file-info';


export type ProductDocType = 'vehicleReg' | 'safety' | 'cabCard';
const physicalLocationDetails: VehicleValidationGroup = 'physicalLocationDetails';//MKN

export interface ProductSummaryBase {
  // cid: string | number;
  pid: string | number;
  pRevid: number;
  productType: ProductType;
}

export interface LastUpdateUserDetails {
  uid: string | number;
  displayName: string;
}

//For Products algolia dates
export const ProductDates = ['plateExpiryDate', 'safetyExpiryDate', 'pmDate', 'createdAt', 'updatedAt','pictureCreatedAt', 'pictureLastUpdatedAt'];

@Exclude()
export class ProductBase extends BaseModel {

  public static readonly collectionName = 'product';

  //netSuite stock number for internal use
  @Expose()
  netSuiteStockNumber: string;

  /** Thumbnail image. */
  @Expose()
  thumbNailImg: string;

  @Expose()
  @IsEnum(ProductType)
  productType: ProductType;

  @Expose()
  marketingLocationSummary: StoreLocationSummary;

  
/**
 * @author MKN
 * Product Physical Location Type
 * If prideLocation is selected then store location is required
 * If other is selected then Physical location address is required
 */
  @Expose()
  @IsDefined({ message : "Physical location type is required", groups: [physicalLocationDetails] })
  physicalLocationType: PhysicalLocationType;

  @Expose()
  @ValidateIf(o => o.physicalLocationType == PhysicalLocationType.prideLocation, { groups: [physicalLocationDetails]})
  @IsDefined({ message : "Physical location is required", groups: [physicalLocationDetails] })
  physicalLocationSummary: StoreLocationSummary;

  @Expose()
  @ValidateIf(o => o.physicalLocationType == PhysicalLocationType.other, { groups: [physicalLocationDetails]})
  @IsDefined({ message : "Physical location address is required", groups: [physicalLocationDetails] })
  physicalLocationAddress: Address;

	@Expose()
	imageType: ImageType;//Update when publish the sales options

	@Expose()
	@Type(() => Picture)
	pictures: Picture[];//Update when publish the sales options
  
	@Expose()
	pictureLastUpdatedAt: Date;

  @Expose()
	pictureLastUpdateUserDetails: LastUpdateUserDetails;

  // @Expose()
  // picturesLength = this.picLength;

	// @Expose()
	// pictureLastUpdatedByUid: string | number;

  // @Expose()
	// pictureLastUpdatedByUserName: string;
  
	// @Expose()
	// pictureCreatedAt: Date;

	// @Expose()
	// pictureCreatedByUid: string | number;

  clone() {
    const t = instanceToInstance(this);
    t.sanitize();
    return t;
  }

  getProductSummary(): ProductSummaryBase {
    return {
      // cid: this.cid,
      pid: this.id,
      pRevid: this.revId,
      productType: this.productType,
    };
  }
  //#region task
  setAdminTaskInputs(task: Task, websiteUrl: string) {
    task.taskType = this.getTaskType();
    task.name = `${task.senderCompanyName} ${toSentence(EnumHelper.getName(TaskType, task.taskType))}`;
    task.data = { action: `${task.taskType}`, action_key: `${this.id}` };


    const urlSegment = TaskUtl.getUrlFromTaskType(task.taskType, task.data.action_key).url;

    task.notification = {
      title: `${task.name}`,
      body: `Click to get ${EnumHelper.getName(ProductType, this.productType)} details`,
      icon: 'https://placeimg.com/250/250/people',
      clickAction: `${websiteUrl}${urlSegment}`
    };
  }
  setProdReleaseTaskInputs(task: Task, websiteUrl: string) {
    task.taskType = this.getTaskType();
    task.name = `${task.senderCompanyName} ${toSentence(EnumHelper.getName(TaskType, task.taskType))}`;
    task.data = { action: `${task.taskType}`, action_key: `${this.id}` };


    const urlSegment = TaskUtl.getUrlFromTaskType(task.taskType, task.data.action_key).url;

    task.notification = {
      title: `${task.name}`,
      body: `Click to get ${EnumHelper.getName(ProductType, this.productType)} details`,
      icon: 'https://placeimg.com/250/250/people',
      clickAction: `${websiteUrl}${urlSegment}`
    };
  }

  getTaskType(): TaskType {
    switch (this.dbStatus) {

      case DbStatus.SubmitedForApproval:
        return this.getReleaseReqTaskByProductType();
      case DbStatus.Released:
        return this.getReleasedProdTaskByProductType();
      case DbStatus.ReleasedMod:
      case DbStatus.Initial:
        throw new Error(`Invalid dbStatus, ${this.dbStatus} is not programmed yet`);

      default:
        throw new Error(`Invalid dbStatus, ${this.dbStatus} is not programmed yet`);

    }
  }
  getReleaseReqTaskByProductType() {
    switch (this.productType) {
      case ProductType.trailer:
        return TaskType.RequestReleaseTrailer;
      case ProductType.truck:
        return TaskType.RequestReleaseTruck;

      default:
        throw new Error(`Invalid product type, ${this.productType} is not programmed yet`);
    }
  }
  getReleasedProdTaskByProductType() {
    switch (this.productType) {
      case ProductType.trailer:
        return TaskType.TrailerReleased;
      case ProductType.truck:
        return TaskType.TruckReleased;

      default:
        throw new Error(`Invalid product type, ${this.productType} is not programmed yet`);
    }
  }
  // #endregion
  getProductDesc() {
    switch (this.productType) {
      case ProductType.truck:
        return 'Truck';
      case ProductType.trailer:
        return 'Trailer';

      default:
        return null;
        break;
    }
  }

  getOdometer(odometer : string) : number{
    console.log(odometer);
    if(!odometer){
      return 0;
    }

    if(isNumber(odometer)){
      return odometer;
    }

    if(odometer.toLowerCase().indexOf("kms") > -1 || odometer.toLowerCase().indexOf("km")){
      let odometerSplit = odometer.split(" ");
      return odometerSplit.length > 0 ? Number(odometer.split(" ")[0].trim().replace(/\,/g,'')) : 0
    }

    if(odometer.toLowerCase().indexOf("mi") > -1 || odometer.toLowerCase().indexOf("Miles") > -1 || odometer.toLowerCase().indexOf("miles")){
      let odometerSplit = odometer.split(" ");
      return odometerSplit.length > 0 ? Number(odometer.split(" ")[0].trim().replace(/\,/g,'')) : 0
    }
    return 0;
  }
  
  /**
   * Generate Product object from excel object(Sales Options Bulk upload)
   * @param item 
   */
  generateProductFromExcel(item: any){
  
    ((this as any) as Vehicle).vin = item.vin;
    ((this as any) as Vehicle).netSuiteStockNumber = item.netSuiteStockNumber;
    ((this as any) as Vehicle).unitName = item.onlineStockNumber;
    ((this as any) as Vehicle).asIs = true;

    ((this as any) as Vehicle).make = item.make;
    ((this as any) as Vehicle).model = item.model;
    ((this as any) as Vehicle).modelYear = item.year;
 
    this.physicalLocationSummary = {
      sid : item.storeLocation
    }

    this.physicalLocationType = PhysicalLocationType.prideLocation;
    if(item.GPS) ((this as any) as Vehicle).gpsSrNo = item.GPS;

    switch(this.productType){
      case ProductType.trailer:
       
        break; 
      case ProductType.truck:
        ((this as any) as Truck).engineModel = item.engine;
    
        const odometer = this.getOdometer(item.odometer);
        // prodEm.fuelType = lCVin.fuelType;
        ((this as any) as Truck).odometer =  odometer;
        ((this as any) as Truck).odometerUnits =  item.currency === 'CAD' ? OdometerUnitsType.km : OdometerUnitsType.mi;
        ((this as any) as Truck).horsepower = item.hp;
        ((this as any) as Truck).unitColor = item.color;
        ((this as any) as Truck).isUsed = odometer && odometer > 0 ? true : false;
        break;
    }
  }

        		/** Update all attached documents paths from /draft/ to /rel/ */
      updateFilePathToDraft(pictures, uid: string, oldP:string, newP:string, isReleaseMode: boolean = true): IFileCopyData[]{
        const R: IFileCopyData[] = [];
        let index = 0;
        if(!pictures || (pictures && !pictures.length)){
          this.pictures = [];
          return R;
        } 
    
        this.pictures = this.pictures ? this.pictures: [];
        for (const o of pictures) {
          const pict = new Picture();
          pict.path = o.name;
          pict.name = o.name.replace(/^.*[\\\/]/, '');
          pict.uploadedOn = new Date(o.metadata?.updated);
          let r = FileInfo.replaceFilePathCopyOp(o.name, oldP, newP);
          if (!!r) {
            pict.path = r.newPath;
            r.customMetadata = o.metadata && o.metadata?.metadata;
            if (!r.customMetadata) {
              r.customMetadata = { uid: uid, vin: newP, order: index + 1, visibility: true };
            }
            r.customMetadata['imageType'] = this.imageType;
            if (r.customMetadata['order']) r.customMetadata['order'] = Number(r.customMetadata['order']);
            if (r.customMetadata['visibility']) r.customMetadata['visibility'] = JSON.parse(r.customMetadata['visibility']);
            if (!!r.customMetadata['visibility'] || isReleaseMode) {
              r.customMetadata['visibility'] = (r.customMetadata['loc'] === 'VIN Sticker') ? r.customMetadata['visibility'] : true;
              R.push(r);
              pict.customFirebaseMetadata = r.customMetadata as CustomFirebaseMetadata;
              this.pictures.push(pict);
            }
    
          }
        }
        return R;
    
      }

  
}
