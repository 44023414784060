import { LogLevel } from './../log/ilog';
import { BaseModel } from '../base';
import { platform } from '../../core/environments/ienviorment';
import { plainToInstance } from 'class-transformer';
import { sanitizeDateIPoint } from '../utility';
import { IAgreement } from '../user-agreement';
import { logger } from '../log/logger';
import { IFinanceInput } from '../finance/finance-input/finance-input-handler';

// export interface IAppVersion {
//   versionWeb: string;
//   versionCap: string;
//   versionCdva: string;

//   minVersionWeb: string;
//   minVersionCap: string;
//   minVersionCdva: string;
// }

export interface IVersion {
  web: string;
  cordova: string;
  capacitor: string;
}

/**
 * This class is loaded from database and stored/synced globally everytime site is booted.
 */
export class AppSetting extends BaseModel {

  public static readonly collectionName = 'app-setting';

  logLevel = LogLevel.ALL;

  funcUrl: { [key: string]: string };

  /** This is information only. Showing the latest and greatest version of the site. */
  dbVersion: IVersion;


  /** This used be used for min compatibility check */
  dbVersionMin: IVersion;

  /** This latest agreement (e.g. privacy policy revision) used to check if user signed agreement is uptodate
   * key is agreement type (e.g. privacyPolicy)
   */
  agreementRev: { [key: string]: IAgreement };

  /** Cordova specific settings for gps tracking */
  cdvaGpsConfig: object; //  BackgroundGeolocationConfig;

  /** capacitor specific settings for gps tracking */
  capGpsConfig: object; //


  /** GPS configuration for the TransisterSoft GPS Plugin for IOS  */
  gpsConfigTsoftIos: object;

  /** GPS configuration for the TransisterSoft GPS Plugin for Android  */
  gpsConfigTsoftAndroid: object;

  finInput: IFinanceInput;

  public static parse(obj) {
    if (obj == null) { return null; }

    if (obj instanceof (AppSetting)) { return obj as AppSetting; }

    const m = plainToInstance<AppSetting, any>(AppSetting, sanitizeDateIPoint(obj));
    m.sanitize();
    return m;
  }

  /** Is left greater or equal then right */
  private isGreaterOrEqual(left: string, right: string) {
    try {
      const leftNum = +left.replace(/\.+/g, '');
      const rightNum = +right.replace(/\.+/g, '');
      return leftNum >= rightNum;
    } catch (error) {
      logger.log('[app-setting.ts] IsGreater Then Failed!!', error);
      return true;

    }
  }

  /**
   * compare hard coded version in the code with the version requirement from the datbase.
   * @param pform : web / cap / cdva
   * @param localVersion : hardcoded version in the code
   */
  isCurrentAppCompatible(pform: platform, localVersion: IVersion): boolean {

    switch (pform) {
      case 'web':
        return this.isGreaterOrEqual(localVersion.web, this.dbVersionMin.web);

      case 'capacitor':
        return this.isGreaterOrEqual(localVersion.capacitor, this.dbVersionMin.capacitor);

      case 'cordova':
        return this.isGreaterOrEqual(localVersion.cordova, this.dbVersionMin.cordova);
      default:
        return true;
    }
  }

}

