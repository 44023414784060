import { trigger, state, style, transition, animate, keyframes, query, group } from '@angular/animations';
/**
 * @author Cm
 */
export const Animations = {
    animeCarousel:trigger('carousel', [
        transition(':increment', [
            query(':enter, :leave', style({ position: 'fixed', width: '100%' }), { optional: true }),
            group([
              query(':enter', [style({ transform: 'translateX(100%)' }), animate('.3s ease-out', style({ transform: 'translateX(0%)' }))], {
                optional: true,
              }),
              query(':leave', [style({ transform: 'translateX(0%)' }), animate('.3s ease-out', style({ transform: 'translateX(-100%)' }))], {
                optional: true,
              }),
            ]),
          ]),
        transition(':decrement', [
            query(':enter, :leave', style({ position: 'fixed', width: '200px' }), { optional: true }),
            group([
              query(':enter', [style({ transform: 'translateX(-100%)' }), animate('.3s ease-out', style({ transform: 'translateX(0%)' }))], {
                optional: true,
              }),
              query(':leave', [style({ transform: 'translateX(0%)' }), animate('.3s ease-out', style({ transform: 'translateX(100%)' }))], {
                optional: true,
              }),
            ]),
          ]),
      ]),

    animeTrigger: trigger('animeTrigger', [
        state('in', style({ transform: 'translateY(0)' })),
        transition('void => *', [
            animate(700, keyframes([
                style({ opacity: 0, transform: 'translateY(-100%)', offset: 0 }),
                style({ opacity: 1, transform: 'translateY(25px)', offset: 0.3 }),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0 })
            ]))
        ])
    ]),

    animeSlideInOut: trigger('slideInOut', [
        state('true', style({
            overflow: 'hidden',
            height: '*',
            width: '100%'
        })),
        state('false', style({
            opacity: '0',
            overflow: 'hidden',
            height: '0px',
            width: '0%'
        })),
        transition('true => false', animate('400ms ease-in-out')),
        transition('false => true', animate('400ms ease-in-out'))
    ]),

    animeFade: trigger('fade', [
        transition('void => *', [
            style({ opacity: 0 }),
            animate('3000ms', style({ opacity: 1 })),
        ]),
        transition('* => void', [
            style({ opacity: 1 }),
            animate('3000ms', style({ opacity: 0 })),
        ]),
    ]),

    animeFlip: trigger('flipState', [
        state('active', style({
            transform: 'rotateY(179deg)'
        })),
        state('inactive', style({
            transform: 'rotateY(0)'
        })),
        transition('active => inactive', animate('500ms ease-out')),
        transition('inactive => active', animate('500ms ease-in'))
    ])



};