import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { sanitizeDateIPoint } from '@trent/models/utility';
import { Observable } from 'rxjs';
import { logger } from '@trentm/log/logger';
import { Query, Database, DatabaseReference, listVal, objectVal, query, ref, serverTimestamp, update, set } from '@angular/fire/database';
import { FirebaseApp } from '@angular/fire/app';
import { isArray } from 'lodash';

/** Do not inject this service in any of the component constructors. Use SingletonService Instead. */
@Injectable()
export class FirebaseRTDBService {
  /** modular firebase database. */
  //public dbM: Database;
  constructor(public app: FirebaseApp, public dbM: Database) {
    // this.dbM = getDatabase(this.app);
  }
  /// with Ids
  listWithIds$<T>(path: string, queryFn?: (r: DatabaseReference) => Query, options: { keyField?: string; } = {}) {
    const r = ref(this.dbM, path);
    const q = (typeof queryFn === 'function') ? queryFn(r) : query(r);

    return listVal(q, options)
      .pipe(map(data => isArray(data) ? data.map(x => x as T) : []));

    // return this.db.list(path, queryFn).snapshotChanges().pipe(map(actions => {
    //   return actions.map(a => {
    //     const data: T = sanitizeDateIPoint(a.payload.val());
    //     const id = a.payload.key;
    //     return { id, data }; // ... data does not work.
    //   });
    // }));
  }
  listWithIdsInjected$<T>(path: string, queryFn?: (r: DatabaseReference) => Query): Observable<T[]> {
    return this.listWithIds$(path, queryFn, { keyField: 'id' });
    // return this.db.list(path, queryFn).snapshotChanges().pipe(map(actions => {
    //   return actions.map(a => {
    //     const data = a.payload.val();
    //     const id = a.payload.key;
    //     (<any>data).id = id;
    //     return sanitizeDateIPoint(data);
    //   });
    // }));
  }
  objWithInjectedId$<T>(path: string, id?: string): Observable<T> {
    path = !!id ? `${path}/${id}` : path;
    id = !!id ? id : path.split('/').pop();

    const doc = ref(this.dbM, path);
    return objectVal(doc, { keyField: 'id'})
      .pipe(map(doc => {
        return sanitizeDateIPoint(doc) as T;
      }));

    // return this.db.object(path).valueChanges()
    //   .pipe(map(doc => {
    //     const t = doc;
    //     if (!!t) {
    //       // if (id == null) {
    //       //   id = (<any>doc).id;
    //       // }
    //       (<any>t).id = id;
    //     }
    //     return sanitizeDateIPoint(t) as T;
    //   }));
  }
  obj$<T>(path: string): Observable<T> {
    const doc = ref(this.dbM, path);
    return objectVal(doc)
      .pipe(map(doc => {
        return sanitizeDateIPoint(doc) as T;
      }));

    // return this.db.object(path).valueChanges()
    //   .pipe(map(doc => {
    //     const t = doc;
    //     // logger.log('sanitizeDateIPoint(t)', sanitizeDateIPoint(t));
    //     return sanitizeDateIPoint(t) as T;
    //   }));
  }
  // write fns

  /// Firebase RTDB Server Timestamp
  get timestamp() {
    return serverTimestamp(); //  fir
  }

  update<T>(path: string, data: any) {
    logger.log('update data: ', data);
    const r = ref(this.dbM, path);
    return update(r, {
      ...data,
      updatedAt: this.timestamp
    });

    // return this.db.object(path).update({
    //   ...data,
    //   updatedAt: this.timestamp
    // });
  }
  create<T>(path: string, data: any) {
    logger.log('create data: ', data);

    const r = ref(this.dbM, path);
    return set(r, {
      ...data,
      updatedAt: this.timestamp
    });
    
  }
}
