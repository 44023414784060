import * as entity from '@trent/store/entity.state';
import { BidService } from '../../services/bid.service';
import { Selector, State, Action, StateContext } from '@ngxs/store';
import { map } from 'rxjs/operators';
import { noop, Subscription } from 'rxjs';
import { BidParam, bidSearchClientFilter, getBidSearchOptOrChildren } from '../../models/bid/bid-param';
import { Paging, getObjKey } from '../../models/observable-util/paging';
import { PagingContainer } from '../../models/observable-util/paging-container';
import { StateBase } from '../state-base';
import { BidBase } from '../../models/bid/bid-base';
import {
  IDataLoadStatus, buildDataRequest, LoadStatus,
  updatePaging, getRootLevelChildren
} from '../../models/observable-util/data-status';
import { parseBid } from '@trent/models/bid/bid-helper';
import { Injectable } from '@angular/core';


// #region State Model
export interface BidStateModel {
  param?: BidParam;
  paging: Paging;
  allBidsLoaded: boolean;
  bidsByRentOptionIdLoaded: boolean;

  allBidsLoadStatus: {
    [key: string]: IDataLoadStatus<BidParam>;
  };
  bids: { [id: string]: BidBase }; // entity.EntityState<BidBase>;
  bidHistory: { [id: string]: BidBase }; // entity.EntityState<BidBase>;

}
function initBidState(): BidStateModel {
  return {
    param: null,
    paging: { size: 10, offset: 0, full: false, currentPage: 0, count: 300, maxPageReached: 0 },
    allBidsLoaded: false,
    bidsByRentOptionIdLoaded: false,
    allBidsLoadStatus: {},
    bids: {},
    bidHistory: {}
  };
}
// #endregion

// #region actions
export class AllBidsRequested {
  static readonly type = '[Bids] All Bids Requested';
  constructor(public payload: { pData: Paging, param: BidParam }) { }
}

export class TotalDocumentCount {
  static readonly type = '[Bids] Get Total Document Count';
  constructor(public payload: { param: BidParam }) { }
}

export class AllBidsLoaded {
  static readonly type = '[Bids] All Bids Loaded';
  constructor(public payload: {
    data: { [id: string]: BidBase },
    key: string,
  }) { }
}
export class AllBidsNextPageRequested {
  static readonly type = '[Bids] All Bids Requested - Next Page';
  constructor(public payload: { param: BidParam, pData: Paging }) { }
}
export class UpdatePaging {
  static readonly type = '[Bids] Update List Selection';
  constructor(public payload: { pData: Paging }) { }
}
export class BidRequested {
  static readonly type = '[Bids] Request a single bid entity';
  constructor(public payload: { id: string | number }) { }
}

export class BidsRequested {
  static readonly type = '[Bids] Request multiple bid entities';
  constructor(public payload: { ids: (string | number)[] }) { }
}
export class BidLoaded {
  static readonly type = '[Bids] Load a single bid entity';
  constructor(public payload: { data: BidBase }) { }
}
export class BidsLoaded {
  static readonly type = '[Bids] Load multiple bid entities';
  constructor(public payload: { data: BidBase[] }) { }
}
export class BidsByRentOptionIdRequested {
  static readonly type = '[Bids] Bids by rentOptionId Requested';
  constructor(public payload: { rentOptionId: string | number }) { }
}
export class BidsByRentOptionIdLoaded {
  static readonly type = '[Bids] Bids by rentOptionId Loaded';
  constructor(public payload: { bidsByRentOptionId: BidBase[] }) { }
}
export class BidsByCompIdRequested {
  static readonly type = '[Bids] Bids by Company Id Requested';
  constructor(public payload: { compId: string | number }) { }
}
export class BidsByCompIdLoaded {
  static readonly type = '[Bids] Bids by Company Id Loaded';
  constructor(public payload: { bidsByCompId: BidBase[] }) { }
}
export class AllBidHistoryLoaded {
  static readonly type = '[Bids] All Bid History Loaded';
  constructor(public payload: {
    data: { [id: string]: BidBase },
    key: string,
  }) { }
}
export class BidStateReset {
  static readonly type = '[Bids] Reset State';
}
// #endregion
@State<BidStateModel>({
  name: 'bid',
  defaults: initBidState()
})
@Injectable()
export class BidState extends StateBase {
  allBidReqSub: Subscription;
  allBidsSubData: PagingContainer<BidBase, BidParam> = new PagingContainer();


  constructor(private bidService: BidService) { super(); }
  // #region Static Selectors

  @Selector()
  static selectPaging(state: BidStateModel) {
    return state.paging;
  }

  @Selector()
  static selectAllBids(state: BidStateModel) {
    return (o: BidParam): BidBase[] => {
      if (state.bids == null || Object.keys(state.bids).length === 0) {
        return [];
      }
      // remove keys that have revision/draft ids, i.e that contain '~' in the id.
      const keys = Object.keys(state.bids).filter(k =>
        k.indexOf('/') === -1 && state.bids[k] != null);
      // object without the rev/draft.
      const filtered = keys.reduce((obj, k) => {
        obj[k] = state.bids[k];
        return obj;
      }, {});

      let output = Object.values(filtered).map(x => parseBid(x));
      output = bidSearchClientFilter(output, o);
      let start = +state.paging.currentPage * state.paging.size;
      let end = start + state.paging.size;
      output = output.slice(start, end);
      return output;
    };
  }

  @Selector()
  static selectBidsById(state: BidStateModel) {
    return (ids: (string | number)[]): BidBase[] => {
      if (state.bids == null || Object.keys(state.bids).length === 0) {
        return [];
      }
      const keys = Object.keys(state.bids).filter(k =>
        k.indexOf('/') === -1 && state.bids[k] != null);
      // object without the rev/draft.
      const filtered = keys.reduce((obj, k) => {
        obj[k] = state.bids[k];
        return obj;
      }, {});

      let output = Object.values(filtered).map(x => parseBid(x));
      output = output.filter(b => ids.includes(b.id));
      return output;
    };
  }

  /** Is the Paging Full for this search. */
  @Selector()
  static selectAllBidsFull(state: BidStateModel) {
    return (o: BidParam): boolean => {
      const oKey = getObjKey(o);
      const r = state.allBidsLoadStatus && state.allBidsLoadStatus[oKey];
      return (!!r) ? r.pData.full : true;
    };
  }
  @Selector()
  static selectBidById(state: BidStateModel) {
    return entity.getByIdFn_new(state.bids, parseBid);
  }
  @Selector()
  static selectBidsByRentOptionId(state: BidStateModel) {
    return (rentOptionId: string | number): BidBase[] => {
      return Object.values(state.bids)
        .filter(b => b.rentSummary.rid === rentOptionId)
        .map(m => parseBid(m));
    };
  }
  @Selector()
  static selectBidsByCompId(state: BidStateModel) {
    return (cid: string | number): BidBase[] => {
      return Object.values(state.bids)
        .filter(b => b.customerCompSummary.cid === cid)
        .map(m => parseBid(m));
    };
  }
  @Selector()
  static selectBidParam(state: BidStateModel) {
    return {
      ...state.param
    };
  }
  @Selector()
  static selectAllBidHistory(state: BidStateModel) {
    return (o: BidParam): BidBase[] => {

      if (state.bidHistory == null || Object.keys(state.bidHistory).length === 0) {
        return [];
      }
      // remove keys that have revision/draft ids, i.e that contain '~' in the id.
      const keys = Object.keys(state.bidHistory).filter(k =>
        k.includes('/') && state.bidHistory[k] != null);
      // object without the rev/draft.
      const filtered = keys.reduce((obj, k) => {
        obj[k] = state.bidHistory[k];
        return obj;
      }, {});

      let output = Object.values(filtered).map(x => parseBid(x));
      output = bidSearchClientFilter(output, o);

      return output;
    };
  }
  // #endregion

  // #region Custom Functions and subscriptions
  public clearSubscriptions() {
    if (!!this.allBidReqSub) {
      this.allBidReqSub.unsubscribe();
      this.allBidReqSub = null;
    }
    this.allBidsSubData.unsubscribeAll();
    super.clearSubscriptions();
  }

  @Action(BidStateReset)
  bidStateReset(context: StateContext<BidStateModel>, action: BidStateReset) {
    // unsubscribe the data
    console.log('RentalProductStateReset action called');
    this.clearSubscriptions();
    context.setState(initBidState());
  }

  // #endregion


  @Action(AllBidsRequested)
  allBidsRequested(context: StateContext<BidStateModel>, action: AllBidsRequested) {
    const oKey = getObjKey(action.payload.param);
    const state = context.getState();
    // Define function that return the data status object from the state.
    const getDataStatusStateFn = () => context.getState().allBidsLoadStatus;

    /** custom build the OR children query. */
    const buildOrQueryChildrenFn = (o: BidParam) => getBidSearchOptOrChildren(o);
    // if data requested now, is already partially loaded by another query's child previously
    // but the number of the items are not enough (ex. as a child, it loaded only 5, but current
    // request ask for more, then next page of that query should be called instead.)
    const nextPageFn = (param: BidParam) => {
      context.dispatch(new AllBidsNextPageRequested({ param, pData: state.paging }));
    };
    buildDataRequest(
      oKey, action.payload.param, action.payload.pData,
      getDataStatusStateFn,
      buildOrQueryChildrenFn,
      nextPageFn,
      (
        obj: { [key: string]: IDataLoadStatus<BidParam> },
        set: { key: string, node: IDataLoadStatus<BidParam> }[]
      ) => {
        if (!!obj) {
          // Patch the state.
          const state = context.getState();
          context.patchState({
            allBidsLoadStatus: { ...state.allBidsLoadStatus, ...obj }
          });
        }
        // Process the query.
        set.forEach((val) => {
          // some of the nodes are already loaded. Only process that are loading... status.
          if (val.node.loadStatus !== LoadStatus.Loading) {
            return;
          }
          // if this request is just overwriting a stall or pending request, unsubscribe that observable
          this.allBidsSubData.unsubscribe(val.key);

          console.log('New Service sub created: ', val);
          const b = this.bidService.getAllBids_PagingObservable();
          // create the paging observable and call db.
          b.getCount(action.payload.pData, action.payload.param).subscribe(count => {
            context.patchState({
              paging: {
                ...state.paging,
                count
              }
            });
            //debugger;
            const bid$ = b.getData(action.payload.pData, val.node.param)
              .pipe(
                map(bids => {
                  if (!val.node.param.bidIdForHistory) {
                    context.dispatch(new AllBidsLoaded({
                      data: bids,
                      key: val.key
                    }));
                  } else {
                    context.dispatch(new AllBidHistoryLoaded({
                      data: bids,
                      key: val.key
                    }));
                  }
                  return bids;
                }));
            const sub = this.subscribe(bid$, () => noop(), AllBidsRequested.type);
            this.allBidsSubData.addData(val.key, sub, b);
          });
        }
        );
      });
  }

  @Action(AllBidsLoaded)
  allBidsLoaded(context: StateContext<BidStateModel>, action: AllBidsLoaded) {
    const state = context.getState();
    const subData = this.allBidsSubData.getData(action.payload.key);
    const updatedLoadStatus = updatePaging(action.payload.key, state.allBidsLoadStatus, subData);
    context.patchState({
      allBidsLoaded: true,
      allBidsLoadStatus: updatedLoadStatus,
      bids: { ...state.bids, ...action.payload.data } // entity.addMany(state.bids, action.payload.bids)
    });
  }

  @Action(UpdatePaging)
  updatePaging(context: StateContext<BidStateModel>, action: UpdatePaging) {
    const state = context.getState();
    let pData: Paging = action.payload.pData;
    context.patchState(
      {
        paging: pData
      }
    );
  }


  @Action(AllBidsNextPageRequested)
  allBidsNextPageRequested(context: StateContext<BidStateModel>, action: AllBidsNextPageRequested) {
    const oKey = getObjKey(action.payload.param);
    const state = context.getState();
    context.dispatch(new UpdatePaging({
      pData:
      {
        ...action.payload.pData,
        maxPageReached: action.payload.pData.currentPage
      }
    }));
    // find the node. can be parent or child
    const statusObj = state.allBidsLoadStatus[oKey];
    // if no parent, treat is
    if (statusObj.children == null) {
      this.allBidsSubData.dispatchNextPagingUpdate(oKey);
    } else {
      const children = getRootLevelChildren(oKey, state.allBidsLoadStatus);
      children.forEach(c => {
        this.allBidsSubData.dispatchNextPagingUpdate(c.key);
      });
    }
  }

  @Action(BidRequested)
  bidRequested(context: StateContext<BidStateModel>, action: BidRequested) {
    const state = context.getState();
    console.log('requested Bid is not in the store. sending request to server', action.payload.id);
    this.bidService.getBidById(action.payload.id)
      .pipe(
        map(data => {
          // console.log('from server: retal map was called', data);
          return context.dispatch(new BidLoaded({ data }));
        }
        )).subscribe(noop);
  }

  @Action(BidsRequested)
  bidsRequested(context: StateContext<BidStateModel>, action: BidsRequested) {
    const state = context.getState();
    this.bidService.getBidsById(action.payload.ids)
      .pipe(
        map(data => {
          return context.dispatch(new BidsLoaded({ data }));
        }
        )).subscribe(noop);
  }
  @Action(BidLoaded)
  bidLoaded(context: StateContext<BidStateModel>, action: BidLoaded) {
    const state = context.getState();
    const b = {};
    b[action.payload.data.id] = action.payload.data;

    context.patchState({
      bids: { ...state.bids, ...b } // entity.update(state.bids, action.payload.data)
    });
  }

  @Action(BidsLoaded)
  bidsLoaded(context: StateContext<BidStateModel>, action: BidsLoaded) {
    const state = context.getState();
    const b = {};
    const bids = action.payload.data;
    bids.forEach(bid => {
      b[bid.id] = bid;
    });
    context.patchState({
      bids: { ...state.bids, ...b } // entity.update(state.bids, action.payload.data)
    });
  }
  @Action(AllBidHistoryLoaded)
  allBidHistoryLoaded(context: StateContext<BidStateModel>, action: AllBidHistoryLoaded) {
    const state = context.getState();
    const subData = this.allBidsSubData.getData(action.payload.key);
    const updatedLoadStatus = updatePaging(action.payload.key, state.allBidsLoadStatus, subData);
    context.patchState({
      allBidsLoaded: true,
      allBidsLoadStatus: updatedLoadStatus,
      bidHistory: { ...state.bidHistory, ...action.payload.data } // entity.addMany(state.bids, action.payload.bids)
    });
  }
  // @Action(BidsByRentOptionIdRequested)
  // bidsByRentOptionIdRequested(context: StateContext<BidStateModel>, action: BidsByRentOptionIdRequested) {
  //   const state = context.getState();
  //   if (state.bids.data.find(x => x.id === action.payload.rentOptionId) == null) {
  //     console.log('id', action.payload.rentOptionId);

  //     this.bidService.getBidsByRentOptionId(action.payload.rentOptionId)
  //       .pipe(
  //         map(bidsByRentOptionId => {
  //           console.log('map was called');
  //           return context.dispatch(new BidsByRentOptionIdLoaded({ bidsByRentOptionId }));
  //         }
  //         )).subscribe(noop);
  //   } else { console.log('requested bids are available in the store'); }
  // }
  // @Action(BidsByRentOptionIdLoaded)
  // bidsByRentOptionIdLoaded(context: StateContext<BidStateModel>, action: BidsByRentOptionIdLoaded) {
  //   const state = context.getState();
  //   context.patchState({
  //     bidsByRentOptionIdLoaded: true,
  //     bids: entity.addMany(state.bids, action.payload.bidsByRentOptionId)
  //   });
  // }
  // @Action(BidsByCompIdRequested)
  // bidsByCompIdRequested(context: StateContext<BidStateModel>, action: BidsByCompIdRequested) {
  //   const state = context.getState();
  //   if (state.bids.data.find(x => x.id === action.payload.compId) == null) {
  //     this.bidService.getBidsByCompId(action.payload.compId)
  //       .pipe(
  //         map(bidsByCompId => {
  //           console.log('map was called');
  //           return context.dispatch(new BidsByCompIdLoaded({ bidsByCompId }));
  //         }
  //         )).subscribe(noop);
  //   } else { console.log('requested bids are available in the store'); }
  // }
  // @Action(BidsByCompIdLoaded)
  // bidsByCompIdLoaded(context: StateContext<BidStateModel>, action: BidsByCompIdLoaded) {
  //   const state = context.getState();
  //   context.patchState({
  //     allBidsLoaded: false,
  //     bids: entity.addMany(state.bids, action.payload.bidsByCompId)
  //   });
  // }
}
