import { CompanyBase, CompanyValidationGroup, CompanySummaryBase } from './company-base';
import { Exclude, Expose, plainToInstance, instanceToInstance } from 'class-transformer';
import { IValidationMsg, IValDbStatusResult, IValidate } from '../error-handling/validation-info';
import { ValidatorOptions, IsNumber } from 'class-validator';
import { FileInfo, IFileMoveData } from '../media/file-info';
import { CompanyType } from './company-type';
import { sanitizeDateIPoint } from '../utility';
import { ContactCard } from '../user/contact-card';
import { Address } from '../address/address';
import { DbStatus } from '../base/db-status';
import { logger } from '../log/logger';


// tslint:disable-next-line:no-empty-interface
export interface DriverCompanySummary extends CompanySummaryBase {
  officer: ContactCard[];
}

const legal: CompanyValidationGroup = 'legal';
const documents: CompanyValidationGroup = 'documents';
const accountDetails: CompanyValidationGroup = 'accountDetails';
const misc: CompanyValidationGroup = 'misc';


/** Fleet Providers */
@Exclude()
export class CompanyDriver extends CompanyBase implements IValidate {

  @Expose()
  @IsNumber()
  nDriver = 0;

  constructor() {
    super();
    // this.cType = CompanyType.DriverProvider;
    this.companyType = CompanyType.DriverProvider;
    FileInfo.updateFileSecurity(this, true);
  }
  /** Convert all GeoPoint class instances to IPoint. required before parsing data coming back
   * from firebase. This must be called BEFORE PARSE and not AFTER PARSE
   * @param data Data to be parsed
   * @param type type to be used to decipher latitude/longitude. This is needed because at client the type is : firebase.firestore.GeoPoint
   * and at function it is admin.firestore.GeoPoint they are two different types.
   * https://github.com/Microsoft/TypeScript/wiki/FAQ#why-cant-i-write-typeof-t-new-t-or-instanceof-t-in-my-generic-function
   */
  public static parse(obj) {
    // public static parse<T extends IPoint>(obj, ipointType?: { new(...args: any[]): T }) {
    try {
      if (obj == null) { return null; }
      // obj = sanitizeDateIPoint(obj, ipointType);
      obj = sanitizeDateIPoint(obj);
      const m = plainToInstance<CompanyDriver, any>(CompanyDriver, obj);
      m.sanitize();
      if (m.contact == null) { m.contact = new ContactCard(); }
      if (m.aOI == null) { m.aOI = new FileInfo(); }
      if (m.voidCheque == null) { m.voidCheque = new FileInfo(); }
      if (m.address == null) { m.address = new Address(); }

      // Make all files in this class as secure url.
      FileInfo.updateFileSecurity(this, true);
      return m;
    } catch (error) {
      logger.log('Error happened during parse', error);
      return null;
    }
  }

  public getDriverCompanySummary(c: CompanyDriver): DriverCompanySummary {
    return {
      cid: `${c.id}`,
      legalName: c.legalName,
      address: c.address,
      officer: c.officer,
      dbStatus: c.dbStatus,
      revId: c.revId
    };
  }
  clone() {
    const t = instanceToInstance(this);
    t.sanitize();
    return t;
  }
  sanitize() {
    // if data was recieved from firebase, date is stored as snapshot.
    super.sanitize();
    // return this;
  }
  //

  /** Update all attached documents paths from /draft/ to /rel/ */
  updateFilePathsToRelease(): IFileMoveData[] {
    const R: IFileMoveData[] = [];
    let r = FileInfo.updatePathToRelease(this.aOI);
    r = FileInfo.updatePathToRelease(this.voidCheque);
    if (!!r) { R.push(r); }

    return R;
  }

  validateSyncGroup(group: CompanyValidationGroup): IValidationMsg {
    return this.validateSync({ groups: [group] });
  }
  validateSync(options?: ValidatorOptions): IValidationMsg {

    // for nested entry for address, add address group in it.
    if (!!options && !!options.groups && options.groups.indexOf(legal) > -1) {
      options.groups.push(Address.gName);
      options.groups.push(ContactCard.gName);
    }

    const r = this.validateSyncBase(this, options);

    // Insurance expiry data must be in the future.
    // if (!!options) {

    // if (isDate(this.insExpiryDate) && options.groups.indexOf(insurance) !== -1) {
    //   if (isDate(this.insExpiryDate)) {

    //     if (isAfter(new Date(), this.insExpiryDate)) {
    //       r['insExpiryDate'] = ['Insurance expiry date must be in future!'];
    //     }
    //     // insExpiryDate data must within a year
    //     if (isAfter(this.insExpiryDate, addAYear(1))) {
    //       r['insExpiryDate'] = ['Insurance expiry date must be within a year'];
    //     }

    // }

    return r;
  }
  /** Analyze company to see if more information is needed or suggest the user
   * to submit for approval.
   */
  getValDbStatus(): IValDbStatusResult {
    const result: IValDbStatusResult = {
      pass: false,
      message: undefined,
      groupResult: {}
    };    //  { [group: string]: { result: boolean; message: string; } } = {};
    let x: { groupPass: boolean; message: string; };

    // Legal
    let r = this.validateSyncGroup(legal);
    x = { groupPass: null, message: '' };
    x.groupPass = Object.keys(r).length === 0;
    x.message = (x.groupPass) ? 'Manage Legal Information' : 'Legal information is required';
    result.groupResult[legal] = x;

    // Documents
    r = this.validateSyncGroup(documents);
    x = { groupPass: null, message: '' };
    x.groupPass = Object.keys(r).length === 0;
    x.message = (x.groupPass) ? 'Manage Required Documents' : 'Documents information is required';
    result.groupResult[documents] = x;

    // accountDetails
    r = this.validateSyncGroup(accountDetails);
    x = { groupPass: null, message: '' };
    x.groupPass = Object.keys(r).length === 0;
    x.message = (x.groupPass) ? 'Manage Required Accouting Details' : 'Accouting information is required';
    result.groupResult[accountDetails] = x;

    // Misc
    r = this.validateSyncGroup(misc);
    x = { groupPass: null, message: '' };
    x.groupPass = Object.keys(r).length === 0;
    x.message = (x.groupPass) ? 'Manage Required Fleet Information' : 'Fleet Information information is required';
    result.groupResult[misc] = x;

    // is it passed all the tests? (True means it failed here)
    result.pass = !Object.keys(result.groupResult).some((k) => !result.groupResult[k].groupPass);

    // passed.
    if (!result.pass) {
      if (this.dbStatus === DbStatus.Initial || this.dbStatus === DbStatus.ReleasedMod) {
        result.message = 'Admin approval is pending!';
      }
    } else {
      result.message = 'More information is required!';
    }

    return result;
  }
}
