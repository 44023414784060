import { CurrencyPipe } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Store } from '@ngxs/store';
import { MileageType } from '@trent/models/rental/milage-type';
import { RentalPlan } from '@trent/models/rental/rental-plan';
import { RentalTerm } from '@trent/models/rental/rental-term';
import { BaseForm } from '@trent/models/UI/base-form';
import { DialogService } from '@trent/services/dialog/dialog.service';
import { EventService } from '@trent/services/event.service';
import { UtilityService } from '@trent/services/utility.service';
import { ValidationOptions } from 'class-validator/types/decorator/ValidationOptions';
import { logger } from '../../../../../../libs/models/log/logger';

@Component({
  selector: 'trent-rental-plan',
  templateUrl: './rental-plan.component.html',
  styleUrls: ['./rental-plan.component.scss']
})
export class RentalPlanComponent extends BaseForm<RentalPlan> implements OnInit, OnDestroy, AfterViewInit {

  mileageTypeEnum = MileageType;
  @Input()
  rPlan: RentalPlan;
  @Input()
  selectPlan: boolean;
  @Input()
  isEMode: boolean;

  @Input()
  showRemove: boolean;
  @Input()
  index: number;
  @Input()
  rTerm: RentalTerm;
  @Input()
  hideSubTitle: boolean;
  @Input()
  isContractStarted: boolean;
  @Input()
  currency: 'USD' | 'CAD';
  
  @ViewChild('rentalPlanForm', { static: false }) rentalPlanForm: NgForm;
  @Output() planSelectionChange = new EventEmitter();
  @Output() mileageTypeChange = new EventEmitter();

  constructor(
    store: Store,
    dialogService: DialogService,
    private currencyPipe:CurrencyPipe,

    es: EventService) {
    super(store, dialogService, es);
  }

  ngOnInit(): void {
    if (!!this.rPlan) { this.initModel(); }

  }
  ngAfterViewInit(): void {
    this.setUpFormValidation();

  }

  ngOnDestroy(): void {
    // throw new Error('Method not implemented.');
    this.cleanListeners(false, false, false, false);
  }
  setUpFormValidation() {
    setTimeout(() => {
      this.setFormValidation<RentalPlan>(RentalPlan, this.rentalPlanForm, () => {
        const option: ValidationOptions = {
          groups: []
        };
        return { model: this.em, option };
      });
    }, 1000);
  }
  initModel() {
    this.m = this.rPlan;
    this.em = this.rPlan;
    logger.info('[rental-plan] component', this.em);


  }
  openHelp(s?: string) {

  }
  get milageTypeName() {
    return 'mileageType' + this.em.rentalTerm;
  }
  get selectedName() {
    return 'isSelected' + this.em.rentalTerm;
  }
  mileageTypeChangeFn() {
    this.setUpFormValidation();
    this.mileageTypeChange.emit();
  }
  toCurrency(n: number) {
    if(!!n){
      return this.currencyPipe.transform(n, this.currency, this.currency);
    }
  }
}
