import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { IcloudMessageService } from './icloud-message.service';
import { CloudMessageCoreService } from './cloud-message-core.service';
import { Store } from '@ngxs/store';
import { IAuthCoreService } from '../auth/iauth-core.service';
import { FirestoreService } from '../firestore.service';
import { LocalStorage } from '../local-storage/local-storage.service';
import { IdeviceIdService } from '../device/idevice-id.service';
import { logger } from '@trentm/log/logger';
import { ActionPerformed, PushNotifications, PushNotificationSchema, Token } from '@capacitor/push-notifications';


// import {
//   PushNotification,
//   PushNotificationToken,
//   PushNotificationActionPerformed
// } from '@capacitor/core';

// const { PushNotifications } = Plugins;

/** Do not inject this service in any component. Use @class IcloudMessageService instead.
 * Or better yet, use the @class SingletonService's public property. This this class is singlton also but
 * it is not imported at the app level to reduce the laods. Singlton class has the only copy in it.
 */
@Injectable()
export class CloudMessageCapService extends CloudMessageCoreService implements IcloudMessageService {
  constructor(
    store: Store,
    auth: IAuthCoreService,
    afs: FirestoreService,
    localStorage: LocalStorage,
    deviceIdService: IdeviceIdService,
    router: Router
  ) {
    super(auth, store, localStorage, afs, deviceIdService, router);
  }

  private permissionRcvd = false;

  requestNotificationPermission() {
    return new Promise<boolean>((resolve, reject) => {

      logger.log('[fcm] Registering capacitor fcm');

      if (this.permissionRcvd) {
        logger.log('[fcm] Permission is already received');
        resolve(true);
        return;
      }

      // Request permission to use push notifications
      // iOS will prompt user and return if they granted permission or not
      // Android will just grant without prompting
     (<any> PushNotifications).requestPermission().then(result => {
        logger.log('[fcm] requestPermissions asked. Results are: ', result);
        logger.log('[fcm] requestPermissions asked. Results are: ', !!result?.results ? JSON.stringify(result) : 'null');

        // if (result..granted) {
        // Register with Apple / Google to receive push via APNS/FCM
        logger.log('[fcm] Permission is already received');
        PushNotifications.register();
        // } else {
        // Show some error

        // }
      }).catch(err => {
        logger.error('[fcm] Permission DENIED or error occoured', err);
      });


      // Register with Apple / Google to receive push via APNS/FCM
      // PushNotifications.register();

      // On success, we should be able to receive notifications
      PushNotifications.addListener('registration',
        (token: Token) => {
          // alert('FCM registration completed');
          logger.log('[fcm] Cap Push registration success, token data: ' + JSON.stringify(token));
          this.fcmToken = token.value;
          this.updateFcmToken();
          this.permissionRcvd = true;
          resolve(true);
        }
      );

      // Some issue with our setup and push will not work
      PushNotifications.addListener('registrationError',
        (error: any) => {
          const e = (typeof (error) === 'object') ? JSON.stringify(error) : error;
          logger.log('[fcm] Error on fcm cap registration: ', e);
          reject(error);
        }
      );
      // Show us the notification payload if the app is open on our device
      PushNotifications.addListener('pushNotificationReceived',
        (notification: PushNotificationSchema) => {
          logger.log('[fcm] Notification message, app already open: ' + JSON.stringify(notification));
          this.onMessageReceived(notification as any);
        }
      );

      // Method called when tapping on a notification
      PushNotifications.addListener('pushNotificationActionPerformed',
        (notification: ActionPerformed) => {
          logger.log('[fcm] Notification action, app was closed: ' + JSON.stringify(notification));
          this.onMessageReceived(notification as any);
        }
      );
    });

  }

  async refreshToken() { }
}
