
class HammerFake {
  public static DIRECTION_HORIZONTAL = 2;

  on(eventName: string, callback?: Function) {
  };

  off(eventName: string, callback?: Function) {
  }
  constructor(...arg: any[]) {
  }
}

let Hammer = undefined;

export const setHammer = (h: any) => {
  Hammer = h;
  // console.log('[Hammer] ', h);
};

export const getHammer = () => {
  if (typeof Hammer === 'undefined')
    return HammerFake;
  else
    return Hammer;
};


