import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsModule } from '@ngxs/store';
import { InspectionState } from './inspection.state';
import { InspectionService } from '@trent/services/inspection.service';


@NgModule({
    imports: [
      CommonModule,
      NgxsModule.forFeature([InspectionState])
    ],
    providers: [
      InspectionService,
      ]
  })
  export class InspectionStoreModule { }
