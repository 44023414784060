import { AuthState } from '../store/auth-store/auth.state';
import { ValidatorOptions } from 'class-validator';
import { Injectable } from '@angular/core';
import { FirestoreService } from '@trent/services/firestore.service';
import { map, tap } from 'rxjs/operators';
import { instanceToPlain } from 'class-transformer';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { BaseHttpService } from '@trent/services/base-http.service';
import { Store } from '@ngxs/store';
import { logger } from '@trent/models/log/logger';
import { Paging } from '@trent/models/observable-util/paging';
import { PagingObesrvable } from '@trent/models/observable-util/paging-obesrvable';
import { Observable } from 'rxjs';
import { PtsPriceQuote } from '@trent/models/pts/pts-price-quote';
import { PriceQuoteParam, priceQuoteSearchServerQuery } from '@trent/models/pts/pts-price-quote-param';

@Injectable()
export class PriceQuoteService extends BaseHttpService {

	constructor(store: Store, private db: FirestoreService, private http: HttpClient) {
		super(store);
		this.apiName = 'api';
	}

	public create(priceQuote: PtsPriceQuote) {
		// Prepare the post data
		const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.token });
		return this.http.post<{ id: string | number, salesProduct: PtsPriceQuote }>(this.getApiUrl('/price-quote/create'),
			// Server validation before creating the entry.
			{
				priceQuote: instanceToPlain(priceQuote),
			},
			{ headers: headers })
			.pipe(
				tap(res => console.log('response: ', res))
			);
	}

	public update(priceQuote: PtsPriceQuote, option?: ValidatorOptions) {
		// Prepare the post data
		const headers = this.addBearerToken();
		// Server validation before creating the entry.
		return this.http.post<{ PriceQuote: PtsPriceQuote }>(this.getApiUrl('/price-quote/update'),
			{
				priceQuote: instanceToPlain(priceQuote),
				option: option
			},
			{ headers: headers })
			.pipe(
				tap(r => logger.log('response: ', r)),
			);
	}

	getNewDocId() {
		return this.db.docId(PtsPriceQuote.collectionName);
	}

	public getAllPriceQuotes() {

		// Get store locations
		const c = this.store.selectSnapshot(AuthState.userClaim);
		logger.log('default store is: ', Object.keys(c.cClaim.cRoles));
		return this.db.colWithIdsInjected$<PtsPriceQuote>(PtsPriceQuote.collectionName);
	}

	getPriceQuoteById(id: string | number) {
		return this.db.docWithInjectedId$<PtsPriceQuote>(
			`${PtsPriceQuote.collectionName}/${id}`, id);
	}

	/**
	 * get all companies (admin request)
	 */
	public getAllPriceQuotes_PagingObservable() {
		const p$: PagingObesrvable<PtsPriceQuote, PriceQuoteParam> =
			new PagingObesrvable<PtsPriceQuote, PriceQuoteParam>(this.db,
				(p: Paging, param?: PriceQuoteParam) => this.getAllPriceQuotes_batch(p, param));
		return p$;
	}

	/**
	* Get the next batch of the data from server.
	* @param p paging information.
	*/
	private getAllPriceQuotes_batch(p: Paging, param: PriceQuoteParam): Observable<{ [key: string]: PtsPriceQuote }> {
		if (param.orderBy == null) { param.orderBy = 'updatedAt'; }
		if (param.orderDirection == null) { param.orderDirection = 'desc'; }
		return this.db
			.colWithIdsInjectedNew$<PtsPriceQuote>(`${PtsPriceQuote.collectionName}`, ref => priceQuoteSearchServerQuery(ref, param, p))
			.pipe(
				tap(arr => {
					if (arr == null || arr.length === 0) {
						logger.log('All data is recevied, Paging is FULL');
						p.full = true;
					} else {
						p.lastDoc = arr[arr.length - 1];
					}
				}),
				map(arr => {
					// logger.log('From Server, before mapping is applied: ', arr);

					const arrayAsObj = arr.reduce((acc, cur) => {
						const id = cur.id;
						const data = cur;
						return { ...acc, [id]: data };
					}, {});

					// logger.log('after converting to array object dic ', arrayAsObj);
					return arrayAsObj;
				})
			);
	}
}