import { FileUploadSingle } from './file-upload-single';
import { Observable, Subject, combineLatest } from 'rxjs';
import { take, map, tap } from 'rxjs/operators';
import { Picture } from '@trent/models/inspection/picture';
import { BucketName } from '@trent/models/server-storage/i-strorage-metadata';
import { getDownloadURL, getStorage, ref, Storage, StorageReference, uploadBytesResumable, UploadMetadata, getMetadata } from '@angular/fire/storage';

// declare const baguetteBox: any;
export interface ImageData  {
  picIndex?: number;
  file?: File;
  picture?: Picture;
}

/** Utility class for uploading the multiple files using Firebase Storage. */
export class FileUploadMult {

  private percentageSub: Subject<number>;
  percentage$: Observable<number>;

  errorStr: string[] = [];

  /** File File name to be modified by appending a time string in the begining.
   * This is to ensure there is no duplicate overwrite and file name remains unique. */
  prefixTime = false;  // was true. Calling fn to prefix time.

  // Dispatcher of the outcome of results. true if success, false if there is an error
  // private uploadResultSub: Subject<boolean>;
  uploadResult$: Observable<boolean>;
  results: any[];

  bucketName: BucketName = 'appspot.com';

  constructor(private storage: Storage) {

    this.percentageSub = new Subject();
    this.percentage$ = this.percentageSub.asObservable();
  }

  // uploadFiles<T>(path: string[] | string, files: File[], metadata: T[] = []) {
  uploadFiles<T>(path: string[] | string, images: ImageData[], metadata: T[] = [], bucketPred?: string) {

    const files = images.map(f => f.file);
    const p = (typeof (path) === 'string') ? [`${path}`] : <string[]>path;
    while (p?.length < files.length) {
      p.push(p[p.length - 1]);
    }

    const fuploads: FileUploadSingle[] = [];
    const _percentages: Observable<number>[] = [];
    const _uploadResults$: Observable<boolean>[] = [];
    const n = 1.0 * files.length;
    for (let i = 0; i < files.length; i++) {
      const f = files[i];
      const fu = new FileUploadSingle(this.storage);
      fu.prefixTime = this.prefixTime;
      const name = images.map(a => a.picture)[i]?.name;
      fu.prepUpload(p[i], f, this.bucketName, metadata[i], name);
      _percentages.push(fu.percentage$);
      _uploadResults$.push(fu.uploadResult$);
      fuploads.push(fu);
    }


    // subscribe accumulated result.
    this.uploadResult$ = combineLatest(_uploadResults$)
      .pipe(
        take(1),
        tap(() => {
          for (let idx = 0; idx < fuploads.length; idx++) {
            const fup = fuploads[idx];
            const img = images[idx];
            if (img.picture == null) {
              img.picture = new Picture();
            }
            img.picture.url = fup.downloadUrl;
            img.picIndex = img.picIndex;
            img.picture.locId = img.picture.locId;
            img.picture.path = fup.finalPath;
          }
        }),
        map(x => x.every(y => y)));

    this.percentage$ = combineLatest(_percentages)
      .pipe(map(x => x.reduce((accum, curr) => accum + curr / n, 0)));
  }
}
