import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsModule } from '@ngxs/store';
import { TaskState } from './task.state';
import { ITaskService } from '@trent/services/task/i-task.service';
import { TaskRTDBService } from '@trent/services/task/task-rtdb.service';
// import { TaskService } from '@trent/services/task.service';


@NgModule({
  imports: [
    CommonModule,
    NgxsModule.forFeature([TaskState])
  ],
  providers: [
    // TaskService,
    { provide: ITaskService, useClass: (true) ? TaskRTDBService : null }
  ]
})
export class TaskStoreModule { }
