import { StateBase } from '../state-base';
import { State, StateContext, Action, Selector } from '@ngxs/store';
import { map } from 'rxjs/operators';
import { noop } from 'rxjs';
import { Injectable } from '@angular/core';
import { getObjKey, Paging } from '@trent/models/observable-util/paging';
import { logger } from '@trentm/log/logger';
import { buildDataRequest, getRootLevelChildren, IDataLoadStatus, LoadStatus, updatePaging } from '@trent/models/observable-util/data-status';
import { PagingContainer } from '@trent/models/observable-util/paging-container';
import { getILegacyContracts, ILegacyContractParam, legacyContractSearchAlgolia, legacyContractSearchClientFilter, parseLegacyContract } from '@trent/models/legacy-contract';
import { LegacyContractService } from '@trent/services/legacy-contract.service';
import { LCDates, LegacyContractBase } from '@trent/models/legacy-contract/legacy-contract-base';
import * as entity from '@trent/store/entity.state';
import { AlgoliaSearchService } from '@trent/services/algolia-search.service';
import { updateNestedDateValueToFirebaseTimeStamp } from '@trent/models/utility';

// #region State Model

export interface LegacyContractStateModel {

  // New approach with paging
  lCLoaded: boolean;
  lC: { [id: string]: LegacyContractBase };  // changed
  /** Which client side queries are complete */
  dataLoadStatus: {
    [key: string]: IDataLoadStatus<ILegacyContractParam>;
  };
  paging: Paging;
  allLCLoaded: boolean;
  lCs: { [id: string]: LegacyContractBase }; // entity.EntityState<BidBase>;
  lCHistory: { [id: string]: LegacyContractBase }; // entity.EntityState<BidBase>;
  lCsFile: LegacyContractBase[];
  totalCount?: number;
}

function initLCState() {
  return {
    lCLoaded: false,
    lC: {},
    allLCLoaded: false,
    dataLoadStatus: {},
    lCs: {},
    lCHistory: {},
    lCsFile: [],
    paging: { size: 10, offset: 0, full: false, nextClicked: false, prevClicked: false, startAt: "", endBefore: "", count: 0, maxPageNo: -1, maxPageReached: -1 },
  };
}

// #region Actions for new data methods with paging

export class GetLegacyContractRequested {
  static readonly type = '[Legacy-Contract] Get Legacy Contracts for user - requested ( initial page)';
  constructor(public payload: { pData: Paging; param: any }) { }
}

export class GetLegacyContractsRequestedNextBatch {
  static readonly type = '[Legacy-Contract] Get Legacy Contracts for user - requested (next page)';
  constructor(public payload: { pData: Paging; param: any }) { }
}

export class GetLegacyContracts {
  static readonly type = '[Legacy-Contract] Get Legacy Contracts for user - loaded (paged)';
  constructor(public payload: { data: { [id: string]: LegacyContractBase } }) { }
}

export class LegacyContractUpdated {
  static readonly type = '[Legacy-Contract] Legacy Contract Updated';
  constructor(public payload: { id: string | number, lC: LegacyContractBase }) { }
}

export class LegacyContractRequested {
  static readonly type = '[Legacy-Contract] Request a single Legacy Contract';
  constructor(public payload: { id: string | number }) { }
}
export class LegacyContractLoaded {
  static readonly type = '[Legacy-Contract] Load a single Legacy Contract entity';
  constructor(public payload: { id: string | number, data: LegacyContractBase }) { }
}

export class LegacyContractStateReset {
  static readonly type = '[Legacy-Contract] Reset State';
}
export class LegacyContractRemoveById {
  static readonly type = '[Legacy-Contract] Remove by Id';
  constructor(public payload: { leaseNo: string | number }) { }
}
export class LegacyContractsRequested {
  static readonly type = '[Legacy-Contract] All Legacy Contracts Requested';
  constructor(public payload: { pData: Paging, param: ILegacyContractParam }) { }
}
export class LegacyContractsLoaded {
  static readonly type = '[Legacy-Contract] All Legacy Contracts Loaded';
  constructor(public payload: {
    data: { [id: string]: LegacyContractBase }, // Data
    key: string,
    page?: string | number | Date
    isSearchApplied?: boolean
  }) { }
}
export class LegacyContractsNextPageRequested {
  static readonly type = '[Legacy-Contract] All Legacy Contracts requested - Next Page';
  constructor(public payload: { option: ILegacyContractParam }) { }
}
export class LegacyContractsUpdated {
  static readonly type = '[Legacy-Contract] Legacy Contracts Updated';
  constructor(public payload: { id: string | number, data: LegacyContractBase }) { }
}

export class AddLCsFromFile {
  static readonly type = '[Legacy-Contract] Add Legacy Contracts added from file';
  constructor(public payload: { data: LegacyContractBase[] }) { }
}

export class GetLCFromFile {
  static readonly type = '[Legacy-Contract] Fetch Legacy Contracts added from file';
  constructor(public payload: { id: string | number }) { }
}

export class DeleteLCsFromFile {
  static readonly type = '[Legacy-Contract] Delete Legacy Contracts added from file';
  constructor(public payload: { data: LegacyContractBase[] }) { }
}

export class DeleteLCFromFile {
  static readonly type = '[Legacy-Contract] Delete Legacy Contracts added from file';
  constructor(public payload: { id: string | number }) { }
}

export class UpdatePaging {
  static readonly type = '[Legacy-Contract] Request to update pagination';
  constructor(public payload: any) { }
}

export class FetchLegacyContractUsingAlgolia {
  static readonly type = '[Legacy-Contract] Fetch using Algolia';
  constructor(public payload: { pData: Paging, param: ILegacyContractParam }) { }
}

@State<LegacyContractStateModel>({
  name: 'legacyContract',
  defaults: initLCState()
})
@Injectable()
export class LegacyContractState extends StateBase {

  lCData: PagingContainer<LegacyContractBase, ILegacyContractParam> = new PagingContainer();

  constructor(private lCService: LegacyContractService, private algoliaSearchService: AlgoliaSearchService) {
    super();
  }

  // #region Selectors

  @Selector()
  static selectAllLegacyContractsUsingAlgolia(state: LegacyContractStateModel) {
    return (o: ILegacyContractParam): LegacyContractBase[] => {
      if (state?.lCs == null || Object.keys(state.lCs).length === 0) {
        return [];
      }
      let output = Object.values(state.lCs).map(x => parseLegacyContract(x));
      // output = salesOptionSearchClientFilter(output, o);
      return output;
    };
  }

  @Selector()
  static selectTotalCount(state: LegacyContractStateModel) {
    return state.totalCount;;
  }

  /** Get Legacy Contract list (no draft/rev entires are not included) */
  @Selector()
  static selectAllLegacyContracts(state: LegacyContractStateModel) {
    return (o: ILegacyContractParam): LegacyContractBase[] => {

      if (state.lCs == null || Object.keys(state.lCs).length === 0) {
        return null;
      }

      let output = Object.values(state.lCs).map(x => parseLegacyContract(x));
      output = legacyContractSearchClientFilter(output, o);

      return output;
    };
  }

  @Selector()
  static selectAllLegacyContractsFromFile(state: LegacyContractStateModel) {
    return (o: ILegacyContractParam): LegacyContractBase[] => {

      if (Array.isArray(state.lCsFile) && state.lCsFile.length === 0) {
        return null;
      }

      let output = state.lCsFile.map(x => parseLegacyContract(x));
      output = legacyContractSearchClientFilter(output, o);

      return output;
    };
  }

  @Selector()
  static selectLCFromFile(state: LegacyContractStateModel) {
    return (o: ILegacyContractParam): LegacyContractBase => {

      if (Array.isArray(state.lCsFile) && state.lCsFile.length === 0) {
        return null;
      }

      let output = state.lCsFile.find(x => parseLegacyContract(x).leaseNo == o.leaseNo);

      return output;
    };
  }

  @Selector()
  static selectAllLegacyContractClientList(state: LegacyContractStateModel) {
    return (o: ILegacyContractParam): LegacyContractBase[] => {

      if (state?.lCs == null || Object.keys(state.lCs).length === 0) {
        return [];
      }
      // remove keys that have revision/draft ids, i.e that contain '~' in the id.
      const keys = Object.keys(state.lCs).filter(k =>
        k.indexOf('/') === -1 && state.lCs[k] != null);
      // object without the rev/draft.
      const filtered = keys.reduce((obj, k) => {
        obj[k] = state.lCs[k];
        return obj;
      }, {});

      let output = Object.values(filtered).map(x => parseLegacyContract(x));
      output = legacyContractSearchClientFilter(output, o);

      return output;
    };
  }

  @Selector()
  static selectLegacyContractById(state: LegacyContractStateModel) {
    return entity.getByIdFn_new(state.lC, parseLegacyContract);
  }


  @Selector()
  static selectPaging(state: LegacyContractStateModel) {
    return state.paging;
  }

  // #endregion

  @Action(GetLegacyContractRequested)
  GetLegacyContractRequested(context: StateContext<LegacyContractStateModel>, action: GetLegacyContractRequested) {
    if (!context.getState().lCLoaded) {
      const o = this.lCService.getLegacyContractsByLeaseNo(action.payload.pData, action.payload.param)
        .pipe(
          map(c => {
            context.dispatch(new LegacyContractLoaded({ id: c.data.leaseNo, data: c.data }));
            return c;
          }));
      this.subscribe(o, (x) => noop(), GetLegacyContractRequested.type);
    }
  }

  @Action(GetLegacyContractsRequestedNextBatch)
  GetLegacyContractsRequestedNextBatch(context: StateContext<LegacyContractStateModel>, action: GetLegacyContractsRequestedNextBatch) {
    if (!context.getState().lCLoaded) {
      this.lCService.lCList.nextBatch(action.payload.pData);
    }
  }

  @Action(LegacyContractRequested)
  LegacyContractRequested(context: StateContext<LegacyContractStateModel>, action: LegacyContractRequested) {
    const state = context.getState();
    const id = action.payload.id;
    if (state.lC[action.payload.id] == null) {
      // logger.log('comp by id, not found in the store, gettting from server....');
      const s = this.lCService.getLegacyContractById(action.payload.id)
        .pipe(
          map(data => {
            // const c = CompanyFleet.parse(data);
            // logger.log('Company Data was recived from server', data);
            return context.dispatch(new LegacyContractLoaded({ id, data }));
          }
          )); // .subscribe(noop);
      this.subscribe(s, (x) => noop(), LegacyContractRequested.type);
    }
  }

  @Action(LegacyContractLoaded)
  LegacyContractLoaded(context: StateContext<LegacyContractStateModel>, action: LegacyContractLoaded) {
    const state = context.getState();
    const c = {};
    c[action.payload.data?.id] = action.payload.data;
    context.patchState({
      lC: { ...state.lC, ...c }
    });
  }


  @Action(LegacyContractUpdated)
  LegacyContractUpdated(context: StateContext<LegacyContractStateModel>, action: LegacyContractUpdated) {
    const state = context.getState();
    let c = action.payload.lC;
    if (typeof (c.toFirebaseObj()) === 'function') {
      c = c.toFirebaseObj();
    }
    context.patchState({
      lC: { ...state.lC, ...{ [action.payload.id]: c } }
    });
  }


  @Action(LegacyContractStateReset)
  LegacyContractStateReset(context: StateContext<LegacyContractStateModel>, action: LegacyContractStateReset) {
    // unsubscribe the data
    this.clearSubscriptions();
    context.setState(initLCState());
  }

  @Action(LegacyContractRemoveById)
  LegacyContractRemoveById(context: StateContext<LegacyContractStateModel>, action: LegacyContractRemoveById) {
    const state = context.getState();
    if (Object.keys(state.lC).indexOf(`${action.payload.leaseNo}`) > -1) {
      const currState = context.getState();
      const newData = { ...currState.lC };
      delete newData[action.payload.leaseNo];
      context.patchState({ lC: newData });
      const state1 = context.getState();
      logger.log('[Legacy-Contract-State], item removed by id', action.payload.leaseNo, state1);

    } else { logger.log('[Legacy-Contract-State], item to be removed id is not available in the store'); }
  }
  // #region All Records
  @Action(LegacyContractsRequested)
  dataRequested(context: StateContext<LegacyContractStateModel>, action: LegacyContractsRequested) {
    console.log(action, 'getObjKey actionnnnn???');

    const oKey = getObjKey(action.payload.param);
    // Define function that return the data status object from the state.
    const getDataStatusStateFn = () => context.getState().dataLoadStatus;

    /** custom build the OR children query. */
    const buildOrQueryChildrenFn = (o: ILegacyContractParam) => getILegacyContracts(o);

    // if data requested now, is already partially loaded by another query's child previously
    // but the number of the items are not enough (ex. as a child, it loaded only 5, but current
    // request ask for more, then next page of that query should be called instead.)
    const nextPageFn = (option: ILegacyContractParam) => {
      context.dispatch(new LegacyContractsNextPageRequested({ option }));
    };
    buildDataRequest(
      oKey, action.payload.param, action.payload.pData,
      getDataStatusStateFn,
      buildOrQueryChildrenFn,
      nextPageFn,
      (
        obj: { [key: string]: IDataLoadStatus<ILegacyContractParam> },
        set: { key: string, node: IDataLoadStatus<ILegacyContractParam> }[]
      ) => {

        if (!!obj) {
          // Patch the state.
          const state = context.getState();
          context.patchState({
            dataLoadStatus: { ...state.dataLoadStatus, ...obj }
          });
        }

        // Process the query.
        set.forEach((val) => {
          // some of the nodes are already loaded. Only process that are loading... status.
          if (val.node.loadStatus !== LoadStatus.Loading) {
            return;
          }
          // if this request is just overwriting a stall or pending request, unsubscribe that observable
          this.lCData.unsubscribe(val.key);

          // create the paging observable and call db.
          const p = this.lCService.getAllLegacyContracts_PagingObservable();
          console.log(p, 'P Inside contracts::::');
          const prod$ = p.getData(action.payload.pData, val.node.param)
            .pipe(
              map(pickDrops => {
                context.dispatch(new LegacyContractsLoaded({
                  data: pickDrops as any,
                  key: val.key,
                  page: action.payload.pData.offset,
                  isSearchApplied: action.payload.param.isSearchApplied
                }));
                // logger.log(pickDrops, 'pickDrops::::', Object.entries(pickDrops), 'Object.entries(allMaintenance)[0][1].id?????');

                if (action.payload.param.isPaginationRequired) {
                  context.dispatch(new UpdatePaging({ endBefore: Object.entries(pickDrops)[0][1][`${action.payload.param.orderBy.split(' ')[0]}`], startAt: Object.entries(pickDrops)[Object.entries(pickDrops).length - 1][1][`${action.payload.param.orderBy.split(' ')[0]}`] }));
                }
                return pickDrops;
              }));
          const sub = this.subscribe(prod$, () => noop(), LegacyContractsRequested.type);
          // save the observable call
          this.lCData.addData(val.key, sub, p);
        });
      }
    );
  }
  @Action(LegacyContractsLoaded)
  LegacyContractsLoaded(context: StateContext<LegacyContractStateModel>, action: LegacyContractsLoaded) {
    const state = context.getState();
    const subData = this.lCData.getData(action.payload.key);
    const updatedLoadStatus = updatePaging(action.payload.key, state.dataLoadStatus, subData);
    // if(!action.payload.page) {//not required
    //   state.lCs = {};
    // }
    context.patchState({
      allLCLoaded: true,
      dataLoadStatus: updatedLoadStatus,
      lCs: action.payload.isSearchApplied ?  {...action.payload.data } : {...state.lCs, ...action.payload.data} // entity.addMany(state.bids, action.payload.bids)
    });
  }
  @Action(LegacyContractsNextPageRequested)
  LegacyContractsNextPageRequested(context: StateContext<LegacyContractStateModel>, action: LegacyContractsNextPageRequested) {
    const oKey = getObjKey(action.payload.option);
    const state = context.getState();
    // find the node. can be parent or child
    const statusObj = state.dataLoadStatus[oKey];
    // if no parent, treat is
    if (statusObj.children == null) {
      this.lCData.dispatchNextPagingUpdate(oKey);
    } else {
      const children = getRootLevelChildren(oKey, state.dataLoadStatus);
      children.forEach(c => {
        this.lCData.dispatchNextPagingUpdate(c.key);
      });
    }
  }

  @Action(AddLCsFromFile)
  AddLCsFromFile(context: StateContext<LegacyContractStateModel>, action: AddLCsFromFile) {
    const state = context.getState();
    context.patchState({
      lCsFile: [...state.lCsFile, ...action.payload.data]
    });
    logger.info('[Legacy-Contract][State, AddLCsFromFile] Adding incomplete records to state \n', action.payload.data);
  }

  @Action(DeleteLCsFromFile)
  DeleteLCsFromFile(context: StateContext<LegacyContractStateModel>, action: DeleteLCsFromFile) {
    context.patchState({
      lCsFile: []
    });
    logger.info('[Legacy-Contract][State, DeleteLCsFromFile] Delete incomplete records from state \n', action.payload.data);
  }

  @Action(DeleteLCFromFile)
  DeleteLCFromFile(context: StateContext<LegacyContractStateModel>, action: DeleteLCFromFile) {
    const state = context.getState();
    const updatedLCsFile = state.lCsFile.filter(item => item.leaseNo != action.payload.id);
    context.patchState({
      lCsFile: [...updatedLCsFile]
    });
    logger.info('[Legacy-Contract][State, DeleteLCFromFile] Delete incomplete records from state \n', action.payload.id);
  }

  @Action(UpdatePaging)
  paging(context: StateContext<LegacyContractStateModel>, action: any) {
    const state = context.getState();
    if (action.payload.nextClicked || action.payload.prevClicked) {
      context.patchState({
        paging: { ...state.paging, nextClicked: action.payload.nextClicked, prevClicked: action.payload.prevClicked, maxPageNo: action.payload.maxPageNo, maxPageReached: action.payload.maxPageReached}
      });
    }else {
      context.patchState({
        paging: { ...state.paging, startAt: action.payload.startAt, endBefore: action.payload.endBefore }
      });
    }
  }

  @Action(FetchLegacyContractUsingAlgolia)
  fetchLegacyContractUsingAlgolia(context: StateContext<LegacyContractStateModel>, action: FetchLegacyContractUsingAlgolia) {
    const { query, indexName } = legacyContractSearchAlgolia(action.payload.param, action.payload.pData);
    const rAlgolia = this.algoliaSearchService.getDataUsingAlgolia(query, indexName);
    rAlgolia.subscribe(rProducts => {
      const data = {};
      rProducts.hits.forEach(hit => {
        hit.id = hit.objectID;
        hit = updateNestedDateValueToFirebaseTimeStamp(hit,LCDates);
        data[hit.objectID] = hit;
      });
      context.patchState({
        lCs: data,
        totalCount: rProducts.nbHits
      });
    });
  }
}
