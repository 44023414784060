import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PhoneChangeComponent } from './phone-change.component';
import { MaterialModule } from '../share-module/material.module';
import { StatusModule } from '../status/status.module';



@NgModule({
  declarations: [
    PhoneChangeComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    StatusModule
  ],
  exports: [
    PhoneChangeComponent
  ]
})
export class PhoneChangeModule { }
