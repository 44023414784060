import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { EventService } from '@trent/services/event.service';
import { DialogService } from '@trent/services/dialog/dialog.service';
import { Store } from '@ngxs/store';
import { UtilityService } from '@trent/services/utility.service';
import { FileUploadComponent } from '../../shared/file-upload/file-upload.component';
import { fromFailedValidation, fromInvalidInput, getError_unknownError, MessageInfo, readErrorMessage } from '@trent/models/error-handling/message-info';
import { UrlDownloader } from '@trent/models/UI/url-downloader';
import { NgForm } from '@angular/forms';
import { map, take } from 'rxjs/operators';
import { FileInfo } from '@trent/models/media/file-info';
import { PtsHomeImage } from '@trent/models/pts/pts-home-image';
import { DbStatus } from '@trent/models/base/db-status';
import { CmsAllPageHtmlRequested, CmsRemoveById, CmsState } from '@trent/store/cms-store';
import { BaseForm } from '@trent/models/UI/base-form';
import { Animations } from '../../shared/common2/animations';
import { CmsService } from '@trent/services/cms.service';
import { DpcHome } from '@trent/models/dpc/dpc-home';
import { PageHtmlType } from '@trent/models/cms/page-html';
import { parseCms } from '@trent/models/cms/cms-helper';


@Component({
  selector: 'trent-dpc-home-carousel',
  templateUrl: './dpc-home-carousel.component.html',
  styleUrls: ['../dpc-common.scss'],
  animations: [Animations.animeSlideInOut]
})

export class DpcHomeCarouselComponent extends BaseForm<PtsHomeImage> implements OnInit, OnDestroy {

  /// Upload image using  FileUploadComponent
  @ViewChild('imageUploaderCtrl', { static: false })
  imageUploader: FileUploadComponent;

  imageDownloader: UrlDownloader;

  @ViewChild('form', { static: false }) form: NgForm;

  showUploadWarning: any;

  imgList: PtsHomeImage[] = [];
  editMode: boolean;
  pageData = { size: 8, offset: 0, full: false };
  isHandset$: Observable<boolean>;
  isHandset: boolean;
  dpcObject: DpcHome;

  constructor(
    eventService: EventService,
    dialogService: DialogService,
    store: Store,
    private cmsService: CmsService,

  ) {
    super(store, dialogService, eventService);
  }

  ngOnInit(): void {
    this.isHandset$ = this.us.isHandset$;
    this.mSubscription.push(this.us.isHandset$.subscribe((h) => (this.isHandset = h)));
    // Get boolean value when click the toggle edit button
    this.store.select(CmsState.selectPageEditMode).subscribe(m => this.editMode = m);
    this.initialData();
  }

  initialData() {
    // Here create class instance
    this.m = new PtsHomeImage();
    // For file upload FileInfo class call
    this.m.inputImage = new FileInfo();
    this.em = this.m.clone();
    delete this.em.pageHtmlType;
    this.store.dispatch(new CmsAllPageHtmlRequested({ pData: this.pageData, param: { type: PageHtmlType.dpcHome } }));
    /// Fetch data from store
    const param = {
      dbStatus: DbStatus.Initial,
      type: PageHtmlType.dpcHome
    };

    const data$ = this.store.select(CmsState.selectedAllPageHtml).pipe(map(clientFilterFn => clientFilterFn(param)));
    this.mSubscription.push(data$.subscribe(d => {
      if (d[0] && d.length > 0) {
        this.dpcObject = parseCms(d[0]) as DpcHome;
        this.imgList = this.dpcObject.images;
      }
    }));
  }
  /**
   * 
   * when the save button click file upload in firebase storage
   */
  async uploadFiles(): Promise<boolean> {
    try {
      this.showUploadWarning = false;
      let m = new MessageInfo();
      const path = 'dpc/homePage/carouselImages';
      const r = [];
      // eslint-disable-next-line max-len
      const uploadedItems = [];
      if (this.imageUploader) {
        uploadedItems.push(this.imageUploader);

        if (this.imageUploader.hasFile) {
          const imageDocProm = this.imageUploader.uploadResult$.pipe(take(1)).toPromise();
          this.imageUploader.uploadFile(path);
          r.push(imageDocProm);
        }
      }

      if (r.length === 0) {
        m = new MessageInfo();
        m.description = 'At least one document must be selected!';
        m.msgCss = 'warn';
        m.header = 'File upload error!';
        this.showAlert(m);
        this.hideLoading();
        return false;
      }
      const rp = await Promise.all<boolean>(r);
      // All files were uploaded successfully.
      if (rp.indexOf(false) === -1) {
        return true;
      }

      // some were updated.
      if (rp.indexOf(true) > -1) {
        // add warning but continue savings.
        this.showUploadWarning = true;
        return true;
      }
      m = new MessageInfo();
      m.header = 'Error Uploading file(s)!';
      m.description = '';
      uploadedItems.forEach((v) => {
        if (v.hasError) {
          m.description += '</br>' + v.errorStr;
        }
      });
      m.msgCss = 'warn';
      this.showAlert(m);
      this.hideLoading();
      // means operation failed.
      return false;
    } catch (error) {
      this.showAlert(getError_unknownError(error));
      this.hideLoading();
      return false;
    }
  }

  async save() {
    // here checking the only 5 images allowed for upload.
    if (this.imgList?.length == 5) {
      const msg = new MessageInfo({
        msgCss: 'warn',
        header: 'Error',
        description: 'Only 5 images allow not more than that.'
      });
      this.showAlert(msg);
      return;
    }

    this.showLoading(10);

    // Here check the input for validation
    const tempR = this.em.validateSync();
    if (this.imageUploader && !this.em.inputImage?.name) {
      let checkSignedDocUploaded = this.imageUploader.hasFile;
      if (!checkSignedDocUploaded) {
        tempR['Image'] = ['Please upload image document'];
      }
    }

    if (Object.keys(tempR).length > 0) {
      this.showAlert(fromFailedValidation(tempR).messageInfo);
      this.hideLoading();
      return false;
    }

    if (this.imageUploader.hasFile) {
      const result = await this.uploadFiles();
      if (!result) {
        this.hideLoading();
        return;
      }
    }

    const tempI = this.em.validateImage({ groups: [] });
    if (Object.keys(tempI).length > 0) {
      this.showAlert(fromFailedValidation(tempI).messageInfo);
      this.hideLoading();
      return false;
    }

   
    let response;
    // Here checking the object is available or not
    if (!this.dpcObject) {
      // If object not available create the new instance of DpcHome
      const dpc = new DpcHome();
      if(!dpc.images){
        dpc.images = [];
      }
      dpc.images.push(this.em);
      // Here call the api for create the document
      response = this.cmsService.createDpc(dpc).toPromise();
    } else {
      // If object available then clone and update it 
      const dpc = this.dpcObject.clone();
      if(!dpc.images){
        dpc.images = [];
      }
      // Add the new object existing DpcHome object 
      dpc.images.push(this.em);
      // Here call the api
      response = this.cmsService.updateDpc(dpc.id, dpc).toPromise();
    }
    response.then(b => {
      const msg = new MessageInfo({
        msgCss: 'primary',
        header: 'Image Uploaded',
        description: 'Image uploaded successfully.'
      });
      this.showAlert(msg);
      this.hideLoading();
      this.resetFileInfo(this.em.inputImage);
      this.em.altImage = '';
    }).catch(err => {
      this.hideLoading();
      this.showAlert(readErrorMessage(err));
    });

  }

  resetFileInfo(f: FileInfo) {
    f.name = null;
    f.path = null;
    f.url = null;
    this.imageUploader.removeFile();
  }

  // Here the delete the image according by images object path match
  delete(path: string | number) {
    this.showLoading(10);
    // Here clone the object
    const dpc = this.dpcObject.clone();
    //Here filter out not match object
    dpc.images = dpc.images.filter(v => v.inputImage.path != path);
    // Here call the api for create the document
    this.cmsService.updateDpc(dpc.id, dpc).toPromise()
      .then(b => {
        const msg = new MessageInfo({
          msgCss: 'primary',
          header: 'Image Deleted',
          description: 'Image deleted successfully.'
        });
        this.showAlert(msg);
        this.hideLoading();
        this.initialData();
      }).catch(err => {
        this.hideLoading();
        this.showAlert(readErrorMessage(err));
      });
  }

  ngOnDestroy(): void {
    this.cleanListeners();
  }
}
