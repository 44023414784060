import { Equals, IsBoolean, isNumber, IsNumber, Length, Max, ValidateIf, validateSync, IsDefined, ValidateNested, IsDate, ValidatorOptions, isDefined } from 'class-validator';
import { Exclude, instanceToInstance, plainToInstance, Expose, Type } from 'class-transformer';
import { IValidationMsg } from '../error-handling';
import { sanitizeDate, sanitizeDateIPoint, toValidationError } from '../utility';
import { addYears, isAfter, lastDayOfMonth } from 'date-fns';
import { BaseModel } from '../base';
import { Address } from '../address/address';
import { PromoListBase } from '../promo';
import { logger } from '../log/logger';
import { CompanyFleet } from '../company/company-fleet';


/**
 * Maintain subsidiary Vendor Company Id
 */
export interface ILinkedVendorCompany {
  /** key is the company id */
  cName: string;
  cid : string | number;
}

/**
 * @author - MKN
 * @purpose - Maintain all subsidiary data to fetch all available units from net suite
 */
@Exclude()
export class Subsidiary extends BaseModel {
  
  @Expose()
  @IsNumber(undefined, { message: 'Number is required for Subsidiary Id'})
  subsidiaryId: number;

  @Expose()
  @Length(5, 254, {
    message: 'Subsidiary Name must be $constraint1 - $constraint2 chars long',
  })  
  name: string;

  @Expose()
  @ValidateNested({ message: 'Address info is required' })
  @IsDefined({ message: 'Address information is required' })
  @Type(() => Address)
  address: Address;

  @Expose()
  vendorCompany: ILinkedVendorCompany;

  @Expose()
  lastSyncTime: Date;

  constructor() {
    super();
    this.address = new Address();
  }

  public static parse(obj) {
    if (obj instanceof (Subsidiary)) { return <Subsidiary>obj; }

    const m = plainToInstance<Subsidiary, any>(Subsidiary, sanitizeDateIPoint(obj));
    m.sanitize();
    return m;
  }

  sanitize() {
    // super.sanitize();
    this.lastSyncTime = sanitizeDate(this.lastSyncTime);
  }

  clone() {
    const t = instanceToInstance(this);
    t.sanitize();
    return t;
  }

  validateSync(options?: any): IValidationMsg {

    const m = validateSync(this, options);
    const r = toValidationError(m);
    return r;
  }

  updateSubsidiaryData(iData : Subsidiary){
    this.name = iData.name;
    this.subsidiaryId = iData.subsidiaryId;
    this.address = iData.address;
  }
}



/** List of subsidiaries  */
@Exclude()
export class SubsidiaryList extends PromoListBase {

  @Expose()
  /** Subsidiary List, @param key | netSuite id */
  list: { [key: string]: Subsidiary };

  public static parse(obj) {
    if (obj == null) { return null; }
    const m = plainToInstance<SubsidiaryList, any>(SubsidiaryList, sanitizeDateIPoint(obj));
    for (const key in m.list) {
      if (Object.prototype.hasOwnProperty.call(m.list, key)) {
        const element = m.list[key];
        m.list[key] = Subsidiary.parse(element);        
      }
    }
    m.sanitize();
    return m;
  }
  
  validateSync(options?: ValidatorOptions): IValidationMsg {
    const r = this.validateSyncBase(this);
    return r;
  }

  clone() {
    const t = instanceToInstance(this);
    t.sanitize();
    return t;
  }

  /**
   * adds and updates subsidiary list when new subsidiary is added or updated
   * @param subsidiaryItem updated or new subsidiary
   * @param id new or updated netSuite id
   */
  updateSubsidiaryList(subsidiaryItem: Subsidiary, id: string | number) {
    // clone from the doc from db
    //const uAggregate = this.clone();
    // initialize list
    if (!this.list) { this.list = {} as { [key: string]: Subsidiary; }; }

    if (!this.list[id]) { this.list[id] = {} as Subsidiary; }

    this.list[id].name = subsidiaryItem.name;
    this.list[id].address = subsidiaryItem.address;
    this.list[id].subsidiaryId = subsidiaryItem.subsidiaryId;
    
    // // check if change is required. Compare before and after dictionary item.
    // if (!!this.list && (!!this.list[id] && isEqual(uAggregate.list[id], this.list[id]))) {
    //   return { changeRequired: false };
    // } else {
    //   return { changeRequired: true, uAggregate };
    // }
  }
  
  /**
   * adds and updates subsidiary list when new subsidiary is added or updated
   * @param companyInfo updated or new company
   * @param id new or updated netSuite id
   */
  updateCompanyData(companyInfo: CompanyFleet, id: string | number) {
    
    this.list[id].vendorCompany = {
      cid : companyInfo.id,
      cName : companyInfo.name
    };
  }
}