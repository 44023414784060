<div class="slide-container">
    <div class="sliders" [@fade]="current" [style.height]="showHeightVW ? heightImg+'vw' : heightImg+'px'">
        <ng-container *ngFor="let item of list; let i = index">
            <div class="slider" *ngIf="current === i" >
                <ng-container *ngIf="homeTemplateRef" [ngTemplateOutlet]="homeTemplateRef" [ngTemplateOutletContext]="{$implicit:item}"></ng-container>
            </div>
        </ng-container>
        <img [src]="defaultImg" alt="image" *ngIf="!list?.length && defaultImg" class="defaultImg"/>
    </div>
    <mat-icon aria-label="" (click)="prevArrow()" class="p-arrow arrow" *ngIf="arrowShow && list && list?.length > 1">chevron_left</mat-icon>
    <mat-icon aria-label="" (click)="nextArrow()" class="n-arrow arrow" *ngIf="arrowShow && list && list?.length > 1">chevron_right</mat-icon>
</div>
<div class="thumbnail-bar-container" *ngIf="showThumbnails">
      <mat-icon (click)="prevArrow()">chevron_left</mat-icon>
    <div #thumbnailBar class="thumbnail-bar">
      <ng-container *ngFor="let p of list; index as i">
        <div class="thumbnail-container" [ngClass]="{'active-thumbnail': i === current}">
            <img [src]="p?.serverUrl" (error)="handleImageError(p)"  alt="" class="thumbnail" (click)="selectedImage(i)"/>
        </div>
      </ng-container>
    </div>
      <mat-icon (click)="nextArrow()">chevron_right</mat-icon>
  </div>
