import { map } from "lodash";

export enum InvoiceItemCat {
  /** cover escrow / security deposits and other non-revenue items */
  SecurityDeposit = -100,
  SecurityDepositRefund = -95,

  // Any number between -100 up to -1 is NON REVENUE. 
  /** IMPORTANT:  Any number 0 or above will be revenue items. */
  Revenue = 0,
  RefundRevenue = 10,
  Discount = 20
}

/** Special Item types that are stored in the db settings.  */
export type SpecialItemTypeDb = 'serviceFee' | 'environmentFee' | 'vehicleLicensingFee'; //  | 'custom';
export type SpecialItemTypeOptionalDb = 'gapInsurance' | 'walkAwayInsurance' | 'waiverInsurance'; //  | 'custom';
export type SpecialItemTypeOptionalCombDb = 'gapInsuranceComb' | 'walkAwayInsuranceComb' | 'waiverInsuranceComb'; //  | 'custom';
export type SpecialItemTypeDbN = SpecialItemTypeDb | SpecialItemTypeOptionalDb | SpecialItemTypeOptionalCombDb; //  | 'custom';

/** Special Item that are not stored in the db settings. */
export type SpecialItemTypeCus =  'admin' | 'custom' | 'discount';

/** Special Item types are union of db saved vs non db saved ( manual added)  */
export type SpecialItemType = SpecialItemTypeDbN | SpecialItemTypeCus

export interface ISpecialItem {
  serviceFee: boolean;
  environmentFee: boolean;
  vehLicLicensingFee: boolean;
}
export interface ISpecialItemN {
  serviceFee: boolean;
  environmentFee: boolean;
  vehLicLicensingFee: boolean;
  gapInsurance?: boolean;
  walkAwayInsurance?: boolean;
  waiverInsurance?: boolean;
  gapInsuranceComb?: boolean;
  walkAwayInsuranceComb?: boolean;
  waiverInsuranceComb?: boolean;
}

export const getSpecialFeeCondition = (invoiceCat: InvoiceItemCat, optionals: (SpecialItemTypeOptionalDb | SpecialItemTypeOptionalCombDb)[] = []): ISpecialItemN => {
  
  switch (invoiceCat) {
    case InvoiceItemCat.SecurityDeposit:
      case InvoiceItemCat.SecurityDepositRefund:
        return {
          serviceFee: false,
          environmentFee: false,
          vehLicLicensingFee: false,
          gapInsurance: false,
        };
        case InvoiceItemCat.Revenue:
          case InvoiceItemCat.RefundRevenue:
            case InvoiceItemCat.Discount:
              return {
                serviceFee: true,
                environmentFee: true,
                vehLicLicensingFee: true,
                gapInsurance: optionals.includes('gapInsurance'),
                walkAwayInsurance: optionals.includes('walkAwayInsurance'),
                waiverInsurance: optionals.includes('waiverInsurance'),
                gapInsuranceComb: optionals.includes('gapInsuranceComb'),
                walkAwayInsuranceComb: optionals.includes('walkAwayInsuranceComb'),
                waiverInsuranceComb: optionals.includes('waiverInsuranceComb')
                
              };
              default:
                throw new Error(`special fee condition are are not defined for ${InvoiceItemCat[invoiceCat]} `);     
                
              }
              
            };
  export type InvoiceItemNameType =  'Reserve' | 'Security Deposit' | 'Admin Fee (One Time)' | 'Rent Daily' |'Rent per day' | 'Rent Weekly' | 'Rent Monthly' | 'Security Refund' | 'Mileage' | 'Minimum Mileage' | 'Damage' | 'Credit' | 'Other';
