import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { CoreLazyModule } from '../shared/core-lazy/core-lazy.module';
import { FileUploadModule } from '../shared/file-upload/file-upload.module';
import { CustomPipeModule } from '@trent/custom-pipe/custom-pipe.module';
import { MaterialModule } from '../shared/share-module/material.module';
import { CkEditorModule } from '../shared/ck-editor/ck-editor.module';
import { Common2Module } from '../shared/common2/common2.module';
import { DpcHomeCarouselComponent } from './dpc-home-carousel/dpc-home-carousel.component';
import { DpcHomeBestSellerComponent } from './dpc-home-best-seller/dpc-home-best-seller.component';
import { SparePartsCardComponent } from './spare-parts-card/spare-parts-card.component';
import { CarouselModule } from '../shared/carousel/carousel.module';
import { SparePartStoreModule } from '@trent/store/spare-part/spare-part-store.module';
import { HomeDpcComponent } from '../home-dpc/home-dpc.component';
import { DpcAboutComponent } from './dpc-about/dpc-about.component';
import { DpcContactComponent } from './dpc-contact/dpc-contact.component';
import { DpcCatalogComponent } from './dpc-catalog/dpc-catalog.component';
import { DpcHomeBlogsComponent } from './dpc-home-blogs/dpc-home-blogs.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { DpcOurLocationsComponent } from './dpc-our-locations/dpc-our-locations.component';
import { SubPageModule } from '../shared/sub-page/sub-page.module';
import { DpcDetailsComponent } from './dpc-details/dpc-details.component';
import { MatTabsModule } from '@angular/material/tabs';
import { PtsReviewAreaComponent } from '../pts/pts-common/pts-review-area/pts-review-area.component';
import { PtsCommonModule } from '../pts/pts-common/pts-common.module';
import { AuthGuard } from '@trent/core';
import {MatSliderModule} from '@angular/material/slider';

const routes: Routes = [
  { path: '', component: HomeDpcComponent },
  { path: 'contact-us', component: DpcContactComponent },
  { path: 'about-us', component: DpcAboutComponent },
  { path: 'catalog', component: DpcCatalogComponent },
  { path: 'catalog/:id', component: DpcCatalogComponent },
  { path: 'catalog/details/:id', component: DpcDetailsComponent },
  { path: 'catalog/category/:cid', component: DpcCatalogComponent },
  { path: 'our-locations/:id', component: DpcOurLocationsComponent },
  {
    path: 'spare-part', canActivate: [AuthGuard],
    loadChildren: () => import('../spare-parts/spare-parts.module').then(m => m.SparePartsModule),
  },
  {
    path: 'store-locations', canActivate: [AuthGuard],
    loadChildren: () => import('../store-location/store-location.module').then(m => m.StoreLocationModule),
  },

];

@NgModule({
  declarations: [
    HomeDpcComponent,
    DpcHomeCarouselComponent,
    DpcHomeBestSellerComponent,
    SparePartsCardComponent,
    DpcAboutComponent,
    DpcContactComponent,
    DpcCatalogComponent,
    DpcHomeBlogsComponent,
    DpcOurLocationsComponent,
    DpcDetailsComponent,
  ],
  imports: [
    CommonModule,
    CoreLazyModule,
    MatIconModule,
    FileUploadModule,
    CustomPipeModule,
    MaterialModule,
    CkEditorModule,
    Common2Module,
    CarouselModule,
    SparePartStoreModule,
    RouterModule.forChild(routes),
    MatGridListModule,
    SubPageModule,
    MatTabsModule,
    PtsCommonModule,
    MatSliderModule
  ],
  providers: [
  ],
  exports:[
    DpcHomeCarouselComponent,
    DpcHomeBestSellerComponent,
    SparePartsCardComponent
  ]
})
export class DpcModule { }
