import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchOrderComponent } from './search-order.component';
import { MaterialModule } from '../share-module/material.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';



@NgModule({
  declarations: [
    SearchOrderComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    MatDatepickerModule,
    MatNativeDateModule,
  ],
  exports: [
    SearchOrderComponent
  ]
})
export class SearchOrderModule { }
