/** This is the default file used for web development. 
 * When project is compiled for mobile apps i.e. cordova or capacitor,
 * this file will be dynamically replaced by base-import-mobile.ts.
 * See build configuration in the angular.json file for 'mobileCloud' and mobileLocal. 
 */
import { platform } from '@trent/core/environments/ienviorment';
import { IAuthCoreService } from '@trent/services/auth/iauth-core.service';
import { AuthCoreWebService } from '@trent/services/auth/auth-core-web.service';


export const checkPlatform = (p: platform) => {
  switch (p) {
    case 'web':
      return true;
    case 'capacitor':
    case 'cordova':
    default:
      throw new Error('Programming Error! This file must not be used or called when mobile platform is compiled in mobile [capacitor/cordova] mode.');
  }
};

/**
 * Modules imports specifically only required in the WEB platform
 */
export const mobileOrWebImports = [];

/**
 * Modules providers specifically only required in the WEB platform
 */
export const mobileOrWebProviders = [
  { provide: IAuthCoreService, useClass: AuthCoreWebService }
];