import { SingletonService } from '@trent/services/singleton.service';
import { AuthState, SetAgreementDbCallRequired, UserAgreementRequested } from '@trent/store/auth-store/auth.state';
import { AppLinkComponent } from './shared/app-link/app-link.component';
import { DialogService } from '@trent/services/dialog/dialog.service';
import { AppSetting } from '@trent/models/sys/app-setting';
import { cleanListeners, promiseWraper, TimerHelper } from '@trent/models/utility';
import { LazyLoaderService } from '@trent/services/lazy-loader.service';
import { environment } from '@trent/core/environments/environment';
import { Component, HostBinding, OnInit, AfterViewInit, isDevMode, HostListener } from '@angular/core';
import { CmsService } from '@trent/services/cms.service';
import { Location } from '@angular/common';
import { OverlayContainer } from '@angular/cdk/overlay';
import { LibSetting } from '@trent/models/lib-setting';

import { EventService, IEventListener } from '@trent/services/event.service';
import { IAuthCoreService } from '@trent/services/auth/iauth-core.service';
import { SiteData } from '@trent/models/site-data';
import { Store } from '@ngxs/store';
import { Subscription, fromEvent, combineLatest, Observable } from 'rxjs';
import { StateManager } from '@trent/store/state-manager';
import { Router, NavigationEnd, ActivatedRoute, NavigationStart } from '@angular/router';
import { debounceTime, map, distinctUntilChanged, startWith } from 'rxjs/operators';
import { ScriptService } from '@trent/services/base/script.service';
import { SetScreenDisplay, ScreenDisplayMode, AppState1 } from '@trent/store/root-store';
import { Navigate } from '@trent/store/root-store/router.state';
import { SetUserRef, SetRefId } from '@trent/store/auth-store/auth.state';
import { MessageInfo } from '@trent/models/error-handling';
import { UtilityService } from '@trent/services/utility.service';
import { getUserReqdAgreements, isUpdateAgreementReqd, isAgreementDbCallReqd, saveCookiePolicyInStorage, canAgreementSilentDbUpdate, savePwaInstallationPromptDisabled, IPwaStatus } from '@trent/models/user-agreement';
import { PolicyListComponent } from './shared/policy/policy-list/policy-list.component';
import { MatDialog } from '@angular/material/dialog';
import { restoreRouterConfig, enforceAgreementSignoff } from './ws-common/app-routing-base-data';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { PolicyComponent } from './shared/policy/policy.component';
import { logger, loggerFactory } from '@trent/models/log/logger';
import { LocalStorage } from '@trent/services/local-storage/local-storage.service';
import { LogLevel } from '@trent/models/log/ilog';
import { setFinanceInput } from '../../../../libs/models/finance/finance-input/finance-input-handler';
import { GeoPoint } from '@angular/fire/firestore';
// import type { financeSettingDb } from '@trent/models/finance/finance-input/fin-data-db';
import { MenuData } from '@trent/models/UI/menu-data';
import { AppInstallerAlertComponent } from './shared/app-installer-alert/app-installer-alert.component';
import { PwaInstallation } from '@trent/models/user-agreement/pwa-installation';
import { Platform } from '@angular/cdk/platform';
import { includes } from 'lodash';
import { takeLast } from 'rxjs/operators';
import { SiteMapManager, fetchSiteMapFromServer } from '@trent/models/site-map/site-map-manager';


const loggerGps = loggerFactory.getLogger('gps');

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {

  public eventListners: IEventListener[] = [];
  sub: Subscription[] = [];
  agreementDialogOpen = false;
  agreementWarningCounter = 1;
  agreementTimer = null;
  agreementSignReqd = false;
  agreementDbSub: Subscription;

  title = 'locus-loop';
  @HostBinding('class') componentCssClass;

  appSetting: AppSetting;
  appSettingTimer = null;

  // @ViewChild('lazyLoadContainer', { read: ViewContainerRef, static: true })
  // lazyLoadContainer;

  showBusy = false;
  showBusyCommand = false;
  userSubscription: Subscription;
  claimSubscription: Subscription;


  /**
   * @author MKN 
   * App installer popup functionality
   */
  userAppInstallPrompt: any;
  subMenuData: MenuData[];

  isHandset$: Observable<boolean>;
  isHandset: boolean;
  installPwaDisabledForSession = false;
  appInstalled: any;
  pwaStatus: IPwaStatus;
  deviceId: string;
  cookiePolicySaved: boolean;
  hideAppInstallerAlert: boolean = false;
  /**
   * capture event for manually trigger install app functionality
   * @param e 
   */
  @HostListener('window:beforeinstallprompt', ['$event'])
  onBeforeInstallPrompt(e) {
    logger.log('Installer called');
    logger.log(e);
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    e.preventDefault();
    // Stash the event so it can be triggered later.
    this.userAppInstallPrompt = e;
    this.pwaCheck();
    // if (this.userAppInstallPrompt) {
    //   setTimeout(() => {
    //     this.showAppInstallerBottomSheet();
    //   }, 3000);
    // }
  }
  @HostListener('window:appinstalled', ['$event'])
  onAppInstalled(a) {
    logger.log('pwa app is installed', a);
    this.appInstalled = a;
    // Clear the deferredPrompt so it can be garbage collected
    this.userAppInstallPrompt = null;
  }


  constructor(
    private store: Store,
    private lazyLoaderService: LazyLoaderService,
    public us: UtilityService,
    // private messageService: MessagingService,
    // private as: IAuthCoreService,
    // private fs: FirestoreService,
    private seo: CmsService,
    private location: Location,
    // private aroute: ActivatedRoute,
    private overlayContainer: OverlayContainer,
    private auth: IAuthCoreService,
    private router: Router,
    private scriptService: ScriptService,
    private aroute: ActivatedRoute,
    private ds: DialogService,
    private localStorage: LocalStorage,
    private ss: SingletonService,
    public platform: Platform,
    private eventService?: EventService,
    private dialog?: MatDialog,
    private _bottomSheet?: MatBottomSheet,
    // private storage?: Storage
  ) {

    // Setup Log level
    logger.log('Environment: ', environment);
    // logger.info('- LOG - param ', { x: 1 });
    // logger.log('-  INFO Log - param ', { x: 1 });
    // logger.warn('-  WARN Log - param ', { x: 1 });
    // logger.error('-  ERROR Log - param ', { x: 1 });

    if (environment.platform !== 'web') {
      this.scriptService.loadScript('cordova.js')
        .then(() => logger.log('codrova script successfully loaded!'))
        .catch(err => logger.error(err));
    }

    if (this.us.isPlatformBrowser) {
      this.setLogLevel();
      loggerFactory.setLogLevel(LogLevel.ALL);
      logger.log('lazy loading module started');
      this.lazyLoaderService.load(null);
    }

    //only printed in dev mode.
    this.printFinanceHardCodedDb();

    router.events.subscribe((val) => {
      if (location.path() != '') {
        this.hideAppInstallerAlert = (location.path().includes('/get-info-page/') || location.path().includes('/promotion/unsubscribe/')) ? true : false;
      }
    });
  }

  /**
   * @author - MKN @
   * Trigger install app routine
   */
  addToHomeScreen() {

    // Show the prompt
    this.userAppInstallPrompt.prompt();
    // Wait for the user to respond to the prompt
    this.userAppInstallPrompt.userChoice
      .then(async (choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          logger.log('User accepted the A2HS prompt');
          // this.savePwaInstallStatus({isPWAInstalled : true, isPwaInstallationPromptDisabled : false, uid : this.auth.userId });//android desktop case
        } else {
          this.installPwaDisabledForSession = true;
          logger.log('User dismissed the A2HS prompt');
        }
        this.userAppInstallPrompt = null;
      });
  }

  /**
   * Save app install status on DB
   */
  savePwaInstallStatus(data?: IPwaStatus) {
    savePwaInstallationPromptDisabled(this.localStorage).then(() => {
      logger.info('[AppComponent] pwa status isPwaInstallationPromptDisabled saved');
    })
    .catch(e => logger.error('[AppComponent] pwa status isPwaInstallationPromptDisabled save failed', e));
    // if (!!this.deviceId) {
    //   const promiseD = !this.pwaStatus ? this.deviceService.setDevicePWAStatus(this.deviceId, data) : this.deviceService.updateDevicePWAStatus(this.deviceId, data);
    //   promiseD.then(() => {
    //     logger.info('[AppComponent] pwa status saved');
    //   })
    //   .catch(e => logger.error('[AppComponent] pwa status save failed', e));
    // }
  }


  /**
   * @author - MKN 
   * Show Install app popup on screen
   */
  async showAppInstallerBottomSheet() {

    this._bottomSheet.open(AppInstallerAlertComponent, {
      data: {},
      disableClose: true
    });
    this._bottomSheet._openedBottomSheetRef.afterDismissed().toPromise()
      .then((r: PwaInstallation) => {
        logger.log('[App component], App Installer bottom sheet', r);
        switch (r) {
          case PwaInstallation.promptToInstall:
            this.addToHomeScreen();
            break;
          case PwaInstallation.promptLater:
            logger.info('App component], Pwa Installation promptLater');
            break;
          case PwaInstallation.promptDisabled:
            this.savePwaInstallStatus({isPwaInstallationPromptDisabled : true, uid : this.auth.userId });
            break;
          default:
            break;
        }
      })
      .catch(err => logger.log('[App component], App Installer bottom sheet failed', err)
      );
  }

  ngOnInit(): void {
    this.isHandset$ = this.us.isHandset$;
    this.isHandset$.subscribe(h => {
      this.isHandset = h;
    });

    this.eventService.listen<MenuData[]>(this.eventService.loadPageOptions,
      data => (this.subMenuData = data));
    this.checkAppVersion();
    this.handleUserAgreementsChecksAlt();
    this.sub.push(
      this.aroute.queryParams.subscribe(p => {
        if (!!p) {
          if (!!p['refType']) {
            this.store.dispatch(new SetUserRef({ userRef: p['refType'] }));
          }
          if (!!p['refId']) {
            this.store.dispatch(new SetRefId({ refId: p['refId'] }));
          }
        }
      }));

    // if (this.seo.isPlatformServer) {
    // lib initiation moved to lazy load singlton service. for browser (dont remeber why)
    // // Initialize Library types.
    LibSetting.setTypes(GeoPoint);
    // }

    /** Update SiteData, this is fast Initialize site data. and suscribet to the Site static class data */
    this.sub.push(
      this.auth.user$.subscribe(u => {
        if (!!SiteData.userFb && (u == null || u.uid !== SiteData.userFb.uid)) {
          StateManager.LogoutState(this.store);
        }
        SiteData.setUserFb(u);
      }));

    /* Since there is a delay, the SiteData constructor and the store constrctor, User
     * user and claim are now bined in the constructor of the AuthState */
    // const user$ = this.store.select(AuthState1.user);
    // this.userSubscription = user$.subscribe(u => SiteData.setUser(u));
    // this.claimSubscription = this.store.select(AuthState1.userClaim).subscribe(c => SiteData.setClaim(c));

    logger.log('Starting App with Enviorment Project as: ', environment.proj);
    this.seo.initUrl = this.location.path(true);

    // this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
    if (this.us.isPlatformBrowser) {
      this.router.events.subscribe((ev) => {
        if (ev instanceof NavigationStart && ev.url !== '/') {
        }
        if (ev instanceof NavigationEnd) {
          try {
            const n = <NavigationEnd>ev;
            if (n.url.indexOf('returnUrl') === -1) {
              // Update the history in the state.
              this.store.dispatch(new Navigate({
                url: n.url,
                recordOnly: true
              }));
            }
            // Also clear the options sub menu that will be cleaned by the shell page (option menu from three vertical dots on RHS top)
            // logger.log('[app compo] cleaning up sub menu sir.');
            this.eventService.emit(this.eventService.loadPageOptions, []);
          } catch (error) {
            logger.error(error);
          }
        }
      });


    }

    // Set theme.
    this.onSetTheme('default-theme');
    this.bindWindowResize();
  }

  ngAfterViewInit(): void {
    if (this.us.isPlatformBrowser) {
      this.setLoadingBusy();
    }
    // clean listeners for server.
    if (this.us.isPlatformServer) {
      cleanListeners(this.eventListners, this.sub);
    }
  }

  async pwaCheck() {
    const cPP = await promiseWraper(this.localStorage.get('cookie-policy'));
    if (!cPP.success) {
      logger.error('failed to get cookie-policy');
      return;
    }
    this.cookiePolicySaved = !!cPP.data ? true : false;
    if(!this.cookiePolicySaved) {
      return;
    }
    const pwaDisableP = await promiseWraper(this.localStorage.get('isPwaInstallationPromptDisabled'));
    if (!pwaDisableP.success) {
      logger.error('failed to get isPwaInstallationPromptDisabled');
      return;
    }
    if(!!pwaDisableP.data) {
      return;
    }
    // this.deviceId = (await this.deviceService.getDeviceId()).deviceId;
    // if(!this.deviceId) {
    //   return;
    // }
    // checking pwa is launched on iphone
    if ((window.navigator as any).standalone) {
      logger.info('[]App Component] iphone paw launched');
      return;
    }
    // check if browser is chrome, edge or safari
    if (!this.platform.BLINK && !this.platform.EDGE && !this.platform.SAFARI) {
      return;
    }
    // if iphone
    const ua = window.navigator.userAgent;
    const iOS = ua.match(/iPad/i) || ua.match(/iPhone/i);
    const webkit = ua.match(/WebKit/i);
    const iOSSafari = iOS && webkit && !ua.match(/CriOS/i);
    if (this.isHandset && this.platform.IOS ) {
      return;
    }
    if(!this.platform.BLINK && !this.platform.EDGE  ) {
      return;
    }
    if((!!this.platform.BLINK || !!this.platform.EDGE) && !this.userAppInstallPrompt ) {
      return;
    }

    if(this.hideAppInstallerAlert){//hide in case of promotions contact us page
      return;
    }
    
    this.showAppInstallerBottomSheet();
    // this.deviceService.getDevicePWAStatus(this.deviceId).subscribe(d => {
    
    //   if(d != undefined ) {
    //     this.pwaStatus = d;
    //   }
    //     if(!!this.pwaStatus && ( !!this.pwaStatus.isPwaInstallationPromptDisabled)) {
    //       return;
    //     } else {
          

    //     }
    //    });
  }

  async setLogLevel() {
    if (this.us.isPlatformBrowser) {
      this.localStorage.get('logLevel').then(level => {
        if (!!level && !isNaN(+level)) {
          console.log('log level updated from cache', level);
          loggerFactory.setLogLevel(+level);
        }
      })
        .catch(err => { });
    }
  }

  onSetTheme(theme) {
    this.overlayContainer.getContainerElement().classList.add(theme);
    this.componentCssClass = theme;
    // logger.log('class list is: ', this.overlayContainer.getContainerElement().classList);
  }

  /** Only called for browser */
  setLoadingBusy() {
    // Hide Busy
    this.eventService.listen<any>(this.eventService.app_hideBusy, () => {
      // logger.log('*** hide busy received', x);
      this.showBusyCommand = this.showBusy = false;
    });

    // Show Busy
    this.eventService.listen<number>(this.eventService.app_showBusy, delay => {
      // logger.log('*** show busy called', +delay);
      if (isNaN(+delay) || +delay === 0) {
        this.showBusy = true;
      } else {
        this.showBusyCommand = true;
        setTimeout(() => {
          this.showBusy = this.showBusyCommand;
        }, delay);
      }
    });
  }

  bindWindowResize() {
    if (this.us.isPlatformBrowser) {

      const MOBILE_MAX_WIDTH = 425;
      const TABLET_MAX_WIDTH = 1024;

      const o = fromEvent(window, 'resize')
        .pipe(
          debounceTime(200),
          map(() => ({ w: window.innerWidth, h: window.innerHeight })), // Don't use mapTo!
          distinctUntilChanged(),
          startWith({ w: window.innerWidth, h: window.innerHeight })
        );

      o.subscribe(g => {
        const width = g.w;
        const height = g.h;
        const screen = (width > TABLET_MAX_WIDTH) ? ScreenDisplayMode.desktop :
          (width < MOBILE_MAX_WIDTH) ? ScreenDisplayMode.mobile : ScreenDisplayMode.tablet;

        this.store.dispatch(new SetScreenDisplay({ screen, width, height }));
        // logger.log('width: ', width, ScreenDisplayMode[screen]);

      });
    }
  }

  checkAppVersion() {
    if (this.us.isPlatformBrowser) {
      // subscribe to app settings.
      const appSettingSub = this.store.select(AppState1.appSettings)
        .pipe(map(obj => AppSetting.parse(obj)))
        .subscribe(c => {
          if (!!c) {
            try {
              this.appSetting = c;
              if (!!this.appSetting && !!this.appSetting.finInput) {
                logger.log('[AppComp] Set finance input was updated');
                setFinanceInput(this.appSetting.finInput);
              }
              if (this.appSetting?.logLevel != null) {
                loggerFactory.setLogLevel(this.appSetting?.logLevel);
                // console.log('LOG level was updated in the cache.', this.appSetting.logLevel);
                this.localStorage.set('logLevel', this.appSetting.logLevel);
              }
              if (!!this.appSettingTimer) {
                clearInterval(this.appSettingTimer);
              }
              logger.log('[AppComp] settings: ', c);
              if (!c.isCurrentAppCompatible(environment.platform, SiteData.appVersionLocal1)) {
                if (environment.platform === 'web') {
                  this.handleAppSettingReload();
                  return;
                }
                logger.error('[AppComponent] App Settings are not compatible.');

                this.ds.alert(new MessageInfo({
                  msgCss: 'warn',
                  header: 'App is OUTDATED!',
                  description: 'Your App is outdated. Please visit the link to update the app.'
                }) // ,
                  // { verticalPosition: 'bottom' }
                );

                this.router.resetConfig([
                  { path: '**', component: AppLinkComponent }
                ]);
                this.router.initialNavigation();
                this.router.navigateByUrl('/download-app');
              }
            } catch (error) {
              logger.error('[Utility Service] ]Prograamming error in parsing the app version information from Db');
            }
          }
        });
      this.sub.push(appSettingSub);
    }
  }

  /** Handle outdate app version for web platform only. */
  handleAppSettingReload() {
    this.appSettingTimer = setInterval(() => {
      this.ds.confirm(new MessageInfo({
        msgCss: 'primary',
        header: 'A Reload is required!',
        description: 'Application requires a reload to ensure it is working properly. Click OK to reload.'
      }), undefined, (r) => {
        if (!!r) {
          clearInterval(this.appSettingTimer);
          this.appSettingTimer = null;
          logger.log('[App-Component] version check confirm modal result: ', r);

          // true is required in the reload otherwise, page will be served from cache.     
          window?.location?.reload();
        }
      });
    }, 10000);

  }

  async handleUserAgreementsChecksAlt() {

    if (this.us.isPlatformBrowser) {

      /** Bind the event to restore the router configuration. It will be called by mandated pages ( Policy Update etc. ) */
      this.eventListners.push(
        this.eventService.listen<any>(this.eventService.restoreRouterConfig, () => {
          restoreRouterConfig(this.router);
          if (!!this.agreementTimer) {
            clearInterval(this.agreementTimer);
          }
          // const url = this.aroute.snapshot.queryParams.returnUrl;
          // this.router.navigateByUrl(!!url ? url : './');
        })
      );

      const setting$ = this.store.select(AppState1.appSettings).pipe(map(obj => AppSetting.parse(obj)));
      const claim$ = this.store.select(AuthState.userClaim);

      const sub = combineLatest([setting$, claim$])
        .subscribe(([setting, claim]) => {
          // clear the timers if user is looged out.


          if (!!setting) {
            this.checkCookiePolicy();
          }
          /** based upon the claim and settings, Is a db call to fetch agreement required ? */
          const isAgreementStatusCheckReqd = isAgreementDbCallReqd(claim, setting);
          this.store.dispatch(new SetAgreementDbCallRequired({ data: isAgreementStatusCheckReqd }));

          // If DbCall Required
          if (isAgreementStatusCheckReqd) {
            // const ag = Object.values(!!setting.// const settingRev = setting.
            this.store.dispatch(new UserAgreementRequested({ uid: claim.user_id }));

            // unsubscribe previous.
            if (!!this.agreementDbSub) {
              this.agreementDbSub.unsubscribe();
            }

            this.agreementDbSub = this.store.select(AuthState.outDatedAgreement)
              .subscribe(agreementStatus => {
                /** Nothing is outdated but agreement timer is still ON, turen it off */
                if (!isAgreementStatusCheckReqd) {
                  if (!!this.agreementTimer) {
                    clearInterval(this.agreementTimer);
                  }
                  return;
                }

                if (!!agreementStatus && !!this.appSetting) {
                  // Outdated component may not be affecting current users as it may not be applicable to him.
                  const reqdAgreements = getUserReqdAgreements(this.auth.userId, this.appSetting);
                  this.agreementSignReqd = isUpdateAgreementReqd(agreementStatus.curr, reqdAgreements.curr).length > 0;
                  if (this.agreementSignReqd) {
                    logger.log('Rab tere bhla kre UPDATE REQD', agreementStatus);
                    this.openAgreementsDialogOrSilentUpdate(claim.user_id);
                  } else {
                    logger.log('Rab tere bhla kre, User new agreement not REQD', agreementStatus);
                    restoreRouterConfig(this.router);
                    if (!!this.agreementTimer) {
                      clearInterval(this.agreementTimer);
                    }
                  }
                }
              });
          }
        });
    }
  }

  async openAgreementsDialogOrSilentUpdate(uid: string) {

    // Handle special case when user agreed to site agreements but data in db was not saved. If same settings are found in the cache
    // then just make a silent db call.
    const silentUpdate = await canAgreementSilentDbUpdate(this.localStorage, uid, this.appSetting);
    if (silentUpdate) {
      if (!!this.agreementTimer) {
        clearInterval(this.agreementTimer);
        this.agreementTimer = null;
      }
      logger.log('[AppComp] Silent update of the agreements to be fired.');
      const x = new TimerHelper();
      x.setInterval(500, 20, () => {
        if (!!this.ss.userService) {
          this.ss?.userService.createSiteAgreementsSilent(uid)
            .then(() => logger.log('[AppComp] Agreement silent update completed'))
            .catch(err => logger.error('[AppComp] silent update failed', err));
          logger.log('[AppComp] silent update, SS loaded');
          return true;
        }
        logger.log('[AppComp] silent update, waiting for singlton to upload');
        return false;
      });
      return;
    }
    /** 
     * Code below is working if new user from Google/Facebook needs to sign the terms/policy document 
     * This create UI nuisance and bad UI exp. so temp blocked. Note code is working though
     */
    if (this.agreementTimer == null && false) {
      this.agreementTimer = setInterval(async () => {
        // sign are not required for one day of user saved it in cache
        this.agreementSignReqd = !(await canAgreementSilentDbUpdate(this.localStorage, uid, this.appSetting));
        if (!this.agreementSignReqd && !!this.agreementTimer) {
          clearInterval(this.agreementTimer);
          return;
        }

        // Only show dialog if the update page is not displayed
        if (!this.router.url.startsWith('/policy-update') && this.agreementSignReqd) {
          if (this.dialog.openDialogs.length === 0) {
            return new Promise<boolean>(async (resolve) => {
              const config = this.ds.getFullScreenConfig(this.us.isHandset, undefined);
              config.data = {
                warningCounter: this.agreementWarningCounter,
                finalReminder: true
              };
              const dialogRef = this.dialog.open(PolicyListComponent, config);
              const result = await promiseWraper(dialogRef.afterClosed().toPromise());
              if (!result.success || !result.data) {
                ++this.agreementWarningCounter;
                if (this.agreementWarningCounter >= 4) {
                  this.ds.alert(new MessageInfo({
                    msgCss: 'warn',
                    header: 'Policy Update',
                    description: 'Your agreement to updated policies is required to continue using this application.'
                  }));
                  // this.router.config
                  enforceAgreementSignoff(this.router);
                }
              }
              resolve(true);
            });
          }
        }
      }, 15000);
    }
  }

  checkCookiePolicy() {
    setTimeout(async () => {
      if (!this.auth.isLoggedin) {
        const cRevP = await promiseWraper(this.localStorage.get('cookie-policy'));
        if (!cRevP.success || !cRevP.data || cRevP.data < this.appSetting.agreementRev['cookie-policy']?.rev) {
          this.openCookiePolicyDialog();
        }
      } else {
        saveCookiePolicyInStorage(this.appSetting.agreementRev['cookie-policy']?.pid,
          this.appSetting.agreementRev['cookie-policy']?.rev, this.localStorage);
      }
    }, 3000);
  }
  openCookiePolicyDialog() {
    this._bottomSheet.open(PolicyComponent, {
      data: {
        title: this.appSetting.agreementRev['cookie-policy']?.name,
        pid: this.appSetting.agreementRev['cookie-policy']?.pid,
        rev: this.appSetting.agreementRev['cookie-policy']?.rev
      },
      disableClose: true
    });
    this._bottomSheet._openedBottomSheetRef.afterDismissed().toPromise()
      .then(r => {
        logger.log('[App component], cookie policy accepted');
        setTimeout(() => {
          this.pwaCheck();
        }, 3000);
      })
      .catch(err => logger.log('[App component], cookie policy failed', err)
      );
    // this.ds.bottomSheet(msg);
  }

  async printFinanceHardCodedDb() {
    if (isDevMode() && this.us.isPlatformBrowser) {
      try {
        let x = await import('@trent/models/finance/finance-input/fin-data-db');
        console.warn('[App component] Printout of Default Hard Coded Finance Setting Db.');
        console.info(`\n\n${JSON.stringify(x.financeSettingDb, null, 2)}\n\n`);
      } catch (error) {
        console.error('[App component] Hard Code Printing of Fin-DB Failed!');
      }
    }
  }

  // handleUserAgreementsChecks() {

  //   if (this.seo.isPlatformBrowser) {

  //     /** Bind the event to restore the router configuration. It will be called by mandated pages ( Policy Update etc. ) */
  //     this.eventListners.push(
  //       this.eventService.listen<any>(this.eventService.restoreRouterConfig, () => restoreRouterConfig(this.router))
  //     );

  //     const setting$ = this.store.select(AppState1.appSettings).pipe(map(obj => AppSetting.parse(obj)));

  //     this.store.dispatch(new OutdatedAgreementRequested({
  //       user$: this.auth.user$,
  //       appSetting$: setting$
  //     }));

  //     this.store.select(AuthState.outDatedAgreement)
  //       .subscribe(agreementStatus => {
  //         /** Nothing is outdated but agreement timer is still ON, turen it off */
  //         if (agreementStatus == null && !!this.agreementTimer) {
  //           clearInterval(this.agreementTimer);
  //         }

  //         if (!!agreementStatus && !!this.appSetting) {
  //           // Outdated component may not be affecting current users as it may not be applicable to him.
  //           const reqdAgreements = getUserReqdAgreements(this.auth.userId, this.appSetting);
  //           this.agreementSignReqd = isUpdateAgreementReqd(agreementStatus.curr, reqdAgreements.curr).length > 0;
  //           if (this.agreementSignReqd) {
  //             logger.log('Rab tere bhla kre UPDATE REQD', agreementStatus);
  //             this.openAgreementsDialog();
  //           } else {
  //             logger.log('Rab tere bhla kre, User new agreement not REQD', agreementStatus);
  //             restoreRouterConfig(this.router);
  //             if (!!this.agreementTimer) {
  //               clearInterval(this.agreementTimer);
  //             }
  //           }
  //         }
  //       });
  //   }
  // }
}

