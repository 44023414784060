import { EventService } from '@trent/services/event.service';
import { DialogService } from '@trent/services/dialog/dialog.service';
import { Component, OnInit, OnDestroy, Input, ViewChild } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { map } from 'rxjs/operators';
import { BasePage } from '@trent/models/UI/base.page';
import { MessageInfo, fromFailedValidation, readErrorMessage } from '@trent/models/error-handling';
import { Router } from '@angular/router';
import { BaseForm } from '@trent/models/UI/base-form';
import { ContactUs, EnquiryType } from '@trent/models/contact-us/contact-us';
import { NgForm } from '@angular/forms';
import { ValidationOptions } from 'class-validator/types/decorator/ValidationOptions';
import { TimerHelper } from '@trent/models/utility';
import { UserService } from '@trent/services/user.service';
import { UtilityService } from '@trent/services/utility.service';
import { DelegateInspectionService } from '@trent/services/delegate-inspection.service';

/**
 * @author MKN 
 * Contact us form component
 * internally load in contact us component
 */


@Component({
  selector: 'trent-contact-us-form',
  templateUrl: './contact-us-form.component.html',
  styleUrls: ['./contact-us-form.component.scss']
})
export class ContactUsFormComponent extends BaseForm<ContactUs> implements OnInit, OnDestroy {

  isHandset:boolean;
 
  @Input() type : string;

  @ViewChild('enquiryForm', { static: false })
  enquiryForm: NgForm;

  constructor(store: Store, ds: DialogService, es: EventService, private router: Router, private delegateInspectionService : DelegateInspectionService) {
    super(store, ds, es);
    this.title = 'Contact Us';
  }

  //Get enquiry type
  getEnquiryType(){
    switch(this.type){
      case 'CUSTOMER' : return EnquiryType.customer;
      case 'DEALER' : return EnquiryType.dealer;
    }
  }

  ngOnInit() {
    this.us.isHandset$.subscribe(h => this.isHandset = h);

    this.m = new ContactUs();
    this.m.enquiryType = this.getEnquiryType();
    this.em = this.m.clone();
    this.setUpFormValidation();
  }


  ngOnDestroy(): void {
    this.cleanListeners();
  }


  //Form validation
  private setUpFormValidation() {
    const timer = new TimerHelper();
    timer.setInterval(800, 10, () => {
      if (this.enquiryForm == null) {
        return false;
      }

      // Set up the validators dynamically on the form. Note, since form is inside ng-if, form can be recreated
      // in the dom if no data is returned on a subscription. so validation has to be called to ensure it is set on
      // any new instance. as before function, pass out the validation object as well.*/


      this.setFormValidation<ContactUs>(ContactUs, this.enquiryForm, () => {

        const option: ValidationOptions = {
          groups: []
        };
        return { model: this.em, option };
      });

      // this.markDirtyAndValidate(this.companyForm);
      return true;
    });

  }

  //Save api
  submit(){    
      const tempR = this.em.validateSync({ groups: [] });
      if (Object.keys(tempR).length > 0) {
        console.log('validation failed');
        this.showAlert(fromFailedValidation(tempR).messageInfo);
        return;
      }
      this.showLoading(10);
      const prom = this.delegateInspectionService.saveEnquiry(this.em).toPromise();
      prom.then((r) => {
        this.hideLoading();
        
        const msg = new MessageInfo({
          msgCss: 'primary',
          header: 'Thank you',
          description: 'Enquiry generated successfully. One of our executive will connect with you shortly.'
        });
        this.showAlert(msg);
        this.resetForm();
      })
      .catch(err => {
        this.hideLoading();
        this.showAlert(readErrorMessage(err));
      });
  }

  resetForm(){
    this.em = this.m.clone();
  }

}