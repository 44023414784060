import { IValidationMsg } from '../error-handling';
import { toValidationError } from '../utility';
import { Exclude, Expose, instanceToInstance, plainToInstance, Type } from 'class-transformer';
import { IsBoolean, IsDate, IsDefined, IsInt, Max, Min, validateSync, ValidateIf, Equals, IsString } from 'class-validator';
import { PageHtml, PageHtmlSection, PageHtmlType } from '../cms/page-html';
import { ValidatorOptions } from 'class-validator';
import { FileInfo } from '../media/file-info';
import { globalToInstance } from '../cms/page-html-decorator';



@Exclude()
export class PtsMiddleSection extends PageHtml {

  @Expose()
  @IsDefined({ message: 'Service Name should be required' })
  serviceName: string;

  @Expose()
  @IsDefined({ message: 'Image should be required' })
  inputImage: FileInfo;

  @Expose()
  @IsDefined({ message: 'Sub-Image should be required' })
  inputSubImage: FileInfo;

  @Expose()
  @IsDefined({ message: 'Alternate Name for image should be required' })
  altImage: string;

  @Expose()
  @IsDefined({ message: 'Alternate Name for sub-image should be required' })
  altSubImage: string;


  /** auto section will keep the track of all custom properties that have @sectionDef attribute applied to them.
   * This is used for auto generating the html control in the cms page. This value will NOT be saved in the database. */
  autoSection: Record<string, PageHtmlSection> = {};
  /**
   * Adding pageHtmlType in this class
   */
  constructor() {
    super();
    this.pageHtmlType = PageHtmlType.ptsHomeMiddleSection;
    globalToInstance(PtsMiddleSection, this);
  }


  sanitize() {
    super.sanitize();
  }

  clone() {
    const t = instanceToInstance(this);
    t.sanitize();
    return t;
  }

  validateSync(options?: ValidatorOptions): IValidationMsg {
    const r = this.validateSyncBase(this);
    return r;
  }

  // public static parse(obj) {
  //   // return super.parse(obj, PtsMiddleSection);
  //   if (obj == null) { return null; }
  //   const m = plainToInstance<PtsMiddleSection, any>(PtsMiddleSection, obj);
  //   m.sanitize();
  //   return m;
  // }

  validateImage(options?: ValidatorOptions): IValidationMsg {
    let r: IValidationMsg = {};
    if (this.inputImage && this.inputImage.name) {
      const tempSD = this.inputImage.validateSync(options, this.inputImage);
      r = { ...r, ...tempSD };
    } else {
      r['Image'] = ["Please upload image document"];
    }
    return r;

  }


}