import { Injectable, NgModuleRef, Compiler, InjectionToken, NgModuleFactory, Type, Injector } from '@angular/core';
import { logger } from '@trentm/log/logger';

export const lazyModules = {
  platform: {
    token: 'Locus-Loop Lazy Loading custom value.',
    tokenVal: 'core_value',
    name: 'lazyModuleProviders',
    loadChildren: () => import('../../projects/locus-loop/src/app/shared/core-lazy/core-lazy.module').then(m => m.CoreLazyModule)
  }
};

/**
 * Lazy loading mechanism for the site. This service will initiate the loading of various delated response.
 */
@Injectable({
  providedIn: 'root'
})
export class LazyLoaderService {
  private lazyModuleRef: NgModuleRef<any>;

  constructor(private compiler: Compiler, private injector: Injector) { }

  load(container) {
    const m = lazyModules.platform;
    const CUSTOM_TOKEN = new InjectionToken<string>(m.token);
    const providers = [
      { provide: CUSTOM_TOKEN, useValue: m.tokenVal }
    ];
    const lazyModuleInjector = Injector.create({
      providers,
      parent: this.injector,
      name: m.name
    });

    m.loadChildren().then(moduleOrFactory => {
      if (moduleOrFactory instanceof NgModuleFactory) {
        return moduleOrFactory;
      } else {
        return this.compiler.compileModuleAsync(moduleOrFactory);
      }
    }).then((factory: NgModuleFactory<any>) => {
      this.lazyModuleRef = factory.create(lazyModuleInjector);

      // Find the core service and create the instance.
      const serviceType: Type<any> = this.lazyModuleRef.instance.entryServiceType;
      const service = this.lazyModuleRef.injector.get(serviceType);

      // ... code to create component factory and its instance.
      // container.clear();
      // const f = this.lazyModuleRef.componentFactoryResolver.resolveComponentFactory(componentType);
      // const componentRef: ComponentRef<any> = container.createComponent(f);

    })
      .catch(err => {
        logger.error('Dynamic Loading of core lazy module failed', err);
      });
  }
}
