import { DbStatus } from "../base/db-status";
import { IKeyVal, Paging } from "../observable-util/paging";
import { PageHtml, PageHtmlType } from "./page-html";
import { CollectionReference, limit, orderBy, query, Query, QueryConstraint, startAfter, where } from 'firebase/firestore';


  /**
 * @author - Cm
 * @purpose - about page html search parameters
 */

export interface ICmsParam {
    type?: PageHtmlType;
    
    orderBy?: string;
  
    dbStatus?: DbStatus;
  
    createdAt?: Date;

    createdByUid?: string;
  }

  export const cmsSearchOrderBy: IKeyVal[] = [
    { key: 'createdAt to Date', value: `createdAt desc` },
  ];

  export const cmsSearchServerQuery = (ref: CollectionReference, o:ICmsParam, p: Paging) => {
    const cons: QueryConstraint[] = [];
    // const dbStatus = DbStatus;
    if (!!o.type) {
      cons.push(where('pageHtmlType', '==', +o.type));
    }
  
    cons.push(limit(p.size));
    return query(ref, ...cons);
  };

  /** Client Filtering of the data */
  export const cmsSearchClientFilter = (p: PageHtml[], o: ICmsParam): PageHtml[] => {
    if (!!o.dbStatus) {
      p = p.filter((val, idx) => val.dbStatus === +o.dbStatus);
    }
    // filter by type.
    if (o.type != null && <any>o.type !== '*') {
      p = p.filter((val, idx) => val.pageHtmlType === +o.type);
    }
    if (o.createdByUid != null) {
      p = p.filter((val, idx) => val.createdByUid === o.createdByUid);
    }

    return p;
  };

