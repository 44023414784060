import { environment } from './../core/environments/environment';
import { UserProfile } from './user/user-profile';
import { IUserClaim, SignInProvider } from './user/user-claim';
import { copyObject } from './utility/helper';
import { IVersion, AppSetting } from './sys/app-setting';
import { logger } from './log/logger';
import { User } from '@angular/fire/auth';

/** Singlten class to store site level global data, like user profile, claim etc.
 *  A subscriber is created in the app.component class that will update this value.
 */
export class SiteData {

  /**
   * These settings are used in AppComponent.checkAppVersion()
   * Database App Settings are loaded in corelazy service ->  this.us.bindAppSettings(this.singleton);
   * settings are loaded on the Store.
   * */
  public static readonly appVersionLocal1: IVersion = {
  web: '1.0.0',
    cordova: '1.0.0',
    capacitor: '1.0.0'
  };

  // public static appSetting: AppSetting;



  // private static u$: Observable<UserProfile>;
  private static _claim: IUserClaim;

  private static _reAuthTimer;

  private static _isReAuthReqd = true;
  static get isReAuthReqd() {
    return this._isReAuthReqd;
  }

  static get authProvider(): SignInProvider {
    return !!this._claim ? <any>this._claim.firebase.sign_in_provider : 'anonymous';
  }

  private static uFb: User;
  private static u: UserProfile;

  /** Last Sign-in time of the user */
  static get lastSignIn(): Date {
    if (!!SiteData.uFb && !!SiteData.uFb.metadata && !!SiteData.uFb.metadata.lastSignInTime) {
      logger.log(`[SiteData] last sign in time: ${SiteData.uFb.metadata.lastSignInTime}`);
      return new Date(+SiteData.uFb.metadata.lastSignInTime);
    }
    return null;
  }

  /**
   * Firebase user of thesite. This should be preferred over @user as this property will be
   * filled when app is loaded. while @user will be populated on a lazy load.
   */
  public static get userFb(): User {
    return this.uFb;
  }

  public static setUserFb(_uNative: User) {
    this.uFb = _uNative;
  }

  /** User will be filled after the lazy loading upon app load. If it is not necessary,
   * prefer @param
   */
  public static get user(): UserProfile {
    return this.u;
  }

  public static setUser(_u: UserProfile) {
    // logger.log('[Site-Data]: set user', _u);
    this.u = _u;
    if (this.u != null) {
      // logger.log('ROLE before parse: ', this.u.roles);
      this.u = UserProfile.parse(this.u);
      // logger.log('ROLE after parse: ', this.u.roles);
    }
  }

  /** Set from the app component data. */
  static setClaim(c: IUserClaim): void {
    this._isReAuthReqd = true;
    if (!!c && typeof c.auth_time === 'number') {
      SiteData._claim = copyObject(c);
      if (typeof this._claim.auth_time === 'number') {
        // logger.log('Last Auth_Time: ', this._claim.auth_time);

        // duration in seconds since last login time.
        const duration = new Date().getTime() / 1000.0 - this._claim.auth_time;

        // logger.log('duration since last Auth: ', duration);

        // set up limit of 10 min, required for user to re-authentiate if he modifiy the senstive data.
        const allowedSpan = environment.production ? 20.0 * 60 : 2000 * 60; // seconds.

        const remainingDuration = allowedSpan - duration;
        if (remainingDuration > 0) {
          // clear previous timer
          if (!!this._reAuthTimer) {
            clearTimeout(this._reAuthTimer);
            this._reAuthTimer = null;
          }

          // logger.log(`User logged in just: ${duration} seconds ago!
          //   So No re-auth is required now! re-auth will be required after  ${remainingDuration} `);
          this._isReAuthReqd = false;

          this._reAuthTimer = setTimeout(() => {
            // Timer expired,
            // logger.log(`reauth timer interval of ${remainingDuration} is expired.
            //   User will need to relogin before modifiying senstive data`);

            this._isReAuthReqd = true;
          }, remainingDuration * 1000);
        } else {
          // logger.log('Last login was a while ago! User will need to re-login for senstive operation');
        }
      }
    }
  }
}
