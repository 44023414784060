<div class="select-comp-page-cont" *ngIf="showChips">
  <mat-form-field class="full-width" >
    <mat-chip-grid #chipList>
      <mat-chip-row *ngFor="let e of selectedCompanies | keyvalue" [removable]="true"
        (removed)="remove(e)">
        {{e.value.uiDescription}}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip-row>
      <!-- [selectable]="true"  -->
      <input #entryId type="text" aria-label="Number" matInput
        [matAutocomplete]="auto" [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        #entry="ngModel" (ngModelChange)="_filter(entry)" (keyup.enter)="enterBlur()" name="entry"
        [(ngModel)]="selected">
        <!-- [Browser]="inputLabel"  -->
    </mat-chip-grid>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="optionSelected($event.option.value)"
      [displayWith]="displayFn">
      <mat-option *ngFor="let option of filtered | keyvalue" [value]="option">
        <!-- <ng-container >
          <mat-icon color="primary" mat-list-icon>business</mat-icon>
          {{option.value.uiDescription}}
        </ng-container> -->
  
        <mat-list>
          <mat-list-item>
            <div matListItemIcon><span class="comp-name">{{firstChar(option.value.legalName)}}</span></div>
            <span matListItemTitle>{{option.value.companyFriendlyName}}</span>
            <span matListItemLine>{{option.value.addressFormated}}</span>
          </mat-list-item>
        </mat-list>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>
<div class="select-comp-page-cont" *ngIf="!showChips">
  <mat-form-field class="example-full-width">
    <mat-label>{{inputLabel}}</mat-label>
    <!-- <ng-container *ngIf="!!selectedKey">
      {{selectedKey}}
      {{filtered[selectedKey]?.companyFriendlyName}}
    </ng-container> -->
    <input type="text"
           aria-label="Number"
           matInput
           [matAutocomplete]="auto"
           #entryKey="ngModel" (ngModelChange)="_filter(entryKey)" (keyup.enter)="enterBlur()" name="entryKey"
          [(ngModel)]="selectedKey"
          [disabled]="!isEMode">
    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="keySelectedFn($event.option.value)">
      <mat-option *ngFor="let option of filtered | keyvalue" [value]="option.key">
        <mat-list>
          <mat-list-item>
            <div matListItemIcon><span  class="comp-name">{{firstChar(option.value.legalName)}}</span></div>
            <span matListItemTitle>{{option.value.companyFriendlyName}}</span>
            <span matListItemLine>{{option.value.addressFormated}}</span>
          </mat-list-item>
        </mat-list>      
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>