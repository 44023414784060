import 'reflect-metadata';
import { DbRule } from './db-rule';
import { ValidationError, ValidatorOptions, validateSync } from 'class-validator';
import { IValidationMsg } from '../error-handling/validation-info';
import { sanitizeDate } from '../utility/sanitize-helper';
import { firestoreMap, cleanupUndefined, toValidationError } from '../utility';
// import * as firebase from 'firebase/app';
// import 'firebase/firestore';
import { DbStatus } from './db-status';
import { Exclude, Expose, instanceToPlain } from 'class-transformer';
import { logger } from '../log/logger';

@Exclude()
export class BaseModel {

  /** ID can be of type string or a number. This will not be saved in the firestore db 
   * id is with reference to the collection name. if the record points to a sub collection, then its value
   * is the full path. example:  xxxxxxxxx/dev/id
  **/
  @Expose()
  id: string | number;


  @Expose()
  createdAt: Date;


  @Expose()
  updatedAt: Date;


  /** Who created this record */
  @Expose()
  createdByUid: string | number;


  /** Who last updated this record. */
  @Expose()
  updatedByUid: string | number;


  /** Company ID. */
  @Expose()
  cid: string | number;


  @Expose()
  uid: string | number;


  /** Db Staus of this record. */
  @Expose()
  dbStatus: DbStatus;


  /** Db Revision information of this record. */
  @Expose()
  revId: number;


  /** Draft ID related to this record. that rev contain pending modifications.*/
  @Expose()
  draftId: string | number;


  /** @deprecated , was used in toFirebaseObj. Not used now, as it is done using instanceToPlain. */
  static getBaseIgnoreList(): string[] {
    return ['id', 'rootId', 'isFrozenReleaseBranch', 'isDraft', 'hasDraft', 'draftIdUrl'];
  }

  /** Latest released rev. It is used to find out if current record is latest.*/
  get rootId() { return DbRule.getRootId(this.id); }

  /* if id is from rev branch, that means this record is frozen.
  so LatestId/draftId may not be uptodate on this record.
  For root and draft entries, dbstatus/release information is always latest.*/
  get isFrozenReleaseBranch() { return DbRule.isFrozenReleaseBranch(`${this.id}`); }

  /** If current record is in draft state. either this record is not released/locked, Or it has an associated
   * dratt id. */
  get isDraft(): boolean {
    return this.dbStatus === DbStatus.Initial || this.dbStatus === DbStatus.ReleasedMod;
  }

  /** If current record has a draft version id. (draft version OF this record, i.e not this object.*/
  get hasDraft(): boolean {
    return this.draftId != null;
  }

  /** effective id, that points to the current revision as required. i.e it is id/revId */
  get draftIdUrl() {
    // if id, is already a path to subcollection record, just return it by substituting / by ~
    const idstr = `${this.id}`.replace('%2', '/');

    if (idstr.includes('/')) {
      return idstr;
    }
    return (this.dbStatus === DbStatus.Initial || this.draftId == null) ? this.id :
      `${this.id}/draft/${this.draftId}`;
  }

    /** If current record has a draft version id. (draft version OF this record, i.e not this object.*/
    get isSubmitted(): boolean {
      return this.dbStatus === DbStatus.SubmitedForApproval;
    }
  /** effective id, that points to the current revision as required. i.e it is id/revId
   * @param col | Collection Name
  */
  getDraftIdUrl(col: string) {
    // if id, is already a path to subcollection record, just return it by substituting / by ~
    const idstr = `${this.id}`.replace('%2', '/');

    if (idstr.includes('/')) {
      return idstr;
    }
    return (this.dbStatus === DbStatus.Initial || this.draftId == null) ? this.id :
      `${this.id}/${col}-draft/${this.draftId}`;
  }
  /** if this is from draft or from root branch, this is always latest.
 * otherwise, compare with provided rootM.
 * see @property latestRevId for the latest revision. */
  isLatest(rootM: BaseModel): boolean {
    if (this.isFrozenReleaseBranch) {
      // if (!isNaN(+this.revId) && !isNaN(+this.latestRevId)) {
      //   return +this.revId >= +this.latestRevId;
      // }
      return +this.revId >= +rootM.revId;
    }
    return true;
  }

  /** effective id, that points to the latest version of tihs record. */
  // get latedIdUrl() { return (this.latestRevId == null) ? this.id : `${this.id}~rev~${this.latestRevId}`; }


  /** Increment the REV level of the record. */
  incrementRev() {
    this.revId = (this.revId == null || isNaN(+this.revId) || +this.revId <= 0) ? 1 :
      this.revId + 1;
  }

  // managing the release status.
  public validateSyncBase($this: any, options?: ValidatorOptions, forbidUnknownValues?:boolean): IValidationMsg {
    if(!options){
      options = {};
    }
    options.forbidUnknownValues = forbidUnknownValues ? true : false;
    const r = validateSync($this, options);
    const m = toValidationError(r);
    return m;
  }

  /** Helper Method  to convert validation errors to easy display messages messages */
  protected toValidationError(ve: ValidationError[]): IValidationMsg {
    return toValidationError(ve);
  }

  sanitize() {
    // if data was recieved from firebase, date is stored as snapshot.
    this.createdAt = sanitizeDate(this.createdAt);
    this.updatedAt = sanitizeDate(this.updatedAt);
    // return this;
  }


  /**
   * @param timeStampCreate if null/undefined: no update,
   * If True: createdAt and updatedAt filed are set as time stamp.
   * If False: only updateAt field is updated with time stamp.
   * @param getGeoPointFn function to be used to update GeoPoint key, if present
   */
  public toFirebaseObj(timeStampCreate?: boolean,
    getGeoPointFn?: (obj: any) => any, ignore?: string[]) {

    // user class to plain
    let obj = instanceToPlain(this);
    obj = cleanupUndefined(obj);

    // const a = (!!ignore && ignore.length > 0) ?
    //   Array.prototype.push.apply(BaseModel.getBaseIgnoreList(), ignore) :
    //   BaseModel.getBaseIgnoreList();

    const a = (!!ignore && ignore.length > 0) ? Array.prototype.push.apply(['id'], ignore) : ['id'];
    const o = firestoreMap(obj, a); // , getGeoPointFn);
    if (timeStampCreate != null) {
      // block for timestamp error at gps deployed functions
      // o.updatedAt = firebase.firestore.FieldValue.serverTimestamp();
      logger.log('updatedAt blocked at base model');
      logger.log('timeStampCreate', timeStampCreate);
      if (timeStampCreate) {
        logger.log('createdAt blocked at base model');
        // o.createdAt = firebase.firestore.FieldValue.serverTimestamp();
      }
    }
    return o;
  }

}

