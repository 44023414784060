import { ProductType } from '../product';
import { TripBase } from './trip-base';
import { TripParam } from './trip-param';
import { TripStatus } from './trip-status';

/** Client Filtering of the data */
export const tripSearchClientFilter = (p: TripBase[], o: TripParam): TripBase[] => {
  // filter by type. MyTrips show all, Search Rent option show released only
  p = p.filter((val) => val.productType === +ProductType.trip);
  if (!!o.dbStatus) {
    p = p.filter((val) => val.dbStatus === +o.dbStatus);
  }
  // filter by Trip type. MyRent Options show all, Search Rent option show released only
  // if (o.tripType != null && <any>o.type !== '*') {
  //   p = p.filter((val) => val.tripType === +o.tripType);
  // }

  // filter by cid
  if (o.cids.length > 0 && !o.trackingNo) {
    p = p.filter((val) => o.cids.indexOf(`${val.carrierCompanySummary.cid}`) > -1);
  }
  if (o.isOpen !== undefined && !o.tripStatus) {
    p = p.filter(val => val.isOpen === o.isOpen);
  }
  if (o.isExpired !== undefined) {
    // p = p.filter(val => val.isExpired === o.isExpired);
    p = p.filter(val => val.tripStatus !== TripStatus.expired);
  }
  if (!!o.truck) {
    p = p.filter(val => { if (!!val.activeTrucks && val.activeTrucks.includes(o.truck)) { return true; } else { return false; } });
  }
  if (!!o.trailer) {
    p = p.filter(val => { if (!!val.activeTrailers && val.activeTrailers.includes(o.trailer)) { return true; } else { return false; } });
  }
  if (!!o.tripStatus && o.tripStatus !== <any>'*') {
    p = p.filter(val => val.tripStatus === o.tripStatus);
  }
  if (!!o.driver) {
    p = p.filter(val => val.driverIdOrPhone === o.driver);
  }
  if (!!o.searchEndDate && !!o.searchStartDate) {
    p = p.filter(r => r.tripSequence[0].arrivalTime.valueOf() < o.searchStartDate.valueOf() &&
      r.tripSequence[r.tripSequence.length - 1].arrivalTime.valueOf() > o.searchStartDate.valueOf() &&
      r.tripSequence[0].arrivalTime.valueOf() < o.searchEndDate.valueOf() &&
      r.tripSequence[r.tripSequence.length - 1].arrivalTime.valueOf() > o.searchEndDate.valueOf()
    );
  }
  if (!!o.trackingNo) {
    p = p.filter(r => r.trackingNumbers.includes(o.trackingNo));
  }

  return p;




};
