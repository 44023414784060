import { Injectable } from '@angular/core';
import { FirestoreService } from '@trent/services/firestore.service';
import { map, tap } from 'rxjs/operators';
import { instanceToPlain } from 'class-transformer';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { BaseHttpService } from '@trent/services/base-http.service';
import { Store } from '@ngxs/store';
import { Observable, firstValueFrom, from } from 'rxjs';
import { PagingObesrvable } from '@trent/models/observable-util/paging-obesrvable';
import { Paging } from '@trent/models/observable-util/paging';
import { logger } from '@trent/models/log/logger';
import { LegacyContractBase, LegacyContractValidationGroup } from '@trent/models/legacy-contract/legacy-contract-base';
import { CancelledAllChildRecordStatus, ILegacyContractParam, legacyContractSearchServerQuery, legacyContractSearchServerQueryWithCount } from '@trent/models/legacy-contract';
import { limit, orderBy, query, startAfter } from '@angular/fire/firestore';
import { collection, getCountFromServer, getFirestore } from '@angular/fire/firestore';

declare var google: any;
@Injectable()
export class LegacyContractService extends BaseHttpService {

    lCList: PagingObesrvable<LegacyContractBase, any>;

    constructor(store: Store, private db: FirestoreService, private http: HttpClient) {
        super(store);
        this.lCList = new PagingObesrvable<LegacyContractBase, string>(this.db, (this.getLegacyContracts_getBatch));
        this.apiName = 'api';
    }

    public update(lCId: string | number, lC: LegacyContractBase, cancelledAllChildRecordStatus : CancelledAllChildRecordStatus, childMasterId : string,  option?: LegacyContractValidationGroup) {
        // Prepare the post data
        const headers = this.addBearerToken();

        // Server validation before creating the entry.
        return this.http.post<{ id: string | number, data: LegacyContractBase }>(this.getApiUrl('/legacy-contract/update'),
            {
                // tslint:disable-next-line:object-literal-shorthand
                lCId,
                lC: instanceToPlain(lC),
                cancelledAllChildRecordStatus,
                childMasterId,
                option: option
            },
            // tslint:disable-next-line:object-literal-shorthand
            { headers: headers })
            .pipe(
                tap(r => logger.log('response: ', r)),
            );
    }

    public create(lC: LegacyContractBase, option?: LegacyContractValidationGroup) {
        // Prepare the post data
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + this.token });

        // Server validation before creating the entry.
        return this.http.post<{ id: string | number, data: LegacyContractBase }>(this.getApiUrl('/legacy-contract/create'),
            {
                lC: lC,
                option: option
            },
            // tslint:disable-next-line:object-literal-shorthand
            { headers: headers })
            .pipe(
                tap(r => logger.log('response: ', r)),
            );
    }

    /**
     * Bulk upload legacy contract records
     * @param lCList 
     * @returns 
     */
    public bulkCreate(lCList: LegacyContractBase[]) {
        // Prepare the post data
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + this.token });

        // Server validation before creating the entry.
        return this.http.post<any>(this.getApiUrl('/legacy-contract/bulk-create'),
            {
                lCList: instanceToPlain(lCList),
            },
            // tslint:disable-next-line:object-literal-shorthand
            { headers: headers })
            .pipe(
                tap(r => logger.log('response: ', r)),
            );
    }

    /**
     * Update legacy contract by sNo
     * @param lCList 
     * @returns 
     */
    public bulkUpdateDataBySno(lCList: any[]) {
        // Prepare the post data
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + this.token });

        // Server validation before creating the entry.
        return this.http.post<any>(this.getApiUrl('/legacy-contract/bulk-modify-data-by-sno'),
            {
                lCList: instanceToPlain(lCList),
            },
            // tslint:disable-next-line:object-literal-shorthand
            { headers: headers })
            .pipe(
                tap(r => logger.log('response: ', r)),
            );
    }

    /**
     * set Obsolete Record
     * @param lCId 
     * @param isObsolete 
     * @returns 
     */
    public setObsoleteRecord(lCId: string | number,isObsolete : boolean) {
        // Prepare the post data
        const headers = this.addBearerToken();

        // Server validation before creating the entry.
        return this.http.post<{ id: string | number, data: LegacyContractBase }>(this.getApiUrl('/legacy-contract/set-obsolete-record'),
            {
                // tslint:disable-next-line:object-literal-shorthand
                lCId,
                isObsolete
            },
            // tslint:disable-next-line:object-literal-shorthand
            { headers: headers })
            .pipe(
                tap(r => logger.log('response: ', r)),
            );
    }


    public getAllLegacyContracts() {
        return this.db.colWithIdsInjected$<LegacyContractBase>(LegacyContractBase.collectionName);
    }

    getLegacyContractById(id: string | number) {
        return this.db.docWithInjectedId$<LegacyContractBase>(`${LegacyContractBase.collectionName}/${id}`, id);
    }

    // #region Paging Load
    public getLegacyContractsByLeaseNo(p: Paging, leaseNo: string | number) {
        return this.lCList.getData(p, leaseNo);
    }

    /**Generate new doc id for legacy contract collection */
    getNewDocId() {
        return this.db.docId(LegacyContractBase.collectionName);
    }

    /**
     * Get the next batch of the data from server.
     * @param p paging information.
     */
    private getLegacyContracts_getBatch(p: Paging): Observable<{ [key: string]: LegacyContractBase }> {
        logger.log('Legacy Contracts called with', p.offset, p.size);
        return this.db
            .colWithIdsInjected$<LegacyContractBase>(`${LegacyContractBase.collectionName}`, ref =>
                query(ref,
                    // .where('owner.uid', '==', uid)  /** OWNER */
                    //  .where(`authUsers.${uid}`, '>', 0)
                    // .where(`authUsers.${uid}.dataFlag`, '>', 0)
                    // .where('adminId', 'array-contains', uid)
                    orderBy('sNo'),
                    startAfter(p.offset),
                    limit(p.size))
            )
            .pipe(
                tap(arr => {
                    if (arr == null || arr.length === 0) {
                        logger.log('All data is received, Paging is FULL');
                        p.full = true;
                    }
                }),
                map(arr => {
                    // logger.log('From Server, before mapping is applied: ', arr);
                    const arrayAsObj = arr.reduce((acc, cur) => {
                        const id = cur.sNo;
                        const data = cur;
                        return { ...acc, [id]: data };
                    }, {});
                    // logger.log('after converting to array object dic ', arrayAsObj);
                    return arrayAsObj;
                })
            );
    }

    /**
     * ger all Legacy Contracts (admin request)
     */
    public getAllLegacyContracts_PagingObservable() {
        const p$: PagingObesrvable<LegacyContractBase, ILegacyContractParam> =
            new PagingObesrvable<LegacyContractBase, ILegacyContractParam>(this.db,
                (p: Paging, param?: ILegacyContractParam) => this.getLegacyContracts_Batch(p, param));
        return p$;
    }

    /**
     * Get the next batch of the data from server.
     * @param p paging information.
     */
    private getLegacyContracts_Batch(p: Paging, param: ILegacyContractParam): Observable<{ [key: string]: LegacyContractBase }> {
        // if (param.orderBy == null) { param.orderBy = 'updatedAt'; }
        // if (param.orderDirection == null) { param.orderDirection = 'desc'; }
        return this.db
            .colWithIdsInjectedNew$<LegacyContractBase>(`${LegacyContractBase.collectionName}`, ref => legacyContractSearchServerQuery(ref, param, p))
            .pipe(
                tap(arr => {
                    // logger.log(arr, 'response from server11');
                    if (arr == null || arr.length === 0) {
                        logger.log('All data is received, Paging is FULL');
                        p.full = true;
                    } else {
                        p.lastDoc = arr[arr.length - 1];
                    }
                }),
                map(arr => {
                    // logger.log(arr, 'response from server22');

                    logger.log('From Server, before mapping is applied: ', arr);

                    const arrayAsObj = arr.reduce((acc, cur) => {
                        const id = cur.id;
                        const data = cur;
                        return { ...acc, [id]: data };
                    }, {});

                    logger.log('after converting to array object dic ', arrayAsObj);
                    return arrayAsObj;
                })
            );
    }


    /**
   * 
   * @param addr 
   * input element is fetched where we have address
   * 1. Initiate places service and pass addr to textSearch method to get the place id
   * 2. Using the place id trigger the getDetails method to get details of the place
   * 3. Update flag if address is checked to not retrigger the address verification
   * @returns 
   */
    public placeResultsFromPartialAddress(addr: string, input: HTMLInputElement) {
        try {
            const placeServiceComp = new google.maps.places.PlacesService(input);
            return new Promise(function (resolve, reject) {
                placeServiceComp.textSearch({ query: addr }, (results, status) => {
                    if (status !== google.maps.places.PlacesServiceStatus.OK) {
                        resolve('');
                    } else {
                        resolve(results);
                    }
                });
            });
        } catch (error) {
            console.log(error);
        }
    }
    public placeDetailsFromPartialAddress(results: any, input: HTMLInputElement) {
        try {
            const placeServiceComp = new google.maps.places.PlacesService(input);
            return new Promise(function (resolve, reject) {
                placeServiceComp.getDetails({
                    placeId: results[0].place_id
                }, (details, status) => {
                    if (status !== google.maps.places.PlacesServiceStatus.OK) {
                        resolve('');
                    } else {
                        resolve(details);
                    }
                });
            });
        } catch (error) {
            console.log(error);
        }
    }
    public getSecureUrl(path: string) {
        // Prepare the post data
        const headers = this.addBearerToken();
        return firstValueFrom(this.http.post<{ url: string }>(this.getApiUrl('/legacy-contract/getSecureUrl'),
            { path },
            // tslint:disable-next-line:object-literal-shorthand
            { headers: headers })
            .pipe(map(x => (!!x && !!x.url && x.url.length) ? x.url[0] : ''))
        );
    }
    public archiveFile(path: string) {
        // Prepare the post data
        const headers = this.addBearerToken();
        return firstValueFrom(this.http.post<{ url: string }>(this.getApiUrl('/legacy-contract/archive-file'),
            { path },
            // tslint:disable-next-line:object-literal-shorthand
            { headers: headers })
            .pipe(map(x => (!!x && !!x.url && x.url.length) ? x.url[0] : ''))
        );
    }

    getCount(param): Observable<number> {
        const db = getFirestore();
        const rent = collection(db, LegacyContractBase.collectionName);
        const q = legacyContractSearchServerQueryWithCount(rent, param);
        const countObservable = from(getCountFromServer(q));
        return countObservable.pipe(map(r => r.data().count));
    }

    /**
     * Export Data (CSV)
     * @param params 
     * @returns 
     */
    public exportData(params : ILegacyContractParam, totalCount : number) {
        // Prepare the post data
        return this.getLegacyContracts_Batch({ size: totalCount, offset: 0,  full: false }, params);
    }
}


/**
 * placeServiceComp.textSearch({ query: addr }, (results, status) => {
                if (status !== google.maps.places.PlacesServiceStatus.OK) {
                    throw new Error('Unable to fetch place from address provided');
                }
                placeServiceComp.getDetails({
                    placeId: results[0].place_id
                }, (details, status) => {
                    if (status !== google.maps.places.PlacesServiceStatus.OK) {
                        throw new Error('Unable to fetch place from address provided');
                    }
                    return (details);
                });
            });
 */