<div class="pb80">

  <trent-rent-option-list [horizontalCarousel]="true" [isChild]="true"></trent-rent-option-list>

  <div class="container-form" *ngIf="isHandset">
    <!-- <p>Interested for</p> -->

    <!-- upper button section to select-->
    <!-- <div class="slider" #slider></div> -->
    <div class="btn-container">

      <button mat-flat-button [color]="clientColor" (click)="onClient()">Client</button>
      <button mat-flat-button [color]="dealerColor" (click)="onDealer()">Dealer</button>
    </div>
    <!-- <div class="slider-btn">
  </div> -->
    <mat-tab-group mat-align-tabs="start" [selectedIndex]="formIndex">
      <mat-tab>
        <trent-contact-us-form [type]="'CUSTOMER'"></trent-contact-us-form>
      </mat-tab>
      <mat-tab>
        <trent-contact-us-form [type]="'DEALER'"></trent-contact-us-form>
      </mat-tab>
    </mat-tab-group>
  </div>

  <div *ngIf="!isHandset" [ngClass]="{'card-container': !isHandset}">
    <trent-contact-us-form [type]="'CUSTOMER'" style="justify-self: end;"></trent-contact-us-form>
    <trent-contact-us-form [type]="'DEALER'" style="justify-self: start;"></trent-contact-us-form>
  </div>
  <trent-sub-page [pageId]="'contact'" [showHtmlEditMenu]="false" class="sub-page"> </trent-sub-page>
</div>