import { IKeyVal, Paging } from '../observable-util/paging';
import { TripTracker, TripTrackerStatus } from './trip-tracker';
import { IPoint } from '../product-search/interfaces';
import { Address } from '../address/address';
import { TripActivityType } from './trip-activity-type';
import { cloneDeep } from 'lodash';
import { logger } from '../log/logger';
import { CollectionReference, limit, orderBy, query, Query, QueryConstraint, startAfter, where } from 'firebase/firestore';

export interface ITripTrackerParam {
  cid?: number | string;
  orderDirection?: 'desc' | 'asc';
  orderBy?: string;
  trackingNo?: string;
  tripTrackerStatus?: TripTrackerStatus;
  pickupPostalZipCode?: string;
  deliveryPostalZipCode?: string;
  pickupCity?: string;
  deliveryCity?: string;
  pickupStateProv?: string;
  deliveryStateProv?: string;
  previousDate?: Date;
  pickup?: Address;
  delivery?: Address;
  uid?: string | number;
}
export const tripTrackerParamInit = (): ITripTrackerParam => {
  return {
    orderBy: 'createdAt',
    tripTrackerStatus: TripTrackerStatus.active,
    orderDirection: 'desc',
    pickupPostalZipCode: null,
    deliveryPostalZipCode: null,
    pickupCity: null,
    deliveryCity: null,
    pickupStateProv: null,
    deliveryStateProv: null,
    previousDate: null,
    pickup: null,
    delivery: null,
    trackingNo: null,
    uid: null
  };
};

/** Server filtering of firebase query building of query  */
export const tripTrackerSerachServerQuery = (ref: CollectionReference, param: ITripTrackerParam, p: Paging) => {
  let q: Query = <any>ref;
  const cons: QueryConstraint[] = [];

  // if (!!param.displayName) {
  //   cons.push(where('displayName', '==', param.displayName.toLowerCase()));
  // }


  if (!!param.cid) {
    cons.push(where('carrierCompanySummary.cid', '==', param.cid));  // vendorCompSummary
  }
  if (!!param.trackingNo) {
    cons.push(where('trackingNo', '==', param.trackingNo));
    // q = q.where('trackingNo', '==', '97064-64998-877344');
  }
  if (!!param.tripTrackerStatus) {
    cons.push(where('tripTrackerStatus', '==', param.tripTrackerStatus));
  }
  if (!!param.pickupPostalZipCode) {
    cons.push(where('pickupTracker.activity.address.postalZipCode', '==', param.pickupPostalZipCode));
  }
  if (!!param.deliveryPostalZipCode) {
    cons.push(where('deliveryTracker.activity.address.postalZipCode', '==', param.deliveryPostalZipCode));
  }
  if (!!param.pickupCity) {
    cons.push(where('pickupTracker.activity.address.city', '==', param.pickupCity));
  }
  if (!!param.deliveryCity) {
    cons.push(where('deliveryTracker.activity.address.city', '==', param.deliveryCity));
  }
  if (!!param.pickupStateProv) {
    cons.push(where('pickupTracker.activity.address.stateProv', '==', param.pickupCity));
  }
  if (!!param.deliveryStateProv) {
    cons.push(where('deliveryTracker.activity.address.stateProv', '==', param.deliveryCity));
  }
  if (!!param.uid) {
    cons.push(where('pickupTracker.activity.freightCustomer', 'array-contains', param.uid));
  }
  if (!param.trackingNo && !param.uid) {
    if (!!param.previousDate) {
      cons.push(where('createdAt', '<=', param.previousDate));
      cons.push(orderBy('createdAt', 'desc'));
    } else {
      cons.push(orderBy('createdAt', 'desc'));
    }
  }
  if (!!p.lastDoc) {
    logger.log('Start After Query: ', p.lastDoc[param.orderBy]);
    cons.push(startAfter(p.lastDoc[param.orderBy]));
  }
  cons.push(limit(p.size));
  q = query(ref, ...cons);
  return q;
};
/** Client side filtering of the orders. */
export const tripTrackerSerachClientFilter = (p: TripTracker[], o: ITripTrackerParam) => {

  if (!!o.cid) {
    p = p.filter(val => val.carrierCompanySummary.cid === o.cid);
  }
  if (!!o.trackingNo) {
    p = p.filter(val => val.trackingNo === o.trackingNo);
  }
  if (!!o.tripTrackerStatus) {
    p = p.filter(val => val.tripTrackerStatus === o.tripTrackerStatus);
  }
  if (!!o.pickupPostalZipCode) {
    p = p.filter(val => val.pickupTracker.activity.address.postalZipCode === o.pickupPostalZipCode);
  }
  if (!!o.deliveryPostalZipCode) {
    p = p.filter(val => val.deliveryTracker.activity.address.postalZipCode === o.deliveryPostalZipCode);
  }
  if (!!o.pickupCity) {
    p = p.filter(val => val.pickupTracker.activity.address.city === o.pickupCity);
  }
  if (!!o.deliveryCity) {
    p = p.filter(val => val.deliveryTracker.activity.address.city === o.deliveryCity);
  }
  if (!!o.pickupStateProv) {
    p = p.filter(val => val.pickupTracker.activity.address.stateProv === o.pickupStateProv);
  }
  if (!!o.deliveryStateProv) {
    p = p.filter(val => val.deliveryTracker.activity.address.city === o.deliveryStateProv);
  }
  if (!!o.previousDate) {
    p = p.filter(val => val.updatedAt.valueOf() <= o.previousDate.valueOf());
  }
  if (!!o.uid) {
    p = p.filter(val => val.pickupTracker.activity.freightCustomer.includes(`${o.uid}`));
  }

  return p;
};
export const getITripTrackerOptionOrChildren = (o: ITripTrackerParam): { [key: string]: ITripTrackerParam } => {
  // if (!!o.cids && o.cids.length > 1) {
  //   const r: { [key: string]: PickDropParam } = {};
  //   o.cids.forEach((cid) => {
  //     const c = DeepCopy.copy(o);
  //     c.cids = [cid];
  //     r[getObjKey(c)] = c;
  //   });
  //   return r;
  // }

  return undefined;
};
/**
 * convert address to query, when address has postal code, return geogash from the addreess.
 * when only city is defined return city and when only. When only state or province is defined return state/province
 * @param address Address
 * @param aType Activity Type Pick Up or Delivery
 */
export const searchAddressToQueryBy = (address: Address, aType: TripActivityType.pickUp | TripActivityType.delivery,
  p: ITripTrackerParam): ITripTrackerParam => {
    const q = cloneDeep(p);
    q.pickup = Address.parse(p.pickup).clone();
    q.delivery = Address.parse(p.delivery).clone();
    // q.pickup = new Address();
    // q.delivery = new Address();
  if (!address) {
    return null;
  }
  if (address.postalZipCode) {
    switch (aType) {
      case TripActivityType.pickUp:
        q.pickupPostalZipCode = address.postalZipCode;
        q.pickupCity = null;
        q.pickupStateProv = null;
        q.pickup = address;
        return q;
      case TripActivityType.delivery:
        q.deliveryPostalZipCode = address.postalZipCode;
        q.deliveryCity = null;
        q.deliveryStateProv = null;
        q.delivery = address;
        return q;
      default:
        return null;
    }
  }
  if (!!address.city) {
    switch (aType) {
      case TripActivityType.pickUp:
        q.pickupPostalZipCode = null;
        q.pickupCity = address.city;
        q.pickupStateProv = null;
        q.pickup = address;
        return q;
      case TripActivityType.delivery:
        q.deliveryPostalZipCode = null;
        q.deliveryCity = address.city;
        q.deliveryStateProv = null;
        q.delivery = address;
        return q;
      default:
        return null;
    }
  }
  if (!!address.stateProv) {
    switch (aType) {
      case TripActivityType.pickUp:
        q.pickupPostalZipCode = null;
        q.pickupCity = null;
        q.pickupStateProv = address.stateProv;
        q.pickup = address;
        return q;
      case TripActivityType.delivery:
        q.deliveryPostalZipCode = null;
        q.deliveryCity = null;
        q.deliveryStateProv = address.stateProv;
        q.delivery = address;
        return q;
      default:
        return null;
    }
  }
  return null;
};
