<!-- <div style="height: env(safe-area-inset-top) !important;"></div>  -->
<div class="t-bar"></div>
<div class="social-area" *ngIf="!isHandset">
  <div class="social-area-outer">
    <div class="social-area-inner">
      Call for Local Branch &nbsp;
      <mat-icon>phone_enabled</mat-icon>
      <a href="tel:1-844-727-8247" class="a-tel">1-844-727-8247</a>
    </div>
    <div class="social-area-inner l-bar">
      Follow us
      <ng-container *ngFor="let item of socials">
        <a href="{{item.url}}" target="_blank">
          <fa-icon [icon]="['fab', item.icon]" class="social-icon"></fa-icon>
        </a>
      </ng-container>

    </div>
  </div>
  <div class="social-area-outer">
    <a href="https://prideev.com/" target="_blank">
      <img class="img" style="height: 22px; width: auto;padding-top: 4px;" src="./assets/Pride-EV.webp" alt="PrideEv" />
    </a>
    <div class="social-area-inner l-bar w-loc">
      <mat-icon class="l-icon">location_on</mat-icon>
      <!-- 38+ Locations & Growing -->

      <trent-sub-page [pageId]="'location-growing'" class="sub-page"> </trent-sub-page>

    </div>
  </div>

</div>
<div class="b-bar" *ngIf="!isHandset"></div>
<mat-sidenav-container class="sidenav-container">
  <!-- Side Menu only shown in mobile - Start  -->
  <mat-sidenav #drawer class="sidenav" fixedInViewport [attr.role]="isHandset? 'dialog' : 'navigation'"
    [mode]="isHandset$ ? 'over' : 'side'" [opened]="false">
    <!--  [opened]="(isHandset$ | async) === false" -->
    <mat-toolbar class="row1-mb">
      <!-- <mat-icon>keyboard_arrow_left</mat-icon> -->
      <h3>Menu</h3>
      <mat-icon (click)="handleClose()" class="pull-right">close</mat-icon>
    </mat-toolbar>
    <mat-nav-list>
      <a *ngIf="isAdmin || isSalesOperationDpc" mat-list-item href="javascript:void(0)" (click)="togglePageEdit()">
        <!-- <i class="material-icons">home</i> -->
        <mat-icon aria-hidden="false" aria-label="home icon">edit</mat-icon>
        Toggle Page Edit
      </a>

      <a *ngIf="!isAuth" mat-list-item (click)="handleClose();login()">
        <mat-icon aria-hidden="false" aria-label="home icon">login</mat-icon> Login
      </a>
      <a *ngIf="isAuth" mat-list-item (click)="logout()">
        <mat-icon aria-hidden="false" aria-label="home icon">logout</mat-icon> Logout {{ displayName }}
      </a>
      <mat-divider></mat-divider>

      <div class="side-menu">
        <ul>
          <li (click)="handleClose()"><a href="javascript:void(0)" [routerLink]="['/']">HOME</a></li>

          <ng-container *ngFor="let a of menuFilt;let i=index">

            <!-- no sub menu       -->
            <ng-container *ngIf="!a.subMenu">
              <li (click)="homeClick();handleClose()"><a href="javascript:void(0)" [routerLink]="[a.url]">{{a.title}}</a></li>
            </ng-container>

            <!-- has sub menu  -->
            <ng-container *ngIf="!!a.subMenu">

              <li (click)="openMenu($event,i)"><a href="javascript:void(0)">{{a.title}} <mat-icon>{{ showMenu === i ?
                    'expand_more' : 'chevron_right'}}</mat-icon></a>
                <ul [@slideInOut]="showMenu === i">
                  <ng-container *ngFor="let b of getFilterArray(a.subMenu);let j=index">
                    <ng-container *ngIf="!b.subMenu">
                      <li><a href="javascript:void(0)" [routerLink]="[b.url]" (click)="handleClose();handleClose()">{{b.title}}</a></li>
                    </ng-container>
                    <ng-container *ngIf="!!b.subMenu">
                      <li (click)="openSubMenu($event,j)"><a href="javascript:void(0)">{{b.title}} <mat-icon>{{
                            showSubMenu === j ? 'expand_more' : 'chevron_right'}}</mat-icon></a>
                        <ul [@slideInOut]="showSubMenu === j">
                          <ng-container *ngFor="let c of getFilterArray(b.subMenu);let k=index">
                            <ng-container *ngIf="!c.subMenu">
                              <li><a href="javascript:void(0)" [routerLink]="[c.url]" (click)="handleClose()">{{c.title}}</a></li>
                            </ng-container>
                            <ng-container *ngIf="!!c.subMenu">
                              <li (click)="openSubSubMenu($event,k)"><a href="javascript:void(0)">{{c.title}}
                                  <mat-icon>{{ showSubSubMenu === k ? 'expand_more' : 'chevron_right'}}</mat-icon></a>
                                <ul [@slideInOut]="showSubSubMenu === k">
                                  <ng-container *ngFor="let d of getFilterArray(c.subMenu)">
                                    <li><a href="javascript:void(0)" [routerLink]="[d.url]" (click)="handleClose()">{{d.title}}</a></li>
                                  </ng-container>
                                </ul>
                              </li>
                            </ng-container>
                          </ng-container>
                        </ul>
                      </li>

                    </ng-container>
                  </ng-container>
                </ul>
              </li>
            </ng-container>
          </ng-container>
        </ul>
      </div>
      <!-- logout at last -->
      <mat-divider></mat-divider>
      <a *ngIf="isAuth && isMultipleComp" mat-list-item routerLink="/company" (click)="handleClose()">
        <mat-icon aria-hidden="false" aria-label="home icon">settings</mat-icon>
        Change Company
      </a>
      <a mat-list-item disabled class="def-company" *ngIf="isAuth && dComp">
        <mat-icon>business</mat-icon>
        <div>{{dComp.cName}}</div>
      </a>

      <!-- <a *ngIf="isAuth" mat-list-item routerLink="/login" (click)="handleClose()">
        <i class="fas fa-sign-out-alt"></i>
        Logout
      </a> -->
    </mat-nav-list>
  </mat-sidenav>
  <!-- Side Menu only shown in mobile - End  -->

  <mat-sidenav-content id="shellSideNav" [ngClass]="{'bg-image': showBg, 'bg-image-mb': showBg && isHandset && !isLandscape, 'bg-image-mb-landscape': isLandscape && !isFixViewPort,
  'fix-view-port': isFixViewPort, 'native-view-port': !isFixViewPort, 'bg-image-landing': showLanding}">
    <mat-toolbar color="primary" [ngClass]="{ 'shrink-dt': shrinkToolbar, 'reg-dt': !shrinkToolbar }">
      <!-- Header Menu : MOBILE START -->
      <mat-toolbar-row class="row1-mb" *ngIf="isHandset">
        <div class="menu-btn">
          <div class="menu-icon" *ngIf="!showBackBtn" (click)="drawer.toggle()"></div>
        </div>

        <button *ngIf="showBackBtn" type="button" aria-label="Toggle sidenav" mat-icon-button (click)="handleBackBtn()">
          <mat-icon aria-label="Side nav toggle icon">arrow_back</mat-icon>
        </button>

        <a *ngIf="url === '/'" class="logo" [ngClass]="{'logo-center':isHandset}">
          <img class="img" style="height: 36px; width: auto;" src="./assets/dixie-truck-parts.svg" alt="Logo" />
          <!-- <span> LocusLoop </span> -->
        </a>
        <div *ngIf="url !== '/'">
          <div>{{title}}</div>
          <div class="def-comp-mob-title" *ngIf="!!subTitle">
            <!-- {{dComp?.cName}} -->
            {{subTitle}}
          </div>
        </div>

        <!-- <span *ngIf="url != '/'">{{ title }}
          <br>
          <sub>Company Name</sub>
        </span> -->
        <span class="fill-space"></span>
        <!-- showNotification button -->
        <ng-container *ngIf="showNotification;">
          <mat-icon aria-hidden="false" aria-label="notifications icon" color="accent">notifications</mat-icon>
        </ng-container>
        <!-- show search button -->
        <ng-container *ngIf="showSearch;">
          <button (click)="search()" type="button" aria-label="Search" mat-icon-button>
            <mat-icon aria-label="search">search</mat-icon>
          </button>
        </ng-container>
        <ng-container *ngIf="!!gps?.show;">
          <button (click)="search()" type="button" aria-label="GPS" mat-icon-button>
            <mat-icon aria-label="search" color="accent" *ngIf="!!gps?.status">gps_fixed</mat-icon>
            <mat-icon aria-label="search" *ngIf="!gps?.status">gps_off</mat-icon>
          </button>
        </ng-container>
        <!-- show save-cancel button -->
        <ng-container *ngIf="showSaveCancel; ">
          <button (click)="cancel()" type="button" aria-label="Cancel" mat-icon-button>
            <mat-icon aria-label="cancel">clear</mat-icon>
          </button>
          <button (click)="save()" type="button" aria-label="Cancel" mat-icon-button>
            <mat-icon aria-label="cancel">done</mat-icon>
          </button>
        </ng-container>
        <!-- show edit button -->
        <ng-container *ngIf="showEdit;">
          <button (click)="edit()" type="button" aria-label="Edit" mat-icon-button>
            <mat-icon aria-label="edit">edit</mat-icon>
          </button>
        </ng-container>
        <!-- show next cancel button -->
        <ng-container *ngIf="showNextCancel;">
          <button (click)="cancel()" type="button" aria-label="Cancel" mat-icon-button>
            <mat-icon aria-label="cancel">clear</mat-icon>
          </button>
          <button (click)="next()" type="button" aria-label="Next" mat-icon-button>
            <mat-icon aria-label="keyboard arrow right">keyboard_arrow_right</mat-icon>
          </button>
        </ng-container>
        <div style="display:flex;color: var(--primary);">
          <mat-icon mat-button (click)="openDPCSearchPopup()">search</mat-icon>
          <mat-icon>favorite_outline</mat-icon>
          <mat-icon>shopping_cart</mat-icon>
        </div>

        <ng-container *ngTemplateOutlet="topOption"></ng-container>

        <ng-template #topOption>
          <!-- && !showNextCancel -->
          <ng-container *ngIf="!showSaveCancel && !showEdit ">
            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <ng-container *ngFor="let link of subMenuData">
                <a *ngIf="link.type === 'link'" mat-menu-item [routerLink]="link.url">
                  <mat-icon aria-hidden="false" aria-label="home icon">{{ link.icon }}</mat-icon>
                  <span>{{ link.title }}</span>
                </a>
                <a *ngIf="link.type === 'button'" mat-menu-item (click)="link.click()">
                  <mat-icon aria-hidden="false" aria-label="home icon">{{ link.icon }}</mat-icon>
                  <span>{{ link.title }}</span>
                </a>
              </ng-container>
            </mat-menu>
          </ng-container>

        </ng-template>

      </mat-toolbar-row>
      <!-- Header Menu : MOBILE END -->

      <!-- Desktop - Top Header START -->
      <mat-toolbar-row class="container-2 h-80px space-between" *ngIf="!isHandset">
        <a class="logo" [routerLink]="['/']">
          <img class="img w-190 dpc-logo-size" src="./assets/dixie-truck-parts.svg" alt="Logo" />
        </a>
        <nav>
          <ul>
            <ng-container *ngFor="let a of menuFilt">

              <!-- no sub menu       -->
              <ng-container *ngIf="!a.subMenu">
                <li><a [routerLink]="[a.url]">{{a.title}}</a></li>
              </ng-container>

              <!-- has sub menu  -->
              <ng-container *ngIf="!!a.subMenu">

                <li><a href="javascript:void(0)">{{a.title}} <mat-icon>expand_more</mat-icon></a>
                  <ul>
                    <ng-container *ngFor="let b of getFilterArray(a.subMenu)">
                      <ng-container *ngIf="!b.subMenu">
                        <li class="nav-shadow"><a href="javascript:void(0)" [routerLink]="[b.url]">{{b.title}}</a></li>
                      </ng-container>
                      <ng-container *ngIf="!!b.subMenu">
                        <li class="nav-shadow"><a href="javascript:void(0)">{{b.title}}
                            <mat-icon>chevron_right</mat-icon></a>
                          <ul>
                            <ng-container *ngFor="let c of getFilterArray(b.subMenu)">
                              <ng-container *ngIf="!c.subMenu">
                                <li class="nav-shadow"><a href="javascript:void(0)" [routerLink]="[c.url]">{{c.title}}</a></li>
                              </ng-container>
                              <ng-container *ngIf="!!c.subMenu">
                                <li class="nav-shadow"><a href="javascript:void(0)">{{c.title}}
                                    <mat-icon>chevron_right</mat-icon></a>
                                  <ul>
                                    <ng-container *ngFor="let d of getFilterArray(c.subMenu)">
                                      <li class="nav-shadow z-11"><a href="javascript:void(0)" [routerLink]="[d.url]">{{d.title}}</a></li>
                                    </ng-container>
                                  </ul>
                                </li>
                              </ng-container>
                            </ng-container>
                          </ul>
                        </li>
                      </ng-container>
                    </ng-container>
                  </ul>
                </li>
              </ng-container>
            </ng-container>
          </ul>
        </nav>
        <div class="header_opt-wrpr">
          <mat-icon mat-button (click)="openDPCSearchPopup()">search</mat-icon>
          <mat-icon>favorite_outline</mat-icon>
          <mat-icon>shopping_cart</mat-icon>
        </div>

        <!-- <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <ng-container *ngFor="let link of subMenuData">
            <a mat-menu-item [routerLink]="link.url" (click)="link.click()">
              <mat-icon color="primary" aria-hidden="false" aria-label="home icon">{{ link.icon }}</mat-icon>
              <span>{{ link.title }}</span>
            </a>
          </ng-container>
        </mat-menu> -->
      </mat-toolbar-row>
      <mat-toolbar-row class="row2" *ngIf="!isHandset" style="z-index:-1">

        <div class="container-2">
          <a class="crm-command-bar-button title-desktop" color="accent" *ngIf="isAuth && title">{{title}}</a>
          <!-- sub menu -->
          <ng-container *ngIf="subMenuData">
            <ng-container *ngFor="let mData of subMenuData">
              <a [ngClass]="{'float-right': mData.icon==='help'}" class="crm-command-bar-button mat-button" mat-button
                (click)="mData.click()" [color]="mData.color">
                <mat-icon class="mat-icon notranslate material-icons mat-icon-no-color sub-menu-icon" role="img"
                  aria-hidden="true">
                  {{mData.icon}}
                </mat-icon>{{mData.title}}
              </a>
            </ng-container>

          </ng-container>

          <!-- <div style="flex-direction: row; box-sizing: border-box; display: flex; place-content: stretch flex-end; align-items: stretch; flex: 1 1 0%;"> -->
          <div class="float-right">
            <ng-container *ngIf="isMultipleComp && isAuth && dComp?.cid">
              <a mat-button [matMenuTriggerFor]="changeComp">
                <mat-icon>business</mat-icon>
                {{ dComp?.cName }}
              </a>
              <mat-menu #changeComp="matMenu" class="mat-menu-toolbar">
                <a mat-menu-item [routerLink]="['company', this.rb]" (click)="handleClose()">
                  <mat-icon aria-hidden="false" aria-label="settings icon" color="primary">settings</mat-icon>
                  Change Company
                </a>
              </mat-menu>
            </ng-container>
            <ng-container *ngIf="!isMultipleComp && isAuth && dComp?.cid">
              <a mat-button [routerLink]="['company/detail/', dComp.cid]" [queryParams]="this.rb.queryParams"
                (click)="handleClose()">
                <mat-icon>business</mat-icon>
                {{ dComp?.cName }}
              </a>
            </ng-container>
            <a *ngIf="isAdmin || isSalesOperationDpc" mat-button href="javascript:void(0)" (click)="togglePageEdit()">
              <mat-icon aria-hidden="false" aria-label="home icon">edit</mat-icon>
              Toggle Page Edit
            </a>

            <a *ngIf="!isAuth" mat-button (click)="login()">
              <mat-icon class="mat-icon notranslate material-icons " role="img" aria-hidden="true"> login
              </mat-icon> Login
            </a>
            <a mat-button (click)="toAccount()">
              <span class="mat-button-wrapper d-flex d-a-center">
                <mat-icon class="mat-icon notranslate material-icons mat-icon-no-color" role="img" aria-hidden="true">
                  account_circle</mat-icon> Account
              </span>
              <div class="mat-button-ripple mat-ripple" matripple=""></div>
              <div class="mat-button-focus-overlay"></div>
            </a>
            <a *ngIf="isAuth" mat-button (click)="logout()">
              <mat-icon class="mat-icon notranslate material-icons " role="img" aria-hidden="true"> logout
              </mat-icon> Logout {{ displayName }}
            </a>
          </div>
        </div>
      </mat-toolbar-row>
      <!--  Desktop - Top Header END -->
    </mat-toolbar>

    <!-- Page Content Goes here -->
    <div [ngClass]="{'dtop': !isHandset, 'mobile': isHandset, 'landscape': isLandscape}">
      <div style="background: var(--background);">
        <header></header>
        <div id="mArea" [ngStyle]="{'height': 'calc(76vh - ' + mAreaHeight + 'px)' }">
          <trent-alert-bar></trent-alert-bar>
          <ng-content></ng-content>
          <trent-pts-footer [footerFrom]="'dpc'" [socials]="socials" *ngIf="!(isAdmin || isEditor || isSalesOperationDpc)"></trent-pts-footer>
        </div>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

<!-- Sub Menu Popup Data -->
<mat-menu #subMenu="matMenu">
  <ng-container *ngFor="let mData of subMenuData">
    <button type="button" mat-menu-item (click)="mData.click()">
      <mat-icon color="primary">{{ mData.icon }}</mat-icon> {{ mData.title }}
    </button>
  </ng-container>
</mat-menu>
<!-- </div> -->