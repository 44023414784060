import { IPoint } from '../product-search/interfaces';
import { MapProvider } from './map-provider';
import { Distance } from './distance';

/** Input data required to get query distance. */

export type TravelMode = 'driving' | 'truck';

export type DistanceUnit = 'mi' | 'km';

export type TimeUnit = 'minute' | 'second';

export interface DistanceQuery {

  provider: MapProvider;

  origins: IPoint[];

  destinations: IPoint[];

  travelMode: TravelMode;

  timeUnit?: TimeUnit;

  distanceUnit?: DistanceUnit;
}

/** interface to calculate the distance between to locations. services will be used at client
 * while functions can use api calls. */
export interface DistanceCalculator {
  /** Get the distance/duration between two locations from the map api rest call.
   * distance in miles, and druation in hours.*/
  getDistance(data: DistanceQuery, useCache?: boolean): Promise<Distance>;
}


export const getDistanceKeys = (q: DistanceQuery): string[] => {
  const r: string[] = [];
  q.origins.forEach(orig => {
    q.destinations.forEach(dest => {
      r.push(`distance:${orig.latitude},${orig.longitude}:${dest.latitude},${dest.longitude}`);
    });
  });
  return r;
};

// export interface DistanceQueryResult {

//   origin: IPoint;

//   destinations: IPoint;

//   distance: number;

//   time: number;
// }