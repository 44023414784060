import { SecurityContext, inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { logger } from '@trent/models/log/logger';

export function getSafeWeb(txt: string, context: SecurityContext, sanitizer: DomSanitizer,) {
  let str = (typeof txt === 'string') ? txt : '?';
  // Remove any html script out.
  try {
    return sanitizer.sanitize(context, txt);
  } catch (error) {
    logger.log(`[ck-editor] Sanitization of ${SecurityContext[context]} failed for text: `, str);
    return undefined;
  }
}

/** Remove harmful script from html */
export function getSafeHtml(txt: string, sanitizer: DomSanitizer) {
  return sanitizer.bypassSecurityTrustHtml(getSafeWeb(txt, SecurityContext.HTML, sanitizer));
}

/** Remove harmful script from scripts. */
export function getSafeUrl(txt: string, sanitizer: DomSanitizer) {
  // return sanitizer.bypassSecurityTrustUrl(getSafeWeb(txt, SecurityContext.URL, sanitizer));
  return sanitizer.bypassSecurityTrustResourceUrl(getSafeWeb(txt, SecurityContext.URL, sanitizer));
  // return getSafeWeb(txt, SecurityContext.URL, sanitizer);
}