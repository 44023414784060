import { AuthState } from '../store/auth-store/auth.state';
import { ValidatorOptions } from 'class-validator';
import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { plainToInstance, instanceToPlain } from 'class-transformer';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { BaseHttpService } from '@trent/services/base-http.service';
import { Store } from '@ngxs/store';
import { Paging } from '@trent/models/observable-util/paging';
import { logger } from '@trent/models/log/logger';
import { IRoleGroupData, RoleGroup } from '@trent/models/role-group/role-group';
import { FirestoreService } from './firestore.service';
import { IRoleGroupParam, roleGroupSearchServerQuery } from '@trent/models/role-group/role-group-param';
import { PagingObesrvable } from '@trent/models/observable-util/paging-obesrvable';
import { Observable } from 'rxjs';

@Injectable()
export class RoleGroupService extends BaseHttpService {

  constructor(store: Store, private http: HttpClient,private db: FirestoreService,) {
    super(store);
    this.apiName = 'api';
  }

  getDataById(id: string | number) {
    return this.db.docWithInjectedId$<RoleGroup>(`${RoleGroup.collectionName}/${id}`);
  }
  
  public getAllRoleGroup_PagingObservable() {
    const p$: PagingObesrvable<RoleGroup, IRoleGroupParam> =
      new PagingObesrvable<RoleGroup, IRoleGroupParam>(this.db,
        (p: Paging, param?: IRoleGroupParam) => this.getAllRoleGroup_batch(p, param));
    return p$;
  }

  /**
 * Get the next batch of the data from server.
 * @param p paging information.
 */
  private getAllRoleGroup_batch(p: Paging, param: IRoleGroupParam): Observable<{ [key: string]: RoleGroup }> {
    if (param.orderBy == null) { param.orderBy = 'updatedAt'; }
    if (param.orderDirection == null) { param.orderDirection = 'desc'; }
    return this.db
      .colWithIdsInjectedNew$<RoleGroup>(`${RoleGroup.collectionName}`, ref => roleGroupSearchServerQuery(ref, param, p))
      .pipe(
        tap(arr => {
          if (arr == null || arr.length === 0) {
            logger.log('All data is recevied, Paging is FULL');
            p.full = true;
          } else {
            p.lastDoc = arr[arr.length - 1];
          }
        }),
        map(arr => {
          logger.log('From Server, before mapping is applied: ', arr);

          const arrayAsObj = arr.reduce((acc, cur) => {
            const id = cur.id;
            const data = cur;
            return { ...acc, [id]: data };
          }, {});

          logger.log('after converting to array object dic ', arrayAsObj);
          return arrayAsObj;
        })
      );
  }

  public create(data : IRoleGroupData) {
    // Prepare the post data
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + this.token });

    // Server validation before creating the entry.
    return this.http.post<any>(this.getApiUrl('/role-group/create'), data,
      // tslint:disable-next-line:object-literal-shorthand
      { headers: headers })
      .pipe(
        tap(r => logger.log('response: ', r)),
      );
  }

  
  public update(data : IRoleGroupData) {
    // Prepare the post data
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + this.token });

    // Server validation before creating the entry.
    return this.http.post<any>(this.getApiUrl('/role-group/update'), data,
      // tslint:disable-next-line:object-literal-shorthand
      { headers: headers })
      .pipe(
        tap(r => logger.log('response: ', r)),
      );
  }

  
  public addUserInRoleGroupByEmail(data) {
    // Prepare the post data
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + this.token });

    // Server validation before creating the entry.
    return this.http.post<any>(this.getApiUrl('/role-group/update-users-role-group'), data,
      // tslint:disable-next-line:object-literal-shorthand
      { headers: headers })
      .pipe(
        tap(r => logger.log('response: ', r)),
      );
  }

    
  public removeUserFromRoleGroup(data) {
    // Prepare the post data
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + this.token });

    // Server validation before creating the entry.
    return this.http.post<any>(this.getApiUrl('/role-group/remove-users-role-group'), data,
      // tslint:disable-next-line:object-literal-shorthand
      { headers: headers })
      .pipe(
        tap(r => logger.log('response: ', r)),
      );
  }
}
