import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { File, Entry } from '@awesome-cordova-plugins/file/ngx';


@Injectable()
export class FileCdvaService {


  constructor(private file: File, private platform: Platform) {

  }

  get isFileAvailable() { return true; }

  resolveFile(url: string): Promise<Entry> {
    /** Android */
    return new Promise((resolve, reject) => {
      (<any>window).resolveLocalFileSystemURL(
        url,
        (fileEntry: any) => {
          fileEntry.file(resolve);
        });
    });
  }
  // Read File's Blob
  readFile(file: any): Promise<Blob> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = (ev) => {
        if (!ev.target) {
          return reject(ev.target);
        }
        return resolve(new Blob([new Uint8Array(<any>reader.result)], {type: 'image/jpeg'}));
      };
      reader.readAsArrayBuffer(file);
    });
  }
}
// Dealing with large files in Cordova

// https://clockwise.software/blog/dealing-with-large-files-in-cordova/


// https://forum.ionicframework.com/t/convert-file-uri-to-blob/133635
