import { Component, OnInit } from '@angular/core';
import { EventService } from '@trent/services/event.service';
import { Router } from '@angular/router';

@Component({
  selector: 'trent-notfound',
  templateUrl: './notfound.component.html',
  styleUrls: ['./notfound.component.scss']
})
export class NotfoundComponent implements OnInit {

  get url() { return  'test test'; } // this.router.url; }
  
  // constructor(public es: EventService, private router: Router) {
  //   this.title('404 Error');
  // }

  ngOnInit() {

  }

  // public title(t: string) {
  //   this.es.emit<string>(this.es.app_setTitle, t);
  // }
}
