export enum PromoListType {

  promoTruck = 1,
  promoTrailer = 2,
  promoDriver = 3,
  promoFreightCustomer = 4,
  carrier = 5,
  border = 6,
  trackingNumber = 7,
  /** Agreegate list of  rentalTrailers*/
  rentalTrailer = 8,
  /** Agreegate list of  rentalTrucks*/
  rentalTruck = 9,
  
  /** Agreegate list of  Customer Companies in vendor's portfolio
   * Company added to list when new bid is created by a customer on vendor's asset
  */
  vendorPortfolio = 10,
  /** Aggregate List of salesTruck */
  salesTruck = 51,
  /** Aggregate List of salesTrailer */
  salesTrailer = 52,
/** Aggregate list of subsidiary*/
  subsidiary = 11,
}
