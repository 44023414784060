import { isNil } from 'lodash';
import { Router } from '@angular/router';
import { promiseWraper } from '../../models/utility/helper';
import { Injectable } from '@angular/core';
import { IcloudMessageService } from './icloud-message.service';
import { CloudMessageCoreService } from './cloud-message-core.service';
import { Store } from '@ngxs/store';
import { IAuthCoreService } from '../auth/iauth-core.service';
import { FirebaseX } from '@awesome-cordova-plugins/firebase-x/ngx';
import { FirestoreService } from '../firestore.service';
import { LocalStorage } from '../local-storage/local-storage.service';
// import { Device } from '@awesome-cordova-plugins/device/ngx';
// import { Platform } from '@ionic/angular';
import { IdeviceIdService } from '../device/idevice-id.service';
import { logger } from '@trentm/log/logger';

/** Do not inject this service in any component. Use @class IcloudMessageService instead.
 * Or better yet, use the @class SingletonService's public property. This this class is singlton also but
 * it is not imported at the app level to reduce the laods. Singlton class has the only copy in it.
 */
@Injectable()
export class CloudMessageCdvaService extends CloudMessageCoreService implements IcloudMessageService {

  constructor(
    store: Store,
    auth: IAuthCoreService,
    private firebaseX: FirebaseX,
    afs: FirestoreService,
    localStorage: LocalStorage,
    deviceIdService: IdeviceIdService,
    router: Router
  ) {
    super(auth, store, localStorage, afs, deviceIdService, router);
    if (isNil(document)) {
      logger.log('[fcm] document ready could not be bind as document is null!!');
    }
    document?.addEventListener('deviceready', () => {
      logger.log('[fcm] document ready was fired!!');
      this.requestNotificationPermission();
    }, false);
  }

  requestNotificationPermission() {
    // continue for cordova.
    // do not do anything, on adroid permission is received upon app installment.
    // https://github.com/dpa99c/cordova-plugin-firebasex#background-notifications
    // https://ionicframework.com/docs/native/firebase-x

    logger.log('[fcm] Request/Bind call made on cloud-message-cdva');
    return new Promise<boolean>(async (resolve, reject) => {
      const haspermission = await promiseWraper(this.firebaseX.hasPermission());
      // get permission, only if it has not been granted so far. IOS throw error when you ask
      // for permission twice.
      if (haspermission.error || !haspermission.data) {
        const permission = await promiseWraper(this.firebaseX.grantPermission());
        if (!permission.success) {
          logger.log('[fcm] Cdva Cloud message: error in getting permission', permission.error);
          return reject(permission.error);
        }
        if (!permission.data) {
          logger.log('[fcm] Cdva Cloud message: notification permission denied!');
          return resolve(false);
        }
      }

      // You have permission.
      logger.log('[fcm] permission received, Getting Fcm Token!');
      const token = await promiseWraper(this.firebaseX.getToken());
      if (!token.success) {
        logger.log('[fcm] Cdva Cloud message: error in getting permission', token.error);
        return reject(token.error);
      }
      if (token.data == null || token.data === '') {
        return resolve(false);
      }
      this.fcmToken = token.data;
      logger.log('[fcm] token received: ', this.fcmToken);

      this.updateFcmToken();
      // bind subscription asynchronously.
      if (!!this.msgSub) {
        this.msgSub.unsubscribe();
      }

      /** If app in cordova is not running, android native will receive the meesage. call the app
       * and following subscription will be called. */
      this.msgSub = this.firebaseX.onMessageReceived().subscribe(
        data => {
          logger.log(`[fcm] User opened a notification  via cordova native ${data}`);
          this.onMessageReceived(data as any);
        },
        error => {
          logger.log('[fcm] Cordova FCM message receiving error!', error);
        }
      );

      /** If app is already running then following will be executed once fcm message is received. */
      try {
        // logger.log('[fcm] window.onMessage', typeof((window as any).onMessage));
        // logger.log('[fcm] window.FirebasePlugin', typeof((window as any).FirebasePlugin));
        const ff = (window as any).FirebasePlugin;
        ff.onMessageReceived((_msg: any) => {
          logger.log('[fcm]-cdva Message received. Detail: ', _msg);
          logger.log('[fcm]-cdva Message received. (json): ', JSON.stringify(_msg));
          if (_msg.messageType === 'notification') {
            // alert('Notification message received');
            logger.log('[fcm]-cdva Message received. (json): ', JSON.stringify(_msg));
            if (_msg.tap) {
              // alert('Tapped in ' + _msg.tap);
            }
          }
          this.onMessageReceived(_msg);
        }, (error) => {
          logger.error(error);
        });
      } catch (error) {
        logger.log('[fcm] seeting up on Window.FirebasePlugin failed', error);

      }



      // bind token refresh as well.
      if (!!this.tokenRefreshSub) {
        this.tokenRefreshSub.unsubscribe();
      }

      this.tokenRefreshSub = this.firebaseX.onTokenRefresh().subscribe(
        (t: string) => {
          this.fcmToken = t;
          this.updateFcmToken();
          logger.log(`[fcm] cordova refresh, Got a new fcm token ${t}`);
        },
        error => {
          logger.log('[fcm] Cordova FCM refresh token receving error!', error);
        }
      );

      // Notification process passed.
      return resolve(true);
    });
  }
  async refreshToken() { }
}
