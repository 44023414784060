import { DocDropZoneDirective } from './../../directive/doc-drop-zone.directive';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileUploadComponent } from './file-upload.component';
// import { PhotoDetailComponent } from './photo-detail/photo-detail.component';
import { FormsModule } from '@angular/forms';
// import { NgxGalleryModule } from 'ngx-gallery';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { CustomPipeModule } from '@trent/custom-pipe/custom-pipe.module';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { connectStorageEmulator, getStorage, provideStorage } from '@angular/fire/storage';
import { FileUploadMultiComponent } from './file-upload-multi/file-upload-multi.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    // NgxGalleryModule,
    CustomPipeModule,
    // Material Imports
    MatFormFieldModule,
    MatRadioModule,
    MatIconModule,
    MatDividerModule,
    MatProgressBarModule,
    provideStorage(() => {
      const storage = getStorage();
      if (false) { // environment.useEmulators) {
          connectStorageEmulator(storage, 'localhost', 9199);
      }
      return storage;
    }),

    ClipboardModule
  ],
  declarations: [
    FileUploadComponent,
    DocDropZoneDirective,
    FileUploadMultiComponent,
    // PhotoDetailComponent,

  ],
  exports: [
    FileUploadComponent,
    DocDropZoneDirective,
    FileUploadMultiComponent
    // PhotoDetailComponent
  ],

})
export class FileUploadModule { }
