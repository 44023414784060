/** frequency enum */
export enum Frequency {
    MONTHLY = 'MONTHLY',
    WEEKLY = 'WEEKLY',
    BIWEEKLY = 'BIWEEKLY'
}

/** credit rating enum */
export enum CreditRating {
    A = 'A',
    B = 'B',
    C = 'C',
    D = 'D',
    TBD = 'TBD'
}

/** Applicable taxes enum */
export enum ApplicableTaxes {
    zeroFive = 0.05,
    zeroEleven = 0.11,
    zeroTwelve = 0.12,
    zeroThirteen = 0.13,
    zeroFifteen = 0.15,
    zeroTwenty = 0.20,
    zeroFourteenNine = 0.14975
}

/** DmfReserve enum */
export enum DmfReserve {
    five = 0.05,
    six = 0.06,
    sevenFive = 0.075,
    eight = 0.08,
    ten = 0.10,
    twelve = 0.12,
    fifteen = 0.15
}

/** Contract Type enum */
export enum ContractType {
    leaseToOwn = 'leaseToOwn',
    rental = 'rental',
    rentalEotGuaranteedByPride = 'rental-EOTGuaranteedByPride' //Rental - EOT Guaranteed by Pride
}
/** Maintained Type enum */
export enum MaintainedBy {
    client = 'client',
    PFS = 'PFS'
}
/** AccountStanding Type enum */
export enum AccountStanding {
    current = 'current',
    default = 'default'
}
/** AccountStanding Status1 enum */
export enum Status1 {
    cancelled = 'Cancelled',
    happyNewYear = 'HAPPY NEW YEAR',
    fundedByCWB = 'Funded by CWB',
    fundedByDMF = 'Funded by DMF',
    buyOut = 'BUY OUT',
    buyOutInProgress = 'BUY OUT IN PROGRESS',
    fundedByBDC = 'Funded by BDC',
    tpineDP = 'Tpine/ DP',
    fundedByVFS = 'Funded by VFS',
    fundedByBFC = 'Funded by BFC',
    fundedByRBCLeaseLine = 'Funded by RBC LEASELINE',
    paidByTpine = 'Paid by Tpine',
    fundedBySecurcor = 'Funded by Securcor',
    fundedByCoastCapital = 'Funded by Coast Capital',
    fundedByPaccar = 'Funded by Paccar',
    fundedByMF = 'Funded by MF',
    equipmentExchange = 'Equipment Exchange',
    paidByPGL = 'Paid by PGL',
    claimReceived = 'Claim Received',
    insuranceClaim = 'Insurance Claim',
    swapped = 'Swapped',
    fundedByRCAP = 'Funded by RCAP',
    equipmentReturned = 'Equipment Returned',
    uSDeal = 'US Deal',
    paidByTpineIn$$US = 'Paid by Tpine in $$US',
    fundedByDLL = 'Funded by DLL',
    fundedByCCG = 'Funded by CCG',
    dispute = 'Dispute',
    fundedByRBCLeaseLineUSD = 'Funded by RBC LEASELINE USD'
}



/** Part Of Multiple Units */
export enum PartOfMultipleUnits {
    singleUnit = 'Single Unit',
    masterOfMultiple = 'Master of Multiple',
    childOfMultiple = 'Child of Multiple',
}

/** Vendor Company */
export enum LCVendorCompanies {
    TPINETRUCKRENTALLP = 'TPINE TRUCK RENTAL LP',
    TPINETRUCKRENTALINC = 'TPINE TRUCK RENTAL INC',
    PRIDETRUCKSALESLTD = 'PRIDE TRUCK SALES LTD',
    PRIDETRUCKSALESLP = 'PRIDE TRUCK SALES LP'
}

/** AccountStanding Type enum */
export enum PaymentType {
    fixed = 'Fixed',
    variable = 'Variable'
}

/** AccountStanding Type enum */
export enum DownPaymentType {
    advance = 'advance',
    firstPaymentOnly = 'firstPaymentOnly',
    firstAndLastPayment  = 'firstAndLastPayment',
    firstAndLastPaymentPlusDeposit = 'firstAndLastPaymentPlusDeposit'
}

// Legacy Contract
export enum LegacyContractCountryOrigin {
    ca = 0,
    us = 1,
}