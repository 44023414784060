<form #rentalPlanForm="ngForm">
  <ng-container *ngIf="!!selectPlan">
    <mat-form-field class="full-width check-input">
      <mat-label>{{em.termString}} Plan</mat-label>
      <input matInput name="isSelected" autocomplete="off" readonly="true" [ngModel]="em.isAvailSlectString"  [disabled]="true" />
      <mat-checkbox matSuffix color="primary" [(ngModel)]="em.isSelected" [name]="selectedName" #isSelected="ngModel"
      [disabled]="!isEMode || !em.isAvailable" class="ml-4" (change)="planSelectionChange.emit()">
    </mat-checkbox>
    <mat-hint *ngIf="!showError(isSelected)" align="end"></mat-hint>
    <mat-error align='end' *ngIf="showError(isSelected)">
      {{isSelected.errors.msg}}</mat-error>
    </mat-form-field>
  </ng-container>
  
  <ng-container *ngIf="!selectPlan">
  <mat-card-subtitle [ngClass]="{'accent-class': isContractStarted}" *ngIf="!hideSubTitle">Rental Plan {{em.termString}}</mat-card-subtitle>
  <mat-form-field class="radio1">
    <mat-label>Mileage Type</mat-label>
    <input matInput name="mileageType" #mileageType="ngModel" [(ngModel)]="em.mileageType" style="display: none">
    <!-- <div style="display: grid; align-self: bottom; grid-template-columns: 3fr 1fr; align-items: center; height: 40px;"> -->
      <mat-radio-group aria-label="Select an option" [name]="milageTypeName" #mileageType="ngModel" [disabled]="!isEMode || isContractStarted"
        [(ngModel)]="em.mileageType" (change)="mileageTypeChangeFn()">
        <mat-radio-button color="accent" [value]="mileageTypeEnum.minimum" style="padding-right: 5px">
          Min
        </mat-radio-button>
        <mat-radio-button color="accent" [value]="mileageTypeEnum.maximum" style="padding-right: 5px">
          Max
        </mat-radio-button>
        <mat-radio-button color="accent" [value]="mileageTypeEnum.flexible" style="padding-right: 5px">
          Flex
        </mat-radio-button>
        <mat-radio-button color="accent" [value]="mileageTypeEnum.unlimited" style="padding-right: 0px">
          Unlimted
        </mat-radio-button>
      </mat-radio-group>
    <!-- </div> -->
    <mat-hint *ngIf="!showError(mileageType)" align="end">Required</mat-hint>
    <mat-error *ngIf="showError(mileageType)">{{mileageType.errors.msg}}</mat-error>
  
    <!-- <button mat-icon-button matSuffix color="primary" type="button" (click)="openHelp('rent-option-detail#milageType')">
      <mat-icon class="help-icon">help</mat-icon>
    </button> -->
  </mat-form-field>
  
  <mat-form-field class="full-width">
    <span matPrefix>$ &nbsp;</span>
    <mat-label>Rate Per Day</mat-label>
    <input matInput [disabled]="!isEMode || isContractStarted" type="number" name="ratePerDay" #ratePerDay="ngModel"
      [(ngModel)]="em.ratePerDay"  autocomplete="off">
      <!-- Browser="Rent Per Day ({{em.termString}} Plan)" -->
    <mat-hint *ngIf="!showError(ratePerDay)" align="end">Required</mat-hint>
    <mat-error *ngIf="showError(ratePerDay)">{{ratePerDay.errors.msg}}</mat-error>
    <span matSuffix>{{toCurrency(em.ratePerDay)}}</span>

  </mat-form-field>
  
  <mat-form-field class="full-width" *ngIf="em.mileageType!==mileageTypeEnum.unlimited">
    <span matPrefix>$ &nbsp;</span>
    <mat-label>Rate Per Mile</mat-label>
    <input matInput [disabled]="!isEMode || isContractStarted" type="number" name="ratePerMile" #ratePerMile="ngModel"
      [(ngModel)]="em.ratePerMile" autocomplete="off">
      <span matSuffix>{{toCurrency(em.ratePerMile)}}</span>
    <mat-hint *ngIf="!showError(ratePerMile)" align="end">Required</mat-hint>
    <mat-error *ngIf="showError(ratePerMile)">{{ratePerMile.errors.msg}}</mat-error>
  </mat-form-field>
  <mat-form-field class="full-width" *ngIf="em.mileageType===mileageTypeEnum.maximum || em.mileageType===mileageTypeEnum.minimum">
    <mat-label>Mileage</mat-label>
    <input matInput [disabled]="!isEMode" type="number" name="milage" #milage="ngModel"
      [(ngModel)]="em.milage"  autocomplete="off">
      <!-- [Browser]="em.mileageString" -->
    <mat-hint *ngIf="!showError(milage)" align="end">Required</mat-hint>
    <mat-error *ngIf="showError(milage)">{{milage.errors.msg}}</mat-error>
  </mat-form-field>
</ng-container>

  </form>

