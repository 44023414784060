<ng-container *ngIf="canPromptInstall">
  <h4 class="install-app-heading">
    <mat-icon>{{ isHandset ? 'install_mobile' : 'install_desktop' }}</mat-icon>
    Install App
  </h4>
  <p style=" font-size: 16px;">Install App for a better user experience.</p>
  <div class="buttons">
    <button mat-flat-button color="primary" (click)="installApp()" type="button" *ngIf="!doNotAsk">
      <!-- <mat-icon>{{ isHandset ? 'install_mobile' : 'install_desktop' }}</mat-icon>  -->
      Install
    </button>
    <button mat-stroked-button color="primary"  (click)="closeAlert()" type="button" *ngIf="!doNotAsk">
      <!-- <mat-icon>clear</mat-icon>  -->
      Remind me later
    </button>
    <button mat-flat-button color="primary" (click)="doNotAskFn()" type="button" *ngIf="doNotAsk">
      <!-- <mat-icon>{{ isHandset ? 'install_mobile' : 'install_desktop' }}</mat-icon>  -->
      Cancel
    </button>
  </div>
  <mat-checkbox matSuffix color="primary" [(ngModel)]="doNotAsk" name="doNotAsk"
  #doNotAsk1="ngModel"  class="ml-4">Do not ask me again</mat-checkbox>
</ng-container>
<ng-container *ngIf="canPromptAddToScreen">
  <h4 class="install-app-heading">
    <!-- <mat-icon>ios_share</mat-icon> -->
    Add to Home Screen
  </h4>
  <p style=" font-size: 16px;">Add to Home Screen for a better user experience.</p>
  <mat-list>
    <mat-list-item>
      <mat-icon matListItemIcon style="color: #089cf5">ios_share</mat-icon>
      <div matListItemTitle style="padding-left: 20px ;">1) Press the 'Share' button on the menu bar below</div>
    </mat-list-item>
    <mat-list-item>
      <mat-icon matListItemIcon class="material-symbols-outlined" style="font: 48px"><span>
        add_box
        </span></mat-icon>
      
      <div matListItemTitle style="padding-left: 20px ;">2) Press 'Add to Home Screen'</div>
    </mat-list-item>
  </mat-list>  
  <div class="buttons">
    <button mat-flat-button color="primary"  (click)="closeAlert()" type="button" *ngIf="!doNotAsk">
      <!-- <mat-icon>clear</mat-icon>  -->
      Close
    </button>
    <button mat-stroked-button color="primary" (click)="doNotAskFn()" type="button" *ngIf="doNotAsk">
      <!-- <mat-icon>{{ isHandset ? 'install_mobile' : 'install_desktop' }}</mat-icon>  -->
      Cancel
    </button>
  </div>
  <mat-checkbox matSuffix color="primary" [(ngModel)]="doNotAsk" name="doNotAsk"
  #doNotAsk1="ngModel"  class="ml-4" style="padding-bottom: 20px;">Do not ask me again</mat-checkbox>
</ng-container>

