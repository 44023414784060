import { isDate } from './../../utility/utl-datef';
import { differenceInDays, differenceInHours } from 'date-fns';
import { isNumber, iteratee, isObject, isString } from 'lodash';
import { InvoiceItem } from '../invoice/invoice-item';
import { IMethodData, IPeriod } from './i-method';

// #region Library of Percent Functions
export interface IPercentArg {

  /** Percent Charges. It must be fractional. i.e. 5% must be input  0.05 */
  rate: number;

  /** initial amount that will be added to the percent calculation */
  initial?: number;

  /** Min Cap, Minimum amount of charges that will be applied if calculated amount is less then this number. (to be < min)*/
  min?: number;

  /** Max Cap, total amount calculated will be capped to this amount if provided. (to be > max)*/
  max?: number;
}

/**
 * Function to calculate percent charges based of a given amount.
 * @param arg 
 * @returns 
 */
export const percentFn = (arg: IPercentArg, amount: number): number => {
  if (!isNumber(amount)) {
    throw Error('Invalid Amount was provided. It must be a number');
  }

  let result = amount * arg.rate;

  if (isNumber(arg.initial) && arg.initial > 0) {
    result += arg.initial;
  }

  if (isNumber(arg.min) && arg.min > 0) {
    result = Math.max(result, arg.min);
  }

  if (isNumber(arg.max) && arg.max > 0) {
    result = Math.min(result, arg.max);
  }

  return result;
};

// #endregion

// #region Library of Per Day Functions
export interface IDurationArg {
  /** Base Amount per day. */
  amount: number;

  timeUnit?: 'day' | 'hour';

  /** initial amount that will be added to the percent calculation 
   * Note: these calcs are done per pay period/Invoice Item and not the Gross at invoice level.
  */
  initial?: number;

  /** Min Cap, Minimum amount of charges that will be applied if calculated amount is less then this number. (to be < min)
   * Note: these calcs are done per pay period/Invoice Item and not the Gross at invoice level. 
  */

  min?: number;

  /** Max Cap, total amount calculated will be capped to this amount if provided. (to be > max)
   * Note: these calcs are done per pay period/Invoice Item  and not at the Gross at invoice level.
  */
  max?: number;
}

/**
 * Function to calculate percent charges based of a given amount.
 * @param arg 
 * @returns 
 */
export const durationFn = (arg: IDurationArg, period: IPeriod): number => {
  let duration = 0;

  if (!(isDate(period?.to) && isDate(period?.from) && period?.to >= period?.from)) {
    throw Error('Invalid Time period was provided!');
  }

  switch (arg.timeUnit) {
    /** Default is number of Days */
    case undefined:
    case null:
    case 'day':
      duration = differenceInDays(period.to, period.from);
      break;
    case 'hour':
      duration = differenceInHours(period.to, period.from);
      break;
    default:
      throw Error('Invalid Time Unit was provided in the method!');
  }

  let result = arg.amount * duration;

  if (isNumber(arg.initial) && arg.initial > 0) {
    result += arg.initial;
  }

  if (isNumber(arg.min) && arg.min > 0) {
    result = Math.max(result, arg.min);
  }

  if (isNumber(arg.max) && arg.max > 0) {
    result = Math.min(result, arg.max);
  }

  return result;
};

// #endregion

/**
 * Specific methods defined in the setting data base to perform certain calculations.
 */
export interface IMethodInfo {
  funName: 'percent' | 'duration';
  args: number | IPercentArg | IDurationArg;
  isTaxable?: boolean;
  isOptional?: boolean;
}

function getOptionalNumberVal(arg: any) {

  if (isObject(arg)) {
    return Number.NaN;
  }

  if (isNumber(arg)) {
    return arg as number;
  }

  if (isString(arg) && isNumber(+arg)) {
    return +arg;
  }
  return Number.NaN;
}

/** Function Method handler for various calculations */
export const methodFn = (mInfo: IMethodInfo, item: InvoiceItem, customAmount?: number): number => {

  let data: IMethodData = item.getMethodFnData();

  // Use a custom amount is provided, use that amount instead.
  if (isNumber(customAmount)) {
    data.amount = customAmount;
  }


  // if args is just a string or a number, args may need to be reconstructed.
  const numberData = getOptionalNumberVal(mInfo.args);

  switch (mInfo.funName) {
    case 'percent':
      let percentArgs = mInfo.args as IPercentArg;
      // construct arg if only a number/string is stored in the db.
      if (isString(percentArgs) || isNumber(percentArgs)) {
        percentArgs = {
          rate: +percentArgs
        };
      }
      return percentFn(percentArgs, data.amount);
    case 'duration':
      let durationArgs = mInfo.args as IDurationArg;
      // construct arg if only a number/string is stored in the db.
      if (isString(durationArgs) || isNumber(durationArgs)) {
        durationArgs = {
          amount: +durationArgs
        };
      }
      return durationFn(durationArgs, data.period);
    default:
      throw new Error(`Invalid Service Type provided, ${mInfo.funName} is invalid or not yet implemented!`);
  }
};
