import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { AlgoliaSearchService } from '@trent/services/algolia-search.service';
import { SalesOptionService } from '@trent/services/sales.service';
import { SalesOptionState } from './sales-option.state';

@NgModule({
  imports: [
    CommonModule,
    NgxsModule.forFeature([SalesOptionState])
  ],
  providers: [
    SalesOptionService,
    AlgoliaSearchService
  ]
})
export class SalesOptionStoreModule { }