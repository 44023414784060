import { copyObject } from './../utility/helper';
import { IUserType, getUserTypeNumber, getUserType } from './user-type';
import { UserPhone } from './user-phone';
import { ILocalStorage } from './../local-storage/local-storage';
import { UserSetting } from './../setting/user-setting';
import { IUserClaim } from '@trent/models/user/user-claim';
import { IValidate, IValidationMsg } from './../error-handling/validation-info';
import { plainToInstance, instanceToInstance, Exclude, Expose, instanceToPlain, Type } from 'class-transformer';
import { Role, RoleEnum } from './role';
import { RoleCompany, RoleCompanyEnum, getMaxCompanyUserRole, getCompRoleAuthLevel } from './role-company';
import { ICompanyRoleProfile, mapCompRoleToProfile, mapCompRoleToClaim } from './role-mapping';
import { CompanyUserProfile } from './company-user-profile';
import { IUserAuthClaim } from './user-claim';
import { keys, isEmpty, pickBy, cloneDeep } from 'lodash';
import { BaseModel } from '../base/base-model';
import { cleanupUndefined, sanitizeDateIPoint } from '../utility';
import { ValidatorOptions, IsEmail, IsDefined, Length, ValidateIf, IsArray, ValidateNested, IsOptional } from 'class-validator';
import { DriverList } from '../promo/driver-list';
import { DrivingFor } from './driving-for';
import { CommPreference } from '../setting/comm-preference';
import { TripTrackerList } from '../promo/trip-tracker-list';
import { INotifiedFor } from './notified-for';
import { ItemStatus } from '../promo/item-status';
import { toPascalCase } from '../utility/helper';
import { logger } from '../log/logger';
import { User } from '@angular/fire/auth';
import { RoleGroup } from '../role-group/role-group';
import { RoleGroupLevel } from '../role-group/role-group-level';
import { AssignedStoreLocation } from '../store-location/assigned-store-location';
import { CompanyFleet } from '../company/company-fleet';
import { AssignedCompany } from '../company/assigned-company';

export type ProfileValidationGroup = 'general';
const general: ProfileValidationGroup = 'general';
export type UserType = 'anonymous' | 'carrier' | 'driver' | 'tracker' | 'vendor' | 'customer' | 'logedIn';
export type RoleValidationGroup = 'roles';
const roles: RoleValidationGroup = 'roles';

export interface IParentRoleGroupAccess {
  name: string,
  shortName: string
}

@Exclude()
export class UserProfile extends BaseModel implements IValidate {

  public static readonly collectionName = 'user-profile';

  /** Maximum number of entries required per claim. */
  public static readonly maxClaimNComp = 10;

  /** A multiple user profiles isDriver/isCarrier/isCarrier */
  // @Expose()
  userTypes: IUserType;

  // @Expose()
  // @Length(5, 12, { message: 'Invalid Account type. type must be $constraint1 - $constraint2 chars long' })
  // userType: UserType;

  // Sales person add company for user data store in sales person profile
  @Expose()
  companyAccountIds?: { [key: string]: { cName: string } };

  @Expose()
  @IsEmail({}, { message: 'Valid Email is required', groups: [general] })
  @Length(5, 254, { message: 'Email must be $constraint1 - $constraint2 chars long' })
  email: string;


  @Expose()
  @Length(3, 30, { message: 'Display Name must be $constraint1 - $constraint2 chars long', groups: [general] })
  displayName: string;


  @Expose()
  // @Length(5, 30, { message: 'A valid phone no with area code is required', groups: [general] })
  // Note phone will be set by the twilio so it will be always valid.
  phoneNumber: string | null;


  @Expose()
  // @Length(5, 254, { message: 'Photo Url must be $constraint1 - $constraint2 chars long' })
  photoURL: string;


  /**Roles for webiste wide authentications. */
  // @IsDefined({ message: 'Roles must be defined.' })  TODO: this fails the name change
  @Expose()
  roles: Role = {};

  /** Roles for all of the companies that user possess. */
  // @IsDefined({ message: 'Company Roles must be defined.' }) TODO: this fails the name change
  @Expose()
  cRolesAll: ICompanyRoleProfile = {};

  /** 
   * @author MKN 
   * Maintain user's branch manager role group data
   **/
  @Expose()
  branchManagers: { [key: string]: IParentRoleGroupAccess };

  /** 
   * @author MKN 
   * (Getter) - Maintain branch managers role group short name information for access rights purpose
   * Used in firebase rules(Later on)
   **/
  @Expose({ toPlainOnly: true })
  get branchMgr(): string[] {
    let r: string[] = [];
    if (this.branchManagers) {
      for (const key in this.branchManagers) {
        if (this.branchManagers.hasOwnProperty(key)) {
          r.push(this.branchManagers[key].shortName);
        }
      }
    }
    return r;
  }

  /** 
   * @author MKN 
   * Maintain user's region manager role group data
   **/
  @Expose()
  regionManagers: { [key: string]: IParentRoleGroupAccess };

  /** 
   * @author MKN 
   * (Getter) - Maintain region managers role group short name information for access rights purpose
   * Used in firebase rules(Later on)
   **/
  @Expose({ toPlainOnly: true })
  get regionMgr(): string[] {
    let r: string[] = [];
    if (this.regionManagers) {
      for (const key in this.regionManagers) {
        if (this.regionManagers.hasOwnProperty(key)) {
          r.push(this.regionManagers[key].shortName);
        }
      }
    }
    return r;
  }

  /** 
   * @author MKN 
   * Maintain user's country manager role group data
   **/
  @Expose()
  countryManagers: { [key: string]: IParentRoleGroupAccess };

  /** 
   * @author MKN 
   * (Getter) - Maintain country managers role group short name information for access rights purpose
   * Used in firebase rules(Later on)
   **/
  @Expose({ toPlainOnly: true })
  get countryMgr(): string[] {
    let r: string[] = [];
    if (this.countryManagers) {
      for (const key in this.countryManagers) {
        if (this.countryManagers.hasOwnProperty(key)) {
          r.push(this.countryManagers[key].shortName);
        }
      }
    }
    return r;
  }

  /** 
   * @author MKN 
   * Maintain user's enterprise manager role group data
   **/
  @Expose()
  enterpriseManagers: { [key: string]: IParentRoleGroupAccess };

  /** 
   * @author MKN 
   * (Getter) - Maintain enterprise managers role group short name information for access rights purpose
   * Used in firebase rules(Later on)
   **/
  @Expose({ toPlainOnly: true })
  get enterpriseMgr(): string[] {
    let r: string[] = [];
    if (this.enterpriseManagers) {
      for (const key in this.enterpriseManagers) {
        if (this.enterpriseManagers.hasOwnProperty(key)) {
          r.push(this.enterpriseManagers[key].shortName);
        }
      }
    }
    return r;
  }

  @Expose()
  setting: UserSetting;

  @Expose()
  // @ValidateIf(u => u.userType === 'driver' && false)
  // @IsDefined({ message: 'Select valid carrier', groups: [general] })
  drivingFor: { [key: string]: { isActive: boolean } } = {};

  /** carriers whom the user is getting notfied for.
   * Set at server based on the user's email listed in carriers notifier list (trip-tracker list)
   * @param key | carrier cid
   * @param name | carrier business name
   * @param legalName | carrier legal name
   */
  @Expose()
  notifiedFor: INotifiedFor = {};

  // #region Temporaray Properties, not to be saved in db.
  /**
   * If the current instance was received from db. Rather this was created from firebase claim @interface IUserClaim
   * or from @interface firebase.User
   * Note: this is not saved in db. Make sure this property is skipped in @method toFirebaseObj
   */
  public loadedFromDb = false;

  /**
   * If the Auth data (roles and company roles) contain full data in it. Since this instance can be sometime created from
   * claim @interface IUserClaim token that may not have all the data in it, this variable can be use to fetch the db with complete
   * data from db.
   * Note: this is not saved in db. Make sure this property is skipped in @method toFirebaseObj
   */
  public isFullAuth = false;

  @Expose()
  @Type(() => AssignedStoreLocation)
  assignedStoreLocations: AssignedStoreLocation[];

  @Expose()
  @IsOptional()
  @ValidateIf(o => !!o.roles.credit && o.assignedCompanies.length)
  @ValidateNested({ message: 'Provide Store Location' })
  @Type(() => AssignedCompany)
  assignedCompanies: AssignedCompany[];

  // #endregion

  constructor() {
    super();
    this.displayName = 'Guest';
    this.assignedStoreLocations = [];
    this.assignedCompanies = [];
    // this.userTypes = {
    //   isCarrier: false,
    //   isDriver: false,
    //   isShipper: false
    // };
  }

  public static parse(obj: any) {
    if (obj == null) {
      return new UserProfile();
    }
    const p = plainToInstance<UserProfile, any>(UserProfile,
      sanitizeDateIPoint(obj)); // ,{ strategy: 'excludeAll' }); exclude all fails.
    p.sanitize();
    if (!p.assignedStoreLocations) p.assignedStoreLocations = [];
    if (!p.assignedCompanies) p.assignedCompanies = [];
    // if (p.userTypes == null) {
    //   p.userTypes = {
    //     isCarrier: false,
    //     isDriver: false,
    //     isShipper: false
    //   };
    // }
    return p;
  }

  /**
   *
   * @param u user data from firebase.
   * @param currProfile : current profile that will be clined and updated by the properties
   * specified in the user
   */
  public static async getUserProfileFromFbUser(u: User, currProfile: UserProfile,
    storage: ILocalStorage): Promise<UserProfile> {
    if (u == null) {
      return null;
    }
    let p;
    if (currProfile == null) {
      p = await UserProfile.buildNewProfile(u.uid, storage);
    } else {
      p = UserProfile.parse(currProfile);
    }
    p.id = u.uid;
    p.email = u.email;
    p.displayName = u.displayName;
    // companies are loaded from the claim. if default company is not defined,
    // get the first one from claim.
    if (p.setting == null) {
      p.setting = <any>{};
    }
    await p.loadSettingsLocal(p.id, storage);
    return p;
  }

  /**
  * @param claim, the user claim infomration received from token refresh.
  * @param currProfile : current profile that will be updated and updated by the properties
  * specified in the user.Note, this will not update the db.
  */
  public static async getUserProfileFromFbClaim(claim: IUserClaim, currProfile: UserProfile,
    storage: ILocalStorage): Promise<UserProfile> {

    if (claim == null) { return currProfile; }

    const cClaim: IUserAuthClaim = (claim == null) ? null : <IUserAuthClaim>claim.cClaim;
    if (cClaim == null && currProfile != null) {
      return currProfile;
    }
    let p = currProfile;

    if (currProfile == null) {
      if (!!storage) {
        p = await UserProfile.buildNewProfile(claim.user_id, storage);
      } else { p = new UserProfile(); }
    } else {
      p = UserProfile.parse(currProfile);
    }

    if (!!cClaim) {
      p.userTypes = getUserType(cClaim.ut);
      p.isFullAuth = cClaim.full;
      p.cRolesAll = mapCompRoleToProfile(cClaim.cRoles);
      p.roles = { ...cClaim.roles };
    } else {
      p.userTypes = getUserType(0);
      p.isFullAuth = true;
      p.cRolesAll = {};
      p.roles = {};
    }
    p.id = claim.user_id;
    p.displayName = claim.name;
    p.email = claim.email;
    p.phoneNumber = claim.phone_number;
    if (p.displayName == null) { p.displayName = p.email.split('@')[0]; }

    // companies are loaded from the claim. if default company is not defined,
    // get the first one from claim.
    if (p.setting == null) {
      p.setting = <any>{};
      await p.loadSettingsLocal(p.id, storage);
    }

    if (p.setting.defaultCompany == null) {
      const k = keys(p.cRolesAll);
      if (k.length > 0) {
        const d = p.cRolesAll[k[0]];
        p.setting.defaultCompany = {
          cid: k[0],
          cName: d.cName
          // roles: { ...d.roles }
        };
      }
    }
    // If profile is not complete, must load from db.
    return p;
  }

  /**
  * @param currProfile : as received from data base.
  */
  public static async getUserProfileFromDbObj(currProfile: UserProfile,
    storage: ILocalStorage): Promise<UserProfile> {
    const p = UserProfile.parse(currProfile);
    p.isFullAuth = true;
    p.loadedFromDb = true;

    // companies are loaded from the claim. if default company is not defined,
    // get the first one from claim.
    if (p.setting == null) { p.setting = <any>{}; }
    if (p.setting.defaultCompany !== null || p.notifiedFor != null || p.drivingFor != null) {
      await p.saveSettingsLocal(p.id, storage);
    } else {
      const k = keys(p.cRolesAll);
      if (k.length > 0) {
        const d = p.cRolesAll[k[0]];
        p.setting.defaultCompany = {
          cid: k[0],
          cName: d.cName
          // roles: { ...d.roles }
        };
      }
    }
    await p.loadSettingsLocal(p.id, storage);
    return p;
  }

  public static async buildNewProfile(uid: string | number, storage: ILocalStorage): Promise<UserProfile> {
    const p = new UserProfile();
    p.loadedFromDb = false;
    await p.loadSettingsLocal(uid, storage);
    if (p.setting == null) {
      p.setting = <any>{};
    }
    if (p.notifiedFor == null) {
      p.notifiedFor = {};
    }
    if (p.drivingFor == null) {
      p.drivingFor = {};
    }
    return p;
  }

  public static getUserType(u: UserProfile): UserType {
    if (u == null) {
      return 'anonymous';
    }
    try {
      if (!!u.cRolesAll && Object.keys(u.cRolesAll).length > 0) {
        return 'customer';
      } else {
        return 'logedIn';
      }
    } catch (error) {
      return 'anonymous';
    }
  }

  public static getUserTypes(u: UserProfile): UserType[] {
    if (u == null) {
      return [];
    }
    try {
      const a: UserType[] = [];
      if (u.isDriver) {
        a.push('driver');
      }
      if (u.isCarrier) {
        a.push('carrier');
      }
      if (u.isShipper) {
        a.push('tracker');
      }
      return a;

    } catch (error) {
      return [];
    }

  }
  public sanitize() {
    this.displayName = toPascalCase(this.displayName);
    if (!!this.email) {
      this.email = this.email.toLocaleLowerCase();
    }
    super.sanitize();
  }

  /** update name / email / phone of the profile from the user claim. */
  public updateFromClaim(claim: IUserClaim) {
    if (!!claim) {
      this.userTypes = getUserType(claim.cClaim?.ut);
      this.displayName = claim.name;
      this.email = claim.email;
      this.phoneNumber = claim.phone_number;
      if (this.displayName == null) {
        this.displayName = this.email.split('@')[0];
      }
    }
  }


  // public static getDefaultCompany(cRoll: ICompanyRoleProfile): IDefaultCompanyData {

  //   const k = keys(cRoll);

  //   if (k == null || k.length === 0) { return null; }

  //   // ICompanyRoleProfile has default, ICompanyRoleClaim has claim.
  //   // const cRollClaim = <ICompanyRoleClaim>cRoll;
  //   const cRollProfile = cRoll;

  //   let defK = k.find(x => cRollProfile[x].default);
  //   if (defK == null) { defK = k[0]; }

  //   const vProfile = cRollProfile[defK];
  //   const r = { cid: defK, roles: { ...vProfile.roles }, cName: vProfile.cName };
  //   cleanupUndefined(r);
  //   return r;
  // }

  /**
  * @param storage Local Storage plugin
  */
  async loadSettingsLocal(uid: string | number, storage: ILocalStorage) {
    if (storage == null) {
      return null;
    }
    const key = `${uid}-us`;
    try {
      const localObj = await storage.get(key);
      if (!!localObj) {
        // logger.log('local setting exist in the local storage as: ', settingObj);
        this.setting = localObj.setting;
        this.notifiedFor = localObj.notifiedFor;
        this.drivingFor = localObj.drivingFor;
        return <{ setting: UserSetting, notifiedFor: INotifiedFor }>localObj;
      } else {
        logger.log('local user setting does not exist');
        return null;
      }
    } catch (error) {
      logger.log(`getting local storage db data for key: ${key} threw an error!!`);
      logger.error(error);
      return null;
    }
  }

  async saveSettingsLocal(uid: string | number, storage: ILocalStorage) {
    const key = `${uid}-us`;
    let l;
    try {
      l = await storage.get(key);
      logger.log('Current Local Storage : Key', await storage.get(key));

    } catch (error) {
      logger.log('Current Local Key not found!');
    }

    try {
      logger.log('saving local key as: ', this.setting);
      const uSetting: UserSetting = l && l.setting ? cloneDeep(l.setting) : {};
      for (const k in this.setting) {
        if (this.setting.hasOwnProperty(k)) {
          const element = this.setting[k];
          uSetting[k] = element;
        }
      }
      if (!uSetting.defaultUserType) {
        uSetting.defaultUserType = UserProfile.getUserTypes(UserProfile.parse(this))[0];
      }
      await storage.set(key, { setting: uSetting, notifiedFor: this.notifiedFor, drivingFor: this.drivingFor });
    } catch (error) {
      logger.log('Local cache could not be saved with user settings!');
    }
    logger.log('After setting Local Storage : Key', await storage.get(key));

  }

  // public toFirebaseObj() {
  //   const o = firestoreMap(this, ['id', 'createdFromDb', 'isFullAuth']);
  //   cleanupUndefined(o);
  //   return o;
  // }

  /**
   * Fetch the claim from profile. Note, it is assumed that profile is uptodate from db.
   * Otherwise claim can be wrongfully set.
   */
  get claim(): IUserAuthClaim {
    // if (!this.createdFromDb) {
    //   throw Error('Claim can not be created from User profile that was not fetched from Db.');
    // }
    // total number of company data s
    const companyKeys = keys(this.cRolesAll);
    const nComp = companyKeys.length;

    const full = nComp <= UserProfile.maxClaimNComp;

    const l = (full) ? nComp : UserProfile.maxClaimNComp;

    // const def = this.defaultCompany;
    // if (def != null) {
    //   --l;
    //   companyKeys = companyKeys.filter(x => x !== def.cid);
    // }

    const cRoles: ICompanyRoleProfile = {};
    for (let i = 0; i < l; i++) {
      const key = companyKeys[i];
      cRoles[key] = this.cRolesAll[key];
    }

    // cleanup the undefined.
    const r: IUserAuthClaim = {
      ut: getUserTypeNumber(this.userTypes),
      ag: 0,
      roles: { ...this.roles },
      cRoles: mapCompRoleToClaim(cRoles),
      full: full
    };
    return cleanupUndefined(r);
    // return r;
  }

  public clone(): UserProfile {
    const u = instanceToInstance<UserProfile>(this);
    u.sanitize();
    return u;
  }

  /** Is user admin of the site. */
  get isAdmin(): boolean {
    return !!this.roles && this.roles[RoleEnum.admin];
  }
  /** Is user editor of the site. */
  get isEditor(): boolean {
    return !!this.roles && this.roles[RoleEnum.editor];
  }
  /** Is user editor of the site. */
  get isCredit(): boolean {
    return !!this.roles && !!this.roles[RoleEnum.credit];
  }

  /** Highest role of the user in the given company */
  getMaxCompanyUserRole(cid: string) {
    return getMaxCompanyUserRole(this, cid);
  }

  /** Get number based auth level of this user in the provided company.
   * Owner: 4, Admin: 3, Execture: 2, Reader: 1 , not authorized: 0*/
  getCompanyAuthLevel(cid: string) {
    return getCompRoleAuthLevel(this.getCompanyUserProfile(cid).roles);
  }

  /**
   * Get minni profile data for company user.
   * Note: If company id is not provided, it will not return the roles.
   */
  public getCompanyUserProfile(cid?: string | number): CompanyUserProfile {
    const r = (!!cid && this.cRolesAll) ? this.cRolesAll[cid] : null;
    return {
      uid: this.id,
      email: this.email,
      displayName: this.displayName,
      roles: (!!r) ? r.roles : null,
      // dataFlag: true
    };
  }



  // public addSiteRole(r: RoleEnum) { this.roles[r] = true; }

  public removeSiteRole(r: RoleEnum) {
    if (!!this.roles[r]) {
      delete this.roles[r];
    }
  }

  public updateCompanyRole(cid: string, cr: RoleCompany, cName?: string /*, def?: boolean*/) {
    if (this.cRolesAll == null) {
      this.cRolesAll = {};
    }
    // remove the role if null was provided.
    if (cr == null || Object.keys(cr).length === 0) {
      delete this.cRolesAll[cid];
    } else {
      this.cRolesAll[cid] = {
        // default: def,
        roles: cr,
        cName: cName
      };
    }
  }



  /** Update 'this' from the given profile. Note: Only provided groups are covered. */
  public updateFrom(p: UserProfile, group: ProfileValidationGroup[]) {
    for (let idx = 0; idx < group.length; idx++) {
      const g = group[idx];
      switch (g) {
        case 'general':
          this.displayName = p.displayName;
          this.userTypes = copyObject(p.userTypes);
          break;
        default:
          throw Error(`[User-Profile] Updating Profile not implemented for group: ${g}`);
      }
    }

  }

  public removeCompanyRole(cid: string, cr: RoleCompanyEnum) {
    const c = this.cRolesAll[cid];
    if (!!c) {
      if (!!c.roles[cr]) {
        delete c.roles[cr];
      }
      // If No roles are left, remove the company as well
      if (keys(c.roles).length === 0) {
        delete this.cRolesAll[cid];
      }
    }
  }

  /**
   * Remove a company authorizations from User profile.
   * @param cid company id that need to remoed.
   */
  public removeCompanyAuth(cid: string) {
    if (!!this.cRolesAll && !!this.cRolesAll[cid]) {
      delete this.cRolesAll[cid];
    }
  }

  /**
   * @author MKN 
   * Check Hierarchy is required or not for th
   */
  public checkHierarchyAccessIsRequiredForRole() {
    return (!!this.roles && (this.roles.sales)) ? true : false;
  }

  /**
   * add role group info in BranchManager array
   * @param roleGroup
   */
  public assignBranchManager(rg: RoleGroup) {
    if (!this.branchManagers) {
      this.branchManagers = {};
    }
    this.branchManagers[rg.id] = {
      name: rg.name,
      shortName: rg.shortName
    }
  }

  /**
   * add role group info in RegionManager array
   * @param roleGroup
   */
  public assignRegionManager(rg: RoleGroup) {
    if (!this.regionManagers) {
      this.regionManagers = {};
    }

    this.regionManagers[rg.id] = {
      name: rg.name,
      shortName: rg.shortName
    }
  }

  /**
   * add role group info in CountryManager array
   * @param roleGroup
   */
  public assignCountryManager(rg: RoleGroup) {
    if (!this.countryManagers) {
      this.countryManagers = {};
    }

    this.countryManagers[rg.id] = {
      name: rg.name,
      shortName: rg.shortName
    }
  }

  /**
   * add role group info in EnterpriseManager array
   * @param roleGroup
   */
  public assignEnterpriseManager(rg: RoleGroup) {
    if (!this.enterpriseManagers) {
      this.enterpriseManagers = {};
    }

    this.enterpriseManagers[rg.id] = {
      name: rg.name,
      shortName: rg.shortName
    }
  }

  /**
   * add role group info with level
   * @param shortName roleGroup Short Name
   * @param rgLevel role group levels
   */
  public addRoleGroupWithLevel(shortName: string, rgLevel: RoleGroupLevel) {
    if (!this.roles) {
      this.roles = {};
    }

    if (!this.roles.rGrp) {
      this.roles.rGrp = {};
    }
    this.roles.rGrp[shortName] = rgLevel;
  }


  /**
   * cleared non-active roles data i.e. false
   */
  public clearedRoles() {
    for (let key in this.roles) {
      if (!this.roles[key]) delete this.roles[key];
    }
  }

  /**
  * MKN - Validate role group (Assigning role group and levels at user level)
  */
  public validateRoleGroupSync(options: ValidatorOptions): IValidationMsg {
    const r = this.validateSyncBase(this, options);

    if (!this.roles.rGrp || (this.roles.rGrp && (Object.keys(this.roles.rGrp).length == 0))) {
      r['roleGroup'] = ['Please select group'];
    }
    if (this.roles.rGrp && Object.keys(this.roles.rGrp).length > 0) {
      for (let key in this.roles.rGrp) {
        const roleGroupSubLevel = this.roles.rGrp[key];
        const subLevel = Object.values(roleGroupSubLevel);
        if (!subLevel.includes(true)) {
          r['roleGroupLevel'] = [`Please select sub level for ${key}`];
        }
      }
    }

    return r;
  }

  public validateSync(options?: ValidatorOptions): IValidationMsg {

    if (!options) options = {};

    if (options && !options.groups) {
      options.groups = ['general'];
    }

    const r = this.validateSyncBase(this, options);

    // // Insurance expiry data must be in the future.
    // if (isDate(this.insExpiryDate)) {
    //   if (isAfter(new Date(), this.insExpiryDate)) {
    //     r['insExpiryDate'] = ['Insurance expiry date must be in future'];
    //   }
    // }
    // Add any additional validation here if/as required.
    return r;
  }

  /** Remove admin related data before updating the db. to ensure a user does not assign himself some super powwers
   * during updating his profile.
   * IMP: Change @method addAdminData if you make any changeto this function. */
  public removeAdminData() {
    this.roles = {};
    this.cRolesAll = {};
  }

  /** used by the functions to update admin data while sending the changed obj to client. */
  // tslint:disable-next-line:member-ordering
  public static addAdminData(src: UserProfile, dest: UserProfile) {
    dest.roles = src.roles;
    dest.cRolesAll = src.cRolesAll;
  }



  public getUserPhone() {
    const p = new UserPhone();
    p.phoneNumber = this.phoneNumber;
    return p;
  }
  public setDrivingForStatus(d: DrivingFor, driverLists: DriverList[]) {
    driverLists.forEach(e => {
      const dFor = d.drivingForUI[e.cid];
      if (!!d) {
        this.drivingFor = !this.drivingFor ? <any>{} : this.drivingFor;
        this.drivingFor[e.cid] = !this.drivingFor[e.cid] ? <any>{} : this.drivingFor[e.cid];
        this.drivingFor[e.cid].isActive = dFor.isActive;
      }
    });
    return this;
  }
  /**
   * Update user communication settings
   * @param d User Communication Preferences
   */
  updateCommSettings(commPreference: CommPreference): UserProfile {
    this.setting = this.setting ? this.setting : <UserSetting>{};
    this.setting.commPreference = instanceToPlain(commPreference);
    return this;
  }
  get isCarrier(): boolean {
    if (!this.cRolesAll) {
      return false;
    }
    for (const key in this.cRolesAll) {
      if (this.cRolesAll.hasOwnProperty(key)) {
        const ele = this.cRolesAll[key];
        if (Object.values(ele.roles).includes(true)) {
          return true;
        }
      }
    }
    return false;
  }

  get isDriver(): boolean {
    if (!this.drivingFor || isEmpty(this.drivingFor)) {
      return false;
    } else {
      return true;
    }
  }
  get isShipper(): boolean {
    if (!this.notifiedFor || isEmpty(this.notifiedFor)) {
      return false;
    } else {
      return true;
    }
  }
  /**
   * set notifierList.
   * called when new user signs up and email exists in carrier lists.
   * called when carrier add existing user in to notifier list
   * @param notfierLists Array of NotifierLists (TripTrackerLists).
   */
  setNotifierFor(notfierLists: TripTrackerList[]) {
    this.notifiedFor = this.notifiedFor ? this.notifiedFor : {};
    for (const l of notfierLists) {
      this.notifiedFor[l.id] = { name: l.carrierCompanySummary.name, legalName: l.carrierCompanySummary.legalName };
    }
  }
  inActiveDrivingFor(cid: string | number) {
    const updatedProf = this.clone();
    if (!updatedProf.drivingFor || !updatedProf.drivingFor[cid] || !updatedProf.drivingFor[cid].isActive) {
      return null;
    } else {
      updatedProf.drivingFor[cid].isActive = false;
      return updatedProf;
    }
  }
  updateNotifierFor(cid: string | number, notfierList: TripTrackerList): UserProfile {
    const updatedProf = this.clone();

    updatedProf.notifiedFor = updatedProf.notifiedFor ? updatedProf.notifiedFor : {};
    switch (notfierList.list[updatedProf.id].itemStatus) {
      case ItemStatus.active:
        updatedProf.notifiedFor[cid] = {
          name: notfierList.carrierCompanySummary.name,
          legalName: notfierList.carrierCompanySummary.legalName
        };
        break;
      case ItemStatus.inactive:
        updatedProf.notifiedFor[cid] = null;
        break;
      default:
        logger.error('programming error');
        return null;
    }
    return updatedProf;

  }

  addCompanyInSales(comp: CompanyFleet) {
    // sales person not having companyAccountIds first create blank object
    if (!this.companyAccountIds) {
      this.companyAccountIds = {};
    }

    let c: { [key: string]: { cName: string } } = {
      [comp.id]: {
        cName: comp.name,
      }
    };
    // taking id of the company updated in sales person profile
    this.companyAccountIds = { ...this.companyAccountIds, ...c };
  }

  /**
   * @author Cm
   * Remove a Company from User profile of sales person.
   * @param cid company id that need to removed.
   */
  public removeCompanyInSales(cid: string) {
    if (!!this.companyAccountIds && !!this.companyAccountIds[cid]) {
      delete this.companyAccountIds[cid];
    }
  }


  addCompanyInCredit(comp: CompanyFleet) {
    // sales person not having companyAccountIds first create blank object
    if (!this.assignedCompanies) {
      this.assignedCompanies = [];
    }

    let c = {
      companyName: comp.name,
      companyId: comp.id
    };
    // taking id of the company updated in sales person profile
    this.assignedCompanies.push(AssignedCompany.parse(c));
  }

    /**
   * @author Cm
   * Remove a Company from User profile of credit person.
   * @param cid company id that need to removed.
   */
    public removeCompanyInCredit(cid: string) {
      if (!!this.assignedCompanies) {
        this.assignedCompanies = this.assignedCompanies.filter(x => x.companyId !== cid);
      }
    }
  

}




