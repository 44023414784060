import { Injectable } from '@angular/core';
import { BaseHttpService } from './base-http.service';
import { Store } from '@ngxs/store';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { instanceToPlain } from 'class-transformer';
import { tap, map, switchMap, take } from 'rxjs/operators';
import { TripParam, tripSearchServerQuery } from '@trent/models/trip/trip-param';
import { Observable, of, forkJoin } from 'rxjs';
import { sanitizeDateIPoint } from '@trent/models/utility';
import { FirestoreService } from './firestore.service';
import { TripBase, TripValidationGroup } from '@trent/models/trip';
import { PagingObesrvable } from '@trent/models/observable-util/paging-obesrvable';
import { Paging } from '@trent/models/observable-util/paging';
import { ProductType } from '@trent/models/product';
import { SingletonService } from './singleton.service';
import { ITripGpsStatus, gpsStatusColName } from '../models/trip/trip-gps-status';
import { logger } from '@trentm/log/logger';
import { LocationData } from '@trent/models/location/loc-data';
import { equalTo, orderByChild, query } from '@angular/fire/database';

@Injectable()
export class TripService extends BaseHttpService {

  private get rtdb() { return this.sa.firebaseRTDBService; }

  constructor(store: Store, private db: FirestoreService, private http: HttpClient, protected sa: SingletonService) {
    super(store);
    this.apiName = 'api';
  }
  public createTrip(t: TripBase, cid: string | number, options: TripValidationGroup = 'schedule') {
    // Prepare the post data
    logger.log('trip at service', t);

    //    const headers = new HttpHeaders({ Authorization: 'Bearer ' + this.token });
    const headers = this.addBearerToken();
    const seq = t.tripSequence;
    const seqPlain = seq.map(s => s = instanceToPlain(s) as any);
    const tripPlain = instanceToPlain(t) as TripBase;

    tripPlain.tripSequence = seqPlain;
    logger.log('tripPlain', tripPlain);

    return this.http.post<{ tid: string | number, trip: TripBase }>(this.getApiUrl('/trip/createTrip'),
      // Server validation before creating the entry.
      {
        trip: tripPlain,
        options: options,
        cid: cid

      },
      // tslint:disable-next-line:object-literal-shorthand
      { headers: headers })
      .pipe(
        tap(res => logger.log('response: ', res))
      ).toPromise();
  }
  public updateTrip(tid: string | number, t: TripBase, cid: string | number, options: TripValidationGroup = 'schedule') {
    logger.log('trip at service', t);

    const seq = t.tripSequence;
    const seqPlain = seq.map(s => s = instanceToPlain(s) as any);
    const tripPlain = instanceToPlain(t) as TripBase;
    tripPlain.tripSequence = seqPlain;
    logger.log('tripPlain', tripPlain);

    const headers = this.addBearerToken();
    // Server validation before creating the entry.
    return this.http.post<{ tid: string | number, trip: TripBase }>(this.getApiUrl('/trip/updateTrip'),
      {
        trip: tripPlain,
        tid: tid,
        options: options,
        cid: cid

      },
      // tslint:disable-next-line:object-literal-shorthand
      { headers: headers })
      .pipe(
        tap(res => logger.log('response: ', res))
      ).toPromise();

  }
  deletePods(cid: string | number, tripId: string | number, seqIndex: number, picIndex?: number) {
    const headers = this.addBearerToken();
    // Server validation before creating the entry.
    return this.http.post<{ tid: string | number, trip: TripBase }>(this.getApiUrl('/trip/deletePods'),
      {
        // tslint:disable-next-line:object-literal-shorthand
        cid: cid,
        tid: tripId,
        // tslint:disable-next-line:object-literal-shorthand
        seqIndex: seqIndex,
        // tslint:disable-next-line:object-literal-shorthand
        picIndex: picIndex
      },
      // tslint:disable-next-line:object-literal-shorthand
      { headers: headers })
      .pipe(
        tap(res => logger.log('response: ', res))
      );
  }
  manuallyComplete(cid: string | number, tripId: string | number, seqIndex?: number) {
    const headers = this.addBearerToken();
    // Server validation before creating the entry.
    return this.http.post<{ tid: string | number, trip: TripBase }>(this.getApiUrl('/trip/manuallyComplete'),
      {
        cid: cid,
        tid: tripId,
        seqIndex: seqIndex,
      },
      // tslint:disable-next-line:object-literal-shorthand
      { headers: headers })
      .pipe(
        tap(res => logger.log('response: ', res))
      ).toPromise();
  }
  resetTrip(cid: string | number, tripId: string | number) {
    const headers = this.addBearerToken();
    // Server validation before creating the entry.
    return this.http.post<{ tid: string | number, trip: TripBase }>(this.getApiUrl('/trip/resetTrip'),
      {
        cid: cid,
        tid: tripId,
      },
      // tslint:disable-next-line:object-literal-shorthand
      { headers: headers })
      .pipe(
        tap(res => logger.log('response: ', res))
      ).toPromise();
  }
  expireTrip(cid: string, tid: string | number) {
    const headers = this.addBearerToken();
    // Server validation before creating the entry.
    return this.http.post<{ tid: string | number, trip: TripBase }>(this.getApiUrl('/trip/expire-trip'),
      {
        cid,
        tid
      },
      // tslint:disable-next-line:object-literal-shorthand
      { headers: headers })
      .pipe(
        tap(res => logger.log('response: ', res))
      ).toPromise();
  }
  public getTripById(id: string | number) {
    return this.db.docWithInjectedId$<TripBase>(
      `${TripBase.collectionName}/${id}`, id);
  }
  public getAllTrips_PagingObservable() {
    const p$: PagingObesrvable<TripBase, TripParam> =
      new PagingObesrvable<TripBase, TripParam>(this.db,
        (p: Paging, param?: TripParam) => this.getAllTrips_batch(p, param));
    return p$;
  }
  private getAllTrips_batch(p: Paging, param: TripParam): Observable<{ [key: string]: TripBase }> {

    logger.log('Product Server called with', p.offset, p.size);
    logger.log('RentOptionParam', param);
    let r: Observable<any>;

    if (param.type === ProductType.trip) {
      // if radius is less 99 you geofirex to get documents by radius if radius is greater than 99 use avialEndDate to search documents 
      r = this.db
        .colWithIdsInjectedNew$<TripBase>(TripBase.collectionName,
          ref => tripSearchServerQuery(ref, param, p));
    }
    return r
      .pipe(
        tap(arr => {
          if (arr == null || arr.length === 0) {
            logger.log('All data is recevied, Paging is FULL');
            p.full = true;
          } else {
            p.lastDoc = arr[arr.length - 1];
          }
        }),
        map(o => {
          const arr = sanitizeDateIPoint(o);
          logger.log('Got here 148', arr);
          return arr.reduce((acc, cur) => {
            const id = cur.id;
            const data = cur;
            return { ...acc, [id]: data };
          }, {});
        })
      );
  }

  getGpsStatus(id: string): Observable<ITripGpsStatus> {
    // const x: ITripGpsStatus = {
    //   isBroadCasting: true,
    //   updatedAt: new Date()
    // };
    // return of(x);
    return this.rtdb.obj$<ITripGpsStatus>(`${gpsStatusColName}/${id}`);
  }

  getGpsLogs(tid: string | number): Observable<LocationData[]> {
    interface LogI { d: LocationData[]; time: Date; uid: string; tid: string; udx: string; tdx: string; }

    return this.rtdb.listWithIdsInjected$<LogI>('gps-log', ref =>
      query(ref,
        orderByChild('tid'),
        equalTo(`${tid}`))).pipe(
          map(d => {
            const locArray: LocationData[] = [];
            for (const log of d) {
              for (const p of log.d) {
                locArray.push(p);
              }
            }
            return locArray.sort((a, b) => a.createdOn?.valueOf() - b.createdOn?.valueOf());
          })
        );
  }
}