import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { StoreLocationService } from '@trent/services/store-location.service';
import { StoreLocationState } from './store-location.state';
import { UserService } from '@trent/services/user.service';

@NgModule({
  imports: [
    CommonModule,
    NgxsModule.forFeature([StoreLocationState])
  ],
  providers: [
    StoreLocationService,
    UserService
  ]
})
export class StoreLocationStoreModule { }
