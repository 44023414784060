import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { LegacyContractState } from './legacy-contract.state';
import { LegacyContractService } from '@trent/services/legacy-contract.service';
import { AlgoliaSearchService } from '@trent/services/algolia-search.service';

@NgModule({
  imports: [
    CommonModule,
    NgxsModule.forFeature([LegacyContractState])
  ],
  providers: [
    LegacyContractService,
    AlgoliaSearchService
  ]
})
export class LegacyContractStoreModule { }
