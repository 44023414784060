// import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { NgModule } from '@angular/core';
import { NgAisModule } from 'angular-instantsearch';
import { AppComponent } from '../app.component';

import { FaIconLibrary } from '@fortawesome/angular-fontawesome';

import { faGoogle, faFacebook, faMicrosoft } from '@fortawesome/free-brands-svg-icons';
import { faRoad } from '@fortawesome/free-solid-svg-icons';

import { baseDeclarations, baseExports, baseImports, baseProviders } from '../ws-common/app-module-base-data';
import { AppDpcRoutingModule } from './app-dpc-routing.module';
import { ShellDpcComponent } from '../shared/shell-dpc/shell-dpc.component';
import { DpcModule } from '../dpc/dpc.module';
import { DpcGlobalSearchPopupComponent } from '../dpc/dpc-global-search-popup/dpc-global-search-popup.component';
import { PtsFooterModule } from '../pts/pts-footer/pts-footer.module';
import { SearchSalesOptionModule } from '../shared/pts-search-sales-option/pts-search-sales-option.module';

/**
 * App Module to be used for WebSite: Pride Truck Sales.
 * Only Add content ( Imports / Declaration / Exports etc.) here that is specific to PRIDE TRUCK SALES.
 * Common content to be used in all websites must be used in App-base-data.ts file.  
 */
@NgModule({
  declarations: [
    ...baseDeclarations,
    /** Components unique to Pride Truck Sales */
    ShellDpcComponent,
    DpcGlobalSearchPopupComponent
  ],
  imports: [
    ...baseImports,
    /** import modules specific for Pride Truck Sales */
    AppDpcRoutingModule,
    PtsFooterModule,
    SearchSalesOptionModule,
    DpcModule,
    NgAisModule.forRoot()
  ],
  exports: [
    ...baseExports
  ],
  providers: [
    ...baseProviders
  ],
  bootstrap: [AppComponent]
})
export class AppDpcModule {

  // Add fontowesome icons.
  constructor(library: FaIconLibrary) {

    // https://github.com/FortAwesome/angular-fontawesome/blob/master/docs/usage/using-other-styles.md
    library.addIcons(faGoogle, faFacebook, faRoad, faMicrosoft);
  }
}
