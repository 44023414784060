import { AuthState } from '../store/auth-store/auth.state';
import { ValidatorOptions } from 'class-validator';
import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { plainToInstance, instanceToPlain } from 'class-transformer';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { BaseHttpService } from '@trent/services/base-http.service';
import { Store } from '@ngxs/store';
import { Paging } from '@trent/models/observable-util/paging';
import { logger } from '@trent/models/log/logger';
import { FirestoreService } from './firestore.service';
import { PagingObesrvable } from '@trent/models/observable-util/paging-obesrvable';
import { Observable } from 'rxjs';
import { IPromotionData, Promotions } from '@trent/models/promotional-messages/promotions';
import { IPromotionParam, promotionSearchServerQuery } from '@trent/models/promotional-messages/promotions-param';
import { PromotionLeads } from '@trent/models/promotional-messages/promotion-leads';
import { IPromotionLeadsParam, promotionLeadsSearchServerQuery } from '@trent/models/promotional-messages/promotion-leads-param';
import { PromotionResponseType } from '@trent/models/promotional-messages/promotion-response';

@Injectable()
export class PromotionsService extends BaseHttpService {

  constructor(store: Store, private http: HttpClient,private db: FirestoreService,) {
    super(store);
    this.apiName = 'api';
  }

  getPromotionsDataById(id: string | number) {
    return this.db.docWithInjectedId$<Promotions>(`${Promotions.collectionName}/${id}`);
  }
  
  getPromotionLeadsDataById(id: string | number) {
    return this.db.docWithInjectedId$<PromotionLeads>(`${PromotionLeads.collectionName}/${id}`);
  }

  // public getAllRoleGroup_PagingObservable() {
  //   const p$: PagingObesrvable<RoleGroup, IRoleGroupParam> =
  //     new PagingObesrvable<RoleGroup, IRoleGroupParam>(this.db,
  //       (p: Paging, param?: IRoleGroupParam) => this.getAllPromotions_batch(p, param));
  //   return p$;
  // }

  /**
 * Get the next batch of the data from server.
 * @param p paging information.
 */
  public getAllPromotions_batch(p: Paging, param: IPromotionParam): Observable<{ [key: string]: Promotions }> {
    if (param.orderBy == null) { param.orderBy = 'updatedAt'; }
    if (param.orderDirection == null) { param.orderDirection = 'desc'; }
    return this.db
      .colWithIdsInjectedNew$<Promotions>(`${Promotions.collectionName}`, ref => promotionSearchServerQuery(ref, param, p))
      .pipe(
        tap(arr => {
          if (arr == null || arr.length === 0) {
            logger.log('All data is recevied, Paging is FULL');
            p.full = true;
          } else {
            p.lastDoc = arr[arr.length - 1];
          }
        }),
        map(arr => {
          logger.log('From Server, before mapping is applied: ', arr);

          const arrayAsObj = arr.reduce((acc, cur) => {
            const id = cur.id;
            const data = cur;
            return { ...acc, [id]: data };
          }, {});

          logger.log('after converting to array object dic ', arrayAsObj);
          return arrayAsObj;
        })
      );
  }

  public getAllPromotionLeads_batch(p: Paging, param: IPromotionLeadsParam): Observable<{ [key: string]: PromotionLeads }> {
    if (param.orderBy == null) { param.orderBy = 'updatedAt'; }
    if (param.orderDirection == null) { param.orderDirection = 'desc'; }
    return this.db
      .colWithIdsInjectedNew$<PromotionLeads>(`${PromotionLeads.collectionName}`, ref => promotionLeadsSearchServerQuery(ref, param, p))
      .pipe(
        tap(arr => {
          if (arr == null || arr.length === 0) {
            logger.log('All data is recevied, Paging is FULL');
            p.full = true;
          } else {
            p.lastDoc = arr[arr.length - 1];
          }
        }),
        map(arr => {
          logger.log('From Server, before mapping is applied: ', arr);

          const arrayAsObj = arr.reduce((acc, cur) => {
            const id = cur.id;
            const data = cur;
            return { ...acc, [id]: data };
          }, {});

          logger.log('after converting to array object dic ', arrayAsObj);
          return arrayAsObj;
        })
      );
  }
  public getPromotionCount(param: IPromotionLeadsParam): Promise<number> {
    if (param.orderBy == null) { param.orderBy = 'updatedAt'; }
    if (param.orderDirection == null) { param.orderDirection = 'desc'; }
    return this.db
      .colCount$<PromotionLeads>(`${PromotionLeads.collectionName}`, ref => promotionLeadsSearchServerQuery(ref, param, undefined));
  
  }

  public create(data : IPromotionData) {
    // Prepare the post data
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + this.token });

    // Server validation before creating the entry.
    return this.http.post<any>(this.getApiUrl('/promotions/create'), data,
      // tslint:disable-next-line:object-literal-shorthand
      { headers: headers })
      .pipe(
        tap(r => logger.log('response: ', r)),
      );
  }

  
  public update(data : IPromotionData) {
    // Prepare the post data
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + this.token });

    // Server validation before creating the entry.
    return this.http.post<any>(this.getApiUrl('/promotions/update'), data,
      // tslint:disable-next-line:object-literal-shorthand
      { headers: headers })
      .pipe(
        tap(r => logger.log('response: ', r)),
      );
  }

  
  public sendMail(data : { promotionId : string, sendPromotionType : PromotionResponseType, sendLeadsCount : number, }) {
    // Prepare the post data
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + this.token });

    // Server validation before creating the entry.
    return this.http.post<any>(this.getApiUrl('/promotions/send-mail'), data,
      // tslint:disable-next-line:object-literal-shorthand
      { headers: headers })
      .pipe(
        tap(r => logger.log('response: ', r)),
      );
  }

  
  public sendSms(data : { promotionId : string, sendPromotionType : PromotionResponseType, sendLeadsCount : number, country : 'USA' | 'CA' }) {
    // Prepare the post data
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + this.token });

    // Server validation before creating the entry.
    return this.http.post<any>(this.getApiUrl('/promotions/send-sms'), data,
      // tslint:disable-next-line:object-literal-shorthand
      { headers: headers })
      .pipe(
        tap(r => logger.log('response: ', r)),
      );
  }

  /**
     * Bulk upload legacy contract records
     * @param lCList 
     * @returns 
     */
  public bulkCreate(plList: PromotionLeads[]) {
    // Prepare the post data
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + this.token });

    // Server validation before creating the entry.
    return this.http.post<any>(this.getApiUrl('/promotions/bulk-create'),
        {
          plList: instanceToPlain(plList),
        },
        // tslint:disable-next-line:object-literal-shorthand
        { headers: headers })
        .pipe(
            tap(r => logger.log('response: ', r)),
        );
}
  /**
     * Bulk upload legacy contract records
     * @param lCList 
     * @returns 
     */
   public setDistribution(promotionId: string | number) {
    // Prepare the post data
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + this.token });

    // Server validation before creating the entry.
    return this.http.post<any>(this.getApiUrl('/promotions/setup-distribution'),
        {
          promotionId: promotionId,
        },
        // tslint:disable-next-line:object-literal-shorthand
        { headers: headers })
        .pipe(
            tap(r => logger.log('response: ', r)),
        );
}
  /**
     * Bulk upload legacy contract records
     * @param lCList 
     * @returns 
     */
   public cleanBadPhoneNumbers() {
    // Prepare the post data
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + this.token });

    // Server validation before creating the entry.
    return this.http.post<any>(this.getApiUrl('/promotions/cleanup-bad-phones'),
        {
        },
        // tslint:disable-next-line:object-literal-shorthand
        { headers: headers })
        .pipe(
            tap(r => logger.log('response: ', r)),
        );
}
  
}
