import { Exclude, Expose, plainToInstance } from 'class-transformer';
import { IsNumber, IsBoolean, IsEnum, ValidateIf, IsPositive, validateSync, ValidatorOptions } from 'class-validator';
import { sanitizeDateIPoint } from '../utility/sanitize-helper';
import { IValidationMsg } from '../error-handling/validation-info';
import { toValidationError } from '../utility';
import { round } from 'lodash';

export enum ConsumableUnit {
  liters = 1,
  gallons = 2,
}
export enum ConsumableType {
  diesel = 1,
  def = 2,
}
export const dieselPerGallon = 5;
export const defPerGallon = 4;

const gName = 'IncidentalCharges';
@Exclude()
export class IncidentalCharges {

  public static readonly gName = gName;
  constructor() {
    // super();
  }
  @ValidateIf(o => !!o.chargeFuel)
  @Expose()
  @IsPositive({ message: 'invalid fuelCharges', groups: [gName] })
  @IsNumber({ allowNaN: false, allowInfinity: false }, { message: 'invalid fuelCharges', groups: [gName] })
  fuelCharges: number;

  @ValidateIf(o => !!o.chargeDef)
  @Expose()
  @IsPositive({ message: 'invalid DEF Charges', groups: [gName] })
  @IsNumber({ allowNaN: false, allowInfinity: false }, { message: 'invalid DEF Charges', groups: [gName] })
  defCharges: number;

  @ValidateIf(o => !!o.chargeFuel)
  @Expose()
  @IsPositive({ message: 'invalid fuel short', groups: [gName] })
  @IsNumber({ allowNaN: false, allowInfinity: false }, { message: 'Enter Qty of Fuel Short', groups: [gName] })
  fuelShort: number;


  @ValidateIf(o => !!o.chargeDef)
  @Expose()
  @IsPositive({ message: 'invalid DEF short', groups: [gName] })
  @IsNumber({ allowNaN: false, allowInfinity: false }, { message: 'Enter Qty of DEF Short', groups: [gName] })
  defShort: number;

  @Expose()
  @IsBoolean({ message: 'invalid chargeFuel', groups: [gName] })
  chargeFuel = false;

  @Expose()
  @IsBoolean({ message: 'invalid chargeDef', groups: [gName] })
  chargeDef = false;

  @ValidateIf(o => !!o.chargeFuel || !!o.chargeDef)
  @Expose()
  @IsPositive({ message: 'invalid Incidental Charge', groups: [gName] })
  @IsNumber({ allowNaN: false, allowInfinity: false }, { message: 'invalid Incidental Charge', groups: [gName] })
  totalIncidentalCharge = 0;

  @ValidateIf(o => !!o.chargeFuel)
  @Expose()
  @IsEnum(ConsumableUnit, { message: 'invalid fuelUnits', groups: [gName] })
  fuelUnits: ConsumableUnit = ConsumableUnit.gallons;

  @ValidateIf(o => !!o.chargeDef)
  @Expose()
  @IsEnum(ConsumableUnit, { message: 'invalid DEF Units', groups: [gName] })
  defUnits: ConsumableUnit = ConsumableUnit.gallons;

  get fuelCost() {
    let f: number;
    if (this.fuelShort > 0 && this.chargeFuel === true) {
      f = this.fuelUnits === ConsumableUnit.gallons ? this.fuelShort * dieselPerGallon :
        this.fuelUnits === ConsumableUnit.liters ? this.fuelShort * dieselPerGallon / 3.78 : 0;
    } else {
      f = 0;
    }
    return f === 0 ? 0 : round(f, 2);
  }
  get defCost() {
    let d: number;
    if (this.defShort > 0 && this.chargeDef === true) {
      d = this.defUnits === ConsumableUnit.gallons ? this.defShort * defPerGallon :
        this.defUnits === ConsumableUnit.liters ? this.defShort * defPerGallon / 3.78 : 0;
    } else {
      d = 0;
    }
    console.log(d);

    return d === 0 ? 0 : round(d, 2);
  }
  get total() {
    let t = 0;
    console.log('this.fuelCharges', this.fuelCharges);
    console.log('this.defCharges', this.defCharges);
    if (this.fuelCharges > 0) {
      t = t + this.fuelCharges;
    }
    if (this.defCharges > 0) {
      t = t + this.defCharges;
    }
    return round(t, 2);
  }
  public static parse(obj) {
    if (obj == null) { return null; }

    if (obj instanceof (IncidentalCharges)) { return <IncidentalCharges>obj; }

    const m = plainToInstance<IncidentalCharges, any>(IncidentalCharges, sanitizeDateIPoint(obj));
    m.sanitize();
    return m;
  }
  sanitize() {
    // super.sanitize();
  }
  validateSync(options: ValidatorOptions): IValidationMsg {
    const r = validateSync(this, options);
    const m = toValidationError(r);
    return m;
  }
}
