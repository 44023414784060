import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { algoliaClientConfig } from '@trent/core/environments/fb-env';

@Injectable()
export class AlgoliaSearchService {

    constructor(private http: HttpClient) { }

    /**
     * Fetches data from Algolia using a given query and index name.
     * @param {IAlgoliaQuery} query - The Algolia query object.
     * @param {string} indexName - The name of the index to query.
     * @returns {Observable<IApiResponse>} - An observable that emits the API response.
     */
    public getDataUsingAlgolia(query: IAlgoliaQuery, indexName: string): Observable<IApiResponse> {
        const href = `${algoliaClientConfig.baseUrl}/1/indexes/${indexName}/query`;
        const headers = {
            headers: new HttpHeaders({
                'X-Algolia-Application-Id': algoliaClientConfig.appId,
                'X-Algolia-API-Key': algoliaClientConfig.searchApiKey,
                'Content-Type': 'application/json'
            })
        };
        return this.http.post(href, query, headers) as Observable<IApiResponse>;
    };
};

// Algolia API response
export interface IApiResponse {
    exhaustiveNbHits: boolean;
    exhaustiveTypo: boolean;
    hits: any[];
    hitsPerPage: number; //page size
    nbHits: number; //total records
    nbPages: number; //total pages
    page: number; //page number
    params: string; // "hitsPerPage=10"
    query: string;
    renderingContent: object;
};

// Algolia Query
// https://www.algolia.com/doc/api-reference/api-parameters
export interface IAlgoliaQuery {
    query: string;
    restrictSearchableAttributes?: string[]; //  ["customerCompSummary.applicant", "leaseNo"],
    filters?: string; // exact match ex - isActive:true AND productSummary.productType=2 AND isExclusive:false
    hitsPerPage: number;
    page?: number;
    aroundLatLng?: string;
    aroundRadius?: string;
    ranking?: string;// "desc(availEndDate)",
};