import { CloudMessageWebService } from '@trent/services/fcm/cloud-message-web.service';
import { NgModule, Type, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreLazyComponent } from './core-lazy.component';
import { CoreLazyService } from './core-lazy.service';
import { AuthService } from '@trent/services/auth/auth.service';
import { AuthStoreModule } from '@trent/store/auth-store/auth-store.module';
import { UserService } from '@trent/services/user.service';
import { HttpClientModule } from '@angular/common/http';
import { IcloudMessageService } from '@trent/services/fcm/icloud-message.service';
import { environment } from '@trent/core/environments/environment';
import { Device } from '@awesome-cordova-plugins/device/ngx';
// import { Geolocation } from '@ionic-native/geolocation/ngx'; (REMOVED HG, Aug 22. May need it later)
import { IgeoLocationService } from '@trent/services/geo-loc/igeo-location.service';
import { GeoLocationWebService } from '@trent/services/geo-loc/geo-location-web.service';
import { IdeviceIdService } from '@trent/services/device/idevice-id.service';
import { DeviceIdWebService } from '@trent/services/device/device-id-web.service';
import { DeviceIdCdvaService } from '@trent/services/device/device-id-cdva.service';
import { FileBaseService } from '@trent/services/file/file-base-service';
import { FileCdvaService } from '@trent/services/file/file-cdva-service';
import { CloudMessageMobileService } from '@trent/services/fcm/cloud-message-mobile.service';
import { FirebaseRTDBService } from '@trent/services/firebase-rtdb.service';
import { TaskStoreModule } from '@trent/store/task-store';
import { PolicyModalModule } from '../policy/policy-modal.module';
import { BackGroundGeoLocTSoft } from '@trent/services/geo-loc/geo-location-geo-tsoft.service';
import { getMessaging, provideMessaging } from '@angular/fire/messaging';
import { getDatabase, provideDatabase } from '@angular/fire/database';
// import { BackgroundGeolocation } from '@ionic-native/background-geolocation';

/** Lazy module that will be loaded once page is loaded on the client. It is designed to reduce initial load. */
@NgModule({
    declarations: [CoreLazyComponent],
    imports: [
        HttpClientModule,
        CommonModule,
        AuthStoreModule,
        provideMessaging(() => getMessaging()),
        provideDatabase(() => getDatabase()),
        // AngularFireMessagingModule,
        // AngularFireDatabaseModule,
        // AngularFirestoreModule,
        // AngularFireAuthModule,
        TaskStoreModule,
        PolicyModalModule,
    ],
    providers: [
        // Only use services that will not be called by any other module that uses CoreLazyModule.
        CoreLazyService,
        AuthService,
        UserService,
        FirebaseRTDBService,
        // Cloud Messaging Service, different for web and cordova
        { provide: IcloudMessageService, useClass: environment.platform === 'web' ? CloudMessageWebService : CloudMessageMobileService },
        { provide: IgeoLocationService, useClass: environment.platform === 'web' ? GeoLocationWebService : BackGroundGeoLocTSoft },
        { provide: IdeviceIdService, useClass: environment.platform === 'web' ? DeviceIdWebService : DeviceIdCdvaService },
        { provide: FileBaseService, useClass: environment.platform === 'web' ? FileCdvaService : FileCdvaService },
        // environment.platform === "web" ? [] : [Device, Geolocation, BackgroundGeolocation]
        environment.platform === 'web' ? [] : [Device /*, Geolocation*/]
    ]
})
export class CoreLazyModule {
  entryComponentsType: Type<CoreLazyComponent>;
  entryServiceType: Type<CoreLazyService>;

  public static forRoot(): ModuleWithProviders<CoreLazyModule> {
    return {
      ngModule: CoreLazyModule,
      providers: [UserService]
    };
  }

  constructor() {
    this.entryComponentsType = CoreLazyComponent;
    this.entryServiceType = CoreLazyService;
  }
}
