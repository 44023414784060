import { Component, OnInit, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs/internal/Subscription';
import { UtilityService } from '@trent/services/utility.service';
import { ActivatedRoute, Data } from '@angular/router';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { DialogService } from '@trent/services/dialog/dialog.service';
import { LocalStorage } from '@trent/services/local-storage/local-storage.service';
import { Observable } from 'rxjs';
import { PwaInstallation } from '@trent/models/user-agreement/pwa-installation';
import { Platform } from '@angular/cdk/platform';

/**
 * @author - MKN 
 * Auto App installer alert functionality
 */
@Component({
  selector: 'trent-app-installer-alert',
  templateUrl: './app-installer-alert.component.html',
  styleUrls: ['./app-installer-alert.component.scss']
})
export class AppInstallerAlertComponent implements OnInit {

  
  isHandset$: Observable<boolean>;
  isHandset: boolean;
  doNotAsk = false;
  browser: string;
  isIOS: boolean;
  isBlink: boolean;
  canPromptInstall: boolean;
  canPromptAddToScreen: boolean;

  constructor(
    public us: UtilityService,
    private aroute: ActivatedRoute,
    public bottomSheetRef: MatBottomSheetRef<AppInstallerAlertComponent, PwaInstallation>,
    private storage: LocalStorage,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: { title: string, pid: string, rev: number },
    public dialogService: DialogService,
    public platform: Platform) {

  }

  ngOnInit() {
    this.isHandset$ = this.us.isHandset$;
    this.isHandset$.subscribe(h => {
      this.isHandset = h;
    });
    this.canPromptInstall = this.platform.BLINK || this.platform.EDGE;
    this.canPromptAddToScreen = this.platform.IOS && this.platform.SAFARI;
  
  }
  async installApp() {
    this.bottomSheetRef.dismiss(PwaInstallation.promptToInstall);
  }

  async closeAlert() {
    this.bottomSheetRef.dismiss(PwaInstallation.promptLater);
  }
  async doNotAskFn() {
    this.bottomSheetRef.dismiss(PwaInstallation.promptDisabled);
  }
 
}
