import { cleanupUndefined } from '@trentm/utility';
import { isNil } from 'lodash';
export type PaymentTypeB = 'card' | 'token';

/** Token Response recived from Bambora when credit card details are sent */
export interface ITokenResponseB {
  /**	String	The HTTP status code of the tokenization request. */
  code: string;

  /** Error If Any */
  error: { field: string; type: string; message: string };

  /** Only present if no error.The payment token result. */
  token: string;

  /** Only present if no error.The last 4 digits of the card number. */
  last4: string;

  /** Only present if no error.The expiry month of the card. */
  expiryMonth: string;

  /** String	Only present if no error.The expiry year of the card. */
  expiryYear: string;
}

/** Token Data for Bambora */
export interface ITokenB {
  name: string;
  code: string;
  complete: boolean;
}

/** Credit Card data for processing */
export interface ICardB {
  name: string;
  number: number;
  expiry_month: number;
  expiry_year: number;
  cvd: string;

  /** true means process payment, false means for pre-auth */
  complete: boolean;
}

export interface IPaymentProfileB {
  customer_code: string;
  card_id: number;
  complete: boolean;
}


export interface ITransactionRefData {
  orderId?: string;
  invoiceId?: string;
  cid?: string;
  parentTransactionId?: string;
  comment?: string;
}


export const setTransactionRefData = (ref: ITransactionRefData, param: IPymtInitiateParamB | IPymtProcessParamB) => {
  if (isNil(param.custom)) {
    param.custom = {};
  }
  const c = param.custom;
  c.ref1 = ref?.invoiceId;
  c.ref2 = ref?.orderId;
  c.ref3 = ref?.cid;
  c.ref4 = ref?.parentTransactionId;
  c.ref5 = ref?.comment;
  cleanupUndefined(param);
};

export const getTransactionRefData = (param: IPymtInitiateParamB | IPymtProcessParamB): ITransactionRefData => {
  const ref: ITransactionRefData = {
    orderId: param?.custom?.ref1,
    invoiceId: param?.custom?.ref2,
    cid: param?.custom?.ref3,
    parentTransactionId: param?.custom?.ref4,
    comment: param?.custom?.ref5
  };
  cleanupUndefined(ref);
  if (Object.keys(ref).length === 0) {
    return undefined;
  }
  return ref;
};



/** Bambora Card processing parmas.
 * https://dev.na.bambora.com/docs/references/payment_SDKs/take_payments/?javascript#
 * Parameters required to initiate a payment (pay or pre-authorize) using token. 
*/
export interface IPymtInitiateParamB {
  order_number: string;
  amount: number;
  payment_method: 'card' | 'token' | 'payment_profile';
  custom?: {
    /** Invoice ID */
    ref1?: string;
    /** Order ID */
    ref2?: string;
    /** company id  */
    ref3?: string;

    ref4?: string;
    /** custom comment */
    ref5?: string;
  };
  token?: ITokenB;
  card?: ICardB;
  payment_profile?: IPaymentProfileB;
}

/** Server payment error received back from bambora. */
export interface IPaymentErrorResponseB {
  code: number;
  category: number;
  message: string;
  reference: string;
  details: { field: string; message: string; }[];
  // validation : CardValidation { }
}

export interface ICardValidationB {
  id: string;
  approved: number;
  message_id: number;
  message: string;
  auth_code: string;
  trans_date: string;
  order_number: string;
  type: string;
  amount: number;
  cvd_id: number;
}

export interface IProfileCreateResponseErrorB {
  code: number;
  category: number;
  message: string;
  reference: string;
  details: { field: string; message: string; }[];
  validation: ICardValidationB;
};

/** paramter required to complte the process of payment.
 * i.e  pre-auth -> preauthroized
 *      preauth -> complte preauth or void preauth *
 *      or paid -> return
 */
export interface IPymtProcessParamB {

  /** This is the new firestore ID of the doc that will store the return object of this transaction.  */
  order_number?: string;
  amount: number;
  vendorTransId: string;
  custom?: {
    /** Invoice ID */
    ref1?: string;
    /** Order ID */
    ref2?: string;
    /** company id  */
    ref3?: string;
    /** Predessor/Parent firestore transaction id for the transaction which getting returned */
    ref4?: string;
    /** custom comment */
    ref5?: string;
  }
}

