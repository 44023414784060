import { isEmpty, isNil } from 'lodash';
import { BaseModel } from '../base/base-model';
import { IsEnum, Length, IsDate, validateSync, IsDefined, IsNumber, IsBoolean, ValidateNested, ValidateIf } from 'class-validator';
import { Term } from '../rental/term';
import { plainToInstance, instanceToInstance, Exclude, Expose, Type, instanceToPlain } from 'class-transformer';
import {
  isAfter, addDays, differenceInHours, addHours, startOfDay, addMonths, addWeeks, compareAsc, isBefore,
  areIntervalsOverlapping
} from 'date-fns';
import { IValidationMsg } from '../error-handling/validation-info';
import { BidStatus } from './bid-status';
import { sanitizeDate, sanitizeDateIPoint } from '../utility/sanitize-helper';
import { BidHistory } from './bid-history';
import { Author } from './author';
import { NegoTermsResponse } from '../nego-terms-base/nego-terms-response';
import { CompanyFleet, CustomerCompanySummary, VendorCompanySummary } from '../company/company-fleet';
import { RentalProductBase } from '../rental/rental-product-base';
import { CompanyBase, IAuth, ISales, IUserRole } from '../company/company-base';
import { PaymentTransaction } from './payment-transaction';
import { UpdateDocRef, CronActions } from '../cron-jobs/cron-jobs-log';
import { UserProfile } from '../user/user-profile';
import { RoleCompanyAuthRank } from '../user/role-company';
// import { formatDate } from '@angular/common';
import { DbRule } from '../base/db-rule';
import { BindingBid } from './binding-bid';
import { RentOptionSummary } from '../rental/rent-option-summary';
import { ProductSummary } from '../product/product-helper';
import { ContractTruck } from '../contract/contract-truck';
import { CreditCardPayment, PaymentType } from '../payment/credit-card-payment';
import { UserEnum } from '../user/user-enum';
import { InvoiceRec } from '../finance/invoice/invoice-rec';
import { OrderPay } from '../finance/order/order-pay';
import { OrderRec } from '../finance/order/order-rec';
import { InvoicePay } from '../finance/invoice/invoice-pay';
import { InvoiceStatus } from '../finance/invoice/i-item';
import { Task } from '@trent/models/sys/task';
import { TaskType } from '../sys/task-type';
import { companyFriendlyName } from '../company/company-helper';
import { TaskUtl } from '../sys/task-utl';
import { Invoice } from '../finance/invoice/invoice';
import { NegoTermsBase } from '../nego-terms-base/nego-terms-base';
import { DbStatus } from '../base';
import { Address } from '../address/address';
import { logger } from '../log/logger';
import { NegoTermsAssigned } from '../nego-terms-base/nego-terms-assigned';
import { Vehicle } from '../product/vehicle';
import { cleanupUndefined, firestoreMap } from '../utility';
import { BidDocuments } from './bid-documents';
import { BidGuarantors } from './bid-guarantors';
import { TransactionManual } from '../finance-transaction/transaction-manual';
import { parseNegoTerms } from '../nego-terms-base/nego-terms-helper';

export interface BidSummary {
  startDate: Date;
  endDate: Date;
  bidId: string | number;
  revId: number;
}
export interface IContractNo {
  suffix: string, 
  number: number,
  rev:number
}


@Exclude()
export class BidBase extends BaseModel {




  constructor() {
    super();

    this.bidPrevious = new BidHistory;

  }
  public static readonly collectionName = 'bid';
  public static readonly isReleaseOnSubmission = true;
  public static readonly contractSuffix = 'TTR';
  public static readonly contractStartingNumber = 1000000;
  // readonly author = Author;
  // readonly bStatus = BidStatus;

  // @IsEnum(ProductType)
  // productType: ProductType;

  /** Vendor Invoice Id including revision
   * Set at the server when bid is saved. Required to show contract on UI
  */
  /**
   * Contract e.g. TTR-123456
   */
  @Expose()
  @ValidateIf(o => !!o.bidStatus && o.bidStatus >= BidStatus.accepted)
  @Length(5, 20, { message: 'Database Key needs to be $constraint1 chars' })
  contractNo: IContractNo;

  get contactNoString() { 
    if(!isEmpty(this.contractNo)){
      return `${this.contractNo.suffix}/${this.contractNo.number ? this.contractNo.number : '000'}/${this.contractNo.rev ? this.contractNo.rev : 0}`;
    }
  }
  get contactNoStringForInvoice() { 
    if(!isEmpty(this.contractNo)){
      return `${this.contractNo.suffix}/${this.contractNo.number}`;
    }
  }

  @Expose()
  vInvId: string;
  /** Customer Invoice Id including revision
   * Set at the server when bid is saved. Required to show contract on UI
  */
  @Expose()
  cInvId: string;

  @Expose()
  @Length(20, 20, { message: 'Database Key needs to be $constraint1 chars' })
  productId: string;

  @Expose()
  @Length(20, 20, { message: 'Database Key needs to be $constraint1 chars' })
  rentOptionId: number | string;

  @Expose()
  @IsEnum(BidStatus)
  bidStatus: BidStatus;

  @Expose()
  @IsDefined()
  rentSummary: RentOptionSummary;

  @Expose()
  @IsDefined()
  productSummary: ProductSummary;

  @Expose()
  @IsDefined()
  @Type(() => BidHistory)
  bidPrevious?: BidHistory;

  @Expose()
  @IsDefined()
  @Type(() => NegoTermsBase)
  bidNegoTerms: NegoTermsBase;

  @Expose()
  @IsNumber()
  nHistory: number;

  @Expose()
  @IsDefined()
  contract: ContractTruck;

  @Expose()
  @IsDefined()
  customerCompSummary: CustomerCompanySummary;

  @Expose()
  @IsDefined()
  vendorCompSummary: VendorCompanySummary;

  // @Expose()
  // @IsBoolean()
  // isCustomerAcceptTC: boolean;

  // @Expose()
  // @IsBoolean()
  // isVendorAcceptTC: boolean;

  @Expose()
  @ValidateNested({ message: 'Payment info is required' })
  @IsDefined({ message: 'Payment information is required' })
  @Type(() => PaymentTransaction)
  paymentSchedule: PaymentTransaction[];

  @Expose()
  @IsNumber()
  totalPayment: number;

  @Expose()
  @IsDate()
  expiry: Date;

  hRootId: string; // rootID for history search; // deprecated to removed, currently reference history service which is alos deprecated

  @Expose()
  isExpired: boolean;  // temp property of UI until bid status is not updated to expired by cron job

  @Expose()
  @ValidateNested({ message: 'Credit Card Payment info is required' })
  @IsDefined({ message: 'Credit Card Payment information is required' })
  @Type(() => CreditCardPayment)
  creditCardPayment: CreditCardPayment;

  @Expose()
  @ValidateNested({ message: 'Upload documents' })
  @Type(() => BidDocuments)
  bidDocuments: BidDocuments;

  //MKN - add guarantors for contract
  @Expose()
  bidGuarantors: BidGuarantors[];

  //MKN - Maintain replacement unit contract flag
  @Expose()
  @IsBoolean()
  isReplacementUnit = false;

  //MKN Sales Workflow - added to maintain bid submitted role name
  @Expose()
  submittedByRole :string;

  //MKN  Sales Workflow - added to maintain bid submitted sale person Id
  @Expose()
  submittedBy : string;

  //MKN  Maintain manual transaction information
  @Expose()
  manualPaymentInformation : TransactionManual;
  
  @Expose()
  customerAuth: IAuth;

  @Expose({ toPlainOnly: true })
  get customerAuthIds(): string[] {
    let r: string[] = [];
    for (const key in this.customerAuth) {
      let keys = Object.keys(this.customerAuth[key]);
      r.push(...keys);
    }
    
    return r;
  }

  @Expose()
  vendorAuth: IAuth;

  @Expose({ toPlainOnly: true })
  get vendorAuthIds(): string[] {
    let r: string[] = [];
    if(this.vendorAuth){
      for (const key in this.vendorAuth) {
        let keys = Object.keys(this.vendorAuth[key]);
        r.push(...keys);
      }
    }
    return r;
  }

  statusInfo: { stg: string; color: 'var(--primary)' | 'var(--accent)'; };

  get applicableRate() {
    let r: Number;
    switch (this.term) {
      case Term.daily:
        r = this.rentSummary.rateDaily;
        break;
      case Term.weekly:
        r = this.rentSummary.rateWeekly;
        break;
      case Term.monthly:
        r = this.rentSummary.rateWeekly;
        break;
      default:
        break;
    }
    return r;
  }
  get applicableRateString() {
    let rs: string;
    switch (this.term) {
      case Term.daily:
        rs = `$${this.rentSummary.rateDaily} ${this.rentSummary.currency}/day`;
        break;
      case Term.weekly:
        rs = `$${this.rentSummary.rateWeekly} ${this.rentSummary.currency}/week`;
        break;
      case Term.monthly:
        rs = `$${this.rentSummary.rateMonthly} ${this.rentSummary.currency}/month`;
        break;
      default:
        break;
    }
    return rs;
  }
  get term() {
    if (this.bidStatus <= BidStatus.accepted || this.bidStatus === BidStatus.expired) {
      return RentalProductBase.getApplicableRentalTerm(this.bidNegoTerms.startDate,
        this.bidNegoTerms.endDate, this.rentSummary)
        .applicableTerm;
    } else if (this.bidStatus === BidStatus.rejected) {
      return RentalProductBase.getApplicableRentalTerm(this.bidPrevious.bidSnapshot.startDate,
        this.bidPrevious.bidSnapshot.endDate, this.rentSummary)
        .applicableTerm;
    } else {
      return null;
    }
  }
  // get uiDateRange() {
  //   let sD: string;
  //   let eD: string;
  //   if (this.bidStatus <= BidStatus.accepted || this.bidStatus === BidStatus.expired) {
  //     sD = formatDate(this.bidNegoTerms?.startDate, 'mediumDate', 'en');
  //     eD = formatDate(this.bidNegoTerms?.endDate, 'mediumDate', 'en');
  //     return `${sD} To ${eD}`;
  //   } else if (this.bidStatus === BidStatus.rejected) {
  //     sD = formatDate(this.bidPrevious?.bidSnapshot?.startDate, 'mediumDate', 'en');
  //     eD = formatDate(this.bidPrevious?.bidSnapshot?.endDate, 'mediumDate', 'en');
  //     return `${sD} To ${eD}`;
  //   } else {
  //     return null;
  //   }
  // }
  get uiDeposit() {
    let ds: string;
    if (this.bidStatus <= BidStatus.accepted || this.bidStatus === BidStatus.expired) {
      ds = this.bidNegoTerms.deposit > 0 ? `$ ${this.rentSummary.currency} ${this.bidNegoTerms.deposit}` : null;
      return ds;
    } else if (this.bidStatus === BidStatus.rejected) {
      ds = this.bidPrevious.bidSnapshot.deposit > 0 ? `$ ${this.rentSummary.currency} ${this.bidPrevious.bidSnapshot.deposit}` : null;
      return ds;
    } else {
      return null;
    }
  }

  get expiresIn() {
    const d = differenceInHours(this.expiry, new Date());
    const s1 = Math.round(d / 24).toString() + (Math.round(d / 24) > 1 ? ' days ' : ' day ');
    const s2 = (d % 24) > 0 ? (d % 24).toString() + (d % 24 > 1 ? ' hours' : ' hour') : '';
    return s1 + s2;
  }
  public static parse(obj) {
    if (obj == null) { return null; }
    const m = plainToInstance<BidBase, any>(BidBase, sanitizeDateIPoint(obj));
    m.sanitize();
    m.bidNegoTerms = parseNegoTerms(obj.bidNegoTerms);
    return m;
  }
  /** Create bid on server when customer submits nego terms */
  public static createBid(data: {
    negoTerms: NegoTermsBase,
    rentOption: RentalProductBase, custComp: CompanyBase, vendComp: CompanyBase, bidStatus: BidStatus, invRecId: string, invPayId: string,
    cNumber?: IContractNo
  }) {
    const b = new BidBase();
    const custCompSum = (<CompanyFleet>data.custComp)?.getCustomerCompanySummary();
    const rSummary = data.rentOption.getRentOptionSummary();
    const pSummary = data.rentOption.productSummary;

    b.bidNegoTerms = data.negoTerms;

    b.customerAuth = (<CompanyFleet>data.custComp)?.auth;
    if(data.vendComp)
      b.vendorAuth = (<CompanyFleet>data.vendComp)?.auth;

    b.productSummary = pSummary;
    b.rentSummary = rSummary;
    b.bidStatus = data.bidStatus;
    b.vendorCompSummary = data.rentOption.vendorCompSummary;

    b.nHistory = -1;
    b.customerCompSummary = custCompSum;

    b.expiry = b.getExpiryD(new Date());
    b.isExpired = false;
    // rev 0 for daft bids where customer company is not registered
    const invRev = data.custComp?.id ? 1 : 0;
    b.cInvId = !!data.invRecId ? `${data.invRecId}/${Invoice.collectionName}-rev/${invRev}` : null;
    b.vInvId = !!data.invPayId ? `${data.invPayId}/${Invoice.collectionName}-rev/${invRev}` : null;
    b.contractNo = !!data.cNumber ?  data.cNumber : {suffix: BidBase.contractSuffix, number: BidBase.contractStartingNumber, rev : 0};
    return b;
  }
  public static createAssignedContract(data: { negoTermsAssigned: NegoTermsAssigned; prodSummary: ProductSummary, vCompanySummary: VendorCompanySummary, cCompanySummary: CustomerCompanySummary; invRecId: string; invPayId: string; cNumber: IContractNo; }): BidBase {
    const b = new BidBase();
    

    b.bidNegoTerms = data.negoTermsAssigned;
    // b.bidNegoTerms.isSignedDocUploaded = undefined;
    // b.bidNegoTerms.isInsCertUploaded = undefined;
    b.bidNegoTerms.signedDoc = undefined;
    b.bidNegoTerms.insCert = undefined; 

    b.productSummary = data.prodSummary;
    b.rentSummary = data.negoTermsAssigned.rentOptionSummary as RentOptionSummary;
    b.bidStatus = BidStatus.accepted;
    b.vendorCompSummary = data.vCompanySummary;
    b.customerCompSummary = data.cCompanySummary;
    b.nHistory = -1;
    b.isExpired = false;
    b.expiry = undefined;
    b.cInvId = !!data.invRecId ? `${data.invRecId}/${Invoice.collectionName}-rev/1` : null;
    b.vInvId = !!data.invPayId ? `${data.invPayId}/${Invoice.collectionName}-rev/1` : null;
    b.contractNo = !!data.cNumber ?  data.cNumber : {suffix: BidBase.contractSuffix, number: BidBase.contractStartingNumber, rev : 0};
    


    return b;
  }



  /**cNumber
   * get next contract based on @param latestContract latest contract 
   * @param latestContract BidBase
   * @returns 
   */
  public static getNextContractNumber(latestContract?: BidBase): IContractNo {

    let r: IContractNo = {} as IContractNo;
    r.suffix = BidBase.contractSuffix;
    if (!!latestContract) {
      r.number = latestContract.contractNo.number + 1;
      r.rev = 0; //MKN - added default rev version
    } else {
      r.number = BidBase.contractStartingNumber + 1;
      r.rev = 0; //MKN - added default rev version
    }
    return r;
  }

  /** returns next notification date time based in current noticiation, expiry date and notification window
   * @param currentN: current notification in db
   * @param notification every 6 hours if it falls in notification window, else postponed to 7 am next day.
   * Irrespective of notification window there is notification 1 hour before expiry.
   * If next notification is within 1 hour of expiry notification it is omitted.
   */
  public static getNextNotifyAt(currentN: Date, lastN: Date) {
    let nextN = !!this.notificationWindow(addHours(currentN, 6)) ? addHours(currentN, 6) : addHours(startOfDay(addDays(currentN, 1)), 7);
    nextN = isAfter(addHours(nextN, 1), lastN) ? lastN : nextN;
    return nextN;
  }
  /** notification window 7AM to 9PM
   * @param t: datetime of notification to be eveluated if it falls in the notification window
   */
  private static notificationWindow(t: Date): boolean {
    if (t.getHours() < 7 || t.getHours() > 20) {
      return false;
    } else {
      return true;
    }
  }
  
  public updateBid(bidStatus: BidStatus, negoTerms: NegoTermsBase): void {
    // const b = this.clone();
    if (this.nHistory < 0) {
      this.bidPrevious = new BidHistory();
      this.bidPrevious.bidSnapshot = this.bidNegoTerms;
      this.bidPrevious.updatedAt = new Date();
      this.bidPrevious.updatedBy = negoTerms.author;
    } else {
      this.bidPrevious.bidSnapshot = this.bidNegoTerms;
      this.bidPrevious.updatedAt = new Date();
      this.bidPrevious.updatedBy = negoTerms.author;
    }
    this.bidNegoTerms = negoTerms;
    this.nHistory = this.nHistory + 1;
    this.bidStatus = bidStatus;
    if (bidStatus === BidStatus.waitingForCustomer || bidStatus === BidStatus.waitingForVendor) {
      this.expiry = this.getExpiryD(new Date());
    } else {
      this.expiry = undefined;
    }
  }

  /**
   * @author - MKN
   * @purpose - increment Contract rev number
   */
  public incrementContractRevNo(): void {
    if(this.contractNo && this.contractNo.number){
      this.contractNo.rev = !this.contractNo.rev ? 1 : this.contractNo.rev + 1;
    }
  }
  clearDocuments() {
    this.bidDocuments.signedDoc = undefined;
    this.bidDocuments.insCert = undefined;
    this.bidDocuments.insCertStartDate = undefined;
    this.bidDocuments.insCertEndDate = undefined;
  }

  /**
   * @author - MKN
   * @purpose - update bid nego terms keeping old version
   */
  
  public updateBidWithDocuments( bidDocuments: BidDocuments): void {
    this.bidDocuments = bidDocuments;
    // if (this.nHistory < 0) {
    //   this.bidPrevious = new BidHistory();
    //   this.bidPrevious.bidSnapshot = this.bidNegoTerms;
    //   this.bidPrevious.updatedAt = new Date();
    //   this.bidPrevious.updatedBy = negoTerms.author;
    // } else {
    //   this.bidPrevious.bidSnapshot = this.bidNegoTerms;
    //   this.bidPrevious.updatedAt = new Date();
    //   this.bidPrevious.updatedBy = negoTerms.author;
    // }
    // this.bidNegoTerms = negoTerms;
    // this.nHistory = this.nHistory + 1;
  }

  /**
   * @author - MKN
   * @purpose - update Single bid guarantor
   * @param uBidGuarantor 
   */
  
  public updateBidGuarantor(uBidGuarantor: BidGuarantors): void {
    if(!this.bidGuarantors){
      this.bidGuarantors = [];
    }

    let index = this.bidGuarantors.findIndex(f => f.fullName === uBidGuarantor.fullName);
    if(index > -1){
      this.bidGuarantors[index] = uBidGuarantor;
    }else{
      this.bidGuarantors.push(uBidGuarantor);
    }
  }

  /**
   * @author - MKN
   * @purpose - remove Single bid guarantor
   * @param index  
   */
  
  public removeBidGuarantor(index: number): void {
    this.bidGuarantors.splice(index,1);
  }

  addCustComp(custComp: CompanyBase) {
    const custCompSum = (<CompanyFleet>custComp)?.getCustomerCompanySummary();
    this.customerCompSummary = custCompSum;
  }
  sanitize() {
    super.sanitize();
    // if data was recieved from firebase, date is stored as snapshot.
    this.rentSummary.availStartDate = sanitizeDate(this.rentSummary.availStartDate);
    this.rentSummary.availEndDate = sanitizeDate(this.rentSummary.availEndDate);
    this.expiry = sanitizeDate(this.expiry);
    if (this.bidNegoTerms != null) {
      this.bidNegoTerms.createdAt = sanitizeDate(this.bidNegoTerms.createdAt);
      this.bidNegoTerms.updatedAt = sanitizeDate(this.bidNegoTerms.updatedAt);
      this.bidNegoTerms.startDate = sanitizeDate(this.bidNegoTerms.startDate);
      this.bidNegoTerms.endDate = sanitizeDate(this.bidNegoTerms.endDate);
    }
    if (this.bidPrevious != null) {
      this.bidPrevious.bidSnapshot.createdAt = sanitizeDate(this.bidPrevious.bidSnapshot.createdAt);
      this.bidPrevious.bidSnapshot.updatedAt = sanitizeDate(this.bidPrevious.bidSnapshot.updatedAt);
      this.bidPrevious.bidSnapshot.startDate = sanitizeDate(this.bidPrevious.bidSnapshot.startDate);
      this.bidPrevious.bidSnapshot.endDate = sanitizeDate(this.bidPrevious.bidSnapshot.endDate);
    }

    // sanitize the address as address  are inside an interface.
    try {
      if (!isNil(this.vendorCompSummary?.address)) {
        this.vendorCompSummary.address = Address.parse(this.vendorCompSummary.address);
      }
      if (!isNil(this.customerCompSummary?.address)) {
        this.customerCompSummary.address = Address.parse(this.customerCompSummary.address);
      }
    } catch (error) {
      logger.error('Error sanitizing the address object of the customer/vendor company inside the bid', error);
    }

    //MKN - for existing contract set this flag as false
    if(this.isReplacementUnit == undefined){
      this.isReplacementUnit = false;
    }
  }

  clone() {
    const t = instanceToInstance(this);
    t.sanitize();
    return t;
  }

  validateSync(): IValidationMsg {
    const r = this.validateSyncBase(this);
    return r;
  }
  isUserVendor(u: UserProfile) {
    return u.getCompanyAuthLevel(`${this.vendorCompSummary.cid}`) !== RoleCompanyAuthRank.unAuthorized;
  }

  isUserCustomer(u: UserProfile) {
    return u.getCompanyAuthLevel(`${this.customerCompSummary.cid}`) !== RoleCompanyAuthRank.unAuthorized;
  }

  compareBid(clientNegoTerms: NegoTermsBase, bid: BidBase, author: Author) {
    // no error checking as client data and db Data checked in api before the calling function
    const bStatus = BidStatus;
    clientNegoTerms.deposit = !clientNegoTerms.deposit ? 0 : clientNegoTerms.deposit;
    bid.bidNegoTerms.deposit = !bid.bidNegoTerms.deposit ? 0 : bid.bidNegoTerms.deposit;


    if ((clientNegoTerms.rate === bid.bidNegoTerms.rate)
      && (clientNegoTerms.startDate.valueOf() === bid.bidNegoTerms.startDate.valueOf())
      && (clientNegoTerms.endDate.valueOf() === bid.bidNegoTerms.endDate.valueOf())
      && (clientNegoTerms.deposit === bid.bidNegoTerms.deposit)
      && ((author === Author.customer && clientNegoTerms.bidResponse === NegoTermsResponse.acceptedByCustomer) ||
        (author === Author.vendor && clientNegoTerms.bidResponse === NegoTermsResponse.acceptedByVendor))
    ) {

      return this.bidStatus = bStatus.accepted;
    } else if ((!clientNegoTerms.rate)
      && (!clientNegoTerms.startDate)
      && (!clientNegoTerms.endDate)
      && (!clientNegoTerms.deposit)
      && ((author === Author.customer && clientNegoTerms.bidResponse === NegoTermsResponse.rejectedByCustomer) ||
        (author === Author.vendor && clientNegoTerms.bidResponse === NegoTermsResponse.rejectedByVendor))
    ) {
      return this.bidStatus = bStatus.rejected;
    } else if (
      ((clientNegoTerms.rate !== bid.bidNegoTerms.rate)
        || (clientNegoTerms.startDate.valueOf() !== bid.bidNegoTerms.startDate.valueOf())
        || (clientNegoTerms.endDate.valueOf() !== bid.bidNegoTerms.endDate.valueOf())
        || (clientNegoTerms.deposit !== bid.bidNegoTerms.deposit))
      && ((author === Author.customer && clientNegoTerms.bidResponse === NegoTermsResponse.counteredByCustomer) ||
        (author === Author.vendor && clientNegoTerms.bidResponse === NegoTermsResponse.counteredByVendor)
      )
    ) {

      return this.setBidStatus(author);
    } else {
      return null;
    }
  }
  setBidStatus(author: Author) {
    const a = Author;
    const bStatus = BidStatus;
    if (author === a.vendor) {
      this.bidStatus = bStatus.waitingForCustomer;
      return this.bidStatus;
    } else if (author === a.customer) {
      this.bidStatus = bStatus.waitingForVendor;
      return this.bidStatus;
    } else {
      return null;
    }
  }
  /** Get client response to set the bid status
   */
  compareBidNegoTerms(clientNegoTerms: NegoTermsBase): BidStatus {
    let s: BidStatus;
    if (!clientNegoTerms.startDate || !clientNegoTerms.endDate) {
      s = BidStatus.rejected;
    } else {
      const dDiff = this.bidNegoTerms.deposit - clientNegoTerms.deposit;
      if (!!this.macthDateRange(clientNegoTerms) && dDiff === 0) {
        s = BidStatus.accepted;
      } else {
        s = clientNegoTerms.author === Author.vendor ? BidStatus.waitingForCustomer
          : clientNegoTerms.author === Author.customer ? BidStatus.waitingForVendor : null;
      }
    }
    return s;
  }
  macthDateRange(clientNegoTerms: NegoTermsBase): boolean {
    // https://date-fns.org/v2.0.0/docs/compareAsc
    const sDiff = compareAsc(clientNegoTerms.startDate, this.bidNegoTerms.startDate);
    const eDiff = compareAsc(clientNegoTerms.endDate, this.bidNegoTerms.endDate);
    if (sDiff === 0 && eDiff === 0) {
      return true;
    } else {
      return false;
    }
  }
  get rSummary() {
    return `From: ${(this.rentSummary.availStartDate).toLocaleDateString()}
      To: ${(this.rentSummary.availEndDate).toLocaleDateString()}` +
      `${(this.rentSummary.isOneWay) ? `From ${(this.rentSummary.startAddress)} to ${(this.rentSummary.endAddress)}` :
        `Pickup & Drop-Off: ${(this.rentSummary.startAddress)}`}`;
  }
  getExpiryD(createdD: Date) {
    let e = addDays(createdD, 3);
    if (isAfter(e, this.bidNegoTerms.startDate)) {
      e = addDays(this.bidNegoTerms.startDate, 0);
    }
    // potential update use https://www.npmjs.com/package/date-holidays to check if e is a holiday
    return e;
  }
  private createUpdateCreditCardPayment(bid?: BidBase): CreditCardPayment {
    let c = new CreditCardPayment();
    switch (this.bidStatus) {
      case BidStatus.rejected:
      case BidStatus.waitingForCustomer:
      case BidStatus.expired:
      case BidStatus.terminated:
        c.refId = 'rrr'; // will be updated to payment provider response
        c.releaseDate = new Date(); // will be updated to payment provider response
        break;
      case BidStatus.accepted:
        c = bid.creditCardPayment;
        c.isCapture = true;
        c.captureDate = new Date(); // will be updated to payment provider response
        c.validUntil = null;
        break;
      case BidStatus.waitingForVendor:
        c.isCapture = false;
        c.authorizationDate = new Date();
        c.refId = 'xxx'; // will be updated to payment provider response
        c.paymentType = PaymentType.firstPayment;
        c.paymentValue = this.paymentSchedule[0].payment + this.bidNegoTerms.deposit;
        c.currency = this.rentSummary.currency;
        c.validUntil = this.expiry;
        break;
      default:
        break;
    }
    return c;
  }
  getBidSummary(): BidSummary {
    return {
      startDate: this.bidNegoTerms.startDate,
      endDate: this.bidNegoTerms.endDate,
      bidId: this.id,
      revId: this.revId
    };
  }
  setBidStatusOnExpiry(): { bid: BidBase, chgDoc: UpdateDocRef } {
    const updatedBid = this.clone();
    updatedBid.isExpired = true;
    updatedBid.bidStatus = BidStatus.expired;
    const c = this.getCronJobLog(updatedBid);

    return { bid: updatedBid, chgDoc: c };
  }
  getCronJobLog(updatedBid: BidBase): UpdateDocRef {

    let chgDoc: UpdateDocRef;
    const chgMap1 = new Map();
    const chgMap2 = new Map();

    chgMap1.set(`bidStatus`, {
      before: this.bidStatus,
      after: updatedBid.bidStatus
    });
    chgMap2.set(`isExpired`, {
      before: this.isExpired,
      after: updatedBid.isExpired
    });
    chgDoc = {
      did: this.id,
      collection: BidBase.collectionName,
      revCreated: this.revId + 1,
      action: CronActions.setBidExpiredStatus,
      change: [chgMap1, chgMap2]
    };
    return chgDoc;
  }
  // getUrlSegment(author: Author) {
  //   let urlSeg: string;
  //   switch (author) {
  //     case Author.customer:
  //       urlSeg = `${webSiteUrl}/bid-counter/vendorCounter/`;
  //       break;
  //     case Author.vendor:
  //       urlSeg = `${webSiteUrl}/bid-counter-customer/customerCounter/`;
  //       break;
  //     default:
  //       break;
  //   }
  //   return urlSeg;
  // }
  applyRateTerm(nego: NegoTermsBase) {
    let r: number;
    let term: Term;
    if (isAfter(nego.endDate, addMonths(nego.startDate, 1)) && !!this.rentSummary.rateMonthly) {
      r = this.rentSummary.rateMonthly;
      term = Term.monthly;
    } else if (isAfter(nego.endDate, addWeeks(nego.startDate, 1)) && !!this.rentSummary.rateWeekly) {
      r = this.rentSummary.rateWeekly;
      term = Term.weekly;
    } else if (!!this.rentSummary.rateDaily) {
      r = this.rentSummary.rateDaily;
      term = Term.daily;
    }
    return { rent: r, term: term };
  }
  /** returns discount string if applicable. Compare total payment to weekly or monthly term is applicable
   * function is called from bid detail which does not have bid
   * @param tPayment: number
   * @param rental: RentalProductBase
   * @param negoTerm: NegoTermsBase
   */
  getDiscountString(tPayment: number, rental: RentalProductBase, negoTerm: NegoTermsBase): string {
    let s: string;
    let d: number;
    const m = isBefore(negoTerm.endDate, addMonths(negoTerm.startDate, 1));
    const w = isBefore(negoTerm.endDate, addWeeks(negoTerm.startDate, 1));
    if (!!this.id && !!DbRule.isFrozenReleaseBranch(this.id)) { s = null; } else {

      if (!!rental.rateMonthly && tPayment > rental.rateMonthly && !!m && !!rental.isMonthlyAvail) {
        d = tPayment - rental.rateMonthly;
        d = d > .99 ? Math.round(d * 100) / 100 : null;
        if (!!d) {
          s = `Save $${d} ${rental.currency} by renting for a month`;
        }
      } else if (!!rental.rateWeekly && tPayment > rental.rateWeekly && !!w && !!rental.isWeeklyAvail) {
        d = tPayment - rental.rateWeekly;
        d = d > .99 ? Math.round(d * 100) / 100 : null;
        if (!!d) {
          s = `Save $${d} ${rental.currency} by renting for a week`;
        }
      } else {
        s = null;
      }
    }
    return s;

  }
  getBindingBid(negoTerms: NegoTermsBase): BindingBid {
    const bb = new BindingBid();
    bb.bindingBidId = this.id;
    bb.bindingStartD = negoTerms.startDate;
    bb.bindingEndD = negoTerms.endDate;
    bb.bidStatus = this.bidStatus;
    bb.revId = this.revId;
    return bb;
  }
  getStatusInfo(u: UserEnum, comp?: CompanyBase) {
    let stg: string;
    let color: 'var(--primary)' | 'var(--accent)';
    if (this.dbStatus !== DbStatus.Released) {
      if (!comp && comp?.dbStatus !== DbStatus.Released) {
        stg = `Wating for you to add company details`;
        color = 'var(--accent)';
      }
    } else {
      switch (this.bidStatus) {
        case BidStatus.waitingForVendor:
          if (u === UserEnum.vendor) {
            stg = `Bid By: ${this.customerCompSummary.legalName} waiting for your approval`;
            color = 'var(--accent)';
          } else {
            stg = `Waiting for vendor's approval `;
            color = 'var(--accent)';
          }

          break;
        case BidStatus.accepted:
          if (u === UserEnum.vendor) {
            stg = `Contract with ${this.customerCompSummary.legalName}`;
            color = 'var(--primary)';
          } else {
            stg = `Contract with ${this.vendorCompSummary.legalName}`;
            color = 'var(--primary)';
          }
          break;
        case BidStatus.rejected:
          switch (this.bidNegoTerms.author) {
            case Author.vendor:
              stg = `Rejected by ${this.vendorCompSummary.legalName}`;
              color = 'var(--primary)';
              break;
            case Author.customer:
              stg = `Rejected by ${this.customerCompSummary.legalName}`;
              color = 'var(--primary)';
              break;
            default:
              break;
          }
          break;
        case BidStatus.expired:
          switch (this.bidNegoTerms.author) {
            case Author.vendor:
              stg = `Expired, ${this.customerCompSummary.legalName} did not respond`;
              break;
            case Author.customer:
              stg = `Expired, ${this.vendorCompSummary.legalName} did not respond`;
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }
    }
    this.statusInfo = { stg, color };
  }

  updateInvoicesOnVendorUpdate(oInvoiceRec: InvoiceRec, oInvociePay: InvoicePay): {
    uInvoiceRec: InvoiceRec; uInvoicePay: InvoicePay; uOrderPay: OrderPay;
  } {

    const uInvoiceRec: InvoiceRec = oInvoiceRec.clone();
    const uInvoicePay: InvoicePay = oInvociePay.clone();
    let uOrderPay: OrderPay;
    switch (this.bidStatus) {
      case BidStatus.accepted:
        // uInvoiceRec.invoiceStatus = InvoiceStatus.paid; // replace with invoice/order class function which updates
        break;
      case BidStatus.rejected:
        uInvoiceRec.invoiceStatus = InvoiceStatus.cancelled;
        uInvoicePay.invoiceStatus = InvoiceStatus.cancelled;
        uOrderPay = null;
        break;


      default:
        throw new Error(`Invalid bid status, ${this.bidStatus} is programmed yet`);
    }

    return { uInvoiceRec, uInvoicePay, uOrderPay };
  }
  getCidFromBidResponse(): string {
    switch (this.bidNegoTerms.bidResponse) {
      case NegoTermsResponse.acceptedByCustomer:
      case NegoTermsResponse.counteredByCustomer:
      case NegoTermsResponse.rejectedByCustomer:
        return this.vendorCompSummary.cid;
      case NegoTermsResponse.acceptedByVendor:
      case NegoTermsResponse.counteredByVendor:
      case NegoTermsResponse.rejectedByVendor:
        return this.customerCompSummary.cid;

      default:
        throw new Error(`Invalid bid Response, ${this.bidNegoTerms.bidResponse} is programmed yet`);
    }
  }
  /**
   * Set task properties base on bid response,
   * @param task Task created when bid is created or updated
   * @param websiteUrl website url (e.g. www.locusloop.com)
   */
  setTaskInputs(task: Task, websiteUrl: string, userType: 'companyUser' | 'salesUser' = 'companyUser') {
    switch (this.bidNegoTerms.bidResponse) {
      case NegoTermsResponse.acceptedByCustomer:
        task.senderCompanyName = companyFriendlyName(this.customerCompSummary?.name, this.customerCompSummary?.legalName);
        task.name = `${task.senderCompanyName} has submitted bid`;
        switch (userType) {
          case 'companyUser':
            task.taskType = TaskType.RequestBidApprovalVendor;            
            task.data = { action: `${TaskType.RequestBidApprovalVendor}`, action_key: `${this.id}` };
            break;
            case 'salesUser':
              task.taskType = TaskType.RequestBidApprovalVendorForSales;            
              task.data = { action: `${TaskType.RequestBidApprovalVendorForSales}`, action_key: `${this.id}` };
            break;
          default:
            logger.error(`Invalid user type, ${userType}`);
            break;
        }
        break;
      case NegoTermsResponse.acceptedByVendor:
        task.senderCompanyName = companyFriendlyName(this.vendorCompSummary?.name, this.vendorCompSummary?.legalName);
        task.name = `${task.senderCompanyName} has accepted bid `;
        switch (userType) {
          case 'companyUser':
            task.taskType = TaskType.BidApprovedByVendor;
            task.data = { action: `${TaskType.BidApprovedByVendor}`, action_key: `${this.id}` };
            break;
            case 'salesUser':
              task.taskType = TaskType.BidApprovedByVendorForSales;
              task.data = { action: `${TaskType.BidApprovedByVendorForSales}`, action_key: `${this.id}` };
            break;
          default:
            logger.error(`Invalid user type, ${userType}`);
            break;
        }
        break;
      case NegoTermsResponse.rejectedByVendor:
        task.senderCompanyName = companyFriendlyName(this.vendorCompSummary?.name, this.vendorCompSummary?.legalName);
        task.name = `${task.senderCompanyName} has rejected bid`;
        switch (userType) {
          case 'companyUser':
            task.taskType = TaskType.BidRejectedByVendor;
            task.data = { action: `${TaskType.BidRejectedByVendor}`, action_key: `${this.id}` };
            break;
            case 'salesUser':
              task.taskType = TaskType.BidRejectedByVendorForSales;
              task.data = { action: `${TaskType.BidRejectedByVendorForSales}`, action_key: `${this.id}` };
            break;
          default:
            logger.error(`Invalid user type, ${userType}`);
            break;
        }
        break;

      default:
        throw new Error(`Invalid bid Response, ${this.bidNegoTerms.bidResponse} is programmed yet`);
    }
    const urlSegment = TaskUtl.getUrlFromTaskType(task.taskType, task.data.action_key).url;

    task.notification = {
      title: `${task.name}`,
      body: 'Click to get bid details',
      icon: 'https://placeimg.com/250/250/people',
      clickAction: `${websiteUrl}${urlSegment}`
    };
  }
  /**
   * Set task properties based on draft bid submitted by new company or company not released for rental,
   * @param task Task created when bid is created or updated
   * @param websiteUrl website url (e.g. www.locusloop.com)
   */
  setAdminTaskInputsForDraftBid(task: Task, websiteUrl: string) {
    task.senderCompanyName = companyFriendlyName(this.customerCompSummary?.name, this.customerCompSummary?.legalName);
    // tslint:disable-next-line:max-line-length
    // task.name = task.senderCompanyName ? `${task.senderCompanyName} has sumitted draft bid` : `User ${task.createdByUid} has sumitted draft bid`;
    task.data = { action: `${TaskType.DraftBidCreated}`, action_key: `${this.id}` };
    task.taskType = TaskType.DraftBidCreated;

    const urlSegment = TaskUtl.getUrlFromTaskType(task.taskType, task.data.action_key).url;

    task.notification = {
      title: `${task.name}`,
      body: 'Click to get bid details',
      icon: 'https://placeimg.com/250/250/people',
      clickAction: `${websiteUrl}${urlSegment}`
    };
  }
  /** Return filtered bids which have overlapping date range to current bid
 * Used to expire bids which have same overlapping date when certain bid is accepted
 * @param bids Open bids on rent option
 * @param currentBid Current bid being accepted
 */
  overlappingBids(bids: BidBase[]): BidBase[] {
    const overlappingBid = bids.filter(e => {
      if (e.id === this.id) {
        return false;
      } else {
        // tslint:disable-next-line:max-line-length
        if (areIntervalsOverlapping({ start: e.bidNegoTerms.startDate, end: e.bidNegoTerms.endDate }, { start: this.bidNegoTerms.startDate, end: this.bidNegoTerms.endDate })) {
          return true;
        }
        // tslint:disable-next-line:max-line-length
        // if (areRangesOverlapping(e.bidNegoTerms.startDate, e.bidNegoTerms.endDate, this.bidNegoTerms.startDate, this.bidNegoTerms.endDate)) {
        //   return true;
        // }

        return false;
      }
    });
    return overlappingBid;
  }
  /** reject bid when other bid with overlapping date is accepted */
  rejectOvelappingBid() {
    const d = new NegoTermsBase();
    d.bidResponse = NegoTermsResponse.rejectedByVendor;
    d.author = Author.vendor;
    const bidStatus: BidStatus = d.getBidStatus();
    // update bid
    this.updateBid(bidStatus, d);
  }
  /** Custom fn to fix the assigned Nego Terms  */
  public toFirebaseObj(timeStampCreate?: boolean,
    getGeoPointFn?: (obj: any) => any, ignore?: string[]) {

    // user class to plain
    let n = instanceToPlain(this.bidNegoTerms);
    let obj = instanceToPlain(this);
    obj.bidNegoTerms = n;
    obj = cleanupUndefined(obj);

    // const a = (!!ignore && ignore.length > 0) ?
    //   Array.prototype.push.apply(BaseModel.getBaseIgnoreList(), ignore) :
    //   BaseModel.getBaseIgnoreList();

    const a = (!!ignore && ignore.length > 0) ? Array.prototype.push.apply(['id'], ignore) : ['id'];
    const o = firestoreMap(obj, a); // , getGeoPointFn);
    if (timeStampCreate != null) {
      // block for timestamp error at gps deployed functions
      // o.updatedAt = firebase.firestore.FieldValue.serverTimestamp();
      logger.log('updatedAt blocked at base model');
      logger.log('timeStampCreate', timeStampCreate);
      if (timeStampCreate) {
        logger.log('createdAt blocked at base model');
        // o.createdAt = firebase.firestore.FieldValue.serverTimestamp();
      }
    }
    return o;
  }
  /**
   * @author Cm
   * @purpose Add Sales User
   * @param user UserProfile
   */

  addUserAsSales(user:{id: string | number, displayName:string, isPrimary?:boolean, percentage?:number}) {
    // taking id of the auth user
    if (!this.customerAuth) {
      this.customerAuth = { sales: {} };
    }
    if (!this.customerAuth?.sales) {
      this.customerAuth.sales = {};
    }
    let sales: { [key: string]: ISales } = {
      [user.id]: {
        displayName: user.displayName,
        isPrimary: user?.isPrimary ? true : false,
        percentage: user?.percentage ? user.percentage : 0
      }
    };
    // Here adding sales person id 
    this.customerAuth.sales = { ...this.customerAuth.sales, ...sales };
  }
  /**
   * @author Cm
   * @purpose Add credit User
   * @param user UserProfile
   */

  addUserAsCredit(user: UserProfile) {
    // taking id of the credit user
    if (!this.vendorAuth) {
      this.vendorAuth = { credit: {} };
    }
    if (!this.vendorAuth?.credit) {
      this.vendorAuth.credit = {};
    }
    let credit: { [key: string]: IUserRole } = {
      [user.id]: {
        displayName: user.displayName,
      }
    };
    // Here adding credit person id 
    this.vendorAuth.credit = { ...this.vendorAuth.credit, ...credit };
  }


}
