import { TripTransaction } from './trip-transaction';
import { Exclude, plainToInstance, instanceToInstance, Expose, Type } from 'class-transformer';
import { sanitizeDateIPoint } from '../utility';
import { ValidatorOptions, ValidateNested, IsDefined } from 'class-validator';
import { IValidationMsg } from '../error-handling';
import { TripBase } from './trip-base';
import { VehicleList } from '../promo/promo-vehicle';
import { IPointGeo } from '../product-search/interfaces';
import { Address } from '../address/address';
import { BorderListItem } from '../promo/border-list';
import { logger } from '../log/logger';

@Exclude()
export class WayPoint extends TripTransaction {

  @Expose()
  onlyGeoLoc: boolean;
  @Expose()
  isBorderCrossing: boolean;

  @Expose()
  @ValidateNested({ message: 'Address info is required', groups: [Address.geoLoc] })
  @IsDefined({ message: 'Address information is required' })
  @Type(() => Address)
  address: Address;

  @Expose()
  borderWayPoint: { [key: string]: BorderListItem };

  public static parse(obj) {
    try {
      if (obj instanceof WayPoint) {
        // custom parse for trip transaction
        if (!obj.isApptApp) {
          obj.apptDateTime = undefined;
          obj.apptWindow = undefined;
        }
        return obj;
      }
      if (obj == null) { return null; }
      // obj = sanitizeDateIPoint(obj, ipointType);
      obj = sanitizeDateIPoint(obj);
      const m = plainToInstance<WayPoint, any>(WayPoint, obj);
      m.sanitize();
      return m;
    } catch (error) {
      logger.log('Error happened during parse', error);
      return null;
    }
  }
  clone() {
    const t = instanceToInstance(this);
    t.sanitize();
    return t;
  }

  validateSync(options?: ValidatorOptions, trip?: TripBase, index?: number, truckL?: VehicleList, trailerL?: VehicleList): IValidationMsg {
    options = !!options ? options : { groups: [Address.geoLoc] };
    const r = this.validateSyncBase(this, options);

    return r;
  }
}
