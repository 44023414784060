import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProductDetails } from '@trent/models/pts/pts-enquiry';

@Component({
  selector: 'trent-pts-enquiry-dialog',
  templateUrl: './pts-enquiry-dialog.component.html',
  styleUrls: ['./pts-enquiry-dialog.component.scss']
})
export class PtsEnquiryDialogComponent{

  type:number;
  productDetails:ProductDetails;
  
  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    public dialogRef: MatDialogRef<PtsEnquiryDialogComponent>,
  ) { 
    if(data){
      this.type = data.enquiryType;
      this.productDetails = data.productDetails ? data?.productDetails : null;
    }
  }

  close() {
    this.dialogRef.close();
  }
}
