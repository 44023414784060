// tslint:disable-next-line:max-line-length
import { Task } from '@trent/models/sys/task';
import { ITaskParam, taskSearchClientFilter } from '@trent/models/sys/task-param';
import { State, Selector, Action, StateContext } from '@ngxs/store';
import { StateBase } from '../state-base';
import { Subscription, noop } from 'rxjs';
import * as entity from '@trent/store/entity.state';
import { map } from 'rxjs/operators';
import { getITasksOptionOrChildren } from '../../models/sys/task-param';
import { ITaskService } from '@trent/services/task/i-task.service';
import { Injectable } from '@angular/core';
import { Paging, getObjKey } from '@trent/models/observable-util/paging';
// tslint:disable-next-line:max-line-length
import { IDataLoadStatus, buildDataRequest, LoadStatus, getRootLevelChildren, updatePaging } from '@trent/models/observable-util/data-status';
import { PagingContainer } from '@trent/models/observable-util/paging-container';
import { logger } from '@trentm/log/logger';

// #region State Model
export interface TaskStateModel {

  paging: Paging;

  /** Which client side queries are complete */
  dataLoadStatus: {
    [key: string]: IDataLoadStatus<ITaskParam>;
  };

  /** Order Container. */
  data: { [id: string]: Task }; // entity.EntityState<ProductBase>;
}

function initTaskState(): TaskStateModel {
  return {
    paging: { size: 10, offset: 0, full: false },
    dataLoadStatus: {},
    data: {}
  };
}

// #endregion

// #region actions
export class TaskByIdRequested {
  static readonly type = '[Task] Request a single task entity';
  constructor(public payload: { id: string | number }) { }
}
export class TaskByIdLoaded {
  static readonly type = '[Task] Load a single task entity';
  constructor(public payload: { data: Task, id: string | number }) { }
}

export class TaskStateReset {
  static readonly type = '[Task] Reset State';
}

export class TasksRequested {
  static readonly type = '[Task] All tasks Requested';
  constructor(public payload: { pData: Paging, param: ITaskParam }) { }
}

export class TasksLoaded {
  static readonly type = '[Task] All tasks Loaded';
  constructor(public payload: {
    data: { [id: string]: Task }, // Data
    key: string,
  }) { }
}

export class TasksNextPageRequested {
  static readonly type = '[Task] All tasks requested - Next Page';
  constructor(public payload: { param: ITaskParam }) { }
}
// #endregion
@State<TaskStateModel>({
  name: 'Task',
  defaults: initTaskState()
})
@Injectable()
export class TaskState extends StateBase {

  dataReqSub: Subscription;

  /** Container that keep all of the subscription related to gettting data. */
  dataSubData: PagingContainer<Task, ITaskParam> = new PagingContainer();


  constructor(private newTs: ITaskService) {
    super();
  }
  // #region Selectors

  @Selector()
  static selectDataById(state: TaskStateModel) {
    return entity.getByIdFn_new(state.data, Task.parse);
  }

  @Selector()
  static selectAllData(state: TaskStateModel) {
    return (o: ITaskParam): Task[] => {

      if (state.data == null || Object.keys(state.data).length === 0) {
        return [];
      }

      // remove keys that have revision/draft ids, i.e that contain '~' in the id.
      const keys = Object.keys(state.data).filter(k =>
        k.indexOf('/') === -1 && state.data[k] != null);

      // object without the rev/draft.
      const filtered = keys.reduce((obj, k) => {
        obj[k] = state.data[k];
        return obj;
      }, {});

      let output = Object.values(filtered).map(x => Task.parse(x));
      output = taskSearchClientFilter(output, o);

      return output;
    };
  }


  /** Is the Paging Full for this search. */
  @Selector()
  static selectAllDataFull(state: TaskStateModel) {
    return (o: ITaskParam): boolean => {
      const oKey = getObjKey(o);
      const r = state.dataLoadStatus && state.dataLoadStatus[oKey];
      return (!!r) ? r.pData.full : true;
    };
  }


  // #endregion

  // #region Custom Functions and subscriptions

  public clearSubscriptions() {
    if (!!this.dataReqSub) {
      this.dataReqSub.unsubscribe();
      this.dataReqSub = null;
    }
    this.dataSubData.unsubscribeAll();
    super.clearSubscriptions();
  }

  // #endregion
  // #region Actions Single Entity


  @Action(TaskByIdRequested)
  dataByIdRequested(context: StateContext<TaskStateModel>, action: TaskByIdRequested) {
    const state = context.getState();
    if (Object.keys(state.data).indexOf(`${action.payload.id}`) === -1) {
      // logger.log('requested Rental-Product is not in the store. sending request to server', action.payload.id);
      this.newTs.getTaskById(action.payload.id)
        .pipe(
          map(data => {
            logger.log('from server: retal map was called', data);
            return context.dispatch(new TaskByIdLoaded({ data, id: action.payload.id }));
          }
          )).subscribe(noop, error => {
            logger.log('[gps] failed to task by id!', error);
            // return context.dispatch(new TaskByIdLoaded({ data: new Task(), id: action.payload.id }));
          });
    } else { logger.log('requested Rental-Product is available in the store'); }
  }

  @Action(TaskByIdLoaded)
  dataByIdLoaded(context: StateContext<TaskStateModel>, action: TaskByIdLoaded) {
    const state = context.getState();
    const data = action.payload.data || null;
    const p = {};
    p[action.payload.id] = data;
    context.patchState({
      data: { ...state.data, ...p }
    });
  }

  // #endregion Single Entity

  // #region All Orders
  @Action(TasksRequested)
  dataRequested(context: StateContext<TaskStateModel>, action: TasksRequested) {

    const oKey = getObjKey(action.payload.param);

    // Define function that return the data status object from the state.

    const getDataStatusStateFn = () => context.getState().dataLoadStatus;

    /** custom build the OR children query. */
    const buildOrQueryChildrenFn = (o: ITaskParam) => getITasksOptionOrChildren(o);

    // if data requested now, is already partially loaded by another query's child previously
    // but the number of the items are not enough (ex. as a child, it loaded only 5, but current
    // request ask for more, then next page of that query should be called instead.)
    const nextPageFn = (param: ITaskParam) => {
      context.dispatch(new TasksNextPageRequested({ param }));
    };

    buildDataRequest(
      oKey, action.payload.param, action.payload.pData,
      getDataStatusStateFn,
      buildOrQueryChildrenFn,
      nextPageFn,
      (
        obj: { [key: string]: IDataLoadStatus<ITaskParam> },
        set: { key: string, node: IDataLoadStatus<ITaskParam> }[]
      ) => {

        if (!!obj) {
          // Patch the state.
          const state = context.getState();
          context.patchState({
            dataLoadStatus: { ...state.dataLoadStatus, ...obj }
          });
        }

        // Process the query.
        set.forEach((val) => {
          // some of the nodes are already loaded. Only process that are loading... status.
          if (val.node.loadStatus !== LoadStatus.Loading) {
            return;
          }
          // if this request is just overwriting a stall or pending request, unsubscribe that observable
          this.dataSubData.unsubscribe(val.key);

          // create the paging observable and call db.
          // const p = this.ts.getTasks_PagingObservable();
          const p = this.newTs.getTasks_PagingObservable();
          const prod$ = p.getData(action.payload.pData, val.node.param)
            .pipe(
              map(tasks => {
                // if (!val.node.param.pdIdForHistory) {
                context.dispatch(new TasksLoaded({
                  data: tasks,
                  key: val.key
                }));
                // } else {
                //   context.dispatch(new AllPickDropHistoryLoaded({
                //     data: pickDrops,
                //     key: val.key
                //   }));
                // }
                return tasks;
              }));
          const sub = this.subscribe(prod$, () => noop(), TasksRequested.type);
          // save the observable call
          this.dataSubData.addData(val.key, sub, p);
        });
      }
    );
  }
  @Action(TasksNextPageRequested)
  dataNextPageRequested(context: StateContext<TaskStateModel>, action: TasksNextPageRequested) {
    const oKey = getObjKey(action.payload.param);
    const state = context.getState();
    // find the node. can be parent or child
    const statusObj = state.dataLoadStatus[oKey];
    // if no parent, treat is
    if (statusObj.children == null) {
      this.dataSubData.dispatchNextPagingUpdate(oKey);
    } else {
      const children = getRootLevelChildren(oKey, state.dataLoadStatus);
      children.forEach(c => {
        this.dataSubData.dispatchNextPagingUpdate(c.key);
      });
    }
  }
  @Action(TasksLoaded)
  dataLoaded(context: StateContext<TaskStateModel>, action: TasksLoaded) {
    const state = context.getState();
    const subData = this.dataSubData.getData(action.payload.key);
    const updatedLoadStatus = updatePaging(action.payload.key, state.dataLoadStatus, subData);
    context.patchState({
      dataLoadStatus: updatedLoadStatus,
      data: { ...state.data, ...action.payload.data }// entity.addMany(state.products, action.payload.products)
    });
  }
  @Action(TaskStateReset)
  taskStateReset(context: StateContext<TaskStateModel>, action: TaskStateReset) {
    // unsubscribe the data
    this.clearSubscriptions();
    context.setState(initTaskState());
  }
}
