
import * as Hammer from 'hammerjs';
setHammer(Hammer);
console.log('hammer was set');

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

// import { AppModule } from './app/web-site-switch/app.module';
import { environment } from '@trent/core/environments/environment';
import { AppModule } from './app/app.module';
import { setHammer } from '@trent/services/hammer-config-fake.service';

if (environment.production) {
  enableProdMode();
}


platformBrowserDynamic().bootstrapModule(AppModule);


// document.addEventListener('DOMContentLoaded', () => {
//   platformBrowserDynamic().bootstrapModule(AppModule)
//   .catch(err => logger.error(err));
// });

