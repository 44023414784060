import { round } from 'lodash';
import { EnumHelper } from '../utility/enum-helper';

export enum MileageUnit {
  km = 1,
  miles = 2,
}
export const kmToMiles = (k: number) => {
  return round(k * 0.621371, 0);
};
export const milesToKm = (m: number) => {
  return round(m / 0.621371, 0);
};
export const mileageUnitString = (mileageUnit: MileageUnit): string => {
  return EnumHelper.getName(MileageUnit, mileageUnit);
};

