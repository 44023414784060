import { isDate } from './../../utility/utl-datef';
import { format, isAfter } from 'date-fns';
import { isEqual as isEqualD } from 'date-fns';
import { isArray } from 'class-validator';
import { IItemDetail, IInvoiceSummary, getItemDetail } from './../invoice/i-item';
import { isNil, isNumber, difference, isEqual } from 'lodash';
import { InvoiceItem } from './../invoice/invoice-item';
import { Order } from './order';
import { TransactionType } from '../invoice/invoice-type';
import { OrderRec } from './order-rec';
import { OrderPay } from './order-pay';
import { Invoice } from '../invoice/invoice';
import { OrderType } from './order-type';
import { IPymtSchedule } from '../invoice/i-item';
import { logger } from '../../log/logger';
import { BidBase } from '@trentm/bid/bid-base';
import { companyFriendlyName } from '@trentm/company/company-helper';
import { DbRule } from '@trentm/base';
import { TruckSummary } from '@trentm/product/truck';
import { NegoTermsAssigned } from '@trentm/nego-terms-base/nego-terms-assigned';
import { CompanyBase } from '@trentm/company';
import { Inspection } from '@trentm/inspection/inspection';
import { InspectionDataTruck } from '@trentm/inspection/inspection-data-truck';

export const parseOrder = (obj: any): Order => {
  if (obj == null) { return null; }

  const r = <Order>obj; // cast it to base, remember it is still a javascript obj at run time.

  switch (r.invoiceType) {
    // case ProductType.driver:
    //   return RentalDriver.parse(r);

    case TransactionType.credit:
      return OrderRec.parse(r);

    case TransactionType.debit:
      return OrderPay.parse(r);

    // case ProductType.trip:
    //   const t = <TripBase>obj;
    //   return TripBase.parse(t);

    default:
      throw new Error(`Invalid invoice type. Invoice Type: ${r.invoiceType} is invalid or not yet implemented.`);
  }
};

export const parseOrderArray = (obj: any[]): Order[] => {
  const r = !!obj ? obj.map(o => Order.parse(o)) : null;
  return r;
};

/** update this payable order, clear to pay flag. 
 * Mark the clear to pay items in the payable order for which , custmer has already made the payment. This flag will then allow the vendor
 * to be paid down the road.
*/
export const updateClearToPay = (d: { payOrder: Order, payInv: Invoice, recOrder: Order, recInv: Invoice }) => {

  const recdPymts = d.recOrder.pymtSchedule.filter(x => x.orderType === OrderType.paid);
  const recdPymtsStrKeys = recdPymts.map(x => x.invItemId);

  // convert to ids.
  /**
   * x = ['1,2', '2,3,4'];
   * y = x.reduce((accum, curr) => accum.concat(curr.split(',')), []);
   * // Gives: y = ["1", "2", "2", "3", "4"]
   */
  let recItemsIds = recdPymtsStrKeys.reduce((accum, curr) => accum.concat(curr.split(',')), []).map(y => +y);
  recItemsIds = Array.from(new Set(recItemsIds)); // removed duplicates.


  /** Now find the payable items that are cleared for payments and belong to the pay order */
  const payItems = d.payInv.invoiceItems.filter(x => recItemsIds.includes(x.contingentItemId) && x.orderId === d.payOrder.id);
  const payItemsId = payItems.map(x => x.itemId);

  // Now go through all of the payments on the payable invoice and mark them clear for payments.
  const pendPymts = d.payOrder.pymtSchedule.filter(x => x.orderType === OrderType.pay);
  for (const p of pendPymts) {
    const ids = p.invItemId.split(',').map(x => +x);
    // all of the above ids must be contained inside the pay items ids, this payment is cleared to be paid.
    // Loadash to rescue,  difference([1,2], [1,2,3,4,5]).length is zero as all of first are contained in second.
    // [1,2]   [1,2,3,4,5]
    if (difference(ids, payItemsId).length === 0) {
      p.clearToProcess = true;
    }
  }
};

/** @deprecated */
export const buildInvoiceOrderUI = (inv: Invoice, o: Order): { schPayment: IPymtSchedule, detail: InvoiceItem[], detailData: IItemDetail[] }[] => {
  const r: { schPayment: IPymtSchedule, detail: InvoiceItem[], detailData: IItemDetail[] }[] = [];

  for (const p of o.pymtSchedule) {
    const ids = p.invItemId.split(',').map(x => +x);
    const detail = inv.invoiceItems.filter(x => ids.includes(x.itemId));
    r.push({
      detail,
      detailData: InvoiceItem.getDetailsAll(detail),
      schPayment: p
    });
  }
  return r;
};

/**
 * Build summary for the invoice/order so that it can be used to write pdf and show the data on component html page.
 * @param inv Invoice
 * @param o Order
 * @param pymtSchIdx [optional] only get the summary of payment schedules whose index id is provided 
 * (as single index, or multiple indexes in the array form).
 * @returns array of invoice summary.
 */
export const buildInvoiceSummary = (inv: Invoice, o: Order, pymtSchIdx?: number | number[]): IInvoiceSummary[] => {
  const summary: IInvoiceSummary[] = [];
  let idxArray: number[] = null;
  if (isNumber(pymtSchIdx)) {
    idxArray = [pymtSchIdx as number];
  }
  if (isArray(pymtSchIdx)) {
    idxArray = pymtSchIdx as number[];
  }

  let isFutureInvoiceFound : boolean = false;//MKN added to disable generate invoice button for future date period(After 1)

  const sch = isArray(idxArray) ? o.pymtSchedule.filter((curr, idx) => idxArray.indexOf(idx) >= 0) : o.pymtSchedule;
  for (const p of sch) {
    const ids = p.invItemId.split(',').map(x => +x);
    const detail = inv.invoiceItems.filter(x => ids.includes(x.itemId));
    const history: IItemDetail[] = [];
    if (isArray(p.history) && p.history.length > 0) {
      for (const x of p.history) {
        const h: IItemDetail = {
          name: `${OrderType[x.action]} `,
          amount: x.payment?.totalAmount
        };
        if (isDate(x.paymentDate)) {
          h.name += ` on ${format(x.paymentDate, 'dd-MMM-yyyy')}`;
        }
        history.push(h);
      }
    }

    const x: IInvoiceSummary = {
      pymtSch: p,
      isGenerateInvoiceEnabled : true,
      items: InvoiceItem.getDetailsAll(detail, undefined, inv.taxItems),
      pendingInfo: getItemDetail(p.pendingPymnt, p.pendingPymnt?.name, undefined, undefined, inv.taxItems),
      completedInfo: getItemDetail(p.completedPymt, p.completedPymt?.name, undefined, getCompletedTid(o, p), inv.taxItems),
      historyInfo: history,
      period: InvoiceItem.getDetailsAll(detail, undefined, inv.taxItems).find(f => f.invoiceItemType === 'rent')?.period,
      commentInfo: InvoiceItem.getDetailsAll(detail, undefined, inv.taxItems).filter(f => !!f.comments).map(e => { return { comment: e.comments, commentNumber: e.commentNumber }; })
    };

    /**
     * MKN added to disable generate invoice button for future date period(After 1 generate button)
     */

    if(detail && detail.length > 0 && detail[0].invoiceItemType != 'securityDeposit'){
      if(!isFutureInvoiceFound){
        if(x.pymtSch && !x.pymtSch.accountingInvoicePath && isAfter(x.pymtSch.dueDate, new Date())){
          isFutureInvoiceFound = true;
        }
      }else{
        x.isGenerateInvoiceEnabled = false;
      }
    }


    summary.push(x);
  }
  return summary;
};

/** Competed payment transaction ID */
export const getCompletedTid = (o: Order, p: IPymtSchedule): string => {
  const h = o.pymtSchedule.find(d => isEqualD(d.dueDate, p.dueDate));
  const tIds = h?.history?.map(f => f.tid);
  // if(!tIds || tIds.length === 0) {
  //   return undefined;
  // }
  // const transaction = o.transactions?.find(f => tIds?.includes(f.vendorOrderNumber) && (f.type === 'PAC' || f.type === 'R' || f.paymentService === 'Manual'));
  // return transaction.vendorTransId;

  const transactionIds = o.pymtSchedule.find(d => isEqualD(d.dueDate, p.dueDate)).history?.map(t => t.tid);
  if(!transactionIds || transactionIds.length === 0){
    return undefined;
  }
  // const t = o.transactions?.find(f => transactionIds?.includes(f.vendorOrderNumber));
  let t = o.transactions?.find(f => transactionIds.includes(f.vendorOrderNumber));
  let s: string;
  if(!t){
    return undefined;
  }
  if(t.paymentService === 'Manual') {
    s = `M${t.vendorTransId}`;
  } else {
    t = o.transactions?.find(f => (f.type === 'PAC' || f.type === 'R' || f.type === 'PA'));
    s = t ? `B${t.vendorTransId}` : null;
  }
  return s;
};


export type IContractInvoiceSummary  = {
  contractNo: string, 
  vin: string, 
  replacement: string, 
  customerName: string,
  startDate: Date,
  endDate: Date,
  insCertStartDate: Date,
  insCertEndDate: Date,
  odometerStart?: number | string,
  odometerEnd?: number | string,
  invoiceSummary: IInvoiceSummary[]
}

export type excelSheetType = {
  sheetData: (string | number)[][];
  sheetName: string;
}

export const buildContractsInvoiceSummary = (bids: BidBase[], invoices: Invoice[], orders: Order[], inspections: Inspection[]) => {
  const summaryData: IContractInvoiceSummary[] = [];

  bids.forEach((bid) => { 
    const contractInvoiceSummary: IContractInvoiceSummary = {
      contractNo: bid?.contractNo?.suffix + '/' + bid?.contractNo?.number + '/' + bid?.contractNo?.rev,
      vin: (bid.productSummary as TruckSummary)?.vin,
      replacement: ((bid.bidNegoTerms as NegoTermsAssigned)?.originalContractSummary?.vin || '') + ((bid.bidNegoTerms as NegoTermsAssigned)?.originalContractSummary?.contractNo?.number ? '(' + (bid.bidNegoTerms as NegoTermsAssigned).originalContractSummary.contractNo.number + ')' : ''),
      customerName: companyFriendlyName(bid.customerCompSummary?.name, bid.customerCompSummary?.legalName),
      startDate: bid.bidNegoTerms?.startDate,
      endDate: bid.bidNegoTerms?.endDate,
      insCertStartDate: bid.bidDocuments?.insCertStartDate,
      insCertEndDate: bid.bidDocuments?.insCertEndDate,
      odometerStart: '',
      odometerEnd:'',
      invoiceSummary: []
    };
    const inspection: Inspection = inspections.filter(inspection => inspection.bidSummary.bidId === bid.id)[0];
    const invoice: Invoice = invoices.filter((invoice)=> invoice.id === DbRule.getRootId(bid.cInvId))[0];
    const order: Order = orders.filter((order)=> order.invoiceId === DbRule.getRootId(bid.cInvId) && order.cid === bid.customerCompSummary?.cid)[0];
    contractInvoiceSummary['invoiceSummary'] = buildInvoiceSummary(invoice, order);
    contractInvoiceSummary['odometerStart'] = (inspection.vendorDrop as InspectionDataTruck)?.odometer ?? '';
    contractInvoiceSummary['odometerEnd'] = (inspection.vendorPick as InspectionDataTruck)?.odometer ?? '';
    summaryData.push(contractInvoiceSummary);
  });
  return summaryData;
};

export const buildExcelData = (summaryList: IContractInvoiceSummary[]): (string | number)[][] => {
  const no = summaryList.reduce((max, cur) => {
    if(cur.invoiceSummary.length > max) { 
      max = cur.invoiceSummary.length;
    }
    return max;
  },0);
  const colNames = ['Security Deposit', 'Admin Fee (One Time)', 'Security Refund', 'Reserve'];
  const paymentCols = ['Per Day Charges', 'Gap Insurance', 'Walk Away Insurance', 'Waiver Insurance', 'Period Payment', 'Environmental Fee', 'Vehicle Licensing Fee', 'Service Fee'];
  const extraCols = ['Mileage', 'Overage Miles', 'Damage', 'Credit', 'Other'];
  // paymentOneCols should be repeatable 
  const paymentOneCols = ['Duration', ...colNames, ...paymentCols, ...extraCols, 'Sub Total', 'Tax - HST', 'Total', 'Due date', 'Received date', 'Received/Pending', 'Transaction id'];
  const contractCols: string[] = ['Contract Number', 'VIN', 'Replacement', 'Customer','Contract Start date', 'Contract End date', 'Insurance Start date', 'Insurance End date', 'Odometer Start', 'Odometer End'];
  const headerRow: (string | string[])[] = [...contractCols];
  const paymentHeader = new Array(headerRow.length).fill('');
  for (let i = 0; i < no; i++) {
    headerRow.push(paymentOneCols);
    paymentHeader.push(...[`Payment ${i + 1}`, ...new Array(paymentOneCols.length - 1)]);
  }

  const valueRows: any = [];
  summaryList.forEach(summary => {
    const contractDetails = {
      contractNo: summary.contractNo,
      vin: summary?.vin || '',
      replacementRef: summary?.replacement || '',
      name: summary.customerName,
      startDate: summary.startDate.toLocaleDateString('default', { year: 'numeric', month: 'short', day: 'numeric' }) || '',
      endDate: summary.endDate.toLocaleDateString('default', { year: 'numeric', month: 'short', day: 'numeric' }) || '',
      insuranceStartDate: summary.insCertStartDate?.toLocaleDateString('default', { year: 'numeric', month: 'short', day: 'numeric' }) || '',
      insuranceEndDate: summary.insCertEndDate?.toLocaleDateString('default', { year: 'numeric', month: 'short', day: 'numeric' }) || '',
      odometerStart: summary.odometerStart, 
      odometerEnd: summary.odometerEnd
    };

    const valueRow: any = [];
    valueRow.push(...Object.values(contractDetails));
    summary.invoiceSummary.forEach((tableItem) =>{
      const values = new Array(paymentOneCols.length);
      if (tableItem.period) {
        const from = tableItem.period.from.toLocaleDateString('default', { year: 'numeric', month: 'short', day: 'numeric' });
        const to = tableItem.period.to.toLocaleDateString('default', { year: 'numeric', month: 'short', day: 'numeric' });
        values[0] = from + ' - ' + to;
      }
      let index = -1;
      tableItem.items.forEach(item => {
        index = paymentOneCols.indexOf(item.name?.trim());
        if (index > -1) {
          values[index] = item.amount;
        }
      });
      tableItem.pendingInfo.forEach(item => {
        if (item.name.includes('Due on')) {
          index = paymentOneCols.indexOf('Total');
          values[index] = item.amount;
        } else {
          index = paymentOneCols.indexOf(item.name);
          if (index > -1) {
            values[index] = item.amount;
          }
        }
      });
      tableItem.completedInfo.forEach(item => {
        if(item.name.includes('Received')){
          index = paymentOneCols.indexOf('Total');
          values[index] = item.amount;
          index = paymentOneCols.indexOf('Transaction id');
          values[index] = item.tid ?? '';
        } else {
          index = paymentOneCols.indexOf(item.name);
          if(index > -1) {
            values[index] = item.amount;
          }
        }
      });
      index = paymentOneCols.indexOf('Due date');
      values[index] = tableItem.pymtSch.dueDate.toLocaleDateString('default', { year: 'numeric', month: 'short', day: 'numeric' });
      index = paymentOneCols.indexOf('Received date');
      values[index] = tableItem.pymtSch?.paymentDate?.toLocaleDateString('default', { year: 'numeric', month: 'short', day: 'numeric' });
      index = paymentOneCols.indexOf('Received/Pending');
      values[index] = tableItem.completedInfo.length > 0 ? 'Received' : 'Pending';
      valueRow.push(values);
    });
    valueRows.push(valueRow);
  });

  const excel2DArray = [];
  excel2DArray.push(paymentHeader);

  const contractColsLength = contractCols.length;
  const header = headerRow.slice(0, contractColsLength);
  for (let i = contractColsLength; i < headerRow.length; i++) {
    header.push(...headerRow[i]);
  }

  excel2DArray.push(header);
  valueRows.forEach((valueRow) => {
    const values = [...valueRow.slice(0, contractColsLength)];
    for (let i = contractColsLength; i < valueRow.length; i++) {
      values.push(...valueRow[i]);
    }
    excel2DArray.push(values);
  });
  return excel2DArray;
};

export const buildContractCompanyData = (bids: BidBase[], orders: Order[], companies: CompanyBase[], invoices: Invoice[]): excelSheetType[] => {
  const CanadaHeaders: string[] = ['cCid', 'Company', 'Contract', 'Bank Transit No', 'Financial Inst No', 'Bank Account No', 'Next Due', 'Next Due Date'];
  const CanadaExcelData: (string | number)[][] = [CanadaHeaders];

  const USHeaders: string[] = ['cCid', 'Company', 'Contract', 'Routing', 'ACC', 'Next Due', 'Next Due Date'];
  const USExcelData: (string | number)[][] = [USHeaders];

  let pymtDueLenUS = 0, pymtDueLenCA = 0;
  bids.forEach((bid: BidBase) => {
    const order: Order = orders.filter(order => order.refId === bid.id && order.invoiceType === TransactionType.credit)[0];
    const company: CompanyBase = companies.filter(company => company.id == bid.customerCompSummary.cid)[0];
    const invoice: Invoice = invoices.filter((invoice) => invoice.id === DbRule.getRootId(bid.cInvId))[0];
    let invoiceSummary;
    try {
      invoiceSummary = buildInvoiceSummary(invoice, order).filter(item => item.pymtSch.dueDate < order?.nextPaymentDate || (item.pymtSch.dueDate < new Date()));
    } catch (e) {
      invoiceSummary = [];
    }
    const summaryData = {
      cCid: company?.id ?? bid.customerCompSummary?.cid,
      companyName: company?.name ?? bid.customerCompSummary.legalName,
      contractNo: bid?.contractNo?.suffix + '/' + bid?.contractNo?.number + '/' + bid?.contractNo?.rev,
      nextDueAmount: order?.pendingPymnt?.totalAmount ?? '',
      dueDate: order?.nextPaymentDate?.toLocaleDateString('default', { month: '2-digit', day: '2-digit', year: 'numeric' }) ?? '',
    };
    const pymntDueLen = invoiceSummary.filter(invSum => invSum.pendingInfo.length).length;
    if (company?.address.country == 'CA') {
      const CADetails = {
        cCid: summaryData.cCid,
        companyName: summaryData.companyName,
        contractNo: summaryData.contractNo,
        bankTransitNo: company.bankTransitNo,
        financialInstNo: company.financialInstNo,
        bankAccNo: company.bankAccNo,
        nextDueAmount: summaryData.nextDueAmount,
        dueDate: summaryData.dueDate
      };
      for (let i = 0; i < invoiceSummary.length; i++) {
        if(invoiceSummary[i].completedInfo?.length === 0){
          CADetails[`paymentDue${i + 1}`] = invoiceSummary[i].pymtSch.pendingPymnt?.totalAmount;
          CADetails[`dueDate${i + 1}`] = invoiceSummary[i].pymtSch.dueDate?.toLocaleDateString('default', { month: '2-digit', day: '2-digit', year: 'numeric' }) ?? '';
        }
      }
      CanadaExcelData.push(Object.values(CADetails));
      if (pymtDueLenCA < pymntDueLen) {
        pymtDueLenCA = pymntDueLen;
      }
    } else {
      const USDetails = {
        cCid: summaryData.cCid,
        companyName: summaryData.companyName,
        contractNo: summaryData.contractNo,
        routing: company?.usRoutingNo ?? '',
        accountNo: company?.usAccountNo ?? '',
        nextDueAmount: summaryData.nextDueAmount,
        dueDate: summaryData.dueDate
      };
      for (let i = 0; i < invoiceSummary.length; i++) {
        if(invoiceSummary[i].completedInfo?.length === 0) {
          USDetails[`paymentDue${i + 1}`] = invoiceSummary[i].pymtSch.pendingPymnt?.totalAmount;  
          USDetails[`dueDate${i + 1}`] = invoiceSummary[i].pymtSch.dueDate?.toLocaleDateString('default', { month: '2-digit', day: '2-digit', year: 'numeric' }) ?? '';
        }
      }
      USExcelData.push(Object.values(USDetails));
      if (pymtDueLenUS < pymntDueLen) {
        pymtDueLenUS = pymntDueLen;
      }
    }
  });
  for (let i = 1; i <= pymtDueLenCA; i++) {
    CanadaHeaders.push(`Payment due ${i}`, `Due date ${i}`);
  }
  for (let i = 1; i <= pymtDueLenUS; i++) {
    USHeaders.push(`Payment due ${i}`, `Due date ${i}`);
  }
  const data: excelSheetType[] = [
    { sheetData: CanadaExcelData, sheetName: 'Canada Data' },
    { sheetData: USExcelData, sheetName: 'US Data' }
  ];
  return data;
};