import { keys } from 'lodash';
import { Injectable } from '@angular/core';
import { BaseHttpService } from '@trent/services/base-http.service';

import { FirestoreService } from '@trent/services/firestore.service';
import { map, tap } from 'rxjs/operators';
import { plainToInstance, instanceToPlain } from 'class-transformer';
import { HttpHeaders, HttpClient } from '@angular/common/http';
// tslint:disable-next-line:max-line-length
import { Store } from '@ngxs/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { ValidatorOptions } from 'class-validator';
import { Paging } from '@trent/models/observable-util/paging';
import { PagingObesrvable } from '@trent/models/observable-util/paging-obesrvable';
import { logger } from '../models/log/logger';
import { NetSuiteProductSyncLogs } from '@trent/models/netSuite/netsuite-product-sync-logs';
import { INetSuiteProductSyncLogsParam, netSuiteProductSyncLogsSearchServerQuery } from '@trent/models/netSuite/netSuite-product-sync-logs-param';

/**
 * @author - MKN 
 * Service for getting data from firebase
 * 
 */
@Injectable()
export class NetSuiteProductSyncLogsService extends BaseHttpService {

  offset = new BehaviorSubject(null);
  infinite: Observable<any[]>;


  constructor(store: Store, private db: FirestoreService, private http: HttpClient) {
    super(store);
    this.apiName = 'api';
  }

  
  getNetSuiteProductSyncLogsById(id: string) {
    return this.db.docWithInjectedId$<NetSuiteProductSyncLogs>(
      `${NetSuiteProductSyncLogs.collectionName}/${id}`, id);
  }

  public getAllNetSuiteProductSyncLogs1_PagingObservable() {
    const p: PagingObesrvable<NetSuiteProductSyncLogs, INetSuiteProductSyncLogsParam> =
      new PagingObesrvable<NetSuiteProductSyncLogs, INetSuiteProductSyncLogsParam>(this.db, this.getAllNetSuiteProductSyncLogs1_batch);
    return p;
  }
  private getAllNetSuiteProductSyncLogs1_batch(p: Paging, o: INetSuiteProductSyncLogsParam): Observable<{ [key: string]: NetSuiteProductSyncLogs }> {
    const col = NetSuiteProductSyncLogs.collectionName;

    return this.db
      .colWithIdsInjectedNew$<NetSuiteProductSyncLogs>(col,
        ref => netSuiteProductSyncLogsSearchServerQuery(ref, o, p))
      .pipe(
        tap(arr => {
          if (arr == null || arr.length === 0) {
            console.log('All data is received, Paging is FULL');
            p.full = true;
          } else {
            p.lastDoc = arr[arr.length - 1];
          }
        }),
        map(arr => {
          return arr.reduce((acc, cur) => {
            const id = cur.id;
            const data = cur;
            return { ...acc, [id]: data };
          }, {});
        })
      );

  }
}
