import { keys } from 'lodash';
import { ValidationError, validateSync, ValidatorOptions, ValidationOptions } from 'class-validator';


export interface IValidate {
  // validate(): Promise<ValidationError[]>;
  validateSync(options: ValidatorOptions): IValidationMsg;
}

/** Output of validation result. key is the property and value is the array of errors
 * agains that property.
 */
export interface IValidationMsg {
  [key: string]: string[];
}

/** Add prope/errro to the validation message.
 * if prop is string, it is the key for the error. else it is the first object key.
 */
export const addValidationMsg = (v: IValidationMsg, prop: string | object, msg: string) => {
  const key = (typeof prop === 'string') ? <string>prop : <string>Object.keys(<object>prop)[0];
  if (typeof v[key] === 'object') {
    v[key].push(msg);
  } else {
    v[key] = [msg];
  }
};

/**
 * @param parent Parent Result that will be populated with child result
 * @param child  Chile result
 * @param prefix each key in the child, when added to the parent, will be appended by the prefix.
 */
export const mergeValidation = (parent: IValidationMsg, child: IValidationMsg, prefix: string, msgPrefix = ''): void => {
  if (child != null) {
    keys(child).forEach((k) => {
      if (!!msgPrefix && msgPrefix.length > 0) {
        for (let idx = 0; idx < child[k].length; idx++) {
          child[k][idx] = `${msgPrefix}-${child[k][idx]}`;
        }
      }
      parent[`${prefix}-${k}`] = child[k];
    });
  }
};

export interface FormValidationData<T> {
  model: T;
  option?: ValidationOptions;
}

/**
 * Output of validation as well as the Db Status of the model.
 * See implementation in the CompanyFleet.
 */
export interface IValDbStatusResult {

  /** If validation was pass for all of the groups tried in this object.*/
  pass: boolean;

  /** Net Resultant Message to be displayed to the user */
  message: string;

  /** Groupwise results of validation of all the groups in the model. */
  groupResult: {

    [group: string]: {

      /** True mean this group passes the validation */
      groupPass: boolean;

      message: string;
    }
  };
}
