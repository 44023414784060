import { promiseWraper } from './../../models/utility/helper';
import { Injectable } from '@angular/core';
import { ILocalStorage } from '@trent/models/local-storage/local-storage';
import { Preferences } from '@capacitor/preferences';

// import { Plugins } from '@capacitor/core';
// const { Storage } = Plugins;

/**
 * Do not use this service calss directly, USE @class LocalStorage
 */
@Injectable()
export class LocalStorageCap implements ILocalStorage {

  async get(key: string): Promise<any> {
    // const item = await Storage.get({ key: key });
    const item = await Preferences.get({ key: key });
    return JSON.parse(item.value);
  }


  async  set(key: string, value: any): Promise<any> {
    // await Storage.set({
      await Preferences.set({
      key: key,
      value: JSON.stringify(value)
    });
  }

  async remove(key: string): Promise<any> {
    // await Storage.remove({ key: key });
    await Preferences.remove({ key: key });
  }

  async clear(): Promise<void> {
    // await Storage.clear();
    await Preferences.clear();
  }

  async keys(): Promise<string[]> {
    const k = await Preferences.keys();
    return k.keys;
  }

  constructor() {

  }

}
