import { addDays, isEqual, format, isAfter } from 'date-fns';
import { getRandomNumber } from './../../utility/helper';
import { BaseModel } from './../../base/base-model';
import { Expose, instanceToInstance, plainToInstance, Type, Exclude } from 'class-transformer';
import { Min, ArrayNotEmpty, ValidateNested, IsEnum, ValidatorOptions, IsArray } from 'class-validator';
import { InvoiceItem } from './invoice-item';
import { CurrencyCode, OrderType } from '../order/order-type';
import { round, isNil, isArray, isDate, isEmpty } from 'lodash';
import { logger } from '../../log/logger';
import { TransactionType } from './invoice-type';
import { IPriceItem, InvoiceStatus, IPymtSchedule, IPayment, removeUnitPriceQuantity } from './i-item';
import { PaymentMethod } from '../payment-method/i-payment-method';
import { Order } from '../order/order';
import { getTotalTax } from '../tax/tax-handler';
import { IValidationMsg } from '../../error-handling/validation-info';
import { UserEnum } from '../../user/user-enum';
import { sanitizeDateIPoint } from '../../utility/sanitize-helper';
import { shortDate } from '../../utility/timer-helper';
import { InvoiceItemCat, SpecialItemTypeOptionalCombDb, SpecialItemTypeOptionalDb } from './invoice-item-type';
import { RentalPlan } from '../../rental/rental-plan';
import { MileageType } from '../../rental/milage-type';
import { RentalTerm } from '../../rental/rental-term';
import { ITaxItem } from '../tax/i-tax-item';
import { IAuth, ISales, IUserRole } from '@trent/models/company/company-base';
import { UserProfile } from '@trent/models/user/user-profile';
import * as moment from 'moment';


/**
 * Invoice is the master (Outcome of a service/contract/rental etc.)
 * IT will have an array of orders. *
 */
@Exclude()
export class Invoice extends BaseModel implements IPriceItem {






  public static readonly collectionName = 'invoice';
  /** invoice/invoiceNumber/invoiceNumber-index
   *  e.g. invoice/firebaseKey/TTR301-1
   */
  public static readonly storageFolderName = { customer: 'customer-invoice', vendor: 'vendor-invoice' };

  public name: string;

  /** contract that generated that invoice
   * Bid  / Rental / Product etc.   */
  @Expose()
  public refId: string;

  /** Number ID for the order that user can refer to. */
  @Expose()
  numId: number;

  @Expose()
  invoiceType: TransactionType;

  /** Invoice category Vendor Or Customer  */
  @Expose()
  invoiceCat: UserEnum;


  @Expose()
  paymentMethod: PaymentMethod;

  /** Company Id on this order. */
  @Expose()
  cid: string;
  @Expose()
  cCid: string | number;
  @Expose()
  vCid: string | number;

  /** Total Tax on the invoice (unrealized). */
  @Expose()
  tax: { [key: string]: number };

  @Expose({ toPlainOnly: true })
  get totalTax() {
    return getTotalTax(this.tax);
  }


  // @Expose()
  // @Min(0)
  // discount = 0;

  @Expose()
  @IsEnum(InvoiceStatus)
  invoiceStatus = InvoiceStatus.quote;

  @Expose()
  @ArrayNotEmpty({ message: 'Order must contain at least one item in it' })
  @ValidateNested({ each: true })
  @Type(() => InvoiceItem)
  invoiceItems: InvoiceItem[] = [];

  @Expose()
  @IsEnum(CurrencyCode)
  currencyCode: CurrencyCode;

  // @Expose()
  // @ValidateNested({ each: true })
  depositItems: number[] = [];

  //@Min(0)
  //amount = 0;

  // serviceFee: BaseFee;

  // // @Min(0)
  // get totalAmount() {
  //   return 0
  // }


  @Expose()
  total: IPriceItem;

  // @Expose()
  // oneTimeAdminFee = 0;
  // @Expose({ toPlainOnly: true })
  // get isFrozen() { return this.invoiceStatus >= 0; }


  // orderData: { [key: string]: IOrderData };


  @Expose({ toPlainOnly: true })
  @Min(0)
  get depositTotal(): number {
    // set deposit null items to zero
    const d = !!this.depositItems ? this.depositItems.slice().map(f => f = !!f ? f : 0) : [];
    return round(d.reduce((a, b) => a + b, 0), 2);
  }
  /** applicable rental plan added at the time invoice setup (this makes invoice independent of the contract) */
  @Expose()
  @Type(() => RentalPlan)
  rentalPlan: RentalPlan;

  @Expose()
  @IsArray()
  @ValidateNested({ each: true })
  addOns: (SpecialItemTypeOptionalDb | SpecialItemTypeOptionalCombDb)[];

  @Expose()
  @IsArray()
  taxItems: ITaxItem[];

  //Maintain business role Id who has access of this invoice
  @Expose()
  customerAuth: IAuth;

  @Expose({ toPlainOnly: true })
  get customerAuthIds(): string[] {
    let r: string[] = [];
    if(this.customerAuth){
      for (const key in this.customerAuth) {
        let keys = Object.keys(this.customerAuth[key]);
        r.push(...keys);
      }
    }
    
    return r;
  }

  @Expose()
  vendorAuth: IAuth;

  @Expose({ toPlainOnly: true })
  get vendorAuthIds(): string[] {
    let r: string[] = [];
    if(this.vendorAuth){
      for (const key in this.vendorAuth) {
        let keys = Object.keys(this.vendorAuth[key]);
        r.push(...keys);
      }
    }
    return r;
  }

  get paymentTerm() {
    const q = this.invoiceItems.find(f => f.invoiceItemType === 'rent').quantity;
    if(q < 7) {
      return RentalTerm.daily;
    } else if (q === 7) {
      return RentalTerm.weekly;
    } else if(q === 30) {
      return RentalTerm.monthly;
    } else {
      return undefined;
    }
  }

  /** Convert all GeoPoint class instances to IPoint. required before parsing data coming back
* from firebase. This must be called BEFORE PARSE and not AFTER PARSE
* @param data Data to be parsed
* @param type type to be used to decipher latitude/longitude. This is needed because at client the type is : firebase.firestore.GeoPoint
* and at function it is admin.firestore.GeoPoint they are two different types.
* https://github.com/Microsoft/TypeScript/wiki/FAQ#why-cant-i-write-typeof-t-new-t-or-instanceof-t-in-my-generic-function
*/
  public static parse(obj) {
    // public static parse<T extends IPoint>(obj, ipointType?: { new(...args: any[]): T }) {
    try {
      if (obj == null) { return null; }
      // obj = sanitizeDateIPoint(obj, ipointType);
      obj = sanitizeDateIPoint(obj);
      const m = plainToInstance<Invoice, any>(Invoice, obj);
      m.sanitize();
      return m;
    } catch (error) {
      logger.error('Error happened during parse', error);
      return null;
    }
  }

  constructor() {
    super();
    this.numId = getRandomNumber(16);
  }

  private updateTotalAmount() {
    this.total = InvoiceItem.getTotal(this.invoiceItems, this.invoiceType);
    removeUnitPriceQuantity(this.total);
  }

  /** only to be used BEFORE adding ite */
  private generateInvItemId() {
    if (!isArray(this.invoiceItems)) {
      this.invoiceItems = [];
    }
    return this.invoiceItems.length;
  }

  /** Can only be used when invoice is in unfrozen state. */
  public addItem(item: InvoiceItem) {
    if (!this.checkUnfrozenOrderItemsPolarity()) {
      throw new Error('Error adding item to the invoice. Non-process items in an invoice can not be a mix match of credit/debit.');
    }
    // a frozen item can not be added.
    if (item.isFrozen) {
      throw new Error('A frozen invoice item can not be added to the invoice');
    }

    // Update the total amount.
    item.updateTotalAmount(this.paymentMethod, this.addOns, this.taxItems);
    item.itemId = this.generateInvItemId();
    this.invoiceItems.push(item);
    this.updateTotalAmount();
  }

  /** Update the order ids in the invoice items.  */
  public updateOrderId(newId: string, currId?: string) {
    let counter = 0;
    for (const item of this.invoiceItems) {
      if (item.orderId === currId) {
        ++counter;
        item.orderId = newId;
      }
    }
    return counter; // how many items were updated.
  }

  /** any unfrozen order items need to be of a common polarity. */
  private checkUnfrozenOrderItemsPolarity() {
    const invItems = this.invoiceItems.filter(x => !x.isFrozen);
    if (invItems.length === 0) { return true; }

    const creditItems = invItems.filter(x => x.invoiceType === TransactionType.credit);
    const debitItems = invItems.filter(x => x.invoiceType === TransactionType.debit);

    if (creditItems.length === 0 && debitItems.length === invItems.length) { return true; }
    if (creditItems.length === invItems.length && debitItems.length === 0) { return true; }

    return false;
  }

  /**
   *
   * @param oid order id must be defined. Only the invoice item that do not have an order id associated with them, will
   * be used to build the order. (initial or adjustment)
   * @param preAuth if first payment is to be pre-authorized. (i.e not completed). For bid initiations, this is true.
   * @param transType if not defined, class own invoice type will be used. Remember, all payment schedule in an order are of one polarity
   * credit or debit. */
  public buildOrders(oid: string, preAuth = true, transType?: TransactionType) {

    const ttype = !isNil(transType) ? transType : this.invoiceType;

    // check for duplicate oid.
    const errItemCount = this.invoiceItems.filter(x => x.orderId === oid).length;
    if (errItemCount > 0) {
      logger.error(`Invalid Build order was called. Provided Order ID: ${oid} already exists in the invoice`);
    }

    const invItems = this.invoiceItems.filter(x => !x.isFrozen && ttype === x.invoiceType);
    if (invItems.length === 0) {
      logger.error('Invalid Build Order was called. No unassigned order items exists inside the order');
      return undefined;
    }

    if (!this.checkUnfrozenOrderItemsPolarity()) {
      throw new Error('Error adding item to the invoice. Non-process items in an invoice can not be a mix match of credit/debit.');
    }

    const o = new Order();
    o.id = oid;
    o.invoiceId = this.id;

    // o.in
    o.pendingPymnt = InvoiceItem.getPriceSummary(invItems);
    // Remove quantity as it is irrelevant.
    // if(!isNil(o.pendingPymnt.quantity)) {
    //   delete o.pendingPymnt.quantity;
    // }   


    const dic: { [key: number]: InvoiceItem[] } = {};
    for (const item of invItems) {
      item.orderId = oid;
      // Lock the invoice items which are added during the build order
      // item.isLocked = true;
      const id = item.dueDate.valueOf();
      if (!!dic[id]) {
        dic[id].push(item);
      } else {
        dic[id] = [item];
      }
    }

    const sortedIds = Object.keys(dic).sort();
    o.pymtSchedule = [];
    for (const key of sortedIds) {
      const a: InvoiceItem[] = dic[key];
      const sch: IPymtSchedule = {
        orderType: OrderType.pay,
        dueDate: a[0].dueDate,
        pendingPymnt: InvoiceItem.getPriceSummary(a),
        // completedPymt: undefined,
        // history: undefined
      } as IPymtSchedule;
      // item ids are comma delimited invoice item ids.
      sch.invItemId = a.map(x => x.itemId).join();
      sch.pendingPymnt.name = `Due on ${moment.tz(sch.dueDate, 'America/New_York').format('DD-MMM-YYYY')}`; // `Due on ${format(sch.dueDate, 'dd-MMM-yyyy', )}`; // Payment 
      o.pymtSchedule.push(sch);
    }
    if (preAuth && o.pymtSchedule?.length > 0 && this.invoiceType === TransactionType.credit) {
      o.pymtSchedule[0].orderType = OrderType.preAuthorization;
    }
    // o.pendingPymt = clonePriceItem(this);
    o.nextPaymentDate = new Date(o.pymtSchedule[0].dueDate);
    o.currencyCode = this.currencyCode;
    o.invoiceType = invItems[0].invoiceType; // copy the first one.
    o.cid = this.cid;
    o.refId = this.refId;
    o.orderCat = this.invoiceCat;
    switch (o.invoiceType) {
      case TransactionType.credit:
        o.vCid = this.vCid;
        break;
      case TransactionType.debit:
        o.cCid = this.cCid;
        break;
    
      default:
        break;
    }
    return o;
  }

  /**
   * HG: TODO
   * Invoice has already been updated by adding extra item in it. ( Ex. milage invoice-item). This function will update the existing order
   * by adding the new or 
   * @deprecated
   */
  public updateOrderMilage(o: Order, dueDate: Date, finalMilage: number, taxJuris: string, initialMilage: number) {
    // First make sure dueDate already exists in the payment schedule.
    if (!(isDate(dueDate) && o.pymtSchedule.some(x => isEqual(dueDate, x.dueDate)))) {
      throw Error('Invalid Dates was provided. for  updating the milage.');
    }

    //make sure invoice has all the items frozen ( we are updating, not creating order)
    if (this.invoiceItems.filter(x => !x.isFrozen).length > 0) {
      throw Error('Error updating the milage. Invoice contains non-frozen items.');
    }

    const a = this.rentalPlan.calculateMilageAmount(finalMilage - initialMilage);
    if (a <= 0) {
      return;
    }

    const pymtSch = o.pymtSchedule.find(x => isEqual(dueDate, x.dueDate));

    const item: InvoiceItem = new InvoiceItem();
    item.dueDate = new Date(dueDate);
    item.unitPrice = a;
    item.quantity = 1;
    item.itemCat = InvoiceItemCat.Revenue;
    // item.actualMileageAmount = a;
    item.taxJuris = taxJuris;
    item.invoiceType = TransactionType.credit;
    this.addItem(item);
    // Update the order ID later as 

    // Finally update the order. 
    item.orderId = `${o.id}`;
    const pymtItems = this.invoiceItems.filter(x => isEqual(x.dueDate, dueDate));
    pymtSch.pendingPymnt = InvoiceItem.getPriceSummary(pymtItems);
  }
  /**
   * 
   * Invoice has already been updated by adding extra item in it. ( Ex. milage invoice-item). This function will update the existing order
   * by adding the new or 
   * Order is updated as a side effect
   */
  public updateInvoice(o: Order, item: InvoiceItem, taxJuris: string) {
    // First make sure dueDate already exists in the payment schedule.
    if (!(isDate(item.dueDate) && o.pymtSchedule.some(x => isEqual(item.dueDate, x.dueDate)))) {
      throw Error('Invalid Dates was provided. for  updating the milage.');
    }
    let a: number;
    // make sure invoice has all the items frozen ( we are updating, not creating order)
    if (this.invoiceItems.filter(x => !x.isFrozen).length > 0) {
      throw Error('Error updating the milage. Invoice contains non-frozen items.');
    }
    switch (item.invoiceItemType) {
      case 'mileage':
        if (!this.rentalPlan.ratePerMile || this.rentalPlan.mileageType === MileageType.unlimited) {
          throw new Error('cannot charge mileage for unlimited mileage plan');

        }
        let daysOnDailyTerm: number;
        if (this.rentalPlan.rentalTerm === RentalTerm.daily) {
          daysOnDailyTerm = this.invoiceItems.find(f => f.invoiceItemType === 'rent').quantity;
        }
        const mileageDiff = round((item.mileage.endOdometer - item.mileage.startOdometer), 0);
        item.quantity = this.rentalPlan.calculateMilageAmount(mileageDiff, daysOnDailyTerm);
        item.name = 'Mileage';
        item.unitPrice = this.rentalPlan.ratePerMile; // item.actualMileageAmount;
        item.itemCat = InvoiceItemCat.Revenue;
        break;
      case 'damage':
        item.name = 'Damage';
        item.itemCat = InvoiceItemCat.Revenue;
        break;
      case 'other':
        item.name = 'Other';
        item.itemCat = InvoiceItemCat.Revenue;
        break;
      case 'credit':
        item.name = 'Credit';
        item.itemCat = InvoiceItemCat.Revenue;
        break;

      default:
        throw new Error(`update invoice not implemented for ${item.invoiceItemType}`);
    }

    if (item.amount <= 0 && item.invoiceItemType !== 'credit') {
      return;
    }
    if (item.amount >= 0 && item.invoiceItemType === 'credit') {
      return;
    }

    const pymtSch = o.pymtSchedule.find(x => isEqual(item.dueDate, x.dueDate));
    const pendingPymntName = pymtSch.pendingPymnt.name;
    // const item: InvoiceItem = new InvoiceItem();
    // item.dueDate = new Date(dueDate);
    // item.actualMileageAmount = a;
    item.taxJuris = taxJuris;
    item.invoiceType = this.invoiceType;
    this.addItem(item);
    // Update the order ID later as

    // Finally update the order.
    item.orderId = `${o.id}`;
    const pymtItems = this.invoiceItems.filter(x => isEqual(x.dueDate, item.dueDate));
    pymtSch.pendingPymnt = InvoiceItem.getPriceSummary(pymtItems);
    pymtSch.invItemId = pymtSch.invItemId + `,${item.itemId}`;
    pymtSch.pendingPymnt.name = pendingPymntName; 

  }
  /**
   * add new Invoice Items
   * @param newItems New Invoice Items added during contract change (which do not fall under print invoices)
   * @param taxJuris 
   */
  addItemOnContractChange(newItems: InvoiceItem[], taxJuris: string) {

    for (const e of newItems) {
      e.taxJuris = taxJuris;
      e.invoiceType = this.invoiceType;
      this.addItem(e);
    }
    // move deposit to end
    const itemsWPeriod = this.invoiceItems.filter(f => !isEmpty(f.period) && isDate(f.period.to));
    const lastDate = itemsWPeriod.sort((a, b) => a.period.to.valueOf() - b.period.to.valueOf()).pop().period.to;
    //MKN - Solved error in case of replacement unit assigned contract - modified case
    const itemSecurityDeposit = this.invoiceItems.find(f => f.invoiceItemType === 'securityDeposit' && f.amount < 0);
    itemSecurityDeposit && (itemSecurityDeposit.dueDate = addDays(lastDate, 3));
  }

  clone() {
    const t = instanceToInstance(this);
    t.sanitize();
    return t;
  }
  sanitize() {
    // if data was received from firebase, date is stored as snapshot.
    super.sanitize();
    // this.insExpiryDate = sanitizeDate(this.insExpiryDate);
    // return this;
  }

  validateSync(options?: ValidatorOptions): IValidationMsg {

    // for nested entry for address, add address group in it.
    const r = this.validateSyncBase(this, options);

    return r;
  }

  // #region UI Helper functions
  /**
   * @deprecated
   */
  getPaymentDesc(o: Order, i: number) {
    if (!!o && !!o.pymtSchedule[i]) {
      switch (o.pymtSchedule[i]?.orderType) {
        case OrderType.preAuthorized:
          return `(Valid till ${shortDate(addDays(o.pymtSchedule[i].paymentDate, 3))})`;
        case OrderType.paid:
          return `(Paid on ${shortDate(addDays(o.pymtSchedule[i].paymentDate, 3))})`;
        default:
          return `(Valid till ${shortDate(addDays(o.pymtSchedule[i].paymentDate, 3))})`; // TODO SS remove once orderType is fixed
      }
    }
    return null;
  }
  /**
   * @deprecated
   */
  getInvoiceUIItems(orders?: Order[]): { detail: IPayment[], total: IPriceItem } {
    logger.error('Sandhu Fix this UI element, it is using and order and order can only be build with a new id');
    // const o = this.buildOrders('?SandhuFixIt?');
    const tableArray: IPayment[] = [];
    const total = this.total;
    for (let i = 0; i < this.invoiceItems.length; i++) {
      const p = this.invoiceItems[i];
      const o = orders?.find(f => f.id === p.orderId || true);
      const t = {
        basePrice: p.amount,
        serviceFee: p.serviceFee.amount,
        tax: p.tax,
        totalAmount: p.totalAmount,
        dueDate: p.dueDate,
        from: p.period.from,
        to: p.period.to,
        orderId: p.orderId,
        paymentStatus: o?.pymtSchedule[i]?.orderType, // OrderType.preAuthorized,
        completedPymt: o?.pymtSchedule[i]?.completedPymt?.totalAmount,
        paymentDesc: this.getPaymentDesc(o, i),
        invoiceType: p.invoiceType,
        showFilter: true
        // preAuthValidTill: addDays(this.order?.transactions[i]?.created, 3),
      };
      tableArray.push(t);

    }
    return { detail: tableArray, total };


  }
  // #endregion
  /**
   * Returns filtered Invoice items which frozen, not paid and has period
   * @param o Order
   */
  filterInvoiceItems(o: Order) {
    return this.invoiceItems.filter(f => f.isFrozen && f.invoiceItemType === 'rent' && o.pymtSchedule.findIndex(g => g.orderType === OrderType.pay) > -1);
  }
  /** Add invoice Items to invoice for contract change, these are Accessorials  which added after the contract is signed */
  addAccessorials(origInvoice: Invoice, updatedOrder: Order) {
    const a: InvoiceItem[] = origInvoice.invoiceItems.filter(f => f.invoiceItemType === 'mileage' || f.invoiceItemType === 'other' || f.invoiceItemType === 'damage' || f.invoiceItemType === 'credit');
    for (const item of a) {
      item.orderId = undefined;
      item.subItems = !item.subItems ? [] : item.subItems;
      const newDueDates = updatedOrder.pymtSchedule.map(d => d.dueDate);
      newDueDates.sort((a, b) => a.valueOf() - b.valueOf());
      const uDate = newDueDates.find(f => isAfter(f, item.dueDate) || isEqual(f, item.dueDate));
      item.dueDate = uDate;
      this.updateInvoice(updatedOrder, item, item.taxJuris);
    }
  }

  getDepositDueDate(): Date {
    return this.invoiceItems.find(f => f.invoiceItemType === 'securityDeposit' && f.amount > 0)?.dueDate;
  }
  /** for a given order invItemId check is its is security deposit return item */
  isSecurityDepositItem(invItemId: string): boolean {
    const iArray: number[] = invItemId.split(',').map(s => +s);
    return this.invoiceItems.findIndex(f => iArray.includes(f.itemId) && f.invoiceItemType === 'securityDeposit' && f.amount > 0) > -1;

  }
  /** for a given order invItemId check is its is security deposit return item */
  isSecurityDepositReturnItem(invItemId: string): boolean {
    const iArray: number[] = invItemId.split(',').map(s => +s);
    return this.invoiceItems.findIndex(f => iArray.includes(f.itemId) && f.invoiceItemType === 'securityDeposit' && f.amount < 0) > -1;

  }

    /**
   * @author Cm
   * @purpose Add Sales User
   * @param user UserProfile
   */

  addUserAsSales(user:{id: string | number, displayName:string, isPrimary?:boolean, percentage?:number}) {
    // taking id of the auth user
    if (!this.customerAuth) {
      this.customerAuth = { sales: {} };
    }
    if (!this.customerAuth?.sales) {
      this.customerAuth.sales = {};
    }
    let sales: { [key: string]: ISales } = {
      [user.id]: {
        displayName: user.displayName,
        isPrimary: user?.isPrimary ? true : false,
        percentage: user?.percentage ? user.percentage : 0
      }
    };
    // Here adding sales person id 
    this.customerAuth.sales = { ...this.customerAuth.sales, ...sales };
  }

    /**
   * @author Cm
   * @purpose Add credit User
   * @param user UserProfile
   */

  addUserAsCredit(user: UserProfile) {
    // taking id of the credit user
    if (!this.vendorAuth) {
      this.vendorAuth = { credit: {} };
    }
    if (!this.vendorAuth?.credit) {
      this.vendorAuth.credit = {};
    }
    let credit: { [key: string]: IUserRole } = {
      [user.id]: {
        displayName: user.displayName,
      }
    };
    // Here adding credit person id 
    this.vendorAuth.credit = { ...this.vendorAuth.credit, ...credit };
  }


}
