import { TripTransaction } from './trip-transaction';
import { logger } from '../log/logger';



export interface IHOS {
  offDuty: number;
  drvSinceBreak: number; // driving time after the last break on the transaction
  drvSinceReset: number; // commulative driving between resets
  isReset: boolean;
}
export class HOS {

  public static readonly onDutyLimit = 13;
  public static readonly drivingLimit = 11;
  public static readonly durationToReset = 60;
  public static readonly resetDuration = 34;
  public static readonly hosBreak = 10;
  /**
   * set HOS properties for the trip transaction
 * @param prev previous transaction index
 * @param curr curr transaction
 */
  static getHosDurations(prev: TripTransaction, curr: TripTransaction): IHOS {
    let offDuty = 0;
    // const curr = this.tripSequence[idx];
    // const prev = this.tripSequence[idx - 1];
    // logger.log('curr', curr.hos);
    // logger.log('prev', prev.hos);


    const prevBalance = prev.hos.balanceDrivingTime;
    const onDuty = prev.dwellDuration;
    const drvDuration = curr.drvDuration;
    let isReset = false;
    let drvSinceBreak: number;
    let drvSinceReset = 0;
    // logger.dir({ drvDuration, prevBalance: prevBalance, onDuty: onDuty });
    // check is hosBreak is required
    const currCommDrive = (drvDuration + prev.hos.commDrivingTime);
    // check is reset is required
    if (currCommDrive < HOS.durationToReset) {
      if ((drvDuration + prevBalance + onDuty) <= HOS.onDutyLimit && (drvDuration + prevBalance) <= HOS.drivingLimit) {
        offDuty = 0;
      } else {
        const x = Math.floor((drvDuration + prevBalance) / HOS.drivingLimit);
        const y = Math.floor((drvDuration + prevBalance + onDuty) / HOS.onDutyLimit);
        const c = x > y ? x : y;
        offDuty = HOS.hosBreak * c;
      }
      drvSinceBreak = (drvDuration + prevBalance) % HOS.drivingLimit;
      // drvSinceReset = !prev.hos.isReset ? (prev.hos.commDrivingTime + drvDuration) : drvDuration;
      drvSinceReset = prev.hos.commDrivingTime + drvDuration;
    } else {
      // logger.log('currBalance cal', drvSinceBreak);
      // drvSinceReset = !prev.hos.isReset ? (prev.hos.commDrivingTime + drvDuration) : drvDuration;
      drvSinceReset = prev.hos.commDrivingTime + drvDuration;
      if (drvSinceReset > HOS.durationToReset) {
        const r = Math.floor(drvSinceReset / HOS.durationToReset);
        for (let i = 0; i < r; i++) {
          offDuty = offDuty + HOS.resetDuration;
          drvSinceReset = (drvSinceReset) - HOS.durationToReset;
          if (drvSinceReset > HOS.drivingLimit) {
            const z = Math.floor((drvSinceReset) / HOS.drivingLimit);
            offDuty = offDuty + HOS.hosBreak * z;
          }
          drvSinceBreak = ((drvSinceReset) % HOS.durationToReset) % HOS.drivingLimit;
        }
        isReset = true;
      }
    }
    // logger.dir({ offDuty, drvSinceBreak, drvSinceReset, isReset });
    return { offDuty, drvSinceBreak, drvSinceReset, isReset };
  }
}

