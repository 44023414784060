import { IPoint } from '../product-search/interfaces';
import { ProductType } from '../product/product-type';
import { isEqual } from 'lodash';

export type MapLabelClick = 'Rent' | 'Details' | 'Edit';
export type MapActions = 'ClusterAction' | 'IdAction';
export interface IMapLabelClick {
  btnLabel: 'Rent' | 'Details';
  emitterAction: string;
}

export type MapLabelType = 'StoreLocation' | 'OurLocation' | 'Gps';

export interface MapLabel {
  /** Type of products to be searched */
  iPoint?: IPoint;
  geohash?: string;
  icon?: string;
  title?: string;
  desc?: string;
  clickLabel?: IMapLabelClick[];
  actions?: MapActions[];
  id?: number | string;
  rent?: number | string;
  productType?: ProductType;
  addressFormated?: string;
  country?: string;
  index?: number;
  startTime?: Date;
  stateProv?: string;
  type?: MapLabelType;
}
/**
 * Compares to maplabel arrays and returns true is equal and false if different
 * @param o orginal MapLabel[]
 * @param u update MapLabel[]
 */
export function isEqualMapLables(o: MapLabel[], u: MapLabel[]): boolean {
  if (!o || !u || o.length !== u.length) {
    return false;
  }
  for (let i = 0; i < o.length; i++) {
    const ele = o[i];
    if (!isEqual(ele, u[i])) {
      return false;
    }
  }
  return true;
}
