import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsModule } from '@ngxs/store';
import { InvoiceState } from './invoice.state';
import { InvoiceService } from '../../services/invoice.service';


@NgModule({
  declarations: [],
  imports: [
    HttpClientModule,
    CommonModule,
    NgxsModule.forFeature([InvoiceState])
  ],
  providers: [
    InvoiceService
  ]
})
export class InvoiceStoreModule { }
