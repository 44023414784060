import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from './confirmation-dialog.component';
@NgModule({
  declarations: [ConfirmationDialogComponent],
  imports: [
    CommonModule,
    // Material
    MatButtonModule,
    MatDialogModule,

  ],

})
export class ConfirmationDialogModule { }
