// export const environment = {
//   production: true
// };

/** @author - PT
 * Algolia search import
 */
import algoliasearch from 'algoliasearch/lite';

const searchClient = algoliasearch(
    'C99O14RU2M', //Application Id
    '7826955334b6a870e4b0e2f7c2af7510' //API key
);

// configuration of alogolia search
export const algoliaConfig = {
    indexName: 'spare-part', //name of the index
    searchClient
};
