<!-- This is a material grid list component with one column and a row height of 400px. -->
<mat-grid-list [cols]="1" rowHeight="400px">
    <!-- This grid tile has a background image and a banner title "Contact". -->
    <mat-grid-tile class="background-image">
            <h2 class="banner-title c-white">Contact</h2>
                <!-- This div contains a Home link and an arrow icon. -->
                <!-- <div>
                    <a class="c-white" routerLink="/">Home</a>
                    <mat-icon class="arrow-icon">keyboard_arrow_right</mat-icon>
                </div> -->
    </mat-grid-tile>
</mat-grid-list>

<!-- This is another material grid list component with a dynamic number of columns based on the screen size, a row height of 400px, and a gutter size of 20. -->
<mat-grid-list class="content-wrapper" [cols]="breakpoint" rowHeight="400px" (window:resize)="onResize($event)"  gutterSize="20">
    <!-- This grid tile contains an image sourced from a file path. -->
    <mat-grid-tile>
        <img src="../../../assets/images/conatct-us-left.jpg" />
    </mat-grid-tile>
    <!-- This grid tile contains a custom element for a form, presumably used for enquiries. -->
    <mat-grid-tile>
        <div class="form-wrapper w-100">
            <trent-pts-enquiry-form [type]="type" [returnUrl]="'/contact-us'" [qParam]="{site:'dpc'}"></trent-pts-enquiry-form>
        </div>
    </mat-grid-tile>
</mat-grid-list>
