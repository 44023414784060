import { Exclude, Expose, instanceToInstance, plainToInstance, Type } from 'class-transformer';
import { IsBoolean, IsDate, IsDefined, IsInt, Max, Min, validateSync, ValidateIf, Equals, IsString, Length } from 'class-validator';
import { BaseModel } from '../base';
import { Task } from '@trent/models/sys/task';
import { TaskType } from '../sys/task-type';
import { TaskUtl } from '../sys/task-utl';
import { phoneRegex } from '@trent/models/utility';


// Different enum type for call same model different component
export enum EnquiryType {
    special = 1,
    contact = 2,
    financeDepartment = 3,
    financeApplication = 4,
    getAQuote = 6,
    bookAViewing = 7,
    applyForFinancing = 8,
    tradeInAppraisal = 9,
    dpcGetAQuote = 10,
    dpcContact = 11,
}

export interface UserDetails {
    uid?: string | number;
    displayName: string;
    phoneNumber: string | null;
    altPhoneNumber?: string | null;
    email: string;
}

export interface ProductDetails {
    pid: string;
    sid: string;
    vin?: string;
    stock?: string;
    unitName?: string
}

@Exclude()
export class PtsEnquiry extends BaseModel {

    public static readonly collectionName = 'pts-enquiry';

    @Expose()
    enquiryType?: EnquiryType;

    @Expose()
    userDetails: UserDetails;

    // Description for the Enquiry
    @Expose()
    @Length(5, 255, { message: 'Description should be $constraint1 and $constraint2 chars' })
    @IsDefined({ message: 'Description should be required' })
    description: string;

    phoneNumber: string | null;

    altPhoneNumber: string | null;

    @Expose()
    @ValidateIf(o => o.enquiryType == 6 || o.enquiryType == 7 || o.enquiryType == 8 || o.enquiryType == 9 || o.enquiryType == 10)  // Optional parameter 
    productDetails: ProductDetails;

    @Expose()
    isSubscribe: boolean;

    isPhoneNumberSavedInUserProfile: boolean;
    

    constructor() {
        super();
        this.userDetails = {
            uid: '',
            displayName: '',
            phoneNumber: null,
            altPhoneNumber: null,
            email: ''

        };
    }


    sanitize() {
        super.sanitize();
    }

    clone() {
        const t = instanceToInstance(this);
        t.sanitize();
        return t;
    }

    validateSync() {
        const r = this.validateSyncBase(this);
        // if (!this.userDetails.phoneNumber) {
        //     r['phoneNumber'] = ['Phone number should be required.'];
        // }
        // if(!phoneRegex(`${this.userDetails.phoneNumber}`)){
        //     r['phoneNumber'] = ['This is not a valid phone number.'];
        // }
        return r;
    }

    public static parse(obj) {
        if (obj == null) { return null; }
        const m = plainToInstance<PtsEnquiry, any>(PtsEnquiry, obj);
        m.sanitize();
        return m;
    }

    /**
 * Set task properties based on draft bid submitted by new company or company not released for rental,
 * @param task Task created when bid is created or updated
 * @param websiteUrl website url (e.g. www.locusloop.com)
 */
    setEnquiryNotification(task: Task, inq: PtsEnquiry) {
        // task.senderCompanyName = companyFriendlyName(this.customerCompSummary?.name, this.customerCompSummary?.legalName);
        // tslint:disable-next-line:max-line-length
        task.name = this.userDetails.displayName;

        switch (this.enquiryType) {
            case EnquiryType.special:
                task.taskType = TaskType.ptsSpecialEnquiry;
                task.data = { action: `${TaskType.ptsSpecialEnquiry}`, action_key: `${this.id}` };
                break;
            case EnquiryType.contact:
                task.taskType = TaskType.ptsContactEnquiry;
                task.data = { action: `${TaskType.ptsContactEnquiry}`, action_key: `${this.id}` };
                break;
            case EnquiryType.financeApplication:
                task.taskType = TaskType.ptsFinanceApplicationEnquiry;
                task.data = { action: `${TaskType.ptsFinanceApplicationEnquiry}`, action_key: `${this.id}` };
                break;
            case EnquiryType.financeDepartment:
                task.taskType = TaskType.ptsFinanceDepartmentEnquiry;
                task.data = { action: `${TaskType.ptsFinanceDepartmentEnquiry}`, action_key: `${this.id}` };
                break;
            case EnquiryType.getAQuote:
                task.taskType = TaskType.ptsGetAQuoteEnquiry;
                task.data = { action: `${TaskType.ptsGetAQuoteEnquiry}`, action_key: `${this.id}` };
                break;
            case EnquiryType.bookAViewing:
                task.taskType = TaskType.ptsBookAViewingEnquiry;
                task.data = { action: `${TaskType.ptsBookAViewingEnquiry}`, action_key: `${this.id}` };
                break;
            case EnquiryType.applyForFinancing:
                task.taskType = TaskType.ptsApplyForFinancingEnquiry;
                task.data = { action: `${TaskType.ptsApplyForFinancingEnquiry}`, action_key: `${this.id}` };
                break;
            case EnquiryType.tradeInAppraisal:
                task.taskType = TaskType.ptsTradeInAppraisalEnquiry;
                task.data = { action: `${TaskType.ptsTradeInAppraisalEnquiry}`, action_key: `${this.id}` };
                break;
            default:
                throw new Error(`Invalid enquiry Response, ${this.enquiryType} is programmed yet`);
        }



        task.notification = {
            title: `${task.name}`,
            body: `<div style="background: #99b48f; margin:20px 30px;border-radius:10px">
            <div style="margin:20px 30px;">
                <h3>Hi ${inq.userDetails.displayName},</h3>
                <p>Thank you for contacting us, we have received your request and will be in touch shortly. A copy of the information you submitted is attached to this message for your reference</p>
                <h4>Name : ${inq.userDetails.displayName}</h4>
                <h4>Email : ${inq.userDetails.email}</h4>
                <h4>Phone : ${inq.userDetails.phoneNumber}</h4>
                ${inq.userDetails.altPhoneNumber ? `<h4>Alt Phone: ${inq.userDetails.altPhoneNumber}</h4>` : ''}
                <h3>Comments</h3>
                    ${inq.productDetails?.vin ? `<h4>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Vin : ${inq.productDetails.vin}</h4>` : ''}
                    ${inq.productDetails?.unitName ? `<h4>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Unit Name : ${inq.productDetails.unitName}</h4>` : ''}
                <p>Description: ${inq.description}</p>
            </div>
            </div>`
        };
    }


    setEnquiryNotificationForAdmin(task: Task, inq: PtsEnquiry) {
        // task.senderCompanyName = companyFriendlyName(this.customerCompSummary?.name, this.customerCompSummary?.legalName);
        // tslint:disable-next-line:max-line-length
        task.name = this.userDetails.displayName;

        let type;

        switch (this.enquiryType) {
            case EnquiryType.special:
                task.taskType = TaskType.ptsSpecialEnquiry;
                task.data = { action: `${TaskType.ptsSpecialEnquiry}`, action_key: `${this.id}` };
                type = 'Specials';
                break;
            case EnquiryType.contact:
                task.taskType = TaskType.ptsContactEnquiry;
                task.data = { action: `${TaskType.ptsContactEnquiry}`, action_key: `${this.id}` };
                type = 'Contact';

                break;
            case EnquiryType.financeApplication:
                task.taskType = TaskType.ptsFinanceApplicationEnquiry;
                task.data = { action: `${TaskType.ptsFinanceApplicationEnquiry}`, action_key: `${this.id}` };
                type = 'Finance';

                break;
            case EnquiryType.financeDepartment:
                task.taskType = TaskType.ptsFinanceDepartmentEnquiry;
                task.data = { action: `${TaskType.ptsFinanceDepartmentEnquiry}`, action_key: `${this.id}` };
                type = 'Finance';

                break;
            case EnquiryType.getAQuote:
                task.taskType = TaskType.ptsGetAQuoteEnquiry;
                task.data = { action: `${TaskType.ptsGetAQuoteEnquiry}`, action_key: `${this.id}` };
                type = 'Get a quote';

                break;
            case EnquiryType.bookAViewing:
                task.taskType = TaskType.ptsBookAViewingEnquiry;
                task.data = { action: `${TaskType.ptsBookAViewingEnquiry}`, action_key: `${this.id}` };
                type = 'Book A viewing';

                break;
            case EnquiryType.applyForFinancing:
                task.taskType = TaskType.ptsApplyForFinancingEnquiry;
                task.data = { action: `${TaskType.ptsApplyForFinancingEnquiry}`, action_key: `${this.id}` };
                type = 'Apply for Finance';

                break;
            case EnquiryType.tradeInAppraisal:
                task.taskType = TaskType.ptsTradeInAppraisalEnquiry;
                task.data = { action: `${TaskType.ptsTradeInAppraisalEnquiry}`, action_key: `${this.id}` };
                type = 'Trade in appraisal';

                break;
            default:
                throw new Error(`Invalid enquiry Response, ${this.enquiryType} is programmed yet`);
        }



        task.notification = {
            title: `${task.name}`,
            body: `<div style="background: #ffb9b3; margin:20px 30px;border-radius:10px">
            <div style="margin:20px 30px;">
                <h3>Hi User,</h3>
                <p>New enquiry is generated. Please take appropriate action on it.</p>
                <h3>Enquiry Details</h3>
                ${inq.userDetails?.displayName ? `<h4>Name : ${inq.userDetails.displayName}</h4>` : 'Guest user'}
                ${inq.userDetails?.email ? `<h4>Email : ${inq.userDetails.email}</h4>` : ''}
                ${inq.userDetails?.phoneNumber ? `<h4>Phone : ${inq.userDetails.phoneNumber}</h4>` : ''}
                ${inq.userDetails.altPhoneNumber ? `<h4>Alt Phone: ${inq.userDetails.altPhoneNumber}</h4>` : ''}
                <h4>Enquiry Type: ${type}</h4>

                <h3>Comments</h3>
                    ${inq.productDetails?.vin ? `<h4>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Vin : ${inq.productDetails.vin}</h4>` : ''}
                    ${inq.productDetails?.unitName ? `<h4>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Unit Name : ${inq.productDetails.unitName}</h4>` : ''}
                <p>Description: ${inq.description}</p>
            </div>
            </div>`
        };
    }




}
