import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnumPipe, ExponentialStrengthPipe } from './enum.pipe';
import { DurationPipe } from './duration.pipe';
import { PhonePipe } from './phone.pipe';
// import { SafePipe } from './safe.pipe.ts_';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    EnumPipe,
    ExponentialStrengthPipe,
    DurationPipe,
    PhonePipe
  ],
  exports: [
    EnumPipe,
    ExponentialStrengthPipe,
    DurationPipe,
    PhonePipe
  ]
})
export class CustomPipeModule { }
