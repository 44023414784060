import { ProductSummaryBase } from './product-base';
import { IsBoolean, IsDate, isEmpty, IsInt, IsNumber, ValidateIf, ValidatorOptions } from 'class-validator';
import { instanceToInstance, plainToInstance, Exclude, Expose, Type } from 'class-transformer';
import { IValidationMsg, IValDbStatusResult } from '../error-handling/validation-info';
import { ProductType } from './product-type';
import { GpsType, Vehicle } from './vehicle';
import { sanitizeDate, sanitizeDateIPoint } from '../utility/sanitize-helper';
import { isDate, isAfter, lastDayOfMonth, addYears, addDays } from 'date-fns';
import { addAYear } from '../utility/utl-datef';
import { FileInfo, IFileMoveData } from '../media/file-info';
import { DbStatus } from '../base';
import { ValidateNested, IsDefined } from 'class-validator';
import { logger } from '../log/logger';
import { IInspectionPersistentData } from '../inspection/i-inpection-presistent-data';
import { OdometerUnitsType } from './netsuite-product-data';

export type TruckValidationGroup = 'unitDetails' | 'specificationDetails' | 'physicalLocationDetails' | 'documents' | 'plating' | 'misc' | 'pics';
const unitDetails: TruckValidationGroup = 'unitDetails';
const specificationDetails: TruckValidationGroup = 'specificationDetails';//MKN
const physicalLocationDetails: TruckValidationGroup = 'physicalLocationDetails';//MKN
const documents: TruckValidationGroup = 'documents';
const plating: TruckValidationGroup = 'plating';
const misc: TruckValidationGroup = 'misc';
const pics: TruckValidationGroup = 'pics';

export const TruckMakes = ['FREIGHTLINER', 'VOLVO TRUCK', 'PETERBILT', 'KENWORTH', 'INTERNATIONAL', 'MACK', 'Other'] as const;
export type TruckMakeType = typeof TruckMakes[number];

export interface TruckSummary extends ProductSummaryBase {
  unitName: string;
  make: string;
  modelYear: number;
  isDayCab: boolean;
  isAuto: boolean;
  isIRP: boolean;
  vin: string;
  plateNo: string;
  safetyExpiryDate: Date;
  plateExpiryDate: Date;
  summaryString: string;
  title?: string;
  prodIcon?: string;
  truckType: TruckType;
  isUsed:boolean;
  model?: string;
  color?: string;
  odometer?: number;
  odometerUnits?: string;
  vinLastSix?:string;
  gpsProvider?: GpsType;
  gpsSrNo: string;
}
export interface ContractTruckSummary {
  vin: string;
  plateNo: string;
  safetyExpiryDate: Date;
  plateExpiryDate: Date;
}
export enum TruckType {
  boxTruck = 1,
  dumpTruck = 2,
  highwayTractor = 3, // aka Sleeper
  dayCab = 5,
}



/** For Truck  */
@Exclude()
export class Truck extends Vehicle {
  constructor() {
    super();
    this.productType = ProductType.truck;
    this.cabCard = new FileInfo();
    // this.truckFile = new FileInfo();
  }

  
  @Expose()
  get vinLastSix() {
    const l = this.vin ? this.vin.length : 0;
    return l > 6 ? this.vin?.substring(l - 6, l) : undefined;
  }

  @Expose()
  get picturesLength() {
    return (this.pictures) ? this.pictures?.length : 0;
  }
  
  @Expose()
  //@IsBoolean({ message: 'Define Cab Type', groups: [unitDetails] })
  isDayCab = false;

  @Expose()
  //@IsBoolean({ message: 'Define Transmission Type', groups: [unitDetails] })
  isAuto = true;

  @Expose()
  @ValidateIf(o => !o.unplated, { groups: [plating] })
  @IsBoolean({ message: 'Define Plate Type', groups: [plating] })
  isIRP = true;

  @Expose()
  @ValidateIf(o => !o.unplated, { groups: [plating] })
  @IsDate({ message: 'Plate Expiry date is required', groups: [plating] })
  plateExpiryDate: Date;

  // @Length(5, 254, { message: 'Upload Document for Vehicle Registration', groups: [documents] })
  @Expose()
  @ValidateIf(o => !o.unplated, { groups: [documents] })
  @ValidateNested({ message: 'Upload Vehicle Registration', groups: [documents] })
  @IsDefined({ message: 'Upload Vehicle Registration', groups: [documents] })
  @Type(() => FileInfo)
  cabCard: FileInfo;

  // @Length(5, 254, { message: 'Upload Document for Vehicle Registration', groups: [documents] })
  // @Expose()
  // @ValidateIf(o => !o.unplated, { groups: [documents]})
  // @ValidateNested({ message: 'Upload Truck File', groups: [documents] })
  // @IsDefined({ message: 'Upload Truck File', groups: [documents] })
  // @Type(() => FileInfo)
  // truckFile: FileInfo;

  @Expose()
  @ValidateIf(o => !o.unplated && !o.asIs, { groups: [plating] })
  @IsInt({ groups: [plating] })
  pmMileage: number;

  //MKN -  Specification field
  // @Expose()
  // @ValidateIf(o => !!o.vin, { groups: [specificationDetails] })
  // engineManufacturer: string;

  //MKN -  Specification field
  @Expose()
  @ValidateIf(o => !!o.vin, { groups: [specificationDetails]})
  unitColor : string;

  //MKN -  unit Details field
  @Expose()
  @ValidateIf(o => !!o.vin, { groups: [specificationDetails] })
  engineModel: string;

  //MKN -  Specification field
  @Expose()
  @ValidateIf(o => !!o.vin, { groups: [specificationDetails] })
  odometer: number;

  //MKN -  Specification field
  @Expose()
  @ValidateIf(o => !!o.vin, { groups: [specificationDetails] })
  odometerUnits: OdometerUnitsType;

  //KS - Truck Type
  @Expose()
  @ValidateIf(o => !!o.vin, { groups: [specificationDetails] })
  truckType: TruckType;

  //MKN -  Specification field
  @Expose()
  @ValidateIf(o => !!o.vin, { groups: [specificationDetails] })
  horsepower: number;

  //MKN -  Specification field
  @Expose()
  @ValidateIf(o => !!o.vin, { groups: [specificationDetails] })
  torque: number;

  // @Expose()
  // @ValidateIf(o => !!o.vin, { groups: [specificationDetails] })
  // wheelBase: number;

  @Expose()
  @ValidateIf(o => !!o.vin && !o.isDayCab, { groups: [specificationDetails] })
  sleeperSize: number;

  @Expose()
  @ValidateIf(o => !!o.vin, { groups: [specificationDetails] })
  gearRatio: number;

  // @Expose()
  // @ValidateIf(o => !!o.vin, { groups: [specificationDetails] })
  // deferentialRatio: number;

  @Expose()
  @ValidateIf(o => !!o.vin, { groups: [specificationDetails] })
  rearAxilWeight: number;

  @Expose()
  @ValidateIf(o => !!o.vin, { groups: [specificationDetails] })
  @IsBoolean()
  isFifthWheelSliding: boolean;



  /** inspectionPersistentData added when inspection is completed for a vehicle.*/
  @Expose()
  inspectionPersistentData: IInspectionPersistentData;  // IInspectionPersistentDataTruck;

  get plateExpiryDateMinDate() {
    return new Date();
  }
  get plateExpiryDateMaxDate() {
    return addDays(addYears(new Date(), 1), -1);
  }

  get odometerString(){
    return this.odometer ? (this.odometer+" "+this.odometerUnits) : ''
  }

  get summary() {
    return (
      `${this.isDayCab ? 'Day Cab Truck, ' : ' Sleeper Truck, '} ${this.isIRP ? 'IRP Plated, ' : 'Local Plated, '}` +
      `${this.isAuto ? 'Automatic Transmission' : 'Manual Transmission'}`
    );
  }

  get plateExpiryDateIso(): string { return isDate(this.plateExpiryDate) ? this.plateExpiryDate.toISOString() : ''; }
  set plateExpiryDateIso(val) { this.plateExpiryDate = sanitizeDate(val); } // sanitizeIonicDatePicker(val); }

  get defaultImage() {
    return './assets/black-truck-icon.svg';
  }
  /** Convert all GeoPoint class instances to IPoint. required before parsing data coming back
   * from firebase. This must be called BEFORE PARSE and not AFTER PARSE
   * @param data Data to be parsed
   * @param type type to be used to decipher latitude/longitude. This is needed because at client the type is : firebase.firestore.GeoPoint
   * and at function it is admin.firestore.GeoPoint they are two different types.
   * https://github.com/Microsoft/TypeScript/wiki/FAQ#why-cant-i-write-typeof-t-new-t-or-instanceof-t-in-my-generic-function
   */
  public static parse(obj) {
    // public static parse<T extends IPoint>(obj, ipointType?: { new(...args: any[]): T }) {
    try {

      if (obj == null) { return null; }
      obj = sanitizeDateIPoint(obj);
      // obj = sanitizeDateIPoint(obj, ipointType);
      const m = plainToInstance<Truck, any>(Truck, sanitizeDateIPoint(obj));
      m.sanitize();
      if (m.vehicleReg == null) { m.vehicleReg = new FileInfo(); }
      if (m.asIs === undefined) { m.asIs = false; }
      if (m.unplated === undefined) { m.unplated = false; }
      if (!!m.asIs) { m.unplated = true }
      if (!m.asIs) {
        if (m.safety == null) { m.safety = new FileInfo(); }
      } else {
        if (!isEmpty(m.safety)) { m.safety = undefined; }
      }
      if (!m.unplated) {
        // if (m.truckFile == null) { m.truckFile = new FileInfo(); }
        if (m.cabCard == null) { m.cabCard = new FileInfo(); }
      } else {
        // if (!isEmpty(m.truckFile)) { m.truckFile = undefined; }
        if (!isEmpty(m.cabCard)) { m.cabCard = undefined; }
      }
      return m;
    } catch (error) {
      logger.log('Error happened during parse', error);
      return null;
    }
  }
  // public static getTruckSummary(product: Truck): TruckSummary {
  //   return {
  //     unitName: product.unitName,
  //     make: product.make,
  //     modelYear: product.modelYear,
  //     isDayCab: product.isDayCab,
  //     isAuto: product.isAuto,
  //     isIRP: product.isIRP,
  //     cid: product.cid,
  //     pRevid: product.revId
  //   };
  // }
  // public getContractSummary(product: Truck): ContractTruckSummary  {
  //   return {
  //     vin: product.vin,
  //     plateNo: product.plateNo,
  //     safetyExpiryDate: product.safetyExpiryDate,
  //     plateExpiryDate: product.plateExpiryDate

  //   };
  // }

  getProductSummary(): TruckSummary {
    return {
      unitName: this.unitName,
      make: this.make,
      model: this.model,
      color: this.unitColor,
      modelYear: this.modelYear,
      isDayCab: this.isDayCab,
      isAuto: this.isAuto,
      isIRP: this.isIRP,
      isUsed: this.isUsed,
      vin: this.vin,
      vinLastSix: this.vinLastSix,
      odometer : this.odometer,
      odometerUnits : this.odometerUnits,      
      plateNo: this.plateNo,
      safetyExpiryDate: this.safetyExpiryDate,
      plateExpiryDate: this.plateExpiryDate,
      summaryString: this.summary,
      title: `${(this.isDayCab) ? 'Day Cab' : 'Sleeper'} Truck`,
      prodIcon: `local_shipping`,
      truckType: this.truckType,
      gpsProvider: this.gpsProvider,
      gpsSrNo: this.gpsSrNo,
      ...super.getProductSummary()
    };
  }
  // public static setTruckSummary(prodSummary: TruckSummary): TruckSummary {
  //   return {
  //     unitName: prodSummary.unitName,
  //     make: prodSummary.make,
  //     modelYear: prodSummary.modelYear,
  //     isDayCab: prodSummary.isDayCab,
  //     isAuto: prodSummary.isAuto,
  //     isIRP: prodSummary.isIRP
  //   };
  // }

  clone() {
    const t = instanceToInstance(this);
    t.sanitize();
    return t;
  }
  /** Update all attached documents paths from /draft/ to /rel/ */
  updateFilePathsToRelease(): IFileMoveData[] {
    const R: IFileMoveData[] = [];
    let r = FileInfo.updatePathToRelease(this.cabCard);
    if (!!r) { R.push(r); }

    // r = FileInfo.updatePathToRelease(this.truckFile);
    // if (!!r) { R.push(r); }

    r = FileInfo.updatePathToRelease(this.safety);
    if (!!r) { R.push(r); }

    r = FileInfo.updatePathToRelease(this.vehicleReg);
    if (!!r) { R.push(r); }

    return R;
  }
  validateSync(options?: ValidatorOptions): IValidationMsg {
    const r = this.validateSyncBase(this, options);
    // mergeValidation(r, this.contact.validateSyncBase(this.contact, options), 'contact');
    // mergeValidation(r, this.address.validateSyncBase(this.address, options), 'a');

    // safetyExpiryDate  data must be in the future.
    if (isDate(this.plateExpiryDate)) {
      if (isAfter(new Date(), this.plateExpiryDate)) {
        r['plateExpiryDate'] = ['Plate expiry date must be in future'];
      }
      // plateExpiryDate data must within a year
      if (isAfter(this.plateExpiryDate, addAYear(1))) {
        r['plateExpiryDate'] = ['Plate expiry date must be within a year'];
      }
    }
    if (isDate(this.safetyExpiryDate)) {
      if (isAfter(new Date(), this.safetyExpiryDate)) {
        r['safetyExpiryDate'] = ['Safety expiry date must be in future'];
      }
      // safetyExpiryDate data must within a year, end of the month.
      if (isAfter(lastDayOfMonth(this.safetyExpiryDate), lastDayOfMonth(addAYear(2)))) {
        r['safetyExpiryDate'] = ['Safety expiry date must be within two years'];
      }
    }
    // Add any additional validation here if/as required.
    // if (options.groups.indexOf(documents) !== -1 && Object.keys(r).length < 3) {
    //   return {};
    // }
    return r;

  }

  sanitize() {
    super.sanitize();
    this.plateExpiryDate = sanitizeDate(this.plateExpiryDate);
    this.safetyExpiryDate = sanitizeDate(this.safetyExpiryDate);
    // return this;
  }
  /** Analyze product to see if more information is needed or suggest the user
   * to submit for approval.*/
  getValDbStatus(): IValDbStatusResult {
    const result: IValDbStatusResult = {
      pass: false,
      message: undefined,
      groupResult: {}
    };
    let x: { groupPass: boolean; message: string };
    // unitDetails
    let r = this.validateSyncGroup(unitDetails);
    x = { groupPass: null, message: '' };
    x.groupPass = Object.keys(r).length === 0;
    x.message = x.groupPass ? 'Manage unitDetails Information' : 'unitDetails information is required';
    result.groupResult[unitDetails] = x;

    // specificationDetails
    r = this.validateSyncGroup(specificationDetails);
    x = { groupPass: null, message: '' };
    x.groupPass = Object.keys(r).length === 0;
    x.message = (x.groupPass) ? 'Manage specification details' : 'specification information is required';
    result.groupResult[specificationDetails] = x;

    // physicalLocationDetails
    r = this.validateSyncGroup(physicalLocationDetails);
    x = { groupPass: null, message: '' };
    x.groupPass = Object.keys(r).length === 0;
    x.message = (x.groupPass) ? 'Manage physical location details' : 'Physical location is required';
    result.groupResult[physicalLocationDetails] = x;
    
    // Documents
    r = this.validateSyncGroup(documents);
    x = { groupPass: null, message: '' };
    x.groupPass = Object.keys(r).length === 0;
    x.message = x.groupPass ? 'Manage Required Documents' : 'Documents information is required';
    result.groupResult[documents] = x;

    // r = this.validateSyncGroup(plating);
    // x = { groupPass: null, message: '' };
    // x.groupPass = Object.keys(r).length === 0;
    // x.message = (x.groupPass) ? 'Manage Vehicle Plating Details' : 'Vehicle Plating information is required';
    // result.groupResult[plating] = x;

    r = this.validateSyncGroup(plating);
    x = { groupPass: null, message: '' };
    x.groupPass = Object.keys(r).length === 0;
    x.message = x.groupPass ? 'Manage Vehicle Plating Details' : 'Vehicle Plating information is required';
    result.groupResult[plating] = x;

    r = this.validateSyncGroup(misc);
    x = { groupPass: null, message: '' };
    x.groupPass = Object.keys(r).length === 0;
    x.message = x.groupPass ? 'Manage Other Vehicle Details' : 'Vehicle Other information is required';
    result.groupResult[misc] = x;

    // is it passed all the tests? (True means it failed here)
    result.pass = !Object.keys(result.groupResult).some(k => !result.groupResult[k].groupPass);
    // passed.
    if (!result.pass) {
      if (this.dbStatus === DbStatus.Initial || this.dbStatus === DbStatus.ReleasedMod) {
        result.message = 'Admin approval is pending!';
      }
    } else {
      result.message = 'More information is required!';
    }
    return result;
  }
  /** set to false until trucks are ready for rental, criteria to be determined */
  get isRentable(): boolean {
    if (this.asIs) {
      return true;
    } else if(this.dbStatus === DbStatus.Released && isAfter(this.safetyExpiryDate, new Date())) {
      return true;
    } else {
      return false;
    }
  }

}
