import { Injectable } from '@angular/core';
import { IdeviceIdService } from './idevice-id.service';
import { IDeviceIdComponents } from '@trent/models/fcm/fcm-token-data';
import { UtilityService } from '../utility.service';
import { promiseWraper } from '@trent/models/utility';
import { LocalStorage } from '../local-storage/local-storage.service';
import { addDays } from 'date-fns';
import { DeviceIdCoreService } from './device-id-core.service';
import { ScriptService } from '../base/script.service';
import { logger } from '@trentm/log/logger';
import { SingletonService } from '../singleton.service';
import * as firebase from 'firebase/app';
import { IPwaStatus } from '@trent/models/user-agreement';

declare const Fingerprint2;

/** Do not inject this service in any component. Use @class IdeviceIdService instead.
 * Or better yet, use the @class SingletonService's public property. This this class is singlton also but
 * it is not imported at the app level to reduce the laods. Singlton class has the only copy in it.
 */
@Injectable()
export class DeviceIdWebService extends DeviceIdCoreService implements IdeviceIdService {
  private _deviceId: IDeviceIdComponents;

  async platformInfo(): Promise<string> {
    return this.getPlatformInfo(await this.getDeviceId(), 'web');
  }

  private get rtdb() { return this.sa.firebaseRTDBService; }

  constructor(private utlService: UtilityService, private localStorage: LocalStorage, private scriptService: ScriptService, private sa: SingletonService) {
    super();
  }

  async getDeviceId(): Promise<IDeviceIdComponents> {
    if (!this.utlService.isPlatformBrowser) {
      logger.error('Device ID was called on non-browser platform!!');
      const d: IDeviceIdComponents = {
        deviceId: '---xxxxx---',
        components: [],
        updatedOn: null
      };
      return d;
    }
    if (!!this._deviceId) {
      return this._deviceId;
    }
    // tslint:disable-next-line:max-line-length
    const cacheData = await promiseWraper<IDeviceIdComponents>(this.localStorage.get(DeviceIdCoreService.deviceFingerPrintKey));
    if (cacheData.success && !!cacheData.data && typeof cacheData.data.deviceId === 'string') {
      cacheData.data.updatedOn = new Date(cacheData.data.updatedOn);
      const lastweek = addDays(new Date(), -7);
      if (cacheData.data.updatedOn > lastweek) {
        logger.log('cache finger print of brwser was found');
        this._deviceId = cacheData.data;
        return cacheData.data;
      }
    }

    // not found, create one for web.
    // tslint:disable-next-line:max-line-length
    const fingerPrint = await promiseWraper(this.scriptService.loadScript('https://cdnjs.cloudflare.com/ajax/libs/fingerprintjs2/2.1.0/fingerprint2.min.js'));
    if (fingerPrint.success && fingerPrint.data.status === 'loaded') {
      const res = await promiseWraper<Array<any>>(Fingerprint2.getPromise({}));
      if (res.success && !!Fingerprint2) {
        const components = res.data;
        const values = components.map(c => c.value);
        const murmur = Fingerprint2.x64hash128(values.join(''), 31);
        logger.log('Finger print data');
        logger.log('comp: ', components);
        logger.log('values: ', values);
        logger.log('murmur: ', murmur);
        // save for future.
        const deviceInfo: IDeviceIdComponents = {
          deviceId: murmur,
          components,
          updatedOn: new Date()
        };
        this.localStorage.set(DeviceIdCoreService.deviceFingerPrintKey, deviceInfo).catch(err => {
          logger.error('could not save fcm token in local storage', err);
        });
        this._deviceId = deviceInfo;
        return deviceInfo;
      }
    }
  }

/**
 * Save pwa app install status 
 * @param deviceId 
 * @param isPWAInstalled 
 * @param uid 
 * @returns 
 */
  public setDevicePWAStatus(deviceId: string, data: IPwaStatus): Promise<void> {
    return this.rtdb.create(`pwa-status/${deviceId}`, data);
  }

  /**
   * Update pwa app install status 
   * @param deviceId 
   * @param data 
   * @returns 
   */
  public updateDevicePWAStatus(deviceId: string, data: IPwaStatus): Promise<void> {
    return this.rtdb.update(`pwa-status/${deviceId}`, data);
  }

  /**
   * Get pwa app install status from db
   * @param deviceId 
   * @returns 
   */
  public getDevicePWAStatus(deviceId: string) {
    return this.rtdb.obj$<IPwaStatus>(`pwa-status/${deviceId}`);
  }

   /**
   * save pwa app install status(Prompt Disabled) 
   * @param deviceId 
   * @returns 
   */
  public setPwaInstallationPromptDisabled(deviceId: string, isPwaInstallationPromptDisabled: boolean, uid?: string): Promise<void> {
    let data : { isPwaInstallationPromptDisabled : boolean, uid? : string} = { isPwaInstallationPromptDisabled : isPwaInstallationPromptDisabled};
    if(uid){
      data.uid =  uid;
    }
    return this.rtdb.create(`pwa-status/${deviceId}`, data);
  }

}
