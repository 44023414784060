<h4>{{data.title}}</h4>
<p>By continuing to browse or by clicking 'Accept', you agree to the storing of cookies on your device to enhance your site experience and for analytical purpose. To learn about how we use the cookies, please see our 
  <a href="javascript:void(0)" (click)="openPolicy('cookie-policy')" >cookies policy</a>
</p>
  <button mat-flat-button color="primary" (click)="saveCPolicy()" type="button" class="md-18 right">
    <mat-icon>done</mat-icon> Accept and Close
  </button>

<!-- <div>
  <div mat-dialog-title>
    <span class="closebtn" (click)="dialogRef.close()">&times;</span>
    {{ header }}
  </div>



  <div mat-dialog-content *ngIf="showPolicy && page">
    <trent-ck-editor-ctrl [page] = 'page'></trent-ck-editor-ctrl>      
  </div>
  <section class="checkbox-section">
    <mat-checkbox class="example-margin" [(ngModel)]="agreePPolicy" >
      I agree to LocusLoop's <ng-container *ngIf="showPolicy"><br>{{agreementsText()}}</ng-container>
      
    </mat-checkbox>
    <br><a href="" type="button" (click)="setShowPolicy()" *ngIf="!showPolicy" class="agreement-anchor">&nbsp;{{agreementsText()}}</a>
  </section>

  <div mat-dialog-actions>
    <button mat-stroked-button (click)="dialogRef.close(false)" type="button" class="md-18 right">
      <mat-icon>close</mat-icon> Cancel
    </button>
    <button mat-button (click)="dialogRef.close(true)" type="button" color="primary" class="mat-raised-button md-18 right" [disabled]="!agreePPolicy">
      <mat-icon>done</mat-icon> OK
    </button>
  </div>
</div> -->