import { InspectionValidationGroup, interior, tiresSteer, tiresDrive, InspectionData, InspectionResponse } from './inspection-data';
import { IncidentalCharges } from './Incidental-charges';
import { Exclude, Expose, instanceToInstance, plainToInstance, Type } from 'class-transformer';
// tslint:disable-next-line:max-line-length
import { Min, IsInt, Max, IsNumber, IsEnum, IsArray, IsBoolean, MinLength, MaxLength, IsDefined, ValidateNested, ArrayNotEmpty, ValidateIf, Equals } from 'class-validator';
import { MileageUnit } from '../product/mileage-unit';
import { Inspection } from './inspection';
import { InspectionStatus } from './inspection-status';
import { IValDbStatusResult, IValidationMsg } from '../error-handling';
import { Picture } from './picture';
import { decimalToFraction } from '../utility/math-helper';
import { TireInspection } from './tire-inspection';
import { Truck } from '../product/truck';
import { sanitizeDateIPoint } from '../utility';
import { IInspectionPersistentData, ITruckTireData } from './i-inpection-presistent-data';
import { ProductType } from '../product/product-type';
import { UserProfile } from '../user/user-profile';
import { checkPicturesDeleted, hasPicArrayChanged } from '../utility/compare-pics-utl';

export type FaultLights = 'checkEngine' | 'defLight' | 'xyzLight';

@Exclude()
export class InspectionDataTruck extends InspectionData {


  constructor() {
    super();
    this.pictures = [];
    this.location = { geopoint: { latitude: null, longitude: null }, geohash: null };
    this.location.geopoint = { latitude: null, longitude: null };
    this.isIncidentalCharges = true;
    // if (!this.incidentalCharges) {
    //   this.incidentalCharges = new IncidentalCharges();
    // }
  }

  @Expose()
  vehType = 'truck';

  @Expose()
  @IsInt({ message: 'Enter odometer', groups: [interior] })
  @Min(100)
  odometer: number;

  @Expose()
  @IsEnum(ProductType)
  productType = ProductType.truck;



  @Expose()
  @ValidateIf(o => !!o.showVerify, { message: 'Verify Odometer', groups: [interior] })
  @Equals(true, { message: 'Verify Odometer', groups: [interior] })
  isOdometerVerified: boolean;


  @Expose()
  @IsEnum(MileageUnit, { message: 'Select milage units', groups: [interior] })
  mileageUnit: MileageUnit = MileageUnit.km;


  @Expose()
  @Min(0.125, { message: 'Invalid fuel level', groups: [interior] })
  @Max(1, { message: 'Invalid fuel level', groups: [interior] })
  @IsNumber(undefined, { message: 'Invalid fuel level', groups: [interior] })
  fuelLevel = 0;

  @Expose()
  @ValidateIf(o => !!o.showVerify, { message: 'Verify Fuel Level', groups: [interior] })
  @Equals(true, { message: 'Verify Fuel Level', groups: [interior] })
  isFuelLevelVerified: boolean;

  @Expose()
  @Min(0.125, { message: 'Invalid DEF level', groups: [interior] })
  @Max(1, { message: 'Invalid DEF level', groups: [interior] })
  @IsNumber(undefined, { message: 'Invalid DEF level', groups: [interior] })
  defLevel = 0;

  @Expose()
  @ValidateIf(o => !!o.showVerify, { message: 'Verify DEF Level', groups: [interior] })
  @Equals(true, { message: 'Verify DEF Level', groups: [interior] })
  isDefLevelVerified: boolean;


  @Expose()
  @IsBoolean()
  isCheckEngine = false;

  @Expose()
  @ValidateIf(o => !!o.showVerify, { message: 'Verify Check Engine Light', groups: [interior] })
  @Equals(true, { message: 'Verify Check Engine Light', groups: [interior] })
  isCheckEngineVerified: boolean;



  @Expose()
  @IsBoolean()
  isTransmissionWarning = false;

  @Expose()
  @ValidateIf(o => !!o.showVerify, { message: 'Verify Transmission Light', groups: [interior] })
  @Equals(true, { message: 'Verify Transmission Light', groups: [interior] })
  isTransmissionWarningVerified: boolean;


  @Expose()
  @IsArray({ message: 'Upload Pictures', groups: [interior] })
  @ArrayNotEmpty({ message: 'Upload Pictures', groups: [interior] })
  @Type(() => Picture)
  interiorPictures: Picture[] = [];

  @Expose()
  @ValidateIf(o => !!o.innerInspectionComments)
  @MaxLength(200)
  innerInspectionComments: string;

  get fuelFraction() {

    return this.fuelLevel === 1 ? 'Full Tank' : this.fuelLevel === 0 ? 'Empty Tank' : `${decimalToFraction(this.fuelLevel)} of the Tank`;
  }
  get defFraction() {
    return this.defLevel === 1 ? 'Full Tank' : this.defLevel === 0 ? 'Empty Tank' : `${decimalToFraction(this.defLevel)} of the Tank`;
  }
  // tire inspection

  @Expose()
  @ValidateNested({ message: 'Add Tire Details', groups: [tiresSteer] })
  @Type(() => TireInspection)
  @IsDefined({ message: 'Add Tire Details', groups: [tiresSteer] })
  steerDriver: TireInspection = new TireInspection();

  @Expose()
  @ValidateNested({ message: 'Add Tire Details', groups: [tiresSteer] })
  @Type(() => TireInspection)
  @IsDefined({ message: 'Add Tire Details', groups: [tiresSteer] })
  steerPassenger: TireInspection = new TireInspection();

  @Expose()
  @ValidateNested({ message: 'Add Tire Details', groups: [tiresDrive] })
  @Type(() => TireInspection)
  @IsDefined({ message: 'Add Tire Details', groups: [tiresDrive] })
  driveFrontOuterDriver: TireInspection = new TireInspection();

  @Expose()
  @ValidateNested({ message: 'Add Tire Details', groups: [tiresDrive] })
  @Type(() => TireInspection)
  @IsDefined({ message: 'Add Tire Details', groups: [tiresDrive] })
  driveFrontInnerDriver: TireInspection = new TireInspection();

  @Expose()
  @ValidateNested({ message: 'Add Tire Details', groups: [tiresDrive] })
  @Type(() => TireInspection)
  @IsDefined({ message: 'Add Tire Details', groups: [tiresDrive] })
  driveRearOuterDriver: TireInspection = new TireInspection();

  @Expose()
  @ValidateNested({ message: 'Add Tire Details', groups: [tiresDrive] })
  @Type(() => TireInspection)
  @IsDefined({ message: 'Add Tire Details', groups: [tiresDrive] })
  driveRearInnerDriver: TireInspection = new TireInspection();

  @Expose()
  @ValidateNested({ message: 'Add Tire Details', groups: [tiresDrive] })
  @Type(() => TireInspection)
  @IsDefined({ message: 'Add Tire Details', groups: [tiresDrive] })
  driveFrontOuterPassenger: TireInspection = new TireInspection();

  @Expose()
  @ValidateNested({ message: 'Add Tire Details', groups: [tiresDrive] })
  @Type(() => TireInspection)
  @IsDefined({ message: 'Add Tire Details', groups: [tiresDrive] })
  driveFrontInnerPassenger: TireInspection = new TireInspection();

  @Expose()
  @ValidateNested({ message: 'Add Tire Details', groups: [tiresDrive] })
  @Type(() => TireInspection)
  @IsDefined({ message: 'Add Tire Details', groups: [tiresDrive] })
  driveRearOuterPassenger: TireInspection = new TireInspection();

  @Expose()
  @ValidateNested({ message: 'Add Tire Details', groups: [tiresDrive] })
  @Type(() => TireInspection)
  @IsDefined({ message: 'Add Tire Details', groups: [tiresDrive] })
  driveRearInnerPassenger: TireInspection = new TireInspection();

  get tires() {

    const t = [this.steerDriver, this.steerPassenger,
    this.driveFrontInnerDriver, this.driveFrontInnerPassenger, this.driveFrontOuterDriver, this.driveFrontOuterPassenger,
    this.driveRearInnerDriver, this.driveRearInnerPassenger, this.driveRearOuterDriver, this.driveRearOuterPassenger
    ];
    if (t.findIndex(f => typeof f === 'undefined') > -1) {
      return [];
    }
    return t;
  }
  get steerTires() {

    const t = [this.steerDriver, this.steerPassenger];
    if (t.findIndex(f => typeof f === 'undefined') > -1) {
      return [];
    }
    return t;
  }
  get driveTires() {

    const t = [
      this.driveFrontInnerDriver, this.driveFrontInnerPassenger, this.driveFrontOuterDriver, this.driveFrontOuterPassenger,
      this.driveRearInnerDriver, this.driveRearInnerPassenger, this.driveRearOuterDriver, this.driveRearOuterPassenger
    ];
    if (t.findIndex(f => typeof f === 'undefined') > -1) {
      return [];
    }
    return t;
  }

  get driveTiresDSide() {

    const t = [
      this.driveFrontInnerDriver, this.driveFrontOuterDriver,
      this.driveRearInnerDriver, this.driveRearOuterDriver
    ];
    if (t.findIndex(f => typeof f === 'undefined') > -1) {
      return [];
    }
    return t;
  }
  get driveTiresPSide() {

    const t = [
      this.driveFrontInnerPassenger, this.driveFrontOuterPassenger,
      this.driveRearInnerPassenger, this.driveRearOuterPassenger
    ];
    if (t.findIndex(f => typeof f === 'undefined') > -1) {
      return [];
    }
    return t;
  }
  get checkAllSteer() {
    if (this.steerTires.findIndex(f => !f.isMakeVerified || !f.isDateOfManufacturingVerified) > -1) {
      return false;
    } else {
      return true;
    }
  }
  get checkAllDrive() {

    if (this.driveTires.findIndex(f => !f.isMakeVerified || !f.isDateOfManufacturingVerified) > -1) {
      return false;
    } else {
      return true;
    }
  }
  get checkAllDriveD() {

    if (this.driveTiresDSide.findIndex(f => !f.isMakeVerified || !f.isDateOfManufacturingVerified) > -1) {
      return false;
    } else {
      return true;
    }
  }
  get checkAllDriveP() {

    if (this.driveTiresPSide.findIndex(f => !f.isMakeVerified || !f.isDateOfManufacturingVerified) > -1) {
      return false;
    } else {
      return true;
    }
  }

  public static parse(obj) {
    if (obj == null) { return null; }

    if (obj instanceof (InspectionDataTruck)) { return <InspectionDataTruck>obj; }

    const m = plainToInstance<InspectionDataTruck, any>(InspectionDataTruck, sanitizeDateIPoint(obj));
    m.sanitize();
    m.tires?.forEach(element => {
      element.sanitize();
      if (m.inspectionType === InspectionStatus.vendorPicked || m.inspectionType === InspectionStatus.customerDropped) {
        element.isReturn = true;
      }
    });
    return m;
  }


  // getCanChargeFuel(pickDrop: Inspection): boolean {
  //   if (!!this.incidentalCharges) {
  //     if (this.fuelLevel > 0 && this.fuelLevel < (<InspectionDataTruck>pickDrop.vendorDrop).fuelLevel
  //       && pickDrop.status >= InspectionStatus.rentStartHandShake
  //       && pickDrop.status < InspectionStatus.finalHandShake) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   } else {
  //     return null;
  //   }
  // }
  // getCanChargeDef(pickDrop: Inspection): boolean {
  //   if (!!this.incidentalCharges) {
  //     if (this.defLevel > 0 && this.defLevel < (<InspectionDataTruck>pickDrop.vendorDrop).defLevel
  //       && pickDrop.status >= InspectionStatus.rentStartHandShake &&
  //       pickDrop.status < InspectionStatus.finalHandShake) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   } else {
  //     return null;
  //   }
  // }
  sanitize() {
    // super.sanitize();
  }
  clone() {
    const t = instanceToInstance(this);
    t.sanitize();
    return t;
  }
  
  validateSyncGroup(group: InspectionValidationGroup, pickDrop: Inspection, action: InspectionStatus): IValidationMsg {
    return this.validateSync({ groups: [group], pickDrop, action });
  }

  validateSync(options?: any, pickDrop?: Inspection, action?: InspectionStatus): IValidationMsg {
    const r = super.validateSync(options, pickDrop, action);
    return r;
  }

  getValDbStatus(inspection: Inspection, action: InspectionStatus): IValDbStatusResult {
    // const result: IValDbStatusResult = {
    //   pass: false,
    //   message: undefined,
    //   groupResult: {}
    // };
    let x: { groupPass: boolean; message: string; };

    // unitDetails
    // let r = this.validateSyncGroup(unitDetails, inspection, action);
    // x = { groupPass: null, message: '' };
    // x.groupPass = Object.keys(r).length === 0;
    // x.message = (x.groupPass) ? 'Unit Details' : 'Unit information is required'; //  `${Object.values(r)[0]}`
    // result.groupResult[unitDetails] = x;

    // Int inspection
    const result = super.getValDbStatus(inspection, action);

    let r = this.validateSyncGroup(interior, inspection, action);
    x = { groupPass: null, message: '' };

    // interior pictures
    // const xIntPics = [];

    // for (const p of this.interiorPictures) {
    //   const rS = p.validateSync();
    //   if (Object.keys(rS).length > 0) {
    //     xIntPics.push(rS);
    //   }
    // }

    x.groupPass = Object.keys(r).length === 0;
    x.message = (x.groupPass) ? 'Interior Inspection' : 'Interior inspection is required';
    result.groupResult[interior] = x;
    // steer tires
    r = this.validateSyncGroup(tiresSteer, inspection, action);
    x = { groupPass: null, message: '' };
    x.groupPass = Object.keys(r).length === 0;
    x.message = (x.groupPass) ? 'Steer Tire Inspection' : 'Steer Tire inspection is required';
    result.groupResult[tiresSteer] = x;
    // drive tires
    r = this.validateSyncGroup(tiresDrive, inspection, action);
    x = { groupPass: null, message: '' };
    x.groupPass = Object.keys(r).length === 0;
    x.message = (x.groupPass) ? 'Drive Tire Inspection' : 'Drive Tire inspection is required';
    result.groupResult[tiresDrive] = x;





    // is it passed all the tests? (True means it failed here)
    result.pass = !Object.keys(result.groupResult).some((k) => !result.groupResult[k].groupPass);
    // passed.

    return result;
  }

  setTruckTireData(product: Truck) {
    this.steerDriver = (product?.inspectionPersistentData?.tireData as ITruckTireData)?.steerDriver;
    this.steerPassenger = (product?.inspectionPersistentData?.tireData as ITruckTireData)?.steerPassenger;
    this.driveFrontOuterDriver = (product?.inspectionPersistentData?.tireData as ITruckTireData)?.driveFrontOuterDriver;
    this.driveFrontInnerDriver = (product?.inspectionPersistentData?.tireData as ITruckTireData)?.driveFrontInnerDriver;
    this.driveRearOuterDriver = (product?.inspectionPersistentData?.tireData as ITruckTireData)?.driveRearOuterDriver;
    this.driveRearInnerDriver = (product?.inspectionPersistentData?.tireData as ITruckTireData)?.driveRearInnerDriver;
    this.driveFrontOuterPassenger = (product?.inspectionPersistentData?.tireData as ITruckTireData)?.driveFrontOuterPassenger;
    this.driveFrontInnerPassenger = (product?.inspectionPersistentData?.tireData as ITruckTireData)?.driveFrontInnerPassenger;
    this.driveRearOuterPassenger = (product?.inspectionPersistentData?.tireData as ITruckTireData)?.driveRearOuterPassenger;
    this.driveRearInnerPassenger = (product?.inspectionPersistentData?.tireData as ITruckTireData)?.driveRearInnerPassenger;

  }
  /** reset properties which are expected to change during return */
  resetPropsForReturn() {
    this.isVinVerified = false;
    this.isPlateNoVerified = false;
    this.defLevel = 0;
    this.fuelLevel = 0;
    this.odometer = undefined;
    this.pictures = [];
    this.interiorPictures = [];
    this.videos = [];
    this.tires.forEach(f => {
      f.showVerify = true;
      f.isMakeVerified = false;
      f.isDateOfManufacturingVerified = false;
    });
    this.exteriorInspectionComments = undefined;
    this.innerInspectionComments = undefined;
    

  }
   /** reset properties if customer dropped with return inspection  */
   resetPropsForReturnWhenReturnExists() {
  this.isVinVerified = false;
  this.isPlateNoVerified = false;
  this.tires.forEach(f => {
    f.showVerify = true;
    f.isMakeVerified = false;
    f.isDateOfManufacturingVerified = false;
  });

}
  getPersistentData(): IInspectionPersistentData {
    const d: IInspectionPersistentData = {};
    
    d.tireData = {
      steerDriver: this.steerDriver,
      steerPassenger: this.steerPassenger,
      driveFrontOuterDriver: this.driveFrontOuterDriver,
      driveFrontInnerDriver: this.driveFrontInnerDriver,
      driveRearOuterDriver: this.driveRearOuterDriver,
      driveRearInnerDriver: this.driveRearInnerDriver,
      driveFrontOuterPassenger: this.driveFrontOuterPassenger,
      driveFrontInnerPassenger: this.driveFrontInnerPassenger,
      driveRearOuterPassenger: this.driveRearOuterPassenger,
      driveRearInnerPassenger: this.driveRearInnerPassenger
    } as ITruckTireData;
    for (const key in d.tireData) {
      if (Object.prototype.hasOwnProperty.call(d.tireData, key)) {
        const element = TireInspection.parse(d.tireData[key]);
        element.resetVerification();
      }
    }
    return d;
  }
  setPropertiesToReview() {
    super.setPropertiesToReview();
    this.showVerify = true;
    this.tires.forEach(f => f.showVerify = true);

  }
  // interiorCommentsForReview(d: InspectionDataTruck) {
  //   // if (inspection.status >= InspectionStatus.rentStartHandShake || inspection.canMakePdiDecision(user)) {
  //   //   return inspection.combinedPdiIntComments;
  //   // } else {
  //   //   return null;
  //   // }

  // }
  /**
   * Setup Inspection response based on action and reference inspection
   */
  setupInspectionResponseTruck(action: InspectionStatus, inspection: Inspection) {
    if (inspection.productSummary.productType !== ProductType.truck) {
      throw new Error('incorrect fn called for product is not truck');
    }
    let ref: InspectionData;
    switch (action) {
      case InspectionStatus.vendorDropped:
        this.inspectionResponse = InspectionResponse.initialize;
        break;
      case InspectionStatus.customerPicked:
        ref = inspection.vendorDrop;
        if (!ref) {
          throw new Error('incorrect fn called for product is not truck');
        } else {
          if (this.hasTruckInspectionChanged(ref)) {
            this.inspectionResponse = InspectionResponse.update;
          } else {
            this.inspectionResponse = InspectionResponse.accept;
          }
        }
        break;
      case InspectionStatus.customerDropped:
        this.inspectionResponse = InspectionResponse.initialize;
        break;
      case InspectionStatus.vendorPicked:
        ref = inspection.customerDrop;
        if (!ref) {
          this.inspectionResponse = InspectionResponse.accept;
        } else {
          if (this.hasTruckInspectionChanged(ref)) {
            this.inspectionResponse = InspectionResponse.update;
          } else {
            this.inspectionResponse = InspectionResponse.accept;
          }
        }
        break;

      default:
        throw new Error('error calling setupInspectionResponseTruck');


    }
  }
  /** returns true if interior inspection comments have changed */
  hasIntCommentsChange(ref: InspectionDataTruck) {
    return ref.exteriorInspectionComments === this.exteriorInspectionComments;
  }
  /** return true if interior pictures changed */
  hasIntPicChanged(ref: InspectionData) {
    return hasPicArrayChanged(ref.pictures, this.pictures);
  }
  hasTruckInspectionChanged(ref: InspectionData) {
    if (this.hasExtCommentsChanged(ref) || this.hasExtPicChanged(ref) ||
      this.hasIntCommentsChange(ref as InspectionDataTruck) ||
      this.hasIntPicChanged(ref as InspectionDataTruck)
    ) {
      return true;
    } else {
      return false;
    }
  }
  hasIntPicDeleted(ref: InspectionDataTruck) {

    
    return checkPicturesDeleted(ref.interiorPictures, this.interiorPictures);
  }
}
