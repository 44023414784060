import { validateSync, ValidatorOptions, Length} from 'class-validator';
import { toValidationError } from '../utility';
import { IValidationMsg } from '../error-handling/validation-info';
import { instanceToInstance } from 'class-transformer';

/** Registration model used during custum registration of email and password. */
export class UserPhone {

  // @IsPhoneNumber('ZZ', { message: 'A valid phone no with area code is required' , groups: ['phone']})
  // currPhoneNumber: string | null;


  // @IsPhoneNumber('ZZ', { message: 'A valid phone no with area code is required', groups: ['phone'] })
  phoneNumber: string | null;

  @Length(6, 7, { message: 'Phone Code need to be 6 digits long!', groups: ['code'] })
  phoneCode: string;


  public clone(): UserPhone {
    const u = instanceToInstance<UserPhone>(this);
    // u.sanitize();
    return u;
  }

  validateSync(options: ValidatorOptions): IValidationMsg {
    options.forbidUnknownValues = false;
    const r = validateSync(this, options);
    const m = toValidationError(r);
    return m;
  }

}
