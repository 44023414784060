import { AuthService } from './auth/auth.service';
import { IcloudMessageService } from '@trent/services/fcm/icloud-message.service';
import { UserService } from '@trent/services/user.service';
import { Injectable, Type } from '@angular/core';
import { IgeoLocationService } from './geo-loc/igeo-location.service';
import { IdeviceIdService } from './device/idevice-id.service';
import { FirebaseRTDBService } from './firebase-rtdb.service';

// type serviceKey = 'UserService' | 'IcloudMessageService' | 'AuthService';

/**
 * To reduce the size of the root load, some singlton services are lady loaded in the app component.
 * They are filled here on load and all the customer of those lazy loaded services should use this singlton
 * service to get the copy.
 * Note:
 * - Do not use dependency injection for the services storeed in this class as that would create
 *   duplicates.
 * - App.component lady load the core lazy module
 * - l This class is filed by the lazy load service in the app.component => loazy-loader service.
 */
@Injectable({ providedIn: 'root' })
export class SingletonService {

  userService: UserService;

  cloudMsgService: IcloudMessageService;

  authService: AuthService;

  geoLocService: IgeoLocationService;

  deviceIdService: IdeviceIdService;

  firebaseRTDBService: FirebaseRTDBService;

  // private container: { [key: string]: any } = {};

  // setService<T>(key: serviceKey, service: T) {
  //   this.container[key] = service;
  // }

  // getService<T>(key: serviceKey): T {
  //   if (!!this.container[key]) {
  //     return this.container[key] as T;
  //   } else {
  //     return null;
  //   }

  // }

  constructor() {}
}
