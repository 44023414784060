import { PagingObesrvable } from './paging-obesrvable';
import { Subscription } from 'rxjs';

export interface Paging {
  size: number;
  offset: number | string | Date;
  full: boolean;
  lastDoc?: any;
  startAt?: any;
  endBefore?: any;
  currentPage?:number;
  prevClicked?: boolean;
  nextClicked?: boolean;
  count?: number;
  orderBy?: string;
  createdAt?: Date;
  maxPageNo?: number;
  maxPageReached?: number;
}

export type OrderByDirection = 'desc' | 'asc';

export interface IKeyVal {
  key: string;
  value: string;
  // direction?: OrderByDirection;
}

/** Get a key representing an object by converting it to a json string as key. */
export const getObjKey = (o: object): string => {
  if (o == null) {
    return null;
  }
  return JSON.stringify(o);
};


export const getDirection = (a: IKeyVal[], val: string): { col: string, dir: OrderByDirection } => {
  /** keep a space before text so that order is distinct. */
  if (val.indexOf(' desc') > -1) {
    return {
      col: val.replace('desc', '').trim(),
      dir: 'desc'
    };
  }
  return {
    col: val.replace(' asc', '').trim(),
    dir: 'asc'
  };
};

/** Object to be stored inside the class instance of the store (not in the ngxs store)
 * to keep a track of all the paging observable subscribers.
 */
export interface IPagingObservable<T, TParam> {

  /** Parent param Key */
  key: string;

  /** Child param key */
  cKey: string;

  /** param. can be from child or from parent. */
  param?: TParam;

  /** paging data */
  // pData: Paging;

  /** Paging observable */
  p?: PagingObesrvable<T, TParam>;

  /** subscription */
  sub?: Subscription;
}

/** Object to be stored inside the class instance of the store (not in the ngxs store)
 * to keep a track of all the paging observable subscribers.
 */
export interface ISubscriptionNode<T, TParam> {
  sub: Subscription;
  p: PagingObesrvable<T, TParam>;
}


