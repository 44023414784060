<div [class]="className">
  <div mat-dialog-title>
    <span class="closebtn" (click)="dialogRef.close()">&times;</span>
    {{ data.data.header }}
  </div>
  <!-- <h1 mat-dialog-title>
    {{data.header}}
    <mat-icon>close</mat-icon>
  </h1> -->
  <div mat-dialog-content class="p16">
    <p>{{ data.data.description }}</p>
    <ul *ngFor="let item of list">
      <li *ngIf="isValidUrl(item)">
        <a [href]="item" target="_blank">{{ item }}</a>
      </li>
      <li *ngIf="!isValidUrl(item)">
        {{ item }}
      </li>
    </ul>
  </div>
  <div mat-dialog-actions>
    <button mat-stroked-button *ngIf="isConfirmBox" (click)="dialogRef.close(false)" type="button" [color]="className" class="md-18 right">
      <mat-icon>close</mat-icon> Cancel
    </button>
    <button mat-raised-button *ngIf="isConfirmBox" (click)="dialogRef.close(true)" type="button" [color]="className" class="md-18 right">
      <mat-icon>done</mat-icon> OK
    </button>
  </div>
</div>
