import { PromoListBase } from './promo-list-base';
import { PromoListType } from './promo-list-type';
import { CarrierList, CarrierListItem } from './carrier-list';
import { BorderList } from './border-list';
import { DriverList, DriverListItem } from './driver-list';
import { TripTrackerList, TripTrackerListItem } from './trip-tracker-list';
// import { VehicleList, VehicleListItem } from './promo-list';
import { VehicleList, VehicleListItem } from '../../models/promo/promo-vehicle';
import { PromoListItem } from './promo-list-item';
import { pickBy, isEmpty, isEqual, remove } from 'lodash';
import { CarrierCompanySummary, CompanyCarrier } from '../company/company-carrier';
import { UserProfile } from '../user/user-profile';
import { IValidationMsg } from '../error-handling';
import { validateSync, ValidationError, Validator, maxLength, isString, isArray } from 'class-validator';
import { toValidationError } from '../utility/validation-helper';
import { logger } from '../log/logger';
import { ProductType } from '../product/product-type';
import { ProductBase } from '../product/product-base';
import { Vehicle } from '../product/vehicle';
import { RentalTrailer } from '../rental/rental-trailer';
import { RentalTruck } from '../rental/rental-truck';
import { RentalTrailerAggregate, RentalTrailerItem } from './rental-trailer-aggregate';
import { MapLabel } from '../map/map-label';
import { getCurrencyString, getRentString } from '../rental/rental-helper';
import { VendorPortfolio } from './vendor-portfolio';
import { DbRule } from '../base/db-rule';
import { IPoint } from '../product-search/interfaces';
import { shortDateTime } from '../utility/timer-helper';
import { RentalVehicalListItem } from './rental-vehical-list-item';
import { ITelematics } from '../product/i-telematics';
import { SalesProductBase } from '../sales-option';
import { SalesTruckAggregate } from './sales-truck-aggregate';

import { RentalProductBase } from '@trent/models/rental/rental-product-base';
import { SubsidiaryList } from '../netSuite/subsidiary';
import { addDays } from 'date-fns';
export type ListItem = TripTrackerListItem | VehicleListItem | DriverListItem | CarrierListItem | RentalTrailerItem;
// | BorderListItem;

export const parsePromoList = (obj: any): PromoListBase => {
  if (obj == null) { return null; }
  const p = <PromoListBase>obj; // cast it to base, remember it is still a javascript obj at run time.

  switch (p.listType) {
    case PromoListType.carrier:
      return CarrierList.parse(p);
    case PromoListType.border:
      return BorderList.parse(p);
    case PromoListType.promoDriver:
      return DriverList.parse(p);
    case PromoListType.promoFreightCustomer:
      return TripTrackerList.parse(p);
    case PromoListType.promoTruck:
    case PromoListType.promoTrailer:
      return VehicleList.parse(p);
    case PromoListType.rentalTrailer:
      return RentalTrailerAggregate.parse(p);
    case PromoListType.rentalTruck:
      return RentalTrailerAggregate.parse(p);
    case PromoListType.vendorPortfolio:
      return VendorPortfolio.parse(p);
    case PromoListType.salesTruck:
      return SalesTruckAggregate.parse(p);
    case PromoListType.salesTrailer:
      return SalesTruckAggregate.parse(p);

    case PromoListType.subsidiary:
        return SubsidiaryList.parse(p);
    default:
      throw new Error(`Invalid List type was provided. Type: ${p.listType} is invalid.`);
  }
};
export const parsePromoListArray = (obj: any[]): PromoListBase[] => {
  const r = !!obj ? obj.map(o => <PromoListBase>parsePromoList(o)) : null;
  return r;
};
export const createPromoList = (obj: any, cid: number | string, promoListType: PromoListType, carrierCSummary?: CarrierCompanySummary,
  userProfile?: UserProfile) => {
  const p: { [key: string]: {} } = obj; // cast it to base, remember it is still a javascript obj at run time.
  let l: PromoListBase;
  switch (promoListType) {
    case PromoListType.carrier:
      // l = CarrierList.createPromoList(<{ [key: string]: CarrierListItem }>p, cid);
      break;
    case PromoListType.border:
      break;
    case PromoListType.promoDriver:
      l = DriverList.createPromoList(cid, carrierCSummary, userProfile, <{ [key: string]: DriverListItem }>p);
      break;
    case PromoListType.promoFreightCustomer:
      l = TripTrackerList.createPromoList(cid, carrierCSummary, userProfile, <{ [key: string]: TripTrackerListItem }>p);
      break;
    case PromoListType.promoTruck:
    case PromoListType.promoTrailer:
      l = VehicleList.createPromoList(cid, promoListType, carrierCSummary, <{ [key: string]: VehicleListItem }>p);
      break;
    default:
      throw new Error(`Invalid List type was provided. Type: ${p.listType} is invalid.`);
  }
  return l;
};
export const updatePromoList = (uEntry: { [key: string]: ListItem }, oPList: PromoListBase, isNewEntry?: boolean,
  oUserProfile?: UserProfile) => {
  const p: { [key: string]: {} } = uEntry; // cast it to base, remember it is still a javascript obj at run time.
  let l: PromoListBase;
  switch (oPList.listType) {
    case PromoListType.carrier:
      // l = CarrierList.updatePromoList(<{ [key: string]: CarrierListItem }>p, <CarrierList>pList);
      break;
    case PromoListType.border:
      break;
    case PromoListType.promoDriver:
      if (isNewEntry) {
        l = (<DriverList>oPList).updateListToAddEntry(<{ [key: string]: DriverListItem }>uEntry, oUserProfile);
      } else {
        l = (<DriverList>oPList).updateListEditEntry(<{ [key: string]: DriverListItem }>uEntry, oUserProfile);
      }
      break;
    case PromoListType.promoFreightCustomer:
      if (isNewEntry) {
        l = (<TripTrackerList>oPList).updateListToAddEntry(<{ [key: string]: TripTrackerListItem }>uEntry, oUserProfile);
      } else {
        l = (<TripTrackerList>oPList).updateListEditEntry(<{ [key: string]: TripTrackerListItem }>uEntry, oUserProfile);
      }
      break;
    case PromoListType.promoTruck:
    case PromoListType.promoTrailer:
      l = (<VehicleList>oPList).updateList(<{ [key: string]: VehicleListItem }>uEntry);
      break;
    default:
      throw new Error(`Invalid List type was provided. Type: ${p.listType} is invalid.`);
  }
  return l;
};
export const parsePromoListItem = (obj: any, listType: PromoListType): ListItem => {
  if (obj == null) { return null; }
  const p = <ListItem>obj; // cast it to base, remember it is still a javascript obj at run time.

  switch (listType) {
    case PromoListType.carrier:
      return CarrierListItem.parse(p);
    case PromoListType.border:
      return CarrierListItem.parse(p);
    case PromoListType.promoDriver:
      return DriverListItem.parse(p);
    case PromoListType.promoFreightCustomer:
      return TripTrackerListItem.parse(p);
    case PromoListType.promoTruck:
    case PromoListType.promoTrailer:
      return VehicleListItem.parse(p);
    case PromoListType.rentalTrailer:
      return RentalTrailerItem.parse(p);

    default:
      throw new Error(`Invalid List type was provided. Type: ${listType} is invalid.`);
  }
};
export const getListItemByKey = (itemkey: string, promoList: PromoListBase): { [key: string]: ListItem } => {
  if (itemkey == null) { return null; }

  const item = pickBy((<any>promoList).list, (v, key) => key === itemkey);

  return item;
};
export const getListTypeDescription = (listType: PromoListType): string => {
  switch (listType) {
    case PromoListType.promoTruck:
      return 'Truck';
    case PromoListType.promoTrailer:
      return 'Trailer';
    case PromoListType.promoDriver:
      return 'Driver';
    case PromoListType.promoFreightCustomer:
      return 'Shipment Tracker';
    case PromoListType.carrier:
      return 'Carrier';
    case PromoListType.border:
      return 'Border';
    case PromoListType.rentalTrailer:
      return 'Rental Trailer';
    default:
      throw new Error(`Invalid List type was provided. Type: ${listType} is invalid.`);

  }
};
/**
 * 
 * @param cid company id
 * @param tno tracking number
 * @param key trailer key (number)
 */
export const checkEmptyTrailer = (cid: string | number, tnos: string[], key: string): IValidationMsg => {
  const r: ValidationError[] = [];
  const m = toValidationError(r);
  if (!isString(cid) && !maxLength(cid, 50)) {
    m['cid'] = [`Invalid cid`];
  }
  if (!isArray(tnos) || tnos.findIndex(f => !isString(f) || !maxLength(f, 18)) > -1) {
    m['tno'] = [`Invalid Tracking number`];
  }
  if (!isString(key) && !maxLength(key, 50)) {
    m['key'] = [`Invalid Trailer number`];
  }
  return m;
};
/**
 * 
 * @param d new or updated entry
 * @param oPList PromoList (Driver List or Notofier List)
 */
export const getUpdatedItemFromList = (d: { [key: string]: ListItem }, oPList: PromoListBase): { [key: string]: ListItem } => {
  const entryKey = Object.keys(d)[0];
  const entryVal = Object.values(d)[0];
  // let listKey: string;
  // let listVal: ListItem;
  let listItem: { [key: string]: ListItem };
  let uid: string | number;
  switch (oPList.listType) {
    case PromoListType.promoDriver:
      uid = entryVal.driverId;
      break;
    case PromoListType.promoFreightCustomer:
      uid = entryVal.tripTrackerId;
      break;
    default:
      break;
  }
  if (uid) {
    listItem = pickBy((oPList as DriverList).list, (val, key) => key === `${uid}`);
  } else {
    listItem = pickBy((oPList as DriverList).list, (val, key) => key === `${entryKey}`);
  }
  if (!listItem || isEmpty(listItem)) {
    return null;
  } else {
    return listItem;
  }
};
/**
 * intialize lists with TBD entry called when copmany is created
 * @param cid new company id
 * @param company carrier company (does not include id and called on company create)
 */
export const initalizeLists = (cid: string | number, company: CompanyCarrier): {
  truckList: VehicleList, trailerList: VehicleList, driverList: DriverList, notifierList: TripTrackerList
} => {
  try {
    const cSummary = company.carrierCompanySummary;
    cSummary.cid = `${cid}`;
    const truckList = VehicleList.createPromoList(cid, PromoListType.promoTruck, cSummary);
    const trailerList = VehicleList.createPromoList(cid, PromoListType.promoTrailer, cSummary);
    const driverList = DriverList.createPromoList(cid, cSummary);
    const notifierList = TripTrackerList.createPromoList(cid, cSummary);
    return { truckList, trailerList, driverList, notifierList };

  } catch (error) {
    logger.error('[promo-list helper], initalizeLists failed', error);
    return null;
  }

};
/** get Promo List type based on product type */
export const getPromoListType = (prodType: ProductType) => {
  switch (prodType) {
    case ProductType.truck:
    case ProductType.promoTruck:
      return PromoListType.promoTruck;
    case ProductType.trailer:
    case ProductType.promoTrailer:
      return PromoListType.promoTrailer;
    default:
      throw new Error(`Invalid List type was provided. Type: ${prodType} is invalid.`);
  }
};
/** get Product type based on promoList type */
export const getProductType = (listType: PromoListType) => {
  switch (listType) {
    case PromoListType.promoTruck:
      return ProductType.truck;
    case PromoListType.promoTrailer:
      return ProductType.trailer;

    default:
      throw new Error(`Invalid List type was provided. Type: ${listType} is invalid.`);
  }
};
/** get Promo List type based on product type */
export const getPromoRentalListType = (prodType: ProductType) => {
  switch (prodType) {
    case ProductType.truck:
      return PromoListType.rentalTruck;
    case ProductType.trailer:
      return PromoListType.rentalTrailer;

    default:
      throw new Error(`Invalid List type was provided. Type: ${prodType} is invalid.`);
  }
};
/** get Promo List type based on sales product type */
export const getPromoSalesListType = (prodType: ProductType) => {
  switch (prodType) {
    case ProductType.truck:
      return PromoListType.salesTruck;
    case ProductType.trailer:
      return PromoListType.salesTrailer;

    default:
      throw new Error(`Invalid List type was provided. Type: ${prodType} is invalid.`);
  }
};
export const updatePromoListWhenProdUpdated = (uProd: ProductBase, oProd: ProductBase, oList: PromoListBase): PromoListBase => {
  const pid = DbRule.getRootId(oProd.id);
  switch (uProd.productType) {
    case ProductType.truck:
    case ProductType.trailer:
      return (oList as VehicleList).updateVehListWhenProdUpdate(uProd as Vehicle, oProd as Vehicle);

    default:
      throw new Error(`Invalid List type was provided. Type: ${uProd.productType} is invalid.`);
  }
};
/**
 * update rental aggregate based on  product type
 * @param rental RentalTruck | RentalTrailer
 * @param rid new or updated if for RentalTruck | RentalTrailer
 * @param oAggregate ogirnal RentalTrailerAggregate @updateLater to include RentalTruckAggregate
 */
export const updateRentalAggregateHelper = (rental: RentalTruck | RentalTrailer, rid: string | number,
  oAggregate: RentalTrailerAggregate): {
    changeRequired: boolean, uAggregate?: RentalTrailerAggregate
  } | null => {  // later update to include RentalTruckAggregate

  if (!oAggregate) {
    switch (rental.productType) {
      case ProductType.trailer:
        oAggregate = new RentalTrailerAggregate();
        oAggregate.listType = PromoListType.rentalTrailer;
        break;
      case ProductType.truck:
        oAggregate = new RentalTrailerAggregate();
        oAggregate.listType = PromoListType.rentalTruck;
        break;
      default:
        throw new Error(`Invalid List type was provided. Type: ${rental.productType} is invalid.`);

    }
  }
  return oAggregate.updateRentalAggregate(rental as RentalTrailer, rid);
};

/**
 * update sales aggregate based on  product type
 * @param sales
 * @param rid
 * @param oAggregate
 */
export const updateSalesAggregateHelper = (sales: SalesProductBase, sid: string | number,
  oAggregate: SalesTruckAggregate): {
    changeRequired: boolean, uAggregate?: SalesTruckAggregate
  } | null => {  // later update to include RentalTruckAggregate

  if (!oAggregate) {
    switch (sales.productType) {
      case ProductType.trailer:
        oAggregate = new SalesTruckAggregate();
        oAggregate.listType = PromoListType.salesTrailer;
        break;
      case ProductType.truck:
        oAggregate = new SalesTruckAggregate();
        oAggregate.listType = PromoListType.salesTruck;
        break;
      default:
        throw new Error(`Invalid List type was provided. Type: ${sales.productType} is invalid.`);

    }
  }
  return oAggregate.updateSalesAggregate(sales as SalesProductBase, sid);
};
/**
 * Gets Rental Aggregate List type and id based on product type
 * @param pType Product Type
 */
export const getRentalAggListTypeId = (pType: ProductType): { listType: PromoListType, lid: string } => {

  switch (pType) {
    case ProductType.trailer:
      return { listType: PromoListType.rentalTrailer, lid: RentalTrailerAggregate.rentalTrailerAggreateID };
    case ProductType.truck:
      return { listType: PromoListType.rentalTruck, lid: RentalTrailerAggregate.rentalTruckAggreateID };

    default:
      throw new Error(`Invalid List type was provided. Type: ${pType} is invalid.`);
  }
};


// tslint:disable-next-line:max-line-length
export const getMapLabelsAggList = (list: { [key: string]: RentalVehicalListItem; }, pType: ProductType, cid?: string): MapLabel[] => {

  const labels: MapLabel[] = [];

  for (const key in list) {
    if((list[key].availEndDate > addDays(new Date(), 2))) {
    if (Object.prototype.hasOwnProperty.call(list, key)) {
      const e = list[key];
      let actions: string;
      if (!!cid && cid === e.cid) {
        actions = '<a id="ClusterAction">Details</a>';
      } else {
        actions = '<a id="ClusterAction">Details</a> | <a id="IdAction">Rent</a>';
      }
      if (!!e.geoLoc) {
        labels.push({
          iPoint: {
            latitude: Math.round(e.geoLoc.geopoint.latitude * 1000000) / 1000000,
            longitude: Math.round(e.geoLoc.geopoint.longitude * 1000000) / 1000000
          },
          geohash: e.geoLoc.geohash,
          title: `${(e as RentalTrailerItem).summary}`,
          productType: pType,
          id: key,
          desc: !e.targetCCids ? `<div>${getCurrencyString(e.currency)}<br>${(e as RentalTrailerItem).bestRateString}</div>  <br>
          <div style="border-top: 1px; border-top-style: solid; border-color: #888; margin-left:-10px; margin-right:-10px; padding: 10px">
          <div>${actions}<div>
          </div>`
            : 'Exlusive',
          clickLabel: !!cid && cid === e.cid ? [{ btnLabel: 'Details', emitterAction: 'mapClusterAction' }] :
            [{ btnLabel: 'Rent', emitterAction: 'mapLabelActionById' }],
          actions: ['ClusterAction', 'IdAction']

        });
      }
    }
  }
  }
  return labels;
};

  /**
   * @author - SKS
   * @purpose - To prepare CSV data for sale-option-list
   */
export const makeCSVHeader= (list:RentalProductBase[]): MapLabel[] => {
  const labels: MapLabel[] = [];
  for (const key in list) {
    if (Object.prototype.hasOwnProperty.call(list, key)) {
      const e = list[key];
      labels.push(flattenObject(e));
     
    }
  }
  return labels;
};

function flattenObject(ob:any) {
  var toReturn = {};

  for (var i in ob) {
      if (!ob.hasOwnProperty(i)) continue;

      if ((typeof ob[i]) == 'object' && ob[i] !== null) {
          var flatObject = flattenObject(ob[i]);
          for (var x in flatObject) {
              if (!flatObject.hasOwnProperty(x)) continue;
              if(flatObject[x]!==undefined)
              if(x=='url'){
                toReturn[i + '-' + x] = flatObject[x];
              }else{
                toReturn[x] = flatObject[x];
              }             
             
          }
      } else {
        if(ob[i]!==undefined)
          toReturn[i] = ob[i];
      }
  }
  return toReturn;
}

/**
 * check if any updated properties required Aggregate List updated
 * NOTE: Call when @param uProd.dbStatus is set as comparison includes dBStatus change
 * @param uProd Updated Vehicle
 * @param oProd Orignal Vehicle
 */
export const isAggListUpdateReq = (oProd: Vehicle, uProd: Vehicle): boolean => {
  const pid = DbRule.getRootId(oProd.id);
  switch (oProd.productType) {
    case ProductType.truck:
    case ProductType.trailer:
      const oDummyItem: { [key: string]: VehicleListItem } = {} as any;
      const uDummyItem: { [key: string]: VehicleListItem } = {} as any;
      oDummyItem[oProd.unitName] = { pid: pid } as any;
      uDummyItem[uProd.unitName] = { pid: pid } as any;
      const oDummyVehList = VehicleList.createPromoList(oProd.cid, PromoListType.promoTruck, {} as any, oDummyItem);
      const uDummyVehList = VehicleList.createPromoList(oProd.cid, PromoListType.promoTruck, {} as any, uDummyItem);
      // duplicated oProd is used just to test if update is require
      const o = oDummyVehList.updateVehListWhenProdUpdate(uProd, oProd);
      const u = uDummyVehList.updateVehListWhenProdUpdate(uProd, oProd);
      if (isEqual(o, u) && oProd.unitName === uProd.unitName) {
        return false;
      } else {
        return true;
      }
    default:
      throw new Error(`Invalid product type was provided. Type: ${oProd.productType} is invalid.`);

  }

};
export const promoListAssetLocMapLabels = (locData: ITelematics[], pList: VehicleList): MapLabel[] => {
  const labels: MapLabel[] = [];
  for (const d of locData) {
    const actions = '<a id="ClusterAction">Details</a>';

    labels.push({
      iPoint: d.location.geopoint, title: d.unitName,
      desc: `Location recorded at <br>${shortDateTime(new Date(d.timestamp))} <br> 
      <div style="border-top: 1px; border-top-style: solid; border-color: #888; margin-left:-10px; margin-right:-10px; padding: 10px"> 
          <div>${actions}<div>
          </div>`,
      productType: getProductType(pList.listType),
      id: d.unitName,  // pList.list[d.unitName]?.pid
      clickLabel: [{ btnLabel: 'Details', emitterAction: 'mapClusterAction' }],  // !!pList.list[d.unitName]?.pid ? 'Details' : 'Edit'
      actions: ['ClusterAction']
    });
  }
  return labels;
};
