import { Exclude, Expose, plainToInstance, instanceToInstance } from 'class-transformer';
import { logger } from '../log/logger';
import { IPdfContentData, IPdfContentSectionData, IPdfTemplateColumns } from '../pdf/i-pdf-data-structure';
import { PageHtml, PageHtmlSection } from './page-html';
import { AgreementType } from '../contract/legal-type';
import { IsEnum, ValidatorOptions } from 'class-validator';
import { PdfTemplate, TemplateType } from './pdf-template';
import { BidGuarantors } from '../bid/bid-guarantors';
import { format } from 'date-fns';
import { BidBase } from '../bid/bid-base';
import { globalToInstance } from './page-html-decorator';

/**
 * @author - MKN
 * @purpose - Contracts
 */

@Exclude()
export class ContractHtml extends PageHtml {

  /** auto section will keep the track of all custom properties that have @sectionDef attribute applied to them.
   * This is used for auto generating the html control in the cms page. This value will NOT be saved in the database. */
  autoSection: Record<string, PageHtmlSection> = {};

  constructor() {
    super();
    globalToInstance(ContractHtml, this);
  }

  /** Contract PDF Content Structure type  */
  @Expose()
  @IsEnum(AgreementType)
  pdfLegalType: AgreementType;

  @Expose()
  pdfContent: IPdfContentData;

  public syncTagChanges() {
    const changes: { old: string; new: string }[] = [];
    const currKeys = Object.keys(this.pdfContent);
    logger.log('syncTagChanges --->');
    logger.log(this.pdfContent);

  }

  /**
   * @author - MKN
   * @purpose - create pdf structure JSON from UI array
   */
  public static createPdfMakeJson(iContent) {

    let content = [];
    if (iContent && iContent.length > 0) {
      iContent.map(item => {
        if (item.pdfTemplateType == TemplateType.newLine) {
          content.push('\n');
        } else {
          if (Object.keys(item.pdfData).length > 0) {
            content.push(item.pdfData);
          }
        }
      });
    }

    return content;
  }

  // public static parse(obj) {
  //   if (obj == null) { return null; }
  //   const m = plainToInstance<ContractHtml, any>(ContractHtml, obj);
  //   m.sanitize();
  //   return m;
  // }

  /**
   * @author MKN 
   * Generate Personal Guaranty page based on parameter
   * @param personalGuarantyPage 
   * @param bidGuarantors 
   * @param bid 
   * @param addNewPageAtStart
   * @returns 
   */
  public static generatePersonalGuarantyPage(personalGuarantyPage : ContractHtml, bidGuarantors : BidGuarantors[], bid : BidBase, addNewPageAtStart : boolean){
   
    let content = [];
    if(personalGuarantyPage && bidGuarantors){
      for(let j = 0; j < bidGuarantors.length ; j++){
        let tempPersonalGuarantyPage = (personalGuarantyPage as ContractHtml).clone();

        for(let i = 0; i < tempPersonalGuarantyPage.pdfContent?.content.length ; i++){
          let contentData = tempPersonalGuarantyPage.pdfContent?.content[i];
          if(contentData.pdfData){

            if(contentData.pdfTemplateType === TemplateType.newLine){
                continue;
            }

            if(contentData.pdfTemplateType === TemplateType.heading || contentData.pdfTemplateType === TemplateType.subHeading){
              let pdfData = (contentData.pdfData as IPdfContentSectionData);
            
              if(i == 0 && addNewPageAtStart){
                pdfData.pageBreak = 'before';
              }
              if(!!pdfData.ids){
                let allIds = pdfData.ids.split(',');
                for(let k = 0; k < allIds.length ; k++){
                  if(allIds[k] === 'guarantor-name'){
                    if(typeof pdfData.text == 'string'){
                      let text : any = (pdfData.text as string).replace(allIds[k], `${bidGuarantors[j].fullName}`); 
                      pdfData.text = text;
                    }
                  }
  
                  if(allIds[k] === 'personal-guaranty-date'){
                    if(typeof pdfData.text == 'string'){
                      let text : any = (pdfData.text as string).replace(allIds[k], `${format(new Date(), 'dd-MMM-yy')}`); 
                      pdfData.text = text;
                    }
                  }  
                  
                  if(allIds[k] === 'customer-company-name'){
                    if(typeof pdfData.text == 'string'){
                      let text : any = (pdfData.text as string).replace(allIds[k], `${bid.customerCompSummary.legalName}`); 
                      pdfData.text = text;
                    }
                  }
  
                  if(allIds[k] === 'guarantor_address'){
                    if(typeof pdfData.text == 'string'){
                      let text : any = (pdfData.text as string).replace(allIds[k], `${bidGuarantors[j].address.addressFormated}`); 
                      pdfData.text = text;
                    }
                  }
  
                }//for loop close         
              }//close pdfData.ids
            }else if(contentData.pdfTemplateType === TemplateType.columns){
              let pdfData = (contentData.pdfData as IPdfTemplateColumns);
            
              if(i == 0 && addNewPageAtStart){
                pdfData.pageBreak = 'before';
              }

              for(let l = 0 ; l < pdfData.columns.length ; l++){

                let tempPdfData = (pdfData.columns[l] as IPdfContentSectionData);

                if(!!tempPdfData.ids){
                  let allIds = tempPdfData.ids.split(',');
                  for(let k = 0; k < allIds.length ; k++){
                    
                    if(allIds[k] === 'guarantor-name'){
                      if(typeof tempPdfData.text == 'string'){
                        let text : any = (tempPdfData.text as string).replace(allIds[k], `${bidGuarantors[j].fullName}`); 
                        tempPdfData.text = text;
                      }
                    }

                    if(allIds[k] === 'guarantor-address'){
                      if(typeof tempPdfData.text == 'string'){
                        let text : any = (tempPdfData.text as string).replace(allIds[k], `${bidGuarantors[j].address.addressFormated}`); 
                        tempPdfData.text = text;
                      }
                    }

                    if(allIds[k] === 'customer-company-name'){
                      if(typeof tempPdfData.text == 'string'){
                        let text : any = (tempPdfData.text as string).replace(allIds[k], `${bid.customerCompSummary.legalName}`); 
                        tempPdfData.text = text;
                      }
                    }
    
                  }//for loop close         
                }//close tempPdfData.ids
              }
            }else if(contentData.pdfTemplateType === TemplateType.numberedList || contentData.pdfTemplateType === TemplateType.bulletList){
              
            }else if(contentData.pdfTemplateType === TemplateType.table){

            }
            
            
          }//close contentData.pdfData
        }
        content.push(...ContractHtml.createPdfMakeJson(tempPersonalGuarantyPage.pdfContent?.content));      
      }
    }
    // KN getting undefined value for pdfContent images
    return { content : content , images : (personalGuarantyPage as ContractHtml).pdfContent?.images ? (personalGuarantyPage as ContractHtml).pdfContent?.images : {}};
  }

}
