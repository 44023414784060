import { IsNumber, IsDate, IsDefined, IsBoolean } from 'class-validator';
import { Term } from '../rental/term';
import { differenceInDays, isBefore, addDays, addMonths, addWeeks } from 'date-fns';
import { NegoTermsBase } from '../nego-terms-base/nego-terms-base';
import { RentalProductBase } from '../rental/rental-product-base';
import { plainToInstance } from 'class-transformer';
import { sanitizeDateIPoint } from '../utility/sanitize-helper';
import { RentOptionSummary } from '../rental/rent-option-summary';

/**
 * OUTPUT OF BID SPECFIC LOGIC
 * - Ref (bid ID / company / )
 * - Array of Order Items
 * - Each Order ITEM must specify following
 *    - Base Amount
 *    - Tax Method [] (ENUM ARRAY )
 *    - Payment TYPE ( pay / Pre-Auth / scheduled )
 * 
 */


export class PaymentTransaction {
  constructor() {
    this.currency = 'CAD';
  }

  @IsNumber()
  payment: number;

  @IsNumber()
  tax: number;

  currency?: 'CAD' | 'USD';

  @IsDate({ message: 'Payment date is required' })
  dueDate: Date;

  @IsDefined()
  period?: { from: Date, to: Date };

  @IsBoolean()
  isDeposit?: boolean;

  bidId?: string | number;

  public static parse(obj) {
    if (obj == null) { return null; }
    const m = plainToInstance<PaymentTransaction, any>(PaymentTransaction, sanitizeDateIPoint(obj));
    // m.sanitize();
    return m;
  }

  /** rental payment, return payment schedule (inc deposit) and total payment
   *
   * @param rentSummary: RentOptionSummary
   * @param negoTerm: NegoTermsBase
   */


  public static getRentalPayments2(rentSummary: RentOptionSummary, negoTerm: NegoTermsBase): {
    schedule: PaymentTransaction[], totalPayment: number
  } {
    // const rentPD = RentalProductBase.getRentPerDay(negoTerm.rate, term);
    const rentPD = RentalProductBase.getApplicableRentalTerm(negoTerm.startDate, negoTerm.endDate, rentSummary).proratedDailyRate;
    const term = RentalProductBase.getApplicableRentalTerm(negoTerm.startDate, negoTerm.endDate, rentSummary).applicableTerm;
    let sch: PaymentTransaction[] = [];
    let trailingDays: number;
    let p: PaymentTransaction;
    let totalRent = 0;
    if (!term) {
      return null;
    } else {
      switch (term) {
        case Term.daily:
          const nDays = differenceInDays(negoTerm.endDate, negoTerm.startDate);
          p = new PaymentTransaction();
          p.payment = Math.round(rentSummary.rateDaily * nDays * 100) / 100;
          p.period = { from: negoTerm.startDate, to: negoTerm.endDate };
          p.dueDate = new Date();
          sch.push(p);
          break;
        case Term.weekly:
          // calculate payment of full weeks
          for (let s = negoTerm.startDate; isBefore(s, negoTerm.endDate); s = addWeeks(s, 1)) {
            p = new PaymentTransaction();
            if (isBefore(addWeeks(s, 1), addDays(negoTerm.endDate, 1))) {
              p.payment = Math.round(rentSummary.rateWeekly * 100) / 100;
              // first payment is due at time of booking bid submission
              p.period = { from: s, to: addDays(addWeeks(s, 1), -1) };
              p.dueDate = s === negoTerm.startDate ? new Date() : s;
              sch.push(p);
            } else {
              trailingDays = differenceInDays(negoTerm.endDate, s);
              p.payment = Math.round(trailingDays * rentPD * 100) / 100;
              p.period = { from: s, to: addDays(negoTerm.endDate, -1) };
              p.dueDate = s === negoTerm.startDate ? new Date() : s;
              sch.push(p);
            }
          }
          break;
        case Term.monthly:
          // calculate payment of full weeks
          for (let s = negoTerm.startDate; isBefore(s, negoTerm.endDate); s = addMonths(s, 1)) {
            p = new PaymentTransaction();
            if (isBefore(addMonths(s, 1), addDays(negoTerm.endDate, 1))) {
              p.payment = Math.round(rentSummary.rateMonthly * 100) / 100;
              // first payment is due at time of booking bid subission
              p.period = { from: s, to: addDays(addMonths(s, 1), -1) };
              p.dueDate = s === negoTerm.startDate ? new Date() : s;
              sch.push(p);
            } else {
              trailingDays = differenceInDays(negoTerm.endDate, s);
              p.payment = Math.round(trailingDays * rentPD * 100) / 100;
              p.period = { from: s, to: addDays(negoTerm.endDate, -1) };
              p.dueDate = s === negoTerm.startDate ? new Date() : s;
              sch.push(p);
            }
          }
          break;
        default:
          sch = null;
          totalRent = null;
          break;
      }
      // add deposit and refund when deposit is applicable
      if (!!negoTerm.deposit && negoTerm.deposit > 0) {
        const d = new PaymentTransaction();
        d.payment = Math.round(negoTerm.deposit * 100) / 100;
        d.period = null;
        d.dueDate = new Date();
        sch.unshift(d);
        const r = new PaymentTransaction();
        r.payment = -1 * Math.round(negoTerm.deposit * 100) / 100;
        r.period = null;
        r.dueDate = addDays(negoTerm.endDate, 1);
        sch.push(r);
      }
      sch.forEach(r => totalRent = r.payment + totalRent);
      totalRent = Math.round(totalRent * 100) / 100;
    }
    return { schedule: sch, totalPayment: totalRent };
  }
}


