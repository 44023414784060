import { TripTransaction } from './trip-transaction';
import { Exclude, plainToInstance, instanceToInstance, Expose } from 'class-transformer';
import { sanitizeDateIPoint, trackingNoRegex } from '../utility';
import { ValidatorOptions, IsArray, ArrayMinSize, ArrayMaxSize, MaxLength, MinLength, ValidateIf, Matches, Validator } from 'class-validator';
import { IValidationMsg } from '../error-handling/validation-info';
import { TripBase } from './trip-base';
import { VehicleList } from '../promo/promo-vehicle';
import { TripTrackerList } from '../promo/trip-tracker-list';
import { pickBy } from 'lodash';
import { ItemStatus } from '../promo/item-status';
import { Address } from '../address/address';
import { logger } from '../log/logger';



@Exclude()
export class Pickup extends TripTransaction {
  /** trip trackers(notifiers) email dictionary */
  @Expose()
  @ValidateIf(r => !!r.trackingNos)
  @IsArray()
  @MaxLength(50, { each: true })
  @ArrayMaxSize(3, { message: 'upto 3 emails allowed' })
  @ArrayMinSize(1, { message: 'Add email(s) to notify!' })
  freightCustomer: string[];

  /** Array of tracking assciated with the pickup*/
  @ArrayMaxSize(5)
  @ArrayMinSize(1)
  @MaxLength(18, { each: true })
  @MinLength(18, { each: true })
  @Expose()
  trackingNos: string[] = [];

  /** Array of reference number custom to carrier associated pickup, e.g. PO BOL*/
  @ValidateIf(r => !!r.referenceNos)
  @ArrayMinSize(0)
  @ArrayMaxSize(3)
  @MaxLength(20, { each: true })
  @Expose()
  referenceNos: string[] = [];

  public static parse(obj) {
    try {
      if (obj instanceof Pickup) {
        // custom parse for trip transaction
        if (!obj.isApptApp) {
          obj.apptDateTime = undefined;
          obj.apptWindow = undefined;
        }
        return obj;
      }
      if (obj == null) { return null; }
      // obj = sanitizeDateIPoint(obj, ipointType);
      obj = sanitizeDateIPoint(obj);
      const m = plainToInstance<Pickup, any>(Pickup, obj);
      m.sanitize();
      m.address = Address.parse(m.address);
      return m;
    } catch (error) {
      logger.log('Error happened during parse', error);
      return null;
    }
  }
  clone() {
    const t = instanceToInstance(this);
    t.sanitize();
    return t;
  }

  validateSync(options?: ValidatorOptions, trip?: TripBase, index?: number, truckL?: VehicleList, trailerL?: VehicleList,
    tripTrackerL?: TripTrackerList): IValidationMsg {
    const r = super.validateSync(undefined);
    const p = this.validateSyncBase(this, options);
    for (const key in p) {
      if (p.hasOwnProperty(key)) {
        const ele = p[key];
        r[key] = ele;
      }
    }
    if (!this.trackingNos || this.trackingNos.length < 1) {
      r['trackingNos'] = ['Atleast one Tracking number is required!'];
    }
    if (!!this.trackingNos && this.trackingNos.length > 0 && this.validateTrackingNos().length > 0) {
      r['trackingNos'] = [`Invalid tracking no, ${this.validateTrackingNos()[0]}`];
    }
    if (!this.freightCustomer || this.freightCustomer.length < 1) {
      r['freightCustomer'] = ['Add email(s) to notify!'];
    }
    if (!!tripTrackerL && !!this.freightCustomer && !this.isNotifierActive(tripTrackerL).success) {
      r['freightCustomer'] = [`${this.isNotifierActive(tripTrackerL).email} is inactive`];
    }
    return r;
  }
  // addTrackingNo(): void {
  //   let t = Math.random().toFixed(16).toString().replace('0.', '');
  //   logger.log('tracking', t);
  //   t = `${t.substr(0, 5)}-${t.substr(5, 5)}-${t.substr(10)}`;
  //   logger.log('tracking', t);
  //   if (!this.trackingNos.includes(t)) {
  //     this.trackingNos.push(t);
  //   }
  // }
  isNotifierActive(tripTrackerL: TripTrackerList): { email: string | null, success: boolean } {
    const notifiers = pickBy(tripTrackerL.list, (v, k) => this.freightCustomer.includes(k));
    for (const key in notifiers) {
      if (notifiers.hasOwnProperty(key)) {
        const notifier = notifiers[key];
        if (notifier.itemStatus !== ItemStatus.active) {
          return { email: key, success: false };
        }
      }
    }
    return { email: null, success: true };
  }
  validateTrackingNos(): string[] {
    const validate = new Validator();
    const invalidTrackingNos: string[] = [];
    for (const tno of this.trackingNos) {
      if (!trackingNoRegex(tno)) {
        invalidTrackingNos.push(tno);
      }
    }
    return invalidTrackingNos;
  }
}
