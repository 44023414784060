import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { File, Entry } from '@awesome-cordova-plugins/file/ngx';
// import { Plugins, FilesystemEncoding } from '@capacitor/core';
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
// const { Filesystem } = Plugins;
import { logger } from '@trentm/log/logger';

@Injectable()
export class FileCapService {


  constructor() {

  }

  get isFileAvailable() { return true; }

  resolveFile(url: string): Promise<Entry> {
    /** Android */
    return new Promise((resolve, reject) => {
      Filesystem.readFile({
        path: 'secrets/text.txt',
        directory: Directory.Documents,
        encoding: Encoding.UTF8
      })
        .then(x => {
          logger.log('resolve file executed.', x);
          resolve(x as any);
        })
        .catch(err => {
          logger.log(`[cap file service] Error resolving the file.`, err);
          reject(err);
        });
      // logger.log(contents);
      // (<any>window).resolveLocalFileSystemURL(
      //   url,
      //   (fileEntry: any) => {
      //     fileEntry.file(resolve);
      //   });
    });
  }
  // Read File's Blob
  readFile(file: any): Promise<Blob> {
    return new Promise((resolve, reject) => {
      Filesystem.readFile({
        path: file,
        // directory: FilesystemDirectory.Documents,
        // encoding: FilesystemEncoding.UTF8
      })
        .then(x => {
          logger.log('resolve file executed.', x);
          resolve(x.data as any );
        })
        .catch(err => {
          logger.log(`[cap file service] Error resolving the file.`, JSON.stringify(err));
          reject(err);
        });
    });
  }
}
// Dealing with large files in Cordova

// https://clockwise.software/blog/dealing-with-large-files-in-cordova/


// https://forum.ionicframework.com/t/convert-file-uri-to-blob/133635
