export enum TransactionType {
  /** Tranction of a token payment using Bambora */
  TokenB = 0
}

/** Transaction Type Bambora response
 * https://dev.na.bambora.com/docs/references/checkout/misc/
 */
export type TransactionTypeBambora = 'P' | 'VP' | 'PA' | 'PAC' | 'R' | 'VR';

export const transactionTypeBamboraDesc = (t: TransactionTypeBambora): string => {
  switch (t) {
    case 'P':
      return 'Purchase';
    case 'VP':
      return 'Void Purchase';
    case 'PA':
      return 'Pre Authorization';
    case 'PAC':
      return 'Pre Authorization Completion';
    case 'R':
      return 'Return';
    case 'VR':
      return 'Void Return';
  
    default:
      throw new Error(`Invalid transaction type was provided. Type: ${t} is invalid.`);
  }
};
