import { Injectable } from '@angular/core';
import { BaseHttpService } from './base-http.service';
import { Store } from '@ngxs/store';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IPoint } from '@trent/models/product-search/interfaces';
import { instanceToPlain } from 'class-transformer';
import { tap } from 'rxjs/operators';
import { logger } from '@trentm/log/logger';

@Injectable()
export class TimezoneService extends BaseHttpService {

  constructor(store: Store, private http: HttpClient) {
    super(store);
    this.apiName = 'api';
  }

  getTimezone(iPoint: IPoint) {
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + this.token });

    // tslint:disable-next-line:max-line-length
    // return this.http.post<{utcOffset: number, timezone: string}>('https://us-central1-lloop-debug.cloudfunctions.net/tzone/tz/getTimezone',
    return this.http.post<{ utcOffset: number, timezone: string, zoneString: string }>(this.getApiUrl('/tz/getTimezone'),
      // Server validation before creating the entry.
      {
        iPoint: instanceToPlain(iPoint),
        // date: date
      },
      // tslint:disable-next-line:object-literal-shorthand
      { headers: headers }
    )
      .pipe(
        tap(res => logger.log('response: ', res))
      );
  }
}
