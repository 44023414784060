<trent-app-comp-shell>
  
</trent-app-comp-shell>
<div *ngIf="showBusy" class="spinner-container">
  <mat-progress-spinner class="spinner" [mode]="'indeterminate'">
  </mat-progress-spinner>
</div>
<!-- Map Container Place Holders -->
<div id='mapBackup' style="height: 100%; width:100%; display: none;">
  <div id="mapHome" style="width: 100%;">
  </div>
  <div id="mapTrip" style="width: 100%;">
  </div>
</div>
