import { Component, OnInit, Input } from '@angular/core';
import { Store } from '@ngxs/store';
import { MapLabel, MapLabelClick } from '@trent/models/map/map-label';
import { StoreLocation } from '@trent/models/store-location/store-location';
import { StoreLocationParam } from '@trent/models/store-location/store-location-param';
import { BasePage } from '@trent/models/UI/base.page';
import { DialogService } from '@trent/services/dialog/dialog.service';
import { EventService } from '@trent/services/event.service';
import { StoreLocationsRequested, StoreLocationState } from '@trent/store/store-location-store';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { getMapLabelsAggList } from '@trent/models/store-location/store-location-helper';
import { SubPageComponent } from '../../shared/sub-page/sub-page.component';
import { AuthState } from '@trent/store/auth-store/auth.state';
import { Router } from '@angular/router';
import { UtilityService } from '@trent/services/utility.service';

@Component({
	selector: 'trent-pts-footer',
	templateUrl: './pts-footer.component.html',
	styleUrls: ['./footer-styles.scss']
})
export class PtsFooterComponent extends BasePage<StoreLocation> implements OnInit {

	stores: StoreLocation[];
	storeLocationSubscription: Subscription;
	uid: string | number;
	mapLabels: MapLabel[];
	selectedStore: StoreLocation;
	@Input() footerFrom;
	@Input() socials;
	isHandset: boolean;
	telephone: string;
	email: string;

	/**
	 * Class Constructor
	 * @param store Store
	 * @param dialogService DialogService
	 * @param es EventService
	 * @param router Router
	 */
	constructor(
		public store: Store,
		dialogService: DialogService,
		es: EventService,
		private router: Router,
		public us: UtilityService
	) {
		super(store, dialogService, es);
	}

	ngOnInit(): void {
		this.mSubscription.push(this.us.isHandset$.subscribe(d => this.isHandset = d));
		this.telephone = this.footerFrom == 'pts' ? '+1 866-774-3324' : '1-844-727-8247';
		this.email = this.footerFrom == 'pts' ? 'leads@pridetrucksales.com' : 'info@dixiepartscentre.com';
		this.getStoreLocations();
	}

	/**
	 * @author KS
	 * @purpose Get Store Locations
	 */
	getStoreLocations() {
		// Set Params
		const pageData = { size: 1000, offset: 0, full: false };
		const sParam: StoreLocationParam = {};
		if(this.footerFrom == 'dpc'){
			sParam.isDPC = true;
		}
		// Get Store Locations from data store
		this.store.dispatch(new StoreLocationsRequested({ pData: pageData, param: sParam }));
		this.storeLocationSubscription = this.store.select(StoreLocationState.selectAllStoreLocations)
			.pipe(map(clientFilterFn => clientFilterFn({ ...sParam })))
			.subscribe((s: StoreLocation[]) => {
				this.stores = s;
				// Set the first store as `selectedStore`
				this.selectedStore = this.stores[0];
				if (this.footerFrom == 'dpc') {
					// Get Map Labels
					this.mapLabels = getMapLabelsAggList(s);
					// Load map
					this.loadStoreMap(this.mapLabels);
				}
			});
		this.mSubscription.push(this.storeLocationSubscription);
	}


	/**
	 * @author KS
	 * @purpose Load Store Location Map
	 * @param mapLabels MapLabel[]
	 */
	loadStoreMap(mapLabels: MapLabel[]) {
		this.es.emit<MapLabel[]>('showMapLabels', mapLabels);
	}
	/**
	 * @author KS
	 * @purpose Listener for  Selected Store
	 * @param store StoreLocation
	 */
	getSelectedStore(store: StoreLocation) {
		this.selectedStore = store;
	}

	/**
	 * Open policy dialogue
	 */
	openPolicy() {
		let url = this.footerFrom == 'dpc' ? 'dpc-privacy-policy' : 'pts-privacy-policy';
		this.dialogService.openInfoPage({ url: url }, SubPageComponent);
	}

	toOurLOc() {
		  this.router.navigate(['our-locations'], { queryParams: { country: '*' } });
	  }
	
}