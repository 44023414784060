import { Directive, ElementRef, Input, OnDestroy, Output, EventEmitter, HostListener, NgZone, OnInit, AfterViewInit } from '@angular/core';
import { EventService, IEventListener } from '@trent/services/event.service';
// import { MapsAPILoader } from '@agm/core';
import { TimerHelper, promiseWraper } from '@trent/models/utility';
import { googleConfig } from '@trent/core/config';
// import { AlertController } from '@ionic/angular';
// import { AlertOptions } from '@ionic/core';
import { ScriptService } from '@trent/services/base/script.service';
import { UtilityService } from '@trent/services/utility.service';
import { logger } from '@trent/models/log/logger';
import { findMeP } from '@trent/models/utility/utl-map-fun';


declare var google: any;
// declare const $: JQueryStatic;

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[appGooglePlaces]'
})
export class GooglePLacesAutoDirective implements OnInit, AfterViewInit {
  constructor(
    private el: ElementRef,
    private es: EventService,
    private ngZone: NgZone,
    private scriptService: ScriptService,
    private us: UtilityService
    // private mapApi: MapsAPILoader,
  ) {
    if (this.us.isPlatformBrowser) {
      this.ele = this.el.nativeElement;
      this.initializeAutoComplete();
    }
  }

  private initialized = false;

  /** Native element represented by the directive. */
  ele: any;
  // private _mode;

  private autocomplete: any;
  // private autocomplete: google.maps.places.Autocomplete;

  private trigger: any;
  private trigger2: any;
  // private trigger: google.maps.MapsEventListener;

  //   /** Event dispatch to the parent page where address information is received from Google. */
  @Output()
  placeLoaded = new EventEmitter<any>();
  // placeLoaded = new EventEmitter<google.maps.places.PlaceResult>();
  @Output()
  placeWithRef = new EventEmitter<{ place: any, ref: any }>();
  //  placeWithRef = new EventEmitter<{place: google.maps.places.PlaceResult, ref: any}>();



  timer: TimerHelper = new TimerHelper();

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    
  }

  ngOnInit(): void {
    
    // this.mapApi.load()
    //   .then(() => {
    //     // intialize auto complete after a delay after api is loaded. But since, for a revisit to the page, map api is
    //     // already loaded, implement a timer delay function that try to initialize the auto complete 10 times afer every 300 milliseconds
    //     this.timer.setInterval(300, 10, () => this.initializeAutoComplete());
    //   })
    //   .catch((err) => {
    //     // const a: AlertOptions = {
    //     //   header: 'Alert',
    //     //   subHeader: 'Map Loading Error!',
    //     //   message: 'Error Loading the Map! <br/> <b>Are you off line? </b>',
    //     //   buttons: ['OK']
    //     // };
    //     // this.showAlert(a);
    //     logger.log(err);
    //   });
  }

  async initializeAutoComplete() {
    const searchInput = this.ele; // document.getElementById('txtHome'); id will also work but not necessary.
    // $('.pac-container').css({'z-index': '10000 !important'});
    if (searchInput == null) {
      logger.log('could not find sarch input. just return false as it might have not loaded in the DOM yet.');
      return false;
    }
    // eslint-disable-next-line max-len
    const googleMap = await promiseWraper(this.scriptService.loadScript(googleConfig.url));

    // logger.log('autocomplete is initialized');
    // if (googleMap.success && googleMap.data.status === 'loaded') {  // TODO: HG

      this.autocomplete = new google.maps.places.Autocomplete(searchInput, {
        componentRestrictions: { country: ['US', 'CA'] },
        types: ['geocode']
      });
      logger.info(`testing to get the value of place on change \n${JSON.stringify(this.autocomplete.getPlace())}`);
      this.trigger = this.autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          const p = this.autocomplete.getPlace();
          this.placeLoaded.emit(p);
          /**
           * returns google autocomplete result and calling element ref
           */
          this.placeWithRef.emit({ place: p, ref: this.ele });
        });
      });

      // everything was fine. return true.
      return true;
    // }

    // Alt method without using zone.
    // this.autocomplete.addListener('place_changed', () => this.placeLoaded.emit(this.autocomplete.getPlace()));

  }

  // Bias the autocomplete object to the user's geographical location,
  // as supplied by the browser's 'navigator.geolocation' object.
  // @HostListener('ionFocus', ['$event.target'])
  geolocate() {
    logger.log('Geo Locate was called. But it is Blocked for now ??');

    if (navigator.geolocation) {
      logger.log('Navigator Geo Location is: ', navigator.geolocation);
      navigator.geolocation.getCurrentPosition((position) => {
        const geolocation = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
        const circle = new google.maps.Circle({
          center: geolocation,
          radius: position.coords.accuracy
        });
        this.autocomplete.setBounds(circle.getBounds());
      });
    }
  }

  async showAlert(a: any) {
    // const alert = await this.alertC.create(a);
    // await alert.present();
    await logger.log(a);
  }
}

