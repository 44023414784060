import { GpsTrackingType, ITripGpsStatus } from '@trent/models/trip/trip-gps-status';
// You may import any optional interfaces
import BackgroundGeolocation, {
  Config,
  Location,
  HttpEvent,
  MotionActivityEvent,
  ProviderChangeEvent,
  MotionChangeEvent,
  GeofenceEvent,
  ConnectivityChangeEvent,
  AuthorizationEvent,
  State
} from 'cordova-background-geolocation-lt';
import { IgeoLocationService } from './igeo-location.service';
import { promiseWraper } from '@trent/models/utility';
import { DialogService } from '../dialog/dialog.service';
import { IAuthCoreService } from '../auth/iauth-core.service';
import { IdeviceIdService } from '../device/idevice-id.service';
import { GeoLocationCoreService } from './geo-location-core.service';
import { FirestoreService } from '../firestore.service';
import { MessageInfo } from '@trent/models/error-handling';
import { SingletonService } from '../singleton.service';
import { Store } from '@ngxs/store';
import { Platform } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { LocDataCap } from '@trent/models/location/loc-data';
import { logger } from '@trentm/log/logger';
import { isNull, isNil } from 'lodash';


/** (paid version)
 * https://github.com/transistorsoft/cordova-background-geolocation-lt
 * The class was taken from: https://gist.github.com/christocracy/0379dd519d77f4215d5de943ed51ade9
 * Documentation: https://transistorsoft.github.io/cordova-background-geolocation-lt/modules/_cordova_background_geolocation_lt_.html
 * 2020-07-11  This plugin is now used in both Android and Capacitor
 */
@Injectable()
export class BackGroundGeoLocTSoft extends GeoLocationCoreService implements IgeoLocationService {

  private isInitialized = false;
  private gpsLogging = false;
  private _isGpsBroadCasting = false;

  /** GPS Specfic information for the current user from db */
  private userGpsStatus: ITripGpsStatus;

  constructor(
    store: Store,
    private ds: DialogService,
    auth: IAuthCoreService,
    deviceIdService: IdeviceIdService,
    db: FirestoreService,
    sa: SingletonService,
    private platform: Platform) {
    super(store, auth, db, deviceIdService, sa);

  }

  private getConfig(deviceId: string, userId: string, platformInfo: string): Config {

    /**
     * LOG_LEVEL_OFF: 0,
    LOG_LEVEL_ERROR: 1,
    LOG_LEVEL_WARNING: 2,
    LOG_LEVEL_INFO: 3,
    LOG_LEVEL_DEBUG: 4,
    LOG_LEVEL_VERBOSE: 5,

    // For #desiredAccuracy
    DESIRED_ACCURACY_NAVIGATION: -2,
    DESIRED_ACCURACY_HIGH: -1,
    DESIRED_ACCURACY_MEDIUM: 10,
    DESIRED_ACCURACY_LOW: 100,
    DESIRED_ACCURACY_VERY_LOW: 1000,
    DESIRED_ACCURACY_THREE_KILOMETER: 3000,

    // For providerchange event
    AUTHORIZATION_STATUS_NOT_DETERMINED: 0,
    AUTHORIZATION_STATUS_RESTRICTED: 1,
    AUTHORIZATION_STATUS_DENIED: 2,
    AUTHORIZATION_STATUS_ALWAYS: 3,
    AUTHORIZATION_STATUS_WHEN_IN_USE: 4,

    // For android #notificationPriority
    NOTIFICATION_PRIORITY_DEFAULT: 0,
    NOTIFICATION_PRIORITY_HIGH: 1,
    NOTIFICATION_PRIORITY_LOW: -1,
    NOTIFICATION_PRIORITY_MAX: 2,
    NOTIFICATION_PRIORITY_MIN: -2,

    // For iOS #activityType
    ACTIVITY_TYPE_OTHER: 1,
    ACTIVITY_TYPE_AUTOMOTIVE_NAVIGATION: 2,
    ACTIVITY_TYPE_FITNESS: 3,
    ACTIVITY_TYPE_OTHER_NAVIGATION: 4,

    // For persistMode
    PERSIST_MODE_ALL: 2,
    PERSIST_MODE_LOCATION: 1,
    PERSIST_MODE_GEOFENCE: -1,
    PERSIST_MODE_NONE: 0,

     */
    // Capacitor Configuration Default.    

    const param: LocDataCap = {
      deviceId: deviceId,
      userId: userId,
      trackingType: GpsTrackingType.trip
    };
    if (!isNil(this.userGpsStatus?.trackingType)) {
      param.trackingType = this.userGpsStatus.trackingType;
    }

    if (this.gpsLogging) {
      param.gpsLogging = true;
    }


    const configIos: Config = {
      reset: true,
      debug: this.debug,
      logLevel: BackgroundGeolocation.LOG_LEVEL_INFO,
      desiredAccuracy: BackgroundGeolocation.DESIRED_ACCURACY_MEDIUM,
      distanceFilter: 50,
      url: this.getApiUrl(), //  environment.gpsPostUrl,
      params: {
        ...param,
        platform: 'capacitor'
      },
      autoSync: true,
      autoSyncThreshold: 5,
      batchSync: true,
      maxBatchSize: 100,
      stopOnTerminate: false,
      startOnBoot: true,
      locationAuthorizationAlert: {
        titleWhenNotEnabled: 'LocusLoop location-services are not enabled',
        titleWhenOff: 'LocusLoop location-services OFF',
        instructions: 'LocusLoop trip tracking need this setting to enabled as \'Always\'.',
        cancelButton: 'Cancel',
        settingsButton: 'Settings'
      },
      locationUpdateInterval: 10 * 1000 // Debug for now, 10 seconds. ANDROID ONLY
    };

    // Capacitor Configuration Default.
    const configAndroid: Config = {
      reset: true,
      debug: this.debug,
      logLevel: BackgroundGeolocation.LOG_LEVEL_INFO,
      desiredAccuracy: BackgroundGeolocation.DESIRED_ACCURACY_MEDIUM,
      distanceFilter: 50,
      url: this.getApiUrl(), //  environment.gpsPostUrl,
      params: {
        ...param,
        platform: 'capacitor',
      },
      autoSync: true,
      autoSyncThreshold: 5,
      batchSync: true,
      maxBatchSize: 100,
      stopOnTerminate: false,
      startOnBoot: true,
      locationAuthorizationAlert: {
        titleWhenNotEnabled: 'LocusLoop location-services are not enabled',
        titleWhenOff: 'LocusLoop location-services OFF',
        instructions: 'LocusLoop trip tracking need this setting to enabled as \'Always\'.',
        cancelButton: 'Cancel',
        settingsButton: 'Settings'
      },
      locationUpdateInterval: 10 * 1000 // Debug for now, 10 seconds. ANDROID ONLY
    };

    let config: Config;
    let dynamicAppSetting;

    if (this.platform.is('ios')) {

      dynamicAppSetting = !!this.appSetting?.gpsConfigTsoftIos ? this.appSetting.gpsConfigTsoftIos : {};
      config = {
        ...configIos,
        ...dynamicAppSetting
      };

    } else {
      // Android
      dynamicAppSetting = !!this.appSetting?.gpsConfigTsoftAndroid ? this.appSetting.gpsConfigTsoftAndroid : {};
      config = {
        ...configAndroid,
        ...dynamicAppSetting
      };
    }
    /** THIS IS DEBUGGING ONLY  */
    this.logConfigData(config, this.platform.is('ios') ? 'ios' : 'android');
    return config;
  }

  /** Function to update the configurations at run time */
  private async updateRuntimeConfig(forceUpdate = false) {
    if (this.updateGpsConfig || forceUpdate) {
      this.updateGpsConfig = false;
      const deviceId = (await this.deviceIdService.getDeviceId()).deviceId;
      const userId = this.auth.userId;
      const platformInfo = await this.deviceIdService.platformInfo();
      const c = this.getConfig(deviceId, userId, platformInfo);
      logger.log('[GPS-CAP] Trying updating Capacitor gps configurations to : ', c);
      return new Promise<State>(async (resolve, reject) => {
        BackgroundGeolocation.setConfig(c)
          .then(s => {
            // if(s.suss)
            resolve(s);
          })
          .catch(error => {
            this.logConfigData({ ...c, isError: true, error }, this.platform.is('ios') ? 'ios' : 'android');
            reject(error);
          });
      });
    }
  }


  get isGeoLocSupported() {
    return true;
  }

  async getCurrentPosition() {
    return BackgroundGeolocation.getCurrentPosition({}) as Promise<any>;
  }

  async startBroadcastLocation(gpsStatus: ITripGpsStatus) {
    this.userGpsStatus = gpsStatus;
    logger.log('[GPS ALT] Starting Broadcasting Called on cap');
    this.gpsLogging = (!!gpsStatus);

    // update the device id and user.
    const deviceId = (await this.deviceIdService.getDeviceId()).deviceId;
    const userId = this.auth.userId;
    const platformInfo = await this.deviceIdService.platformInfo();
    const config = this.getConfig(deviceId, userId, platformInfo);

    this.gpsLogging = (typeof gpsStatus?.logGps === 'boolean') ? gpsStatus?.logGps : false;

    // Get the state
    const state = (this.isInitialized) ? await promiseWraper(this.updateRuntimeConfig(true)) :
      await promiseWraper(this.setupBroadCasting());

    /** Error happened, unknown */
    if (!state.success) {
      logger.log('[GPS] Error getting permissions', state.error);
      this.ds.openSnackBar(new MessageInfo({
        header: 'Error Getting Permission to collect GPS',
        description: 'An error happened while getting the permissions for setting up the gps request.',
        devError: state.error,
        msgCss: 'warn'
      }));
      return;
    }

    if (!state.data.enabled) {
      logger.log('[GPS ALT] calling BackgroundGeolocation.start() on cap');
      if (isNil(gpsStatus.geoFence)) {
        const removeGeoFenc = await promiseWraper(BackgroundGeolocation.removeGeofences());
        if (removeGeoFenc.success) {
          logger.log('[gps] removeGeofences] all geofences have been destroyed');
        } else {
          logger.log('[gps] removeGeofences] failed.');
        }
        BackgroundGeolocation.start();
      } else {
        BackgroundGeolocation.addGeofences([gpsStatus.geoFence as any]).then((success) => {
          BackgroundGeolocation.startGeofences();
          console.log('[gps addGeofences] success');
        }).catch((error) => {
          console.log('[gps addGeofences] FAILURE: ', error);
        });
      }
    } else {
      logger.log('[GPS Alt] State is already enabled! GPS plugin should be auto working now!!');
    }
    this._isGpsBroadCasting = true;

    /** Call the current location, just to ensure that user has permissions. This should ask/silently get permission. */

    // const currLoc = await promiseWraper(BackgroundGeolocation.getCurrentPosition({ maximumAge: 30000 }));
    // if (!!currLoc.error) {
    //   logger.log('[GPS ALT] getting current location failed. User may not have required permissions.');
    //   this.ds.alert(new MessageInfo({
    //     msgCss: 'warn',
    //     header: 'Location Permission',
    //     description: 'Location permissions were not granted! You will not be able to track the trips.'
    //   }));
    //   return;
    // }
  }

  async stopBroadcastingLocation() {
    if (this.isInitialized) {
      const state = await promiseWraper(BackgroundGeolocation.stop());
      if (!!state.error) {
        logger.log('[GPS Alt] Stopping function failed, error =>', state.error);
      }
      logger.log('[GPS Alt] State after stopBroadcastLocation', state.data);
      this._isGpsBroadCasting = false;
    }
  }

  // Like any Cordova plugin, you must wait for Platform.ready() before referencing the plugin.
  async setupBroadCasting() {
    // 1. Listen to events (see the docs a list of all available events)
    BackgroundGeolocation.onLocation(this.onLocation.bind(this));
    BackgroundGeolocation.onMotionChange(this.onMotionChange.bind(this));
    BackgroundGeolocation.onActivityChange(this.onActivityChange.bind(this));
    BackgroundGeolocation.onGeofence(this.onGeofence.bind(this));
    BackgroundGeolocation.onHttp(this.onHttp.bind(this));
    BackgroundGeolocation.onEnabledChange(this.onEnabledChange.bind(this));
    BackgroundGeolocation.onConnectivityChange(this.onConnectivityChange.bind(this));
    BackgroundGeolocation.onProviderChange(this.onProviderChange.bind(this));
    BackgroundGeolocation.onAuthorization(this.onAuthorization.bind(this));

    // 2.  Configure the plugin with #ready
    logger.log('[GPS Alt] configuring the location services.');
    logger.log(`[GPS ALT] URL is: ${this.getApiUrl()  /*environment.gpsPostUrl*/}`);

    const deviceId = (await this.deviceIdService.getDeviceId()).deviceId;
    const userId = this.auth.userId;
    const platformInfo = await this.deviceIdService.platformInfo();
    const config = this.getConfig(deviceId, userId, platformInfo);

    const sData = await promiseWraper(BackgroundGeolocation.ready(config));
    if (sData.success && !!sData.data) {
      logger.log('[GPS ALT] State after configuration: ', JSON.stringify(sData.data));
      const state = sData.data;
      this.isInitialized = true;
      this.setupBroadCastingBase(this, null);
      return state;
    } else {
      this.isInitialized = false;
      logger.error('[GPS alt] Error setting up the broadcasting of the location.', sData.error);
      throw Error('[GPS Alt] Enabling failed! Error: ' + sData.error);
    }
  }
  // #region GPS Mapping events 
  onLocation(location: Location) {
    logger.log('[Gps Alt] - ', location);
    this._isGpsBroadCasting = true;
    this.updateRuntimeConfig();
  }
  onMotionChange(event: MotionChangeEvent) {
    logger.log('[Gps Alt] [motionchange] -', event.isMoving, event.location);
    this.updateRuntimeConfig();
  }
  onActivityChange(event: MotionActivityEvent) {
    logger.log('[Gps Alt] [activitychange] -', event.activity, event.confidence);
    this.updateRuntimeConfig();
  }
  onGeofence(event: GeofenceEvent) {
    logger.log('[Gps Alt] [geofence] -', event.action, event.identifier, event.location);
    // if (!!event.identifier) {
    //   if (event.action === 'ENTER') {
    //     // Entering the danger-zone, we want to aggressively track location.
    //     BackgroundGeolocation.start();
    //   } else if (event.action === 'EXIT') {
    //     // Exiting the danger-zone, we resume geofences-only tracking.
    //     BackgroundGeolocation.startGeofences();
    //   }
    // }
    this.updateRuntimeConfig();
  }
  onHttp(event: HttpEvent) {
    logger.log('[Gps Alt] [http] -', event.success, event.status, event.responseText);
    this.updateRuntimeConfig();
  }
  onEnabledChange(enabled: boolean) {
    logger.log('[Gps Alt] [enabledchange] - enabled? ', enabled);
    this.updateRuntimeConfig();
  }
  onConnectivityChange(event: ConnectivityChangeEvent) {
    logger.log('[Gps Alt] [connectivitychange] - connected?', event.connected);
    this._isGpsBroadCasting = event.connected;
    this.updateRuntimeConfig();
  }
  onProviderChange(event: ProviderChangeEvent) {
    logger.log(`[Gps Alt providerchange] - enabled: ${event}, event.status, event.gps`);
    this.updateRuntimeConfig();
    if (!event.network || !event.gps || event.status !== 3) {
      this.ds.openSnackBar(new MessageInfo({
        header: 'Location Network Error!',
        description: 'GPS system is not enabled or not working on the phone! Kindly turn it on and try again.',
        msgCss: 'warn'
      }));
      this._isGpsBroadCasting = false;
      return;
    }
    this._isGpsBroadCasting = true;
  }
  onAuthorization(event: AuthorizationEvent) {
    logger.log('[Gps Alt] On auth fired');
    if (event.success) {
      logger.log('[GPS Alt] auth success, Response: ', event.response);
    } else {
      logger.log('[Gps Alt] Auth not successfull', event.error);
      // we need to set delay or otherwise alert may not be shown
      // setTimeout(() => {
      //   const showSettings = confirm('[GPS] App requires location tracking permission. Would you like to open app settings?');
      //   if (showSettings) {
      //     return BackgroundGeolocation.
      //   }
      // }, 1000);
    }
  }
  async isGpsBroadCasting() {
    return this._isGpsBroadCasting;
    // const res = await promiseWraper(BackgroundGeolocation.getState());
    // if (res.success && res.data?.enabled) {
    //   return true;
    // }
    // return false;
  }
  // #endregion
}
