<div class="card-div card-q" (click)="viewDetails(item.id)" >
    <div class="card-content">
        <!-- showing the pictures -->
        <trent-carousel #carouselCtrl class="pod-pictures" [pics]="item.pictures" [showIndex]="0" [hideTabs]="true"
            [containerHeight]="'250'" [eMode]="false" [hidePage]="true" [hideThumbnails]="true" [showDots]="true" (click)="routeM($event, item.id)">
        </trent-carousel>
    </div>
    <div class="card-header">
        <div class="card-header-details">
            <p (click)="viewDetails(item.id)" class="card-title">{{item.name}}</p>
            <p class="card-subtitle"></p>
        </div>
    </div>
    <div class="card-info">
        <p class="card-price">
            <!-- <span>$&nbsp; </span> -->
            <!-- <span class="price-old">80.00</span> -->
            <span class="price" *ngIf="isPriceShow">${{item.price}}</span>
        </p>
        <div class="action-icon">
            <div class="share">
                <button color="primary" (click)="share(item.id);$event.stopPropagation()" matTooltip="Share"
                    matTooltipPosition="above" class="button">
                    <mat-icon>share</mat-icon>
                </button>
            </div>
        </div>
    </div>

</div>