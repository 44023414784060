import { Injectable } from '@angular/core';
import { IDeviceIdComponents } from '@trent/models/fcm/fcm-token-data';

// @Injectable()
export class DeviceIdCoreService {
  static get deviceFingerPrintKey() {
    return 'deviceFingerPrint';
  }

  protected getPlatformInfo(d: IDeviceIdComponents, def: string) {
    if (!!d && !!d.components && !!d.components.length) {
      const p = d.components.find(x => x.key === 'platform');
      if (!!p && !!p.value) {
        return p.value;
      }
    }
    return def;
  }

  constructor() {}
}
