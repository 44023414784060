import { DialogService } from '@trent/services/dialog/dialog.service';
import { EventService } from '@trent/services/event.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SingletonService } from '@trent/services/singleton.service';
import { IAuthCoreService } from '@trent/services/auth/iauth-core.service';
import { AppState1 } from '@trent/store/root-store';
import { AuthState } from '@trent/store/auth-store/auth.state';
import { cleanListeners } from '@trent/models/utility';
import { Subscription, combineLatest } from 'rxjs';
import { UtilityService } from '@trent/services/utility.service';
import { PageHtml } from '@trent/models/cms/page-html';
import { Component, OnInit, OnDestroy, Optional, Inject } from '@angular/core';
import { Store } from '@ngxs/store';
import { CmsPageRequested, CmsState } from '@trent/store/cms-store';
import { map } from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { getUserReqdAgreements, isUpdateAgreementReqd, IAgreement, saveAgreementInCache } from '@trent/models/user-agreement';
import { BasePage } from '@trent/models/UI/base.page';
import { readErrorMessage } from '@trent/models/error-handling';
import { logger } from '@trent/models/log/logger';
import { LocalStorage } from '@trent/services/local-storage/local-storage.service';
import { AppSetting } from '@trent/models/sys/app-setting';

interface IPolicyData extends IAgreement {

  /** if whole content of the policy is to be shown */
  showPage: boolean;

  page: PageHtml;

  /** if Page is to be loaded. */
  checked: boolean;
}

@Component({
  selector: 'trent-policy-list',
  templateUrl: './policy-list.component.html',
  styleUrls: ['./policy-list.component.scss']
})
export class PolicyListComponent extends BasePage<any> implements OnInit, OnDestroy {

  isModal: boolean;
  warningCounter = 0;
  reminderText;

  finalReminder = false;

  constructor(
    store: Store, public us: UtilityService, public auth: IAuthCoreService,
    public ss: SingletonService, public router: Router, public aroute: ActivatedRoute,
    private localStorage: LocalStorage,
    es: EventService, ds: DialogService,
    @Optional() public dialogRef: MatDialogRef<PolicyListComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data) {

    super(store, ds, es);

    this.isModal = !!this.dialogRef;
    if (this.isModal) {
      this.warningCounter = !isNaN(+this.data?.warningCounter) ? +this.data.warningCounter : 0;
      this.finalReminder = this.data.finalReminder;
      if (this.warningCounter > 0) {
        this.reminderText = ` ( Reminder # ${this.warningCounter} of 3 )`;
      }
    } else {
      this.finalReminder = !!this.aroute.snapshot.queryParams.finalReminder;
      this.reminderText = '( Your Approval is Required )';
    }

    this.title = 'Policies and Agreements';
    // this.showLoading(300);
  }

  policies: IAgreement[] = [];
  policyData: IPolicyData[] = [];

  pageLoaded = false;
  appSetting: AppSetting;

  // shownPolicies: IPolicyData[];

  subs: Subscription[] = [];


  // Accordian
  step = 0;

  ngOnDestroy(): void {
    cleanListeners([], this.subs);
  }

  ngOnInit() {
    if (this.us.isPlatformBrowser) {
      const o$ = combineLatest([
        this.store.select(AuthState.outDatedAgreement),
        this.store.select(AppState1.appSettings)]);

      this.subscribe(o$, ([outdatedAgreement, appSetting]) => {
        this.appSetting = appSetting;
        if (outdatedAgreement == null) {
          this.policyData = [];
          this.policyData = [];
          this.setShowEmpty();
          // If this is a popup, close it.
          // this.dialogRef?.close(true);
        }
        if (!!outdatedAgreement && !!appSetting) {
          this.showEmpty = false;
          const reqdAgreements = getUserReqdAgreements(this.auth.userId, appSetting);
          this.policies = isUpdateAgreementReqd(outdatedAgreement.curr, reqdAgreements.curr);
          this.loadSelectedPolicyData();
        }
      }, 300 // ,
        // err => {
        //   logger.log(err);
        // }
      );
    }
  }


  save() {
    // Save in Cache
    let url: string;
    saveAgreementInCache(this.localStorage, this.auth.userId, this.appSetting);
    this.ss.userService?.updateSiteAgreements(this.auth.userId, this.policyData)
      .then(() => {
        logger.log('[Policy-List] Agreements were updated!');
        // this.dialogRef?.close(true);
        // if (this.router.config.length === 1) {
        //   this.es.emit<any>(this.es.restoreRouterConfig, null);
        //   url = this.aroute.snapshot.queryParams.returnUrl;
        //   this.router.navigateByUrl(!!url ? url : './');
        // }
      })
      .catch(err => {
        // Do not show the error as user agreement is stored in the local cache and is valid for 1 day. unless renewed again.
        // this.showAlert(readErrorMessage(err));
        this.dialogRef?.close(true);
        logger.error(err);
      });

    this.dialogRef?.close(true);
    this.es.emit<any>(this.es.restoreRouterConfig, null);
    url = this.aroute.snapshot.queryParams.returnUrl;
    this.router.navigateByUrl(!!url ? url : '/');
  }

  cancel() {
    // user did not accept.
    this.dialogRef?.close(false);
  }

  // reloadAgreements() {
  //   try {
  //     const setting$ = this.store.select(AppState1.appSettings).pipe(map(obj => AppSetting.parse(obj)));
  //     this.store.dispatch(new OutdatedAgreementRequested({
  //       user$: this.auth.user$,
  //       appSetting$: setting$,
  //       forceReload: true
  //     }));
  //   } catch (error) {
  //     logger.error('[Outdated Agreements Reload failed!');
  //   }
  // }




  // buildDefaultPolicyContainer() {
  //   // TO DO, for now, show all polcies
  //   for (const p of this.policies) { p.acceptReqd = true; }
  //   this.policies[1].acceptReqd = false;

  //   this.loadSelectedPolicyData();
  // }

  loadSelectedPolicyData() {
    this.policyData = [];
    this.policies.forEach(p => this.policyData.push({
      ...p,
      page: null,
      showPage: false,
      checked: false
    }));

    this.policyData.forEach(p => this.showPolicyDetail(p));
    // if (this.policyData.length > 0) {
    //   this.showPolicyDetail(this.policyData[0]);
    // }
  }

  checkAcceptance() {
    return this.policyData.some(x => !x.checked);
  }
  policyTextShown() {
    return this.policyData.some(p => p.showPage);
  }

  showPolicyDetail(p: IPolicyData) {
    if (!p.showPage) {
      if (!!p.page) {
        p.showPage = true;
        return;
      }
      this.store.dispatch(new CmsPageRequested({ id: `${p.pid}` }));
      this.subs.push(
        this.store
          .select(CmsState.selectPageById)
          .pipe(map(pageFn => PageHtml.parse(pageFn(p.pid))))
          .subscribe(page => {
            p.page = page;
            if (!!p.page) {
              this.pageLoaded = true;
              p.showPage = true;
              logger.log(`[Policy-list] html content loaded for policy:  ${p.name}`);
            }
          }, (err) => {
            logger.log(`[Policy-list] Error downloading html text for policy:  ${p.name}`);
            logger.error(err);
          }));
    }
  }

  /** called when policies are uptodate, go to home and reset router */
  updatedPolicyHomeClick() {
    this.es.emit<any>(this.es.restoreRouterConfig, null);
    this.dialogRef?.close(true);
    const url = this.aroute.snapshot.queryParams.returnUrl;
    this.router.navigateByUrl(!!url ? url : './');
  }


  //#region Accordian 
  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }


  //#endregion
}
