<div class="container" #container>
	<div class="left" *ngIf="totalPages > 1" (click)="currentPage !== 1 && changePage(-1)" [ngClass]="{'disabled d-none':currentPage === 1}">
        <mat-icon aria-label="" class="p-arrow">chevron_left</mat-icon>
    
    </div>
    <!-- [ngClass]="{'w-100': totalPages === 1}" -->
	<div class="cards" > 
		<div class="overflow" [ngStyle]="{'width': overflowWidth, 'left': pagePosition}" [style.height.px]="height">
			<div class="card" *ngFor="let i of arr; let index = index" [ngStyle]="{'width': cardWidth}">
                <ng-container *ngIf="slideTemplateRef" [ngTemplateOutlet]="slideTemplateRef" [ngTemplateOutletContext]="{$implicit:i}"></ng-container>
			</div>
			<div class="card view-all" *ngIf="viewAllShow">
				<button class="btn-2" (click)="viewAll()">View All</button>
			</div>
		</div>
	</div>
	<div class="right" *ngIf="totalPages > 1" (click)="currentPage !== totalPages && changePage(+1)" [ngClass]="{'disabled d-none':currentPage === totalPages}">
        <mat-icon aria-label="" class="n-arrow">chevron_right</mat-icon>
    </div>
</div>