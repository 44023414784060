<div class="inv-container">
	<div class="inv-left" *ngIf="!isHandset">
		<form class="inv-left__inner">
			<!-- <mat-expansion-panel hideToggle #panel1 [expanded]="false">
				<mat-expansion-panel-header>
					<mat-panel-title>
						Categories
					</mat-panel-title>
					<mat-icon>{{panel1.expanded? 'remove' : 'add_box'}}</mat-icon>
				</mat-expansion-panel-header>
				<ng-container *ngTemplateOutlet="categories"></ng-container>
			</mat-expansion-panel> -->
			<mat-expansion-panel hideToggle #panel2 [expanded]="false">
				<mat-expansion-panel-header>
					<mat-panel-title>
						Price Filter
					</mat-panel-title>
					<mat-icon>{{panel2.expanded? 'remove' : 'add_box_outline'}}</mat-icon>
				</mat-expansion-panel-header>
				<ng-container *ngTemplateOutlet="price"></ng-container>
			</mat-expansion-panel>
			<mat-expansion-panel hideToggle #panel3 [expanded]="false">
				<mat-expansion-panel-header>
					<mat-panel-title>
						Dealership
					</mat-panel-title>
					<mat-icon>{{panel3.expanded? 'remove' : 'add_box_outline'}}</mat-icon>
				</mat-expansion-panel-header>
				<ng-container *ngTemplateOutlet="location"></ng-container>
			</mat-expansion-panel>
		</form>
	</div>
	<div class="inv-right">
		<div class="inv-right__inner">
			<!-- Filters & Sort -->
			<div class="d-flex d-a-center d-j-between p10">
				<!-- Filter Panel -->
				<div [ngClass]="{'v-hidden': !isHandset}" class="filter-panel">
					<button mat-button class="btn-outline btn-red" (click)="filterPanel = !filterPanel">
						<mat-icon>filter_alt</mat-icon>FILTER
					</button>
				</div>
				<div class="d-flex d-a-center">
					<!-- Sort -->
					<mat-form-field class="full-width dropdown">
						<mat-select name="dropdown" class="dpc-dropdown" [(ngModel)]="dropdown"
							(ngModelChange)="onDropdown($event)">
							<mat-option *ngFor="let d of dropdownList" [value]="d">
								{{d}}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
			</div>
			<div class="filter-dropdown">
				<mat-expansion-panel *ngIf="filterPanel" [expanded]="filterPanel">
					<div class="filter-container pt30">
						<!-- <div class="d-col">
							<h3>Categories</h3>
							<ng-container *ngTemplateOutlet="categories"></ng-container>
						</div> -->
						<div>
							<h3>Price</h3>
							<ng-container *ngTemplateOutlet="price"></ng-container>
						</div>
						<div>
							<h3>Dealership</h3>
							<ng-container *ngTemplateOutlet="location"></ng-container>
						</div>
						<div class="d-col">
						</div>
					</div>
				</mat-expansion-panel>
			</div>
			<!-- Catalog -->
			<div class="cards-inv" *ngIf="list?.length > 0">
				<ng-container *ngFor="let part of list">
					<trent-spare-parts-card [item]="part"></trent-spare-parts-card>
				</ng-container>
			</div>
		</div>
	</div>
</div>

<ng-template #categories>
	<div *ngFor="let v of categoriesFilter; let i = index;" class="mt10">
		<mat-checkbox matSuffix color="primary" class="ml-4" [(ngModel)]="categoriesFilter[i].checked" [name]="v.name"
			(change)="onCategoryFilter()" [checked]="v.checked">
			<span class="text-capitalize">{{v.name}}</span>
		</mat-checkbox>
	</div>
</ng-template>

<ng-template #price>
	<div class="price-slider mt10">
		<mat-label>{{priceFilter.from}}</mat-label>
		<mat-slider [min]="0" [max]="5000" (change)="onPriceFilter()" [step]="100"  [discrete]="true" ngDefaultControl>
			<input [(ngModel)]="priceFilter.from" matSliderStartThumb>
			<input [(ngModel)]="priceFilter.to" matSliderEndThumb>
		</mat-slider>
		<mat-label>{{priceFilter.to}}</mat-label>
	</div>
</ng-template>

<ng-template #location>
	<div *ngFor="let d of dealerShipList; let i = index;" class="mt10">
		<mat-checkbox matSuffix color="primary" class="ml-4" [(ngModel)]="dealerShipList[i].checked" [name]="d.name"
			(change)="onDealership()" [checked]="d.checked">
			<span>{{d.name}}</span>
		</mat-checkbox>
	</div>
</ng-template>