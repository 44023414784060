import { RentalProductBase } from './rental-product-base';
import { ProductType } from '../product/product-type';
import { instanceToInstance, plainToInstance, Exclude, Expose, Type } from 'class-transformer';
import { IsBoolean, ValidatorOptions } from 'class-validator';
import { TrailerSummary } from '../product/trailer';
import { sanitizeDateIPoint } from '../utility';
import { MapLabel } from '../map/map-label';
import { Picture } from '../inspection/picture';
import { IValidationMsg } from '../error-handling/validation-info';
import { BidBase } from '../bid/bid-base';

@Exclude()
export class RentalTrailer extends RentalProductBase {

  constructor() {
    super();
    this.productType = ProductType.trailer;
    this.pictures = [];

  }

  @Expose()
  productSummary: TrailerSummary;

  @Expose()
  @Type(() => Picture)
  pictures: Picture[];

  get defaultImage() {
    if(this.productSummary.productType == ProductType.truck){
      return './assets/black-truck-icon.svg';
    }else{
      if (this.productSummary.isReefer) {
        // to be extended for nAxle
        return './assets/black-trailer-icon.svg';
      } else {
        return './assets/black-trailer-icon.svg';
      }
    }
  }

  // @Expose()
  // @IsDefined()
  // picRef = PicRef.new; // property for user to add new picture or copy from previous rental or select no pictures

  // @Expose()
  // @IsBoolean()
  // hasPicture: boolean; // property to query dB if pictures exist. property entered by functions if picture array length > 0

  /**https://bingmapsv8samples.azurewebsites.net/Tools/SVG%20Pushpin%20Maker/SVG%20Pushpin%20Maker.html */
  // tslint:disable-next-line:max-line-length

  // tslint:disable-next-line:max-line-length
  // icon = '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="53.2" height="63" viewBox="0 0 38 45" xml:space="preserve"><rect x="0" y="8" rx="8" ry="8" width="30" height="30" fill="{color}"/><circle cx="30" cy="8" r="8" fill="{color}"/><polygon fill="{color}" points="10,36 20,36 15,45 10,36"/><text x="30" y="12" style="font-size:12px;font-family:arial;fill:#ffffff;" text-anchor="middle">{text}</text></svg>';


  public static parse(obj) {
    if (obj == null) { return null; }
    // if data was recieved from firebase server, it may contain GeoPoint class that throw error
    // when using planToClass method. So simply the object aagin to get rid of GeoPoint class.
    obj = sanitizeDateIPoint(obj);
    // obj = instanceToPlain(obj);
    const m = plainToInstance<RentalTrailer, any>(RentalTrailer, sanitizeDateIPoint(obj));
    m.sanitize();
    return m;
  }
  clone() {
    const t = instanceToInstance(this);
    t.sanitize();
    return t;
  }

  sanitize() {
    super.sanitize();
  }
  validateSync(options?: ValidatorOptions, ): IValidationMsg {
    const r = super.validateSync(options);

    return r;
  }
  getMapLabel(): MapLabel {
    return {
      iPoint: this.startLoc.geopoint,
      // title: this.createTitle(),
      title: 'T',
      id: this.id,
      // icon: this.icon,
      rent: this.getLowestRentString(),
      productType: this.productSummary.productType

    };
  }
  /**
* Get Product Summary.
*/
  public getTrailerSummary() {
    return {
      productSummary: this.productSummary,
    };
  }
  get summary() {
    return `${this.productSummary.trailerLength}' ${this.productSummary.nAxle}-Axle ` +
      `${(this.productSummary.isReefer) ? 'Reefer Trailer' : 'Dry Van Trailer'}`;
  }
  createTitle() {
    let title: string;
    switch (this.productSummary.isReefer) {
      case false:

        title = (this.productSummary.make.split(' '))[0] + ' ' + this.productSummary.modelYear + ' ' +
          this.productSummary.nAxle + ' axle ' + 'dry-van';
        break;
      case true:
        title = (this.productSummary.make.split(' '))[0] + ' ' + this.productSummary.modelYear + ' ' +
          this.productSummary.nAxle + ' axle ' + 'reefer';
        break;
      default:
        title = null;
        break;
    }
    return title;

  }
}
