import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapContainerComponent } from './map-container.component';
import { MapGenService } from '@trent/services/map/map-gen.service';
import { MaterialModule } from '../share-module/material.module';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule
  ],
  declarations: [
    MapContainerComponent,


  ],

  exports: [
    MapContainerComponent,
  ],
  providers: [
    MapGenService
  ]
})
export class MapContainerModule { }
