import { Exclude, Expose, plainToInstance, instanceToInstance } from 'class-transformer';
import { TripTransaction } from './trip-transaction';
import { Length, ValidatorOptions, IsBoolean, IsDefined, ValidateIf } from 'class-validator';
import { sanitizeDateIPoint } from '../utility';
import { TripBase } from './trip-base';
import { IValidationMsg } from '../error-handling';
import { VehicleList } from '../promo/promo-vehicle';
import { Address } from '../address/address';
import { logger } from '../log/logger';

@Exclude()
export class Drop extends TripTransaction {

  @Expose()
  @Length(1, 10, { message: 'Truck number must be $constraint1 - $constraint2 chars long' })
  truck: string;

  @Expose()
  @Length(1, 10, { message: 'Trailer number must be $constraint1 - $constraint2 chars long' })
  trailer: string;

  @IsBoolean()
  @Expose()
  clearDelivery: boolean;

  @Expose()
  @IsDefined()
  @ValidateIf(c => c.clearDelivery === true)
  delTrackingNos: { [key: string]: { pickedFrom: string; pickedAt: Date }; }; // will be saved in dB
  
  public static parse(obj) {
    try {
      if (obj instanceof Drop) {
        // custom parse for trip transaction
        if (!obj.isApptApp) {
          obj.apptDateTime = undefined;
          obj.apptWindow = undefined;
        }
        return obj;
      }
      if (obj == null) { return null; }
      // obj = sanitizeDateIPoint(obj, ipointType);
      obj = sanitizeDateIPoint(obj);
      const m = plainToInstance<Drop, any>(Drop, obj);
      m.sanitize();
      m.address = Address.parse(m.address);
      return m;
    } catch (error) {
      logger.log('Error happened during parse', error);
      return null;
    }
  }
  clone() {
    const t = instanceToInstance(this);
    t.sanitize();
    return t;
  }

  validateSync(options?: ValidatorOptions, trip?: TripBase, index?: number, truckL?: VehicleList, trailerL?: VehicleList): IValidationMsg {
    const r = super.validateSync(undefined);
    const p = this.validateSyncBase(this, options);
    for (const key in p) {
      if (p.hasOwnProperty(key)) {
        const ele = p[key];
        r[key] = ele;
      }
    }
    if (this.delTrackingNos && !!this.valdateDelTrackingNo()) {
      r['delTrackingNos'] = [this.valdateDelTrackingNo()];
    }
    return r;
  }

}
