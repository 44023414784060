import { PageMetaTag } from './../models/cms/page-meta-tag';
import { DbRule } from '@trent/models/base';
import { IBatchSet } from './firestore.service';
import { promiseWraper } from '@trent/models/utility/helper';
import { PageHtml, PageHtmlSection } from '../models/cms/page-html';
import { Injectable, inject } from '@angular/core';
import { Title, Meta, TransferState, makeStateKey, StateKey } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { BaseHttpService } from '@trent/services/base-http.service';
import { FirestoreService } from '@trent/services/firestore.service';
import { map, take, tap } from 'rxjs/operators';
import { addSysPropBeforeCreateClientS, addSysPropBeforeUpdateClientS } from '../models/sys/doc-sys-props';
import { logger } from '@trent/models/log/logger';
import { serverTimestamp } from 'firebase/firestore';
import { ContractHtml } from '@trent/models/cms/contract-html';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PtsHomeImage } from '@trent/models/pts/pts-home-image';
import { PtsMiddleSection } from '@trent/models/pts/pts-middle-section';
import { cmsSearchServerQuery, ICmsParam } from '@trent/models/cms/cms-param';
import { Observable } from 'rxjs';
import { PagingObesrvable } from '@trent/models/observable-util/paging-obesrvable';
import { Paging } from '@trent/models/observable-util/paging';
/** HG, Only import as a type as this file use the class only as a declaration. It helps reducing the size of the build */
import type { DpcHome } from '@trent/models/dpc/dpc-home';
import { UtilityService } from './utility.service';

@Injectable({
  providedIn: 'root'
})
export class CmsService extends BaseHttpService {
  initTime: Date;
  initUrl: string;
  us: UtilityService = inject(UtilityService);
  /** if current code is executed on client browser */
  get isPlatformBrowser() { return this.us.isPlatformBrowser; }

  /** if current code is executed on SERVER SSR */
  get isPlatformServer() { return this.us.isPlatformServer; }

  isHandSet = false;

  constructor(
    store: Store,
    private db: FirestoreService,
    private title: Title,
    private meta: Meta,
    private router: Router,
    private state: TransferState,
    private http: HttpClient
  ) {
    super(store);
    this.initTime = new Date();
    this.apiName = 'api';
  }

  /**
   * https://www.concretepage.com/angular/angular-meta-service-for-meta-tags
   */
  generateTags(m: PageHtml, dynamicMetaTagsFn?: (p: PageHtml) => PageMetaTag[]) {
    // tags to be rendered only at the server
    if (!this.us.isPlatformBrowser) {
      console.log('[SSR] Page tags were rendered at the server');
      this.title.setTitle(m.title);
      const tags: PageMetaTag[] = (typeof dynamicMetaTagsFn === 'function') ? dynamicMetaTagsFn(m) : [];
      tags.push({ name: 'description', content: m.description });
      tags.push({ name: 'og:url', content: `https://tpinerental.com${this.router.url}` }); // `https://locusloop.com${this.router.url}` },
      tags.push({ name: 'og:title', content: m.title });
      tags.push({ name: 'og:description', content: m.description });
      for (const t of m.metaTags) {
        tags.push(t);
      }
      // { name: 'twitter:card', content: 'summary' },
      // { name: 'twitter:site', content: '@locusloop' }
      this.meta.addTags(tags);
      // for (const tag of m.metaTags) {
      //   logger.warn('Meta tag insertion code is TBD', tag);
      // }
    }
  }

  /** 
   * Call from Component Only: Read content from cache or db. call from component 
   * Also inject the meta tags to the page if called at server 
  */
  // tslint:disable-next-line:max-line-length
  async getPageHtmlById_comp(id: string | number, dynamicMetaTagsFn?: (page: PageHtml) => PageMetaTag[]): Promise<PageHtml> {
    // Check Cache.
    const key: StateKey<PageHtml> = makeStateKey(`${id}`);
    let data: PageHtml = this.state.get<PageHtml>(key, null);
    if (!!data) {
      logger.log(`[Cms Service] Page html data for id: ${id} was fetched from the cache (Angular State)`);
      let p = PageHtml.parse(data);
      if (this.us.isPlatformServer) {
        this.generateTags(p, dynamicMetaTagsFn);
      }
      return p;
    }
    // Only fetch as a promise from server if it is called from server.
    // calling from client should also bind the store so client side db loading should be done from
    // inside the component.
    if (this.us.isPlatformServer) {
      // const d = await promiseWraper( firstValueFrom(this.getPageHtmlById_db(id))); // .toPromise());
      const d = await promiseWraper(this.db.docP<PageHtml>(`${PageHtml.collectionName}/${id}`, 'id'));
      // const d = {
      //   success: true,
      //   data: new PageHtml(),
      //   error: undefined
      // };
      data = d.data;
      // data.title = "My Title goes here";
      // data.description  = 'my description goes here';
      // data.metaTags = [{content: 'website', property: 'og:type'}];

      if (d.success && !!d.data) {
        data = PageHtml.parse(d.data);
        this.generateTags(data, dynamicMetaTagsFn);
        // save to the state.
        this.state.set(key, d.data);
      }
      if (!d.success) {
        logger.error(`[Cms Service] Getting page html failed with id: ${id}. Error Description:`, d.error);
      }
    }
    return data;

    // else { // client
    //   // Look in to page cache as page might have been served from the angular Universal.
    //   store.dispatch(new CmsPageRequested({ id }));
    //   return {
    //     data: null,
    //     obs: this.store.select(CmsState.selectPageById).pipe(map(y => y(id)))
    //   };
    // }
  }

  /**
 * @author - MKN
 * @purpose - Get contract by Id - PDF Type
 */
  async getContractHtmlById_comp(id: string | number): Promise<ContractHtml> {
    // Check Cache.
    const key: StateKey<ContractHtml> = makeStateKey(`${id}`);
    let data: ContractHtml = this.state.get<ContractHtml>(key, null);
    if (!!data) {
      logger.log(`[Cms Service] Contract html data for id: ${id} was fetched from the cache (Angular State)`);
      return PageHtml.parse(data, ContractHtml); // ContractHtml.parse(data);
    }
    // Only fetch as a promise from server if it is called from server.
    // calling from client should also bind the store so client side db loading should be done from
    // inside the compoenent.
    if (this.us.isPlatformServer) {
      const d = await promiseWraper(this.getPageHtmlById_db(id).toPromise());
      data = <ContractHtml>d.data;
      if (d.success && !!d.data) {
        data = ContractHtml.parse(d.data);
        this.state.set(key, d.data);
      }
      if (!d.success) {
        logger.error(`[Cms Service] Getting contract html failed with id: ${id}. Error Description:`, d.error);
      }
    }
    return data;
  }

  /** Call by STORE only: Read content from database. Do not call it directly from component.
   * This should be called by store ensuring that cache is properly utilized. */
  getPageHtmlById_db(id: string | number) {
    return this.db.docWithInjectedId$<PageHtml>(
      // `${CompanyFleet.collectionName}/${resolveId(id)}`);
      `${PageHtml.collectionName}/${id}`, id).pipe(map(x => {
        if (!!x) {
          // store db fetch time.
          logger.log(`[Cms Service] Page html data for id: ${id} was fetched from the firestore db`);
          x.dbFetchTime = new Date();
        }
        return x;
      }));
  }

  async savePageHtmlById(id: string | number, data: PageHtml, uid: string, origData?: PageHtml) {
    // Rev ID is maintained.
    if (!DbRule.isRootBranch(data.id) || data.id == null) {
      throw Error(`[cms-service] page can not be created as id is not root-id (${data.id} )`);
    }

    const d = addSysPropBeforeUpdateClientS(origData, data, uid, false);
    // logger.log('content is: ', d.sections['tag0'].val);
    const a: IBatchSet<PageHtml>[] = [];
    a.push({
      ref: `${PageHtml.collectionName}/${data.id}`, //  : this.db.afs.firestore.doc(`${d.ref}`),
      data: d,
      id: data.id,
      updateIfExisting: true,
      opt: { merge: false }
    });

    if (d.isRevControlled) {
      a.push({
        ref: `${PageHtml.collectionName}/${data.id}/${PageHtml.collectionName}-rev/${d.revId}`,
        data: d,
        id: data.id,
        updateIfExisting: false,
      });
    }
    const r = await promiseWraper(this.db.batchSet(a));
    // const r = await promiseWraper(this.db.addWithId(`${PageHtml.collectionName}`, d, data.id, false));
    if (r.success) {
      // Backup here
      return data.id;
    } else {
      throw r.error;
    }
    // ---------------------
    // if (!data.isRevControlled) {
    //   const d0 = (data instanceof PageHtml) ? data.toFirebaseObj() : data;
    //   return this.db.set<PageHtml>(`${PageHtml.collectionName}/${id}`, d0, false, { merge: false });
    // }
  }

  async createPageHtml(data: PageHtml, uid: string) {
    if (!DbRule.isRootBranch(data.id)) {
      throw Error(`[cms-service] page can not be created as id is not root-id (${data.id} )`);
    }
    const d = addSysPropBeforeCreateClientS(data, uid, true);
    // const d = (data instanceof PageHtml) ? data.toFirebaseObj() : data;
    let refid: any = `${PageHtml.collectionName}/${data.id}`;
    if (data.id == null || data.id.toString().trim().length == 0) {
      // refid = this.db.afs.firestore.collection(`${PageHtml.collectionName}`).doc();
      data.id = this.db.docId(`${PageHtml.collectionName}`);
      refid = `${PageHtml.collectionName}/${data.id}`;
    }

    // if ((!!data.id && data.id.toString().trim().length > 0)) {
    const a: IBatchSet<PageHtml>[] = [];
    a.push({
      ref: refid, // userDefinedId ? `${PageHtml.collectionName}/${data.id}` : this.db.afs.firestore.doc(`${d.ref}`),
      data: d,
      id: data.id,
      updateIfExisting: false
    });

    if (d.isRevControlled) {
      a.push({
        ref: `${PageHtml.collectionName}/${data.id}/${PageHtml.collectionName}-rev/${1}`,
        data: d,
        id: data.id,
        updateIfExisting: false
      });
    }
    const r = await promiseWraper(this.db.batchSet(a));
    // const r = await promiseWraper(this.db.addWithId(`${PageHtml.collectionName}`, d, data.id, false));
    if (r.success) {
      // Backup here
      return data.id;
    } else {
      throw r.error;
    }
    // }
  }


  savePageHtmlSectionById(page: PageHtml, sectionName: string, content: PageHtmlSection, isAutoSection = false) {
    if (page.isRevControlled) {
      throw Error(`[CMS Service] Updating a section of a page where rev id is implemented, is not supported yet!`);
    }
    const id = page.id;
    let d = {
      // sections: { },
      updatedAt: serverTimestamp()
    };
    if(isAutoSection) {
      d[sectionName] = content.val;
    } else {
      d['sections'] = {};
      d['sections'][sectionName] = content;
    }
    return this.db.set<PageHtml>(`${PageHtml.collectionName}/${id}`, d, false, { merge: true });
  }

  async createPageHtmlRevCtrl(data: PageHtml, uid: string): Promise<string | number> {
    if (!data.id || data.id.toString().trim().length === 0) {
      throw Error(`Record does not have id`);
    }

    const d = addSysPropBeforeCreateClientS(data, uid);
    const r = await promiseWraper(this.db.docWithInjectedId$<PageHtml>(`${PageHtml.collectionName}/${data.id}`)
      .pipe(take(1)).toPromise());
    if (!r.success) {
      throw Error(`dB call failed`);
    }
    if (!!r.data) {
      throw Error(`Record already exists in the database. Can not insert with a duplicate key: ${data.id}`);
    }
    const pAll: Promise<void>[] = [];
    const pathRoot = `${PageHtml.collectionName}/${data.id.toString().trim()}`;
    pAll.push(this.db.set<PageHtml>(pathRoot, d));
    const pathRev = `${PageHtml.collectionName}/${data.id.toString().trim()}/${PageHtml.collectionName}-rev/${1}`;
    pAll.push(this.db.set<PageHtml>(pathRev, d));
    const res = await promiseWraper(Promise.all(pAll));
    if (!res.success) {
      throw Error(`Failed to create doc in dB: ${data.id}`);
    }
    return data.id;
  }
  async updatePageHtmlRevCtrl(oDate: PageHtml, uData: PageHtml, uid: string): Promise<string | number> {
    if (!uData.id || uData.id.toString().trim().length === 0) {
      throw Error(`Record does not have id`);
    }

    const d = addSysPropBeforeUpdateClientS(oDate, uData, uid);
    const r = await promiseWraper(this.db.docWithInjectedId$<PageHtml>(`${PageHtml.collectionName}/${uData.id}`)
      .pipe(take(1)).toPromise());
    if (!r.success) {
      throw Error(`dB call failed`);
    }
    if (!r.data) {
      throw Error(`Record ${uData.id} does not exist in database.`);
    }
    uData.incrementRev();
    d.revId = uData.revId;
    const pAll: Promise<void>[] = [];
    const pathRoot = `${PageHtml.collectionName}/${uData.id.toString().trim()}`;
    pAll.push(this.db.update<PageHtml>(pathRoot, d));
    const pathRev = `${PageHtml.collectionName}/${uData.id.toString().trim()}/${PageHtml.collectionName}-rev/${uData.revId}`;
    pAll.push(this.db.set<PageHtml>(pathRev, d));
    const res = await promiseWraper(Promise.all(pAll));
    if (!res.success) {
      throw Error(`Failed to create doc in dB: ${uData.id}`);
    }
    return uData.id;
  }

  /**
 * Here get the data from db according pageHtmlType
 * @returns 
 */
  public getAllCms_PagingObservable() {
    const p: PagingObesrvable<PageHtml, ICmsParam> =
      new PagingObesrvable<PageHtml, ICmsParam>(this.db, this.getAllCms_batch);
    return p;
  }
  private getAllCms_batch(p: Paging, o: ICmsParam): Observable<{ [key: string]: PageHtml }> {
    // console.log('Cms Server called with', p.offset, p.size);
    const col = PageHtml.collectionName;
    return this.db
      .colWithIdsInjectedNew$<PageHtml>(col,
        ref => cmsSearchServerQuery(ref, o, p))
      .pipe(
        tap(arr => {
          if (arr == null || arr.length === 0) {
            p.full = true;
          } else {
            p.lastDoc = arr[arr.length - 1];
          }
        }),
        map(arr => {
          return arr.reduce((acc, cur) => {
            const id = cur.id;
            const data = cur;
            return { ...acc, [id]: data };
          }, {});

        })
      );
  }
  // delete the carousel and pts service image or object through id in the firestore
  public deleteImage(id: string | number) {
    // Delete the doc with reference id
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.token });
    return this.http.post<{ status: boolean }>(this.getApiUrl('/pts/delete'),
      // Server validation before creating the entry.
      {
        id
      },
      { headers: headers })
      .pipe(
        tap(res => console.log('response: ', res))
      );
  }

  // upload new images for carousel 
  public uploadImage(data: PtsHomeImage) {
    // Prepare the post data for create the carousel image
    const headers = this.addBearerToken();
    // Server validation before creating the entry.
    return this.http.post<{ status: boolean }>(this.getApiUrl('/pts-carousel/create'),
      {
        data
      },
      { headers: headers })
      .pipe(
        tap(res => console.log('response: ', res))
      );
  }

  // upload new services for middle section of PTS page
  public uploadMiddleSection(data: PtsMiddleSection) {
    // Prepare the post data for create the middle section image
    const headers = this.addBearerToken();
    // Server validation before creating the entry.
    return this.http.post<{ status: boolean }>(this.getApiUrl('/pts-middleSection/create'),
      {
        data
      },
      { headers: headers })
      .pipe(
        tap(res => console.log('response: ', res))
      );
  }

  // Create new Dpc object 
  public createDpc(data: DpcHome) {
    // Prepare the post data for create new
    const headers = this.addBearerToken();
    // Server validation before creating the entry.
    return this.http.post<{ data: any }>(this.getApiUrl('/dpc-home/create'),
      {
        data
      },
      { headers: headers })
      .pipe(
        tap(res => console.log('response: ', res))
      );
  }

  // Update new Dpc object 
  public updateDpc(id: string | number, data: DpcHome) {
    // update the existing object in dpc.
    const headers = this.addBearerToken();
    // Server validation before creating the entry.
    return this.http.post<{ id: string | number, data: DpcHome }>(this.getApiUrl('/dpc-home/update'),
      {
        data, id
      },
      { headers: headers })
      .pipe(
        tap(res => console.log('response: ', res))
      );
  }

}
