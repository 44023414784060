import { ValidatorOptions } from 'class-validator/types/validation/ValidatorOptions';
import { IValidationMsg } from '../error-handling';
import { round, toValidationError } from '../utility';
import { Exclude, Expose, instanceToInstance } from 'class-transformer';
import { IsInt, Max, Min, validateSync } from 'class-validator';
import { kmToMiles, MileageUnit, mileageUnitString, milesToKm } from '../product/mileage-unit';
import { EnumHelper } from '../utility/enum-helper';


@Exclude()
export class Mileage {


  /** Odometer start of the billing period */
  @Expose()
  @IsInt()
  @Min(0)
  startOdometer?: number;
  /** Odometer end of the billing period*/
  @Expose()
  @IsInt()
  @Min(0)
  endOdometer?: number;
  /** Mileage for the current period calucated at end of the billing cycle  */

  get mileageCurrent(): number {
    if (this.startOdometer && this.endOdometer) {
      return this.endOdometer - this.startOdometer;
    } else {
      return null;
    }
  }
  /** Accumulated Mileage  */
  @Expose()
  @IsInt()
  @Min(0)
  mileageAccumulated = 0;

  @Expose()
  @IsInt()
  @Min(0)
  mileageUnit?: MileageUnit = MileageUnit.km;

  @Expose()
  @Max(50)
  comment?: string;


  validateSync(options?: ValidatorOptions): IValidationMsg {

    const m = validateSync(this, options);
    const r = toValidationError(m);

    if (this.endOdometer && this.startOdometer && this.endOdometer < this.startOdometer) {
      r['endOdometer'] = ['End Odometer reading cannot be less than Start Odometer reading'];
    }


    return r;
  }
  clone() {
    const t = instanceToInstance(this);
    t.sanitize();
    return t;
  }

  sanitize() {
  }
  /**
   * covert mileage to required unit and return string
   * @param mUnit Required mileage Unit
   * @returns string
   */
  currentMileageString(mUnit: MileageUnit): string {
    if (mUnit === this.mileageUnit) {
      return `${this.mileageCurrent} ${mileageUnitString(mUnit)}`;
    } else {
      switch (mUnit) {
        case MileageUnit.km:
          return `${milesToKm(this.mileageCurrent)} ${mileageUnitString(mUnit)}`;
        case MileageUnit.miles:
          return `${kmToMiles(this.mileageCurrent)} ${mileageUnitString(mUnit)}`;
        default:
          throw new Error('incorrect mileage unit');
      }
    }

  }
}

