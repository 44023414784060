import { DialogService } from './../../../../../../libs/services/dialog/dialog.service';
import { IAuthCoreService } from '@trent/services/auth/iauth-core.service';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SiteData } from '@trent/models/site-data';
import { UserReValidate } from '@trent/models/user/user-re-validate';
import { SignInProvider } from '@trent/models/user/user-claim';
import { ILoginService } from '@trent/services/account/ilogin-service';
import { logger } from '@trent/models/log/logger';
import { EmailAuthProvider } from '@angular/fire/auth';

@Component({
  selector: 'trent-re-validate',
  templateUrl: './re-validate.component.html',
  styleUrls: ['./re-validate.component.scss']
})
export class ReValidateComponent implements OnInit {

  signInProvider: SignInProvider = SiteData.authProvider;
  providerName = SiteData.authProvider.replace('.com', '');

  // name =  this. SiteData.user.displayName;

  hasError = false;
  errMsg = '';

  usePopup = true;

  constructor(public dialogRef: MatDialogRef<ReValidateComponent>, public acore: IAuthCoreService,
    @Inject(MAT_DIALOG_DATA) public data: UserReValidate, private auth: ILoginService, private ds: DialogService) {

  }
  ngOnInit(): void {
  }

  /** Validation using password */
  async validate() {
    switch (SiteData.authProvider) {
      case 'google.com':
      case 'facebook.com':
        return this.validateFromProfider();
      case 'password':
        this.hasError = (this.data.password == null || this.data.password.trim() === '');
        if (this.hasError) {
          this.errMsg = 'password is required';
          return;
        }
        if (this.data.runValidation) {
          const email = (!!this.data.email && this.data.email.trim().length > 0) ? this.data.email : SiteData.user.email;
          logger.log('email is: ', email);
          const credential = EmailAuthProvider.credential(email, this.data.password);
          this.auth.reauthenticateWithCredential(credential)
            .then(c => {
              logger.log('User was re-authenticated');
              this.data.credential = c;
              this.data.result = true;
              this.dialogRef.close(this.data);
            })
            .catch(err => {
              logger.log('re-auth failed', err);
              this.errMsg = 'Password is incorrect!';
              this.hasError = true;
            });
        } else {
          this.dialogRef.close(this.data);
        }
        break;
      default:
        // provider is not implemented or unknown. Just logout the user and let them login again
        logger.log('provider is not implemented or unknown. logging out the user and re-logging again');
        await this.auth.logout();
        this.dialogRef.close(this.data);

        // logger.error(`Reauth can not be completed as provider: ${SiteData.authProvider} is not implemented in Re-Auth Component`);

        break;
    }

  }

  validateFromProfider() {
    this.auth.reauthenticateWithProvider(SiteData.authProvider, this.usePopup)
      .then(c => {
        logger.log('User was reauthenticated');
        this.data.credential = <any>c;
        this.data.result = true;
        this.dialogRef.close(this.data);
      })
      .catch(err => {
        logger.log('reauth failed', err);
        this.errMsg = err.message;
        this.hasError = true;
        // this.ds.alert()
      });
  }

}
