import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CompanyService } from '@trent/services/company.service';
import { NgxsModule } from '@ngxs/store';
import { CompanyState } from './company.state';

@NgModule({
  imports: [
    CommonModule,
    NgxsModule.forFeature([CompanyState])
  ],
  providers: [
    CompanyService
  ]
})
export class CompanyStoreModule { }
