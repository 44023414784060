import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { googlePlaceToAddress } from '@trent/models/address/address-helper';
import { RentOptionParam } from '@trent/models/rental/rent-option-param';
import { Address } from '@trent/models/address/address';
import { cloneDeep } from 'lodash';
import { Subscription } from 'rxjs';
import { UtilityService } from '@trent/services/utility.service';
import { ProductType, TrailerMakes, TruckMakes } from '@trent/models/product';

@Component({
  selector: 'trent-rental-search',
  templateUrl: './rental-search.component.html',
  styleUrls: ['./rental-search.component.scss']
})
export class RentalSearchComponent implements OnInit {

  @Output() paramEmit = new EventEmitter<RentOptionParam>();
  // @Output() focusEvent = new EventEmitter<boolean>();

  @Input()
  mo: RentOptionParam;
  @Input()
  isGlobalSearch = true;

  eo: RentOptionParam = {};
  nAxle = '*';
  isHandset$: any;
  refeerDryVan: 'reefer' | 'dry-van' | 'all' = 'all';
  pTypeEnum = ProductType;
  make = '*';


  constructor(private us: UtilityService,
  ) {

  }

  ngOnInit() {
    this.isHandset$ = this.us.isHandset$;

    if (!!this.mo) {
      this.eo = cloneDeep(this.mo);
      if (!!this.eo.nAxle) {
        this.nAxle = this.eo.nAxle as any;
        this.nAxleSelect();
      }
      if (typeof this.eo.isReefer === 'boolean') {
        this.refeerDryVan = !!this.eo.isReefer ? 'reefer' : 'dry-van';
      }
    }
    if (!this.mo) {
      this.eo = {type: ProductType.truck, isDayCab: '*',  };
    }
    if (!this.eo.startAddress) {
      this.eo.startAddress = new Address();
    }
    if (this.eo.type === ProductType.truck ) {
    
      this.eo.isDayCab = !this.eo.isDayCab ? '*' : this.eo.isDayCab ;
      this.eo.make = !this.eo.make ? '*' : this.eo.make ;
    }
  }

  searchParmEmit() {
    this.paramEmit.emit(this.eo);
  }
  clearSearch() {
  }
  onPlaceLoaded(place: any) {
    if (place === undefined || place === null) {
      return;
    }
    this.eo.startAddress = googlePlaceToAddress(place);
    this.eo.center = this.eo.startAddress.geoLoc.geopoint;
  }
  nAxleSelect() {
    switch (this.nAxle) {
      case '*':
        this.eo.nAxle = undefined;
        break;
      default:
        this.eo.nAxle = +this.nAxle;
        break;
    }
  }
  reeferDrySelect() {
    switch (this.refeerDryVan) {
      case 'reefer':
        this.eo.isReefer = true;
        break;
      case 'dry-van':
        this.eo.isReefer = false;
        break;
      case 'all':
        this.eo.isReefer = null;
        break;
      default:
        break;
    }
  }
  isReeferToUI() {

  }
  get makes() {
    switch (this.eo.type) {
      case ProductType.truck:
        return TruckMakes;
      case ProductType.trailer:
        return TrailerMakes;
    
      default:
        break;
    }
  }
  dayCabSelect() {

  }
}
