import { PromoListBase } from './promo-list-base';
import { Exclude, Expose, plainToInstance, instanceToInstance } from 'class-transformer';
import { sanitizeDateIPoint } from '../utility/sanitize-helper';
import { ValidatorOptions } from 'class-validator';
import { IValidationMsg } from '../error-handling/validation-info';
import { logger } from '../log/logger';
import { isEqual } from 'lodash';
import { ProductType } from '../product/product-type';
import { SalesProductListItem } from './sales-vehicle-list-item';
import { SalesOptionStatus, SalesProductBase, SalesTruck } from '../sales-option';
import { PromoListType } from './promo-list-type';

@Exclude()
export class SalesTruckItem extends SalesProductListItem {
	constructor() { super(); }
	
	@Expose()
	isDayCab: boolean;
	@Expose()
	isIRP: boolean;
	@Expose()
	isAuto: boolean;

	get summary() {
		return (
			`${this.isDayCab ? 'Day Cab Truck, ' : ' Sleeper Truck, '} ${this.isIRP ? 'IRP Plated, ' : 'Local Plated, '}` +
			`${this.isAuto ? 'Automatic Transmission' : 'Manual Transmission'}`
		);
	}

	public static parse(obj) {
		if (obj == null) { return null; }
		const m = plainToInstance<SalesTruckItem, any>(SalesTruckItem, sanitizeDateIPoint(obj));
		return m;
	}
}

@Exclude()
export class SalesTrailerItem extends SalesProductListItem {
	constructor() { super(); };
	
	@Expose()
	nAxle: number;
	@Expose()
	trailerLength: number;
	@Expose()
	isReefer: boolean;

	get summary() {
		return `${this.trailerLength}' ${this.nAxle}-Axle ` +
			`${(this.isReefer) ? 'Reefer' : 'Dry-Van'} Trailer`;
	}
	public static parse(obj) {
		if (obj == null) { return null; }
		const m = plainToInstance<SalesTrailerItem, any>(SalesTrailerItem, sanitizeDateIPoint(obj));
		return m;
	}
}

/** List of Carriers registered for trip tracking promo */
@Exclude()
export class SalesTruckAggregate extends PromoListBase {

	public static readonly salesTruckAggregateID = 'sales-truck-aggregate';
	public static readonly salesTrailerAggregateID = 'sales-trailer-aggregate';

	@Expose()
	list: { [key: string]: SalesProductListItem };

	public static parse(obj) {
		if (obj == null) { return null; }
		const m = plainToInstance<SalesTruckAggregate, any>(SalesTruckAggregate, sanitizeDateIPoint(obj));
		for (const key in m.list) {
			if (Object.prototype.hasOwnProperty.call(m.list, key)) {
				const element = m.list[key];
				switch (m.listType) {
					case PromoListType.salesTrailer:
						m.list[key] = SalesTrailerItem.parse(element);
						break;
					case PromoListType.salesTruck:
						m.list[key] = SalesTruckItem.parse(element);
						break;
					default:
						break;
				}
				m.list[key].key = !m.list[key].key ? key : m.list[key].key;
			}
		}
		m.sanitize();
		return m;
	}
	public static salesAggId(p: ProductType) {
		switch (p) {
			case ProductType.trailer:
				return SalesTruckAggregate.salesTrailerAggregateID;
			case ProductType.truck:
				return SalesTruckAggregate.salesTruckAggregateID;
			default:
				throw new Error(`Product Type ${p}`);
		}
	}

	validateSync(options?: ValidatorOptions): IValidationMsg {
		const r = this.validateSyncBase(this);
		return r;
	}

	clone() {
		const t = instanceToInstance(this);
		t.sanitize();
		return t;
	}
	/**
	 * adds and updates SalesTruckAggregate list when new sales-option is added or updated
	 * @param salesProduct updated or new sales-option
	 * @param id new or updated sales option id
	 * @param returns SalesTruckAggregate when item is added or updated or deleted
	 * @param returns SalesTruckAggregate when item is added or updated
	 */
	updateSalesAggregate(salesProduct: SalesProductBase, id: string | number): { changeRequired: boolean, uAggregate?: SalesTruckAggregate } | null {
		// clone from the doc from db
		const uAggregate = this.clone();
		try {
			// intialize list
			if (!uAggregate.list) { uAggregate.list = {} as { [key: string]: SalesTruckItem; }; }
			// check if sales option status is closed or obsolated, if so remove from the list
			if ((salesProduct.salesOptionStatus === SalesOptionStatus.closed ||
				salesProduct.salesOptionStatus === SalesOptionStatus.obsolete) && !!uAggregate.list[id]) {
				uAggregate.list[id] = null;
			} else {
				// intialize dictionary item
				if (!uAggregate.list[id]) {
					uAggregate.list[id] = new SalesTruckItem();
				}
				uAggregate.list[id].geoLoc = salesProduct.storeLocationSummary.address.geoLoc;
				uAggregate.list[id].make = (salesProduct as SalesTruck).productSummary.make;
				uAggregate.list[id].modelYear = (salesProduct as SalesTruck).productSummary.modelYear;
				uAggregate.addSpecificProps(`${id}`, (salesProduct as SalesTruck));
			}
			// check if change is required. Compare before and after dictionary item.
			if (!!this.list && (!!this.list[id] && isEqual(uAggregate.list[id], this.list[id]))) {
				return { changeRequired: false };
			} else {
				return { changeRequired: true, uAggregate };
			}
		} catch (error) {
			logger.info('[SalesTruckAggregate] failed to getSalesTrailerItem ', error);
			return null;
		}
	}

	addSpecificProps(id: string, salesVeh: SalesTruck) {
		(this.list[id] as SalesTruckItem).isDayCab = (salesVeh as SalesTruck).productSummary.isDayCab;
		(this.list[id] as SalesTruckItem).isAuto = (salesVeh as SalesTruck).productSummary.isAuto;
		(this.list[id] as SalesTruckItem).isIRP = (salesVeh as SalesTruck).productSummary.isIRP;
	}
}