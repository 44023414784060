import { BaseFormAuth } from '../../../models/base-form-auth';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { UserPhone } from '@trent/models/user/user-phone';
import { Store } from '@ngxs/store';
import { MatDialog } from '@angular/material/dialog';
import { OnDestroy } from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';
import { SiteData } from '@trent/models/site-data';
import { ValidationOptions } from 'class-validator';
import { promiseWraper, phoneRegex } from '@trent/models/utility';
import { UserService } from '@trent/services/user.service';
import { SingletonService } from '@trent/services/singleton.service';
import { EventService } from '@trent/services/event.service';
import { DialogService } from '@trent/services/dialog/dialog.service';
import { FormDisplayMode } from '@trent/models/UI';
import { readErrorMessage, MessageInfo } from '@trent/models/error-handling';
import { Router } from '@angular/router';
import { UserRef } from '@trent/models/user/user-ref';
import { logger } from '@trent/models/log/logger';
export enum PhoneStatus {
  default = 0,
  phoneEdit = 1,
  phoneEntered = 2,
  isWaiting = 3,
  codeEntered = 4,
}
@Component({
  selector: 'trent-phone-change',
  templateUrl: './phone-change.component.html',
  styleUrls: ['./phone-change.component.scss']
})
export class PhoneChangeComponent extends BaseFormAuth<UserPhone> implements OnInit, OnDestroy {

  mode = FormDisplayMode;
  
  @ViewChild('phoneForm', { static: false })
  phoneForm: NgForm;

  hasCodeError = false;

  showPhoneCode = false;

  phoneCodeMsg = 'Enter verifiation code';

  pStatus = PhoneStatus;

  phoneStatus = PhoneStatus.default;
  @Output()
  phoneChanged = new EventEmitter<boolean>();

  private userService: UserService;

  @Input() phoneNumberParam: string;


  constructor(
    dialog: MatDialog, // public store: Store, private auth: AuthService, ) {
    store: Store,
    private singleton: SingletonService,
    // public localStorage: LocalStorage,
    es: EventService,
    ds: DialogService,
    private router: Router) {
    super(dialog, store, ds, es);
    this.userService = this.singleton.userService;
    this.m = SiteData.user.getUserPhone();
    this.em = this.m.clone();
    if (!!this.em.phoneNumber) {
      this.displayMode = FormDisplayMode.read;
    } else {
      this.displayMode = FormDisplayMode.edit;

    }
  }

  ngOnInit() {
    //     this.mSubscription.push(this.store.select(AuthState1.userSignInProvider)
    //   .subscribe(x => this.signInProvider = <any>x));
    // this.mSubscription.push(this.store.select(AuthState1.userClaim)
    //   .subscribe(uc => {
    //     this.claim = uc;
    //     this.m.currEmail = this.claim.email;
    //     this.em.currEmail = this.claim.email;
    //   }));

    if (this.phoneNumberParam) {
      this.loadOtpPage();
    }
  }

  ngOnDestroy(): void {
  }


  async loadOtpPage() {
    this.em.phoneNumber = this.phoneNumberParam;
    await this.validate();
  }

  getValidationOption() {
    const option: ValidationOptions = {};
    option.groups = (this.showPhoneCode) ? ['code'] : ['phone'];
    return option;
  }

  validateForm(form) {
    this.phoneForm = form;
    // set up the and check validation.
    this.setFormValidation<UserPhone>(UserPhone, form, () => {
      return { model: this.em, option: this.getValidationOption() };
    });

    this.markDirtyAndValidate(form);
  }

  async requestPhoneVerification(form) {
    this.phoneStatus = PhoneStatus.isWaiting;
    // if (!!this.em.phoneNumber && this.em.phoneNumber.length > 0 && !this.em.phoneNumber.startsWith('+1')) {
    //   this.em.phoneNumber = `+1${this.em.phoneNumber}`;
    // }
    this.validateForm(form);
    await this.validate();
  }

  async validate() {
    const tempR = this.em.validateSync(this.getValidationOption());
    logger.log('validation Results: ', tempR);
    if (!!this.em.phoneNumber && this.em.phoneNumber.length > 0 && !this.em.phoneNumber.startsWith('+1')) {
      this.em.phoneNumber = `+1${this.em.phoneNumber}`;
    }
    if (tempR == null || Object.keys(tempR).length === 0) {
      const reauth = await promiseWraper(this.reAuth());
      if (reauth.success && !!reauth.data) {
        this.showLoading(300);
        this.userService.requestPhoneToken(this.em.phoneNumber)
          .then(() => {
            this.hideLoading();
            // const msg = new MessageInfo({
            //   msgCss: 'success',
            //   header: 'Enter Verification Code',
            //   description: `A verification code has been sent to ${this.em.phoneNumber}.
            //    Please enter the code below and click Confirm.`
            // });
            // this.showAlert(msg);
            this.showPhoneCode = true;
            this.phoneCodeMsg = `A sms text has been sent to the provided phone number.
            Please verify the phone code to certify your new phone number`;
          })
          .catch(err => {
            this.hideLoading();
            this.showAlert(readErrorMessage(err));
            this.phoneStatus = PhoneStatus.default;
            // this.showAlert(new MessageInfo({
            //   msgCss: 'danger',
            //   header: 'Phone change error',
            //   description: err.message
            // }));
          });
      }


      // Reconfirm password before proceeding
      // this.openDialog();
    }
  }

  async verifyAndUpdatePhone() {
    if (!!this.em.phoneNumber && this.em.phoneNumber.length > 0 && !this.em.phoneNumber.startsWith('+1')) {
      this.em.phoneNumber = `+1${this.em.phoneNumber}`;
    }
    this.validateForm(this.phoneForm);
    const tempR = this.em.validateSync(this.getValidationOption());
    logger.log('validation Results: ', tempR);
    if (tempR == null || Object.keys(tempR).length === 0) {
      const reauth = await promiseWraper(this.reAuth());
      if (reauth.success && !!reauth.data) {
        this.showLoading(300);
        this.userService.verifyAndUpdatePhone({ phone: this.em.phoneNumber, token: this.em.phoneCode })
          .then(() => {
            this.hideLoading();
            const msg = new MessageInfo({
              msgCss: 'primary',
              header: 'Phone Updated',
              description: 'THe phone number is successfully updated.'
            });
            // this.showAlert(msg);
            this.dialogService.alert(msg, undefined, () => {
              this.showPhoneCode = false;
              this.displayMode = FormDisplayMode.read;
              this.showPhoneCode = false;
              this.em.phoneCode = '';
              //
              // const userRef = this.store.selectSnapshot(AuthState.userRef);
              this.phoneChanged.emit(true);


            });
          })
          .catch(err => {
            this.hideLoading();
            this.showAlert(readErrorMessage(err));
          });

      }


      // if (this.phoneCode == null || this.phoneCode.trim().length < 4) {
      //   this.hasCodeError = true;
      //   this.markDirtyAndValidate(this.phoneForm);
      //   return;
      // }
    }
  }

  cancel() {
    if (this.m.phoneNumber) {
      this.displayMode = FormDisplayMode.read;
    }
    this.showPhoneCode = false;
    this.em = this.m.clone();
    if (!!this.phoneForm) {
      this.phoneForm.resetForm(this.em);
    }
    this.phoneStatus = PhoneStatus.default;
  }
  get userAction(): string {

    return !!this.em.phoneNumber ? 'Update your phone no' : 'Add phone number';
  }
  checkCode(ng: NgModel) {
    if (ng.value && ng.value.length === 6) {
      this.phoneStatus = PhoneStatus.codeEntered;
    }
  }
  checkPhone(phoneNumber: string) {
    if (phoneNumber && phoneRegex(phoneNumber) && phoneNumber !== this.m.phoneNumber) {
      this.phoneStatus = PhoneStatus.phoneEntered;
    }
  }
}
