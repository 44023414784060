import { UtilityService } from './../utility.service';
import { Injectable } from '@angular/core';
import { IAuthCoreService } from './iauth-core.service';
import { AuthCoreService } from './auth-core.service';
import { Store } from '@ngxs/store';
import { logger } from '@trentm/log/logger';
import { Auth } from '@angular/fire/auth';

@Injectable()
export class AuthCoreWebService extends AuthCoreService implements IAuthCoreService {
  constructor(
    store: Store,
    auth: Auth,
    us: UtilityService
    // afs: FirestoreService
  ) {
    super(store, auth, us);
    this.init();
  }

  init() {
    logger.log('auth core-web was called.');
  }
}
