
/** Log severty, mimiced from cloud logging */
export enum LogLevel {
  ALL = 0,
  DEBUG = 10,
  INFO = 20,
  WARN = 30,
  ERROR = 40
}

/** Log Severity Names  */
export type LogLevelNames = keyof typeof LogLevel;

export interface ILogger {

  name?: string;

  log: (message?: any, ...optionalParams: any[]) => void;

  info: (message?: any, ...optionalParams: any[]) => void;

  warn: (message?: any, ...optionalParams: any[]) => void;

  error: (message?: any, ...optionalParams: any[]) => void;
}

/** Loggers used in the applications.  */
export interface IAppLoggers {
  default: ILogger;
  // gpsLogger: ILogger;
  getLogger: (name: string) => ILogger;
  container: { [name: string]: ILogger };
}



