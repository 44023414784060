import { Reviews, ReviewType } from "../reviews/reviews";

export const parseReviews = (obj: any): Reviews => {
    if (obj == null) { return null; }

    const r = <Reviews>obj; // cast it to base, remember it is still a javascript obj at run time.
    return Reviews.parse(r);
};
/**
 * 
 * @param obj 
 * @returns Reviews []
 */
export const parseReviewsArray = (obj: any[]): Reviews[] => {
    const r = !!obj ? obj.map(o => <Reviews>parseReviews(o)) : null;
    return r;
};
