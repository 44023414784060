import { RoleCompany } from './role-company';
import { keys } from 'lodash';
import { cleanupUndefined } from '../utility/helper';

/**
 * Keep it in sync with @interface ICompanyRoleClaim
 * This is used inside @class UserProfile
 */
export interface ICompanyRoleProfile {
  /** key is the company id */
  [key: string]: {
    /** User roles for the given company */
    roles: RoleCompany;
    /** name of the company */
    cName: string;
    /** If this company is the default company. */
    // default: boolean;
  };
}

/**
 * Keep it insync with @interface ICompanyRoleProfile
 */
export interface ICompanyRoleClaim {
  /** Key is the company id */
  [key: string]: {

    /** Role(s) for the given company, Note: r => roles */
    r: RoleCompany;

    /** Name of the company (optional), Note: n => cName */
    n?: string;

  };
}



export const mapCompRoleToProfile = (c: ICompanyRoleClaim): ICompanyRoleProfile => {
  const o: ICompanyRoleProfile = {};
  for (const key of keys(c)) {
    const item = c[key];
    o[key] = {
      roles: { ...item.r},
      cName: item.n,
      // default: item.d
    };
  }
  return cleanupUndefined(o); //  as ICompanyRoleProfile;
  // return o;
};

export const mapCompRoleToClaim = (c: ICompanyRoleProfile): ICompanyRoleClaim => {
  const o: ICompanyRoleClaim = {};
  for (const key of keys(c)) {
    const item = c[key];
    o[key] = {
      r: { ...item.roles },
      n: item.cName
      // d: item.default
    };
  }
  return cleanupUndefined(o); //  as ICompanyRoleClaim;
  // return o;
};
