<h1 mat-dialog-title>Hello {{ acore.displayName$ | async }}</h1>
<ng-container *ngIf="signInProvider == 'password'; else social">
  <div mat-dialog-content>
    <!-- <p>Re-confirm your password</p> -->
    <mat-form-field style="width: 100%">
      <mat-label>Enter current password</mat-label>
      <input type="password" matInput [(ngModel)]="data.password">
      <mat-hint *ngIf="hasError" class='warn' align='end'>{{errMsg}}</mat-hint>
    </mat-form-field>
  </div>
  <div mat-dialog-actions>
    <button mat-stroked-button  [mat-dialog-close]="null">  <mat-icon>close</mat-icon> Cancel</button>
    <button mat-button (click)="validate()" cdkFocusInitial>  <mat-icon>done</mat-icon> Validate</button>
  </div>
</ng-container>

<ng-template #social>
  <div mat-dialog-content>
    Please re-authenticate from your <b>{{providerName}}</b> account before proceeding.
  </div>
  <div class="cb">
    <mat-checkbox [(ngModel)]="usePopup" >Use Popup Window</mat-checkbox>
  </div>   
    <p>
      <mat-hint *ngIf="hasError" class='warn error' align='end'>{{errMsg}}</mat-hint>
    </p>
  <div mat-dialog-actions>
    <button mat-stroked-button  class="md-18" color="primary" [mat-dialog-close]="null"> <mat-icon>close</mat-icon>  Cancel</button>
    <button mat-raised-button color="primary"  (click)="validate()" cdkFocusInitial class="md-18 ml-4"> <mat-icon>done</mat-icon>  Proceed</button>
  </div>
</ng-template>
