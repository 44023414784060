import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IUserAuthClaim } from '@trent/models/user/user-claim';
import { User } from '@angular/fire/auth';

@Injectable()
export abstract class IAuthCoreService {

  isAdmin$: Observable<boolean>;
  isAdmin: () => boolean;

  displayName$: Observable<string>;

  isLoggedin$: Observable<boolean>;
  isLoggedin: () => boolean;

  user$: Observable<User>;

  user: User;

  userId: string;

  authClaim: IUserAuthClaim;

  isEditor$: Observable<boolean>;
  isEditor: () => boolean;

  isCredit$: Observable<boolean>;
  isCredit: () => boolean;

  sendVerificationEmail: () => Promise<void>;

  /** Launched when website or app is launched. */
  init: () => void;

  logout: () => Promise<void>;

  // Lazy Loaded References to ensure only singleton services are used
  // Because these services were lazy loaded, but their need is singleton.
  // singletonContainer: { [key: string]: any };

}
