import { Component, OnInit, ChangeDetectionStrategy, Input, OnDestroy, ChangeDetectorRef, Output, EventEmitter, AfterViewInit, ViewChild, Renderer2 } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Observable, Subscription } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { map, shareReplay } from 'rxjs/operators';
import { EventService, IEventListener } from '@trent/services/event.service';
import { PaneType } from '@trent/models/UI/menu-data';
import { UtilityService } from '@trent/services/utility.service';
import { Store } from '@ngxs/store';
import { AppState1, ScreenDisplayMode } from '@trent/store/root-store';

@Component({
  selector: 'trent-slide-panel',
  templateUrl: './slide-panel.component.html',
  styleUrls: ['./slide-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('slide', [
      state('left', style({ transform: 'translateX(0)' })),
      state('right', style({ transform: 'translateX(-50%)' })),
      transition('* => *', animate(300))
    ])
  ]
})
export class SlidePanelComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('pnlDiv') pnlDiv;

  sub: Subscription[] = [];
  private el: IEventListener[] = [];

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map(result => result.matches),
    shareReplay()
  );

  isHandset = true;

  /// Left div width : Desktop only
  leftStyleDt: any;

  /// Right div width : Desktop only
  rightStyleDt: any;

  /** left div width in percentage number : Desktop Only. */
  private leftPctDt: number;
  minWidth: string;

  // @HostBinding('style.height') 
  sliderHt = 750;

  @Input() set leftWidthDt(w: number) {
    this.leftPctDt = w;
    this.leftStyleDt = `${w}%`;
    this.rightStyleDt = `${100 - w}%`;
  }

  @Input() overY: boolean = true;  
  @Input() heightRequire: boolean = true;  

  // Actual pane specified on the animation.
  public activePaneApplied: PaneType;

  // Requested pane.
  private activePaneRequested: PaneType;
  @Input()
  reduceBy = 100;

  // Setter
  @Input() set activePane(p: PaneType) {
    this.activePaneRequested = p;
    // animate only, if it is not a mobile.
    if (this.isHandset) {
      this.activePaneApplied = p;
    }
  }

  @Output() activePanChg = new EventEmitter();

  constructor(private breakpointObserver: BreakpointObserver, private cdr: ChangeDetectorRef, private es: EventService,
    private us: UtilityService,
    private store: Store,
    private renderer: Renderer2
  ) {
    this.leftWidthDt = 25;
  }

  ngOnInit() {
    this.sub.push(
      this.isHandset$.subscribe(x => {
        this.isHandset = x;
        // this.className = this.isHandset ? 'panes-mb' : 'panes-dt';
        if (this.isHandset) {
          this.leftStyleDt = undefined;
          this.rightStyleDt = undefined;
          if (this.activePaneApplied !== this.activePaneRequested) {
            this.activePaneApplied = this.activePaneRequested;
          }
        } else {
          this.leftWidthDt = this.leftPctDt;
          // Going to desktop mode, animate to be reset.
          this.activePaneApplied = 'left';
          this.minWidth = '350px';
        }
        //  Note: cdr is only needed because of the change detection policy on this component.
        //  changeDetection: ChangeDetectionStrategy.OnPush in the component attribute on top of this page.
        this.cdr.detectChanges();
      })
    );

    this.el.push(
      this.es.listen<PaneType>(this.es.setActivePan, p => {
        this.activePane = p;
        // this.cdr.detectChanges();
        this.activePanChg.emit(this.activePane);
      })
    );
  }
  ngOnDestroy(): void {
    this.sub.forEach(x => x.unsubscribe());
    this.el.forEach(x => x.ignore());
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.getWindowSize();
    }, 100);
  }
  // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
  getWindowSize() {
    if (this.us.isPlatformBrowser) {

      // We listen to the resize event
      // window.addEventListener('resize', () => {
      // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
      // const vh = window.innerHeight * 0.01;
      // Then we set the value in the --vh custom property to the root of the document
      // document.documentElement.style.setProperty('--vh', `${vh}px`);
      // this.mobileHeight = calc(100vh - `${vh}`)
      // });


      if (!!this.us.isPlatformBrowser) {
        this.sub.push(this.store.select(AppState1.currentScreenDisplay)
          .subscribe(x => {
            const browserHeight = x.height;
            if (x.screen === ScreenDisplayMode.mobile) {
              this.sliderHt = browserHeight - 64 - this.reduceBy + 50;
              if(this.heightRequire){
                this.renderer.setStyle(this.pnlDiv.nativeElement, 'height', `${this.sliderHt}px`);
              }
              console.log('[slider] Height set to be: ' + this.sliderHt);
            } else {
              // this.paymentTableHt = x.height - 60 - 40 - statusBarHeight;
            }
          }));
      }
    }
  }

}
