import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Store } from '@ngxs/store';
import { fromFailedValidation, MessageInfo, readErrorMessage } from '@trent/models/error-handling';
import { logger } from '@trent/models/log/logger';
import { EnquiryType, ProductDetails, PtsEnquiry } from '@trent/models/pts/pts-enquiry';
import { BaseForm } from '@trent/models/UI/base-form';
import { DialogService } from '@trent/services/dialog/dialog.service';
import { EventService } from '@trent/services/event.service';
import { PtsService } from '@trent/services/pts.service';
import { AuthState } from '@trent/store/auth-store/auth.state';
import { PhoneChangeComponent } from '../../../shared/phone-change/phone-change.component';
import { UserProfile } from '@trent/models/user';
import { UserAnonymousService } from '@trent/services/user-anonymous.service';

@Component({
	selector: 'trent-pts-enquiry-form',
	templateUrl: './pts-enquiry-form.component.html',
	styleUrls: ['../pts-common.scss'],
})
export class PtsEnquiryFormComponent extends BaseForm<PtsEnquiry> implements OnInit {
	@ViewChild('form', { static: false }) form: NgForm;
	@ViewChild('ph', { static: false }) ph: PhoneChangeComponent;

	@Input() type: EnquiryType;
	@Input() returnUrl: string;
	@Input() qParam;
	@Input() productDetails: ProductDetails;
	@Output() closeDialog: EventEmitter<boolean> = new EventEmitter();
	phoneNo: string;
	isReadOnly: boolean = false;
	isPhoneVerificationRequired: boolean = false;
	isLoggedIn;
	guest: boolean = false;
	userProfile: UserProfile;

	constructor(
		eventService: EventService,
		dialogService: DialogService,
		store: Store,
		private ptsService: PtsService,
		public userAnonymousService:UserAnonymousService
	) {
		super(store, dialogService, eventService);
	}

	ngOnInit(): void {
		// Checking user is logged or not
		this.store.select(AuthState.isLoggedIn).subscribe(u => {
			this.isLoggedIn = !!u ? true : false;
			this.guest = !!u ? true : false;
		});
		this.initialize();
		// here taking the phone number from user profile
		this.store.select(AuthState.user).subscribe(u => {
			this.userProfile = u;
			if (!!u) {
				this.em.phoneNumber = u?.phoneNumber;
				this.em.userDetails.email = this.userProfile.email;
				this.em.userDetails.displayName = this.userProfile.displayName;
				this.em.userDetails.uid = this.userProfile.id;
				if(u.phoneNumber){
					this.isReadOnly = true;
				}
			}
		});
	}
	// Before save checking the form validation;
	checkForm() {
		this.markDirtyAndValidate(this.form);
		const tempR = this.em.validateSync();
		if (Object.keys(tempR).length > 0) {
			logger.log('validation failed');
			this.showAlert(fromFailedValidation(tempR).messageInfo);
			return false;
		} else {
			return true;
		}
	}

	cancel() {
		this.isPhoneVerificationRequired = false;
	}

	initialize() {
		// Here initialed the instance
		this.m = new PtsEnquiry();
		this.m.enquiryType = this.type;
		this.em = this.m.clone();
	}

	submit() {
		this.em.userDetails.phoneNumber = this.em.phoneNumber;
		this.em.userDetails.altPhoneNumber = this.em.altPhoneNumber;
		this.em.userDetails.displayName = !!this.em.userDetails.displayName ? this.em.userDetails.displayName : 'Guest user'

		if (this.productDetails) {
			this.em.productDetails = this.productDetails;
		}

		if (!this.checkForm()) {
			return;
		}
		this.showLoading(10);
		this.closeDialog.emit(true);
		let ser;
		if (this.isLoggedIn) {
			ser = this.ptsService.createEnquiry(this.em).toPromise();
		} else {
			ser = this.userAnonymousService.createEnquiryAsGuest(this.em).toPromise();
		}
		ser.then(b => {
			const msg = new MessageInfo({
				msgCss: 'accent',
				header: 'Enquiry Created',
				description: 'Enquiry Created successfully.'
			});
			this.showAlert(msg);
			this.hideLoading();
			if (this.em.isPhoneNumberSavedInUserProfile) {
				this.isPhoneVerificationRequired = true;
				this.ph.checkPhone(this.em.phoneNumber);
			} else {
				this.initialize();
			}
		}).catch(err => {
			this.hideLoading();
			this.showAlert(readErrorMessage(err));
		});
	}

	phoneChanged() {
		this.closeDialog.emit(true);
	}
	onGuest() {
		this.guest = true;
	}

}
