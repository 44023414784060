import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'trent-dpc-about',
  templateUrl: './dpc-about.component.html',
  styleUrls: ['../dpc-common.scss', './dpc-about.component.scss']
})
export class DpcAboutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
