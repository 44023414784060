import { PromoListBase, ListValidationGroup } from './promo-list-base';
import { Exclude, Expose, plainToInstance, instanceToInstance } from 'class-transformer';
import { IsDefined, Validator, ValidatorOptions, IsEmail, validateSync, isEmail, IsBoolean, ValidateIf } from 'class-validator';
import { sanitizeDateIPoint, toValidationError } from '../utility';
import { PromoListType } from './promo-list-type';
import { IValidationMsg } from '../error-handling/validation-info';
import { ItemValidationGroup, PromoListItem } from './promo-list-item';
import { ItemStatus } from './item-status';
import { pickBy, isEmpty } from 'lodash';
import { VendorCompanySummary } from '../company';
import { CarrierCompanySummary } from '../company/company-carrier';
import { UserProfile } from '../user/user-profile';
import { UserEmail } from '../user/user-email';

const valueValidition: ItemValidationGroup = 'valueValidition';
const keyValidition: ItemValidationGroup = 'keyValidition';
@Exclude()
export class TripTrackerListItem extends PromoListItem {
  constructor() {
    super();
    this.itemStatus = ItemStatus.active;
  }
  @IsEmail()
  @IsDefined({ message: 'needs valid email' })
  @Expose()
  key: string;  // this is dictionary key, added in values for ui validation, saved in db as key

  // @Length(2, 254)
  @Expose()
  name?: string;
  // @Length(1, 254)
  @Expose()
  tripTrackerId?: string;

  domainName?: string;
  @Expose()
  email?: string;
  phone?: string;

  @Expose()
  @IsBoolean()
  @ValidateIf(v => v.key === 'TBD')
  isPlaceHolder: boolean;



  public static parse(obj) {
    if (obj == null) { return null; }
    const m = plainToInstance<TripTrackerListItem, any>(TripTrackerListItem, sanitizeDateIPoint(obj));
    // m.sanitize();
    return m;
  }
  clone() {
    const t = instanceToInstance(this);
    // t.sanitize();
    return t;
  }
  validateSyncGroup(group?: ItemValidationGroup): IValidationMsg {
    return this.validateSync({ groups: [group] });
  }
  validateSync(options?: ValidatorOptions, list?: TripTrackerList): IValidationMsg {
    const validate = new Validator();
    const r = validateSync(this, options);
    const m = toValidationError(r);
    if (!!list && !!list.isDuplicate(this.key)) {
      m['key'] = [`duplicate entry ${this.key}`];
    }
    return m;
  }
}
export type TripTrackerListValidationGroup = 'selectMode' | 'addMode';
const selectMode: TripTrackerListValidationGroup = 'selectMode';
const addMode: TripTrackerListValidationGroup = 'addMode';

/** List of Notofiers registered for carrier promo */
@Exclude()
export class TripTrackerList extends PromoListBase {


  @Expose()
  @IsDefined({ message: 'list needs to be defined', groups: [addMode] })
  list: { [key: string]: TripTrackerListItem };  // key in this case is phone number

  @IsDefined({ message: 'email needs to be defined', groups: [selectMode] })
  /** email for input field in ui */
  entry: { [key: string]: TripTrackerListItem };
  /** email chips in ui */
  entries: { [key: string]: TripTrackerListItem } = {};

  key: string; // binds to new item key to be added
  // @Expose()
  uploadItem: string;
  /** prop required for ui to display inActive record and give option to activate */
  activateItem: string;

  // vendorCompanySummary?: VendorCompanySummary;
  @Expose()
  carrierCompanySummary: CarrierCompanySummary;

  @Expose({ toPlainOnly: true })
  get emailArray () {
    const keys = !!this.list && !isEmpty(this.list) ? Object.keys(this.list) : [];
    return keys.filter(f => isEmail(f) && this.list[f].itemStatus === ItemStatus.active);
  }

  public static parse(obj) {
    if (obj == null) { return null; }
    const m = plainToInstance<TripTrackerList, any>(TripTrackerList, sanitizeDateIPoint(obj));
    m.sanitize();
    return m;
  }
  static createPromoList(cid: number | string, carrierCSummary: CarrierCompanySummary,
    uProfile?: UserProfile, entry?: { [key: string]: TripTrackerListItem })
    : TripTrackerList {
    let r = new TripTrackerList();
    // const k = Object.keys(entry)[0];
    r.cid = cid;
    r.list = {};
    r.listType = PromoListType.promoFreightCustomer;
    r.carrierCompanySummary = carrierCSummary;
    if (!!entry) {
      r = r.updateListToAddEntry(entry, uProfile);
    }
    const v = Object.values(r.addTBD())[0];
    const k = Object.keys(r.addTBD())[0];
    r.list[k] = v;
    return r;
  }
  // static updatePromoList(entry: { [key: string]: TripTrackerListItem }, tripTrackerList: TripTrackerList, uProfile?: UserProfile)
  //   : TripTrackerList {
  //   const k = Object.keys(entry)[0];

  //   if (!!k) {
  //     tripTrackerList.list[k] = <TripTrackerListItem>{};
  //     tripTrackerList.list = !!tripTrackerList.list ? tripTrackerList.list : <{ [key: string]: TripTrackerListItem }>{};
  //     if (!!uProfile) {
  //       tripTrackerList.list[k].name = uProfile.displayName;
  //       tripTrackerList.list[k].tripTrackerId = `${uProfile.id}`;
  //       // driverList.list[k].isActive = TODO: SS once userprifle is updated to dictionary driving for
  //     } else {
  //       tripTrackerList.list[k].name = entry[k].name; // reset to empty for pending record
  //     }
  //     tripTrackerList.list[k].itemStatus = ItemStatus.active;
  //     return tripTrackerList;
  //   } else {
  //     return null;
  //   }
  // }
  updateListToAddEntry(entry: { [key: string]: TripTrackerListItem }, u?: UserProfile) {
    const email = Object.keys(entry)[0];
    if (!!email) {
      // this.list[email] = <TripTrackerListItem>{}; // reset to empty and add properties based on the UserProfile
      if (!!u) {
        const key = `${u.id}`;
        this.list[key] = <any>{};
        this.list[key].name = u.displayName;
        this.list[key].email = email;
        this.list[key].itemStatus = ItemStatus.active;
      } else {
        this.list[email] = <any>{};
        this.list[email].itemStatus = ItemStatus.active;
        this.list[email].name = entry[email].name;
      }
      return this;
    } else {
      return null;
    }
  }
  /**
   * update notifier list to edit notifier's item status @param ItemStatus.
   * @param entry update entery (key is phone)
   * @param u Notofier's userprofile
   */
  updateListEditEntry(entry: { [key: string]: TripTrackerListItem }, u?: UserProfile): TripTrackerList {
    const entryKey = Object.keys(entry)[0];
    const entryVal = Object.values(entry)[0];
    const e = Object.keys(entry)[0];
    if (!e) {
      return null;
    }
    if (!!u && u.id !== entryVal.tripTrackerId) {
      return null;
    }
    // const key: string = !!u ? `${u.id}` : entryKey;
    const key: string = !!entryVal.tripTrackerId ? `${entryVal.tripTrackerId}` : entryKey;

    // can only set status inactive or  inactive. Pending status is not applicable for notifier list
    if (entryVal.itemStatus !== ItemStatus.pending) {
      this.list[key].itemStatus = entryVal.itemStatus;
      return this;
    } else {
      return null;
    }
  }
  addTBD(): { [key: string]: TripTrackerListItem } {
    const d = new TripTrackerListItem();
    d.name = 'TBD';
    d.itemStatus = ItemStatus.active;
    d.email = 'TBD (Place Holder)';
    d.isPlaceHolder = true;
    const t: { [key: string]: TripTrackerListItem } = {};
    t['TBD'] = d;
    return t;
  }
  validateSyncGroup(group: ListValidationGroup): IValidationMsg {
    return this.validateSync({ groups: [group] });
  }
  validateSync(options?: ValidatorOptions): IValidationMsg {

    const r = this.validateSyncBase(this, options);
    if (!!this.uploadItem && !isEmail(this.uploadItem)) {
      r['entry'] = ['enter valid email'];
    }
    return r;
  }
  clone() {
    const t = instanceToInstance(this);
    t.sanitize();
    return t;
  }
  addNewEntry(newEntry: { [key: string]: TripTrackerListItem }): TripTrackerList | null {
    const k = Object.keys(newEntry)[0];
    const v = Object.values(newEntry)[0];
    this.list[`${k}`] = v;
    return this;

  }
  isDuplicate(v: string): boolean {
    const a = Object.keys(this.list);
    return !!a.includes(v) ? true : false;
  }
  createNewEntry(v: string, itemStatus?: ItemStatus): { [key: string]: TripTrackerListItem } {
    const c: { [key: string]: TripTrackerListItem } = {};
    c[v] = <any>{ itemStatus: !!itemStatus ? itemStatus : ItemStatus.active };
    // c[v] = <any>{ name: 'namePlaceHolder', tripTrackerId: `placeholder${v}`, itemStatus: !!itemStatus ? itemStatus : ItemStatus.active };
    return c;
  }
  activateRecord(activateItem: string): { [key: string]: TripTrackerListItem; } {
    const a = pickBy(this.list, (value, key) => key === activateItem);
    a[activateItem].itemStatus = ItemStatus.active;
    return a;
  }
  /**
   * set the list with user when userprofile is created or updated.
   * New entry with userId key created and related entery with emails is remnoved.
   * e.g. email@email.com: {itemStatus: 3} is replaced by XXXXXXXNEWUSERID: {email: email@email.com, name: NotifierSingh, itemStatus: 3}
   * @param d UserProfile
   * @param uid User id
   */
  updateWithNotifierId(d: UserProfile, uid: string) {

    this.list[uid] = <TripTrackerListItem>{ email: d.email, name: d.displayName, itemStatus: this.list[d.email].itemStatus };
    delete this.list[d.email];
    return this;
  }
  /** transform list from dB where key for notfier is email (for anonymous) or firestore id (for loged users) */
  get listForUI(): { [key: string]: TripTrackerListItem; } {
    const l: { [key: string]: TripTrackerListItem } = {};
    for (const key in this.list) {
      if (this.list.hasOwnProperty(key)) {
        const ele = this.list[key];
        if (UserEmail.regexEmail(key)) {
          l[key] = ele;
          l[key].email = key;
        } else if (ele.email) {
          l[ele.email] = <TripTrackerListItem>{
            email: ele.email, name: ele.name, itemStatus: ele.itemStatus,
            tripTrackerId: key, isPlaceHolder: ele.isPlaceHolder
          };
        }
      }
    }
    return l;
  }
}
