import { Exclude, plainToInstance, instanceToInstance, Expose } from 'class-transformer';
import { sanitizeDateIPoint } from '../utility';
import { ValidatorOptions, IsDefined, IsEmail, Length, IsEnum, ValidateIf, IsInt, Max, Min, MinLength } from 'class-validator';
import { IValidationMsg } from '../error-handling/validation-info';
import { isEmpty } from 'lodash';
import { logger } from '../log/logger';
import { BaseModel } from '../base';
import { TaskType } from '../sys/task-type';
import { Task } from '../sys/task';
import { TaskUtl } from '../sys/task-utl';


/**
 * @author MKN
 * Contact us model for saving TPINE enquiries
 * 
 */


/**
 * Enum for enquiry type
 */
export enum EnquiryType {
  customer = 1,
  dealer = 2,
}

@Exclude()
export class ContactUs extends BaseModel {

  public static readonly collectionName = 'tpine-enquiry';


  @Expose()
  @IsEmail({}, { message: 'Valid Email is required' })
  @Length(5, 254, { message: 'Email must be $constraint1 - $constraint2 chars long' })
  email: string;

  @Expose()
  @IsEnum(EnquiryType)
  enquiryType: EnquiryType;

  @Expose()
  @Length(5, 30, { message: 'Contact Name must be $constraint1 - $constraint2 chars long' } )
  fullName: string;

  @Expose()
  @ValidateIf(o => !!o.phoneNumber)
  @Length(5, 30, { message: 'Phone number must be $constraint1 - $constraint2 chars long' } )
  phoneNumber: string;

  // //customer Type field
  // @Expose()
  // @ValidateIf(o => o.year != undefined)
  // @IsInt()
  // @Max(2040)
  // @Min(1995, {  message: 'Year should be valid (> $constraint1)' })
  // year: number;

  //customer Type field
  // @Expose()
  // @ValidateIf(o => !!o.make)
  // @Length(2,100, { message: 'Make needs must be $constraint1 - $constraint2 chars long' })
  // make: string;

  // //customer Type field
  // @Expose()
  // @ValidateIf(o => !!o.model)
  // @Length(2,100, { message: 'Model needs must be $constraint1 - $constraint2 chars long' })
  // model: string;

  // //customer Type field
  // @Expose()
  // @ValidateIf(o => o.miles != undefined)
  // @IsInt()
  // miles: number;

  // //customer Type field
  // @Expose()
  // @ValidateIf(o => o.miles != undefined)
  // @IsInt()
  // downPayment: number;
  
  //customer Type field
  @Expose()
  @ValidateIf(o => !!o.equipment)
  @Length(2,500, { message: 'Equipment needs must be $constraint1 - $constraint2 chars long' })
  equipment: string;
  
  //Dealer Type field
  @Expose()
  @ValidateIf(o => o.retailNumbers != undefined)
  @IsInt()
  retailNumbers: number;

  public static parse(obj) {
    try {
      if (obj == null) { return null; }
      obj = sanitizeDateIPoint(obj);
      const m = plainToInstance<ContactUs, any>(ContactUs, obj);
      m.sanitize();
      return m;
    } catch (error) {
      logger.log('Error happened during parse', error);
      return null;
    }
  }

  clone() {
    const t = instanceToInstance(this);
    t.sanitize();
    return t;
  }

  validateSync(options?: ValidatorOptions): IValidationMsg {
    const r = this.validateSyncBase(this, options);
    return r;
  }
}
