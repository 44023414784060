import { Component, OnInit } from '@angular/core';
import { EnquiryType } from '@trent/models/pts/pts-enquiry';

@Component({
  selector: 'trent-dpc-contact',
  templateUrl: './dpc-contact.component.html',
  styleUrls: ['../dpc-common.scss',  './dpc-contact.component.scss']
})
export class DpcContactComponent implements OnInit {
  breakpoint: number;
  type: EnquiryType = EnquiryType.dpcContact;

  constructor() { }

/* Initializes the component after Angular first displays the data-bound properties
and sets the breakpoint value depending on the window inner width */
ngOnInit(): void {
  this.breakpoint = (window.innerWidth <= 768) ? 1 : 2;
}

/* Listens for changes to the size of the viewport and updates the breakpoint value accordingly */
onResize(event) {
  this.breakpoint = (event.target.innerWidth <= 768) ? 1 : 2;
}
}
