import { IValidationMsg } from '../error-handling';
import { Exclude, Expose, instanceToInstance, plainToInstance } from 'class-transformer';
import { IsDefined } from 'class-validator';
import { PageHtml, PageHtmlSection, PageHtmlType } from '../cms/page-html';
import { PtsHomeImage } from '../pts/pts-home-image';
import { globalToInstance } from '../cms/page-html-decorator';



@Exclude()
export class DpcHome extends PageHtml {

  @Expose()
  @IsDefined({ message: 'Image should be required' })
  images: PtsHomeImage[] = [];


  sanitize() {
    super.sanitize();
  }

  /** auto section will keep the track of all custom properties that have @sectionDef attribute applied to them.
   * This is used for auto generating the html control in the cms page. This value will NOT be saved in the database. */
  autoSection: Record<string, PageHtmlSection> = {};
  /**
   * Adding pageHtmlType in this class
   */
  constructor() {
    super();
    this.pageHtmlType = PageHtmlType.dpcHome;
    globalToInstance(DpcHome, this);
  }

  clone() {
    const t = instanceToInstance(this);
    t.sanitize();
    t.id = this.id;
    return t;
  }

  validateSync(): IValidationMsg {
    const r = this.validateSyncBase(this);
    return r;
  }

  // public static parse(obj) {
  //   if (obj == null) { return null; }
  //   const m = plainToInstance<DpcHome, any>(DpcHome, obj);
  //   m.sanitize();
  //   return m;
  // }

}