export enum ProductType {
  trailer = 1,
  truck = 2,
  driver = 3,
  trip = 4,
  promoTruck = 5,
  promoTrailer = 6,
  promoDriver = 7,
  promoFreightCustomer = 8

}

/**
 * @author MKN
 * Product Physical Location Type
 * If prideLocation is selected then store location is required
 * If other is selected then Physical location address is required
 */
export enum PhysicalLocationType {
  prideLocation = 1,
  other = 2
}
