import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';
import { BasePage } from '@trent/models/UI/base.page';
import { logger } from '@trent/models/log/logger';
import { SparePart, SparePartCategory, SparePartSortByCategory } from '@trent/models/spare-part/spare-part';
import { SparePartParam } from '@trent/models/spare-part/spare-part-param';
import { StoreLocationParam } from '@trent/models/store-location/store-location-param';
import { EnumHelper } from '@trent/models/utility/enum-helper';
import { DialogService } from '@trent/services/dialog/dialog.service';
import { EventService } from '@trent/services/event.service';
import { UtilityService } from '@trent/services/utility.service';
import { AllSparePartsRequested, SparePartState } from '@trent/store/spare-part';
import { StoreLocationState, StoreLocationsRequested } from '@trent/store/store-location-store';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
	selector: 'trent-dpc-catalog',
	templateUrl: './dpc-catalog.component.html',
	styleUrls: ['../dpc-common.scss'],
})
export class DpcCatalogComponent extends BasePage<SparePart[]> implements OnInit, AfterViewInit, OnDestroy {
	pid: string = 'pts-inventory';
	@ViewChildren(MatExpansionPanel) viewPanels: QueryList<MatExpansionPanel>;
	sParam: SparePartParam = {};
	list: SparePart[];
	dropdown = 'All';
	firstCard: number;
	lastCard: number;
	isHandset$: Observable<boolean>;
	isHandset: boolean;
	filterPanel = false;
	columnPanel = false;
	dealerShipList = [];

	// Prices
	priceFilter = { from: 0, to: 5000 };

	// Categories
	categoriesFilter = [];

	dropdownList: string[] = [
		'All',
		'Top Rated',
		'Best Seller',
	];

	constructor(
		store: Store,
		ds: DialogService,
		es: EventService,
		private activatedRoute: ActivatedRoute,
		private cdr: ChangeDetectorRef,
	) {
		super(store, ds, es);
	}

	ngOnInit(): void {
		this.isHandset$ = this.us.isHandset$;
		this.mSubscription.push(this.us.isHandset$.subscribe((h) => (this.isHandset = h)));
		// Get Spare Part Id from route
		const s = this.activatedRoute.params.subscribe(data => {
			if (data.id) this.sParam = { id: data.id };
			if (data.cid) {
				this.sParam = { categories: [Number(data.cid)] };
			}
		});
		this.mSubscription.push(s);
	}

	ngAfterViewInit(): void {
		this.initialize();
		this.categoriesFilter = EnumHelper.getNamesAndValues(SparePartCategory).map((x) => ({ ...x, checked: false }));
		this.dropdown = this.dropdownList[0];
		this.cdr.detectChanges();
	}

	ngOnDestroy(): void {
		this.cleanListeners();
	}

	/**
	 * @author KS
	 * @purpose Initialize component
	 */
	initialize() {
		const pData = { size: 1000, offset: 0, full: false };
		const storelocationParam : StoreLocationParam= { isDPC : true };
		this.store.dispatch(new AllSparePartsRequested({ pData: pData, param: { ...this.sParam } }));
		this.store.dispatch(new StoreLocationsRequested({ pData: pData, param: storelocationParam }));

		const sparePart$ = this.store.select(SparePartState.selectAllSpareParts).pipe(map(clientFilterFn => clientFilterFn({ ...this.sParam })));
		const store$ = this.store.select(StoreLocationState.selectAllStoreLocations).pipe(map(clientFilterFn => clientFilterFn({ ...storelocationParam })));

		const subs = combineLatest([sparePart$, store$]).subscribe(d => {
			// Spare Parts
			if (!!d[0]) {
				this.m = d[0];
				this.list = d[0];
				this.firstCard = 0;
				this.lastCard = 9;
			}
			// Store Locations
			if (!!d[1] && !this.dealerShipList.length) {
				const stores = d[1];
				this.dealerShipList = stores.map((c, idx) => {
					return { id: c.id, name: `DTP (${c.address.city})`, checked: false, phone: c.phone, city: c.address.city, addressFormated: c?.address?.addressFormated };
				});
				logger.log(this.dealerShipList);
			}
		});
		this.mSubscription.push(subs);
	}

	/**
	 * Toggle Panels
	 * @param value 
	 */
	togglePanels(value: boolean) {
		this.viewPanels.forEach(p => value ? p.open() : p.close());
	}

	/**
	 * Category Filter
	 */
	onCategoryFilter() {
		let selectedCategories = this.categoriesFilter.filter((t) => t.checked);
		const categories = selectedCategories.map(v => v.value);
		if (categories?.length > 0) {
			this.sParam = { categories: categories as [SparePartCategory], orderBy: 'createdAt desc' };
		}
		// Show all when none is selected
		if (categories?.length == 0) {
			this.sParam = { orderBy: 'createdAt desc' };
		}
		this.initialize();
	}

	/**
	 * Price Filter
	 */
	onPriceFilter() {
		let partPrice = { from: this.priceFilter.from, to: this.priceFilter.to };
		this.sParam = { ...this.sParam, priceRange: partPrice };
		this.initialize();
	}

	/**
	 * Dealership filter
	 */
	onDealership() {
		const selectedDealership = this.dealerShipList.filter(d => d.checked);
		let sidArray = selectedDealership.map(v => v.id);
		this.sParam = { ...this.sParam, sidArray: sidArray as [string | number], orderBy: 'createdAt desc' };
		// Show all when none is selected
		if (selectedDealership?.length == 0) {
			this.sParam = {  ...this.sParam, sidArray: null, orderBy: 'createdAt desc' };
		}
		this.initialize();
	}

	/**
	 * Dropdown action
	 * @param e 
	 */
	onDropdown(e) {
		switch (e) {
			case 'Top Rated':
				this.list = this.m.filter(v => v.sortBy == SparePartSortByCategory.TopRated);
				break;
			case 'Best Seller':
				this.list = this.m.filter(v => v.sortBy == SparePartSortByCategory.BestSeller);
				break;
			default:
				this.initialize();
				break;
		}
	}

	/**
	 * Reset Filters
	 */
	resetFilters() {
		this.sParam = {};
		this.initialize();
	}
}