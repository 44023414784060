import * as entity from '@trent/store/entity.state';
import { Selector, State, Action, StateContext } from '@ngxs/store';
import { map } from 'rxjs/operators';
import { noop, Subscription } from 'rxjs';
import { Paging, getObjKey } from '../../models/observable-util/paging';
import { PagingContainer } from '../../models/observable-util/paging-container';
import { StateBase } from '../state-base';
import {
  IDataLoadStatus, buildDataRequest, LoadStatus,
  updatePaging, getRootLevelChildren
} from '../../models/observable-util/data-status';
import { Injectable } from '@angular/core';
import { getSparePartSearchOptOrChildren, SparePartParam, sparePartSearchClientFilter } from '@trent/models/spare-part/spare-part-param';
import { SparePart } from '@trent/models/spare-part/spare-part';
import { SparePartsService } from '@trent/services/spare-parts.service';
import { parseSparePart } from '@trent/models/spare-part/spare-part-helper';

/**
 *  Spare PartState Model
 */

export interface SparePartStateModel {
  param?: SparePartParam;
  paging: Paging;
  allSparePartsLoaded: boolean;
  allSparePartsLoadStatus: {
    [key: string]: IDataLoadStatus<SparePartParam>;
  };
  spareParts: { [id: string]: SparePart };
  sparePartHistory: { [id: string]: SparePart }; 

}

/**
 * init Spare Part State Model
 */
function initSparePartState(): SparePartStateModel {
  return {
    param: null,
    paging: { size: 10, offset: 0, full: false },
    allSparePartsLoaded: false,
    allSparePartsLoadStatus: {},
    spareParts: {},
    sparePartHistory: {}
  };
}
// #endregion

// Spare part actions


export class AllSparePartsRequested {
  static readonly type = '[SpareParts] All Spare Parts Requested';
  constructor(public payload: { pData: Paging, param: SparePartParam }) { }
}

export class AllSparePartsLoaded {
  static readonly type = '[SpareParts] All Spare Parts Loaded';
  constructor(public payload: {
    data: { [id: string]: SparePart },
    key: string,
  }) { }
}

export class AllSparePartsNextPageRequested {
  static readonly type = '[SpareParts] All Spare Parts Requested - Next Page';
  constructor(public payload: { param: SparePartParam }) { }
}

export class SparePartRequested {
  static readonly type = '[SpareParts] Request a single Spare Part entity';
  constructor(public payload: { id: string | number }) { }
}

export class SparePartLoaded {
  static readonly type = '[SpareParts] Load a single Spare Part entity';
  constructor(public payload: { data: SparePart }) { }
}

export class AllSparePartHistoryLoaded {
  static readonly type = '[SpareParts] All Spare Part History Loaded';
  constructor(public payload: {
    data: { [id: string]: SparePart },
    key: string,
  }) { }
}

export class SparePartStateReset {
  static readonly type = '[SpareParts] Reset State';
}

// #end region actions


@State<SparePartStateModel>({
  name: 'sparePart',
  defaults: initSparePartState()
})

@Injectable()
export class SparePartState extends StateBase {
  allSparePartReqSub: Subscription;
  allSparePartsSubData: PagingContainer<SparePart, SparePartParam> = new PagingContainer();

  constructor(private sparePartService: SparePartsService) { super(); }

  // #region Static Selectors
  @Selector()
  static selectAllSpareParts(state: SparePartStateModel) {
    return (o: SparePartParam): SparePart[] => {

      if (state.spareParts == null || Object.keys(state.spareParts).length === 0) {
        return [];
      }
      // remove keys that have revision/draft ids, i.e that contain '~' in the id.
      const keys = Object.keys(state.spareParts).filter(k =>
        k.indexOf('/') === -1 && state.spareParts[k] != null);
      // object without the rev/draft.
        const filtered = keys.reduce((obj, k) => {
          obj[k] = state.spareParts[k];
          return obj;
        }, {});

      let output = Object.values(filtered).map(x => parseSparePart(x));
      output = sparePartSearchClientFilter(output, o);

      return output;
    };
  }

  /** Is the Paging Full for this search. */
  @Selector()
  static selectAllSparePartsFull(state: SparePartStateModel) {
    return (o: SparePartParam): boolean => {
      const oKey = getObjKey(o);
      const r = state.allSparePartsLoadStatus && state.allSparePartsLoadStatus[oKey];
      return (!!r) ? r.pData.full : true;
    };
  }

  @Selector()
  static selectSparePartById(state: SparePartStateModel) {
    return entity.getByIdFn_new(state.spareParts, parseSparePart);
  }
  
  @Selector()
  static selectSparePartParam(state: SparePartStateModel) {
    return {
      ...state.param
    };
  }

  @Selector()
  static selectAllSparePartHistory(state: SparePartStateModel) {
    return (o: SparePartParam): SparePart[] => {

      if (state.sparePartHistory == null || Object.keys(state.sparePartHistory).length === 0) {
        return [];
      }
      // remove keys that have revision/draft ids, i.e that contain '~' in the id.
      const keys = Object.keys(state.sparePartHistory).filter(k =>
        k.includes('/') && state.sparePartHistory[k] != null);
      // object without the rev/draft.
        const filtered = keys.reduce((obj, k) => {
          obj[k] = state.sparePartHistory[k];
          return obj;
        }, {});

      let output = Object.values(filtered).map(x => parseSparePart(x));
      output = sparePartSearchClientFilter(output, o);

      return output;
    };
  }
  // #endregion selectors

  // #region Custom Functions and subscriptions
  public clearSubscriptions() {
    if (!!this.allSparePartReqSub) {
      this.allSparePartReqSub.unsubscribe();
      this.allSparePartReqSub = null;
    }
    this.allSparePartsSubData.unsubscribeAll();
    super.clearSubscriptions();
  }

  @Action(SparePartStateReset)
  SparePartStateReset(context: StateContext<SparePartStateModel>, action: SparePartStateReset) {
    // unsubscribe the data
    console.log('RentalProductStateReset action called');
    this.clearSubscriptions();
    context.setState(initSparePartState());
  }

  @Action(AllSparePartsRequested)
  allSparePartsRequested(context: StateContext<SparePartStateModel>, action: AllSparePartsRequested) {
    const oKey = getObjKey(action.payload.param);

    // Define function that return the data status object from the state.
    const getDataStatusStateFn = () => context.getState().allSparePartsLoadStatus;

    /** custom build the OR children query. */
    const buildOrQueryChildrenFn = (o: SparePartParam) => getSparePartSearchOptOrChildren(o);
    // if data requested now, is already partially loaded by another query's child previously
    // but the number of the items are not enough (ex. as a child, it loaded only 5, but current
    // request ask for more, then next page of that query should be called instead.)
    const nextPageFn = (param: SparePartParam) => {
      context.dispatch(new AllSparePartsNextPageRequested({ param }));
    };
    buildDataRequest(
      oKey, action.payload.param, action.payload.pData,
      getDataStatusStateFn,
      buildOrQueryChildrenFn,
      nextPageFn,
      (
        obj: { [key: string]: IDataLoadStatus<SparePartParam> },
        set: { key: string, node: IDataLoadStatus<SparePartParam> }[]
      ) => {
        if (!!obj) {
          // Patch the state.
          const state = context.getState();
          context.patchState({
            allSparePartsLoadStatus: { ...state.allSparePartsLoadStatus, ...obj }
          });
        }
        // Process the query.
        set.forEach((val) => {
          // some of the nodes are already loaded. Only process that are loading... status.
          if (val.node.loadStatus !== LoadStatus.Loading) {
            return;
          }
          // if this request is just overwriting a stall or pending request, unsubscribe that observable
          this.allSparePartsSubData.unsubscribe(val.key);

          console.log('New Service sub created: ', val);
          // create the paging observable and call db.
          const b = this.sparePartService.getAllSpareParts_PagingObservable();
          const sparePart$ = b.getData(action.payload.pData, val.node.param)
            .pipe(
              map(spareParts => {
                if (!val.node.param.sparePartIdForHistory) {
                  context.dispatch(new AllSparePartsLoaded({
                    data: spareParts,
                    key: val.key
                  }));
                } else {
                  context.dispatch(new AllSparePartHistoryLoaded({
                    data: spareParts,
                    key: val.key
                  }));
                }
                return spareParts;
              }));
          const sub = this.subscribe(sparePart$, () => noop(), AllSparePartsRequested.type);
          this.allSparePartsSubData.addData(val.key, sub, b);
        }
        );
      });
  }

  @Action(AllSparePartsLoaded)
  allSparePartsLoaded(context: StateContext<SparePartStateModel>, action: AllSparePartsLoaded) {
    const state = context.getState();
    const subData = this.allSparePartsSubData.getData(action.payload.key);
    const updatedLoadStatus = updatePaging(action.payload.key, state.allSparePartsLoadStatus, subData);
    context.patchState({
      allSparePartsLoaded: true,
      allSparePartsLoadStatus: updatedLoadStatus,
      spareParts: { ...state.spareParts, ...action.payload.data } 
    });
  }

  @Action(AllSparePartsNextPageRequested)
  allSparePartsNextPageRequested(context: StateContext<SparePartStateModel>, action: AllSparePartsNextPageRequested) {
    const oKey = getObjKey(action.payload.param);
    const state = context.getState();
    // find the node. can be parent or child
    const statusObj = state.allSparePartsLoadStatus[oKey];
    // if no parent, treat is
    if (statusObj.children == null) {
      this.allSparePartsSubData.dispatchNextPagingUpdate(oKey);
    } else {
      const children = getRootLevelChildren(oKey, state.allSparePartsLoadStatus);
      children.forEach(c => {
        this.allSparePartsSubData.dispatchNextPagingUpdate(c.key);
      });
    }
  }
  
  @Action(SparePartRequested)
  SparePartRequested(context: StateContext<SparePartStateModel>, action: SparePartRequested) {
    const state = context.getState();
    console.log('requested SparePart is not in the store. sending request to server', action.payload.id);
    this.sparePartService.getSparePartById(action.payload.id)
      .pipe(
        map(data => {
          // console.log('from server: retal map was called', data);
          return context.dispatch(new SparePartLoaded({ data }));
        }
        )).subscribe(noop);

  }

  @Action(SparePartLoaded)
  SparePartLoaded(context: StateContext<SparePartStateModel>, action: SparePartLoaded) {
    const state = context.getState();
    const b = {};
    b[action.payload.data.id] = action.payload.data;

    context.patchState({
      spareParts: { ...state.spareParts, ...b } // entity.update(state.spareParts, action.payload.data)
    });
  }

  @Action(AllSparePartHistoryLoaded)
  allSparePartHistoryLoaded(context: StateContext<SparePartStateModel>, action: AllSparePartHistoryLoaded) {
    const state = context.getState();
    const subData = this.allSparePartsSubData.getData(action.payload.key);
    const updatedLoadStatus = updatePaging(action.payload.key, state.allSparePartsLoadStatus, subData);
    context.patchState({
      allSparePartsLoaded: true,
      allSparePartsLoadStatus: updatedLoadStatus,
      sparePartHistory: { ...state.sparePartHistory, ...action.payload.data } // entity.addMany(state.sparePartHistory, action.payload.sparePartHistory)
    });
  }
}
