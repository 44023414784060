import { IsDefined, ValidatorOptions, validateSync } from 'class-validator';
import { instanceToInstance, Exclude, Expose, plainToInstance } from 'class-transformer';
import { IValidationMsg } from '../error-handling';
import { toValidationError } from '../utility/validation-helper';
import { CarrierCompanySummary } from '../company/company-carrier';
import { sanitizeDateIPoint } from '../utility/sanitize-helper';

@Exclude()
export class DrivingFor {


  @Expose()
  drivingForUI: { [key: string]: { isActive: boolean, cComp: CarrierCompanySummary } };

  // @Expose({ toPlainOnly: true })
  // get drivingFor(): { [key: string]: { isActive: boolean } }  {
  //   const x = {};
  //   for (const key in this.drivingForUI) {
  //     if (this.drivingForUI.hasOwnProperty(key)) {
  //       const element = this.drivingForUI[key];
  //       x[key] = { isActive: element.isActive };
  //     }
  //   }
  //   return x;
  // }

  public static parse(obj) {
    if (obj == null) { return null; }
    const m = plainToInstance<DrivingFor, any>(DrivingFor, sanitizeDateIPoint(obj));
    // m.sanitize();
    return m;
  }

  public clone(): DrivingFor {
    const u = instanceToInstance<DrivingFor>(this);
    // u.sanitize();
    return u;
  }

  validateSync(options: ValidatorOptions): IValidationMsg {
    const r = validateSync(this, options);
    const m = toValidationError(r);
    for (const key in this.drivingForUI) {
      if (this.drivingForUI.hasOwnProperty(key)) {
        const element = this.drivingForUI[key];
        if (key.length > 50) {
          m['drivingFor'] = ['Select valid carrier'];
        }
        if (typeof element.isActive !== 'boolean') {
          m['drivingFor'] = ['Select carrier status'];
        }

      }
    }

    return m;
  }
  removeCComp() {
    const r = this.clone();
    for (const key in r.drivingForUI) {
      if (r.drivingForUI.hasOwnProperty(key)) {
        const element = r.drivingForUI[key];
        element.cComp = <any>{};
      }
    }
    return r;
  }
  patchCarrier(carriers: CarrierCompanySummary[]) {
    // for (const key in this.drivingForUI) {
    //   if (this.drivingForUI.hasOwnProperty(key)) {
    //     const ele = this.drivingForUI[key];
    //     ele.cComp = carriers.find(f => f.cid === key);
    //   }
    // }
    carriers.forEach(c => {
      if (!this.drivingForUI[c.cid]) {
        this.drivingForUI[c.cid] = { isActive: false, cComp: c };
      } else {
        this.drivingForUI[c.cid].cComp = c;
      }
    });

  }

}
