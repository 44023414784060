import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogComponent } from './dialog.component';
import { DialogService } from '../../../../../../libs/services/dialog/dialog.service';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MaterialModule } from '../share-module/material.module';
import { MatBottomSheetModule} from '@angular/material/bottom-sheet'


@NgModule({
    declarations: [DialogComponent],
    imports: [
        CommonModule,
        MatDialogModule,
        MatSnackBarModule,
        MatProgressSpinnerModule,
        MatBottomSheetModule,
        MaterialModule
    ],
    providers: [
        DialogService
    ]
})
export class DialogModule { }
