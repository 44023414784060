import { AuthState } from '../store/auth-store/auth.state';
import { ValidatorOptions } from 'class-validator';
import { Injectable } from '@angular/core';
import { FirestoreService } from '@trent/services/firestore.service';
import { map, tap } from 'rxjs/operators';
import { instanceToPlain } from 'class-transformer';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { BaseHttpService } from '@trent/services/base-http.service';
import { Store } from '@ngxs/store';
import { logger } from '@trent/models/log/logger';
import { Paging } from '@trent/models/observable-util/paging';
import { PagingObesrvable } from '@trent/models/observable-util/paging-obesrvable';
import { Observable } from 'rxjs';
import { SalesOptionParam, SalesOptionsBulkUpload, salesOptionSearchServerQuery, SalesProductBase } from '@trent/models/sales-option';
import { ProductWholesalePrice } from '@trent/models/product-wholesale-price/product-wholesale-price';
import { ProductBase, Trailer } from '@trent/models/product';

@Injectable()
export class SalesOptionService extends BaseHttpService {

	constructor(store: Store, private db: FirestoreService, private http: HttpClient) {
		super(store);
		this.apiName = 'api';
	}


	public create(s: SalesProductBase, cid: string | number, newDocId: string, p: ProductWholesalePrice) {
		// Prepare the post data
		console.log('salesProduct at service', s);
		const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.token });
		return this.http.post<{ id: string | number }>(this.getApiUrl('/sales-options/create'),
			// Server validation before creating the entry.
			{
				salesProduct: instanceToPlain(s),
				cid,
				newDocId,
				productWholesalePrice: instanceToPlain(p),
			},
			{ headers: headers })
			.pipe(
				tap(res => console.log('response: ', res))
			);
	}

	public update(s: SalesProductBase, p: ProductWholesalePrice, newPsp: number, option?: ValidatorOptions) {
		// Prepare the post data
		const headers = this.addBearerToken();
		// Server validation before creating the entry.
		return this.http.post<{ id: string | number, data: SalesProductBase }>(this.getApiUrl('/sales-options/update'),
			{
				salesProduct: instanceToPlain(s),
				productWholesalePrice: instanceToPlain(p),
				newPsp: newPsp,
				option: option
			},
			{ headers: headers })
			.pipe(
				tap(r => logger.log('response: ', r)),
			);
	}

	public publish(s: SalesProductBase, id: string | number) {
		// Prepare the post data
		const headers = this.addBearerToken();
		// Server validation before creating the entry.
		return this.http.post<{ sid: string | number, data: SalesProductBase }>(this.getApiUrl('/sales-options/publish'),
			{
				id,
				salesProduct: instanceToPlain(s),
			},
			{ headers: headers })
			.pipe(
				tap(r => logger.log('response: ', r)),
			);
	}

	/**
	 * Publish images 
	 * @param id - Sales option id 
	 * @returns 
	 */
	public publishImages(id: string | number) {
		// Prepare the post data
		const headers = this.addBearerToken();
		// Server validation before creating the entry.
		return this.http.post<{ sid: string | number, data: SalesProductBase }>(this.getApiUrl('/sales-options/publish-images'),
			{
				id
			},
			{ headers: headers })
			.pipe(
				tap(r => logger.log('response: ', r)),
			);
	}

	/** Send request to approve the record. */
	public requestApproval(sid: string | number, option?: ValidatorOptions) {
		// Prepare the post data
		const headers = this.addBearerToken();
		return this.http.post<{ sid: string | number, data: SalesProductBase }>(this.getApiUrl('/sales-options/requestApproval'),
			{ sid, option: option },
			{ headers: headers })
			.pipe(
				tap(r => logger.log('response: ', r)),
			);
	}

	cancelApprovalReq(sid: string | number) {
		// Prepare the post data
		const headers = this.addBearerToken();
		return this.http.post<{ sid: string | number, data: SalesProductBase }>(this.getApiUrl('/sales-options/cancelApprovalReq'),
			{
				sid
			},
			{ headers: headers })
			.pipe(
				tap(r => logger.log('response: ', r)),
			);
	}

	/** Send request to approve the record. */
	public salesOperationsApprove(sid: string | number, option?: ValidatorOptions) { // :  CompanyFleet) {
		// Prepare the post data
		const headers = this.addBearerToken();
		return this.http.post<{ sid: string | number, data: SalesProductBase }>(this.getApiUrl('/sales-options/salesOperationsApprove'),
			{
				sid, option
			},
			{ headers: headers })
			.pipe(
				tap(r => logger.log('response: ', r)),
			);
	}

	public deleteDraft(sid: string | number) {
		// Prepare the post data
		const headers = this.addBearerToken();
		return this.http.post<{ sid: string | number, data: SalesProductBase }>(this.getApiUrl('/sales-options/delete-draft'),
			{ sid },
			{ headers: headers })
			.pipe(
				tap(r => console.log('response: ', r))
			);
	}

	/**
	 * Bulk upload legacy contract records
	 * @param lCList 
	 * @returns 
	 */
	public bulkCreate(sOList: SalesOptionsBulkUpload[], vendorCid: string) {
		// Prepare the post data
		const headers = new HttpHeaders({ Authorization: 'Bearer ' + this.token });

		// Server validation before creating the entry.
		return this.http.post<any>(this.getApiUrl('/sales-options/bulk-create'),
			{
				sOList: instanceToPlain(sOList),
				vCid: vendorCid
			},
			// tslint:disable-next-line:object-literal-shorthand
			{ headers: headers })
			.pipe(
				tap(r => logger.log('response: ', r)),
			);
	}

	getNewDocId() {
		return this.db.docId(SalesProductBase.collectionName);
	}

	public getAllSalesOptions() {

		// Get store locations
		// const c = this.store.selectSnapshot(AuthState.userClaim);
		// logger.log('default store is: ', Object.keys(c.cClaim.cRoles));
		return this.db.colWithIdsInjected$<SalesProductBase>(SalesProductBase.collectionName);
	}

	getSalesOptionById(id: string | number) {
		return this.db.docWithInjectedId$<SalesProductBase>(
			`${SalesProductBase.collectionName}/${id}`, id);
	}

	/**
	 * get all companies (admin request)
	 */
	public getAllSalesOptions_PagingObservable() {
		const p$: PagingObesrvable<SalesProductBase, SalesOptionParam> =
			new PagingObesrvable<SalesProductBase, SalesOptionParam>(this.db,
				(p: Paging, param?: SalesOptionParam) => this.getAllSalesOptions_batch(p, param));
		return p$;
	}

	/**
	* Get the next batch of the data from server.
	* @param p paging information.
	*/
	private getAllSalesOptions_batch(p: Paging, param: SalesOptionParam): Observable<{ [key: string]: SalesProductBase }> {
		if (param.orderBy == null) { param.orderBy = 'updatedAt'; }
		if (param.orderDirection == null) { param.orderDirection = 'desc'; }
		return this.db
			.colWithIdsInjectedNew$<SalesProductBase>(`${SalesProductBase.collectionName}`, ref => salesOptionSearchServerQuery(ref, param, p))
			.pipe(
				tap(arr => {
					if (arr == null || arr.length === 0) {
						logger.log('All data is recevied, Paging is FULL');
						p.full = true;
					} else {
						p.lastDoc = arr[arr.length - 1];
					}
				}),
				map(arr => {
					// logger.log('From Server, before mapping is applied: ', arr);

					const arrayAsObj = arr.reduce((acc, cur) => {
						const id = cur.id;
						const data = cur;
						return { ...acc, [id]: data };
					}, {});

					// logger.log('after converting to array object dic ', arrayAsObj);
					return arrayAsObj;
				})
			);
	}

	/**
	* @author KS
	* @param payload 
	* @returns 
	*/
	getYardPersons() {
		// Prepare the post data
		const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.token });
		// Server validation before creating the entry.
		return this.http.post(this.getApiUrl('/sales-options/get-yard-persons'), {},
			{ headers: headers })
			.pipe(
				tap(r => logger.log('response: ', r)),
			);
	}

	getSimilarSalesOptions(payload: SalesProductBase) {
		// Prepare the post data
		const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.token });
		// Server validation before creating the entry.
		return this.http.post(this.getApiUrl('/sales-options/get-similar-sales-options'),
			{ salesOption: payload },
			{ headers: headers })
			.pipe(
				tap(r => logger.log('response: ', r)),
			);
	}
}