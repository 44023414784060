import { Paging, IKeyVal, getDirection } from '../observable-util/paging';
import { Task, TaskStatus } from './task';
import { logger } from '../log/logger';
import { CollectionReference, limit, orderBy, query, QueryConstraint, startAfter, where } from 'firebase/firestore';
import { query as queryR, Query as QueryR, QueryConstraint as QueryConstraintR, orderByChild as orderByChildR, endAt as endAtR, equalTo as equalToR, limitToLast as limitToLastR, DatabaseReference } from '@angular/fire/database';
/** Search params for fetching the orders by user settings. 
 * TODO:  This need to be better defined. What is the difference between isNotRead and isNew
 * when is isNew set to be false? 
 * currently if messages are loaded and then new messages are clicked again, the query logic
 * is going through multiple orderby conditions that is not allowed by firebase. This need to be addressed.
*/
export interface ITaskParam {
  orderDirection?: 'desc' | 'asc';
  orderBy?: string;
  assignedTo?: string; // deprecated, was used for firestore tasks
  taskStatus?: TaskStatus; // client side filter
  sendTo?: string; // server side filter
  createdAt?: Date; // order by server side filter
  isNotRead?: boolean; // client side filter
  isNew?: boolean; // client side filter
}
export const taskSearchOrderBy: IKeyVal[] = [
  { key: 'createdAt', value: 'createdAt' },

];
/** Server filtering of firebase query building of query  */
export const taskSerachServerQuery = (ref: CollectionReference, o: ITaskParam, p: Paging) => {
  const cons: QueryConstraint[] = [];

  if (!!o.assignedTo) {
    cons.push(where(`assignedTo`, 'array-contains', o.assignedTo));
  }
  if (!!o.sendTo) {
    cons.push(where(`sendTo.${o.sendTo}.fbm`, '==', true));
  }
  if (!!o.taskStatus) {
    cons.push(where('taskStatus', '==', o.taskStatus));
  }
  const { col, dir } = getDirection(taskSearchOrderBy, (!!o.orderBy) ? o.orderBy : 'createdAt desc');
  logger.log('col and dir are: ', col);
  cons.push(orderBy(col, dir));

  if (!!p.lastDoc) {
    logger.log('Start After Query: ', p.lastDoc[o.orderBy]);
    cons.push(startAfter(p.lastDoc[o.orderBy]));
  }
  cons.push(limit(p.size));
  return query(ref, ...cons);
};
export const taskSerachServerQueryRTDB = (ref: DatabaseReference, o: ITaskParam, p: Paging) => {
  let q: QueryR = ref;
  let cons: QueryConstraintR[] = [];// =  new Array<QueryConstraint>(); 

  if (!o.isNew && !o.isNotRead) {
    if (o.createdAt) {
      cons.push(orderByChildR('createdAt'));
      cons.push(endAtR(o.createdAt.valueOf()));
    } else {
       cons.push(orderByChildR('createdAt'));
       cons.push(endAtR(new Date().valueOf()));
    }
  }
  if (!!o.isNew && !o.createdAt) {
    cons.push(orderByChildR('isNew'));
    cons.push(equalToR(true));
  }
  if (!!o.isNew && !!o.createdAt) {
    cons.push(orderByChildR('isNew_createdAt'));
    cons.push(endAtR(`${true}_${o.createdAt.valueOf()}`));
  }
  if (!!o.isNotRead && !o.createdAt) {
    cons.push(orderByChildR('isRead'));
    cons.push(equalToR(false));
  }
  if (!!o.isNotRead && !!o.createdAt) {
    cons.push(orderByChildR('isRead_createdAt'));
    cons.push(endAtR(`${false}_${o.createdAt.valueOf()}`));
  }

  if (!!p.lastDoc) {
    logger.log('Start After Query: ', p.lastDoc);
    cons.push(orderByChildR('createdAt'));
    cons.push(endAtR(p.lastDoc.createdAt));
  }
  cons.push(limitToLastR(p.size));

  return queryR(ref, ...cons);
};
/** Client Filtering of the data */
export const taskSearchClientFilter = (p: Task[], o: ITaskParam): Task[] => {
  // filter by type.
  if (!!o.sendTo) {
    p = p.filter((val) => !!val.sendTo && !!val.sendTo[`${o.sendTo}`]);
  }
  if (!!o.taskStatus) {
    p = p.filter((val) => val.taskStatus === +o.taskStatus);
  }
  if (!!o.isNotRead && o.sendTo) {
    p = p.filter((val) => !val.sendTo[`${o.sendTo}`].isRead);
  }
  if (!!o.isNew && o.sendTo) {
    p = p.filter((val) => !!val.sendTo[`${o.sendTo}`].isNew);
  }
  // Latest on top
  p = p.sort(function (a, b) { return b.createdAt.valueOf() - a.createdAt.valueOf(); });
  return p;
};

/** Get the OR condition options. if there are more then one company id's are provided
 *  each cid makes up a unique OR condition query.
 * TBD function, see Pick-Drop implementation */
export const getITasksOptionOrChildren = (o: ITaskParam): { [key: string]: ITaskParam } => {
  // if (!!o.cids && o.cids.length > 1) {
  //   const r: { [key: string]: PickDropParam } = {};
  //   o.cids.forEach((cid) => {
  //     const c = DeepCopy.copy(o);
  //     c.cids = [cid];
  //     r[getObjKey(c)] = c;
  //   });
  //   return r;
  // }

  return undefined;
};

