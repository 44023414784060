import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { BasePage } from '@trent/models/UI/base.page';
import { logger } from '@trent/models/log/logger';
import { EnquiryType } from '@trent/models/pts/pts-enquiry';
import { ReviewType } from '@trent/models/reviews/reviews';
import { SparePart, SparePartCategory, SparePartSortByCategory } from '@trent/models/spare-part/spare-part';
import { SparePartParam } from '@trent/models/spare-part/spare-part-param';
import { EnumHelper } from '@trent/models/utility/enum-helper';
import { DialogService } from '@trent/services/dialog/dialog.service';
import { AuthState } from '@trent/store/auth-store/auth.state';
import { AllSparePartsNextPageRequested, AllSparePartsRequested, SparePartRequested, SparePartState } from '@trent/store/spare-part';
import { Observable, map, of, switchMap } from 'rxjs';
import { PtsEnquiryDialogComponent } from '../../pts/pts-common/pts-enquiry-dialog/pts-enquiry-dialog.component';
import { UtilityService } from '@trent/services/utility.service';
import { MessageInfo } from '@trent/models/error-handling';
import { ShareOn, SocialShareComponent } from '../../shared/social-share/social-share.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { environment } from '@trent/core/environments/environment';
import { webSiteUrl } from '@trent/core/environments/fb-env';

@Component({
	selector: 'trent-dpc-details',
	templateUrl: './dpc-details.component.html',
	styleUrls: ['../dpc-common.scss', './dpc-details.component.scss'],
})
export class DpcDetailsComponent extends BasePage<SparePart> implements OnInit {

	sParam: SparePartParam = {};
	relatedProducts = [];
	firstCard = 0;
	lastCard = 9;
	type: EnquiryType;
	reviewType = ReviewType.storeLocation;
	id: string | number;
	isHandset$: Observable<boolean>;
	isHandset: boolean;
	horizontalIncrement: number = 0;
	isPriceShow: boolean;

	constructor(
		store: Store,
		private ds: DialogService,
		private router: Router,
		private aRoute: ActivatedRoute,
		private dialog: MatDialog,
		private _bottomSheet: MatBottomSheet,
	) {
		super(store, ds);
	}

	ngOnInit(): void {
		//Cm - Page scroll up when load
		this.us.scrollToTop1('mArea');

		this.isHandset$ = this.us.isHandset$;
		this.mSubscription.push(this.us.isHandset$.subscribe((h) => (this.isHandset = h)));
		this.aRoute.paramMap.pipe(switchMap((params: ParamMap) => of(params.get('id')))).subscribe(id => {
			this.id = id;
			this.initialize();
		});
		//Price show only admin,dps sales operation
		this.store.select(AuthState.user).subscribe(u => {
			this.isPriceShow = u?.roles?.salesOperationsDpc || u?.roles?.admin || u?.roles?.editor ? true : false;
		});
	}

	/**
	 * Initialize state
	 */
	async initialize() {
		// Get Sales Option
		this.store.dispatch(new SparePartRequested({ id: this.id }));
		const sparePart$ = this.store.select(SparePartState.selectSparePartById).pipe(map(filterFn => filterFn(`${this.id}`)))
			.subscribe((sparePart) => {
				if (sparePart) {
					this.m = sparePart;
					this.em = this.m.clone();
				}
			});
		if (!!this.em) this.getRelatedProducts();
		this.mSubscription.push(sparePart$);
	}

	getAQuote() {
		this.type = EnquiryType.getAQuote;
		this.openDialog();
	}

	// getCategoryName(c: SparePartCategory) {
	// 	let n = EnumHelper.getName(SparePartCategory, c);
	// 	return n;
	// }

	categoryRoute(c: SparePartCategory) {
		this.router.navigate(['/catalog/category', c]);
	}

	/**
	 * @author KS
	 * @purpose Get similar products
	 */
	async getRelatedProducts() {
		// if(this.relatedProducts?.length > 0) return;
		const sParam = { sortBy: this.em.sortBy };
		const pData = { size: 30, offset: 0, full: false };
		// Get Sales Option
		this.store.dispatch(new AllSparePartsRequested({ pData: pData, param: { ...sParam } }));
		const relatedProducts$ = this.store.select(SparePartState.selectAllSpareParts).pipe(map(clientFilterFn => clientFilterFn({ ...sParam })))
			.subscribe((d) => {
				if (!d) return;
				this.relatedProducts = [];
				let index = d.findIndex(d => d.id == this.em.id);
				d.splice(index, 1);
				this.relatedProducts = d;
				this.us.scrollToTop1('mArea');
			});
		this.mSubscription.push(relatedProducts$);
	}

	openDialog() {
		// Checking user is logged or not
		const isLoggedIn = this.store.select(AuthState.isLoggedIn);
		isLoggedIn.subscribe(v => {
			// if the user not logged in then go to login page
			if (!v) {
				const returnUrl = `/catalog/details/${this.id}`;
				this.router.navigate(['/login', { returnUrl }]);
			} else {
				const unitName = this.em.name;
				this.dialog.open(PtsEnquiryDialogComponent, {
					data: { enquiryType: this.type, productDetails: { pid: this.id, sid: this.em.storeLocationSummary.sid, unitName } }
				});
			}
		});
	}

	/**
	 * @author KS
	 * @purpose Request next page
	 */
	nextPage() {
		this.store.dispatch(new AllSparePartsNextPageRequested({ param: {} }));
	}

	/**
	 * Load next sales options
	 */
	loadNext() {
		this.nextPage();
		if (this.lastCard < this.relatedProducts.length) {
			this.firstCard = this.firstCard + 9;
			this.lastCard = this.lastCard + 9;
		}
	}

	/**
	 * Load previous sales options
	 */
	loadPrevious() {
		if (this.firstCard > 0) {
			this.firstCard = this.firstCard - 9;
			this.lastCard = this.lastCard - 9;
		}
		if (this.firstCard < 0) {
			this.firstCard = 0;
			this.lastCard = 9;

		}
	}

	get showPrevious() {
		return this.firstCard > 0;
	}

	get showNext() {
		return true;
	}

	/** PT
   * description - share the spare parts with the user
   * @param id - spare part id
   */
	share(id: string | number) {
		// let url: string = `${webSiteUrl.production}/catalog/${id}`;
		let url: string = `https://dixiepartscentre.com/catalog/${id}`;
		
		const desc = `Details for spare part ${this.em.name}`;
		if (!!(<any>navigator) && (<any>navigator).share) {
			(<any>navigator)
				.share({
					title: 'Spare Part',
					text: desc,
					url,
				})
				.then(() => {
					logger.log('Thanks for sharing!');
				})
				.catch((err) => {
					logger.log("Couldn't share because of", err.message);
				});
		} else {
			logger.log('web share not supported');
			// fall back
			const msg = new MessageInfo({
				msgCss: 'primary',
				header: desc,
				description: url,
			});
			this._bottomSheet.open(SocialShareComponent, {
				data: {
					title: 'Spare Part',
					text: desc,
					url,
					shareOn: [ShareOn.email, ShareOn.whatsapp],
				},
			});
		}
	}

	get cardsOnHorizontalPage() {
		if (this.isHandset) {
			return 1;
		} else {
			return 3;
		}
	}

	get horizontalStart() {
		return 0 + this.cardsOnHorizontalPage * this.horizontalIncrement;
	}
	get horizontalEnd() {
		if (this.cardsOnHorizontalPage + this.cardsOnHorizontalPage * this.horizontalIncrement > this.relatedProducts.length) {
			return this.relatedProducts.length;
		} else {
			return this.cardsOnHorizontalPage + this.cardsOnHorizontalPage * this.horizontalIncrement;
		}
	}
	get horizontalIndex() {
		return 1 + this.horizontalIncrement;
	}
	get horizontalPages() {
		if (this.isHandset) {
			return this.relatedProducts.length;
		} else {
			return Math.ceil(this.relatedProducts.length / 3);
		}
	}
	addHorizontalIncrement() {
		if (this.horizontalIndex <= this.horizontalPages - 1) {
			this.horizontalIncrement = this.horizontalIncrement + 1;
		}
	}
	subHorizontalIncrement() {
		if (this.horizontalIndex - 1 > 0) {
			this.horizontalIncrement = this.horizontalIncrement - 1;
		}
	}
	get hPages() {
		const p = [];
		if (this.relatedProducts.length > 0) {
			for (let index = 0; index < this.horizontalPages; index++) {
				p.push(index);
			}
			return p;
		}
	}


}