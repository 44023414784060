import { Exclude, Expose, plainToInstance, instanceToInstance } from 'class-transformer';
import { logger } from '../log/logger';
import { IPdfContentData, IPdfContentSectionData, IPdfTemplateColumns, IPdfTemplateList, IPdfTemplateTable } from '../pdf/i-pdf-data-structure';

/**
 * @author - MKN
 * @purpose - PDFMake - Generate pdf enum
 */
export enum TemplateType {
  heading = 'Heading',
  subHeading = 'SubHeading',
  bulletList = 'bulletList',
  numberedList = 'numberedList',
  columns  = 'columns',
  newLine = 'newLine',
  table = 'table',
  pageBreakAfter = 'after',
  pageBreakBefore = 'before',
  pageBreakReset = 'reset'
}


@Exclude()
export class PdfTemplate {

  @Expose()
  pdfTemplateType: TemplateType;

  @Expose()
  columnCount ?: number;
  constructor() {
  }

  @Expose()
  pdfData: IPdfContentSectionData | IPdfTemplateList | IPdfTemplateColumns | IPdfTemplateTable;
}
