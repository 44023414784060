import { Exclude, Expose } from "class-transformer";
import { IValidationMsg } from "../error-handling";
import { ValidateIf, validateSync, ValidatorOptions } from 'class-validator';
import { toValidationError } from "../utility";

export interface IDaysTimings {
	start: string;
	end: string;
}
@Exclude()
export class StoreTimings {

	@Expose()
	@ValidateIf(o => !!o.monday)
	monday: IDaysTimings;

	@Expose()
	@ValidateIf(o => !!o.tuesday)
	tuesday: IDaysTimings;

	@Expose()
	@ValidateIf(o => !!o.wednesday)
	wednesday: IDaysTimings;

	@Expose()
	@ValidateIf(o => !!o.thursday)
	thursday: IDaysTimings;

	@Expose()
	@ValidateIf(o => !!o.friday)
	friday: IDaysTimings;

	@Expose()
	@ValidateIf(o => !!o.saturday)
	saturday: IDaysTimings;

	@Expose()
	@ValidateIf(o => !!o.sunday)
	sunday: IDaysTimings;

	constructor() {
		this.monday = { start: null, end: null };
		this.tuesday = { start: null, end: null };
		this.wednesday = { start: null, end: null };
		this.thursday = { start: null, end: null };
		this.friday = { start: null, end: null };
		this.saturday = { start: null, end: null };
		this.sunday = { start: null, end: null };
	}

	validateDays(r: IValidationMsg) {

		if (!this.monday.start || !this.monday.end || (this.monday.start > this.monday.end)) {
			r['monday'] = ['Enter valid monday start/end times'];
		}
		if (!this.tuesday.start || !this.tuesday.end || (this.tuesday.start > this.tuesday.end)) {
			r['tuesday'] = ['Enter valid tuesday start/end times'];
		}
		if (!this.wednesday.start || !this.wednesday.end || (this.wednesday.start > this.wednesday.end)) {
			r['wednesday'] = ['Enter valid wednesday start/end times'];
		}
		if (!this.thursday.start || !this.thursday.end || (this.thursday.start > this.thursday.end)) {
			r['thursday'] = ['Enter valid thursday start/end times'];
		}
		if (!this.friday.start || !this.friday.end || (this.friday.start > this.friday.end)) {
			r['friday'] = ['Enter valid friday start/end times'];
		}
		if ((this.saturday?.start > this.saturday?.end)) {
			r['saturday'] = ['Enter valid saturday start/end times'];
		}
		if ((this.sunday?.start > this.sunday?.end)) {
			r['sunday'] = ['Enter valid sunday start/end times'];
		}

		return r;
	}

	validateSync(options: ValidatorOptions): IValidationMsg {
		const m = validateSync(this, options);
		const r = toValidationError(m);
		this.validateDays(r);
		return r;
	}
}