import { addDays } from 'date-fns';
import { IUserClaim } from './../user/user-claim';
import { cloneDeep, pickBy, isDate } from 'lodash';
import { sanitizeDateIPoint } from '../utility/sanitize-helper';
import { AppSetting } from '../sys/app-setting';
import { ILocalStorage } from '../local-storage/local-storage';
import { promiseWraper } from '../utility/helper';
import { logger } from '../log/logger';

export const agreementStatusCollectionName = 'agreement';
export type AgreementScope = 'anonymous' | 'authenticated';

export interface IAgreement {
  scope?: AgreementScope;
  // aggreementType: AgreementType;
  pid: string;
  rev: number;
  userSignedDate?: Date;
  policyReleaseDate?: Date;
  name?: string;
}
export interface IPwaStatus {
  isPwaInstallationPromptDisabled?: boolean, uid?: string
}

/** structure saved in agreement collection in firestore. One for each user. userid is the primary key of collection  */
export interface IAgreementStatus {

  uid: string | number;

  netRev?: number;

  /** key is the record id. */
  curr: { [id: string]: IAgreement };

  history?: IAgreement[];

}

export const initAgreementStatus = (uid: string): IAgreementStatus => {
  return {
    uid: uid,
    curr: {}
  };
};

export const getNetPolicyRevision = (agreements: IAgreement[], uc: AgreementScope[] = ['authenticated', 'authenticated']): number => {
  const a = agreements.filter(x => uc.indexOf(x.scope) > -1);

  return (!!a && a.length > 0) ?
    a.map(x => x.rev).reduce((prev, curr) => prev + curr, 0) : 0;
};

export const getDefaultSiteAgreements = (a: { [key: string]: IAgreement }) => {
  const x: { [key: string]: IAgreement } = pickBy(a, (val) => val.scope === 'authenticated' || val.scope === 'anonymous');
  return x;
};

/** Get Agreements for new Users. */
export const getDefaultAgreements = (uid: string, appSetting: AppSetting): IAgreementStatus => {

  const a: IAgreementStatus = {
    uid: uid,
    curr: getDefaultSiteAgreements(appSetting.agreementRev),
  };
  a.netRev = getNetPolicyRevision(Object.values(a.curr));
  return a;
};

/** Update Default agreements for the users.  */
export const updateAgreementss = (orig: IAgreementStatus, reqdAgreements: { [key: string]: IAgreement }): IAgreementStatus => {

  const update = cloneDeep(orig);

  if (update.history == null) {
    update.history = [];
  }


  for (const key in reqdAgreements) {
    if (reqdAgreements.hasOwnProperty(key)) {
      const ele = reqdAgreements[key];

      /** set the user sign date as null, they will be updated by the calling api and set it as the latest date. */
      ele.userSignedDate = null;
      if (update.curr == null) {
        update.curr = {};
      }

      if (update.curr[key] == null) {
        update.curr[key] = ele;
      } else if (update.curr[key].rev < ele.rev) {
        update.history.push(update.curr[key]);
        update.curr[key] = ele;
      }

    }
  }
  update.netRev = getNetPolicyRevision(Object.values(update.curr));
  return update;
};

/** Get all the agreements that this user is reuiqred to agree to. Note: In future
 * this may require a function /db call. Example: If a user is a paid customer, a different
 * types of agreements may be rquired. For now, it is only using the default agreements.
 */
export const getUserReqdAgreements = (uid: string, appSetting: AppSetting): IAgreementStatus => {

  // FUTURE CODE 

  return getDefaultAgreements(uid, appSetting);
};

/** Get all the agreements that this user is reuiqred to agree to. Note: In future
 * this may require a function /db call. Example: If a user is a paid customer, a different
 * types of agreements may be rquired. For now, it is only using the default agreements.
 */
export const isAgreementDbCallReqd = (claim: IUserClaim, appSetting: AppSetting): boolean => {
  if (claim == null || appSetting == null) {
    return false;
  }

  /** Get the revision of agreed agreements for the user claim */
  const userAgreementRev = (!isNaN(+claim?.cClaim?.ag)) ? +claim.cClaim.ag : 0;

  const siteAgreements = getDefaultAgreements(claim.user_id, appSetting);
  const siteAgreementRev = getNetPolicyRevision(Object.values(siteAgreements.curr));

  return siteAgreementRev > userAgreementRev;
};

/** called after confirming that agreement db update is required. Handle the special case of failure of network.
 * i.e user agreed to the policies. His settings were saved in cache but db call failed. In this case, cache is read
 * If this function return true, user settings will be silently upgraded.
 * If this function return false, user will be presented with a dialog.
 */
export const canAgreementSilentDbUpdate = async (storage: ILocalStorage, uid: string, appSetting: AppSetting): Promise<boolean> => {
  try {
    const siteAgreements = getDefaultAgreements(uid, appSetting);
    const siteAgreementRev = getNetPolicyRevision(Object.values(siteAgreements.curr));
    let ag: { netRev: number, createdAt: Date } = await storage.get(`${uid}-ag`);
    if (ag == null) {
      ag = await storage.get(`anonymous-ag`);
    }
    if (ag == null || ag.createdAt == null || ag.netRev == null) {
      return false;
    }
    const d = addDays(ag.createdAt, 1);
    if (isDate(d) && !isNaN(ag?.netRev) && ag.netRev >= siteAgreementRev) {
      // const d = new Date();
      // date when cache was created it + 1 day must be in future.
      // d.setDate(ag.createdAt.getDate() + 1);
      return d > new Date();
    }
    // settings might be just saved by a new user. so that will be saved as anonymous
    return false;
  } catch (error) {
    logger.error('[Agreement Save Cache] Checking local cache for user agreement approval failed.');
    return false;
  }
};

export const saveAgreementInCache = async (storage: ILocalStorage, uid: string, appSetting: AppSetting): Promise<void> => {
  if (!!storage && !!appSetting) {
    try {
      const siteAgreements = getDefaultAgreements(!!uid ? uid : 'anonymous', appSetting);
      const siteAgreementRev = getNetPolicyRevision(Object.values(siteAgreements.curr));
      const ag: { netRev: number, createdAt: Date } = {
        netRev: siteAgreementRev,
        createdAt: new Date()
      };
      await storage.set(`${uid}-ag`, ag);
    } catch (error) {
      logger.error('[Agreement Save Cache] Saving user acceptace of agrements in local cache failed.', error);
    }
  }
};



export const isUpdateAgreementReqd = (curr: { [key: string]: IAgreement }, reqd: { [key: string]: IAgreement })
  : IAgreement[] => {

  const obj: IAgreement[] = [];

  for (const key in reqd) {
    if (reqd.hasOwnProperty(key)) {
      const r = reqd[key];
      if (curr == null || curr[key] == null || curr[key].rev < r.rev) {
        obj.push(r);
      }
    }
  }
  return obj;
};

export const saveCookiePolicyInStorage = async (pid: string, rev: number, storage: ILocalStorage) => {
  const cPP = await promiseWraper(storage.set(`${pid}`, rev));
  // save in local stoage
  if (!cPP.success) {
    logger.log('[agreement], failed to save cookie policy in storage', cPP.error);
  }
  // save in dB

};

export const savePwaInstallationPromptDisabled = async (storage: ILocalStorage) => {
  const pP = await promiseWraper(storage.set(`isPwaInstallationPromptDisabled`, true));
  // save in local stoage
  if (!pP.success) {
    logger.log('[AppInstaller], failed to save isPwaInstallationPromptDisabled flag in storage', pP.error);
  }
};






// export function createUpdateAgreementStatus(agreement: PageHtml[], uid: string, oAgreementStatus?: IAgreementStatus): IAgreementStatus {

//   const uAgreementStatus = oAgreementStatus ? { ...oAgreementStatus } : <IAgreementStatus>{ uid };

//   // uAgreementStatus.signedPrivacyPolicyRev = agreement.find(a => a.agreementType === AgreementType.privacyPolicy).revId;
//   uAgreementStatus.history = uAgreementStatus.history ? uAgreementStatus.history : [];
//   for (const p of agreement) {
//     uAgreementStatus.history.push({
//       aggreementType: p.agreementType,
//       aggreementPath: `${PageHtml.collectionName}/${p.id}/rev/${p.revId}`,
//       rev: p.revId,
//       userSignedDate: new Date()
//     });
//   }
//   return uAgreementStatus;
// }

// export const createUpdateAgreementStatus2 = (latestAgreements: { [key: string]: number; },
//   uid: string, oAgreementStatus?: IAgreementStatus): IAgreementStatus => {

//   // set clone u or set empty with uid
//   const uAgreementStatus = oAgreementStatus ? { ...oAgreementStatus } : <IAgreementStatus>{ uid };
//   const latestSignedAgreements: { [key: string]: IAgreement } = {};

//   // set history to empty array if undefined
//   uAgreementStatus.history = uAgreementStatus.history ? uAgreementStatus.history : <any>[];

//   // uAgreementStatus.historyAgreements.push(oAgreementStatus.currAgreements);

//   const agreements = getDefaultAgreements().filter(f => Object.keys(latestAgreements).includes(f.pid));
//   for (const a of agreements) {
//     latestSignedAgreements[a.pid] = {
//       aggreementType: a.type,
//       aggreementPath: `${PageHtml.collectionName}/${a.pid}/rev/${latestAgreements[a.pid]}`,
//       rev: latestAgreements[a.pid]
//     };
//   }

//   return uAgreementStatus;
// };
export const parseAgreementStatus = (obj: IAgreementStatus) => {
  if (obj == null) {
    return null;
  }
  obj = sanitizeDateIPoint(obj);
  return obj;
};
