import { IPoint } from '../product-search/interfaces';
// import * as geofirex from 'geofirex';
import {geohashForLocation} from 'geofire-common';

export function getGeoHash(iPoint: IPoint): string {
  // const geo = geofirex.init(firebase);
  return geohashForLocation([iPoint.latitude, iPoint.longitude]);
}
export function getGeoHashArr(iPoint: IPoint[]): string[] {
  const r: string[] = [];
  for (const itr of iPoint) {
    r.push(getGeoHash(itr));
  }
  return r;
}

