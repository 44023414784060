import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDividerModule } from '@angular/material/divider';
import { MatDialogModule } from '@angular/material/dialog';
import { MatBadgeModule } from '@angular/material/badge';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatGridListModule } from '@angular/material/grid-list';

import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import {MatTabsModule} from '@angular/material/tabs';

const matModules = [
  LayoutModule,
  MatMenuModule,
  ScrollingModule,
  MatToolbarModule,
  MatButtonModule,
  MatSidenavModule,
  MatIconModule,
  MatListModule,
  MatCardModule,
  MatFormFieldModule,
  MatInputModule,
  MatProgressSpinnerModule,
  MatDividerModule,
  MatInputModule,
  MatFormFieldModule,
  MatCheckboxModule,
  MatSelectModule,

  MatDialogModule,
  MatPaginatorModule,
  // MatSnackBarModule,
  // MatProgressSpinnerModule,
  // MatBottomSheetModule,

  // also add fontAwesome
  FontAwesomeModule,
  MatBadgeModule,
  MatExpansionModule,
  MatButtonToggleModule,
  MatRadioModule,
  MatTooltipModule,
  MatGridListModule,
  MatSlideToggleModule,
  MatTabsModule
];

@NgModule({
  declarations: [
    // HDocDetailComponent,
    // CkInlineDirective,
    // CkEditorCtrlComponent,
    // SubPageComponent,


  ],
  imports: [
    CommonModule,
    FormsModule,
    ...matModules,
  ],
  exports: [
    ...matModules,
    // CkInlineDirective,
    // HDocDetailComponent,
    // CkEditorCtrlComponent,
    // SubPageComponent,
    FormsModule

  ]
})
export class MaterialModule { }
