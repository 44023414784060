import { HttpClient } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { Injectable } from '@angular/core';
import { VinData } from '@trent/models/product/vin.model';


@Injectable()
export class VinService {
  vinInfoObs: any;
  vinInfo: any;
  VinDecode: number;
  reeferInfo: string;

  vinData: any;

  vinObject: VinData;
  isReefer: boolean;
  reeferDisplay: string;
  tl: number;

  constructor(public http: HttpClient) { }

  async getVin(vin: string): Promise<{}> {
    try {
      const response = await this.http
        // .get('https://vpic.nhtsa.dot.gov/api/vehicles/DecodeVin/' + vin + '/440?format=json')
        .get('https://vpic.nhtsa.dot.gov/api/vehicles/DecodeVin/' + vin + '/?format=json')
        .toPromise();
      console.log('data from http: ', response);

      return response;
    } catch (error) {
      await console.log(error);
    }
  }
  async getVinData(vin: string) {
    this.vinData = await this.getVin(vin);
    console.log('getVinData: ', this.vinData);
    if(!this.vinData){
      return null;
    }
    console.log('data sent from: ', this.vinData.Results);
      /**
     Variable: "Other Trailer Info" VariableId : 155 reported mulitple times,
      Used for loop to form concatenated string to find word reefer
    */
    this.tl = +this.vinData.Results.find(id => id.VariableId === 118).Value;
    if (this.tl > 0) {
      for (let i = 0, l = this.vinData.Results.length; i < l; i++) {
        if (this.vinData.Results[i].VariableId === 155) {
          this.reeferInfo = ('' + this.reeferInfo + this.vinData.Results[i].Value);  // '' to address empty values
        }
        console.log('reeferInfo: ', this.reeferInfo);
      }
      if (this.reeferInfo === '') {
        console.log('reeferInfo: ', this.reeferInfo.length);
        this.isReefer = false;
      } else if ((this.reeferInfo).search(/reefer/gi) === -1) {
        console.log('search check: ', (this.reeferInfo).search(/reefer/gi));
        this.isReefer = false;
      } else {
        this.isReefer = true;
      }
    } else {
      this.isReefer = false;
    }

    this.vinObject = {
      reg: this.vinData.Results.find(id => id.VariableId === 143).Value,
      bodyType: this.vinData.Results.find(id => id.VariableId === 5).Value,
      make: this.vinData.Results.find(id => id.VariableId === 26).Value,
      model: this.vinData.Results.find(id => id.VariableId === 28).Value,
      modelYear: +this.vinData.Results.find(id => id.VariableId === 29).Value,
      trailerLength: +this.vinData.Results.find(id => id.VariableId === 118).Value,
      nAxles: +this.vinData.Results.find(id => id.VariableId === 41).Value,
      isReefer: this.isReefer,
      engineModel: this.vinData.Results.find(id => id.VariableId === 18).Value,
      fuelType: this.vinData.Results.find(id => id.VariableId === 24).Value,
      doors: +this.vinData.Results.find(id => id.VariableId === 14).Value,
      wheelBase: +this.vinData.Results.find(id => id.VariableId === 111).Value,
      // engineManufacturer: this.vinData.Results.find(id => id.VariableId === 146).Value,
    };



    return this.vinObject;
  }


  // decodeVin(vin) {
  //   // console.log(vin.model);

  //   this.vinInfoObs = this.http.get('https://vpic.nhtsa.dot.gov/api/vehicles/DecodeVin/' + vin.model + '/440?format=json');
  //   console.log(this.vinInfoObs);
  //   this.vinInfo = this.vinInfoObs.subscribe(data => {
  //     this.vinInfo = data;
  //     console.log(this.vinInfo);
  //     /**
  //      * Check if Vin can be decoded
  //      * Manufacturer may not be registered, e.g utility
  //      */
  //     this.VinDecode = (this.vinInfo.Results[1].Value).search(/Manufacturer is not registered/gi);
  //     // this.VinDecode = (this.vinInfo.Results.find(id => id.VariableId === 118).Value).search(/Manufacturer is not registered/gi);
  //     console.log('VinDecode: ', this.VinDecode);
  //     if (this.VinDecode !== -1) {
  //       console.log(this.vinInfo.Results.find(id => id.VariableId === 118).Value);
  //       return;
  //     } else {
  //       console.log(this.vinInfo.Results.find(id => id.VariableId === 118).Value);
  //       this.make = this.vinInfo.Results[5].Value;
  //       console.log ('data sent', this.make);
  //       return this.make;

  //     }
  //   });
  // }

}



