import { Exclude, Expose, instanceToInstance } from 'class-transformer';
import { Matches, Length, IsEnum, IsInt, validateSync } from 'class-validator';
import { phoneRegexString, phoneCodeRegexString, toValidationError } from '../utility/validation-helper';
import { InspectionStatus } from './inspection-status';
import { IValidationMsg } from '../error-handling/validation-info';

export type InspectionDelegateValidationGroup = 'delegate' | 'getPhoneCode' | 'validatePhoneCode';
export const delegate: InspectionDelegateValidationGroup = 'delegate';
export const getPhoneCode: InspectionDelegateValidationGroup = 'getPhoneCode';
export const validatePhoneCode: InspectionDelegateValidationGroup = 'validatePhoneCode';
@Exclude()
export class InspectionDelegate {


  constructor() {

  }

  /** delegate's phone#. deleted when inspection is completed to block future access*/
  @Expose()
  @Matches(phoneRegexString, { message: 'Enter valid cell phone', groups: [delegate, getPhoneCode] })
  phone: string;

  /** uid of user who delegated the inspection */
  @Expose()
  @Length(16, 30, { message: 'Invalid User', groups: [delegate] })
  delegateBy: string | number;
  /** inspection completed by. Writen only when inspection is completed. Writen at the server. Does not required client validation*/
  @Expose()
  completedBy?: string;

  @Expose()
  @IsEnum(InspectionStatus, { message: 'Invalid Action', groups: [delegate, getPhoneCode] })
  action: InspectionStatus;

  @Expose()
  @Matches(phoneCodeRegexString, { message: 'Invalid Phone Code', groups: [validatePhoneCode] })
  phoneCode: number;

  /** UI helper to rensed phone verification code */
  resendCode: boolean;

  sanitize() {

    // super.sanitize();

  }
  clone() {
    const t = instanceToInstance(this);
    t.sanitize();
    return t;
  }
  validateSyncGroup(group: InspectionDelegateValidationGroup): IValidationMsg {
    return this.validateSync({ groups: [group] });
  }
  validateSync(options?: any): IValidationMsg {
    // const r = this.validateSyncBase(this, options);
    // for nested entry for address, add address group in it.

    const m = validateSync(this, options);
    const r = toValidationError(m);
    return r;
  }
}
