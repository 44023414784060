import { isNil } from 'lodash';
import { Router, ActivatedRoute } from '@angular/router';
import { DialogService } from '@trent/services/dialog/dialog.service';
import { Component, OnInit, OnDestroy, Inject, Input } from '@angular/core';
import { PageHtml, PageHtmlType, PageHtmlTemplate } from '@trent/models/cms/page-html';
import { Store } from '@ngxs/store';
import { EventService, IEventListener } from '@trent/services/event.service';
import { Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData, MessageInfo } from '@trent/models/error-handling/message-info';
import { CmsService } from '@trent/services/cms.service';
import { UtilityService } from '@trent/services/utility.service';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { FormDisplayMode } from '@trent/models/UI/form-display-mode';
import { logger } from '@trent/models/log/logger';
import { BasePage } from '@trent/models/UI/base.page';
import { SeoService } from '@trent/services/seo.service';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'trent-sub-page',
  templateUrl: './sub-page.component.html',
  styleUrls: ['./sub-page.component.scss']
})
export class SubPageComponent extends BasePage<any> implements OnInit, OnDestroy {

  /** if edit html link is to be displayed on the top shell menu. Note: this has to be true and 
   * component need to be displayed in a full page (i.e no modal) for link to be displayed. */
  @Input()
  showHtmlEditMenu = true;

  @Input()
  pageId: string;
  showHeader = true;
  isHandset: boolean;

  get isFullPage() { return this.dialogRef == null; }
  // get className() { return 'primary'; }
  className: string;

  /** Event Listner container. Only need to store so that ngDistroy can remove the linkages of events. */
  public eventListners: IEventListener[] = [];

  // pageId: string;
  readSection: string;

  page: PageHtml;

  pClazz = PageHtml;

  header = 'Sample Header';

  showBusy: boolean;

  dm = FormDisplayMode;

  displayMode: FormDisplayMode;

  pid: string;


  private timers = [];

  showAuto = false;

  constructor(
    store: Store,
    es: EventService,
    cms: CmsService,
    public seo: SeoService,
    public us: UtilityService,
    ds: DialogService,
    public router: Router,
    public aroute: ActivatedRoute,
    @Optional() public dialogRef: MatDialogRef<SubPageComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: DialogData<string>) {
    super(store, ds, es, cms);

  }

  loadPageIdData(data: any, param: any, qParam: any) {
    this.showAuto = data.showAuto || false;
    this.displayMode = !!data.mode ? +data.mode : FormDisplayMode.edit;
    if (this.displayMode == FormDisplayMode.create) {
      this.createNewPage();
      return false;
    }

    /** If component was called in as a part of a modal. Ex. Home page video */
    let pid: string;
    if (!this.isFullPage) {
      pid = this.data?.data;
      if (isNil(pid)) {
        return false;
      }
      const x = pid.split('#');
      if (x.length > 1) {
        pid = x[0];
        this.readSection = x[1];
      }
      if (this.pid == pid) {
        return false; // already loaded.
      }
      this.pid = pid;
      if (!!this.data) {
        switch (this.data.boxType) {
          case 'center-video':
            this.className = (this.us.isHandset) ? this.data.boxType + '-mobile' : this.data.boxType;
            this.showHeader = false;
            break;
          default:
            break;
        }
      }
      if (this.className == null) {
        this.className = (this.isFullPage) ? 'info-page' : 'info-modal';
      }
      return true;
    }

    /** page id is in param or in data or in qParam */
    if (this.isFullPage) {
      pid = param.pid || data.pid || qParam.pid;
      if (this.pid == pid) {
        return false;
      }
      this.pid = pid;
      return !isNil(this.pid);
    }
  }

  ngOnInit(): void {
    const mode = this.aroute.snapshot.data.mode;
    logger.log('mode: ', mode);
    this.displayMode = !!mode ? +mode : FormDisplayMode.edit;
    this.mSubscription.push(this.us.isHandset$.subscribe(h => this.isHandset = h));
    this.mSubscription.push(
      combineLatest([this.aroute.data, this.aroute.params, this.aroute.queryParams])
        .subscribe(([data, params, queryParams]) => {
          if (this.loadPageIdData(data, params, queryParams)) {
            this.loadPageHtml();
          }
        })
    );
    if (!this.pid && !!this.pageId) {
      this.pid = this.pageId;
      this.loadPageHtml();
    }
    return;


    // /** Mode and Show auto are in the route data. */
    // const mode = this.aroute.snapshot.data.mode;
    // this.showAuto = this.aroute.snapshot.data.showAuto;
    // /** Page id is in the param */
    // const x: PageHtmlTemplate = this.aroute.snapshot.params as any;
    // this.pClazz = getPageHtmlClazz(x.pageType);

    // logger.log('mode: ', mode);
    // this.displayMode = !!mode ? +mode : FormDisplayMode.edit;
    const snapShot = this.aroute.snapshot;
    this.loadPageIdData(snapShot.data, snapShot.params, snapShot.queryParams);

    if (this.displayMode === FormDisplayMode.create) {
      this.createNewPage();
      return;
    }

    if (this.isFullPage) {
      this.mSubscription.push(
        combineLatest([this.aroute.data, this.aroute.params, this.aroute.queryParams])
          .subscribe(([data, params, queryParams]) => {
            if (this.loadPageIdData(data, params, queryParams)) {
              this.loadPageHtml();
            }
          })
      );
      if (!this.pid && !!this.pageId) {
        this.pid = this.pageId;
        this.loadPageHtml();
      }
    } else {
      this.loadPageHtml();
    }
  }

  ngOnDestroy(): void {
    this.cleanListeners();
    // cleanListeners(this.eventListners, this.subs, this.timers);
    // this.es.emit(this.es.app_hideBusy, 0);
  }

  setTitle(t: string) {
    if (this.isFullPage) {
      this.es.emit<string>(this.es.app_setTitle, t);
    }
    this.header = t;
  }

  async loadPageHtml() {
    const sub = this.seo.loadPageHtml(this.pid, undefined, this.pClazz)
      .subscribe({
        next: p => {
          if (!!p) {
            this.page = p;
            // Dispatch the html edit link, if this is a full page.
            if (this.isFullPage) {
              const pageTemplate = {
                pid: `${p.id}`,
                pageType: PageHtmlType.pageHtml
              };
              if (this.showHtmlEditMenu) {
                // Fire the page html edit event so that edit html can be utilized by admin on the top menu.
                this.es.emit<PageHtmlTemplate>(this.es.menuShowHtmlEdit, pageTemplate);
              }
            }
            if (!!this.page.title) {
              this.setTitle(this.page.title);
            }
            // logger.log('[info-page] Content is: ', this.page.getSection(this.readSection));
          }
        },
        error: err => {
          this.hideAlert();
          this.hideLoading();
          this.hidePopover();
          this.showAlert(new MessageInfo({ description: 'Sorry Help information could not be loaded!' }));
        }
      });
    this.mSubscription.push(sub);
  }

  createNewPage() {
    const p = new PageHtml();
    p.id = !!this.pageId ? this.pageId : 'testId';
    if (!this.pageId) {
      p.title = 'Test title';
    }
    p.sections = {};
    for (let i = 0; i < 1; i++) {
      p.sections[`#tag${i}`] = {
        type: 'html',
        order: i,
        tag: `#tag${i}`,
        header: `header title : ${i}`,
        val: `<p>This is Sample text for ${i}. Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
         magna non dolor consectetur, vel dapibus lacus gravida. Sed non euismod turpis, at eleifend turpis.</p>`
      };
    }
    this.page = p;
    return true;

  }


}

