
import { Length, IsInt, Min, validateSync, Max, IsDate, IsDecimal, IsNumber, ValidateNested, IsDefined, IsOptional, IsEnum, IsBoolean, Validate, ValidateIf, Matches, ValidatorOptions, ArrayNotEmpty, IsArray } from 'class-validator';
import { BaseModel } from '../base/base-model';
import { plainToInstance, Expose, Exclude, instanceToInstance, Type } from 'class-transformer';
import { sanitizeDateIPoint, toPascalCase } from '../utility';
import { sanitizeDate, sanitizePhone } from '../utility/sanitize-helper';
import { IValDbStatusResult, IValidationMsg } from '../error-handling';
import { addDays, addYears, isDate, startOfDay } from 'date-fns';
import { Address } from '../address/address';
import { ContractType, Frequency, MaintainedBy, CreditRating, Status1, PartOfMultipleUnits, LegacyContractCountryOrigin } from './legacy-contract-enum';
import { ProductSummary } from '../product/product-helper';
import { CompanySummaryBase } from '../company/company-base';
import { LCExcelSummary } from './lc-excel-summary';
import { LCCustomerCompanySummary } from './lc-customer-company-summary';
import { LeaseSummary } from './lease-summary';
import { ProductSummaryBase } from '../product/product-base';
import { TruckSummary } from '../product';
import { LCProduct } from './lc-product';
import { Currency } from '../rental/rental-helper';
import { LCLeaseOperation } from './lc-lease-operation';
import { FileInfo } from '../media/file-info';
import { LCMaintenanceRecord } from '../maintenance/lc-maintenance-record';
import { LCSummary } from '../maintenance/maintenance-base';
import { reverseString } from '../utility/helper';
import { logger } from '../log/logger';
import { RoleEnum } from '../user';

/**
 * MKN - Legacy Contract Model
 */

/**
 * Interface for vendor Company Summary
 */
export interface VendorCompanySummary extends CompanySummaryBase {

}


//For Legacy Contract algolia dates
export const LCDates : string[] = ['lcLeaseOperation.holdbackRelDate1','lcLeaseOperation.holdbackRelDate2','lcLeaseOperation.buyoutDate','lcLeaseOperation.releaseDate','lcLeaseOperation.fundedOn', 'lcLeaseOperation.statusDate', 'leaseSummary.approvedOn', 'leaseSummary.firstPaymentDt','leaseSummary.lastPaymentDt', 'createdAt', 'updatedAt'];


/**
 * Interface for maintaining product summary
 */
// export interface LCProductSummary extends TruckSummary {
//     model: string
// }

/**
 * Interface for sales person information
 */
export interface SalesPerson {
    name: string
    uid?: string
}

export type LCPathFolder = 'legacy-contract-sharepoint' | 'legacy-contract';

export interface ILContractStorageMetadata {
    customMetadata: {
      uid?: string;
      /** lease No */
      leaseNo: number;
      /** year folder */
      year: string;
    };
  }
/**
 * Validation Group
 */
export type LegacyContractValidationGroup = 'general' | 'productSummary' | 'leaseSummary' | 'lcExcelSummary' | 'lcCustomerCompanySummary' | 'lcVendorCompanySummary' | 'lcMaintenancePackage' | 'lcProduct' | 'lcLeaseOperation' | 'lcDocuments';

const general: LegacyContractValidationGroup = 'general';
const productSummary: LegacyContractValidationGroup = 'productSummary';
const leaseSummary: LegacyContractValidationGroup = 'leaseSummary';
const lcExcelSummary: LegacyContractValidationGroup = 'lcExcelSummary';
const lcCustomerCompanySummary: LegacyContractValidationGroup = 'lcCustomerCompanySummary';
const lcVendorCompanySummary: LegacyContractValidationGroup = 'lcVendorCompanySummary';
export const lcLeaseOperation: LegacyContractValidationGroup = 'lcLeaseOperation';
export const lcDocuments: LegacyContractValidationGroup = 'lcDocuments';

export interface ILegacyContractUI {
    sNo: number;
    leaseNo: string;
    sequence: string;
    applicant: string;
    operatingName: string;
    firstName: string;
    lastName: string;
    address: string;
    city: string;
    province: string;
    postal: string;
    homePhone: string;
    businessPhone: string;
    cellPhone: string;
    salesPerson: string;
    year: number;
    make: string;
    model: string;
    vin: string;
    vendor: string;
    vinLastSix: string;
    term: number;
    remainingTerm: number;
    remainingTermCalculated: number;
    lastPaymentDt: Date;
    lastPaymentDtCalculated: Date;
    frequency: Frequency;
    approvedOn: Date;
    firstPaymentDt: Date;
    sellingPrice: number;
    gapPremium: number;
    walkAwayPremium: number;
    sellingPriceWithSp: number;
    down: number;
    adminFeetPine: number;
    downRcd: number;
    finAmtWithSp: number;
    finAmtWithoutSp: number;
    rental: number;
    gap: number;
    pymtBfrTax: number;
    endOfTerm: number;
    applicableTaxes: number;
    taxAmt: number;
    totalPayment: number
    fico: number;
    bni: number;
    creditRating: CreditRating;
    status1: string;
    status2: string;
    amtRecd: number;
    papDt: string;
    leaseAssociate: string;
    reserveRelease: number;
    contractType: ContractType;
    walkAway: number;
    fundedOn: Date;
    tranche: string;
    dmfReserve: number;
    reserveBal: number;
    releaseDate: Date;
    misc: string;
    mileageAllowance: number;
    maintainedBy: MaintainedBy;
    isIncomplete: boolean;
    isObsolete: boolean;
    partOfMultipleUnits: string

}


export interface ILegacyContractPapViewUI {
    leaseNo: string;
    applicant: string;
    firstPaymentDt: Date;
    pymtBfrTax: number;
    taxAmt: number;
    totalPayment: number;
    papDt: string;
    status1: string;
    status2: string;
    tranche: string;
    isIncomplete: boolean;
    isObsolete: boolean;
}

@Exclude()
export class LegacyContractBase extends BaseModel {


    public static readonly collectionName = 'legacy-contract-base';
    public static readonly srNoStartingNumber = 0;

    constructor() {
        super();

        this.contractType = ContractType.leaseToOwn;
        this.currency = 'CAD';
        this.status1 = Status1.paidByTpine;
        this.leaseSummary = new LeaseSummary();
        this.customerCompSummary = new LCCustomerCompanySummary();
        this.customerCompSummary.address = new Address();
        this.originalExcelSummary = new LCExcelSummary();
        this.lcLeaseOperation = new LCLeaseOperation();
        this.salesPerson = { name: '' };
        this.countryOrigin = LegacyContractCountryOrigin.ca;;
    }

    @Expose()
    sNo: number;

    //This is casitron LeaseNo
    @Expose()
    @Length(4, 50, { message: 'Lease No. must be $constraint1 - $constraint2 chars long', groups: [general] })
    leaseNo: string;

    @Expose()
    @ValidateIf(o => o.leaseSummary.partOfMultipleUnits === PartOfMultipleUnits.masterOfMultiple, {  groups: [general] })
    @Min(1, { message : 'Number of child records need to specified',  groups: [general]} )
    @Max(100, { message : 'Number of child records need to specified',  groups: [general]} )
    @IsInt({ message : 'Number of child records need to specified', groups: [general] })
    numberOfUnits: number;

    get leaseNoInNumber(){
        return isNaN(Number(this.leaseNo)) ? 0 : Number(this.leaseNo);
    }
    
    @Expose()
    @IsEnum(LegacyContractCountryOrigin)
    countryOrigin: LegacyContractCountryOrigin = LegacyContractCountryOrigin.ca;

    @Expose()
    get sequence(): string {
        const l = `${this.leaseNo}`.length;
        return (`${this.leaseNo}`).substring(l - 4, l);
    }

    @Expose()
    @IsOptional()
    @ValidateIf(o => o.salesPerson, {groups: [general]})
    @IsDefined({ message: 'Sales Person information need to defined', groups: [general] })
    salesPerson: SalesPerson;

    @Expose()
    @ValidateIf(o => !!o.broker && o.broker.length > 0, { groups: [general] })
    @Length(50, 0, { message: 'Broker information need to defined', groups: [general] })
    brokerName: string;

    @Expose()
    @IsDefined({ message: 'Vin Make Model Year need to defined', groups: [productSummary] })
    @ValidateNested({ message: 'Vin Make Model Year need to defined', groups: [productSummary] })
    lcProduct: LCProduct;

    // get vinLastSix() {
    //     const l = this.productSummary.vin.length;
    //     return this.productSummary.vin.substring(l - 6, l);
    // }

    @Expose()
    @IsDefined({ message: 'Leasing information need to defined', groups: [leaseSummary] })
    leaseSummary: LeaseSummary;

    @Expose()
    @IsDefined({ message: 'Customer company information need to defined', groups: [lcCustomerCompanySummary] })
    customerCompSummary: LCCustomerCompanySummary;

    @Expose()
    @IsDefined()
    originalExcelSummary: LCExcelSummary;

    @Expose()
    @IsDefined({ message: 'Vendor company information need to defined', groups: [lcVendorCompanySummary] })
    @ValidateNested({ message: 'Vendor company information need to defined', groups: [lcVendorCompanySummary] })
    vendorCompSummary: VendorCompanySummary;

    @Expose()
    @IsDefined({ message: 'Status1 should be defined', groups: [general] })
    @IsEnum(Status1)
    status1: Status1;

    @Expose()
    @Length(1, 400, { message: 'Status 2 must be $constraint1 - $constraint2 chars long', groups: [general] })
    status2: string;

    @Expose()
    @IsDefined({ message: 'Contract Type should be defined', groups: [general] })
    @IsEnum(ContractType)
    contractType: ContractType;

    @Expose()
    @ValidateIf(o => o.contractType === ContractType.rental, { groups: [general] })
    @Min(1000, { message: 'Mileage Allowance needs to be defined', groups: [general] })
    mileageAllowance: number;

    @Expose()
    @ValidateIf(o => o.contractType === ContractType.rental, { groups: [general] })
    @IsDefined({ message: 'Maintained By needs to be defined', groups: [general] })
    @IsEnum(MaintainedBy, { groups: [general] })
    maintainedBy: MaintainedBy;

    @Expose()
    currency: Currency;

    @Expose()
    @IsDefined({ message: 'Vendor company information need to defined', groups: [lcLeaseOperation] })
    @ValidateNested({ message: 'Vendor company information need to defined', groups: [lcLeaseOperation] })
    lcLeaseOperation: LCLeaseOperation;

    @Expose()
    @IsArray({ message: 'Upload Documents', groups: [lcDocuments] })
    @ArrayNotEmpty({ message: 'Upload Documents', groups: [lcDocuments] })
    @Type(() => FileInfo)
    lcDocuments: FileInfo[] = [];

    @Expose()
    isIncomplete: boolean = false;

    @Expose()
    isObsolete: boolean = false;

    @Expose()
    masterContractId: string;
    
    public static getPathByYear(leaseNoString: string, dataOrigin: 'sharepoint' | 'sharedDrive' = 'sharedDrive') {
        const suffix1 = dataOrigin === 'sharepoint' ? 'legacy-contract-sharepoint' : 'legacy-contract';
        let suffix2: string;
        let leaseNo: number;
        
        if(leaseNoString.length === 5 && +(leaseNoString[0]) >= 3) {
            suffix2 = 'current';
            leaseNo = +leaseNoString;
        } else {
            suffix2 = reverseString(leaseNoString.substring(0, 4));
            leaseNo = +leaseNoString.substring(4, leaseNoString.length);
        }
        return `/${suffix1}/${suffix2}/${leaseNo}`;
    }
    public static getSuffix2AndLeasNoFromEncryptedLeaseNo(leaseNoString: string) {
        let suffix2: string;
        let leaseNo: number;
        
        if(leaseNoString.length === 5 && +(leaseNoString[0]) >= 3) {
            suffix2 = 'current';
            leaseNo = +leaseNoString;
        } else {
            suffix2 = reverseString(leaseNoString.substring(0, 4));
            leaseNo = +leaseNoString.substring(4, leaseNoString.length);
        }
        return {suffix2, leaseNo};
    }
    /**
     * Parse Legacy Contract Data
     * @param obj 
     * @returns 
     */
    public static parse(obj: any) {
        if (obj == null) { return null; }
        const m = plainToInstance<LegacyContractBase, any>(LegacyContractBase, sanitizeDateIPoint(obj));
        m.sanitize();
        if (!m.lcLeaseOperation) {
            m.lcLeaseOperation = new LCLeaseOperation();
        }
        if (!m.lcProduct) {
            m.lcProduct = new LCProduct();
        }
        if (!m.customerCompSummary) {
            m.customerCompSummary = new LCCustomerCompanySummary();
        }
        if (!m.originalExcelSummary) {
            m.originalExcelSummary = new LCExcelSummary();
        }
        return m;
    }

    /**
     * Validate legacy contract data
     * @param options 
     * @returns 
     */
    validateSync(options?: ValidatorOptions): IValidationMsg {
        let r = this.validateSyncBase(this, options);

        if (options.groups && options.groups.indexOf(leaseSummary) > -1) {
            if (this.leaseSummary.partOfMultipleUnits == PartOfMultipleUnits.childOfMultiple) {

            } else {
                let tempR = this.leaseSummary.validateSync({ groups: [leaseSummary] });
                r = Object.assign(r, tempR);
            }

        }

        if (options.groups && options.groups.indexOf(lcExcelSummary) > -1) {
            let tempR = this.originalExcelSummary?.validateSync({ groups: [lcExcelSummary] });
            r = Object.assign(r, tempR);
        }
        if (options.groups && options.groups.indexOf(lcLeaseOperation) > -1) {
            let tempR = this.lcLeaseOperation?.validateSync({ groups: [lcLeaseOperation] });
            r = Object.assign(r, tempR);
        }

        if (options.groups && options.groups.indexOf(lcCustomerCompanySummary) > -1) {
            let tempR = this.customerCompSummary?.validateSync({ groups: [Address.gName, lcCustomerCompanySummary] });
            r = Object.assign(r, tempR);
        }
        
        // if (options.groups && options.groups.indexOf(lcDocuments) > -1) {
        //     if(!this.lcDocuments || (this.lcDocuments && this.lcDocuments.length == 0)){
        //         r['documents'] = ['Please upload the documents']
        //     }
        // }

        return r;
    }

    /**
     * Clone Model
     * @returns 
     */
    clone() {
        const t = instanceToInstance(this);
        t.sanitize();
        return t;
    }

    /**
     * sanitize decimal value
     * @param decimalStr 
     * @returns 
     */
    sanitizeAsDecimal(decimalStr: any) {
        decimalStr = + decimalStr;
        decimalStr = decimalStr.toFixed(2);
        decimalStr = + decimalStr;
        return decimalStr;
    }

    /**
     * Sanitize Data
     */
    sanitize() {
        // if data was recieved from firebase, date is stored as snapshot.
        super.sanitize();
        // this.fico = this.sanitizeAsDecimal(this.fico);

    }

    /**
     * Validation method on UI (Left panel)
     * @returns 
     */
    getValDbStatus(): IValDbStatusResult {
        const result: IValDbStatusResult = {
            pass: false,
            message: undefined,
            groupResult: {}
        };    //  { [group: string]: { result: boolean; message: string; } } = {};
        let x: { groupPass: boolean; message: string; };

        // General
        let r = this.validateSync({ groups: [general] });
        x = { groupPass: null, message: '' };
        x.groupPass = Object.keys(r).length === 0;
        x.message = (x.groupPass) ? 'Manage General Information' : 'General information is required';
        result.groupResult[general] = x;

        // lease Summary
        r = this.validateSync({ groups: [leaseSummary] });
        x = { groupPass: null, message: '' };
        x.groupPass = Object.keys(r).length === 0;
        x.message = (x.groupPass) ? 'Manage Leasing Information' : 'Leasing information is required';
        result.groupResult[leaseSummary] = x;

        // Product Summary
        r = this.validateSync({ groups: [productSummary] });
        x = { groupPass: null, message: '' };
        x.groupPass = Object.keys(r).length === 0;
        x.message = (x.groupPass) ? 'Manage Product Information' : 'Product information is required';
        result.groupResult[productSummary] = x;

        // Lease Documents

        result.groupResult[lcDocuments] = {groupPass: true, message: 'Manage Lease Documents'};

        // Customer Company Summary
        r = this.validateSync({ groups: [lcCustomerCompanySummary] });
        x = { groupPass: null, message: '' };
        x.groupPass = Object.keys(r).length === 0;
        x.message = (x.groupPass) ? 'Manage Customer Company Information' : 'Customer Company info is required';
        result.groupResult[lcCustomerCompanySummary] = x;

        // Vendor Company Summary
        r = this.validateSync({ groups: [lcVendorCompanySummary] });
        x = { groupPass: null, message: '' };
        x.groupPass = Object.keys(r).length === 0;
        x.message = (x.groupPass) ? 'Manage Vendor Company Information' : 'Vendor Company info is required';
        result.groupResult[lcVendorCompanySummary] = x;

        // Funding information
        r = this.validateSync({ groups: [lcLeaseOperation] });
        x = { groupPass: null, message: '' };
        x.groupPass = Object.keys(r).length === 0;
        x.message = (x.groupPass) ? 'Manage Funding Information' : 'Funding Information is required';
        result.groupResult[lcLeaseOperation] = x;

        return result;
    }


    /**
     * Remove column from front end array for displaying proper data
     * @param obj 
     * @returns 
     */
    public static removeBaseFields(obj) {
        let keys = Object.keys(obj);
        if(keys.indexOf("isIncomplete") > -1){
            keys.splice(keys.indexOf("isIncomplete"),1);
        }
        if(keys.indexOf("isObsolete") > -1){
            keys.splice(keys.indexOf("isObsolete"),1);
        }
        return keys;
    }

    toCamelCase(str) {
        return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
            return index === 0 ? word.toLowerCase() : word.toUpperCase();
        }).replace(/\s+/g, '');
    }

    /**
     * Generate legacy contract data from raw data
     * @param lc 
     */
    createLegacyContractGeneralData(lc: any) {
        this.leaseNo = `${lc.leaseNo}`;
        this.status1 = lc.status1;
        this.status2 = lc.status2;
        this.brokerName = lc.brokerName;
        this.contractType = lc.contractType ? (this.toCamelCase(lc.contractType)) as ContractType : null;
        this.lcDocuments = lc.lcDocuments;
        this.maintainedBy = lc.maintainedBy;
        this.mileageAllowance = lc.mileageAllowance;
        this.currency = lc.currency;
        this.sNo = + lc.sNo;
    }

    public static dateValidity(elem: any) {
        return isDate(elem) || false;
    }
    getLegacyContractUI(userType?: RoleEnum): ILegacyContractUI {
        const d: ILegacyContractUI = {
            sNo: this.sNo,
            leaseNo: this.leaseNo,
            sequence: this.sequence,
            applicant: this.customerCompSummary?.applicant,
            operatingName: this.customerCompSummary?.operatingName,
            firstName: this.customerCompSummary?.firstName,
            lastName: this.customerCompSummary?.lastName,
            address: this.customerCompSummary?.address?.addressFormated,
            city: this.customerCompSummary?.address?.city,
            province: this.customerCompSummary?.address?.stateProv,
            postal: this.customerCompSummary?.address?.postalZipCode,
            homePhone: this.customerCompSummary?.homePhone,
            businessPhone: this.customerCompSummary?.businessPhone,
            cellPhone: this.customerCompSummary?.cellPhone,
            salesPerson: this.salesPerson?.name,
            year: this.lcProduct?.modelYear,
            make: this.lcProduct?.make,
            model: this.lcProduct?.model,
            vin: this.lcProduct?.vin,
            vendor: this.vendorCompSummary?.legalName,
            vinLastSix: this.lcProduct?.vinLastSix,
            term: isNaN(this.leaseSummary?.term) ? undefined : this.leaseSummary?.term,
            remainingTerm: isNaN(this.leaseSummary?.remainingTerm) ? undefined : this.leaseSummary?.remainingTerm,
            remainingTermCalculated : isNaN(this.leaseSummary?.remainingTermCalculated) ? undefined : this.leaseSummary?.remainingTermCalculated,
            lastPaymentDt: this.leaseSummary?.lastPaymentDt,
            lastPaymentDtCalculated : this.leaseSummary?.lastPaymentDtCalculated,
            frequency: this.leaseSummary?.frequency,
            approvedOn: this.leaseSummary?.approvedOn,
            firstPaymentDt: this.leaseSummary?.firstPaymentDt,
            sellingPrice: isNaN(this.leaseSummary?.sellingPrice) ? undefined : this.leaseSummary?.sellingPrice,
            gapPremium: isNaN(this.leaseSummary?.gapPremium) ? undefined : this.leaseSummary?.gapPremium,
            walkAwayPremium: isNaN(this.leaseSummary?.walkAwayPremium) ? undefined : this.leaseSummary?.walkAwayPremium,
            sellingPriceWithSp: isNaN(this.leaseSummary?.sellingPrice) ? undefined : this.leaseSummary?.sellingPrice, 
            down: isNaN(this.leaseSummary?.down) ? undefined : this.leaseSummary?.down,
            adminFeetPine: isNaN(this.leaseSummary?.adminFeeTpine) ? undefined : this.leaseSummary?.adminFeeTpine,
            downRcd: isNaN(this.leaseSummary?.downRcd) ? undefined : this.leaseSummary?.downRcd,
            finAmtWithSp: isNaN(this.leaseSummary?.finAmtWithSp) ? undefined : this.leaseSummary?.finAmtWithSp,
            finAmtWithoutSp: isNaN(this.leaseSummary?.finAmtWithoutSp) ? undefined : this.leaseSummary?.finAmtWithoutSp,
            rental: isNaN(this.leaseSummary?.rental) ? undefined : this.leaseSummary?.rental,
            gap: isNaN(this.leaseSummary?.gap) ? undefined : this.leaseSummary?.gap,
            pymtBfrTax: isNaN(this.leaseSummary?.pymtBfrTax) ? undefined : this.leaseSummary?.pymtBfrTax,
            endOfTerm: isNaN(this.leaseSummary?.endOfTerm) ? undefined : this.leaseSummary?.endOfTerm,
            applicableTaxes: isNaN(this.leaseSummary?.applicableTaxes) ? undefined : this.leaseSummary?.applicableTaxes,
            taxAmt: isNaN(this.leaseSummary?.taxAmt) ? undefined : this.leaseSummary?.taxAmt,
            totalPayment: isNaN(this.leaseSummary?.totalPayment) ? undefined : this.leaseSummary?.totalPayment,
            fico: isNaN(this.customerCompSummary?.fico) ? undefined : this.customerCompSummary?.fico,
            bni: isNaN(this.customerCompSummary?.bni) ? undefined : this.customerCompSummary?.bni,
            creditRating: this.customerCompSummary?.creditRating,
            status1: this.status1,
            status2: this.status2,
            amtRecd: this.lcLeaseOperation?.amtRecd,
            papDt: this.leaseSummary?.papDt,
            leaseAssociate: this.leaseSummary?.leaseAssociate,
            reserveRelease: isNaN(this.leaseSummary?.reserveRelease) ? undefined : this.leaseSummary?.reserveRelease,
            contractType: this.contractType,
            walkAway: isNaN(this.leaseSummary?.walkAwayPremium) ? undefined : this.leaseSummary?.walkAwayPremium,
            fundedOn: this. lcLeaseOperation?.fundedOn,
            tranche: this.lcLeaseOperation?.tranche,
            dmfReserve: this.lcLeaseOperation?.dmfReserve,
            reserveBal: this.lcLeaseOperation?.dmfReserve,
            releaseDate: this.lcLeaseOperation?.releaseDate,
            misc: this.lcLeaseOperation?.misc,
            maintainedBy: this.maintainedBy,
            mileageAllowance: this.mileageAllowance,
            isIncomplete: this.isIncomplete,
            isObsolete: this.isObsolete,
            partOfMultipleUnits: this.leaseSummary?.partOfMultipleUnits,
        };

        //Added this code as we have to hide status1 and status2 from leasing sales person and will add the new interface when we will get the actual fields which we have to display to leasing sales.
        if(userType && userType === RoleEnum.leasingSales){
            delete d.status1;
            delete d.status2;
        }

        return d;

    }


    getPapViewLegacyContractUI(userType?: RoleEnum): ILegacyContractPapViewUI {
        const d: ILegacyContractPapViewUI = {
            leaseNo: this.leaseNo,
            applicant: this.customerCompSummary?.applicant,
            firstPaymentDt: this.leaseSummary?.firstPaymentDt,
            pymtBfrTax: isNaN(this.leaseSummary?.pymtBfrTax) ? undefined : this.leaseSummary?.pymtBfrTax,
            taxAmt: isNaN(this.leaseSummary?.taxAmt) ? undefined : this.leaseSummary?.taxAmt,
            totalPayment: isNaN(this.leaseSummary?.totalPayment) ? undefined : this.leaseSummary?.totalPayment,
            papDt: this.leaseSummary?.papDt,
            status1: this.status1,
            status2: this.status2,
            tranche: this.lcLeaseOperation?.tranche,
            isIncomplete: this.isIncomplete,
            isObsolete: this.isObsolete
        };

        if(userType && userType === RoleEnum.leasingSales){
            delete d.status1;
            delete d.status2;
        }

        return d;
    }

    /**
     * MKN -Get LC Summary for maintenance object
     * @returns 
     */
    getLCSummaryForMaintenance(): LCSummary {
        return {
            maintainedBy: this.maintainedBy,
            mileageAllowance: this.mileageAllowance,
            leaseNo: this.leaseNo,
            sNo : this.sNo,
            term: this.leaseSummary?.term,
            contractType: this.contractType,
            status1: this.status1,
            accountStanding: this.lcLeaseOperation.accountStanding,
            firstPaymentDt: this.leaseSummary.firstPaymentDt

        };
    }

    /**sNo
     * get next serial no based on @param latestContract latest contract 
     * @param latestContract BidBase
     * @returns 
     */
    getNextSerialNumber(latestContract?: LegacyContractBase) {

        this.sNo = 0;
        if (!!latestContract) {
            this.sNo = latestContract.sNo + 1;
        } else {
            this.sNo = LegacyContractBase.srNoStartingNumber + 1;
        }
    }

    validateDuplicateLease(contractsWithSameLeaseNo: LegacyContractBase[]) {
        let r: IValidationMsg = {};

        //MKN - added isObsolete condition to solve PGDT - 279
        const o = contractsWithSameLeaseNo.filter(f => !f.isObsolete && f.id !== this.id && f.leaseSummary?.partOfMultipleUnits !== PartOfMultipleUnits.childOfMultiple);
        switch (this.leaseSummary.partOfMultipleUnits) {
            case PartOfMultipleUnits.masterOfMultiple:
            case PartOfMultipleUnits.singleUnit:
                if(o.length > 0) {
                    r['leaseNo'] = [`Duplicate Lease No. ${this.leaseNo} exist in ${o[0].sNo}`];
                }
                return r;
            case PartOfMultipleUnits.childOfMultiple:
                if(o.findIndex(f => f.leaseNo === this.leaseNo && this.leaseSummary.partOfMultipleUnits !== PartOfMultipleUnits.masterOfMultiple) === -1) {
                    r['leaseNo'] = [`Master Lease  ${this.leaseNo} missing. Create Master Lease before creating child`];
                }
                return r;
        
            default:
                logger.error(`[legacy-contract-base] ${this.leaseSummary.partOfMultipleUnits} not defined`);
                break;
        }
        
    }

    updateChildrenToMaster(lc : LegacyContractBase){
        this.customerCompSummary = lc.customerCompSummary;
        this.vendorCompSummary = lc.vendorCompSummary;
        if(!this.leaseSummary) this.leaseSummary = new LeaseSummary();
        this.leaseSummary.partOfMultipleUnits = PartOfMultipleUnits.childOfMultiple;
        if(lc.originalExcelSummary){
            this.originalExcelSummary.updateLCOriginalExcelSummaryForChildren(lc.originalExcelSummary);
        }
    }

    public static isDateCheck(element: any) {
        if (element && element?.constructor === Object) {
          return false;
        }
    
        if(!element || element == 'Invalid Date'){
          return false;
        }
    
        if (!isDate(element)) {
          return false;
        }
    
          if (isDate(element)) {
            return LegacyContractBase.dateValidity(element) || false;
          }
      }
}
