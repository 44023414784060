import { CompanyValidationGroup, CompanyDocType, CompanyBase, ISales, IUserRole } from './../models/company/company-base';
import { AuthState } from './../store/auth-store/auth.state';
import { ValidatorOptions } from 'class-validator';
import { UserProfile } from './../models/user/user-profile';
import { Injectable } from '@angular/core';
import { FirestoreService } from '@trent/services/firestore.service';
import { map, tap } from 'rxjs/operators';
import { plainToInstance, instanceToPlain } from 'class-transformer';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { BaseHttpService } from '@trent/services/base-http.service';
import { Store } from '@ngxs/store';
import { CompanyFleet } from '@trent/models/company/company-fleet';
import { Observable } from 'rxjs';
import { PagingObesrvable } from '@trent/models/observable-util/paging-obesrvable';
import { CompanyDriver } from '@trent/models/company/company-driver';
import { RoleCompany } from '@trent/models/user';
import { Paging } from '@trent/models/observable-util/paging';
import { logger } from '@trent/models/log/logger';
import { ICompanyParam } from '@trent/models/company/company-param';
import { companySerachServerQuery } from '@trent/models/company/company-param';
import { StoreLocation } from '@trent/models/store-location/store-location';
import { limit, orderBy, query, startAfter, where } from '@angular/fire/firestore';

// https://angularfirebase.com/lessons/firestore-advanced-usage-angularfire/

@Injectable()
export class CompanyService extends BaseHttpService {


  allCompaines: PagingObesrvable<CompanyFleet, any>;

  constructor(store: Store, private db: FirestoreService, private http: HttpClient) {
    super(store);
    this.allCompaines = new PagingObesrvable<CompanyFleet, string>(this.db, this.getCompByUser_getBatch);
    this.apiName = 'api';
    // this.allCompaines.nextBatch = this.nextBatch;
  }

  public getSecureUrl(cid: string | number, docType: CompanyDocType) {
    // Prepare the post data
    const headers = this.addBearerToken();
    return this.http.post<{ url: string }>(this.getApiUrl('/company/getSecureUrl'),
      { cid, docType },
      // tslint:disable-next-line:object-literal-shorthand
      { headers: headers })
      .pipe(map(x => (!!x && !!x.url && x.url.length) ? x.url[0] : ''))
      .toPromise();
  }

  /** Send request to approve the record. */
  public requestApproval(cid: string | number, option?: ValidatorOptions) { // , c: CompanyFleet, option?: ValidatorOptions) {
    // Prepare the post data
    const headers = this.addBearerToken();
    return this.http.post<{ cid: string | number, company: CompanyFleet }>(this.getApiUrl('/company/requestApproval'),
      {
        // tslint:disable-next-line:object-literal-shorthand
        cid: cid,
        // company: instanceToPlain(c),
        option: option
      },
      // tslint:disable-next-line:object-literal-shorthand
      { headers: headers })
      .pipe(
        tap(r => logger.log('response: ', r)),
      );
  }

  cancelApprovalReq(cid: string | number) {
    // Prepare the post data
    const headers = this.addBearerToken();
    return this.http.post<{ cid: string | number, company: CompanyFleet }>(this.getApiUrl('/company/cancelApprovalReq'),
      {
        // tslint:disable-next-line:object-literal-shorthand
        cid: cid // c.draftIdUrl //,
        // company: instanceToPlain(c)
      },
      // tslint:disable-next-line:object-literal-shorthand
      { headers: headers })
      .pipe(
        tap(r => logger.log('response: ', r)),
      );
  }

  /** Send request to approve the record. */
  public admin_approveCompany(cid: string | number, option?: ValidatorOptions) { // :  CompanyFleet) {
    // Prepare the post data
    const headers = this.addBearerToken();
    return this.http.post<{ cid: string | number, company: CompanyFleet }>(this.getApiUrl('/company/adminApprove'),
      {
        // tslint:disable-next-line:object-literal-shorthand
        cid: cid, // c.draftIdUrl //,
        // company: instanceToPlain(c)
        option: option
      },
      // tslint:disable-next-line:object-literal-shorthand
      { headers: headers })
      .pipe(
        tap(r => logger.log('response: ', r)),
      );
  }


  public update(cid: string | number, c: CompanyFleet | CompanyDriver | StoreLocation, option?: ValidatorOptions) {
    // Prepare the post data
    const headers = this.addBearerToken();

    // Server validation before creating the entry.
    return this.http.post<{ cid: string | number, company: CompanyFleet | CompanyDriver }>(this.getApiUrl('/company/update'),
      {
        // tslint:disable-next-line:object-literal-shorthand
        cid: cid,
        company: instanceToPlain(c),
        // tslint:disable-next-line:object-literal-shorthand
        option: option
      },
      // tslint:disable-next-line:object-literal-shorthand
      { headers: headers })
      .pipe(
        tap(r => logger.log('response: ', r)),
      );
  }

  public buildDefaultCompany(p: UserProfile) {
    const c = new CompanyFleet();
    c.name = `${p.displayName}'s Company`;
    const g: CompanyValidationGroup = 'initial';
    return this.create(c, { groups: [g] }).toPromise();
  }

  public create(c: CompanyFleet | CompanyDriver | StoreLocation, option: ValidatorOptions, draftBidId?: string, email?: string) {
    // Prepare the post data
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + this.token });

    // Server validation before creating the entry.
    return this.http.post<{ cid: string | number, company: CompanyFleet | CompanyDriver }>(this.getApiUrl('/company/create'),
      {
        company: instanceToPlain(c),
        draftBidId: draftBidId,
        // tslint:disable-next-line:object-literal-shorthand
        option: option,
        email: email
      },
      // tslint:disable-next-line:object-literal-shorthand
      { headers: headers })
      .pipe(
        tap(r => logger.log('response: ', r)),
      );
  }
  setLesseeLessor(c: CompanyFleet, option: ValidatorOptions) {
    // Prepare the post data
    const headers = this.addBearerToken();
    return this.http.post<{ cid: string | number, company: CompanyFleet }>(this.getApiUrl('/company/setLesseeLessor'),
      {
        company: instanceToPlain(c),
        option: option

      },
      { headers: headers })
      .pipe(
        tap(r => logger.log('response: ', r)),
      );
  }

  public deleteDraft(cid: string | number) {
    // Prepare the post data
    const headers = this.addBearerToken();

    // Server validation before creating the entry.
    return this.http.post<{ cid: string | number, product: CompanyBase }>(this.getApiUrl('/company/delete-draft'),
      {
        cid: cid,
      },
      { headers: headers })
      .pipe(
        tap(r => console.log('response: ', r))
      );
  }

  public getAllCompanies(uid: string | number) {

    // Get companies
    const c = this.store.selectSnapshot(AuthState.userClaim);
    logger.log('default company is: ', Object.keys(c.cClaim.cRoles));

    // const f = firebase.firestore();
    // const xx = f.doc(`${CompanyFleet.collectionName}/1XTSoCZMLhnipi2Llgsx`).get()
    // .then(d => logger.log('doc data fetched from promise: ', d))
    // .catch(err => logger.error(err));

    // const yy = f.collection(CompanyFleet.collectionName)
    // .where(`authUsers.${uid}.dataFlag`, '==', true)
    // .get()
    // .then(d => logger.log('collection fetched from promise: ', d))
    // .catch(err => logger.error(err));

    // return this.db.colWithIdsInjected$<CompanyFleet>(CompanyFleet.collectionName);
    // logger.log('company URL is : ', `authUsers.${uid}.dataFlag`);

    // normal reader only
    // this.db.docWithInjectedId$<CompanyFleet>(`${CompanyFleet.collectionName}/1XTSoCZMLhnipi2Llgsx`).subscribe(
    //   x => logger.log('single doc fetched', x)
    // );

    // Only admin access
    // this.db.docWithInjectedId$<CompanyFleet>(`${CompanyFleet.collectionName}/3rfvr3rrSWayIOwe101B`).subscribe(
    //   x => logger.log('single doc fetched', x)
    // );

    // const a: CompanyFleet[] = [];
    // return of(a);
    return this.db.colWithIdsInjected$<CompanyFleet>(CompanyFleet.collectionName,
      // ref =>
      // ref.where(`authUsers.${uid}.dataFlag`, '==', true)
      // ref.where(`id`, '==', '1XTSoCZMLhnipi2Llgsx')
    );
  }

  public getCompaniesByUser() {

    // const compRef = this.db.colWithIds$<CompanyFleet>('company', ref => {
    //   ref.where('owner.uid', '==', 'SARtOdAqXlZdzSyIUD7pOStrAK62');
    // });

    const compRef = this.db.colWithIds$<CompanyFleet>('company');

    return compRef.pipe(
      map(x => {
        const R: CompanyFleet[] = [];
        // Inject the ids in to the model.
        for (const a of x) {
          const c = plainToInstance<CompanyFleet, CompanyFleet>(CompanyFleet, a.data);
          c.id = a.id;
          c.sanitize();
          R.push(c);
        }
        return R;
      }));
  }

  getCompanyById(id: string | number) {
    return this.db.docWithInjectedId$<CompanyBase>(
      // `${CompanyFleet.collectionName}/${resolveId(id)}`);
      `${CompanyFleet.collectionName}/${id}`, id);
  }

  updateCompanyUserRoles(cid: string | number, uid: string | number, role: RoleCompany) {

    logger.log('input data : ', cid, uid, role);
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + this.token });

    // Server validation before creating the entry.
    return this.http.post<any>(this.getApiUrl('/company/updateUserRole'),
      { cid, uid, role },
      // tslint:disable-next-line:object-literal-shorthand
      { headers: headers })
      .pipe(
        tap(r => logger.log('response: ', r)),
      );
  }

  addCompanyUserByEmail(cid: string | number, email: string, role: RoleCompany) {
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + this.token });
    logger.log('email is: ', email);

    // Server validation before creating the entry.
    return this.http.post<any>(this.getApiUrl('/company/addUserRole'),
      { cid, email, role },
      // tslint:disable-next-line:object-literal-shorthand
      { headers: headers })
      .pipe(
        tap(r => logger.log('response: ', r)),
      );
  }

  removeCompanyUser(cid: string | number, uid: string | number) {
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + this.token });

    // Server validation before creating the entry.
    return this.http.post<any>(this.getApiUrl('/company/removeCompanyUser'),
      { cid, uid },
      // tslint:disable-next-line:object-literal-shorthand
      { headers: headers })
      .pipe(
        tap(r => logger.log('response: ', r)),
      );
  }

  setDefaultCompany(cid: string | number, uid: string | number) {
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + this.token });

    // Server validation before creating the entry.
    return this.http.post<UserProfile>(this.getApiUrl('/company/setDefaultCompany'),
      { cid, uid },
      // tslint:disable-next-line:object-literal-shorthand
      { headers: headers })
      .pipe(
        tap(r => logger.log('response: ', r)),
      );
  }

  // #region Paging Load
  public getCompaniesByUser1(p: Paging, uid: string) {
    return this.allCompaines.getData(p, uid);
  }

  /**
   * Get the next batch of the data from server.
   * @param p paging information.
   */
  private getCompByUser_getBatch(p: Paging, uid: string): Observable<{ [key: string]: CompanyFleet }> {
    logger.log('Company Server called with', p.offset, p.size);
    return this.db
      .colWithIdsInjected$<CompanyFleet>(`${CompanyFleet.collectionName}`, ref =>
        query(ref,
          // .where('owner.uid', '==', uid)  /** OWNER */
          //  .where(`authUsers.${uid}`, '>', 0)
          // .where(`authUsers.${uid}.dataFlag`, '>', 0)
          // .where('adminId', 'array-contains', uid)
          where('companyUsers', 'array-contains', uid),
          orderBy('name'),
          startAfter(p.offset),
          limit(p.size)))
      .pipe(
        tap(arr => {
          if (arr == null || arr.length === 0) {
            logger.log('All data is received, Paging is FULL');
            p.full = true;
          }
        }),
        map(arr => {
          // logger.log('From Server, before mapping is applied: ', arr);

          // convert array to { [id: string] = CompanyFleet} object.
          const arrayAsObj = arr.reduce((acc, cur) => {
            const id = cur.id;
            const data = cur;
            return { ...acc, [id]: data };
          }, {});

          // logger.log('after converting to array object dic ', arrayAsObj);
          return arrayAsObj;
        })
      );
  }

  // #endregion
  /**
   * ger all companies (admin request)
   */
  public getAllCompanies_PagingObservable() {
    const p$: PagingObesrvable<CompanyBase, ICompanyParam> =
      new PagingObesrvable<CompanyBase, ICompanyParam>(this.db,
        (p: Paging, param?: ICompanyParam) => this.getAllCompanies_batch(p, param));
    return p$;
  }

  /**
* Get the next batch of the data from server.
* @param p paging information.
*/
  private getAllCompanies_batch(p: Paging, param: ICompanyParam): Observable<{ [key: string]: CompanyBase }> {
    if (param.orderBy == null) { param.orderBy = 'updatedAt'; }
    if (param.orderDirection == null) { param.orderDirection = 'desc'; }
    return this.db
      .colWithIdsInjectedNew$<CompanyBase>(`${CompanyBase.collectionName}`, ref => companySerachServerQuery(ref, param, p))
      .pipe(
        tap(arr => {
          if (arr == null || arr.length === 0) {
            logger.log('All data is recevied, Paging is FULL');
            p.full = true;
          } else {
            p.lastDoc = arr[arr.length - 1];
          }
        }),
        map(arr => {
          logger.log('From Server, before mapping is applied: ', arr);

          const arrayAsObj = arr.reduce((acc, cur) => {
            const id = cur.id;
            const data = cur;
            return { ...acc, [id]: data };
          }, {});

          // logger.log('after converting to array object dic ', arrayAsObj);
          return arrayAsObj;
        })
      );
  }

  /**
  * @author - MKN
  * @purpose - Assign role group to company
  */
  assignRoleGroupToCompany(cid: string | number, rgId: string | number) {
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + this.token });

    // Server validation before creating the entry.
    return this.http.post<any>(this.getApiUrl('/company/assignRoleGroupToCompany'),
      { cid, rgId },
      // tslint:disable-next-line:object-literal-shorthand
      { headers: headers })
      .pipe(
        tap(r => logger.log('response: ', r)),
      );
  }

  /**
   * @author - MKN
   * @purpose - Update role group Info (Level access) in company level
   */

  updateAssignedCompanyRoleGroup(cid: string | number, rgId: string | number) {

    logger.log('input data : ', cid, rgId);
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + this.token });

    // Server validation before creating the entry.
    return this.http.post<any>(this.getApiUrl('/company/updateAssignedCompanyRoleGroup'),
      { cid, rgId },
      // tslint:disable-next-line:object-literal-shorthand
      { headers: headers })
      .pipe(
        tap(r => logger.log('response: ', r)),
      );
  }

  /**
   * @author - MKN
   * @purpose - Remove role group from company
   */
  removeRoleGroupFromCompany(cid: string | number, rgId: string | number) {
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + this.token });

    // Server validation before creating the entry.
    return this.http.post<any>(this.getApiUrl('/company/removeRoleGroupFromCompany'),
      { cid, rgId },
      // tslint:disable-next-line:object-literal-shorthand
      { headers: headers })
      .pipe(
        tap(r => logger.log('response: ', r)),
      );
  }

  /**
 * @author - Cm
 * @purpose - Admin add sale/credit person in company
 */
  addSalePersonInCompany(cid: string | number, uid: string | number, data: ISales | IUserRole) {
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + this.token });

    // Server validation before creating the entry.
    return this.http.post<any>(this.getApiUrl('/company/updateCompanyAddSale'),
      { cid, uid, data },
      // tslint:disable-next-line:object-literal-shorthand
      { headers: headers })
      .pipe(
        tap(r => logger.log('response: ', r)),
      );
  }

  /**
* @author - Cm
* @purpose - Admin remove sale/credit person in company
*/
  removeSalePersonInCompany(cid: string | number, uid: string | number) {
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + this.token });

    // Server validation before creating the entry.
    return this.http.post<any>(this.getApiUrl('/company/updateCompanyRemoveSale'),
      { cid, uid },
      // tslint:disable-next-line:object-literal-shorthand
      { headers: headers })
      .pipe(
        tap(r => logger.log('response: ', r)),
      );
  }


}
