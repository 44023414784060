import { Equals, IsBoolean, isNumber, IsNumber, Length, Max, ValidateIf, validateSync, IsDefined, ValidateNested, IsDate, ValidatorOptions, isDefined } from 'class-validator';
import { Exclude, instanceToInstance, plainToInstance, Expose, Type } from 'class-transformer';
import { IValidationMsg } from '../error-handling';
import { sanitizeDate, sanitizeDateIPoint, toValidationError } from '../utility';
import { addYears, isAfter, lastDayOfMonth } from 'date-fns';
import { BaseModel } from '../base';
import { logger } from '../log/logger';
import { SalesPerson } from '../legacy-contract/legacy-contract-base';
import { isNil } from 'lodash';


/**
 * @author - MKN
 * @purpose - 
 */
@Exclude()
export class PromotionLeads extends BaseModel {


    
  public static readonly collectionName = 'promotion-leads';

  @Expose()
  uId: string;

  @Expose()
  phoneNumber: string;

  @Expose()
  email: string;

  @Expose()
  companyName: string;

  @Expose()
  isEmailUnSubscribed: boolean;
  @Expose()
  isSMSUnSubscribed: boolean;

  @Expose()
  accountManager : SalesPerson;

  @Expose()
  respondedTo?: string[];

  @Expose()
  stateProvince: string;

  @Expose()
  country: 'USA' | 'CA';

  @Expose()
  pendingEmailPromotions?: string[];
  @Expose()

  pendingSMSPromotions?: string[];
  @Expose()

  completedEmailPromotions?: string[];
  @Expose()
  completedSMSPromotions?: string[];

  constructor() {
    super();
  }

  public static parse(obj) {
    if (obj instanceof (PromotionLeads)) { return <PromotionLeads>obj; }

    const m = plainToInstance<PromotionLeads, any>(PromotionLeads, sanitizeDateIPoint(obj));
    m.sanitize();
    return m;
  }

  sanitize() {
    // super.sanitize();
  }

  clone() {
    const t = instanceToInstance(this);
    t.sanitize();
    return t;
  }

  validateSync(options?: any): IValidationMsg {

    const m = validateSync(this, options);
    const r = toValidationError(m);
    return r;
  }

  public static checkMobileNumber(mobile : string){
    if(mobile == '#N/A' || mobile == ''  || mobile == '0'){
      return null;
    }

    let phone = `${mobile}`.replace(/\D/g, '').trim();
    return phone.length < 10 ? null : phone;
  }

  convertMobileNumber(){
    this.phoneNumber = this.phoneNumber ? `${this.phoneNumber}`.replace(/\D/g, '').trim() : null;
  }

   /**
     * Generate legacy contract data from raw data
     * @param lc 
     */
   createPromotionLeadsData(pl: any) {
    this.email = pl.email;
    this.phoneNumber = PromotionLeads.checkMobileNumber(pl.mobile);
    this.companyName = pl.companyName;
    this.stateProvince = pl.stateProvince;
    this.country = pl.country;
    this.accountManager = { name : pl.salesPerson };
  }

  setupEmailDistribution(promotionId: any) {
    if(!!this.isEmailUnSubscribed) {
      return;
    }
    if(!this.pendingEmailPromotions || this.pendingEmailPromotions.length === 0) {
      this.pendingEmailPromotions = [];
    }
    if(!this.pendingEmailPromotions.includes(promotionId)) {
      this.pendingEmailPromotions.push(promotionId);
    }
  }

  setupSMSDistribution(promotionId: any) {
    if(!!this.isSMSUnSubscribed) {
      return;
    }
    if(!this.pendingSMSPromotions || this.pendingSMSPromotions.length === 0) {
      this.pendingSMSPromotions = [];
    }
    const phoneNumber = PromotionLeads.checkMobileNumber(this.phoneNumber);

    if(!this.pendingSMSPromotions.includes(promotionId) && !isNil(phoneNumber)) {
      this.pendingSMSPromotions.push(promotionId);
    }
  }
  completeEmailPromotion(promotionId: any) {
    if(!this.pendingEmailPromotions || this.pendingEmailPromotions.length === 0) {
      throw new Error('condition of pendingEmailPromotions should not occur');
    }
    this.pendingEmailPromotions = this.pendingEmailPromotions.filter(f => f !== promotionId);
    if(! this.completedEmailPromotions || this.completedEmailPromotions.length === 0) {
      this.completedEmailPromotions = [];
    }
    if(!this.completedEmailPromotions.includes(promotionId) || !this.email) {
      this.completedEmailPromotions.push(promotionId);
    }
  }
  completeSMPromotion(promotionId: any) {
    if(!this.pendingSMSPromotions || this.pendingSMSPromotions.length === 0) {
      throw new Error('condition of pendingEmailPromotions should not occur');
    }
    this.pendingSMSPromotions = this.pendingSMSPromotions.filter(f => f !== promotionId);
    if(!this.completedSMSPromotions || this.completedSMSPromotions.length === 0) {
      this.completedSMSPromotions = [];
    }
    if(!this.completedSMSPromotions.includes(promotionId)) {
      this.completedSMSPromotions.push(promotionId);
    }
  }
  removeBadPhones() {
    const phoneNumber = PromotionLeads.checkMobileNumber(this.phoneNumber);
    if(!phoneNumber) {
      this.pendingSMSPromotions = null;
    }
  }
}