import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { ReleaseStatusComponent } from './release-status.component';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../share-module/material.module';

@NgModule({
    declarations: [ReleaseStatusComponent],
    exports: [ReleaseStatusComponent],
    imports: [
        CommonModule,
        RouterModule,
        MaterialModule
    ],
    providers: [DatePipe]
})
export class ReleaseStatusModule { }
