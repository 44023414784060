

export enum TransactionType {
  credit = 10,
  debit = 20
}


export const validateTransacType = (t: TransactionType) => {
  if(t === TransactionType.credit || t === TransactionType.debit) {
    return true;
  }
  return false;
}
