<ng-container *ngIf="!!rental && !horizontalCarousel">
  <ng-container *ngIf="tl(rental) as val">
    <mat-card-header *ngIf="!showMiniCard; else miniCardHeader">
      <div class="imageDiv">

        <mat-icon color="primary" svgIcon="truckIcon" *ngIf="val?.productSummary?.productType === pTypeEnum.truck"></mat-icon>
        <mat-icon color="primary" svgIcon="trailerIcon" *ngIf="val?.productSummary?.productType === pTypeEnum.trailer"></mat-icon>

        <!-- <mat-icon color="primary">{{val.productSummary.prodIcon}}</mat-icon> -->
      </div>
      <div class="header-title--area">
        <mat-card-title>{{val?.productSummary?.title}} {{val?.productSummary?.modelYear}}
          (#{{val?.productSummary.unitName}})</mat-card-title>
        <mat-card-subtitle>{{(val?.productSummary.make)}} </mat-card-subtitle>
        <mat-card-subtitle style="margin-bottom: 0px;">{{val?.productSummary.summaryString}}</mat-card-subtitle>
      </div>
    </mat-card-header>
    <ng-template #miniCardHeader>
      <mat-list class="item-list" style="padding:0 !important;color: #000000DE;">
        <mat-list-item>
          <mat-icon matListItemIcon>{{val?.productSummary.prodIcon}}</mat-icon>
          <div matListItemTitle>&nbsp;{{val?.productSummary.summaryString}}</div>
          <div matListItemLine>&nbsp;{{(val?.productSummary.make)}}({{val?.productSummary.modelYear}})</div>
        </mat-list-item>
      </mat-list>

    </ng-template>

    <mat-card-content>
      <ng-container *ngIf="!showMiniCard">
        <trent-carousel #carouselCtrl class="pod-pictures" [pics]="val?.pictures" [showIndex]="0"
          [containerHeight]="'250'" [eMode]="false" [hidePage]="true" [hideThumbnails]="true" [showDots]="true">
        </trent-carousel>
        <div *ngIf="(!val?.pictures || val?.pictures.length===0 && !!val?.defaultImage)" class="default-img-cont">
          <div>
            <img [src]="val?.defaultImage" class="default-image">
          </div>
        </div>
      </ng-container>
      <h4 *ngIf="!!val?.promoContent" style="padding-bottom:0px; margin-bottom: 10px; margin-top: 10px; color: var(--accent);">{{val?.promoContent}}</h4>

      <div mat-subheader class="docs-component-category-list-card-title">Rent({{val?.currency}}):&nbsp;
        {{val.getLowestRentString(false)}}
      </div>
        <mat-card-subtitle style="padding-top: 10px;">{{val?.getRentString2(false)}} &nbsp;</mat-card-subtitle>
        <!-- <mat-card-subtitle style="padding-top: 10px;" *ngIf="!!val?.getCommonMileRateString()">{{val?.getCommonMileRateString()}}</mat-card-subtitle> -->
        <mat-card-subtitle>Mileage({{val?.currency}}): <span [innerHtml]="val.getMileRateString()"></span></mat-card-subtitle>
      <mat-list class="item-list">
        <mat-list-item class="line-item" *ngIf="!!showMiniCard">
          <mat-icon matListItemIcon color="primary">date_range</mat-icon>
          <div matListItemTitle class="docs-component-category-list-card-summary">&nbsp;{{val?.availStartDate | date}} - {{val?.availEndDate | date}}</div>
        </mat-list-item>
        <ng-container *ngFor="let item of val?.openDateRange">
          <mat-list-item class="line-item" *ngIf="!showMiniCard">
            <mat-icon matListItemIcon color="primary">date_range</mat-icon>
            <div matListItemTitle class="docs-component-category-list-card-summary">&nbsp;{{item.start | date}} - {{item.end | date}}
            </div>
          </mat-list-item>
        </ng-container>
        <mat-list-item>
          <mat-icon matListItemIcon color="primary">location_on</mat-icon>
          <a matListItemTitle class="docs-component-category-list-card-summary"
            (click)="showLocation()">{{val?.startAddress.addressShort}}</a>
        </mat-list-item>
      </mat-list>
    </mat-card-content>
    <mat-card-actions *ngIf="showReplaceActions">
      <div align="end">
        <button mat-flat-button color="primary" (click)="selectEmitter.emit(val?.id)">Select</button>
      </div>
    </mat-card-actions>
    <mat-card-footer class="card-footer" *ngIf="!showReplaceActions">
      {{val?.vendorCompSummary?.legalName}}({{val?.id}})
    </mat-card-footer>
    <mat-card-footer class="card-footer" *ngIf="showReplaceActions">
      {{val?.productSummary?.vin}}(#{{val?.productSummary?.unitName}})
    </mat-card-footer>

  </ng-container>
</ng-container>

<ng-container *ngIf="!!horizontalCarousel">
  <ng-container *ngIf="tl(rental) as val">
    <mat-card-header style="height: 95px;">
      <div class="imageDiv">
        <mat-icon color="primary" svgIcon="truckIcon" *ngIf="val?.productSummary.productType === pTypeEnum.truck"></mat-icon>
        <mat-icon color="primary" svgIcon="trailerIcon" *ngIf="val?.productSummary.productType === pTypeEnum.trailer"></mat-icon>
        <!-- <mat-icon color="primary">{{val.productSummary.prodIcon}}</mat-icon> -->
      </div>
      <div class="header-title--area">
        <mat-card-title>{{val?.productSummary.title}} {{val?.productSummary?.modelYear}}
          (#{{val?.productSummary?.unitName}})</mat-card-title>
        <mat-card-subtitle>{{(val?.productSummary?.make)}} </mat-card-subtitle>
        <mat-card-subtitle style="margin-bottom: 0px;">{{val?.productSummary?.summaryString}}</mat-card-subtitle>
      </div>
    </mat-card-header>
    <mat-card-content style="height: 260px;">

      <div *ngIf="(!val?.pictures || !val?.pictures[0]?.url) && !!val?.defaultImage" class="hor-img-cont">
        <div>
          <img [src]="val.defaultImage" class="hor-image">
        </div>
      </div>
      <div *ngIf="val?.pictures?.length >0" class="hor-img-cont">
        <img [src]="val.pictures[0].url" class="hor-image">
      </div>
    </mat-card-content>
  </ng-container>

</ng-container>
