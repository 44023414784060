import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { MaterialModule } from '../shared/share-module/material.module';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { UnsubscribePromotionComponent } from './unsubscribe-promotion.component';
import { DelegateInspectionService } from '@trent/services/delegate-inspection.service';

const routes: Routes = [
    { path: 'unsubscribe/:lid', component: UnsubscribePromotionComponent },
  ];

@NgModule({
    declarations: [
        UnsubscribePromotionComponent,
    ],
    imports: [
        CommonModule,
        HttpClientModule,
        FormsModule,
        MaterialModule,
        RouterModule.forChild(routes),
    ], 
    providers: [
        DelegateInspectionService
    ]
})
export class UnsubscribePromotionModule {
  constructor() {

  }
}
