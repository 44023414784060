<ng-container *ngIf="!!page">
  <div class="top-bar" *ngIf="isEditMode">
    <div>
      <button style="margin-left: 0px;" *ngIf="isEditMode" type="button" (click)="cancel()" class="md-18 right"
        mat-stroked-button>
        <mat-icon>close</mat-icon>Cancel
      </button>
      <button style="margin-left: 0px;" *ngIf="!isEditMode" (click)="edit()" type="button" class="md-18 right"
        mat-stroked-button>
        <mat-icon>edit</mat-icon> Edit
      </button>
      <button *ngIf="isEditMode" (click)="save()" type="button" class="md-18 right" mat-raised-button color="primary">
        <mat-icon>done</mat-icon> {{ (displayMode === dm.create) ? 'Create' : 'Update'}}
      </button>
      <mat-checkbox [(ngModel)]="em.isRevControlled" (change)="storeRevChg()">Store Document Revisions</mat-checkbox>
      <mat-checkbox *ngIf="em.isRevControlled" [(ngModel)]="em.isAgreement"> Is document an Agreement</mat-checkbox>
    </div>
  </div>
  <div *ngIf="showAuto" class="mb20">
    <div class="top-bar" *ngIf="isEditMode && displayMode === dm.create">
      <mat-form-field class="full-width top-10">
        <mat-label>Create ID (leave blank for auto)</mat-label>
        <input [(ngModel)]="em.id" matInput type="text" name="newId1" />
      </mat-form-field>
    </div>
    <div *ngIf="isEditMode && isTitleEdit">
      <mat-form-field class="full-width top-10">
        <mat-label>Title Text</mat-label>
        <input [(ngModel)]="em.title" matInput type="text" name="title" />
      </mat-form-field>
    </div>
    <div *ngIf="isEditMode && isDescEdit">
      <mat-form-field class="full-width top-10">
        <mat-label>Description Text</mat-label>
        <textarea rows="5" [(ngModel)]="em.description" matInput type="text" name="description"></textarea>
      </mat-form-field>
    </div>
    <!-- Auto Section - START -->

    <ng-container *ngFor="let item of em.autoSection | keyValueOrder">
      <ng-container [ngSwitch]="item.value.type">

        <!-- string Type -->
        <ng-container *ngSwitchCase="'string'">
          <mat-form-field class="full-width top-10">
            <mat-label>{{item.key}}</mat-label>
            <input type="text" [(ngModel)]="item.value.val" matInput [name]="item.key" />
            <input type="number" [(ngModel)]="item.value.val" matInput [name]="item.key" />
          </mat-form-field>
        </ng-container>

        <!-- number Type -->
        <ng-container *ngSwitchCase="'number'">
          <mat-form-field class="full-width top-10">
            <mat-label>{{item.key}}</mat-label>
            <input type="number" [(ngModel)]="item.value.val" matInput [name]="item.key" />
            <mat-hint>number only</mat-hint>
          </mat-form-field>
        </ng-container>

        <!-- date Type -->
        <ng-container *ngSwitchCase="'date'">
          <mat-form-field>
            <mat-label>{{item.key}}</mat-label>
            <input [(ngModel)]="item.value.val" matInput [matDatepicker]="picker">
            <mat-hint>MM/DD/YYYY</mat-hint>
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </ng-container>

        <!-- SEO Meta Tags -->
        <ng-container *ngSwitchCase="'meta-tag-array'">
          <div style="display: flex; align-items: center;"> Meta Tag Details
            <a style="max-width: 100px; margin-left: 20px" mat-list-item href="javascript:void(0)"
              *ngIf="!item.value.val || item.value.val.length === 0" color="primary"
              (click)="seo.initMetaTags(item.value, 'small')"> <mat-icon>add</mat-icon> small set </a>
            <a style="max-width: 100px" mat-list-item href="javascript:void(0)"
              *ngIf="!item.value.val || item.value.val.length === 0" color="primary"
              (click)="seo.initMetaTags(item.value, 'large')"> <mat-icon>add</mat-icon> large set </a>
            <a style="max-width: 100px; margin-left: 20px" mat-list-item href="javascript:void(0)"
              *ngIf="!!item.value.val" color="primary" (click)="item.value.val = undefined">
              <mat-icon>delete</mat-icon>
              delete all</a>
          </div>
          <section id="meta-tag">
            <div class="grid-container" *ngFor="let sItem of item.value.val; let i = index">
              <div class="grid-item">
                <mat-form-field>
                  <mat-label>name</mat-label>
                  <input matInput [(ngModel)]="sItem.name" name="charset">
                </mat-form-field>
              </div>
              <div class="grid-item">
                <mat-form-field>
                  <mat-label>Content</mat-label>
                  <input matInput [(ngModel)]="sItem.content" name="content">
                </mat-form-field>
              </div>
              <div class="grid-item">
                <mat-form-field>
                  <mat-label>Http Equiv</mat-label>
                  <input matInput [(ngModel)]="sItem.httpEquiv" name="httpEquiv">
                </mat-form-field>
              </div>
              <div class="grid-item" style="display: flex; padding: 0px">
                <button mat-icon-button color="warn" (click)="item.value.val.splice(i,1)">
                  <mat-icon>delete</mat-icon>
                </button>
                <button mat-icon-button color="primary"
                  (click)="!!item.value.val ? item.value.val.push({}) : (item.value.val = [{}])">
                  <mat-icon>add</mat-icon> </button>
              </div>
            </div>
          </section>
        </ng-container>

        <!-- HTML Text -->
        <ng-container *ngSwitchCase="'html'">
          <div class="pt10 pb10 mt10">
            <span style="color:gray" class="mb10">{{item.key}}</span>
            <div class="mt15" *ngIf="!isEditMode" [innerHTML]="getSafeHtml(item.value.val)"></div>
            <div class="mt15" *ngIf="isEditMode" ck-inline [page]="em" [sectionName]="item.key" [isAutoSection]="true"
              [disableDbUpdate]="true" (contentChanged)="contentChangedAutoSection($event)"></div>
          </div>
        </ng-container>


      </ng-container>
    </ng-container>

    <!-- Auto Section - END -->
  </div>
  <div *ngFor="let k of keys; let i = index;">
    <!-- Read Mode -->
    <ng-container *ngIf="!isEditMode && showSection(k)">
      <h3 *ngIf="!!em.sections[k].header && em.sections[k].header.trim().length > 0">
        <a> {{em.sections[k].header}}</a>
        <!-- <a [href]='router.url'> {{em.sections[k].header}}</a> -->
      </h3>
      <div *ngIf="em.sections[k].type !== 'video-yt'" [innerHTML]="getSafeHtml(em.sections[k].val)"></div>
      <!-- {{em.sections[k].text}} -->
      <div [ngClass]="{'video-container': isCenterVideo}">
        <iframe *ngIf="em.sections[k].type === 'video-yt'" width="100%" [src]="getSafeUrl(em.sections[k].val)"
          frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen></iframe>
      </div>
    </ng-container>

    <!-- Edit -->
    <ng-container *ngIf="isEditMode">
      <div class="section-edit">
        <div class="section-menu">
          <div class="pull-right">
            <button (click)="insert(i)" mat-icon-button aria-label="Example icon-button with a heart icon">
              <mat-icon>add</mat-icon>
            </button>
            <button (click)="delete(k)" mat-icon-button color="primary"
              aria-label="Example icon-button with a heart icon">
              <mat-icon>delete</mat-icon>
            </button>
            <button (click)="insert(i+1)" *ngIf="i === sections.length-1" mat-icon-button color="accent"
              aria-label="Example icon-button with a heart icon">
              <mat-icon>add</mat-icon> after
            </button>
          </div>
        </div>
        <mat-form-field class="full-width top-10">
          <mat-label>Header Text</mat-label>
          <input [(ngModel)]="em.sections[k].header" matInput type="text" [name]="'header'+i" />
        </mat-form-field>
        <mat-form-field class="top-10 tag">
          <mat-label>Header Tag</mat-label>
          <input [(ngModel)]="em.sections[k].tag" matInput type="text" [name]="'tag' + i" />
        </mat-form-field>
        {{em.sections[k].tag}}
        <div ck-inline [page]="em" [sectionName]="em.sections[k].tag" [disableDbUpdate]="true"
          (contentChanged)="contentChanged($event)"></div>
      </div>
    </ng-container>
  </div>
</ng-container>


<!-- <ng-template #youtube let-url="url">
  <iframe width="560" height="315" [src]="url" frameborder="0"
    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
</ng-template> -->