import { NegoTermsBase } from './nego-terms-base';
import { TermsType } from './terms-type';
import { NegoTermsAssigned } from './nego-terms-assigned';

export const parseNegoTerms = (obj: any): NegoTermsBase => {
  if (obj == null) { return null; }

  const r = <NegoTermsBase>obj; // cast it to base, remember it is still a javascript obj at run time.
  switch (r.termsType) {
    // case ProductType.driver:
    //   return BidDriver.parse(r);

    case TermsType.assignedByVendor:
      return NegoTermsAssigned.parse(r);

    case TermsType.negotiated:
      return NegoTermsBase.parse(r);
      
      default:
        return NegoTermsBase.parse(r);
      // throw new Error(`Invalid Terms Type in the Bid. Nego-Term ${r.termsType}
      // is invalid or not yet implemented.`);
  }
};