export enum DbStatus {

  /** Initial, i.e when the entiry is just created and is not released.
   * Initial is only allowed at root, untill record is released.
   * collection */
  Initial = 0,

  /** Released in Db but it is modified, i.e a draft version also exist
   * ONLY exists in draft branch. */
  ReleasedMod = 1,


  /** Modifications have been submitted for approval. record is LOCKED for editing untill
   * admin act on it. */
  SubmitedForApproval = 2,

  /** Released in Db. Latest released record is stored at root as well as a subcollection as well.
   * Other historical previous releases are stored in the subcollection only. */
  Released = 3
}
