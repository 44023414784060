import { SparePart } from "./spare-part";

/**
 * @author - MKN
 * Parse spare part after getting data(In State file)
 */

export const parseSparePart = (obj: any): SparePart => {
    if (obj == null) { return null; }
  
    const r = <SparePart>obj; // cast it to base, remember it is still a javascript obj at run time.
    return  SparePart.parse(r);
};