import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchUsersComponent } from './search-users.component';
import { MaterialModule } from '../share-module/material.module';
import { MatAutocompleteModule } from '@angular/material/autocomplete';



@NgModule({
  declarations: [
    SearchUsersComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    MatAutocompleteModule,
  ],
  exports: [SearchUsersComponent]
})
export class SearchUsersModule { }
