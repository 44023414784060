import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CameraBaseService } from '@trent/services/camera/camera-base.service';
import { environment } from '@trent/core/environments/environment';
import { Camera } from '@awesome-cordova-plugins/camera/ngx';
import { CameraMobileService } from '@trent/services/camera/camera-mobile.service';

const mobileAppProviders = environment.platform === 'web' ? [] : [Camera];


@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    { provide: CameraBaseService, useClass: environment.platform === 'web' ? CameraBaseService : CameraMobileService },
    ...mobileAppProviders
  ]
})
export class CameraModule { }
