export const currencies = ['USD', 'CAD',] as const;
export type CurrencyType = typeof currencies[number];

/** returns currency base on country string @param CA @param US */
export const getCurrencyByCountry = (countryString: 'CA' | 'US'):  CurrencyType => {
  switch (countryString) {
    case 'CA':
      return 'CAD';
    case 'US':
      return 'USD';
    default:
      throw new Error(`failed to return currency for ${countryString}`, );
  }
};

const locale = ['CA', 'US'] as const;

export type LocaleType = typeof locale[number]

export const isValidLocale = (l: LocaleType): boolean => {
  return l === 'CA' || l === 'US';
};