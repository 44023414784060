import { InspectionData, InspectionResponse, InspectionValidationGroup, tiresTrailerDS, tiresTrailerPS } from './inspection-data';
import { Exclude, Expose, Type } from 'class-transformer';
import { instanceToInstance, plainToInstance } from 'class-transformer';
import { Inspection } from './inspection';
import { InspectionStatus } from './inspection-status';
import { IValidationMsg } from '../error-handling';
import { Trailer } from '../product/trailer';
import { sanitizeDateIPoint } from '../utility';
// import { IInspectionPersistentDataTrailer } from './i-inpection-presistent-data';
import { TireInspection } from './tire-inspection';
import { ValidateNested, IsDefined, IsEnum } from 'class-validator';
import { IncidentalCharges } from './Incidental-charges';
import { ProductType } from '../product/product-type';
import { IInspectionPersistentData } from './i-inpection-presistent-data';


@Exclude()
export class InspectionDataTrailer extends InspectionData {

  constructor() {
    super();
    this.pictures = [];
    this.location = { geopoint: { latitude: null, longitude: null }, geohash: null };
    this.location.geopoint = { latitude: null, longitude: null };
    this.isIncidentalCharges = true;
    // if (!this.incidentalCharges) {
    //   this.incidentalCharges = new IncidentalCharges();
    // }
  }

  @Expose()
  vehType = 'trailer';

  @Expose()
  @IsEnum(ProductType)
  productType = ProductType.trailer;



  @Expose()
  @ValidateNested({ message: 'Add Tire Details', groups: [tiresTrailerDS] })
  @Type(() => TireInspection)
  @IsDefined({ message: 'Add Tire Details', groups: [tiresTrailerDS] })
  axilOneOuterDriver: TireInspection;
  @Expose()
  @ValidateNested({ message: 'Add Tire Details', groups: [tiresTrailerDS] })
  @Type(() => TireInspection)
  @IsDefined({ message: 'Add Tire Details', groups: [tiresTrailerDS] })
  axilOneInnerDriver: TireInspection;
  @Expose()
  @ValidateNested({ message: 'Add Tire Details', groups: [tiresTrailerDS] })
  @Type(() => TireInspection)
  @IsDefined({ message: 'Add Tire Details', groups: [tiresTrailerDS] })
  axilTwoOuterDriver: TireInspection;
  @Expose()
  @ValidateNested({ message: 'Add Tire Details', groups: [tiresTrailerDS] })
  @Type(() => TireInspection)
  @IsDefined({ message: 'Add Tire Details', groups: [tiresTrailerDS] })
  axilTwoInnerDriver: TireInspection;
  @Expose()
  @ValidateNested({ message: 'Add Tire Details', groups: [tiresTrailerDS] })
  @Type(() => TireInspection)
  @IsDefined({ message: 'Add Tire Details', groups: [tiresTrailerDS] })
  axilThreeOuterDriver: TireInspection;
  @Expose()
  @ValidateNested({ message: 'Add Tire Details', groups: [tiresTrailerDS] })
  @Type(() => TireInspection)
  @IsDefined({ message: 'Add Tire Details', groups: [tiresTrailerDS] })
  axilThreeInnerDriver: TireInspection;
  @Expose()
  @ValidateNested({ message: 'Add Tire Details', groups: [tiresTrailerDS] })
  @Type(() => TireInspection)
  @IsDefined({ message: 'Add Tire Details', groups: [tiresTrailerDS] })
  axilFourOuterDriver: TireInspection;
  @Expose()
  @ValidateNested({ message: 'Add Tire Details', groups: [tiresTrailerDS] })
  @Type(() => TireInspection)
  @IsDefined({ message: 'Add Tire Details', groups: [tiresTrailerDS] })
  axilFourInnerDriver: TireInspection;



  @Expose()
  @ValidateNested({ message: 'Add Tire Details', groups: [tiresTrailerPS] })
  @Type(() => TireInspection)
  @IsDefined({ message: 'Add Tire Details', groups: [tiresTrailerPS] })
  axilOneOuterPassenger: TireInspection;
  @Expose()
  @ValidateNested({ message: 'Add Tire Details', groups: [tiresTrailerPS] })
  @Type(() => TireInspection)
  @IsDefined({ message: 'Add Tire Details', groups: [tiresTrailerPS] })
  axilOneInnerPassenger: TireInspection;
  @Expose()
  @ValidateNested({ message: 'Add Tire Details', groups: [tiresTrailerPS] })
  @Type(() => TireInspection)
  @IsDefined({ message: 'Add Tire Details', groups: [tiresTrailerPS] })
  axilTwoOuterPassenger: TireInspection;
  @Expose()
  @ValidateNested({ message: 'Add Tire Details', groups: [tiresTrailerPS] })
  @Type(() => TireInspection)
  @IsDefined({ message: 'Add Tire Details', groups: [tiresTrailerPS] })
  axilTwoInnerPassenger: TireInspection;
  @Expose()
  @ValidateNested({ message: 'Add Tire Details', groups: [tiresTrailerPS] })
  @Type(() => TireInspection)
  @IsDefined({ message: 'Add Tire Details', groups: [tiresTrailerPS] })
  axilThreeOuterPassenger: TireInspection;
  @Expose()
  @ValidateNested({ message: 'Add Tire Details', groups: [tiresTrailerPS] })
  @Type(() => TireInspection)
  @IsDefined({ message: 'Add Tire Details', groups: [tiresTrailerPS] })
  axilThreeInnerPassenger: TireInspection;
  @Expose()
  @ValidateNested({ message: 'Add Tire Details', groups: [tiresTrailerPS] })
  @Type(() => TireInspection)
  @IsDefined({ message: 'Add Tire Details', groups: [tiresTrailerPS] })
  axilFourOuterPassenger: TireInspection;
  @Expose()
  @ValidateNested({ message: 'Add Tire Details', groups: [tiresTrailerPS] })
  @Type(() => TireInspection)
  @IsDefined({ message: 'Add Tire Details', groups: [tiresTrailerPS] })
  axilFourInnerPassenger: TireInspection;

  public static parse(obj) {
    if (obj == null) { return null; }

    if (obj instanceof (InspectionDataTrailer)) { return <InspectionDataTrailer>obj; }

    const m = plainToInstance<InspectionDataTrailer, any>(InspectionDataTrailer, sanitizeDateIPoint(obj));
    m.sanitize();
    m.tires?.forEach(element => {
      element.sanitize();
    });
    return m;
  }

  sanitize() {
    // super.sanitize();
  }
  clone() {
    const t = instanceToInstance(this);
    t.sanitize();
    return t;
  }
  validateSyncGroup(group: InspectionValidationGroup, pickDrop: Inspection, action: InspectionStatus): IValidationMsg {
    return this.validateSync({ groups: [group], pickDrop, action });
  }
  validateSync(options?: any, pickDrop?: Inspection, action?: InspectionStatus): IValidationMsg {
    const r = super.validateSync(options, pickDrop, action);

    return r;
  }
  setTruckTireData(product: Trailer) {
    console.log(product.productType);
    // this.steerDriver = product.inspectionPersistentData.tireData.steerDriver;
    // this.steerPassenger = product.inspectionPersistentData.tireData.steerPassenger;
    // this.driveFrontOuterDriver = product.inspectionPersistentData.tireData.driveFrontOuterDriver;
    // this.driveFrontInnerDriver = product.inspectionPersistentData.tireData.driveFrontInnerDriver;
    // this.driveRearOuterDriver = product.inspectionPersistentData.tireData.driveRearOuterDriver;
    // this.driveRearInnerDriver = product.inspectionPersistentData.tireData.driveRearInnerDriver;
    // this.driveFrontOuterPassenger = product.inspectionPersistentData.tireData.driveFrontOuterPassenger;
    // this.driveFrontInnerPassenger = product.inspectionPersistentData.tireData.driveFrontInnerPassenger;
    // this.driveRearOuterPassenger = product.inspectionPersistentData.tireData.driveRearOuterPassenger;
    // this.driveRearInnerPassenger = product.inspectionPersistentData.tireData.driveRearInnerPassenger;

  }
  resetPropsForReturn() {
    this.isVinVerified = false;
    this.isPlateNoVerified = false;
   
    this.pictures = [];
    this.tires.forEach(f => {
      f.showVerify = true;
      f.isMakeVerified = false;
      f.isDateOfManufacturingVerified = false;
    });
    this.exteriorInspectionComments = undefined;
  }
     /** reset properties if customer dropped with return inspection  */
     resetPropsForReturnWhenReturnExists() {
      this.isVinVerified = false;
      this.isPlateNoVerified = false;
      this.tires.forEach(f => {
        f.showVerify = true;
        f.isMakeVerified = false;
        f.isDateOfManufacturingVerified = false;
      });
    
    }
  getPersistentData(): IInspectionPersistentData {
    const d: IInspectionPersistentData = {};
    d.tireData = {
      axilOneOuterDriver: this.axilOneOuterDriver,

      axilOneInnerDriver: this.axilOneInnerDriver,

      axilTwoOuterDriver: this.axilTwoOuterDriver,

      axilTwoInnerDriver: this.axilTwoInnerDriver,

      axilThreeOuterDriver: this.axilThreeOuterDriver,

      axilThreeInnerDriver: this.axilThreeInnerDriver,

      axilFourOuterDriver: this.axilFourOuterDriver,

      axilFourInnerDriver: this.axilFourInnerDriver,

      axilOneOuterPassenger: this.axilOneOuterPassenger,

      axilOneInnerPassenger: this.axilOneInnerPassenger,

      axilTwoOuterPassenger: this.axilTwoOuterPassenger,

      axilTwoInnerPassenger: this.axilTwoInnerPassenger,

      axilThreeOuterPassenger: this.axilThreeOuterPassenger,

      axilThreeInnerPassenger: this.axilThreeInnerPassenger,

      axilFourOuterPassenger: this.axilFourOuterPassenger,

      axilFourInnerPassenger: this.axilFourInnerPassenger
    };
    return d;
  }
  get tires() {
    const t = [this.axilOneOuterDriver, this.axilOneInnerDriver, this.axilTwoOuterDriver, this.axilTwoInnerDriver,
    this.axilThreeOuterDriver, this.axilThreeInnerDriver, this.axilFourOuterDriver, this.axilFourInnerDriver,
    this.axilOneOuterPassenger, this.axilOneInnerPassenger, this.axilTwoOuterPassenger, this.axilTwoInnerPassenger,
    this.axilThreeOuterPassenger, this.axilThreeInnerPassenger, this.axilFourOuterPassenger, this.axilFourInnerPassenger
    ];
    if (t.findIndex(f => typeof f === 'undefined') > -1) {
      return [];
    }
    return t;
  }
/**
   * Setup Inspection response based on action and reference inspection
   */
 setupInspectionResponseTrailer(action: InspectionStatus, inspection: Inspection) {
  if (inspection.productSummary.productType !== ProductType.trailer) {
    throw new Error('incorrect fn called for product is not trailer');
  }
  let ref: InspectionData;
  switch (action) {
    case InspectionStatus.vendorDropped:
      this.inspectionResponse = InspectionResponse.initialize;
      break;
    case InspectionStatus.customerPicked:
      ref = inspection.vendorDrop;
      if (!ref) {
        throw new Error('incorrect fn called for product is not truck');
      } else {
        if (this.hasTrailerInspectionChanged(ref)) {
          this.inspectionResponse = InspectionResponse.update;
        } else {
          this.inspectionResponse = InspectionResponse.accept;
        }
      }
      break;
    case InspectionStatus.customerDropped:
      this.inspectionResponse = InspectionResponse.initialize;
      break;
    case InspectionStatus.vendorPicked:
      ref = inspection.customerDrop;
      if (!ref) {
        this.inspectionResponse = InspectionResponse.accept;
      } else {
        if (this.hasTrailerInspectionChanged(ref)) {
          this.inspectionResponse = InspectionResponse.update;
        } else {
          this.inspectionResponse = InspectionResponse.accept;
        }
      }
      break;

    default:
      throw new Error('error calling setupInspectionResponseTruck');


  }
}

hasTrailerInspectionChanged(ref: InspectionData) {
  if (this.hasExtCommentsChanged(ref) || this.hasExtPicChanged(ref)) {
    return true;
  } else {
    return false;
  }
}
}
