import { CmsService } from '@trent/services/cms.service';
import { DialogService } from '@trent/services/dialog/dialog.service';
import { BasePage } from './base.page';
import { Input, Directive } from '@angular/core';
import { FormDisplayMode } from '@trent/models/UI/form-display-mode';
import { EventService } from '@trent/services/event.service';
import { Store } from '@ngxs/store';
import { AbstractControl, NgForm, ValidatorFn } from '@angular/forms';
// import { getValidatorFn } from '@trent/models/utility/validation-helper';
import { IValidate, FormValidationData } from '@trent/models/error-handling/validation-info';
import { BackBtnData } from './menu-data';
import { logger } from '../log/logger';
import { ValidatorOptions } from 'class-validator';
import { PropertyUtils } from '../utility/property-utils';

/** Name of the control for the AbstractControl */
export const getControlName = (c: AbstractControl): string | null => {
  const formGroup = c.parent.controls;
  return Object.keys(formGroup).find(name => c === formGroup[name]) || null;
};

export const getValidatorFn = <T extends IValidate>(
  // tslint:disable-next-line:callable-types
  clazz: { new(): T },
  key: string,
  callBeforeValidationFn: (id: string, $this: T) => FormValidationData<T>): ValidatorFn[] | ValidatorFn => {
  return (fc) => {
    let valid = null;
    const cName = getControlName(fc);
    // const cName = cNameOrig;
    if (cName) {
      // if (cName === 'companyContact_phone') {
      //   logger.log('got it');
      // }
      let t: T;
      let option: ValidatorOptions = {
        skipMissingProperties: false
      };
      // Get the model from validation or create new.
      if (typeof (callBeforeValidationFn) === 'function') {
        const r = callBeforeValidationFn(cName, t);
        if (!!r) {
          t = r.model; // equal model if model is provided.
          option = { ...option, ...r.option };
        }
      }

      // create model if it was not received from the callback function.
      if (t == null) {
        t = new clazz();
        const transVal = PropertyUtils.transformPropertyValue(clazz, cName, fc.value);
        PropertyUtils.setProperty(t, cName, transVal);
      } else {
        /** Existing model binding is one char off. if this is the control that is being edited in focus, its
         * value is one char off then the model. So model need to be updated. */
        const tVal = PropertyUtils.getProperty(t, cName);
        // tslint:disable-next-line:triple-equals
        if (tVal != fc.value) {
          // logger.log(`[validation] Require Update: ${cName} : ${tVal} : ${fc.value}`);
          PropertyUtils.setProperty(t, cName, fc.value);
        } // else { logger.log(`[validation] Skipping update: ${cName} : ${tVal} : ${fc.value}`); }
      }

      const errMsg = t.validateSync(option);
      // If error against this control exists, just display the top msg only.
      if (!!errMsg[cName]) {
        valid = { msg: errMsg[cName][0] };
      }
    }
    return valid;
  };
};


/** Plumbing base class for a Form read / write operation */
@Directive()
export class BaseForm<T> extends BasePage<T> {
  /** modeEnum to be used instead of Enum class name in the html. otherwise it create errors. * i.e use
   * modeEnum.read vs using FormDisplayMode.read. Note: in .ts file, it is OK to use FormDisplayMode.read
   */
  modeEnum = FormDisplayMode;

  /** If true, mobile menu display of save/cancel buttons will be tied to this component if this value is true. */
  bindMenuSaveCancelDisplay = false;

  // State of the UI read / edit / create mode.
  private _displayMode: FormDisplayMode;
  @Input()
  get displayMode() { return this._displayMode; }
  set displayMode(v: FormDisplayMode) {
    this._displayMode = v;
    if (this.bindMenuSaveCancelDisplay) {
      this.es.emit<boolean>(this.es.menuShowSaveCancel, this.isEditMode);
    }
  }

  get isEditMode() {
    return this.displayMode === FormDisplayMode.edit || this.displayMode === FormDisplayMode.create;
  }
  get isReadMode() {
    return this.displayMode === FormDisplayMode.read;
  }

  get isCreateRequest() {
    if (this.m == null) {
      return true;
    }
    return (<any>this.m).id == null;
  }

  constructor(store: Store, dialogService: DialogService, eventService?: EventService, cms?: CmsService) {
    super(store, dialogService, eventService, cms);
  }

  /** Fire show/hide flag to manage the display of save/cancel button on mobile top menu.
   * Either use this method, or just bind the show/hide by setting @param bindMenuSaveCancelDisplay to true.
   * and show hide will then be handled by the @param displayMode.
  */
  showHideSaveCancel(show: boolean) {
    this.es.emit<boolean>(this.es.menuShowSaveCancel, show);
  }
  /** show/hide flag to manage display of edit button on mobile top menu
   */
  showHideEdit(show: boolean) {
      this.es.emit<boolean>(this.es.menuShowEdit, show);
  }

  /** Hide the save/click on the top mobile as well while cleaning the listers.
   * @param clearCustomGoBack clears goBack button, if false omits clearing
   * @param clearSaveCancel clears save cancel buttons, if false omits clearing
   * @param clearEdit clears edit buttons, if false omits clearing
   * @param clearSearch clears search button, if false omits clearing
   */
  cleanListeners(clearCustomGoBack?: boolean, clearSaveCancel = true, clearEdit = true, clearSearch = true) {
    if (clearSaveCancel) {
      this.showHideSaveCancel(false);
    }
    if (clearEdit) {
      this.showHideEdit(false);
    }
    super.cleanListeners(clearCustomGoBack, clearSearch);
  }


  showError(ctrl) {
    // if (!!ctrl.name && ctrl.name === 'fullName') {
    //   logger.log('*** ctrl: ', ctrl);
    //   logger.log('*** show invalid', ctrl.invalid);
    //   logger.log('*** show dirty', ctrl.dirty );
    //   logger.log('*** show touched', ctrl.touched);
    // }
    return ctrl.invalid && (ctrl.dirty || ctrl.touched);
  }
  getColor(ctrl) {
    return this.showError(ctrl) ? 'danger' : '';
  }

  /** Set up the form validation of given model type.
   * @param clazz : model class type that will be validated.
   * @param form : NgForm with controls.
   * @param callBeforeValidationFn : Function that will be called before validation will be called on each form control
   * must return the model and the validation option that will be utilized by the control.
   * @param delay : delay in the milliseconds. to ensure form binding is complete.
   * @param useCtrlLevel: if true, form validation setting will be done by checking each
   * and every control instead. Use it = true, when from have ngif statements applied to certain
   * controls inside the form. their validation may not have set as ngif might have
   * missing certain
   */
  public setFormValidation<V extends IValidate>(
    clazz: { new(): V },
    form: NgForm,
    callBeforeValidationFn?: (id: string, $this: V) => FormValidationData<V>,
    delay?: number
  ) {
    if (!this.isFormValidationSet(form)) {
      /** Set up the validators dynamically on the form, delay is needed to complete the binding of form controls to ngModel */
      if (delay == null) {
        delay = 500;
      }
      setTimeout(() => {
        if (!this.isFormValidationSet(form)) {
          Object.keys(form.controls).forEach(key => {
            const c = form.controls[key];
            if ((<any>c).__validation_set == null) {
              // logger.log('VALIDATOR was set for control: ', key);
              c.setValidators(getValidatorFn(clazz, key, callBeforeValidationFn));
              c.updateValueAndValidity();
              // avoid multiple set on the the same form for future.
              (<any>c).__validation_set = true;
            }
          });
          // avoid multiple set on the the same form for future.
          // (<any>form).__validation_set = true;
        }
      }, delay);
    }
  }

  public markDirtyAndValidate(f: NgForm) {
    Object.keys(f.controls).forEach(key => {
      // logger.log('validating ctrl: ', key);
      const c = f.controls[key];
      c.markAsDirty();
      c.markAsTouched();
      c.updateValueAndValidity();
    });
  }

  /** avoid multiple calls on the form. each instance form should be set once only
   * also avoid null form calls
   */
  private isFormValidationSet(f: NgForm) {
    if (f == null) {
      return true;
    }
    // // at form level.
    // if (!useCtrlLevel) {
    //   return f == null || (<any>f).__validation_set === true;
    // }

    // at control level
    const keys = Object.keys(f.controls);
    for (let idx = 0; idx < keys.length; idx++) {
      const ele = keys[idx];
      const c = f.controls[ele];
      if ((<any>c).__validation_set == null || (<any>c).__validation_set === false) {
        return false;
      }
    }
    return true;
  }
}
