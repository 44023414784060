import { instanceToPlain } from 'class-transformer';
import { AppSetting } from '@trent/models/sys/app-setting';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, timer, Subscription } from 'rxjs';
import { IPoint } from '@trent/models/product-search/interfaces';
import { promiseWraper, cleanupUndefined, cleanUp } from '@trent/models/utility';
import { IAuthCoreService } from '../auth/iauth-core.service';
import { FirestoreService } from '../firestore.service';
import { IdeviceIdService } from '../device/idevice-id.service';
import { pickBy, identity, isEqual, isNil } from 'lodash';
import { appRunningMode } from '@trent/models/sys/app-info';
import { IgeoLocationService } from './igeo-location.service';
import { environment } from '@trent/core/environments/environment';
import { SingletonService } from '../singleton.service';
import { FunctionName } from '@trent/core/config';
import { Store } from '@ngxs/store';
import { AppState1 } from '@trent/store/root-store';
import { map } from 'rxjs/operators';
import { ITripGpsStatus, gpsStatusColName } from '@trent/models/trip/trip-gps-status';
import { logger } from '@trentm/log/logger';

/** common between cordova and web (db calls) */
@Injectable()
export class GeoLocationCoreService {

  /** Flag to dynamically change the gps configuration on the fly. */
  updateGpsConfig = false;

  appSetting: AppSetting;

  apiName: FunctionName = 'gps';

  /** Is app running in forground or background  */
  public appMode$: Observable<appRunningMode>;
  protected appModeBS: BehaviorSubject<appRunningMode>;

  protected locationBS: BehaviorSubject<GeolocationPosition>;
  public location$: Observable<GeolocationPosition>;

  /** default refresh rate of gps = 10 minutes. */
  protected refreshRate = 10 * 60 * 1000;
  protected refreshTimer$: Observable<number>;
  protected refreshSub: Subscription;

  protected dbReadSub: Subscription;
  protected uid: string;
  private get rtdb() { return this.sa.firebaseRTDBService; }

  private gpsStatus: ITripGpsStatus;

  get isGpsBroadCastingReqd() {
    if (typeof this.gpsStatus?.isBroadCasting === 'boolean') {
      return this.gpsStatus.isBroadCasting;
    }
    return false;
  }

  debug = false;

  /** Domain Url for firebase api services. */
  private get apiUrl() {
    // Function name has to be appened in the url ir emulator or production is running
    if (environment.emulator || environment.production) {
      if (this.apiName == null || this.apiName.trim().length === 0) {
        throw Error('Invalid API name. The name can not be emepty!');
      }
      return `${environment.functionUrl}/${this.apiName}`;
    }
    // it is dev tscriptD script running.
    return environment.functionUrl;
    // environment.production ?
    //    `${firebaseFuncUrl.production}/${this.apiName}` :
    //    firebaseFuncUrl.devleopment;
  }

  /**
   * @param segment : trailing segment of api url. (must start with '/')
   */
  public getApiUrl(segment: string = '/location/gps-location-update') {
    if (!segment || segment[0] !== '/') {
      throw new Error(`Invalid get api url was called. segement must start with '/'. Provided: ${segment}`);
    }
    logger.log('[GPS-CORE] Function URL is: ' + `${this.apiUrl}${segment}`);
    return `${this.apiUrl}${segment}`;
  }

  constructor(protected store: Store, protected auth: IAuthCoreService, protected afs: FirestoreService,
    protected deviceIdService: IdeviceIdService, protected sa: SingletonService) {
    this.appModeBS = new BehaviorSubject<appRunningMode>('foreground');
    this.appMode$ = this.appModeBS.asObservable();

    this.locationBS = new BehaviorSubject(null);
    this.location$ = this.locationBS.asObservable();

    // Bind the location$ to the server calls.
    if (environment.platform === 'web') {
      this.bindServerCalls();
    }

    // Bind the app settings.
    const appSettingSub = this.store.select(AppState1.appSettings)
      .pipe(map(obj => AppSetting.parse(obj)))
      .subscribe(c => {
        if (!!c && !isEqual(instanceToPlain(c), instanceToPlain(this.appSetting))) {
          this.appSetting = c;
          this.updateGpsConfig = true;
          logger.log(`[gps core] app setting updated`);
        }
      });
  }

  protected getCurrentPosition(): Promise<GeolocationPosition> {
    throw Error('Programming error! this function should be overridden in the derived class.');
  }

  public setupBroadCastingBase(clientGeoSerive: IgeoLocationService, gpsStatus: ITripGpsStatus) {
    if (!isNaN(+gpsStatus?.refreshRate)) {
      this.refreshRate = +gpsStatus.refreshRate;
    }
    // Geo location is to be mapped to he user who is logged in.
    this.auth.user$.subscribe(u => {
      if (u == null) {
        logger.log('[gps] User Logged out, broadcasting of location stopped');
        this.uid = null;
        clientGeoSerive.stopBroadcastingLocation();
        // since user is logged off or user has changed, current subscription of bradcasting status should be turned off as well.
        if (!!this.dbReadSub) {
          this.dbReadSub.unsubscribe();
          this.dbReadSub = null;
        }
        return;
      }

      // to user subscription can be called multiple times, ensure that restarting is only done if last uid is different
      // from the current uid.
      if (u.uid === this.uid && !!this.dbReadSub) {
        // do nothing, just return
        return;
      }

      // user changed,
      logger.log('New user login, starting broadcasting Broadcasting started', u.uid);
      this.uid = u.uid;
      if (!!this.dbReadSub) {
        this.dbReadSub.unsubscribe();
      }
      this.dbReadSub = this.rtdb.obj$<ITripGpsStatus>(`${gpsStatusColName}/${u.uid}`).subscribe(gps => {
        this.gpsStatus = gps;
        // is tracking is required at server
        if (!!gps && gps.isBroadCasting) {
          logger.log('[GPS]: broadcasting is required at server.');
          clientGeoSerive.startBroadcastLocation(gps);
        } else {
          // not required. stop broadcasting.
          logger.log('[GPS]: broadcasting is NOT required at server');
          clientGeoSerive.stopBroadcastingLocation();
        }
      });
    });
  }

  /**
   * Only requried for web. Cordova may need it in the future if it is not bradcasting the location via background GPS posting
   * diretely to the server. For now, only web will send this suscription.
   * Note: cordova will not push the loctions in the location observable, so following db call will never be made
   * */
  private async bindServerCalls() {
    const sub = this.location$.subscribe(async loc => {
      if (!!loc) {
        const d1 = cleanupUndefined(loc);
        loc = cleanUp(loc) as any;
        const d = await promiseWraper(this.deviceIdService.getDeviceId());
        if (d.error || d.data == null) {
          logger.error('Error sending location data to server. Device id was not established');
        }
        const dataObj = {};
        dataObj[d.data.deviceId] = loc;
        dataObj[d.data.deviceId].updatedAt = this.afs.timestamp;
        dataObj[d.data.deviceId].platform = await this.deviceIdService.platformInfo();

        logger.log('** Updating Server with location data');
        if (!isNil(this.uid)) {

          this.afs
            .set(`gps-location/${this.uid}`, dataObj, true, { merge: true })
            .then(async () => {
              logger.log('** location data was updated at server..');
            })
            .catch(err => {
              logger.error('Error happened during updating the location data at server', err);
            });
        }
      }
    });
  }


  /**
   * Strictly for configuration only. Used to debug the configurations.
   */
  public async logConfigData(config: object, platform: 'ios' | 'android') {

    /** Temporary Debug */
    return this.afs.add('log-config', { ...config, platform, idx: `${platform}-${(new Date()).valueOf()}` })
      .then(r2 => {
        logger.log('[GPS CORE] logging was completed', r2);
        // resolve();
      })
      .catch(error => {
        logger.error('[GPS CORE] Logging configuration failed', error);
        // reject(error);
      });

    // /** Temporary Debug */
    // return new Promise<void>(async (resolve, reject) => {
    //   this.afs.set('log1/1', { time: 1 })
    //     .then(r2 => {
    //       logger.log('[GPS CORE] logging was completed', r2);
    //       resolve();
    //     })
    //     .catch(error => {
    //       logger.error('[GPS CORE] Logging configuration failed', error);
    //       reject(error);
    //     });
    // });


  }
}
