import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { MapLabel } from '@trent/models/map/map-label';
import { MapName } from '@trent/models/map';
import { MapGenService } from '@trent/services/map/map-gen.service';
import { Store } from '@ngxs/store';
import { AppState1, ScreenDisplayMode } from '@trent/store/root-store';
import { Subscription } from 'rxjs';
import { UtilityService } from '@trent/services/utility.service';
import { EventService, IEventListener } from '@trent/services/event.service';
import { cleanListeners } from '@trent/models/utility';

@Component({
  selector: 'trent-map-wrapper',
  templateUrl: './map-wrapper.component.html',
  styleUrls: ['./map-wrapper.component.scss']
})
export class MapWrapperComponent implements OnInit, OnDestroy {
  @Input()
  mLabels: MapLabel[];

  @Input() inputId: string = 'mapHomeHolder';


  mapName: MapName = 'home-bing';

  private map = this.mapService.getMap(this.mapName);
  @Input()
  mapHeight: number;
  @Input() /// Cm- some time map show full height
  showHeightPercentage: boolean = false;
  public showMap = true;
  browserHeight: number;
  subs: Subscription[] = [];
  eListener: IEventListener[] = [];

  constructor(private mapService: MapGenService, private store: Store, private us: UtilityService, private eventService: EventService) {
  }

  ngOnInit() {
    if(this.us.isPlatformServer) {
      return;
    }
    this.loadMap();
    if (!this.mapHeight) {
      if (!!this.us.isPlatformBrowser) {
        this.subs.push(this.store.select(AppState1.currentScreenDisplay)
          .subscribe(x => {
            this.browserHeight = x.height;
            if (x.screen === ScreenDisplayMode.desktop) {
              this.mapHeight = x.height - 120;
            } else {
              this.mapHeight = x.height - 60;
            }
            // logger.log('browserInfo', x);
          }));
      }
    }
    this.eListener.push(
      this.eventService.listen<string>(this.eventService.showOnMapById, (val) => {
        if (this.mLabels?.length > 0) {
          this.loadMap(val, this.mLabels.filter(f => f.id === val));
        } else {
          this.loadMap(val, this.mLabels);
        }
      }), this.eventService.listen<MapLabel[]>(this.eventService.showMapLabels, (val) => {
        if (val?.length > 0) {
          this.mLabels = val;
          this.loadMap();
        }
      }
      ));
  }
  ngOnDestroy(): void {
    cleanListeners(this.eListener, this.subs);
    this.mLabels = undefined;
    this.map.exportMapDiv();
  }
  loadMap(selectedId?: string, centerAtLabels?: MapLabel[]) {
    if(this.us.isPlatformBrowser) {
      setTimeout(() => {
        this.mapService.setMapId(this.inputId);
        const r = this.mapService.load(this.mapName, this.mLabels, selectedId, centerAtLabels);
      }, 10);
    }
  }
}
