import { Paging, ISubscriptionNode } from './paging';
import { DeepCopy } from '../utility/deep-copy';
import { isNil, keys } from 'lodash';
import { TimerHelper } from '../utility';
import { logger } from '../log/logger';

export enum LoadStatus {

  /** nothing was requested before */
  Empty = 0,

  /** Request is being made but server data has not received yet. */
  Loading = 1,

  /** Data has already been loaded. */
  Loaded = 2
}

export interface IDataLoadStatus<TParam> {
  /** Primary Load status (result from all the children OR subscribes.) */
  loadStatus: LoadStatus;

  /** Key Parameter that was used to get child TParam */
  param: TParam;

  /** Paging Data for the master (will be derived from children.) */
  pData: Paging;

  /** children of primary query (OR conditions) */
  children?: string[];
}

// Read the load status of given key.
export const readLoadStatus = <TParam>(
  dic: { [key: string]: IDataLoadStatus<TParam> },
  key: string,
  pData: Paging)
  : { status: LoadStatus, nextPageReqd: boolean } => {

  const qNode = dic[key];
  if (!!qNode) {
    return {
      status: qNode.loadStatus,
      nextPageReqd: !qNode.pData.full && pData.size > qNode.pData.size
    };
  } else {
    return {
      status: LoadStatus.Empty,
      nextPageReqd: false
    };
  }
};

export const getSetByStatus = <TParam>(
  dic: { [key: string]: IDataLoadStatus<TParam> },
  key: string,
  status: LoadStatus,
): { key: string, node: IDataLoadStatus<TParam> }[] => {

  const n = dic[key];
  if (!!n && n.loadStatus === status) {
    const output: { key: string, node: IDataLoadStatus<TParam> }[] = [];
    if (n.children == null || n.children.length === 0) {
      output.push({
        key: key,
        node: {
          loadStatus: n.loadStatus,
          param: DeepCopy.copy(n.param),
          pData: DeepCopy.copy(n.pData)
        }
      });
    } else {
      n.children.forEach(cKey => {
        const o = getSetByStatus(dic, cKey, status);
        if (!!o && o.length > 0) {
          output.concat(o);
        }
      });
    }
    return output;
  }
  return [];
};


export const buildOrChildren = <TParam>(
  key: string,
  param: TParam,
  pData: Paging,
  refStatObj: { [key: string]: IDataLoadStatus<TParam> },
  children: { [key: string]: TParam }) => {

  const node: IDataLoadStatus<TParam> = {
    loadStatus: LoadStatus.Loading,
    param: param,
    pData: pData
  };
  const newNodeTree: { [key: string]: IDataLoadStatus<TParam> } = {};
  newNodeTree[key] = node;

  if (!!children) {
    const a = keys(children);
    // children page sizes need to be adjusted.
    const totalSz = (pData.size > a.length) ? pData.size : a.length;
    const sz = Math.floor(totalSz / a.length);
    let mod = pData.size % a.length;

    node.children = [];
    for (let i = 0; i < a.length; i++) {
      const cKey = a[i];
      const c: IDataLoadStatus<TParam> = {
        /** copy parent status */
        loadStatus: node.loadStatus,
        /** child param comes from the children */
        param: children[cKey],
        /** copy pdata from parent, will be modified below */
        pData: DeepCopy.copy(node.pData)
      };
      c.pData.lastDoc = undefined;
      // adjust size, i.e distribute total in equal.
      // if there exact divisibles, sprinkle mod.
      // Ex: if parent sz: 9,  childrens count is : 3, each child is : 3,3,3
      // Ex: if parent sz: 8,  childrens count is : 3, each child is : 3,3,2 
      c.pData.size = (mod > 0) ? sz + 1 : sz;
      --mod;

      // only add add node to root, unless it already exists. just in case if this 
      // sub query
      if (refStatObj[cKey] == null) {
        node[cKey] = c;
      } else {
        // Future TO DO , if query already exists, its paging data many need an update.
      }
      node.children.push(cKey);
    }
  }

  return newNodeTree;
};

const getParents = <TParam>(key: string, state: { [key: string]: IDataLoadStatus<TParam> }) => {
  const k1 = keys(state)
    .filter(x => !!state[x] && !!state[x].children && state[x].children.includes(key))
    .map(y => ({ key: y, node: state[y] }));
  return k1;
};

/**
 * Get root level children for the parent k. Note: if A has two children name B,C and then C has further two
 * root level children D,E, this function will return B,D,E
 * @param pKey parent key whose children has to be found
 * @param state  ref. state container. Note: state is not modified in this function.
 */
export const getRootLevelChildren = <TParam>(pKey: string, state: { [key: string]: IDataLoadStatus<TParam> }) => {
  const result: { key: string, node: IDataLoadStatus<TParam> }[] = [];
  const node = state[pKey];
  if (!!node && !!node.children) {
    node.children.forEach(c => {
      const r = getRootLevelChildren(c, state);
      if (r.length > 0) {
        result.concat(r);
      } else {
        result.push({ key: c, node: state[c] });
      }
    });
  }
  return result;
};

const updateLoadingAndPagingStatus = <TParam>(key: string, state: { [key: string]: IDataLoadStatus<TParam> }) => {
  // find all the parents that have children and currently loading.
  const parents = getParents(key, state);
  parents.forEach(p => {
    if (p.node.children.every(x => state[x].loadStatus === LoadStatus.Loaded)) {
      // all chldren of this parent are full loaded.
      const node = DeepCopy.copy(state[p.key]);
      node.loadStatus = LoadStatus.Loaded;
      // also check if all of the children are full.
      if (p.node.children.every(x => state[x].pData.full)) {
        node.pData.full = true;
      }
      state[p.key] = node;
    }
  });
};

/**
 * Update the paging status and return a copy of the update root branch that can be patched to the state.
 * @param key key of the query that has just been loaded from the server.
 * @param state current load status object from the store. This is frozen state, i.e a copy will be used to return
 * the new state.
 * @param subData Subscription container that keeps the latest option as well as the paging data of
 * all the queries. */
export const updatePaging = <T, TParam>(
  key: string,
  state: { [key: string]: IDataLoadStatus<TParam> },
  subData: ISubscriptionNode<T, TParam>
) => {
  const newState = { ...state };

  const node = DeepCopy.copy(state[key]);
  if (!isNil(node)) {
    node.loadStatus = LoadStatus.Loaded;
    node.pData = DeepCopy.copy(subData.p.pData);
    node.param = DeepCopy.copy(subData.p.param);
  }

  newState[key] = node;
  updateLoadingAndPagingStatus(key, newState);
  return newState;
};

/**
* process the data query. It waits to see of previous request was completed. 
* at the end of the timer if request is not cleared. It send the current request.    * 
* @param getDataStatusStateFn : function to read the current status and also if next page call
* has to be made instead of a full page query.
* @param nextPageFn : next page query if required.
* @param processQueryFn : full page query Fn, if requierd.
*/
export const buildDataRequest = <IParam>(
  key: string,
  param: IParam,
  pData: Paging,
  getDataStatusStateFn: () => { [key: string]: IDataLoadStatus<IParam> },
  buildOrQueryChildrenFn: (o: IParam) => { [key: string]: IParam },
  // buildOrChildrenQueryFn: (o: IParam) => 
  nextPageFn: (o: IParam) => void,
  processQueryFn: (
    updatedStatusObj: { [key: string]: IDataLoadStatus<IParam> },
    set: { key: string, node: IDataLoadStatus<IParam> }[]) => void
) => {

  const timer: TimerHelper = new TimerHelper();
  timer.tryTimeZero = true;
  timer.setInterval(300, 10, () => {
    const statusObj = getDataStatusStateFn();
    const r = readLoadStatus(statusObj, key, pData);

    switch (r.status) {
      case LoadStatus.Loaded:
        logger.log('case loaded exe');
        if (r.nextPageReqd) {
          nextPageFn(param);
        }
        return true;
      case LoadStatus.Loading:
        logger.log('case loading exe');
        if (!timer.isLastCall) {
          return false;
        }
        processQueryFn(null, getSetByStatus(statusObj, key, LoadStatus.Loading));
        return true;
      case LoadStatus.Empty:
        logger.log('case empty exe');
        const currItem = buildOrChildren(key, param, pData, statusObj, buildOrQueryChildrenFn(param));
        // const currItem = getFullBranchWithUpdatedNode(statusObj, key, curr);
        const set = getSetByStatus(currItem, key, LoadStatus.Loading);
        processQueryFn(currItem, set);
        return true;
      default:
        throw Error(`coding errror! Loading status of : ${LoadStatus[status]} in not implemented.`);
    }
  });
};




// #region : Old Full Tree approach 
/** Linked node type structure to process queries. */
// export interface IDataStatus<TParam> {

//   /** Primary Load status (result from all the children OR subscribes.) */
//   loadStatus: LoadStatus;

//   /** Key Parameter that was used to get child TParam */
//   param: TParam;

//   /** Paging Data for the master (will be derived from children.) */
//   pData: Paging;

//   /** children of primary query (OR conditions) */
//   children?: {
//     [cKey: string]: IDataStatus<TParam>
//   };
// }

// export const buildOrChildren = <TParam>(parent: IDataStatus<TParam>, children: { [key: string]: TParam }) => {
//   if (!!children) {
//     const a = Object.keys(children);

//     // children page sizes need to be adjusted.
//     const totalSz = (parent.pData.size > a.length) ? parent.pData.size : a.length;
//     const sz = Math.floor(totalSz / a.length);
//     let mod = parent.pData.size % a.length;

//     parent.children = {};
//     for (let i = 0; i < a.length; i++) {
//       const cKey = a[i];
//       const c: IDataStatus<TParam> = {
//         /** copy parent status */
//         loadStatus: parent.loadStatus,
//         /** child param comes from the children */
//         param: children[cKey],
//         /** copy pdata from parent, will be modified below */
//         pData: DeepCopy.copy(parent.pData)
//       };
//       c.pData.lastDoc = undefined;
//       // adjust size, i.e distribute total in equal.
//       // if there exact divisibles, sprinkle mod.
//       // Ex: if parent sz: 9,  childrens count is : 3, each child is : 3,3,3
//       // Ex: if parent sz: 8,  childrens count is : 3, each child is : 3,3,2 
//       c.pData.size = (mod > 0) ? sz + 1 : sz;
//       --mod;
//       parent.children[cKey] = c;
//     }
//   }
// };

// /**
//  * Go through all the children queries. If the child query has been loaded/full, mark the node 
//  * load status to complete and full. Marked private. it should be used by updatePaging.
//  * @param node query top.
//  */
// const updateLoadedAndFullStatus = <TParam>(node: IDataStatus<TParam>) => {
//   if (!!node.children) {
//     const vals = values(node.children);
//     // assume the tree is loaded and is full.
//     let isLoaded = true;
//     let isFull = true;

//     for (const v of vals) {
//       // call recursively as down the road, child OR query can have its own child queries.
//       updateLoadedAndFullStatus(v);
//       // No need to go further, if last entry is still not fully loaded.
//       if (v.loadStatus !== LoadStatus.Loaded) {
//         isLoaded = false;
//       }
//       if (!v.pData.full) {
//         isFull = false;
//       }
//     }
//     if (isLoaded) {
//       node.loadStatus = LoadStatus.Loaded;
//     }
//     node.pData.full = isFull;
//   }
// };

// /**
//  * Update the paging status and return a copy of the update root branch that can be patched to the state.
//  * @param key key of the query that has just been loaded from the server.
//  * @param state current load status object from the store.
//  * @param subData Subscription container that keeps the latest option as well as the paging data of
//  * all the queries. */
// export const updatePaging = <T, TParam>(
//   key: string,
//   state: { [key: string]: IDataStatus<TParam> },
//   subData: ISubscriptionNode<T, TParam>
// ) => {

//   // get the copy of the root  branch that (itself or its children) contain the given key.
//   const rootBranchCopy = getFullBranch(state, key);

//   // get the exact node from parent. may or may not be root.
//   const qnode = getNode(rootBranchCopy, key);

//   qnode.loadStatus = LoadStatus.Loaded;
//   qnode.pData = DeepCopy.copy(subData.p.pData);
//   qnode.param = DeepCopy.copy(subData.p.param);

//   // node is uptodate. Now check from the top, if this request was for a branch
//   // all the children subscribtion may be complted. so root query may need to be marked completed.
//   const k = keys(rootBranchCopy);

//   if (k.length !== 1) { throw Error('programming error, root must have only one node.'); }

//   // top only contain one key. update the status if loaded.
//   updateLoadedAndFullStatus(rootBranchCopy[k[0]]);
//   return rootBranchCopy;
// };

// #endregion