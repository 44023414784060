// import 'firebase/firestore';

import { SingletonService } from '@trent/services/singleton.service';
import { UserService } from '@trent/services/user.service';
import { IAuthCoreService } from '@trent/services/auth/iauth-core.service';
import { Injectable } from '@angular/core';
import { AuthService } from '@trent/services/auth/auth.service';
import { IcloudMessageService } from '@trent/services/fcm/icloud-message.service';
import { IgeoLocationService } from '@trent/services/geo-loc/igeo-location.service';
import { Store } from '@ngxs/store';
import { IdeviceIdService } from '@trent/services/device/idevice-id.service';
import { environment } from '@trent/core/environments/environment';
import { LibSetting } from '@trent/models/lib-setting';
import { FirebaseRTDBService } from '@trent/services/firebase-rtdb.service';
import { EventService } from '@trent/services/event.service';
import { TasksRequested, TaskState } from '@trent/store/task-store';
import { map } from 'rxjs/operators';
import { isEqual } from 'lodash';
import { Subscription } from 'rxjs';
import { ITaskParam } from '@trent/models/sys/task-param';
import { UtilityService } from '@trent/services/utility.service';
import { logger } from '@trent/models/log/logger';
import { GeoPoint } from '@angular/fire/firestore';


/**
 * Purpose of this service is to fill in the singlton service container @class SingletonSerive with the key
 * services of the site that are lazy loaded. It also set up the initial messaging / and Geo location services.
 * processes.
 */
@Injectable()
export class CoreLazyService {
  constructor(
    private singleton: SingletonService,
    private store: Store,
    private es: EventService,
    private as: IAuthCoreService,
    private userService: UserService,
    private geoLocService: IgeoLocationService,
    public cloudMsgService: IcloudMessageService,
    public authService: AuthService,
    public deviceIdService: IdeviceIdService,
    public firebaseRTDBService: FirebaseRTDBService,
    public us: UtilityService
  ) {

    // Initialize Library types.
    LibSetting.setTypes(GeoPoint);

    // Fill in the lazy container.
    this.setupDeviceService();
    this.setupAuthService();
    this.setupCloudMessaging();
    this.setupUserService();

    // Geo must be called after the cloud as device id subscription is needed
    // in Geo Location.
    this.setupGeoLoction();
    this.setupFirebaseRTDB();
    this.us.bindAppSettings(this.singleton);
  }

  private setupDeviceService() {
    if (this.singleton.deviceIdService == null) {
      this.singleton.deviceIdService = this.deviceIdService;
      this.deviceIdService.getDeviceId()
        .then(d => logger.log('[deviceID] did: ', d));
    }
  }

  private setupAuthService() {
    if (this.singleton.authService == null) {
      this.singleton.authService = this.authService;
      // Setup User Auth and token management.
      this.authService.initUserAuthSync();
    }
  }

  private async setupCloudMessaging() {
    if (this.singleton.cloudMsgService == null) {
      this.singleton.cloudMsgService = this.cloudMsgService;
      // setup the cloud messaging.
      this.cloudMsgService
        .requestNotificationPermission()
        .then(res => {
          logger.log('fcm permission result :-> ', res);
        })
        .catch(err => {
          if (!!err && !!err.message) {
            logger.warn('fcm permission denied or result in error :-> ', err.message);
          } else {
            logger.error('fcm permission denied or result in error :-> ', err);
          }
        });
    }
  }

  private setupUserService() {
    if (this.singleton.userService == null) {
      this.singleton.userService = this.userService;
    }
  }

  private async setupGeoLoction() {
    if (this.singleton.geoLocService == null) {
      this.singleton.geoLocService = this.geoLocService;
      // Broadcasting for now is only required on cordova.
      // web will be on demand.
      if (environment.platform !== 'web') {
        logger.log('[GeoLocation] setup started!');
        this.geoLocService.setupBroadCasting();
      }
    }
  }

  private setupFirebaseRTDB() {
    if (this.singleton.firebaseRTDBService == null) {
      this.singleton.firebaseRTDBService = this.firebaseRTDBService;
      // Get the tass from the store/db and emit the counts.
      const pData = { size: 2, offset: 0, full: false };
      let tasks: any;

      let subTask: Subscription = null;
      let lastUid: string;
      this.as.user$.subscribe(u => {
        if (u == null) {
          subTask?.unsubscribe();
          subTask = null;
        } else if (u.uid !== lastUid) {
          lastUid = u.uid;
          const param: ITaskParam = { sendTo: u.uid, isNew: true };
          this.store.dispatch(new TasksRequested({ pData, param }));
          subTask = this.store.select(TaskState.selectAllData)
            .pipe(map(clientFilterFn => clientFilterFn(param)))
            .subscribe(p => {
              if (!!p && p.length > 0) {
                if (!isEqual(tasks, p)) {
                  tasks = p;
                  logger.log('tasks', tasks);
                }
              } else {
                tasks = [];
                logger.log('tasks', tasks);
              }
              this.es.emit<number>(this.es.loadTaskNumbers, tasks.length);
            });
        }
      });
    }
  }
}
