import { Paging, getObjKey } from '@trent/models/observable-util/paging';
import { IDataLoadStatus, buildDataRequest, LoadStatus, getRootLevelChildren, updatePaging } from '@trent/models/observable-util/data-status';
import { State, Selector, Action, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { StateBase } from '../state-base';
import { Subscription, noop } from 'rxjs';
import { PagingContainer } from '@trent/models/observable-util/paging-container';
import * as entity from '@trent/store/entity.state';
import { map } from 'rxjs/operators';
import { logger } from '@trentm/log/logger';
import { RoleGroup } from '@trent/models/role-group/role-group';
import { getIRoleGroupOptionOrChildren, IRoleGroupParam,roleGroupSearchClientFilter } from '@trent/models/role-group/role-group-param';
import { RoleGroupService } from '@trent/services/rolegroup.service';

// #region State Model
export interface RoleGroupStateModel {

  paging: Paging;

  /** Which client side queries are complete */
  dataLoadStatus: {
    [key: string]: IDataLoadStatus<IRoleGroupParam>;
  };

  /** Promo List Container. */
  data: { [id: string]: RoleGroup }; // entity.EntityState<ProductBase>;
}
function initRoleGroupState(): RoleGroupStateModel {
  return {
    paging: { size: 10, offset: 0, full: false },
    dataLoadStatus: {},
    data: {}
  };
}
// #endregion
// #region actions
export class RoleGroupByIdRequested {
  static readonly type = '[RoleGroup] Request a single RoleGroup entity';
  constructor(public payload: { id: string | number }) { }
}
export class RoleGroupByIdLoaded {
  static readonly type = '[RoleGroup] Load a single RoleGroup entity';
  constructor(public payload: { data: RoleGroup, id: string | number }) { }
}
export class RoleGroupStateReset {
  static readonly type = '[RoleGroup] Reset State';
}
export class RoleGroupRequested {
  static readonly type = '[RoleGroup] All RoleGroups Requested';
  constructor(public payload: { pData: Paging, param: IRoleGroupParam }) { }
}
export class RoleGroupLoaded {
  static readonly type = '[RoleGroup] All RoleGroups Loaded';
  constructor(public payload: {
    data: { [id: string]: RoleGroup }, // Data
    key: string,
  }) { }
}
export class RoleGroupsNextPageRequested {
  static readonly type = '[RoleGroup] All RoleGroups requested - Next Page';
  constructor(public payload: { option: IRoleGroupParam }) { }
}
export class UsersUpdated {
  static readonly type = '[RoleGroup] RoleGroups Updated';
  constructor(public payload: { id: string | number, data: RoleGroup }) { }
}
// #endregion
@State<RoleGroupStateModel>({
  name: 'RoleGroup',
  defaults: initRoleGroupState()
})
@Injectable()
export class RoleGroupState extends StateBase {
  dataReqSub: Subscription;

  /** Container that keep all of the subscription related to gettting data. */
  dataSubData: PagingContainer<RoleGroup, IRoleGroupParam> = new PagingContainer();


  constructor(private rgs: RoleGroupService) {
    super();
  }
  // #region Selectors
  @Selector()
  static selectDataById(state: RoleGroupStateModel) {
    return entity.getByIdFn_new(state.data, RoleGroup.parse);
  }
  @Selector()
  static selectAllData(state: RoleGroupStateModel) {
    return (o: IRoleGroupParam): RoleGroup[] => {

      if (state.data == null || Object.keys(state.data).length === 0) {
        return [];
      }

      // remove keys that have revision/draft ids, i.e that contain '~' in the id.
      const keys = Object.keys(state.data).filter(k =>
        k.indexOf('/') === -1 && state.data[k] != null);

      // object without the rev/draft.
      const filtered = keys.reduce((obj, k) => {
        obj[k] = state.data[k];
        return obj;
      }, {});

      let output = Object.values(filtered).map(x => RoleGroup.parse(x));
      output = roleGroupSearchClientFilter(output, o);

      return output;
    };
  }
 
  // #endregion
  // #region Custom Functions and subscriptions

  public clearSubscriptions() {
    if (!!this.dataReqSub) {
      this.dataReqSub.unsubscribe();
      this.dataReqSub = null;
    }
    this.dataSubData.unsubscribeAll();
    super.clearSubscriptions();
  }
  // #endregion

  // #region Actions Single Entity
  @Action(RoleGroupByIdRequested)
  dataByIdRequested(context: StateContext<RoleGroupStateModel>, action: RoleGroupByIdRequested) {
    const state = context.getState();
    if (Object.keys(state.data).indexOf(`${action.payload.id}`) === -1) {
      // logger.log('requested Rental-Product is not in the store. sending request to server', action.payload.id);
      this.rgs.getDataById(action.payload.id)
        .pipe(
          map(data => {
            logger.log('from server: userProfile map was called', data);
            return context.dispatch(new RoleGroupByIdLoaded({ data, id: action.payload.id }));
          }
          )).subscribe(noop);
    } else { logger.log('requested userProfile is available in the store'); }
  }
  @Action(RoleGroupByIdLoaded)
  dataByIdLoaded(context: StateContext<RoleGroupStateModel>, action: RoleGroupByIdLoaded) {
    const state = context.getState();
    const data = action.payload.data || null;
    const p = {};
    p[action.payload.id] = data;
    context.patchState({
      data: { ...state.data, ...p }
    });
  }
  // #endregion Single Entity

  // #region All Records
  @Action(RoleGroupRequested)
  dataRequested(context: StateContext<RoleGroupStateModel>, action: RoleGroupRequested) {

    const oKey = getObjKey(action.payload.param);

    // Define function that return the data status object from the state.
    const getDataStatusStateFn = () => context.getState().dataLoadStatus;

    /** custom build the OR children query. */
    const buildOrQueryChildrenFn = (o: IRoleGroupParam) => getIRoleGroupOptionOrChildren(o);

    // if data requested now, is already partially loaded by another query's child previously
    // but the number of the items are not enough (ex. as a child, it loaded only 5, but current
    // request ask for more, then next page of that query should be called instead.)
    const nextPageFn = (option: IRoleGroupParam) => {
      context.dispatch(new RoleGroupsNextPageRequested({ option }));
    };

    buildDataRequest(
      oKey, action.payload.param, action.payload.pData,
      getDataStatusStateFn,
      buildOrQueryChildrenFn,
      nextPageFn,
      (
        obj: { [key: string]: IDataLoadStatus<IRoleGroupParam> },
        set: { key: string, node: IDataLoadStatus<IRoleGroupParam> }[]
      ) => {

        if (!!obj) {
          // Patch the state.
          const state = context.getState();
          context.patchState({
            dataLoadStatus: { ...state.dataLoadStatus, ...obj }
          });
        }

        // Process the query.
        set.forEach((val) => {
          // some of the nodes are already loaded. Only process that are loading... status.
          if (val.node.loadStatus !== LoadStatus.Loading) {
            return;
          }
          // if this request is just overwriting a stall or pending request, unsubscribe that observable
          this.dataSubData.unsubscribe(val.key);

          // create the paging observable and call db.
          const p = this.rgs.getAllRoleGroup_PagingObservable();
          const prod$ = p.getData(action.payload.pData, val.node.param)
            .pipe(
              map(pickDrops => {
                // if (!val.node.param.pdIdForHistory) {
                context.dispatch(new RoleGroupLoaded({
                  data: pickDrops as any,
                  key: val.key
                }));
                // } else {
                //   context.dispatch(new AllPickDropHistoryLoaded({
                //     data: pickDrops,
                //     key: val.key
                //   }));
                // }
                return pickDrops;
              }));
          const sub = this.subscribe(prod$, () => noop(), RoleGroupRequested.type);
          // save the observable call
          this.dataSubData.addData(val.key, sub, p);
        });
      }
    );
  }
  @Action(RoleGroupsNextPageRequested)
  dataNextPageRequested(context: StateContext<RoleGroupStateModel>, action: RoleGroupsNextPageRequested) {
    const oKey = getObjKey(action.payload.option);
    const state = context.getState();
    // find the node. can be parent or child
    const statusObj = state.dataLoadStatus[oKey];
    // if no parent, treat is
    if (statusObj.children == null) {
      this.dataSubData.dispatchNextPagingUpdate(oKey);
    } else {
      const children = getRootLevelChildren(oKey, state.dataLoadStatus);
      children.forEach(c => {
        this.dataSubData.dispatchNextPagingUpdate(c.key);
      });
    }
  }
  @Action(RoleGroupLoaded)
  dataLoaded(context: StateContext<RoleGroupStateModel>, action: RoleGroupLoaded) {
    const state = context.getState();
    const subData = this.dataSubData.getData(action.payload.key);
    const updatedLoadStatus = updatePaging(action.payload.key, state.dataLoadStatus, subData);
    context.patchState({
      dataLoadStatus: updatedLoadStatus,
      data: { ...state.data, ...action.payload.data }// entity.addMany(state.products, action.payload.products)
    });
  }
  
  @Action(RoleGroupStateReset)
  dataStateReset(context: StateContext<RoleGroupStateModel>, action: RoleGroupStateReset) {
    // unsubscribe the data
    this.clearSubscriptions();
    context.setState(initRoleGroupState());
  }
  // #endregion All TripTrackers
}
