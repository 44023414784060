import { IValidationMsg } from '../error-handling';
import { toValidationError } from '../utility';
import { Exclude, Expose, instanceToInstance, plainToInstance, Type } from 'class-transformer';
import { IsBoolean, IsDate, IsDefined, IsInt, Max, Min, validateSync, ValidateIf, Equals, IsString } from 'class-validator';
import { PageHtml, PageHtmlSection, PageHtmlType } from '../cms/page-html';
import { ValidatorOptions } from 'class-validator';
import { FileInfo } from '../media/file-info';
import { globalToInstance } from '../cms/page-html-decorator';



@Exclude()
export class PtsLocationImage extends PageHtml {

  @Expose()
  @IsDefined({ message: 'Image should be required' })
  inputImage: FileInfo;

  @Expose()
  @IsDefined({ message: 'Alternate Name should be required' })
  altImage: string;

  @Expose()
  locId: string;

  sanitize() {
    super.sanitize();
  }

  autoSection: Record<string, PageHtmlSection> = {};

  /**
   * Adding pageHtmlType in this class
   */
  constructor() {
    super();
    this.pageHtmlType = PageHtmlType.ptsLocation;
    globalToInstance(PtsLocationImage, this);
  }

  clone() {
    const t = instanceToInstance(this);
    t.sanitize();
    return t;
  }

  validateSync(): IValidationMsg {
    const r = this.validateSyncBase(this);
    return r;
  }

  // public static parse(obj) {
  //   if (obj == null) { return null; }
  //   const m = plainToInstance<PtsLocationImage, any>(PtsLocationImage, obj);
  //   m.sanitize();
  //   return m;
  // }

  validateImage(options?: ValidatorOptions): IValidationMsg {
    let r: IValidationMsg = {};
    if (this.inputImage && this.inputImage.name) {
      const tempSD = this.inputImage.validateSync(options, this.inputImage);
      r = { ...r, ...tempSD };
    } else {
      r['Image'] = ["Please upload image document"];
    }
    return r;

  }

}