import { pickBy } from 'lodash';
import { Subscription, Observable } from 'rxjs';
import { logger } from '@trentm/log/logger';

export class StateBase {

  // private deletedRecords: string[] = [];
  /**
    * Container that must be emptied when user is logged out.
    */
  private subscriptions: { sub: Subscription, name: string }[] = [];

  /**
   * Logout all the states when user is logged out
   */
  public clearSubscriptions() {
    // Unsubscribe the logged in subscriptions.
    while (this.subscriptions.length > 0) {
      const s = this.subscriptions[this.subscriptions.length - 1];
      logger.log('Unsubscribing -> Store Action: ', s.name);
      s.sub.unsubscribe();
      this.subscriptions.pop();
    }

    // this.resetDeletedKeys();
  }

  /**
   * Utility function to manage the subscription in the store so proper error handling can be done.
   * @param o  : Observable
   * @param fnSuccess  : run after subscription receive the data
   * @param fnFailure : custom method , if error is thrown.
   */
  public subscribe<Z>(o: Observable<Z>, fnSuccess: (z: Z) => void, name: string, fnFailure?: (err) => void) {
    const s = o.subscribe(
      c => fnSuccess(c),
      err => {
        if (!!fnFailure) {
          fnFailure(err);
        } else {
          logger.error('Store Subscription Error: ', err, name);
        }
      }
    );
    this.subscriptions.push({ sub: s, name: name });
    return s;
  }

  // public filterDeletedContent<T>(data: { [key: string]: T }): { [key: string]: T } {
  //   return pickBy(data, (val, key) => !this.deletedRecords.includes(key));
  // }

  // public storeDeletedKey(key: string | number) {
  //   if (!this.deletedRecords.includes(`${key}`)) {
  //     this.deletedRecords.push(`${key}`);
  //   }
  // }

  // public hasDeletedKey(key: string | number) {
  //   return this.deletedRecords.includes(`${key}`);
  // }

  // private resetDeletedKeys() {
  //   this.deletedRecords = [];
  // }
}
