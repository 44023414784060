import { Paging } from '../observable-util/paging';
import { UserProfile } from '../user/user-profile';
import { logger } from '../log/logger';
import { CollectionReference, limit, orderBy, query, Query, QueryConstraint, startAfter, where } from 'firebase/firestore';
import { PromotionLeads } from './promotion-leads';

/**
 * Promotion Leads Store method and interface
 */
export interface IPromotionLeadsParam {
  orderDirection?: 'desc' | 'asc';
  orderBy?: string;
  name?: string;
  isEmailUnSubscribed? : boolean;
  pendingEmailPromotions?: string;
  pendingSMSPromotions?: string;
  completedEmailPromotions?: string;
  completedSMSPromotions?: string;
}

export const promotionLeadsParamInit = (): IPromotionLeadsParam => {
  return {
    orderBy: 'updatedAt',
    orderDirection: 'desc',
  };
};

/** Server filtering of firebase query building of query  */
export const promotionLeadsSearchServerQuery = (ref: CollectionReference, o: IPromotionLeadsParam, p: Paging) => {
  let q: Query = <any>ref;

  const cons: QueryConstraint[] = [];
  if (o.isEmailUnSubscribed != undefined) {
    cons.push(where('isEmailUnSubscribed', '==', o.isEmailUnSubscribed));
  }
  
  cons.push(where('updatedAt', '<=', new Date()));
  cons.push(orderBy('updatedAt', 'desc'));
  if(o.pendingEmailPromotions) {
    cons.push(where('pendingEmailPromotions', 'array-contains', o.pendingEmailPromotions));
  }
  if(o.completedEmailPromotions) {
    cons.push(where('completedEmailPromotions', 'array-contains', o.completedEmailPromotions));
  }
  if(o.pendingSMSPromotions) {
    cons.push(where('pendingSMSPromotions', 'array-contains', o.pendingSMSPromotions));
  }
  if(o.completedSMSPromotions) {
    cons.push(where('completedSMSPromotions', 'array-contains', o.completedSMSPromotions));
  }


  if(!!p) {
    if (!!p.lastDoc) {
      logger.log('Start After Query: ', p.lastDoc[o.orderBy]);
      cons.push(startAfter(p.lastDoc[o.orderBy]));
    }
    cons.push(limit(p.size));
  }
  q = query(ref, ...cons);
  return q;
};

/** Client side filtering of the data. */
export const PromotionLeadsSearchClientFilter = (p: PromotionLeads[], o: IPromotionLeadsParam) => {

  // if (!!o.name) {
  //   p = p.filter(val => val.name === o.name.toLowerCase());
  // }

  return p;
};


export const getIPromotionLeadsOptionOrChildren = (o: IPromotionLeadsParam): { [key: string]: IPromotionLeadsParam } => {
  return undefined;
};
