import { Length, IsEmail, ValidatorOptions, validateSync } from 'class-validator';
import { toValidationError } from '../utility';
import { IValidationMsg } from '../error-handling/validation-info';

/** Registration model used during custum registration of email and password. */
export type LoginMethod = 'phone' | 'email';

export class UserLogin {

  loginMethod: LoginMethod = 'email';

  @IsEmail({}, { message: 'A valid email is required', groups: ['email'] })
  email: string;

  @Length(8, 16, {
    message: 'Password is required. $constraint1 - $constraint2 to chars', groups: ['email']
  })
  password: string;


  // @IsPhoneNumber('ZZ', { message: 'A valid phone is required', groups: ['phone'] })
  phone: string;

  @Length(6, 7, {
    message: 'Phone code is required. $constraint1 - $constraint2 to chars', groups: ['code']
  })
  code: string;
  

  validateSync(options: ValidatorOptions): IValidationMsg {
    const r = validateSync(this, options);
    const m = toValidationError(r);
    return m;
  }

}
