import { IsDefined, IsEnum, IsInt, Max, MinLength } from "class-validator";
import { BaseModel } from "../base/base-model";
import { IValidationMsg } from "../error-handling";
import { sanitizeDateIPoint } from "../utility/sanitize-helper";
import { LegacyContractValidationGroup } from "./legacy-contract-base";
import { MaintainedBy } from "./legacy-contract-enum";
import { Exclude, Expose, instanceToInstance, plainToInstance } from 'class-transformer';
import { vinRegex } from "../product/vehicle";
import { Matches, Min, ValidatorOptions } from 'class-validator';

const productSummary: LegacyContractValidationGroup = 'productSummary';


/**
 * Lease Maintenance Package model (Internally used in Legacy Contract model)
 */

@Exclude()
export class LCProduct extends BaseModel {

    constructor() {
        super();
    }
    @Expose()
    @MinLength(2, { message: 'Make needs Minimum $constraint1 chars', groups: [productSummary] })
    make: string;
  
    @Expose()
    @MinLength(1, { message: 'Model needs defined', groups: [productSummary] })
    model: string;
  
    @Expose()
    @IsInt()
    @Max(2040)
    @Min(1995, { groups: [productSummary] })
    modelYear: number;



    @Expose()
    @Matches(vinRegex.js.nounVin, { message: 'enter valid vin', groups: [productSummary] })
    vin: string;

    @Expose()
    get vinLastSix() {
      const l = this.vin ? this.vin.length : 0;
      return l > 6 ? this.vin?.substring(l - 6, l) : undefined;
    }

    

    public static parse(obj : any) {
        if (obj == null) { return null; }
        const m = plainToInstance<LCProduct, any>(LCProduct, sanitizeDateIPoint(obj));
        m.sanitize();
   
        return m;
    }

    validateSync(options? : ValidatorOptions): IValidationMsg {
        const r = this.validateSyncBase(this, options);
        return r;
    }

    clone() {
        const t = instanceToInstance(this);
        t.sanitize();
        return t;
    }

    sanitize() {
        // if data was recieved from firebase, date is stored as snapshot.
        super.sanitize();
    
    }

 


   

}


