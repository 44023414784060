import { ProductType } from '../product/product-type';
import { DbStatus } from '../base';
import { IKeyVal, getObjKey, Paging, getDirection } from '../observable-util/paging';
import { TripType } from './trip-type';
import { DeepCopy } from '../utility/deep-copy';
import { Address } from '../address/address';
import { TripStatus } from './trip-status';
import { startOfToday } from 'date-fns';
import { logger } from '../log/logger';
import { CollectionReference, limit, orderBy, query, Query, QueryConstraint, startAfter, where } from 'firebase/firestore';

export interface TripParam {
  type?: ProductType;
  tripType?: TripType | '*';
  /** Parent Companies to be searched */
  cids?: string[];
  orderBy?: string;
  startSearchAddress?: Address;
  // startLat?: number;
  // startLong?: number;
  endSearchAddress?: Address;
  // endLat?: number;
  // endLong?: number;
  radius?: number;
  incRadius?: number;  // incremental radius
  searchStartDate?: Date;  //
  searchEndDate?: Date; // for client filter
  dbStatus?: DbStatus;

  trackingNo?: string;
  tripStatus?: TripStatus;
  isOpen?: boolean;
  truck?: string;
  trailer?: string;
  driver?: string;
  isExpired?: boolean;
  isTableView?: boolean;  // not used for the search for saving page state


}
export const tripSearchOrderBy: IKeyVal[] = [
  // { key: 'Available to Date', value: 'startDate' },
  { key: 'rate', value: 'rate' },
  { key: 'distance', value: 'distance' },
  { key: 'time', value: 'time' }
];
export const tripParamInit = (): TripParam => {
  return {
    type: ProductType.trip,
    tripType: <any>'*',
    cids: [],
    orderBy: 'createdAt desc',
    // startLat: 43.7315,
    // startLong: -79.7624,
    searchStartDate: startOfToday(),
    // dbStatus: DbStatus.Released,

    tripStatus: null,
    // isTableView: false,
    isOpen: true
  };
};
/** Get the OR condition options. if there are more then one company id's are provided
 *  each cid makes up a unique OR condition query. */
export const getTripSearchOptOrChildren = (o: TripParam): { [key: string]: TripParam } => {
  if (!!o.cids && o.cids.length > 1) {
    const r: { [key: string]: TripParam } = {};
    o.cids.forEach((cid) => {
      const c = DeepCopy.copy(o);
      c.cids = [cid];
      r[getObjKey(c)] = c;
    });
    return r;
  }
  return undefined;
};
/** Server filtering of firebase query building of query  */
export const tripSearchServerQuery = (ref: CollectionReference, o: TripParam, p: Paging) => {
  const cons: QueryConstraint[] = [];

  const dbStatus = DbStatus;
  if (!!o.dbStatus) {
    cons.push(where('dbStatus', '==', o.dbStatus));
  }
  // where productType is trip, defined in tripParamInit
  if (!!o.type) {
    cons.push(where('productType', '==', +o.type));
  }
  if (o.isExpired !== undefined) {
    cons.push(where('isExpired', '==', o.isExpired));
  }
  if (!!o.trackingNo) {
    cons.push(where('trackingNumbers', 'array-contains', o.trackingNo));
    p.size = 2; // overwrites page size, db rules allow anonomus user to get trips upto 2 when tracking number is passed in search
    o.cids = [];
  }
  if (!!o.tripType && o.tripType !== <any>'*') {
    cons.push(where('tripType', '==', +o.tripType));
  }
  if (o.cids.length > 0) {
    logger.log('o.cids[0]', o.cids[0]);
    cons.push(where('carrierCompanySummary.cid', '==', o.cids[0]));  // vendorCompSummary
  }
  if (o.isOpen !== undefined && !o.tripStatus) {
    cons.push(where('isOpen', '==', o.isOpen));
    // q = q.orderBy('tripStatus', 'asc');
  }
  if (!!o.truck) {
    cons.push(where('activeTrucks', 'array-contains', o.truck));
  }
  if (!!o.trailer) {
    cons.push(where('activeTrailers', 'array-contains', o.trailer));
  }
  if (!!o.tripStatus && o.tripStatus !== <any>'*') {
    cons.push(where('tripStatus', '==', o.tripStatus));
  }
  if (!!o.driver) {
    cons.push(where('driverIdOrPhone', '==', o.driver));
  }
  const { col, dir } = getDirection(tripSearchOrderBy, (!!o.orderBy) ? o.orderBy : 'createdAt desc');
  logger.log('col and dir are: ', col);
  cons.push(orderBy(col, dir));
  if (!!p.lastDoc) {
    logger.log('last doc: ', p.lastDoc[col]);
    cons.push(startAfter(p.lastDoc[col]));  // startAfter(p.lastDoc[col]);
  }
  cons.push(limit(p.size));
  return query(ref, ...cons);
};
