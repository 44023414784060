import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsModule } from '@ngxs/store';
import { TripTrackerService } from '@trent/services/trip-tracker.service';
import { TripTrackerState } from './trip-tracker-state';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    NgxsModule.forFeature([TripTrackerState])
  ],
  providers: [
    TripTrackerService
  ]
})
export class TripTrackerStoreModule { }
