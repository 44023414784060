import { UtilityService } from '@trent/services/utility.service';
import { cleanListeners } from '@trent/models/utility';
import { getMenuDataDpc } from './menu-data-dpc';
import { EventService, IEventListener } from '@trent/services/event.service';
import { Component, OnDestroy, OnInit, ViewChild, NgZone, HostListener, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Observable, Subscription, fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, shareReplay } from 'rxjs/operators';
import { MatSidenav, MatDrawer } from '@angular/material/sidenav';
import { ScrollDispatcher, CdkScrollable } from '@angular/cdk/scrolling';
import { MenuData, BackBtnData, PaneType } from '@trent/models/UI/menu-data';
import { Router } from '@angular/router';
import { IAuthCoreService } from '@trent/services/auth/iauth-core.service';
import { Store } from '@ngxs/store';
import { RouterState } from '@trent/store/root-store/router.state';
import { CmsTogglePageEdit } from '@trent/store/cms-store';
import { UserByIdRequested, UserState } from '@trent/store/user-store';
import { AuthState } from '@trent/store/auth-store/auth.state';
import { ICompanyRoleProfile } from '@trent/models/user/role-mapping';
import { IDefaultCompanyData } from '@trent/models/user/company-user-profile';
import { Platform } from '@ionic/angular';
import { logger } from '@trent/models/log/logger';
import { faInstagram, faLinkedin, faTiktok, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faIcons, faPlus, faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { Animations } from '../common2/animations';
import { AllSparePartsRequested, SparePartState } from '@trent/store/spare-part';
import { SparePart } from '@trent/models/spare-part/spare-part';
import { MatDialog } from '@angular/material/dialog';
import { DpcGlobalSearchPopupComponent } from '../../dpc/dpc-global-search-popup/dpc-global-search-popup.component';
import { CmsState } from '@trent/store/cms-store';

@Component({
  selector: 'trent-shell-dpc',
  templateUrl: './shell-dpc.component.html',
  styleUrls: ['./shell-dpc.component.scss'],
  animations: [Animations.animeSlideInOut],
})

export class ShellDpcComponent implements OnInit, AfterViewInit, OnDestroy {

  nTasks = 0;
  uid: string;
  dComp: IDefaultCompanyData;
  cRoleProfile: ICompanyRoleProfile;
  isMultipleComp: boolean;
  subTitle: string;
  customH: string;
  isFixViewPort: boolean;
  gps: { show: boolean; status: boolean; };
  // 

  get url() {
    return this.router.url.toLocaleLowerCase();
  }
  get isRoot() { return this.router.url.split('?')[0] === '/'; }

  get showBg() { return this.isRoot; }

  get showLanding() { return this.router.url.split('?')[0] === '/landing-page'; }

  socials = [
    { icon: 'facebook', url: 'https://www.facebook.com/dixiepartscentre?mibextid=ZbWKwL' },
    { icon: 'instagram', url: 'https://www.instagram.com/dixiepartscentre/?igshid=YmM0MjE2YWMzOA' },
    // { icon: 'youtube', url: 'https://www.youtube.com/channel/UCf9_3OPk4xqgbBHlSwiyNDQ' },
    // { icon: 'tiktok', url: 'https://www.tiktok.com/@pride_truck_sales' },
    // { icon: 'linkedin', url: 'https://www.linkedin.com/company/pride-truck-sale' }
  ];


  constructor(
    public as: IAuthCoreService,
    // public afAuth: AngularFireAuth,
    private breakpointObserver: BreakpointObserver,
    private scrollDispatcher: ScrollDispatcher,
    private ngZone: NgZone,
    private es: EventService,
    private router: Router,
    private store: Store,
    private us: UtilityService,
    private platform: Platform,
    library: FaIconLibrary,
    public dialog: MatDialog
  ) {
    this.isHandset$ = this.us.isHandset$;
    this.layoutChanges = this.us.layoutChanged$;
    setTimeout(() => {
      console.log('URL IS: ', this.router.url);
      console.log('URL Is ROOT: ', this.isRoot);
    }, 3000);
    // https://github.com/FortAwesome/angular-fontawesome/blob/master/docs/usage/using-other-styles.md    
    library.addIcons(faPlus, faIcons, faInstagram, faYoutube, faTiktok, faLinkedin);
  }
  isAuth = false;
  isAdmin = false;
  //Sale operation of dpc user data
  isSalesOperationDpc = false;
  displayName = '';
  isEditor: boolean;

  title: string; // = '??-sub title';
  isLeftVisible = true;
  menuFilt: MenuData[] = [];
  subMenuFilt: MenuData[] = [];
  menu: MenuData[] = getMenuDataDpc();

  public eventListners: IEventListener[] = [];

  subMenuData: MenuData[];

  sub: Subscription[] = [];

  private readonly SHRINK_TOP_SCROLL_POSITION = 50;
  shrinkToolbar = false;

  @ViewChild('drawer', { static: false })
  drawer: MatSidenav;

  isHandset$: Observable<boolean>;

  layoutChanges: Observable<BreakpointState>;

  isLandscape = false;

  isHandset = false;
  /** effective show/hide of back button. */
  showBackBtn = false;
  /** custom handeling of back button by a given page actions. */
  private showBackBtnCustom = false;
  /** back button flag from the router state of store. */
  private showBackBtnRouter = false;

  // backBtnClickFn: (param) => Promise<boolean>;
  // backBtnClickCheckFn: (param) => Promise<boolean>;


  public showSaveCancel = false;
  public showEdit = false;
  public showNextCancel = false;
  public showSearch = false;
  public showNotification = false;
  showMenu: number;
  showSubMenu: number;
  showSubSubMenu: number;

  @ViewChild('searchTextField') searchTextRef;
  isSearchShow: boolean = false;
  searchResults: any;
  searchText: string;
  searchTextCount: number = 0;
  allSpareParts: SparePart[];
  mAreaHeight:number= 0;

  ngOnInit(): void {

    // if (this.us.isPlatformServer) {
    //   this.initMenu();
    //   return;
    // }

    // temp using auth.
    this.sub.push(
      this.as.user$.subscribe(x => {
        if (!!x) {
          this.isAuth = true;
          this.displayName = x.displayName;
          this.dComp = null;
          this.isMultipleComp = false;
          this.getdefaultCompany();
        } else {
          this.isAuth = false;
          this.displayName = '';
        }
        this.initMenu();
      })
    );

    this.sub.push(
      this.as.isAdmin$.subscribe(x => {
        this.isAdmin = x;
        this.initMenu();
      })
    );
    this.sub.push(
      this.as.isEditor$.subscribe(x => {
        this.isEditor = x;
        this.initMenu();
      })
    );

      /// Here checking person is sales/ credit person
      this.sub.push(
        this.store.select(AuthState.user).subscribe(u => {
          this.isSalesOperationDpc = u?.roles?.salesOperationsDpc ? true : false;
          this.initMenu();
        })
      );


    // router state managed back button
    this.sub.push(
      this.store.select(RouterState.canGoBack)
        .subscribe(flag => {
          this.showBackBtnRouter = flag;
          this.updateShowHideBackbutton();
        })
    );

    // back button dispaly request from page
    this.eventListners.push(
      this.es.listen<boolean>(this.es.showHidePageBackBtn, (flag) => {
        this.showBackBtnCustom = flag;
        logger.log(`[Shell] Goback-cust: ${flag}`);
        this.updateShowHideBackbutton();
      })
    );

    // listen to the task counts
    this.eventListners.push(
      this.es.listen<number>(this.es.loadTaskNumbers, (n) => {
        if (n > this.nTasks && !this.showNotification) {
          this.showNotification = true;
          setTimeout(() => {
            this.showNotification = false;
          }, 3000);
        }
        this.nTasks = n; logger.log('nTasks', this.nTasks);

      })
    );

    // Listens to show Save/Cancel buttons on the menu.
    this.eventListners.push(
      this.es.listen<boolean>(this.es.menuShowSaveCancel, (show) => this.showSaveCancel = show)
    );
    // Listens to show Edit buttons on the menu.
    this.eventListners.push(
      this.es.listen<boolean>(this.es.menuShowEdit, (show) => this.showEdit = show)
    );
    // Listens to show Next/Cancel buttons on the menu.
    this.eventListners.push(
      this.es.listen<boolean>(this.es.menuShowNextCancel, (show) => this.showNextCancel = show)
    );
    // Listens to show search buttons on the menu.
    this.eventListners.push(
      this.es.listen<boolean>(this.es.menuShowSearch, (show) => this.showSearch = show)
    );
    // Listens to show search buttons on the menu.
    this.eventListners.push(
      this.es.listen<{ show: boolean, status: boolean }>(this.es.menuShowGPSOn, (gps) => this.gps = gps)
    );

    // Update the menu.
    this.initMenu();
    this.eventListners.push(this.es.listen<MenuData[]>(this.es.loadPageOptions,
      data => (this.subMenuData = data)));
    this.sub.push(this.isHandset$.subscribe(x => { this.isHandset = x; }));
    this.sub.push(this.layoutChanges.subscribe(x => {
      // logger.log('[Shell] Orientation Changed: ' + JSON.stringify(x));
      // logger.log('[Shell] Mobile Landscape: ' + x.breakpoints[Breakpoints.HandsetLandscape]);
      // logger.log('[Shell] Tablet Landscape: ' + x.breakpoints[Breakpoints.TabletLandscape]);
      this.isLandscape = x.breakpoints[Breakpoints.HandsetLandscape] || x.breakpoints[Breakpoints.TabletLandscape];
    }));

    // show hide back button
    // this.eventListners.push(
    //   this.es.listen<BackBtnData>(this.es.showHideBackBtn, data => {
    //     if (data.show) {
    //       this.showBackBtn = true;
    //     }
    //     this.backBtnClickFn = data.click;
    //   })
    // );

    // set title
    this.eventListners.push(this.es.listen<string>(this.es.app_setTitle, (title) => this.title = title));
    this.eventListners.push(this.es.listen<string>(this.es.app_setSubTitle, (subTitle) => this.subTitle = subTitle));

    // Obserable to control the shrinking of the top header bar.
    this.scrollDispatcher
      .scrolled()
      .pipe(map((event: CdkScrollable) => event.getElementRef().nativeElement.scrollTop))
      .subscribe(scrollTop => this.ngZone.run(() => (this.shrinkToolbar = scrollTop > this.SHRINK_TOP_SCROLL_POSITION ? true : false)));
    this.getWindowSize();
    this.getFixViewPort();

    const param = {};
    const pData = { size: 10, offset: 0, full: false };

    this.store.dispatch(new AllSparePartsRequested({ pData, param }));
    this.store.select(SparePartState.selectAllSpareParts)
      .pipe(map(clientFilterFn => clientFilterFn({ ...param }))).subscribe(_sparePart => {
        this.allSpareParts = _sparePart;
      });
      this.sub.push(this.store.select(CmsState.selectPageEditMode).subscribe(m =>{
        this.mAreaHeight = m ? 125 : 0;
      }));

  }

  ngAfterViewInit(): void {
    // on search input change
    if (this.searchTextRef) {
      fromEvent(this.searchTextRef.nativeElement, 'keyup')
        .pipe(
          debounceTime(500),
          distinctUntilChanged()
        ).subscribe(
          value => {
            this.searchTextCount = this.searchTextRef.nativeElement.value.length;
            logger.log(this.allSpareParts, 'allSpareParts:::');
            if (this.searchTextCount > 1) {
              this.searchResults = this.allSpareParts?.filter(_data => (_data?.name).toLowerCase() === this.searchTextRef.nativeElement.value.toLowerCase());
              if (this.searchResults?.length === 0) {
                this.searchText = 'No result found for your search';
              }
            } else {
              this.searchText = 'You must enter at least 2 characters.';
            }
          }
        );
    }
  }


  ngOnDestroy(): void {
    cleanListeners(this.eventListners, this.sub);
  }

  private updateShowHideBackbutton() {
    this.showBackBtn = this.showBackBtnCustom || this.showBackBtnRouter;
  }

  handleClose() {
    // only close in the handset mode.
    if (this.isHandset) {
      this.drawer.close();
    }
  }

  async handleBackBtn() {
    if (this.showBackBtnCustom) {
      this.es.emit<any>(this.es.menuBackBtnClickCust, null);
    } else {
      // Go back to the history.
      // @param router to be used for changing the url from history.
      // Note: In past, it was used to be optional and router in the router State was used.
      // that was throwing a warning. So now, routing is handled right in the base class and 
      // state is just used to update/keep the history.
      const url = this.store.selectSnapshot(RouterState.navigateBackUrl); //  =>
      if (!!url) {
        this.router.navigateByUrl(url);
      }
    }
  }

  save() { this.es.emit<boolean>(this.es.menuFireSaveCancel, true); }
  cancel() { this.es.emit<boolean>(this.es.menuFireSaveCancel, false); }
  edit() { this.es.emit<boolean>(this.es.menuFireEdit, true); }
  next() { this.es.emit<boolean>(this.es.menuFireNextCancel, true); }
  search() { this.es.emit<boolean>(this.es.menuFireSearch, true); }
  async logout() {
    this.handleClose();
    await this.as.logout();
    this.router.navigate(['/']);
  }

  public getFilterArray(m: MenuData[]) {
    let o: MenuData[] = [];
    if (!this.isAuth) {
      o = m.filter(a => a.auth === 'anonymous');
    } else {
      if (this.isAdmin) {
        o = m.filter(a => true).filter(a => a.auth !== 'operation');  // this.router.url !== a.url
      } else if (this.isEditor) {
        o = m.filter(a => (a.auth === 'anonymous' || a.auth === 'auth' || a.auth === 'editor')).filter(a => a.auth !== 'operation');
      } else if (this.isSalesOperationDpc) {
        o = m.filter(a => (a.auth === 'anonymous' || a.auth === 'operation'));
      } else {
        // authenticated.
        o = m.filter(a => (a.auth === 'anonymous' || a.auth === 'auth'));  // && this.router.url !== a.url
      }
    }
    return o;
  }

  initMenu() {
    this.menuFilt = this.getFilterArray(this.menu);
  }

  buldSubMenu(m: MenuData[]) {
    this.subMenuFilt = this.getFilterArray(m);
    setTimeout(() => {
      this.isLeftVisible = false;
    }, 100);
  }

  hideMenu() {
    this.isLeftVisible = !this.isLeftVisible;
  }

  navigateBack() {
    this.isLeftVisible = !this.isLeftVisible;
  }

  /** Used in template binding */
  getLinkObj(x) {
    return { x };
  }

  togglePageEdit() {
    this.store.dispatch(new CmsTogglePageEdit());
    logger.log('toggle edit mode was called!');
    this.handleClose();
    return false;
  }

  /** Get an object to be passed to router query param, rb => record back button navigation. */
  get rb() { return { queryParams: { rb: true } }; }

  /** Alert showing managment. */
  getBadgeColor() {
    const isUrlTask = this.router.url.includes('/task') ? true : false;
    return this.showNotification ? 'warn' : !isUrlTask ? 'accent' : null;
  }
  getdefaultCompany() {
    // this.store.dispatch(new UserByIdRequested({ id: this.uid }));
    this.sub.push(this.store.select(AuthState.user)
      .subscribe(u => {
        if (u) {
          logger.log('[shell] user', u);
          this.dComp = u.setting.defaultCompany;
          if (!!u.cRolesAll && Object.keys(u.cRolesAll).length > 1) {
            this.isMultipleComp = true;
          }
        }
      }));
  }
  toAccount() {
    this.router.navigate(['account']);
  }
  // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
  getWindowSize() {
    if (this.us.isPlatformBrowser) {

      // We listen to the resize event
      // window.addEventListener('resize', () => {
      // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
      const vh = window.innerHeight * 0.01;
      // Then we set the value in the --vh custom property to the root of the document
      document.documentElement.style.setProperty('--vh', `${vh}px`);
      // });
    }
  }
  getFixViewPort() {
    logger.log(`[Shell] this.platform.is('cordova'): ${this.platform.is('cordova')}`);
    logger.log(`[Shell] this.platform.is('android'): ${this.platform.is('android')}`);
    if (this.platform.is('cordova') || this.platform.is('android')) {
      this.isFixViewPort = true;
      logger.log(`[Shell] isFixViewPort: ${this.isFixViewPort}`);
    } else {
      this.isFixViewPort = false;
      logger.log(`[Shell] isFixViewPort: ${this.isFixViewPort}`);
    }

  }

  openMenu(e, i: number) {
    e.stopPropagation();
    this.showSubMenu = -1;
    this.showSubSubMenu = -1;
    this.showMenu = this.showMenu == i ? -1 : i;
  }
  openSubMenu(e, j: number) {
    e.stopPropagation();
    this.showSubSubMenu = -1;
    this.showSubMenu = this.showSubMenu == j ? -1 : j;
  }
  openSubSubMenu(e, k: number) {
    e.stopPropagation();
    this.showSubSubMenu = this.showSubSubMenu == k ? -1 : k;
  }
  homeClick() {
    this.showMenu = -1;
    this.showSubMenu = -1;
    this.showSubSubMenu = -1;

  }

  /**
   * purpose - opens the search DPC popup
   */
  openDPCSearchPopup() {
    const dialogRef = this.dialog.open(DpcGlobalSearchPopupComponent, {
      width: '100vw',  //setting width of the popup
      maxWidth: '100vw',  //setting max width of the popup
      // minHeight: '40vw',
      position: { top: '5px' } //setting position of the popup
    });

    dialogRef.afterClosed().subscribe(result => {
      logger.log(`Dialog result: ${result}`);
    });
  }
  login(){
    this.router.navigate(
      ['/login'],
      { queryParams: { site: 'dpc' } }
    );
  }
}
