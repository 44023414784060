import { Observable, Subject } from 'rxjs';
import { logger } from '@trent/models/log/logger';
import { fireProject } from '@trent/core/environments/fb-env';
import { BucketName } from '@trent/models/server-storage/i-strorage-metadata';
import { getDownloadURL, getStorage, ref, Storage, StorageReference, uploadBytesResumable, UploadMetadata, getMetadata } from '@angular/fire/storage';

/**
 * Angular Universal require this to be explicitly define it. Otherwise, it was not needed.
 */
declare type TaskState = 'notScheduled' | 'scheduling' | 'scheduled' | 'running' | 'canceling' | 'unknown';

/** Utility class for uploading the files using Firebase Storage. */
export class FileUploadSingle {

  private percentageSub: Subject<number>;

  /** end user client when subscribe to this will start the downloads. provided
   * pre upload is called before subscribing.*/
  percentage$: Observable<number>;

  buildDownloadUrl = true;

  errorStr = null;

  downloadUrl: string;
  downloadMetadata: any;

  /** File File name to be modified by appending a time string in the beginning.
   * This is to ensure there is no duplicate overwrite and file name remains unique. */
  prefixTime = true;

  // Dispatcher of the outcome of results. true if success, false if there is an error
  private uploadResultSub: Subject<boolean>;
  uploadResult$: Observable<boolean>;

  file: File;

  finalPath: string;

  storageRef: StorageReference;


  constructor(private storage: Storage) {
    this.uploadResultSub = new Subject();
    this.uploadResult$ = this.uploadResultSub.asObservable();
    this.percentageSub = new Subject();
  }


  /** Set up the pre-upload variable.
   * @param cBucketPredicate | Custom Bucket Predicate e.g. rental-rules-access (for lloop-debug-rental-rules-access)
   * @package fileName | File name specified by calling function
  */
  prepUpload(path: string, f: File, bname: BucketName = 'appspot.com', metadata?: UploadMetadata, fileName?: string) {
    this.file = f;
    if (this.file == null) {
      this.uploadResultSub.next(false);
      throw Error(`Invalid File was specified to upload! File: ${this.file}`);
    }
    logger.log('upload file was called on path', path);
    // The storage path
    if (!fileName) {
      this.finalPath = (this.prefixTime) ? `${path}/${new Date().getTime()}_${this.file.name}`
        : `${path}/${this.file.name}`;
    } else {
      this.finalPath = `${path}/${fileName}`;
    }
    const storage = getStorage(this.storage.app, `${fireProject.projectId}.${bname}`);
    this.storageRef = ref(storage, this.finalPath);


    // const b = this.storage.app.storage(`${fireProject.projectId}.${bname}`);  // getBucket(bname, fireProject.projectId);
    // this.storageRef = b.ref().child(this.finalPath);
    
    this.percentage$ = this.percentageSub.asObservable();
    this.startUploadJS(metadata);
  }

  startUploadJS(metadata: UploadMetadata) {
    const uploadTask = uploadBytesResumable(this.storageRef, this.file, metadata);
    // Register three observers:
    // 1. 'state_changed' observer, called any time the state changes
    // 2. Error observer, called on failure
    // 3. Completion observer, called on successful completion
    uploadTask.on('state_changed', async (snapshot) => {
      // Observe state change events such as progress, pause, and resume
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      this.percentageSub.next(progress);
      console.log('Upload is ' + progress + '% done');
      switch ( snapshot.state?.toLowerCase() as TaskState) {
        case 'paused' as TaskState: // or 'paused'
          console.log('Upload is paused');
          break;
        case 'running': // or 'running'
          console.log('Upload is running');
          break;
        case 'success' as TaskState:

          break;
        case 'error' as TaskState:
          logger.error(`[file-upload] File uploaded errored!: ${this.file.name}`);
          this.completeUpload(false);
          break;
        case 'canceled' as TaskState:
          logger.error(`[file-upload] File uploaded cancelled!: ${this.file.name}`);
          this.completeUpload(false);
          break;
      }
    }, (error: any) => {
      // FAILED
      logger.error(`[file-upload] File uploaded errored!: ${this.file.name}`);
      switch (error.code) {
        case 'storage/unauthorized':
          logger.error('[file-upload] User does not have required permission', error);
          break;
        case 'storage/canceled':
          logger.error('[file-upload] User cancelled the upload', error);
          break;
        case 'storage/unknown':
          logger.error('[file-upload] Unknown error', error);
          break;
      }
      this.completeUpload(false);
    }, async () => {
      // SUCCESS
      logger.log(`[file-upload] File uploaded successfully: ${this.file.name}`);
      logger.log('upload finalize called');
      if (this.buildDownloadUrl) {
        try {
          this.downloadUrl = await getDownloadURL(uploadTask.snapshot.ref); // .getDownloadURL();
          logger.log('[File-Upload] File available at', this.downloadUrl);
          this.downloadMetadata = await getMetadata(uploadTask.snapshot.ref); // .getMetadata();
        } catch (error) {
          logger.log('[File-Upload] File uploaded but Download URL failed', error);
        }
        logger.log('Download URL: ', this.downloadUrl);
        logger.log('downloadMetadata: ', this.downloadMetadata);
      }
      this.completeUpload(true);
    });
  }
  // __startUploadJS(metadata: UploadMetadata) {
  //   const uploadTask = this.storageRef.put(this.file, metadata);
  //   // Register three observers:
  //   // 1. 'state_changed' observer, called any time the state changes
  //   // 2. Error observer, called on failure
  //   // 3. Completion observer, called on successful completion
  //   uploadTask.on('state_changed', async (snapshot) => {
  //     // Observe state change events such as progress, pause, and resume
  //     // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
  //     const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
  //     this.percentageSub.next(progress);
  //     console.log('Upload is ' + progress + '% done');
  //     switch (snapshot.state) {
  //       case firebase.storage.TaskState.PAUSED: // or 'paused'
  //         console.log('Upload is paused');
  //         break;
  //       case firebase.storage.TaskState.RUNNING: // or 'running'
  //         console.log('Upload is running');
  //         break;
  //       case firebase.storage.TaskState.SUCCESS:

  //         break;
  //       case firebase.storage.TaskState.ERROR:
  //         logger.error(`[file-upload] File uploaded errored!: ${this.file.name}`);
  //         this.completeUpload(false);
  //         break;
  //       case firebase.storage.TaskState.CANCELED:
  //         logger.error(`[file-upload] File uploaded cancelled!: ${this.file.name}`);
  //         this.completeUpload(false);
  //         break;
  //     }
  //   }, (error: any) => {
  //     // FAILED
  //     logger.error(`[file-upload] File uploaded errored!: ${this.file.name}`);
  //     switch (error.code) {
  //       case 'storage/unauthorized':
  //         logger.error('[file-upload] User does not have required permission', error);
  //         break;
  //       case 'storage/canceled':
  //         logger.error('[file-upload] User cancelled the upload', error);
  //         break;
  //       case 'storage/unknown':
  //         logger.error('[file-upload] Unknown error', error);
  //         break;
  //     }
  //     this.completeUpload(false);
  //   }, async () => {
  //     // SUCCESS
  //     logger.log(`[file-upload] File uploaded successfully: ${this.file.name}`);
  //     logger.log('upload finalize called');
  //     if (this.buildDownloadUrl) {
  //       try {
  //         this.downloadUrl = await uploadTask.snapshot.ref.getDownloadURL();
  //         logger.log('[File-Upload] File available at', this.downloadUrl);
  //         this.downloadMetadata = await uploadTask.snapshot.ref.getMetadata();
  //       } catch (error) {
  //         logger.log('[File-Upload] File uploaded but Download URL failed', error);
  //       }
  //       logger.log('Download URL: ', this.downloadUrl);
  //       logger.log('downloadMetadata: ', this.downloadMetadata);
  //     }
  //     this.completeUpload(true);
  //   });
  // }

  private completeUpload(success: boolean) {
    this.uploadResultSub.next(success);
    this.uploadResultSub.complete();

    this.percentageSub.next(success ? 100 : 0);
    this.percentageSub.complete();
  }
}
