import { ILocalStorage } from '../local-storage/local-storage';
import { PageMetaTag } from './page-meta-tag';
import { keys, isDate, isNil, isObject } from 'lodash';
import { BaseModel } from '../base';
import { Exclude, Expose, plainToInstance, instanceToInstance } from 'class-transformer';
import { promiseWraper } from '../utility';
import { logger } from '../log/logger';
import { globalToInstance, sectionDef } from './page-html-decorator';
import { ValidatorOptions } from 'class-validator';
import { IValidationMsg } from '../error-handling';

export interface PageHtmlSection {
  type: 'html' | 'string' | 'number' | 'date' | 'string-array' | 'boolean' | 'video-yt' | 'meta-tag-array';
  order?: number;
  tag?: string;
  header?: string;
  val?: string;
}

/**
 * Important, If you are extending this enum by adding a new class that extends the 
 * @PageHtml make sure you update @parseCms and @getPageHtmlClazz functions in the file
 * ./cms-helper
 */
export enum PageHtmlType{
  pageHtml =1,
  contract =2,
  taskMessaging=3,
  ptsLocation = 99,
  ptsHomeReviews = 100,
  ptsHomeCarousel = 101,
  ptsHomeMiddleSection = 102,
  ptsWelcome = 103,
  dpcHome = 104,
  tprHome = 110,
  tprContact = 111,
  tprRentOptionList = 112,
  tprBidDetail = 113,
  ptsInventoryList = 105,
  ptsSalesOptionDetail = 106,
  ptsHome = 107,
  ptsContactUs = 108,
  ptsAboutUs = 109,
}

/** used to properly fetch the html data from the database.  */
export interface PageHtmlTemplate {
  pid: string;
  pageType?: PageHtmlType;
  // col?: string;
}


/** dynamic html Html content on a given page.  */
@Exclude()
export class PageHtml extends BaseModel {

  public static readonly collectionName = 'page-html';

  @Expose()
  pageHtmlType?:PageHtmlType;

  @Expose()
  @sectionDef({ type: 'string', order: 1})
  title: string;

  /** SEO: Description will be injected in the meta-tag on SSR. */
  @Expose()
  @sectionDef({ type: 'string', order: 2})
  description: string;

   @Expose()
   @sectionDef({ type: 'string', order: 2})
   descriptionTemp: string;

  /** Store metatags. Do not include title and  description as they will be taken from page properties. */
  @Expose()
  @sectionDef({ type: 'meta-tag-array', order: 5})
  metaTags: Array<PageMetaTag> = [];

  /** Html sections by the name. sections are not in an order */
  @Expose()
  sections: { [anchor: string]: PageHtmlSection };

  /** true if document needs revision control */
  @Expose()
  isRevControlled: boolean;
  /** agreement type e.g. privacy policy. applicable only when rev controlled*/
  @Expose()
  isAgreement?: boolean;

  /** auto section will keep the track of all custom properties that have @sectionDef attribute applied to them.
   * This is used for auto generating the html control in the cms page. This value will NOT be saved in the database. */
  autoSection: Record<string, PageHtmlSection> = {};


  /** Ordered sections with the full document map useful to display the whole page in an ordered fashion*/
  get sectionsOrdered(): PageHtmlSection[] {
    const a: Array<PageHtmlSection> = [];
    for (const key in this.sections) {
      if (this.sections.hasOwnProperty(key)) {
        const element = { ...this.sections[key] };
        element.tag = key;
        a.push(element);
      }
    }
    a.sort((x, y) => x.order - y.order);
    return a;
  }

  get keysOrdered(): string[] {
    return this.sectionsOrdered.map(x => x.tag);
  }

  constructor() {
    super();
    globalToInstance(PageHtml, this);
  }

  /** Time when the record was fetched from db at client. No need to store this in the firebase */
  dbFetchTime: Date;

  public static parse<T extends PageHtml>(obj: any, clazz?: new () => T): T {
    if (obj == null) { return null as any; }

    const m = (!!clazz) ? plainToInstance<T, any>(clazz, obj) : plainToInstance(PageHtml, obj);
    m.sanitize();
    /** reassign the keys. */
    if(isObject(m.sections)) {
      Object.keys(m.sections).forEach(k => m.sections[k].tag = k);
    };

    return m as T;
  }

  public static sanitizeObj(obj: PageHtml) {
    if (!!obj.dbFetchTime) {
      obj.dbFetchTime = new Date(obj.dbFetchTime);
    }
  }

  static async saveToStorage(d: PageHtml, storage: ILocalStorage) {
    storage.set(`html-${d.id}`, d);
  }

  static async getFromStorage(id: string | number, storage: ILocalStorage) {
    const p = await promiseWraper(storage.get(`html-${id}`));
    if (p.success && !!p.data) {
      const data: PageHtml = p.data;
      PageHtml.sanitizeObj(data);
      // only use this data if it was loaded no later then at least 10 min ago
      if (isDate(data.dbFetchTime) && (new Date().getTime() - data.dbFetchTime.getTime()) < 10 * 60 * 1000) {
        logger.log(`cms storage] data with ID: ${id}:  was served from local cache.`);
        return data;
      }
    }
    return null;
  }

  /** update the section name/keys if use has updated it. */
  public syncTagChanges() {
    const changes: { old: string; new: string }[] = [];
    const currKeys = Object.keys(this.sections);
    for (const k of currKeys) {
      const ele = this.sections[k];
      if (ele.tag == null) {
        ele.tag = k;
      }
      ele.tag = ele.tag.replace('#', '').trim();

      if (k !== ele.tag) {
        // tag modified, it must be unique.
        if (!!this.sections[ele.tag]) {
          throw Error(`Invalid Tag "${ele.tag}" is already been used. Please change this tag`);
        }
      }
      changes.push({ old: k, new: ele.tag });
    }

    // ensure new keys to be unique, i.e no duplicates. 
    const newKeys = changes.map(x => x.new);
    const duplicates = newKeys.filter((item, idx) => newKeys.indexOf(item) !== idx);
    if (duplicates.length > 0) {
      throw Error(`New tags are not unique. Duplicates are: ${JSON.stringify(duplicates)}`);
    }

    const newSections: { [anchor: string]: PageHtmlSection } = {};
    for (let i = 0; i < changes.length; i++) {
      const ele = changes[i];
      newSections[ele.new] = this.sections[ele.old];
    }
    this.sections = newSections;
  }

  sanitize() {
    super.sanitize();    
    this.fixLegacySection(this.sections);
  }

  clone() {
    const t = instanceToInstance(this);
    t.sanitize();
    t.id = this.id;
    return t;
  }
  
  validateSync(options?: ValidatorOptions): IValidationMsg {

    // for nested entry for address, add address group in it.
    // if (!!options && !!options.groups && options.groups.indexOf(legal) > -1) {
    //   options.groups.push(Address.gName);
    //   options.groups.push(ContactCard.gName);
    // }
    // if (!!options && !!options.groups && options.groups.indexOf(privateG) > -1 
    //     &&  (options.groups.indexOf(officer1Id) > -1 || options.groups.indexOf(officer2Id) > -1 )) {
    //   options.groups.push(ContactCard.photoId);
    // }
    const r = this.validateSyncBase(this, options);
    return r;
  }

  public toFirebaseObj(timeStampCreate?: boolean, getGeoPointFn?: (obj: any) => any, ignore?: string[]) {
    const obj: PageHtml = super.toFirebaseObj(timeStampCreate, getGeoPointFn, ignore);

    if (!!obj && !!obj.sections) {
      // remove the tag as it is not needed in the db.
      Object.values(obj.sections).forEach(x => delete x['tag']);
    }
    return obj;
  }


  getSection(k) { return this.sections[k]; }

  isEqualTo(p: PageHtml): boolean {

    if (this.title !== p.title) { return false; }

    if (this.description !== p.description) { return false; }

    const k = keys(this.sections);

    const pk = keys(p.sections);

    if (k.length !== pk.length) { return false; }

    for (let i = 0; i < k.length; i++) {
      const ele = this.sections[k[i]];
      const eleP = p.sections[k[i]];
      if (ele !== eleP) { return false; }
      // if (ele.tagTitle !== eleP.tagTitle) { return false; }
      // if (ele.content !== eleP.content) { return false; }
    }

    return true;
  }

  private getTagNo(): number {
    let c = Math.floor(Math.random() * 1000);
    while (!!this.sectionsOrdered[`#ptag${c}`]) {
      c = Math.floor(Math.random() * 1000);
    }
    return c;
  }

  insert(idx: number, t:PageHtmlSection['type'] = 'html') {
    if (this.sections == null) {
      this.sections = {};
    }
    const xKeys = Object.keys(this.sections);
    if (idx >= 0 && idx < xKeys.length + 1) {
      const n = this.getTagNo();
      const newS: PageHtmlSection = {
        type: t,
        order: idx,
        tag: `tag${n}`,
        header: '?',
        val: `<p>This is Sample text for ${n}. Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
        Maecenas posuere justo a pretium elementum. Proin in metus orci. Donec vel urna ac risus venenatis
        gravida vel ultrices lorem. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ornare nisi 
        eu at eleifend turpis</p>`
      };
      for (const key of xKeys) {
        if (this.sections[key].order >= idx) {
          ++this.sections[key].order;
        }
      }
      this.sections[newS.tag] = newS;
    }
  }

  // delete(idx: number) {
  //   const _keys = Object.keys(this.sections);
  //   if (idx >= 0 && idx < _keys.length + 1) {
  //     let delKey = null;
  //     for (const key of _keys) {
  //       const ele = this.sections[key];
  //       if (ele.order === idx) {
  //         delKey = key;
  //       } else if (ele.order >= idx) {
  //         --this.sections[key].order;
  //       }
  //     }
  //     delete this.sections[delKey];
  //   }
  // }

  /** Old sections had a property .text that was renamed to .val. Database content is still at text. so copy. */
  fixLegacySection(sec: Record<string, PageHtmlSection>)  {
    for (const key in sec) {
      if (Object.prototype.hasOwnProperty.call(sec, key)) {
        const element = sec[key];
        if(isNil(element.val) && !isNil((element as any).text)) {
          element.val = (element as any).text;
          delete (element as any)['text']; //  = 'legacy N/A';
        }

      }
    }
  }
}