import { BaseModel } from '../base/base-model';
import { Exclude, Expose, instanceToInstance, plainToInstance } from 'class-transformer';
import { IPoint } from '../product-search/interfaces';
import { sanitizeDateIPoint } from '../utility';
import { findDistance, randomIPointInCircle, randomIPointBetweenTwoCircles } from '../utility/distf';
import { isAfter, addMinutes, addHours } from 'date-fns';
import { MapLabel } from '../map/map-label';
import { TransactionStatus } from './transaction-status';
import { TripBase, TripValidationGroup } from './trip-base';
import { Length, Max, Min, ValidatorOptions, validateSync } from 'class-validator';
import { IValidationMsg } from '../error-handling';
import { TripTransaction } from './trip-transaction';
import { TripStatus } from './trip-status';
import { isEqual, isEmpty } from 'lodash';
import { LocStaticMap } from './loc-static-map';
import { LocationData } from '../location/loc-data';
import { TripActivityType } from './trip-activity-type';
import { logger } from '../log/logger';

export interface ILocation {
  g: string; l: { 0: number, 1: number }; locTimeStamp: number; addedAt?: number;
}
export interface ILocCdva {
  deviceId: string; userId: string | number; latitude: number; longitude: number; time: Date; speed: number;
}

@Exclude()
export class TripLocation extends BaseModel {


  public static readonly collectionName = 'gps-data'; // 'trip-location';

  // @Expose()
  locRealDb?: { [key: string]: ILocation };

  /** depricated */
  location?: { [key: string]: { geohash: string, iPoint: IPoint, locTimeStamp: Date | number, tripId?: string | number } };


  // @Expose()
  driverId?: number | string;

  @Expose()
  @Length(1, 50)
  deviceId: string;

  @Expose()
  @Length(1, 50)
  userId: string | number;

  @Expose()
  @Max(90)
  @Min(-90)
  latitude: number;

  @Expose()
  @Max(180)
  @Min(-180)
  longitude: number;

  @Expose()
  @Min(0)
  time: number;

  @Expose()
  // @Min(0)
  speed: number;
  @Expose()
  usrDevId: string;
  @Expose()
  altitude: number;
  @Expose()
  bearing: null;
  @Expose()
  locationProvider: number;
  @Expose()
  accuracy: number;
  @Expose()
  provider: string;
  @Expose()
  radius: number;
  @Expose()
  geohash: string;
  @Expose()
  tripId: string | number;

  constructor() {
    super();

  }


  public static parse(obj) {
    try {
      if (obj == null) { return null; }
      // obj = sanitizeDateIPoint(obj, ipointType);
      obj = sanitizeDateIPoint(obj);
      const m = plainToInstance<TripLocation, any>(TripLocation, obj);
      m.sanitize();
      return m;
    } catch (error) {
      logger.log('Error happened during parse', error);
      return null;
    }
  }
  public static parseTripLocationArray = (obj: any[] | {}): TripLocation[] => {
    let locs: TripLocation[];
    if (Object.keys(obj[Object.keys(obj)[0]])[0] === '0') {
      locs = TripLocation.flattenToArray(<any>obj);
    } else {
      locs = <[]>obj;
    }
    // const r = !!obj ? obj.map(o => TripLocation.parse(o)) : null;
    const r = !!locs ? locs.map(o => TripLocation.parse(o)) : null;
    return r;
  }
  /**
   * Updates trip based on the GPS location data. Returns
   * @param updatedTrip returns Updated trip
   * @param updateReq returns Is Updated required
   * @param uTransWMap returns updatedTransaction with map metadata
   * @param trip TripBase
   * @param tripLoc TripLocation[]
   * @param locRadLimitIn min (inner) loc radius (km) used to decidie if truck is departed/entered from its location.
   * @param locRadLimitOut max (outer) loc radius (km) used to decidie if truck is departed/entered from its location.
   */
  public static autoUpdateTripFn(trip: TripBase, tripLoc: LocationData[], locRadLimitIn: number = 0.2, locRadLimitOut = 1.0): {
    updatedTrip: TripBase, updateReq: boolean, uTransWMap: TripTransaction[],
  } {

    let uReq = false; // update required
    const transCompleted = TripLocation.transactionsCompleted(trip);
    const uTrip = trip.clone(); // updated trip
    const uTransWMap: TripTransaction[] = [];
    // loop thru trip seqquence
    for (let i = 0; i < (uTrip.tripSequence).length; i++) {
      const ele = uTrip.tripSequence[i];
      let eleFirst: TripTransaction;
      let eleLast: TripTransaction;
      if (!uTrip.isOneWay) {
        eleFirst = uTrip.tripSequence[0];  // first trip transaction for the return trip
        eleLast = uTrip.tripSequence[uTrip.tripSequence.length - 1];  // last trip transaction for the return trip
      }
      // loop thru location array
      let enteredAt: Date;
      for (let j = 0; j < tripLoc.length; j++) {
        const loc = tripLoc[j];
        // find distance between reported gps point and trip location
        const d = findDistance(loc.latitude, loc.longitude, ele.address.geoLoc.geopoint.latitude,
          ele.address.geoLoc.geopoint.longitude, 'km');
        // if gps point is in 100 m radius then record the point in the trip location
        if (d < locRadLimitIn) {
          ele.gpsData = !ele.gpsData || ele.gpsData.length === 0 ? [] : ele.gpsData;
          // restrict to maximum 20 points
          if (ele.gpsData.length <= 20) {
            ele.gpsData.push({ iPoint: { latitude: loc.latitude, longitude: loc.longitude }, timeStamp: new Date(loc.createdOn) });
          } else {
            // add code to remove last and add latest TODO: SS
          }
          // sort to get first entery based on timestamp
          ele.gpsData.sort((a, b) => a.timeStamp.valueOf() - b.timeStamp.valueOf());
          enteredAt = ele.gpsData[0].timeStamp;
          // if location is not marked as entered then update
          if (!ele.autoCompleteTime || !ele.autoCompleteTime.enteredAt) {
            // for return trip last tranaction is handled seprately because address for start and end is same for return trips
            if (!!uTrip.isOneWay || i !== uTrip.tripSequence.length - 1) {
              ele.autoCompleteTime = <any>{};
              ele.autoCompleteTime.enteredAt = enteredAt;
              ele.transactionStatus = TransactionStatus.arrived;
              // set trip in process
              // if (uTrip.tripStatus !== TripStatus.inProcess) { uTrip.tripStatus = TripStatus.inProcess; }
              logger.log('enteredAt', ele.autoCompleteTime.enteredAt);
              logger.log('update tripData enteredAt', uTrip.tripSequence[i]);
              // for one way trip if last acitivity is drop, set transaction to autocompleted and trip status closed
              if (i === uTrip.tripSequence.length - 1 && ele.activity === TripActivityType.drop) {
                ele.transactionStatus = TransactionStatus.autoCompleted;
                uTrip.tripStatus = TripStatus.closed;
              }
              // For return trip last tranaction update only if 1 or more transactions are completed.
              // For last location on return trip, mark transaction status as 'autocomplete' upon entry instead marking 'arrived'.
              // And Close the trip
            } else if (transCompleted.length > 0 && uTrip.tripSequence[0].transactionStatus > TransactionStatus.arrived) {
              ele.autoCompleteTime = <any>{};
              ele.autoCompleteTime.enteredAt = enteredAt;
              // ele.transactionStatus = TransactionStatus.arrived;

              ele.transactionStatus = TransactionStatus.autoCompleted;
              uTrip.tripStatus = TripStatus.closed;

              logger.log('enteredAt', ele.autoCompleteTime.enteredAt);
              logger.log('update tripData enteredAt', uTrip.tripSequence[i]);
            }
          }
          uReq = true;
          // if location is marked as entered and left is not marked leftAt then update
        } else if (d > locRadLimitIn && d < locRadLimitOut && !!ele.autoCompleteTime &&
          !!ele.autoCompleteTime.enteredAt && !ele.autoCompleteTime.leftAt) {
          // for return trip last tranaction is handled seprately because address for start and end is same for return trips
          if (!!uTrip.isOneWay || i !== uTrip.tripSequence.length - 1) {
            ele.autoCompleteTime.leftAt = new Date(loc.createdOn);
            ele.transactionStatus = TransactionStatus.autoCompleted;
            // for return trip last tranaction update only if 1 or more transactions are completed
            // ***** do i need this code for return trip, it marked closed upon entry
          } else if (transCompleted.length > 0 || uTrip.tripSequence[0].transactionStatus > TransactionStatus.arrived) {
            ele.autoCompleteTime.leftAt = new Date(loc.createdOn);
            ele.transactionStatus = TransactionStatus.autoCompleted;
            uTrip.tripStatus = TripStatus.closed;
          }
          uReq = true;
          logger.log('update tripData leftAt', uTrip.tripSequence[i]);
        } else {
          uReq = false;
          logger.log('no update required');
        }
        if (!!uReq) {
          // get map param for waiting time report
          const pic = new LocStaticMap();
          ele.locStaticMap = pic.createLocMapImage(trip.id, ele);
          if (!!ele.locStaticMap) {
            uTransWMap.push(ele);
          }
        }
      }
    }
    return { updatedTrip: uTrip, updateReq: uReq, uTransWMap: uTransWMap };
  }
  
  /**
 * @deprecated Updates trip based on the GPS location data. Returns
 * @param updatedTrip returns Updated trip
 * @param updateReq returns Is Updated required
 * @param uTransWMap returns updatedTransaction with map metadata
 * @param oTrip TripBase
 * @param tripLoc TripLocation[]
 * @param locRadLimitIn min (inner) loc radius (km) used to decidie if truck is departed/entered from its location.
 * @param locRadLimitOut max (outer) loc radius (km) used to decidie if truck is departed/entered from its location.
 */
  public static gpsAutoUpdateTripFn(oTrip: TripBase, tripLoc: LocationData[], locRadLimitIn: number = 0.2, locRadLimitOut = 2.0): {
    updatedTrip: TripBase, updateReq: boolean, uTransWMap: TripTransaction[],
  } {

    let uReq = false; // update required
    const transCompleted = TripLocation.transactionsCompleted(oTrip);
    const uTrip = oTrip.clone(); // updated trip
    const uTransWMap: TripTransaction[] = [];
    for (let gx = 0; gx < tripLoc.length; gx++) {
      const gLoc = tripLoc[gx];
      for (let ix = 0; ix < uTrip.tripSequence.length; ix++) {
        const elem = uTrip.tripSequence[ix];
        if (elem.transactionStatus >= TransactionStatus.autoCompleted) {
          continue;
        }
        // if current transaction is first and any subsequenct transactions are complete then consider it missed
        if (ix === 0 && uTrip.tripSequence.findIndex((f, j) => j > ix && f.transactionStatus >= TransactionStatus.arrived) > -1) {
          continue;
        }
        // find consecutive
        let consecutive: TripTransaction;
        if (ix < uTrip.tripSequence.length - 1 && uTrip.tripSequence[ix + 1].address.geoLoc.geohash === elem.address.geoLoc.geohash) {
          consecutive = uTrip.tripSequence[ix + 1];
        }
        // find all duplicate address to current tranactions (except for consecutive next)
        const allDuplicates: TripTransaction[] = uTrip.tripSequence.filter((f, i) => ix < uTrip.tripSequence.length - 1 &&
          i !== ix && i !== ix + 1 && f.address.geoLoc.geohash === elem.address.geoLoc.geohash);
        // if any of the subsequent (except for consecutive next) transaction
        // is marked arrived or higher then current transaction is consdered missed. So skip and break
        if (allDuplicates.findIndex((f) => f.transactionStatus >= TransactionStatus.arrived) > -1) {
          continue;
        }

        // find distance between reported gps point and trip location
        const dist = findDistance(gLoc.latitude, gLoc.longitude, elem.address.geoLoc.geopoint.latitude,
          elem.address.geoLoc.geopoint.longitude, 'km');
        elem.gpsData = !elem.gpsData || elem.gpsData.length === 0 ? [] : elem.gpsData;

        // if distance is less than inner rad and transaction is not autoCompleted
        if (dist < locRadLimitIn && elem.transactionStatus < TransactionStatus.autoCompleted) {
          // restrict to maximum 20 points
          if (elem.gpsData.length < 20) {
            elem.gpsData.push({ iPoint: { latitude: gLoc.latitude, longitude: gLoc.longitude }, timeStamp: new Date(gLoc.createdOn) });
          }
          elem.gpsData.sort((a, b) => a.timeStamp.valueOf() - b.timeStamp.valueOf());
          // set entered at
          if (elem.transactionStatus < TransactionStatus.arrived) {
            elem.autoCompleteTime = <any>{};
            elem.autoCompleteTime.enteredAt = elem.gpsData[0].timeStamp;
            if (ix === uTrip.tripSequence.length - 1) {
              uTrip.tripStatus = TripStatus.closed;
            }
            elem.transactionStatus = TransactionStatus.arrived;
          }
          // get map param for waiting time report
          const pic = new LocStaticMap();
          elem.locStaticMap = pic.createLocMapImage(uTrip.id, elem);
          if (!!elem.locStaticMap) {
            uTransWMap.push(elem);
          }
          // if duplicate address consecutive exists set autocomplete and transaction status
          if (!!consecutive) {
            consecutive.gpsData = elem.gpsData;
            consecutive.autoCompleteTime = elem.autoCompleteTime;
            consecutive.transactionStatus = elem.transactionStatus;
          }
          uReq = true;
          break;
          // if distance is greater than inner rad; And Transaction is not autocompleted
        } else if (dist > locRadLimitIn && elem.transactionStatus === TransactionStatus.arrived) {
          if (!!elem.gpsData && elem.gpsData.length > 0) {
            elem.autoCompleteTime['leftAt'] = elem.gpsData[elem.gpsData.length - 1].timeStamp;
          } else {
            elem.autoCompleteTime['leftAt'] = new Date(gLoc.createdOn);
          }
          elem.transactionStatus = TransactionStatus.autoCompleted;
          if (ix === uTrip.tripSequence.length - 1) {
            uTrip.tripStatus = TripStatus.closed;
          }
          // get map param for waiting time report
          const pic = new LocStaticMap();
          elem.locStaticMap = pic.createLocMapImage(uTrip.id, elem);
          if (!!elem.locStaticMap) {
            uTransWMap.push(elem);
          }
          // if duplicate address consecutive exists set autocomplete and transaction status
          if (!!consecutive) {
            consecutive.autoCompleteTime = elem.autoCompleteTime;
            consecutive.transactionStatus = elem.transactionStatus;
          }
          uReq = true;
          // break;
        } else {
          logger.log(`[gpsAutoUpdateTripFn] ${ix}, no update required`)
        }
      }
    }
    return { updatedTrip: uTrip, updateReq: uReq, uTransWMap: uTransWMap };
  }
  /** returned autoCompleted or manually completed transactions */
  public static transactionsCompleted(trip: TripBase): TripTransaction[] {
    const seq = trip.tripSequence;
    const filtered = seq.filter(f =>
      (f.transactionStatus === TransactionStatus.autoCompleted || f.transactionStatus === TransactionStatus.manuallyCompleted));
    return filtered;
  }
  /**
   * After autoComplete update ETA for the subsequent locations
   * @param uTrip Updated Trip
   * @param oTrip Orignal Trip
   */
  static updateETA(uTrip: TripBase, oTrip: TripBase) {
    // Find the transaction which is auto complete by comparing before and after
    // As more than one transaction could updated during the auto complete, get the last changed transaction
    const autoC: TripTransaction = uTrip.tripSequence.filter((f, i) =>
      (f.transactionStatus === TransactionStatus.arrived || f.transactionStatus === TransactionStatus.autoCompleted) &&
      (f.transactionStatus > oTrip.tripSequence[i].transactionStatus)).pop();
    const autoCIndex: number = uTrip.tripSequence.findIndex(f => isEqual(f, autoC));
    // Find the ETA for the subsequent locations based on the actual departure from the autocompleted location and using the driving time
    // assume travel time is same what was used during the trip schedule.

    // Find the subsequent transactions
    if (autoC && autoCIndex > -1) {
      const seq = uTrip.tripSequence;
      // Add driving duration, HOS hours and dwell duration to next location ETA
      for (let i = 0; i < seq.length; i++) {
        const ele = seq[i];
        if (i === autoCIndex + 1) {
          if (autoC.transactionStatus === TransactionStatus.autoCompleted) {
            ele.eta = addHours(autoC.autoCompleteTime.leftAt, (ele.drvDuration + ele.hos.offDuty));
          } else if (autoC.transactionStatus === TransactionStatus.arrived) {
            ele.eta = addHours(autoC.autoCompleteTime.enteredAt, (autoC.dwellDuration + ele.drvDuration + ele.hos.offDuty));
          }
        } else if (i > autoCIndex + 1) {
          ele.eta = addHours(seq[i - 1].eta, (seq[i - 1].dwellDuration + ele.drvDuration + ele.hos.offDuty));
        }
      }
    }
    return uTrip;
  }
  /**
   * mocks lat, long and time at the next open transaction on the trip.
   * order of simmulation,
   * 1: distance < .1 (arrived)
   * 2: distance < .1 (at the location)
   * 1: distance < .1 (at the location)
   * 1: distance > .1 < 1 (left the location)
   * @param trip TripBase
   */
  public static mockLocs(trip: TripBase): { lat: number, long: number, time: Date } {
    let latitude: number;
    let longitude: number;
    let time: Date;
    const firstOpenTrans = trip.tripSequence.find(f => f.transactionStatus >= TransactionStatus.scheduled &&
      f.transactionStatus < TransactionStatus.autoCompleted);
    if (!firstOpenTrans) {
      return null;
    }
    const p = firstOpenTrans.address.geoLoc.geopoint;
    const r1 = randomIPointInCircle(p, .05); // point in the circle
    const r2 = randomIPointInCircle(p, .1); // point in the circle
    const r3 = randomIPointInCircle(p, .1); // point in the circle
    let r4 = randomIPointInCircle(p, 0.9); // point in the circle
    let r4d = 0;
    while (r4d < .1) {
      r4 = randomIPointInCircle(p, 0.9); // point in the circle
      r4d = findDistance(r4.latitude, r4.longitude, p.latitude, p.longitude, 'km');
    }
    switch (firstOpenTrans.transactionStatus) {
      case TransactionStatus.scheduled:
        latitude = r1.latitude;
        longitude = r1.longitude;
        time = addMinutes(firstOpenTrans.arrivalTime, -15);
        break;
      case TransactionStatus.arrived:
        if (firstOpenTrans.gpsData.length <= 2) {
          latitude = r2.latitude;
          longitude = r2.longitude;
          time = addMinutes(firstOpenTrans.gpsData.pop().timeStamp, +15);
        } else {
          latitude = r4.latitude;
          longitude = r4.longitude;
          time = addMinutes(firstOpenTrans.gpsData.pop().timeStamp, +15);
        }
        break;
      default:
        break;
    }
    return { lat: latitude, long: longitude, time: time };
  }

  /**
   *  Mocks the arrival, waiting and depatrure for all the transactions to given @param tripSequence index
   * @param trip TripBase
   * @param index Trasaction Index(optional), in undefined takes index as the last location on the trip
   */
  static mockCompleteLoc(trip: TripBase, completeTillIndex?: number): { iPoint: IPoint, time: number }[] {
    const locs: { iPoint: IPoint, time: number }[] = [];
    completeTillIndex = completeTillIndex == null ? trip.tripSequence.length - 1 : completeTillIndex;
    for (let i = 0; i < (completeTillIndex + 1); i++) {
      const l = trip.tripSequence[i].address.geoLoc.geopoint;
      const t = trip.tripSequence[i].arrivalTime;
      for (let j = 0; j < 4; j++) {
        let p: IPoint;
        if (j < 3) {
          p = randomIPointInCircle(l, .05); // point in the circle
        } else {
          p = randomIPointInCircle(l, 0.9); // point in the circle
          let r4d = 0;
          while (r4d < .1) {
            p = randomIPointInCircle(l, 0.9); // point in the circle
            r4d = findDistance(p.latitude, p.longitude, l.latitude, l.longitude, 'km');
          }
        }
        const summulatedT = addMinutes(t, 15 * (j + 1)).valueOf();
        locs.push({ iPoint: p, time: summulatedT });
      }
    }
    return locs;
  }
  /**
   * Deprecated, developed for gps data in storage
   * @param trip TripBase
   * @param locArray TripLocation Array
   */
  static autoCompleteFn(trip: TripBase, locArray: TripLocation[])
    : { updatedTrip: TripBase, updateReq: boolean, uTransWMap: TripTransaction[] } {
    const uTrip: TripBase = trip.clone();
    const bArr: boolean[] = [];
    let uReq: boolean;
    const uTransWMap: TripTransaction[] = [];
    // const locArray = TripLocation.flattenToArray(fbGPS);
    const transCompleted = TripLocation.transactionsCompleted(trip);

    for (let i = 0; i < uTrip.tripSequence.length; i++) {
      const ele = uTrip.tripSequence[i];
      for (const loc of locArray) {
        // find distance between reported gps point and trip location
        const d = findDistance(loc.latitude, loc.longitude, ele.address.geoLoc.geopoint.latitude,
          ele.address.geoLoc.geopoint.longitude, 'km');
        let enteredAt: Date;
        if (d < .1) {
          if (!!uTrip.isOneWay || (!uTrip.isOneWay && i !== uTrip.tripSequence.length - 1)) {
            ele.gpsData = !ele.gpsData || ele.gpsData.length === 0 ? [] : ele.gpsData;
            ele.gpsData.push({ iPoint: { latitude: loc.latitude, longitude: loc.longitude }, timeStamp: new Date(loc.time) });
            // sort to get first entery based on timestamp
            ele.gpsData.sort((a, b) => a.timeStamp.valueOf() - b.timeStamp.valueOf());
            enteredAt = ele.gpsData[0].timeStamp;
            // if location is not marked as entered then update
            if (!ele.autoCompleteTime || !ele.autoCompleteTime.enteredAt) {
              // for return trip last tranaction is handled seprately because address for start and end is same for return trips
              ele.autoCompleteTime = <any>{};
              ele.autoCompleteTime.enteredAt = enteredAt;
              ele.transactionStatus = TransactionStatus.arrived;
              // set trip in process
              // if (uTrip.tripStatus !== TripStatus.inProcess) { uTrip.tripStatus = TripStatus.inProcess; }
              // logger.log('enteredAt', ele.autoCompleteTime.enteredAt);
              // logger.log('update tripData enteredAt', uTrip.tripSequence[i]);
            }
            bArr.push(true);
          } else {
            // For return trip last tranaction update only if 1 or more transactions are completed.
            // For last location on return trip, mark transaction status as 'autocomplete' upon entry instead marking 'arrived'.
            // And Close the tri
            if (uTrip.tripSequence[0].transactionStatus === TransactionStatus.autoCompleted) {
              ele.gpsData = !ele.gpsData || ele.gpsData.length === 0 ? [] : ele.gpsData;
              ele.gpsData.push({ iPoint: { latitude: loc.latitude, longitude: loc.longitude }, timeStamp: new Date(loc.time) });
              // sort to get first entery based on timestamp
              ele.gpsData.sort((a, b) => a.timeStamp.valueOf() - b.timeStamp.valueOf());
              enteredAt = ele.gpsData[0].timeStamp;

              ele.autoCompleteTime = <any>{};
              ele.autoCompleteTime.enteredAt = enteredAt;
              // ele.transactionStatus = TransactionStatus.arrived;
              ele.transactionStatus = TransactionStatus.autoCompleted;
              uTrip.tripStatus = TripStatus.closed;
              // logger.log('enteredAt', ele.autoCompleteTime.enteredAt);
              // logger.log('update tripData enteredAt', uTrip.tripSequence[i]);
              bArr.push(true);
            }
          }
          // uReq = true;
          // if location is marked as entered and left is not marked leftAt then update
        } else if (d > .1 && d < 1 && !!ele.autoCompleteTime && !!ele.autoCompleteTime.enteredAt && !ele.autoCompleteTime.leftAt) {
          // for return trip last tranaction is handled seprately because address for start and end is same for return trips
          ele.autoCompleteTime.leftAt = new Date(loc.time);
          ele.transactionStatus = TransactionStatus.autoCompleted;
          if (!!uTrip.isOneWay && i === uTrip.tripSequence.length - 1) {
            uTrip.tripStatus = TripStatus.closed;
          }
          if ((!uTrip.isOneWay && i !== uTrip.tripSequence.length - 1) && transCompleted.length > 0) {
            uTrip.tripStatus = TripStatus.closed;
          }
          bArr.push(true);
          // uReq = true;
          // logger.log('update tripData leftAt', uTrip.tripSequence[i]);
        } else {
          bArr.push(false);
          // uReq = false;
          // logger.log('no update required');
        }
        if (!!bArr[i]) {
          // get map matadata for waiting time report
          const pic = new LocStaticMap();
          ele.locStaticMap = pic.createLocMapImage(trip.id, ele);
          if (!!ele.locStaticMap) {
            uTransWMap.push(ele);
          }
        }
        uReq = bArr.findIndex(f => !!f) > -1 ? true : false;
      }
    }
    return { updatedTrip: uTrip, updateReq: uReq, uTransWMap: uTransWMap };
  }

  static flattenToArray(fbGPS: { [key: string]: { [key: string]: TripLocation } }): TripLocation[] {
    const tripLocations: TripLocation[] = [];
    for (const key in fbGPS) {
      if (fbGPS.hasOwnProperty(key)) {
        const ele = fbGPS[key];
        for (const k in ele) {
          if (ele.hasOwnProperty(k)) {
            const v = ele[k];
            tripLocations.push(v);
          }
        }
      }
    }
    return tripLocations;
  }
  clone() {
    const t = instanceToInstance(this);
    t.sanitize();
    return t;
  }
  sanitize() {
    super.sanitize();
  }
  validateSyncGroup(group: TripValidationGroup): IValidationMsg {
    return this.validateSync({ groups: [group] });
  }

  validateSync(options?: ValidatorOptions): IValidationMsg {
    this.sanitize();
    const r = this.validateSyncBase(this,options);
    return r;
  }
  getTimeAtLoc(iPoint: IPoint): { enteredAt: Date, leftAt: Date } {
    const tStampsInside: Date[] = [];
    const tStampsOutside: Date[] = [];
    let enteredAt, leftAt: Date;
    let locationTime: Date;
    for (const key in this.location) {
      if (this.location.hasOwnProperty(key)) {
        const element = this.location[key];
        const d = findDistance(iPoint.latitude, iPoint.longitude, element.iPoint.latitude, element.iPoint.longitude, 'km');
        if (d < .1) {
          if (typeof element.locTimeStamp === 'number') {
            locationTime = new Date(element.locTimeStamp);
          } else {
            locationTime = element.locTimeStamp;
          }
          tStampsInside.push(locationTime);
          tStampsInside.sort((a, b) => b.valueOf() - a.valueOf());
        } else {
          tStampsOutside.push(locationTime);
          tStampsOutside.sort((a, b) => b.valueOf() - a.valueOf());
        }
        if (tStampsInside.length > 0 && tStampsOutside.length > 0) {
          enteredAt = tStampsInside[0];
          tStampsOutside.forEach(ele => {
            if (isAfter(ele, tStampsInside[tStampsInside.length - 1])) {
              leftAt = ele;
            }
          });
          if (!!enteredAt && !!leftAt) { break; }
          // const tStampsAfterLeaving = tStampsOutside.filter(f => isAfter(f, tStampsInside[tStampsInside.length - 1]));
          // if (tStampsAfterLeaving.length > 0) { leftAt = tStampsAfterLeaving[0]; }
        }
      }
    }
    return { enteredAt: enteredAt, leftAt: leftAt };
  }
  get mapLabels(): MapLabel[] {
    const labels: MapLabel[] = [];
    for (const key in this.location) {
      if (this.location.hasOwnProperty(key)) {
        const element = this.location[key];
        labels.push({ iPoint: element.iPoint });
      }
    }
    if (labels.length > 0) {
      return labels;
    } else {
      return [];
    }
  }
  autoUpdateTrip(tripData: TripBase) {
    let uReq = false;
    for (const key in this.locRealDb) {
      if (this.locRealDb.hasOwnProperty(key)) {
        const element = this.locRealDb[key];
        for (let i = 0; i < (tripData.tripSequence).length; i++) {
          const ele = tripData.tripSequence[i];
          const d = findDistance(element.l[0], element.l[1], ele.address.geoLoc.geopoint.latitude,
            ele.address.geoLoc.geopoint.longitude, 'km');
          if (d < .1 && (!ele.autoCompleteTime || !ele.autoCompleteTime.enteredAt)) {
            ele.autoCompleteTime = <any>{};
            ele.autoCompleteTime.enteredAt = new Date(element.locTimeStamp);
            ele.transactionStatus = TransactionStatus.arrived;
            uReq = true;
            logger.log('enteredAt', ele.autoCompleteTime.enteredAt);
            logger.log('update tripData enteredAt', tripData.tripSequence[i]);
            break;
          } else if (d > .1 && d < 1 && !!ele.autoCompleteTime && !!ele.autoCompleteTime.enteredAt && !ele.autoCompleteTime.leftAt) {
            ele.autoCompleteTime.leftAt = new Date(element.locTimeStamp);
            ele.transactionStatus = TransactionStatus.autoCompleted;
            uReq = true;
            logger.log('update tripData leftAt', tripData.tripSequence[i]);
            break;
          } else {
            uReq = false;
            logger.log('no update required');
          }
        }
      }
    }
    return { updated: tripData, uRequired: uReq };
  }
  /** updates Trip Transaction status based on gps data from driver's phone, called from functions
   * @param trip: Open Trip assigned to driver
   */
  autoUpdateTripFnOld(trip: TripBase): { updatedTrip: TripBase, updateReq: boolean } {
    let uReq = false;
    for (let i = 0; i < (trip.tripSequence).length; i++) {
      const ele = trip.tripSequence[i];
      const d = findDistance(this.latitude, this.longitude, ele.address.geoLoc.geopoint.latitude,
        ele.address.geoLoc.geopoint.longitude, 'km');
      if (d < .1 && (!ele.autoCompleteTime || !ele.autoCompleteTime.enteredAt)) {
        ele.autoCompleteTime = <any>{};
        ele.autoCompleteTime.enteredAt = new Date(this.time);
        ele.transactionStatus = TransactionStatus.arrived;
        uReq = true;
        logger.log('enteredAt', ele.autoCompleteTime.enteredAt);
        logger.log('update tripData enteredAt', trip.tripSequence[i]);
        break;
      } else if (d > .1 && d < 1 && !!ele.autoCompleteTime && !!ele.autoCompleteTime.enteredAt && !ele.autoCompleteTime.leftAt) {
        ele.autoCompleteTime.leftAt = new Date(this.time);
        ele.transactionStatus = TransactionStatus.autoCompleted;
        uReq = true;
        logger.log('update tripData leftAt', trip.tripSequence[i]);
        break;
      } else {
        uReq = false;
        logger.log('no update required');
      }
    }
    return { updatedTrip: trip, updateReq: uReq };
  }
  mockArrivedDeparted(trip: TripBase, index: number, mockType: 'arrived' | 'departed', delta = 15): {
    iPoint: IPoint, time: number }[] {
    const loc: { iPoint: IPoint, time: number }[] = [];
    const currAct = trip.tripSequence[index];
    const l = currAct.address.geoLoc.geopoint;
    const t = currAct.eta ? currAct.eta : currAct.arrivalTime;
    const g = !!currAct.gpsData ? currAct.gpsData.length : 0;
    const a = currAct.autoCompleteTime?.enteredAt ? currAct.autoCompleteTime?.enteredAt : currAct.arrivalTime;
    const eDeparture = addMinutes(a, currAct.dwellDuration * 60);
    let p: IPoint;
    let simulatedT: number;
    switch (mockType) {
      case 'arrived':
        p = randomIPointInCircle(l, 0.2); // point in the circle
        simulatedT = addMinutes(t, delta ).valueOf();
        // simulatedT = addMinutes(t, 15 * (g + 1)).valueOf();
        loc.push({ iPoint: p, time: simulatedT });
        break;
      case 'departed':
        p = randomIPointBetweenTwoCircles(l, 0.21, 0.9); // point in the circle
        simulatedT = addMinutes(eDeparture, delta).valueOf();
        // simulatedT = addMinutes(t, 75 * (g + 1)).valueOf();
        loc.push({ iPoint: p, time: simulatedT });
        break;
      default:
        break;
    }
    return loc;
  }

}
