import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';
import { NgControl, NgModel } from '@angular/forms';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[phone]',
  // host: {
  //   '(ngModelChange)': 'onInputChange($event)'
  // }
})
export class PhoneMaskDirective {

  constructor(private el: ElementRef, private ngControl: NgControl) { }

  @HostListener('input', ['$event'])
  onInput(event: Event) {
    const inputValue = this.el.nativeElement.value;

    // Modify the input value as per your phone number format/validation logic
    const formattedValue = this.formatPhoneNumber(inputValue);

    // Update the ngModel value with the formatted value
    this.ngControl.control.setValue(formattedValue, { emitEvent: false });
  }

  private formatPhoneNumber(input: string): string {
    // Implement your phone number formatting logic here
    // For example, you can format it as (XXX) XXX-XXXX
    // You may also want to perform validation here
    // Return the formatted value

    let x: RegExpMatchArray;
    const rgx = /(\d{0,3})(\d{0,3})(\d{0,4})/;
    if (input.charAt(0) === '+' && input.charAt(1) === '1') {
      const u = input.substr(2);
      x = u.replace(/\D/g, '').match(rgx);
      input = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
      input = '+1' + input;
    } else if (input.charAt(0) === '1') {
      const u = input.substr(1);
      x = u.replace(/\D/g, '').match(rgx);
      input = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
      input = +'1' + input;
    } else {
      x = input.replace(/\D/g, '').match(rgx);
      input = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    }

    return input;
  }


  // constructor(public model: NgControl) { }

  // onInputChange(value: string) {
  //   if (typeof value === 'string') {
      // let x: RegExpMatchArray;
      // const rgx = /(\d{0,3})(\d{0,3})(\d{0,4})/;
      // if (value.charAt(0) === '+' && value.charAt(1) === '1') {
      //   const u = value.substr(2);
      //   x = u.replace(/\D/g, '').match(rgx);
      //   value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
      //   value = '+1' + value;
      // } else if (value.charAt(0) === '1') {
      //   const u = value.substr(1);
      //   x = u.replace(/\D/g, '').match(rgx);
      //   value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
      //   value = +'1' + value;
      // } else {
      //   x = value.replace(/\D/g, '').match(rgx);
      //   value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
      // }
  //     // logger.log('PhoneMask', x);
  //     this.model.valueAccessor.writeValue(value);
  //   }
  // }

}
// https://stackblitz.com/edit/angular-648zrp?file=src%2Fapp%2Fphone-mask.directive.ts


// import { Directive, ElementRef, Input, OnInit, OnDestroy, Renderer2 } from '@angular/core';
// import { AbstractControl } from '@angular/forms';
// import { Subscription } from 'rxjs';

// @Directive({
// eslint-disable-next-line
//   selector: '[phoneMask]'
// })
// export class PhoneMaskDirective implements OnInit, OnDestroy {

//   private _phoneControl: AbstractControl;
//   private _preValue: string;

//   @Input()
//   set phoneControl(control: AbstractControl) {
//     this._phoneControl = control;
//   }
//   @Input()
//   set preValue(value: string) {
//     this._preValue = value;
//   }

//   private sub: Subscription;

//   constructor(private el: ElementRef, private renderer: Renderer2) { }

//   ngOnInit() {
//     this.phoneValidate();
//   }

//   ngOnDestroy() {
//     this.sub.unsubscribe();
//   }

//   phoneValidate() {

//     this.sub = this._phoneControl.valueChanges.subscribe(data => {

//       /**the most of code from @Günter Zöchbauer's answer.*/

//       /**we remove from input but: 
//          @preInputValue still keep the previous value because of not setting.
//       */
//       const preInputValue: string = this._preValue;
//       const lastChar: string = preInputValue.substr(preInputValue.length - 1);
//       // remove all mask characters (keep only numeric)
//       let newVal = data.replace(/\D/g, '');

//       let start = this.renderer.selectRootElement('#tel').selectionStart;
//       let end = this.renderer.selectRootElement('#tel').selectionEnd;

//       // let start=this.phoneRef.nativeElement.selectionStart;
//       // let end = this.phoneRef.nativeElement.selectionEnd;
//       // when removed value from input
//       if (data.length < preInputValue.length) {
//         // this.message = 'Removing...'; //Just console
//         /**while removing if we encounter ) character,
//           then remove the last digit too.*/
//         if (preInputValue.length < start) {
//           if (lastChar === ')') {
//             newVal = newVal.substr(0, newVal.length - 1);
//           }
//         }
//         // if no number then flush
//         if (newVal.length === 0) {
//           newVal = '';
//         } else if (newVal.length <= 3) {
//           /**when removing, we change pattern match.
//           "otherwise deleting of non-numeric characters is not recognized"*/
//           newVal = newVal.replace(/^(\d{0,3})/, '($1');
//         } else if (newVal.length <= 6) {
//           newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, '($1) $2');
//         } else {
//           newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(.*)/, '($1) $2-$3');
//         }

//         this._phoneControl.setValue(newVal, { emitEvent: false });
//         // keep cursor the normal position after setting the input above.
//         this.renderer.selectRootElement('#tel').setSelectionRange(start, end);
//         // when typed value in input
//       } else {
//         // this.message = 'Typing...'; //Just console
//         const removedD = data.charAt(start);
//         // don't show braces for empty value
//         if (newVal.length === 0) {
//           newVal = '';
//           // don't show braces for empty groups at the end
//         } else if (newVal.length <= 3) {
//           newVal = newVal.replace(/^(\d{0,3})/, '($1)');
//         } else if (newVal.length <= 6) {
//           newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, '($1) $2');
//         } else {
//           newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(.*)/, '($1) $2-$3');
//         }
//         // check typing whether in middle or not
//         // in the following case, you are typing in the middle
//         if (preInputValue.length >= start) {
//           // change cursor according to special chars.
//           // logger.log(start+removedD);
//           if (removedD === '(') {
//             start = start + 1;
//             end = end + 1;
//           }
//           if (removedD === ')') {
//             start = start + 2; // +2 so there is also space char after ')'.
//             end = end + 2;
//           }
//           if (removedD === '-') {
//             start = start + 1;
//             end = end + 1;
//           }
//           if (removedD === ' ') {
//             start = start + 1;
//             end = end + 1;
//           }
//           this._phoneControl.setValue(newVal, { emitEvent: false });
//           this.renderer.selectRootElement('#tel').setSelectionRange(start, end);
//         } else {
//           this._phoneControl.setValue(newVal, { emitEvent: false });
//           this.renderer.selectRootElement('#tel').setSelectionRange(start + 2, end + 2); // +2 because of wanting standard typing
//         }
//       }
//     });
//   }
// }

// https://stackblitz.com/edit/angular-phone-mask-directive?file=app%2Fapp.component.html