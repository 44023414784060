import { StateBase } from '../state-base';
import { State, StateContext, Action, Selector } from '@ngxs/store';
import { map } from 'rxjs/operators';
import { noop } from 'rxjs';
import * as entity from '../entity.state';
import { Injectable } from '@angular/core';
import { getObjKey, Paging } from '@trent/models/observable-util/paging';
import { logger } from '@trentm/log/logger';
import { buildDataRequest, getRootLevelChildren, IDataLoadStatus, LoadStatus, updatePaging } from '@trent/models/observable-util/data-status';
import { PagingContainer } from '@trent/models/observable-util/paging-container';
import { ProductWholesalePrice } from '@trent/models/product-wholesale-price/product-wholesale-price';
import { ProductWholesalePriceService } from '@trent/services/product-wholesale-price.service';
import { parseProductWholesalePrice } from '@trent/models/product-wholesale-price/product-wholesale-price-helper';
import { getProductWholesalePriceOptionOrChildren, ProductWholesalePriceParam, productWholesalePriceSearchClientFilter } from '@trent/models/product-wholesale-price/product-wholesale-price-param';

// #region State Model
export interface ProductWholesalePriceStateModel {

	// New approach with paging
	productWholesalePriceByUserLoaded: boolean;
	productWholesalePriceByUser: { [id: string]: ProductWholesalePrice };  // changed

	/** Which client side queries are complete */
	dataLoadStatus: {
		[key: string]: IDataLoadStatus<ProductWholesalePriceParam>;
	};
	allProductWholesalePricesLoaded: boolean;
	productWholesalePrices: { [id: string]: ProductWholesalePrice }; // entity.EntityState<BidBase>;
}

function initProductWholesalePriceState() {
	return {
		productWholesalePriceByUserLoaded: false,
		productWholesalePriceByUser: {},
		allProductWholesalePricesLoaded: false,
		dataLoadStatus: {},
		productWholesalePrices: {},
	};
}

// #endregion

// #region Actions for new data methods with paging

export class GetProductWholesalePriceByUserRequested {
	static readonly type = '[ProductWholesalePrices] Get Product Wholesale Prices for user - requested ( initial page)';
	constructor(public payload: { pData: Paging; param: any }) { }
}

export class ProductWholesalePriceUpdated {
	static readonly type = '[ProductWholesalePrices] Product Wholesale Price Updated';
	constructor(public payload: { sid: string | number, productWholesalePrice: ProductWholesalePrice }) { }
}

export class ProductWholesalePriceRequested {
	static readonly type = '[ProductWholesalePrice] Request a single product Wholesale price';
	constructor(public payload: { id: string | number }) { }
}
export class ProductWholesalePriceLoaded {
	static readonly type = '[ProductWholesalePrice] Load a single product Wholesale price entity';
	constructor(public payload: { id: string | number, data: ProductWholesalePrice }) { }
}

export class ProductWholesalePriceStateReset {
	static readonly type = '[ProductWholesalePrice] Reset State';
}
export class ProductWholesalePriceRemoveById {
	static readonly type = '[ProductWholesalePrice] Remove by Id';
	constructor(public payload: { sid: string | number }) { }
}
export class ProductWholesalePricesRequested {
	static readonly type = '[ProductWholesalePrices] All Product Wholesale Prices Requested';
	constructor(public payload: { pData: Paging, param: ProductWholesalePriceParam }) { }
}
export class ProductWholesalePricesLoaded {
	static readonly type = '[ProductWholesalePrice] All Product Wholesale Prices Loaded';
	constructor(public payload: {
		data: { [id: string]: ProductWholesalePrice }, // Data
		key: string,
	}) { }
}
export class ProductWholesalePricesNextPageRequested {
	static readonly type = '[ProductWholesalePrice] All Product Wholesale Prices requested - Next Page';
	constructor(public payload: { option: ProductWholesalePriceParam }) { }
}
export class ProductWholesalePricesUpdated {
	static readonly type = '[ProductWholesalePrice] Product Wholesale Prices Updated';
	constructor(public payload: { id: string | number, data: ProductWholesalePrice }) { }
}
export class GetProductWholesalePriceByUserLoaded {
	static readonly type = '[ProductWholesalePrice] Get Product Wholesale Prices for user - loaded (paged)';
	constructor(public payload: { data: { [id: string]: ProductWholesalePrice } }) { }
}

// #endregion

@State<ProductWholesalePriceStateModel>({
	name: 'productWholesalePrice',
	defaults: initProductWholesalePriceState()
})
@Injectable()
export class ProductWholesalePriceState extends StateBase {

	/** Container that keep all of the subscription related to gettting data. */
	dataSubData: PagingContainer<ProductWholesalePrice, ProductWholesalePriceParam> = new PagingContainer();

	constructor(private productWholesalePriceService: ProductWholesalePriceService) {
		super();
	}

	// #region Selectors

	/** Get ProductWholesalePrice list (no draft/rev entires are not included) */
	@Selector()
	static selectAllProductWholesalePricesOld(state: ProductWholesalePriceStateModel) {

		// remove keys that have revision/draft ids, i.e that contain '~' in the id.
		const keys = Object.keys(state.productWholesalePriceByUser).filter(k =>
			k.indexOf('/') === -1 && state.productWholesalePriceByUser[k] != null);

		// object without the rev/draft.
		const filtered = keys.reduce((obj, k) => {
			obj[k] = state.productWholesalePriceByUser[k];
			return obj;
		}, {});

		const output = Object.values(filtered).map(x => parseProductWholesalePrice(x));

		return output;
	}

	@Selector()
	static selectProductWholesalePriceById(state: ProductWholesalePriceStateModel) {
		// new approach, use dictionary of company.
		return entity.getByIdFn_new(state.productWholesalePriceByUser, parseProductWholesalePrice);
	}

	@Selector()
	static selectAllProductWholesalePrices(state: ProductWholesalePriceStateModel) {
		return (o: ProductWholesalePriceParam): ProductWholesalePrice[] => {


			if (state?.productWholesalePrices == null || Object.keys(state.productWholesalePrices).length === 0) {
				return [];
			}

			// remove keys that have revision/draft ids, i.e that contain '~' in the id.
			const keys = Object.keys(state.productWholesalePrices).filter(k =>
				k.indexOf('/') === -1 && state.productWholesalePrices[k] != null);

			// object without the rev/draft.
			const filtered = keys.reduce((obj, k) => {
				obj[k] = state.productWholesalePrices[k];
				return obj;
			}, {});

			let output = Object.values(filtered).map(x => parseProductWholesalePrice(x));
			output = productWholesalePriceSearchClientFilter(output, o);

			return output;
		};
	}

	// #endregion

	@Action(GetProductWholesalePriceByUserLoaded)
	getProductWholesalePriceByUserLoaded(context: StateContext<ProductWholesalePriceStateModel>, action: GetProductWholesalePriceByUserLoaded) {
		const state = context.getState();
		context.patchState({
			productWholesalePriceByUserLoaded: true,
			productWholesalePriceByUser: { ...state.productWholesalePriceByUser, ...action.payload.data },
		});
	}

	@Action(ProductWholesalePriceRequested)
	productWholesalePriceRequested(context: StateContext<ProductWholesalePriceStateModel>, action: ProductWholesalePriceRequested) {
		const state = context.getState();
		const id = action.payload.id;
		if (state.productWholesalePriceByUser[action.payload.id] == null) {
			// logger.log('comp by id, not found in the store, gettting from server....');
			const s = this.productWholesalePriceService.gedProductWholesalePriceById(action.payload.id)
				.pipe(
					map(data => {
						return context.dispatch(new ProductWholesalePriceLoaded({ id, data }));
					}
					));
			this.subscribe(s, (x) => noop(), ProductWholesalePriceRequested.type);
		}
	}

	@Action(ProductWholesalePriceLoaded)
	productWholesalePriceLoaded(context: StateContext<ProductWholesalePriceStateModel>, action: ProductWholesalePriceLoaded) {
		const state = context.getState();
		const c = {};
		c[action.payload.data?.id] = action.payload.data;
		context.patchState({
			productWholesalePriceByUser: { ...state.productWholesalePriceByUser, ...c }
		});
	}


	@Action(ProductWholesalePriceUpdated)
	productWholesalePriceUpdated(context: StateContext<ProductWholesalePriceStateModel>, action: ProductWholesalePriceUpdated) {
		const state = context.getState();
		let c = action.payload.productWholesalePrice;
		if (typeof (c.toFirebaseObj()) === 'function') {
			c = c.toFirebaseObj();
		}
		context.patchState({
			productWholesalePriceByUser: { ...state.productWholesalePriceByUser, ...{ [action.payload.sid]: c } }
		});
	}


	@Action(ProductWholesalePriceStateReset)
	productWholesalePriceStateReset(context: StateContext<ProductWholesalePriceStateModel>, action: ProductWholesalePriceStateReset) {
		// unsubscribe the data
		this.clearSubscriptions();
		context.setState(initProductWholesalePriceState());
	}

	@Action(ProductWholesalePriceRemoveById)
	dataByIdRemoved(context: StateContext<ProductWholesalePriceStateModel>, action: ProductWholesalePriceRemoveById) {
		const state = context.getState();
		if (Object.keys(state.productWholesalePriceByUser).indexOf(`${action.payload.sid}`) > -1) {
			const currState = context.getState();
			const newData = { ...currState.productWholesalePriceByUser };
			delete newData[action.payload.sid];
			context.patchState({ productWholesalePriceByUser: newData });
			const state1 = context.getState();
			logger.log('[ProductWholesalePrice-State], item removed by id', action.payload.sid, state1);

		} else { logger.log('[ProductWholesalePrice-State], item to be removed id is not available in the store'); }
	}
	// #region All Records
	@Action(ProductWholesalePricesRequested)
	dataRequested(context: StateContext<ProductWholesalePriceStateModel>, action: ProductWholesalePricesRequested) {

		const oKey = getObjKey(action.payload.param);

		// Define function that return the data status object from the state.
		const getDataStatusStateFn = () => context.getState().dataLoadStatus;

		/** custom build the OR children query. */
		const buildOrQueryChildrenFn = (o: ProductWholesalePriceParam) => getProductWholesalePriceOptionOrChildren(o);

		// if data requested now, is already partially loaded by another query's child previously
		// but the number of the items are not enough (ex. as a child, it loaded only 5, but current
		// request ask for more, then next page of that query should be called instead.)
		const nextPageFn = (option: ProductWholesalePriceParam) => {
			context.dispatch(new ProductWholesalePricesNextPageRequested({ option }));
		};

		buildDataRequest(
			oKey, action.payload.param, action.payload.pData,
			getDataStatusStateFn,
			buildOrQueryChildrenFn,
			nextPageFn,
			(
				obj: { [key: string]: IDataLoadStatus<ProductWholesalePriceParam> },
				set: { key: string, node: IDataLoadStatus<ProductWholesalePriceParam> }[]
			) => {

				if (!!obj) {
					// Patch the state.
					const state = context.getState();
					context.patchState({
						dataLoadStatus: { ...state.dataLoadStatus, ...obj }
					});
				}

				// Process the query.
				set.forEach((val) => {
					// some of the nodes are already loaded. Only process that are loading... status.
					if (val.node.loadStatus !== LoadStatus.Loading) {
						return;
					}
					// if this request is just overwriting a stall or pending request, unsubscribe that observable
					this.dataSubData.unsubscribe(val.key);

					// create the paging observable and call db.
					const p = this.productWholesalePriceService.getAllProductWholesalePrices_PagingObservable();
					const prod$ = p.getData(action.payload.pData, val.node.param)
						.pipe(
							map(pickDrops => {
								context.dispatch(new ProductWholesalePricesLoaded({
									data: pickDrops as any,
									key: val.key
								}));
								return pickDrops;
							}));
					const sub = this.subscribe(prod$, () => noop(), ProductWholesalePriceRequested.type);
					// save the observable call
					this.dataSubData.addData(val.key, sub, p);
				});
			}
		);
	}

	@Action(ProductWholesalePricesLoaded)
	productWholesalePricesLoaded(context: StateContext<ProductWholesalePriceStateModel>, action: ProductWholesalePricesLoaded) {
		const state = context.getState();
		const subData = this.dataSubData.getData(action.payload.key);
		const updatedLoadStatus = updatePaging(action.payload.key, state.dataLoadStatus, subData);
		context.patchState({
			allProductWholesalePricesLoaded: true,
			dataLoadStatus: updatedLoadStatus,
			productWholesalePrices: { ...state.productWholesalePrices, ...action.payload.data } // entity.addMany(state.bids, action.payload.bids)
		});
	}

	@Action(ProductWholesalePricesNextPageRequested)
	allCompaniesNextPageRequested(context: StateContext<ProductWholesalePriceStateModel>, action: ProductWholesalePricesNextPageRequested) {
		const oKey = getObjKey(action.payload.option);
		const state = context.getState();
		// find the node. can be parent or child
		const statusObj = state.dataLoadStatus[oKey];
		// if no parent, treat is
		if (statusObj.children == null) {
			this.dataSubData.dispatchNextPagingUpdate(oKey);
		} else {
			const children = getRootLevelChildren(oKey, state.dataLoadStatus);
			children.forEach(c => {
				this.dataSubData.dispatchNextPagingUpdate(c.key);
			});
		}
	}
}
