import { ICameraPicData } from './icamera-data';
import { Injectable } from '@angular/core';
import { CameraOptions } from '@awesome-cordova-plugins/camera/ngx';

@Injectable()
export class CameraBaseService {

  constructor() {

  }

  get isCapacitor() { return false; }
  get isCdva() { return false; }

  get isCameraAvailable() { return false; }

  async takePicture(o: CameraOptions): Promise<ICameraPicData> {
    return new Promise<ICameraPicData>((resolve, reject) => reject('Camera is not available in web app!'));
  }
}
