import { FileCapService } from './file-cap.service';
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { File, Entry } from '@awesome-cordova-plugins/file/ngx';
import { FileCdvaService } from './file-cdva-service';


@Injectable()
export class FileMobileService {

  fileService: FileCdvaService | FileCapService;

  constructor(private file: File, private platform: Platform) {
    if (this.platform.is('capacitor')) {
      this.fileService = new FileCapService();
    } else {
      this.fileService = new FileCdvaService(file, platform);
    }
  }

  get isFileAvailable() { return this.fileService.isFileAvailable; }

  resolveFile(url: string): Promise<Entry> {
    return this.fileService.resolveFile(url);
  }

  readFile(file: any): Promise<Blob> {
    return this.fileService.readFile(file);
  }
}
