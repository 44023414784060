import { RoleGroupState } from './role-group-state';
import { NgxsModule } from '@ngxs/store';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RoleGroupService } from '@trent/services/rolegroup.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    NgxsModule.forFeature([RoleGroupState])
  ],
  providers: [
    RoleGroupService
  ]
})
export class RoleGroupStoreModule { }
