import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { MaterialModule } from '../shared/share-module/material.module';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { PromotionLeadsInfoPageComponent } from './promotions-leads-info-page.component';
import { DelegateInspectionService } from '@trent/services/delegate-inspection.service';
import { PhoneMaskModule } from '../directive/phone-mask.module';
import { PromotionsService } from '@trent/services/promotions.service';
import { PromotionResponseType } from '@trent/models/promotional-messages/promotion-response';

const routes: Routes = [
    { path: 'promotion-email/:lid/:pid', component: PromotionLeadsInfoPageComponent, data: { prType: PromotionResponseType.email } },
    { path: 'promotion-sms/:lid/:pid', component: PromotionLeadsInfoPageComponent, data: { prType: PromotionResponseType.sms } },

  ];

@NgModule({
    declarations: [
        PromotionLeadsInfoPageComponent,
    ],
    imports: [
        CommonModule,
        HttpClientModule,
        FormsModule,
        MaterialModule,
        PhoneMaskModule,
        RouterModule.forChild(routes),
    ], 
    providers: [
        DelegateInspectionService,
        PromotionsService 
    ]
})
export class PromotionLeadsInfoPageModule {
  constructor() {

  }
}
