import { Exclude, Expose, plainToInstance } from 'class-transformer';
import { ValidatorOptions } from 'class-validator/types/validation/ValidatorOptions';
import { IValidationMsg } from '../error-handling';
import { ItemValidationGroup, PromoListItem } from './promo-list-item';
import { MaxLength, validateSync } from 'class-validator';
import { toValidationError } from '../utility/validation-helper';
import { IPointGeo } from '../product-search/interfaces';
import { Currency } from '../rental/rental-helper';
import isBefore from 'date-fns/isBefore';
import startOfToday from 'date-fns/startOfToday';
import addDays from 'date-fns/addDays';
import isWithinInterval from 'date-fns/isWithinInterval';
import { sanitizeDateIPoint } from '../utility/sanitize-helper';

@Exclude()
export class SalesProductListItem extends PromoListItem {

	constructor() {
		super();
	}
	@Expose()
	geoLoc: IPointGeo;
	@Expose()
	modelYear: number;
	@Expose()
	unitName: string;
	@Expose()
	cid: string;
	@Expose()
	rateDaily?: number;
	@Expose()
	rateWeekly?: number;
	@Expose()
	rateMonthly?: number;
	@Expose()
	currency: Currency;
	@Expose()
	isOneWay: boolean;
	@Expose()
	targetCCids: string[];
	@Expose()
	availStartDate: Date;
	@Expose()
	availEndDate: Date;
	@Expose()
	bindingBids: { startD: Date, endD: Date }[];
	@Expose()
	bestRateString: string;
	@Expose()
	make: string;
	@Expose()
	key?: string;

	public static parse(obj) {
		if (obj == null) { return null; }
		const m = plainToInstance<SalesProductListItem, any>(SalesProductListItem, sanitizeDateIPoint(obj));
		// m.sanitize();
		return m;
	}
	validateSyncGroup(group?: ItemValidationGroup): IValidationMsg {
		return this.validateSync({ groups: [group] });
	}
	validateSync(options?: ValidatorOptions, list?: any): IValidationMsg {
		const r = validateSync(this, options);
		const m = toValidationError(r);

		// }
		return m;
	}
	isAvailable() {
		if (isBefore(this.availEndDate, startOfToday())) {
			return false;
		}
		const startDate = isBefore(this.availStartDate, startOfToday()) ? startOfToday() : this.availStartDate;
		for (let s = startDate; isBefore(s, this.availEndDate); s = addDays(s, 1)) {
			if (!this.bindingBids) {
				return true;
			}
			for (const b of this.bindingBids) {
				if (!isWithinInterval(s, { start: b.startD, end: b.endD })) {
					return true;
				}
				// if (!isWithinRange(s, b.startD, b.endD)) {
				//   return true;
				// }
			}
		}
		return false;
	}
}