import { Length, ValidatorOptions, validateSync, IsDefined, IsEmail, MaxLength, MinLength, ValidateNested } from 'class-validator';
import { UserProfile } from './user-profile';
import { IValidate, IValidationMsg } from '../error-handling/validation-info';
import { toValidationError, phoneRegex, sanitizeDateIPoint } from '../utility';
import { IUserClaim } from './user-claim';
import { instanceToInstance, plainToInstance, Type, Expose } from 'class-transformer';
import { BaseModel } from '../base/base-model';
// import { isEqual, forEach } from 'lodash';
import { FileInfo } from '../media/file-info';
import { logger } from '../log/logger';


const gName = 'ContactCard';
const photoId = 'PhotoId';

export class ContactCard extends BaseModel {


  /** global group name for this class validation. */
  public static readonly gName = gName;
  public static readonly photoId = photoId;



  /** Officer ID that is key for both @function UserProfilePvt  and  @function UserProfile */
  @Expose()
  contactUid: string | number;


  /** title: president etc.. 
   * @deprecated
  */
  // @Length(5, 128, {
  //   message: 'Title is required. $constraint2 chars max',
  //   groups: []
  //   // groups: [gName]
  // })
  title: string;


  /** full name, linked to @function UserProfile */
  @Expose()
  @Length(5, 128, {
    message: 'Full Name is required. $constraint2 chars max',
    groups: [gName]
  })
  fullName: string;

  @Expose()
  // @Matches(phoneRegexString, {message: 'Enter valid phone', groups: [gName]})
  @IsDefined({ message: 'Enter valid phone', groups: [gName] })
  @MinLength(10, { message: 'Enter valid phone', groups: [gName] })
  phone: string;

  @Expose()
  @IsEmail(undefined, { message: 'Enter valid email', groups: [gName] })
  @MaxLength(254, { message: 'Enter valid email', groups: [gName] })
  email: string;

  @Expose()
  @ValidateNested({ message: 'Upload officer\'s Photo ID ', groups: [photoId] })
  @IsDefined({ message: 'Upload officer\'s Photo ID ', groups: [photoId] })
  @Type(() => FileInfo)
  photoId: FileInfo;


  public static initByUserProfile(user: UserProfile) {
    // Add contect
    const c = new ContactCard();
    c.contactUid = user.id;
    c.title = undefined;
    c.fullName = user.displayName;
    c.phone = undefined;
    return c;
  }

  public static initByUserClaim(user: IUserClaim) {
    // Add contect
    const c = new ContactCard();
    c.contactUid = user.user_id;
    c.title = undefined;
    c.fullName = user.name;
    c.phone = undefined;
    return c;
  }
  /**
   * sets contactCard email, phone, contactUId to userProfile
   * @param c ContactCard that passed and set
   * @param userProile UserProfile
   */
  static setToUserProfile(c: ContactCard, userProile: UserProfile) {
    c.email = userProile.email;
    c.phone = !!userProile.phoneNumber ? userProile.phoneNumber : c.phone;
    c.contactUid = userProile.id;
  }
  static comparison(c1: ContactCard[], c2: ContactCard[]) {
    const emails1 = [];
    const emails2 = [];
    c1?.forEach(e => {
      emails1.push(e.email);
    });
    c2?.forEach(e => {
      emails2.push(e.email);
    });
    const differnce = [];
    emails1.forEach(e => {
      if (!emails2.includes(e)) { differnce.push(e); }
    });
    emails2.forEach(e => {
      if (!emails1.includes(e)) { differnce.push(e); }
    });

    return differnce.length > 0 ? false : true;
  }
  
  public static parseArray = (obj: any[]): ContactCard[] => {
    const r = !!obj ? obj.map(o => ContactCard.parse(o)) : null;
    return r;
  };
  
  public static parse(obj) {
    // public static parse<T extends IPoint>(obj, ipointType?: { new(...args: any[]): T }) {
    try {
      if (obj == null) { return null; }
      // obj = sanitizeDateIPoint(obj, ipointType);
      obj = sanitizeDateIPoint(obj);
      const m = plainToInstance<ContactCard, any>(ContactCard, obj);
      m.sanitize();

      if (m.photoId == null) { m.photoId = new FileInfo(); }

      return m;
    } catch (error) {
      logger.log('Error happened during parse', error);
      return null;
    }
  }

  validateSync(options?: ValidatorOptions): IValidationMsg {
    const r = validateSync(this, options);
    const m = toValidationError(r);
    // if (!!this.phone && !phoneRegex(this.phone)) {
    //   m['phone'] = ['enter valid phone number'];
    // }
    return m;
  }


  constructor() {
    super();
    this.photoId = new FileInfo();


  }

  clone() {
    const t = instanceToInstance(this);
    t.sanitize();
    return t;
  }
  sanitize() {
    // if data was recieved from firebase, date is stored as snapshot.
    super.sanitize();
    // return this;
  }

}
