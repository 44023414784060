import { isNil, isObject } from 'lodash';
import { DialogService } from '@trent/services/dialog/dialog.service';
import { PageHtml } from '@trent/models/cms/page-html';
import { CmsState } from '@trent/store/cms-store/cms.state';
import { Subscription } from 'rxjs';
import { IAuthCoreService } from '@trent/services/auth/iauth-core.service';
import { promiseWraper, cleanListeners } from '@trent/models/utility';
import { ScriptService } from '@trent/services/base/script.service';
import { Directive, ElementRef, Input, OnDestroy, Output, EventEmitter, Renderer2, OnInit } from '@angular/core';
import { IEventListener, EventService } from '@trent/services/event.service';
import { Store } from '@ngxs/store';
import { CmsService } from '@trent/services/cms.service';
import { MessageInfo } from '@trent/models/error-handling';
import { logger } from '@trent/models/log/logger';
import { AuthState } from '@trent/store/auth-store/auth.state';
import { DomSanitizer } from '@angular/platform-browser';


export interface CkData {
  /** Page Id */
  pid: string | number;
  /** Section id */
  sid: string;
  /** Section content */
  content: string;
}

declare var CKEDITOR: any;

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[ck-inline]'
})
export class CkInlineDirective implements OnInit, OnDestroy {

  /** Event Listener container. Only need to store so that ngDistroy can remove the linkages of events. */
  public eventListners: IEventListener[] = [];

  private subs: Subscription[] = [];

  /** if true, then ck directive will not update the db rather it will delegate the save to the 
   * parent page that host that directive. It will fire the change method on blurr though and on
   * clicking the save.*/
  @Input()
  public disableDbUpdate = false;

  /** either provide @property page and sectionName */
  private _page: PageHtml;
  @Input()
  set page(x: PageHtml) {
    this._page = x;
    this.updatePageHtml();
  }

  private _sectionName: string;
  @Input()
  set sectionName(v: string) {
    this._sectionName = v;
    this.updatePageHtml();
  }

  /** Or provide HTML text directly. */
  private _html: string;
  @Input()
  set HTML(v: string) {
    if (v !== this._html) {
      this._html = v;
      this.renderer.setProperty(this.ele, 'innerHTML', v); //  getSafeHtml(v, this.sanitizer));
    }
  }

  /** Which section of PageHtml,  .section or .autoSection is to be used to update the values. */
  @Input()
  isAutoSection;

  /** Native element represented by the directive. */
  ele: any;

  private _mode;

  isAdmin = false;
  isSalesOperationsDpc = false;
  isMarketing = false;
  isSalesOperations = false;

  /** Everytime editor is initialized, backup is stored that can be reset back to the container if cancel is called. */
  private origContent: string;

  @Output()
  contentChanged = new EventEmitter<CkData>();

  get editMode() { return this._mode; }

  private initialized = false;

  /** current fck editor. */
  private editor;

  constructor(
    private store: Store,
    private cms: CmsService,
    private ds: DialogService,
    private renderer: Renderer2,
    el: ElementRef,
    private es: EventService,
    private auth: IAuthCoreService,
    private sc: ScriptService,
    private sanitizer: DomSanitizer,) {

    logger.log('Constructor called');

    this.ele = el.nativeElement; // .id;

    this.eventListners.push(this.es.listen<string>(this.es.ckInlineReset, () => {
      this.HTML = this.origContent;
    }));

    this.eventListners.push(this.es.listen<string>(this.es.fireCkContentChg, () => {
      this.editor?.fire('change');
    }));

    this.subs.push(this.auth.isAdmin$.subscribe(a => {
      this.isAdmin = a;
      if (!this.isAdmin) {
        this.editMode = false;
      }
    }));
    this.subs.push(this.store.select(AuthState.user).subscribe(a => {
      this.isSalesOperationsDpc = a?.roles?.salesOperationsDpc ? true : false;
      this.isMarketing = a?.roles?.marketing ? true : false;
      if (!this.isSalesOperationsDpc || !this.isMarketing) {
        this.editMode = false;
      }
    }));
    
    // CmsStateModel.
    this.subs.push(this.store.select(CmsState.selectPageEditMode).subscribe(m => this.editMode = m));
  }

  ngOnInit(): void {
    this.updatePageHtml();
  }

  ngOnDestroy() {
    logger.log('ON Destroy called');
    this.editMode = false; // event will be renoved
    this.removeEditor();
    cleanListeners(this.eventListners, this.subs);
  }

  private updatePageHtml() {
    if (!!this._page && !!this._page.sections && !!this._page.sections[this._sectionName]) {
      let content = this._page.sections[this._sectionName].val;
      if(typeof content !== 'string') {
        content = this.extractInnerTextFromObject(content);
      }
      this.HTML = content;
    }
  }

  /** Fix legacy code. */
  private extractInnerTextFromObject(obj) {
    let xObj = obj;
    while(isObject(xObj) && !!obj['changingThisBreaksApplicationSecurity']) {
      xObj = xObj['changingThisBreaksApplicationSecurity'];
    }
    let str = xObj as string;
    str = str.replace('SafeValue must use [property]=binding:\n', '');
    str = str.replace('\n(see https://g.co/ng/security#xss)', '');
    return str;
  }

  private updateDisplay() {
    if (this.editMode && !!this.editor) {
      if (this.origContent === this.editor.getData()) {
        this.renderer.setStyle(this.ele, 'background', '#deeaed');
      } else {
        this.renderer.setStyle(this.ele, 'background', '#FCF3CF');
      }
    }
  }

  @Input()
  set editMode(val: boolean) {
    if (val && (this.isAdmin || this.isSalesOperationsDpc || this.isMarketing || this.isSalesOperations)) {
      this._mode = val;
      this.renderer.setStyle(this.ele, 'background', '#deeaed');
      // this.renderer.listen(this.ele, 'mouseover', this.mouseOver);
      // this.renderer.listen(this.ele, 'mouseout', this.mouseOut);
      // this.ele.addEventListener('mouseover', this.mouseOver);
      // this.ele.addEventListener('mouseout', this.mouseOut);
      this.origContent = this._html;
      this.initializeEditor();
    } else {
      this._mode = false; // may be user was non admin.
      this.renderer.setStyle(this.ele, 'background', '');
      this.removeEditor();
    }
  }

  public mouseOver() {
    // e.target.style.background = "#deeaed";
    // e.target.style.border =  "2px solid #ff6868";
  }

  public mouseOut() {
    // e.target.style.background = '';
    // e.target.style.border= '';
  }

  private removeEditor() {
    this.renderer.setAttribute(this.ele, 'contenteditable', 'false');
    // this.el.nativeElement.setAttribute('contenteditable', 'false');
    if (this.editor) {
      this.editor.destroy();
      this.editor = null;
    }
  }

  /** Initialze only if needed for Admin/editor purposes */
  async initializeEditor() {
    const self = this; // contenteditable="true"
    this.renderer.setAttribute(this.ele, 'contenteditable', 'true');
    // this.el.nativeElement.setAttribute('contenteditable', 'true');
    if (!this.initialized) {
      // logger.log('script loading requested!');
      // const url = 'https://cdn.ckeditor.com/4.13.1/standard/ckeditor.js';
      const url = 'https://cdn.ckeditor.com/4.13.1/standard-all/ckeditor.js';
      // const url = '/../../../assets/plugins/ckeditor/ckeditor.js';
      const res = await promiseWraper(this.sc.loadScript(url));
      if (res.success) {
        this.initialized = true;
        // Add source dialog plugin.
        // RROR [CKEDITOR.resourceManager.load] Resource name "sourcedialog" was not found at
        // "https://cdn.ckeditor.com/4.4.5.1/standard/plugins/sourcedialog/plugin.js?t=E8PB".
        // Use below, only if getting ckeditor from CDN.
        // CKEDITOR.plugins.addExternal('sourcedialog', '/assets/plugins/ckeditor/plugins/sourcedialog/', 'plugin.js');
        CKEDITOR.disableAutoInline = true;
        CKEDITOR.dtd.$removeEmpty['i'] = false;
        CKEDITOR.dtd.$removeEmpty.span = 0;
        CKEDITOR.config.allowedContent = true;
        CKEDITOR.config.entities = false;
        self.setupEditor();
      } else {
        logger.error('File Load ERROR! Could not load CK Editor file.');
      }
    } else {
      // logger.log('script already exists');
      this.setupEditor();
    }
  }

  private setupEditor() {
    if (!!this.editor) {
      return;
    }
    this.editor = CKEDITOR.inline(this.ele, {
      extraPlugins: 'sourcedialog',
      // removePlugins: 'sourcearea',
      enterMode: CKEDITOR.ENTER_BR,
      shiftEnterMode: CKEDITOR.ENTER_P,
      on: {
        change: () => {
          // logger.log('[CkEdit] change fired');
          // this.contentChanged.emit(self.editor.getData());
        },
        blur: () => {
          //alert('blur fired');
          logger.log('[CkEdit] blur fired');
          this.updateDisplay();
          if (this.disableDbUpdate) {
            const content = this.editor.getData();
            this.fireContentChg(content);
          }
        }
      }
    });

    // Add custom save and cancel commands
    if (!this.disableDbUpdate) {
      this.editor.addCommand('mySaveCmd', {
        exec: (edt) => this.save()
      });
      // custom save
      this.editor.ui.addButton('MySave', {
        label: 'Save data at server',
        command: 'mySaveCmd',
        toolbar: 'insert',
        icon: '/assets/icons/save.png'
      });
    }
    // Add custom cancel command
    this.editor.addCommand('myCancelCmd', {
      exec: () => this.cancel()
    });
    // custom save
    this.editor.ui.addButton('MyCancel', {
      label: 'Cancel the edits',
      command: 'myCancelCmd',
      toolbar: 'insert',
      icon: '/assets/icons/noimage.png'
    });
  }

  private fireContentChg(content: string) {
    this.contentChanged.emit({ pid: this._page.id, sid: this._sectionName, content });
  }

  async save1() {
    // manually fire the event

    setTimeout(() => {
      this.save();
    }, 100);
  }

  /** refactoring , val was used to be text but data base still have text. */
  private async save() {
    const content = this.editor.getData();
    this.fireContentChg(content);
    /** Do not update customer is handling it in a different way. */
    if (this.disableDbUpdate) {
      logger.log('update was disabled at inline-ck-editor level');
      return;
    }
    // also save if page is defined
    if (!!this._page && !!this._sectionName) {
      this.cms.savePageHtmlSectionById(this._page, this._sectionName, {
        val: content,
        text: 'legacy N/A'
      } as any, this.isAutoSection)
        .then(() => {
          this.ds.openSnackBar(new MessageInfo({
            msgCss: 'success',
            description: 'Edits were updated!'
          }), {
            duration: 2000,
            verticalPosition: 'bottom'
          });
          this.origContent = content;
          this.updateDisplay();
        })
        .catch(err => {
          const msg = (!!err && typeof err.message === 'string') ? err.message :
            'Sorry! Login was not successful. Please try again!';
          this.ds.openSnackBar(new MessageInfo({
            msgCss: 'warn',
            description: msg
          }), {
            duration: 2000,
            verticalPosition: 'bottom'
          });
        });
    }
  }

  cancel() {
    // logger.log('CANCEL was called');
    this.editor.setData(this._html);
    // this.HTML = this.origContent;
  }

}
