import { Router } from '@angular/router';
import { CloudMessageCoreService } from './cloud-message-core.service';
import { environment } from '@trent/core/environments/environment';
import { LocalStorage } from '@trent/services/local-storage/local-storage.service';
import { Injectable } from '@angular/core';
import { FirestoreService } from '../firestore.service';
import { Store } from '@ngxs/store';
import { promiseWraper } from '@trent/models/utility';
import { UtilityService } from '../utility.service';
import { IcloudMessageService } from './icloud-message.service';
import { IAuthCoreService } from '../auth/iauth-core.service';
import { IdeviceIdService } from '../device/idevice-id.service';
import { logger } from '@trentm/log/logger';

// import 'firebase/messaging';
import { getMessaging, getToken, Messaging, onMessage } from '@angular/fire/messaging';

/** Do not inject this service in any component. Use @class IcloudMessageService instead.
 * Or better yet, use the @class SingletonService's public property. This this class is singleton also but 
 * it is not imported at the app level to reduce the loads. Singleton class has the only copy in it.
 */
/** Firebase Cloud Messaging for WEB */
@Injectable()
export class CloudMessageWebService extends CloudMessageCoreService implements IcloudMessageService {


  messaging: Messaging;

  constructor(
    // private coreAuth: AuthCoreService,
    store: Store,
    private us: UtilityService,
    auth: IAuthCoreService,
    afs: FirestoreService,
    localStorage: LocalStorage,
    deviceIdService: IdeviceIdService,
    router: Router
  ) {
    super(auth, store, localStorage, afs, deviceIdService, router);

    // // assing the get device id function
    // super.getDeviceIdFn = this.getDeviceId;

    logger.log('Cloud Messaging Service Constructor Called');
  }

  // async getDeviceId() {
  //   if (!this.utlService.isPlatformBrowser) {
  //     logger.error('Device ID was called on non-browser platform!!');
  //     const d: IDeviceIdComponents = {
  //       deviceId: '---xxxxx---',
  //       components: [],
  //       updatedOn: null
  //     };
  //     return d;
  //   }
  //   // tslint:disable-next-line:max-line-length
  //   const cacheData = await promiseWraper<IDeviceIdComponents>(this.localStorage.get(CloudMessageCoreService.deviceFingerPrintKey));
  //   if (cacheData.success && !!cacheData.data && typeof cacheData.data.deviceId === 'string') {
  //     cacheData.data.updatedOn = new Date(cacheData.data.updatedOn);
  //     const lastweek = addDays(new Date(), -7);
  //     if (cacheData.data.updatedOn > lastweek) {
  //       logger.log('cache finger print of brwser was found');
  //       return cacheData.data;
  //     }
  //   }

  //   // not found, create one for web.
  //   // tslint:disable-next-line:max-line-length
  // tslint:disable-next-line:max-line-length
  //   const fingerPrint = await promiseWraper(this.scriptService.loadScript('https://cdnjs.cloudflare.com/ajax/libs/fingerprintjs2/2.1.0/fingerprint2.min.js'));
  //   if (fingerPrint.success && fingerPrint.data.status === 'loaded') {
  //     const res = await promiseWraper<Array<any>>(Fingerprint2.getPromise({}));
  //     if (res.success && !!Fingerprint2) {
  //       const components = res.data;
  //       const values = components.map((c) => c.value);
  //       const murmur = Fingerprint2.x64hash128(values.join(''), 31);
  //       logger.log('Finger print data');
  //       logger.log('comp: ', components);
  //       logger.log('values: ', values);
  //       logger.log('murmur: ', murmur);
  //       // save for future.
  //       const deviceInfo: IDeviceIdComponents = {
  //         deviceId: murmur,
  //         components,
  //         updatedOn: new Date()
  //       };
  //       this.localStorage.set(CloudMessageCoreService.deviceFingerPrintKey, deviceInfo)
  //         .catch(err => { logger.error('could not save fcm token in local storage', err) });
  //       return deviceInfo;
  //     }
  //   }
  // }


  /** called by request notification. */
  async init() {
    if (!!this.messaging) {
      return;
    }

    // if (!!this.msgSub) {
    //   this.msgSub.unsubscribe();
    // }

    // this.msgSub = this.afMessaging.messages.subscribe((m) => {
    this.messaging = getMessaging(); //  m as firebase.messaging.Messaging;

    // this.messaging.usePublicVapidKey(environment.fire.publicVapidKey);


    getToken(this.messaging, { vapidKey: environment.fire.publicVapidKey })
      .then(t => {
        logger.log('[fcm] Token from web is: ', t);
        this.fcmToken = t;
        this.updateFcmToken();
      })
      .catch(err => logger.log('get token failed!', err));

      // TBD
    // this.messaging.onTokenRefresh((t) => this.onTokenRefresh(t));
    onMessage(this.messaging, (payload) => {
      logger.log('[fcm] got data on messaging.onMessage', payload);
      this.onMessageReceived(payload as any);
    });

    // this.messaging.onMessage(msg => {
    //   logger.log('[fcm] got data on messaging.onMessage', msg);
    //   this.onMessageReceived(msg);
    // });
    navigator?.serviceWorker?.addEventListener('message', (message) => {
      logger.log('[fcm] got data on navigator service worker', message);
      this.onMessageReceived(message);
    });
    // },
    //   (error) => {
    //     logger.log('messagin error: ', error);
    //   });
  }

  public requestNotificationPermission() {

    logger.log('[fcm] Request/Bind call made on cloud-message-web');
    return new Promise<boolean>(async (resolve, reject) => {
      if (this.us.isPlatformBrowser) {
        const permission = await promiseWraper(Notification?.requestPermission());
        if (!permission.success) {
          logger.log('[fcm] Error: Web Cloud message: error in getting permission');
          return reject(permission.error);
        }
        // note, rejected permission results in web results in error above. so 
        // if you are here, you hae permission.
        await this.init();
        return resolve(true);
      } else {
        return reject(new Error('Request permission is not supported at server'));
      }
    });
  }

  async refreshToken() {
    throw new Error('THIS FUNCTION WAS NOT UPDATED, NEED TO BE INVESTIGATED IF CALLED DURING RUN TIME>');
    // if (!!this.fcmToken && this.fcmToken.length > 0) {
    //   const r = await promiseWraper(this.messaging.deleteToken(this.fcmToken));
    //   if (r.success) {
    //     logger.log('Fcm token was deleted.');
    //     this.fcmToken = await this.messaging.getToken();
    //     logger.log('new token', this.fcmToken);
    //     this.updateFcmToken();
    //   } else {
    //     logger.log('Fcm token could not be deleted', r.error);
    //   }
    // }
  }
}
