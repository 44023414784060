import { IsBoolean, IsNumber, Length, Max, ValidateIf, IsDefined, IsDate, Min, IsInt, IsEnum, ValidatorOptions, IsOptional } from 'class-validator';
import { Exclude, instanceToInstance, plainToInstance, Expose, Type } from 'class-transformer';
import { IValidationMsg } from '../error-handling';
import { round, sanitizeDate, sanitizeDateIPoint } from '../utility';
import { addDays, addMonths, addYears, differenceInMonths, format, isAfter, isDate, lastDayOfMonth, startOfDay } from 'date-fns';
import { BaseModel } from '../base';
import { ApplicableTaxes, DownPaymentType, Frequency, PartOfMultipleUnits, PaymentType } from './legacy-contract-enum';
import { LegacyContractValidationGroup } from './legacy-contract-base';
import { capitalize } from 'lodash';

/**
 * Lease Summary model (Internally used in Legacy Contract model)
 */
const leaseSummary: LegacyContractValidationGroup = 'leaseSummary';

@Exclude()
export class LeaseSummary extends BaseModel {

  
  @Expose()
  @IsDefined({ message: 'Part Of Multiple Units should be defined', groups: [leaseSummary] })
  @IsEnum(PartOfMultipleUnits)
  partOfMultipleUnits: PartOfMultipleUnits;

  @Expose()
  @IsNumber()
  @Min(0, { message: 'Rental should be more than $constraint1 number' , groups : [leaseSummary]})
  @Max(1000000, { message: 'Rental should be less than $constraint1 number' , groups : [leaseSummary]})
  rental: number;

  @Expose()
  @IsInt()
  @Min(1, { message: 'Term needs to be min $constraint1 value', groups : [leaseSummary] })
  @Max(100, { message: 'Term needs to be max $constraint1 value', groups : [leaseSummary] })
  term: number;

  @Expose()
  // @IsInt()
  // @Min(0, { message: 'Remaining Term needs to be min $constraint1 value', groups : [leaseSummary] })
  // @Max(200, { message: 'Remaining Term needs to be max $constraint1 value', groups : [leaseSummary] })
  remainingTerm: number;

  //Remaining Term calculated (add 1 for managing month index)
  get remainingTermCalculated(): number {
    return this.lastPaymentDtCalculated ? differenceInMonths(this.lastPaymentDtCalculated,new Date())+1 : undefined;//+1
  }
  
  @Expose()
  @IsNumber()
  @Min(0, { message: 'Gap should be more than $constraint1 number', groups : [leaseSummary] })
  @Max(1000000, { message: 'Gap should be less than $constraint1 number', groups : [leaseSummary] })
  gap: number;

  // @Expose()
  // @IsNumber()
  // @Min(0, { message: 'Payment Before Tax should be more than $constraint1 number', groups : [leaseSummary] })
  // @Max(1000000, { message: 'Payment Before Tax should be less than $constraint1 number', groups : [leaseSummary] })
  
  get pymtBfrTax(): number {
    return round((this.rental + this.gap), 2);
  }

  @Expose()
  @IsNumber()
  @Min(0, { message: 'End Of Term should be more than $constraint1 number', groups : [leaseSummary] })
  @Max(1000000, { message: 'End Of Term should be less than $constraint1 number', groups : [leaseSummary] })
  endOfTerm: number;

  @Expose()
  @IsDefined({ message: 'Applicable Taxes should be defined', groups: [leaseSummary] })
  @IsEnum(ApplicableTaxes)
  applicableTaxes: ApplicableTaxes;

  // @Expose()
  // @IsNumber()
  // @Min(0, { message: 'Tax Amount should be more than $constraint1 number', groups : [leaseSummary] })
  // @Max(1000000, { message: 'Tax Amount should be less than $constraint1 number', groups : [leaseSummary] })
  get taxAmt (): number {
    return round((this.pymtBfrTax * this.applicableTaxes), 2);
  }

  // @Expose()
  // @IsNumber()
  // @Min(0, { message: 'Total Payment should be more than $constraint1 number', groups : [leaseSummary] })
  // @Max(1000000, { message: 'Total Payment should be less than $constraint1 number', groups : [leaseSummary] })

  get totalPayment (): number {
    return round((this.pymtBfrTax + this.taxAmt), 2);
  }
  
  @Expose()
  @IsNumber()
  @Min(0, { message: 'Selling Price should be more than $constraint1 number', groups : [leaseSummary] })
  @Max(1000000, { message: 'Selling Price should be less than $constraint1 number', groups : [leaseSummary] })
  sellingPrice: number;

  @Expose()
  @IsDate({ message: 'First Payment Date should be a date', groups : [leaseSummary] })
  firstPaymentDt: Date;
  
  @Expose()
  lastPaymentDt: Date;

  //Based on down payment type
  get termAdjustment(){
      switch(this.downPaymentType){
        case DownPaymentType.firstPaymentOnly : return 1;
        case DownPaymentType.firstAndLastPayment : return 2;
        case DownPaymentType.firstAndLastPaymentPlusDeposit : return 2;
        default : return 0;
      }
  }

  //Last payment date Calculated(Subtract 1 for managing month index)
  get lastPaymentDtCalculated(){
    return (this.firstPaymentDt && (this.papDt == '1st' || this.papDt == '15th' || this.papDt == '20th' || this.papDt == '22nd')) ? addMonths(new Date(this.firstPaymentDt), (this.term - this.termAdjustment - 1)) : undefined;
  }
  
  @Expose()
  @IsDate({ message: 'Approved On should be a date', groups : [leaseSummary] })
  approvedOn: Date;



  @Expose()
  @IsNumber()
  @Min(0, { message: 'Gap Premium should be more than $constraint1 number', groups : [leaseSummary] })
  @Max(1000000, { message: 'Gap Premium should be less than $constraint1 number', groups : [leaseSummary] })
  gapPremium: number;

  @Expose()
  @IsBoolean()
  isWalkAwaySelected : boolean = false;

  @Expose()
  @ValidateIf(o => !!o.isWalkAwaySelected)
  @IsNumber()
  @Min(0, { message: 'Walk Away Premium should be more than $constraint1 number', groups : [leaseSummary] })
  @Max(200000, { message: 'Walk Away Premium should be less than $constraint1 number', groups : [leaseSummary] })
  walkAwayPremium: number;

  // @Expose()
  // @IsNumber()
  // @Min(0, { message: 'Selling Price With Sp With Sp should be more than $constraint1 number', groups : [leaseSummary] })
  // @Max(1000000, { message: 'Selling Price With Sp With Sp should be less than $constraint1 number', groups : [leaseSummary] })
  // sellingPriceWithSp: number;

  get sellingPriceWithSp() {
    return round((this.sellingPrice + this.gapPremium),2);
  }

  @Expose()
  @IsNumber()
  @Min(0, { message: 'Down With Sp With Sp should be more than $constraint1 number', groups : [leaseSummary] })
  @Max(1000000, { message: 'Down With Sp With Sp should be less than $constraint1 number', groups : [leaseSummary] })
  down: number;

  @Expose()
  @IsNumber()
  @Min(0, { message: 'Admin Feet Pine With Sp With Sp should be more than $constraint1 number', groups : [leaseSummary] })
  @Max(1000000, { message: 'Admin Feet Pine With Sp With Sp should be less than $constraint1 number', groups : [leaseSummary] })
  adminFeeTpine: number;

  // @Expose()
  // @IsNumber()
  // @Min(0, { message: 'Down Rcd With Sp With Sp should be more than $constraint1 number', groups : [leaseSummary] })
  // @Max(1000000, { message: 'Down Rcd With Sp With Sp should be less than $constraint1 number', groups : [leaseSummary] })
  get downRcd(): number {
    return round((this.down, this.adminFeeTpine), 2);
  }

  // @Expose()
  // @IsNumber()
  // @Min(0, { message: 'Fin Amt With Sp should be more than $constraint1 number', groups : [leaseSummary] })
  // @Max(1000000, { message: 'Fin Amt With Sp should be less than $constraint1 number', groups : [leaseSummary] })


  get finAmtWithSp (): number {
    return round((this.sellingPriceWithSp - this.down), 2);
  }

  // @Expose()
  // @IsNumber()
  // @Min(0, { message: 'Fin Amt Without Sp should be more than $constraint1 number', groups : [leaseSummary] })
  // @Max(1000000, { message: 'Fin Amt Without Sp should be less than $constraint1 number', groups : [leaseSummary] })
  
  get finAmtWithoutSp(): number {
    return round((this.sellingPriceWithSp - this.down), 2);
  }

  // @Expose()
  // @IsBoolean({ message: 'Walk away is required', groups : [leaseSummary] })
  // walkAway: boolean;

  // @Expose()
  // @IsDate({ message: 'Column 42 should be a date', groups : [leaseSummary] })
  // column42: Date;

  // @Expose()
  // @IsNumber()
  // @Min(0, { message: 'Months In Contract should be more than $constraint1 chars', groups : [leaseSummary] })
  // @Max(100000, { message: 'Months In Contract should be less than $constraint1 number', groups : [leaseSummary] })
  // monthsInContract: number;

  // @Expose()
  // @IsNumber()
  // @Min(0, { message: 'Amount Outstanding should be more than $constraint1 chars', groups : [leaseSummary] })
  // @Max(1000000, { message: 'Amount Outstanding should be less than $constraint1 number', groups : [leaseSummary] })
  // amountOutstanding: number;
  
  // @Expose()
  // @IsNumber()
  // @Min(0, { message: 'Amount Recd should be more than $constraint1 number', groups : [leaseSummary] })
  // @Max(100000, { message: 'Amount Recd should be less than $constraint1 number', groups : [leaseSummary] })
  // amtRecd: number;

  /**Put number as a day of the month remove 'th' */
  // @Expose()
  // @Min(1, { message: 'PAP Date should be more than $constraint1 number', groups : [leaseSummary] })
  // @Max(31, { message: 'PAP Date should be less than $constraint1 number', groups : [leaseSummary] })
  get papDt(): string {
    return this.firstPaymentDt && isDate(this.firstPaymentDt) ? format(this.firstPaymentDt, 'do') : '';
  }

  //message: 'Lease Associate should be more than $constraint1 chars length and less than $constraint1 number',
  @Expose()
  @IsOptional()
  @ValidateIf(o => o.leaseAssociate, {groups: [leaseSummary]})
  @Length(1, 150, { groups : [leaseSummary] })
  leaseAssociate: string;

  @Expose()
  @IsNumber()
  @Min(0, { message: 'Reserve Release should be more than $constraint1 chars', groups : [leaseSummary] })
  @Max(100000, { message: 'Reserve Release should be less than $constraint1 number', groups : [leaseSummary] })
  reserveRelease: number;
  
  @Expose()
  @IsDefined({ message: 'frequency is required', groups : [leaseSummary] })
  @IsEnum(Frequency)
  frequency: Frequency;

  @Expose()
  @Length(0,50, {groups : [leaseSummary] })
  @IsEnum(PaymentType, {groups : [leaseSummary] })
  pymtType: PaymentType = PaymentType.fixed;

  @Expose()
  @IsOptional()
  @ValidateIf(o => o.downPaymentType, {groups: [leaseSummary]})
  @IsEnum(DownPaymentType, {groups : [leaseSummary] })
  downPaymentType: DownPaymentType = DownPaymentType.advance;
  
  constructor() {
    super();
    this.frequency = Frequency.MONTHLY;
    this.pymtType = PaymentType.fixed;
    this.partOfMultipleUnits = PartOfMultipleUnits.singleUnit;
    this.isWalkAwaySelected = false;
  }

  
  get lastPaymentDtMinDate() {
    return addDays(addYears(new Date(), -1), -1);
  }

  get lastPaymentDtMaxDate() {
      return addDays(addYears(new Date(), 10), -1);
  }

  get approvedOnDtMinDate() {
      return addDays(addYears(new Date(), -1), -1);
  }

  get approvedOnDtMaxDate() {
      return addDays(addYears(new Date(), 10), -1);
  }

  public static parse(obj) {
    if (obj instanceof (LeaseSummary)) { return <LeaseSummary>obj; }
    const m = plainToInstance<LeaseSummary, any>(LeaseSummary, sanitizeDateIPoint(obj));
    m.sanitize();

    // if(obj.walkAway && typeof obj.walkAway === 'string') {
    //     obj.walkAway = obj.walkAway === 'YES' ? true : false;
    // }

    return m;
  }

  sanitize() {
    // super.sanitize();
    this.firstPaymentDt = sanitizeDate(this.firstPaymentDt);
    this.approvedOn = sanitizeDate(this.approvedOn);
    /**
     * add condition for decimal for sanitize - round to 2 decimal
     */
    this.sellingPrice = this.sanitizeAsDecimal(this.sellingPrice);
    this.gapPremium = this.sanitizeAsDecimal(this.gapPremium);
    this.walkAwayPremium = this.sanitizeAsDecimal(this.walkAwayPremium);
    this.down = this.sanitizeAsDecimal(this.down);
    // this.sellingPriceWithSp = this.sanitizeAsDecimal(this.sellingPriceWithSp);
    // this.downRcd = this.sanitizeAsDecimal(this.downRcd);
    // this.finAmtWithSp = this.sanitizeAsDecimal(this.finAmtWithSp);
    // this.finAmtWithoutSp = this.sanitizeAsDecimal(this.finAmtWithoutSp);
    // this.column42 = startOfDay(sanitizeDate(this.column42));
    this.gap = this.sanitizeAsDecimal(this.gap);
    // this.pymtBfrTax = this.sanitizeAsDecimal(this.pymtBfrTax);
    this.endOfTerm = this.sanitizeAsDecimal(this.endOfTerm);
    this.applicableTaxes = this.sanitizeAsDecimal(this.applicableTaxes);
    // this.taxAmt = this.sanitizeAsDecimal(this.taxAmt);
    // this.totalPayment = this.sanitizeAsDecimal(this.totalPayment);
    // this.amountOutstanding = this.sanitizeAsDecimal(this.amountOutstanding);
   // this.monthsInContract = this.sanitizeAsDecimal(this.monthsInContract);
    this.reserveRelease = this.sanitizeAsDecimal(this.reserveRelease);
    this.adminFeeTpine =+ this.adminFeeTpine;
    // this.amtRecd = this.sanitizeAsDecimal(this.amtRecd);
    this.rental = this.sanitizeAsDecimal(this.rental);
    // this.fico = this.sanitizeAsDecimal(this.fico);
    // this.bni = this.sanitizeAsDecimal(this.bni);
    this.term =+ this.term;
    this.remainingTerm =+ this.remainingTerm;


  }

  clone() {
    const t = instanceToInstance(this);
    t.sanitize();
    return t;
  }

  validateSync(options? : ValidatorOptions): IValidationMsg {
    const r = this.validateSyncBase(this, options);
    return r;
}

  sanitizeAsDecimal(decimalStr: any) {
    decimalStr =+ decimalStr;
    // decimalStr = decimalStr.toFixed(2);
    // decimalStr =+ decimalStr;
    return decimalStr;
  }

  createLeaseSummary(lc : any){
    // this.leaseNo = +lc.leaseNo;
    ///this.walkAway = lc.walkAway === 'YES' ? true : false;

    this.leaseAssociate = lc.leaseAssociate;
    this.frequency = lc.frequency;
    this.pymtType = lc.pymtType && (capitalize(lc.pymtType) as PaymentType);

    this.lastPaymentDt = sanitizeDate(new Date(lc.lastPaymentDt));
    this.approvedOn = sanitizeDate(new Date(lc.approvedOn));
    this.firstPaymentDt = sanitizeDate(new Date(lc.firstPaymentDt));
    this.sellingPrice = this.sanitizeAsDecimal(lc.sellingPrice);
    this.gapPremium = this.sanitizeAsDecimal(lc.gapPremium);
    this.walkAwayPremium = this.sanitizeAsDecimal(lc.walkAwayPremium);
    this.isWalkAwaySelected = lc.isWalkAwaySelected == 'YES' ? true : false;
    this.down = this.sanitizeAsDecimal(lc.down);
    // this.sellingPriceWithSp = this.sanitizeAsDecimal(lc.sellingPriceWithSp);
    // this.finAmtWithSp = this.sanitizeAsDecimal(lc.finAmtWithSp);
    // this.finAmtWithoutSp = this.sanitizeAsDecimal(lc.finAmtWithoutSp);
    // this.column42 = startOfDay(sanitizeDate(lc.column42));
    this.gap = this.sanitizeAsDecimal(lc.gap);
    // this.pymtBfrTax = this.sanitizeAsDecimal(lc.pymtBfrTax);
    this.endOfTerm = this.sanitizeAsDecimal(lc.endOfTerm);
    this.applicableTaxes = this.sanitizeAsDecimal(lc.applicableTaxes);
    // this.taxAmt = this.sanitizeAsDecimal(lc.taxAmt);
    // this.totalPayment = this.sanitizeAsDecimal(lc.totalPayment);
    // this.amountOutstanding = this.sanitizeAsDecimal(lc.amountOutstanding);
    //this.monthsInContract = this.sanitizeAsDecimal(lc.monthsInContract);
    this.reserveRelease = this.sanitizeAsDecimal(lc.reserveRelease);
    this.adminFeeTpine =+ lc.adminFeetPine;
    // this.amtRecd = this.sanitizeAsDecimal(lc.amtRecd);
    this.rental = this.sanitizeAsDecimal(lc.rental);
    // this.fico = this.sanitizeAsDecimal(lc.fico);
    // this.bni = this.sanitizeAsDecimal(lc.bni);
    this.term =+ lc.term;
    this.remainingTerm =+ lc.remainingTerm;
    this.partOfMultipleUnits = PartOfMultipleUnits.singleUnit;
    this.downPaymentType = lc.downPaymentType;
  }

}