import { Invoice } from './invoice';
import { plainToInstance, instanceToInstance, Expose, Exclude } from 'class-transformer';
import { TransactionType } from './invoice-type';
import { logger } from '../../log/logger';
import { sanitizeDateIPoint } from '../../utility/sanitize-helper';

@Exclude()
export class InvoiceRec extends Invoice {
  
  // public static readonly collectionName = 'invoice-rec';
  @Expose()
  vCid: number | string;
  public static parse(obj) {
    try {

    if (obj == null) { return null; }
    // if data was recieved from firebase server, it may contain GeoPoint class that throw error
    // when using planToClass method. So simply the object aagin to get rid of GeoPoint class.
    obj = sanitizeDateIPoint(obj);
    // obj = instanceToPlain(obj);
    const m = plainToInstance<InvoiceRec, any>(InvoiceRec, sanitizeDateIPoint(obj));
    m.sanitize();
    return m;
    } catch (error) {
      logger.log('Error happened during parse', error);
      return null;
    }
  }

  constructor() {
    super();
    this.invoiceType = TransactionType.credit;
    // this.oneTimeAdminFee = fi.oneTimeCharge.truckRental.adminFee;
  }
  clone() {
    const t = instanceToInstance(this);
    t.sanitize();
    return t;
  }

  sanitize() {
    super.sanitize();
  }
}
