import { Injectable } from '@angular/core';
import { IgeoLocationService } from './igeo-location.service';
import { GeoLocationCoreService } from './geo-location-core.service';
import { IPoint } from '@trent/models/product-search/interfaces';
import { IAuthCoreService } from '../auth/iauth-core.service';
import { FirestoreService } from '../firestore.service';
import { IdeviceIdService } from '../device/idevice-id.service';
import { cleanupUndefined, cleanUp, promiseWraper } from '@trent/models/utility';
import { timer } from 'rxjs';
import { SingletonService } from '../singleton.service';
import { Store } from '@ngxs/store';
import { ITripGpsStatus } from '@trent/models/trip/trip-gps-status';
import { logger } from '@trentm/log/logger';

// declare re

/** to be used in the pwa / web only. */
@Injectable()
export class GeoLocationWebService extends GeoLocationCoreService implements IgeoLocationService {
  constructor(store: Store, auth: IAuthCoreService, db: FirestoreService, deviceIdService: IdeviceIdService, sa: SingletonService) {
    super(store, auth, db, deviceIdService, sa);
    // assign the get geo location permission function.
    super.getCurrentPosition = this.getCurrentPosition;
    logger.log('Geo Location Web called!');
  }

  get isGeoLocSupported() {
    return false;
    // return !!navigator && !!navigator.geolocation;
  }

  get isGpsBroadCastingReqd() { return false;}

  public setupBroadCasting(gpsStatus?: ITripGpsStatus) {
    this.setupBroadCastingBase(this, gpsStatus);
  }

  /**
   * https://developer.mozilla.org/en-US/docs/Web/API/Geolocation_API/Using_the_Geolocation_API
   */
  getCurrentPosition() {
    return new Promise<GeolocationPosition>((resolve, reject) => {
      if (!this.isGeoLocSupported) {
        reject('navigator is not available or permission was not granted');
      }
      navigator.geolocation.getCurrentPosition(
        (resp: GeolocationPosition) => {
          // logger.log('Location Data: ', resp);
          resolve(resp);
        },
        (err: GeolocationPositionError) => {
          reject(err);
        }
      );
    });
  }

  public async stopBroadcastingLocation() {
    if (!!this.refreshSub) {
      logger.log('Broadcasting was closed!');
      this.refreshSub.unsubscribe();
      this.refreshSub = null;
    }
    // also set the last bradcast location to be null, that way, if bradcasting is restarted
    // current location goes to the db again.
    if (this.locationBS.getValue() !== null) {
      this.locationBS.next(null);
    }
  }

  /**
   * Start broadcasting the location  the the obeserver.
   * @param interval duration in millisectiodns.
   */
  public async startBroadcastLocation() {
    // cancel the previous if any.
    const interval: number = 10 * 60 * 1000;
    this.stopBroadcastingLocation();

    this.refreshRate = interval;

    // create
    this.refreshTimer$ = timer(100, this.refreshRate);
    this.refreshSub = this.refreshTimer$.subscribe(async t => {
      logger.log('-- processing the location ');

      const currPos = await promiseWraper(this.getCurrentPosition());
      if (currPos.success && !!currPos.data) {
        // only push, if location is changed.
        const curr = currPos.data;
        const prev = this.locationBS.getValue();
        if (
          prev == null ||
          prev.coords.latitude.toFixed(8) !== curr.coords.latitude.toFixed(8) ||
          prev.coords.longitude.toFixed(8) !== curr.coords.longitude.toFixed(8)
        ) {
          // logger.log("Geo Location changed to: ", curr);
          this.locationBS.next(curr);
        } else {
          logger.log('Geo location was not changed, nothing was broadcasted.', curr);
        }
      } else {
        logger.log('Error receiving the lat/long. Nothing was broadcast!');
      }
    });
  }
  async isGpsBroadCasting() {
    return false;
  }
}
