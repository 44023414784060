import { Component, Renderer2, ElementRef ,ViewChild, OnInit, inject} from '@angular/core';
import { UtilityService } from '@trent/services/utility.service';
import { PaneType } from '@trent/models/UI/menu-data';
import { PageHtml, PageHtmlTemplate, PageHtmlType } from '@trent/models/cms/page-html';
import { PageMetaTag } from '@trent/models/cms/page-meta-tag';
import { SeoService } from '@trent/services/seo.service';
import { TprContactPageHtml } from '@trent/models/cms/tpr-contact-page-html';
import { EventService, IEventListener } from '@trent/services/event.service';
import { Subscription, Observable } from 'rxjs';

/**
 * @author MKN 
 * Contact us parent component
 */

@Component({
  selector: 'trent-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})

export class ContactUsComponent implements OnInit{
  isHandset:boolean;
  seo: SeoService = inject(SeoService);
  page: TprContactPageHtml = new TprContactPageHtml();
  pageTemplate: PageHtmlTemplate;
  @ViewChild('slider') slider:ElementRef;
  @ViewChild('formSection') formSection:ElementRef;

  formIndex = 0;
  clientColor = 'primary';
  dealerColor: string;
  public eventListners: IEventListener[] = [];
  private subs: Subscription[] = [];
// Here Renderer2 using add or remove the class in the element
  constructor(private _ren: Renderer2, private us: UtilityService, private es: EventService,) { }
  ngOnInit(): void {
    this.loadPageHtml();
    this.us.isHandset$.subscribe(h => this.isHandset = h);
  }

  /** Dynamic SEO meta tags for the current page. This function will only be used in angular universal
   * These keywords are dynamic, i.e based upon the current url and these will be added to the static tags
   * already defined in the database for this page (PageHtml) collection.
   */
  dynamicMetaTags<T extends PageHtml>(p: T): PageMetaTag[] {
    return [
      { content: 'text/html; charset=utf-8', 'http-equiv': 'Content-Type' },
      { keyword: 'tuvalu pride truck, PgeNm, pride group, toronto' }
    ];
  }

  loadPageHtml<T extends PageHtml>() {
    // Setup the ID and page Html type.
    this.pageTemplate = {
      pid: 'contact',
      pageType: PageHtmlType.tprContact //  PageHtmlType.pageHtml
    };
    // Fire the page html edit event so that edit html can be utilized by admin on the top menu.
    this.es.emit<PageHtmlTemplate>(this.es.menuShowHtmlEdit, {...this.pageTemplate});

    // Download the page html
    const s = this.seo.loadPageHtml(this.pageTemplate.pid, (p: T) => this.dynamicMetaTags(p), TprContactPageHtml)
      .subscribe(p => {
        this.page = p;
        if (!!this.page) {
          this.es.emit(this.es.app_setTitle, this.page.title);
        }
      });
    this.subs.push(s);
  }

  onClient(){
  //  this._ren.removeClass(this.slider.nativeElement, 'move-slider');
  //   this._ren.removeClass(this.formSection.nativeElement, 'form-section-move');
  // this.activePane = 'left';
  this.formIndex = 0;
  this.clientColor = 'primary';
  this.dealerColor = undefined;
  }

  onDealer(){
    // this._ren.addClass(this.formSection.nativeElement, 'form-section-move');
    // this._ren.addClass(this.slider.nativeElement, 'move-slider');
    // this.activePane = 'right';
    this.formIndex = 1;
    this.dealerColor = 'primary';
    this.clientColor = undefined;
  }
}