import { DeviceIdCapService } from './device-id-cap.service';
import { Injectable } from '@angular/core';
import { IdeviceIdService } from './idevice-id.service';
import { IDeviceIdComponents } from '@trent/models/fcm/fcm-token-data';
import { DeviceIdCoreService } from './device-id-core.service';
import { promiseWraper } from '@trent/models/utility';
import { Platform } from '@ionic/angular';
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { logger } from '@trentm/log/logger';

/** Do not inject this service in any component. Use @class IdeviceIdService instead.
 * Or better yet, use the @class SingletonService's public property. This this class is singlton also but 
 * it is not imported at the app level to reduce the laods. Singlton class has the only copy in it.
 */
@Injectable()
export class DeviceIdCdvaService extends DeviceIdCoreService implements IdeviceIdService {

  private deviceIdCap: DeviceIdCapService;

  constructor(private platform: Platform, private device: Device) {
    super();

    // assign responsibility to capacitor if this is a capacitor version.
    if (this.platform.is('capacitor')) {
      this.deviceIdCap = new DeviceIdCapService();
    }
  }

  async platformInfo(): Promise<string> {
    // use capacitor if available
    if (!!this.deviceIdCap) {
      return this.deviceIdCap.platformInfo();
    }
    return this.getPlatformInfo(await this.getDeviceId(), 'cdva');
  }

  public async getDeviceId() {
    // use capacitor if available
    if (!!this.deviceIdCap) {
      return this.deviceIdCap.getDeviceId();
    }

    const d = await promiseWraper(this.platform.ready());
    if (d.success) {
      logger.log('Device UUID is: ' + this.device.uuid);
      const result: IDeviceIdComponents = {
        deviceId: this.device.uuid,
        components: [
          { key: 'model', value: this.device.model },
          { key: 'platform', value: this.device.platform },
          { key: 'manufacturer', value: this.device.manufacturer },
          { key: 'serial', value: this.device.serial }
        ],
        updatedOn: new Date()
      };
      return result;
    } else {
      return {
        deviceId: 'cdva-device-id-not-available',
        components: [],
        updatedOn: new Date()
      };
    }
  }
}
