import { getSpecialFeeCondition, InvoiceItemCat, InvoiceItemNameType, SpecialItemTypeOptionalCombDb, SpecialItemTypeOptionalDb } from './invoice-item-type';
import { TransactionType, validateTransacType } from './invoice-type';
import { calculateTax, getTotalTax } from '../tax/tax-handler';
import { instanceToInstance, Exclude, Expose, plainToInstance, Type } from 'class-transformer';
import { Min, IsDate, IsDefined, IsNumber, IsEnum, validateSync, ValidatorOptions, ValidateIf, Max, ValidateNested, MaxLength } from 'class-validator';
import { round, isNil, isNumber, isArray } from 'lodash';
import { calculateServiceFee, calculateEnvFee, calculateVehLicFee, calculateGapInsurance, calculateWalkAwayInsurance, calculateWaiverInsurance, calculateGapInsuranceComb, calculateWalkAwayInsuranceComb, calculateWaiverInsuranceComb } from '../service-fee/service-type';
import { PaymentMethod } from '../payment-method/i-payment-method';
import { IPriceItem, ItemStatus, initPriceItem, subtractPriceItem, addPriceItem, IItemDetail, initPriceSummary, roundObj } from './i-item';
import { ServiceType } from '../service-fee/i-service-item';
import { calculateTransactionFee } from '../payment-method/payment-fn';
import { IValidationMsg } from '@trent/models/error-handling';
import { TransactionSummary } from '@trent/models/finance-transaction/transaction-b';
import { addDays, startOfDay } from 'date-fns';
import { logger } from '../../log/logger';
import { toValidationError } from '../../utility/validation-helper';
import { sanitizeDate, sanitizeDateIPoint } from '../../utility/sanitize-helper';

import { BaseFee } from './base-fee';
import { Mileage } from '../../inspection/mileage';
import { RentalPlan } from '../../rental/rental-plan';
import { IPeriod } from '../finance-input/i-method';
import { MileageType } from '../../rental/milage-type';
import { mileageUnitString } from '../../product/mileage-unit';
import { Order } from '../order/order';
import { FinanceInput } from '../finance-input/finance-input-handler';
import { ITaxItem } from '../tax/i-tax-item';

export type InvoiceItemType = 'rent' | 'mileage' | 'damage' | 'other' | 'adminFee' | 'minMileage' | 'securityDeposit' | 'credit';
/** Order item inside the order */
@Exclude()
export class InvoiceItem implements IPriceItem {

  //#region  Sandhu Props
  /** ref transaction index */
  refTid: string;

  /** unique number id (within the scope of the class only). This is linked to order-> schPayments
   * to ensure a track is maintained. */
  @Expose()
  itemId: number;


  /** Linked invoice item id from vendor invoice item to customer invoice items. */
  @Expose()
  contingentItemId: number;

  //#endregion

  /** product / bid / driver etc. */

  /** associated order related to this item. Note that if an order is assigned to an item, that item is now frozen */
  @Expose()
  orderId: string;


  /** Credit or debit entry as related to Locus loop.  */
  @Expose()
  invoiceType: TransactionType;

  /** Details  */
  @Expose()
  name: InvoiceItemNameType; // string;

  @Expose()
  itemCat: InvoiceItemCat = InvoiceItemCat.Revenue;


  /** Type of service  */
  @Expose()
  serviceType: ServiceType;

  /** Number of units . Example number of days */
  @Expose()
  @Min(0)
  quantity = 1;

  @Expose()
  @IsEnum(ItemStatus)
  itemStatus = ItemStatus.quote;

  /** One time Admin fe applies only for first installment */
  // @Expose()
  // @Min(0)
  // oneTimeAdminFee = 0;

  get isFrozen() { return !isNil(this.orderId); } //  this.itemStatus >= 0;}

  // @Expose()
  // @IsBoolean()
  // isLocked: boolean;

  /** Amount per unit before any taxes / discount is added.
   * Ex. rent per day
  */
  @Expose()
  @IsNumber()
  unitPrice: number;

  @Expose()
  taxJuris: string;

  get amount() { return round(this.quantity * this.unitPrice, FinanceInput.fin.rdigit); } // private

  @Expose()
  tax: { [key: string]: number } = {};


  @Expose()
  taxTypes: string[];

  // @Expose()
  // taxJurisDiction: string;

  @Expose()
  // @IsNumber()
  totalAmount: number;


  /** date when payment is due */
  @Expose()
  @IsDate({ message: 'Payment date is required' })
  dueDate: Date;

  /** period for which service is applicable, e.g. rent payment for Jan 1, 2021 to Jan 7, 2021 */
  @Expose()
  @ValidateIf(r => r.invoiceItemType === 'rent' || r.invoiceItemType === 'mileage')
  @IsDefined()
  period: IPeriod;

  /** Service fee charged on the order. Note: it is saved in the subItem property. getter is just for easy
   * access.
  */
  get serviceFee(): BaseFee {
    return this.subItems.find(x => x.type == 'serviceFee');
  }

  /** Service fee charged on the order. Note: it is saved in the subItem property. getter is just for easy
   * access.  */
  get discount(): BaseFee {
    return this.subItems.find(x => x.type === 'discount');
  }

  // @Expose({ toPlainOnly: true })
  get totalTax() {
    return getTotalTax(this.tax);
  }


  /**
   * Sub-items inside the invoice items. Eg. Service fee, License fee, environment fee etc.
   */
  @Expose()
  @ValidateNested({ message: 'Related Special Items' })
  @Type(() => BaseFee)
  subItems: BaseFee[] = [];

  @Expose()
  @Min(0, { message: 'Maximum 0% ' })
  @Max(1, { message: 'Maximum 100% ' })
  @ValidateIf(x => x.itemCat === InvoiceItemCat.RefundRevenue)
  serviceFeeRefReten: number;

  /** Original related item. Only needed for refund purposes.
   * Not to be saved in the db.*/
  @Expose()
  @Type(() => InvoiceItem)
  @ValidateIf(x => x.itemCat === InvoiceItemCat.RefundRevenue)
  origItem: InvoiceItem;

  /** Minimum milage $ amount charged at the time of Invoice creation. Can be Zero if not applicable */
  @Expose()
  @Min(0)
  minMileageAmount = 0;

  /** Actual milage $ amount charged at the end of the billing period. */
  // @Expose()
  // @Min(0)
  // actualMileageAmount: number;

  /** Mileage parameter added at the time invoice setup and updated every billing cycle */
  @Expose()
  @ValidateIf(x => x.invoiceItemType === 'mileage')
  @Type(() => Mileage)
  mileage: Mileage;

  @Expose()
  invoiceItemType: InvoiceItemType;

  @Expose()
  @MaxLength(50)
  comments: string = '';

  /** UI helper prop will not be saved in DB */
  isNewItem = false;

  //#region  Sandhu static
  public static parse(obj) {
    // public static parse<T extends IPoint>(obj, ipointType?: { new(...args: any[]): T }) {
    try {
      if (obj == null) { return null; }
      // obj = sanitizeDateIPoint(obj, ipointType);
      obj = sanitizeDateIPoint(obj);
      const m = plainToInstance<InvoiceItem, any>(InvoiceItem, obj);
      m.sanitize();
      return m;
    } catch (error) {
      logger.error('Error happened during parse', error);
      return null;
    }
  }
  public static parseInvoiceItemArray(obj: any[]): InvoiceItem[] {
    const r = !!obj ? obj.map(o => InvoiceItem.parse(o)) : null;
    return r;
  }

  public static buildReturnItem(
    orig: InvoiceItem,
    serviceFeeRetnPerct?: number,
    qty?: number, unitPrice?: number,
    oTransactionSumm?: TransactionSummary): InvoiceItem {

    if (!isNumber(serviceFeeRetnPerct)) { serviceFeeRetnPerct = 1.0; }

    if (!(serviceFeeRetnPerct >= 0 && serviceFeeRetnPerct <= 1.0)) {
      throw new Error('Invalid Service fee retentaion. Retention pct must be between > 0 and < 1.0');
    }

    if (!isNumber(qty)) { qty = orig.quantity; }
    if (!isNumber(unitPrice)) { unitPrice = orig.unitPrice; }

    const r = orig.clone();
    r.origItem = orig;
    r.orderId = undefined;
    r.invoiceType = TransactionType.debit;
    r.itemCat = InvoiceItemCat.RefundRevenue;
    r.serviceFeeRefReten = serviceFeeRetnPerct;
    r.dueDate = startOfDay(new Date());

    // If it is not a fully refund request. both quantity and unit price must be prvided.s
    if (isNil(qty) || isNil(unitPrice) || qty <= 0 || unitPrice <= 0) {
      throw Error('Invalid quantity/unit price was provided for creating a refund entry.');
    }

    r.quantity = qty;
    r.unitPrice = unitPrice;

    if (r.amount > orig.amount) {
      throw Error('Invalid quantity/unit price was provided for creating a refund entry. Amount is ');
    }
    return r;
  }

  public static getTotal(src: InvoiceItem[], ttype: TransactionType): IPriceItem {
    try {
      if (!validateTransacType(ttype)) {
        throw new Error(`Invalid Transaction Type: ttype: ${ttype}`);
      }
      let r = initPriceItem(null);
      for (const item of src) {
        if (!validateTransacType(item.invoiceType)) {
          throw new Error(`Invalid Transaction Type: ttype: ${ttype}`);
        }
        if (item.invoiceType === ttype) {
          r = addPriceItem(item, r);
        } else {
          r = subtractPriceItem(r, item);
        }
      }
      return r;
    } catch (error) {
      logger.error(error);
      throw new Error(error);
    }
  }
  /**
  * 
  * @param items array of invoice items where details are sought after.
  * @param childPrefix is the indent to be prefixed for sub items.
  * @returns 
  */
  static getDetailsAll(items: InvoiceItem[], childPrefix = ' ', taxItems?: ITaxItem[] ) {

    let d: IItemDetail[] = [];
    let taxData: { [key: string]: number } = {};

    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      d.push({
        name: this.getCustomName(item),
        amount: item.amount,
        quantity: item.quantity,
        unitPrice: item.unitPrice,
        period: item.period,
        invoiceItemType: item.invoiceItemType,
        mileage: item.mileage,
        itemId: item.itemId,
        isNewItem: item.isNewItem,
        comments: item.comments,
        commentNumber: item.getItemNumber(items)

      });
      // Add Sub Items
      if (isArray(item.subItems)) {
        for (const subItem of item.subItems) {
          d.push({
            name: `${childPrefix}${subItem.name}`,
            amount: subItem.amount,
            // isLocked: item.isLocked
          });
        }
      }

      // build Tax data.
      for (const key in item.tax) {
        if (Object.prototype.hasOwnProperty.call(item.tax, key)) {
          const t = item.tax[key];
          if (isNumber(t) && t !== 0) {
            if (isNumber(taxData[key])) {
              taxData[key] += t;
            } else {
              taxData[key] = t;
            }
            roundObj(taxData);
          }
        }
      }
    }

    // Add the accumulated tax data.
    for (const key in taxData) {
      if(key != 'HST'){
        if (Object.prototype.hasOwnProperty.call(taxData, key)) {
          const taxName = !taxItems ? FinanceInput.fin.taxCalcDb[key].name : key;
          d.push({
            name: `Tax - ${taxName}`,
            amount: taxData[key],
            // isLocked: true
          });
        }
      }
    }

    return d;
  }

  static getPriceSummary = (src: InvoiceItem[]) => {
    const d = initPriceSummary();
    for (const i of src) {
      i.subItems = !isArray(i.subItems) ? [] : i.subItems;
      d.amount += i.amount + i.subItems.map(x => x.amount).reduce((prev, curr) => prev + curr, 0);
      d.taxableAmount += i.getTaxableAmount();

      // add tax.
      let hasTaxes = false;
      for (const key in i.tax) {
        if (Object.prototype.hasOwnProperty.call(i.tax, key)) {
          const tax = i.tax[key];
          if (isNumber(tax)) {
            hasTaxes = true;
            if (isNil(d.tax[key])) {
              d.tax[key] = tax;
            } else {
              d.tax[key] += tax;
            }
          }
        }
      }
      d.totalAmount += i.totalAmount;
    }
    roundObj(d);
    roundObj(d.tax);
    return d;
  };

  /** get custom name for milage and minimum Milage (requested by Tpine Team) */
  static getCustomName(item: InvoiceItem) {
    switch (item.invoiceItemType) {
      case 'minMileage':
        return 'Period Payment'; // 'Minimum Mileage';
      case 'mileage':
        return 'Overage Miles';    
      case 'rent':
        return 'Per Day Charges';    
      default:
        return item.name;

    }
  }
  //#endregion


  //#region Special Items

  addAdminFee(amount: number) {
    let a = new BaseFee();
    a.amount = amount;
    a.isTaxable = true;
    a.name = 'Admin Fee';
    a.type = 'admin';
    this.addSubItem(a);
  }

  /**
   * Add special item via this method. Do not use this.subItems.push
   * @param subItem 
   */
  addSubItem(subItem: BaseFee) {
    // TODO: add any special tests that need to be added to subitem.
    // custom items can be added as duplicate
    if (subItem.type !== 'custom' && this.subItems.some(x => x.type === subItem.type)) {
      // Duplicate entry not allowed for certain item types.
      throw Error('Error Adding subItem to Invoice Item. Duplicate special entry is not allowed!');
    }
    this.subItems.push(subItem);
  }

  constructor() {
    // this.environmentalFee = new BaseFee();
    // this.serviceFee = new BaseFee();
    // this.vehicleLicensingFee = new BaseFee();
  }

  // setItemId(id: number) { this._itemId = id;}
  getTaxableAmount() {
    let taxableAmount = this.subItems.reduce((prev, a) => prev + a.taxableAmount, 0);
    // taxableAmount += !this.environmentalFee.isTaxable ? 0 : this.environmentalFee.amount;
    taxableAmount += this.itemCat >= InvoiceItemCat.Revenue ? this.amount : 0;
    taxableAmount += this.minMileageAmount;
    // taxableAmount += this.serviceFee?.isTaxable ? 0 : this.serviceFee?.amount;
    // taxableAmount += this.vehicleLicensingFee.isTaxable ? 0 : this.vehicleLicensingFee.amount;
    // taxableAmount += this.oneTimeAdminFee;
    return taxableAmount;
  }

  private updateTotalAmountReceivable(taxJuris: string, iTaxItems?: ITaxItem[]) {
    const taxableAmount = this.getTaxableAmount();
    this.tax = calculateTax(taxJuris, this, taxableAmount, iTaxItems);

    this.totalAmount = round(this.amount + this.totalTax + this.subItems.reduce((prev, a) => prev + a.amount, 0) + this.minMileageAmount,
      FinanceInput.fin.rdigit);
  }

  private updateTotalAmountPayable(taxJuris: string, iTaxItems?: ITaxItem[]) {
    const taxableAmount = this.getTaxableAmount();

    this.tax = calculateTax(taxJuris, this, taxableAmount, iTaxItems);
    // tslint:disable-next-line:max-line-length
    this.totalAmount = round(this.amount + this.totalTax + this.subItems.reduce((prev, a) => prev + a.amount, 0) + this.minMileageAmount,
      FinanceInput.fin.rdigit);

    // tslint:disable-next-line:max-line-length
    // this.totalAmount = round(this.amount + this.serviceFee.amount + this.totalTax + this.minMileageAmount + this.oneTimeAdminFee - this.discount, FinanceInput.fin.rdigit);
  }

  /** Public function to update the total price of the order item.  */

  updateTotalAmount(paymentMethod: PaymentMethod, addOns?: (SpecialItemTypeOptionalDb | SpecialItemTypeOptionalCombDb) [], iTaxItems?: ITaxItem[]) {
    if (this.isFrozen) {
      throw new Error('Update can not be calculated on a frozen invoice item.');
    }
    if (isNil(this.invoiceType)) {
      throw Error('Programming ERROR, order Credit/debit entry must be defined on the invoice item.');
    }

    if (this.itemCat === InvoiceItemCat.RefundRevenue) {
      this.updateTotalAmountRefund(this.taxJuris, paymentMethod);
      return;
    }

    // if (rentalPlan.mileageType !== MileageType.unlimited) {
    //   this.mileage = new Mileage();
    //   this.minMileageAmount = rentalPlan.getMinMilageAmount();
    // }
    const spcCond = getSpecialFeeCondition(this.itemCat, addOns);

    // tslint:disable-next-line:max-line-length
    // this.serviceFee = !isNil(this.serviceType) && spcCond.serviceFee ? calculateServiceFee(this.amount, this.serviceType, this.invoiceType, paymentMethod) : 0;
    if (!isNil(this.serviceType && spcCond.serviceFee && FinanceInput.fin.specialFeeNN[this.serviceType].jurisdiction[this.taxJuris].serviceFee)) {
      this.addSubItem(calculateServiceFee(this, this.serviceType, this.invoiceType, paymentMethod));
    }
    if (!isNil(this.serviceType && spcCond.environmentFee && FinanceInput.fin.specialFeeNN[this.serviceType].jurisdiction[this.taxJuris].environmentFee)) {
      // tslint:disable-next-line:max-line-length
      this.addSubItem(calculateEnvFee(this.taxJuris, this, this.serviceType, this.invoiceType, paymentMethod));
    }
    if (!isNil(this.serviceType && spcCond.vehLicLicensingFee &&  FinanceInput.fin.specialFeeNN[this.serviceType].jurisdiction[this.taxJuris].vehicleLicensingFee)) {
      // tslint:disable-next-line:max-line-length
      this.addSubItem(calculateVehLicFee(this.taxJuris, this, this.serviceType, this.invoiceType, paymentMethod));
    }
    if ((this.serviceType && spcCond.gapInsurance && FinanceInput.fin.specialFeeNN[this.serviceType].jurisdiction[this.taxJuris].gapInsurance)) {
      // tslint:disable-next-line:max-line-length
      this.addSubItem(calculateGapInsurance(this.taxJuris, this, this.serviceType, this.invoiceType, paymentMethod));
    }
    if ((this.serviceType && spcCond.walkAwayInsurance && FinanceInput.fin.specialFeeNN[this.serviceType].jurisdiction[this.taxJuris].walkAwayInsurance)) {
      // tslint:disable-next-line:max-line-length
      this.addSubItem(calculateWalkAwayInsurance(this.taxJuris, this, this.serviceType, this.invoiceType, paymentMethod));
    }
    if ((this.serviceType && spcCond.waiverInsurance && FinanceInput.fin.specialFeeNN[this.serviceType].jurisdiction[this.taxJuris].waiverInsurance)) {
      // tslint:disable-next-line:max-line-length
      this.addSubItem(calculateWaiverInsurance(this.taxJuris, this, this.serviceType, this.invoiceType, paymentMethod));
    }
    // COMB
    if ((this.serviceType && spcCond.gapInsuranceComb && FinanceInput.fin.specialFeeNN[this.serviceType].jurisdiction[this.taxJuris].gapInsuranceComb)) {
      this.addSubItem(calculateGapInsuranceComb(this.taxJuris, this, this.serviceType, this.invoiceType, paymentMethod));
    }
    if ((this.serviceType && spcCond.walkAwayInsuranceComb && FinanceInput.fin.specialFeeNN[this.serviceType].jurisdiction[this.taxJuris].walkAwayInsuranceComb)) {
      this.addSubItem(calculateWalkAwayInsuranceComb(this.taxJuris, this, this.serviceType, this.invoiceType, paymentMethod));
    }
    if ((this.serviceType && spcCond.waiverInsuranceComb && FinanceInput.fin.specialFeeNN[this.serviceType].jurisdiction[this.taxJuris].waiverInsuranceComb)) {
      // tslint:disable-next-line:max-line-length
      this.addSubItem(calculateWaiverInsuranceComb(this.taxJuris, this, this.serviceType, this.invoiceType, paymentMethod));
    }
    let transactionFee = 0;
    // let total =
    // this.serviceFee += calculateTransactionFee()
    switch (this.invoiceType) {
      case TransactionType.credit:
        // Locus loop receive money from the customer.
        // Calculate Initial total, so that transaction fee amount can be calculated.
        this.updateTotalAmountReceivable(this.taxJuris, iTaxItems);
        transactionFee = !isNil(this.serviceType) ? calculateTransactionFee(paymentMethod, this, this.totalAmount) : 0;

        // Now that transaction fee is known, recalculate the tax and total.
        // this.serviceFee += transactionFee;
        if (!!this.serviceFee) {
          this.serviceFee.amount = round(this.serviceFee.amount, FinanceInput.fin.rdigit);
        }
        this.updateTotalAmountReceivable(this.taxJuris, iTaxItems);
        break;

      case TransactionType.debit:
        // TODO: Receivable function is not fully tested.

        // this.serviceFee.amount = -1.0 * this.serviceFee.amount;
        this.updateTotalAmountPayable(this.taxJuris, iTaxItems);
        transactionFee = -1.0 * calculateTransactionFee(paymentMethod, this, this.totalAmount);

        // Now that transaction fee is known, recalculate the tax and total.
        // this.serviceFee += transactionFee;
        this.updateTotalAmountPayable(this.taxJuris, iTaxItems);
        break;
      default:
        throw new Error(`Invalid invoice type provided [${this.invoiceType}] is not implemented`);
    }

  }

  /** Update total for refund items. Note: Service fee is already adjusted by @method buildReturnItem */
  private updateTotalAmountRefund(taxJuris: string, paymentMethod: PaymentMethod) {
    switch (this.invoiceType) {
      case TransactionType.credit:

        // this.serviceFee = -this.origItem.serviceFee * (1.0 - this.serviceFeeRefReten);
        this.serviceFee.amount = this.origItem.serviceFee.amount * (1.0 - this.serviceFeeRefReten);
        // if the return quantity / unit price was modified, the service fee need to be prorated.
        this.serviceFee.amount *= this.amount / this.origItem.amount;
        this.updateTotalAmountPayable(taxJuris);
        break;
      case TransactionType.debit:
        // this.serviceFee = -this.origItem.serviceFee * (1.0 - this.serviceFeeRefReten);
        this.serviceFee.amount = this.origItem.serviceFee.amount * (1.0 - this.serviceFeeRefReten);
        // if the return quantity / unit price was modified, the service fee need to be prorated.
        this.serviceFee.amount *= this.amount / this.origItem.amount;
        this.updateTotalAmountPayable(taxJuris);
        break;

      default:
        throw new Error(`Invalid invoice type provided [${TransactionType[this.invoiceType]}] is not implemented`);
    }
  }

  getMethodFnData() {
    return { amount: this.amount, period: this.period };
  }

  //#region Sandhu pub function
  validateSync(options?: ValidatorOptions, order?: Order): IValidationMsg {

    const m = validateSync(this, options);
    const r = toValidationError(m);
    if (this.itemCat === InvoiceItemCat.RefundRevenue && this.amount > this.origItem.amount) {
      r['unitPrice'] = [`Return amount cannot be more ${this.origItem.amount}`];
    }
    if (order && !!this.isNewItem) {
      if (!order.availableDates.includes(this.dueDate)) {
        r['dueDate'] = [`Due date is not valid ${this.origItem.amount}`];
      }
    }
    if (this.invoiceItemType !== 'credit' && this.amount <= 0) {
      r['unitPrice'] = [`Unit Price cannot be less than or equal to zero`];
    }
    if (this.invoiceItemType === 'credit' && this.amount >= 0) {
      r['unitPrice'] = [`Unit Price form credit cannot more than or equal to zero`];
    }

    return r;
  }
  clone() {
    const t = instanceToInstance(this);
    t.sanitize();
    return t;
  }

  sanitize() {
    if (this.dueDate) { this.dueDate = sanitizeDate(this.dueDate); }
    if (this.period?.from) { this.period.from = sanitizeDate(this.period.from); }
    if (this.period?.to) { this.period.to = sanitizeDate(this.period.to); }
    this.origItem?.sanitize();
  }

  //#endregion
  odometerStringFn(rentalPlan: RentalPlan): string {
    if (!this.mileage) {
      throw new Error('Method not implemented.');
    }
    switch (rentalPlan.mileageType) {
      case MileageType.unlimited:
        return null;
      case MileageType.flexible:
      case MileageType.minimum:
      case MileageType.maximum:
        return `${this.mileage.startOdometer} ${mileageUnitString(this.mileage.mileageUnit)} - ${this.mileage.endOdometer} ${mileageUnitString(this.mileage.mileageUnit)}`;
      default:
        throw new Error(`${rentalPlan.mileageType} not implemented for odometerStringFn`);

    }
  }

  getItemNumber(items: InvoiceItem[]): number {

    const c = items.filter(f => f.comments).map((e, i) => {
      return { itemId: e.itemId, commentNumber: i + 1 };
    });


    return c.find(f => f.itemId === this.itemId)?.commentNumber;
  }
  setSecurityDepositItem(deposit: number, dueDate: Date) {
    this.name = 'Security Deposit';
    this.unitPrice = deposit; 
    this.quantity = 1;
    this.dueDate = dueDate;
    this.itemCat = InvoiceItemCat.SecurityDeposit;
    this.invoiceItemType = 'securityDeposit';
  }
  setAdminFeeItem(adminFee: number, dueDate?: Date) {

    this.invoiceItemType = 'adminFee';
    this.unitPrice = adminFee;
    this.quantity = 1.0;
    this.name = 'Admin Fee (One Time)';
    this.dueDate = dueDate;
  }
  
  setRentItem(numberOfDays: number, ratePerDay: number, serviceType: ServiceType, startDate: Date, name: InvoiceItemNameType ) {
    this.invoiceItemType = 'rent';
    this.unitPrice = ratePerDay;
    this.serviceType = serviceType;
    this.name = name;
    this.quantity = numberOfDays;
    this.period = { from: startDate, to: addDays(startDate, numberOfDays) };
    this.dueDate = this.period.to;
  }

  setReserveAmountItem(reserveAmount: number, dueDate: Date) {
    this.name = 'Reserve';
    this.unitPrice = reserveAmount; 
    this.quantity = 1;
    this.dueDate = dueDate;
    this.itemCat = InvoiceItemCat.SecurityDeposit;
    this.invoiceItemType = 'securityDeposit';
  }
}
