import { PdfInvoiceHandler } from './../models/pdf/pdf-invoice';
import { IAssignedContractData, IBidDocData, IBidGuarantorData, IBidOrderData } from './../models/nego-terms-base/i-bid-order-data';
import { BaseHttpService } from '@trent/services/base-http.service';
import { FirestoreService } from '@trent/services/firestore.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { instanceToPlain } from 'class-transformer';
import { tap, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { BidContractDocType, NegoTermsBase } from '@trent/models/nego-terms-base/nego-terms-base';
import { PagingObesrvable } from '../models/observable-util/paging-obesrvable';
import { BidParam, bidSearchServerQuery, bidSearchServerCountQuery } from '../models/bid/bid-param';
import { Paging } from '../models/observable-util/paging';
import { Observable } from 'rxjs';
import { BidBase, IContractNo } from '../models/bid/bid-base';
import { Invoice } from '@trent/models/finance/invoice/invoice';

// import "pdfmake/build/pdfmake";
// import "pdfmake/build/vfs_fonts";
// import pdfMake from "pdfmake/build/pdfmake";
// import pdfFonts from "pdfmake/build/vfs_fonts";
import { Order } from '@trent/models/finance/order/order';
import { TDocumentDefinitions } from 'pdfmake/interfaces';
import { CustomerCompanySummary, VendorCompanySummary } from '@trent/models/company/company-fleet';
import { DbStatus } from '@trent/models/base';
import { CompanyStatus } from '@trent/models/company/company-status';
import { UtilityService } from './utility.service';
import { query } from '@angular/fire/firestore';
import { where } from 'firebase/firestore';
// pdfMake.vfs = pdfFonts.pdfMake.vfs;

var pdfMake, pdfFonts;
@Injectable({ providedIn: 'root' })
export class BidService extends BaseHttpService {

  constructor(store: Store, private db: FirestoreService, private http: HttpClient, private us: UtilityService) {
    super(store);
    this.apiName = 'api';
    if (this.us.isPlatformBrowser) {
      this.loadPdfMake();
    }
  }

  /**
   * This is a heavy library, only load on the client side when the service is initiated.
   */
  async loadPdfMake() {
    try {
      await import("pdfmake/build/pdfmake");
      pdfMake = window["pdfMake"];

      pdfFonts = await import("pdfmake/build/vfs_fonts");
      // pdfFonts = window["pdfFonts"];
      pdfMake.vfs = pdfFonts?.pdfMake?.vfs;
    } catch (error) {
      console.error('Error loading pdf make library');
    }
  }

  getNewDocId() {
    return this.db.docId(BidBase.collectionName);
  }

  /**
  * @author - MKN
  * @purpose - get secured url from firebase storage
  */
  public getSecureUrl(cid: string | number, bidid: string | number, docType: BidContractDocType) {
    // Prepare the post data
    const headers = this.addBearerToken();
    return this.http.post<{ url: string }>(this.getApiUrl('/bid/get-contract-secure-url'),
      { cid, bidid, docType },
      // tslint:disable-next-line:object-literal-shorthand
      { headers: headers })
      .pipe(map(x => (!!x && !!x.url && x.url.length) ? x.url[0] : ''))
      .toPromise();
  }

  public create(bidOrderData: IBidOrderData) {
    bidOrderData.negoTerms = bidOrderData.negoTerms.toFirebaseObj();
    bidOrderData.order = bidOrderData.order.toFirebaseObj();
    if (!!bidOrderData.ccData) {
      bidOrderData.ccData.billingAddress = instanceToPlain(bidOrderData.ccData?.billingAddress) as any;

    }
    // Prepare the post data
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.token });
    return this.http.post<{ id: string | number, data: BidBase }>(this.getApiUrl('/bid/create'),
      // Server validation before creating the entry.
      {
        data: bidOrderData
      },
      { headers: headers })
      .pipe(
        tap(res => console.log('response: ', res))
      );
  }

  public counterVendor(data: IBidOrderData) {
    data.negoTerms = data.negoTerms.toFirebaseObj();
    data.order = !!data.order ? data.order.toFirebaseObj() : null;
    data.bidDocuments = !!data.bidDocuments ? data.bidDocuments.toFirebaseObj() : null;
    // bidId: string | number, b: NegoTermsBase, cid: string | number


    // Prepare the post data
    const headers = this.addBearerToken();
    // Server validation before creating the entry.
    return this.http.post<{ id: string | number, data: BidBase }>(this.getApiUrl('/bid/counter-vendor'),
      {
        data
      },
      { headers: headers })
      .pipe(
        tap(res => console.log('response: ', res))
      );
  }

  public counterCustomer(data: IBidOrderData) {
    data.negoTerms = data.negoTerms.toFirebaseObj();
    data.order = !!data.order ? data.order.toFirebaseObj() : null;
    // bidId: string | number, b: NegoTermsBase, cid: string | number


    // Prepare the post data
    const headers = this.addBearerToken();
    // Server validation before creating the entry.
    return this.http.post<{ id: string | number, data: BidBase }>(this.getApiUrl('/bid/counter'),
      {
        data
      },
      { headers: headers })
      .pipe(
        tap(res => console.log('response: ', res))
      );
  }

  createDraftBid(bidOrderData: IBidOrderData) {
    bidOrderData.negoTerms = bidOrderData.negoTerms.toFirebaseObj();
    // Prepare the post data
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.token });
    return this.http.post<{ id: string | number, data: BidBase }>(this.getApiUrl('/bid/create-draft-bid'),
      // Server validation before creating the entry.
      {
        data: bidOrderData
      },
      { headers: headers })
      .pipe(
        tap(res => console.log('response: ', res))
      );
  }

  updateContract(data: IBidOrderData) {
    data.negoTerms = data.negoTerms.toFirebaseObj();
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.token });
    return this.http.post<{ id: string | number, data: BidBase }>(this.getApiUrl('/bid/update-contract'),
      // Server validation before creating the entry.
      {
        data
      },
      { headers: headers })
      .pipe(
        tap(res => console.log('response: ', res))
      );
  }

  terminateContract(bidId: string | number) {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.token });
    return this.http.post<{ id: string | number, data: BidBase }>(this.getApiUrl('/bid/terminate-contract'),
      // Server validation before creating the entry.
      {
        bidId
      },
      { headers: headers })
      .pipe(
        tap(res => console.log('response: ', res))
      );
  }

  getCustomerCompanySummary(pid: string | number, cid: string | number,) {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.token });
    return this.http.post<{ lesseeStatus: CompanyStatus, dbStatus: DbStatus, summary?: CustomerCompanySummary }>(this.getApiUrl('/bid/get-customer-company-summary'),
      // Server validation before creating the entry.
      {
        pid,
        cid
      },
      { headers: headers })
      .pipe(
        tap(res => console.log('response: ', res))
      );
  }

  /**
   * Get vendor company details
   * @param cid 
   * @returns 
   */
  getVendorCompanySummary(cid: string | number,) {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.token });
    return this.http.post<{ lessorStatus: CompanyStatus,name : string, dbStatus: DbStatus, summary?: VendorCompanySummary }>(this.getApiUrl('/bid/get-vendor-company-summary'),
      // Server validation before creating the entry.
      {
        cid
      },
      { headers: headers })
      .pipe(
        tap(res => console.log('response: ', res))
      );
  }
  getContractNumber(cid: string | number) {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.token });
    return this.http.post<IContractNo>(this.getApiUrl('/bid/get-contract-number'),
      // Server validation before creating the entry.
      {
        cid
      },
      { headers: headers })
      .pipe(
        tap(res => console.log('response: ', res))
      );
  }

  createVendorContract(data: IBidOrderData) {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.token });
    data.negoTerms = data.negoTerms.toFirebaseObj();

    return this.http.post<{ id: string, data: BidBase }>(this.getApiUrl('/bid/create-vendor-contract'),
      // Server validation before creating the entry.
      {
        data
      },
      { headers: headers })
      .pipe(
        tap(res => console.log('response: ', res))
      );
  }

  createAssignedContract(data: IAssignedContractData) {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.token });
    data.negoTermsAssigned = data.negoTermsAssigned.toFirebaseObj();

    return this.http.post<{ id: string, data: BidBase }>(this.getApiUrl('/bid/create-assigned-contract'),
      // Server validation before creating the entry.
      {
        data
      },
      { headers: headers })
      .pipe(
        tap(res => console.log('response: ', res))
      );
  }

  updatedAssignedContract(data: IAssignedContractData) {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.token });
    data.negoTermsAssigned = data.negoTermsAssigned.toFirebaseObj();

    return this.http.post<{ id: string, data: BidBase }>(this.getApiUrl('/bid/update-assigned-contract'),
      // Server validation before creating the entry.
      {
        data
      },
      { headers: headers })
      .pipe(
        tap(res => console.log('response: ', res))
      );
  }

  createAssignedContractFromCustomerBid(data: IAssignedContractData) {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.token });
    data.negoTermsAssigned = data.negoTermsAssigned.toFirebaseObj();

    return this.http.post<{ id: string, data: BidBase }>(this.getApiUrl('/bid/update-assigned-contract-from-customer-bid'),
      // Server validation before creating the entry.
      {
        data
      },
      { headers: headers })
      .pipe(
        tap(res => console.log('response: ', res))
      );
  }

  terminateAssignedContract(bidId: string | number) {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.token });

    return this.http.post<{ id: string, data: BidBase }>(this.getApiUrl('/bid/terminate-assigned-contract'),
      // Server validation before creating the entry.
      {
        bidId
      },
      { headers: headers })
      .pipe(
        tap(res => console.log('response: ', res))
      );
  }

  getDraftBidInfo(bidId: string | number) {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.token });

    return this.http.post<{ name: string; email: string, ph: string, companyLegalName: string, companyName: string }>(this.getApiUrl('/bid/get-draft-bid-info'),
      // Server validation before creating the entry.
      {
        bidId
      },
      { headers: headers })
      .pipe(
        tap(res => console.log('response: ', res))
      );
  }

  // public counterByVendor(bidId: string | number, b: NegoTermsBase, cid: string | number, isVendorAcceptTC: boolean) {
  //     // Prepare the post data
  //     const headers = this.addBearerToken();


  //     // Server validation before creating the entry.
  //     return this.http.post<BidBase>(this.getApiUrl('/bid/counter'),
  //         {
  //             negoTerms: instanceToPlain(b),
  //             bidId: bidId,
  //             cid: cid,
  //             isTC: isVendorAcceptTC
  //         },
  //         { headers: headers })
  //         .pipe(
  //             tap(res => console.log('response: ', res))
  //         );
  // }


  public rejectByCustomer(bidId: string | number, b: NegoTermsBase, cid: string | number) {
    // Prepare the post data
    console.log('as submitted', b);

    const headers = this.addBearerToken();

    // Server validation before creating the entry.
    return this.http.post<BidBase>(this.getApiUrl('/bid/rejectByCustomer'),
      {
        negoTerms: instanceToPlain(b),
        bidId: bidId,
        cid: cid
      },
      { headers: headers })
      .pipe(
        tap(res => console.log('response: ', res))
      );
  }

  public rejectByVendor(bidId: string | number, b: NegoTermsBase, cid: string | number) {
    // Prepare the post data
    console.log('as submitted', b);

    const headers = this.addBearerToken();

    // Server validation before creating the entry.
    return this.http.post<BidBase>(this.getApiUrl('/bid/rejectByVendor'),
      {
        negoTerms: instanceToPlain(b),
        bidId: bidId,
        cid: cid
      },
      { headers: headers })
      .pipe(
        tap(res => console.log('response: ', res))
      );
  }

  public getAllBids() {
    return this.db.colWithIdsInjected$<BidBase>('bid');
  }

  public getBidById(id: string | number) {
    return this.db.docWithInjectedId$<BidBase>(
      `${BidBase.collectionName}/${id}`, id);
  }

  public getBidsById(ids: (string | number)[]) {
    const b = this.db.colWithIdsInjected$<BidBase>(BidBase.collectionName, ref =>
      query(ref, where('id', 'in', ids)));
    return b;
  }

  public getBidsByRentOptionId(rentOptionId: string | number) {
    console.log('rentOptionId: ', rentOptionId);
    const b = this.db.colWithIdsInjected$<BidBase>('bid', ref =>
      query(ref, where('bidNegoTerms.rentOptionId', '==', `${rentOptionId}`)));
    console.log(b);

    return b;
  }

  public getBidsByCompId(compId: string | number) {
    console.log('compId: ', compId);
    const b = this.db.colWithIdsInjected$<BidBase>('bid', ref =>
      query(ref, where('vendorCompSummary.cid', '==', `${compId}`)));
    return b;
  }

  public getAllBids_PagingObservable() {
    const p: PagingObesrvable<BidBase, BidParam> =
      new PagingObesrvable<BidBase, BidParam>(this.db, this.getAllBids_batch, this.getCount);
      console.log("@@@", p);
    return p;
  }

  public getCount(p: Paging, o: BidParam): Observable<number> {
    return this.db
      .getDocumentCount$<BidBase>(BidBase.collectionName,
        ref => bidSearchServerCountQuery(ref, o));
  }

  private getAllBids_batch(p: Paging, o: BidParam): Observable<{ [key: string]: BidBase }> {
    console.log('Bid Server called with', p.offset, p.size);
    const col = !o.bidIdForHistory ? BidBase.collectionName : `${BidBase.collectionName}/${o.bidIdForHistory}/rev`;
    return this.db
      .colWithIdsInjectedNew$<BidBase>(col,
        ref => bidSearchServerQuery(ref, o, p))
      .pipe(
        tap(arr => {
          if (arr == null || arr.length === 0) {
            console.log('All data is recevied, Paging is FULL');
            p.full = true;
          } else {
            p.lastDoc = arr[arr.length - 1];
          }
        }),
        map(arr => {
          if (!o.bidIdForHistory) {
            return arr.reduce((acc, cur) => {
              const id = cur.id;
              const data = cur;
              return { ...acc, [id]: data };
            }, {});
          } else {
            return arr.reduce((acc, cur) => {
              const id = o.bidIdForHistory.toString() + '/rev/' + cur.id;
              const data = cur;
              data.id = id;
              // data.hRootId = o.bidId;
              return { ...acc, [id]: data };
            }, {});
          }
        })
      );
  }

  // #region PDF Creation
  public createPdfServer() {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.token });
    return this.http.post(this.getApiUrl('/bid/create-pdf1'),
      // Server validation before creating the entry.
      {
        data: 'Create PDF-1'
      },
      {
        headers: headers,
        responseType: 'blob'
      });
    // .pipe(
    //   tap(res => console.log('PDF create Response: ', res))
    // );
  }

  public createPdfClient(action: 'download' | 'print' | 'open', invoice: Invoice, order: Order, bid: BidBase, pymtIdx?: number | number[]) {

    let pdfData = new PdfInvoiceHandler();
    let dd = pdfData.buildPdf(invoice, order, bid, pymtIdx) as TDocumentDefinitions;

    if (action === 'download') {
      pdfMake.createPdf(dd).download();
    } else if (action === 'print') {
      pdfMake.createPdf(dd).print();
    } else {
      pdfMake.createPdf(dd).open();
    }
  }

  /**
  * @author - MKN
  * @purpose - update signed doc and Ins certificate in nego terms
  */
  public updateContractDocument(data: IBidDocData) {
    data.bidDocuments = data.bidDocuments.toFirebaseObj();
    // Prepare the post data
    const headers = this.addBearerToken();
    // Server validation before creating the entry.
    return this.http.post<{ id: string | number, data: BidBase }>(this.getApiUrl('/bid/update-bid-contract-document'),
      {
        data
      },
      { headers: headers })
      .pipe(
        tap(res => console.log('response: ', res))
      );
  }

  public getAccountingInvoice(d: { invoiceId: string | number }) {
    // Prepare the post data
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });
    return this.http.post<any>(this.getApiUrl('/invoice/get-accounting-invoice'),
      // Server validation before creating the entry.
      {
        invoiceId: d.invoiceId,
      },
      {
        headers: headers,
        // responseType: 'blob' as 'json'  // use this to request a blow download.
      })
      .pipe(
        tap(r => console.log('response: ', r))
      );
  }

  /**
   * @author - MKN
   * @purpose - update bid guarantor in contract
   * @param data 
   * @returns 
   */
  public updateBidGuarantorInContract(data: IBidGuarantorData) {
    // Prepare the post data
    const headers = this.addBearerToken();
    // Server validation before creating the entry.
    return this.http.post<{ id: string | number }>(this.getApiUrl('/bid/update-bid-guarantor-in-contract'),
      {
        data
      },
      { headers: headers })
      .pipe(
        tap(res => console.log('response: ', res))
      );
  }

  /**
   * @author - MKN
   * @purpose - remove bid guarantor from contract
   * @param data 
   * @returns 
   */
  public removeBidGuarantorFromContract(data: IBidGuarantorData) {
    // Prepare the post data
    const headers = this.addBearerToken();
    // Server validation before creating the entry.
    return this.http.post<{ id: string | number }>(this.getApiUrl('/bid/remove-bid-guarantor-from-contract'),
      {
        data
      },
      { headers: headers })
      .pipe(
        tap(res => console.log('response: ', res))
      );
  }

  /**
  * @author - MKN
  * @purpose - get secured url from firebase storage for Bid Guarantor
  */
  public getBidGuarantorSecureUrl(cid: string | number, bidid: string | number, guarantorIndex: number, docType: string, path?: string) {
    // Prepare the post data
    const headers = this.addBearerToken();
    return this.http.post<{ url: string }>(this.getApiUrl('/bid/get-bid-guarantor-secure-url'),
      { cid, bidid, guarantorIndex, docType, path },
      // tslint:disable-next-line:object-literal-shorthand
      { headers: headers })
      .pipe(map(x => (!!x && !!x.url && x.url.length) ? x.url[0] : ''))
      .toPromise();
  }


}


  // #end region


  // #region cron jobs
  // setExpiredStatus(cJob: CronJob) {
  //     // Prepare the post data
  //     const headers = this.addBearerToken();

  //     // Server validation before creating the entry.
  //     return this.http.post<{ col: string | number }>(this.getApiUrl('/bid/setExpiredStatus'),
  //         {
  //             cJob: cJob
  //         },
  //         { headers: headers })
  //         .pipe(
  //             tap(res => console.log('response: ', res))
  //         );
  // }
  // sendNotifications(cJob: CronJob) {
  //     // Prepare the post data
  //     const headers = this.addBearerToken();

  //     // Server validation before creating the entry.
  //     return this.http.post<{ cJob: CronJob }>(this.getApiUrl('/bid/sendNotifications'),
  //         {
  //             cJob: instanceToPlain(cJob)
  //         },
  //         { headers: headers })
  //         .pipe(
  //             tap(res => console.log('response: ', res))
  //         );
  // }
  // #endregion


