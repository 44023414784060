import { State, Action, StateContext, Actions, ofAction, Selector } from '@ngxs/store';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { logger } from '@trentm/log/logger';

export interface RouterStateModel {
  currentTitle: string;
  history: string[];
}

export class Navigate {
  static readonly type = '[router] navigate';
  constructor(public payload: { url: string, recordOnly?: boolean, clearHistory?: boolean }) { }
}

export class NavigateBack {
  static readonly type = '[router] navigate back';
  /**
   * @param payload : True if only state has to be updated and no router
   * navigation is required (router has already executed.)
   */
  constructor(public payload: { recordOnly?: boolean, router?: Router }) { }
}

export class SetTitle {
  static readonly type = '[router] set current title';
  /**
   * @param payload : True if only state has to be updated and no router
   * navigation is required (router has already executed.)
   */
  constructor(public payload?: string) { }
}


@State<RouterStateModel>({
  name: 'router',
  defaults: {
    currentTitle: '',
    history: []
  }
})
@Injectable()
export class RouterState {

  constructor(private router: Router) { }

  @Selector()
  static navigateBackUrl(state: RouterStateModel) {
    const currHistory = state.history;
    if (currHistory.length > 1) {
      return currHistory[currHistory.length - 2];
    }
    return null;
  }


  @Selector()
  static canGoBack(state: RouterStateModel) {
    return state.history.length > 1;
  }

  @Selector()
  static getTitle(state: RouterStateModel) {
    return state.currentTitle;
  }


  @Action(Navigate)
  async navigate(context: StateContext<RouterStateModel>, action: Navigate) {
    // logger.log('Navigation dispatch, URL: ', action.payload.url);
    const path = action.payload.url;
    const h = context.getState().history;

    if (h.length > 1 && h[h.length - 2] === path) {
      context.dispatch(new NavigateBack({ recordOnly: action.payload.recordOnly }));
      return;
    }

    // only keep top 20 items in the history.
    const limit = 10;
    const history = (h.length > limit) ? h.slice(h.length - limit) : h.slice(0);

    // Change: 2019-04-20
    // only store the history, if record back was passed (i.e rb=true)
    if (history.length === 0) {
      history.push(action.payload.url);
    } else {
      // if record was passsed, add otherwise edit.
      if (action.payload.url.toLocaleLowerCase().indexOf('rb=true') > -1) {
        history.push(action.payload.url);
      } else {
        // updae and not add
        history[history.length - 1] = action.payload.url;
      }
    }

    // old, before 2019-04-20 change
    // history.push(action.payload.url);

    if (!(action.payload.recordOnly)) {
      await this.router.navigateByUrl(path); // .navigate([path]);
    }

    context.patchState({
      history: (action.payload.clearHistory) ? [] : history
    });

  }

  @Action(NavigateBack)
  async navigateBack(context: StateContext<RouterStateModel>, action: NavigateBack) {
    // logger.log('navigate back called');
    const currHistory = context.getState().history;
    if (currHistory.length > 1) {
      const path = currHistory[currHistory.length - 2];
      // logger.log('navigate happening: ', path);
      const history = currHistory.slice(0, currHistory.length - 1);
      if (!action.payload.recordOnly) {
        const r = (!!action.payload.router) ? action.payload.router : this.router;
        await r.navigateByUrl(path); // .navigate([path]);
      }
      context.patchState({
        history: history
      });
    }
  }


  @Action(SetTitle)
  async setTitle(ctx: StateContext<RouterStateModel>, action: SetTitle) {
    ctx.patchState({
      currentTitle: action.payload
    });
  }

}


@Injectable()
export class RouteHandler {
  constructor(private router: Router, private action$: Actions) {
    this.action$
      .pipe(ofAction(Navigate))
      .subscribe(({ payload }) => this.router.navigate([payload]));
  }
}
