import { CollectionReference, Query, query, QueryConstraint, where, orderBy, startAfter, limit } from 'firebase/firestore';
import { Paging } from '../observable-util/paging';
import { logger } from '../log/logger';
import { StoreLocation } from './store-location';


export interface StoreLocationParam {
  orderDirection?: 'desc' | 'asc';
  orderBy?: string;
  country? : string;
  sid?: string | number; // used only for client filter
  dbStatus?: number;
	sidArray?: [string | number]; //PT - to get all the locations by ids
  isPTS?: boolean,
  isDPC?: boolean,

}
export const storeLocationParamInit = (): StoreLocationParam => {
  return {
    orderBy: 'updatedAt',
    orderDirection: 'desc',
  };
};

/** Server filtering of firebase query building of query  */
export const storeLocationSearchServerQuery = (ref: CollectionReference, param: StoreLocationParam, p: Paging) => {
  let q: Query = <any>ref;
  const cons: QueryConstraint[] = [];
  cons.push(where('updatedAt', '<=', new Date()));
  cons.push(orderBy('updatedAt', 'desc'));

  if (!!param.country) {
    cons.push(where('address.country', '==', param.country));
  }
  
  if (!!param.isPTS) {
    cons.push(where('isPTS', '==', param.isPTS));
  }

  if (!!param.isDPC) {
    cons.push(where('isDPC', '==', param.isDPC));
  }

  if (!!param.sidArray && param.sidArray.length > 0) {
		cons.push(where('id', 'in', param.sidArray));
	}

  if (!!p.lastDoc) {
    logger.log('Start After Query: ', p.lastDoc[param.orderBy]);
    cons.push(startAfter(p.lastDoc[param.orderBy]));
  }
  cons.push(limit(p.size));
  q = query(ref, ...cons);
  return q;
};

/** Client Filtering of the data */
export const storeLocationSearchClientFilter = (p: StoreLocation[], o: StoreLocationParam): StoreLocation[] => {

  // only used as client filter
  if (o.sid) {
    p = p.filter((val, idx) => val.id === o.sid);
  }

  // Filter by sid array
	if (o.sidArray) {
		p = p.filter((val, idx) => o.sidArray.indexOf(val.id) > -1);
	}

  if (o.country) {
    p = p.filter((val, idx) => val.address?.country === o.country);
  }
  if (o.isPTS) {
    p = p.filter((val, idx) => val.isPTS === o.isPTS);
  }
  return p as StoreLocation[];
};

export const getStoreLocationOptionOrChildren = (o: StoreLocationParam): { [key: string]: StoreLocationParam } => {

  return undefined;
};

