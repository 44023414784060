
import { Length, IsInt, Min, validateSync, Max, IsDate, IsDecimal, IsNumber, ValidateNested, IsDefined, IsOptional, IsEnum, IsBoolean, Validate, ValidateIf, Matches, ValidatorOptions } from 'class-validator';
import { BaseModel } from '../base/base-model';
import { plainToInstance, Expose, Exclude, instanceToInstance, Type } from 'class-transformer';
import { sanitizeDateIPoint } from '../utility';
import { IValidationMsg } from '../error-handling';
import { LegacyContractValidationGroup } from './legacy-contract-base';

const lcExcelSummary: LegacyContractValidationGroup = 'lcExcelSummary';

@Exclude()
export class LCExcelSummary extends BaseModel {

    constructor() {
        super();
    }

    @Expose()
    addressUnverified: string;

    @Expose()
    cityUnverified: string;

    @Expose()
    province: string;

    @Expose()
    postal: string;

    @Expose()
    dmfReserve: string;

    @Expose()
    vin: string;

    @Expose()
    make: string;
  
    @Expose()
    model: string;
  
    @Expose()
    modelYear: number;

    public static parse(obj : any) {
        if (obj == null) { return null; }
        const m = plainToInstance<LCExcelSummary, any>(LCExcelSummary, sanitizeDateIPoint(obj));
        m.sanitize();
        return m;
    }

    validateSync(options? : ValidatorOptions): IValidationMsg {
        const r = this.validateSyncBase(this, options);
        return r;
    }

    clone() {
        const t = instanceToInstance(this);
        t.sanitize();
        return t;
    }

    sanitize() {
        // if data was recieved from firebase, date is stored as snapshot.
        super.sanitize();
    }

    createLCOriginalExcelSummary(lc : any){
        this.addressUnverified = lc.address;
        this.cityUnverified = lc.city;
        this.province = lc.province;
        this.postal = lc.postal;
        this.dmfReserve = lc.dmfReserve;
        this.vin = lc.vin;
        this.make = lc.make;
        this.model = lc.model;
        this.modelYear = lc.year;
    }

    updateLCOriginalExcelSummaryForChildren(lc : LCExcelSummary){
        this.addressUnverified = lc.addressUnverified;
        this.cityUnverified = lc.cityUnverified;
        this.province = lc.province;
        this.postal = lc.postal;
    }

    //generate address string from unverified data
    getOriginalAddressString(){
        return `${this.addressUnverified} ${this.province} ${this.postal}`;
    }
   

}
