import { Exclude, Expose, plainToInstance, instanceToInstance } from 'class-transformer';
import { PromoListBase, ListValidationGroup } from './promo-list-base';
import { sanitizeDateIPoint, toValidationError, sanitizePhone, toSentence } from '../utility';
import { ValidatorOptions, IsDefined, Validator, validateSync, Matches, ValidateIf, Length, IsBoolean, ValidationError } from 'class-validator';
import { IValidationMsg } from '../error-handling/validation-info';
import { PromoListType } from './promo-list-type';
import { PromoListItem, ItemValidationGroup } from './promo-list-item';
import { ItemStatus } from './item-status';
import { TripBase } from '../trip/trip-base';
import { pickBy, isEmpty } from 'lodash';
import { phoneRegexString, phoneRegex } from '../utility/validation-helper';
import { UserProfile } from '../user';
import { CarrierCompanySummary } from '../company/company-carrier';
import { TripStatus } from '../trip/trip-status';
import { logger } from '../log/logger';
// import { TripStatus } from '../trip';

@Exclude()
export class DriverListItem extends PromoListItem {
  constructor() {
    super();
    this.itemStatus = ItemStatus.pending;
  }

  // @Length(10, 10, { message: 'needs to be $constraint1-$constraint2 chars', groups: [keyValidition] })
  // @IsMobilePhone('en-US', {message: 'needs 10 digit phone number'})
  // @Length(11)
  @IsDefined({ message: 'needs phone number' })
  @Matches(phoneRegexString, { message: 'Enter valid phone' })
  @Expose()
  key?: string;  // this is dictionary key, added in values for ui validation, saved in db as key

  @Length(4, 48, { message: 'Name to be $constraint1-$constraint2 chars' })
  @Expose()
  name: string;

  // @Length(1, 254)
  @Expose()
  driverId: string;
  @Expose()
  phone?: string;
  // uiDescription?: string;

  @Expose()
  @IsBoolean()
  @ValidateIf(v => v.key === 'TBD')
  isPlaceHolder: boolean;

  @Expose({ toClassOnly: true })
  get uiDescription() {
    if (this.isPlaceHolder) {
      return `${(this.name)} (${this.phone})`;
    }
    return `${toSentence(this.name)} (${this.phone})`;
  }
  @Expose()
  openTrip?: { [key: string]: { description: string, updatedAt: Date } };
  // openTrip?: string | number;
  public static parse(obj) {
    if (obj == null) { return null; }
    const m = plainToInstance<DriverListItem, any>(DriverListItem, sanitizeDateIPoint(obj));
    m.sanitize();
    return m;
  }
  clone() {
    const t = instanceToInstance(this);
    // t.sanitize();
    return t;
  }
  sanitize() {
    if (!this.isPlaceHolder) {
      this.key = sanitizePhone(this.key);
    }
  }
  validateSyncGroup(group?: ItemValidationGroup): IValidationMsg {
    return this.validateSync({ groups: [group] });
  }
  validateSync(options?: ValidatorOptions, list?: DriverList): IValidationMsg {
    const r = validateSync(this, options);
    const m = toValidationError(r);
    if (!!list && !!list.isDuplicate(this.key, list)) {
      m['key'] = [`duplicate entry ${this.key}`];
    }
    // if (!!this.key && !phoneRegex(this.key)) {
    //   m['key'] = [`entry valid phone number`];
    // }
    return m;
  }
}
export type DriverListValidationGroup = 'selectMode' | 'addMode';
const selectMode: DriverListValidationGroup = 'selectMode';
const addMode: DriverListValidationGroup = 'addMode';

/** List of Drivers registered for carrier promo */
@Exclude()
export class DriverList extends PromoListBase {


  @Expose()
  @IsDefined({ message: 'list needs to be defined', groups: [addMode] })
  list: { [key: string]: DriverListItem };  // key in this case is phone number

  @IsDefined({ message: 'driver needs to be defined', groups: [selectMode] })
  entry: { [key: string]: DriverListItem };

  // @Expose()
  // uploadPhone: string;
  @ValidateIf(f => !!f.key, { groups: [addMode] })
  @Matches(phoneRegexString, { message: 'Enter valid phone1', groups: [addMode] })
  key: string; // binds to new item key to be added


  @Expose()
  carrierCompanySummary?: CarrierCompanySummary;

  public static parse(obj) {
    if (obj == null) { return null; }
    const m = plainToInstance<DriverList, any>(DriverList, sanitizeDateIPoint(obj));
    m.sanitize();
    return m;
  }
  static createPromoList(cid: number | string, carrierCSummary: CarrierCompanySummary,
    driverUProfile?: UserProfile, entry?: { [key: string]: DriverListItem })
    : DriverList {
    let r = new DriverList();
    r.cid = cid;
    r.list = {};
    r.listType = PromoListType.promoDriver;
    r.carrierCompanySummary = carrierCSummary;
    if (!!entry) {
      r = r.updateListToAddEntry(entry, driverUProfile);
    }
    const v = Object.values(r.addTBD())[0];
    const k = Object.keys(r.addTBD())[0];
    r.list[k] = v;
    return r;
  }
  /**
   * update driver list to add new driver.
   * @param entry update entery (key is phone)
   * @param u Driver's userprofile
   */
  updateListToAddEntry(entry: { [key: string]: DriverListItem }, u?: UserProfile) {

    let p = Object.keys(entry)[0];
    p = sanitizePhone(p);
    if (!p) {
      return null;
    }
    this.list[p] = <DriverListItem>{}; // reset to empty and add properties based on the driverUProfile
    if (!!u) {
      const key = `${u.id}`;
      this.list[key] = <any>{};
      this.list[key].name = u.displayName;
      this.list[key].phone = p;
      this.list[key].itemStatus = ItemStatus.pending;
    } else {
      this.list[p] = <any>{};
      this.list[p].itemStatus = ItemStatus.pending;
      this.list[p].name = entry[p].name; // reset to empty for pending record
    }
    return this;
  }
  /**
   * update driver list to edit driver driver's item status @param ItemStatus.
   * Can one set status to inActive or pending, active is set when driver accepts carrier's request
   * @param entry update entery (key is phone)
   * @param u Driver's userprofile
   */
  updateListEditEntry(entry: { [key: string]: DriverListItem }, u?: UserProfile): DriverList {
    const entryKey = Object.keys(entry)[0];
    const entryVal = Object.values(entry)[0];
    let p = Object.keys(entry)[0];
    p = sanitizePhone(p);
    if (!p) {
      return null;
    }
    if (!!u && u.id !== entryVal.driverId) {
      return null;
    }
    const key: string = !!entryVal.driverId ? `${entryVal.driverId}` : entryKey;
    // can only set status inactive or  pending. Active status is set when driver accepets carrier's request
    if (entryVal.itemStatus < ItemStatus.active) {
      this.list[key].itemStatus = entryVal.itemStatus;
      return this;
    } else {
      return null;
    }
  }
  addTBD(): { [key: string]: DriverListItem } {
    const d = new DriverListItem();
    d.name = 'TBD';
    d.itemStatus = ItemStatus.active;
    d.phone = 'Place Holder';
    d.isPlaceHolder = true;
    const t: { [key: string]: DriverListItem } = {};
    t['TBD'] = d;
    return t;
  }
  validateSyncGroup(g: ListValidationGroup): IValidationMsg {
    return this.validateSync({ groups: [g] });
  }
  validateSync(options?: ValidatorOptions): IValidationMsg {

    const r = this.validateSyncBase(this, options);

    // if (options.groups.includes(addMode) && !!this.entry && !phoneRegex(<any>this.entry)) {
    //   r['entry'] = ['enter valid phone number'];
    // }
    // if (!!this.list && this.checkInvalidPhoneNo().length > 0) {
    //   r['entry'] = [` ${this.checkInvalidPhoneNo().length} invalid phone in the list, ${this.checkInvalidPhoneNo()[0]}`];
    // }
    if (!!this.list && !!this.checkDuplicatePhoneNo() && this.checkDuplicatePhoneNo().length > 0) {
      r['entry'] = [` ${this.checkDuplicatePhoneNo().length} duplicate phone in the list, ${this.checkDuplicatePhoneNo()[0]}`];
    }
    return r;

  }
  validatePhone(listItem: { [key: string]: DriverListItem }) {
  }
  clone() {
    const t = instanceToInstance(this);
    t.sanitize();
    return t;
  }
  addNewEntry(newEntry: { [key: string]: DriverListItem }): DriverList | null {
    if (!this.isDuplicate(Object.keys(newEntry)[0])) {
      const k = Object.keys(newEntry)[0];
      const v = Object.values(newEntry)[0];
      this.list[`${k}`] = v;
      return this;
    } else {
      return null;
    }
  }
  isDuplicate(v: string, dList?: DriverList): boolean {
    let a: string[];
    if (!!dList) {
      a = Object.keys(dList.list);
    } else {
      a = Object.keys(this.list);
    }
    logger.log('isDuplicate', a.includes(v));
    return !!a.includes(v) ? true : false;
  }
  createNewEntry(v: DriverListItem, itemStatus?: ItemStatus): { [key: string]: DriverListItem } {
    let c: { [key: string]: DriverListItem };
    const k = !!v.driverId ? v.driverId : v.key;
    c = <any>{
      [k]: {
        name: v.name,
        driverId: `placeholder${v.key}`,
        itemStatus: !!itemStatus ? itemStatus : ItemStatus.pending
      }
    };
    return c;
  }
  activateRecord(activateItem: DriverListItem): { [key: string]: DriverListItem; } {
    const a = pickBy(this.list, (value, key) => key === activateItem.key);
    a[activateItem.key].itemStatus = ItemStatus.active;
    a[activateItem.key].name = activateItem.name;
    return a;
  }
  checkInvalidPhoneNo(): string[] {
    const invalidPhs: string[] = [];
    for (const key in this.list) {
      if (this.list.hasOwnProperty(key)) {
        // if (!validate.isMobilePhone(key, 'en-US')) {
        //   invalidPhs.push(key);
        // }
      }
    }
    return invalidPhs;
  }
  checkDuplicatePhoneNo(): string[] {
    let duplicatePhs: string[] = [];

    const findDuplicates = arr => arr.filter((item, index) => arr.indexOf(item) !== index);
    duplicatePhs = !!this.list && Object.keys(this.list).length > 0 ? findDuplicates(Object.keys(this.list)) : null;
    return duplicatePhs;
  }
  /**
* update driver list to add blocking trip id
* @param trip TripBase
* @param tripId trip id reserved for trip create, trip.id when trip is updated
*/
  assignToTrip(trip: TripBase, tripId: string | number): DriverList {
    const updatedList = this.clone();
    // trip has driverId and diverList is key is phone.
    const dR = pickBy(this.list, (value, key) => key === trip.driverIdOrPhone);
    const k = Object.keys(dR)[0];
    if (!dR[k].isPlaceHolder) {
      updatedList.list[k].openTrip = {};
      updatedList.list[k].openTrip[tripId] = { description: trip.tripDescription, updatedAt: trip.startDate };
    }
    return updatedList;
  }
  /**
   * updates Driver, removes trip reference from the driver in original trip and adds trip reference from the updated trip
   * @param oTrip Original Trip
   * @param updatedTrip Updated Trip
   */
  reAssignOrUnAssignTrip(oTrip: TripBase, uTrip: TripBase): DriverList | null {
    const updatedList = this.clone();
    // oginal
    const o = pickBy(updatedList.list, (value, key) => key === oTrip.driverIdOrPhone);

    // updated
    const u = (uTrip.tripStatus < TripStatus.closed) ? pickBy(updatedList.list, (value, key) => key === uTrip.driverIdOrPhone) : null;
    // const u = !!newDriverId ? pickBy(updatedList.list, (value, key) => key === newDriverId) : null;
    // ogrinal key
    const ok = !!o ? Object.keys(o)[0] : null;
    if (!!ok) {
      updatedList.list[ok].openTrip = null;
    } else {
      return null;
    }
    // u/newDriverId will be null when tripStatus is set to closed or expired.
    // In which case driver list will not updated block driver
    if (!!u) {
      // updated key
      const uk = Object.keys(u)[0];
      if (!!this.list[uk].openTrip) {
        // newDriverId is already booked
        return null;
      }
      // check if newDriverId is avialable
      if (!u[uk].isPlaceHolder) {
        updatedList.list[uk].openTrip = {};
        updatedList.list[uk].openTrip[oTrip.id] = { description: oTrip.tripDescription, updatedAt: oTrip.startDate };
      }
    }
    return updatedList;
  }
  /**
   * @param origTrip TripBase
   */
  unAssignFromTrip(origTrip: TripBase): DriverList | null {
    const updatedList = this.clone();

    // driver key
    const dk = !!updatedList.list[origTrip.driverIdOrPhone] ? origTrip.driverIdOrPhone : null;
    // remover trip ref
    if (!!dk) {
      updatedList.list[dk].openTrip = null;
      return updatedList;
    } else {
      return null;
    }


  }
  getByItemByDriverId(driverId: string): { [key: string]: DriverListItem } {
    if (!driverId || !this.listForUI) {
      return null;
    }
    return pickBy(this.listForUI, (value) => value.driverId === driverId);
  }
  /**
   * update driver itemStatus based on driver's user profile
   * @param updatedObj UserProfile
   */
  updateItemStatus(uDriverProfile: UserProfile, oDriverProfile: UserProfile) {
    const item = this.list[oDriverProfile.id];
    // const item = this.list[uDriverProfile.phoneNumber];
    // orginal carrier status in driver's profile
    const o = !oDriverProfile.drivingFor || !oDriverProfile.drivingFor[this.carrierCompanySummary.cid] ?
      false : oDriverProfile.drivingFor[this.carrierCompanySummary.cid].isActive;
    const u = uDriverProfile.drivingFor[this.carrierCompanySummary.cid].isActive;
    // if there is no change return null
    if (o === u) {
      return null;
    }
    // get item from driverFor related to current companay
    const d = pickBy(uDriverProfile.drivingFor, (value, key) => key === this.carrierCompanySummary.cid);
    // return null dictionary does not include item releated to current company cid
    if (isEmpty(d)) {
      return null;
    }
    // now check if driver profile has actived or in activied.
    if (d[this.carrierCompanySummary.cid].isActive) {
      // true: if driverList item is pending then then activate, else no change
      item.itemStatus = item.itemStatus === ItemStatus.pending ? ItemStatus.active : item.itemStatus;
    } else {
      // false: if driverList item is active then then set to pending, else no change
      item.itemStatus = item.itemStatus === ItemStatus.active ? ItemStatus.pending : item.itemStatus;
    }
    return this;
  }
  /** transform list from dB where key for driver is phone (for anonymous) or firestore id (for loged users) */
  get listForUI(): { [key: string]: DriverListItem; } {
    const l: { [key: string]: DriverListItem } = {};
    for (const key in this.list) {
      if (this.list.hasOwnProperty(key)) {
        const ele = this.list[key];
        if (phoneRegex(key)) {
          l[key] = DriverListItem.parse({ ...ele, phone: key });
        } else if (ele.phone) {
          l[ele.phone] = DriverListItem.parse({
            phone: ele.phone, name: ele.name, itemStatus: ele.itemStatus,
            driverId: key, openTrip: ele.openTrip, isPlaceHolder: ele.isPlaceHolder
          });
        }
      }
    }
    return l;
  }
  isOpen(driverIdOrPhone: string): boolean {
    if (this.list[driverIdOrPhone].openTrip) {
      return false;
    }
    return true;

  }
  errorNotAvaialble(driverIdOrPhone: string): IValidationMsg {
    const r: ValidationError[] = [];
    const m = toValidationError(r);
    m[`${driverIdOrPhone}`] = [`(${this.list[driverIdOrPhone].name}) has open trip ${Object.values(this.list[driverIdOrPhone].openTrip)[0].description}. Close open trip before assgining new trip`];
    return m;
  }


  /**
   * updates driver list replace phone key by userId on phone add. On phone change it updates the phone
   * @param uProfile updated UserProfile
   * @param oProfile orgial UserProfile
   */
  updatePhone(uProfile: UserProfile, oProfile: UserProfile): DriverList {
    const phoneChange = !!oProfile.phoneNumber ? true : false;

    const uList = this.clone();
    if (phoneChange) {
      for (const key in uList.list) {
        if (uList.list.hasOwnProperty(key)) {
          const element = uList.list[key];
          if (phoneChange) {
            element.phone = uProfile.phoneNumber;
          }
        }
      }
    } else {
      uList.list[oProfile.id] = <DriverListItem>{
        email: uProfile.email,
        name: uProfile.displayName,
        itemStatus: uList.list[uProfile.phoneNumber].itemStatus,
        phone: uProfile.phoneNumber
      };
      uList.list[uProfile.phoneNumber] = null;
    }
    return uList;
  }
}
