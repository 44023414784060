/**
 * To build filter string from filters array
 * @param {Array<filter>} filterArr - raw filters array
 * @returns {string}
 */
export const buildFilterString = (filterArr: Filter[]): string => {
    return filterArr.reduce((str, filter) => {
        let filterString = '';
        if(Array.isArray(filter.value) && filter.value.length > 1) {
            for(let i = 0; i < filter.value.length; i++) {
                filterString += `${filter.attributeName}${filter.operator}${wrapStringInParentheses(filter.value[i])}`
                if (i !== filter.value.length - 1) {
                    filterString += ' OR '
                }
            }
        } else {
            filterString = `${filter.attributeName}${filter.operator}${wrapStringInParentheses(filter.value)}`;
        }
        
        if (str === '')
            return filterString;
        return `${str} AND (${filterString})`
    }, '')
}

// Wrap the string with single quotes if it contain spacial characters
function wrapStringInParentheses(str) {
    if (/:|\s/.test(str)) { // Check if the string contains space or - or : 
        return `'${str}'`;
    }
    return str; // Return the string as is
}

/** Filter object interface */
export interface Filter {
    attributeName: string;
    operator: Operator;
    value: string | number | boolean | string[] | number[];
}
export enum Operator {
    LESS_THAN = '<',
    LESS_THAN_EQ = '<=',
    EQUAL = '=',
    NOT_EQUAL = '!=',
    GREATER_THAN_EQ = '>=',
    GREATER_THAN = '>',
    COLON = ':'
}