import { BaseHttpService } from './base-http.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { FirestoreService } from './firestore.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { InspectionData } from '@trent/models/inspection/inspection-data';
import { instanceToPlain } from 'class-transformer';
import { tap, map } from 'rxjs/operators';
import { ValidatorOptions } from 'class-validator';
import { Inspection, InspectionDecision } from '@trent/models/inspection/inspection';
import { InspectionStatus } from '@trent/models/inspection/inspection-status';
import { IInspectionParam, inspectionSerachServerQuery } from '@trent/models/inspection/inspection-param';
import { PagingObesrvable } from '@trent/models/observable-util/paging-obesrvable';
import { Paging } from '@trent/models/observable-util/paging';
import { IInspectionPayload } from '@trent/models/inspection/i-inspection-payload';
import { IPoint } from '@trent/models/product-search/interfaces';
import { CompanyStatus } from '@trent/models/company/company-status';

@Injectable()
export class InspectionService extends BaseHttpService {



  offset = new BehaviorSubject(null);
  infinite: Observable<any[]>;
  constructor(store: Store, private db: FirestoreService, private http: HttpClient) {
    super(store);
    this.apiName = 'api';
  }

  getInspectionById(id: string) {
    return this.db.docWithInjectedId$<Inspection>(
      `${Inspection.collectionName}/${id}`, id);
  }

  public create(bidId: string | number, i: InspectionData, action: InspectionStatus, option: ValidatorOptions) {
    // Prepare the post data
    console.log('created db called', i);

    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.token });
    return this.http.post<{ pickupId: string | number, pickDrop: Inspection }>(this.getApiUrl('/pickup/create'),
      // Server validation before creating the entry.
      {
        inspection: instanceToPlain(i),
        bidId: bidId,
        action: action,
        option: option
      },
      { headers: headers })
      .pipe(
        tap(r => console.log('response: ', r))
      );

  }
  public update(data: IInspectionPayload) {
    // inspectionId: string | number, i: InspectionData, action: InspectionStatus
    data.inspectionData = instanceToPlain(data.inspectionData) as InspectionData;
    // Prepare the post data
    const headers = this.addBearerToken();
    // Server validation before creating the entry.
    return this.http.post<{ id: string | number, data: Inspection }>(this.getApiUrl('/inspection/update'),
      {
        data
      },
      { headers: headers })
      .pipe(
        tap(r => console.log('response: ', r))
      );
  }
  public inspectionDecision(iId: string | number, decision: InspectionDecision) {
    const headers = this.addBearerToken();
    // Server validation before creating the entry.
    return this.http.post<{ id: string | number, data: Inspection }>(this.getApiUrl('/inspection/inspection-decision'),
      {
        iId,
        decision

      },
      { headers: headers })
      .pipe(
        tap(r => console.log('response: ', r))
      );
  }
  getGpsLocation(unitNames: string[]) {
    const headers = this.addBearerToken();
    return this.http.post<{ unitName: string, iPoint: IPoint, timestamp: number }[]>(this.getApiUrl('/inspection/get-gps-location'),
      {
        unitName: unitNames
      },
      { headers: headers })
      .pipe(
        tap(r => console.log('response: ', r))
      );
  }
  /**
   * Get phone token for anonymous  user
   * @param data InspectionPayload, optional properties,  code, inpsectionData will be stripped
   */
  // requestPhoneToken(data: IInspectionPayload, phone: string) {
  //   data.inspectionData = null;
  //   data.phoneCode = null;
  //   // const headers = this.addBearerToken(); // not requied for anonymous user
  //   // Server validation before creating the entry.
  //   return this.http.post<{ status: string }>(this.getApiUrl('/inspection-delegate/get-phone-token'),
  //     {
  //       data, phone
  //     },
  //     // { headers: headers }
  //   )
  //     .pipe(
  //       tap(r => console.log('response: ', r))
  //     );
  // }
  // /**
  //  * Updated by delegate
  //  *  @param data IInspectionPayload
  //  */
  // public updateByDelegate(data: IInspectionPayload) {
  //   // inspectionId: string | number, i: InspectionData, action: InspectionStatus, phone: string
  //   data.inspectionData = instanceToPlain(data.inspectionData) as InspectionData;

  //   // Prepare the post data
  //   // const headers = this.addBearerToken();
  //   // Server validation before creating the entry.
  //   return this.http.post<{ id: string | number, data: Inspection }>(this.getApiUrl('/inspection-delegate/update-by-delegate'),
  //     {
  //       data,
  //     },
  //     // { headers: headers }
  //   )
  //     .pipe(
  //       tap(r => console.log('response: ', r))
  //     );
  // }

  public addRemoveDelegate(data: IInspectionPayload) {
    //  addRemove: boolean, inspectionId: string | number, action: InspectionStatus, phone: string
    data.inspectionData = null;

    const headers = this.addBearerToken();
    return this.http.post<string>(this.getApiUrl('/inspection/add-remove-delegate'),
      {
        data
      },
      { headers: headers })
      .pipe(
        tap(r => console.log('response: ', r))
      );

  }
  public resetAction(iId: string | number, action: InspectionStatus, option: ValidatorOptions) {
    // Prepare the post data
    const headers = this.addBearerToken();
    console.log('resetLastChg called');

    // Server validation before creating the entry.
    return this.http.post<{ id: string | number, data: Inspection }>(this.getApiUrl('/inspection/reset-action'),
      {
        iId,
        action: action,
        option: option
      },
      { headers: headers })
      .pipe(
        tap(r => console.log('response: ', r))
      );
  }
  public resetExpiry(iId: string | number, updatedExpiry: Date, option: ValidatorOptions) {
    // Prepare the post data
    const headers = this.addBearerToken();
    console.log('resetLastChg called');

    // Server validation before creating the entry.
    return this.http.post<{ id: string | number, data: Inspection }>(this.getApiUrl('/inspection/reset-expiry'),
      {
        iId,
        updatedExpiry,
        option: option
      },
      { headers: headers })
      .pipe(
        tap(r => console.log('response: ', r))
      );
  }
  /** 
   * @purpose Get company status for give inspection ID
   * @author SSS
   */
  getCustomerCompanyStatusForInspection(iId: string, userType?: number) {
    const headers = this.addBearerToken();

    // Server validation before creating the entry.
    return this.http.post<{ lesseeStatus: CompanyStatus }>(this.getApiUrl('/inspection/get-customer-company-status'),
      {
        iId,
        userType
      },
      { headers: headers })
      .pipe(
        tap(r => console.log('response: ', r))
      );
  }

  public getAllInspections1_PagingObservable() {
    const p: PagingObesrvable<Inspection, IInspectionParam> =
      new PagingObesrvable<Inspection, IInspectionParam>(this.db, this.getAllInspections1_batch);
    return p;
  }
  private getAllInspections1_batch(p: Paging, o: IInspectionParam): Observable<{ [key: string]: Inspection }> {
    console.log('PickDrop Server called with', p.offset, p.size);
    const col = !o.pdIdForHistory ? Inspection.collectionName : `${Inspection.collectionName}/${o.pdIdForHistory}/rev`;

    return this.db
      .colWithIdsInjectedNew$<Inspection>(col,
        ref => inspectionSerachServerQuery(ref, o, p))
      .pipe(
        tap(arr => {
          if (arr == null || arr.length === 0) {
            console.log('All data is recevied, Paging is FULL');
            p.full = true;
          } else {
            p.lastDoc = arr[arr.length - 1];
          }
        }),
        map(arr => {
          if (!o.pdIdForHistory) {
            return arr.reduce((acc, cur) => {
              const id = cur.id;
              const data = cur;
              return { ...acc, [id]: data };
            }, {});
          } else {
            return arr.reduce((acc, cur) => {
              const id = o.pdIdForHistory.toString() + '/rev/' + cur.id;
              const data = cur;
              data.id = id;
              // data.hRootId = o.bidId;
              return { ...acc, [id]: data };
            }, {});
          }
        })
      );

  }
  //   setPickDropStatus(cJob: CronJob) {
  // // Prepare the post data
  // const headers = this.addBearerToken();

  // // Server validation before creating the entry.
  // return this.http.post<{ col: string | number }>(this.getApiUrl('/pickup/setPickDropStatus'),
  //   {
  //     cJob: cJob
  //   },
  //   { headers: headers })
  //   .pipe(
  //     tap(res => console.log('response: ', res))
  //   );
  //   }
  // #endregion
}
