import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Store } from '@ngxs/store';
import { getUrlFromPath, getMetadataFS, updateCustomMetadata } from '@trent/services/firebase-storage';
import { FileUploadMult } from '../../../models/file-upload/file-upload-mult';
import { Storage } from '@angular/fire/storage';
import { DialogData, ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { EventService } from '@trent/services/event.service';
import { DialogService } from '@trent/services/dialog/dialog.service';
import { DomSanitizer } from '@angular/platform-browser';
import { UtilityService } from '@trent/services/utility.service';
import { CameraBaseService } from '@trent/services/camera/camera-base.service';
import { FileBaseService } from '@trent/services/file/file-base-service';
import { promiseWraper } from '@trent/models/utility';
import { BasePage } from '@trent/models/UI/base.page';
import { Picture, pictureTargetSize } from '@trent/models/inspection/picture';
import { fromCreateWarning, fromCreateSuccess } from '@trent/models/error-handling/message-info';
import { Platform } from '@ionic/angular';
import { logger } from '@trent/models/log/logger';
import { PicLocId } from '@trent/models/inspection/pic-loc-id';
import { BucketName } from '@trent/models/server-storage/i-strorage-metadata';
import { isNil } from 'lodash';
declare var window: any;


type DialogType = 'DeleteFile' | 'DeleteAllFiles';

@Component({
  selector: 'trent-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent extends BasePage<Picture[]> implements OnInit, OnDestroy {


  @Input()
  eMode: boolean;
  @Input()
  picWidth: number;
  @Input()
  picHeight: number;
  @Input()
  canDelete: boolean;
  @Input()
  hidePage: boolean;
  @Input()
  hideThumbnails: boolean;
  @Input()
  hideTabs: boolean;
  @Input()
  hideUpload: boolean;
  @Input()
  showDots: boolean;
  @Input()
  containerHeight: number;

  _index: number;
  percentComp: number;
  progressBarColor: 'primary' | 'accent' | 'warn';
  resizedUrl: string;
  showUploadWarning: boolean;
  isHandset$: any;
  @Input()
  get showIndex(): number {
    return this._index;
  }
  set showIndex(i: number) {
    i = !!i ? i : 0;
    if (i !== this._index) {
      this._index = i;
    }
    this.imageIndex = this.showIndex;
  }

  _value: Picture[] = [];

  @Input()
  get pics(): Picture[] {
    return this._value;
  }
  set pics(v: Picture[]) {
    if (v !== this._value) {
      this._value = v;
      //this.loadPics();
    }
  }
  _metadata = [];
  @Input()
  get cMetadata() { return this._metadata; }
  set cMetadata(m) {
    if (m !== this._metadata) {
      this._metadata = m;
    }
  }
  _path: string;
  @Input()
  get path() { return this._path; }
  set path(p: string) {
    if (p !== this._path) {
      this._path = p;
    }
  }
  @Input()
  tempPath: string;
  @Input()
  bucketName: BucketName;
  @Output() metadataGetter: EventEmitter<{ file: File, picture: Picture }[]> = new EventEmitter();
  @Output() deleteFiles: EventEmitter<{ pic?: Picture }> = new EventEmitter();  // index?: number,
  @Output() emitSelected: EventEmitter<Picture[]> = new EventEmitter();
  @Output() emitSelectedIndex: EventEmitter<number> = new EventEmitter();

  @ViewChild('thumbnailBar', { static: false })
  thumbnailBar: ElementRef;
  @ViewChild('filePicker', { static: false })
  filePicker: any;
  @ViewChild('currentIndex', { static: false })
  currentIndex: any;
  @ViewChild('imgCtrl', { static: false })
  imgCtrl: ElementRef;

  @Input()
  imageIndex = 0;
  upLoadFiles: { file: File, picture: Picture }[] = [];

  isNative = false;

  constructor(store: Store, private storage: Storage, public dialog: MatDialog,
    dialogService: DialogService, es: EventService, public domSanitizer: DomSanitizer,
    public us: UtilityService, public camera: CameraBaseService,
    private fbs: FileBaseService, private platform: Platform) {
    super(store, dialogService, es);


  }

  ngOnInit() {
    this.loadPics();
    // logger.log('path', this.path);
    this.isHandset$ = this.us.isHandset$;
    // logger.log('iphone', this.platform.is('ios'));
    // logger.log('android', this.platform.is('android'));
    if (this.camera.isCameraAvailable) {
      this.isNative = true;
    }
  }
  ngOnDestroy() {
    this.cleanListeners(false);
  }
  loadPics() {
    this.m = this.pics;
    // reset progress bar, which set accent for delete operation
    this.progressBarColor = 'primary';
    this.percentComp = null;
    // logger.log('pics in carousel', this.m);
    if (this.m && this.m.length > 0) {
      this.em = this.m.slice();
      this.getUrls();
    } else {
      // this.em = this.m.slice();
      this.em = [];
    }
  }
  /** gets URLs if pod exists but the URL is missing  */
  getUrls() {
    this.m.forEach(async (element) => {
      if (!element.url && !element.localUrl) {
        const r = await getUrlFromPath(element.path);
        if (!r.success) {
          logger.log(r.error);
        } else {
          element.url = r.url;
          const c = element.path.split('/');
          element.name = c[c.length - 1];
          // clone to em after async function completes
          this.em = this.m.slice();
          // logger.log('em with urls', this.em);
        }
      }
    });
  }
  previousPic(index: number) {
    console.log('hammer js: perv');
    this.imageIndex = this.imageIndex > 0 ? this.imageIndex - 1 : this.imageIndex;
    this.emitSelectedIndex.emit(this.imageIndex);

  }
  nextPic(index: number) {
    console.log('hammer js: next');
    this.imageIndex = this.imageIndex < (this.m.length - 1) ? this.imageIndex + 1 : this.imageIndex;
    this.emitSelectedIndex.emit(this.imageIndex);
  }
  selectedImage(j: number) {
    this.imageIndex = j;
    this.emitSelectedIndex.emit(this.imageIndex);
  }
  addPictures(event) {
    if (!!this.us.isPlatformBrowser) {
      this.selectFilesInWeb(event);
    }
  }
  async selectFilesInWeb(event: Event, picLoc?: PicLocId, filePicker?: ElementRef<HTMLInputElement>) {
    const files: FileList = (event.target as any).files;
    logger.log('file open event', event);
    for (let index = 0; index < files.length; index++) {
      logger.log(files[index].type);
      if (!files[index] || files[index].type.split('/')[0] !== 'image') {
        logger.log('invalid file type', files[index].type);
        return;
      }
      const reader = new FileReader();
      const file = files[index];
      const pic = new Picture();
      pic.locId = picLoc;
      // pic.name = file.name;
      pic.uploadedOn = new Date();
      logger.log('imgCtrl', this.imgCtrl);
      // pic.url = URL.createObjectURL(files[0]);
      // this.imgCtrl.nativeElement.src = window.URL.createObjectURL(files[i]);
      // this.imgCtrl.nativeElement.height = 300;
      // this.imgCtrl.nativeElement.onload = function() {
      //   window.URL.revokeObjectURL(this.src);
      // };

      // this.upLoadFiles.push({ file: file, pic: pic });
      // this.em.push(pic);
      this.imageIndex = this.em.length - 1;
      reader.onload = (e: any) => {

        logger.log('File client uploaded Name: ', file.name);
        // TODO: update from base64 to blob.
        // see: https://dev.to/daviddalbusco/take-photo-and-access-the-picture-library-in-your-pwa-without-plugins-25jl

        // resize image

        const img = new Image();
        img.src = e.target.result;

        img.onload = (q) => {
          console.log('findOut', q);
          pic.localUrl = e.target.result;  // pic.url = e.target.result;
          pic.name = `${new Date().getTime()}_${file.name}`;
          if (file.size < pictureTargetSize) {
            logger.log('resized not required');
            pic.name = `${new Date().getTime()}_${file.name}`;
            // eslint-disable-next-line max-len
            pic.file = file; // add blob to file in pic model, not saved db, can passed back to carousel component to upload
            this.upLoadFiles.push({ file: file, picture: pic });
            this.em.push(pic);
            this.emitSelectedPics();
            this.imageIndex = this.em.length - 1;
          } else {

            this.compressImage(q.target, file.size, 1, file.name)
              .then((r) => {
                logger.log('resized image', r);
                // eslint-disable-next-line max-len
                pic.file = r.compressedImageFile; // add blob to file in pic model, not saved db, can passed back to carousel component to upload
                // pic.file = file; // add blob to file in pic model, not saved db, can passed back to carousel component to upload
                this.upLoadFiles.push({ file: r.compressedImageFile, picture: pic });
                // this.upLoadFiles.push({ file: file, picture: pic });
                this.em.push(pic);
                this.emitSelectedPics();
                this.imageIndex = this.em.length - 1;
              })
              .catch((error) => {
                logger.log(`re sizing error ${error}`);
              });
          }
          // pic.localUrl = e.target.result;  // pic.url = e.target.result;
          // pic.name = `${new Date().getTime()}_${file.name}`;
          // pic.file = file; // add blob to file in pic model, not saved db, can passed back to carousel component to upload
          // this.upLoadFiles.push({ file: file, picture: pic });
          // this.em.push(pic);
          // this.emitSelectedPics();
          // this.imageIndex = this.em.length - 1;

        };
      };
      reader.readAsDataURL(files[index]);
    }
  }
  takePicCrdv(o: number = 1, picLoc?: PicLocId) {
    const pic = new Picture();
    pic.locId = null;
    pic.uploadedOn = new Date();
    this.camera.takePicture({ destinationType: o })
      .then(async res => {
        let blob = null;
        logger.log('camra image url: ', res);
        pic.url = res.webPath;
        let url = '';

        // Handle Cordova
        if (this.camera.isCdva) {
          const fileP = await promiseWraper(this.fbs.resolveFile(res.path));
          if (!fileP.success) {
            logger.error('path cannot be resolved');
            return;
          }
          url = fileP.data as any;
          logger.log('[car] file', url);
          const blobP = await promiseWraper(this.fbs.readFile((<any>url)));
          if (!blobP.success) {
            logger.error('[car] conversion to blob failed', blobP.error);
            return;
          }
          logger.log('[car] ', blobP.data);
          blob = blobP.data as File;
        } else {
          // capacitor send the url directly.
          url = res.path as string;
          blob = await fetch(Object.values(res.webPath)[0]).then(r => r.blob());
        }

        // logger.log('[car] file', url);
        // const blobP = await promiseWraper(this.fbs.readFile((<any>url)));
        // if (!blobP.success) {
        //   logger.error('[car] conversion to blob failed', blobP.error);
        //   return;
        // }
        // logger.log('[car] ', blobP.data);
        // const blob = blobP.data as File;
        pic.name = `${new Date().getTime()}_${blob.name}`;
        pic.locId = !isNil(picLoc) ? picLoc : undefined;
        pic.file = blob; // add blob to file in pic model, not saved db, can passed back to carousel component to upload
        this.upLoadFiles.push({ file: blob, picture: pic });
        logger.log('this.upLoadFiles', this.upLoadFiles);
        this.em.push(pic);
        this.emitSelectedPics();
        this.imageIndex = this.em.length - 1;
      })
      .catch(err => {
        logger.error('camera Error', err);
      });
  }
  async upload() {
    this.metadataGetter.emit(this.upLoadFiles);
    logger.log('metadata', this.cMetadata);
    logger.log('path', this.path);
    this.progressBarColor = 'primary';
    return this.uploadPvt();
  }
  async uploadPvt() {
    const files = this.upLoadFiles.filter(g => !!g.file);
    logger.log('files', files);
    this.percentComp = 10; // manual entry to show the start
    const fileUpload = new FileUploadMult(this.storage);
    // this.fileUpload.uploadFiles('/temp', files.map(f => f.file));
    if (this.tempPath) {
      fileUpload.uploadFiles(this.tempPath, files, this.cMetadata, this.bucketName);
    } else if (!!this.path) {
      fileUpload.uploadFiles(this.path, files, this.cMetadata, this.bucketName);
    } else {
      fileUpload.uploadFiles('/temp', files, this.cMetadata, this.bucketName);
    }
    fileUpload.percentage$.subscribe(r => {
      this.percentComp = r;
      logger.log('percentage', r);
      if (this.percentComp === 100) {
        this.upLoadFiles = [];
        const msg = (this.showUploadWarning) ? fromCreateWarning('file') : fromCreateSuccess('file');
        this.showPopover(msg, 500);
      }
    });
    return fileUpload.uploadResult$.toPromise();
  }
  async upload2(silentUpload = false) {
    this.metadataGetter.emit();
    logger.log('metadata', this.cMetadata);
    logger.log('path', this.path);
    this.progressBarColor = 'primary';
    return this.uploadPvt2(silentUpload);
  }
  async uploadPvt2(silentUpload: boolean) {

    const files1 = this.em.map(f => {
      return { file: f.file, picture: f };
    });
    const files = files1.filter(g => !!g.file);
    logger.log('files', files);
    this.percentComp = 10; // manual entry to show the start
    const fileUpload = new FileUploadMult(this.storage);
    // this.fileUpload.uploadFiles('/temp', files.map(f => f.file));
    if (this.tempPath) {
      fileUpload.uploadFiles(this.tempPath, files, this.cMetadata, this.bucketName);
    } else if (!!this.path) {
      fileUpload.uploadFiles(this.path, files, this.cMetadata, this.bucketName);
    } else {
      fileUpload.uploadFiles('/temp', files, this.cMetadata, this.bucketName);
    }
    fileUpload.percentage$.subscribe(r => {
      this.percentComp = r;
      logger.log('percentage', r);
      if (this.percentComp === 100) {
        this.upLoadFiles = [];
        if (!silentUpload) {
          const msg = (this.showUploadWarning) ? fromCreateWarning('file') : fromCreateSuccess('file');
          this.showPopover(msg, 500);
        }
      }
    });
    return fileUpload.uploadResult$.toPromise();
  }
  thumbnailsLeft() {
    this.previousPic(this.imageIndex);
    this.thumbnailBar.nativeElement.scrollBy({ left: -68, behavior: 'smooth' });
  }
  thumbnailsRight() {
    this.nextPic(this.imageIndex);
    this.thumbnailBar.nativeElement.scrollBy({ left: 68, behavior: 'smooth' });
  }
  async getMetadata(index: number): Promise<{}> {
    const docRef = this.m[index].path;
    let metadata: any;
    const p = await getMetadataFS(docRef);
    if (!p.success) {
      logger.log(p.error);
    } else {
      metadata = p.metadata;
    }
    logger.log('metadata', metadata);
    this.percentComp = 50;
    return metadata;
  }
  async archiveFile(index: number) {
    if (!!this.upLoadFiles) {
      this.upLoadFiles.forEach((e, i) => {
        if (e.picture.url === this.em[index].url) {
          // remove from pictures array
          this.em.splice(index, 1);
          // remove from upload array
          this.upLoadFiles.splice(i, 1);
          // reset the up load element to allow user to reselect the same file
          this.filePicker.nativeElement.value = null;
        }
      });
    }
    // if file was never loaded to storage, then return
    if (!this.m[index]) {
      logger.log('file does not exist in db');

      return;
    }
    this.progressBarColor = 'accent';
    const metadata = await this.getMetadata(index);
    let customMetadata = { ...(<any>metadata).customMetadata };
    customMetadata.isArchive = true;
    customMetadata.updatedBy = this.user.id;
    const docRef = this.m[index].path;
    try {
      const p = await updateCustomMetadata(docRef, { customMetadata: customMetadata });
      if (!!p.success) {
        customMetadata = p.updatedMetadata;
        logger.log(`${this.m[index].name} deleted`);
      } else {
        logger.log('error occured during delete operation', p.error);
      }
      this.percentComp = 100;
      // this.progressBarColor = 'primary';
    } catch (error) {
      logger.log('error occured', error);
    }
  }
  archiveAllFiles() {
    this.em.forEach((e, i) => {
      this.archiveFile(i);
    });
  }
  openConfDialog(dialogType: DialogType, index?: number) {
    let dialogData: DialogData;
    switch (dialogType) {
      case 'DeleteFile':
        dialogData = {
          title: 'Delete File',
          subtitle: 'File will be permanently deleted',
          trueButton: 'Delete',
          falseButton: 'Cancel',
          imgUrl: this.em[index].url
        };
        break;
      case 'DeleteAllFiles':
        dialogData = {
          title: `Delete All (${this.em.length} files)`,
          subtitle: 'Files will be permanently deleted',
          trueButton: 'Delete',
          falseButton: 'Cancel',
        };
        break;

      default:
        break;
    }
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      // width: '250px',
      data: dialogData
    });
    dialogRef.afterClosed().subscribe(result => {
      logger.log('The dialog was closed');
      if (!!result) {
        switch (dialogType) {
          case 'DeleteFile':
            const newFileIndex = this.upLoadFiles.findIndex(f => f.picture.url === this.em[index].url);
            if (!!this.upLoadFiles && newFileIndex > -1) {
              this.upLoadFiles.splice(newFileIndex, 1);
              // this.em.splice(index, 1);
              // reset the filePicker element else removed files cannot be selected
              this.filePicker.nativeElement.value = null;
              this.imageIndex = this.em.length - 1;
            } else {
              // this.em.splice(index, 1);
            }
            this.deleteFiles.emit({ pic: this.em[index] });
            this.em.splice(index, 1);
            // this.emitSelectedPics();
            // this.archiveFile(index);
            break;
          case 'DeleteAllFiles':
            // this.archiveAllFiles();
            this.deleteFiles.emit({ pic: null });
            if (!!this.upLoadFiles && this.upLoadFiles.length > 0) {
              this.upLoadFiles = [];
              this.em = [];
              // reset the filePicker element else removed files cannot be selected
              this.filePicker.nativeElement.value = null;
              this.imageIndex = 0;
              this.emitSelectedPics();
            }
            break;
          default:
            break;
        }
      }
    });
  }
  openPodPage(podIndex: number) {
    if (this.us.isPlatformBrowser) {
      window.open(this.m[podIndex].url);
    }
  }
  reset() {
    this.upLoadFiles = [];
  }
  emitSelectedPics() {
    const pics = this.upLoadFiles.map(r => r.picture);
    logger.log(`[carusoel.comp], emitSelectedPic ${this.em}`);
    this.emitSelected.emit(this.em);
  }
  addPicCommon(event: Event, locId: PicLocId) {
    if (!this.isNative) {
      this.selectFilesInWeb(event, locId);
    } else {
      this.takePicCrdv(1, locId);
    }
  }
  get hasComments() {
    return this.pics.findIndex(f => !!f.comment) > -1;
  }
  compressImage(originalImage: any, originalSize: number, quality: number, name: string): Promise<{
    compressedImageFile: File, compressedImageSrc: string, sizeString: string
  }> {
    // showing the compressed image
    // const originalImage = document.querySelector('#originalImage') as any;

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    let compressedImageBlob: Blob;
    let compressedImageFile: File;
    let compressedImageSrc: string;
    let sizeString: string;

    const resizingFactor = pictureTargetSize < originalSize ? pictureTargetSize / originalSize : 1;


    const originalWidth = originalImage.width;
    const originalHeight = originalImage.height;

    const canvasWidth = originalWidth * resizingFactor;
    const canvasHeight = originalHeight * resizingFactor;

    canvas.width = canvasWidth;
    canvas.height = canvasHeight;

    context.drawImage(
      originalImage as any,
      0,
      0,
      originalWidth * resizingFactor,
      originalHeight * resizingFactor
    );

    // reducing the quality of the image
    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          if (blob) {
            compressedImageBlob = blob;
            compressedImageFile = new File([blob], name, {
              type: blob.type
            });
            compressedImageSrc = URL.createObjectURL(compressedImageBlob);
            sizeString = this.bytesToSize(blob.size);
            resolve({ compressedImageFile, compressedImageSrc, sizeString });
          } else {
            reject('no blob exists');
          }
        },
        'image/jpeg',
        quality
      );
    });
    // canvas.toBlob(
    //   (blob) => {
    //     if (blob) {
    //       compressedImageBlob = blob;
    //       compressedImageSrc = URL.createObjectURL(compressedImageBlob);
    //       sizeString = this.bytesToSize(blob.size);
    //     }
    //   },
    //   'image/jpeg',
    //   quality
    // );
    // return { compressedImageBlob, compressedImageSrc, sizeString };
  }
  bytesToSize(bytes: number) {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

    if (bytes === 0) {
      return '0 Byte';
    }
    // eslint-disable-next-line radix
    const i = parseInt(`${(Math.floor(Math.log(bytes) / Math.log(1024)))}`);
    return Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i];
  }
  get imageHeight() {
    if (this.hasComments) {
      return this.containerHeight - 30;
    } else {
      return this.containerHeight;
    }
  }
  showRemoveButton(p: Picture) {
    return !!this.eMode && this.canDelete && !p.deleteDisabled;
  }
}
// headless tab
// https://stackblitz.com/edit/angular-tzdhop?file=app%2Fapp.component.html
