import { PromoListBase } from './promo-list-base';
import { Exclude, Expose, plainToInstance, instanceToInstance } from 'class-transformer';
import { sanitizeDateIPoint } from '../utility/sanitize-helper';
import { ValidatorOptions } from 'class-validator';
import { IValidationMsg } from '../error-handling/validation-info';
import { IPoint, IPointGeo } from '../product-search/interfaces';
import { findDistance } from '../utility/distf';
import { getGeoHash, getGeoHashArr } from '../map/map-helper';
import { pickBy } from 'lodash';
// import * as geofirex from 'geofirex';
// import * as firebase from 'firebase/app';

export interface BorderListItem {
  province: string;
  state: string;
  iPoint: IPoint;
  geoLoc?: IPointGeo;
}

/** List of Carriers registered for trip tracking promo */
@Exclude()
export class BorderList extends PromoListBase {

  public static readonly idName = 'border-list';
  // readonly geo = geofirex.init(firebase);


  @Expose()
  list: { [key: string]: BorderListItem };

  public static parse(obj) {
    if (obj == null) { return null; }
    const m = plainToInstance<BorderList, any>(BorderList, sanitizeDateIPoint(obj));
    m.sanitize();
    return m;
  }
  validateSync(options?: ValidatorOptions): IValidationMsg {

    const r = this.validateSyncBase(this);
    return r;

  }
  clone() {
    const t = instanceToInstance(this);
    t.sanitize();
    return t;
  }
  getBrorderInProv(prov: string): { [key: string]: BorderListItem } {


    const filteredBorders: { [key: string]: BorderListItem } = {};
    for (const k in this.list) {
      if (this.list.hasOwnProperty(k)) {
        const e = this.list[k];
        if (e.province === prov) {
          filteredBorders[k] = e;
        }
      }
    }
    return filteredBorders;
  }
  findBorderOnRoute(filteredBorders: { [key: string]: BorderListItem }, iPoints: IPoint[]) {
    let r: { [key: string]: BorderListItem } = {};

    let n = 10000;
    for (const itr of iPoints) {

      for (const key in filteredBorders) {
        if (filteredBorders.hasOwnProperty(key)) {
          const border = filteredBorders[key];
          const d = findDistance(border.iPoint.latitude, border.iPoint.longitude, itr.latitude, itr.longitude);
          if (d < n) {
            n = d;
            r = {};
            r[key] = border;
          }
        }
      }

    }
    return r;
  }
  addGeoHash(borders?: { [key: string]: BorderListItem }) {
    borders = !!borders ? borders : this.list;
    for (const key in borders) {
      if (borders.hasOwnProperty(key)) {
        const b = borders[key];
        b.geoLoc = { geohash: getGeoHash(b.iPoint), geopoint: b.iPoint };
      }
    }
    return borders;
  }
    /** transform list from dB no change required for Border list */
    get listForUI(): { [key: string]: BorderListItem; } {
      return this.list;
    }
}
