import { getUrlEncodedSlashToPercentTwoF } from '../utility/helper';

export class DbRule {

  /** root id of the record.  xxxxxx/yyyy-rev/1 will return xxxxxx. where yyyy is root collection name */
  public static getRootId(id: string | number) {
    if (!!id) {
      return `${id}`.split('/')[0];
    }
    return null;
  }

  /** if id is from rev branch, (a rev branch is frozen branch. */
  public static isFrozenReleaseBranch(id: string | number) {
    return `${id}`.toLowerCase().includes('-rev/');
  }

  /** if id is from draft branch */
  public static isDraftBranch(id: string | number) {
    return `${id}`.toLowerCase().includes('-draft/');
  }

  /** is the id witout any draft/released branchy */
  public static isRootBranch(id: string | number) {
    return !(DbRule.isFrozenReleaseBranch(id) || DbRule.isDraftBranch(id));
  }

  /** Get draft # from id.  xxxxxxx/yyyy-draft/5 will return 5. Where yyyy is root collection name*/
  public static getDraftid(id: string | number): number {
    if (id == null) {
      return undefined;
    }
    const a = `${id}`.toLocaleLowerCase().split('-draft/');
    if (a.length < 2) {
      return undefined;
    }
    return (isNaN(+a[1])) ? undefined : +a[1];
  }
  /** Get draft # from id.  xxxxxxx/yyyy-rev/5 will return 5 */
  public static getRevid(id: string | number): number {
    if (id == null) {
      return undefined;
    }
    const a = `${id}`.toLocaleLowerCase().split('-rev/');
    if (a.length < 2) {
      return undefined;
    }
    return (isNaN(+a[1])) ? undefined : +a[1];
  }
  /** return encoded url segment
   * e.g. xxxxx/product-rev/1 will return xxxxx%2Fproduct-rev%2F1
   * e.g. xxxxx/product-draft/1 will return xxxxx%2Fproduct-draft%2F1
   */
  public static getEncodedURLSegment(seg: string) {
    if (typeof seg !== 'string') {
      return null;
    }
    if (DbRule.isRootBranch(seg)) {
      return seg;
    }
    if (DbRule.isDraftBranch(seg) || DbRule.isFrozenReleaseBranch(seg)) {
      return getUrlEncodedSlashToPercentTwoF(`${seg}`);
    } else {
      return null;
    }
  }
}

