// import { parse, getYear, getMonth, getDate, addMinutes } from 'date-fns';


import { addMinutes } from 'date-fns';
import { logger } from '../log/logger';
// import moment from 'moment-timezone';

export const getAdjustedTime = (zstr: string, utcOffset: number, t: Date): Date => {
  if (!zstr) {
    return t;
  }
  const bOffS = new Date().getTimezoneOffset();
  const tzCorrected = addMinutes(t, (-bOffS - utcOffset));

  return tzCorrected;
};
export const getBrowserTime = (zstr: string, utcOffset: number, t: Date): Date => {
  if (!zstr) {
    return t;
  }
  const bOffS = new Date().getTimezoneOffset();
  return addMinutes(t, (bOffS + utcOffset));

};

/** If d is a date object. */
export const isDate = (d) => {
  return Object.prototype.toString.call(d) === '[object Date]';
};

/** Get tomorrow morning. if today is 31 Dec, it is: 01 Jan , Time: 00:00:00 */
export function tomorrowMorning(): Date {
  const t = new Date();
  // add one day
  t.setDate(t.getDate() + 1);
  // set hours/min/sec/mill to 0 for tomorrow morning.
  t.setHours(0);
  t.setMinutes(0);
  t.setSeconds(0);
  t.setMilliseconds(0);
  return t;
}

/** Tonight, just before mid nigtht. i.e 23:59:59:999 tonight*/
export function midNightTonite(): Date {
  const d = new Date();
  d.setHours(23);
  d.setMinutes(59);
  d.setSeconds(59);
  d.setMilliseconds(999);
  return d;
}
/** adds year to date, if date is not defined add year to today's date*/

export function addAYear(n: number, d?: Date): Date {
  if (d == null) { d = new Date(); }
  const year = d.getFullYear();
  const month = d.getMonth();
  const day = d.getDate();
  const yearFromNow = new Date(year + n, month, day);
  return yearFromNow;
}
export function addNDays(n: number, d?: Date): Date {
  if (d == null) {
    d = new Date();
  }
  let v = d.valueOf();
  // logger.log(v);
  // logger.log('dates as is: ', new Date(v));

  v = v + n * 24 * 60 * 60 * 1000;
  // logger.log(v, n);
  // logger.log(n * 24 * 60 * 60 * 1000);

  // logger.log('date updated: ', new Date(v));
  return new Date(v);

  // const year = d.getFullYear();
  // const month = d.getMonth();
  // const day = d.getDate();
  // logger.log(year, month, day + n, new Date(year, month, day + n));
  // return new Date(year, month, day + n);
}
export function addNHours(n: number, d?: Date): Date {
  if (d == null) {
    d = new Date();
  }
  let v = d.valueOf();
  v = v + n * 60 * 60 * 1000;
  // logger.log(v, n);
  // logger.log(n * 24 * 60 * 60 * 1000);

  // logger.log('date updated: ', new Date(v));
  return new Date(v);
}

export function convertStringtoDate(s: string): Date {
  if (typeof s === 'string') {
    const d = s.split('-');
    const k = <any>d[0] + '-' + d[1] + '-' + d[2];
    return new Date(k);
  } else {
    return s;
  }

}
/** gets two dates and return number of months in between */
export function nMonthsBetweenDates(d1: Date | string, d2: Date | string): number {
  d1 = typeof d1 === 'string' ? convertStringtoDate(d1) : d1;
  d2 = typeof d2 === 'string' ? convertStringtoDate(d2) : d2;
  let dL: Date;
  let dS: Date;
  if (d1 === d2) { return null; }
  d1.valueOf() > d2.valueOf() ? (dL = d1, dS = d2) : (dL = d2, dS = d1);
  const n = (dL.valueOf() - dS.valueOf()) * (1 / 1000 * 1 / 60 * 1 / 60 * 1 / 24 * 1 / 365 * 12);
  logger.log(n);
  return n;
}

/** gets number of months and return nYears and n Months */
export function nMonthsToString(nMonths: number): string {
  const s = (Math.floor(nMonths / 12) > 0 ? Math.floor(nMonths / 12)
    + (Math.floor(nMonths / 12) > 1 ? ' years and ' : ' year and ') : '') + (Math.round(nMonths % 12) > 0 ?
      (Math.round(nMonths % 12) + (Math.round(nMonths % 12) > 1 ? ' months' : ' months')) : '');
  logger.log(s);
  return s;

}
/** parses UTC yyyymmddThhmmssZ to "2013-02-08T08:09:10.000Z" */
export function parseUTCDate(input) {
  return new Date(Date.UTC(
    parseInt(input.slice(0, 4), 10),
    parseInt(input.slice(4, 6), 10) - 1,
    parseInt(input.slice(6, 8), 10),
    parseInt(input.slice(9, 11), 10),
    parseInt(input.slice(11, 13), 10),
    parseInt(input.slice(13, 15), 10)
  ));
}
export const isSameDate = (d1: Date, d2: Date) => {
// check if d1 and d2 are same day month and year
return (
  d1.getDate() === d2.getDate() &&
  d1.getMonth() === d2.getMonth() &&
  d1.getFullYear() === d2.getFullYear()
);

};