import { IAuthCoreService } from '@trent/services/auth/iauth-core.service';
import { promiseWraper } from '@trent/models/utility';
import { StateBase } from './../state-base';
import { State, StateContext, Action, Selector } from '@ngxs/store';
import { map } from 'rxjs/operators';
import { noop, Subscription } from 'rxjs';
import * as entity from '../entity.state';
import { LocalStorage } from '@trent/services/local-storage/local-storage.service';
import { Injectable } from '@angular/core';
import { logger } from '@trentm/log/logger';
import { ContractHtml } from '@trent/models/cms/contract-html';
import { buildDataRequest, IDataLoadStatus, LoadStatus, updatePaging } from '@trent/models/observable-util/data-status';
import { getObjKey, Paging } from '@trent/models/observable-util/paging';
import { PagingContainer } from '@trent/models/observable-util/paging-container';
import { Reviews } from '@trent/models/reviews/reviews';
import { IReviewsParam, reviewsSearchClientFilter } from '@trent/models/reviews/reviews-param';
import { parseReviews } from '@trent/models/reviews/reviews-helper';
import { PtsService } from '@trent/services/pts.service';


// #region State Model
export interface ReviewsStateModel {
  // New approach with paging
  data: { [id: string]: Reviews };  // changed

  allDataLoaded: boolean;
  allDataLoadStatus: {
    [key: string]: IDataLoadStatus<IReviewsParam>;
  };
}

function initReviewsState(): ReviewsStateModel {
  return {
    data: {},
    allDataLoaded: false,
    allDataLoadStatus: {},
  };
}

// #endregion

/**
* @author - Cm
* @purpose - Pts home screen
*/


export class AllReviewsRequested {
  static readonly type = '[Reviews] All Requested';
  constructor(public payload: { pData: Paging, param?: IReviewsParam }) { }
}

export class AllReviewsLoaded {
  static readonly type = '[Reviews] All Loaded';
  constructor(public payload: {
    data: { [id: string]: Reviews },
    key: string,
  }) { }
}


export class ReviewsRemoveById {
  static readonly type = '[Reviews] Remove by Id';
  constructor(public payload: { id: string | number }) { }
}

// #endregion

@State<ReviewsStateModel>({
  name: 'reviewsState',
  defaults: initReviewsState()
})

@Injectable()
export class ReviewsState extends StateBase {

  allReviewsReqSub: Subscription;
  allReviewsSubData: PagingContainer<Reviews, IReviewsParam> = new PagingContainer();


  constructor(private pts: PtsService, private storage: LocalStorage, private auth: IAuthCoreService) {
    super();
  }

  // #region Selectors
  @Selector()
  static selectPageById(state: ReviewsStateModel) {
    /** use it if return is a class */
    return entity.getByIdFn_new(state.data, Reviews.parse);
  }


  @Selector()
  static selectedAllReviews(state: ReviewsStateModel) {
    return (o: IReviewsParam): Reviews[] => {
      if (state.data == null || Object.keys(state.data).length === 0) {
        return [];
      }
      // remove keys that have revision/draft ids, i.e that contain '~' in the id.
      const keys = Object.keys(state.data).filter(k =>
        k.indexOf('/') === -1 && state.data[k] != null);

      // object without the rev/draft.
      const filtered = keys.reduce((obj, k) => {
        obj[k] = state.data[k];
        return obj;
      }, {});
      let output: Reviews[];

      output = Object.values(filtered).map(x => parseReviews(x));
      output = reviewsSearchClientFilter(output, o);

      return output;
    };
  }


  // #endregion


  @Action(AllReviewsRequested)
  allReviewsRequested(context: StateContext<ReviewsStateModel>, action: AllReviewsRequested) {
    const oKey = getObjKey(action.payload.param);

    // Define function that return the data status object from the state.
    const getDataStatusStateFn = () => context.getState().allDataLoadStatus;

    /** custom build the OR children query. */
    const buildOrQueryChildrenFn = (o: IReviewsParam) => undefined;
    // if data requested now, is already partially loaded by another query's child previously
    // but the number of the items are not enough (ex. as a child, it loaded only 5, but current
    // request ask for more, then next page of that query should be called instead.)
    const nextPageFn = (param: IReviewsParam) => {
      //context.dispatch(new AllBidsNextPageRequested({ param }));
    };
    buildDataRequest(
      oKey, action.payload.param, action.payload.pData,
      getDataStatusStateFn,
      buildOrQueryChildrenFn,
      nextPageFn,
      (
        obj: { [key: string]: IDataLoadStatus<IReviewsParam> },
        set: { key: string, node: IDataLoadStatus<IReviewsParam> }[]
      ) => {
        if (!!obj) {
          // Patch the state.
          const state = context.getState();
          context.patchState({
            allDataLoadStatus: { ...state.allDataLoadStatus, ...obj }
          });
        }
        // Process the query.
        set.forEach((val) => {
          // some of the nodes are already loaded. Only process that are loading... status.
          if (val.node.loadStatus !== LoadStatus.Loading) {
            return;
          }
          // if this request is just overwriting a stall or pending request, unsubscribe that observable
          this.allReviewsSubData.unsubscribe(val.key);

          // create the paging observable and call db.
          const b = this.pts.getAllReviews_PagingObservable();

          const msg$ = b.getData(action.payload.pData, val.node.param)
            .pipe(
              map(d => {
                context.dispatch(new AllReviewsLoaded({
                  data: d,
                  key: val.key
                }));
                return d;
              }));
          const sub = this.subscribe(msg$, () => noop(), AllReviewsLoaded.type);
          this.allReviewsSubData.addData(val.key, sub, b);
        }
        );
      });

  }

  @Action(AllReviewsLoaded)
  allReviewsLoaded(context: StateContext<ReviewsStateModel>, action: AllReviewsLoaded) {
    const state = context.getState();
    const subData = this.allReviewsSubData.getData(action.payload.key);
    const updatedLoadStatus = updatePaging(action.payload.key, state.allDataLoadStatus, subData);
    context.patchState({
      allDataLoaded: true,
      allDataLoadStatus: updatedLoadStatus,
      data: { ...state.data, ...action.payload.data }
    });
  }

  @Action(ReviewsRemoveById)
  ReviewsRemoveById(context: StateContext<ReviewsStateModel>, action: ReviewsRemoveById) {
    const state = context.getState();
    if (Object.keys(state.data).indexOf(`${action.payload.id}`) > -1) {
      const currState = context.getState();
      const newData = { ...currState.data };
      delete newData[action.payload.id];
      context.patchState({ data: newData });
      const state1 = context.getState();
      logger.log('[Reviews-State], item removed by id', action.payload.id, state1);

    } else { logger.log('[Reviews-State], item to be removed id is not available in the store'); }
  }

  // #region Custom Functions and subscriptions
  public clearSubscriptions() {
    if (!!this.allReviewsReqSub) {
      this.allReviewsReqSub.unsubscribe();
      this.allReviewsReqSub = null;
    }
    this.allReviewsSubData.unsubscribeAll();
    super.clearSubscriptions();
  }



}
