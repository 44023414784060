import { BidBase } from './bid-base';
import { plainToInstance, instanceToInstance } from 'class-transformer';
import { TrailerSummary } from '../product/trailer';
import { sanitizeDateIPoint } from '../utility';
import { NegoTermsBase } from '../nego-terms-base/nego-terms-base';
import { parseNegoTerms } from '../nego-terms-base/nego-terms-helper';
import { Address } from '../address/address';

export interface TrailerProdSummary {
  unitName: string;
  make: string;
  modelYear: number;
  trailerLength: number;
  nAxle: number;
  isReefer: boolean;
}

export class BidTrailer extends BidBase {
  constructor() {
    super();

  }


  productSummary: TrailerSummary;


  public static parse(obj) {
    if (obj == null) { return null; }
    const m = plainToInstance<BidTrailer, any>(BidTrailer, sanitizeDateIPoint(obj));
    m.bidNegoTerms = parseNegoTerms(obj.bidNegoTerms);
    if(m?.rentSummary?.startAddress){
      m.rentSummary.startAddress = Address.parse(m.rentSummary.startAddress);
    }
    if(m?.rentSummary?.endAddress){
      m.rentSummary.endAddress = Address.parse(m.rentSummary.endAddress);
    }

   m.sanitize();
    return m;
  }
  clone() {
    const t = instanceToInstance(this);
    t.bidNegoTerms = this.bidNegoTerms.clone();
    t.sanitize();
    return t;
  }

  sanitize() {
    super.sanitize();
  }
  get summary() {
    return `${this.productSummary.trailerLength}' ${this.productSummary.nAxle}-Axle Trailer ` +
      `${(this.productSummary.isReefer) ? 'Reefer' : 'Dry Van'}`;
  }
}
