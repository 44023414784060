import { DbStatus } from '../base/db-status';
import { CollectionReference, Query, query, QueryConstraint, where, orderBy, startAfter, limit } from 'firebase/firestore';
import { Paging } from '../observable-util/paging';
import { logger } from '../log/logger';
import { CompanyBase } from './company-base';
import { CompanyFleet } from './company-fleet';
import { CompanyStatus } from './company-status';


export interface ICompanyParam {
  orderDirection?: 'desc' | 'asc';
  orderBy?: string;
  dbStatus?: DbStatus;
  isLessee?: boolean;
  isLessor?: boolean;
  uid?: string;
  isDraft?: boolean;
  cid?: string | number; // used only for client filter
  rgp?: string[];//MKN - fetching data as per role group(Account Manager, Credit Manager etc)
  authId?:string; //Cm - fetching data as per role authId(Sales person, Credit Manager ...etc)
  lesseeStatus?: number;
  lessorStatus?: number;
}
export const companyParamInit = (): ICompanyParam => {
  return {
    orderBy: 'updatedAt',
    orderDirection: 'desc',
  };
};

/** Server filtering of firebase query building of query  */
export const companySerachServerQuery = (ref: CollectionReference, param: ICompanyParam, p: Paging) => {
  let q: Query = <any>ref;
  const cons: QueryConstraint[] = [];
  if (!!param.isLessee) {
    // cons.push(where('isLessee', '==', true));
  }

  if (!!param.isLessor) {
    cons.push(where('isLessor', '==', true));
  }

  if(!!param.lesseeStatus){
    cons.push(where('lesseeStatus', '==', param.lesseeStatus));
  }
  if(!!param.lessorStatus){
    cons.push(where('lessorStatus', '==', param.lessorStatus));
  }

  if (!!param.uid) {
    cons.push(where('companyUsers', 'array-contains', param.uid));
  }
  if (!!param.rgp) { //MKN - fetch company data as per role group Ids 
    cons.push(where('roleGroupIds', 'array-contains-any', param.rgp));
  }

  if (!!param.authId) { //Cm - fetch company data as per auth Id 
    cons.push(where('authIds', 'array-contains', param.authId));
  }

  cons.push(where('updatedAt', '<=', new Date()));
  cons.push(orderBy('updatedAt', 'desc'));

  if (!!p.lastDoc) {
    logger.log('Start After Query: ', p.lastDoc[param.orderBy]);
    cons.push(startAfter(p.lastDoc[param.orderBy]));
  }
  cons.push(limit(p.size));
  q = query(ref, ...cons);
  return q;
};
/** Client Filtering of the data */
export const companySearchClientFilter = (p: CompanyBase[], o: ICompanyParam): CompanyFleet[] => {

  // filter by dbStatus, for new companies the dbStatus will be set as draft
  if (!!o.dbStatus) {
    p = p.filter((val, idx) => val.dbStatus === +o.dbStatus);
  }

  if(!!o.lesseeStatus){
    p = p.filter((val, idx) => val.lesseeStatus === o.lesseeStatus);
  }

  if(!!o.lessorStatus){
    p = p.filter((val, idx) => val.lessorStatus === o.lessorStatus);
  }

  if (o.isLessee != null) {
    p = p.filter((val, idx) => !val.lessorStatus);
  }
  if (o.isLessor != null) {
    p = p.filter((val, idx) => !!val.isLessor);
  }
  if (o.uid != null) {
    p = p.filter((val, idx) => val.companyUsers.includes(o.uid));
  }

  if (o.authId != null) {
    p = p.filter((val, idx) => val.authIds.includes(o.authId));
  }

  if (o.isDraft !== undefined) {
    p = p.filter((val, idx) => !val.isDraft);
  }
  // only used as client filter
  if (o.cid) {
    p = p.filter((val, idx) => val.id === o.cid);
  }
  return p as CompanyFleet[];
};

export const getICompanyOptionOrChildren = (o: ICompanyParam): { [key: string]: ICompanyParam } => {

  return undefined;
};

