<ng-container *ngIf="!!eMode && !hideUpload">
  <div class="upload-container">
    <button mat-icon-button color="warn" (click)="openConfDialog('DeleteAllFiles')"
      *ngIf="!!em && em.length >0 && canDelete">Delete
      All<mat-icon>delete</mat-icon></button>
    <button mat-icon-button color="primary" *ngIf="!!upLoadFiles && upLoadFiles.length>0 && !hideUpload"
      (click)="upload()" style="grid-column: 2">
      Upload({{upLoadFiles.length}}) <mat-icon>cloud_upload</mat-icon></button>
    <button mat-icon-button color="primary" (click)="filePicker.click()" style="grid-column: 3"
      *ngIf="!(isHandset$ | async)">ADD <mat-icon>
        add_circle</mat-icon></button>
    <ng-container *ngIf="!!(isHandset$ | async)">
      <button mat-icon-button color="primary" (click)="takePicCrdv(1)" style="grid-column: 3" *ngIf="!!isNative">
        <mat-icon>
          camera_alt</mat-icon>
      </button>
      <button mat-icon-button color="primary" (click)="filePicker.click()" style="grid-column: 3" *ngIf="!isNative">
        <mat-icon>
          add_a_photo</mat-icon>
      </button>
    </ng-container>

    <mat-progress-bar class="progress-bar" [color]="progressBarColor" mode="determinate" [value]="percentComp">
    </mat-progress-bar>
  </div>
</ng-container>

<ng-container *ngIf="!!em && em.length>0">
  <div class="tab-container">
    <mat-tab-group [selectedIndex]="imageIndex">
      <ng-container *ngFor="let p of em; index as j">
        <mat-tab [label]="j+1">
          <div [style.height.px]="!showRemoveButton(p) ? containerHeight : containerHeight + 50" >
            <button class="image-button" mat-icon-button color="accent" (click)="openConfDialog('DeleteFile', j)"
              *ngIf="showRemoveButton(p)">
              <!-- !!eMode && canDelete && !p.deleteDisabled  -->
              <mat-icon>delete</mat-icon>
            </button>
            <div class="image-container" [style.height.px]="containerHeight" >
              <figure > 
                <img [src]="p.url" alt="" class="image" (swiperight)="previousPic(j)" (swipeleft)="nextPic(j)"
                  *ngIf="!!p.url" [style.height.px]="imageHeight" [style.touch-action]="'pan-y'">
                <img id="originalImage" [src]="p.localUrl" alt="" class="image" (swiperight)="previousPic(j)" (swipeleft)="nextPic(j)"
                  *ngIf="!!p.localUrl && !p.url" [style.height.px]="containerHeight" [style.touch-action]="'pan-y'">

                  <img src="../../../assets/TP-Logo-Text-black.svg" alt="" class="image" *ngIf="!p.url" [style.max-height]="containerHeight">
                <!-- <div class="d-flex-center" [style.height.px]="containerHeight" *ngIf="!p.localUrl && !p.url">
                  <mat-icon >local_shipping</mat-icon>
                </div> -->

                <figcaption *ngIf="p.comment" style="background-color: var(--accent); text-align: center">{{p.comment}}</figcaption>
                <figcaption *ngIf="!p.comment && hasComments">&nbsp;</figcaption>
              </figure>
            </div>
          </div>
        </mat-tab>
      </ng-container>
    </mat-tab-group>
  </div>
</ng-container>
<ng-container *ngIf="!!em && em.length>0 && (!!em[0].url || !!em[0].localUrl)">
  <div class="dots-container" *ngIf="showDots">
    <div class="dots" [ngClass]="{'dots': em.length > 1 && em.length < 10, 'dots-10': em.length >=10 ,'no-dots': em.length === 1}">
      <ng-container *ngFor="let pic of em; index as j">
        <div class="dot" [ngClass]="{'display-white': em.length <= 1}" [ngClass]="{'selected-dot': imageIndex===j}"
          (click)="selectedImage(j);$event.stopPropagation()">
          {{j + 1}}
        </div>
      </ng-container>
    </div>
  </div>
  <div class="thumbnail-bar-container" *ngIf="!hideThumbnails">
    <button class="scroll-button" mat-icon-button color="primary" (click)="thumbnailsLeft()">
      <mat-icon>keyboard_arrow_left</mat-icon>
    </button>
    <div #thumbnailBar class="thumbnail-bar">
      <ng-container *ngFor="let p of em; index as j">
        <div class="thumbnail-container">
            <img [src]="p.url" alt="" class="thumbnail" *ngIf="p.url">
            <img [src]="p.localUrl" alt="" class="thumbnail" *ngIf="!p.url && !!p.localUrl">
          <button mat-stroked-button class="thumbnail-button" (click)="selectedImage(j)"></button>
        </div>
      </ng-container>
    </div>
    <button class="scroll-button" mat-icon-button color="primary" (click)="thumbnailsRight()">
      <mat-icon>keyboard_arrow_right</mat-icon>
    </button>
  </div>

</ng-container>

<div class="doc-links" *ngIf="!hidePage">
  <ng-container *ngFor="let pic of m; index as j">
    <a mat-stroked-button (click)="openPodPage(j)" style="margin-right: 10px;">page-{{j+1}}</a>
  </ng-container>
</div>

<!-- Input File Picker-->
<input #filePicker [id]="currentIndex" class="hide" type="file" (change)="addPictures($event)" accept="image/*">