<mat-grid-list [cols]="isMobile ? 1 : 3" class="grid-list">
    <mat-grid-tile>
      <mat-card>
        <mat-card-header>
          <mat-card-title>Truck Details</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div *ngIf="bl(bid) as val">
            <mat-card class="modern-card">
                <mat-card-header>
                  <div mat-card-avatar class="card-avatar">
                    <img src="https://via.placeholder.com/64" alt="Avatar">
                  </div>
                  <mat-card-title>{{val.productSummary.title}}</mat-card-title>
                  <mat-card-subtitle>{{val.productSummary.make}} ({{val.productSummary.modelYear}})</mat-card-subtitle>
                  <mat-card-subtitle>{{val.productSummary.summaryString}}</mat-card-subtitle>
                </mat-card-header>
                <mat-card-content>
                  <p>
                    This is a brief description of the card content. You can put any content you want here.
                  </p>
                </mat-card-content>
                <mat-card-actions>
                  <button mat-button>Button 1</button>
                  <button mat-button>Button 2</button>
                </mat-card-actions>
              </mat-card>
             </div> 
        </mat-card-content>
      </mat-card>
    </mat-grid-tile>
    <mat-grid-tile>
      <mat-card>
        <mat-card-header>
          <mat-card-title>Images</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="card-div">
            <ng-container *ngIf="tl(rentalProduct) as val">
              <trent-carousel #carouselCtrl class="pod-pictures" [pics]="val.pictures" [showIndex]="0"
                [containerHeight]="250" [eMode]="false" [hidePage]="true" [hideThumbnails]="true" [showDots]="true">
              </trent-carousel>
            </ng-container>
        </div>
        </mat-card-content>
      </mat-card>
    </mat-grid-tile>
    <mat-grid-tile>
      <mat-card>
        <mat-card-header>
          <mat-card-title>Map View</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <trent-map-wrapper [mLabels]="mapLabels" [mapHeight]="mapHeight/2"></trent-map-wrapper>
        </mat-card-content>
      </mat-card>
    </mat-grid-tile>
  </mat-grid-list>


  