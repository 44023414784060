import { ProductType } from '@trentm/product/product-type';
import { cloneDeep } from 'lodash';
import { ITaxItem } from '../tax/i-tax-item';
import { ServiceType, IServiceItem, IServiceItemN } from '../service-fee/i-service-item';
import { PaymentMethod } from '../payment-method/i-payment-method';
import { IMethodInfo } from './fn-lib';
import { SpecialItemTypeDb, SpecialItemTypeDbN } from '../invoice/invoice-item-type';
import { financeSettingDb } from './fin-data-db';
import { isDevMode } from '@angular/core';
export interface IFinanceInput {

  rdigit: number;

  ccTransaction: {
    validateAddress: boolean;
  };

  taxCalcDb: { [taxId: string]: ITaxItem };

  jurisTaxDb: { [stateProv: string]: string[] };

  transactionFeeDb: { [key in PaymentMethod]: IMethodInfo };

  /** One Time Admin Fee charged once per contract */
  oneTimeCharge: {
    [key in ServiceType]: {
      adminFee: number;
    }
  },

    /** Reserve Amount charged at the time of reserving the equipment once per contract */
    reserve: {
      [key in ServiceType]: {
        amount: number;
      }
    },

  /** Jurisdiction dependent special fees.  
   * @deprecated. Use specialFeeN instead
  */
  specialFee: {
    /** Environment / Veh License fee */
    [key in SpecialItemTypeDb]: {
      /** Type of Rental: trailer rental / truck rental */
      [key in ServiceType]: {
        /** Tax Jurisdiction place. Eg. ON  */
        [jurisKey: string]: IServiceItem;
      };
    };
  };

  specialFeeN?: {
    /** Jurisdiction Data ON, QC etc.*/
    jurisdiction: {
      [juris: string]: {
        [key in SpecialItemTypeDb]: string
      }
    },
    data: {
      [key in SpecialItemTypeDb]: {
        [keyId: string]: IServiceItem
      }
    },
  }
  specialFeeNN?: {
    /** Jurisdiction Data ON, QC etc.*/
    [key in ServiceType]: {

      jurisdiction: {
        [juris: string]: {
          [key in SpecialItemTypeDbN]: string
        }
      },
      data: {
        [key in SpecialItemTypeDbN]: {
          [keyId: string]: IServiceItemN,
        }
      },
    }
  }
}

export class FinanceInput {
  private static _fin: IFinanceInput = {} as any;

  public static get fin(): IFinanceInput {
    return FinanceInput._fin;
  }
  public static UpdateFin(v: IFinanceInput) {
    FinanceInput._fin = v;
  }
}

// var _financeInput: IFinanceInput = {} as any; // getFinanceSettingDb_Default(); 

export const setFinanceInput = (f: IFinanceInput) => {
  FinanceInput.UpdateFin(cloneDeep(f) as IFinanceInput);
  // _financeInput = cloneDeep(f) as IFinanceInput;
};

// export const getFinanceInput = () => _financeInput;

export const getOneTimeFee = (type: ProductType) => {
  const f = FinanceInput.fin;
  switch (type) {
    case ProductType.trailer:
      return f.oneTimeCharge.trailerRental;
    default:
      return f.oneTimeCharge.truckRental;
  }
};

export const getReserveAmount = (type: ProductType) => {
  const f = FinanceInput.fin;
  switch (type) {
    case ProductType.trailer:
      return f.reserve.trailerRental.amount;
    default:
      return f.reserve.truckRental.amount;
  }
};


// /** Debug mode. */
// function getFinanceSettingDb_Default() {
//   // Print it for the logger.
//   // console.warn('Default Hard Coded Finance Setting Db.');
//   // console.info(`\n\n${JSON.stringify(financeSettingDb, null, 2)}\n\n`);
//   return cloneDeep(financeSettingDb); 
// }