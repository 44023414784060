/** Picture Location ID of the Asset Grid.
 * Three in the front and three in the back and 
 * i, j are the 2D grid and 
 */
export enum PicLocId {
  i0_j0 = 'Front_Driver',
  i0_j1 = 'Front_Mid',
  i0_j2 = 'Front_Passenger',

  i1_j0 = 'Side_Driver_Front',
  i1_j2 = 'Side_Passenger_Front',

  i2_j0 = 'Side_Driver_Mid',
  i2_j2 = 'Side_Passenger_Mid',

  i3_j0 = 'Side_Driver_Bottom',
  i3_j2 = 'Side_Passenger_Bottom',

  i4_j0 = 'Rear_Driver',
  i4_j1 = 'Rear_Mid',
  i4_j2 = 'Rear_Passenger'
}

export const getPicLocStr = (i: number, j: number): string => {
  return `i${i}_j${j}`;
};
export enum PicLocId2 {
  front = 'Front',
  rear = 'Rear',
  driverSide = 'Driver Side',
  passengerSide = 'Passenger Side',
  frontDriver = 'Front Driver',
  frontPassenger = 'Front Passenger',
  dashBoard = 'Dashboard',
  bunk = 'Bunk',
  underTheHood = 'Under The Hood',
  vinSticker = 'VIN Sticker'
}