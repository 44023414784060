import { DialogService } from '@trent/services/dialog/dialog.service';
import { Component, OnInit, OnDestroy, Input, ViewChild } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { map } from 'rxjs/operators';
import { MessageInfo, fromFailedValidation, readErrorMessage } from '@trent/models/error-handling';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseForm } from '@trent/models/UI/base-form';
import { ContactUs, EnquiryType } from '@trent/models/contact-us/contact-us';
import { NgForm } from '@angular/forms';
import { ValidationOptions } from 'class-validator/types/decorator/ValidationOptions';
import { TimerHelper } from '@trent/models/utility';
import { UserService } from '@trent/services/user.service';
import { UtilityService } from '@trent/services/utility.service';
import { DelegateInspectionService } from '@trent/services/delegate-inspection.service';
import { EventService } from '@trent/services/event.service';
import { logger } from '@trent/models/log/logger';
import { PromotionsService } from '@trent/services/promotions.service';
import { PromotionLeads } from '@trent/models/promotional-messages/promotion-leads';
import { Promotions } from '@trent/models/promotional-messages/promotions';
import { DomSanitizer } from '@angular/platform-browser';
import { PromotionResponseType } from '@trent/models/promotional-messages/promotion-response';
import { webSiteUrl } from '@trent/core/environments/fb-env';

@Component({
  selector: 'trent-promotions-leads-info-page',
  templateUrl: './promotions-leads-info-page.component.html',
  styleUrls: ['./promotions-leads-info-page.component.scss']
})
export class PromotionLeadsInfoPageComponent extends BaseForm<ContactUs> implements OnInit {

  promotionLeadId : string;
  promotionId : string;
  promotionResponseType : PromotionResponseType;
  promotionLeadInfo : PromotionLeads;
  isHandset:boolean;
  enquiryType : EnquiryType;
  promotion : Promotions;
  type : EnquiryType = EnquiryType.customer;

  @ViewChild('enquiryForm', { static: false })
  enquiryForm: NgForm;

  constructor(store: Store, public _sanitizer : DomSanitizer, ds: DialogService, es: EventService, public aroute: ActivatedRoute,public promotionAPI: PromotionsService, private router: Router, private delegateInspectionService : DelegateInspectionService) {
    super(store, ds, es);
    this.title = 'Connect with Us';
  }

  ngOnInit() {

    this.promotionLeadId = this.aroute.snapshot.params.lid;
    this.promotionResponseType = this.aroute.snapshot.data.prType;

    this.promotionId = this.aroute.snapshot.params.pid;
    logger.log(this.promotionLeadId,this.promotionId);
    
    this.us.isHandset$.subscribe(h => this.isHandset = h);

    this.m = new ContactUs();
    this.m.enquiryType = EnquiryType.customer;
    this.em = this.m.clone();
    this.getPromotionLead();
    this.setUpFormValidation();
    //this.getStoreData();
  }

  getStoreData(){    
    this.promotionAPI.getPromotionsDataById(this.promotionId).subscribe(promo => {
      this.promotion = Promotions.parse(promo);
      const leadGenerationUrl = `${webSiteUrl.production}/get-info-page/promotion-email/${this.promotionLeadId}/${this.promotionId}`;
      this.promotion.emailTemplate = this.promotion.emailTemplate.replace("tpine/get-info-page/tpine", `${leadGenerationUrl}`); 
      this.promotion.emailTemplate = this._sanitizer.bypassSecurityTrustHtml(this.promotion.emailTemplate) as any;
    });
  }

  getPromotionLead(){
    this.promotionAPI.getPromotionLeadsDataById(this.promotionLeadId).subscribe(promo => {
      this.promotionLeadInfo = PromotionLeads.parse(promo);
 //     this.em.phoneNumber = this.promotionLeadInfo.phoneNumber;
      this.em.email = this.promotionLeadInfo.email;
    });
  }


  ngOnDestroy(): void {
    this.cleanListeners();
  }


  //Form validation
  private setUpFormValidation() {
    const timer = new TimerHelper();
    timer.setInterval(800, 10, () => {
      if (this.enquiryForm == null) {
        return false;
      }

      // Set up the validators dynamically on the form. Note, since form is inside ng-if, form can be recreated
      // in the dom if no data is returned on a subscription. so validation has to be called to ensure it is set on
      // any new instance. as before function, pass out the validation object as well.*/


      this.setFormValidation<ContactUs>(ContactUs, this.enquiryForm, () => {

        const option: ValidationOptions = {
          groups: []
        };
        return { model: this.em, option };
      });

      // this.markDirtyAndValidate(this.companyForm);
      return true;
    });

  }

  //Save api
  submit(){    
      const tempR = this.em.validateSync({ groups: [] });
      if (Object.keys(tempR).length > 0) {
        console.log('validation failed');
        this.showAlert(fromFailedValidation(tempR).messageInfo);
        return;
      }
      this.showLoading(10);
      const promotionDetails = {

      }
      const prom = this.delegateInspectionService.savePromotionEnquiry(this.em,this.promotionLeadId,this.promotionId,this.promotionResponseType).toPromise();
      prom.then((r) => {
        this.hideLoading();
        
        const msg = new MessageInfo({
          msgCss: 'primary',
          header: 'Thank you',
          description: 'Enquiry generated successfully. One of our executive will connect with you shortly.'
        });
        this.showPopover(msg);
        this.resetForm();
        this.router.navigate(['rent-option/truck/list']);
      })
      .catch(err => {
        this.hideLoading();
        this.showAlert(readErrorMessage(err));
      });
  }

  resetForm(){
    this.em = this.m.clone();
  }

}
