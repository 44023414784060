<ng-container  *ngIf="(bids?.length === 0 && otherRentals?.length === 0); else openContractRentalTemplate">



  <ng-container *ngIf="em && em.productSummary" >
    <trent-slide-panel [activePane]="activePane" (activePanChg)="activePane = $event">
  
      <div leftPane class="wide-margin">
        <mat-card appearance="outlined" class="log-card" *ngIf="emRTruck?.productSummary">
          <mat-card-header>
            <div mat-card-avatar>
              <mat-icon matSuffix color="primary">{{emRTruck.productSummary?.prodIcon}}</mat-icon>
            </div>
            <mat-card-title>{{emRTruck.productSummary.title}}
              (#{{emRTruck.productSummary.unitName}})
            </mat-card-title>
            <mat-card-subtitle>{{(emRTruck.productSummary.make)}} {{emRTruck.productSummary.modelYear}}
            </mat-card-subtitle>
            <mat-card-subtitle>{{emRTruck.productSummary.summaryString}}</mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
            <!-- <ng-container *ngFor="let r of rentals">
              <mat-label>Open Rentals:</mat-label><br>
              <mat-label> {{r.availStartDate | date}} to {{r.availEndDate | date}} </mat-label><br>
            </ng-container>
            <mat-label>Open contracts:</mat-label><br>
            <ng-container *ngFor="let b of bids">
              <mat-label> {{b.bidNegoTerms.startDate | date}} to {{b.bidNegoTerms.endDate | date }}
                <ng-container *ngFor="let s of bStatusEnum | enumPipe">
                  <ng-container *ngIf="b.bidStatus===+s.key">{{s.value}}</ng-container>
                </ng-container>
              </mat-label> <br>
            </ng-container> -->
  
            <ul class="menu-list menu-list-boxed" >
              <li>
                <a href="javascript:void(0)" (click)="showHide('locationAndDeposit')">
                  <mat-icon aria-hidden="false" aria-label="Phone" class="left-icon">list</mat-icon>
                  <span>Rental Details</span>
                  <!-- <em [class.color-yellow-dark]="false">
                    Security and Location Details
                  </em> -->
                  <em [ngClass]="{'missing-info': !valDbStatus?.groupResult['locationAndDeposit']?.groupPass}">
                    {{valDbStatus?.groupResult['locationAndDeposit']?.message}}
                  </em>
                  <mat-icon>arrow_forward_ios</mat-icon>
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" (click)="showHide('ratePlan')">
                  <mat-icon aria-hidden="false" class="left-icon">paid</mat-icon>
                  <span>Rate Plans</span>
                  <em [ngClass]="{'missing-info': !valDbStatus?.groupResult['ratePlan']?.groupPass}">
                    {{valDbStatus?.groupResult['ratePlan']?.message}}
                  </em>
  
                  <mat-icon>arrow_forward_ios</mat-icon>
                </a>
              </li>
  
              <ng-container *ngIf="em.shortPlans.length> 0">
                <li>
                  <a href="javascript:void(0)" (click)="showHide('shortTerm')">
                    <mat-icon aria-hidden="false" class="left-icon">attach_money</mat-icon>
                    <span>Short Term</span>
                    <em [ngClass]="{'missing-info': !valDbStatus?.groupResult['shortTerm']?.groupPass}">
                      {{valDbStatus?.groupResult['shortTerm']?.message}}
                    </em>
  
                    <mat-icon>arrow_forward_ios</mat-icon>
                  </a>
                </li>
              </ng-container>
              <ng-container *ngIf="em.monthlyPlans.length > 0">
                <li>
                  <a href="javascript:void(0)" (click)="showHide('monthly')">
                    <mat-icon aria-hidden="false" class="left-icon">attach_money</mat-icon>
                    <span>Monthly Plans</span>
                    <em [ngClass]="{'missing-info': !valDbStatus?.groupResult['monthly']?.groupPass}">
                      {{valDbStatus?.groupResult['monthly']?.message}}
                    </em>
                    <mat-icon>arrow_forward_ios</mat-icon>
                  </a>
                </li>
              </ng-container>
              <ng-container *ngIf="em.yearlyPlans.length > 0">
                <li>
                  <a href="javascript:void(0)" (click)="showHide('yearly')">
                    <mat-icon aria-hidden="false" class="left-icon">attach_money</mat-icon>
  
                    <span>Yearly Plans</span>
                    <em [ngClass]="{'missing-info': !valDbStatus?.groupResult['yearly']?.groupPass}">
                      {{valDbStatus?.groupResult['yearly']?.message}}
                    </em>
                    <mat-icon>arrow_forward_ios</mat-icon>
                  </a>
                </li>
              </ng-container>
              <li>
                <a href="javascript:void(0)" (click)="showHide('pictures')">
                  <mat-icon aria-hidden="false" class="left-icon">photo</mat-icon>
  
                  <span>Pictures</span>
                  <em [ngClass]="{'missing-info': !valDbStatus?.groupResult['pictures']?.groupPass}">
                    {{valDbStatus?.groupResult['pictures']?.message}}
                  </em>
                  <mat-icon>arrow_forward_ios</mat-icon>
                </a>
              </li>
              <!-- <li>
                <a href="javascript:void(0)" (click)="showHide('exclusive')">
                  <mat-icon aria-hidden="false" class="left-icon">verified_user</mat-icon>
  
                  <span>Exclusive</span>
                  <em [ngClass]="{'missing-info': !valDbStatus?.groupResult['exclusive']?.groupPass}">
                    {{valDbStatus?.groupResult['exclusive']?.message}}
                  </em>
                  <mat-icon>arrow_forward_ios</mat-icon>
                </a>
              </li> -->
  
  
  
  
            </ul>
          </mat-card-content>
          <ng-container *ngIf="!isHandset">
            <ng-container *ngTemplateOutlet="desktopActions"></ng-container>
          </ng-container>
  
  
        </mat-card>
      </div>
  
      <div rightPane>
        <ng-container>
          <mat-card appearance="outlined" class="gen-card wide-margin">
  
  
            <mat-card-content>
              <ng-container *ngIf="currSubMenu === 'locationAndDeposit'">
                <ng-container *ngTemplateOutlet="locationTemp"></ng-container>
              </ng-container>
              <ng-container *ngIf="currSubMenu === 'ratePlan'">
                <ng-container *ngTemplateOutlet="rentalPlansTemp"></ng-container>
              </ng-container>
              <ng-container *ngIf="currSubMenu === 'shortTerm'">
                <ng-container *ngTemplateOutlet="shortTermTemp; context: {p: em.shortPlans}"></ng-container>
              </ng-container>
              <ng-container *ngIf="currSubMenu === 'monthly'">
                <ng-container *ngTemplateOutlet="monthlyTemp; context: {p: em.monthlyPlans}"></ng-container>
              </ng-container>
              <ng-container *ngIf="currSubMenu === 'yearly'">
                <ng-container *ngTemplateOutlet="yearlyTemp; context: {p: em.yearlyPlans}"></ng-container>
              </ng-container>
              <ng-container *ngIf="currSubMenu === 'pictures' && !addHiddenDom">
                <ng-container *ngTemplateOutlet="pictureTemp"></ng-container>
              </ng-container>
              <ng-container *ngIf="currSubMenu === 'exclusive'">
                <ng-container *ngTemplateOutlet="selectCompanies"></ng-container>
              </ng-container>
  
            </mat-card-content>
  
          </mat-card>
  
          <div>
            <!-- <ng-container *ngTemplateOutlet="openRentals"></ng-container> -->
          </div>
  
        </ng-container>
  
      </div>
    </trent-slide-panel>
  </ng-container>
</ng-container>



<ng-template #locationTemp>

  <mat-card-subtitle>
    Rental Details
  </mat-card-subtitle>
  <form #locationAndDepositForm="ngForm">
    <mat-form-field class="radio1">
      <mat-label>Active/Inactive</mat-label>
      <input matInput name="isActive" #isActive="ngModel" [(ngModel)]="em.isActive" style="display: none">
      <div style="display: grid; align-self: bottom">
        <mat-radio-group aria-label="Select an option" name="isActive" #isActive="ngModel" [disabled]="!isEditMode"
          [(ngModel)]="em.isActive" (change)="setupFormVal()">
          <mat-radio-button color="accent" [value]="true" style="padding-right: 10px">
            Active
          </mat-radio-button>
          <mat-radio-button color="accent" [value]="false" style="padding-right: 10px">
            Inactive
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <mat-hint *ngIf="!showError(isActive)" align="end">Requried</mat-hint>
      <mat-error *ngIf="showError(isActive)">{{isActive.errors.msg}}</mat-error>
      <button mat-icon-button matSuffix color="primary" type="button" (click)="openHelp('trip-detail#onewayReturn')">
        <mat-icon class="help-icon">help</mat-icon>
      </button>
    </mat-form-field>
    <mat-form-field class="full-width">
      <!-- <span matPrefix>{{em.currency}}$ &nbsp;</span> -->
      <mat-label>Security Deposit</mat-label>
      <input matInput [disabled]="!isEditMode" type="number" name="deposit" #deposit="ngModel" [(ngModel)]="em.deposit" autocomplete="off">
        <span matSuffix *ngIf="em.deposit">{{toCurrency(em.deposit)}}</span>
      <mat-hint *ngIf="!showError(deposit)" align="end">Required</mat-hint>
      <mat-error *ngIf="showError(deposit)">{{deposit.errors.msg}}</mat-error>
    </mat-form-field>

    <mat-form-field class="radio1">
      <mat-label>Return/One-Way</mat-label>
      <input matInput name="isOneWay" #isOneWay="ngModel" [(ngModel)]="em.isOneWay" style="display: none">
      <div style="display: grid; align-self: bottom">
        <mat-radio-group aria-label="Select an option" name="isOneWay" #isOneWay="ngModel" [disabled]="!isEditMode"
          [(ngModel)]="em.isOneWay" (change)="setupFormVal()">
          <mat-radio-button color="accent" [value]="false" style="padding-right: 10px">
            Return
          </mat-radio-button>
          <mat-radio-button color="accent" [value]="true" style="padding-right: 10px">
            One Way
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <mat-hint *ngIf="!showError(isOneWay)" align="end">Requried</mat-hint>
      <mat-error *ngIf="showError(isOneWay)">{{isOneWay.errors.msg}}</mat-error>
      <button mat-icon-button matSuffix color="primary" type="button" (click)="openHelp('trip-detail#onewayReturn')">
        <mat-icon class="help-icon">help</mat-icon>
      </button>
    </mat-form-field>
    <!-- start address -->
    <mat-form-field class="full-width">
      <mat-label *ngIf="em.isOneWay">Pick-up Address</mat-label>
      <mat-label *ngIf="!em.isOneWay">Pick-up/Drop-off Address</mat-label>
      <input matInput appGooglePlaces id="pickup" type="text" name="startAddress_addressFormated" (ngModel)="searchTxt"
        (placeWithRef)='onPlaceLoaded($event)' #startAddress_addressFormated="ngModel" [(ngModel)]="em.startAddress.addressFormated" [disabled]="!isEditMode" autocomplete="off">
      <span matSuffix>
        <button mat-icon-button color="primary" type="button" (click)="removeAddress(startAddress_addressFormated)"
          *ngIf="em.startAddress.geoLoc">
          <mat-icon>cancel</mat-icon>
        </button>
      </span>
      <span matSuffix>
        <button mat-icon-button color="primary" type="button" (click)="openHelp('rent-option#address')">
          <mat-icon class="help-icon" [inline]="true">help</mat-icon>
        </button>
      </span>
      <mat-hint *ngIf="!showError(startAddress_addressFormated)" align="end">Required</mat-hint>
      <mat-error *ngIf="showError(startAddress_addressFormated)">{{startAddress_addressFormated.errors.msg}}
      </mat-error>
    </mat-form-field>

    <!-- end address -->
    <mat-form-field class="full-width" *ngIf="em.isOneWay">
      <mat-label>Drop Address</mat-label>
      <input matInput appGooglePlaces type="text" name="endAddress_addressFormated" (ngModel)="searchTxt"
        (placeLoaded)='onPlaceLoaded($event)' #endAddress_addressFormated="ngModel" [(ngModel)]="em.endAddress.addressFormated" autocomplete="off">
      <span matSuffix>
        <button mat-icon-button color="primary" type="button" (click)="removeAddress(endAddress_addressFormated)"
          *ngIf="em.endAddress.geoLoc">
          <mat-icon>cancel</mat-icon>
        </button>
        <button mat-icon-button color="primary" type="button" (click)="openHelp('rent-option#address')">
          <mat-icon class="help-icon" [inline]="true">help</mat-icon>
        </button>
      </span>
      <mat-hint *ngIf="!showError(endAddress_addressFormated)" align="end">Required</mat-hint>
      <mat-error *ngIf="showError(endAddress_addressFormated)" align='end'>
        {{endAddress_addressFormated.errors.msg}}
      </mat-error>
    </mat-form-field>
    <mat-form-field class="full-width" *ngIf="em.isOneWay">
      <span matPrefix>$ &nbsp;</span>
      <mat-label>Dropoff Fee</mat-label>
      <input matInput [disabled]="!isEditMode" type="number" name="dropOffFee" #dropOffFee="ngModel"
        [(ngModel)]="em.dropOffFee" autocomplete="off">
      <mat-hint *ngIf="!showError(dropOffFee)" align="end">Required</mat-hint>
      <mat-error *ngIf="showError(dropOffFee)">{{dropOffFee.errors.msg}}</mat-error>
    </mat-form-field>
    <mat-form-field class="radio1">
        <mat-label>Replacement Unit</mat-label>
        <input matInput name="isReplacementUnit" [(ngModel)]="em.isReplacementUnit" [disabled]="!isEditMode"
          [hidden]="true">
          <div style="display: grid; align-self: bottom">
            <mat-radio-group aria-label="Select an option" name="isReplacementUnit" #isReplacementUnit="ngModel" [disabled]="!isEditMode"
              [(ngModel)]="em.isReplacementUnit" (change)="setupFormVal()">
              <mat-radio-button color="accent" [value]="false" style="padding-right: 10px">
                Regular Rental
              </mat-radio-button>
              <mat-radio-button color="accent" [value]="true" style="padding-right: 10px">
                Replacement Unit
              </mat-radio-button>
            </mat-radio-group>
          </div>
        <mat-hint *ngIf="!showError(isReplacementUnit)" align="end">Optional</mat-hint>
        <mat-error *ngIf="showError(isReplacementUnit)">{{promoContent.errors.msg}}</mat-error>
        <button mat-icon-button matSuffix color="primary" type="button" (click)="openHelp('trip-detail#promoContent')">
          <mat-icon class="help-icon">help</mat-icon>
        </button>
    </mat-form-field>
    <mat-form-field class="promo">
      <mat-label>Promotional Content</mat-label>
      <textarea matInput name="promoContent" #promoContent="ngModel" [(ngModel)]="em.promoContent"
        [disabled]="!isEditMode"></textarea>
      <mat-hint *ngIf="!showError(promoContent)" align="end">Optional</mat-hint>
      <mat-error *ngIf="showError(promoContent)">{{promoContent.errors.msg}}</mat-error>
      <button mat-icon-button matSuffix color="primary" type="button" (click)="openHelp('trip-detail#promoContent')">
        <mat-icon class="help-icon">help</mat-icon>
      </button>
    </mat-form-field>
  </form>
</ng-template>

<ng-template #pickupDropOff>

  <!-- start address -->
  <mat-form-field class="full-width">
    <mat-label *ngIf="em.isOneWay">Pick-up Address</mat-label>
    <mat-label *ngIf="!em.isOneWay">Pick-up/Drop-off Address</mat-label>
    <input matInput appGooglePlaces id="pickup" type="text" name="startAddress_addressFormated" (ngModel)="searchTxt"
      (placeWithRef)='onPlaceLoaded($event)' #startAddress_addressFormated="ngModel" [(ngModel)]="em.startAddress.addressFormated" [disabled]="!isEditMode" autocomplete="off">
    <span matSuffix>
      <button mat-icon-button color="primary" type="button" (click)="removeAddress(startAddress_addressFormated)"
        *ngIf="em.startAddress.geoLoc">
        <mat-icon>cancel</mat-icon>
      </button>
      <button mat-icon-button color="primary" type="button" (click)="openHelp('rent-option#address')">
        <mat-icon class="help-icon" [inline]="true">help</mat-icon>
      </button>
    </span>
    <mat-hint *ngIf="!showError(startAddress_addressFormated)" align="end">Required</mat-hint>
    <mat-error *ngIf="showError(startAddress_addressFormated)">{{startAddress_addressFormated.errors.msg}}
    </mat-error>
  </mat-form-field>

  <!-- end address -->
  <mat-form-field class="full-width" *ngIf="em.isOneWay">
    <mat-label>Drop Address</mat-label>
    <input matInput appGooglePlaces type="text" name="endAddress_addressFormated" (ngModel)="searchTxt"
      (placeLoaded)='onPlaceLoaded($event)' #endAddress_addressFormated="ngModel" 
      [(ngModel)]="em.endAddress.addressFormated" autocomplete="off">
    <span matSuffix>
      <button mat-icon-button color="primary" type="button" (click)="removeAddress(endAddress_addressFormated)"
        *ngIf="em.endAddress.geoLoc">
        <mat-icon>cancel</mat-icon>
      </button>
      <button mat-icon-button color="primary" type="button" (click)="openHelp('rent-option#address')">
        <mat-icon class="help-icon" [inline]="true">help</mat-icon>
      </button>
    </span>
    <mat-hint *ngIf="!showError(endAddress_addressFormated)" align="end">Required</mat-hint>
    <mat-error *ngIf="showError(endAddress_addressFormated)" align='end'>
      {{endAddress_addressFormated.errors.msg}}
    </mat-error>
  </mat-form-field>
  <mat-form-field class="full-width" *ngIf="em.isOneWay">
    <span matPrefix>$ &nbsp;</span>
    <mat-label>Dropoff Fee</mat-label>
    <input matInput [disabled]="!isEditMode" type="number" name="dropOffFee" #dropOffFee="ngModel"
      [(ngModel)]="em.dropOffFee" autocomplete="off">
    <mat-hint *ngIf="!showError(dropOffFee)" align="end">Required</mat-hint>
    <mat-error *ngIf="showError(dropOffFee)">{{dropOffFee.errors.msg}}</mat-error>
  </mat-form-field>
</ng-template>

<ng-template #oneWayReturn>

  <mat-form-field class="radio1">
    <mat-label>Return/One-Way</mat-label>
    <input matInput name="isOneWay" #isOneWay="ngModel" [(ngModel)]="em.isOneWay" style="display: none">
    <div style="display: grid; align-self: bottom">
      <mat-radio-group aria-label="Select an option" name="isOneWay" #isOneWay="ngModel" [disabled]="!isEditMode"
        [(ngModel)]="em.isOneWay">
        <mat-radio-button color="accent" [value]="false" style="padding-right: 10px">
          Return
        </mat-radio-button>
        <mat-radio-button color="accent" [value]="true" style="padding-right: 10px">
          One Way
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <mat-hint *ngIf="!showError(isOneWay)" align="end">Requried</mat-hint>
    <mat-error *ngIf="showError(isOneWay)">{{isOneWay.errors.msg}}</mat-error>
    <button mat-icon-button matSuffix color="primary" type="button" (click)="openHelp('trip-detail#onewayReturn')">
      <mat-icon class="help-icon">help</mat-icon>
    </button>
  </mat-form-field>
</ng-template>

<ng-template #rentalPlansTemp>
  <!-- <form #rentalPlansForm="ngForm"> -->

  <!-- start date -->
  <mat-form-field class="full-width">
    <input #availStartDate="ngModel" matInput [matDatepicker]="availStartDate1" name="availStartDate"
      [(ngModel)]="em.availStartDate"  [min]="em.availStartDateMin"
      [max]="em.availStartDateMax" [disabled]="!isEditMode" (dateChange)="dateChange($event)" autocomplete="off"
      [readonly]="true" placeholder="Available From">
    <mat-hint *ngIf="!showError(availStartDate)" align="end">Required</mat-hint>
    <mat-error *ngIf="showError(availStartDate)" align="end">{{availStartDate.errors.msg}}</mat-error>
    <mat-datepicker touchUi #availStartDate1 [disabled]="!isEditMode" startView="month"></mat-datepicker>
    <mat-datepicker-toggle matSuffix [for]="availStartDate1"></mat-datepicker-toggle>
  </mat-form-field>
  <!-- end date -->
  <mat-form-field class="full-width">
    <input #availEndDate="ngModel" matInput [matDatepicker]="availEndDate1" name="availEndDate"
      [(ngModel)]="em.availEndDate"  [min]="em.availEndDateMin"
      [max]="em.availEndDateMax" [disabled]="!isEditMode" (dateChange)="dateChange($event)" autocomplete="off"
      [readonly]="true" placeholder="Available To">
    <mat-hint *ngIf="!showError(availEndDate)" align="end">Required</mat-hint>
    <mat-error *ngIf="showError(availEndDate)" align="end">{{availEndDate.errors.msg}}</mat-error>
    <mat-datepicker touchUi #availEndDate1 [disabled]="!isEditMode" startView="month"></mat-datepicker>
    <mat-datepicker-toggle matSuffix [for]="availEndDate1"></mat-datepicker-toggle>
  </mat-form-field>
  <ng-container *ngFor="let p of em.rentalPlans">
    <trent-rental-plan [rPlan]="p" [isEMode]="isEditMode" [selectPlan]="true" [currency]="em.currency"
      (planSelectionChange)="onPlanSelection(p)"></trent-rental-plan>
  </ng-container>
  <!-- </form> -->
</ng-template>
<ng-template #pictureTemp>

  <!-- <mat-card class="carusole-card" *ngIf="!!emRTruck.pictures && (currMenu==='pic' || !(isHandset$ | async))"> -->
  <div class="carusole-button-container">
    <button class="image-add-button" mat-mini-fab color="primary" *ngIf="!!isEditMode" (click)="onCameraClick()">
      <!-- filePicker.click() -->
      <mat-icon>add</mat-icon>
    </button>
    <div class="carusole-container">
      <trent-carousel #carouselCtrl class="pod-pictures" [pics]="emRTruck.pictures" [cMetadata]="metadata" [path]="path"
        [eMode]="isEditMode" [containerHeight]="250" [canDelete]="isEditMode"
        (emitSelected)="selectedPics($event)" (deleteFiles)="deletePics($event)" [hidePage]="true" [hideUpload]="true"
        [hideTabs]="true" [showDots]="true" [hideThumbnails]="true">
      </trent-carousel>
      <!-- (metadataGetter)="uploadTrigger()" -->
    </div>
  </div>

  <div *ngIf="(!emRTruck.pictures || emRTruck.pictures.length===0 && !!emRTruck.defaultImage)" class="default-img-cont">
    <div>
      <img [src]="emRTruck.defaultImage" class="default-image">
    </div>
  </div>
  <!-- </mat-card> -->

</ng-template>
<ng-template #securityDepositTemp let-form="form">

  <mat-form-field class="full-width">
    <span matPrefix>$ &nbsp;</span>
    <mat-label>Security Deposit</mat-label>
    <input matInput [disabled]="!isEditMode" type="number" name="deposit" #deposit="ngModel" [(ngModel)]="em.deposit" autocomplete="off">
    <mat-hint *ngIf="!showError(deposit)" align="end">Required</mat-hint>
    <mat-error *ngIf="showError(deposit)">{{deposit.errors.msg}}</mat-error>
  </mat-form-field>
</ng-template>

<ng-template #desktopActions>
  <mat-card-actions *ngIf="!(isHandset$ | async)" class="d-flex d-j-end">
    <button [disabled]="serverReqInProcess" *ngIf="isEditMode" type="button" (click)="cancel()" class="md-18 ml-4"
      mat-stroked-button>
      <mat-icon>close</mat-icon>Cancel
    </button>
    <button [disabled]="serverReqInProcess" *ngIf="isReadMode" (click)="edit()" type="button" class="md-18 ml-4"
      mat-stroked-button>
      <mat-icon>edit</mat-icon> Edit
    </button>
    <button [disabled]="serverReqInProcess" *ngIf="isEditMode" type="button" (click)="save()" class="md-18 ml-4"
      mat-raised-button color="primary">
      <mat-icon>done</mat-icon> Update
    </button>
  </mat-card-actions>
</ng-template>




<ng-template #shortTermTemp let-p="p">
  <ng-container *ngFor="let item of p; let i=index">
    <trent-rental-plan [rPlan]="item" [isEMode]="isEditMode" style="padding-bottom: 10px;"
      (mileageTypeChange)="onMileageTypeChange(item)" [currency]="em.currency"></trent-rental-plan>
    <mat-divider *ngIf="i<p.length-1" style="padding-bottom: 10px;"></mat-divider>
  </ng-container>
</ng-template>
<ng-template #monthlyTemp let-p="p">
  <ng-container *ngFor="let item of p; let i=index">
    <trent-rental-plan [rPlan]="item" [isEMode]="isEditMode" style="padding-bottom: 10px;"  [currency]="em.currency"></trent-rental-plan>
    <mat-divider *ngIf="i<p.length-1" style="padding-bottom: 10px;"></mat-divider>
  </ng-container>
</ng-template>
<ng-template #yearlyTemp let-p="p">
  <ng-container *ngFor="let item of p; let i=index">
    <trent-rental-plan [rPlan]="item" [isEMode]="isEditMode" style="padding-bottom: 10px;" [currency]="em.currency"></trent-rental-plan>
    <mat-divider *ngIf="i<p.length-1" style="padding-bottom: 10px;"></mat-divider>
  </ng-container>
</ng-template>
<ng-template #selectCompanies>
  <!-- <mat-card> -->
  <mat-card-header>
    <div mat-card-avatar>
      <mat-icon matSuffix color="primary">business</mat-icon>
    </div>
    <mat-card-title>Exclusive Rental Offer</mat-card-title>
  </mat-card-header>
  <trent-select-company [param]="vParam" (itemSelectedC)="addSelectedComp($event)" [isMasterList]="true" [showChips]="false" [inputLabel]="'Customer Company'" [selectedKeys]="em.targetCCids" [defComp]="defaultCompany.cid" (itemRemovedC)="removeCompany($event)" [isEMode]="isEditMode"></trent-select-company>

  <ng-container *ngFor="let e of em.targetCCids">
    {{e}}
  </ng-container>

  <!-- </mat-card> -->
</ng-template>

<div style="display: none;" *ngIf="!!addHiddenDom">
  <ng-container *ngTemplateOutlet="pictureTemp"></ng-container>

</div>
<ng-template #openContractRentalTemplate>
  <mat-card appearance="outlined" class="log-card" *ngIf="emRTruck?.productSummary">
    <h2>Cannot Setup Rental</h2>
    <mat-card-header>
      <div mat-card-avatar>
        <mat-icon matSuffix color="primary">{{emRTruck.productSummary?.prodIcon}}</mat-icon>
      </div>
      <mat-card-title>{{emRTruck.productSummary.title}}
        (#{{emRTruck.productSummary.unitName}})
      </mat-card-title>
      <mat-card-subtitle>{{(emRTruck.productSummary?.make)}} {{emRTruck.productSummary?.modelYear}}
      </mat-card-subtitle>
      <mat-card-subtitle>{{emRTruck.productSummary?.summaryString}}</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <ng-container *ngIf="bids.length>0">
        <h4>Open Contract</h4>
        <a (click)="openContract(bids[0])">
          <ng-container *ngIf="bids[0].contactNoString; else elseTemplateContractNo">
            {{bids[0].contactNoString}}
            
          </ng-container>
          <ng-template #elseTemplateContractNo>
            {{bids[0].id}}            
          </ng-template>
          
        </a>
      </ng-container>
      <ng-container *ngIf="otherRentals?.length>0">
        <h4>Open Rental</h4>
        <a (click)="openRental(otherRentals[0])">{{otherRentals[0].availStartDate | date}} - {{otherRentals[0].availEndDate| date}}</a>
      </ng-container>
      
    </mat-card-content>
  </mat-card>
</ng-template>



<!-- Input File -->
<input #filePicker hidden type="file" (change)="addPicturesWithoutLocId($event)">