import { Equals, IsBoolean, isNumber, IsNumber, Length, Max, ValidateIf, validateSync, IsDefined, ValidateNested, IsDate, ValidatorOptions, isDefined, IsEnum } from 'class-validator';
import { Exclude, instanceToInstance, plainToInstance, Expose, Type } from 'class-transformer';
import { IValidationMsg } from '../error-handling';
import { sanitizeDate, sanitizeDateIPoint, toValidationError } from '../utility';
import { addYears, isAfter, lastDayOfMonth } from 'date-fns';
import { BaseModel } from '../base';
import { logger } from '../log/logger';
import { SalesPerson } from '../legacy-contract/legacy-contract-base';

export enum PromotionResponseType{
  email = 1,
  sms =  2
}
/**
 * @author - MKN
 * @purpose - 
 */
@Exclude()
export class PromotionResponse extends BaseModel {
    
  public static readonly collectionName = 'promotion-response';

  @Expose()
  @IsEnum(PromotionResponseType)
  promotionResponseType: PromotionResponseType = PromotionResponseType.email;

  @Expose()
  phoneNumber: string;

  @Expose()
  email: string;

  @Expose()
  promotionId: string;

  @Expose()
  promotionLeadId: string;

  @Expose()
  responseText: string;

  constructor() {
    super();
  }

  public static parse(obj) {
    if (obj instanceof (PromotionResponse)) { return <PromotionResponse>obj; }

    const m = plainToInstance<PromotionResponse, any>(PromotionResponse, sanitizeDateIPoint(obj));
    m.sanitize();
    return m;
  }

  sanitize() {
    // super.sanitize();
  }

  clone() {
    const t = instanceToInstance(this);
    t.sanitize();
    return t;
  }

  validateSync(options?: any): IValidationMsg {

    const m = validateSync(this, options);
    const r = toValidationError(m);
    return r;
  }

  checkMobileNumber(mobile : string){
    return mobile.replace(/[$,]/g, '').trim();
  }

  //  /**
  //    * Generate legacy contract data from raw data
  //    * @param lc 
  //    */
  //  createPromotionResponseData(pl: any) {
  //   this.email = pl.email;
  //   this.phoneNumber = this.checkMobileNumber(pl.mobile);
  //   this.companyName = pl.companyName;
  //   this.stateProvince = pl.stateProvince;
  //   this.country = pl.country;
  //   this.accountManager = { name : pl.salesPerson } 
  // }



}