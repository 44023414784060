import { Component, OnDestroy, OnInit } from '@angular/core';
import { SparePart, SparePartSortByCategory } from '@trent/models/spare-part/spare-part';
import { SparePartParam } from '@trent/models/spare-part/spare-part-param';
import { BasePage } from '@trent/models/UI/base.page';
import { AllSparePartsRequested, SparePartState } from '@trent/store/spare-part/spare-part.state';
import { map } from 'rxjs/operators';
import { EventService } from '@trent/services/event.service';
import { DialogService } from '@trent/services/dialog/dialog.service';
import { Store } from '@ngxs/store';


@Component({
  selector: 'trent-dpc-home-best-seller',
  templateUrl: './dpc-home-best-seller.component.html',
  styleUrls: ['../dpc-common.scss']
})

export class DpcHomeBestSellerComponent extends BasePage<SparePart[]> implements OnInit, OnDestroy {

  param: SparePartParam;
  list:SparePart[];
  activeTab:number;

  constructor(store: Store, ds: DialogService, es: EventService) {
    super(store, ds, es);
  }

  ngOnInit(): void {
    this.getStoreData();
  }

  /**
   * Get spare part data from store
   */
  getStoreData() {
    this.param = {};
    const pData = { size: 10, offset: 0, full: false };
    // Dispatch new request.
    this.store.dispatch(new AllSparePartsRequested({ pData: pData, param: { ...this.param } }));

    const spareParts$ = this.store.select(SparePartState.selectAllSpareParts)
      .pipe(map(clientFilterFn => clientFilterFn({ ...this.param })));
    this.mSubscription.push(spareParts$.subscribe(d => {
      if (d && d.length > 0) {
        this.m = d;
        // here filter out data according category
        this.list = this.m.filter(v => v.sortBy == SparePartSortByCategory.BestSeller);
        // Initially bestseller tab active
        this.activeTab = SparePartSortByCategory.BestSeller;
      }
    }));
  }

  /**
   * Display spare part category on UI
   */
  getCategoryData(category: number) {
    this.list = this.m.filter(v => v.sortBy == category);
    // According to category active the tab
    this.activeTab = category;
  }

  ngOnDestroy(): void {
    this.cleanListeners();
  }


}
