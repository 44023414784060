import { logger } from '../log/logger';
import { MapLabel, MapLabelType } from '../map/map-label';
import { StoreLocation } from './store-location';

export const parseStoreLocationArray = (obj: any[]): StoreLocation[] => {
  return obj.map(o => <StoreLocation>parseStoreLocation(o));
};

export const parseStoreLocation = (obj: any): StoreLocation => {
  if (obj == null) { return null; }
  const c = obj as StoreLocation; // cast it to base, remember it is still a javascript obj at run time.
  return StoreLocation.parse(c);;
};

export const getMapLabelsAggList = (list, type:MapLabelType = 'StoreLocation'): MapLabel[] => {
  const labels: MapLabel[] = [];

  for (const key in list) {
    if (Object.prototype.hasOwnProperty.call(list, key)) {
      const e = list[key];
      let actions: string;
      actions = '<a id="IdAction">Details</a>';
      labels.push({
        iPoint: {
          latitude: Math.round(e.address.geoLoc.geopoint.latitude * 1000000) / 1000000,
          longitude: Math.round(e.address.geoLoc.geopoint.longitude * 1000000) / 1000000
        },
        geohash: e.address.geoLoc.geohash,
        title: `${e.address.addressFormated}`,
        type: type,
        id: e.id,
        desc: `${actions}`,
        clickLabel: [{ btnLabel: 'Details', emitterAction: 'mapClusterAction' }],
        actions: ['ClusterAction', 'IdAction']
      });
    }
    // logger.log('labels', labels);

  }
  return labels;
};

export const getMapLabels = (vehicle): MapLabel[] => {
  const labels: MapLabel[] = [];
  const type = 'Gps';

  for (let index = 0; index < vehicle.length; index++) {
    const v = vehicle[index];
    let actions: string;
    labels.push({
      iPoint: {
        latitude: Math.round(v.lat * 1000000) / 1000000,
        longitude: Math.round(v.lng * 1000000) / 1000000
      },
      geohash: v.geohash,
      title: `${v.address}`,
      type: type,
      id: v.id,
    });
   
  }
  return labels;
};

