<div class="footer" [ngClass]="{'pts-color': footerFrom === 'pts' , 'dpc-color': footerFrom === 'dpc'}">
	<div class="footer-main">
		<div class="footer-left">
			<div class="inner i1">
				<ng-container>
					<mat-list>
						<mat-list-item  *ngIf="!isHandset">
							<h4 class="clr mb-8 footer_header">CONTACT US</h4>
						</mat-list-item>
						<mat-list-item>
							<a class="mat-body d-flex align-items-center anchor footer_sub" href="tel:{{telephone}}"><mat-icon matSuffix class="pe-2">phone_enabled</mat-icon>{{telephone}}</a>
						</mat-list-item>
						<mat-list-item>
							<a class="mat-body d-flex align-items-center anchor footer_sub" href="mailto:{{email}}"><mat-icon matSuffix class="pe-2">mail</mat-icon><strong>Email Us</strong></a>
						</mat-list-item>
					</mat-list>
				</ng-container>
				<ng-container *ngIf="!isHandset">
					<mat-list style="margin-top: 66px;">
						<mat-list-item  *ngIf="!isHandset">
							<h4 class="clr mb-8 footer_header">CONNECT WITH US</h4>
						</mat-list-item>
						<mat-list-item>
							<div class="button-row">
								<div class="d-flex d-j-between flex-wrap" style="margin-left: -13px;">
								  <ng-container *ngFor="let item of socials">
									<a href="{{item.url}}" target="_blank" mat-icon-button>
									  <fa-icon [icon]="['fab', item.icon]"></fa-icon>
									</a>
								  </ng-container>
								</div>
							  </div>
						</mat-list-item>
					</mat-list>
				  </ng-container> 
			</div>
			<div class="inner">
				<trent-footer-location-selector [stores]="stores" [selectedStore]="selectedStore"
					(_store)="getSelectedStore($event)" [hideShow]="true"></trent-footer-location-selector>
				<trent-footer-visit-store [selectedStore]="selectedStore"></trent-footer-visit-store>
			</div>
		</div>
		<div class="footer-right" *ngIf="footerFrom === 'pts'">
			<div class="footer-img" (click)="toOurLOc()"></div>
		</div>
		<div class="footer-right" *ngIf="footerFrom === 'dpc' && mapLabels?.length > 0">
			<trent-map-wrapper [mLabels]="mapLabels" [mapHeight]="400" [inputId]="'footerMap'"></trent-map-wrapper>
		</div>
	</div>
	<ng-container *ngTemplateOutlet="banner"></ng-container>
	<ng-container *ngIf="isHandset">
		<div class="button-row d-flex justify-content-center">
		  <div class="d-flex d-j-between flex-wrap">
			<ng-container *ngFor="let item of socials">
			  <a href="{{item.url}}" target="_blank" mat-icon-button>
				<fa-icon [icon]="['fab', item.icon]"></fa-icon>
			  </a>
			</ng-container>
		  </div>
		</div>
	  </ng-container> 
</div>

<ng-template #banner>
	<div class="footer-banner d-flex justify-content-center align-items-center py-4 wrap">
		<span>{{ footerFrom === 'pts' ? 'Pride Truck Sales Ltd': 'Dixie Truck Parts Ltd'}} © 2023 </span>
		<ul class="d-flex justify-content-between footer-link wrap">
			<!-- <li><a href="#" target="_blank">SiteMap</a></li>
			<li><a href="#" target="_blank">Terms and Conditions</a></li> -->
			<li style="cursor: pointer;"><a (click)="openPolicy()">Privacy Policy</a> </li>
		</ul>
	</div>
</ng-template>
