<mat-card appearance="outlined" class="log-card">
  <mat-card-header>
    <div mat-card-avatar>
      <mat-icon class="x2" matSuffix>error</mat-icon>
    </div>
    <mat-card-title>
      Page Not Found
    </mat-card-title>
  </mat-card-header>
  <mat-card-content class="login-content">
    <p>
      We are sorry, the requested page was not found on the website.
    </p>
    <p>
      Requested Page:
      <b
        ><i> {{ url }}</i></b
      >
    </p>
  </mat-card-content>
  <mat-card-actions>
    <div>
      <a class="mat-primary md" [routerLink]="['/']" mat-raised-button>
        <span class="mat-button-wrapper"> <mat-icon>home</mat-icon></span>
      </a>
    </div>
  </mat-card-actions>
</mat-card>
