import { IsBoolean, IsDate, IsDefined, IsEnum, IsOptional, Length, Max, MaxLength, Min, ValidateIf, ValidatorOptions } from "class-validator";
import { BaseModel } from "../base/base-model";
import { IValidationMsg } from "../error-handling";
import { sanitizeDate, sanitizeDateIPoint } from "../utility/sanitize-helper";
import { LegacyContractValidationGroup } from "./legacy-contract-base";
import { Exclude, Expose, instanceToInstance, plainToInstance } from 'class-transformer';
import { round } from "../utility/helper";
import { AccountStanding, DmfReserve } from "./legacy-contract-enum";


const lcLeaseOperation: LegacyContractValidationGroup = 'lcLeaseOperation';


/**
 * Lease Operations model 
 * @author SSS 
 * @alert check with Rupinder 
 */

@Exclude()
export class LCLeaseOperation extends BaseModel {

    constructor() {
        super();
    }
    @Expose()
    @IsOptional()
    @ValidateIf(o => o.amtRecd, { groups: [lcLeaseOperation] })
    @Min(0, { message: 'Amount Recd should be more than $constraint1 number', groups: [lcLeaseOperation] })
    @Max(10000000, { message: 'Amount Recd should be less than $constraint1 number', groups: [lcLeaseOperation] })
    amtRecd: number;

    @Expose()
    @IsBoolean()
    isDmfReserve: boolean = false;

    @Expose()
    @IsOptional()
    @ValidateIf(o => o.isDmfReserve, { groups: [lcLeaseOperation] })
    @IsDefined({ message: 'DMF Reserve should be defined', groups: [lcLeaseOperation] })
    @IsEnum(DmfReserve)
    dmfReserve: number;


    get reserveBal(): number {
        return round((this.amtRecd * this.dmfReserve), 2);
    };

    @Expose()
    @IsOptional()
    @ValidateIf(o => o.releaseDate, { groups: [lcLeaseOperation] })
    @IsDate({ groups: [lcLeaseOperation] })
    releaseDate: Date;

    @Expose()
    @IsOptional()
    @ValidateIf(o => o.misc, { groups: [lcLeaseOperation] })
    @Length(0, 100, { groups: [lcLeaseOperation] })
    misc: string;

    @Expose()
    @IsOptional()
    @ValidateIf(o => o.tranche, { groups: [lcLeaseOperation] })
    @MaxLength(100, { message: 'tranche is required', groups: [lcLeaseOperation] })
    tranche: string;


    @Expose()
    @IsOptional()
    @ValidateIf(o => o.fundedOn, { groups: [lcLeaseOperation] })
    @IsDate({ message: 'Funded On should be a date', groups: [lcLeaseOperation] })
    fundedOn: Date;

    @Expose()
    @IsOptional()
    @ValidateIf(o => o.accountStanding, { groups: [lcLeaseOperation] })
    @IsEnum(AccountStanding, { groups: [lcLeaseOperation] })
    accountStanding: AccountStanding;

    @Expose()
    @IsOptional()
    @ValidateIf(o => o.buyoutDate, { groups: [lcLeaseOperation] })
    @IsDate({ groups: [lcLeaseOperation] })
    buyoutDate: Date;

    @Expose()
    @IsOptional()
    @ValidateIf(o => o.buyoutFunder, { groups: [lcLeaseOperation] })
    @Length(0, 100, { groups: [lcLeaseOperation] })
    buyoutFunder: string;

    @Expose()
    @IsOptional()
    @ValidateIf(o => o.statusDate, { groups: [lcLeaseOperation] })
    @IsDate({ groups: [lcLeaseOperation] })
    statusDate: Date;

    @Expose()
    @IsOptional()
    @ValidateIf(o => o.holdback, { groups: [lcLeaseOperation] })
    @Max(1000000, { groups: [lcLeaseOperation] })
    @Min(0, { groups: [lcLeaseOperation] })
    holdback: number;

    @Expose()
    @IsOptional()
    @ValidateIf(o => o.holdbackRel1, { groups: [lcLeaseOperation] })
    @Max(1000000, { groups: [lcLeaseOperation] })
    @Min(0, { groups: [lcLeaseOperation] })
    holdbackRel1: number;

    @Expose()
    @IsOptional()
    @ValidateIf(o => o.holdbackRel2, { groups: [lcLeaseOperation] })
    @Max(1000000, { groups: [lcLeaseOperation] })
    @Min(0, { groups: [lcLeaseOperation] })
    holdbackRel2: number;

    @Expose()
    @IsOptional()
    @ValidateIf(o => o.holdbackRelDate1, { groups: [lcLeaseOperation] })
    @IsDate({ groups: [lcLeaseOperation] })
    holdbackRelDate1: Date;

    @Expose()
    @IsOptional()
    @ValidateIf(o => o.holdbackRelDate2, { groups: [lcLeaseOperation] })
    @IsDate({ groups: [lcLeaseOperation] })
    holdbackRelDate2: Date;

    @Expose()
    @IsOptional()
    @ValidateIf(o => o.schedule, { groups: [lcLeaseOperation] })
    @Length(0, 50, { groups: [lcLeaseOperation] })
    schedule: string;

    get holdBackBalance(): number {
        return round((this.holdbackRel1 + this.holdbackRel2 - this.reserveBal), 2);
    }


    public static parse(obj: any) {
        if (obj == null) { return null; }
        const m = plainToInstance<LCLeaseOperation, any>(LCLeaseOperation, sanitizeDateIPoint(obj));
        m.sanitize();

        return m;
    }

    validateSync(options?: ValidatorOptions): IValidationMsg {
        const r = this.validateSyncBase(this, options);
        return r;
    }

    clone() {
        const t = instanceToInstance(this);
        t.sanitize();
        return t;
    }

    sanitize() {
        // if data was recieved from firebase, date is stored as snapshot.
        super.sanitize();
        this.releaseDate = sanitizeDate(this.releaseDate);
        this.fundedOn = sanitizeDate(this.fundedOn);
        this.buyoutDate = sanitizeDate(this.buyoutDate);
        this.statusDate = sanitizeDate(this.statusDate);
        this.holdbackRelDate1 = sanitizeDate(this.holdbackRelDate1);
        this.holdbackRelDate2 = sanitizeDate(this.holdbackRelDate2);
    }

    getDmfReserveValue(dmfReserve: string) {

        if (dmfReserve && typeof (dmfReserve) == 'string') {
            if (dmfReserve === 'YES' || dmfReserve === 'yes') {
                this.dmfReserve = 0.12;//12%
            } else if (dmfReserve === 'NO' || dmfReserve === 'no') {
                this.dmfReserve = 0;//0
            } else if (dmfReserve.indexOf("%") > -1) {
                this.dmfReserve = +dmfReserve.split("%")[0] / 100;
            }
        } else {
            this.dmfReserve = 0;
        }

    }

    createLeaseOperations(lc: any) {

        this.releaseDate = sanitizeDate(new Date(lc.releaseDate));
        this.fundedOn = sanitizeDate(new Date(lc.fundedOn));
        this.buyoutDate = sanitizeDate(new Date(lc.buyoutDate));
        this.statusDate = sanitizeDate(new Date(lc.statusDate));
        this.holdbackRelDate1 = sanitizeDate(new Date(lc.holdbackRelDate1));
        this.holdbackRelDate2 = sanitizeDate(new Date(lc.holdbackRelDate2));

        this.amtRecd = lc.amtRecd ? lc.amtRecd : 0;
        this.holdback = lc.holdback ? lc.holdback : 0;
        this.holdbackRel1 = lc.holdbackRel1 ? lc.holdbackRel1 : 0;
        this.holdbackRel2 = lc.holdbackRel2 ? lc.holdbackRel2 : 0;
        this.getDmfReserveValue(lc.dmfReserve);

        this.schedule = lc.schedule;
        this.buyoutFunder = lc.buyoutFunder;
        this.accountStanding = lc.accountStanding;
        this.tranche = lc.tranche;
        this.misc = lc.misc;

    }

}
