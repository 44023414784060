import { Component, HostListener, ViewChild, ElementRef, OnInit, ContentChild, Input, TemplateRef, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'trent-slider-carousel',
  templateUrl: './slider-carousel.component.html',
  styleUrls: ['./slider-carousel.component.scss']
})
export class SliderCarouselComponent implements OnInit {

  @ContentChild('slide', { static: false }) slideTemplateRef: TemplateRef<any>;
  @ViewChild('container', { static: true, read: ElementRef }) container: ElementRef;
  @Input() arr;
  @Input() totalCards: number;
  @Input() height: number;
  @Input() cWidth: number;
  @Input() viewAllShow: boolean;
  @Output() viewAllOutPut = new EventEmitter();

  currentPage: number = 1;
  pagePosition: string = '0%';
  cardsPerPage: number;
  totalPages: number;
  overflowWidth: string;
  cardWidth: string;
  containerWidth: number;

  constructor() { }
  
  @HostListener('window:resize') windowResize() {
    let newCardsPerPage = this.getCardsPerPage();
    if (newCardsPerPage != this.cardsPerPage) {
      this.cardsPerPage = newCardsPerPage;
      this.initializeSlider();
      if (this.currentPage > this.totalPages) {
        this.currentPage = this.totalPages;
        this.populatePagePosition();
      }
    }
  }

  ngOnInit() {
    this.cardsPerPage = this.getCardsPerPage();
    this.initializeSlider();
  }

  initializeSlider() {
    if (this.totalCards) {
      this.totalPages = Math.ceil(this.totalCards / this.cardsPerPage);
    }
    if (this.totalPages) {
      this.overflowWidth = `calc(${this.totalPages * 100}% + ${this.totalPages * 10}px)`;
    }
    this.cardWidth = `calc((${100 / this.totalPages}% - ${this.cardsPerPage * 10}px) / ${this.cardsPerPage})`;

  }

  getCardsPerPage() {
    return Math.floor(this.container.nativeElement.offsetWidth / this.cWidth);
  }

  changePage(incrementor) {
    this.currentPage += incrementor;
    this.populatePagePosition();
  }

  populatePagePosition(): void {
    this.pagePosition = `calc(${-100 * (this.currentPage - 1)}% - ${10 * (this.currentPage - 1)}px)`;
  }

  viewAll(){
    this.viewAllOutPut.emit();
  }
}
