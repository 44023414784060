<div class="special-area">
    <div class="upper-image">
      <div class="img-our-location img"></div>
      <h3 *ngIf="city">Dixie Truck Parts Sales in {{city}}</h3>
    </div>
    <div class="container">
      <div class="mt20 mb20 p-30 mx-auto w-86">
        <h2 *ngIf="city">A one-stop destination for all your spare parts needs in {{city}}</h2>
        <trent-sub-page [pageId]="pid" class="trent-sub-page"> </trent-sub-page>
        <ng-container *ngIf="spareParts?.length > 0">
          <trent-slider-carousel [arr]="spareParts" [totalCards]="salesArrLength" [height]="" [cWidth]="370">
            <ng-template let-item #slide>
              <trent-spare-parts-card [item]="item"></trent-spare-parts-card>
            </ng-template>
          </trent-slider-carousel>
        </ng-container>
        <!-- <div class="flex-container" *ngIf="spareParts?.length > 0">
          <div class="flex-1">
            <trent-sub-page [pageId]="pid1" class="trent-sub-page"> </trent-sub-page>
          </div>
          <div class="flex-1">
            <trent-sub-page [pageId]="pid2" class="trent-sub-page"> </trent-sub-page>
          </div>
        </div> -->
      </div>
    </div>
  
    <h2 style="text-align: center;">VISIT US</h2>
    <p style="text-align: center;margin-bottom: 10px;" *ngIf="address">
      {{address}}
    </p>
    <a id="phone" *ngIf="phone" href="tel:{{phone}}">
      + {{phone}}
    </a>
  </div>