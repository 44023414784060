import { Paging } from "../observable-util/paging";
import { ProductWholesalePrice } from "./product-wholesale-price";
import { CollectionReference, Query, query, QueryConstraint, where, orderBy, startAfter, limit } from 'firebase/firestore';

export interface ProductWholesalePriceParam {
	orderDirection?: 'desc' | 'asc';
	orderBy?: string;
	pid?: string | number; // used only for client filter
	dbStatus?: number;
}

/** Server filtering of firebase query building of query  */
export const productWholesalePriceSearchServerQuery = (ref: CollectionReference, param: ProductWholesalePriceParam, p: Paging) => {
	let q: Query = <any>ref;
	const cons: QueryConstraint[] = [];
	cons.push(where('updatedAt', '<=', new Date()));
	cons.push(orderBy('updatedAt', 'desc'));
	if(param.pid) cons.push(where('productId', '==', `${param.pid}`));
  
	if (!!p.lastDoc) {
	  cons.push(startAfter(p.lastDoc[param.orderBy]));
	}
	cons.push(limit(p.size));
	q = query(ref, ...cons);
	return q;
  };

/** Client Filtering of the data */
export const productWholesalePriceSearchClientFilter = (p: ProductWholesalePrice[], o: ProductWholesalePriceParam): ProductWholesalePrice[] => {

	// only used as client filter
	if (o.pid) {
		p = p.filter((val, idx) => val.id === o.pid);
	}
	return p as ProductWholesalePrice[];
};

export const getProductWholesalePriceOptionOrChildren = (o: ProductWholesalePriceParam): { [key: string]: ProductWholesalePriceParam } => {

	return undefined;
  };