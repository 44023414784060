<ng-container *ngIf="!!m">
 <div class="p16">
  <h4>
    {{m.title}}
  </h4>
  <img [src]="m.imgUrl" alt="" class="thumbnail" *ngIf="!!m.imgUrl">
  <mat-dialog-content class="mat-typography">
    {{m.subtitle}}
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="false">{{m.falseButton}}</button>
    <button mat-raised-button [mat-dialog-close]="true" cdkFocusInitial color="primary">{{m.trueButton}}</button>
  </mat-dialog-actions>
 </div>

</ng-container>