import { Exclude, Expose, plainToInstance, instanceToInstance } from 'class-transformer';
import { TripTransaction } from './trip-transaction';
import { ValidatorOptions, Length, IsBoolean, ArrayMaxSize, ArrayMinSize, MaxLength, MinLength, ValidateIf, IsArray } from 'class-validator';
import { sanitizeDateIPoint, trackingNumberLength } from '../utility';
import { IValidationMsg } from '../error-handling/validation-info';
import { TripBase } from './trip-base';
import { VehicleList } from '../promo/promo-vehicle';
import { isEmpty } from 'lodash';
import { Address } from '../address/address';
import { logger } from '../log/logger';

@Exclude()
export class Hook extends TripTransaction {

  @Expose()
  @Length(1, 10, { message: 'Truck number must be $constraint1 - $constraint2 chars long' })
  truck: string;

  @Expose()
  @Length(1, 10, { message: 'Trailer number must be $constraint1 - $constraint2 chars long' })
  trailer: string;

  @Expose()
  @IsBoolean()
  isLoaded = false;

  /** Array of tracking assciated with the pickup*/
  @ArrayMaxSize(5)
  @ArrayMinSize(1)
  @MaxLength(trackingNumberLength, { each: true })
  @MinLength(trackingNumberLength, { each: true })
  @Expose()
  @ValidateIf(c => c.clearDelivery)
  trackingNos: string[] = [];

  /** trip trackers(notifiers) email dictionary */
  @Expose()
  @IsArray()
  @MaxLength(50, { each: true })
  @ArrayMaxSize(3, { message: 'upto 3 emails allowed' })
  @ArrayMinSize(1, { message: 'Add email(s) to notify!' })
  @ValidateIf(c => c.clearDelivery)
  freightCustomer: string[];

  /** Array of reference number custom to carrier associated pickup, e.g. PO BOL*/
  @ValidateIf(r => !!r.referenceNos)
  @ArrayMinSize(0)
  @ArrayMaxSize(3)
  @MaxLength(20, { each: true })
  @Expose()
  @ValidateIf(c => c.clearDelivery)
  referenceNos: string[] = [];

  constructor() {
    super();
    this.address = new Address();
  }

  public static parse(obj) {
    try {
      if (obj instanceof Hook) {
        // custom parse for trip transaction
        if (!obj.isApptApp) {
          obj.apptDateTime = undefined;
          obj.apptWindow = undefined;
        }
        return obj;
      }
      if (obj == null) { return null; }
      // obj = sanitizeDateIPoint(obj, ipointType);
      obj = sanitizeDateIPoint(obj);
      const m = plainToInstance<Hook, any>(Hook, obj);
      m.sanitize();
      m.isLoaded = m.isLoaded ? m.isLoaded : false;
      m.address = Address.parse(m.address);
      return m;
    } catch (error) {
      logger.log('Error happened during parse', error);
      return null;
    }
  }
  clone() {
    const t = instanceToInstance(this);
    t.sanitize();
    return t;
  }

  validateSync(options?: ValidatorOptions, trip?: TripBase, index?: number,
    truckL?: VehicleList, trailerL?: VehicleList): IValidationMsg {
    const r = super.validateSync(undefined);
    const p = this.validateSyncBase(this, options);
    for (const key in p) {
      if (p.hasOwnProperty(key)) {
        const ele = p[key];
        r[key] = ele;
      }
    }
    const currTripId = !!trip ? trip.id : null;

    // tslint:disable-next-line:max-line-length
    if (!!this.truck && !!truckL && (!!truckL?.list[this.truck].openTrip && Object.keys(truckL?.list[this.truck].openTrip)[0] !== currTripId)) {
      r['truck'] = [`${this.truck} blocked by trip ${Object.values(truckL.list[this.truck].openTrip)[0].description}`];
    }
    // tslint:disable-next-line:max-line-length
    if (!!this.trailer && !!trailerL && (!!trailerL?.list[this.trailer].openTrip && Object.keys(trailerL?.list[this.trailer].openTrip)[0] !== currTripId)) {
      r['trailer'] = [`${this.trailer} blocked by trip ${Object.values(trailerL.list[this.trailer].openTrip)[0].description}`];
    }

    return r;
  }
}
