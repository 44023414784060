import { Component, OnInit, Input } from '@angular/core';
import { MessageType } from '@trentm/UI';

@Component({
  selector: 'trent-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss']
})
export class StatusComponent implements OnInit {

  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
  private _statusMsg = '';

  @Input()
  messageType: MessageType = 'info';

  @Input()
  class = '';

  get statusMsg() { return this._statusMsg; }

  @Input()
  set statusMsg(val) {
    this._statusMsg = val;
    if (!!val && val.length > 0) {
      this.isVisible = true;
    }
  }

  @Input()
  isVisible = true;

  get className() {
    const base = `notification-small notification-has-icon notification-yellow ng-star-inserted`;
    const add = '';
    switch (this.messageType) {
      case 'error':
        return `${base} bg-red-light  ${this.class}`;
      case 'success':
        return `${base} bg-green-dark  ${this.class}`;
      case 'warning':
        return `${base} bg-yellow2-dark  ${this.class}`;
      default:
        return `${base} bg-gray-dark  ${this.class}`;
    }
  }

  constructor() { }

  ngOnInit() { }

}
