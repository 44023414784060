//// <reference path="../../../node_modules/@types/bingmaps/index.d.ts" />
//// <reference path="../../../node_modules/bingmaps/index.js"/>
/// <reference path="../../../node_modules/bingmaps/types/MicrosoftMaps/Microsoft.Maps.All.d.ts"/>
import { IMap, RouteResponse, IRouteRestResponse, IRoutePathRest } from '../../models/map/imap';
import { MapBase } from '../../models/map/map-base';
import { Distance } from '@trent/models/map/distance';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { take, map } from 'rxjs/operators';
import { MapLabel, MapLabelClick } from '@trent/models/map/map-label';
import { DistanceQuery } from '../../models/map/distance-query';
import { InfoBoxData } from '../../models/map/infobox-data';
import { EventService } from '../event.service';
// import * as _ from 'lodash';
import { IPoint } from '@trent/models/product-search/interfaces';
import { isNil, pickBy, } from 'lodash';
import { promiseWraper } from '@trent/models/utility';
import { bingConfig } from '@trent/core/config';
import { LocationData } from '../../models/location/loc-data';
import { logger } from '@trentm/log/logger';
import { UtilityService } from '../utility.service';

export class MapBing extends MapBase implements IMap {

  m: Microsoft.Maps.Map;

  name: string;
  pushpins: Microsoft.Maps.Pushpin[] = [];

  image: '../../../assets/images/products/legoTrailer.jpg';
  loadedCenter: Microsoft.Maps.Location;
  loadedBoundBox: Microsoft.Maps.LocationRect;
  isBoundDisable = false;
  clusterLayer: Microsoft.Maps.ClusterLayer;
  infobox: Microsoft.Maps.Infobox;
  clusterPins: Microsoft.Maps.Pushpin[];
  wPoints: Microsoft.Maps.Directions.Waypoint[];
  currentMapLables: MapLabel[] = [];
  routeSummaryP: Promise<Microsoft.Maps.Directions.IRouteSummary>;
  currentPoints: IPoint[] = [];
  layers: Microsoft.Maps.LayerCollection;
  rectangle: Microsoft.Maps.LocationRect;
  isHandset: boolean;

  constructor(private eventService: EventService,
    private us: UtilityService
    ) {
    super();
    this.us.isHandset$.subscribe((h) => (this.isHandset = h));
  }

  /**
   * Get Distance from api. It will return distance in miles and time in seconds.
   */
  public static getDistance(http: HttpClient, data: DistanceQuery): Promise<Distance> {
    const headers = new HttpHeaders();
    headers.set('Content-Length', '564');
    headers.set('Content-Type', 'application/json');
    headers.set('Access-Control-Allow-Origin', '*');

    if (data.distanceUnit == null) { data.distanceUnit = 'mi'; }
    if (data.timeUnit == null) { data.timeUnit = 'second'; }
    if (data.travelMode == null) { data.travelMode = 'truck'; }
    // Server validation before creating the entry.
    return http.post<Distance>(
      bingConfig.distanceApiUrl,
      data,
      { headers: headers })
      .pipe(
        map(x => Distance.fromBingDistanceQuery(data, x)),
        take(1))
      .toPromise();
  }

  isMapActivated() { return !isNil((this.m as any)?._v8Map); }

  createRouteMapObj(mapLabels?: MapLabel[],
    keepLayers?: { layerIndex: number, layerId: string }[], getRoute?: boolean): Promise<RouteResponse> | null {
    if (this.m == null) {
      this.m = new Microsoft.Maps.Map('#' + this.mapId, <any>{});
    }
    // else if (!this.isMapActivated) {
    //   return null;
    // }
    if (!!mapLabels) {
      this.rectangle = this.getLabelBounds(mapLabels);
    }
    if (!keepLayers || keepLayers.length === 0) {
      this.m.layers.clear();
    } else {
      const layers = pickBy(this.m.layers, (val) => !!val._id);
      for (const key in layers) {
        if (this.layers.hasOwnProperty(key)) {
          const ele = layers[key];
          const k = keepLayers.find(g => g.layerId === ele._id);
          if (!k) {
            this.m.layers.removeAt(+key);
          }
        }
      }
    }
    if (!!mapLabels && mapLabels.length > 1 && !!getRoute) {
      const r = this.getRoute(mapLabels);
      this.m.setView({ bounds: this.rectangle, padding: 80 });
      logger.log('map obj orig', this.m);
      return r;
    } else {
      this.m.setView({ bounds: this.rectangle, padding: 80 });
      logger.log('map obj orig', this.m);
      return null;
    }

  }
  async createRouteMapRest(http: HttpClient, mapLabels?: MapLabel[],
    keepLayers?: { layerIndex: number, layerId: string }[], getRoute?: boolean, gpsLog?: LocationData[]): Promise<RouteResponse> {
    if (this.m == null) {
      this.m = new Microsoft.Maps.Map('#' + this.mapId, <any>{});
    }
    if (!!mapLabels) {
      this.rectangle = this.getLabelBounds(mapLabels);
    }
    if (!keepLayers || keepLayers.length === 0) {
      this.m.layers.clear();
    } else {
      const layers = pickBy(this.m.layers, (val) => !!val._id);
      for (const key in layers) {
        if (this.layers.hasOwnProperty(key)) {
          const ele = layers[key];
          const k = keepLayers.find(g => g.layerId === ele._id);
          if (!k) {
            this.m.layers.removeAt(+key);
          }
        }
      }
    }
    if (gpsLog && gpsLog.length > 0) {
      this.addGpsLogs(gpsLog);
    }
    if (!!mapLabels && mapLabels.length > 1 && !!getRoute) {
      const r = await promiseWraper(this.getRouteUsingRest(http, mapLabels));
      if (!r.success) {
        logger.error('failed to get route', r.error);
        return null;
      }
      const routePath = r.data.resources[0].routePath;
      const layer = this.addPolyLine(routePath, mapLabels);
      this.m.setView({ bounds: this.rectangle, padding: 80 });
      logger.log('map obj', this.m);
      logger.log('route post response', r.data);
      const routeResponse: RouteResponse = {
        layer: { layerIndex: null, layerId: layer.getId() },
        mapLabels: mapLabels,
        // route?: Microsoft.Maps.Directions.IRoute[];
        routeLegs: r.data.resources[0].routeLegs,
        // routeOptions?: Microsoft.Maps.Directions.IDirectionsRequestOptions;
        // toIndex: number;
        travelDistance: r.data.resources[0].travelDistance,
        travelDuration: r.data.resources[0].travelDuration,
        travelDurationTraffic: r.data.resources[0].travelDurationTraffic,
        itineraryItems: r.data.resources[0].routeLegs[0].itineraryItems
      };
      return routeResponse;
    } else {
      this.m.setView({ bounds: this.rectangle, padding: 80 });
      logger.log('map obj orig', this.m);
      return null;
    }
  }

  createMapObj(mapLabels?: MapLabel[], center?: IPoint,
    param?: any, centerAtLabels?: MapLabel[], keepLayers?: { layerIndex: number, layerId: string }[]) {
    if (this.m == null || !this.isMapActivated()) {
      logger.log('[Bing Map] New map created.');
      this.m = new Microsoft.Maps.Map('#' + this.mapId, <any>{});
      this.infobox = new Microsoft.Maps.Infobox(this.m.getCenter(), {
        visible: false,
        zIndex: 100000
      });
      this.infobox.setMap(this.m);
    } else {
      logger.log('[Bing Map] Existing map was used.');
    }

    let rect: Microsoft.Maps.LocationRect;
    if (!!mapLabels) { // && centerAtLabels
      rect = this.getLabelBounds(mapLabels); // centerAtLabels
    } else {
      rect = null;
    }
    if (!!this.m?.entities && this.m.entities.getLength() > 0) {
      this.m.entities?.clear();
    }
    if (!!this.m?.layers) {
      this.m.layers?.clear();
    }
    // this.m.setView({});
    // this.m.setView({ bounds: this.rectangle, padding: 80, zoom: 5 });
    if (!!this.infobox && !param) {
      this.infobox.setMap(null);
    }

    if (!!mapLabels && mapLabels.length > 0) {
      // this.bindEvents();
      this.addPushpin(mapLabels, center);
      this.createCluster();
      if (!!param) {
        const p = this.pushpins.find(f => f.metadata?.mapLabel?.id === param);
        if (!!p) {
          this.pinClick(p);
        }
      }
      this.m.setView({ center: rect.center, zoom: this.isHandset ? 0 : 4 }); // zoom: 10
    } else {
      logger.log('center', center);
      if (!!center) {
        this.m.setView({
          center: new Microsoft.Maps.Location(center.latitude, center.longitude),
        });
      }
    }
    this.currentMapLables = mapLabels;  // old code for commulative route
  }

  redraw() {
    logger.log('map redraw not implemented yet.');
  }

  /** get bouding bpx of the view as user pans and zooms */
  get getBounds() {
    logger.log('getBounds', this.m.getBounds());
    return this.m.getBounds();
  }
  /**add puspins */
  addPushpin(mapLabels?: MapLabel[], searchLoc?: IPoint) {
    if (!this.isMapActivated()) {
      return;
    }
    let loc: Microsoft.Maps.Location;
    const locs = [];
    let pushpin: Microsoft.Maps.Pushpin;
    let rect: Microsoft.Maps.LocationRect;

    // Define an HTML template for a custom infobox.
    this.pushpins = [];
    mapLabels.forEach((element, index) => {
      loc = new Microsoft.Maps.Location(element.iPoint.latitude, element.iPoint.longitude);
      locs.push(loc);
      rect = Microsoft.Maps.LocationRect.fromLocations(locs);
      if (!!element.productType || element.type === 'StoreLocation') {
        pushpin = new Microsoft.Maps.Pushpin(loc); // { text: element.title }
      }
      if (element.type === 'OurLocation' || element.type === 'Gps') {
        pushpin = new Microsoft.Maps.Pushpin(loc, {color:'#ff1700'});
      }

      this.pushpins.push(pushpin);
      pushpin = this.pushpins[index];
      // this.m.entities.push(pushpin);
      // Store metadata with the pushpin
      pushpin.metadata = {
        mapLabel: element
      };
      // Add click handler for the pin and center the map to clicked pin
      Microsoft.Maps.Events.addHandler(pushpin, 'click', <any>((args) => {
        this.pinClick(args.target);
        this.m.setView({
          center: new Microsoft.Maps.Location(args.target.metadata.mapLabel.iPoint.latitude,
            args.target.metadata.mapLabel.iPoint.longitude)
        });
      }));
    });
    // set map view to center multiple pins when pins are two close set the map view to center
    // center to mulitple pins is diabled when map is panned and zoomed by the user
    if ((rect.height > 0.05 || rect.width > 0.05) && !this.isBoundDisable) {
      this.m.setView({ bounds: rect, padding: 80 });
    } else if (!!searchLoc) {
      this.m.setView({
        center: new Microsoft.Maps.Location(searchLoc?.latitude, searchLoc?.longitude),
      });

    }
  }

  /** create cluster of pushpins */
  createCluster() {
    Microsoft.Maps.loadModule('Microsoft.Maps.Clustering', () => {
      // Create a clustering layer
      this.clusterLayer = new Microsoft.Maps.ClusterLayer(this.pushpins, {
        clusteredPinCallback: (cluster) => this.createCustomClusterPushpins(cluster),
      });
      this.m.layers.insert(this.clusterLayer);
    });

  }
  /** callback fn to add click and center the map to clicked cluster */
  createCustomClusterPushpins(cluster: Microsoft.Maps.ClusterPushpin) {

    if(cluster.containedPushpins[0].metadata.mapLabel.type === 'OurLocation' || cluster.containedPushpins[0].metadata.mapLabel.type === 'Gps') {
       cluster.setOptions({ color: '#ff1700' });
    }

    // Add handler for the cluster click event.
    Microsoft.Maps.Events.addHandler(cluster, 'click', () => {
      logger.log('cluster', cluster);
      this.clusterPins = cluster.containedPushpins;
      logger.log(this.clusterPins);
      this.clusterClick(cluster);
      this.m.setView({
        center: new Microsoft.Maps.Location(cluster.getLocation().latitude, cluster.getLocation().longitude),
      });
    });
  }
  /** event listener for the cluster click */
  clusterClick(cluster: Microsoft.Maps.ClusterPushpin) {
    logger.log('this.clusterClick', cluster);
    const val: InfoBoxData<MapLabel[], MapLabel[]> = {
      data: cluster.containedPushpins.map(r => r.metadata.mapLabel),
      callbackFn: (d) => this.showClusterInfoBox(d)
    };
    this.eventService.emit<InfoBoxData<MapLabel[], MapLabel[]>>(
      this.eventService.clusterClick, val);
    this.showClusterInfoBox(val.data);
  }
  /** event listener for the pin click */
  pinClick(pushpin: Microsoft.Maps.Pushpin) {
    logger.log('this.pinClick', pushpin);
    const val = [];
    this.showPinInfoBox(pushpin.metadata.mapLabel);
    val.push(pushpin.metadata.mapLabel);
    this.eventService.emit<MapLabel[]>(this.eventService.clusterClick, val);
  }

  showClusterInfoBox(d: MapLabel[]) {
    logger.log('show infobox was received called from the bing-map.');
    logger.log(d);
    let desc = '';
    desc = `${d[0].title}<br>${d[1].title}<br>`;
    if (d.length > 2) {
      desc = desc + `<br>& ${d.length - 2} more`;
    }
    desc = desc + `<div style="border-top: 1px; border-top-style: solid; border-color: #888; margin-left:-10px; margin-right:-10px; padding: 10px 0 0 10px"> 
    <div><a id="ClusterAction">Details</a><div>
    </div>`;

    this.infobox.setOptions({
      visible: true,
      location: new Microsoft.Maps.Location(d[0].iPoint.latitude, d[0].iPoint.longitude),
      // title: `${d[0].title}<br>${d[1].title}<br>& ${d.length - 1} more`,
      description: desc,
      maxHeight: 200,
      maxWidth: 300,
      zIndex: 10000,
      // actions: [
      //   {
      //     label: 'Details', eventHandler: () => {
      //       this.eventService.emit<MapLabel[]>(this.eventService.mapClusterAction, d);

      //     }
      //   }
      // ]
    });
    Microsoft.Maps.Events.addHandler(this.infobox, 'click', (e) => {
      console.log('[map-bing] ClusterAction event', e);
      const a = d[0].actions?.find(f => f === 'ClusterAction');
      const isDetailsAction = (e.originalEvent.target as any).id === a;
      if (isDetailsAction) {

        // apply some custom actions..
        console.log('[map-bing] ClusterAction button clicked');
        this.eventService.emit(this.eventService.mapClusterAction, d);

      }
    });
    this.infobox.setMap(this.m);

    this.m.setView({
      center: new Microsoft.Maps.Location(d[0].iPoint.latitude,
        d[0].iPoint.longitude)
    });
    Microsoft.Maps.Events.addHandler(this.infobox, 'click', (e) => {
      console.log('[map-bing] Close event', e);
      const isCloseAction = (e.originalEvent.target as any).className === 'infobox-close-img';
      if (isCloseAction) {

        // apply some custom actions..
        console.log('[map-bing] Close button clicked');
        this.eventService.emit(this.eventService.clearFilter, null);
      }
    });
  }
  showPinInfoBox(d: MapLabel) {
    logger.log('show infobox was received called from the bing-map.');
    logger.log(d);
    this.infobox.setOptions({
      visible: true,
      location: new Microsoft.Maps.Location(d.iPoint.latitude, d.iPoint.longitude),
      title: d.title,
      description: d.desc,
      maxHeight: 200,
      maxWidth: 300,
      //   actions: !!d.clickLabel && d.clickLabel.length > 0 ? 
      //   [
      //     {
      //       label: d.clickLabel, eventHandler: () => {
      //         this.eventService.emit<{ id: string, clickLabel: MapLabelClick }>(this.eventService.mapLabelActionById, {
      //           id: `${d.id}`, clickLabel: d.clickLabel
      //         });

      //       }
      //     },
      //     {
      //       label: 'Details', eventHandler: () => {
      //         this.eventService.emit<MapLabel[]>(this.eventService.mapClusterAction, [d]);

      //       }
      //     },
      //   ] : null
    });
    Microsoft.Maps.Events.addHandler(this.infobox, 'click', (e) => {
      console.log('[map-bing] Close event', e);
      const isCloseAction = (e.originalEvent.target as any).className === 'infobox-close-img';
      if (isCloseAction) {

        // apply some custom actions..
        console.log('[map-bing] Close button clicked');
        this.eventService.emit(this.eventService.clearFilter, null);

      }
    });
    Microsoft.Maps.Events.addHandler(this.infobox, 'click', (e) => {
      console.log('[map-bing] ClusterAction event', e);
      const a = d.actions?.find(f => f === 'ClusterAction');
      const isDetailsAction = (e.originalEvent.target as any).id === a;
      if (isDetailsAction) {

        // apply some custom actions..
        console.log('[map-bing] ClusterAction button clicked');
        this.eventService.emit(this.eventService.mapClusterAction, [d]);

      }
    });
    Microsoft.Maps.Events.addHandler(this.infobox, 'click', (e) => {
      console.log('[map-bing] IdAction event', e);
      const a = d.actions?.find(f => f === 'IdAction');
      const isIdAction = (e.originalEvent.target as any).id === a;
      if (isIdAction) {

        // apply some custom actions..
        console.log('[map-bing] IdAction button clicked');
        this.eventService.emit<{ id: string, clickLabel: MapLabelClick }>(this.eventService.mapLabelActionById, { id: `${d.id}`, clickLabel: 'Rent' });

      }
    });

    this.infobox.setMap(this.m);
  }
  getLabelBounds(d: MapLabel[]) {
    let rect: Microsoft.Maps.LocationRect;
    try {
      const locs = [];
      for (let index = 0; index < d.length; index++) {
        const loc = new Microsoft.Maps.Location(d[index].iPoint.latitude, d[index].iPoint.longitude);
        locs.push(loc);
      }
      if(locs.length > 0) {
        rect = Microsoft.Maps.LocationRect.fromLocations(locs);
      }
    } catch (error) {
      logger.warn('Error Fetching map location!', error);
    }
    return rect;
  }
  updateWithSnapToRoad(tripLocs: { [key: string]: { iPoint: IPoint, locTimeStamp: Date } }) {
    logger.log('updateWithSnapToRoad called');
    const locs: Microsoft.Maps.Location[] = [];
    for (const key in tripLocs) {
      if (tripLocs.hasOwnProperty(key)) {
        const ele = tripLocs[key];
        const c = new Microsoft.Maps.Location(ele.iPoint.latitude, ele.iPoint.longitude);
        locs.push(c);
      }
    }

    const polyline = new Microsoft.Maps.Polyline(locs, null);
    this.m.entities.push(polyline);
  }
  async getRoute(d: MapLabel[]): Promise<RouteResponse> {
    return new Promise<RouteResponse>((resolve, reject) => {
      const locs = [];
      logger.log('map labels at directions', d);
      Microsoft.Maps.loadModule('Microsoft.Maps.Directions', () => {
        const directionsManager = new Microsoft.Maps.Directions.DirectionsManager(this.m);

        // Set Route Mode to driving
        (directionsManager).setRequestOptions({
          routeMode: (Microsoft.Maps.Directions.RouteMode).truck,
          routeOptimization: Microsoft.Maps.Directions.RouteOptimization.timeWithTraffic,
          time: d[0].startTime,
          // timeType: Microsoft.Maps.Directions.TimeType.departure,
          // routeMode: (<any>Microsoft.Maps.Directions.RouteMode).truck,
          vehicleSpec: {
            dimensionUnit: 'ft',
            weightUnit: 'lb',
            vehicleHeight: 5,
            vehicleWidth: 3.5,
            vehicleLength: 23,
            vehicleWeight: 30000,
            vehicleAxles: 3,
            vehicleTrailers: 1,
            vehicleSemi: true,
            // vehicleMaxGradient: true,
            // vehicleMaxGradient: 10,
            vehicleMinTurnRadius: 15,
            vehicleAvoidCrossWind: true,
            vehicleAvoidGroundingRisk: true,
            // vehicleHazardousMaterials: 'F',
            // vehicleHazardousPermits: 'F'
          },
        });
        // find if any destination address is not in Canada
        const c = d.findIndex(f => f.country !== 'CA');
        // if Route and any destination outside Canada then set route avoidance to none else set route to minimize toll
        const isToll = c > -1 ? [Microsoft.Maps.Directions.RouteAvoidance.none] : [Microsoft.Maps.Directions.RouteAvoidance.minimizeToll];
        (directionsManager).setRequestOptions({
          // routeAvoidance: [Microsoft.Maps.Directions.RouteAvoidance.minimizeToll],
          routeAvoidance: isToll,
          routeDraggable: false,
          distanceUnit: Microsoft.Maps.Directions.DistanceUnit.miles,
          // routeOptimization: Microsoft.Maps.Directions.RouteOptimization.timeWithTraffic,
          // avoidTraffic: false
        });
        for (let index = 0; index < d.length; index++) {
          const loc = new Microsoft.Maps.Location(d[index].iPoint.latitude, d[index].iPoint.longitude);
          locs.push(loc);
          this.rectangle = Microsoft.Maps.LocationRect.fromLocations(locs);

          const waypoint1 = new Microsoft.Maps.Directions.Waypoint({
            address: d[index].addressFormated,
            location: new Microsoft.Maps.Location(d[index].iPoint.latitude, d[index].iPoint.longitude),

          });
          logger.log('waypoint1', waypoint1);

          directionsManager.addWaypoint(waypoint1);
        }
        if (d.length === 2) {
          directionsManager.setRenderOptions({
            firstWaypointPushpinOptions: { text: `${d[0].title}` },
            lastWaypointPushpinOptions: { text: `${d[1].title}` }
          });
        }
        directionsManager.setRenderOptions({ displayRouteSelector: false });
        Microsoft.Maps.Events.addHandler(directionsManager, 'directionsError', (directionsError) => {
          logger.error('route failed', (<any>directionsError).message, directionsError);
          reject((<any>directionsError).message);
          // this.eventService
          // .emit<{ errorMsg: string }>(
          //   this.eventService.errorOccured, { errorMsg: (<any>directionsError).message });
        });

        Microsoft.Maps.Events.addHandler(
          directionsManager,
          'directionsUpdated',
          (directionsEvent) => {
            logger.log('directionsEvent', directionsEvent);

            const _routeResult = (<any>directionsManager)._routeResult;
            const route = directionsManager.getRouteResult();
            const routeOptions = directionsManager.getRequestOptions();
            if (d.length === 2) {
              route.forEach(e => e.routeLegs.forEach((g) => g.summary.timeWithTraffic = _routeResult.routeSummary[0].timeWithTraffic));
              logger.log('route with traffic time', route);
            }
            this.layers = this.m.layers;
            logger.log('routelayers', this.layers);
            const layerIndex = this.layers.length - 1;
            const layerId = this.layers[layerIndex]._id;
            resolve({
              route: route, routeOptions: routeOptions, toIndex: d[1].index,
              layer: { layerIndex: layerIndex, layerId: layerId },
              mapLabels: d
            });
            // directionsManager.calculateDirections();
            // this.m.setView({ bounds: this.rectangle, padding: 80 });
          });
        directionsManager.calculateDirections();

      });
    });
  }
  getRouteUsingRest(http: HttpClient, d: MapLabel[]): Promise<IRouteRestResponse> {
    const routeData = this.routeData(d);
    const headers = new HttpHeaders();
    headers.set('Content-Length', '564');
    headers.set('Content-Type', 'application/json');
    headers.set('Access-Control-Allow-Origin', '*');
    return http.post<any>(
      bingConfig.postTruckRoute,
      routeData,
      { headers: headers })
      .pipe(
        map(x => x.resourceSets[0]),
        take(1))
      .toPromise();
  }
  private routeData(d: MapLabel[]) {

    const c = d.findIndex(f => f.country === 'CA');
    const u = d.findIndex(f => f.country === 'US');
    const b = d.findIndex(f => f.country === 'border');

    const optimize = 'timeWithTraffic';

    let avoid = c > -1 && u > -1 ? null : 'borderCrossing';
    if (c > -1 && u === -1) {
      avoid = 'borderCrossing, minimizeTolls';
    } else if (c === -1 && u > -1) {
      avoid = 'borderCrossing';
    } else if (c > -1 && u > -1) {
      avoid = null;
    }
    if (b > -1) {
      avoid = null;
    }
    const routeAttributes = 'routePath';
    const dateTime: Date = d[0].startTime;
    const distanceUnit: 'mile' | 'kilometer' = 'mile';
    const vehicleSpec: Microsoft.Maps.Directions.IVehicleSpec = {
      dimensionUnit: 'ft',
      weightUnit: 'lb',
      vehicleHeight: 5,
      vehicleWidth: 3.5,
      vehicleLength: 23,
      vehicleWeight: 30000,
      vehicleAxles: 3,
      vehicleTrailers: 1,
      vehicleSemi: true,
      // vehicleMaxGradient: true,
      // vehicleMaxGradient: 10,
      vehicleMinTurnRadius: 15,
      vehicleAvoidCrossWind: true,
      vehicleAvoidGroundingRisk: true,
      // vehicleHazardousMaterials: 'F',
      // vehicleHazardousPermits: 'F'
    };
    const waypoints = this.getWayPoints(d);
    return { waypoints, avoid, dateTime, distanceUnit, optimize, routeAttributes, vehicleSpec };
  }
  private getWayPoints(d: MapLabel[]): Microsoft.Maps.Directions.IWaypointOptions[] {
    const wayPoints: Microsoft.Maps.Directions.IWaypointOptions[] = [];
    for (let i = 0; i < d.length; i++) {
      const waypoint = {
        // address: d[i].addressFormated,
        latitude: d[i].iPoint.latitude,
        longitude: d[i].iPoint.longitude,
        isViaPoint: i === 0 || i === d.length - 1 ? false : true

      };
      wayPoints.push(waypoint);
    }
    return wayPoints;
  }
  private addPolyLine(routePath: IRoutePathRest, d: MapLabel[]) {
    if (this.m == null) {
      this.m = new Microsoft.Maps.Map('#' + this.mapId, <any>{});
    }
    // const route = response.resourceSets[0].resources[0];
    const path = routePath.line.coordinates;
    // this.rectangle = this.getLabelBounds(d);
    // Generate an array of locations for the route path.
    const locs = [];
    for (let i = 0, len = path.length; i < len; i++) {
      locs.push(new Microsoft.Maps.Location(path[i][0], path[i][1]));
    }
    const line = new Microsoft.Maps.Polyline(locs, { strokeColor: '#3385ff', strokeThickness: 5 });
    const layer = new Microsoft.Maps.Layer();
    layer.add(line);
    d.forEach((e) => {
      const pinLoc = new Microsoft.Maps.Location(e.iPoint.latitude, e.iPoint.longitude);
      const pin = new Microsoft.Maps.Pushpin(pinLoc, { text: e.title, anchor: new Microsoft.Maps.Point(2, 42), color: '#344955' });
      layer.add(pin);
    });
    // this.m.entities.push(line);
    logger.log('map obj before adding layer', this.m);
    this.m.layers.insert(layer);
    logger.log('map obj after  adding layer', this.m);
    this.layers = this.m.layers;
    return layer;
    // this.m.setView({ bounds: this.rectangle, padding: 80 });
  }

  private addGpsLogs(gpsLog: LocationData[]) {
    if (this.m == null) {
      this.m = new Microsoft.Maps.Map('#' + this.mapId, <any>{});

    }
    logger.log('[map-bing] gpsLog', gpsLog);

    const locs = [];
    const layer = new Microsoft.Maps.Layer();
    for (let i = 0, len = gpsLog.length; i < len; i++) {
      const l = gpsLog[i];
      locs.push(new Microsoft.Maps.Location(l.latitude, l.longitude));
      const pinLoc = new Microsoft.Maps.Location(l.latitude, l.longitude);
      const pin = new Microsoft.Maps.Pushpin(pinLoc, { text: `${i}`, anchor: new Microsoft.Maps.Point(2, 42), color: '#f47100' });
      layer.add(pin);
    }
    const line = new Microsoft.Maps.Polyline(locs, { strokeColor: '#f47100', strokeThickness: 3 });
    layer.add(line);
    this.m.layers.insert(layer);
  }

  /***
   * @author KS
   * @purpose Get Route itinerary
   */
  loadRouteDetails(payload) {
    return new Promise<RouteResponse>((resolve, reject) => {
      const w1 = payload.mapLabels[0];
      const w2 = payload.mapLabels[1];
      var map = new Microsoft.Maps.Map(document.getElementById(payload.mapId), { zoom: 99 });
      Microsoft.Maps.loadModule('Microsoft.Maps.Directions', () => {
        const directionsManager = new Microsoft.Maps.Directions.DirectionsManager(map);
        directionsManager.setRequestOptions({ routeMode: Microsoft.Maps.Directions.RouteMode.driving });
        var waypoint1 = new Microsoft.Maps.Directions.Waypoint({ address: w1.addressFormated, location: new Microsoft.Maps.Location(w1.iPoint.latitude, w1.iPoint.longitude) });
        var waypoint2 = new Microsoft.Maps.Directions.Waypoint({ address: w2.addressFormated, location: new Microsoft.Maps.Location(w2.iPoint.latitude, w2.iPoint.longitude) });
        directionsManager.addWaypoint(waypoint1);
        directionsManager.addWaypoint(waypoint2);
        directionsManager.setRenderOptions({ itineraryContainer: document.getElementById(payload.itineraryContainerId) });
        directionsManager.calculateDirections();
      });
    });

  }
}










// GET REQUEST for get Distance
    // let url = this.getDistanceApiUrl(provider) + '&travelMode=driving&origins=';

    // origins.forEach(x => url += `${x.latitude},${x.longitude};`);
    // url = url.substring(0, url.length - 1) + `&destinations=`;

    // destinations.forEach(x => url += `${x.latitude},${x.longitude};`);
    // url = url.substring(0, url.length - 1);

    // return this.http.get<Distance[]>(url).pipe(take(1)).toPromise();

    // 'startTime': '2017-06-15T13:00:00-07:00',
    //   'endTime': '2017-06-15T17:00:00-07:00',
    //   'resolution': 2
    // Prepare the post data

