import { ILogger, IAppLoggers } from './ilog';

/**
 * common interface that can be used for console and firebase.
 */
export interface ILoggerFn {
  info: (msg: any, ...args: any[]) => void;

  warn: (msg: any, ...args: any[]) => void;

  error: (msg: any, ...args: any[]) => void;

  log: (msg: any, ...args: any[]) => void;
}

const buildLog = (fn: (msg: any, ...args: any[]) => void, name: string, msg: any, ...args: any[]): void => {
  let m: string = name;
  if (m.trim() === 'default' || m.trim() === '[default]') {
    m = '';
  }
  if (m.length > 0 && m[0] !== '[') {
    m = `[${m}] `;
  }

  if (typeof msg === 'string') {
    msg = m + msg.toString();
  } else if (typeof (msg as any)?.message === 'string') {
    (msg as any).message = `${m}${(msg as any).message}`;
    // args.push(msg);
  }

  args = args.filter(x => x != null);

  if (args.length === 0) {
    fn(msg);
  } else {
    fn(msg, ...args);
  }

  // if (m.length === 0) {
  //   fn(args);
  // } else if (args.length === 0) {
  //   fn(m);
  // } else {
  //   fn(m, ...args);
  // }
};

/** User for firebase/console/google cloud */
export const buildLogger = (name: string, funC: ILoggerFn, container: { [key: string]: ILogger }): ILogger => {

  const l: ILogger = {
    info: (msg: any, ...args: any[]) => buildLog(funC.info, name, msg, ...args),

    warn: (msg: any, ...args: any[]) => buildLog(funC.warn, name, msg, ...args),

    error: (msg: any, ...args: any[]) => buildLog(funC.error, name, msg, ...args),

    log: (msg: any, ...args: any[]) => buildLog(funC.log, name, msg, ...args),
  };

  // save in the container for eash access.
  if (name.length > 0) {
    container[name] = l;
  }
  return l;
};


/** Should be used for cosole and firebase loggers.  */
export const buildLoggerApp = (fnDefault: ILoggerFn): IAppLoggers => {

  const container: { [key: string]: ILogger } = {};

  const lApp: IAppLoggers = {
    default: buildLogger('', fnDefault, container),
    // gpsLogger: buildLogger('[gps] ', fnGps, container),
    getLogger: (name: string) => !!lApp.container[name] ? lApp.container[name] : buildLogger(name, fnDefault, container),
    container: {}
  };

  lApp.container = container;

  return lApp;
};

export const appLoggersConsole: IAppLoggers = buildLoggerApp(console);
