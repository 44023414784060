import { Router } from '@angular/router';
import { CloudMessageCdvaService } from './cloud-message-cdva.service';
import { CloudMessageCapService } from './cloud-message-cap.service';
import { promiseWraper } from '../../models/utility/helper';
import { Injectable } from '@angular/core';
import { IcloudMessageService } from './icloud-message.service';
import { CloudMessageCoreService } from './cloud-message-core.service';
import { Store } from '@ngxs/store';
import { IAuthCoreService } from '../auth/iauth-core.service';
import { FirebaseX } from '@awesome-cordova-plugins/firebase-x/ngx';
import { FirestoreService } from '../firestore.service';
import { LocalStorage } from '../local-storage/local-storage.service';
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { Platform } from '@ionic/angular';
import { IDeviceIdComponents } from '@trentm/fcm/fcm-token-data';
import { IdeviceIdService } from '../device/idevice-id.service';
import { logger } from '@trentm/log/logger';

/** Do not inject this service in any component. Use @class IcloudMessageService instead.
 * Or better yet, use the @class SingletonService's public property. This this class is singlton also but
 * it is not imported at the app level to reduce the laods. Singlton class has the only copy in it.
 */
@Injectable()
export class CloudMessageMobileService implements IcloudMessageService {

  service: IcloudMessageService;

  constructor(
    // private coreAuth: AuthCoreService,
    private device: Device,
    store: Store,
    auth: IAuthCoreService,
    private firebaseX: FirebaseX,
    afs: FirestoreService,
    localStorage: LocalStorage,
    private platform: Platform,
    deviceIdService: IdeviceIdService,
    router: Router
  ) {
    if (this.platform.is('capacitor')) {
      logger.log('[fcm] capacitor constructor called');
      this.service = new CloudMessageCapService(store, auth, afs, localStorage, deviceIdService, router);
    } else { // it is cordova
      logger.log('[fcm] cordova constructor called');
      this.service = new CloudMessageCdvaService(
        store, auth, firebaseX, afs, localStorage, deviceIdService, router);
    }
  }

  requestNotificationPermission() {
    return this.service.requestNotificationPermission();
  }

  updateFcmToken() {
    return this.service.updateFcmToken();
  }


  refreshToken() {
    return this.service.refreshToken();
  }

}
