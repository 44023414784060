import { ICameraPicData } from './icamera-data';
import { CameraCdvaService } from './camera-cdva.service';
import { CameraCapService } from './camera-cap.service';
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { DomSanitizer } from '@angular/platform-browser';
import { Camera, CameraOptions } from '@awesome-cordova-plugins/camera/ngx';

@Injectable()
export class CameraMobileService {

  cameraService: CameraCapService | CameraCdvaService;

  constructor(camera: Camera, private platform: Platform, private sanitizer: DomSanitizer) {
    if (this.platform.is('capacitor')) {
      this.cameraService = new CameraCapService(sanitizer);
    } else {
      this.cameraService = new CameraCdvaService(camera, platform);
    }
  }

  get isCameraAvailable() { return true; }

  get isCapacitor() { return this.cameraService.isCapacitor; }
  
  get isCdva() { return this.cameraService.isCdva; }

  async takePicture(o: CameraOptions): Promise<ICameraPicData> {
    return this.cameraService.takePicture(o as any);
  }
}
