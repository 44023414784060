import { validateSync, ValidatorOptions, IsEmail, Length, IsPhoneNumber } from 'class-validator';
import { toValidationError } from '../utility';
import { IValidationMsg } from '../error-handling/validation-info';
import { instanceToInstance } from 'class-transformer';

/** Registration model used during custum registration of email and password. */
export class UserEmail {

  @IsEmail({}, { message: 'Current email is required' })
  currEmail: string;

  @IsEmail({}, { message: 'New email is invalid' })
  newEmail: string;


  // @IsPhoneNumber('ZZ', { message: 'A valid phone no with area code is required' })
  // phoneNumber: string | null;

  /**
   * validates email based on regex function
   * @param email email
   */
  static regexEmail(email: string) {
    // tslint:disable-next-line:max-line-length
    const r = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const regex = RegExp(r);
    return regex.test(email);
  }
  public clone(): UserEmail {
    const u = instanceToInstance<UserEmail>(this);
    // u.sanitize();
    return u;
  }

  validateSync(options: ValidatorOptions): IValidationMsg {
    const r = validateSync(this, options);
    const m = toValidationError(r);
    if (this.currEmail === this.newEmail) {
      m['newEmail'] = ['New email is not unique'];
    }
    return m;
  }

}
