<!-- carousel in home screen -->
<ng-container *ngIf="!editMode">
  <trent-carousel2 *ngIf="imgList && imgList.length" [list]="imgList" [heightImg]="47" [intervalCall]="true">
    <ng-template let-item #home>
      <img class="img" [src]="item.inputImage.url" [alt]="item.altImage" class="carousel-img" />
    </ng-template>
  </trent-carousel2>
  <div class="default-img" [style.height.vw]="47" *ngIf="!imgList?.length">
    <img src="../../../assets/PTS Icons/front.svg" alt="image" />
  </div>
</ng-container>

<!--add or delete  carousel image in home screen -->

<div [@slideInOut]="editMode" [ngClass]="{'card-div card-f': editMode}">
  <div [ngClass]="isHandset ? 'w-95' :'w-30'">
    <div class="header">
      <h2 style="text-align: center;">Carousel Image add/remove</h2>
    </div>
  
    <form #form="ngForm">
      <div class="mat-form-field-wrapper p-b-0">
        <mat-label><b>Upload Image*</b></mat-label>
      </div>  
      <div class="mat-form-field-wrapper p-b-0" *ngIf="!!em">
        <div class="file-upload" align="end">
          <trent-file-upload #imageUploaderCtrl #inputImage="ngModel" [(ngModel)]="em.inputImage" name="inputImage"
            [isEditMode]="true" [defaultFName]="'image.png'" [refFName]="m.inputImage.name" [secureUrl]="imageDownloader">
          </trent-file-upload>
          <span matSuffix>
            <button class="file-suffix-btn" mat-icon-button matSuffix color="primary" type="button" [disabled]="!true"
              (click)="resetFileInfo(em.inputImage)">
              <mat-icon>remove_circle</mat-icon>
            </button>
          </span>
        </div>
      </div>
      <div>
        <mat-form-field class="full-width">
          <mat-label>Alt name for Image</mat-label>
          <input matInput type="text" name="altImage" [(ngModel)]="em.altImage" #altImage="ngModel">
          <mat-hint *ngIf="!showError(altImage)" align="end">Required</mat-hint>
          <mat-error align='end' *ngIf="showError(altImage)">{{altImage.errors.msg}}</mat-error>
        </mat-form-field>
      </div>
      <button (click)="save()" class="btn-dark btn-s">save</button>
    </form>
  </div>
  <div class="cards-carousel" [ngClass]="isHandset ? 'w-95' :'w-68'">
    <ng-container *ngFor="let item of imgList">
      <div class="card" style="position:relative">
        <button class="btn-delete" (click)="delete(item.inputImage.path)">
          <mat-icon>delete</mat-icon>
        </button>
        <img class="img" [src]="item.inputImage.url" [alt]="item.altImage"/>
      </div>
    </ng-container>
  </div>
  
</div>
