<ng-container *ngFor="let m of messages; let idx = index">
  <div [class]="'alert' + ' ' + m.msg.msgCss "  style="position: sticky;">
  <span *ngIf="m.showClose" class="closebtn" (click)="hideAlert(m.id)">&times;</span> 
    {{ m.msg.description}}
    <ng-container *ngIf="!!m.moreLinkUrl">
      <a [routerLink]="[m.moreLinkUrl.split('?')[0]]"
         [queryParams] = "getQueryParam(m.moreLinkUrl)"
       >
        <mat-icon>call_made</mat-icon>        
      </a>
    </ng-container>
  </div>  
</ng-container>

