import { isNumber } from 'lodash';
import { ProductType } from '../product/product-type';
import { IKeyVal, getObjKey, Paging, getDirection } from '../observable-util/paging';
import { DeepCopy } from '../utility/deep-copy';
import { RentalProductBase } from './rental-product-base';
import { DbStatus } from '../base';
import { RentOptionStatus } from './rent-option-status';
import { Address } from '../address/address';
import { IPoint } from '../product-search/interfaces';
import { CollectionReference, endAt, limit, orderBy, OrderByDirection, query, Query, QueryConstraint, startAfter, startAt, where } from 'firebase/firestore';
import { TrailerMakeType } from '../product/trailer';
import { TruckMakeType } from '../product/truck';
import { isAfter } from 'date-fns';
import { RentalTruck } from './rental-truck';
import { TruckProdSummary } from '../bid/bid-truck';
import { distanceBetween, geohashForLocation, geohashQueryBounds } from 'geofire-common';
import { logger } from '../log/logger';


export interface RentOptionParam {
  /** Type of products to be searched */
  type?: ProductType;
  /** Product Id */
  pid?: string | number;
  /** Parent Companies to be searched */
  cids?: string[];
  orderBy?: string;
  startAddress?: Address; // string;
  center?: IPoint;
  // startLat?: number;

  // startLong?: number;

  radius?: number;

  /** Geo search based upon the given center and the radius options. Child query @see getRentOptionSearchOptOrChildren will use the center and radius data to define the bounds that will run parallel queries. */
  geoSearchOpt?: {
    orderByCol: string;
    orderByDir: OrderByDirection;
    startAt: string;
    endAt: string;
  };

  // incRadius?: number;

  // searchStartDate?: any;
  // searchEndDate?: any;

  // stateProv?: string;
  // country?: string;

  // isDateFilter?: boolean;
  // isGeoServerSearchReq?: boolean;
  // isClientGeoFilterReq?: boolean;
  dbStatus?: DbStatus;
  // hasPicture?: boolean;
  rentOptionStatus?: RentOptionStatus;
  isReefer?: boolean | '*';
  nAxle?: number;
  isDayCab?: boolean | '*';
  make?: TruckMakeType | TrailerMakeType | '*';
  // only required for searching aggregate list
  rid?: string;
  vCid?: string;
  isActive?: boolean;
  isExclusive?: boolean;
  availEndDate?: Date;
  // id?: number | string;  // only for client filter

  rIds?: any; //PT - to add all the ids of units so that we can filter it accordingly


}

export const rentOptionSearchOrderBy: IKeyVal[] = [
  { key: 'Name', value: 'unitName' },
  { key: 'Available to Date', value: `availEndDate desc` },
  { key: 'Make', value: 'make' },
  { key: 'Model year', value: 'modelYear desc' },
];

export const rentOptionParamInit = (): RentOptionParam => {
  return {
    type: ProductType.trailer,
    pid: null,
    cids: [],
    orderBy: 'startLoc',
    startAddress: new Address(),

    // startLat: 43.7315,
    // startLong: -79.7624,
    radius: 20,
    // incRadius: 0,
    // stateProv: 'ON',
    // country: 'CA',
    // searchStartDate: null,
    // searchEndDate: null,
    // isDateFilter: false,
    // isGeoServerSearchReq: true,
    // isClientGeoFilterReq: true,
    dbStatus: DbStatus.Released,
    rentOptionStatus: RentOptionStatus.open

    // searchEndDate: new Date()
  };
};
// export const myRentalParamInit = (): RentOptionParam => {
//   return {
//     type: <any>'*',
//     pid: null,
//     cids: [],
//     searchStartDate: null,
//     searchEndDate: null,
//     dbStatus: null,
//     rentOptionStatus: RentOptionStatus.open
//   };
// };
export const rentSearchOrderBy: IKeyVal[] = [
  // { key: 'Name', value: 'unitName' },
  // { key: 'bidStatus', value: `2` }, // waitingForCustomer = 2, // Counter Offer waiting for Customer Acceptance;
  { key: 'createdAt to Date', value: `createdAt desc` },
  // { key: 'Make', value: 'make' },
  // { key: 'Model year', value: 'modelYear desc' },
];
/** Get the OR condition options. if there are more then one company id's are provided, or geo location center/radius information further require multiple search queries.
 *  each cid makes up a unique OR condition query. */
export const getRentOptionSearchOptOrChildren = (o: RentOptionParam): { [key: string]: RentOptionParam } => {

  const orig: Array<RentOptionParam> = [];
  if (!!o.center) {
    // TODO:  radius here is hard coded, it should be attached to UI.
    if(!isNumber(o.radius)) {
      o.radius = 1000;
    }
    const bounds = geohashQueryBounds([o.center.latitude, o.center.longitude], o.radius * 1000);
    const { col, dir } = getDirection(rentSearchOrderBy, 'startAddress.geoLoc.geohash');
    for (const b of bounds) {
      const x = DeepCopy.copy(o);
      x.geoSearchOpt = {
        orderByCol: col,
        orderByDir: dir,
        startAt: b[0],
        endAt: b[1]
      };
      orig.push(x);
    }
  }
  if (orig.length === 0) {
    orig.push(DeepCopy.copy(o));
  }

  const r: { [key: string]: RentOptionParam } = {};
  if (!!o.cids && o.cids.length > 1) {
    o.cids.forEach((cid) => {
      for (const tempOrig of orig) {
        const c = DeepCopy.copy(tempOrig);
        c.cids = [cid];
        r[getObjKey(c)] = c;
      }
    });
    return r; // Return here as both cid and geo multiple criterion have been completed. 
  }

  // if multiple cid conditions did not exists, then build the r dic based of multiple bounds of geo location ( if specified)
  if (orig.length > 1) {
    for (const i of orig) {
      r[getObjKey(i)] = i;
    }
    return r;
  }


  return undefined;
};

/** Server filtering of firebase query building of query  */
export const rentOptionSearchServerQuery = (ref: CollectionReference, o: RentOptionParam, p: Paging) => {
  const cons: QueryConstraint[] = [];
  // const dbStatus = DbStatus;

  if (!!o.dbStatus) {
    cons.push(where('dbStatus', '==', o.dbStatus));
  }
  if (typeof o.isActive === 'boolean') {
    cons.push(where('isActive', '==', o.isActive));
  }
  if (!!o.type && o.type !== <any>'*') {
    cons.push(where('productSummary.productType', '==', +o.type));
  }
  if (o.cids?.length > 0) {
    console.log('o.cids[0]', o.cids[0]);
    cons.push(where('vendorCompSummary.cid', '==', o.cids[0]));
  }
  if (o.rIds?.length > 0) {
    console.log('o.ids[0]', o.rIds[0]);
    cons.push(where('id', '==', o.rIds));
  }
  if (!!o.pid && o.pid !== 'x') {
    cons.push(where('productSummary.pid', '==', o.pid));
  }
  if (!!o.vCid) {
    cons.push(where('vendorCompSummary.cid', '==', o.vCid));
  }
  if (!!o.availEndDate) {
    cons.push(where('availEndDate', '>', o.availEndDate));
  }
  if (typeof o.isDayCab === 'boolean') {
    cons.push(where('productSummary.isDayCab', '==', o.isDayCab));
  }
  if (!!o.make && o.make !== '*') {
    cons.push(where('productSummary.make', '==', o.make));
  }

  if (o.orderBy == 'availEndDate') {
    const { col, dir } = getDirection(rentSearchOrderBy, (!!o.orderBy) ? o.orderBy : 'availEndDate desc');
    cons.push(orderBy(col, dir));
    if (!!p.lastDoc) {
      console.log('last doc: ', p.lastDoc[col]);
      cons.push(startAfter(p.lastDoc[col]));
    }
  }
  if (typeof o.isExclusive === 'boolean') {
    cons.push(where('isExclusive', '==', o.isExclusive));
  }


  // // else {
  // //   cons.push(where('isExclusive', '==', false));
  // // }
  // // if (!!o.hasPicture) {
  // //   cons.push(where('hasPicture', '==', o.hasPicture);
  // // }
  // if (o.rentOptionStatus != null && <any>o.rentOptionStatus !== '*') {
  //   cons.push(where('rentOptionStatus', '==', o.rentOptionStatus));
  // }

  if (!!o.geoSearchOpt) {
    cons.push(orderBy(o.geoSearchOpt.orderByCol, o.geoSearchOpt.orderByDir));
    cons.push(startAt(o.geoSearchOpt.startAt));
    cons.push(endAt(o.geoSearchOpt.endAt));

    if (!!p.lastDoc) {
      console.log('last doc: ', p.lastDoc[o.geoSearchOpt.orderByCol]);
      cons.push(startAfter(p.lastDoc[o.geoSearchOpt.orderByCol]));
    }
    // OLD approach
    // const bounds = geohashQueryBounds([o.center.latitude, o.center.longitude], 5000 * 1000);
    // const { col, dir } = getDirection(rentSearchOrderBy, 'startAddress.geoLoc.geohash');
    // for (const b of bounds) {
    //   cons.push(orderBy(col, dir));
    //   cons.push(startAt(b[0]));
    //   cons.push(endAt(b[1]));
    //   break;
    // }

  }

  // o.orderBy = undefined;

  cons.push(limit(p.size));
  try {
    const q = query(ref, ...cons);
    logger.log('[rent-option query]', q);
    return q; // query(ref, ...cons);

  } catch (error) {
    console.error(error);
  }
};

/** Client Filtering of the data */
export const rentOptionSearchClientFilter = (p: RentalProductBase[], o: RentOptionParam): RentalProductBase[] => {
  console.log('RPB',p);
   // filter by id
   if (o.rIds?.length > 0) {
    // p = p.filter((val) => o.rIds.indexOf(`${val.id}`) > -1);
		p = p.filter((val, idx) => o.rIds.indexOf(val.id) > -1);

  }
  // filter by type.
  if (o.type != null && <any>o.type !== '*') {
    p = p.filter((val) => val.productSummary.productType === +o.type);
  }
  // if (o.isRentalVehicle != null) {
  //   p = p.filter((val) => !!val.productSummary);
  //   // client filter to remove rentoptions not cleaned by cronJob
  //   p = RentalProductBase.setObsClosedRentals(p);
  // }
  // filter by type. MyRent Options show all, Search Rent option show released only
  if (!!o.dbStatus) {
    p = p.filter((val) => val.dbStatus === +o.dbStatus);
  }
  if (typeof o.isActive === 'boolean') {
    p = p.filter((val) => val.isActive === o.isActive);
  }
  // filter by cid
  if (o.cids?.length > 0) {
    p = p.filter((val) => o.cids.indexOf(`${val.vendorCompSummary.cid}`) > -1);
  }
  // filter by product ID
  if (!!o.pid) {
    p = p.filter((val) => val.productSummary.pid === o.pid);
  }
  if (!!o.vCid) {
    p = p.filter((val) => val.vendorCompSummary.cid === o.vCid);
  }
  if (!!o.availEndDate) {
    p = p.filter((val) => isAfter(val.availEndDate, o.availEndDate));
  }
  if (!!o.make && o.make !== '*') {
    p = p.filter((val) => (val.productSummary as TruckProdSummary).make === o.make);
  }
  if (typeof o.isExclusive === 'boolean') {
    p = p.filter((val) => val.isExclusive == o.isExclusive);
  }
  if (typeof o.isDayCab === 'boolean') {
    p = p.filter((val) => (val.productSummary as TruckProdSummary).isDayCab === o.isDayCab);
  }
  if (!!o.center) {
    p = p.filter((val) => {
      const distanceInKm = distanceBetween([val.startAddress.geoLoc.geopoint.latitude, val.startAddress.geoLoc.geopoint.longitude],
        [o.center.latitude, o.center.longitude]);
      if (distanceInKm <= 1000) {
        return true;
      } else {
        return false;
      }

    });


  }
  // if (!!o.hasPicture) {
  //   p = p.filter((val) => !!(<RentalTruck>val).hasPicture);
  // }
  if (o.rentOptionStatus != null && <any>o.rentOptionStatus !== '*') {

    p = p.filter((val) => val.rentOptionStatus === o.rentOptionStatus);
  }
  // if (!!o.searchStartDate) {
  //   console.log('p with openDateRange in Param', p);
  //   p = p.filter(f => {
  //     for (let k = 0; k < f.openDateRange.length; k = k + 1) {
  //       if (isWithinRange(o.searchStartDate, f.openDateRange[k].start, f.openDateRange[k].end)
  //         && isWithinRange(o.searchEndDate, f.openDateRange[k].start, f.openDateRange[k].end)) { return true; }
  //     }
  //     return false;
  //   });
  // }
  // documents in radius
  // if (!!o.isClientGeoFilterReq) {
  //   const geo = geofirex.init(firebase);
  //   // p = p.filter(r => geo.point(r.startAddress.geoLoc.geopoint.latitude, r.startAddress.geoLoc.geopoint.longitude)
  //   //   .distance(o.startLat, o.startLong) < (o.radius + o.incRadius));
  // }
  // const d = [];
  // for (let i = 0; i < p.length; i = i + 1) {
  //   // d[i] = <any> Object.assign({ dist: findDistance(o.startLat, o.startLong, p[i].startAddress.lat, p[i].startAddress.long) }, p[i]);
  //   // console.log('p[i]', p[i]);
  //   p[i].dist = findDistance(o.startLat, o.startLong, p[i].startAddress.lat, p[i].startAddress.long);
  // }
  // if (!!o.id) {
  //   p = p.filter(r => r.id === o.id);
  // }
  p = p.sort(function (a, b) { return a.dist - b.dist; });
  return p;
};

/** Not implemented. solution replaced by agreement rental-document */
// export const rentOptionQueryWithGeoSearch = (o: RentOptionParam, p: Paging,
//   col: string, ref: firebase.firestore.CollectionReference) => {

//   const geo = geofirex.init(firebase);
//   const queryFS = firebase.firestore().collection(col);
//   let q: firebase.firestore.Query;
//   // const dbStatus = DbStatus;
//   if (!!o.dbStatus) {
//     q = queryFS.where('dbStatus', '==', o.dbStatus);
//   }
//   if (!!o.type && o.type !== <any>'*') {
//     q = q.where('productSummary.productType', '==', +o.type);
//   }
//   if (o.cids.length > 0) {
//     console.log('o.cids[0]', o.cids[0]);
//     q = q.where('vendorCompSummary.cid', '==', o.cids[0]);
//   }
//   if (!!o.pid && o.pid !== 'x') {
//     q = q.where('productSummary.pid', '==', o.pid);
//   }
//   if (o.rentOptionStatus != null && <any>o.rentOptionStatus !== '*') {
//     q = q.where('rentOptionStatus', '==', o.rentOptionStatus);
//   }
//   q = q.limit(p.size);

//   let geoQuery: geofirex.GeoFireQuery<RentalProductBase>;
//   if (!!o.center) {
//     const radius = !!o.radius ? o.radius : 50;
//     geoQuery = geo.query<RentalProductBase>(q);
//     const center = this.geo.point(o.center.latitude, o.center.longitude);
//     const g$ = geoQuery.within(center, radius, 'startAddress.geoLoc');
//     return g$;
//   } else {
//     throw new Error('to be implemented');
//   }
// };
