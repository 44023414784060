import { isEqual, round, isNaN, isArray, isNil, isNumber, isEmpty, cloneDeep, includes } from 'lodash';
import { TransactionSummary, TransactionB } from '../../finance-transaction/transaction-b';
import { InvoiceItem } from '../invoice/invoice-item';
import { Exclude, Expose, plainToInstance, instanceToInstance, instanceToPlain } from 'class-transformer';
import { BaseModel } from '../../base';
import { ValidatorOptions, IsEnum, IsDefined, isDate } from 'class-validator';
import { sanitizeDateIPoint, } from '../../utility';
import { IValidationMsg } from '../../error-handling/validation-info';
import { OrderType, CurrencyCode } from './order-type';
import { logger } from '../../log/logger';
import { IPriceItem, IPymtSchedule, getPriceItemSum, initPriceItem, isEmptyPriceItem, IPriceSummary, initPriceSummary, getPriceSummarySum, itemIdsToArray } from '../invoice/i-item';
import { TransactionType } from '../invoice/invoice-type';
import { sanitizeDate } from '../../utility/sanitize-helper';
import { format, getDay, getMonth, getYear, isEqual as isEqualDate, toDate, isEqual as isEqualD, isAfter } from 'date-fns';
import { length } from 'class-validator';
import { UserEnum } from '../../user/user-enum';
import { Invoice } from '../invoice/invoice';
import { TransactionManual, TransactionSummaryManual } from '../../finance-transaction/transaction-manual';
import { NegoTermsBase } from '@trentm/nego-terms-base/nego-terms-base';
import { UserProfile } from '../../user/user-profile';
import { InvoiceItemCat } from '../invoice/invoice-item-type';
import { IAuth, ISales, IUserRole } from '@trentm/company/company-base';
import { InvoiceRec } from '../invoice/invoice-rec';
import { isSameDate } from '../../utility/utl-datef';

@Exclude()
export class Order extends BaseModel {
 



  public static readonly collectionName = 'order';

  name: string;

  constructor() {
    super();
  }

  /** Number ID for the order that user can refer to.
   * TODO: Move to Invoice
   */
  // @Expose()
  numId: number;

  @Expose()
  invoiceId: string | number;

  /** contract that generated the invoice and inturn generated order
   * Bid  / Rental / Product etc.   */
  @Expose()
  public refId: string;

  /** used in debit entries only. So only release this payment if the corrosponding payment
   * from customer was received on the receviable order/invoice. */
  @Expose({ toPlainOnly: true })
  get clearToProcess() {
    if (isArray(this.pymtSchedule) && this.pymtSchedule.some(x => x.clearToProcess === true)) {
      return true;
    }
    return false;
  }

  // @Expose()
  // @Min(0)
  // amount: number;

  // @Expose()
  // tax: number;

  // @Expose()
  // @Min(0)
  // totalAmount: number;

  // @Expose()
  // @Min(0)
  // discount: number;

  @Expose({ toPlainOnly: true })
  @IsEnum(OrderType)
  get orderType(): OrderType {
    /** if -1, show last index. else show the nth loc  */
    return (this.pymtSchedule?.length > 0) ?
      this.pymtSchedule[(this.pymtIndex !== -1) ? this.pymtIndex : this.pymtSchedule.length - 1]?.orderType
      : undefined;
  }

  // @Expose()
  // @ArrayNotEmpty({ message: 'Order must contain at least one item in it' })
  // @ValidateNested({ each: true })
  orderItems: InvoiceItem[] = [];

  // @Expose()
  // serviceFee: number;

  // ----------------------------------------

  @Expose()
  invoiceType: TransactionType;

  /** Company Id on this order. */
  @Expose()
  cid: string;

  /** customer cid injected in pay order */
  @Expose()
  cCid: string | number;
  /** vendor cid injected in rec order */
  @Expose()
  vCid: string | number;

  /** contingent Order Id. For payable order matching receivable will be contingent Order  */
  @Expose()
  contingentOid: string | number;

  /** Order category Vendor Or Customer  */
  @Expose()
  orderCat: UserEnum;

  @Expose()
  @IsEnum(CurrencyCode)
  currencyCode: CurrencyCode;

  /** Committed number from the contract (via invoice) */
  pymtIndex = 0;

  @Expose()
  @IsDefined()
  pendingPymnt: IPriceSummary; // IPriceItem;

  /** Completed numbers from the contract (via invoice) */
  @Expose()
  // @IsDefined()
  completedPymt: IPriceItem;

  @Expose()
  pymtSchedule: IPymtSchedule[];

  @Expose()
  nextPaymentDate: Date;

  /** Track when last pre-auth was completed. */
  @Expose()
  preAuthorizedDate: Date;

  @Expose()
  lastPymtDate: Date;

  @Expose()
  transactions: TransactionSummary[] = [];

  //Maintain business role Id who has access of this invoice
  @Expose()
  customerAuth: IAuth;

  @Expose({ toPlainOnly: true })
  get customerAuthIds(): string[] {
    let r: string[] = [];
    if(this.customerAuth){
      for (const key in this.customerAuth) {
        let keys = Object.keys(this.customerAuth[key]);
        r.push(...keys);
      }
    }
    
    return r;
  }

  @Expose()
  vendorAuth: IAuth;

  @Expose({ toPlainOnly: true })
  get vendorAuthIds(): string[] {
    let r: string[] = [];
    if(this.vendorAuth){
      for (const key in this.vendorAuth) {
        let keys = Object.keys(this.vendorAuth[key]);
        r.push(...keys);
      }
    }
    return r;
  }
  
  get canCheckout() {
    return (this.orderType === OrderType.pay ||
      this.orderType === OrderType.preAuthorization);
  }

  get canVoidPreAuth() {
    return (this.orderType === OrderType.preAuthorized);
  }

  get availableDates() {
    return this.pymtSchedule.filter(f => !f.accountingInvoicePath && isDate(f.dueDate)).map(f => {
      return f.dueDate;
    });
  }

  hasReservedPymt(invoice: Invoice) {
    const reserveItem = invoice.invoiceItems.find(f => f.name === 'Reserve');
    return reserveItem.amount > 0; 
  }
  // @Expose()
  // @Length(1, 254, { message: 'Street number needs to be $constraint1-$constraint2 chars', groups: [Address.gName] })
  // streetNumber: string;   // Street Address, PO BOX

  /** Convert all GeoPoint class instances to IPoint. required before parsing data coming back
* from firebase. This must be called BEFORE PARSE and not AFTER PARSE  
* @param data Data to be parsed
* @param type type to be used to decipher latitude/longitude. This is needed because at client the type is : firebase.firestore.GeoPoint
* and at function it is admin.firestore.GeoPoint they are two different types.
* https://github.com/Microsoft/TypeScript/wiki/FAQ#why-cant-i-write-typeof-t-new-t-or-instanceof-t-in-my-generic-function
*/
  public static parse(obj) {
    // public static parse<T extends IPoint>(obj, ipointType?: { new(...args: any[]): T }) {
    try {
      if (obj == null) { return null; }
      if (obj instanceof Order) { return obj; }
      // obj = sanitizeDateIPoint(obj, ipointType);
      obj = sanitizeDateIPoint(obj);
      const m = plainToInstance<Order, any>(Order, obj);
      m.sanitize();
      return m;
    } catch (error) {
      logger.error('Error happened during parse', error);
      return null;
    }
  }

  /** one order is from client, other if from serverdb and a payment is about to be made
   * ensure that next payment schdule is same
   */
  public static isEqualOrder(a: Order, b: Order) {

    const c = JSON.stringify(instanceToPlain(a));
    const s = JSON.stringify(instanceToPlain(b));

    // const c = JSON.stringify(a.toFirebaseObj());
    // const s = JSON.stringify(b.toFirebaseObj());
    const r = isEqual(c, s);
    if (!r) {
      throw new Error(`client order: ${c}, server order ${s}`);
    }
    return r;

    // if (a.orderType !== b.orderType) {
    //   return false;
    // }
    // if (!a.canCheckout || !b.canCheckout) {
    //   return false;
    // }
    // if (!isEqual(a.nextPaymentDate, b.nextPaymentDate)) {
    //   return false;
    // }
    // const aPymnt = a.getNnextInstallmentAmount();
    // const bPymnt = b.getNnextInstallmentAmount();
    // if (!isEqual(aPymnt, bPymnt) || aPymnt == null) {
    //   return false;
    // }
    // return true;
  }
  /**
 * Converts Array of order ItemIds to array of Invoice Ids 
 * @param invItemIdsFromOrder 
 * @returns 
 */
  public static getInvoiceItemIdsForOrderInvItemId(invItemIdsFromOrder: string[]): number[] {
    let invItemIdsForInvoice: number[] = [];

    invItemIdsFromOrder.forEach(s => {
      if (s.includes(',')) {
        invItemIdsForInvoice = invItemIdsForInvoice.concat(s.split(',').map(n => +n));
      } else {
        invItemIdsForInvoice.push(+s);
      }
    });
    return invItemIdsForInvoice;
  };

  public getPreAuthurizedAmount(): TransactionSummary[] {
    const transactions: TransactionSummary[] = [];
    for (const p of this.pymtSchedule) {
      if (p.orderType === OrderType.preAuthorized) {
        // find all history items where action was preauthorized.
        const h = p.history.filter(hh => hh.action === OrderType.preAuthorized);
        for (const item of h) {
          const t = this.transactions.find(_t => _t.vendorOrderNumber === item.tid);
          if (!!t) {
            transactions.push(t);
          }
        }
        return transactions;
      }
    }
    return undefined;
  }


  addItem(x: InvoiceItem) {
    this.orderItems.push(x);
    this.updateTotal();
  }

  getNnextInstallmentAmount() {
    if (!(isArray(this.pymtSchedule))) {
      return undefined;
    }

    // // for pre-authorization, all of the amount is required to be processed.
    // if (this.orderType === OrderType.preAuthorization) {
    //   return { ...this.pendingPymnt };
    // }


    // const sch = this.pymtSchedule.find(x =>  isSameDay(x.dueDate, this.nextPaymentDate)); //  x.dueDate === this.nextPaymentDate);
    const sch = this.pymtSchedule.find(x =>
      x.orderType === OrderType.pay ||
      x.orderType === OrderType.preAuthorization ||
      x.orderType === OrderType.preAuthorized
    );
    if (sch == null) {
      return null;
    }
    return { ...sch.pendingPymnt };
  }


  /** update the  */
  updateTotal() {
    this.lastPymtDate = undefined;
    this.nextPaymentDate = undefined;

    if (!!this.pymtSchedule && this.pymtSchedule.length > 0) {
      this.pendingPymnt = getPriceSummarySum(this.pymtSchedule.map(x => x.pendingPymnt));
      this.completedPymt = getPriceItemSum(this.pymtSchedule.map(x => x.completedPymt));

      // next due date, from the beginning.
      for (let i = 0; i < this.pymtSchedule.length; i++) {
        const ele = this.pymtSchedule[i];
        if (ele.orderType === OrderType.pay || ele.orderType === OrderType.preAuthorization
          || ele.orderType === OrderType.preAuthorized) {
          this.nextPaymentDate = ele.dueDate;
          break;
        }
      }
      // last completed date, from the end
      for (let i = this.pymtSchedule.length - 1; i >= 0; i--) {
        const ele = this.pymtSchedule[i];
        if (ele.orderType === OrderType.paid) {
          this.lastPymtDate = ele.paymentDate;
          break;
        }
      }
    }

    if (isEmptyPriceItem(this.pendingPymnt)) {
      this.pendingPymnt = undefined;
    }
    if (isEmptyPriceItem(this.completedPymt)) {
      this.completedPymt = undefined;
    }


  }

  private movePymtIndex() {
    this.pymtIndex = (this.pymtIndex === this.pymtSchedule?.length - 1) ?
      -1 : this.pymtIndex + 1;
  }

  private progressOrderToNextStep(t: TransactionB): boolean {
    let x = this.pymtSchedule[this.pymtIndex];
    x.paymentDate = t.created;
    if (this.orderType === OrderType.preAuthorization) {
      if (t.amount < this.getNnextInstallmentAmount().totalAmount) {
        logger.error(`Preauthorized payment is invalid, Required preauth: ${this.pendingPymnt.totalAmount}
        paid; ${t.amount}`, t, x);
      } else {
        const p = this.pymtSchedule.find(pp => pp.orderType === OrderType.preAuthorization);
        if (p.history?.length == null) {
          p.history = [];
        }
        p.history.push({
          action: OrderType.preAuthorized,
          tid: t.order_number,
          paymentDate: new Date(x.paymentDate)
        });
        for (let idx = 0; idx < this.pymtSchedule.length; idx++) {
          const ele = this.pymtSchedule[idx];
          ele.orderType = OrderType.pay; // note, the first entry will be rewritten by the calling function to be authrized.
        }
        return true;
      }
    }
    if (isNaN(x.pendingPymnt.totalAmount) || isNaN(t.amount) || (x.pendingPymnt.totalAmount !== t.amount)) {   // TO DO:t.amount <= 0 to be discussed with HG
      logger.error('Error in cc transaction. amount has to be greater then pending amount', t, x);
      return false;
    }

    let pyt = t.amount;
    while (pyt > 0 || this.pymtIndex !== -1) {
      x = this.pymtSchedule[this.pymtIndex];
      x.paymentDate = t.created;
      pyt = round(pyt - x.pendingPymnt.totalAmount, 2);
      // update history when order Type is not preAuthorized
      if (x.history?.length == null) {
        x.history = [];
      }
      const hItem = { action: OrderType.paid, tid: t.order_number };
      if (!x.history.includes(hItem)) {
        x.history.push(hItem);
      }

      /** Exact payment was made */
      if (pyt >= 0) {
        x.completedPymt = (!!x.completedPymt) ?
          getPriceSummarySum([x.pendingPymnt, x.completedPymt]) : x.pendingPymnt;
        if (isDate(x.paymentDate)) {
          x.completedPymt.name = this.getCompletedPymtString(x);
        } else {
          x.completedPymt.name = 'Received';
        }
        x.pendingPymnt = undefined;
        x.orderType = OrderType.paid;
        this.movePymtIndex();
        // return here if expact payment was made
        if (pyt === 0) {
          return true;
        } else {
          // future payment is also paid.
          continue;
        }
      }
      // Here is negative scenario. that means a partial payment was made, just update the
      // partial and completed payment. Example, in the beginning of this loop
      // pend: 50, comp 0, pyt = 40 after subtraction above, now pyt = 40 - 50 = -10
      // pending should be :  10 and completed should be 40
      x.completedPymt = initPriceSummary(); // initPriceItem(x.completedPymt, true);
      // 50 - 10 + already paid from past.
      x.completedPymt.totalAmount = x.pendingPymnt.totalAmount + pyt
        + ((!!x.completedPymt?.totalAmount) ? x.completedPymt.totalAmount : 0);

      if (isNil(x.pendingPymnt)) {
        x.pendingPymnt = initPriceSummary();
      };
      // x.pendingPymnt =  initPriceItem(x.pendingPymnt, true);
      x.pendingPymnt.totalAmount = pyt * -1.0;
      x.orderType = OrderType.pay;
      return false;
      //       this.movePymtIndex();
    }
    return true;
  }
  /** Refund full amount does not handel partial */
  progressOrderAfterRefund(t: TransactionB): boolean {
    let x = this.pymtSchedule[this.pymtIndex];
    x.paymentDate = new Date(t.created);
    // x.pendingPymnt.totalAmount is NEGATIVE for refund item
    const pyt = round(-t.amount, 2);
    if (x.pendingPymnt.amount !== pyt) {
      logger.error('Error in cc transaction. refund amount does not match outstanding', t, x);
      return false;
    } else {
      x.completedPymt = x.pendingPymnt;
      x.orderType = OrderType.paid;
      if (isDate(x.paymentDate)) {
        x.completedPymt.name = this.getCompletedPymtString(x);
      } else {
        x.completedPymt.name = 'Received';
      }
      // update history when order Type is not preAuthorized
      if (x.history?.length == null) {
        x.history = [];
      }
      const hItem = { action: OrderType.paid, tid: t.order_number };
      if (!x.history.includes(hItem)) {
        x.history.push(hItem);
      }
      x.pendingPymnt = undefined;
      return true;
    }

  }
  /** every bambora transaction required a unique order id to be sent agains each transaction.
   * Keep incrementing this id at every request
   */
  getNextTransactionId() {
    if (this.transactions == null || this.transactions.length === 0) {
      return `${this.invoiceId}-0`;
    }
    return `${this.invoiceId}-${this.transactions.length}`;
  }

  /** Update the order once a credit card transaction has been successfully approved.
   * i.e  Original 'pay' to 'paid', preauth to authorized, preauthed to paid. */
  updateOrderAfterTransaction(t: TransactionB) {
    if (this.transactions == null) {
      this.transactions = [];
    }
    this.transactions.push(t.summary);
    if (+t.approved === 1) {
      const x = this.pymtSchedule[this.pymtIndex];
      switch (this.orderType) {
        case OrderType.pay:
          if (this.progressOrderToNextStep(t)) {
            x.orderType = OrderType.paid;
            if (t.paymentService === 'Manual') {
              x.clearToProcess = undefined;
            }
          }
          break;
        case OrderType.preAuthorization:
          if (this.progressOrderToNextStep(t)) {
            x.orderType = OrderType.preAuthorized;
            this.preAuthorizedDate = t.created;
          }
          break;
        case OrderType.preAuthorized:
          if (this.progressOrderToNextStep(t)) {
            x.orderType = OrderType.paid;
          }
          break;
        default:
          const msg = `Invalid Ordert was provided, transaction can only be added to an order with a
          current status of :${OrderType[this.orderType]}`;
          logger.error(msg, t, x);
          throw Error(msg);
      }
      this.updateTotal();
      this.lastPymtDate = t.created;
    }
  }
  updateOrderAfterReturnTransaction(t: TransactionB) {
    if (this.transactions == null) {
      this.transactions = [];
    }
    this.transactions.push(t.summary);
    if (+t.approved === 1) {
      const x = this.pymtSchedule[this.pymtIndex];
      switch (this.orderType) {
        case OrderType.pay:
          if (this.progressOrderAfterRefund(t)) {
            x.orderType = OrderType.paid;
            if (t.paymentService === 'Manual') {
              x.clearToProcess = undefined;
            }
          }
          break;
        default:
          const msg = `Invalid Ordert was provided, transaction can only be added to an order with a
          current status of :${OrderType[this.orderType]}`;
          logger.error(msg, t, x);
          throw Error(msg);
      }
      this.updateTotal();
      this.lastPymtDate = t.created;
    }
  }
  /** Update the order once a credit card transaction has been successfully approved.
 * i.e  Original 'pay' to 'paid', preauth to authorized, preauth to paid. */
  updateOrderAfterVoidPreauth(t: TransactionB) {
    if (this.transactions == null) {
      this.transactions = [];
    }
    this.transactions.push(t.summary);
    if (+t.approved === 1) {
      for (let i = 0; i < this.pymtSchedule.length; i++) {
        const p = this.pymtSchedule[i];
        if (p.orderType === OrderType.preAuthorized && t.amount === 0) {
          p.orderType = OrderType.cancelled; //OrderType.preAuthorization;
          if (p.history?.length == null) {
            p.history = [];
          }
          p.history.push({
            action: OrderType.cancelled, //OrderType.preAuthorization,
            tid: t.order_number,
            paymentDate: new Date(t.created)

          });
          return;
        }
      }
      const msg = `Invalid Ordert was provided, transaction can only be added to an order with a
          current status of :${OrderType[this.orderType]}`;
      logger.error(msg, t);
      throw Error(msg);
    }
    // this.updateTotal();
    this.lastPymtDate = t.created;
  }


  // validateSyncGroup(group: CompanyValidationGroup): IValidationMsg {
  //   return this.validateSync({ groups: [group] });
  // }

  validateSync(options?: ValidatorOptions, dueDate?: Date): IValidationMsg {

    // for nested entry for address, add address group in it.
    const r = this.validateSyncBase(this, options);
    if (isDate(dueDate)) {
      const dueDates = this.pymtSchedule.map(d => d.dueDate.valueOf());
      if (!dueDates.includes(dueDate.valueOf())) {
        r['dueDate'] = [`Incorrect due date`];
      }
      const index = this.pymtSchedule.findIndex(f => isEqualD(f.dueDate, dueDate));
      if (!!this.pymtSchedule[index].accountingInvoicePath) {
        r['pymtSchedule'] = [`Record locked, cannot generate invoice`];
      }
    }
    // const t = this.getTotal();
    // if (t.amount !== this.amount) {
    //   console.log(`should val: ${t.amount}, actual: ${this.amount}`);
    //   addValidationMsg(r, 'amount', 'amount is incorrect');
    // }

    // if (t.tax !== this.tax) {
    //   console.log(`should val: ${t.tax}, actual: ${this.tax}`);
    //   addValidationMsg(r, 'tax', 'tax is incorrect');
    // }

    // if (t.discount !== this.discount) {
    //   console.log(`should val: ${t.discount}, actual: ${this.discount}`);
    //   addValidationMsg(r, 'discount', 'discount is incorrect');
    // }
    // if (t.totalAmount !== this.totalAmount) {
    //   console.log(`should val: ${t.totalAmount}, actual: ${this.totalAmount}`);
    //   addValidationMsg(r, 'amount', 'Total amount is incorrect');
    // }
    return r;
  }

  clone() {
    const t = instanceToInstance(this);
    t.sanitize();
    return t;
  }
  sanitize() {
    // if data was recieved from firebase, date is stored as snapshot.
    super.sanitize();
    this.preAuthorizedDate = sanitizeDate(this.preAuthorizedDate);
    this.nextPaymentDate = sanitizeDate(this.nextPaymentDate);
    this.pymtSchedule.forEach(e => {
      e.dueDate = sanitizeDate(e.dueDate);
      e.paymentDate = sanitizeDate(e.paymentDate);
    });
    // this.insExpiryDate = sanitizeDate(this.insExpiryDate);
    // return this;
  }

  canCancelPreAuth(origTid: string): boolean {

    const x = this.pymtSchedule.find(y => y.orderType === OrderType.preAuthorized);
    const trans = this.transactions.find(f => f.vendorOrderNumber === origTid);

    if (isNil(x) || isNil(x.history) || !x.history.find(z => z.tid === origTid) || isNil(trans)) {
      throw new Error(`Transaction id: ${origTid} does not exist in the preauthorized items.`);
    }

    if (!trans.approved) {
      throw new Error(`Transaction id: ${origTid} was not approved by the the provider.`);
    }

    if (!isNumber(x.pendingPymnt?.totalAmount) || x.pendingPymnt.totalAmount !== trans.amount) {
      throw new Error(`Transaction id: ${origTid} shown amount: ${trans.amount} does not match with amount stored
      inside teh order (${x.pendingPymnt?.totalAmount}.`);
    }
    return true;
  }
  canMarkPaid(paymentForDate: Date, amountPaid: number): boolean {
    if (!isDate(paymentForDate) || !isNumber(amountPaid)) {
      throw new Error(`Invalid data for checking isFullPayment for order id: ${this.id}`);
    }
    const p = this.pymtSchedule.find(f => isEqualDate(f.dueDate, paymentForDate) && !isEmpty(f.pendingPymnt));
    if (isNil(p)) {
      throw new Error(`No payment pending for order id ${this.id} payment date ${paymentForDate}`);
    }
    // check item is clear to process
    if (this.invoiceType === TransactionType.debit) {
      if (!this.clearToProcess || isNil(this.clearToProcess)) {
        return false;
      }
    }
    // now check if amount matches the required payment
    if (p.pendingPymnt.totalAmount < amountPaid) {
      logger.error(`For order id ${this.id} for payment date ${paymentForDate}, paid amount: ${amountPaid} does not match required payment amount
       ${p.pendingPymnt.totalAmount}`);
      return false;
    }
    return true;
  }
  /** To be replaced by @method updateOrderAfterTransaction  
   * @deprecated 
   */
  updateAfterManualPayment(paymentForDate: Date, amountPaid: number, comment: string) {
    if (!isDate(paymentForDate) || !isNumber(amountPaid) || !length(comment, 0, 256)) {
      throw new Error(`Invalid data for checking isFullPayment for order id: ${this.id}`);
    }
    const p = this.pymtSchedule.find(f => isEqualDate(f.dueDate, paymentForDate) && !isEmpty(f.pendingPymnt));
    if (isNil(p)) {
      throw new Error(`No payment pending for order id ${this.id} payment date ${paymentForDate}`);
    }
    p.completedPymt = (!!p.completedPymt) ?
      getPriceSummarySum([p.pendingPymnt, p.completedPymt]) : p.pendingPymnt;
    p.pendingPymnt = undefined;
    p.orderType = OrderType.paid;
    p.clearToProcess = undefined;
    p.comment = comment;
    this.movePymtIndex();
  }
  getCompletedPymtString(x: IPymtSchedule) {
    switch (this.invoiceType) {
      case TransactionType.credit:
        if (x.pendingPymnt.totalAmount > 0) {
          return `Received on ${format(x.paymentDate, 'dd-MMM-yyyy')}`;
        } else if (x.pendingPymnt.totalAmount < 0) {
          return `Refunded on ${format(x.paymentDate, 'dd-MMM-yyyy')}`;
        } else {
          throw new Error('cannot be zero payment');

        }
      case TransactionType.debit:
        if (x.pendingPymnt.totalAmount > 0) {
          return `Paid on ${format(x.paymentDate, 'dd-MMM-yyyy')}`;
        } else if (x.pendingPymnt.totalAmount < 0) {
          return `Collected on ${format(x.paymentDate, 'dd-MMM-yyyy')}`;
        } else {
          throw new Error('cannot be zero payment');
        }
      default:
        throw new Error('incorrect Invoice Type');

        break;
    }
  }
  /**
   * find and match completed payments from old to new Invoice used when revising contract 
   * @param cOrder original customer Order
   * @param cInvoice original customer Invoice
   * @param eCInvoice updated customer Invoice
   */
  matchCompletedItems(cOrder: Order, cInvoice: Invoice, eCInvoice: Invoice) {
    // get completed itemIds strings e.g. ['1', '2,3', ...]
    const completedIds = cOrder.pymtSchedule.filter(f => !isEmpty(f.completedPymt) && f.completedPymt.totalAmount !== 0).map(g => g.invItemId);
    // convert completed itemsIds to number array
    const completedIdsN: number[] = [];
    completedIds.forEach(s => {
      if (s.includes(',')) {
        completedIdsN.concat(s.split(',').map(n => +n));
      } else {
        completedIdsN.push(+s);
      }
    });
    const completedIItemsOriginal = cInvoice.invoiceItems.filter(f => completedIdsN.includes(f.itemId));
    const completedIItemsDatesOriginal = completedIItemsOriginal.map(f => f.dueDate.valueOf());

    const completedIItemsNew = eCInvoice.invoiceItems.filter(f => completedIItemsDatesOriginal.includes(f.dueDate.valueOf()));
    for (const item of completedIItemsNew) {
      const a = completedIItemsOriginal.find(f => f.dueDate.valueOf() === item.dueDate.valueOf());
      // compare amount taxes and name
      if(item.itemId !== a.itemId) {
        return false;
      }
      if(item.amount !== a.amount) {
        return false;
      }
      if(item.name !== a.name) {
        return false;
      }
      if(item.invoiceItemType !== 'securityDeposit') {
        if(!isEqual(item.tax, a.tax)) {
          return false;
        }
        if(!isEqual(item.period, a.period)) {
          return false;
        }
      }

      // if (item.itemId !== a.itemId || item.amount !== a.amount || !isEqual(item.tax, a.tax) || item.name !== a.name || !isEqual(item.period, a.period)) {
      //   return false;
      // }
    }
    return true;
  }
  /**
   * Transfer completed payments from old to new Order used when revising contract 
   * @param cOrder original customer Order
   * @param cInvoice original customer Invoice
   * @param eCInvoice updated customer Invoice
   */
  transferCompletedPayments(cOrder: Order, cInvoice: Invoice, eCInvoice: Invoice) {

    const b = this.matchCompletedItems(cOrder, cInvoice, eCInvoice);
    if (!b) {
      throw new Error('Cannot transfer Security Deposit');
    }
    // this.transactions = cOrder.transactions;
    const completedDueDates = cOrder.pymtSchedule.filter(f => !isEmpty(f.completedPymt) && f.completedPymt.totalAmount !== 0).map(g => g.dueDate);
    for (const p of this.pymtSchedule) {
      const origPaid = cOrder.pymtSchedule.find(f => (completedDueDates.some(d => isSameDate(d, f.dueDate)) && isSameDate(p.dueDate, f.dueDate)));
      const origInvoiced = cOrder.pymtSchedule.find(f => !!f.accountingInvoicePath && isSameDate(p.dueDate, f.dueDate));
      if (!!origPaid) {
        const tids = origPaid.history.filter(f => !!f.tid).sort(s => new Date(s.paymentDate).valueOf()).map(t => t.tid);
        if (tids.length > 0) {
          tids.forEach(t => {
            this.pymtIndex = this.pymtSchedule.findIndex(f => f.dueDate === p.dueDate);
            const tSummary = cOrder.transactions.find(f => f.vendorOrderNumber === t);
            // const obj = TransactionB.parse(this.transactions.find(f => f.vendorOrderNumber === t));
            // const d = obj.paymentService === 'Manual' ? TransactionManual.parse(obj) : TransactionB.parse(obj);
            let d: TransactionB | TransactionManual;
            if(tSummary.paymentService !== 'Manual'){
              d = new TransactionB();
              d.setTransactionBFromSummary(tSummary);
            } else {
              d = new TransactionManual();
              d.setTransactionManualFromSummary(tSummary);
              // (d as TransactionManual).approved = 1; 
              // d.created = origP.paymentDate;
              // (d as TransactionManual).paymentDate = origP.paymentDate; 
              // d.id = 'Manually Logged';
              
              // d.transactionId = obj.vendorTransId;
              // d.amount = origP.completedPymt.totalAmount;
              // (d as TransactionManual).comment = origP.comment;
            }
            this.updateOrderAfterTransaction(d as TransactionB);
            p.accountingInvoicePath = !!origPaid.accountingInvoicePath ? origPaid.accountingInvoicePath : null;
            p.accountingInvoiceDate = !!origPaid.accountingInvoiceDate ? origPaid.accountingInvoiceDate : null;
          });
        } else {
          // const dM = new TransactionManual();
          // dM.approved = 1;
          // dM.created = origP.paymentDate;
          // dM.id = 'Manually Logged';
          // dM.paymentDate = origP.paymentDate;
          // dM.amount = origP.completedPymt.totalAmount;
          // dM.comment = origP.comment;
          // this.pymtIndex = this.pymtSchedule.findIndex(f => f.dueDate.valueOf() === origP.dueDate.valueOf());

          // this.updateOrderAfterTransaction(dM as any);
          // p.accountingInvoicePath = !!origPaid.accountingInvoicePath ? origPaid.accountingInvoicePath : null;
          // p.accountingInvoiceDate = !!origPaid.accountingInvoiceDate ? origPaid.accountingInvoiceDate : null;
        }
      } else if (!!origInvoiced) {
        p.accountingInvoicePath = !!origInvoiced.accountingInvoicePath ? origInvoiced.accountingInvoicePath : null;
        p.accountingInvoiceDate = !!origInvoiced.accountingInvoiceDate ? origInvoiced.accountingInvoiceDate : null;
      }
    }



  }
  /** Transfer PreAuth Payment from one Order to new Order; used for bid modify */
  transferPreAuthPayments(cOrder: Order) {


    // this.transactions = cOrder.transactions;
    const preAuthDueDates = cOrder.pymtSchedule.filter(f => f.history?.length > 0 && f.history.findIndex(g => g.action === OrderType.preAuthorized) > -1 ).map(g => g.dueDate);
    if(preAuthDueDates.length === 0 ){
      return;
    }
    for (const p of this.pymtSchedule) {
      const origP = cOrder.pymtSchedule.find(f => (preAuthDueDates.some(d => isEqualD(d, f.dueDate)) && isEqualD(p.dueDate, f.dueDate)));
      if (!!origP) {
        const tids = origP.history.filter(f => !!f.tid).sort(s => new Date(s.paymentDate).valueOf()).map(t => t.tid);
        if (tids.length > 0) {
          tids.forEach(t => {
            this.pymtIndex = this.pymtSchedule.findIndex(f => f.dueDate === p.dueDate);
            this.pymtSchedule[this.pymtIndex].orderType = OrderType.preAuthorization;
            const tSummary = cOrder.transactions.find(f => f.vendorOrderNumber === t);
            // const obj = TransactionB.parse(this.transactions.find(f => f.vendorOrderNumber === t));
            // const d = obj.paymentService === 'Manual' ? TransactionManual.parse(obj) : TransactionB.parse(obj);
            let d: TransactionB | TransactionManual;
            if(tSummary.paymentService !== 'Manual'){
              d = new TransactionB();
              d.setTransactionBFromSummary(tSummary);
            } else {
              throw new Error('Pre Auth Transaction cannot be manual');
              
            }
            this.updateOrderAfterTransaction(d as TransactionB);
            p.accountingInvoicePath = !!origP.accountingInvoicePath ? origP.accountingInvoicePath : null;
            p.accountingInvoiceDate = !!origP.accountingInvoiceDate ? origP.accountingInvoiceDate : null;
          });
        } else {
          throw new Error('cannot reach this code block');
          
        }
      }
    }



  }
  

  /** check if contract end date can change. 
   * @param eInvoice Original customer Invoice
   */
  isContractCompleted(cInvoice: Invoice): boolean {

    // get payment schedule Items for which which are paid like security deposit
    const paidItemIdsString: string[] = this.pymtSchedule.filter(f => !!f.completedPymt && f.completedPymt.amount !== 0).map(g => g.invItemId);
    const invoicedItemIdsString: string[] = this.pymtSchedule.filter(f => !!f.accountingInvoicePath).map(g => g.invItemId);

    // convert to invoice item ids
    const paidItemsIds: number[] = Order.getInvoiceItemIdsForOrderInvItemId(paidItemIdsString);
    const invoicedItemsIds: number[] = Order.getInvoiceItemIdsForOrderInvItemId(invoicedItemIdsString);
    // check of any payment other than security deposit
    const paidItemsSecurityRefund = cInvoice.invoiceItems.filter(f => paidItemsIds.includes(f.itemId) && f.invoiceItemType === 'securityDeposit' && f.itemCat === InvoiceItemCat.SecurityDepositRefund);
    if (paidItemsSecurityRefund.length > 0) {
      return true;
    }
    const invoicedSecurityRefund = cInvoice.invoiceItems.filter(f => invoicedItemsIds.includes(f.itemId) && f.invoiceItemType === 'securityDeposit' && f.itemCat === InvoiceItemCat.SecurityDepositRefund);
    if (invoicedSecurityRefund.length > 0) {
      return true;
    }
    return false;
  }
  /** check if contract start can change. Allow no invoices are generated and no payments other security deposit are completed
   * @param eInvoice Original customer Invoice
   */
  isContractStarted(cInvoice: Invoice): boolean {
    // get payment schedule Item for which accounting invoice is completed
    const invoicedItemIdsString: string[] = this.pymtSchedule.filter(f => !!f.accountingInvoicePath).map(g => g.invItemId);
    const invoiceItemsIds: number[] = Order.getInvoiceItemIdsForOrderInvItemId(invoicedItemIdsString);
    const invoicedItemsNotSecurityD = cInvoice.invoiceItems.filter(f => invoiceItemsIds.includes(f.itemId) && f.invoiceItemType !== 'securityDeposit');

    // const p = this.pymtSchedule.filter(f => !!f.accountingInvoicePath);
    if (invoicedItemsNotSecurityD.length > 0) {
      return true;
    }
    // get payment schedule Items for which which are paid like security deposit
    const paidItemIdsString: string[] = this.pymtSchedule.filter(f => !!f.completedPymt && f.completedPymt.amount !== 0).map(g => g.invItemId);

    // convert to invoice item ids
    const paidItemsIds: number[] = Order.getInvoiceItemIdsForOrderInvItemId(paidItemIdsString);
    // check of any payment other than security deposit
    const paidItemsNotSecurityD = cInvoice.invoiceItems.filter(f => paidItemsIds.includes(f.itemId) && f.invoiceItemType !== 'securityDeposit');
    if (paidItemsNotSecurityD.length > 0) {
      return true;
    }
    return false;
  }
  /**
    * @param this is original Customer Order
    * @param em updated Terms
    * @returns @param t1: Term which can be modified; @param t2: Term which is additional 
    */
  getTermsForContractChange(em: NegoTermsBase, m: NegoTermsBase, cInvoice: Invoice, eCInvoice: Invoice): { t1: NegoTermsBase, t2?: NegoTermsBase } {
    let t1: NegoTermsBase;
    let t2: NegoTermsBase;
    const isContractStarted = this.isContractStarted(cInvoice);
    if (!isContractStarted) {
      t1 = em.clone();
      return { t1 };
    } else {
      const lastPrintedAccInvoiceItem = this.getLastPrintedAccInvoiceItem();
      if (!lastPrintedAccInvoiceItem) {
        throw new Error('if contract not started');
      }
      const printedInvoicedItemsIds: number[] = Order.getInvoiceItemIdsForOrderInvItemId([lastPrintedAccInvoiceItem.invItemId]);
      const printedInvoicedItems = cInvoice.invoiceItems.filter(f => printedInvoicedItemsIds.includes(f.itemId));
      // printedInvoicedItems will have the same due date 
      const printedInvoicedItemWithPeriod = printedInvoicedItems.find(f => !isEmpty(f.period) && isDate(f.period.from) && isDate(f.period.to));
      if (!printedInvoicedItemWithPeriod) {
        throw new Error('Last item does not have period');
      }
      t1 = m.clone();
      t2 = m.clone();
      t1.endDate = printedInvoicedItemWithPeriod.period.to;
      t1.startDate = m.startDate;
      t2.startDate = t1.endDate;
      t2.endDate = em.endDate;
      if (!isAfter(t2.endDate, t2.startDate)) {
        throw new Error('contract is locked for this period');

      }
      return { t1, t2 };
    }

  }
  /**
   * 
   * @returns last @param pymtSchedule Item for which invoice is printed
   */
  getLastPrintedAccInvoiceItem() {

    const p = this.pymtSchedule.filter(f => !!f.accountingInvoicePath);
    p.sort((a, b) => a.dueDate.valueOf() - b.dueDate.valueOf());
    return p.pop();

  }
  /**
   * 
   * @returns last @param pymtSchedule Item for which invoice is printed
   */
  getLastPrintedAccIItemIds() {

    const p = this.pymtSchedule.filter(f => !!f.accountingInvoicePath);
    p.sort((a, b) => a.dueDate.valueOf() - b.dueDate.valueOf());
    return p.map(f => f.invItemId);

  }

  /**
   * Copy over ref properties when order is updated
   * @param oOrder original Order
   */
  copyRef(oOrder: Order) {
    this.invoiceId = oOrder.invoiceId;
    this.numId = oOrder.numId;
    this.refId = oOrder.refId;
  }
  /**
   * 
   * Check if Admin can terminate contract
   * @param cInvoice 
   * user is only check for client; server checks the user before calling this check
   */
  canTerminate(cInvoice: Invoice, user?: UserProfile): boolean {
    if (!!user) {
      if (!user.isEditor && !user.isAdmin && !user.isCredit) {//MKN - Sales Workflow - Credit person can terminate contract
        return false;
      }
    }
    const deposit = this.pymtSchedule.find(f => cInvoice.isSecurityDepositItem(f.invItemId));
    const returnDeposit = this.pymtSchedule.find(f => cInvoice.isSecurityDepositReturnItem(f.invItemId));
    if (deposit?.completedPymt?.totalAmount < returnDeposit?.pendingPymnt?.totalAmount) {
      return false;
    }

    return !(this.pymtSchedule.findIndex(f => f.accountingInvoicePath && !cInvoice.isSecurityDepositReturnItem(f.invItemId)) > -1);

  }

  /**
   * set Payment Index for return refund
   * @param eCInvoice Invoice
   */
  setReturnPaymentIndex(eCInvoice: Invoice) {
    this.pymtIndex = this.pymtSchedule.findIndex(f => eCInvoice.isSecurityDepositReturnItem(f.invItemId));
  }

  /**
   * Find the security deposit Item and refund the Credit card fee
   * This fn will check if refund can be executed should responsibility of the calling fn
   * @param eCInvoice 
   * @param vendorTransId Bambora server transaction id
   */
  getSecurityDepositPaymentRef(eCInvoice: Invoice): {
    vendorTransId: string, vendorOrderNumber: string, card: {
      card_type: string;
      last_four: string;
    }
  } {
    const p = this.pymtSchedule.find(f => eCInvoice.isSecurityDepositItem(f.invItemId));
    const transactionId = p?.history?.find(f => f.action == OrderType.paid).tid;
    if (!transactionId && !!p) {
      throw new Error('cannot find transaction error');

    }
    const t = this.transactions?.find(f => f.vendorOrderNumber === transactionId);
    const vendorTransId = t?.vendorTransId;
    const vendorOrderNumber = t?.vendorOrderNumber;
    const card = t?.card;
    return { vendorTransId, vendorOrderNumber, card };

  }
  /** check if unit can be switched; Only possible if not invoice has been cut */
  changeUnit(cInvoice: Invoice): boolean {
    throw new Error('Method not implemented.');
  }
  /** returns latest date for which payment is complete for contract which is started */
  lastCompletedPaymentTill(cInvoice: Invoice) {
    
    const itemIds: string[] = this.pymtSchedule.filter(f => !!f.accountingInvoicePath).map(g => g.invItemId);
    if (itemIds.length === 0 || !this.isContractStarted(cInvoice)) {
      throw new Error('if contract not started');
    }
    let pInvoicedItemIds: number[] = [];
    itemIds.forEach(e => {
      pInvoicedItemIds = pInvoicedItemIds.concat(Order.getInvoiceItemIdsForOrderInvItemId([e]));
    });
    const endDates = cInvoice.invoiceItems.filter(f => pInvoicedItemIds.includes(f.itemId) && !isEmpty(f.period) && isDate(f.period.to)).map(g => g.period.to);
    endDates.sort((a, b) => a.valueOf() - b.valueOf());
    return endDates.pop();

   
    // const lastPrintedAccInvoiceItem = this.getLastPrintedAccInvoiceItem();
    // if (!lastPrintedAccInvoiceItem || !this.isContractStarted(cInvoice)) {
    //   throw new Error('if contract not started');
    // }
    // const printedInvoicedItemsIds: number[] = Order.getInvoiceItemIdsForOrderInvItemId([lastPrintedAccInvoiceItem.invItemId]);
    // const printedInvoicedItems = cInvoice.invoiceItems.filter(f => printedInvoicedItemsIds.includes(f.itemId));
    // // printedInvoicedItems will have the same due date 
    // const printedInvoicedItemWithPeriod = printedInvoicedItems.find(f => !isEmpty(f.period) && isDate(f.period.from) && isDate(f.period.to));
    // if (!printedInvoicedItemWithPeriod) {
    //   throw new Error('Last item does not have period');
    // }
    // return printedInvoicedItemWithPeriod.period.to;
  }
  /** Check is original order security deposit was preAuth */
  isPreAuth(): boolean {
    return this.pymtSchedule.findIndex(f => f.invItemId === '0' && f.history?.length > 0 && f.history?.findIndex(g => g.action === OrderType.preAuthorized) > -1) > -1;

  }
    /**
   * @author Cm
   * @purpose Add sales User
   * @param user UserProfile
   */


  addUserAsSales(user:{id: string | number, displayName:string, isPrimary?:boolean, percentage?:number}) {
    // taking id of the auth user
    if (!this.customerAuth) {
      this.customerAuth = { sales: {} };
    }
    if (!this.customerAuth?.sales) {
      this.customerAuth.sales = {};
    }
    let sales: { [key: string]: ISales } = {
      [user.id]: {
        displayName: user.displayName,
        isPrimary: user?.isPrimary ? true : false,
        percentage: user?.percentage ? user.percentage : 0
      }
    };
    // Here adding sales person id 
    this.customerAuth.sales = { ...this.customerAuth.sales, ...sales };
  }
    /**
   * @author Cm
   * @purpose Add credit User
   * @param user UserProfile
   */

  addUserAsCredit(user: UserProfile) {
    // taking id of the credit user
    if (!this.vendorAuth) {
      this.vendorAuth = { credit: {} };
    }
    if (!this.vendorAuth?.credit) {
      this.vendorAuth.credit = {};
    }
    let credit: { [key: string]: IUserRole } = {
      [user.id]: {
        displayName: user.displayName,
      }
    };
    // Here adding credit person id 
    this.vendorAuth.credit = { ...this.vendorAuth.credit, ...credit };
  }
  /**
   * get date reserve amount was collected or pre Authorized 
   * @param cInvoice 
   * @author SSS
   */
  getReserveAmountDate(cInvoice: InvoiceRec): Date {
    const reserveItem = cInvoice.invoiceItems.find(f => f.name === 'Reserve');
    if(!reserveItem || isNil(reserveItem.itemId)) {
      return undefined;
    }
    const index = reserveItem.itemId;
    return this.pymtSchedule.find(f => itemIdsToArray(f.invItemId).includes(index)).dueDate;
  }
 

}