<mat-card appearance="outlined" *ngIf="showPhoneCode">
  <trent-status [statusMsg]="phoneCodeMsg"></trent-status>
</mat-card>
<form #phoneForm="ngForm" (ngSubmit)="requestPhoneVerification(phoneForm)">
  <mat-card appearance="outlined" class="gen-card wide-margin">
    <mat-card-header>
      <div mat-card-avatar>
        <mat-icon matSuffix>supervised_user_circle</mat-icon>
      </div>
      <mat-card-title>Phone</mat-card-title>
      <mat-card-subtitle>{{userAction}}</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <!-- phone no -->
      <mat-form-field class="full-width mt10">
        <mat-label>Phone No</mat-label>
        <input [disabled]='isReadMode || phoneStatus>=pStatus.isWaiting' matInput
          [(ngModel)]="em.phoneNumber" type="text" name="phoneNumber" #phoneNumber="ngModel" phone (ngModelChange)="checkPhone(phoneNumber.value)"/>
        <span matPrefix class='icon'>
          <mat-icon>phone</mat-icon>
        </span>
        <mat-hint *ngIf="!showError(phoneNumber) && !isReadMode" align="end">A valid phone number is required
        </mat-hint>
        <mat-error align='end' *ngIf="showError(phoneNumber)">
          {{phoneNumber.errors.msg}}</mat-error>
      </mat-form-field>

      <!-- Phone Verification Code -->
      <mat-form-field *ngIf="showPhoneCode" class="full-width top-10">
        <mat-label>Enter Verification Code</mat-label>
        <input maxlength="6" matInput [(ngModel)]="em.phoneCode"
          #phoneCode="ngModel" type="text" name="phoneCode" (ngModelChange)="checkCode(phoneCode)"/>
        <span matPrefix class='icon'>
          <mat-icon>verified_user</mat-icon>
        </span>
        <mat-error align='end' *ngIf="showError(phoneCode)">
          Missing or Invalid Verification Code
        </mat-error>
      </mat-form-field>

    </mat-card-content>
    <mat-card-actions class="flex-wrap">
      <!-- <trent-captchav2 (validation)="isCaptchaValid = $event" [class]="'captcha2'"></trent-captchav2> -->

      <button *ngIf="isReadMode" (click)="displayMode = mode.edit;" type="button" mat-stroked-button class="mt-2">
        <mat-icon>edit</mat-icon> Edit
      </button>

      <button *ngIf="isEditMode && !showPhoneCode"  type="submit" mat-stroked-button color="primary" [disabled]="phoneStatus!==pStatus.phoneEntered" class="mt-2">
        <mat-icon>save</mat-icon> Verify
      </button>

      <button *ngIf="isEditMode" type="button" class="ml-4 mt-2" (click)="cancel()" mat-stroked-button color='warn'>
        <mat-icon>cancel</mat-icon>Cancel
      </button>

      <button *ngIf="showPhoneCode" (click)="verifyAndUpdatePhone()" class="ml-4 mt-2" type="button" mat-stroked-button
        color="primary" [disabled]="phoneStatus!==pStatus.codeEntered">
        <mat-icon>save</mat-icon> Confirm Code
      </button>

    </mat-card-actions>
  </mat-card>
</form>