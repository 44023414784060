import { environment } from '@trent/core/environments/environment';

/** Fcm Token data as saved in the collection fcm-token db
 * Any change in property, should update @function getFcmTokenData
 */
export interface IFcmTokenData {
  deviceId: string;
  platform: string;
  token: string;
  updatedAt: Date;

  /** for web only */
  userAgent?: string;

  /** for cordova only */
  model?: string;
  manufacturer?: string;
  serial?: string;
}

/** info fetched for finger print / device id
 *  * Any change in property, should update @function getFcmTokenData
 */
export interface IDeviceIdComponents {
  deviceId: string;
  components: { key: string; value: any }[];
  updatedOn: Date;
}


/** get Fcm data function from input from cordova device id or from browser finger print. */
export const getFcmTokenData = (d: IDeviceIdComponents, token: string): IFcmTokenData => {
  const data: IFcmTokenData = {
    deviceId: d.deviceId,
    platform: d.components.find(x => x.key === 'platform').value,
    token,
    updatedAt: new Date()
  };

  if (environment.platform === 'web') {
    data.userAgent = d.components.find(x => x.key === 'userAgent').value;
  } else {
    data.model = d.components.find(x => x.key === 'model').value;
    data.manufacturer = d.components.find(x => x.key === 'manufacturer').value;
    data.serial = d.components.find(x => x.key === 'serial').value;
  }

  return data;
};
