
import { ProductType } from '../product/product-type';
import { instanceToInstance, plainToInstance, Expose, Exclude, Type } from 'class-transformer';
import { IsBoolean, IsDefined, ValidatorOptions } from 'class-validator';
import { TruckSummary } from '../product/truck';
import { sanitizeDateIPoint } from '../utility';
import { MapLabel } from '../map/map-label';
import { IValidationMsg } from '../error-handling/validation-info';
import { Picture } from '../inspection/picture';
import { BidBase } from '../bid/bid-base';
import { SalesProductBase } from './sales-product-base';

@Exclude()
export class SalesTruck extends SalesProductBase {
  constructor() {
    super();
    this.productType = ProductType.truck;
   // this.pictures = [];

  }
  @Expose()
  @IsDefined()
  productSummary: TruckSummary;

  @Expose()
	get onlineStockNumber() {
		const slName = this.storeLocationSummary?.name;
		const vin = (this.productSummary as TruckSummary)?.vin
		if(!!slName && !!vin){
			return `${slName.substring((slName.length) - 6, (slName.length))}-${vin?.substring((vin.length) - 6, (vin.length))}`;
		}else{
			return undefined;
		}
	}

  public static parse(obj) {
    if (obj == null) { return null; }
    // if data was recieved from firebase server, it may contain GeoPoint class that throw error
    // when using planToClass method. So simply the object aagin to get rid of GeoPoint class.
    obj = sanitizeDateIPoint(obj);
    const m = plainToInstance<SalesTruck, any>(SalesTruck, obj);
    m.sanitize();
    m.removeUndefined();
    return m;
  }

  clone() {
    const t = instanceToInstance(this);
    t.sanitize();
    return t;
  }

  sanitize() {
    super.sanitize();
  }

  validateSync(options?: ValidatorOptions): IValidationMsg {
    const r = super.validateSync(options);
    return r;
  }

  /**
   * Get Product Summary.
   */
  public getTruckSummary() {
    return {
      productSummary: this.productSummary,
    };
  }

  getMapLabel(): MapLabel {
    return {
      title: 'T',
      id: this.id,
      productType: this.productSummary.productType
    };
  }

  get summary() {
    return `${(this.productSummary.isDayCab) ? 'Day Cab Truck, ' : ' Sleeper Truck, '}
      ${(this.productSummary.isIRP) ? 'IRP Plated, ' : 'ON Plated, '}` +
      `${(this.productSummary.isAuto) ? 'Automatic Transmission' : 'Manual Transmission'}`;
  }
  
  createTitle() {
    let title: string;
    switch (this.productSummary.isDayCab) {
      case false:
        title = (this.productSummary.make.split(' '))[0] + ' ' + this.productSummary.modelYear + ' ' + 'Day-Cab Truck';
        break;
      case true:
        title = (this.productSummary.make.split(' '))[0] + ' ' + this.productSummary.modelYear + ' ' + 'Sleeper Truck';
        break;
      default:
        title = null;
        break;
    }
    return title;
  }

  get defaultImage() {
    return './assets/truck-semi.svg';      
  }
}
