import { IValidate, IValidationMsg } from '../error-handling/validation-info';
import { plainToInstance, instanceToInstance, Exclude, Expose, instanceToPlain } from 'class-transformer';
import { toLower } from 'lodash';
import { BaseModel } from '../base/base-model';
import { sanitizeDateIPoint } from '../utility';
import { ValidatorOptions, IsEmail, IsDefined, Length, ValidateIf, MaxLength, ValidateNested, IsBoolean } from 'class-validator';
import { toPascalCase } from '../utility/helper';
import { RoleGroupLevel } from './role-group-level';
import { UserProfile } from '../user';
import { RoleGroupType } from './role-group-enum';

/**
 * 
 * @author - MKN
 * Interface create/update api call
 * 
 */
export interface IRoleGroupData {
  data: RoleGroup;
  rgId?: string | number;
  options: ValidatorOptions;
}

/**
 * 
 * @author - MKN
 * Interface Maintain assigned user info in role group
 * 
 */
export interface RoleGroupUserProfile {
  email: string;
  displayName: string;
  rgLevel: RoleGroupLevel;
}

/**
 * 
 * @author - MKN
 * Interface Maintain assigned company info in role group
 * 
 */
export interface RoleGroupCompany {
  cName : string,
}

// export interface ICompanyRoleGroup {
//   shortName: string;
// }

@Exclude()
export class RoleGroup extends BaseModel implements IValidate {

  public static readonly collectionName = 'role-group';

  @Expose()
  @IsDefined({ message: 'Role Group type should be required' }) 
  roleGroupType: RoleGroupType;

  @Expose()
  @Length(1, 30, { message: 'Name must be $constraint1 - $constraint2 chars long' })
  name: string;

  //shortName - Used for claim purpose
  @Expose()
  @Length(3, 5, { message: 'Short Name must be $constraint1 - $constraint2 chars long' })
  shortName: string;

  @Expose()
  @MaxLength(200,{ message: 'Description must be 200 char long.'})
  @IsDefined({ message: 'Description must be added.'}) 
  description : string;

  @Expose()
  authUsers: { [key: string]: RoleGroupUserProfile };

  @Expose({ toPlainOnly: true })
  get assignedUsers(): string[] {
    let r: string[]= [];
    for (const key in this.authUsers) {
      if (this.authUsers.hasOwnProperty(key)) {
        if (r === undefined) {
          r = [];
        }
        r.push(key);
      }
    }
    return r;
  }

  
  @Expose()
  authCompany: { [key: string]: RoleGroupCompany };

  @Expose({ toPlainOnly: true })
  get assignedCompanies(): string[] {
    let r: string[]= [];
    for (const key in this.authCompany) {
      if (this.authCompany.hasOwnProperty(key)) {
        if (r === undefined) {
          r = [];
        }
        r.push(key);
      }
    }
    return r;
  }

  constructor() {
    super();
  }

  public static parse(obj: any) {
    if (obj == null) {
      return new RoleGroup();
    }
    const p = plainToInstance<RoleGroup, any>(RoleGroup,sanitizeDateIPoint(obj)); // ,{ strategy: 'excludeAll' }); exclude all fails.
    p.sanitize();
    return p;
  }

  clone() {
    const t = instanceToInstance(this);
    t.sanitize();
    return t;
  }

  public sanitize() {
    this.shortName = toLower(this.shortName);
    super.sanitize();
  }

  public validateSync(options?: ValidatorOptions): IValidationMsg {
    const r = this.validateSyncBase(this, options);
    // Add any additional validation here if/as required.
    return r;
  }

  /**
   * Update role group details
   * @param iUpdateData 
   */

  public updateRoleGroup(iUpdateData: RoleGroup): void {
    
    this.name = iUpdateData.name;
    
    if(iUpdateData.roleGroupType != this.roleGroupType)
      this.roleGroupType = iUpdateData.roleGroupType;

    if(iUpdateData.shortName != this.shortName)
      this.shortName = iUpdateData.shortName;

    this.description = iUpdateData.description;
  }

  /**
   * Update role group details
   * @param up 
   */
  public getAuthUserFromUserProfile(up: UserProfile) : RoleGroupUserProfile | null{

    if(up.roles && up.roles.rGrp[this.shortName]){
      return {
        email : up.email,
        displayName : up.displayName,
        rgLevel : (up.roles && up.roles.rGrp[this.shortName] ? up.roles.rGrp[this.shortName] : {})
      }
    }else{
      return null;
    }
  }
  
  /**
   * Update role group user details
   * @param up 
   */
  public updateUser(up: UserProfile, roleGroupUserProfile : RoleGroupUserProfile): void {
    
    if (this.authUsers == null) {
      this.authUsers = {};
    }
    !!roleGroupUserProfile ? (this.authUsers[up.id]  = roleGroupUserProfile) : delete this.authUsers[up.id]; 
  }

  /**
   * add/update company details
   * @param cid 
   * @param cName 
   */
  public addUpdateCompany(cid : string, cName : string ): void {
    
    if (this.authCompany == null) {
      this.authCompany = {};
    }
    this.authCompany[cid]  = {
      cName : cName,
    };
  }

   /**
   * remove company details
   * @param cid 
   */
  public removeCompany(cid : string ): void {
    
    if (this.authCompany == null) {
      this.authCompany = {};
    }
    delete this.authCompany[cid];
  }
  
  /**
   * MKN - Get role group key as per value
   */
  public static getRoleGroupKeyByValue(myEnum : any, enumValue : number) {
    let keys = Object.keys(myEnum).filter(x => myEnum[x] == enumValue);
    return keys.length > 0 ? toPascalCase(keys[0]) : '';
  }

}




