import { round } from 'lodash';
import { methodFn } from '../finance-input/fn-lib';
import { InvoiceItem } from '../invoice/invoice-item';
import { FinanceInput } from '../finance-input/finance-input-handler';
import { ITaxItem } from './i-tax-item';


export const calculateTax = (stateProv: string, item: InvoiceItem,  customAmount?: number, iTaxItems?: ITaxItem[]) => {

  const applTax = !iTaxItems ? FinanceInput.fin.jurisTaxDb[stateProv] : iTaxItems.map(f => f.name);
  if (applTax == null) {
    throw new Error(`Invalid Province code provided, ${stateProv} is invalid or not yet implemented.`);
  }
  const tax = {};
  for (const t of applTax) {
    const taxMethod = !iTaxItems ? FinanceInput.fin.taxCalcDb[t] : iTaxItems.find(f => f.name === t);
    if (taxMethod == null) {
      throw new Error(`Invalid tax method provided for prov: ${stateProv}, method: ${t} is invalid or not implemented.`);
    }
    tax[t] = round(methodFn(taxMethod, item, customAmount), FinanceInput.fin.rdigit);
  }
  return tax;
};


export const getTotalTax = (tax: {[key: string]: number}) => {
  return round( (!!tax) ? Object.values(tax).reduce((a, b) => a + b) : 0 ,  FinanceInput.fin.rdigit);
};
