import { Pickup } from './pickup';
import { Delivery } from './delivery';
import { TripTransaction } from './trip-transaction';
import { TripBase } from './trip-base';
import { TripActivityType } from './trip-activity-type';
import { instanceToInstance, plainToInstance, Expose, Exclude, Type, instanceToPlain } from 'class-transformer';
import { TransactionStatus } from './transaction-status';
// import { EnumPipe } from '../../custom-pipe/enum.pipe';
// import { DatePipe } from '@angular/common';
import { BaseModel } from '../base';
import { isEqual, isEmpty, intersection, pick } from 'lodash';
import { sanitizeDateIPoint, cleanupGenericProperties, cleanupUndefined } from '../utility';
import { CarrierCompanySummary } from '../company/company-carrier';
import { parseTripTransaction } from './trip-helper';
import { PromoListType } from '../promo/promo-list-type';
import { logger } from '../log/logger';
import { isBefore } from 'date-fns';
import { shortDateTime } from '../utility/timer-helper';

export class PickupTracker {
  tripId: string | number;
  @Type(() => Pickup)
  activity: Pickup;
}
export class DeliveryTracker {
  tripId: string | number;
  @Type(() => Delivery)
  activity: Delivery;
}
export class OnRoute {
  tripId: string | number;
  @Type(() => TripTransaction)
  activity: TripTransaction;
}

export enum TripTrackerStatus {
  active = 1,
  expired = 2
}
@Exclude()
export class TripTracker extends BaseModel {

  public static readonly collectionName = 'trip-tracker';

  constructor() {
    super();
  }

  trackingList: { [key: string]: { pickup?: Pickup, delivery?: Delivery, drop?: TripTransaction, onRoute?: TripTransaction[] } } = {};

  @Expose()
  trackingNo?: string;

  @Expose()
  @Type(() => PickupTracker)
  pickupTracker?: PickupTracker;

  @Expose()
  @Type(() => DeliveryTracker)
  deliveryTracker?: DeliveryTracker;

  @Expose()
  @Type(() => TripTransaction)
  drop?: TripTransaction;
  // **  on route transactions between pickup and delivery (e.g. border crossings, drops)*/
  @Expose()
  @Type(() => OnRoute)
  onRoute?: OnRoute[];

  @Expose()
  driverIds?: (string | number)[];

  @Expose()
  activeTrucks?: string[];

  @Expose()
  activeTrailers?: string[];

  @Expose()
  trailer?: string;

  @Expose()
  updateByTripId?: string | number;

  @Expose()
  carrierCompanySummary?: CarrierCompanySummary; // VendorCompanySummary |

  @Expose()
  tripTrackerStatus?: TripTrackerStatus;

  get pickupOnRoute(): OnRoute[] {
    return this.onRoute?.filter(f => f.tripId === this.pickupTracker?.tripId);
  }
  get deliveryOnRoute(): OnRoute[] {
    return this.onRoute?.filter(f => f.tripId === this.deliveryTracker?.tripId);
  }
  get trainstOnRoute(): OnRoute[] {
    return this.onRoute?.filter(f => f.tripId !== this.pickupTracker.tripId &&
      (!this.deliveryTracker || f.tripId !== this.deliveryTracker.tripId));
  }


  public static parse(obj) {
    // public static parse<T extends IPoint>(obj, ipointType?: { new(...args: any[]): T }) {
    try {
      if (obj == null) { return null; }
      // obj = sanitizeDateIPoint(obj, ipointType);
      obj = sanitizeDateIPoint(obj);
      const m = plainToInstance<TripTracker, any>(TripTracker, obj);
      // pickTracker activity can be pickup or hook, parse condtionally
      if (m.pickupTracker && m.pickupTracker.activity) {
        m.pickupTracker.activity = <Pickup>parseTripTransaction(obj.pickupTracker.activity);
      }
      // deliveryTracker activity can be delivery or drop, parse condtionally
      if (m.deliveryTracker && m.deliveryTracker.activity) {
        m.deliveryTracker.activity = <Delivery>parseTripTransaction(obj.deliveryTracker.activity);
      }
      m.sanitize();
      return m;
    } catch (error) {
      logger.log('Error happened during parse', error);
      return null;
    }
  }

  public static parseTripTrackerArray = (obj: any[]): TripTracker[] => {
    const r = !!obj ? obj.map(o => TripTracker.parse(o)) : null;
    return r;
  }
  // ** creates array of TripTracker when trip is ceated. Calling functions needs to ensure trip tracking numbers exists in the trip */
  static createTrackingDocs(trip: TripBase, newTripId?: string | number): TripTracker[] {
    const tNos = trip.trackingNumbers;
    const seq = trip.tripSequence;
    let pick: Pickup;
    let del: Delivery;
    const tripId = !!newTripId ? newTripId : trip.id;
    const tripTrackers: TripTracker[] = [];
    for (let i = 0; i < tNos.length; i++) {
      const tTracker = new TripTracker();
      tTracker.onRoute = [];
      const element = tNos[i];
      // logger.log('trip in process', ele);
      let trailerUsed: string;
      seq.forEach((f, j) => {
        const dKeys = !!(<Delivery>f).delTrackingNos ? Object.keys((<Delivery>f).delTrackingNos) : null;
        // const pKeys = !!(<Pickup>f).trackingNos &&
        // tslint:disable-next-line:max-line-length
        //   (!trip.pendingDeliveriesPreviousTrip || intersection(Object.keys(trip.pendingDeliveriesPreviousTrip), f.trackingNos).length === 0)
        //   ? (<Pickup>f).trackingNos : null;
        // const pKeys = !!(<Pickup>f).trackingNos  ? (<Pickup>f).trackingNos : null;
        let pKeys: string[];
        // tslint:disable-next-line:max-line-length
        if (!!(<Pickup>f).trackingNos && (!trip.pendingDeliveriesPreviousTrip || intersection(Object.keys(trip.pendingDeliveriesPreviousTrip), f.trackingNos).length === 0)) {
          pKeys = (<Pickup>f).trackingNos;
        }
        if (!!pKeys && pKeys.includes(element)) {
          pick = <Pickup>f;
          // pickIdx = j;
          // get the trailer used for the pickup
          if (f.activity === TripActivityType.pickUp) {
            trailerUsed = trip.getCurrentTruckTrailer(j, PromoListType.promoTrailer);
          } else if (f.activity === TripActivityType.hookUp && f.isLoaded) {
            trailerUsed = f.trailer;
          }
        }
        // if (element === dKey) {
        if (!!dKeys && dKeys.includes(element)) {
          del = <Delivery>f;
          // delIdx = j;
        }
        if (!!pick || !!del) {
          tTracker.trackingNo = element;
        }
        // current trans is pickup copy pick and tripId
        if (!!pick) {
          tTracker.pickupTracker = { tripId: tripId, activity: pick.clone() };
          pick = null;  // reset for for loop
        }
        // current trans is delivery copy delivery and tripId
        if (!!del) {
          tTracker.deliveryTracker = { tripId: tripId, activity: del.clone() };
          del = null;  // reset for for loop
        }
      });
      // let o: TripTransaction[] = [];
      // let pickIndex: number;
      // let deliveryIndex: number;
      if (!tTracker.pickupTracker && !tTracker.deliveryTracker) {
        continue;
      }
      // if (!!tTracker.pickupTracker) {
      //   pickIndex = seq.findIndex(f => f.activity === TripActivityType.pickUp &&
      //     isEqual(f.trackingNos, tTracker.pickupTracker.activity.trackingNos));
      // }
      // if (!!tTracker.deliveryTracker) {
      //   deliveryIndex = seq.findIndex(f => f.activity === TripActivityType.delivery && !!f.delTrackingNos &&
      //     Object.keys(f.delTrackingNos)[0] === Object.keys(tTracker.deliveryTracker.activity.delTrackingNos)[0]);
      // }
      // // if trip includes pickup and delivery. Add on route transactions before the delivery
      // if (!!tTracker.pickupTracker && !!tTracker.deliveryTracker) {
      //   o = seq.slice(pickIndex, deliveryIndex)
      //     .filter(f => f.activity !== TripActivityType.pickUp && f.activity !== TripActivityType.delivery && f.trailer === trailerUsed);
      // }
      // // if trip include only delivery for tracking no. Add on route transactions before the delivery
      // if (!tTracker.pickupTracker && !!tTracker.deliveryTracker) {
      //   o = seq.slice(0, deliveryIndex)
      //     .filter(f => f.activity !== TripActivityType.pickUp && f.activity !== TripActivityType.delivery && f.trailer === trailerUsed);
      // }
      // // if trip include only pickup for tracking no. Add on route transactions after pickup
      // if (!tTracker.deliveryTracker && !!tTracker.pickupTracker) {
      //   o = seq.slice(pickIndex, seq.length)
      //     .filter(f => f.activity !== TripActivityType.pickUp && f.activity !== TripActivityType.delivery && f.trailer === trailerUsed);
      // }
      // // add on route transactions
      // o.forEach(e => {
      //   if (!tTracker.onRoute) {
      //     tTracker.onRoute = [{ tripId: tripId, activity: e }];
      //   } else {
      //     tTracker.onRoute.push({ tripId: tripId, activity: e });
      //   }
      // });
      // copy props required for db Search
      tTracker.driverIds = [trip.driverIdOrPhone];
      tTracker.activeTrucks = trip.activeTrucks;
      tTracker.trailer = trailerUsed;  // only one trailer is used for pickup and delivery
      tTracker.updateByTripId = tripId;
      tTracker.carrierCompanySummary = trip.carrierCompanySummary;
      // tTracker.vendorCompSummary = trip.vendorCompSummary;
      tTracker.tripTrackerStatus = TripTrackerStatus.active;
      tripTrackers.push(tTracker);
    }
    return tripTrackers;
  }
  static addOnRoutes(trip: TripBase, tripTracker: TripTracker, newTripId?: string | number): TripTracker {
    const transits: OnRoute[] = [];
    const tid = newTripId ? newTripId : trip.id;

    for (const key in trip.trackingNosTrailerMove) {
      if (trip.trackingNosTrailerMove.hasOwnProperty(key)) {
        const element = trip.trackingNosTrailerMove[key];
        tripTracker.onRoute = tripTracker.onRoute ? tripTracker.onRoute : [];
        const activities = trip.tripSequence.filter(f => f.trailer && f.trailer === element.pickupTrailer);
        activities.forEach(ele => {
          transits.push({ tripId: tid, activity: ele });
        });
      }
    }
    const updatedTripTracker = tripTracker.clone();
    updatedTripTracker.onRoute = updatedTripTracker.onRoute ? updatedTripTracker.onRoute.concat(transits) : transits;
    return updatedTripTracker;
  }
  /**
   * Compare before and after trip trackers and returns updated trip trackers
   * @param oTrackers TripTracker[]
   * @param uTrackers TripTracker[]
   */
  static modifiedTrackers(oTrackers: TripTracker[], uTrackers: TripTracker[])
    : { oModTrackers: TripTracker[], uModTrackers: TripTracker[] } {
    if (!oTrackers || !uTrackers) {
      return null;
    }
    const uModTrackers: TripTracker[] = [];
    const oModTrackers: TripTracker[] = [];
    for (let i = 0; i < uTrackers.length; i++) {
      const u = uTrackers[i];
      const o: TripTracker = oTrackers.find(e => e.trackingNo === u.trackingNo);
      if (!o || isEmpty(o)) {
        uModTrackers.push(u);
        continue;
      }
      if (!!u.pickupTracker && !!u.deliveryTracker) {
        if (!o.pickupTracker || u.pickupTracker.activity.transactionStatus !== o.pickupTracker.activity.transactionStatus ||
          !o.deliveryTracker || u.deliveryTracker.activity.transactionStatus !== o.deliveryTracker.activity.transactionStatus
        ) {
          uModTrackers.push(u);
          oModTrackers.push(o);
        }
      }
      if (!!u.pickupTracker && !u.deliveryTracker) {
        if (!o.pickupTracker || u.pickupTracker.activity.transactionStatus !== o.pickupTracker.activity.transactionStatus) {
          uModTrackers.push(u);
          oModTrackers.push(o);
        }
      }
      if (!u.pickupTracker && !!u.deliveryTracker) {
        if (!o.deliveryTracker || u.deliveryTracker.activity.transactionStatus !== o.deliveryTracker.activity.transactionStatus) {
          uModTrackers.push(u);
          oModTrackers.push(o);
        }
      }
    }

    return { oModTrackers, uModTrackers };
  }
  /**
   * 
   * @param uTrackers updated Trackers from updated Trip
   * @param oTrackers orginal Trackers from db
   */
  static compareAndUpdateTripTrackers(uTrackers: TripTracker[], oTrackers: TripTracker[], tripId: string | number,
    updatedTrackingNos: string[], trackingNosDeleted: string[], trackingNosAdded: string[])
    : { updatedTrackers: TripTracker[]; newTrackers: TripTracker[]; } {
    const updatedTrackers: TripTracker[] = [];
    let newTrackers: TripTracker[] = [];
    // new trackers added by updated trip

    newTrackers = uTrackers.filter(f => trackingNosAdded.includes(f.trackingNo));
    // trackers deleted by updated trip
    const deletedTrackers = oTrackers.filter(f => trackingNosDeleted.includes(f.trackingNo));
    for (const d of deletedTrackers) {
      const o = oTrackers.find(f => f.trackingNo === d.trackingNo);
      if (o.pickupTracker.tripId === tripId && (!o.deliveryTracker || o.deliveryTracker?.tripId === tripId)) {
        d.tripTrackerStatus = TripTrackerStatus.expired;
      } else if (o.pickupTracker.tripId !== tripId && o.deliveryTracker.tripId === tripId) {
        d.deliveryTracker = null;
      } else {
        logger.error('unkown error');
      }
      updatedTrackers.push(d);
    }
    // trackers updated by updated trip
    uTrackers = uTrackers.filter(f => updatedTrackingNos.includes(f.trackingNo) &&
      oTrackers.findIndex(g => g.trackingNo === f.trackingNo) > -1);
    for (const ele of uTrackers) {
      const eleO = oTrackers.find(f => f.trackingNo === ele.trackingNo);
      // clone and clean to compare before after change
      const before = cleanupGenericProperties(eleO.clone());
      const after = cleanupUndefined(instanceToPlain(ele.clone()));
      if (!isEqual(after, before)) {
        // updated delivery only
        if (!ele.pickupTracker) {
          const updatedWithDelivery = eleO.clone().addDelivery(ele);
          updatedTrackers.push(updatedWithDelivery);
        } else {
          updatedTrackers.push(ele);
        }
      }
    }

    return { updatedTrackers, newTrackers };
  }
  /** update trip tracker called when trip status is update. note: not called when trip is updated by user where trip schedule changes
   * @param uTrip Updated Trip
   * @param oTrip Oginal Trip from dB
   * @summary uTrip and oTrip should have same schedule
    */
  updateTripTracker(uTrip: TripBase, oTrip: TripBase): TripTracker {
    const uTripTracker = this.clone();
    for (let i = 0; i < uTrip.tripSequence.length; i++) {
      const uT = <TripTransaction>instanceToPlain(uTrip.tripSequence[i]);
      const oT = <TripTransaction>instanceToPlain(oTrip.tripSequence[i]);
      if (!isEqual(uT, oT)) {
        if (uT.trackingNos && uT.trackingNos.includes(uTripTracker.trackingNo) && this.pickupTracker.tripId === uTrip.id) {
          uTripTracker.pickupTracker.activity = <Pickup>parseTripTransaction(uT);
        }
        if (uT.delTrackingNos && Object.keys(uT.delTrackingNos).includes(uTripTracker.trackingNo)
          && this.deliveryTracker.tripId === uTrip.id) {
          uTripTracker.deliveryTracker.activity = <Delivery>parseTripTransaction(uT);
        }
      }
    }
    return uTripTracker;
  }
  /**
   * adds delivery to trip tracker which has only pickup. Also also adds onRoute transactions
   * @param curr Current Trip which has delivery
   */
  addDelivery(curr: TripTracker): TripTracker {
    this.deliveryTracker = curr.deliveryTracker;
    // for update trip, orig triptracker will onRoute from the delivery trip. filter to get pickuponly onRoute, then add curr trip onRoute
    // const onRouteFromPickup = this.onRoute?.filter(f => f.tripId === this.pickupTracker.tripId);
    // this.onRoute = !this.onRoute ? curr.onRoute : onRouteFromPickup.concat(curr.onRoute);
    this.updateByTripId = curr.deliveryTracker.tripId;
    if (!isEqual(this.activeTrucks, curr.activeTrucks)) { this.activeTrucks = this.activeTrucks.concat(curr.activeTrucks); }
    if (!isEqual(this.activeTrailers, curr.activeTrailers)) { this.activeTrailers = this.activeTrailers.concat(curr.activeTrailers); }
    if (!isEqual(this.driverIds, curr.driverIds)) { this.driverIds = this.driverIds.concat(curr.driverIds); }
    return this;
  }
  /** remove trip reference and set tripTrackerStatus to expired.
   * @param oTrip orginal Trip
   */
  removeTripRef(oTrip: TripBase): TripTracker {
    const u = this.clone();
    if (!!u.pickupTracker && u.pickupTracker.tripId === oTrip.id) {
      u.pickupTracker = null;
    }
    if (!!u.deliveryTracker && u.deliveryTracker.tripId === oTrip.id) {
      u.deliveryTracker = null;
    }
    const updatedOnRoute = u.onRoute.filter(f => f.tripId !== oTrip.id);
    u.onRoute = updatedOnRoute;
    if (!u.pickupTracker && !u.deliveryTracker) {
      u.tripTrackerStatus = TripTrackerStatus.expired;
    }
    return u;
  }

  clone() {
    const t = instanceToInstance(this);
    t.sanitize();
    return t;
  }
  // sanitize() {
  // }

  /** returns task notification based on the transaction status */
  getNotificationMessage(oT?: TripTracker): { header: string, description: string, url: string, tno: string } {
    const uPStatus = !!this.pickupTracker ? this.pickupTracker.activity.transactionStatus : null;
    const oPStatus = !!oT && !!oT.pickupTracker ? oT.pickupTracker.activity.transactionStatus : null;
    const uDStatus = !!this.deliveryTracker ? this.deliveryTracker.activity.transactionStatus : null;
    const oDStatus = !!oT && !!oT.deliveryTracker ? oT.deliveryTracker.activity.transactionStatus : null;
    const r: { header: string, description: string, url: string, tno: string } = {} as any;
    r.tno = this.trackingNo;
    // tslint:disable-next-line:max-line-length
    const pAddress = !!this.pickupTracker ? `${this.pickupTracker.activity.address.addressFormattedL1}, ${this.pickupTracker.activity.address.city}` : null;
    // tslint:disable-next-line:max-line-length
    const dAddress = !!this.deliveryTracker ? `${this.deliveryTracker.activity.address.addressFormattedL1}, ${this.deliveryTracker.activity.address.city}` : null;
    switch (uPStatus) {
      case TransactionStatus.scheduled:
        if (!oT) {
          r.header = `Shipment Scheduled`;
          if (!!this.pickupTracker && !!this.deliveryTracker) {
            // tslint:disable-next-line:max-line-length
            r.description = `Shipment from ${pAddress} to ${dAddress} scheduled`;
          } else if (!!this.pickupTracker && !this.deliveryTracker) {
            r.description = `Pickup from ${pAddress} scheduled`;
          } else {
            r.description = `Deliver to ${dAddress} scheduled`;
          }
        }
        break;
      case TransactionStatus.arrived:
        if (oPStatus < TransactionStatus.arrived) {
          r.header = `Ready for pickup`;
          // tslint:disable-next-line:max-line-length
          r.description = `Driver arrived for the pickup at ${this.pickupTracker.activity.address.addressFormattedL1}, ${this.pickupTracker.activity.address.city}`;
        }
        break;
      case TransactionStatus.autoCompleted:
      case TransactionStatus.manuallyCompleted:
        if (oPStatus <= TransactionStatus.arrived) {
          r.header = `Pickup Completed`;
          // tslint:disable-next-line:max-line-length
          r.description = `Driver departed the pickup location ${this.pickupTracker.activity.address.addressFormattedL1}, ${this.pickupTracker.activity.address.city}`;
        }
        break;
      default:
        break;
    }
    r.url = `/track-delivery/list/${this.trackingNo}`;
    this.onRoute = !!this.onRoute ? this.onRoute : [];
    // onRpute message will overwrite pickup message if happens at the same time.
    for (const uR of this.onRoute) {
      const oR = oT.onRoute.find(f => isEqual(f.activity.address.geoLoc.geopoint, uR.activity.address.geoLoc.geopoint));
      switch (uR.activity.transactionStatus) {
        case TransactionStatus.arrived:
          if (oR.activity.transactionStatus < TransactionStatus.arrived) {
            r.header = `Arrived at transit terminal`;
            r.description = `Load arrived transit terminal at
            ${uR.activity.address.addressFormattedL1}, ${uR.activity.address.city}`;
          }
          break;
        case TransactionStatus.autoCompleted:
        case TransactionStatus.manuallyCompleted:
          if (oR.activity.transactionStatus <= TransactionStatus.arrived) {
            r.header = `Departed transit terminal`;
            r.description = `Driver departed the transit terminal
            ${uR.activity.address.addressFormattedL1}, ${uR.activity.address.city}`;
          }
          break;
        default:
          break;
      }
    }
    // delivery message will overwrite pickup and on Route message if happens at the same time
    switch (uDStatus) {
      case TransactionStatus.arrived:
        if (oDStatus < TransactionStatus.arrived) {
          r.header = `Ready for delivery`;
          // tslint:disable-next-line:max-line-length
          r.description = `Driver arrived at the delivery location ${this.deliveryTracker.activity.address.addressFormattedL1}, ${this.deliveryTracker.activity.address.city}`;
        }
        break;
      case TransactionStatus.autoCompleted:
      case TransactionStatus.manuallyCompleted:
        if (oDStatus <= TransactionStatus.arrived) {
          r.header = `Delivery Completed`;
          // tslint:disable-next-line:max-line-length
          r.description = `Driver departed the delivery location ${this.deliveryTracker.activity.address.addressFormattedL1}, ${this.deliveryTracker.activity.address.city}`;
        }
        break;
      default:
        break;
    }

    return r;
  }
  deletePod(oTrip: TripBase, seqIndex: number, picIndex: number) {
    if (this.deliveryTracker?.activity.pod && oTrip.tripSequence[seqIndex].pod) {
      if (picIndex == null) {
        this.deliveryTracker.activity.pod = null;
      } else {
        const pathInTrip = oTrip.tripSequence[seqIndex].pod[picIndex].path;
        const tTIndex = this.deliveryTracker.activity.pod.findIndex(f => f.path === pathInTrip);
        this.deliveryTracker.activity.pod.splice(tTIndex, 1);
      }
      return this;
    } else {
      return null;
    }
  }
  status() {
    if (!!this.deliveryTracker && !this.pickupTracker) {
      switch (this.deliveryTracker.activity.transactionStatus) {
        case TransactionStatus.scheduled:
          return 'Delivery scheduled'; // test#7
        case TransactionStatus.arrived:
          return `Delivering at, ${shortDateTime(this.deliveryTracker.activity.autoCompleteTimeTz.enteredAt)}`; // test#8
        case TransactionStatus.autoCompleted:
          return `Delivered, ${shortDateTime(this.deliveryTracker.activity.autoCompleteTimeTz.leftAt)}`; // test#9
        default:
          return null;
      }
    } else if (!this.deliveryTracker && !!this.pickupTracker) {
      switch (this.pickupTracker.activity.transactionStatus) {
        case TransactionStatus.scheduled:
          if (!!this.pickupTracker.activity.isApptApp &&
            isBefore(this.pickupTracker.activity?.eta, this.pickupTracker.activity.apptDateTime)) {
            return `ETA for pick ${shortDateTime(this.pickupTracker.activity.apptDateTimeTz)}`; // test#13
          } else {
            return 'Pick up scheduled'; // test#10
          }
        case TransactionStatus.arrived:
          return 'Arrived for pickup'; // test#11
        case TransactionStatus.autoCompleted:
          return `Picked up, ${shortDateTime(this.pickupTracker.activity.autoCompleteTimeTz.leftAt)}`; // test#12
        default:
          return null;
      }
    } else {
      // !!this.deliveryTracker && !!this.pickupTracker
      switch (this.deliveryTracker.activity.transactionStatus) {
        case TransactionStatus.scheduled:
          switch (this.pickupTracker.activity.transactionStatus) {
            case TransactionStatus.scheduled:
              // if pick is scheduled and there is pickup appoint and pickup ETA is before the appointment,
              // show pick completed and ETA for the delivery else show no status
              if (!!this.pickupTracker.activity.isApptApp &&
                isBefore(this.pickupTracker.activity.eta, this.pickupTracker
                  .activity.apptDateTime)) {
                return `ETA for pick ${shortDateTime(this.pickupTracker.activity.apptDateTimeTz)}`; // test#5a, test#5b
              } else {
                return 'Pick up scheduled'; // test#1
              }
            case TransactionStatus.arrived:
              return 'Arrived for pickup'; // test#1a
            case TransactionStatus.autoCompleted:
              // if picked up and there is delivery appoint and delivery ETA is before appointment, 
              // show pick completed and ETA for the delivery else show picked up
              if (!!this.deliveryTracker.activity.isApptApp &&
                isBefore(this.deliveryTracker.activity?.eta, this.deliveryTracker.activity.apptDateTimeTz)) {
                  // test#6a, test#6b
                return `Picked up, ${shortDateTime(this.pickupTracker.activity.autoCompleteTimeTz.leftAt)}. ETA for delivery ${shortDateTime(this.deliveryTracker.activity.apptDateTimeTz)}`;  // test#6a
              } else {
                return `Picked up, ${shortDateTime(this.pickupTracker.activity.autoCompleteTimeTz.leftAt)}`; // test#2
              }
            default:
              return null;
          }
        case TransactionStatus.arrived:
          return `Delivering at, ${shortDateTime(this.deliveryTracker.activity.autoCompleteTimeTz.enteredAt)}`; // test#3
        case TransactionStatus.autoCompleted:
          return `Delivered, ${shortDateTime(this.deliveryTracker.activity.autoCompleteTimeTz.leftAt)}`;  // test#4
        default:
          break;
      }

    }
    return null;
  }
}
