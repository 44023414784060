import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '../share-module/material.module';
import { RentalSearchComponent } from './rental-search.component';
import { AddressModule } from '../address/address.module';
import { MatSelectModule } from '@angular/material/select';
import { GooglePLacesAutoDirectiveModule } from '../../directive/google-places-auto.module';
import { MatRadioModule } from '@angular/material/radio';


@NgModule({
  declarations: [RentalSearchComponent],
  imports: [
    CommonModule,
    // AddressModule,
    GooglePLacesAutoDirectiveModule,
    MaterialModule,
    MatSelectModule,
    MatRadioModule


  ],
  exports: [
    RentalSearchComponent
  ]
})
export class SearchRentalModule { }
