
import { Length, IsInt, Min, validateSync, Max, IsDate, IsDecimal, IsNumber, ValidateNested, IsDefined, IsOptional, IsEnum, IsBoolean, Validate, ValidateIf, Matches, ValidatorOptions } from 'class-validator';
import { BaseModel } from '../base/base-model';
import { plainToInstance, Expose, Exclude, instanceToInstance, Type } from 'class-transformer';
import { sanitizeDate, sanitizeDateIPoint } from '../utility';
import { IValidationMsg } from '../error-handling';


@Exclude()
export class SOExcelSummary extends BaseModel {

    constructor() {
        super();
    }

    @Expose()
    vin: string;

    @Expose()
    make: string;
  
    @Expose()
    model: string;

    @Expose()
    engine: string;
  
    @Expose()
    year: number;

    @Expose()
    hp: string;
    
    @Expose()
    ratio: string;

    @Expose()
    holdDays: number;
    
    @Expose()
    arrivalDate: Date;

    @Expose()
    color: string;

    @Expose()
    odometer: string;

    @Expose()
    comments: string;

    @Expose()
    pictureStatus: string;

    @Expose()
    soldDate: Date;
    
    @Expose()
    proxyVin: string;
    
    public static parse(obj : any) {
        if (obj == null) { return null; }
        const m = plainToInstance<SOExcelSummary, any>(SOExcelSummary, sanitizeDateIPoint(obj));
        m.sanitize();
        return m;
    }

    validateSync(options? : ValidatorOptions): IValidationMsg {
        const r = this.validateSyncBase(this, options);
        return r;
    }

    clone() {
        const t = instanceToInstance(this);
        t.sanitize();
        return t;
    }

    sanitize() {
        // if data was recieved from firebase, date is stored as snapshot.
        super.sanitize();

        this.arrivalDate = sanitizeDate(this.arrivalDate);
        this.soldDate = sanitizeDate(this.soldDate);

    }

    createSOOriginalExcelSummary(so : any){
        this.vin = so.vin;
        this.make = so.make;
        this.model = so.model;
        this.year = so.year;
        this.color = so.color;
        this.odometer = so.odometer;
        this.comments = so.comments;
        this.pictureStatus = so.pictureStatus;
        if(so.soldDate) this.soldDate = sanitizeDate(new Date(so.soldDate));
        if(so.arrival) this.arrivalDate = sanitizeDate(new Date(so.arrival));
        this.engine = so.engine;
        this.hp = so.hp;
        this.ratio = so.ratio;
        this.proxyVin = so.proxyVin;
    }  

}
