
import { Length, IsInt, Min, validateSync, Max, IsDate, IsDecimal, IsNumber, ValidateNested, IsDefined, IsOptional, IsEnum, IsBoolean, Validate, ValidateIf, Matches, ValidatorOptions, isDefined, MinLength, IsEmail } from 'class-validator';
import { BaseModel } from '../base/base-model';
import { plainToInstance, Expose, Exclude, instanceToInstance, Type } from 'class-transformer';
import { sanitizeDateIPoint } from '../utility';
import { sanitizeDate, sanitizePhone } from '../utility/sanitize-helper';
import { IValidationMsg } from '../error-handling';
import { addDays, addYears, isDate, startOfDay } from 'date-fns';
import { Address } from '../address/address';
import { CreditRating, Frequency } from './legacy-contract-enum';
import { ProductSummary } from '../product/product-helper';
import { LegacyContractValidationGroup } from './legacy-contract-base';
import { sanitizeAsDecimal } from '../utility/math-helper';

const lcCustomerCompanySummary: LegacyContractValidationGroup = 'lcCustomerCompanySummary';


/**
 * Lease Summary model (Internally used in Legacy Contract model)
 */

@Exclude()
export class LCCustomerCompanySummary extends BaseModel {

    constructor() {
        super();
    }

    @Expose()
    @Length(4, 100, { message: 'Applicant needs to be $constraint1 chars and less than $constraint2 chars' , groups : [lcCustomerCompanySummary]})
    applicant: string;

    @Expose()
    @IsOptional()
    @ValidateIf(o => o.operatingName, {groups: [lcCustomerCompanySummary]})
    @Length(4, 100, { message: 'Operating name needs to be $constraint1 chars and less than $constraint2 chars' , groups : [lcCustomerCompanySummary]})
    operatingName: string;

    @Expose()
    @Length(2, 50, { message: 'First Name needs to be $constraint1 chars and less than $constraint2 chars', groups : [lcCustomerCompanySummary] })
    firstName: string;

    @Expose()
    @Length(2, 50, { message: 'Last Name needs to be $constraint1 chars and less than $constraint2 chars', groups : [lcCustomerCompanySummary] })
    lastName: string;

    // NE - Added fullName getter to fix name issue in maintenance list
    get fullName() {
        return `${this.firstName != undefined ? this.firstName : ''} ${this.lastName != undefined ? this.lastName : ''}`;
    }

    @Expose()
    @IsOptional()
    @ValidateIf(o => o.email, {groups: [lcCustomerCompanySummary]})
    @IsEmail({}, { message: 'Valid Email is required', groups: [lcCustomerCompanySummary] })
    @Length(5, 254, { message: 'Email must be $constraint1 - $constraint2 chars long', groups: [lcCustomerCompanySummary] })
    email: string;

    @Expose()
    @ValidateNested({ message: 'Address info is required', groups: [Address.gName, lcCustomerCompanySummary] })
    @IsDefined({ message: 'Address information is required', groups: [Address.gName, lcCustomerCompanySummary] })
    @Type(() => Address)
    address: Address;

    @Expose()
    @IsOptional()
    @ValidateIf(o => o.homePhone, {groups: [lcCustomerCompanySummary]})
    @MinLength(10, { message: 'Enter valid phone', groups: [lcCustomerCompanySummary] })
    homePhone: string;
    
    @Expose()
    @MinLength(10, { message: 'Enter valid phone', groups: [lcCustomerCompanySummary] })
    cellPhone: string;
    
    @Expose()
    @IsOptional()
    @ValidateIf(o => o.businessPhone, {groups: [lcCustomerCompanySummary]})
    @MinLength(10, { message: 'Enter valid phone', groups: [lcCustomerCompanySummary] })
    businessPhone: string;


    // credit rating type for ENUM/type for A, B, C, D
    @Expose()
    @IsDefined({ message: 'Credit Rating should be defined' , groups : [lcCustomerCompanySummary] })
    @IsEnum(CreditRating)
    creditRating: CreditRating = CreditRating.TBD;

    @Expose()
    @IsNumber()
    @Min(0, { message: 'Fico should be more than $constraint1 number', groups : [lcCustomerCompanySummary] })
    @Max(100000, { message: 'Fico should be less than $constraint1 number', groups : [lcCustomerCompanySummary] })
    fico: number;
  
    @Expose()
    @IsNumber()
    @Min(0, { message: 'BNI should be more than $constraint1 number', groups : [lcCustomerCompanySummary] })
    @Max(100000, { message: 'BNI should be less than $constraint1 number', groups : [lcCustomerCompanySummary] })
    bni: number;

    public static parse(obj : any) {
        if (obj == null) { return null; }
        const m = plainToInstance<LCCustomerCompanySummary, any>(LCCustomerCompanySummary, sanitizeDateIPoint(obj));
        m.sanitize();
        if(!m.address){
            m.address = new Address();
        }
        return m;
    }

    validateSync(options? : ValidatorOptions): IValidationMsg {
        const r = this.validateSyncBase(this, options);
        return r;
    }

    clone() {
        const t = instanceToInstance(this);
        t.sanitize();
        return t;
    }

    sanitize() {
        // if data was recieved from firebase, date is stored as snapshot.
        super.sanitize();
        this.homePhone = sanitizePhone(this.homePhone);
        this.cellPhone = sanitizePhone(this.cellPhone);
        this.fico = sanitizeAsDecimal(this.fico);
        this.bni = sanitizeAsDecimal(this.bni);
    }

    createLCCustomerCompanySummary(lc : any){

        this.applicant = lc.applicant;
        this.operatingName = lc.operatingName;
        this.firstName = lc.firstName;
        this.lastName = lc.lastName;
        this.email = lc.email;
        this.homePhone = (lc.homePhone == '(   )    -   0' || lc.homePhone == '() -') ? null : lc.homePhone;
        this.cellPhone = (lc.cellPhone == '(   )    -   0' || lc.cellPhone == '() -') ? null : lc.cellPhone;
        this.businessPhone = (lc.businessPhone == '(   )    -   0' || lc.businessPhone == '() -') ? null : lc.businessPhone;
        this.creditRating = lc.creditRating;
        this.fico = lc.fico ? sanitizeAsDecimal(lc.fico) : 0;
        this.bni = lc.bni ? sanitizeAsDecimal(lc.bni) : 0;
    }


   

}
