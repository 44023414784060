import { IPoint } from './product-search/interfaces';
export class LibTest {
  name: string;

}
/** Library Settings unique to angular / functions  */
export class LibSetting {

  // private static _mailerType: new (...args: any[]) => IEmailTemplate;


  private static _geoPointType: { new(...args: any[]): IPoint };
  public static get geoPointType() { return LibSetting._geoPointType; }


  /** call this functions to set the unique types for GeoPoint etc, depending upon weather lib is used from angular application
   * or functions.
 * @param geoType type to be used to decipher latitude/longitude. This is needed because at client the type is : firebase.firestore.GeoPoint
 * and at function it is admin.firestore.GeoPoint. admin firebase can not be added at client app. so this type will be provided
 * at run time.
 * https://github.com/Microsoft/TypeScript/wiki/FAQ#why-cant-i-write-typeof-t-new-t-or-instanceof-t-in-my-generic-function
 */
  public static setTypes<T extends IPoint>(geoType?: { new(...args: any[]): T }) {
    LibSetting._geoPointType = geoType;
  }

}
