import { Exclude, Expose, plainToInstance } from 'class-transformer';
import { IsEnum, IsInt, IsNumber, ValidatorOptions, validateSync, IsBoolean, ValidateIf } from 'class-validator';
import { IValidationMsg } from '../error-handling/validation-info';
import { logger } from '../log/logger';
import { sanitizeDateIPoint } from '../utility/sanitize-helper';
import { toValidationError } from '../utility/validation-helper';
import { MileageType } from './milage-type';
import { RentalTerm } from './rental-term';
import { add, differenceInDays, sub, isAfter } from 'date-fns';
import { NegoTermsBase } from '../nego-terms-base/nego-terms-base';
import { round } from '../utility';

export type RentalPlanValidationGroup = 'isSelectedG';
const isSelectedG: RentalPlanValidationGroup = 'isSelectedG';
const rPlanG = 'rPlanG';
@Exclude()
export class RentalPlan {



  constructor() {

  }
  public static readonly rPlanG = rPlanG;
  @Expose()
  @ValidateIf(o => !!o.isSelected, {groups: [rPlanG]})
  @IsEnum(MileageType)
  mileageType: MileageType;

  @Expose()
  @ValidateIf(o => !!o.isSelected, {groups: [rPlanG]})
  @IsNumber(undefined, {groups: [rPlanG]})
  ratePerDay: number;

  @Expose()
  @ValidateIf(o => o.mileageType !== MileageType.unlimited && !!o.isSelected)
  @IsNumber(undefined, {groups: [rPlanG]})
  ratePerMile: number;

  @Expose()
  @ValidateIf(o => (o.mileageType !== MileageType.unlimited && o.mileageType !== MileageType.flexible) && !!o.isSelected)
  @IsInt({groups: [rPlanG]})
  milage: number;

  /** no. of days term */
  @Expose()
  @ValidateIf(o => !!o.isSelected, {groups: [rPlanG]})
  @IsEnum(RentalTerm, {groups: [rPlanG]})
  rentalTerm: RentalTerm;

  @Expose()
  @IsBoolean({groups: [rPlanG]})
  isAvailable: boolean;
  @Expose()
  @IsBoolean({ groups: [isSelectedG] })
  isSelected: boolean;


  public static getPlanByTerm(rentalTerm: RentalTerm) {
    const p = new RentalPlan();
    p.rentalTerm = rentalTerm;
    return p;
  }
  public static initPlans(): RentalPlan[] {
    const plans: RentalPlan[] = [];
    for (const item in RentalTerm) {
      if (!isNaN(+item)) {
        const p1 = new RentalPlan();
        p1.rentalTerm = +item;
        p1.mileageType = MileageType.minimum;
        p1.isSelected = false;
        plans.push(p1);
      }
    }

    return plans;
  }

  public static parseArray = (obj: any[]): RentalPlan[] => {
    const r = !!obj ? obj.map(o => RentalPlan.parse(o)) : null;
    return r;
  };
  public static parse(obj) {
    // public static parse<T extends IPoint>(obj, ipointType?: { new(...args: any[]): T }) {
    try {
      if (obj == null) { return null; }
      // obj = sanitizeDateIPoint(obj, ipointType);
      obj = sanitizeDateIPoint(obj);
      const m = plainToInstance<RentalPlan, any>(RentalPlan, obj);
      m.sanitize();
      if (m.mileageType === MileageType.flexible) {
        m.milage = undefined;
      }
      if (m.mileageType === MileageType.unlimited) {
        m.milage = undefined;
        m.ratePerMile = undefined;

      }

      return m;
    } catch (error) {
      logger.log('Error happened during parse', error);
      return null;
    }
  }
  /** @deprecated */
  public static getApplicablePlan(rentalPlans: RentalPlan[], negoTerm: NegoTermsBase): RentalPlan {
    const days = differenceInDays(negoTerm.endDate, negoTerm.startDate);
    const plans = rentalPlans.filter(f => f.rentalTerm <= days && f.isAvailable && f.isSelected);
    const p = plans.reduce((prev, next) => prev.rentalTerm > next.rentalTerm ? prev : next);
    return RentalPlan.parse(p);
  }
  public static getApplicablePlanAssigned(rentalPlans: RentalPlan[]): RentalPlan {
    const p = rentalPlans.find(f =>  f.isAvailable && f.isSelected);
    return RentalPlan.parse(p);
  }

  isStillValid(endDate: Date): boolean {
    const availableDays = differenceInDays(endDate, new Date());
    return availableDays >= this.rentalTerm;
  }

  get isAvailSlectString() {
    if (this.isAvailable) {
      return this.isSelected ? 'Selected' : 'Avaialble to Select';
    } else {
      return undefined;
    }
  }
  get termString() {

    switch (this.rentalTerm) {
      case RentalTerm.daily:
        return 'Daily';
      case RentalTerm.weekly:
        return 'Weekly';
      case RentalTerm.monthly:
        return 'Monthly';
      case RentalTerm.threeMonth:
        return 'Three Month';
      case RentalTerm.sixMonth:
        return 'Six Month';
      case RentalTerm.oneYear:
        return 'One Year';

      default:
        throw new Error(`${this.rentalTerm} not supported`);
    }
  }
  /** e.g. month(30 days)*/
  get termString2() {

    switch (this.rentalTerm) {
      case RentalTerm.daily:
        return 'day';
      case RentalTerm.weekly:
        return 'week(7 days)';
      case RentalTerm.monthly:
        return 'month(30 days)';
      case RentalTerm.threeMonth:
        return 'three Months (90 days)';
      case RentalTerm.sixMonth:
        return 'six Month (120 days)';
      case RentalTerm.oneYear:
        return 'year (360 days)';

      default:
        throw new Error(`${this.rentalTerm} not supported`);
    }
  }
  get mileageString() {
    const s = this.getMinMaxMileage();
    switch (this.rentalTerm) {
      case RentalTerm.daily:
        return s + ' Daily Milage';
      case RentalTerm.weekly:
        return s + ' Weekly Milage';
      case RentalTerm.monthly:
      case RentalTerm.threeMonth:
      case RentalTerm.sixMonth:
      case RentalTerm.oneYear:
        return s + ' Monthly Milage';


      default:
        throw new Error(`${this.rentalTerm} not supported`);
    }
  }


  validateSync(options?: ValidatorOptions): IValidationMsg {
    const r = this.validateSyncBase(this, options);
    if ((this.mileageType === MileageType.maximum || this.mileageType === MileageType.minimum) && !!this.isSelected) {
      // if (!this.milage) {
      //   r['milage'] = ['mileage needs to be defined'];
      // }
    }
    // const m = toValidationError(r);

    return r;
  }
  // managing the release status.
  public validateSyncBase($this: any, options?: ValidatorOptions): IValidationMsg {
    const r = validateSync($this, options);
    const m = toValidationError(r);
    return m;
  }
  sanitize() {
    // if data was received from firebase, date is stored as snapshot.
    // this.createdAt = sanitizeDate(this.createdAt);
    // this.updatedAt = sanitizeDate(this.updatedAt);
    // return this;
  }
  get mileageString2() {
    const s = this.getMinMaxMileage();
    switch (this.rentalTerm) {
      case RentalTerm.daily:
        return 'day';
      case RentalTerm.weekly:
        return 'week';
      case RentalTerm.monthly:
      case RentalTerm.threeMonth:
      case RentalTerm.sixMonth:
      case RentalTerm.oneYear:
        return 'month';


      default:
        throw new Error(`${this.rentalTerm} not supported`);
    }
  }
  getMinMaxMileage(): string {
    switch (this.mileageType) {
      case MileageType.maximum:
        return 'Maximim';
      case MileageType.minimum:
        return 'Minimum';
      case MileageType.flexible:
      case MileageType.unlimited:
        return '';

      default:
        throw new Error(`${this.mileageType} not supported`);
    }
  }
  getStar() {
    switch (this.mileageType) {
      case MileageType.maximum:
      case MileageType.minimum:
        return '*';
      default:
        return '';
    }
  }
  /** @deprecated */
  getMinMilageAmount(): number {
    if (this.mileageType === MileageType.minimum) {
      return this.milage * this.ratePerMile;
    } else {
      return 0;
    }
  }
  daysInDefaultBillingCycle() {
    switch (this.rentalTerm) {
      case RentalTerm.daily:
        return RentalTerm.daily;
      case RentalTerm.weekly:
        return RentalTerm.weekly;
      case RentalTerm.monthly:
      case RentalTerm.threeMonth:
      case RentalTerm.sixMonth:
      case RentalTerm.oneYear:
        return RentalTerm.monthly;

      default:
        throw new Error(`Invalid rental term type${RentalPlan[this.rentalTerm]} does not exist`);

    }
  }

  calculateMilageAmount(milage: number, daysOnDailyTerm?: number) {
    const minMaxMileage = this.rentalTerm === RentalTerm.daily ? daysOnDailyTerm * this.milage : this.milage;
    switch (this.mileageType) {
      case MileageType.flexible:
        return milage; // this.ratePerMile * milage;

      case MileageType.maximum:
        // Only additional milage over the limit will be chared.
        return (milage > minMaxMileage) ? round((milage - minMaxMileage),0) : 0; // this.ratePerMile *

      case MileageType.minimum:
        if (milage > minMaxMileage) {
          return round((milage - minMaxMileage), 0); //  * this.ratePerMile;
        } else {
          return 0;
        }

      case MileageType.unlimited:
        return 0;
      default:
        throw Error(`Invalid MilageType ${this.mileageType} (${MileageType[this.mileageType]}) was provided! for calculating the milage amount! `)
    }
  }
  getPaymentString(): string {
    let s1: string = this.mileageType !== MileageType.unlimited  ?`+ $${this.ratePerMile}/mile`: '';
    let s2: string;
    switch (this.mileageType) {
      case MileageType.minimum:
        s2 = `${this.getMinMaxMileage()} ${this.milage} miles per ${this.termString2}. ${this.getMileageString(false)}.`;
        break;
      case MileageType.maximum:
        s2 = `${this.milage} miles per ${this.termString2} included in the rent. ${this.getMileageString(false)}.`;
        break;
    
      default:
        s2= '.';
        break;
    }

    return `$${this.ratePerDay} per day ${s1} ${s2}`;
  }
  getMileageString(showStar = true) {
    const s = !!showStar ? '*' : '';
    switch (this.mileageType) {
      case MileageType.minimum:
      case MileageType.maximum:
        return `${s}Mileage above ${this.milage} per ${this.termString2} will be charged every billing cycle at $${this.ratePerMile}/mile`;
      case MileageType.flexible:
        return `${s}Mileage will be charge every billing cycle at $${this.ratePerMile}/mile`;
      case MileageType.unlimited:
        return undefined;
      default:
        throw new Error(`${this.mileageType} not applicable`);

    }
  }
  

}
