import { PromoListBase, ListValidationGroup } from './promo-list-base';
import { Exclude, Expose, plainToInstance, instanceToInstance, instanceToPlain } from 'class-transformer';
import { IsDefined, ValidatorOptions, Validator, Length, validateSync, IsBoolean, ValidateIf, ValidationError, minLength, maxLength, MaxLength } from 'class-validator';
import { sanitizeDateIPoint, toValidationError } from '../utility';
import { PromoListType } from './promo-list-type';
import { IValidationMsg, mergeValidation } from '../error-handling/validation-info';
import { PromoListItem, ItemValidationGroup } from './promo-list-item';
import { ItemStatus } from './item-status';
import { TripBase } from '../trip/trip-base';
import { isEmpty, pickBy, pick, intersection, omit, isEqual } from 'lodash';
import { TripStatus } from '../trip/trip-status';
import { TripActivityType } from '../trip/trip-activity-type';
import { CarrierCompanySummary } from '../company/company-carrier';
import { MessageInfo } from '../error-handling';
import { TransactionStatus } from '../trip/transaction-status';
import { Address } from '../address/address';
import { Vehicle } from '../product/vehicle';
import { DbStatus } from '../base/db-status';
import { isAfter } from 'date-fns';
import { Trailer } from '../product/trailer';
import { DbRule } from '../base/db-rule';
import { ProductType } from '../product/product-type';
import { CompanyBase, CompanySummaryBase } from '../company/company-base';

const valueValidition: ItemValidationGroup = 'valueValidition';
const keyValidition: ItemValidationGroup = 'keyValidition';


@Exclude()
export class VehicleListItem extends PromoListItem {

  constructor() {
    super();
    this.itemStatus = ItemStatus.active;
  }

  @Expose({ toClassOnly: true })
  @Length(1, 10, { message: 'needs to be $constraint1-$constraint2 chars', groups: [keyValidition] })
  @IsDefined()
  key: string;  // this is dictionary key, added in values for ui validation, saved in db as key


  @Expose()
  @Length(2, 100, { message: 'description needs to be $constraint1-$constraint2 chars', groups: [valueValidition] })
  @IsDefined()
  description: string;

  @Expose()
  // @Length(0, 20, { message: 'gps SNo. needs to be $constraint1-$constraint2 chars', groups: [valueValidition] })
  @MaxLength(20, { message: 'gps SNo. needs to be $constraint1 chars', groups: [valueValidition] })
  // @IsDefined()
  iotId: string;

  @Expose()
  @IsBoolean()
  @ValidateIf(v => v.key === 'TBD')
  isPlaceHolder: boolean;

  @Expose()
  openTrip?: { [key: string]: { description: string, updatedAt: Date } };
  // openTrip?: string | number;

  /** tracking no of pending delivery after trip is closed, updated at fns when trip with op */
  @Expose()
  pendingDelivery: { [key: string]: { pickedFrom: string; pickedAt: Date; dropedAt: Address } };

  /** product ID added at the functions when vehicle has associated Product (doc in product collection) */
  @Expose()
  pid?: string | number;

  @Expose()
  vin?: string;

  /**  product dB Status added at the functions has to be in sync with dBStatus of associated Product (doc in product collection) */
  @Expose()
  prodDbStatus?: number;

  /**  product safety expiry set by product (actual truck or trailer) to  in sync with safetyExpiryDate of associated Product
   *  (doc in product collection) */
  @Expose()
  safetyExpiryDate?: Date;

  @Expose()
  unitName?: string;

  @Expose()
  make?: string;

  /**  is Veh ready to rent. */
  get isRentable(): boolean {
    if (this.prodDbStatus === DbStatus.Released && isAfter(this.safetyExpiryDate, new Date())) {
      return true;
    } else {
      return false;
    }
  }
  @Expose({ toClassOnly: true })
  get uiDescription() {
    return `${this.vin}, ${this.unitName} `;
  }

  public static parse(obj) {
    if (obj == null) { return null; }
    const m = plainToInstance<VehicleListItem, any>(VehicleListItem, sanitizeDateIPoint(obj));
    m.sanitize();
    return m;
  }
  clone() {
    const t = instanceToInstance(this);
    t.sanitize();
    return t;
  }
  sanitize() {

  }
  validateSyncGroup(group?: ItemValidationGroup): IValidationMsg {
    return this.validateSync({ groups: [group] });
  }
  validateSync(options?: ValidatorOptions, list?: VehicleList): IValidationMsg {
    const r = validateSync(this, options);
    const m = toValidationError(r);
    if (!!list && !!list.isDuplicate(this.key)) {
      m['key'] = [`duplicate entry ${this.key}`];
    }
    return m;
  }
}
const selectMode: ListValidationGroup = 'selectMode';
const addMode: ListValidationGroup = 'addMode';
const updateMode: ListValidationGroup = 'updateMode';

/** List of Vehicles registered with carrier promo */
@Exclude()
export class VehicleList extends PromoListBase {


  constructor() {
    super();
  }
  @Expose()
  @IsDefined({ message: 'list needs to be defined', groups: [addMode] })
  list: { [key: string]: VehicleListItem } = {};  // binds to list in dB

  // @Expose()
  // @ValidateNested({ message: 'info is required', groups: [selectMode, PromoListItem.gName] })
  // @Type(() => VehicleListItem)
  @IsDefined({ message: 'needs to be defined', groups: [selectMode] })
  entry: { [key: string]: VehicleListItem }; // binds to selected item

  // @IsDefined({ message: 'vehicle number needs to be defined', groups: [addMode] })
  key: string; // binds to new item key to be added

  @Expose()
  carrierCompanySummary?: CarrierCompanySummary;
  @Expose()
  companySummary: CompanySummaryBase;
  // uploadItem: any; // added to remove prod build error to be checked

  public static parse(obj) {
    if (obj == null) { return null; }
    const m = plainToInstance<VehicleList, any>(VehicleList, sanitizeDateIPoint(obj));
    m.sanitize();
    for (const key in m.list) {
      if (m.list.hasOwnProperty(key)) {
        const element = m.list[key];
        m.list[key] = VehicleListItem.parse(m.list[key]);
        element.key = !element.key ? key : element.key;
        if (element.pendingDelivery) {
          for (const k in element.pendingDelivery) {
            if (element.pendingDelivery.hasOwnProperty(k)) {
              const ele = element.pendingDelivery[k];
              ele.dropedAt = Address.parse(ele.dropedAt);

            }
          }
        }
        switch (m.listType) {
          case PromoListType.rentalTrailer:
            m.list[key] = VehicleListItem.parse(element);
            break;
          case PromoListType.rentalTruck:
            m.list[key] = VehicleListItem.parse(element);
            break;

          default:
            break;
        }


      }
    }
    return m;
  }
  static createPromoList(cid: number | string, pLType: PromoListType,
    carrierCSummary: CarrierCompanySummary, entry?: { [key: string]: VehicleListItem }): VehicleList {
    let r = new VehicleList();
    r.cid = cid;
    // r.list = entry;
    r.listType = pLType;
    r.carrierCompanySummary = carrierCSummary;
    r = r.updateList(r.addTBD());
    if (entry) {
      r = r.updateList(entry);
    }
    return r;
  }
  /** adds to dictionary or edits the dictionary
 * does not check for duplicate key
 * duplicate key is checked in validation before calling this function
 * @param entry: new dictionary key and value
*/
  updateList(entry: { [key: string]: VehicleListItem }): VehicleList {
    const k = Object.keys(entry)[0];
    if (!entry[k].iotId) {
      entry[k].iotId = `placeholder ${entry[k].key}`;
    }
    if (!!k) {
      this.list[k] = <any>instanceToPlain(entry[k]);
      return this;
    } else {
      return null;
    }
  }
  addTBD(): { [key: string]: VehicleListItem } {
    const d = new VehicleListItem();
    d.description = 'TBD';
    d.iotId = 'TBD';
    d.itemStatus = ItemStatus.active;
    d.isPlaceHolder = true;
    const t: { [key: string]: VehicleListItem } = {};
    t['TBD'] = d;
    return t;
  }
  validateSyncGroup(group: ListValidationGroup, orig?: VehicleList): IValidationMsg {
    return this.validateSync({ groups: [group] }, orig);
  }
  validateSync(options?: ValidatorOptions, orig?: VehicleList): IValidationMsg {
    const validate = new Validator();
    // for nested entry, add group in it.
    if (!!options && !!options.groups && options.groups.indexOf(selectMode) > -1) {
      options.groups.push(selectMode);
    }
    const r = super.validateSync(options);

    // const r = this.validateSyncBase(this, options);
    if (!!this.key && (!minLength(this.key, 1) || !maxLength(this.key, 10))) {
      r['entry'] = ['vehicle name should be 1 to 10 characters'];
    }
    if (!!this.list && !!this.checkDuplicate() && this.checkDuplicate().length > 0) {
      r['entry'] = [` ${this.checkDuplicate().length} duplicate entry in the list, ${this.checkDuplicate()[0]}`];
    }
    if (!!this.list && !!this.entry && options.groups.includes(addMode) && !!orig.isDuplicate(Object.keys(this.entry)[0])) {
      r['entry'] = [` ${Object.keys(this.entry)[0]} duplicate entry in the list`];
    }
    if (!!this.list && !!this.checkDuplicateIot() && this.checkDuplicateIot().length > 0) {
      r['entry'] = [` ${this.checkDuplicateIot().length} duplicate GPS SNo.(s) in the list, ${this.checkDuplicateIot()[0]}`];
    }
    for (const key in this.list) {
      if (this.list.hasOwnProperty(key)) {
        const element = VehicleListItem.parse(instanceToPlain(this.list[key]));
        element.key = key;
        const sr = element.validateSyncGroup();
        if (Object.keys(sr).length > 0) {
          mergeValidation(r, sr, `${key}`, `${key}`);
        }
      }
    }
    return r;
  }
  clone() {
    const t = instanceToInstance(this);
    t.sanitize();
    return t;
  }
  /** Depreciated
   * adds to dictionary
   * does not check for duplicate, if key exists it will update the values
   * duplicate key is checked in validation before calling this function
    */
  addNewEntry(newEntry: { [key: string]: VehicleListItem }): VehicleList | null {
    const k = Object.keys(newEntry)[0];
    const v = Object.values(newEntry)[0];
    this.list[`${k}`] = v;
    return this;
  }
  isDuplicate(v: string): boolean {
    const a = Object.keys(this.list);
    return !!a.includes(v) ? true : false;
  }
  /** create new Entry
   * @param v: Dictionary Item key
   * @param manualInit: when manully Initialized (true); when auto Intialized (false)
   * @param description: Vehicle description, reequired when manually intialized
   * @param iotId: GPS SNo., reequired when manually intialized
   * @param return dictionary item
   */
  createNewEntry(v: string, description?: string, iotId?: string, itemStatus?: ItemStatus)
    : { [key: string]: VehicleListItem } {
    let c: { [key: string]: VehicleListItem };
    const des = !!description ? description : v;
    const iId = !!iotId ? iotId : `gps${v}`;
    const iStatus = !!itemStatus ? itemStatus : ItemStatus.active;
    c = <any>{ [v]: { description: des, iotId: iId, itemStatus: iStatus } };
    return c;
  }
  activateRecord(activateItem: string): { [key: string]: VehicleListItem; } {
    const a = pickBy(this.list, (value, key) => key === activateItem);
    a[activateItem].itemStatus = ItemStatus.active;
    return a;
  }
  // cannot have duplicate keys should be removed TODO: SS
  checkDuplicate() {
    let duplicates: string[] = [];
    const findDuplicates = arr => arr.filter((item, index) => arr.indexOf(item) !== index);
    duplicates = !!this.list && Object.keys(this.list).length > 0 ? findDuplicates(Object.keys(this.list)) : null;
    return duplicates;
  }
  checkDuplicateIot() {
    let duplicates: string[] = [];
    const iotSNos: (string | number)[] = [];
    const findDuplicates = arr => arr.filter((item, index) => arr.indexOf(item) !== index);
    for (const key in this.list) {
      if (this.list.hasOwnProperty(key)) {
        const element = this.list[key];
        iotSNos.push(element.iotId);
      }
    }
    duplicates = !!this.list && iotSNos.length > 0 ? findDuplicates(iotSNos) : null;
    return duplicates;
  }
  /**
 * update truck/trailer list to add blocking trip id
 * @param trip TripBase
 * @param newTripId trip id reserved for trip create
 */
  assignToTrip(trip: TripBase, newTripId: string | number): VehicleList {
    const updatedList = this.clone();
    switch (this.listType) {
      case PromoListType.promoTruck:
        trip.activeTrucks.forEach(e => {
          if (!updatedList.list[e].isPlaceHolder) {
            updatedList.list[e].openTrip = {};
            updatedList.list[e].openTrip[newTripId] = { description: trip.tripDescription, updatedAt: trip.startDate };
          }
        });

        break;
      case PromoListType.promoTrailer:
        trip.activeTrailers.forEach(e => {
          if (!updatedList.list[e].isPlaceHolder) {
            updatedList.list[e].openTrip = {};
            updatedList.list[e].openTrip[newTripId] = { description: trip.tripDescription, updatedAt: trip.startDate };
          }
          // if (!isEmpty(trip.pendingDeliveriesAfterDrop)) {
          //   for (const k in trip.pendingDeliveriesAfterDrop) {
          //     if (trip.pendingDeliveriesAfterDrop.hasOwnProperty(k)) {
          //       const ele = trip.pendingDeliveriesAfterDrop[k];
          //       if (!updatedList.list[e].isPlaceHolder) {
          //         updatedList.list[e].pendingDelivery = ele;
          //       }
          //     }
          //   }
          // }
        });
        if (!isEmpty(trip.openPickupTrackingNos)) {
          for (const k in trip.openPickupTrackingNos) {
            if (trip.openPickupTrackingNos.hasOwnProperty(k)) {
              const ele = trip.openPickupTrackingNos[k];
              if (!updatedList.list[ele.pickupTrailer].isPlaceHolder) {
                const d = trip.tripSequence.find(f => f.activity === TripActivityType.drop && f.trailer === ele.pickupTrailer);
                updatedList.list[ele.pickupTrailer].pendingDelivery = {};
                updatedList.list[ele.pickupTrailer].pendingDelivery[k] = {
                  pickedFrom: ele.pickedFrom, pickedAt: ele.pickedAt,
                  dropedAt: d?.address
                };
              }
            }
          }
        }
        break;

      default:
        break;
    }
    return updatedList;
  }
  /**
   * Un assigns trips for the trucks and trailers released.
   * @param vReleased Trucks/Trailer released
   * @param listType List Type
   */
  unAssignTrip(vReleased: string[], listType: PromoListType): void {
    for (const v of vReleased) {
      switch (listType) {
        case PromoListType.promoTruck:
          this.list[v].openTrip = null;
          break;
        case PromoListType.promoTrailer:
          this.list[v].openTrip = null;
          this.list[v].pendingDelivery = null;
          break;
        default:
          break;
      }
    }
  }
  clearPendingDelivery(trailerDeliveryCleared: string[]) {
    for (const t of trailerDeliveryCleared) {
      this.list[t].pendingDelivery = null;
    }
  }

  /**
   * assign trips to trucks and trailer based trucks/trailer released either during the trip completion
   * or when trip is updated to change trucks. trilers. Also add pending delivry to trailer where only pick is completed for tracking number
   * @param uTrip updated Trip
   * @param vReleased vehciles released, when portion of trip is completed(trailer is dropped) or trip is modified to change trucks/trailers
   */
  assignToTripIncr(uTrip: TripBase, vReleased: string[], trailerDeliveryCleared: string[]): void {
    let trucksInUse: string[] = [];
    let trailersInUse: string[] = [];
    switch (this.listType) {
      case PromoListType.promoTruck:
        // find trucks in use by filtering out ones released
        trucksInUse = uTrip.activeTrucks.filter(f => !vReleased.includes(f));
        // assign trip to inuse trucks
        trucksInUse.forEach(e => {
          if (!this.list[e].isPlaceHolder) {
            this.list[e].openTrip = {};
            this.list[e].openTrip[uTrip.id] = { description: uTrip.tripDescription, updatedAt: uTrip.startDate };
          }
        });
        break;
      case PromoListType.promoTrailer:
        // find trailers in use by filtering out ones released
        trailersInUse = uTrip.activeTrailers.filter(f => !vReleased.includes(f));
        trailersInUse.forEach(e => {
          // assign trip to inuse trailers
          if (!this.list[e].isPlaceHolder) {
            this.list[e].openTrip = {};
            this.list[e].openTrip[uTrip.id] = { description: uTrip.tripDescription, updatedAt: uTrip.startDate };
          }
        });
        // add pending delivery to the trailer
        // if (!isEmpty(uTrip.pendingDeliveriesAfterDrop)) {
        //   for (const k in uTrip.pendingDeliveriesAfterDrop) {
        //     if (uTrip.pendingDeliveriesAfterDrop.hasOwnProperty(k)) {
        //       const ele = uTrip.pendingDeliveriesAfterDrop[k];
        //       if (!this.list[k].isPlaceHolder) {
        //         this.list[k].pendingDelivery = ele;
        //       }
        //     }
        //   }
        // }
        // refrence to pending delivery
        if (!isEmpty(uTrip.openPickupTrackingNos)) {
          for (const k in uTrip.openPickupTrackingNos) {
            if (uTrip.openPickupTrackingNos.hasOwnProperty(k)) {
              const ele = uTrip.openPickupTrackingNos[k];
              if (!this.list[ele.pickupTrailer].isPlaceHolder && !trailerDeliveryCleared.includes(ele.pickupTrailer)) {
                const d = uTrip.tripSequence.find(f => f.activity === TripActivityType.drop && f.trailer === ele.pickupTrailer);
                // tslint:disable-next-line:max-line-length
                this.list[ele.pickupTrailer].pendingDelivery = this.list[ele.pickupTrailer].pendingDelivery ? this.list[ele.pickupTrailer].pendingDelivery : {};
                this.list[ele.pickupTrailer].pendingDelivery[k] = {
                  pickedFrom: ele.pickedFrom, pickedAt: ele.pickedAt,
                  dropedAt: d.address
                };
              }
            }
          }
        }
        // if pending deliveries are removed in the updated trip, then remove from the reference from the trailer
        // if there are openPickupTrackingNos
        if (!uTrip.openPickupTrackingNos || isEmpty(uTrip.openPickupTrackingNos)) {
          uTrip.activeTrailers.forEach(g => {
            this.list[g].pendingDelivery = null;
          });
        } else {
          // openPickupTrackingNos are only for one or trailers
          for (const key in uTrip.openPickupTrackingNos) {
            if (uTrip.openPickupTrackingNos.hasOwnProperty(key)) {
              const element = uTrip.openPickupTrackingNos[key];
              uTrip.activeTrailers.forEach(g => {
                if (this.list[g].pendingDelivery && !pickBy(uTrip.openPickupTrackingNos, (val) => val.pickupTrailer === g)) {
                  this.list[g].pendingDelivery = null;
                }
              });
            }
          }
        }
        break;

      default:
        break;
    }
  }


  pendingDeliveryFromTripToTrailer(trailer: string, trip: TripBase): { [key: string]: { pickedFrom: string; } } {
    const p: { [key: string]: { pickedFrom: string; } } = {};
    for (const key in trip.pendingDeliveriesPreviousTrip) {
      if (trip.pendingDeliveriesPreviousTrip.hasOwnProperty(key)) {
        const ele = trip.pendingDeliveriesPreviousTrip[key];
        if (ele.pickupTrailer === trailer) {
          p[key] = { pickedFrom: ele.pickedFrom };
        }
      }
    }
    return p;
  }
  /** transform list from dB no change required for vehcile list */
  get listForUI(): { [key: string]: VehicleListItem; } {
    return this.list;
  }
  canEmptyTrailer(trips: TripBase[]): boolean {
    for (const trip of trips) {
      if (trip.tripStatus < TripStatus.closed) {
        return false;
      }
    }
    return true;
  }
  errorCannotEmptyTrailer(tno: string): IValidationMsg {
    const r: ValidationError[] = [];
    const m = toValidationError(r);
    m['tno'] = [`Cannot Empty, there is an open trip against tracking # ${tno}. Close the trip before removing delivery reference`];
    // const m = new MessageInfo();
    // m.header = `Cannot Empty`;
    // m.msgCss = 'warn';
    // m.description = `There is open trip against tracking no(${tno}). Close trip before removing delivery reference`;
    // m.list = [];
    // return { messageInfo: m };
    return m;
  }
  emptyTrailer(trailer: string, tnos: string[]): VehicleList | null {
    if (!this.list[trailer] || !this.list[trailer].pendingDelivery) {
      return null;
    }
    for (const tno of tnos) {
      if (Object.keys(this.list[trailer].pendingDelivery).includes(tno)) {
        this.list[trailer].pendingDelivery = null;
      } else {
        return null;
      }
    }
    return this;
  }
  isOpen(activeVehs: string[], trip: TripBase): { isOpen: boolean, veh?: string } {
    for (const v of activeVehs) {
      if (this.list[v].openTrip && !Object.keys(this.list[v].openTrip).includes(`${trip.id}`)) {
        return { isOpen: false, veh: v };
      }
    }
    return { isOpen: true };
  }
  /** UI Helper, truck/trailer not avialble for trip */
  errorNotAvaialble(veh: string): IValidationMsg {
    const r: ValidationError[] = [];
    const m = toValidationError(r);
    m[`${veh}`] = [`${veh} has open trip ${Object.values(this.list[veh].openTrip)[0].description}. Close open trip before assgining new trip`];
    return m;
  }
  /** Add New Product reference when place product is created
   * @param newKey | string
   * @param pid | string
  */
  addNewProductRef(newKey: string, veh: Vehicle) {
    this.list[newKey].pid = veh.id;
    this.list[newKey].prodDbStatus = veh.dbStatus;
  }
  /**
   *
   * @param uVeh Vehicle Truck | Trailer
   * @param pid Product id
   */
  updateVehListWhenProdUpdate(uVeh: Vehicle, oVeh: Vehicle): VehicleList {
    // agreegate list only store root ids
    const pid = DbRule.getRootId(oVeh.id);
    const uVehList = this.clone();
    if (uVeh.unitName === oVeh.unitName) {
      const item: { [key: string]: VehicleListItem } = pickBy(uVehList.list, (val) => !!val.pid && val.pid === pid);
      if (!item || isEmpty(item)) {
        return null;
      }
      const key = Object.keys(item)[0];
      item[key].prodDbStatus = uVeh.dbStatus;
      item[key].safetyExpiryDate = uVeh.safetyExpiryDate;
      item[key].iotId = uVeh.gpsSrNo;
      item[key].description = `${uVeh.make}, ${(<Trailer>uVeh).summary}`;
      item[key].vin = uVeh.vin;

      return uVehList;
    } else {
      // remove all the real product properties from the VehicleListItem
      const oItem: { [key: string]: VehicleListItem } = pickBy(uVehList.list, (val) => !!val.pid && val.pid === pid);
      if (!oItem || isEmpty(oItem)) {
        return null;
      }
      const okey = Object.keys(oItem)[0];
      // oItem[okey].prodDbStatus = null;
      // oItem[okey].safetyExpiryDate = null;
      // oItem[okey].iotId = `placeholder ${okey}`;
      // oItem[okey].description = `${okey} description`;
      // oItem[okey].pid = null;
      // uVehList.list[okey].itemStatus = ItemStatus.inactive;

      // Create new VehicleListItem with real product properties
      const nKey = uVeh.unitName;
      uVehList.list[nKey] = oItem[okey];
      uVehList.list[nKey].prodDbStatus = uVeh.dbStatus;
      uVehList.list[nKey].safetyExpiryDate = uVeh.safetyExpiryDate;
      uVehList.list[nKey].iotId = uVeh.gpsSrNo;
      uVehList.list[nKey].description = `${uVeh.make}, ${(<Trailer>uVeh).summary}`;
      uVehList.list[nKey].vin = uVeh.vin;
      uVehList.list[nKey].pid = pid;
      uVehList.list[nKey].itemStatus = ItemStatus.active;

      uVehList.list[okey] = null;

      return uVehList;

    }
  }
  /**
   * Create new or update Promo list; and add truck or trailer. Called from api-products creates new truck or trailer
   * @param veh Truck or Trailer
   */
  createUpdateVehListOnProdCreate(veh: Vehicle, comp: CompanyBase, pid: string) {
    if (!this.id) {
      switch (veh.productType) {
        case ProductType.trailer:
          this.listType = PromoListType.promoTrailer;
          break;
        case ProductType.truck:
          this.listType = PromoListType.promoTruck;
          break;

        default:
          throw new Error(`${veh.productType} not implemented`);
      }
      this.cid = comp.id;
      this.companySummary = comp.companySummary;
      this.list[pid] = {} as any;

    }
    if (this.isDuplicateVehItem(veh.vin, veh.unitName, veh.gpsSrNo)) {
      throw new Error(`${veh.vin} already exists`);
    }

    this.setListItemProperties(veh, pid);

  }
  /**
 * Update Promo list when truck or trailer is updated. Called from api-products updated existing truck or trailer
 * @param veh Truck or Trailer
 */
  updateVehListOnProdUpdate(veh: Vehicle): { updateReq: boolean, uList?: VehicleList } {
    const uList = this.clone();
    const rootId = DbRule.getRootId(veh.id);
    const oListItem = this.list[rootId].clone();
    uList.setListItemProperties(veh);

    if (isEqual(oListItem, uList.list[veh.id])) {
      return { updateReq: false };
    } else {
      return { updateReq: true, uList: uList };

    }
  }
  updateVehListOnStatuChg(veh: Vehicle): { updateReq: boolean, uList?: VehicleList } {
    const uList = this.clone();
    const rootId = DbRule.getRootId(veh.id);
    const oListItem = this.list[rootId].clone();
    uList.list[rootId].prodDbStatus = veh.dbStatus;
    if (isEqual(oListItem, uList.list[rootId])) {
      return { updateReq: false };
    } else {
      return { updateReq: true, uList: uList };
    }
  }
  setListItemProperties(veh: Vehicle, pid?: string) {
    let key = !!pid ? pid : veh.id;
    key = DbRule.getRootId(key);
    if (!this.list[key]) {
      this.list[key] = {} as any;
    }
    this.list[key].itemStatus = veh.isActive ?  ItemStatus.active : ItemStatus.inactive; //MKN - Maintain Product active/inactive status
    this.list[key].iotId = veh.gpsSrNo;
    this.list[key].safetyExpiryDate = veh.safetyExpiryDate;
    this.list[key].description = `${veh.make}, ${(<Trailer>veh).summary}`;
    this.list[key].vin = veh.vin;
    this.list[key].unitName = veh.unitName;
    this.list[key].prodDbStatus = veh.dbStatus;
  }
  isDuplicateVehItem(vin: string, unitName: string, iotId: string) {
    if (Object.values(this.list).filter(f => f.vin === vin).length > 0) {
      return true;
    }
    if (Object.values(this.list).filter(f => f.unitName === unitName).length > 0) {
      return true;
    }
    if (!!iotId && Object.values(this.list).filter(f => f.iotId === iotId).length > 0) {
      return true;
    }
    return false;
  }

}

