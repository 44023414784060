<div [ngClass]="{'address-search': isGlobalSearch}">
  <mat-form-field>
    <mat-label>Pickup Address</mat-label>
    <input style="font-size: 12px" matInput appGooglePlaces (placeLoaded)="onPlaceLoaded($event)" type="text"
      name="startAddress" #startAddress="ngModel" [(ngModel)]="eo.startAddress.addressFormated"
      autocomplete="off">
    <!-- <button style="font-size: 25px" type="button" color="primary" mat-icon-button matSuffix
      (click)="getByTripTrackerNo();" *ngIf="!!eo.startAddress">
      <mat-icon>search</mat-icon>
    </button> -->

    <mat-hint align="end">e.g. Toronto, ON</mat-hint>
  </mat-form-field>
  <div *ngIf="isGlobalSearch">
    <button mat-mini-fab color="primary" (click)="searchParmEmit()">
      <mat-icon>search</mat-icon>
    </button>
    <!-- <button style="font-size: 25px" type="button" color="primary" mat-icon-button matSuffix (click)="clearSearch();">
  <mat-icon>cancel</mat-icon>
</button> -->
  </div>
</div>
<ng-container *ngIf="isGlobalSearch">
  <mat-form-field class="full-width" *ngIf="!(isHandset$ | async)">
    <input matInput name="pType" autocomplete="off" type="number" [(ngModel)]="eo.type" [hidden]="true" #pTypeCtrl="ngModel"/>
    <mat-radio-group aria-label="Select an option" name="milageUnit" [disabled]="true" [(ngModel)]="eo.type" #pTypeCtrl1="ngModel">
      <mat-radio-button color="accent" [value]="pTypeEnum.truck" style="padding-right: 10px">
        Semi-Truck
      </mat-radio-button>
      <mat-radio-button color="accent" [value]="pTypeEnum.driver" style="padding-right: 10px">
        Straight Truck
      </mat-radio-button>
      <mat-radio-button color="accent" [value]="pTypeEnum.trailer" style="padding-right: 10px">
        Trailer
      </mat-radio-button>
    </mat-radio-group>
  </mat-form-field>
  <mat-form-field class="full-width m-type" *ngIf="!!(isHandset$ | async)">
    <mat-select matNativeControl name="nAxle" #pTypeCtrl="ngModel" [(ngModel)]="eo.type">
      <mat-option [value]="pTypeEnum.truck">Semi-Truck</mat-option>
      <mat-option [value]="pTypeEnum.truck" [disabled]="true">Straight Truck</mat-option>
      <mat-option [value]="pTypeEnum.trailer" [disabled]="true">Trailer</mat-option>
    </mat-select>
  </mat-form-field>
</ng-container>


<ng-container *ngIf="eo?.type === pTypeEnum.trailer">

  <div class="options-cont"
    [ngClass]="{'options-cont-mobile': !!(isHandset$ | async), 'options-cont-dtop': !(isHandset$ | async)}">
    <div *ngIf="!(isHandset$ | async)" class="reefer-option">
      <button [color]="eo.isReefer ? 'primary' : ''" mat-raised-button (click)="eo.isReefer = true">Reefer</button>
      <button [color]="eo.isReefer === false ? 'primary' : ''" mat-raised-button (click)="eo.isReefer = false"
        style="padding-left: 10px; padding-right: 10px;">Dry-van</button>
      <button [color]="eo.isReefer === undefined ? 'primary' : ''" mat-raised-button
        (click)="eo.isReefer = undefined ">All</button>
    </div>
    <div *ngIf="!!(isHandset$ | async)" class="select">
      <mat-select matNativeControl name="nAxle" #nAxleCtrl="ngModel" [(ngModel)]="refeerDryVan"
        (selectionChange)="reeferDrySelect()" align="end">
        <mat-option [value]="'reefer'">Reefer</mat-option>
        <mat-option [value]="'dry-van'">Dry-Van</mat-option>
        <mat-option [value]="'all'">Dry or Reefer</mat-option>
      </mat-select>
    </div>
    <div>
      <mat-select matNativeControl name="nAxle" #nAxleCtrl="ngModel" [(ngModel)]="nAxle"
        (selectionChange)="nAxleSelect()" align="end">
        <mat-option [value]="+2">2-Axle</mat-option>
        <mat-option [value]="+3">3-Axle</mat-option>
        <mat-option [value]="+4">4-Axle</mat-option>
        <mat-option [value]="'*'">Any-Axle</mat-option>
      </mat-select>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="eo?.type === pTypeEnum.truck">
  <div class="options-cont"
    [ngClass]="{'options-cont-mobile': !!(isHandset$ | async), 'options-cont-dtop': !(isHandset$ | async)}">
    <div *ngIf="!(isHandset$ | async)" class="reefer-option">
      <button [color]="eo.isDayCab === true ? 'primary' : ''" mat-raised-button (click)="eo.isDayCab = true">Day Cab</button>
      <button [color]="eo.isDayCab === false ? 'primary' : ''" mat-raised-button (click)="eo.isDayCab= false"
        style="padding-left: 10px; padding-right: 10px;">Sleeper</button>
      <button [color]="eo.isDayCab === '*' ? 'primary' : ''" mat-raised-button
        (click)="eo.isDayCab = undefined ">All</button>
    </div>
    <div *ngIf="!!(isHandset$ | async)" class="select">
      <mat-select matNativeControl name="dayCab" #dayCabCtrl="ngModel" [(ngModel)]="eo.isDayCab" (selectionChange)="dayCabSelect()"
        align="end">
        <mat-option [value]="true">Day Cab</mat-option>
        <mat-option [value]="false">Sleeper</mat-option>
        <mat-option [value]="'*'">Day/Sleeper</mat-option>
      </mat-select>
    </div>
    <ng-container *ngTemplateOutlet="makeTemplate"></ng-container>
    
  </div>
</ng-container>

<ng-template #makeTemplate>
  <div class="select">
    <mat-select name="make" #makeCtrl="ngModel" [(ngModel)]="eo.make" align="end">
      <mat-option [value]="'*'">All Makes</mat-option>
      <ng-container *ngFor="let mk of makes">
        <mat-option [value]="mk">{{mk}}</mat-option>
      </ng-container>

    </mat-select>
  </div>

</ng-template>




<!-- <mat-card-actions>
  <button mat-icon-button color="primary"><mat-icon>search</mat-icon></button>
</mat-card-actions> -->