import { State, StateContext, Action, Selector } from '@ngxs/store';
import { LoadingOptions, AlertOptions } from '../../models/utility/ionic-interface';
import { MessageInfo } from '@trent/models/error-handling/message-info';
import { MessagingPayload } from '@trent/models/fcm/fcm-type';
import { Injectable } from '@angular/core';

// #region State Model
export interface LoadingStateModel {
  delay?: number;
  options?: LoadingOptions;
  messageInfo?: MessageInfo;
}

export interface PopoverStateModel {
  zIndex?: number;
  messageInfo?: MessageInfo;
  callBack?: () => any;
}

export interface AlertStateModel {
  messageInfo?: MessageInfo;
  options?: AlertOptions;
  evtEmitters?: {
    [key: string]: {
      evtSignature: string,
      param: {}
    }
  };
}

export interface NotificationStateModel {
  loading: LoadingStateModel;
  popover: PopoverStateModel;
  alert: AlertStateModel;
  fcmNotify: MessagingPayload;
}
// #endregion

// #region actions
export class ShowLoading {
  static readonly type = '[Notification] Show Loading Action';
  constructor(public payload: LoadingStateModel) { }
}

export class ShowPopover {
  static readonly type = '[Notification] Show Popover Action';
  constructor(public payload: PopoverStateModel) { }
}

export class ShowAlert {
  static readonly type = '[Notification] Show Alert Action';
  constructor(public payload: AlertStateModel) { }
}

export class ShowFcbNotification {
  static readonly type = '[Notification] Show Fcb Notification Alert Action';
  constructor(public payload: MessagingPayload) { }
}



// #endregion

@State<NotificationStateModel>({
  name: 'notification',
  defaults: {
    loading: null,
    popover: null,
    alert: null,
    fcmNotify: null
  }
})
@Injectable()
export class NotificationState {

  constructor() { }

  @Selector()
  static loadingShow(state: NotificationStateModel) {
    return state.loading;
  }

  @Selector()
  static popoverShow(state: NotificationStateModel) {
    return state.popover;
  }

  @Selector()
  static alertShow(state: NotificationStateModel) {
    return state.alert;
  }

  @Selector()
  static fcbNotifShow(state: NotificationStateModel) {
    return state.fcmNotify;
  }


  @Action(ShowLoading)
  Loading(context: StateContext<NotificationStateModel>, action: ShowLoading) {
    context.patchState({ loading: action.payload });
  }

  @Action(ShowAlert)
  ShowAlert(context: StateContext<NotificationStateModel>, action: ShowAlert) {
    context.patchState({ alert: action.payload });
  }

  @Action(ShowFcbNotification)
  showFcbNotification(context: StateContext<NotificationStateModel>, action: ShowFcbNotification) {
    context.patchState({ fcmNotify: action.payload });
  }

  @Action(ShowPopover)
  Loaded(context: StateContext<NotificationStateModel>, action: ShowPopover) {
    context.patchState({ popover: action.payload });
  }

}
