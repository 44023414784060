export enum BidStatus {
  draft = 0, // Draft Bid submitted by un registered company
  waitingForVendor = 1, // inital bid waiting for vendor review
  waitingForCustomer = 2, // Counter Offer waiting for Customer Acceptance;
  accepted = 3, // Accepted, Contracted Started
  rejected = 4, // Bid Rejected by Vendor or Customer
  expired = 5, // Bid expired because Vendor did not respond to the initial bid
  terminated = 6, // Bid expired because Vendor did not respond to counter offer
  completed = 7 // Bid completed once end is past
}

