import { IAuthCoreService } from '@trent/services/auth/iauth-core.service';
import { Router } from '@angular/router';
import { DialogService } from '@trent/services/dialog/dialog.service';
import { Component, OnInit, OnDestroy, Input, ElementRef, Optional } from '@angular/core';
import { PageHtml, PageHtmlSection } from '@trent/models/cms/page-html';
import { Store } from '@ngxs/store';
import { EventService, IEventListener } from '@trent/services/event.service';
import { cleanListeners } from '@trent/models/utility';
import { CmsService } from '@trent/services/cms.service';
import { UtilityService } from '@trent/services/utility.service';
import { CmsState, CmsTogglePageEdit } from '@trent/store/cms-store';
import { Subscription } from 'rxjs';
import { CkData } from './ck-inline.directive';
import { MessageInfo } from '@trent/models/error-handling/message-info';
import { FormDisplayMode } from '@trent/models/UI/form-display-mode';
import { DomSanitizer } from '@angular/platform-browser';
import { logger } from '@trent/models/log/logger';
import { SeoService } from '@trent/services/seo.service';
import { getSafeHtml, getSafeUrl } from '../../../models/utility';

@Component({
  selector: 'trent-ck-editor-ctrl',
  templateUrl: './ck-editor-ctrl.component.html',
  styleUrls: ['./ck-editor-ctrl.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class CkEditorCtrlComponent implements OnInit, OnDestroy {

  dm = FormDisplayMode;
  // aType = AgreementType;
  /** Event Listner container. Only need to store so that ngDistroy can remove the linkages of events. */
  public eventListners: IEventListener[] = [];
  private subs: Subscription[] = [];

  private m: PageHtml;
  public em: PageHtml;
  get page() { return this.em; }

  /** For some reason, getting a safe url goes in to a continuous recycling loop. this dic is maintained
   * to ensure it is only used once.
   */
  public safeUrl: { [url: string]: any } = {};


  @Input()
  set page(p: PageHtml) {
    this.m = p;
    this.em = this.m.clone();
    // if (!this.isEditMode) {
    //   this.setSafeHtml();
    // }
    this.sections = this.em.sectionsOrdered;
    this.keys = this.em.keysOrdered;
    // logger.log('[ck] page changed1');
    // this.cdr.detectChanges();
  }

  @Input()
  readSection: string;

  showBusy: boolean;

  sections: PageHtmlSection[] = [];

  keys: string[];

  isEditMode = false;

  isAdmin = false;

  @Input()
  displayMode: FormDisplayMode;

  @Input()
  isCenterVideo: boolean;

  @Input()
  isTitleEdit: boolean;

  @Input()
  isDescEdit: boolean;

  @Input()
  type: PageHtmlSection['type'];

  @Input()
  showAuto: boolean;

  constructor(
    private store: Store,
    public seo: SeoService,
    public cms: CmsService,
    public us: UtilityService,
    public ds: DialogService,
    public router: Router,
    private auth: IAuthCoreService,
    private sanitizer: DomSanitizer,
    private es: EventService,
    @Optional() private elementRef: ElementRef
  ) {
    logger.log('[ck] constructor called');
  }

  ngOnInit(): void {
    // this.readSection = 'tag1';
    this.subs.push(this.store
      .select(CmsState.selectPageEditMode)
      .subscribe(m => {
        this.isEditMode = m;
        // this.cdr.detectChanges();
      }));

    this.subs.push(this.auth.isAdmin$.subscribe(a => {
      this.isAdmin = a;
      // this.cdr.detectChanges();
    }));

    // setInterval(() => {
    //   logger.log(`id: ${this.em.id}, hdr: ${this.sections[0].header}`);
    // }, 2000);
  }

  ngOnDestroy(): void {
    cleanListeners(this.eventListners, this.subs);
  }

  edit() {
    this.store.dispatch(new CmsTogglePageEdit());
    // this.isEditMode = !this.isEditMode;
    // this.cdr.detectChanges();
  }

  cancel() {
    this.store.dispatch(new CmsTogglePageEdit());
    this.page = this.m; // reset.
  }

  getSafeUrl(url) {
    if (!!this.safeUrl[url]) {
      return this.safeUrl[url];
    }
    this.safeUrl[url] = getSafeUrl(url, this.sanitizer);
    // this.safeUrl[url] = this.sanitizer.bypassSecurityTrustResourceUrl(url) as string;
    return this.safeUrl[url];
  }
  contentChanged(data: CkData) {
    logger.log('content changed: ', data);
    if (data.pid === this.em.id) {
      this.em.sections[data.sid].val = data.content;
    }
  }
  contentChangedAutoSection(data: CkData) {
    logger.log('content changed: ', data);
    if (data.pid === this.em.id) {
      this.em.autoSection[data.sid].val = data.content;
    }
  }

  /** used in read mode only, if only a given fragment is to be shown */
  showSection(key) {
    return key === this.readSection || this.readSection == null;
  }

  save() {    
    // try {      
      //   const nativeElement = this.elementRef.nativeElement;  
      //   // Create a new mouse click event
      //   const clickEvent = new MouseEvent('click', { bubbles: true });  
      //   // Dispatch the mouse click event on the element
      //   nativeElement.dispatchEvent(clickEvent);
      // } catch (error) {
        //   logger.error('some ck editor content might not have saved!');      
        // }
     
    /** When a user is editing ck content, and user click on button save without clicking the button somewhere else 
     * on the screen, ck editor does not fire the change event so the content on that active ckeditor will not be saved on db.
     * manually create a click event on the screen OR use event bus to request and update that will force the ck-editors to update.
     */
     this.es.emit<any>(this.es.fireCkContentChg, undefined);
     setTimeout(() => {
      try {
        this.em.syncTagChanges();
      } catch (error) {
        this.ds.openSnackBar(new MessageInfo({
          msgCss: 'warn',
          description: error
        }), {
          duration: 15000,
          verticalPosition: 'bottom'
        });
        return;
      }

      // if (this.displayMode === FormDisplayMode.create && !!this.em.id && this.em.id.toString().trim().length > 0) {
      // setTimeout(() => {
      if (this.displayMode === FormDisplayMode.create) {
        this.AddDb();
      } else {
        this.updateDb();
      }
    }, 100);
  }

  AddDb() {
    // also save if page is defined
    if (!!this.em) {
      // this.em.isRevControlled = true;
      this.cms.createPageHtml(this.em, this.auth.userId)
        .then((id) => {
          this.ds.openSnackBar(new MessageInfo({
            msgCss: 'success',
            description: 'Record was created.'
          }), {
            duration: 2000,
            verticalPosition: 'bottom'
          });
          this.store.dispatch(new CmsTogglePageEdit());
          this.router.navigateByUrl(`/cms/edit/${id}`);
        })
        .catch(err => {
          const msg = (!!err && typeof err.message === 'string') ? err.message :
            'Sorry! Login was not successful. Please try again!';
          this.ds.openSnackBar(new MessageInfo({
            msgCss: 'warn',
            description: msg
          }), {
            duration: 15000,
            verticalPosition: 'bottom'
          });
        });
    }
  }

  updateDb() {
    // also save if page is defined
    if (!!this.em) {
      this.cms.savePageHtmlById(this.em.id, this.em, this.auth.userId, this.m)
        .then(() => {
          this.ds.openSnackBar(new MessageInfo({
            msgCss: 'success',
            description: 'Edits were updated!'
          }), {
            duration: 2000,
            verticalPosition: 'bottom'
          });
          this.store.dispatch(new CmsTogglePageEdit());
          // this.cdr.detectChanges();
        })
        .catch(err => {
          const msg = (!!err && typeof err.message === 'string') ? err.message :
            'Sorry! Login was not successful. Please try again!';
          this.ds.openSnackBar(new MessageInfo({
            msgCss: 'warn',
            description: msg
          }), {
            duration: 15000,
            verticalPosition: 'bottom'
          });
        });
    }
  }


  /** Only update UI, will not update db */
  insert(idx) {
    if(this.type){
      this.em.insert(idx, this.type);
    }else{
      this.em.insert(idx);
    }
    this.sections = this.em.sectionsOrdered;
    this.keys = this.em.keysOrdered;
  }

  /** Only update UI, will not update db */
  delete(key: string) {
    delete this.em.sections[key];
    // this.em.delete(idx);
    this.sections = this.em.sectionsOrdered;
    this.keys = this.em.keysOrdered;
  }

  storeRevChg() {
    if (!this.em.isRevControlled) {
      this.em.isAgreement = null;
    }
  }
  getSafeHtml(txt: string) {
    return getSafeHtml(txt, this.sanitizer);
  }

  // setSafeHtml() {
  //   for (const key in this.em.sections) {
  //     if (Object.prototype.hasOwnProperty.call(this.em.sections, key)) {
  //       const element = this.em.sections[key];
  //       if (element?.type === 'html') {
  //         if (!!element?.val) {
  //           element.val = this.sanitizer.bypassSecurityTrustHtml(element.val) as any;
  //         }
  //         //handle legacy.
  //         if (!!(element as any)?.text) {
  //           (element as any).text = this.sanitizer.bypassSecurityTrustHtml((element as any).text) as any;
  //         }
  //       }
  //     }
  //   }
  // }  
}

