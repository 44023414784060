<mat-card class="mat-card">
  <mat-card-header>
    <mat-card-title>Unsubscribe Successful</mat-card-title>
  </mat-card-header>
  <mat-card-content>
      <p>We're sorry to see you go, but we respect your choice to unsubscribe from our email list. Your request has been successfully processed. You will no longer receive emails from us. If this was a mistake or you changed your mind, you can re-subscribe at any time on our website.
      
      <br>If you have any concerns, feedback, or questions, please feel free to contact our support team at <a href="mailto:1866pride24@pridetrucksales.com">1866pride24@pridetrucksales.com</a>.
      
      <br>Thank you for being a part of our community. We hope to see you again in the future.
      </p>
  </mat-card-content>
</mat-card>
