import { Component, OnInit, Inject } from '@angular/core';
import { BasePage } from '@trent/models/UI/base.page';
import { Store } from '@ngxs/store';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogService } from '@trent/services/dialog/dialog.service';
import { EventService } from '@trent/services/event.service';
import { logger } from '@trent/models/log/logger';

export interface DialogData {
  title: string;
  trueButton: string;
  falseButton: string;
  subtitle?: string;
  imgUrl?: string;

}
@Component({
  selector: 'trent-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent extends BasePage<any> implements OnInit {

  constructor(store: Store, dialogService: DialogService, es: EventService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
      super(store, dialogService, es);
    this.m = this.data;
    logger.log('dialog input', this.m);
  }

  ngOnInit() {
  }

}
