import { Paging } from '../observable-util/paging';
import { UserProfile } from '../user/user-profile';
import { logger } from '../log/logger';
import { CollectionReference, limit, orderBy, query, Query, QueryConstraint, startAfter, where } from 'firebase/firestore';
import { RoleGroup } from './role-group';

/**
 * Role Group Store method and interface
 */
export interface IRoleGroupParam {
  orderDirection?: 'desc' | 'asc';
  orderBy?: string;
  shortName?: string;
  rgType? : number;
  rgTypes? : number[];

}

export const roleGroupParamInit = (): IRoleGroupParam => {
  return {
    orderBy: 'updatedAt',
    orderDirection: 'desc',
  };
};

/** Server filtering of firebase query building of query  */
export const roleGroupSearchServerQuery = (ref: CollectionReference, param: IRoleGroupParam, p: Paging) => {
  let q: Query = <any>ref;
  const cons: QueryConstraint[] = [];
  if (!!param.shortName) {
    cons.push(where('shortName', '==', param.shortName.toLowerCase()));
  }

  if (!!param.rgType) {
    cons.push(where('roleGroupType', '==', param.rgType));
  }

  if (!!param.rgTypes) {
    cons.push(where('roleGroupType', 'in', param.rgTypes));
  }
  
  cons.push(where('updatedAt', '<=', new Date()));
  cons.push(orderBy('updatedAt', 'desc'));

  if (!!p.lastDoc) {
    logger.log('Start After Query: ', p.lastDoc[param.orderBy]);
    cons.push(startAfter(p.lastDoc[param.orderBy]));
  }
  cons.push(limit(p.size));
  q = query(ref, ...cons);
  return q;
};

/** Client side filtering of the data. */
export const roleGroupSearchClientFilter = (p: RoleGroup[], o: IRoleGroupParam) => {

  if (!!o.shortName) {
    p = p.filter(val => val.shortName === o.shortName.toLowerCase());
  }

  if(!!o.rgType){
    p = p.filter(val => val.roleGroupType === o.rgType);
  }

  if(!!o.rgTypes && o.rgTypes.length > 0){
    p = p.filter(val => o.rgTypes.includes(val.roleGroupType));
  }

  return p;
};


export const getIRoleGroupOptionOrChildren = (o: IRoleGroupParam): { [key: string]: IRoleGroupParam } => {
  return undefined;
};
