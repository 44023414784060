<ng-container *ngIf="policyData?.length > 0">
  <div class="primary"
    [ngClass]="{'loaded': pageLoaded, 'policy-container': !us.isHandset, 'policy-container-mobile': us.isHandset, 'full-page': !isModal}">
    <div clsss="top">
      <div *ngIf="isModal" mat-dialog-title>
        Policies and Agreements
      </div>
      <div *ngIf="policyTextShown()" class="alert warning" [ngClass]="{'error': finalReminder}"
        style="position: sticky;">
        Scroll to bottom to accept &nbsp; <b>{{ reminderText}}</b>
      </div>
      <div *ngIf="!isModal" class="policy-head">
        <h1>Policies and Agreements requring approval</h1>
      </div>
      <div mat-dialog-content class="agreement-content" style="scroll-behavior: auto;">
        <ng-container *ngTemplateOutlet="comp"></ng-container>

      </div>
    </div>
    <div class="footer">
      <ng-container *ngTemplateOutlet="cbox">
      </ng-container>
      <div clsss='footer' style="display: block;">
        <ng-container *ngTemplateOutlet="btns">
        </ng-container>
      </div>
    </div>
  </div>

</ng-container>

<!-- Templates  -->
<ng-template #comp>
  <mat-accordion class="accordian" multi>
    <ng-container *ngFor="let p of policyData; let idx = index;">
      <mat-expansion-panel class="panel" [expanded]="step === idx" (opened)="setStep(idx)">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{idx+1}}) &nbsp; {{p.name}}
          </mat-panel-title>
          <mat-panel-description>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="policy-text" *ngIf="!!p.page && p.showPage">
          <div ck-inline [page]="p.page" [sectionName]="'tag0'"></div>
        </div>
        <!-- <mat-action-row></mat-action-row> -->
      </mat-expansion-panel>
    </ng-container>
  </mat-accordion>
</ng-template>

<ng-template #cbox>
  <div style="padding-bottom: 15px;">
    <ul class="cb" *ngFor="let p of policyData; let idx = index;">
      <li>
        <mat-checkbox class="example-margin" (click)="showPolicyDetail(p)" [(ngModel)]="p.checked">
          I agree to <b>{{p.name}}</b>
        </mat-checkbox>
      </li>
    </ul>
  </div>
</ng-template>

<ng-template #btns>
  <button mat-stroked-button (click)="cancel()" type="button" class="md-18 right">
    <mat-icon>close</mat-icon> Cancel
  </button>
  <button mat-raised-button (click)="save()" type="button" color="primary" class="md-18 right"
    [disabled]="checkAcceptance()">
    <mat-icon>done</mat-icon> OK
  </button>
</ng-template>
<ng-container *ngIf="showEmpty">
  <mat-card appearance="outlined">
    <mat-card-header>
      <div mat-card-avatar>
        <mat-icon matSuffix style="font-size:xx-large" >check_circle</mat-icon>
      </div>
      <mat-card-title>Poliices and Agreements</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      Your policies and agreements are upto date.
    </mat-card-content>
    <mat-card-actions>
      <div>
        <a mat-raised-button class="mat-primary md" (click)="updatedPolicyHomeClick()">
          <span class="mat-button-wrapper">
            <mat-icon>home</mat-icon>
          </span>
        </a>
      </div>
    </mat-card-actions>
  </mat-card>
</ng-container>