// import map as loadashMap from 'lodash/map';
import {findIndex} from 'lodash';

/** Array Container for the state entity */
export interface EntityState<T> {
  // ids: number[] | string[];
  data: T[];
}

/** Initialize Entity. */
export const init = <T>(t?: T[]): EntityState<T> => {
  // const data: T[] = [];
  return {
    data: (!!t) ? t : []
  };
};

/** Selector Helper: return the entity data array mapped to a new array using provided function. */
// export const map = <T, V>(entity: EntityState<T>, mapperFn: (T) => V) => {
//   return loadashMap(entity.data, (c) => mapperFn(c));
// };

/** Add One entity */
export const addOne = <T>(entity: EntityState<T>, t: T): EntityState<T> => {
  // logger.log('add one was called', t);
  const data = entity.data.slice(0);
  data.push(t);
  return {
    data
  };
};

/** Add multiple to entity  */
export const addMany = <T>(entity: EntityState<T>, a: T[], idName?: string | number): EntityState<T> => {
  const id = (!!idName && idName !== '') ? idName : 'id';
  const data = entity.data.slice(0); // .concat(a);

  // now add or update.
  for (let idx = 0; idx < a.length; idx++) {
    const ele = a[idx];
    const existingIndex = findIndex(data, (x) => x[id] === ele[id]);
    if (existingIndex === -1) {
      data.push(ele);
    } else {
      data[existingIndex] = ele;
    }
  }

  return {
    data
  };
};

/** Update Entity  */
export const update = <T>(entity: EntityState<T>, c: T, idName?: string | number): EntityState<T> => {
  const id = (!!idName && idName !== '') ? idName : 'id';
  const data = [];
  let flagUpdated = false;
  for (const comp of entity.data) {
    if ((<any>comp)[id] === (<any>c)[id]) {
      flagUpdated = true;
      data.push(c);
    } else {
      data.push(comp);
    }
  }
  if (!flagUpdated) { data.push(c); }
  return {
    data
  };
};

/** Return by id ( Function ). */
export const getByIdFn = <T>(entity: EntityState<T>, parseFn?: (obj) => T) => {
  return (id: string | number, idName?: string) => {
    // split prop name by .
    const iA = (idName == null) ? ['id'] : idName.split('.');

    const m = entity.data.find(x => {
      // split prop name by .
      let o = x;
      for (let i = 0; i < iA.length; i++) {
        if (o == null) {
          return false;
        }
        // if param idName was a.b.c ten subId will be 'a', 'b', 'c' in
        // each iteration of this loop.
        const subId = iA[i];
        o = o[subId];
      }
      return <any>o === id;
    });

    if (m != null) {
      // m can be any product type. so parse it after finding its type.
      if (typeof (parseFn) === 'function') {
        return parseFn(m);
      }
    }
    return m;
  };
};

export const getByIdFn_new = <T>(entity: { [id: string]: T }, parseFn?: (obj) => T) => {
  return (id: string | number, idName?: string) => {
    // it is a simple id call, just return the object in the dictionary.
    if (idName == null) {
      const r = entity[id];
      if (r == null) {
        return null;
      }
      if (typeof (parseFn) === 'function') {
        return parseFn(r);
      }
      return r;
    }

    // otherwise split prop name by .
    const iA = idName.split('.');

    const m = Object.values(entity).find(x => {
      // split prop name by .
      let o = x;
      for (let i = 0; i < iA.length; i++) {
        if (o == null) {
          return false;
        }
        // if param idName was a.b.c ten subId will be 'a', 'b', 'c' in
        // each iteration of this loop.
        const subId = iA[i];
        o = o[subId];
      }
      return <any>o === id;
    });

    if (m != null) {
      // m can be any product type. so parse it after finding its type.
      if (typeof (parseFn) === 'function') {
        return parseFn(m);
      }
    }
    return m;
  };
};


