import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarouselComponent } from './carousel.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogModule } from '../confirmation-dialog/confirmation-dialog.module';
import { FileBaseService } from '@trent/services/file/file-base-service';
import { environment } from '@trent/core/environments/environment';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { CoreLazyModule } from '../core-lazy/core-lazy.module';
import { FileMobileService } from '@trent/services/file/file-mobile';
import { CameraModule } from '../camera/camera.module';
import { HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { HammerConfigService } from '@trent/services/hammer-config.service';
const mobileAppProviders = environment.platform === 'web' ? [] : [File];

@NgModule({
  declarations: [CarouselComponent],
  imports: [
    CommonModule,
    ConfirmationDialogModule,
    // CoreLazyModule,
    // material imports
    MatTabsModule,
    MatIconModule,
    MatButtonModule,
    MatProgressBarModule,
    CameraModule
  ],
  exports: [
    CarouselComponent,
  ],
  providers: [
    { provide: HAMMER_GESTURE_CONFIG, useClass: HammerConfigService },
    { provide: FileBaseService, useClass: environment.platform === 'web' ? FileBaseService : FileMobileService },
    ...mobileAppProviders
  ]
})
export class CarouselModule { }
