import { Exclude, Expose, plainToInstance } from 'class-transformer';
import { BaseModel } from '../base/base-model';
import { sanitizeDateIPoint } from '../utility';
import { logger } from '../log/logger';

export enum CronJobType {
  obsoleteRentOptionStatus = 1, // update Rent options status the avaialble dates are in past
  expireBidStatus = 2,   // update Bid status when bid expiry date is in past
  expirePickDropStatus = 3, // set PickDropStatus based on InspectionResponse.vendor expired or InspectionResponse.customer expired
  sendNotifications = 4,
  updateTripTransaction = 5
}
export enum CronActions {
  removeDraft = 1,
  removedBindingBid = 2,
  setBidExpiredStatus = 3,
  setRentalObsoleteStatus = 4,
  setPickDropOnExpiry = 5,
  updateTask = 6,
  updateTrip = 7,
  setSalesObsoleteStatus = 4,
}
export interface UpdateDocRef {
  did?: string | number;
  revCreated?: number;
  action?: CronActions;
  collection?: string;
  change?: Map<string, { before: any; after: any; }>[];  // depritcated because firebase RTDB does not support arrays
  changes?: { [key: string]: { before: any; after: any } };  // updated to use firebase RTDB

}
export interface UpdateDocRefUi extends UpdateDocRef {
  cronJobType?: CronJobType;
  createdAt: Date;
  detailString?: string[];
  id: string | number;
}
@Exclude()
export class CronJobsLog extends BaseModel {

  public static readonly collectionName = 'cron-jobs-log';

  @Expose()
  cronJobType: CronJobType;

  @Expose()
  updateDocs: UpdateDocRef[];

  @Expose()
  noUpdateRequired?: boolean;

  @Expose()
  error?: string;

  public static parse(obj) {
    try {
      if (obj == null) { return null; }
      obj = sanitizeDateIPoint(obj);
      const m = plainToInstance<CronJobsLog, any>(CronJobsLog, obj);
      m.sanitize();

      return m;
    } catch (error) {
      logger.log('Error happened during parse', error);
      return null;
    }
  }
}
