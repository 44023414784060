export class DeepCopy {

  /** Create a copy clone of given object. Note: It does not copy/create the functions. */
  public static copy<T>(obj: T): T {
    let copy;
    // Handle the 3 simple types, and null or undefined
    if (null == obj || 'object' !== typeof obj) {
      return obj;
    }

    // Handle Date
    if (obj instanceof Date) {
      copy = new Date();
      copy.setTime(obj.getTime());
      return copy;
    }

    // Handle Array
    if (obj instanceof Array) {
      copy = [];
      for (let i = 0, len = obj.length; i < len; i++) {
        copy[i] = DeepCopy.copy(obj[i]);
      }
      return copy;
    }

    // Handle Object
    if (obj instanceof Object) {
      copy = {};
      for (const attr in obj) {
        // logger.log(`${attr} is own property: ${obj.hasOwnProperty(attr)}`);
        if (obj.hasOwnProperty(attr)) {
          copy[attr] = DeepCopy.copy(obj[attr]);
        }
      }
      return copy;
    }

    throw new Error('Unable to copy obj! Its type isn\'t supported.');
  }

  /** If two objects are equal by value */
  public static areEqual(o1: object, o2: object) {
    if (o1 === undefined && o2 === undefined) { return true; }
    if (o1 === null && o2 === null) { return true; }
    return JSON.stringify(o1) === JSON.stringify(o2);
  }
}
