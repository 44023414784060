/**
 * PRODUCTION FILE CREDENTIALS [  lloop  ]
 */

import { IFirebaseOptions } from '../config';

/** PRODUCTION SERVER Firebase setting -- lloop */
export const fireProject: IFirebaseOptions = {

  apiKey: 'AIzaSyDTcKiwqCUPna-MFLsTvRNf7p6C1VSyRxo',
  authDomain: 'lloop-21cf0.firebaseapp.com',
  databaseURL: 'https://lloop-21cf0.firebaseio.com',
  projectId: 'lloop-21cf0',
  storageBucket: 'lloop-21cf0.appspot.com',
  messagingSenderId: '144754681707',
  appId: '1:144754681707:web:3dc8182479c2900f314e2e',
  measurementId: 'G-43S9RFSVTK',
  /** For Cloud Messaging. */
  publicVapidKey: 'BFkxsa4yA_gqjigV0rGCCfBtLp_YpS7ih1EggP1MhVNdsNlhMcx3gAPu2oTNUYECj6WrqUzOyDchy2fDkQyZYfs',
  nonTriggerStorageBucket: 'lloop-21cf0',
  /** capture the environment of the project to be dev,qa,prod */
  env: 'prod'
};

/** Just copy under different name to ensure functions pulls the correct variable. */
export const fireProject_lloop = { ...fireProject };

export const webSiteUrl = {
  development: 'http://localhost:4600',
  production: 'https://tpinerental.com'
  // production: 'https://locusloop.com'
};
/** To be used by functions. */
export const webSiteUrl_lloop = { ...webSiteUrl };

/** Firebase Google Plus Login Web Client ID */
export const gplusInfo = {
  webClientId: '144754681707-thla4tj8u4rkfkq5n3huk5qc0cda8pu4.apps.googleusercontent.com'
};


export const functionUrl = {

  // devleopment_old: 'http://localhost:8010/lloop-21cf0/us-central1/api',

  /** use when emulator is running. Note this is base url, without any function suffix. */
  devleopment_emu: 'http://localhost:8090/lloop-21cf0/us-central1',

  // grewal - mobile
  // devleopment: 'http://192.168.2.13:8080',

  // sandhu - mobile
  // devleopment: 'http://192.168.0.11:8080',

  // common web
  devleopment: 'http://localhost:8080',

  production: 'https://us-central1-lloop-21cf0.cloudfunctions.net', // 'XXXXXXXXXXXXX TBD',

  /** before getting rid of gps url and reverting to function url logic  */
  // gpsPostUrl_dev: 'http://192.168.0.22:8080/location/gps-location-update',
  // gpsPostUrl_emu: 'http://192.168.2.20:8080/gps/location/create',
  // gpsPostUrl_LocusLoop: 'xxx tbd /location/create',
  // gpsPostUrl_lloopDebug: 'https://us-central1-lloop-debug.cloudfunctions.net/gps/location/gps-location-update',

};

// algolia algoliaClientConfig prod
export const algoliaClientConfig = {
  appId: "C99O14RU2M",
  searchApiKey: "7826955334b6a870e4b0e2f7c2af7510",
  baseUrl: "https://C99O14RU2M-dsn.algolia.net"
}
