import { Injectable } from '@angular/core';
import { IDeviceIdComponents } from '@trent/models/fcm/fcm-token-data';

@Injectable()
export abstract class IcloudMessageService {

  constructor() { }

  requestNotificationPermission: () => Promise<boolean>;

  updateFcmToken: () => Promise<void>;

  refreshToken: () => Promise<void>;

  // getDeviceIdFn: () => Promise<IDeviceIdComponents>;
}
