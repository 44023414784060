import { Length, validateSync, ValidatorOptions, IsEmail } from 'class-validator';
import { toValidationError } from '../utility';
import { IValidationMsg } from '../error-handling/validation-info';
import { EmailValidator } from '@angular/forms';

/** Registration model used during custum registration of email and password. */
export class UserRegistration {

  @IsEmail({}, {message: 'A valid email is required'})
  email: string;

  @Length(8, 16, {
    message: 'Password is required. $constraint1 - $constraint2 to chars',
  })

  password: string;

  confirmPassword: string;

  validateSync(options: ValidatorOptions): IValidationMsg {
    const r = validateSync(this, options);
    const m = toValidationError(r);
    if (this.confirmPassword !== this.password || this.confirmPassword == null) {
      m['confirmPassword'] = ['Password provided do not match'];
    }
    return m;
  }

}
