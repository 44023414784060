import { Injectable } from '@angular/core';
import { File, Entry } from '@awesome-cordova-plugins/file/ngx';

@Injectable()
export class FileBaseService {

  constructor() {

  }

  get isFileAvailable() { return false; }

  async resolveFile (url: string): Promise<Entry> {
    return new Promise<any>((resolve, reject) => reject('resolveFile is not available in web app!'));
  }
  async readFile (file: any): Promise<Blob> {
    return new Promise<any>((resolve, reject) => reject('readFile is not available in web app!'));
  }
}
