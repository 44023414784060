import { Injectable} from '@angular/core';

// import * as Hammer from 'hammerjs';

import { getHammer } from './hammer-config-fake.service';
// const Hammer = getHammer();

import { HammerGestureConfig } from '@angular/platform-browser';

@Injectable()
export class HammerConfigService extends HammerGestureConfig {
  overrides = <any>{
    // tslint:disable-next-line:max-line-length
    swipe: { direction: getHammer().DIRECTION_HORIZONTAL },   // DIRECTION_ALL },  // https://hammerjs.github.io/api/ // Hammer.DIRECTION_HORIZONTAL },
    pinch: { enable: false },
    rotate: { enable: false },
    // pan: {direction: hammer.Direction_ALL}
  };
  buildHammer(element: HTMLElement) {
    const Hammer = getHammer();
    let mc = new Hammer(element, {
      touchAction: "auto",
    });
    return mc;
  }
}
