import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PagingObesrvable } from '@trent/models/observable-util/paging-obesrvable';
import { ITaskParam } from '@trent/models/sys/task-param';
import {Task} from '@trent/models/sys/task';

@Injectable()

export abstract class ITaskService {

  public setTaskRead(task: Task, uid: string): Promise<void[]> {
    throw Error('programming error');
  }
  public async setTaskSeen(task: Task, uid: string): Promise<void[]> {
    throw Error('programming error');
  }
  public getTaskById(id: string | number): Observable<Task> {
    throw Error('programming error');
  }
  public getTasks_PagingObservable(): PagingObesrvable<Task, ITaskParam> {
    throw Error('programming error');
  }
}
