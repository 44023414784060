import { Address } from "./../address/address";
import { format } from "date-fns";
import { isNil, isNumber, isArray } from "lodash";
import { BidBase } from "../bid/bid-base";
import { currencyFormat, InvoiceStatus } from "../finance/invoice/i-item";
import { Invoice } from "../finance/invoice/invoice";
import { IPdfData, IPdfDataSection, logoTxt, logoTxt2 } from "./i-pdf-data";
import { getBid_DEBUG_ONLY, getInvRec_DEBUG_ONLY, getOrder_DEBUG_ONLY, IPdfInvoiceData } from "./i-pdf-invoice";
import { Order } from "../finance/order/order";
import { buildInvoiceSummary } from "../finance/order/order-helper";
import { EnumHelper } from "../utility/enum-helper";
import { ProductType } from "../product/product-type";
import { round, toSentence } from "../utility/helper";
import { TruckSummary } from "../product/truck";
import { NegoTermsAssigned } from "../nego-terms-base/nego-terms-assigned";

export class PdfInvoiceHandler {
  /** Starter Invoice Data */
  invoice: Invoice;

  /** Order related to the bid/invoice. */
  order: Order;

  /** Bid Data */
  bid: BidBase;

  /** Content extracted from Invoice to be input in to pdf document data
   * This can also be used to display html on the component page (may be under @media css.)
   */
  data: IPdfInvoiceData;

  /** Data to be input to npm package pdf. */
  documentData: IPdfData;

  /**
   * Remove the tax entries are are both present in the items as well as in the summary.
   */
  removeDuplicateTaxEntries() {
    for (const p of this.data.items) {
      for (const a of [p.completedInfo, p.pendingInfo]) {
        if (isArray(a) && a.length > 0) {
          p.items = p.items.filter((val) => !a.some((e) => e.name === val.name && e.amount === val.amount && e.name?.toLowerCase().includes("tax")));
        }
      }
    }
  }

  /** uses invoice data to generate content pdf. */
  buildContent(pymtIdx?: number | number[]) {
    let vendorComp = this.bid.vendorCompSummary?.address;
    let customerComp = this.bid.customerCompSummary?.address;

    let summary = buildInvoiceSummary(this.invoice, this.order, pymtIdx);

    if (!(vendorComp instanceof Address)) {
      vendorComp = Address.parse(vendorComp);
    }

    // Get the item details.
    // const comb = buildInvoiceOrderUI(this.invoice, this.order);

    this.data = {
      status: {
        receiptNo: this.bid.contactNoStringForInvoice,
        dateIssued: new Date(), // this.invoice.createdAt,
        status: InvoiceStatus[this.invoice.invoiceStatus]?.toUpperCase(),
      },
      from: {
        companyName: this.bid.vendorCompSummary.legalName,
        issuerId: "xxxx",
        address: {
          line1: vendorComp?.addressFormattedL1, // '9999 Street name 1A',
          line2: vendorComp?.addressFormattedL2, // 'New-York City NY 00000',
          line3: vendorComp?.country, //  'USA'
        },
      },
      to: {
        companyName: this.bid.customerCompSummary.legalName,
        receiver: "ClientID--",
        address: {
          line1: customerComp?.addressFormattedL1, //  '2000 Street name 1A',
          line2: customerComp?.addressFormattedL2, //  'New-York City NY 00000',
          line3: customerComp?.country, //  'USA'
        },
      },
      items: summary,
    };
    this.removeDuplicateTaxEntries();
  }

  /**
   * http://pdfmake.org/playground.html
   * @returns
   */
  /** Build Pdf using PDF Make. */
  buildPdf(invoice: Invoice, order: Order, bid: BidBase, pymtIdx?: number | number[], hst?: string): IPdfData {
    this.invoice = invoice;
    this.bid = bid;
    this.order = order;

    /** TODO: Remove this , this for  debugging only. */
    if (isNil(this.invoice)) {
      console.error("Remove Debugging only invoice data function. below.");
      this.invoice = getInvRec_DEBUG_ONLY();
      this.bid = getBid_DEBUG_ONLY();
      this.order = getOrder_DEBUG_ONLY();
    }

    // build the input content from invoice.
    this.buildContent(pymtIdx);

    // create the pdf document.
    const topRightWidth = 150;

    this.documentData = {
      content: [
        {
          columns: [
            /** LOGO */
            {
              image: logoTxt2,
              width: 150,
            },
            [
              /** Invoice Header */
              {
                text: "Invoice",
                color: "#333333",
                width: "*",
                fontSize: 28,
                bold: true,
                alignment: "right",
                margin: [0, 0, 0, 15],
              },
             
            ],
          ],
        },
        {
          columns:[
            {
              /** Company Address [Content] */
              stack: [
                this.getTopLeftSectionRow("Make", `${toSentence((this.bid.productSummary as TruckSummary)?.make)} (${(this.bid.productSummary as TruckSummary).title})`),
                this.getTopLeftSectionRow("Model Year", (this.bid.productSummary as TruckSummary).modelYear),
                this.getTopLeftSectionRow("VIN", (this.bid.productSummary as TruckSummary).vin),
                this.getTopLeftSectionRow("Plate No.", (this.bid.productSummary as TruckSummary).plateNo),
              ],
            },
            {
                  /** Receipt Section - Top Right Area. */
              stack: [
                this.getTopRightSectionRow("Contract No.", this.data.status.receiptNo),
                this.getTopRightSectionRow("Date Issued", format(this.data.status.dateIssued, "MMMM dd, yyyy")),
                this.dueDateOrComplete(this.data),
                this.getTopRightSectionRow("Jira", (this.bid.bidNegoTerms as NegoTermsAssigned).contractReference),
                this.getTopRightSectionRow("Currency", this.bid.rentSummary.currency),
                // hst is passed conditionally by the calling fn (only for CAD vendors)
                this.getTopRightSectionRow(!!hst ? "HST" : null, hst),
              ],
            },
          ]
        },
        {
          /** Top From and To  Company Info. [Header] */
          columns: [
            {
              text: "From",
              color: "#aaaaab",
              bold: true,
              fontSize: 14,
              alignment: "left",
              margin: [0, 20, 0, 5],
            },
            {
              text: "To",
              color: "#aaaaab",
              bold: true,
              fontSize: 14,
              alignment: "left",
              margin: [0, 20, 0, 5],
            },
          ],
        },
        {
          /** Top From and To  Company Info. [Content] */
          columns: [
            {
              text: this.data.from.companyName,
              bold: true,
              color: "#333333",
              alignment: "left",
              fontSize: 11,
            },
            {
              text: this.data.to.companyName,
              bold: true,
              color: "#333333",
              alignment: "left",
              fontSize: 11,
            },
          ],
        },
        {
          /** Company Address [Content] */
          columns: [
            {
              text: `${this.data.from.address.line1}\n ${this.data.from.address.line2}`,
              style: "invoiceBillingAddress",
            },
            {
              text: `${this.data.to.address.line1}\n ${this.data.to.address.line2}`,
              style: "invoiceBillingAddress",
            },
          ],
        },
        "\n",

        "\n",
        // {
        //   width: '100%',
        //   alignment: 'center',
        //   text: 'Invoice No. 123',
        //   bold: true,
        //   margin: [0, 10, 0, 10],
        //   fontSize: 15,
        // },
        //---- Invoice Items START -----
        ...this.getInvoiceItems(),
        //----- Invoice Items END END  -----
        {
          text: "NOTES",
          style: "notesTitle",
        },
        {
          text: this.invoice.rentalPlan.getMileageString(),
          style: "notesText",
        },
        {
          text: `Please make all cheques payable to ${this.bid.vendorCompSummary.legalName}. \n Thank you for your business`,
          style: "notesText",
        },
      ],
      styles: {
        notesTitle: {
          fontSize: 10,
          bold: true,
          margin: [6, 2],
        },
        notesText: {
          fontSize: 10,
          margin: [6, 2],
        },
      },
      defaultStyle: {
        columnGap: 20,
        //font: 'Quicksand',
      },
    };
    return this.documentData;
  }

  // #region PDF Doc Helper Function

  /** Top-Right Section Row data */
  getTopRightSectionRow(title: string, val, color = "black"): IPdfDataSection {
    return {
      columns: [
        {
          text: title,
          color: "#aaaaab",
          bold: true,
          width: "*",
          fontSize: 11,
          alignment: "right",
        },
        {
          text: val,
          bold: true,
          color,
          fontSize: 11,
          alignment: "right",
          width: 130,
        },
      ],
    };
  }
  /** Top-Left Section Row data */
  getTopLeftSectionRow(title: string, val, color = "black"): IPdfDataSection {
    return {
      columns: [
        {
          text: title,
          color: "#aaaaab",
          bold: true,
          width: 70,
          fontSize: 11,
          alignment: "left",
        },
        {
          text: val,
          bold: true,
          color,
          fontSize: 11,
          alignment: "left",
          width: "*",
        },
      ],
    };
  }

  // #endregion

  //#region table of items
  getInvoiceItems() {
    let invTblItem: string | IPdfDataSection;
    let invSummItem: string | IPdfDataSection;
    let data: Array<string | IPdfDataSection> = [];
    for (let idx = 0; idx < this.data.items.length; idx++) {
      const summItem = this.data.items[idx];
      // add the table for invoice items.
      data.push({
        /** Table Item Details */
        layout: {
          defaultBorder: false,
          hLineWidth: (i, node) => 1,
          vLineWidth: (i, node) => 1,
          hLineColor: (i, node) => (i === 1 || i === 0 ? "#bfdde8" : "#eaeaea"),
          vLineColor: (i, node) => "#eaeaea",
          hLineStyle: (i, node) => null, // if (i === 0 || i === node.table.body.length) return null;
          // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
          paddingLeft: (i, node) => 4,
          paddingTop: (i, node) => 4,
          paddingRight: (i, node) => 4,
          paddingBottom: (i, node) => 4,
          fillColor: (rowIndex, node, columnIndex) => "#fff",
        },
        table: {
          headerRows: 1,
          widths: ["*", 70, 70, 70],
          body: [
            [
              {
                text: this.data.items.length > 1 ? `Payment (Installment # ${idx + 1}` : "Payment Details",
                fillColor: "#eaf2f5",
                border: [false, true, false, true],
                margin: [0, 2, 0, 2],
                textTransform: "uppercase",
              },
              {
                text: "Rate",
                fillColor: "#eaf2f5",
                border: [false, true, false, true],
                margin: [0, 2, 0, 2],
                textTransform: "uppercase",
              },
              {
                text: "QTY",
                fillColor: "#eaf2f5",
                border: [false, true, false, true],
                margin: [0, 2, 0, 2],
                textTransform: "uppercase",
              },
              {
                text: "ITEM TOTAL",
                border: [false, true, false, true],
                alignment: "right",
                fillColor: "#eaf2f5",
                margin: [0, 2, 0, 2],
                textTransform: "uppercase",
              },
            ],
          this.setDatesInHeader(summItem),
            ...summItem.items.map((x) => {
              return [
                {
                  text: [
                    {text: x.name},
                    {
                      text: x.comments && x.commentNumber ? x.commentNumber : "",
                      sup: { offset: "7%", fontSize: 11 },
                    },
                    {
                      text: x.name == "Overage Miles" // Checking mileage field for adding date and start and end odometer
                          ? `\n ${PdfInvoiceHandler.getMatchingDates(summItem, x)}
                          Start Odometer: ${x.mileage?.startOdometer} miles, End Odometer: ${x.mileage?.endOdometer} miles, Used Miles: ${round((x.mileage?.endOdometer - x.mileage?.startOdometer), 0)} `
                          :'',
                    },
                   
                  ],
                  border: [false, false, false, true],
                  margin: [0, 1],
                  alignment: "left",
                  fontSize:11,
                },
                {
                  text: currencyFormat("CA", x.unitPrice), // isNumber(x.unitPrice) ? `$ ${x.unitPrice}` : ``,
                  border: [false, false, false, true],
                  margin: [0, 1],
                  alignment: "left",
                  fontSize:11,
                },
                {
                  text:
                    x.invoiceItemType == "adminFee"
                      ? ""
                      : (x.invoiceItemType == "mileage" || x.invoiceItemType == "minMileage" ) 
                      ? `${x.quantity} miles`
                      : x.invoiceItemType == "rent"
                      ? `${x.quantity} days`
                      : isNumber(x.quantity)
                      ? `${x.quantity}`
                      : ``,
                  border: [false, false, false, true],
                  margin: [0, 1],
                  alignment: "left",
                  fontSize:11,
                },
                {
                  border: [false, false, false, true],
                  text: isNumber(x.amount) ? `$ ${x.amount.toFixed(2)}` : ``,
                  fillColor: "#f5f5f5",
                  alignment: "right",
                  margin: [0, 1],
                  fontSize:11,
                },
              ];
            }),
            // blank row show betweenitems and total
            [
              {    
              text: "",
              border: [false, false, false, false],
              margin: [0, 7],
            },
              {    
                text: "",
                border: [false, false, false, false],
                margin: [0, 7],
              },     
              {    
                text: "",
                border: [false, false, false, false],
                margin: [0, 7],
              },      
              {    
                text: "",
                border: [false, false, false, false],
                margin: [0,7],
              },
            ],
             /** Table Summary Total */
            ...summItem.pendingInfo.map((p, i) => [
              {
                /** Put due date on the last item only. */
                // text:  (i === summItem.pendingInfo.length -1) ? `${p.name} [ Due on ${format(summItem.pymtSch.dueDate, 'dd-MMM-yyyy')} ]` : `${p.name}` ,
                text: i === summItem.pendingInfo.length - 1 ? `${p.name}` : `${p.name}`,
                border: [false, true, false, true],
                alignment: "left",
                margin:[0,1,0,1],
                /** bold last item only. */
                bold: i === summItem.pendingInfo.length - 1,
                fontSize:11,
              },
              {
                text: '',
                border: [false, true, false, true],
                alignment: "left",
                margin:[0,1,0,1],
                bold: true,
              },
              {
                text: '',
                border: [false, true, false, true],
                alignment: "left",
                margin:[0,1,0,1],
                bold: true,
              },
              {
                border: [false, true, false, true],
                text: currencyFormat("CA", +p.amount),
                alignment: "right",
                fillColor: "#f5f5f5",
                margin:[0,1,0,1],
                /** bold last item only. */
                bold: i === summItem.pendingInfo.length - 1,
                fontSize:11,
              },
            ]),
          ],
        },
      });

      data.push({
        layout: {
          defaultBorder: false,
          hLineWidth: (i, node) => 1,
          vLineWidth: (i, node) => 1,
          hLineColor: (i, node) => "#eaeaea",
          vLineColor: (i, node) => "#eaeaea",
          hLineStyle: (i, node) => null, // if (i === 0 || i === node.table.body.length) return null;
          // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
          paddingLeft: (i, node) => 10,
          paddingTop: (i, node) => 10,
          paddingRight: (i, node) => 3,
          paddingBottom: (i, node) => 3,
          fillColor: (rowIndex, node, columnIndex) => "#fff",
        },

        stack: summItem.items.map((x) => {
          return [
            /** Comment. */
            {
              text: (x.comments) // condition checking for comment
                ? [
                    { text: x.commentNumber, sup: { offset: "7%", fontSize: 11 } },
                    { text: x.comments, fontSize: 11 },
                  ]
                : "",
              alignment: "left",
              bold: true,
              color: "#aaaaab",
              margin: [4, 2],
            },
          ];

        }),
      });
    }
    data.push('\n');
    return data;
  }
  //#endregion

  // checking summItem.period dates with mileage.period dates

  public static getMatchingDates(sumItems, mileage) {
    let summItemsDates = `${format(sumItems.period?.from, "MMM dd, yyyy")} - ${format(sumItems.period?.to, "MMM dd, yyyy")}`;
    let mileageDates = `${format(mileage.period?.from, "MMM dd, yyyy")} - ${format(mileage.period?.to, "MMM dd, yyyy")}`;
    return summItemsDates == mileageDates ? "" : `${format(mileage.period?.from, "MMM dd, yyyy")} - ${format(mileage.period?.to, "MMM dd, yyyy")}`;
  }
  setDatesInHeader(sumItems){
    return (!!sumItems && sumItems?.period) ? [
      // Adding dates in the table
      {
        text:`${format(sumItems?.period?.from, "MMM dd, yyyy")} - ${format(sumItems?.period?.to, "MMM dd, yyyy")}`,
        border: [false, false, false, true],
        margin: [0, 1],
        alignment: "left",
        bold: true,
        fontSize:11,
      },
      {
        text: "",
        border: [false, false, false, true],
        margin: [0, 1],
        alignment: "left",
      },
      {
        text: "",
        border: [false, false, false, true],
        margin: [0, 1],
        alignment: "left",
      },
      {
        text: "",
        border: [false, false, false, true],
        margin: [0, 1],
        fillColor: "#f5f5f5",
        alignment: "right",
        fontSize:11,
      },
    ]
    : [
      {    
      text: "",
      border: [false, false, false, false],
      margin: [0, 0, 0, 0],
      alignment: "left",
    },
      {    
        text: "",
        border: [false, false, false, false],
        margin: [0, 0, 0, 0],
        alignment: "left",
      },     
      {    
        text: "",
        border: [false, false, false, false],
        margin: [0, 0, 0, 0],
        alignment: "left",
      },      
      {    
        text: "",
        border: [false, false, false, false],
        margin: [0, 0, 0, 0],
        alignment: "right",
        fillColor: "#f5f5f5",
      },
    ];

  }
  dueDateOrComplete(data){
    let d = data.items[0];
    let dc = d.completedInfo;
    let dp = d.pendingInfo;
    let value;
    if(dc.length > 0){
      dc.forEach((e,i) => {
        if(dc[dc.length-1].name.match('Received on')){
          value = this.getTopRightSectionRow("", dc[dc.length-1].name,'green');
        }
      });
    }
    if(dp.length > 0){
      dp.forEach((e,i) => {
        if(dp[dp.length-1].name.match('Payment Due')){
          value = this.getTopRightSectionRow("", `${dp[dp.length-1].name}`);
        }
      });
    } 
    return value;
  }
}
