export enum CompanyStatus {

  /** Not approved as lessee or lessor */
  notApproved = 0,

  /** Not approved as lessee or lessor */
  conditional = 1,

  /** Company which rents out trucks and trailers. */
  approved = 100,

}