// Base Route, Modules provider, imports etc. to be used in all of the websites.

import { environment } from '@trent/core/environments/environment';
import { BrowserModule, HammerModule } from '@angular/platform-browser';

import { AppComponent } from '../app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ServiceWorkerModule } from '@angular/service-worker';
import { LinkBtnComponent } from '../shared/link-btn/link-btn.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
// import { AngularFireModule } from '@angular/fire/compat';
import { RootStoreModule } from '@trent/store/root-store';
// import { FirebaseAuthentication } from '@awesome-cordova-plugins/firebase-authentication/ngx';
// import { IAuthCoreService } from '@trent/services/auth/iauth-core.service';
// import { AuthCoreWebService } from '@trent/services/auth/auth-core-web.service';
// import { AuthCoreCdvaService } from '@trent/services/auth/auth-core-cdva.service';
// import { FirebaseX } from '@awesome-cordova-plugins/firebase-x/ngx';
import { IonicStorageModule } from '@ionic/storage';
import { MaterialModule } from '../shared/share-module/material.module';

import { LocalStorage } from '@trent/services/local-storage/local-storage.service';
import { LocalStorageMobileWeb } from '@trent/services/local-storage/local-storage-mobile-web.service';
import { DialogModule } from '../shared/dialog/dialog.module';
// import { CameraModule } from './shared/camera/camera.module';
import { NotfoundComponent } from '../notfound/notfound.component';
// import { SearchShipmentComponent } from './shared/search-shipment/search-shipment.component';
import { FormsModule } from '@angular/forms';
// import { TrackingNoMaskDirective } from './directive/tracking-no-mask.directive';
import { MatSelectModule } from '@angular/material/select';
import { AlertBarComponent } from '../shared/alert-bar/alert-bar.component';
import { CkEditorModule } from '../shared/ck-editor/ck-editor.module';
import { SubPageModule } from '../shared/sub-page/sub-page.module';
import { SearchRentalModule } from '../shared/search-rental/search-rental.module';

// import { initializeApp } from 'firebase/app';
import { provideFirebaseApp, getApp, initializeApp } from '@angular/fire/app';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { provideAuth, connectAuthEmulator, getAuth } from '@angular/fire/auth';
// import { PdfJsonEditorModule } from '../shared/pdf-editor/pdf-json-editor.module';
import { AppCompShellComponent } from '../app-comp-shell.component';
import { logger } from '@trent/models/log/logger';
import { checkPlatform, mobileOrWebImports, mobileOrWebProviders } from './base-import-web';


// programming check.
checkPlatform(environment.platform);

// const mobileAppProviders = environment.platform === 'web' ? [] : [FirebaseX, FirebaseAuthentication];

import { AppInstallerAlertComponent } from '../shared/app-installer-alert/app-installer-alert.component';
import { ContactUsModule } from '../contact-us/contact-us.module';
import { UnsubscribePromotionModule } from '../unsubscribe/unsubscribe-promotion.module';
import { PromotionLeadsInfoPageModule } from '../promotions-leads-info-page/promotions-leads-info-page.module';

/** SSR will be set for angular universal inside  app function of projects/locus-loop/server/main.ts 
 * It is not used currently , but functionality is available.
*/
let isSSR = false;
export const setSSR = (ssr: boolean) => {
  isSSR = ssr;
};
let isAppInitialized = false;
// export const setFirebaseAppInitialized = (initialized: boolean) => {
//   isAppInitialized = initialized;
// };
export const initFirebaseApp = () => {
  try {
    if (!isAppInitialized) {
      console.log('app Initialized called first time', environment.fire);
      isAppInitialized = true;
      return initializeApp(environment.fire);
    }
    return getApp();
  } catch (error) {
    console.log('ERROR initializing firebase app. Using getApp as alternative.');
    return getApp();
  }
};

/** Common Imports to be used in App Module for various websites. */
export const baseDeclarations = [
  AppCompShellComponent,
  AppComponent,
  LinkBtnComponent,
  NotfoundComponent,
  AlertBarComponent,
  AppInstallerAlertComponent
];

/** Common Imports to be used in App Module for various websites. */
export const baseImports = [
  BrowserModule.withServerTransition({ appId: 'serverApp' }),
  // BrowserTransferStateModule,  
  // HttpClientModule, // temp for home
  // ...mobileOrWebImports,
  CkEditorModule,
  RootStoreModule,
  FontAwesomeModule,
  BrowserAnimationsModule,
  MaterialModule,
  MatSelectModule,
  SubPageModule,
  ContactUsModule,
  UnsubscribePromotionModule,
  PromotionLeadsInfoPageModule,
  // PdfJsonEditorModule,
  IonicStorageModule.forRoot({
    name: 'Database',
    driverOrder: ['indexeddb', 'localstorage']
  }),
  (environment.platform === 'web') ?
    ServiceWorkerModule.register('combined-worker.js', { enabled: environment.production, registrationStrategy: 'registerWhenStable:30000' }) : [],
  provideFirebaseApp(() => initFirebaseApp()),
  provideAuth(() => {
    /** Special Case for Angular Universal SSR. Note: See projects/locus-loop/server/server-post-compile-fix.js  script where global['ssr'] is set. */
    // if (isSSR) { //  'undefined' &&  __ssr) {
    //   console.log('SSR -> Firebase Auth was Faked!');
    //   return {} as any;
    //   return initializeAuth(getApp(), {
    //     persistence: browserLocalPersistence,
    //     popupRedirectResolver: browserPopupRedirectResolver,
    //   });
    // }
    logger.log('Non-SSR -> Firebase Auth was NOT Faked!');
    const auth = getAuth();
    if (false /* environment.useEmulators */) {
      connectAuthEmulator(auth, 'http://localhost:9099', { disableWarnings: true });
    }
    return auth;
  }),
  provideFirestore(() => {
    const firestore = getFirestore();
    // if (environment.useEmulators) {
    //     connectFirestoreEmulator(firestore, 'localhost', 8080);
    // }

    // The code below, use the cache offline data. may be a good advice on production
    // but debug is OFF.
    // enableMultiTabIndexedDbPersistence(firestore).then(
    //   () => resolvePersistenceEnabled(true),
    //   () => resolvePersistenceEnabled(false)
    // );

    return firestore;
  }),
  DialogModule,
  // CoreLazyModule,
  /** TEMP MODULES FOR DEBUG ONLY, REMOVE FROM PRODUCTION  */
  // CameraModule,
  // Added for search
  FormsModule,
  SearchRentalModule,
  // GpsControlModule,
  HammerModule,
  // provideDatabase(() => getDatabase()),
  // provideMessaging(() => getMessaging()),
  // provideStorage(() => getStorage())
  ...mobileOrWebImports
];


export const baseExports = [];

export const baseProviders = [
  ...mobileOrWebProviders,
  // { provide: IAuthCoreService, useClass: environment.platform === 'web' ? AuthCoreWebService : AuthCoreCdvaService },
  // mobileAppProviders,
  // local storage should be a fake class for angular universal SSR
  // server app.module will replace LocalStorage with a fake class.
  { provide: LocalStorage, useClass: LocalStorageMobileWeb }
];