import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectCompanyComponent } from './select-company.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MaterialModule } from '../share-module/material.module';
import {MatChipsModule} from '@angular/material/chips';



@NgModule({
  declarations: [SelectCompanyComponent],
  imports: [
    CommonModule,
    MaterialModule,
    MatAutocompleteModule,
    MatChipsModule
  ],
  exports: [SelectCompanyComponent]
})
export class SelectCompanyModule { }
