export enum TransactionStatus {
  planned = 100,
  scheduled = 200,
  arrived = 300,
  autoCompleted = 400,
  manuallyCompleted = 500,
  cancelled = 600,
  expired = 700,
}

