<ng-container *ngIf="!!m && !horizontalCarousel">
  <ng-container *ngIf="param.type===pTypeEnum.truck">
    <div class="alert" style="position: sticky;" *ngIf="showNoData" align="center" (click)="clearSearch()">
      <a>No Result found in your search criteria, alternate results are presented. Click to clear the search.
        <mat-icon>
          call_made</mat-icon></a>
    </div>
  </ng-container>
  <ng-container *ngIf="param.type===pTypeEnum.trailer">
<ng-container *ngTemplateOutlet="empty"></ng-container>
  </ng-container>
  
  <div class="pos-fixed d-grid-btn" [ngClass]="{'mobile-bottom-left': isHandset, 'desktop-top-right': !isHandset }" *ngIf="!showNoData">
    <ng-container *ngIf="isLeftVisible">
      <ng-container *ngIf="m.length>=8">
        <button mat-mini-fab color="primary" class="d-reverse" (click)="loadPrevious()" [disabled]="!showPrevious">
          {{firstCard}}
          <mat-icon *ngIf="isLeftVisible" >expand_less</mat-icon>
        </button>
        <button mat-mini-fab color="primary" class="d-reverse" (click)="loadNext()" [disabled]="!showNext">
          {{lastCard}}<mat-icon *ngIf="isLeftVisible">expand_more</mat-icon>
        </button>
      </ng-container>
      
      <button mat-mini-fab color="primary" type="button" (click)="openMap()">
        <mat-icon *ngIf="isLeftVisible">map</mat-icon>
        <mat-icon *ngIf="!isLeftVisible">list</mat-icon>
      </button>
    </ng-container>
    
  </div>
  <!-- <div class="pos-fixed d-grid-btn" [ngClass]="{'mobile-bottom-right': isHandset, 'desktop-top-right': !isHandset }" *ngIf="!showNoData">
    <button mat-mini-fab color="primary" type="button" (click)="loadNext()">
      <mat-icon *ngIf="isLeftVisible">expand_less</mat-icon>
    </button>
    <button mat-mini-fab color="primary" type="button" (click)="loadNext()">
      <mat-icon *ngIf="isLeftVisible">expand_more</mat-icon>
    </button>
  </div> -->
  <div class="pos-fixed" [ngClass]="{'mobile-top-right': isHandset}" *ngIf="showCancelSearch">
    <button mat-mini-fab color="primary" type="button" (click)="clearSearch()">
      <mat-icon>search_off</mat-icon>
    </button>
  </div>
  <ng-container *ngIf="isHandset; else desktop">
    <trent-slide-panel [activePane]="activePane" [overY]="isDesktopMapClicked" (activePanChg)="activePane = $event">

      <div leftPane>
        <ng-container *ngTemplateOutlet="rentalCards"></ng-container>
      </div>
      <div rightPane>
        <!-- <trent-map-container [mLabels]="mapLabels"></trent-map-container> -->
        <ng-container *ngIf="mapLabels">
          <trent-map-wrapper [mLabels]="mapLabels"></trent-map-wrapper>
        </ng-container>

      </div>
    </trent-slide-panel>
  </ng-container>
  <ng-template #desktop>
    <div [ngClass]="{'list-container': !isHandset}" *ngIf="isLeftVisible" (scroll)="onScroll($event)" [style.height]="containerHeight">
      <ng-container *ngTemplateOutlet="rentalCards"></ng-container>
    </div>
    <div class="rental-map" [ngClass]="{'display-none': isLeftVisible}">
      <!-- *ngIf="mapLabels" -->
      <div class="map-page-list">
        <ng-container *ngTemplateOutlet="rentalCards context: {miniCard: true}"></ng-container>
      </div>
      <trent-map-wrapper *ngIf="!isLeftVisible" [mLabels]="mapLabels"></trent-map-wrapper>
    </div>
  </ng-template>



</ng-container>

<ng-template #rentalCards let-miniCard='miniCard'>
  <trent-sub-page *ngIf="isMarketingAdsRequired && !isDesktopMapView" [pageId]="'rental-marketing-card'" [showHtmlEditMenu]="false" class="sub-page1"
    [ngClass]=" !isHandset && !isEditMode  ? 'sub-page-dt': ''"> </trent-sub-page>
   
   
   
    <div class="pos-fixed d-grid-btn" [ngClass]="{'mobile-bottom-left': isHandset, 'desktop-top-left': !isHandset }"
    >
      <ng-container *ngIf="!isLeftVisible">
        <ng-container *ngIf="m.length>=8">
          <button mat-mini-fab color="primary" class="d-reverse" (click)="loadPrevious()" [disabled]="!showPrevious">
            {{firstCard}}
            <mat-icon *ngIf="!isLeftVisible" >expand_less</mat-icon>
          </button>
          <button mat-mini-fab color="primary" class="d-reverse" (click)="loadNext()" [disabled]="!showNext">
            {{lastCard}}<mat-icon *ngIf="!isLeftVisible">expand_more</mat-icon>
          </button>
        </ng-container>
        
        <!-- <button mat-mini-fab color="primary" type="button" (click)="openMap()">
          <mat-icon *ngIf="!isLeftVisible">map</mat-icon>
          <mat-icon *ngIf="!isLeftVisible">list</mat-icon>
        </button> -->
      </ng-container>
      
    </div>




  <ng-container *ngFor="let r of m | slice: firstCard:lastCard">
    <mat-card appearance="outlined">
      <!-- <div class="share-btn">
        <button mat-icon-button color="primary" (click)="share(r.id)" matTooltip="Share" matTooltipPosition="above"
          class="share-btn">
          <mat-icon>share</mat-icon>
        </button>
      </div> -->

      <trent-card [rental]="r" (emitShowOnMapById)="showOnMapById($event)" [showMiniCard]="miniCard"></trent-card>

      <div class="buttons">
        <ng-container *ngIf="r.vendorCompSummary.cid!==defaultCompany?.cid else myRental">
          <button mat-button class="ml-auto" color="primary" (click)="toBid(r.id)" matTooltip="Rent"
            matTooltipPosition="above">Rent
            <mat-icon>monetization_on</mat-icon>
          </button>
          <button mat-icon-button color="primary" (click)="share(r)" matTooltip="Share" matTooltipPosition="above">
            <mat-icon>share</mat-icon>
          </button>

        </ng-container>
        <ng-template #myRental>
          <button mat-button type="button" color="primary" (click)="toVendorBids(r.id)" matTooltip="Open Bids"
            matTooltipPosition="above">Open bids
            <mat-icon>monetization_on</mat-icon>
          </button>
          <button mat-icon-button color="primary" (click)="share(r)" matTooltip="Share" matTooltipPosition="above">
            <mat-icon>share</mat-icon>
          </button>

          <button mat-button type="button" color="primary" (click)="toReplacement(r.id)" matTooltip="Replacement"
            matTooltipPosition="above" *ngIf="r.isReplacementUnit">Replacement
            <mat-icon>monetization_on</mat-icon>
          </button>
          <button mat-button type="button" color="primary" (click)="edit(r.id)" matTooltip="Edit"
            matTooltipPosition="above">
            <mat-icon>edit</mat-icon>
          </button>
        </ng-template>
      </div>
    </mat-card>

  </ng-container>
</ng-template>
<ng-container *ngIf="m?.length === 0">
  <mat-card appearance="outlined">
     <mat-card-title>No Data Found</mat-card-title>
  </mat-card>
</ng-container>
<ng-template #empty>
  <mat-card appearance="outlined" >
    <mat-card-header>
      <div mat-card-avatar>
        <mat-icon color="primary" svgIcon="truckIcon" *ngIf="prodType === pTypeEnum.truck"></mat-icon>
        <mat-icon color="primary" svgIcon="trailerIcon" *ngIf="prodType === pTypeEnum.trailer"></mat-icon>
      </div>
      <mat-card-title>No Inventory Online</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <p style="margin-bottom: 1px;">Trailer Inventory is not online yet.</p>
      <p style="margin-top: 1px;">Please contact <a href="tel:1-866-PRIDE-24" class="a-tel">1-866-PRIDE-24</a> </p>
      <mat-label>Or check out online Truck inventory</mat-label>
    </mat-card-content>
    
    <mat-card-actions class="empty-list-actions">
      <button mat-stroked-button  type="button" color="primary" [routerLink]="'/rent-option/truck/list'">
        <mat-icon color="primary" svgIcon="truckIcon"></mat-icon><span style="padding-left: 10px;">Truck</span>

        <!-- <mat-icon>local_shipping</mat-icon><span style="padding-left: 10px;">Truck</span> -->
      </button>

    </mat-card-actions>
  </mat-card>
</ng-template>
<ng-container *ngIf="!!m && m.length > 0 && !!horizontalCarousel">
  <ng-container *ngIf="!isHandset">
    <!-- cards: {{m.length}}
    pages: {{horizontalPages}}<br>
    horizontalIndex: {{horizontalIndex}} <br>
    horizontalIncrement: {{horizontalIncrement}} <br>
    horizontalStart: {{horizontalStart}}<br>
    horizontalEnd: {{horizontalEnd}} -->
    <div class="hor-tab-cont-dtop">
      <button style="margin-left: 10px" mat-mini-fab color="primary" (click)="subHorizontalIncrement()" [disabled]="horizontalIndex===1"><mat-icon>arrow_back_ios</mat-icon></button>
      <mat-tab-group mat-align-tabs="start" [selectedIndex]="horizontalIndex">
        <ng-container *ngFor="let hIndex of hPages">
          <mat-tab>
            <div class="hor-card-cont-dtop" >
            <ng-container *ngFor="let r of m | slice: horizontalStart:  horizontalEnd">
            
              <mat-card>
                <trent-card [rental]="r" (emitShowOnMapById)="showOnMapById($event)" [horizontalCarousel]="true"></trent-card>
                <button mat-stroked-button color="primary" (click)="toBid(r.id)">Details</button>
              </mat-card>
            </ng-container>
          </div>
          </mat-tab>
        </ng-container>
        
        </mat-tab-group>
     <button style="margin-right: 10px" mat-mini-fab color="primary" (click)="addHorizontalIncrement()" [disabled]="horizontalIndex>=horizontalPages"><mat-icon>arrow_forward_ios</mat-icon></button>
    
    </div>
  </ng-container>
  <ng-container *ngIf="isHandset">
    <div class="hor-tab-cont-mobile">
      <button class="hor-cards-button-left" mat-mini-fab color="primary"
      (click)="subHorizontalIncrement()" [disabled]="horizontalIndex===1"><mat-icon>arrow_back_ios</mat-icon></button>
      <mat-tab-group mat-align-tabs="start" [selectedIndex]="horizontalIndex" class="mat-tab-group-mobile" >
        <mat-tab>
          <ng-container *ngFor="let r of m | slice: horizontalStart:horizontalEnd">
            <mat-card (swipeleft)="addHorizontalIncrement()" (swiperight)="subHorizontalIncrement()">
              <trent-card [rental]="r" (emitShowOnMapById)="showOnMapById($event)" [horizontalCarousel]="true"></trent-card>
              <button mat-stroked-button color="primary" (click)="toBid(r.id)">Details</button>

            </mat-card>
          </ng-container>
        </mat-tab>
      </mat-tab-group>
      <button class="hor-cards-button-right"  mat-mini-fab color="primary" (click)="addHorizontalIncrement()" 
      [disabled]="horizontalIndex>=horizontalPages"><mat-icon>arrow_forward_ios</mat-icon></button>
    </div>
  </ng-container>

</ng-container>





<!-- 

  <div [ngClass]="{'grid-container-mobile': browser.screen===1, 'grid-container-desktop': browser.screen===3}" *ngIf="false">
    <ng-container *ngFor="let p of m">
      <mat-card class="rental-card">
        <div>
          <i *ngIf="!!notLatestTitle" style="color: red">{{notLatestTitle}}</i>
          <mat-card-header>
            <div mat-card-avatar [ngClass]="{'truck-header-image': p.productSummary.productType === pTypeEnum.truck, 
          'trailer-header-image': p.productSummary.productType === pTypeEnum.trailer, 
          'driver-header-image': p.productSummary.productType === pTypeEnum.driver}"></div>
            <mat-card-title>{{shortTitle(p.productSummary.make)}}, {{p.productSummary.modelYear}}</mat-card-title>
            <mat-card-subtitle> {{p.summary}}</mat-card-subtitle>
          </mat-card-header>
          <ng-container *ngIf="!!p.pictures">
            <trent-photo-viewer #picDetail name='picDetail' [pics]='p.pictures' [isThumbNails]="false">
            </trent-photo-viewer>
          </ng-container>
          <mat-card-content>
            <h4>
              <ng-container *ngIf="!!p.rentPerDay">
                Rent: ${{p.rentPerDay}}/day
              </ng-container>
            </h4>
            <h4 *ngIf="p.rateString.monthly">{{p.rateString.monthly}}</h4>
            <h4 *ngIf="p.rateString.weekly">{{p.rateString.weekly}}</h4>
            <h4 *ngIf="p.rateString.daily">{{p.rateString.daily}}</h4>
            <ng-container *ngFor="let d of p.openDateRange; let i = index">
              <h5 color="primary">
                <mat-icon inline color="primary">date_range</mat-icon>{{d.start | date}} - {{d.end | date}}
              </h5>
            </ng-container>
            <p>
              <mat-icon inline color="primary">place</mat-icon>{{p.startAddress.addressFormated}}
            </p>
          </mat-card-content>
        </div>
        <mat-card-actions class="mobile-view-button">
          <button mat-raised-button color="primary" (click)="toBid(p)"
            *ngIf="p.vendorCompSummary.cid !== defaultCompany.cid">RENT</button>
          <button mat-raised-button color="primary" (click)="toMyRentals()"
            *ngIf="p.vendorCompSummary.cid === defaultCompany.cid">My Rental</button>
          <button mat-button color="primary">
            <mat-icon>share</mat-icon>
          </button>
          <button mat-button color="primary">
            <mat-icon>save</mat-icon>
          </button>

        </mat-card-actions>
      </mat-card>
    </ng-container>
  </div>

 -->