import { TripActivityType } from './trip-activity-type';
import { Exclude, instanceToInstance, plainToInstance, Expose, Type } from 'class-transformer';
import { Address } from '../address/address';
import { isDate, isBefore, differenceInHours } from 'date-fns';
import { sanitizeDate, sanitizeDateIPoint, trackingNumberLength, TimerHelper } from '../utility';
import { BaseModel } from '../base';
// tslint:disable-next-line:max-line-length
import { ValidatorOptions, IsDefined, IsDate, IsNumber, IsBoolean, ValidateNested, Min, ValidateIf, IsString, MinLength, Length } from 'class-validator';
import { IValidationMsg } from '../error-handling/validation-info';
import { findDistance } from '../utility/distf';
import { TripBase } from './trip-base';
import { TransactionStatus } from './transaction-status';
import { BorderListItem } from '../promo/border-list';
// import { Picture, LocStaticMap } from '@trent/models';
import { IPoint } from '../product-search/interfaces';
import { VehicleList } from '../promo/promo-vehicle';
import { Picture } from '../inspection/picture';
import { LocStaticMap } from './loc-static-map';
import { TripTrackerList } from '../promo/trip-tracker-list';
import { pickBy } from 'lodash';
import { ItemStatus } from '../promo/item-status';
import { UserEmail } from '../user/user-email';
import { getAdjustedTime, getBrowserTime } from '../utility/utl-datef';
import { logger } from '../log/logger';

export interface HOS {
  /** HOS break, time in hours when not driving */
  offDuty: number;
  /** Driving time in hours after the last break on the transaction */
  balanceDrivingTime: number;
  /** commulative driving between resets */
  commDrivingTime: number;
  /** is request required */
  isReset: boolean;
}

@Exclude()
export class TripTransaction extends BaseModel {

  constructor() {
    super();
    this.address = new Address();
    this.hos = { offDuty: 0, balanceDrivingTime: 0, commDrivingTime: 0, isReset: false };
    // this.arrivalTimeBrowser = new Date();
  }

  /** Helper only, not saved in db. */
  tripIndex: number;
  /** Helper only, not saved in db. */
  departure: Date;

  @Expose()
  @IsDefined()
  activity: TripActivityType;

  @Expose()
  @ValidateNested({ message: 'Address info is required' })
  @IsDefined({ message: 'Address information is required' })
  @Type(() => Address)
  address: Address;

  /** Distance between the current and the previus location in miles. */
  @Expose()
  @Min(0, { message: 'Distance is required', groups: [Address.gName] })
  distance = 0;

  /** driving duration time (hours) between the current and the previous location. */
  @ValidateIf(o => !!o.isApptApp)
  @Expose()
  @Min(0, { message: 'DrivingDuration is required', groups: [Address.gName] })
  drvDuration = 0;


  /** Arrival Time at the location. Depending upon the driving conditions
   * It is input only at the start of the journey. During the intermediate
   * steps, it depends upon the traffic, when you read. */
  @Expose()
  @IsDate({ message: 'Date and Time is required' })
  arrivalTime: Date;

  private _arrivalTimeBrowser;
  get arrivalTimeBrowser() { return this._arrivalTimeBrowser; }
  set arrivalTimeBrowser(t: Date) {
    this._arrivalTimeBrowser = t;
    this.arrivalTime = getAdjustedTime(this.address.zoneString, this.address.utcOffset, t);
  }
  /** Waiting / Unloading/ Inspection time spent (in hour) at the activity e.g. waiting time at the delivery/pickup */
  @Expose()
  @IsNumber()
  dwellDuration = .25;

  /** How early driver will arrive, ahead of his appointment time.
   * Not saved in db. Depends upon driving conditions.
   * Additional dwell time is the difference between estimated arrival time - appointment time (early arrival). */
  addDwellDuration = 0;


  /** Some destinations may or may not have an appointment time setup required. */
  @Expose()
  @IsBoolean({ message: 'Is Appointment Applicable' })
  isApptApp = false;


  /** Appointment booked time. per contract requirement. May be optional */
  @Expose()
  apptDateTime: Date; // optional appointment applicable for some pickups/deliveries. Potentially for, hook (rental)

  private _apptDateTimeTz;
  get apptDateTimeTz() { return this._apptDateTimeTz; }
  set apptDateTimeTz(t: Date) {
    this._apptDateTimeTz = t;
    this.apptDateTime = getAdjustedTime(this.address.zoneString, this.address.utcOffset, t);
  }
  /** Expected window of appointment in hours. */
  @Expose()
  apptWindow: number; // appointment window in hours

  /** Hours of service properties. */  // Possible refactor to use IHOS, different property names
  @Expose()
  hos: HOS;

  /** Transaction status */
  @Expose()
  transactionStatus: TransactionStatus;

  /** arrived and departed at time, set by IOT/phone gps service */
  @Expose()
  autoCompleteTime: { enteredAt: Date, leftAt: Date };

  /** arrived and departed at time adjusted to location timezone */
  get autoCompleteTimeTz(): { enteredAt: Date, leftAt: Date } {
    if (!this.autoCompleteTime) { return null; }
    let enteredAt: Date;
    let leftAt: Date;
    if (!!this.autoCompleteTime.enteredAt) {
      enteredAt = getBrowserTime(this.address.zoneString, this.address.utcOffset, this.autoCompleteTime.enteredAt);
    }
    if (!!this.autoCompleteTime.leftAt) {
      leftAt = getBrowserTime(this.address.zoneString, this.address.utcOffset, this.autoCompleteTime.leftAt);
    }
    return { enteredAt, leftAt };
  }

  /** gps data at destination location.*/ // this needs to restricted to maximum points
  @Expose()
  gpsData: { iPoint: IPoint, timeStamp: Date }[];

  /** Border crossing set when way point selected as border crossing.*/ // this needs to restricted to maximum points
  @Expose()
  borderWayPoint: { [key: string]: BorderListItem };

  borderName?: string; // added for UI

  truck?: string; // added to fix the production error
  trailer?: string; // added to fix the production error
  freightCustomer?: string[]; // added to fix the production error
  trackingNos: string[]; // added to fix the production error
  delTrackingNos?: { [key: string]: { pickedFrom: string; pickedAt: Date }; }; // added to fix the production error
  pod?: Picture[]; // added to fix the production error
  referenceNos?: string[]; // added to fix the production error
  onlyGeoLoc?: boolean; // added to fix the production error
  isBorderCrossing?: boolean; // added to fix the production error
  clearDelivery?: boolean; // added to fix the production error, actually drop property
  isLoaded?: boolean; // added to fix the production error, actually hookup property
  /** Location Map for the waiting time report.*/
  @Expose()
  locStaticMap?: LocStaticMap;

  /** Estimated time of arrival updated on auto complete */
  @Expose()
  eta?: Date;

  /** Estimated time of arrival adjusted to location timezone, updated on auto complete */
  get etaTz(): Date {
    if (!this.eta) { return null; }
    return getBrowserTime(this.address.zoneString, this.address.utcOffset, this.eta);
  }

  public static parse(obj) {
    try {
      if (obj instanceof TripTransaction) {
        // custom parse for trip transaction
        if (!obj.isApptApp) {
          obj.apptDateTime = undefined;
          obj.apptWindow = undefined;
        }
        return obj;
      }
      if (obj == null) { return null; }
      // obj = sanitizeDateIPoint(obj, ipointType);
      obj = sanitizeDateIPoint(obj);
      const m = plainToInstance<TripTransaction, any>(TripTransaction, obj);
      m.sanitize();
      return m;
    } catch (error) {
      logger.log('Error happened during parse', error);
      return null;
    }
  }

  // addTrackingNo() { } // added to fix the production error
  addTrackingNo(): void {
    let t = Math.random().toFixed(16).toString().replace('0.', '');
    logger.log('tracking', t);
    t = `${t.substr(0, 5)}-${t.substr(5, 5)}-${t.substr(10)}`;
    logger.log('tracking', t);
    this.trackingNos = this.trackingNos ? this.trackingNos : [];
    if (!this.trackingNos.includes(t)) {
      this.trackingNos.push(t);
    }
  }

  /** Effective Appointment time. It is the approx arrival time when appointment is not defined. */
  get apptDateTimeCalc() { return this.isApptApp ? this.apptDateTime : this.arrivalTime; }

  /** UI Helper. End time is not stored in db. */
  get apptEndDateTime(): Date {
    if (!!this.isApptApp && !!this.apptDateTime && !!this.apptWindow) {
      return new Date(this.apptDateTime.valueOf() + this.apptWindow * 60 * 60 * 1000);
    } else { return null; }
  }
  /** UI Helper. differnce between appointment time and arrival time. */
  get apptMargin(): number {
    let d: number;
    // before arrival use planned arrival time or eta (when avialble)
    if (!this.transactionStatus || this.transactionStatus < TransactionStatus.arrived) {
      const eta = !!this.eta ? this.eta : this.arrivalTime;
      if (!!this.isApptApp && !!this.apptDateTime && !!eta) {
        d = (this.apptDateTime.valueOf() - eta.valueOf()) / 3600000.0;
        // if d is zero or negative return null ( late flag will be shown )
        if (d <= 0) { d = null; }
      } else { d = null; }
    } else if (this.transactionStatus === TransactionStatus.autoCompleted
      && !!this.autoCompleteTime.leftAt && !!this.autoCompleteTime.enteredAt) {
      // when trip is completed show waiting time
      d = (this.autoCompleteTime.leftAt.valueOf() - this.autoCompleteTime.enteredAt.valueOf()) / 3600000.0;
    } else { d = null; }
    return d;
  }

  // get dateTimeIso(): string { return isDate(this.arrivalTime) ? this.arrivalTime.toISOString() : ''; }
  // set dateTimeIso(val) { this.arrivalTime = sanitizeDate(val); } // sanitizeIonicDatePicker(val); }

  sanitize() {
    super.sanitize();
    this.arrivalTime = sanitizeDate(this.arrivalTime);
    if (this.apptDateTime) { this.apptDateTime = sanitizeDate(this.apptDateTime); }
    if (this.eta) { this.eta = sanitizeDate(this.eta); }
    if (this.autoCompleteTime && this.autoCompleteTime.enteredAt) {
      this.autoCompleteTime.enteredAt = sanitizeDate(this.autoCompleteTime.enteredAt);
    }
    if (this.autoCompleteTime && this.autoCompleteTime.leftAt) {
      this.autoCompleteTime.leftAt = sanitizeDate(this.autoCompleteTime.leftAt);
    }
    for (const key in this.gpsData) {
      if (this.gpsData.hasOwnProperty(key)) {
        const g = this.gpsData[key];
        g.timeStamp = sanitizeDate(g.timeStamp);
      }
    }

    if (!!this.arrivalTime) {
      this._arrivalTimeBrowser = getBrowserTime(this.address.zoneString, this.address.utcOffset, this.arrivalTime);
    }
    if (!!this.apptDateTime) {
      this._apptDateTimeTz = getBrowserTime(this.address.zoneString, this.address.utcOffset, this.apptDateTime);
    }
    if (this.delTrackingNos) {
      for (const key in this.delTrackingNos) {
        if (this.delTrackingNos.hasOwnProperty(key)) {
          const ele = this.delTrackingNos[key];
          ele.pickedAt = sanitizeDate(ele.pickedAt);
        }
      }
    }
    // this.apptDateTime = sanitizeDate(this.apptDateTime);
  }

  clone() {
    const t = instanceToInstance(this);
    t.sanitize();
    return t;
  }

  validateSync(options?: ValidatorOptions, trip?: TripBase, index?: number, truckL?: VehicleList, trailerL?: VehicleList): IValidationMsg {
    const r = this.validateSyncBase(this, options);
    if (!!this.isApptApp) {
      if (!this.apptDateTime) {
        r['apptDateTime'] = ['apppointment date and time is required'];
      }
      // if (isBefore(this.apptDateTime, addNHours(- 12, this.arrivalTime))) {
      //   r['apptDateTime'] = [`cannot meet appointment based estimated arrival time ${this.arrivalTime}`];
      // }
    }
    // if (!!this.getBorderWayPtReq(trip, index) && !this.borderWayPoint) {
    //   r['borderWayPoint'] = ['select border crossing'];
    // }
    if (!this.transactionStatus && isBefore(this.arrivalTime, new Date())) {
      r['arrivalTime'] = ['time cannot be in past'];
    }
    if (!!r.address_postalZipCode && r.address_postalZipCode.length > 0) {
      r['address_addressFormated'] = ['enter complete address'];
    }
    return r;

  }


  /** Flags if late for the appointment */
  get lateAptFlag(): boolean {
    if (!this.isApptApp) { return false; }
    if (!this.eta) { return false; }
    if (this.transactionStatus >= TransactionStatus.arrived) { return false; }
    if (isBefore(this.eta, this.apptDateTime)) { return false; } else { return true; }
  }
  /**
   * based in email returns user id for logged in users email for anonomus users
   * @param email email (key)
   * @param tripTrackerL TripTrackerList
   */
  setNotifiersIdOrEmail(email: string, tripTrackerL: TripTrackerList) {
    const notifiers = this.freightCustomer ? this.freightCustomer.slice() : [];
    const activeEmails = pickBy(tripTrackerL.listForUI, (value) => value.itemStatus === ItemStatus.active);
    if (!!tripTrackerL && !!email &&
      !!Object.keys(activeEmails).includes(email) && !notifiers.includes(email)) {
      if (!!activeEmails[email].tripTrackerId) {
        notifiers.push(activeEmails[email].tripTrackerId);
      } else {
        notifiers.push(email);
      }
    }
    return notifiers;
  }
  removedNotifiersIdOrEmail(email: string, tripTrackerL: TripTrackerList) {

    if (!email || !tripTrackerL) {
      return;
    }
    const emailOrId = !!tripTrackerL.listForUI[email].tripTrackerId ? tripTrackerL.listForUI[email].tripTrackerId : email;
    if (!emailOrId || !this.freightCustomer.includes(emailOrId)) {
      return;
    }
    const i = this.freightCustomer.findIndex(f => f.includes(emailOrId));
    this.freightCustomer.splice(i, 1);
  }
  /** intialize autcompleted properties */
  initAutoCompleteProps() {
    this.autoCompleteTime = null;
    this.locStaticMap = null;
    // this.eta = null;  // eta is not reset as trip update overwrites eta
    this.gpsData = null;
  }
  getPendingDeliveries(trip: TripBase)
    : { [key: string]: { [key: string]: { pickedFrom: string; pickedAt: Date } }; } {
    if (this.activity !== TripActivityType.drop) {
      return null;
    }
    const p: { [key: string]: { [key: string]: { pickedFrom: string; pickedAt: Date } }; } = {};
    p[this.trailer] = {};
    const t: { [key: string]: { pickedFrom: string; pickedAt: Date } } = {};
    // const c = trip.getOpenTrackingNos(index);
    const c = trip.openPickupTrackingNos;
    for (const k in c) {
      if (c.hasOwnProperty(k)) {
        const val = c[k];
        // const pickup = trip.tripSequence.find(f => f.trackingNos && f.trackingNos.includes(k));
        if (this.trailer === val.pickupTrailer) {
          t[k] = { pickedFrom: val.pickedFrom, pickedAt: val.pickedAt };
        }
      }
    }
    p[this.trailer] = t;
    return p;

  }
  valdateDelTrackingNo = (): string => {
    if (Object.keys(this.delTrackingNos).length > 5) {
      return 'Maximum 5 tracking number allowed';
    }
    if (Object.keys(this.delTrackingNos).find(f => typeof f !== 'string' || f.length > trackingNumberLength)) {
      return 'Invalid Tracking number';
    }
    for (const key in this.delTrackingNos) {
      if (this.delTrackingNos.hasOwnProperty(key)) {
        const element = this.delTrackingNos[key];
        if (typeof element.pickedFrom !== 'string' || element.pickedFrom.length > 100) {
          return `${key} pick up address`;
        }
        if (!!element.pickedAt && !(element.pickedAt instanceof Date)) {
          return `${key} invalid pickup date`;
        }
      }
    }
    return null;
  }
  checkLoadedTrailerAddress(trailerL: VehicleList, trailer: string): { success: boolean, msg?: string } {
    if (!trailerL.list[trailer] || !trailerL.list[trailer].pendingDelivery || !this.address.geoLoc) {
      return { success: true };
    }
    for (const key in trailerL.list[trailer].pendingDelivery) {
      if (trailerL.list[trailer].pendingDelivery.hasOwnProperty(key)) {
        const element = trailerL.list[trailer].pendingDelivery[key];
        if (element.dropedAt.geoLoc.geohash !== this.address?.geoLoc.geohash) {
          return { success: false, msg: `System shows loaded trailer ${trailer} is dropped at ${element.dropedAt.addressFormated}` };
        }
      }
    }
    return { success: true };
  }
  /** 
   * returns appoint status, based on arrival time, eta and appointment time. 
   * @param s: status string
   * @param n: margin in number of hours
   * 
   * UI helper. . e.g 1h 30m before appointment, late for appointment */
  get apptStatus(): { s: string, n?: number } {
    if (!this.isApptApp) {
      return null;
    }
    if (this.transactionStatus > TransactionStatus.arrived) {
      return null;
    }
    const eta = this.eta ? this.eta : this.arrivalTime;
    if (!this.lateAptFlag) {
      const margin = (this.apptDateTime.valueOf() - eta.valueOf()) / 3600000.0;
      return { n: margin, s: `${TimerHelper.hoursToHrsMinsString(margin)} before appointment` };
    } else {
      return { s: 'Late for appointment' };
    }

  }

}
