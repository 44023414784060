import { TripTransaction } from './trip-transaction';
import { Exclude, plainToInstance, instanceToInstance, Expose } from 'class-transformer';
import { sanitizeDateIPoint } from '../utility';
import { ValidatorOptions, IsDefined } from 'class-validator';
import { IValidationMsg } from '../error-handling/validation-info';
import { Picture } from '../inspection/picture';
import { isEmpty } from 'lodash';
import { VehicleList } from '../promo/promo-vehicle';
import { TripBase } from './trip-base';
import { Address } from '../address/address';
import { logger } from '../log/logger';


@Exclude()
export class Delivery extends TripTransaction {

  public static readonly podPicWidth = 1063;
  public static readonly podPicHeight = 1375;

  @Expose()
  @IsDefined()
  delTrackingNos: { [key: string]: { pickedFrom: string; pickedAt: Date }; }; // will be saved in dB

  @Expose()
  pod?: Picture[] = [];

  public static parse(obj) {
    try {
      if (obj instanceof Delivery) {
        // custom parse for trip transaction
        if (!obj.isApptApp) {
          obj.apptDateTime = undefined;
          obj.apptWindow = undefined;
        }
        return obj;
      }
      if (obj == null) { return null; }
      // obj = sanitizeDateIPoint(obj, ipointType);
      obj = sanitizeDateIPoint(obj);
      const m = plainToInstance<Delivery, any>(Delivery, obj);
      m.sanitize();
      m.address = Address.parse(m.address);
      return m;
    } catch (error) {
      logger.log('Error happened during parse', error);
      return null;
    }
  }
  clone() {
    const t = instanceToInstance(this);
    t.sanitize();
    return t;
  }

  validateSync(options?: ValidatorOptions, trip?: TripBase, index?: number, truckL?: VehicleList, trailerL?: VehicleList): IValidationMsg {
    const r = this.validateSyncBase(this, options);
    if (!this.delTrackingNos || isEmpty(this.delTrackingNos)) {
      r['delTrackingNos'] = [`select delivery tracking number(s)`];
    }
    if (this.delTrackingNos && !!this.valdateDelTrackingNo()) {
      r['delTrackingNos'] = [this.valdateDelTrackingNo()];
    }

    return r;
  }
}
