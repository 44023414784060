import { IAgreement } from '@trent/models/user-agreement';
import { IAuthCoreService } from '@trent/services/auth/iauth-core.service';
import { UserProfile } from './../models/user/user-profile';
import { Injectable } from '@angular/core';
import { FirestoreService } from '@trent/services/firestore.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseHttpService } from '@trent/services/base-http.service';
import { Store } from '@ngxs/store';
import { promiseWraper, getParamObjFromUrl } from '@trent/models/utility';
import { ProfileFromDbLoaded, ProfileFromDbRequested } from '@trent/store/auth-store';
import { readErrorMessage } from '@trent/models/error-handling';
import { Router, ActivatedRoute } from '@angular/router';
import { PagingObesrvable } from '@trent/models/observable-util/paging-obesrvable';
import { IUserParam, userSerachServerQuery, DrivingFor } from '@trent/models/user';
import { Paging } from '@trent/models/observable-util/paging';
import { Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { CarrierCompanySummary } from '../models/company/company-carrier';
import { instanceToPlain } from 'class-transformer';
import { SetNewUser } from '@trent/store/auth-store/auth.state';
import { CommPreference } from '@trent/models/setting/comm-preference';
import { logger } from '@trentm/log/logger';
import { UserCredential } from 'firebase/auth';
import { getAdditionalUserInfo } from '@angular/fire/auth';


@Injectable()
export class UserService extends BaseHttpService {

  constructor(store: Store, private http: HttpClient, private aroute: ActivatedRoute,
    private db: FirestoreService, private router: Router, private authCore: IAuthCoreService) {
    super(store);
    this.apiName = 'api';
  }

  getUserById(id: string | number) {
    return this.db.docWithInjectedId$<UserProfile>(`${UserProfile.collectionName}/${id}`);
  }

  requestPhoneToken(phone: string) {
    const headers = this.addBearerToken();
    return this.http.post<{ status: string }>(this.getApiUrl('/user/request-phone-token'), { phone }, { headers: headers }).toPromise();
  }

  verifyAndUpdatePhone(param: { phone: string; token: string }) {
    // If new user was invited with some actions, like add as a driver by external company, theses params will be
    // sent to server to be used while creating the profile.
    // const fparams = getParamObjFromUrl(this.aroute.snapshot.queryParams.returnUrl);
    const fparams = this.aroute.snapshot.queryParams;
    const headers = this.addBearerToken();
    return this.http
      .post<{ status: string }>(this.getApiUrl('/user/verify-and-update-phone'),
        { phone: param.phone, token: param.token, fparams }, { headers: headers })
      .toPromise();
  }

  async sendVerificationEmail() {
    this.authCore.sendVerificationEmail();
  }

  getCarriersByUserPhone(phone: string) {
    const headers = this.addBearerToken();
    return this.http.post<{ carriers: CarrierCompanySummary[] }>(this.getApiUrl('/user/carriers-by-driver-phone'),
      { phone }, { headers: headers }).toPromise();
  }

  /** Sync firebase general profile information with the db user profile entry.  */
  async updateProfile(uid: string, profile?: UserProfile) {
    const headers = this.addBearerToken();
    return this.http.post<{ uid: string; profile: UserProfile }>(
      this.getApiUrl(
        '/user/profile-update-general'), // '/user/profile-update-general'
      { uid, profile },
      // JSON.stringify({ uid, profile }),
      { headers: headers }).toPromise();
  }

  async updateEmail(uid: string, email: string) {
    const headers = this.addBearerToken();
    return this.http.post<UserProfile>(this.getApiUrl('/user/profile-update-email'), { uid, email }, { headers: headers }).toPromise();
  }
  async updateDrivingFor(uid: string, d: DrivingFor, taskId?: string) {
    const headers = this.addBearerToken();
    const striped = d.removeCComp();
    const drivingFor = instanceToPlain(striped);
    return this.http.post<UserProfile>(this.getApiUrl('/user/profile-update-driving-for'),
      { uid, drivingFor, taskId }, { headers: headers }).toPromise();
  }



  async createProfile(credential: UserCredential, hdr?: HttpHeaders) {
    // Read from the curret router the taskid if provided.
    const taskId = this.aroute.snapshot.queryParams.refId;

    logger.log('New User was created with credentials', credential);
    const user = credential.user;
    const p = new UserProfile();
    p.id = user.uid;
    p.email = user.email;
    p.displayName = user.displayName;
    if (!p.displayName || p.displayName === '') {
      p.displayName = user.email;
    }
    p.photoURL = user.photoURL;

    const token = await credential.user.getIdToken();
    logger.log('token from credential is: ', token);
    const headers = this.addBearerToken(hdr, token);

    const url = this.getApiUrl('/user/profile-create');
    logger.log('Api URL : ', url);

    // If new user was invited with some actions, like add as a driver by external company, theses params will be
    // sent to server to be used while creating the profile.
    const fparams = getParamObjFromUrl(this.aroute.snapshot.queryParams.returnUrl);
    return this.http.post<UserProfile>(url, {
      fparams,
      profile: instanceToPlain(p),
      uid: user.uid,
    }, {
      headers: headers
    }).toPromise();
  }

  /** called once user is created or new log in */
  public async updateUserData(credential: any) {
    if (!!credential) {
      const details = getAdditionalUserInfo(credential);
      
      if (details.isNewUser) {
        this.store.dispatch(new SetNewUser({ isNewUser: true }));
      }
      logger.log('provided credentials are :', credential);
      const user = credential.user;
      // Prepare the post data
      if (details.isNewUser) {
        const cp = await promiseWraper(this.createProfile(credential));
        if (cp.success) {
          logger.log('profile was successfully created at server', cp.data);
          this.store.dispatch(new ProfileFromDbLoaded(cp.data));
          if (!!credential.user.emailVerified) {
            this.router.navigateByUrl('/account;update=general');
          }
        } else {
          logger.error('There was an error creating the profile', cp.error);
          logger.log(readErrorMessage(cp.error));
        }
        // .pipe( take(1), tap(r => logger.log('response: ', r)),).subscribe(noop);
      } else {
        // Evertime Login happens, distapch db request from loading the profile.
        logger.log('Dispatching event to load the user profile from db.');
        this.store.dispatch(new ProfileFromDbRequested({ uid: user.uid }));
      }
    }
  }

  /** Called when an existing user agree to an updated site agreements. */
  updateSiteAgreements(uid: string | number, agreements: IAgreement[]) {
    // sent to server to update to latest user agreements.

    const headers = this.addBearerToken();
    return this.http.post<UserProfile>(this.getApiUrl('/user/update-site-agreements'), {
      uid,
      agreements
    }, {
      headers: headers
    }).toPromise();
  }

  /** Called when an user saved settings in the local cache to be cascaded to the db.
  */
  createSiteAgreementsSilent(uid: string | number) {
    const headers = this.addBearerToken();
    return this.http.post<number>(this.getApiUrl('/user/create-site-agreements'), {
      uid,
    }, {
      headers: headers
    }).toPromise();
  }


  public async sendTestMsg() {
    const headers = this.addBearerToken();
    return this.http
      .post<UserProfile>(this.getApiUrl('/user/test-notification'), { test: 'xxx' }, { headers: headers })
      .toPromise()
      .then(result => logger.log('cloud message sent result', result))
      .catch(error => logger.error('ERROR executing test cloud message', error));
  }
  public getAllUsers_PagingObservable() {
    const p$: PagingObesrvable<UserProfile, IUserParam> =
      new PagingObesrvable<UserProfile, IUserParam>(this.db,
        (p: Paging, param?: IUserParam) => this.getAllUsers_batch(p, param));
    return p$;
  }

  public async updateUserCommSettings(commPreference: CommPreference) {
    const headers = this.addBearerToken();
    return this.http.post<UserProfile>(this.getApiUrl('/user/profile-update-comm-preference'),
      { commPreference }, { headers: headers }).toPromise();
  }
  /**
 * Get the next batch of the data from server.
 * @param p paging information.
 */
  private getAllUsers_batch(p: Paging, param: IUserParam): Observable<{ [key: string]: UserProfile }> {
    if (param.orderBy == null) { param.orderBy = 'updatedAt'; }
    if (param.orderDirection == null) { param.orderDirection = 'desc'; }
    return this.db
      .colWithIdsInjectedNew$<UserProfile>(`${UserProfile.collectionName}`, ref => userSerachServerQuery(ref, param, p))
      .pipe(
        tap(arr => {
          if (arr == null || arr.length === 0) {
            logger.log('All data is recevied, Paging is FULL');
            p.full = true;
          } else {
            p.lastDoc = arr[arr.length - 1];
          }
        }),
        map(arr => {
          // logger.log('From Server, before mapping is applied: ', arr);

          const arrayAsObj = arr.reduce((acc, cur) => {
            const id = cur.id;
            const data = cur;
            return { ...acc, [id]: data };
          }, {});

          // logger.log('after converting to array object dic ', arrayAsObj);
          return arrayAsObj;
        })
      );
  }

  /**
   * Get User by Email
   * @param payload 
   * @returns 
   */
  getUserByEmail(payload) {
   // Prepare the post data
   const headers = this.addBearerToken();
   // Server validation before creating the entry.
   return this.http.post(this.getApiUrl('/user/get-by-email'), payload,
     { headers: headers })
     .pipe(
       tap(r => logger.log('response: ', r)),
     );
  }

   /**
   * MKN - Get User Display Name by uid
   * @param payload 
   * @returns 
   */
   getUserDisplayNameByUid(uid : string | number) {
    // Prepare the post data
    const headers = this.addBearerToken();
    // Server validation before creating the entry.
    return this.http.post(this.getApiUrl('/user/get-display-name-by-uid'), { uid },
      { headers: headers })
      .pipe(
        tap(r => logger.log('response: ', r)),
      );
   }
}