import { IValidate, IValidationMsg } from '../error-handling/validation-info';
import { ValidatorOptions, validateSync, IsEnum, IsBoolean } from 'class-validator';
import { Exclude, Expose } from 'class-transformer';
import { toValidationError } from '../utility/validation-helper';
import { ItemStatus } from './item-status';

export type ItemValidationGroup = 'keyValidition' | 'valueValidition';

@Exclude()
export class PromoListItem implements IValidate {

  @Expose()
  @IsEnum(ItemStatus)
  itemStatus: ItemStatus;

  key?: string;
  description?: string;
  iotId?: string;
  driverId?: string;
  name?: string;
  email?: string;
  private _uiDescription?: string;
  public get uiDescription(): string {
    return this._uiDescription;
  }
  public set uiDescription(value: string) {
    this._uiDescription = value;
  }
  legalName?: string;
  tripTrackerId?: string;






  // @Expose()
  // required for UI, set itemStatus. when itemStatus is inactive or pending isActive = false. when
  // when itemStatus is active, isActive = true
  //  @IsBoolean()
  // get isActive(): boolean { return !!this.itemStatus && this.itemStatus !== ItemStatus.inactive ? true : false; }
  @Expose()
  @IsBoolean()
  isActive = false;

  validateSyncGroup(group?: ItemValidationGroup): IValidationMsg {
    return this.validateSync({ groups: [group] });
  }
  validateSync(options?: ValidatorOptions, list?: any): IValidationMsg {
    const r = validateSync(this, options);
    const m = toValidationError(r);
    // if (!!list && !!list.isDuplicate(this.key)) {
    //   m['key'] = [`duplicate entry ${this.key}`];
    // }
    return m;
  }
  // clone() {
  //   const t = instanceToInstance(this);
  //   // t.sanitize();
  //   return t;
  // }
}

