
import { SubPageComponent } from '../shared/sub-page/sub-page.component';
import { preloadRoute } from '@trent/core/router-preload';
import { Routes, Router } from '@angular/router';
import { AdminGuard, AuthGuard } from '@trent/core';
import { FormDisplayMode } from '@trent/models/UI/form-display-mode';
import { ContactUsComponent } from '../contact-us/contact-us.component';
// import { PdfJsonEditorCtrlComponent } from '../shared/pdf-editor/pdf-json-editor-ctrl.component';

/**
 * Base Routing Data that is common to all of the websites.
 */
export const routesBase: Routes = [
  {
    path: 'landing-page',
    loadChildren: () => import('../landing-page/landing-page.module').then(m => m.LandingPageModule),
    data: preloadRoute(true, 3000)
  },
  {
    path: 'cms', canActivate: [AdminGuard],
    loadChildren: () => import('../shared/cms/cms.module').then(m => m.CmsModule),
    // data: { controlRequired: true, downloadRequired: true }
  },
  // /** CMS Create a new page */
  // { path: 'cms/add', component: SubPageComponent, data: { mode: FormDisplayMode.create, showAuto: true} },
  // /** cms Edit existing page */
  // { path: 'cms/edit/:pid', component: SubPageComponent, data: { mode: FormDisplayMode.edit, showAuto: true } },
  // /** Display a cms page. page  */
  { path: 'info/:pid', component: SubPageComponent, data: { showAuto: false } },
  {
    path: 'pdf-reader/:pid',
    loadChildren: () => import('../shared/pdf-editor/pdf-json-editor.module').then(m => m.PdfJsonEditorModule), // /shared/pdf-editor/pdf-json-editor.module")
    // component: PdfJsonEditorCtrlComponent,
    data: { controlRequired: true, downloadRequired: true }
  },
  {
    path: 'login',
    loadChildren: () => import('../account/login/login.module').then(m => m.LoginModule),
    data: preloadRoute(true, 3000)
  },
  {
    path: 'register',
    loadChildren: () => import('../account/register/register.module').then(m => m.RegisterModule),
    data: preloadRoute(true, 3000)
  },
  {
    path: 'reset-password',
    loadChildren: () => import('../account/reset-password/reset-password.module').then(m => m.ResetPasswordModule),
    data: preloadRoute(true, 3000)
  },
  {
    path: 'admin', canActivate: [AuthGuard],
    loadChildren: () => import('../admin/admin.module').then(m => m.AdminModule),
  },
  {
    path: 'admin-dashboard', canActivate: [AuthGuard],
    loadChildren: () => import('../admin-dashboard/admin-dashboard.module').then(m => m.AdminDashboardModule),
  },
  {
    path: 'account', canActivate: [AuthGuard],
    loadChildren: () => import('../account/account.module').then(m => m.AccountModule),
  },
  { path: 'checkout', canActivate: [AuthGuard], loadChildren: () => import('../checkout/checkout.module').then(m => m.CheckoutModule) },
  { path: 'order', canActivate: [AuthGuard], loadChildren: () => import('../order/order.module').then(m => m.OrderModule) },
  {
    path: 'list', canActivate: [AuthGuard],
    loadChildren: () => import('../promo-lists/promo-lists.module').then(m => m.PromoListsModule),
  },
  {
    path: 'spare-part', canActivate: [AuthGuard],
    loadChildren: () => import('../spare-parts/spare-parts.module').then(m => m.SparePartsModule),
  },
  {
    path: 'company', canActivate: [AuthGuard],
    loadChildren: () => import('../company/company.module').then(m => m.CompanyModule),
  },
  {
    path: 'company-user/:cid',
    loadChildren: () => import('../company-user/company-user.module').then(m => m.CompanyUserModule),
  },
  {
    path: 'trip', canActivate: [AuthGuard],
    loadChildren: () => import('../trip/trip.module').then(m => m.TripModule),
  },
  {
    path: 'track-delivery',
    loadChildren: () => import('../trip-tracker/trip-tracker.module').then(m => m.TripTrackerModule),
  },
  {
    path: 'task', canActivate: [AuthGuard],
    loadChildren: () => import('../task/task.module').then(m => m.TaskModule),
  },
  {
    path: 'setting', canActivate: [AuthGuard],
    loadChildren: () => import('../shared/setting/setting.module').then(m => m.SettingModule),
  },
  {
    path: 'download-app',
    loadChildren: () => import('../shared/app-link/app-link.module').then(m => m.AppLinkModule),
  },
  {
    path: 'policy-update', loadChildren: () => import('../shared/policy/policy.module').then(m => m.PolicyModule),
  },
  {
    path: 'privacy-policy', component: SubPageComponent, data: { mode: FormDisplayMode.read, pid: 'privacy-policy' }
  },
  {
    path: 'terms-conditions', component: SubPageComponent, data: { mode: FormDisplayMode.read, pid: 'terms-conditions' }
  },
  {
    path: 'contact', component: ContactUsComponent, data: { mode: FormDisplayMode.read, pid: 'contact' },
  },
  {
    path: 'promotion', loadChildren: () => import('../unsubscribe/unsubscribe-promotion.module').then(m => m.UnsubscribePromotionModule)
  },
  {
    path: 'get-info-page', loadChildren: () => import('../promotions-leads-info-page/promotions-leads-info-page.module').then(m => m.PromotionLeadsInfoPageModule)
  },
  {
    path: 'trailers', loadChildren: () => import('../product/product.module').then(m => m.ProductModule),
  },
  {
    path: 'rent-option', loadChildren: () => import('../rent-option/rent-option.module').then(m => m.RentOptionModule),
  },
  {
    path: 'bid', canActivate: [AuthGuard], loadChildren: () => import('../bid/bid.module').then(m => m.BidModule),
  },
  {
    path: 'inspection',
    // canActivate: [AuthGuard],
    loadChildren: () => import('../inspection/inspection.module').then(m => m.InspectionModule),
  },
  {
    path: 'admin-manage', canActivate: [AuthGuard],
    loadChildren: () => import('../inspection/inspection.module').then(m => m.InspectionModule),
  },
  {
    path: 'contract-management',
    canActivate: [AuthGuard], 
    loadChildren: () => import('../legacy-contracts/contract-management.module').then(m => m.ContractManagementModule),
  },
  {
    path: 'maintenance',
    // canActivate: [AuthGuard], 
    loadChildren: () => import('../maintenance/maintenance.module').then(m => m.MaintenanceModule),
  },
];

/** Getter and setter full routes of the website. It is set by the site specific app routing module. * */
let _siteRouts: Routes = undefined; //  = [];
export function setSiteRoutes(r: Routes) {
  _siteRouts = r;
}

export function getSiteRoutes(): Routes {
  return _siteRouts;
}

export const restoreRouterConfig = (router: Router) => {
  router.config = getSiteRoutes();
};

/** Enforce user to sign off on the agreements. */
export const enforceAgreementSignoff = (router: Router) => {
  router.resetConfig([
    {
      path: 'policy-update', loadChildren: () => import('../shared/policy/policy.module').then(m => m.PolicyModule),
    },
    {
      path: '**', redirectTo: 'policy-update'
    }
  ]);
  router.initialNavigation();
  // router.navigate(['/product-list'], );
  router.navigateByUrl('/policy-update?finalReminder=1', /* Removed unsupported properties by Angular migration: queryParams. */ {});
  //  router.navigateByUrl('/policy-update?finalReminder=1', { queryParams: { returnUrl: router.url } });
};