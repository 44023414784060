// import 'firebase/storage';
import { logger } from '@trentm/log/logger';
import { getStorage, ref, getDownloadURL, getMetadata, updateMetadata, listAll, deleteObject } from 'firebase/storage';
import { getApp} from 'firebase/app';
import { FullMetadata, ListResult } from '@angular/fire/storage';


export async function getUrlFromPath(path: string, customBucket?: string): Promise<{ url: string, success: boolean, error: string }> {
  // Get a reference to the storage service, which is used to create references in your storage bucket
  const storage = !!customBucket ?  getStorage(getApp(), `gs://${customBucket}`) : getStorage(getApp());
  // Create a storage reference from our storage service
  // const storageRef = storage.ref();

  let url: string;
  let err: string;
  let success: boolean;
  // const podRef = storageRef.child(path);
  try {
    // Get the download URL
    const u = await getDownloadURL(ref(storage, path)); //    podRef.getDownloadURL();
    url = u as string;
    err = null;
    success = true;
  } catch (error) {
    // A full list of error codes is available at
    // https://firebase.google.com/docs/storage/web/handle-errors
    switch (error.code) {
      case 'storage/object-not-found':
        // File doesn't exist
        err = 'File does not exist';
        logger.log('File does not exist', error.code);

        break;
      case 'storage/unauthorized':
        // User doesn't have permission to access the object
        err = 'User does not have permission to access the object';
        logger.log('User does not have permission to access the object', error.code);

        break;
      case 'storage/canceled':
        // User canceled the upload
        err = 'User canceled the upload';
        logger.log(' User canceled the upload', error.code);

        break;
      case 'storage/unknown':
        // Unknown error occurred, inspect the server response
        err = 'Unknown error occurred';
        logger.log('Unknown error occurred', error.code);
        break;
    }
    url = null;
    success = false;
  }
  // tslint:disable-next-line:object-literal-shorthand
  return { url: url, success: success, error: err };
}
export async function getMetadataFS(docRef: string)
  : Promise<{ metadata: FullMetadata, success: boolean, error: string }> {
  let metadata: FullMetadata;
  let err: string;
  let success: boolean;
  // Get a reference to the storage service, which is used to create references in your storage bucket
  const storage = getStorage(getApp()); // firebase.storage();
  // Create a storage reference from our storage service
  // const storageRef = storage.ref();
  // Create a reference to the file whose metadata we want to change
  const fileRef =  ref(storage, docRef); //  storageRef.child(docRef);
  try {
    // Get metadata properties
    const d = await getMetadata(fileRef);
    // Metadata now contains the metadata for 'images/forest.jpg'
    metadata = d;
    success = true;
    err = null;
  } catch (error) {
    // Uh-oh, an error occurred!
    metadata = null;
    success = true;
    err = error;
    logger.error(`get metadata for ${docRef} failed ${error}`);
  }
  // tslint:disable-next-line:object-literal-shorthand
  return { metadata: metadata, success: success, error: err };
}
/** Metadata update also used to set the document as archive
 * @param docRef: path to file
 * @param customMetadata: new metadata
 */
export async function updateCustomMetadata<T>(docRef: string, customMetadata: {})
  : Promise<{ updatedMetadata: T, success: boolean, error: string }> {
  let updated: T;
  let err: string;
  let success: boolean;
  // Get a reference to the storage service, which is used to create references in your storage bucket
  const storage = getStorage(getApp()); // firebase.storage();
  // Create a storage reference from our storage service
  const storageRef = ref(storage, docRef);
  // Create a reference to the file whose metadata we want to change
  const fileRef = ref(storage, docRef); //  s
  try {
    // Update metadata properties
    const u = await updateMetadata(fileRef, customMetadata);
    // Updated metadata for 'images/forest.jpg' is returned in the Promise
    updated = u as any;
    success = true;
    err = null;
    logger.log(`metadata for ${docRef} update to ${updated}`);
  } catch (error) {
    // Uh-oh, an error occurred!
    updated = null;
    success = true;
    err = error;
    logger.error(`metadata for ${docRef} failed ${error}`);
  }
  // tslint:disable-next-line:object-literal-shorthand
  return { updatedMetadata: updated, success: success, error: err };
}
export async function listAllFiles<T>(docRef: string)
  : Promise<ListResult> {
    const storage = getStorage();
    // Create a reference under which you want to list
    const listRef = ref(storage, `${docRef}`);
    return listAll(listRef);
  }
export async function deleteFile<T>(path: string)
  : Promise<void> {
    const storage = getStorage();
    // Create a reference under which you want to list
    const objRef = ref(storage, path);

    return deleteObject(objRef);
  }
export async function getMetaData<T>(docRef: string)
  : Promise<{}> {
    const storage = getStorage();
    // Create a reference under which you want to list
    const listRef = ref(storage, `${docRef}`);
    return getMetadata(listRef);
  }
