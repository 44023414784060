import { UserLogin } from '@trent/models/user/user-login';
import { Injectable } from '@angular/core';
import { SignInProvider } from '@trent/models/user';
import { UserPassword } from '@trent/models/user/user-password';
import { AuthCredential, UserCredential } from 'firebase/auth';

@Injectable()
export abstract class ILoginService {

  loginWithGoogle: () => Promise<any>;

  loginWithMicrosoft: () =>Promise<any>;

  loginWithFacebook: () => Promise<any>;

  loginWithEmail: (m: UserLogin) => Promise<any>;

  logout: () => Promise<any>;

  updateUserData: (credential: UserCredential) => void;

  reauthenticateWithCredential: (credential: AuthCredential) => Promise<UserCredential>;

  reauthenticateWithProvider: (provider: SignInProvider, usePopup: boolean) => Promise<any>;

  changePassword: (data: UserPassword) => Promise<unknown>;
}

