<div *ngIf="!!em" class="detail-page">
    <!-- Info -->
    <div class="info d-wrap">
        <div class="carousal">
            <trent-carousel #carouselCtrl class="" [pics]="em.pictures" [eMode]="false" [containerHeight]="500"
                [hidePage]="true" [hideUpload]="true" [hideTabs]="true" [showDots]="false" [hideThumbnails]="false"
                [indicator]="false">
            </trent-carousel>
        </div>
        <div class="details">
            <div class="d-flex d-a-center d-j-between">
                <!-- Title -->
                <h2>{{em.name}}</h2>
                <div class="action-icon">
                    <div class="share">
                        <button mat-mini-fab color="primary" (click)="share(em.id);$event.stopPropagation()"
                            matTooltip="Share" matTooltipPosition="above">
                            <mat-icon>share</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
            <!-- Price -->
            <p *ngIf="isPriceShow" class="text-red mb-40">${{em.price}} CAD</p>
            <hr>
            <!-- Description -->
            <p class="text-light mat-subtitle-2"> {{em.description}} </p>
            <!-- Get Quote -->
            <button mat-button type="button" class="btn-dark btn-l mb-2" (click)="getAQuote()">GET A QUOTE</button>
            <!-- Specs -->
            <div class="specs text-light mat-subtitle-2">
                <span><span class="text-black">SKU:</span> {{em.skuCode}}</span>
                <br>
                <!-- <span><span class="text-black">Categories: </span> <a class="cursor-pointer"
                        (click)="categoryRoute(em.category)">{{getCategoryName(em.category)}}</a></span> -->
                <!-- <br> -->
                <span *ngIf="em.partNumber"><span class="text-black">Part Number:</span> {{em.partNumber}}</span>
                <br>
                <span *ngIf="em.references"><span class="text-black">References:</span> {{em.references}}</span>
            </div>
        </div>
        <div class="">
            <div class="content_box ">
                <div class="content_text">
                    <h3 class="title">Why Choose Us ?</h3>
                    <div class="text-light mat-subtitle-2">With a reputation for delivering top-notch quality products, our commitment to excellence ensures that you'll receive durable and reliable truck parts tailored to your specific requirements.</div>
                </div>
            </div>
            <div class="content_box ">
                <div class="content_text">
                    <h3 class="title">Returns</h3>
                    <div class="text-light mat-subtitle-2">Return this product within the return window if you change your mind. Get a refund/replacement & free return shipping if it arrives damaged or not as described.</div>
                </div>
            </div>
            <div class="content_box ">
                <div class="content_text">
                    <h3 class="title">Shipping</h3>
                    <div class="text-light mat-subtitle-2">please contact 1-866-774-3324 for more info on shipping.</div>
                </div>
            </div>
        </div>
    </div>
    <!-- Product Details -->
    <div class="product-details">
        <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" animationDuration="0ms">
            <mat-tab label="Description">
                <ng-container [ngTemplateOutlet]="desc"></ng-container>
            </mat-tab>
            <mat-tab label="Review">
                <ng-container [ngTemplateOutlet]="review"></ng-container>
            </mat-tab>
        </mat-tab-group>
    </div>
    <hr>

    <!-- Related Products -->
    <div class="related-products">
        <h1 class="title">Related Products</h1>
        <ng-container>
            <ng-container *ngIf="relatedProducts.length > 0; else empty">
                <ng-container [ngTemplateOutlet]="spareCard"></ng-container>
            </ng-container>
        </ng-container>
    </div>
</div>

<ng-template #desc>
    <div class="tab-container">
        <span><strong>Fit for:</strong> {{em.fitFor}}</span>
        <p class="text-light mat-subtitle-2">{{em.description}}</p>
    </div>

</ng-template>

<ng-template #review>
    <div class="d-flex mt30 mx-auto">
        <h2>Coming soon...</h2>
        <!-- <trent-pts-review-area [type]="reviewType"></trent-pts-review-area> -->
    </div>
</ng-template>


<ng-template #spareCard>
    <ng-container *ngIf="relatedProducts.length > 0">
        <ng-container *ngIf="!isHandset">
            <div class="hor-tab-cont-dtop">
                <button style="margin-left: 10px" mat-mini-fab color="primary" (click)="subHorizontalIncrement()"
                    [disabled]="horizontalIndex===1"><mat-icon>arrow_back_ios</mat-icon></button>
                <mat-tab-group mat-align-tabs="start" [selectedIndex]="horizontalIndex">
                    <ng-container *ngFor="let hIndex of hPages">
                        <mat-tab>
                            <div class="hor-card-cont-dtop">
                                <ng-container
                                    *ngFor="let item of relatedProducts | slice: horizontalStart:  horizontalEnd">
                                    <trent-spare-parts-card [item]="item"></trent-spare-parts-card>
                                </ng-container>
                            </div>
                        </mat-tab>
                    </ng-container>
                </mat-tab-group>
                <button style="margin-right: 10px" mat-mini-fab color="primary" (click)="addHorizontalIncrement()"
                    [disabled]="horizontalIndex>=horizontalPages"><mat-icon>arrow_forward_ios</mat-icon></button>
            </div>
        </ng-container>
        <ng-container *ngIf="isHandset">
            <div class="hor-tab-cont-mobile">
                <button class="hor-cards-button-left" mat-mini-fab color="primary" (click)="subHorizontalIncrement()"
                    [disabled]="horizontalIndex===1"><mat-icon>arrow_back_ios</mat-icon></button>
                <mat-tab-group mat-align-tabs="start" [selectedIndex]="horizontalIndex" class="mat-tab-group-mobile">
                    <mat-tab>
                        <ng-container *ngFor="let item of relatedProducts | slice: horizontalStart:horizontalEnd">
                            <span (swipeleft)="addHorizontalIncrement()" (swiperight)="subHorizontalIncrement()">
                                <trent-spare-parts-card [item]="item"></trent-spare-parts-card>
                            </span>
                        </ng-container>
                    </mat-tab>
                </mat-tab-group>
                <button class="hor-cards-button-right" mat-mini-fab color="primary" (click)="addHorizontalIncrement()"
                    [disabled]="horizontalIndex>=horizontalPages"><mat-icon>arrow_forward_ios</mat-icon></button>
            </div>
        </ng-container>
    </ng-container>
</ng-template>

<ng-template #empty>
    <p class="subtitle">No similar parts found</p>
</ng-template>