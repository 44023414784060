import { GetAppSettingsRequested } from './../store/root-store/app.state';
import { Injectable, PLATFORM_ID, Inject, inject, Optional } from '@angular/core';
import { APP_BASE_HREF, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable, Subscription } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { SingletonService } from './singleton.service';
import { AppSetting } from '@trent/models/sys/app-setting';
import { Store } from '@ngxs/store';
import { FirestoreService } from './firestore.service';
import { agreementStatusCollectionName, IAgreementStatus, getNetPolicyRevision, IAgreement, AgreementScope } from '@trent/models/user-agreement';
import { logger } from '@trentm/log/logger';
import { QueryConstraint, query, where } from '@angular/fire/firestore';
import { HOST_ID } from '../../projects/locus-loop/src/models/host';


// interface IAppSetting {
//   funcUrl: { [key: string]: string };
//   version: string;
//   minVersion: {
//     web: string;
//     android: string;
//     ios: string;
//   };

// }

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  /** if current code is executed on client browser */
  get isPlatformBrowser() { return isPlatformBrowser(this.platform); }

  /** if current code is executed on SERVER SSR */
  get isPlatformServer() { return isPlatformServer(this.platform); }

  private _isHandset = false;
  get isHandset() { return this._isHandset; }


  isSmallScreen = this.breakpointObserver.isMatched('(max-width: 599px)');

  isHandset$: Observable<boolean> = this.breakpointObserver.observe([Breakpoints.Handset, Breakpoints.Tablet]).pipe(
    map(result => result.matches),
    shareReplay()
  );



  // layoutChanges = this.breakpointObserver.observe(['(orientation: portrait)', '(orientation: landscape)']).pipe
  layoutChanged$ = this.breakpointObserver.observe([Breakpoints.HandsetLandscape, Breakpoints.TabletLandscape]).pipe(
    map(result => result),
    shareReplay()
  );

  appSettingSub: Subscription;
  private _appSetting: AppSetting;
  public get appSetting() {
    return this._appSetting;
  }

  /** Request , used for ssr. */
  private domainSSR;

  constructor(
    @Inject(PLATFORM_ID) private platform: string,
    private breakpointObserver: BreakpointObserver,
    private store: Store,
    private db: FirestoreService) {

    this.isHandset$.subscribe(h => this._isHandset = h);
  }

  bindAppSettings(singleton: SingletonService) {
    if (this.appSettingSub == null && singleton.firebaseRTDBService != null) {
      this.store.dispatch(new GetAppSettingsRequested());

      //   this.appSettingSub = singleton.firebaseRTDBService.obj$('app-setting').subscribe(a => {
      //     logger.log('[Utility Sevice] Loaded App setting page from database.', a);
      //     if (!!a) {
      //       try {
      //         this._appSetting = AppSetting.parse(a);
      //         if (!this.appSetting.isCurrentAppCompatible(environment.platform, SiteData.appVersionLocal1)) {
      //           logger.error('App version is not compatible');
      //         }
      //       } catch (error) {
      //         logger.error('[Utility Service] ]Prograamming error in parsing the app version information from Db');
      //       }
      //     }
      //   });
    }
  }
  scrollToTop(id = 'shellSideNav') {
    if (this.isHandset) {
      const sw = document.getElementById(id);
      if (typeof sw.scrollBy === 'function') {
        sw.scrollBy({ top: -100, left: 0, behavior: 'smooth' });
      } else {
        sw.scrollTop = 0;
      }
    }
  }

  // Cm - Through id scroll to top
  scrollToTop1(id, position = 0){
    const sw = document.getElementById(id);
    if(sw) sw.scrollTop = position;
  }

  /** Get Agreement  */
  getUserAgreementStatusByQuery(uid: string, ag: IAgreement[]) {

    const latestRev = getNetPolicyRevision(ag);
    return this.db
      .colWithIdsInjected$<IAgreementStatus>(agreementStatusCollectionName, ref =>
        query(ref, where(`netRev`, '<', latestRev), where(`uid`, '==', uid)));

  }

  getUserAgreementStatusById(uid: string) {
    logger.log(`[Utility Service] agreement db called with id: ${uid}`);
    // return this.db.docWithInjectedId$<IAgreementStatus>(`${agreementStatusCollectionName}/${uid}`);

    return this.db
      .colWithIdsInjected$<IAgreementStatus>(agreementStatusCollectionName, ref =>
        query(ref, where(`uid`, '==', uid)));
  }

  /** Get domain url for SSR Mode only. */
  getFullUrl(relativeUrl: string) {
    if (this.isPlatformBrowser) {
      return relativeUrl;
    }    
    try {
      if (typeof this.domainSSR == 'undefined') {
        const host = inject(HOST_ID);
        // const baseUrl = inject(APP_BASE_HREF);
        if(!!host) { //  && !!baseUrl) {
          this.domainSSR =   `${host}/`;
        }
        console.log('[Utility Service] Domain is: ' + this.domainSSR);

       //  let request: any = inject(Request);
       //  this.domainSSR = !!request ? `${request.protocol}:${request.get('host')}/` : '';
      }
      return this.domainSSR + relativeUrl;

    } catch (error) {
      logger.error('SSR mode could not find Request.');
      return '';
    }

  }

}