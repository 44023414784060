<!-- <ng-container *ngIf="!!value"> -->
<ng-container *ngIf="!!value">
  <!-- Read Mode -->
  <label *ngIf="!isEditMode" [class.lblError]="hasError" [class.mbN20]="showProgress">
    <a *ngIf="!useSecureUrl" [href]="downloadURL" target="_blank"> {{value.name}}</a>
    <a *ngIf="useSecureUrl" href="javascript:void(0)" (click)="getSecureUrl(downloadLink)"> {{value.name}}</a>
  </label>
  
  <!-- Edit Mode, File exists -->
  <ng-container *ngIf="isEditMode && hasValue">
    <!-- <a href="javascript:void(0)" (click)="removeFile()"><i class="fas fa-times"></i></a> -->
    <label [class.lblError]="hasError" [class.mbN20]="showProgress">
      <a class="view-file" (click)="openLocal()" *ngIf="!!fileData"> {{value.name}}</a>
      <ng-container *ngIf="!fileData">
        <a *ngIf="!useSecureUrl" [href]="downloadURL" target="_blank" > {{value.name}}</a>
        <a *ngIf="useSecureUrl" href="javascript:void(0)" (click)="getSecureUrl(downloadLink)"> {{value.name}}</a>
        
      </ng-container>
      
    </label>
  </ng-container>

  <!-- Edit Mode, File missing -->
  <ng-container *ngIf="isEditMode && !hasValue">
    <label [class.lblError]="hasError" [class.mbN20]="showProgress" dropZone (hovered)="toggleHover($event)"
      (dropped)="selectFiles($event)" [class.hovering]="isHovering">
      <ng-container *ngIf="!(isHandset$ | async)">Drop or</ng-container>
      <a class="select-file" href="javascript:void(0)" (click)='filePicker.click()'> Select File</a>
    </label>
  </ng-container>

  <progress *ngIf="showProgress" class="bg-green2-dark1" [value]="pctComplete" max="100"> </progress>

  <!-- Input File -->
  <input #filePicker class="hide" type="file" (change)="selectFiles($event.target.files)" accept=".pdf, image/*">
</ng-container>
<a #downloadLink class="hide" href="#">dont show me</a>

