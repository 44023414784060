<mat-nav-list>
  <a href="mailto:?subject={{data.title}}&body={{data.text}}%0D%0A{{data.url}}" mat-list-item *ngIf="shareOnEmail">
    <mat-icon mat-list-icon color="primary">email</mat-icon>
    <span mat-line>Email</span> 
    <span mat-line>Share by email</span>
  </a>

  <a href="https://www.facebook.com/sharer/sharer.php?u={{data.url}}"  mat-list-item *ngIf="shareOnFacebook">
    <fa-icon mat-list-icon class="primary-color" [icon]="['fab', 'facebook']"></fa-icon>
    <span mat-line>Facebook</span>
    <span mat-line>Share on facebook</span>
  </a>

  <a href="https://wa.me/?text={{data.text}}%0D%0A{{data.url}}" mat-list-item *ngIf="shareOnWhatsapp">
    <fa-icon mat-list-icon class="primary-color" [icon]="['fab', 'whatsapp']"></fa-icon>
    <span mat-line>Whatsapp</span>
    <span mat-line>Share by whapsapp</span>
  </a>
</mat-nav-list>
