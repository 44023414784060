import { ProductWholesalePrice } from "./product-wholesale-price";

export const parseProductWholesalePrice = (obj: any): ProductWholesalePrice => {
    if (obj == null) { return null; }
    const c = obj as ProductWholesalePrice; // cast it to base, remember it is still a javascript obj at run time.
    return ProductWholesalePrice.parse(c);;
};

//MKN - Array parse
export const parseProductWholesalePriceArray = (obj: any[]): ProductWholesalePrice[] => {
  return obj.map(o => parseProductWholesalePrice(o));
};
