import { checkOffset, estStartOfDay } from './../utility/timer-helper';

import { BidBase } from './bid-base';
import { ProductType } from '../product/product-type';
// import { BidDriver } from './bid-driver';
import { BidTrailer } from './bid-trailer';
import { BidTruck } from './bid-truck';
import { BidStatus } from './bid-status';
import { RentOptionSummary } from '../rental/rent-option-summary';
import { NegoTermsBase } from '../nego-terms-base/nego-terms-base';
import { RentalProductBase } from '../rental/rental-product-base';
import { differenceInDays, isBefore, addWeeks, addDays, addSeconds, isDate, addHours, addMinutes, differenceInHours, setHours, setMinutes, setSeconds } from 'date-fns';
import { InvoiceItem } from '../finance/invoice/invoice-item';
import { InvoiceRec } from '../finance/invoice/invoice-rec';
import { InvoicePay } from '../finance/invoice/invoice-pay';
import { ServiceType } from '../finance/service-fee/i-service-item';
import { RentalTruck } from '../rental/rental-truck';
import { RentalTrailer } from '../rental/rental-trailer';
import { TransactionType } from '../finance/invoice/invoice-type';
import { CurrencyCode } from '../finance/order/order-type';
import { Inspection } from '../inspection/inspection';
import { UserEnum } from '../user/user-enum';
import { isEqual, isNumber, clone, isNil, round, isEmpty } from 'lodash';
import { isEqual as isEqualD } from 'date-fns';
import { RentalPlan } from '../rental/rental-plan';
import { RentalTerm } from '../rental/rental-term';
import { MileageType } from '../rental/milage-type';
import { InvoiceItemCat } from '../finance/invoice/invoice-item-type';
import { getOneTimeFee, getReserveAmount } from '../finance/finance-input/finance-input-handler';
import { CustomerCompanySummary } from '../company/company-fleet';
import { ITaxItem } from '../finance/tax/i-tax-item';
import { NegoTermsAssigned } from '../nego-terms-base/nego-terms-assigned';
import { Levy } from '../finance/tax/levy';



export type BidProduct = BidTruck | BidTrailer; // | BidDriver;
export interface IPhoneInfo {
  callerName: string;
}
export interface IRecInvAssignedInput{
  vCid: string | number;
  productType: ProductType;
  deposit: number;
  rentalPlans: RentalPlan[];
  currency: 'CAD' | 'USD';
  negoTerm: NegoTermsBase;
  customerCompanySummary: CustomerCompanySummary, 
  bidId ?: string | number;
  depositDueDate ?: Date;
  firstPaymentDate ?: Date;
  chargeAdminFee ?: boolean;
  waveOtherFee ?: boolean;
  reserveAmountDate? : Date;
}
export interface IPayInvAssignedInput{
  productType: ProductType;
  recInv: InvoiceRec;
  deposit: number;
  negoTerm: NegoTermsBase;
  vCid: string;
  bidId ?: string | number;
  depositDueDate ?: Date;
  firstPaymentDate ?: Date;
  chargeAdminFee ?: boolean;
  waveOtherFee ?: boolean
  reserveAmountDate? : Date;

}
export interface IPaymentMaker {
  
    productType: ProductType,
    deposit: number, 
    depositDueDate: Date,
    firstPaymentDate: Date,
    negoTerm: NegoTermsBase, 
    rentalPlan: RentalPlan,
    chargeAdminFee: boolean, 
    waveOtherFee: boolean,
    reserveAmountDate? : Date;

  
}

export const parseBid = (obj: any): BidBase => {
  if (obj == null) { return null; }

  //const r = <BidBase>obj; // cast it to base, remember it is still a javascript obj at run time.

  const r = BidBase.parse(obj);// cast it to base, remember it is still a javascript obj at run time.
  switch (r.productSummary.productType) {
    // case ProductType.driver:
    //   return BidDriver.parse(r);

    case ProductType.trailer:
      return BidTrailer.parse(r);

    case ProductType.truck:
      return BidTruck.parse(r);

    default:
      throw new Error(`Invalid Product Type in the Rental Product. Producty Type: ${r.productSummary.productType}
      is invalid or not yet implemented.`);
  }
};

export const getServType = (productType: ProductType, isReplacement = false): ServiceType => {
  let s: ServiceType;
  switch (productType) {
    case ProductType.trailer:
      s = !isReplacement ? 'trailerRental' : null;
      return s;
    case ProductType.truck:
      s = !isReplacement ? 'truckRental' : null;
      return s;

    default:
      throw new Error(`unknown error occurred while getting service type [bid-helper] getServType, service not defined for ${productType}`);

      break;
  }
};

export const createPickDrop = (b: BidBase): Inspection => {
  if (!(b instanceof BidTrailer || b instanceof BidTruck)) {  // b instanceof BidDriver ||
    b = parseBid(b);
  }

  if (b.bidStatus !== BidStatus.accepted) {
    throw Error(`Pick-Drop object can only be created when bid status is approved. current: ${BidStatus[b.bidStatus]}`);
  }

  const p = new Inspection();
  p.id = b.id;
  p.customerCompanySummary = b.customerCompSummary;
  p.vendorCompanySummary = b.vendorCompSummary;
  p.productSummary = b.productSummary;
  return p;
};
export const parseBidArray = (obj: any[]): BidBase[] => {
  return obj.map(o => <BidBase>parseBid(o));
};
/**
 * 
 * @param rentOption 
 * @param negoTerm 
 * @param rentalPlan 
 * @param securityDepositDueDate 
 * @param chargeAdminFee 
 * @returns PaymentSchedule
 * @sideEffect negoTerm.startDate to timezone
 * @sideEffect negoTerm.endDate to timezone 
 */
export const getPaymentSchedule = (rentOption: RentalTrailer | RentalTruck, negoTerm1: NegoTermsBase,
  rentalPlan: RentalPlan, securityDepositDueDate = new Date(), chargeAdminFee = true): InvoiceItem[] => {
  const estStartOfDayNow = estStartOfDay();
  const rentSummary: RentOptionSummary = rentOption.getRentOptionSummary();
  // const rentPD = RentalProductBase.getRentPerDay(negoTerm.rate, term);

  // const rentPD = RentalProductBase.getApplicableRentalTerm(negoTerm.startDate, negoTerm.endDate, rentSummary).proratedDailyRate;
  // const term = RentalProductBase.getApplicableRentalTerm(negoTerm.startDate, negoTerm.endDate, rentSummary).applicableTerm;
  const startDate = estStartOfDay(negoTerm1.startDate);
  const endDate = estStartOfDay(negoTerm1.endDate);
  let sch: InvoiceItem[] = [];
  let trailingDays: number;
  let p: InvoiceItem;
  let counter = 0;
  let numberOfDays: number;
  numberOfDays = differenceInDays(endDate, startDate);
  let paymentFreq: RentalTerm.daily | RentalTerm.weekly | RentalTerm.monthly = getPaymentFreq(rentalPlan.rentalTerm, numberOfDays);
  const reserveAmount = rentOption?.deposit > 0 ? getReserveAmount(rentOption.productType) : null;

  let reservePayment: InvoiceItem = null;
  

  if (rentOption?.deposit > 0) {
    reservePayment = new InvoiceItem();
    reservePayment.name = 'Reserve';
    reservePayment.unitPrice = reserveAmount; 
    reservePayment.quantity = 1;
    reservePayment.dueDate = securityDepositDueDate;
    reservePayment.itemCat = InvoiceItemCat.SecurityDeposit;
    reservePayment.invoiceItemType = 'securityDeposit';
    sch.push(reservePayment);
  }


  /** build security deposit.  */
  let securityDeposit: InvoiceItem = null;
  if (rentOption?.deposit - reserveAmount > 0) {
    securityDeposit = new InvoiceItem();
    securityDeposit.name = 'Security Deposit';
    securityDeposit.unitPrice = rentOption.deposit - reserveAmount; 
    securityDeposit.quantity = 1;
    securityDeposit.dueDate = startDate;
    securityDeposit.itemCat = InvoiceItemCat.SecurityDeposit;
    securityDeposit.invoiceItemType = 'securityDeposit';
    sch.push(securityDeposit);
  }

  const oneTimeCharge = !!chargeAdminFee ? getOneTimeFee(rentOption.productType) : null;

  // build the admin invoice item.
  let admin: InvoiceItem = undefined;
  if (isNumber(oneTimeCharge?.adminFee) && oneTimeCharge.adminFee > 0) {
    admin = new InvoiceItem();
    admin.invoiceItemType = 'adminFee';
    admin.unitPrice = oneTimeCharge.adminFee;
    admin.quantity = 1.0;
    admin.name = 'Admin Fee (One Time)';
    //We do not know the period yet, we will copy the period/due date in the loop below.
  }
  let adminEntered = false;

  switch (paymentFreq) {
  // switch (rentalPlan.rentalTerm) {
    case RentalTerm.daily:
      // paymentTerm = RentalTerm.daily;
      p = new InvoiceItem();
      p.invoiceItemType = 'rent';
      p.unitPrice = rentalPlan.ratePerDay;
      p.serviceType = getServType(rentOption.productType, rentOption.isReplacementUnit);
      p.name = 'Rent per day';
      p.quantity = numberOfDays;
      p.period = { from: startDate, to: endDate };
      // p.dueDate = new Date(estStartOfDayNow);
      p.dueDate = p.period.to;
      if (!!admin && !adminEntered) {
        // admin.period = cloneDeep(p.period);
        admin.dueDate = clone(p.dueDate);
        adminEntered = true;
        sch.push(admin);
      }
      sch.push(p);
      break;
    case RentalTerm.weekly:
      // paymentTerm = RentalTerm.weekly;
      for (let s = startDate; isBefore(s, endDate) && (differenceInHours(endDate, s) > 2) ; s = addDays(s, RentalTerm.weekly)) {
        p = new InvoiceItem();
        p.invoiceItemType = 'rent';
        p.unitPrice = rentalPlan.ratePerDay;
        p.serviceType = getServType(rentOption.productType, rentOption.isReplacementUnit);
        p.name = `Rent Weekly`; // # ${++counter}`;
        trailingDays = differenceInDays(endDate, s);
        if (trailingDays >= 6 + 23/24) {
        // if (isBefore(addDays(s, RentalTerm.weekly), addDays(endDate, 1))) {
          p.quantity = RentalTerm.weekly;
          p.period = { from: s, to: addDays(addWeeks(s, 1), 0) };
          // p.dueDate = s === negoTerm.startDate ? new Date(estStartOfDayNow) : estStartOfDay(s);
          p.dueDate = p.period.to;

        } else {
          // trailingDays = differenceInDays(endDate, s);
          p.quantity = trailingDays;
          p.period = { from: s, to: addDays(endDate, 0) };
          // p.dueDate = s === negoTerm.startDate ? new Date(estStartOfDayNow) : estStartOfDay(s);
          p.dueDate = p.period.to;
        }
        if (!!admin && !adminEntered) {
          // admin.period = cloneDeep(p.period);
          admin.dueDate = clone(p.dueDate);
          adminEntered = true;
          sch.push(admin);
        }
        //p.oneTimeAdminFee = sch.findIndex(f => f.oneTimeAdminFee > 0) === -1 ? oneTimeCharge.adminFee : 0;
        sch.push(p);
      }
      break;
    case RentalTerm.monthly:
    // case RentalTerm.threeMonth:
    // case RentalTerm.sixMonth:
    // case RentalTerm.oneYear:
      // paymentTerm = RentalTerm.monthly;
      for (let s = startDate; isBefore(s, endDate) && (differenceInHours(endDate, s) > 2) ; s = addDays(s, RentalTerm.monthly)) {
        p = new InvoiceItem();
        p.invoiceItemType = 'rent';
        p.unitPrice = rentalPlan.ratePerDay;
        p.serviceType = getServType(rentOption.productType, rentOption.isReplacementUnit);
        p.name = `Rent Monthly`; // # ${++counter}`;
        trailingDays = differenceInDays(endDate, s);
        if (trailingDays >= 29 + 23/24) {
        // if (isBefore(addDays(s, RentalTerm.monthly), addDays(endDate, 1))) {
          p.quantity = RentalTerm.monthly;
          p.period = { from: s, to: addDays(addDays(s, RentalTerm.monthly), 0) };
          // p.dueDate = s === negoTerm.startDate ? new Date(estStartOfDayNow) : estStartOfDay(s);
          p.dueDate = p.period.to;
        } else  {
          // trailingDays = differenceInDays(endDate, s);
          console.log({ trailingDays });
          p.quantity = trailingDays;
          p.period = { from: s, to: addDays(endDate, 0) };
          // p.dueDate = s === negoTerm.startDate ? new Date(estStartOfDayNow) : estStartOfDay(s);
          p.dueDate = p.period.to;
        }
        // p.oneTimeAdminFee = sch.findIndex(f => f.oneTimeAdminFee > 0) === -1 ? oneTimeCharge.adminFee : 0;
        if (!!admin && !adminEntered) {
          // admin.period = cloneDeep(p.period);
          admin.dueDate = clone(p.dueDate);
          adminEntered = true;
          sch.push(admin);
        }
        sch.push(p);

      }
      break;

    default:
      break;
  }

  // Add security return item.
  if (!isNil(securityDeposit)) {
    /** build return item. */
    const securityReturn = new InvoiceItem();
    securityReturn.name = 'Security Refund';
    securityReturn.unitPrice = rentOption.deposit; //-securityDeposit.unitPrice
    securityReturn.quantity = 1;
    securityReturn.dueDate = addDays(endDate, 3);
    securityReturn.invoiceItemType = 'securityDeposit';
    // if due date is same the last installment, then add a second to ensure return act as a separate item
    if (sch[sch.length - 1].dueDate.valueOf() === securityReturn.dueDate.valueOf()) {
      securityReturn.dueDate = addSeconds(securityReturn.dueDate, 1);
    }
    securityReturn.itemCat = InvoiceItemCat.SecurityDepositRefund;
    sch.push(securityReturn);
  }
  let minMileage: InvoiceItem = undefined;
  const minMileageItems: InvoiceItem[] = [];
  if (rentalPlan.mileageType === MileageType.minimum) {
    sch.forEach(e => {
      if (e.invoiceItemType === 'rent') {
        minMileage = new InvoiceItem();
        minMileage.invoiceItemType = 'minMileage';
        minMileage.name = 'Minimum Mileage';
        minMileage.unitPrice = rentalPlan.ratePerMile;
        if (e.quantity === paymentFreq) {
          minMileage.quantity = round(rentalPlan.milage * paymentFreq / rentalPlan.rentalTerm);
        } else {
          minMileage.quantity = round(rentalPlan.milage * e.quantity / rentalPlan.rentalTerm); //paymentFreq);
        };
        minMileage.period = e.period;
        minMileage.dueDate = e.dueDate;
        minMileageItems.push(minMileage);
      }
    });
    sch = sch.concat(minMileageItems);
  }
  
  if(isEqual(sch.find(f => f.name === 'Reserve').dueDate, sch.find(f => f.name === 'Security Deposit').dueDate )) {
    const rD = sch.find(f => f.name === 'Reserve').dueDate; 
    sch.find(f => f.name === 'Reserve').dueDate === addMinutes(sch.find(f => f.name === 'Reserve').dueDate, -30);
  }
 
  return sch;
};

export const getPaymentFreq = (rentalTerm: RentalTerm, numberOfDays: number) =>{
  switch (rentalTerm) {
    case RentalTerm.daily:
      return RentalTerm.daily;
      case RentalTerm.weekly:
      return RentalTerm.weekly;
      case RentalTerm.monthly:
      return numberOfDays <= 90 ? RentalTerm.weekly : RentalTerm.monthly;
    case RentalTerm.threeMonth:
    case RentalTerm.sixMonth:
    case RentalTerm.oneYear:
      return RentalTerm.monthly;

  }
}

/**
 * create receivable invoice for the customer
 * @param rentOption Rent-Option
 * @param bidNegoTerms Negotiated Terms
 * @param cid customer company id
 * @param bidId Bid Id (option called when called from the functions)
 */
export const getRentalRecInvoice = (rentOption: RentalProductBase, bidNegoTerms: NegoTermsBase, customerCompanySummary: CustomerCompanySummary, oid: string | number,
  bidId?: string | number, securityDepositDueDate?: Date, chargeAdminFee?: boolean): InvoiceRec => {
  try {
    switch (rentOption.productType) {

      case ProductType.trailer:
      case ProductType.truck:
        const inv = new InvoiceRec();
        inv.rentalPlan = rentOption.getApplicablePlan(bidNegoTerms);
        inv.addOns = !!bidNegoTerms.addOns ? bidNegoTerms.addOns.slice() : [];
        inv.paymentMethod = 'creditCard';
        inv.currencyCode = rentOption.currency === 'CAD' ? CurrencyCode.CAD : CurrencyCode.USD;
        if(rentOption.vendorCompSummary.address.country === 'US' && !bidNegoTerms.taxItems || (customerCompanySummary.address. country === 'US')) {
          inv.taxItems =[Levy.initPlaceholderTax()];
        }
        // inv.taxItems = rentOption.vendorCompSummary.address.country === 'US' && !bidNegoTerms.taxItems || (customerCompanySummary.address. country === 'US') ? [Levy.initPlaceholderTax()] : undefined;
        const invoiceItems = getPaymentSchedule(<RentalTrailer>rentOption, bidNegoTerms, inv.rentalPlan, securityDepositDueDate, chargeAdminFee);
        for (const e of invoiceItems) {
          inv.invoiceType = TransactionType.credit;
          if (!customerCompanySummary.address || !customerCompanySummary.address.stateProv) {
            throw new Error('cannot define jurisdiction');
          }
          e.taxJuris = customerCompanySummary.address.stateProv;
          e.invoiceType = TransactionType.credit;
          inv.addItem(e);
          e.orderId = !!oid ? `${oid}` : undefined;
        }

        inv.cid = customerCompanySummary.cid;
        inv.refId = !!bidId ? `${bidId}` : null;  //
        inv.invoiceCat = UserEnum.customer;
        inv.vCid = rentOption.vendorCompSummary.cid;
        if (!!rentOption.deposit) {
          inv.depositItems = [rentOption.deposit];
        }
        // logger.info('[invoice]: ', { inv });
        return inv;
      default:
        throw new Error(`Invalid Product Type in the Rental Product. Product Type: ${rentOption.productType}
        is invalid or not yet implemented.`);
    }

  } catch (error) {
    throw new Error(`error ${error} occurred while generating invoice [bid-helper] getRentalInvoice`);
  }
};
/**
 * create payable invoice for the customer
 * @param rentOption Rent option
 * @param bidNegoTerms negotiated terms
 * @param bid bid id
 */
export const getRentalPayInvoice = (rentOption: RentalProductBase, bidNegoTerms: NegoTermsBase,
  oid: string, bidId: string | number, invRec: InvoiceRec, securityDepositDueDate?: Date, chargeAdminFee?: boolean): InvoiceRec => {
  try {
    switch (rentOption.productType) {

      case ProductType.trailer:
      case ProductType.truck:
        const inv = new InvoicePay();
        inv.rentalPlan = invRec.rentalPlan;
        inv.paymentMethod = 'creditCard';
        inv.currencyCode = rentOption.currency === 'CAD' ? CurrencyCode.CAD : CurrencyCode.USD;
        inv.invoiceType = TransactionType.debit;
        inv.addOns = !!bidNegoTerms.addOns ? bidNegoTerms.addOns.slice() : [];
        inv.cCid = invRec.cid;
        inv.taxItems = invRec.taxItems;

        const invoiceItems = getPaymentSchedule(<RentalTrailer>rentOption, bidNegoTerms, inv.rentalPlan, securityDepositDueDate, chargeAdminFee);
        for (const e of invoiceItems) {
          e.contingentItemId = invRec.invoiceItems.find(g => isEqual(g.period, e.period)).itemId;
          e.taxJuris = invRec.invoiceItems.find(f => f.taxJuris).taxJuris;
          e.invoiceType = TransactionType.debit;
          e.orderId = oid;
          inv.addItem(e);
        }
        inv.cid = `${rentOption.vendorCompSummary.cid}`;
        inv.refId = !!bidId ? `${bidId}` : null; //
        inv.invoiceCat = UserEnum.vendor;

        return inv;


      default:
        throw new Error(`Invalid Product Type in the Rental Product. Producty Type: ${rentOption.productType}
        is invalid or not yet implemented.`);
    }

  } catch (error) {
    throw new Error(`unknow error occured while generating invoice [bid-helper] getRentalInvoice`);
  }
};
/**
 * creates customer invoice when assign a contract 
 * @param data IRecInvAssignedCInput
 * @returns 
 */
export const getRentalRecInvoiceAssignment = (data: IRecInvAssignedInput): InvoiceRec => {
  try {
    switch (data.productType) {

      case ProductType.trailer:
      case ProductType.truck:
        const inv = new InvoiceRec();
        inv.rentalPlan = RentalPlan.getApplicablePlanAssigned(data.rentalPlans);
        inv.paymentMethod = 'creditCard';
        inv.currencyCode = data.currency === 'CAD' ? CurrencyCode.CAD : CurrencyCode.USD;
        inv.addOns = data.negoTerm.addOns;
        const taxItems = (data.negoTerm as NegoTermsAssigned).taxItems?.map(f => f.iTaxItem);
        inv.taxItems = taxItems?.length > 0 && taxItems.find(f => !!f.args) ? taxItems : undefined;
        const d: IPaymentMaker = {
          productType: data.productType,
          deposit: data.deposit, depositDueDate: data.depositDueDate,
          firstPaymentDate: data.firstPaymentDate,
          negoTerm: data.negoTerm, rentalPlan: inv.rentalPlan,
          chargeAdminFee: data.chargeAdminFee, waveOtherFee: data.waveOtherFee, reserveAmountDate: data.reserveAmountDate
        };
        const invoiceItems = getPaymentScheduleAssignment(d);
        for (const e of invoiceItems) {
          inv.invoiceType = TransactionType.credit;
          if (!data.customerCompanySummary.address || !data.customerCompanySummary.address.stateProv) {
            throw new Error('cannot define jurisdiction');
          }
          e.taxJuris = data.customerCompanySummary.address.stateProv;
          e.invoiceType = TransactionType.credit;
          inv.addItem(e);
          e.orderId = undefined;
        }

        inv.cid = data.customerCompanySummary.cid;
        inv.refId = !!data.bidId ? `${data.bidId}` : null;  //
        inv.invoiceCat = UserEnum.customer;
        inv.vCid = data.vCid;
        if (!!data.deposit) {
          inv.depositItems = [data.deposit];
        }
        // logger.info('[invoice]: ', { inv });
        return inv;
      default:
        throw new Error(`Invalid Product Type in the Rental Product. Product Type: ${data.productType}
        is invalid or not yet implemented.`);
    }

  } catch (error) {
    throw new Error(`error ${error} occurred while generating invoice [bid-helper] getRentalInvoice`);
  }
};
/**
 * creates customer invoice when assign a contract 
 * @param data IPayInvAssignedInput
 * @returns 
 */
export const getRentalPayInvoiceAssignment = (data: IPayInvAssignedInput): InvoiceRec => {
  try {
    switch (data.productType) {

      case ProductType.trailer:
      case ProductType.truck:
        const inv = new InvoiceRec();
        inv.rentalPlan = data.recInv.rentalPlan;
        inv.paymentMethod = 'creditCard';
        inv.currencyCode = data.recInv.currencyCode;
        inv.taxItems = data.recInv.taxItems;
        inv.addOns = data.recInv.addOns;
        const d: IPaymentMaker = {
          productType: data.productType,
          deposit: data.deposit, depositDueDate: data.depositDueDate,
          firstPaymentDate: data.firstPaymentDate,
          negoTerm: data.negoTerm, rentalPlan: inv.rentalPlan,
          chargeAdminFee: data.chargeAdminFee, waveOtherFee: data.waveOtherFee,
          reserveAmountDate: data.reserveAmountDate
        };
        const invoiceItems = getPaymentScheduleAssignment(d);
        inv.invoiceType = TransactionType.debit;
        for (const e of invoiceItems) {
          if (data.recInv.invoiceItems.findIndex(f => f.taxJuris) === -1) {
            throw new Error('cannot define jurisdiction');
          }
          e.contingentItemId = data.recInv.invoiceItems.find(g => isEqual(g.period, e.period)).itemId;
          e.taxJuris = data.recInv.invoiceItems.find(f => f.taxJuris).taxJuris;
          e.invoiceType = TransactionType.debit;
          inv.addItem(e);
          e.orderId = undefined;
        }

        inv.cid = data.vCid;
        inv.cCid = data.recInv.cid;
        inv.refId = !!data.bidId ? `${data.bidId}` : null;  //
        inv.invoiceCat = UserEnum.vendor;
        if (!!data.deposit) {
          inv.depositItems = [data.deposit];
        }
        // logger.info('[invoice]: ', { inv });
        return inv;
      default:
        throw new Error(`Invalid Product Type in the  Product. Product Type: ${data.productType}
        is invalid or not yet implemented.`);
    }

  } catch (error) {
    throw new Error(`error ${error} occurred while generating invoice [bid-helper] getRentalInvoice`);
  }
};
export const getPaymentScheduleAssignment = (
  d: IPaymentMaker
): InvoiceItem[] => {
  const estStartOfDayNow = estStartOfDay();

  const startDate = estStartOfDay(d.negoTerm.startDate);
  const hour = startDate.getHours();
  const minute = startDate.getMinutes();
  const second = startDate.getSeconds();
  // const adjustedEndDate = setHours(d.negoTerm.endDate, d.negoTerm.startDate.getHours());
  const adjustedEndDate = setHours(setMinutes(setSeconds(d.negoTerm.endDate, second), minute), hour);

  const endDate = estStartOfDay(adjustedEndDate);
  const firstPaymentDate = d.firstPaymentDate  ? estStartOfDay((d.negoTerm as NegoTermsAssigned).firstPaymentDate) : undefined;

  let sch: InvoiceItem[] = [];
  const leadingDays: number = !!isDate(firstPaymentDate) ? differenceInDays(firstPaymentDate, startDate) : 0;
  let trailingDays: number;
  let p: InvoiceItem;
  let counter = 0;
  let numberOfDays: number;
  numberOfDays = differenceInDays(endDate, startDate);
  let paymentTerm: RentalTerm.daily | RentalTerm.weekly | RentalTerm.monthly;
  const serviceType = getServType(d.productType, d.waveOtherFee);
  
  const reserveAmount = d?.deposit > 0 && !!d.reserveAmountDate ? getReserveAmount(d.productType) : null;

  /** build security deposit.  */
  let reservePayment: InvoiceItem = null;
  if (d.deposit > 0 && !!reserveAmount) {
    reservePayment = new InvoiceItem();
    reservePayment.setReserveAmountItem(reserveAmount, d.reserveAmountDate);
    sch.push(reservePayment);
  }

  let securityDeposit: InvoiceItem = null;
  if (d?.deposit - reserveAmount > 0) {
    securityDeposit = new InvoiceItem();
    securityDeposit.setSecurityDepositItem(d.deposit - reserveAmount, d.depositDueDate);
    sch.push(securityDeposit);
  }

  const oneTimeCharge = !!d.chargeAdminFee ? getOneTimeFee(d.productType) : null;

  // build the admin invoice item.
  let admin: InvoiceItem = undefined;
  if (isNumber(oneTimeCharge?.adminFee) && oneTimeCharge.adminFee > 0) {
    admin = new InvoiceItem();
    admin.setAdminFeeItem(oneTimeCharge.adminFee, firstPaymentDate);
    admin.invoiceItemType = 'adminFee';
    admin.unitPrice = oneTimeCharge.adminFee;
    admin.quantity = 1.0;
    admin.name = 'Admin Fee (One Time)';
    //We do not know the period yet, we will copy the period/due date in the loop below.
  }
  let adminEntered = false;
  // if (!!d.chargeAdminFee && isDate(admin.dueDate)) {
  //   adminEntered = true;
  //   sch.push(admin);
  // }
  if (leadingDays > 0) {
    p = new InvoiceItem();
    p.setRentItem(leadingDays, d.rentalPlan.ratePerDay, serviceType, startDate, `Rent Daily`);
    if (!!admin && !adminEntered) {
      admin.dueDate = clone(p.dueDate);
      adminEntered = true;
      sch.push(admin);
    }
    sch.push(p);
  }

  switch (d.rentalPlan.rentalTerm) {
    case RentalTerm.daily:
      paymentTerm = RentalTerm.daily;//MKN - Solved NaN issue in Shrink term - payment schedule 
      trailingDays = numberOfDays; // - leadingDays;
      if (trailingDays > 0) {
        p = new InvoiceItem();
        p.setRentItem(trailingDays, d.rentalPlan.ratePerDay, serviceType, addDays(startDate, 0), `Rent Daily`); // leadingDays
        if (!!admin && !adminEntered) {
          admin.dueDate = clone(p.dueDate);
          adminEntered = true;
          sch.push(admin);
        }
        sch.push(p);
      }
      break;
    case RentalTerm.weekly:
      paymentTerm = RentalTerm.weekly;
      for (let s = addDays(startDate, leadingDays); isBefore(s, endDate) && (differenceInHours(endDate, s) > 12) ; s = addDays(s, RentalTerm.weekly)) {
        p = new InvoiceItem();
        trailingDays = differenceInDays(endDate, s);
        if (trailingDays >= 6 + 23/24) {
        // if (isBefore(addDays(s, RentalTerm.weekly), addDays(endDate, 1))) {
          p.setRentItem(RentalTerm.weekly, d.rentalPlan.ratePerDay, serviceType, s, `Rent Weekly`);
        } else  {
          // trailingDays = differenceInDays(endDate, s);
          p.setRentItem(trailingDays, d.rentalPlan.ratePerDay, serviceType, s, `Rent Weekly`);
        }
        if (!!admin && !adminEntered) {
          admin.dueDate = clone(p.dueDate);
          adminEntered = true;
          sch.push(admin);
        }
        sch.push(p);
      }
      break;
    case RentalTerm.monthly:
    case RentalTerm.threeMonth:
    case RentalTerm.sixMonth:
    case RentalTerm.oneYear:
      paymentTerm = RentalTerm.monthly;
      for (let s = addDays(startDate, leadingDays); isBefore(s, endDate) && (differenceInHours(endDate, s) > 12) ; s = addDays(s, RentalTerm.monthly)) {
        p = new InvoiceItem();
        trailingDays = differenceInDays(endDate, s);
        if (trailingDays >= 29 + 23/24) {
          // if (isBefore(addDays(s, RentalTerm.monthly), addDays(endDate, 1))) {
            p.setRentItem(RentalTerm.monthly, d.rentalPlan.ratePerDay, serviceType, s, `Rent Monthly`);
          } else {
            // trailingDays = differenceInDays(endDate, s);
          p.setRentItem(trailingDays, d.rentalPlan.ratePerDay, serviceType, s, `Rent Monthly`);
        }
        if (!!admin && !adminEntered) {
          admin.dueDate = clone(p.dueDate);
          adminEntered = true;
          sch.push(admin);
        }
        sch.push(p);
      }
      break;

    default:
      break;
  }

  // Add security return item.
  if (!isNil(securityDeposit)) {
    /** build return item. */
    const securityReturn = new InvoiceItem();
    securityReturn.name = 'Security Refund';
    securityReturn.unitPrice = -d.deposit;
    securityReturn.quantity = 1;
    securityReturn.dueDate = addDays(endDate, 3);
    securityReturn.invoiceItemType = 'securityDeposit';
    securityReturn.itemCat = InvoiceItemCat.SecurityDepositRefund;
    sch.push(securityReturn);
  }
  let minMileage: InvoiceItem = undefined;
  const minMileageItems: InvoiceItem[] = [];
  if (d.rentalPlan.mileageType === MileageType.minimum) {
    sch.forEach(e => {
      if (e.invoiceItemType === 'rent') {
        minMileage = new InvoiceItem();
        minMileage.invoiceItemType = 'minMileage';
        minMileage.name = 'Minimum Mileage';
        minMileage.unitPrice = d.rentalPlan.ratePerMile;
        if (e.quantity === paymentTerm) {
          minMileage.quantity = d.rentalPlan.milage;
        } else {
          minMileage.quantity = round(d.rentalPlan.milage * e.quantity / paymentTerm);
        };
        minMileage.period = e.period;
        minMileage.dueDate = e.dueDate;
        minMileageItems.push(minMileage);
      }
    });
    sch = sch.concat(minMileageItems);
  }
  
  return sch;
};