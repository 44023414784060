import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { RentOptionDetailComponent } from './detail/rent-option-detail.component';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ProductStoreModule } from '@trent/store/product-store/product-store.module';
import { CustomPipeModule } from '@trent/custom-pipe/custom-pipe.module';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FormDisplayMode } from '@trent/models/UI/form-display-mode';
import { ProductType } from '@trent/models/product/product-type';
import { ReleaseStatusModule } from '../shared/release-status/release-status.module';
import { FileUploadModule } from '../shared/file-upload/file-upload.module';
import { MaterialModule } from '../shared/share-module/material.module';
// import { RentalService } from '@trent/services/rental.service';
import { SlidePanelModule } from '../shared/slide-panel/slide-panel.module';
import { MatNativeDateModule } from '@angular/material/core';
import { RentalProductStoreModule } from '@trent/store/rental-product-store';
import { CarouselModule } from '../shared/carousel/carousel.module';
import { CardComponent } from './card/card.component';
import { RentOptionListComponent } from './list/rent-option-list.component';
import { CarouselViewerModule } from '../shared/carousel-viewer/carousel-viewer.module';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faTruckLoading } from '@fortawesome/free-solid-svg-icons';
import { MapWrapperModule } from '../shared/map-wrapper/map-wrapper.module';
import { MapContainerModule } from '../shared/map-container/map-container.module';
import { SocialShareModule } from '../shared/social-share/social-share.module';
import { RentOptionSearchComponent } from './search/rent-option-search.component';
import { PromoListStoreModule } from '@trent/store/promo-list-store';
import { SearchRentalModule } from '../shared/search-rental/search-rental.module';
import { GooglePLacesAutoDirectiveModule } from '../directive/google-places-auto.module';
import { SelectCompanyModule } from '../shared/select-company/select-company.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { RentalPlanComponent } from './rental-plan/rental-plan.component';
import { RentalTermsComponent } from './rental-terms/rental-terms.component';
import { CompanyStoreModule } from '@trent/store/company-store';
import { SubPageModule } from '../shared/sub-page/sub-page.module';
import { BidStoreModule } from '@trent/store/bid-store/bid-store.module';
import { MatIconRegistry } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { UrlShortenService } from '@trent/services/shorten-url/shorten-url.service';
/* consider make it dynamic import??*/ import { BidDetailComponent } from '../bid/bid-detail/bid-detail.component';
import { TruckDetailComponent } from './truck-detail/truck-detail.component';
import { RentalHeaderModule } from '../shared/rental-header/rental-header.module';

const routes: Routes = [
  { path: 'list', component: RentOptionListComponent },
  { path: 'trailer/list', component: RentOptionListComponent, data: {pType: ProductType.trailer} },
  { path: 'truck/list', component: RentOptionListComponent, data: {pType: ProductType.truck} },
  { path: 'trailer/list/:pid', component: RentOptionListComponent, data: {pType: ProductType.trailer} },
  { path: 'truck/list/:pid', component: RentOptionListComponent, data: {pType: ProductType.truck} },
  { path: 'truck/my-rentals', component: RentOptionListComponent, data: {pType: ProductType.truck} },
  { path: 'detail/:id', component: RentOptionDetailComponent, data: { mode: FormDisplayMode.read }},
  // { path: 'rent-option-list/:id', component: RentOptionListComponent },
  // // { path: 'rent-option-list', component: RentOptionListComponent, data: { mode: 'myRentals' } },
  // // { path: 'rent-option-list/MyRentals', component: RentOptionListComponent, data: { mode: 'myRentals' } },
  // { path: 'my-rent-options', component: MyRentOptionsComponent },
  // { path: 'my-rent-options/:id', component: MyRentOptionsComponent },

  { path: 'rent-option-detail/:id/create', component: RentOptionDetailComponent, data: { mode: FormDisplayMode.create } },
  { path: 'rent-option-detail/:id/edit', component: RentOptionDetailComponent, data: { mode: FormDisplayMode.read } },
  // { path: 'rent-option-param/:id', component: RentOptionParamComponent, data: { mode: FormDisplayMode.read } },
  // { path: 'my-rent-options/:pid', component: MyRentOptionsComponent },
  {
    path: 'truck/:id', component: RentOptionDetailComponent, data: {
      mode: FormDisplayMode.create, pType: ProductType.truck
    }
  },
  {
    path: 'trailer/:id', component: RentOptionDetailComponent, data: {
      mode: FormDisplayMode.create, pType: ProductType.trailer
    }
  },
  {
    path: 'hire-driver/:id', component: RentOptionDetailComponent, data: {
      mode: FormDisplayMode.create, pType: ProductType.driver
    },
  },
  {
    path: 'rent-truck/edit/:id', component: RentOptionDetailComponent, data: {
      mode: FormDisplayMode.read, pType: ProductType.truck
    }
  },
  {
    path: 'product-details/:id', component: TruckDetailComponent, data: { mode: FormDisplayMode.read }
  },
  {
    path: 'rent-trailer/edit/:id', component: RentOptionDetailComponent, data: {
      mode: FormDisplayMode.read, pType: ProductType.trailer
    }
  },
  {
    path: 'hire-driver/edit/:id', component: RentOptionDetailComponent, data: {
      mode: FormDisplayMode.read, pType: ProductType.driver
    }
  },



];
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        // AddressModule,
        GooglePLacesAutoDirectiveModule,
        HttpClientModule,
        RouterModule.forChild(routes),
        ReleaseStatusModule,
        ProductStoreModule,
        RentalProductStoreModule,
        BidStoreModule,
        CompanyStoreModule,
        PromoListStoreModule,
        CustomPipeModule,
        FileUploadModule,
        SlidePanelModule,
        CarouselModule,
        CarouselViewerModule,
        SocialShareModule,
        SearchRentalModule,
        SelectCompanyModule,
        MaterialModule,
        MapWrapperModule,
        MapContainerModule,
        // Material Imports
        MatRadioModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatSelectModule,
        MatCheckboxModule,
        MatTabsModule,
        RentalHeaderModule,
        SubPageModule
        
    ],
    declarations: [RentOptionDetailComponent, CardComponent, RentOptionListComponent, RentOptionSearchComponent, RentalPlanComponent, RentalTermsComponent, TruckDetailComponent
        // sMyRentOptionsComponent, RentOptionSearchComponent
    ],
    exports: [
        CardComponent,
        RentOptionListComponent,
        RentalTermsComponent,
        RentalPlanComponent,
    ],
    providers: [
      CurrencyPipe,
      MatIconRegistry,
      UrlShortenService
    // RentalService
    ]
})
export class RentOptionModule {
  constructor(library: FaIconLibrary) {
    // https://github.com/FortAwesome/angular-fontawesome/blob/master/docs/usage/using-other-styles.md
    library.addIcons(faTruckLoading);
  }
}
