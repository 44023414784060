import { LocalStorage } from '@trent/services/local-storage/local-storage.service';
import { Distance } from './../../models/map/distance';
import { ScriptService } from '../base/script.service';
import { Injectable } from '@angular/core';
// import { MapName, IMap, MapProvider } from '@trentm/map';
import { MapBing } from './map-bing';
import { HttpClient } from '@angular/common/http';
import { EventService } from '../event.service';
import { MapLabel } from '@trent/models/map/map-label';
import { IPoint } from '@trent/models/product-search/interfaces';
import { DistanceCalculator, DistanceQuery, getDistanceKeys } from '@trent/models/map/distance-query';
import { TimerHelper } from '@trent/models/utility';
import { IMap, MapBase, MapName, MapProvider } from '@trent/models/map';
import { LocationData } from '@trent/models/location/loc-data';
import { logger } from '@trentm/log/logger';
import { UtilityService } from '../utility.service';

/**
 * Genearl Map that should cover both Bing and GMap as required.
 */
@Injectable({ providedIn: 'root' })
export class MapGenService implements DistanceCalculator {
  private cacheDistance: { [key: string]: Distance } = {};
  private readonly mapContainer: { [key: string]: IMap } = this.getSiteMapData();
  private readonly tripMapContainer: { [key: string]: IMap } = this.getTripMapData();
  private mapId: string = 'mapHomeHolder';

  // constructor(@Inject(DOCUMENT) private _documentRef: Document, @Inject(WINDOW) private _windowRef: Window) { }
  constructor(private scriptService: ScriptService, public eventService: EventService, private us: UtilityService,
    private http: HttpClient, private localStorage: LocalStorage) {

  }

  public setMapId(mapId: string) { return this.mapId = mapId; };
  public getMap(name: string) { return this.mapContainer[name]; }
  public getTripMap(name: string) { return this.tripMapContainer[name]; }

  public async loadMapRoute(name: MapName, mapLabels?: MapLabel[],
    keepLayers?: { layerIndex: number, layerId: string }[], getRoute?: boolean,
    gpsLog?: LocationData[]) {   // : Promise<RouteResponse> | null
    return new Promise((resolve) => {
      const mc = this.tripMapContainer[name];
      if (!mc) { throw Error('Invalid Map Name: ' + name); }
      mc.setMapContainerId(this.mapId);
      mc.importMapDiv();

      // if script is already loaded, just show.
      if (this.scriptService.isScriptLoaded(mc.url)) {
        resolve(this.loadMapRouteRestPvt(mc, mapLabels, keepLayers, getRoute, gpsLog));
        // resolve(this.loadMapRoutePvt(mc, mapLabels, keepLayers, getRoute));
      }
      // Set up the load event, if not done already.
      if (window[mc.loadCallback] == null) {
        window[mc.loadCallback] = () => {
          logger.log('map script loaded, executing call back!!');
          setTimeout(() => {
            resolve(this.loadMapRouteRestPvt(mc, mapLabels, keepLayers, getRoute, gpsLog));
            // resolve(this.loadMapRoutePvt(mc, mapLabels, keepLayers, getRoute));
          }, 10);
        };
      }
      this.scriptService.loadScript(mc.url);
      // put the script in a timer
      const timer = new TimerHelper();

      timer.setInterval(300, 10, async () => {
        if (this.scriptService.isScriptLoaded(mc.url)) {
          // logger.log('Loaded SIR');
          return true;
        }
        if (this.scriptService.isScriptLoading(mc.url)) {
          // logger.log('Loading SIR');
          return false;
        }
        // logger.log('call script loading !!!');
        this.scriptService.loadScript(mc.url);
        return false;
      });
    });

  }
  private async loadMapRouteRestPvt(map: IMap, mapLabels: MapLabel[],
    keepLayers: { layerIndex: number, layerId: string }[], getRoute: boolean, gpsLog: LocationData[]) {   // : Promise<RouteResponse> | null
    return map.createRouteMapRest(this.http, mapLabels, keepLayers, getRoute, gpsLog);
  }
  public async load(name: MapName, mapLabels?: MapLabel[], param?: string, centerAtLabels?: MapLabel[],
    center?: IPoint, keepLayers?: { layerIndex: number, layerId: string }[] ) {
    let mc: IMap;
    switch (name) {
      case 'home-bing':
        mc = this.mapContainer[name];
        break;
      case 'trip-bing':
        mc = this.tripMapContainer[name];
        break;
      default:
        break;
    }
    if (!mc) { throw Error('Invalid Map Name: ' + name); }

    
    mc.setMapContainerId(this.mapId);
    mc.importMapDiv();

    // if script is already loaded, just show.
    if (this.scriptService.isScriptLoaded(mc.url)) {
      this.loadPvt(mc, mapLabels, center, centerAtLabels, keepLayers, param);
      return;
    }

    // Set up the load event, if not done already.
    if (window[mc.loadCallback] == null) {
      window[mc.loadCallback] = () => {
        logger.log('map script loaded, executing call back!!');
        setTimeout(() => this.loadPvt(mc, mapLabels, center, centerAtLabels, keepLayers, param), 10);
      };
    }
    this.scriptService.loadScript(mc.url);

    // put the script in a timer
    const timer = new TimerHelper();

    timer.setInterval(300, 10, async () => {
      if (this.scriptService.isScriptLoaded(mc.url)) {
        // logger.log('Loaded SIR');
        return true;
      }
      if (this.scriptService.isScriptLoading(mc.url)) {
        // logger.log('Loading SIR');
        return false;
      }
      // logger.log('call script loading !!!');
      this.scriptService.loadScript(mc.url);
      return false;
    });
  }

  private loadPvt(map: IMap, mapLabels?: MapLabel[], center?: IPoint, centerAtLabels?: MapLabel[],
    keepLayers?: { layerIndex: number, layerId: string }[], option?: any) {
    map.createMapObj(mapLabels, center, option, centerAtLabels, keepLayers);
  }


  /**
   * Function should be called ONLY ONCE. as it will reset the script load Promise.
   */
  private getSiteMapData() {
    const mapContainer: { [key: string]: IMap } = {};

    // Add home page Bing.
    const m = new MapBing(this.eventService, this.us);
    const mName: MapName = 'home-bing';
    m.provider = MapProvider.Bing;
    m.mapId = 'mapHome';
    m.hideMapContainer = 'mapBackup';
    m.showMapContainer = this.mapId;
    mapContainer[mName] = m;
    mapContainer[mName] = m;
    return mapContainer;
  }
  /**
 * Function should be called ONLY ONCE. as it will reset the script load Promise.
 */
  private getTripMapData() {
    const tripMapContainer: { [key: string]: IMap } = {};

    // Add home page Bing.
    const m = new MapBing(this.eventService, this.us);
    const mName: MapName = 'trip-bing';
    m.provider = MapProvider.Bing;
    m.mapId = 'mapTrip';
    m.hideMapContainer = 'mapBackup';
    m.showMapContainer = this.mapId;
    tripMapContainer[mName] = m;
    tripMapContainer[mName] = m;
    return tripMapContainer;
  }

  /** Get the distance/duration between two locations from the map api rest call.
   * distance in miles, and druation in hours.*/
  public async getDistance(data: DistanceQuery, useCache = true): Promise<Distance> {
    // await this.localStorage.clear();
    // only single query is supported for now. if there is business need, it should be implemented later.
    const keys = getDistanceKeys(data);
    const k = keys[0];
    if (useCache) {
      if (keys.length !== 1) {
        logger.error('Distance Matrix api, More then one origin/destination pair was provided. Only first entry is supported.');
      }
      // Check in class cache
      if (!!this.cacheDistance[k]) {
        logger.log('distance servered from application cache');
        return this.cacheDistance[k];
      }
      // check in local cache
      // TODO : disabled now, should enable it in prod.
      // const r = await this.localStorage.get(k);
      // if (!!r) {
      //   logger.log('distance served from local storage');
      //   this.cacheDistance[k] = <Distance>r;
      //   return this.cacheDistance[k];
      // }
    }

    // get from server
    let res: Distance;
    switch (data.provider) {
      case MapProvider.Bing:
        res = await MapBing.getDistance(this.http, data);
        break;
      default:
        throw Error('Invalid Map Provider! ' + data.provider + ' is not supported');
    }
    if (!!res) {
      logger.log('distance served from server');
      await this.localStorage.set(k, res);
      this.cacheDistance[k] = res;
    }
    logger.log('distance matrix query result', res);
    return res;

  }

  /**
   * @author KS
   * @purpose Get Route itinerary
   * @param payload 
   * @returns 
   */
  public async loadRouteDetails(payload) {
    const m = new MapBing(this.eventService, this.us);
    let res = m.loadRouteDetails(payload);
    return res;
  }
}
