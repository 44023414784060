import { Component, OnInit, AfterViewInit, ViewChild, OnDestroy, ElementRef } from '@angular/core';

import { Store } from '@ngxs/store';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseForm } from '@trent/models/UI/base-form';
import { NgForm, NgModel } from '@angular/forms';
import { ProductRequested, ProductState } from '@trent/store/product-store/product.state';
import { map, switchMap } from 'rxjs/operators';
import { Observable, of, Subscription } from 'rxjs';

import { RentalTrailer } from '@trent/models/rental/rental-trailer';
import * as _ from 'lodash';
import { RentOptionParam } from '@trent/models/rental/rent-option-param';
import { toSentence, toFirstWord, promiseWraper } from '@trent/models/utility';
import { TaskUtl } from '@trent/models/sys/task-utl';
import { addDays } from 'date-fns';
import { RentalProductBase, RentalValidationGroup } from '@trent/models/rental/rental-product-base';
import { pathRentalMedia, ProductBase, Trailer, TrailerSummary } from '@trent/models/product';
import { ProductType } from '@trent/models/product/product-type';
import { Term } from '@trent/models/rental/term';
import { IValDbStatusResult } from '@trent/models/error-handling/validation-info';
import { Paging } from '@trent/models/observable-util/paging';
import { Picture } from '@trent/models/inspection/picture';
import { EventService } from '@trent/services/event.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogService } from '@trent/services/dialog/dialog.service';
import { FormDisplayMode } from '@trent/models/UI';
import { DbRule } from '@trent/models/base/db-rule';
import { Address } from '@trent/models/address/address';
import { DbStatus } from '@trent/models/base/db-status';
import { fromFailedValidation, fromError, readErrorMessage, MessageInfo } from '@trent/models/error-handling';
import { googlePlaceToAddress } from '@trent/models/address/address-helper';
import { RentalProductRequested, RentalProductState, AllRentalProductsRequested } from '@trent/store/rental-product-store';
import { UtilityService } from '@trent/services/utility.service';
import { RentalService } from '@trent/services/rental.service';
import { PaneType } from '@trent/models/UI/menu-data';
import { RentalTruck } from '@trent/models/rental/rental-truck';
import { logger } from '@trent/models/log/logger';
import { CarouselComponent } from '../../shared/carousel/carousel.component';
import { IRentalStorageMetadata } from '@trent/models/server-storage/i-strorage-metadata';
import { EnumHelper } from '@trent/models/utility/enum-helper';
import { UserProfile } from '@trent/models/user/user-profile';
import { CameraBaseService } from '@trent/services/camera/camera-base.service';
import { IPromoListParam, PromoListType } from '@trent/models/promo';
import { PromoListsRequested, PromoListState } from '@trent/store/promo-list-store';
import { isEqual, filter } from 'lodash';
import { VendorPortfolio } from '@trent/models/promo/vendor-portfolio';
import { MileageType } from '@trent/models/rental/milage-type';
import { RentalPlan } from '@trent/models/rental/rental-plan';
import { RentalTerm } from '@trent/models/rental/rental-term';
import { checkNewPicturesAdded } from '@trent/models/utility/compare-pics-utl';
import { AllBidsRequested } from '@trent/store/bid-store/bid.state';
import { BidParam } from '@trent/models/bid/bid-param';
import { BidState } from '@trent/store/bid-store';
import { combineLatest } from 'rxjs';
import { BidBase } from '@trent/models/bid/bid-base';
import { BidStatus } from '@trent/models/bid/bid-status';
import { Author } from '@trent/models/bid/author';
import { CompanyRequested, CompanyState } from '@trent/store/company-store';
import { CompanyBase } from '@trent/models/company';
import { CurrencyPipe } from '@angular/common';


type Menu = 'pic' | 'openRental' | 'exclusiveOffer';


@Component({
  selector: 'trent-rent-option-detail',
  templateUrl: './rent-option-detail.component.html',
  styleUrls: ['./rent-option-detail.component.scss']
})
export class RentOptionDetailComponent extends BaseForm<RentalProductBase> implements AfterViewInit, OnDestroy, OnInit {

  searchTxt: string;
  activePane: PaneType = 'left';

  @ViewChild('rentOptionForm', { static: false })
  rentOptionForm: NgForm;
  @ViewChild('rentOptionFormSub', { static: false })
  rentOptionFormSub: NgForm;
  @ViewChild('locationAndDepositForm', { static: false })
  locationAndDepositForm: NgForm;
  @ViewChild('rentalPlansForm', { static: false })
  rentalPlansForm: NgForm;


  @ViewChild('picDetail', { static: false })
  // picDetail: PhotoDetailComponent;

  productId: string | number;
  // productRootId: string | number;
  rentalId: string | number;
  rentalRootId: string | number;
  product$: Observable<ProductBase>;
  product: ProductBase;
  productRoot: ProductBase;
  mode: string;
  pTypeEnum = ProductType;
  rTerm = Term;
  mileageTypeEnum = MileageType;
  bStatusEnum = BidStatus;
  rentalProduct$: Observable<RentalProductBase>;
  zIndex = 22000;
  summaryString: string;
  thumbNailImgTruck = 'legoTruck.jpg';
  thumbNailImgTrailer = 'legoTrailer.jpg';
  thumbNailImgDriver = 'legoMan.jpg';
  rentals$: Observable<RentalProductBase[]>;
  rentals: RentalProductBase[] = [];
  cid: string | number;
  showUploadWarning = false;
  mRoot: RentalProductBase;
  saveCancelTxt: string;
  param: RentOptionParam;
  pData: Paging;
  rentalProductsSub: Subscription;
  isLeftVisible = true;


  minDate = new Date();
  maxDate = addDays(new Date(), 120); // allow to rent out for 4 months out
  isHandset$: Observable<boolean>;
  isHandset: boolean;
  rid: string;
  bucket = RentalProductBase.bucket;
  showOpenRentals: boolean;
  pid: any;
  imgContHt: string;
  vParam: IPromoListParam;
  vPortfolio: VendorPortfolio;
  bids: BidBase[];
  otherRentals: RentalProductBase[];
  vCompany: CompanyBase;

  get endMinDate() {
    if (!!this.em && !!this.em.availStartDate) { return addDays(this.em.availStartDate, 1); } else {
      return addDays(new Date(), 1);
    }
  }
  prodType: ProductType;
  rentalWithPics: RentalProductBase[];
  rentalProdWithPicSub: Subscription;
  metadata: IRentalStorageMetadata[] = [];
  path: string;
  @ViewChild('carouselCtrl', { static: false })
  carouselCtrl: CarouselComponent;
  @ViewChild('filePicker', { static: false })
  filePicker: ElementRef<HTMLInputElement>;

  currMenu: Menu;

  get mRTruck(): RentalTruck {
    return (this.m as any) as RentalTruck;
  }
  set mRTruck(value: RentalTruck) {
    this.em = value as any;
  }
  get emRTruck(): RentalTruck {
    return (this.em as any) as RentalTruck;
  }
  set emRTruck(value: RentalTruck) {
    this.em = value as any;
  }
  currSubMenu: RentalValidationGroup = 'locationAndDeposit';
  addHiddenDom = false;

  constructor(store: Store,
    private activatedRoute: ActivatedRoute,
    eventService: EventService,
    public dialog: MatDialog,
    dialogService: DialogService,
    private router: Router,
    private rs: RentalService,
    public camera: CameraBaseService,
    private currencyPipe: CurrencyPipe
  ) { super(store, dialogService, eventService); }

  ngOnInit() {
    this.isHandset$ = this.us.isHandset$;
    this.mSubscription.push(this.us.isHandset$.subscribe(h => {
      this.isHandset = h;
    }));
    this.eventListeners.push(
      this.es.listen<boolean>(this.es.menuBackBtnClickCust, () => this.showLeft()),
      this.es.listen<boolean>(this.es.menuFireEdit, () => this.edit()),
      this.es.listen<boolean>(this.es.menuFireSaveCancel, (save) => save ? this.save() : this.cancel())
    );
    this.bindMenuSaveCancelDisplay = true;
    this.displayMode = this.activatedRoute.snapshot.data.mode;
    let uTester: UserProfile;
    const params$ = this.activatedRoute.params;
    this.prodType = this.activatedRoute.snapshot.data.pType;
    const sub = combineLatest([this.user$, params$])
      .subscribe(([u, p]) => {

        if (!!u && (u.id !== uTester?.id)) {
          uTester = u;
          this.vParam = { type: PromoListType.vendorPortfolio, isMasterList: true }; // companyWithAccess: this.defaultCompany.cid,
          if (this.displayMode === FormDisplayMode.read) {
            this.rentalId = p.id;
            this.rentalRootId = DbRule.isFrozenReleaseBranch(this.rentalId) || DbRule.isDraftBranch(this.rentalId) ?
              DbRule.getRootId(this.rentalId) : this.rentalId;
              this.rentalEdit();
          } else {
            this.pid = p.id;
            this.initialiseState();
          }
          this.title = 'Rental Details';
          this.saveCancelTxt = 'Rental Details';
        }
      });
    this.mSubscription.push(sub);
  }
  private initialiseState() {
    if (this.displayMode === FormDisplayMode.create) {
      switch (this.prodType) {
        case ProductType.trailer:
          this.m = new RentalTrailer();
          break;
        case ProductType.trailer:
          this.m = new RentalTruck();
          break;

        default:
          break;
      }


      this.store.dispatch(new ProductRequested({ id: `${this.pid}` }));

      this.product$ = this.store.select(ProductState.selectProductById)
        .pipe(map(filterFn => filterFn(`${this.pid}`)));
      const pData = { size: 1, offset: 0, full: false };
      this.store.dispatch(new PromoListsRequested({ pData: pData, param: { ...this.vParam } }));
      const v$ = this.store.select(PromoListState.selectAllData)
        .pipe(map(clientFilterFn => clientFilterFn({ ...this.vParam })));
      const rParam: RentOptionParam = { availEndDate: new Date(), vCid: this.defaultCompany?.cid, pid: this.pid, isActive: true }; // vCid: this.defaultCompany.cid, pid: this.pid, isActive: true, orderBy: 'availEndDate' 
      this.store.dispatch(new AllRentalProductsRequested({ pData: this.pData, param: rParam }));
      const r$ = this.store.select(RentalProductState.selectRentalAllProducts)
        .pipe(map(clientFilterFn => clientFilterFn(rParam)));
      const bidParam: BidParam = { endDate: new Date(), vCid: this.defaultCompany?.cid, pid: this.pid, bidStatus: BidStatus.accepted };
      this.store.dispatch(new AllBidsRequested({ pData: this.pData, param: { ...bidParam } }));
      const q$ = this.store.select(BidState.selectAllBids)
        .pipe(map(clientFilterFn => clientFilterFn({ ...bidParam })));
      this.store.dispatch(new CompanyRequested({ id: `${this.defaultCompany.cid}` }));
      const vCompany$ = this.store.select(CompanyState.selectCompanyById)
        .pipe(map(filterFn => filterFn(this.defaultCompany.cid)));

      const sub = combineLatest([this.product$, v$, r$, q$, vCompany$]).subscribe(([p, v, r, q, vC]) => {
        if (!!r && r.length > 0) {
          this.otherRentals = r;
        } else {
          this.otherRentals = [];
        }
        if (!!q && q.length > 0) {
          this.bids = q;
        } else {
          this.bids = [];
        }
        if (!!p) {  // this.isUpdateReqd(x, this.product)
          this.product = p;
          // this.em.productSummary = (<Trailer>this.product).getProductSummary();
          // this.em.productType = +this.product.productType;
          // if (this.em.productType === ProductType.truck || this.em.productType === ProductType.trailer) {
          //   (<RentalTrailer>this.em).pictures = !!(<Trailer>this.product).pictures ? (<Trailer>this.product).pictures : [];
          // }
          // this.getRentalsByPid(this.product.id);
          // this.path = `product/${this.defaultCompany.cid}/${this.em.productSummary.pid}/rental-media`;
          // this.getRentalWithPic();
          // this.getRentalsByPid(this.product.id, true);

        }
        if (!!v && v.length > 0 && !isEqual(v, this.vPortfolio)) {
          this.vPortfolio = (v[0] as VendorPortfolio);
        }
        if (!!vC && !isEqual(vC, this.vCompany) && !!this.product) {
          this.vCompany = vC;
          this.em = this.m.clone();
          this.em.startAddress = new Address();
          this.em.endAddress = new Address();
          this.em.productType = +this.product.productType;
          if (this.em.productType === ProductType.truck || this.em.productType === ProductType.trailer) {
            (<RentalTrailer>this.em).pictures = !!(<Trailer>this.product).pictures ? (<Trailer>this.product).pictures : [];
          }
          this.em.productSummary = this.product.getProductSummary();
          this.em.setCurrency(this.vCompany.address.country as 'CA' | 'US');
          this.path = pathRentalMedia(`${this.product.cid}`, `${this.em.productSummary.pid}`);
          this.setUpMobileButtons();
          this.getImgContHt();
          this.setMetaData();
        }

      });
      this.mSubscription.push(sub);
    }
  }
  rentalEdit() {
    this.store.dispatch(new RentalProductRequested({ id: `${this.rentalRootId}` }));

    this.rentalProduct$ = this.store.select(RentalProductState.selectProductById)
      .pipe(map(filterFn => filterFn(this.rentalRootId)));

    let r$: Observable<RentalProductBase[]>;
    let b$: Observable<BidBase[]>;
    let c$: Observable<[RentalProductBase[], BidBase[]]>;
    const rentalsAndContractsByPid$ = this.store.select(RentalProductState.selectProductById)
      .pipe(map(filterFn => filterFn(this.rentalRootId)),
        switchMap(d => {
          if (!!d && !r$) {
            this.pid = d.productSummary.pid;
            const pData = { size: 1, offset: 0, full: false };
            const rParam: RentOptionParam = { availEndDate: d.availEndDate, vCid: this.defaultCompany?.cid, pid: this.pid, isActive: true }; // vCid: this.defaultCompany.cid, pid: this.pid, isActive: true, orderBy: 'availEndDate' 
            const bidParam: BidParam = { endDate: d.availEndDate, vCid: this.defaultCompany?.cid, pid: this.pid, bidStatus: BidStatus.accepted };
            this.store.dispatch(new AllBidsRequested({ pData: pData, param: { ...bidParam } }));
            this.store.dispatch(new AllRentalProductsRequested({ pData: pData, param: { ...rParam } }));
            r$ = this.store.select(RentalProductState.selectRentalAllProducts)
              .pipe(map(clientFilterFn => clientFilterFn(rParam)));
            b$ = this.store.select(BidState.selectAllBids)
              .pipe(map(clientFilterFn => clientFilterFn({ ...bidParam })));
            c$ = combineLatest([r$, b$]);

          } else {
            c$ = of();
          }
          return c$;
        }));

    const sub = combineLatest([this.rentalProduct$, rentalsAndContractsByPid$])
      .subscribe(([r, [rentalsByPid, bidByPid]]) => {
        if (!!bidByPid && bidByPid.length > 0) {
          this.bids = bidByPid;
        } else {
          this.bids = [];
        }
        if (!!rentalsByPid && rentalsByPid.length > 0) {
          this.otherRentals = rentalsByPid.filter(o => o.id !== this.rentalId);
        } else {
          this.otherRentals = [];
        }
        if (this.isUpdateReqd(r, this.m)) {
          this.m = r;
          this.em = this.m.clone();
          if (!this.em.canEdit(this.defaultCompany.cid, this.user.isAdmin)) {
            logger.log('[rent-option] cannot edit record');
            this.unAuthMessage();
            return;
          }
          this.pid = this.em.productSummary.pid;
          console.log('em', this.em);
          // this.getRentalsByPid(this.em.productSummary.pid);
          if (this.em.productType === ProductType.truck || this.em.productType === ProductType.trailer) {
            (<RentalTrailer>this.em).pictures = !!(<RentalTrailer>this.em).pictures ? (<RentalTrailer>this.em).pictures : [];
          }
          // this.path = `product/${this.defaultCompany.cid}/${this.em.productSummary.pid}/rental-media`;
          this.path = pathRentalMedia(`${this.em.vendorCompSummary.cid}`, `${this.em.productSummary.pid}`);

          // display task as needed.
          this.taskMsg = [];
          const tt = this.activatedRoute.snapshot.queryParams.tt;
          const tmsg = TaskUtl.getClientMsg(tt);
          if (!!tmsg) { this.taskMsg.push(tmsg); }
          this.setUpMobileButtons();
          this.getImgContHt();
          this.setMetaData();

        }

      });
    this.mSubscription.push(sub);
  }
  /** get open rent options on the product to ensure no overlap of the dates & to get pictures from previous rental when available*/

  // getRentalsByPid(pid: string | number) {
  //   let combSub: Subscription;
  //   this.param = {
  //     pid: pid,
  //     cids: [],
  //     vCid: this.defaultCompany.cid,

  //     // isClientGeoFilterReq: false,
  //     dbStatus: DbStatus.Released,
  //     isActive: true
  //     // rentOptionStatus: RentOptionStatus.open,
  //   };
  //   // unsubscribe the previous subscription
  //   if (!!combSub) {
  //     combSub.unsubscribe();
  //   }
  //   this.pData = { size: 10, offset: 0, full: false };

  //   // ** get open rent option by pid to check overlap*/
  //   this.store.dispatch(new AllRentalProductsRequested({ pData: this.pData, param: this.param }));
  //   const bidParam: BidParam = { endDateClient: new Date(), vCid: this.defaultCompany.cid, pid: this.pid };
  //   this.store.dispatch(new AllBidsRequested({ pData: this.pData, param: bidParam }));
  //   const r$ = this.store.select(RentalProductState.selectRentalAllProducts)
  //     .pipe(map(clientFilterFn => clientFilterFn(this.param)));
  //   this.store.dispatch(new AllBidsRequested({ pData: this.pData, param: { ...bidParam } }));

  //   const q$ = this.store.select(BidState.selectAllBids)
  //     .pipe(map(clientFilterFn => clientFilterFn({ ...bidParam })));
  //   combSub = combineLatest([r$, q$]).subscribe(([r, q]) => {
  //     if (!!r && r.length > 0) {
  //       this.rentals = (r as RentalTrailer[]).filter(f => f.id !== this.m.id);  // TODO SS debug only
  //       // if (this.rentalId) {
  //       //   this.rentals = this.rentals.filter(ref => ref.id !== this.rentalId);
  //       // }
  //       this.rentals = this.rentals.sort(function (a, b) { return b.createdAt.valueOf() - a.createdAt.valueOf(); });
  //       console.log('this.rentals', this.rentals);
  //       // this.rentals = this.rentals.filter(f => f.getOpenRentalDates().length > 0);
  //     } else {
  //       this.rentals = [];
  //     }
  //     if (!!q && q.length > 0) {
  //       this.bids = q.filter(f => f.bidStatus === BidStatus.waitingForVendor || f.bidStatus === BidStatus.accepted);
  //     } else {
  //       this.bids = [];
  //     }
  //   });
  //   this.mSubscription.push(combSub);


  // }
  unAuthMessage() {
    this.m = this.em = null;
    const m = new MessageInfo();
    m.header = 'Not Authorized!';
    m.msgCss = 'warn';
    m.description = 'You are not authorized to view this detail!';
    m.delay = 0;
    this.dialogService.alert(m, undefined, () => {
      this.navigateBack(this.router, true);
    });
  }
  toEdit(r: RentalProductBase) {
    // this.rentalId = r.id;
    this.router.navigate([`rent-option/rent-option-detail/${r.id}/edit`]);
  }

  ngAfterViewInit(): void {
    this.setupFormVal();
  }

  setupFormVal(): void {
    setTimeout(() => {
      this.setFormValidation<RentalProductBase>(RentalProductBase, this.currentForm, () => {
        return {
          model: this.em, option: {
            groups: [this.currSubMenu]

          }
        };
      });
    }, 1000);

  }
  openPics() {

    console.log('copyPrivousPic', (<RentalTrailer>this.em).pictures);
    if (this.isHandset) {
      this.showLeft();
    }
  }
  ngOnDestroy(): void {
    this.cleanListeners();
  }
  edit() {
    this.displayMode = FormDisplayMode.edit;
    this.setUpMobileButtons();

  }
  cancel() {
    if (!!this.carouselCtrl) {
      this.carouselCtrl.reset();
    }
    if (this.isCreateRequest) {  // && !this.canNavigateBack
      this.em = this.m.clone();
      this.setUpMobileButtons();
      this.initialiseState();
    } else if (!this.isCreateRequest && !!this.m) {
      this.displayMode = FormDisplayMode.read;
      this.em = null;
      setTimeout(() => {
        this.em = this.m.clone();
        this.setUpMobileButtons();
      }, 300);
    }
  }

  goBack() {
    this.router.navigate(['product']);
  }
  redirectParam(rid: string | number) {
    this.router.navigate([`rent-option/detail/${rid}`]);
    // if (this.rentalId !== rid) {
    //   this.router.navigate([`${this.activatedRoute.snapshot.url[0]}`, 'edit', `${rid}`],
    //     { relativeTo: this.activatedRoute.parent });
    // } else {
    //   { relativeTo: this.activatedRoute.parent });
    // }
  }
  save() {
    this.em.cleanupSelectedTerms();
    if (this.em.isReplacementUnit) {
      const cid: string | number = this.rentalId ? this.em.vendorCompSummary.cid : this.product.cid;
      this.em.setExclusive(`${cid}`);
    }
    logger.log('em at save', this.em);
    this.setPicturesPath();
    if (this.isCreateRequest) {
      if (this.defaultCompanyId !== this.product.cid && !this.user.isEditor) {
        console.log('cid does not match the product cid', this.defaultCompanyId, this.product.cid);
        // need to make this message beautiful
        alert('cid does not match the product cid');
        return;
      }
    }

    const tempR = (this.isCreateRequest) ? this.em.validateSync({ groups: [] })
      : this.em.validateSync({ groups: [] });
    console.log(tempR);
    // console.log(this.rentOptionForm.invalid);
    if (Object.keys(tempR).length > 0) {
      console.log('validation failed');
      this.showAlert(fromFailedValidation(tempR).messageInfo);
      return;
    }

    const b = checkNewPicturesAdded(this.em.pictures, this.m.pictures);

    // check if any new pictures have been added to the previous pictures
    console.log('at save pictures', (<RentalTrailer>this.em).pictures);
    this.showLoading(10);
    // this.uploadPics();
    this.rid = this.isCreateRequest ? this.rs.getNewDocId() : `${this.em.id}`;
    // this.uploadPicsTimer(this.rid);
    // const pR = b ? await promiseWraper(this.carouselCtrl.upload2()) : null;
    // if ((b && (!pR || !pR.success))) {
    //   this.hideLoading();
    //   logger.error('[inspection-detail-comp] pic upload failed ', pR.error);
    //   this.addHiddenDom = false;
    //   return;
    // }
    // if (!this.checkPickUrl()) {
    //   this.hideLoading();
    //   this.addHiddenDom = false;
    //   this.pictureUploadFailed();
    //   return;
    // }

    const prom = (this.isCreateRequest) ? this.rs.create(this.em, this.product.cid, this.rid).toPromise() :
      this.rs.update(this.rentalId, this.em).toPromise();
    prom.then(r => {
      this.hideLoading();
      this.displayMode = FormDisplayMode.read;
      this.redirectParam(r.id);
    }).catch(err => {

      // this.em = this.m.clone();
      this.hideLoading();
      this.showAlert(readErrorMessage(err));
    });
    // this.subscribe(subs, (rData) => {
    //   const rid = rData.rid;
    //   const r = rData.rentalProduct;
    //   if (this.isCreateRequest) {
    //     console.log('created rentalProduct: ', r);
    //     this.m = <RentalTrailer>parseRentalProduct(r);
    //     this.em = this.m.clone();
    //     const msg = (this.showUploadWarning) ? fromCreateWarning('rentalProduct') : fromCreateSuccess('rentalProduct');
    //     this.showPopover(msg, 500, () => this.redirectParam(rid));
    //   } else {
    //     console.log('updated rentalProduct: ', r);
    //     this.m = parseRentalProduct(r) as RentalTrailer;
    //     this.em = this.m.clone();
    //     const msg = (this.showUploadWarning) ? fromUpdateWarning('rentalProduct') : fromUpdateSuccess('rentalProduct');
    //     // if id has modified, redirect param is required.
    //     this.showPopover(msg, 500, () => this.redirectParam(rid));
    //     // if (this.rentalId === rid) {
    //     //   this.m = this.em.clone();
    //     //   this.store.dispatch(new RentalProductUpdated({ rid: rid, rentalProduct: this.em }));
    //     // }
    //   }
    //   // update the validation and the db status of the model.
    //   this.valDbStatus = this.m.getValDbStatus();
    // }, 300);
    return false;
  }
  setPicturesPath() {
    if ((<RentalTrailer>this.em).pictures.length > 0) {
      for (const f of (<RentalTrailer>this.em).pictures) {
        if (!!f.url && (f.url.includes('image/jpeg;base64') || f.url.includes('base64'))) {
          f.url = null;
        }
        f.path = `${this.path}/${f.name}`;
        logger.info('[rent-option] pics path', f.path);
      }
    }
  }

  saveTimer(rid: string | number) {
    this.addHiddenDom = true;

    setTimeout(() => {
      // eslint-disable-next-line max-len
      if (this.carouselCtrl) {
        return this.save();
      } else {
        logger.error('[addHiddenDomain] failed');
        const msg = new MessageInfo({
          msgCss: 'warn',
          header: !!this.isCreateRequest ? 'Rental Created' : 'Rental Updated',
          description: !!this.isCreateRequest ? 'Failed to create.' : 'Failed to update'
        });
        this.showAlert(msg);
        this.hideLoading();
      }
    }, 1000);
  }
  uploadPics(rid: string | number) {

    return this.carouselCtrl.upload2()
      .then(b => {
        if (!!b) {
          logger.log(`[rent-opiton], pcitures loaded ${b}`);
        } else {
          logger.log(`[rent-opiton], pcitures upload failed ${b}`);
        }
        const msg = new MessageInfo({
          msgCss: 'primary',
          header: !!this.isCreateRequest ? 'Rental Created' : 'Rental Updated',
          description: !!this.isCreateRequest ? 'Rental successfully created.' : 'Rental successfully updated'
        });
        this.showAlert(msg);
        this.hideLoading();
        this.addHiddenDom = false;
        this.redirectParam(rid);
      })
      .catch(uError => {
        this.addHiddenDom = false;
        logger.error('[rent-option component], pcitures upload failed', uError);
      });

  }
  // #region Release Process
  submitForApproval() {
    if (this.m.dbStatus !== DbStatus.Initial && this.m.dbStatus !== DbStatus.ReleasedMod) {
      this.showPopover(
        fromError('Data record must be in "initial" or "modified"' +
          'status before review can be submitted').messageInfo);
      return;
    }
    const tempR = this.m.validateSync({ groups: [] });
    console.log(tempR);
    // this.subscribe(s, (c) => {
    //   console.log('submitted for approval: ', c);
    //   this.m = parseRentalProduct(c) as RentalTrailer;
    //   this.em = this.m.clone();
    //   this.showPopover(fromCreateWarning('Rental Product'));
    //   // update the validation and the db status of the model.
    //   this.valDbStatus = this.m.getValDbStatus();
    // });
  }
  adminApproveRecord() {
    console.log('TODO: Must do admin checks before submitting');
    // this.subscribe(s, (rData) => {
    //   const rid = rData.rid;
    //   const c = rData.rentalProduct;
    //   // if a draft branch entry was released, the draft does not exist anymore. so redirect to root.
    //   this.showPopover(fromCreateSuccess('Product [relased]'), null, () => {
    //     if (rid !== this.rentalId) {
    //       this.redirectParam(rid);
    //     } else {
    //       this.m = parseRentalProduct(c) as RentalTrailer;
    //       this.em = this.m.clone();
    //       // update the validation and the db status of the model.
    //       this.valDbStatus = this.m.getValDbStatus();
    //     }
    //   });
    // });
  }
  //#endregion



  navigateBackCustom() {
    console.log('called');
    // this.isLeftVisible = !this.isLeftVisible;

    if (!this.isLeftVisible) {
      this.isLeftVisible = true;
      // this.showHide();
    }
    // else if (this.canNavigateBack) {
    //   this.navigateBack(this.router);
    // }
  }
  /**decipher the address based on google api response  */
  onPlaceLoaded(response: { place: any, ref: any }) { // google.maps.places.PlaceResult
    if (response.ref?.id === 'pickup') {
      this.em.startAddress = googlePlaceToAddress(response.place);
      this.em.startLoc = googlePlaceToAddress(response.place).geoLoc;
    }
    if (response.ref?.id === 'dropoff') {
      this.em.endAddress = googlePlaceToAddress(response.place);
      this.em.endLoc = googlePlaceToAddress(response.place).geoLoc;
    }
  }
  /** filter the dates, based on existing open rent options and allows start date is before end date*/
  // startDateFilter = (d: Date): boolean => {
  //   // const openRentals = this.rentals.filter(f => f.id !== this.rentalId); // && f.rentOptionStatus === RentOptionStatus.open
  //   let isEndDateAfterStart = true;
  //   let isDateStraddle = true;

  //   if (!this.em.availStartDate && !!this.em.availEndDate) {
  //     if (d.valueOf() < this.em.availEndDate.valueOf()) {
  //       isEndDateAfterStart = true;
  //     } else { isEndDateAfterStart = false; }
  //     isDateStraddle = this.em.isDateStraddleOpenRental(openRentals, this.em.availEndDate, d);
  //   }

  //   return this.em.isDateOpenForRent(openRentals, d) && d?.valueOf() > new Date().valueOf()
  //     && isEndDateAfterStart && isDateStraddle;
  //   // && this.em.filterBindingBidsDates(d);
  //   // && this.em.isRentalOverlap(openRentals, d) ;
  // }
  // /** filter the dates, based on existing open rent options and allows end date is afte start date*/
  // endDateFilter = (d: Date): boolean => {
  //   const openRentals = this.rentals.filter(f => f.id !== this.rentalId);  // && f.rentOptionStatus === RentOptionStatus.open
  //   let isEndDateAfterStart = true;
  //   let isDateStraddle = true;
  //   if (!!this.em.availStartDate && !this.em.availEndDate) {
  //     if (d.valueOf() > this.em.availStartDate.valueOf()) {
  //       isEndDateAfterStart = true;
  //     } else { isEndDateAfterStart = false; }
  //     isDateStraddle = this.em.isDateStraddleOpenRental(openRentals, this.em.availStartDate, d);
  //   }
  //   return this.em.isDateOpenForRent(openRentals, d) && d?.valueOf() > new Date().valueOf()
  //     && isEndDateAfterStart && isDateStraddle;
  //   // && this.em.isRentalOverlap(openRentals, d) ;

  //   // && this.em.filterBindingBidsDates(d)
  // }
  // startDateFilter1 = (d: Date): boolean => {
  //   const openRentals = this.rentals.filter(f => f.id !== this.rentalId); // && f.rentOptionStatus === RentOptionStatus.open
  //   let isEndDateAfterStart = true;
  //   let isDateStraddle = true;

  //   if (!this.em.availStartDate && !!this.em.availEndDate) {
  //     if (d.valueOf() < this.em.availEndDate.valueOf()) {
  //       isEndDateAfterStart = true;
  //     } else { 
  //       isEndDateAfterStart = false; 
  //     }
  //     isDateStraddle = this.em.isDateStraddleOpenRental2(openRentals, this.bids, this.em.availEndDate, d);
  //   }

  //   return this.em.isDateOpenForRent1(openRentals, this.bids, d) && d?.valueOf() > new Date().valueOf()
  //     && isEndDateAfterStart && isDateStraddle;
  //   // && this.em.filterBindingBidsDates(d);
  //   // && this.em.isRentalOverlap(openRentals, d) ;
  // }
  // endDateFilter1 = (d: Date): boolean => {
  //   const openRentals = this.rentals.filter(f => f.id !== this.rentalId);  // && f.rentOptionStatus === RentOptionStatus.open
  //   let isEndDateAfterStart = true;
  //   let isDateStraddle = true;
  //   if (!!this.em.availStartDate && !this.em.availEndDate) {
  //     if (d.valueOf() > this.em.availStartDate.valueOf()) {
  //       isEndDateAfterStart = true;
  //     } else { isEndDateAfterStart = false; }
  //     isDateStraddle = this.em.isDateStraddleOpenRental2(openRentals, this.bids, this.em.availStartDate, d);
  //   }
  //   return this.em.isDateOpenForRent1(openRentals, this.bids, d) && d?.valueOf() > new Date().valueOf()
  //     && isEndDateAfterStart && isDateStraddle;
  //   // && this.em.isRentalOverlap(openRentals, d) ;

  //   // && this.em.filterBindingBidsDates(d)
  // }
  /** removes end date if start date is re-entered after the end date.
   * removes start date is end date is re entered before the start date */
  dateChange(e) {
    console.log('datechange fired', e);
    // const openRentals = this.rentals.filter(f => f.id !== this.rentalId || true);  // && f.rentOptionStatus === RentOptionStatus.open

    if (e.targetElement.name === 'availStartDate') {
      if (!!this.em.availEndDate && e.value.valueOf() > this.em.availEndDate.valueOf()) {
        this.em.availEndDate = undefined;
      }
      // if (!!this.em.availEndDate && !this.em.isDateStraddleOpenRental2(openRentals, this.bids, this.em.availEndDate, e.value)) {
      //   this.em.availEndDate = undefined;
      // }
    }
    if (e.targetElement.name === 'availEndDate') {
      if (!!this.em.availStartDate && e.value.valueOf() < this.em.availStartDate.valueOf()) {
        this.em.availStartDate = undefined;
      }
      // if (!!this.em.availStartDate && !this.em.isDateStraddleOpenRental2(openRentals, this.bids, this.em.availStartDate, e.value)) {
      //   this.em.availEndDate = undefined;
      // }
    }
    this.em.setRentalPlanAvailability();
    this.setupFormVal();
  }
  shortTitle(str: string) {
    if (!!str) {
      return toFirstWord(str);
    }
    return '?';
  }
  toSentence(str: string) { return toSentence(str); }

  removeRate(res: 'removeMonthly' | 'removeWeekly' | 'removeDaily' | 'removeThreeMonthly' | 'removeSixMonthly' | 'removeYearly') {
    switch (res) {
      case 'removeMonthly':
        this.em.isMonthlySelected = false;
        this.em.rateMonthly = undefined;
        break;
      case 'removeWeekly':
        this.em.isWeeklySelected = false;
        this.em.rateWeekly = undefined;
        break;
      case 'removeDaily':
        this.em.isDailySelected = false;
        this.em.rateDaily = undefined;
        break;
      case 'removeThreeMonthly':
        this.em.isThreeMonthTermSelected = false;
        this.em.rateThreeMonthTerm = undefined;
        break;
      case 'removeSixMonthly':
        this.em.isSixMonthTermSelected = false;
        this.em.rateSixMonthTerm = undefined;
        break;
      case 'removeYearly':
        this.em.isYearTermSelected = false;
        this.em.rateYearTerm = undefined;
        break;

      default:
        break;
    }
  }
  addRate(res: 'addMonthly' | 'addWeekly' | 'addDaily' | 'addThreeMnth' | 'addSixMnth' | 'addYearly') {
    switch (res) {
      case 'addMonthly':
        this.em.isMonthlySelected = true;
        break;
      case 'addWeekly':
        this.em.isWeeklySelected = true;
        break;
      case 'addDaily':
        this.em.isDailySelected = true;
        break;
      case 'addThreeMnth':
        this.em.isThreeMonthTermSelected = true;
        break;
      case 'addSixMnth':
        this.em.isSixMonthTermSelected = true;
        break;
      case 'addYearly':
        this.em.isYearTermSelected = true;
        break;

      default:
        break;
    }
    this.setupFormVal();
  }
  get baseURL() {
    let u: string;
    if (!!this.em && !!this.em.productSummary) {
      switch (this.em.productSummary.productType) {
        case ProductType.truck:
          u = 'rent-truck/edit/';
          break;
        case ProductType.trailer:
          u = 'rent-trailer/edit/';
          break;

        default:
          break;
      }
    } else {
      u = null;
    }
    return u;
  }
  openHelp(url) {

  }
  tl(a) {
    return a as RentalTrailer;
  }
  removeAddress(n: NgModel) {
    n.reset();
  }
  // showHide(s?: string) {
  //   if (this.isLeftVisible) {
  //     this.showLeft();

  //   }
  // }
  // showLeft(menu: Menu = 'pic') {
  //   logger.log(`[rent-option-component] Before - GoBack ${this.isGoBackCust}, Left-Visible: ${this.isLeftVisible}`);
  //   if (this.isLeftVisible) {
  //     this.currMenu = menu;
  //   }
  //   this.isLeftVisible = !this.isLeftVisible;
  //   this.activePane = this.isLeftVisible ? 'left' : 'right';
  //   this.isGoBackCust = !this.isLeftVisible;
  //   logger.log(`[rent-option-component] After - GoBack ${this.isGoBackCust}, Left-Visible: ${this.isLeftVisible}`);

  //   this.setUpMobileButtons();
  // }
  showLeft(s?: any) {
    logger.log(`[company] Before - GoBack ${this.isGoBackCust}, Left-Visible: ${this.isLeftVisible}`);
    this.isLeftVisible = !this.isLeftVisible;
    this.activePane = this.isLeftVisible ? 'left' : 'right';
    this.isGoBackCust = !this.isLeftVisible;
    logger.log(`[company] After - GoBack ${this.isGoBackCust}, Left-Visible: ${this.isLeftVisible}`);
  }
  /** Show - Hide Master/child form display */
  showHide(sMenu: RentalValidationGroup) {
    this.currSubMenu = sMenu;
    this.setupFormVal();
    // this.addRentalPlans(sMenu)

    if (this.isLeftVisible) {
      this.showLeft();
    }
  }
  get valDbStatus(): IValDbStatusResult { return this.em.getValDbStatus(); };

  // uploadTrigger() {
  //   logger.info('[rent-option detail comp] uploaderTrigger');

  //   this.em.pictures.forEach(() => {
  //     this.metadata.push({
  //       customMetadata: {
  //         uid: `${this.user.id}`,
  //         cid: `${this.defaultCompany.cid}`,
  //         pid: `${this.em.productSummary.pid}`,
  //         rid: `${this.rid}`,
  //         type: 'rental'
  //       }
  //     });
  //   });
  //   logger.log('[rent-options-comp] pics updated', (<RentalTrailer>this.em).pictures);
  // }
  setMetaData() {
    this.metadata.push({
      customMetadata: {
        uid: `${this.user.id}`,
        cid: !this.em.id ? `${this.product.cid}` : this.em.vendorCompSummary.cid,
        pid: `${this.em.productSummary.pid}`,
        rid: `${this.rid}`,
        type: 'rental'
      }
    });
  }
  selectedPics(picFomChild: Picture[]) {
    for (const pic of picFomChild) {
      if (!(<RentalTrailer>this.em).pictures.includes(pic)) {
        (<RentalTrailer>this.em).pictures.push(pic);
      }
    }
    if (!!this.carouselCtrl) {
      this.carouselCtrl.upload2(true)
        .then(b => {
          logger.info(`Picture uploaded ${b}`);
          this.em.pictures.forEach(e => e.file = undefined);
        })
        .catch(e => logger.error('pic uploaded failed', e));
    }
  }
  deletePics(e: { pic?: Picture }) {
    let index: number;
    if (!!e.pic) {
      index = (<RentalTrailer>this.em).pictures.findIndex(u => u.url === e.pic.url);
    }
    if (index == null) {
      (<RentalTrailer>this.em).pictures = [];
    } else {

      (<RentalTrailer>this.em).pictures.splice(index, 1);
      logger.log(`[rent-otion.comp] pictures after delete action index ${index}`, (<RentalTrailer>this.em).pictures);
    }

  }
  /** UI helper to get product name base on Enum value,  1 will return Trailer*/
  get alertBar(): string {
    // eslint-disable-next-line max-len
    return `${(<TrailerSummary>this.em.productSummary).title}(${(<TrailerSummary>this.em.productSummary).unitName}) has open rent-option(s)`;
  }
  openRentOptions() {
    // if (this.isHandset) {
    //   this.showLeft('openRental');
    // }
    switch (this.em?.productSummary?.productType) {
      case ProductType.trailer:
        this.router.navigate([`rent-option/trailer/list/${this.em.productSummary.pid}`], this.rb);
        break;
      case ProductType.truck:
        this.router.navigate([`rent-option/truck/list/${this.em.productSummary.pid}`], this.rb);
        break;

      default:
        throw new Error(`Prodcut Type not implemented', ${this.em?.productSummary?.productType}`);

    }

  }
  setUpMobileButtons() {
    if (this.isLeftVisible) {
      if (this.displayMode === FormDisplayMode.read) {
        this.showHideEdit(true);
        this.showHideSaveCancel(false);
      } else {
        this.showHideEdit(false);
        this.showHideSaveCancel(true);
      }
    } else {
      this.showHideEdit(false);
      this.showHideSaveCancel(false);
    }
  }
  addPicturesWithoutLocId(event: Event) {
    // logger.info('[asset-pic-map-component], add picture event', e.event, e.locId);

    if (!!this.us.isPlatformBrowser) {
      this.carouselCtrl.selectFilesInWeb(event, undefined, this.filePicker);
    }
  }
  getImgContHt() {
    if (this.isHandset) {
      // const mobileToolBar = 60;
      // const filler = 165;
      // const a = mobileToolBar + filler;
      this.imgContHt = 'calc(50vh)';
    } else {
      const dtopToolBar = 80;
      const dtopMiniToolBar = 40;
      const filler = 300;
      const selectCompHt = this.em.isExclusive ? 200 : 0;
      const a = dtopToolBar + dtopMiniToolBar + filler + selectCompHt;
      this.imgContHt = `calc(100vh - ${a}px)`;
    }
  }
  onCameraClick() {
    if (!this.camera.isCameraAvailable) {
      this.filePicker.nativeElement.click();

    } else {
      this.carouselCtrl.takePicCrdv(1);
    }
  }
  exclusiveDetails() {
    if (this.em.isExclusive) {
      this.getImgContHt();
      if (this.isHandset) { this.showLeft('exclusiveOffer'); }
    } else {
      this.em.targetCCids = null;
    }
  }
  addSelectedComp(cCid: string) {
    this.em.targetCCids = !this.em.targetCCids ? this.em.targetCCids = [] : this.em.targetCCids.slice();
    if (!this.em.targetCCids.includes(cCid)) {
      this.em.targetCCids.push(cCid);
    }
  }
  removeCompany(cCid: string) {
    if (!!this.em.targetCCids && this.em.targetCCids.includes(cCid)) {
      this.em.targetCCids = this.em.targetCCids.filter(f => f !== cCid);
    }
  }
  // get hasOpenRentals() {
  //   return this.rentals.filter(f => f.id !== this.em.id).length > 0;
  // }
  milageTypeChg() {
    console.log('[milage]', this.em.mileageType);
    console.log('[rate per mile]', this.em.ratePerMile);
    if (this.em.mileageType === MileageType.unlimited) {
      this.em.setMilageUnlimited();
    } else {
      this.setupFormVal();
    }
  }
  mileageSubString() {
    switch (this.em.mileageType) {
      case MileageType.maximum:
        return 'Maximum';
      case MileageType.minimum:
        return 'Minimum';
      default:
        throw new Error('called with incorrect param');
    }
  }
  mileageString(m?: RentalValidationGroup) {
    switch (this.em.mileageType) {
      case MileageType.maximum:
        return 'Maximum';
      case MileageType.minimum:
        return 'Minimum';
      default:
        throw new Error('called with incorrect param');
    }
  }
  get currentForm(): NgForm {
    switch (this.currSubMenu) {
      case 'locationAndDeposit':
        return this.locationAndDepositForm;
      case 'ratePlan':
        return this.rentalPlansForm;

      default:
        logger.error('Programming error, finish this switch statement sir');
        return null;
    }
  }
  onPlanSelection(p: RentalPlan) {
    if (!p.isSelected) {
      const r = this.em.rentalPlans.find(f => f.rentalTerm === p.rentalTerm);
      r.milage = undefined;
      r.ratePerDay = undefined;
      r.ratePerMile = undefined;
      r.mileageType = MileageType.minimum;
    }
  }
  onMileageTypeChange(p: RentalPlan) {
    if (p.mileageType === MileageType.flexible) {
      const r = this.em.rentalPlans.find(f => f.rentalTerm === p.rentalTerm);
      r.milage = undefined;
    }
    if (p.mileageType === MileageType.unlimited) {
      const r = this.em.rentalPlans.find(f => f.rentalTerm === p.rentalTerm);
      r.milage = undefined;
      r.ratePerMile = undefined;

    }
  }
  checkPickUrl() {
    if (this.em.pictures.findIndex(f => !f.url) > - 1) {
      return false;
    }
    return true;
  }
  pictureUploadFailed() {

    const m = new MessageInfo();
    m.header = 'Picture';
    m.msgCss = 'warn';
    m.description = 'Picture Upload failed';
    m.delay = 0;
    this.dialogService.alert(m, undefined, (b) => {

    });
  }
  openContract(bid: BidBase) {
    const queryParams = { ...this.rb.queryParams, ...{ author: Author.vendor } };
    this.router.navigate([`/bid/rental-contract-vendor/${bid.id}`], { queryParams: queryParams });
  }
  openRental(rental: RentalProductBase) {
    this.router.navigate([`/rent-option/detail/${rental.id}`], this.rb);

  }
  toCurrency(n: number) {
    if (!!n) {
      return this.currencyPipe.transform(n, this.em.currency, this.em.currency);
    }
  }
}


// Exclusive: Only selected companies will be see the exclusive rent-option. Open: Any one can see this rent-option. 