<!-- A div that contains the header and blog wrapper sections -->
<div>
    <!-- A div that styles the header section -->
    <div class="header">
        <h1>Our Blogs</h1>
    </div>
    <!-- A div that contains a grid list of blog tiles -->
    <div class="blogs-wrapper">
        <!-- A Material Design grid list that takes in inputs from the component -->
        <mat-grid-list [cols]="breakpoint" [rowHeight]="rowHeight" (window:resize)="onResize($event)">
            <!-- The first tile in the list with class "tile-1" -->
            <mat-grid-tile class="tile-1">
                <!-- The title heading for the tile -->
                <h1 class="title-heading">GET UPDATE</h1>
                <!-- The text for the tile -->
                <p class="text">Subscribe our newsletter for our latest offers.</p>
            </mat-grid-tile>
            <!-- The second tile in the list -->
            <mat-grid-tile>
                <!-- A button for subscribing to the newsletter, which is enabled when user is logged in -->
                <button class="subscribe-btn" [disabled]="isLoggedIn" (click)="navigateToLogin()">Subscribe</button>
            </mat-grid-tile>
        </mat-grid-list>
    </div>
</div>
