import { Directive, Output, EventEmitter, HostListener } from '@angular/core';
import { logger } from '@trent/models/log/logger';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[dropZone]'
})
export class DocDropZoneDirective {

    @Output() dropped = new EventEmitter<FileList>();
    @Output() hovered = new EventEmitter<boolean>();

    constructor() { }

    @HostListener('drop', ['$event'])
    onDrop($event) {
        $event.preventDefault();
        this.dropped.emit($event.dataTransfer.files);
        this.hovered.emit(false);
        logger.log();
    }
    @HostListener('dragover', ['$event'])
    onDragOver($event) {
        $event.preventDefault();
        this.hovered.emit(true);
    }
    @HostListener('dragleave', ['$event'])
    onDragLeave($event) {
        $event.preventDefault();
        this.hovered.emit(false);
    }
}

