import { Length, validateSync, ValidatorOptions } from 'class-validator';
import { IValidationMsg } from '../error-handling';
import { toValidationError } from '../utility';
import { instanceToInstance, plainToInstance } from 'class-transformer';

export class AssignedCompany {
	@Length(1, 100)
	companyId: string;

	@Length(1, 100)
	companyName: string;

	constructor() { }

	public static parse(obj) {
		if (obj == null) { return null; }
		const m = plainToInstance<AssignedCompany, any>(AssignedCompany, obj);
		m.sanitize();
		return m;
	}

	public static arrayParse(objs) {
		return objs.map(o => AssignedCompany.parse(o));
	}

	clone() {
		const t = instanceToInstance(this);
		t.sanitize();
		return t;
	}

	/** when read from jason or from plain to class, the date objects may be stored as string. convert them to date. */
	sanitize() {
		// if data was received from firebase, date is stored as snapshot.
	}

	validateSync(options?: ValidatorOptions): IValidationMsg {

		let r = this.validateSyncBase(this, options);
		return r;
	}
	public validateSyncBase($this: any, options?: ValidatorOptions, forbidUnknownValues?: boolean): IValidationMsg {
		if (!options) {
			options = {};
		}
		options.forbidUnknownValues = forbidUnknownValues ? true : false;
		const r = validateSync($this, options);
		const m = toValidationError(r);
		return m;
	}
}