import { Exclude, Expose, instanceToInstance, plainToInstance, Type } from 'class-transformer';
import { IsBoolean, IsDate, IsDefined, IsInt, Max, Min, validateSync, ValidateIf, Equals, IsString, Length } from 'class-validator';
import { BaseModel } from '../base/base-model';

export enum ReviewType{
  application = 1,
  storeLocation = 2,
}


@Exclude()
export class Reviews extends BaseModel {

  public static readonly collectionName = 'reviews';

  @Expose()
  @IsDefined({ message: 'ReviewType should be required' })
  reviewType:ReviewType;

  @Expose()
  @ValidateIf(o => o.reviewType == ReviewType.storeLocation)
  @IsDefined({ message: 'City should be required' })
  city:string;


  // Description for the review
  @Expose()
  @Length(5, 255, { message: 'Description should be $constraint1 and $constraint2 chars' })
  @IsDefined({ message: 'Description should be required' })
  description: string;

  // Name of the reviewer
  @Expose()
  @Length(5, 100, { message: 'Name should be $constraint1 and $constraint2 chars' })
  @IsDefined({ message: 'Name should be required' })
  reviewerName: string;

  // Rating given by the reviewer
  @Expose()
  @Max(5)
  @Min(0.5)
  @IsDefined({ message: 'Rating should be required from 0.5 to 5' })
  rating: number;

  // For ui side require for flip
  status:string;


  sanitize() {
    super.sanitize();
  }

  clone() {
    const t = instanceToInstance(this);
    t.sanitize();
    return t;
  }

  validateSync(){
    const r = this.validateSyncBase(this);
    return r;
  }

  public static parse(obj) {
    if (obj == null) { return null; }
    const m = plainToInstance<Reviews, any>(Reviews, obj);
    m.sanitize();
    return m;
  }


}
