import { Injectable } from '@angular/core';
import { BaseHttpService } from './base-http.service';
import { Store } from '@ngxs/store';
import { FirestoreService } from './firestore.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tripTrackerSerachServerQuery, ITripTrackerParam } from '../models/trip/trip-tracker-param';
import { tap, map } from 'rxjs/operators';
import { TripTracker } from '@trent/models/trip';
import { PagingObesrvable } from '@trent/models/observable-util/paging-obesrvable';
import { Paging } from '@trent/models/observable-util/paging';
import { logger } from '@trentm/log/logger';

@Injectable()
export class TripTrackerService extends BaseHttpService {

  constructor(store: Store, private db: FirestoreService, private http: HttpClient) {
    super(store);
    this.apiName = 'api';
  }
  public getTripTrackerById(id: string | number) {
    return this.db.docWithInjectedId$<TripTracker>(
      `${TripTracker.collectionName}/${id}`, id);
  }
  public getAllTripTrackers_PagingObservable() {
    const p$: PagingObesrvable<TripTracker, ITripTrackerParam> =
      new PagingObesrvable<TripTracker, ITripTrackerParam>(this.db,
        (p: Paging, param?: ITripTrackerParam) => this.getAllTripTrackers_batch(p, param));
    return p$;
  }
  /**
 * Get the next batch of the data from server.
 * @param p paging information.
 */
  private getAllTripTrackers_batch(p: Paging, param: ITripTrackerParam): Observable<{ [key: string]: TripTracker }> {
    if (param.orderBy == null) { param.orderBy = 'updatedAt'; }
    if (param.orderDirection == null) { param.orderDirection = 'desc'; }
    return this.db
      .colWithIdsInjectedNew$<TripTracker>(`${TripTracker.collectionName}`, ref => tripTrackerSerachServerQuery(ref, param, p))
      .pipe(
        tap(arr => {
          if (arr == null || arr.length === 0) {
            logger.log('All data is recevied, Paging is FULL');
            p.full = true;
          } else {
            // HG TODO: Fix the usage of lastDoc in the server query functin insdie order-param.
            p.lastDoc = arr[arr.length - 1];
          }
        }),
        map(arr => {
          // logger.log('From Server, before mapping is applied: ', arr);

          // convert array to { [id: string] = CompanyFleet} object.
          const arrayAsObj = arr.reduce((acc, cur) => {
            const id = cur.id;
            const data = cur;
            return { ...acc, [id]: data };
          }, {});

          // logger.log('after converting to array object dic ', arrayAsObj);
          return arrayAsObj;
        })
      );
  }
}