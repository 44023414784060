import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../shared/share-module/material.module';
import { FormsModule } from '@angular/forms';
import { ContactUsComponent } from './contact-us.component';
import { SubPageModule } from '../shared/sub-page/sub-page.module';
import { ContactUsFormComponent } from './contact-us-form/contact-us-form.component';
import { HttpClientModule } from '@angular/common/http';
import { DelegateInspectionService } from '@trent/services/delegate-inspection.service';
import { PhoneMaskModule } from '../directive/phone-mask.module';
/* Consider making dynamic. heavy build.  */ import { RentOptionModule } from '../rent-option/rent-option.module';
import { SlidePanelModule } from '../shared/slide-panel/slide-panel.module';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule, Routes } from '@angular/router';
import { FormDisplayMode } from '@trent/models/UI/form-display-mode';

const routes: Routes = [
{    path: 'contact', component: ContactUsComponent, data: { mode: FormDisplayMode.read, pid: 'contact' },
}
];

@NgModule({
    declarations: [
        ContactUsComponent,
        ContactUsFormComponent
    ],
    imports: [
        CommonModule,
        HttpClientModule,
        FormsModule,
        RouterModule.forChild(routes),
        MaterialModule,
        MatTabsModule,
        SubPageModule,
        PhoneMaskModule,
        RentOptionModule,
        SlidePanelModule
    ], 
    providers: [
        DelegateInspectionService
    ]
})
export class ContactUsModule {
  constructor() {

  }
}
