import { CollectionReference, Query, query, QueryConstraint, where, orderBy, startAfter, limit } from 'firebase/firestore';
import { Paging } from '../observable-util/paging';
import { logger } from '../log/logger';
import { PtsPriceQuote } from './pts-price-quote';

export interface PriceQuoteParam {
	orderDirection?: 'desc' | 'asc';
	orderBy?: string;
	id?: string | number;
}

export const priceQuoteParamInit = (): PriceQuoteParam => {
	return {
		orderBy: 'updatedAt',
		orderDirection: 'desc',
	};
};

/** Server filtering of firebase query building of query  */
export const priceQuoteSearchServerQuery = (ref: CollectionReference, param: PriceQuoteParam, p: Paging) => {
	let q: Query = <any>ref;
	const cons: QueryConstraint[] = [];
	cons.push(where('updatedAt', '<=', new Date()));
	cons.push(orderBy('updatedAt', 'desc'));

	if (!!p.lastDoc) {
		logger.log('Start After Query: ', p.lastDoc[param.orderBy]);
		cons.push(startAfter(p.lastDoc[param.orderBy]));
	}
	if (!!param.id && param.id !== 'x') {
		cons.push(where('id', '==', param.id));
	}
	cons.push(limit(p.size));
	q = query(ref, ...cons);
	return q;
};

/** Client Filtering of the data */
export const priceQuoteSearchClientFilter = (p: PtsPriceQuote[], o: PriceQuoteParam): PtsPriceQuote[] => {
	// Filter by sales option by id
	if (o.id) {
		p = p.filter((val, idx) => val.id === o.id);
	}
	return p as PtsPriceQuote[];
};

export const getPriceQuoteSearchOptOrChildren = (o: PriceQuoteParam): { [key: string]: PriceQuoteParam } => {
	return undefined;
};