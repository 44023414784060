import { LCCustomerCompanySummary } from './lc-customer-company-summary';
import { LCExcelSummary } from './lc-excel-summary';
import { LeaseSummary } from './lease-summary';
import { LegacyContractBase } from './legacy-contract-base';
import { LCProduct } from './lc-product';
import { LCLeaseOperation } from './lc-lease-operation';

//MKN  - UI flag to manage buyout and cancelled child scenario
export type CancelledAllChildRecordStatus = 'ALL_CHILD' | 'ONLY_MASTER' | 'NO_CHILD_LEFT'; //UI only


export const parseLegacyContractArray = (obj: any[]): LegacyContractBase[] => {
  return obj.map(o => <LegacyContractBase>parseLegacyContract(o));
};

export const parseLegacyContract = (obj: any): LegacyContractBase => {
  if (obj == null) { return null; }
  const r = LegacyContractBase.parse(obj); // cast it to base, remember it is still a javascript obj at run time.
  if(r.leaseSummary) r.leaseSummary = LeaseSummary.parse(r.leaseSummary); // cast it to base, remember it is still a javascript obj at run time.
  if(r.customerCompSummary) r.customerCompSummary = LCCustomerCompanySummary.parse(r.customerCompSummary); // cast it to base, remember it is still a javascript obj at run time.
  if(r.lcProduct) r.lcProduct = LCProduct.parse(r.lcProduct); // cast it to base, remember it is still a javascript obj at run time.
  if(r.lcLeaseOperation) r.lcLeaseOperation = LCLeaseOperation.parse(r.lcLeaseOperation); // cast it to base, remember it is still a javascript obj at run time.
  if(r.originalExcelSummary) r.originalExcelSummary = LCExcelSummary.parse(r.originalExcelSummary); // cast it to base, remember it is still a javascript obj at run time.

  return r;
};



