import { ContractBase } from './contract-base';
import { ContractTruckSummary } from '../product/truck';
import { CustomerCompanySummary, VendorCompanySummary } from '../company/company-fleet';

export class ContractTruck  {
    constructor() {
    }
    contractTruckSummary: ContractTruckSummary;
    customerCompanySummary: CustomerCompanySummary;
    vendorCompanySummary: VendorCompanySummary;

}
