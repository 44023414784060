import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { AuthState } from '@trent/store/auth-store/auth.state';

@Component({
  selector: 'trent-dpc-home-blogs',
  templateUrl: './dpc-home-blogs.component.html',
  styleUrls: ['./dpc-home-blogs.component.scss']
})
export class DpcHomeBlogsComponent implements OnInit {
  breakpoint: number;
  rowHeight: string;
  isLoggedIn: boolean;

  constructor(private store: Store, private router: Router) {}

  ngOnInit(): void {
    // Selecting the isLoggedIn property from the authentication store using state selector,
    // and subscribing to its value changes. Whenever its value changes, updating the isLoggedIn variable accordingly.
    this.store.select(AuthState.isLoggedIn)
    .subscribe(isLoggedIn => {
      this.isLoggedIn = isLoggedIn;
    });

    // Setting initial breakpoint and row height values based on the device screen's width 
    this.breakpoint = (window.innerWidth <= 768) ? 1 : 2;
    this.rowHeight = (window.innerWidth <= 768) ? (window.innerWidth <= 479)? '120px': '150px' : '400px';
  }

  // Function to handle screen resize events, and update breakpoint and row height variables accordingly.
  onResize(event) {
    this.breakpoint = (event.target.innerWidth <= 768) ? 1 : 2;
    this.rowHeight = (event.target.innerWidth <= 768) ? (event.target.innerWidth <= 479)? '120px': '150px' : '400px';
  }

  // Function to navigate user to login page
  navigateToLogin(){
    this.router.navigateByUrl('/login');
  }
}
