export enum OrderType {

  rejected = -30,

  /** Initial state when some one bids etc. No contract in place and record is in draft. */
  cancelled = -20,

  /** Initial state when some one bids etc. No contract in place and record is in draft. */
  quote = -10,

  /** Offer made, if accepted by other party, it is a contract. */
  preAuthorization = 0,


  /** The order is already preauthorzed. It will be automatically paid when the order contract
   * is processed. */
  preAuthorized = 10,


  /** Order here is now a binding contract. It can be paid.  */
  pay = 20,


  /** Order is already paid. */
  paid = 30

}

/** Currency code on the orders. */
export enum CurrencyCode {
  CAD = 0,
  USD = 1
}
