import { Address } from "../address/address";
import { RentOptionSummaryBase } from "../rental/rent-option-summary";
import { RentalPlan } from "../rental/rental-plan";
import { NegoTermsBase } from "./nego-terms-base";
import { TermsType } from "./terms-type";
import { IsArray, IsBoolean, IsDate, IsDefined, IsEnum, isEnum, IsIn, IsNumber, Length, Matches, MaxLength, MinLength, ValidateIf, ValidateNested, ValidatorOptions } from 'class-validator';
import { Exclude, Expose, instanceToInstance, plainToInstance, Type } from 'class-transformer';
import { addDays, addYears, daysInWeek, differenceInDays, isAfter, isBefore, isDate, isEqual, startOfDay } from "date-fns";
import { logger } from "../log/logger";
import { RentalTerm } from "../rental/rental-term";
import { F } from "@angular/cdk/keycodes";
import { BidBase, IContractNo } from "../bid/bid-base";
import { IValidationMsg } from "../error-handling/validation-info";
import { RentalProductBase } from "../rental/rental-product-base";
import { Author } from "../bid/author";
import { NegoTermsResponse } from "./nego-terms-response";
import { getRentalAgreement } from "../rental/rental-helper";
import { ProductSummary, ProductType } from "../product";
import { sanitizeDate, sanitizeDateIPoint } from "../utility/sanitize-helper";
import { Order } from "../finance/order/order";
import { Invoice } from "../finance/invoice/invoice";
import { IAssignedContractData } from "./i-bid-order-data";
import { InvoiceItemCat, SpecialItemTypeOptionalDb } from "../finance/invoice/invoice-item-type";
import { Levy } from "../finance/tax/levy";
import { TaxApplicable } from "../finance/tax/tax-applicable";
import { MileageType } from "../rental/milage-type";
import { currencies, CurrencyType, getCurrencyByCountry } from "../finance/currency/currency-type";
import { isEmpty } from "lodash";


/**
 * MKN - Maintain Original Contract summary in case of replacement contract case
 */

// KN Added leaseNo, sNo & contractType for legacy contracts
export interface OriginalContractSummary{
  contractNo?: IContractNo,
  contractType?: 'LEGACY-CONTRACT' | 'SHORT-TERM-RENTAL',
  bidId ?: string | number,
  vin : string
  unitName ?: string,
  //sNo ?: number,
  leaseNo ?: string
}

export type AssignmentValidationGroup = 'page1' | 'page2' | 'page3';
const page1: AssignmentValidationGroup = 'page1';
const page2: AssignmentValidationGroup = 'page2';
const page3: AssignmentValidationGroup = 'page3';

const regex = {
  js: {
    // tslint:disable-next-line:max-line-length

    nounVin: /^(([a-h,A-H,j-n,J-N,p-z,P-Z,0-9]{9})([a-h,A-H,j-n,J-N,p,P,r-t,R-T,v-z,V-Z,0-9])([a-h,A-H,j-n,J-N,p-z,P-Z,0-9]{7}))$/,
  }
};

@Exclude()
export class NegoTermsAssigned extends NegoTermsBase {



  constructor() {
    super();
    this.termsType = TermsType.assignedByVendor;
    this.deposit = undefined;
    this.startAddress = new Address();
    this.endAddress = new Address();

  }


  /** Required for back dated units Assigned by vendor without saving rent option*/
  @Expose()
  @IsBoolean()
  isLegacy = false;

  /** Required for units Assigned by vendor without saving rent option*/
  @Expose()
  @ValidateIf(o => o.termsType === TermsType.assignedByVendor && o.deposit > 0, { groups: [page1] })
  @IsDate({ groups: [page1] })
  depositDate: Date;

  /** Required for Assigned by vendor without saving rent option*/
  @Expose()
  @ValidateIf(o => o.termsType === TermsType.assignedByVendor && o.numberOfDays > 7, { groups: [page1] })
  @IsDate({ groups: [page1] })
  firstPaymentDate: Date;

  @Expose()
  @ValidateNested({ message: 'Address info is required', groups: [Address.gName, page1] })
  @IsDefined({ message: 'Address information is required', groups: [Address.gName, page1] })
  @Type(() => Address)
  startAddress: Address;

  @Expose()
  @ValidateIf(f => !!f.isOneWay, { groups: [page1, Address.gName] })
  @ValidateNested({ message: 'Address info is required', groups: [Address.gName, page1] })
  @IsDefined({ message: 'Address information is required', groups: [Address.gName, page1] })
  @Type(() => Address)
  endAddress: Address;


  @Expose()
  @IsBoolean()
  isOneWay = false;

  // @Expose()
  // @Length(3, 3)
  // currency: 'CAD' | 'USD';
  @Expose()
  // @Equals('CAD' || 'USD')
  @IsIn(currencies)
  currency: CurrencyType; 

  @Expose()
  @Type(() => RentalPlan)
  @ValidateNested({ message: 'Define Rental Plans'  })
  rentalPlans: RentalPlan[];

  @Expose()
  @ValidateIf(o => o.taxApplicable === TaxApplicable.custom, {groups: [page2, page3]})
  @Type(() => Levy)
  @ValidateNested({ message: 'Define Tax Info', groups: [page2, page3] })
  taxItems: Levy[];

  @Expose()
  @IsEnum(TaxApplicable)
  taxApplicable: TaxApplicable = TaxApplicable.default;

  get selectedPlans() {
    return this.rentalPlans.filter(f => f.isAvailable && f.isSelected);
  }

  get numberOfDays() {
    if (!!this.endDate && !!this.startDate) {
      return differenceInDays(this.endDate, this.startDate);
    } else {
      return null;
    }
  }

  @Expose()
  @IsNumber(undefined, { message: 'Number is required for Security Deposit', groups: [page2] })
  deposit: number;

  @Expose()
  @ValidateIf(o => !!o.contractReference, {groups: [page1]})
  @MinLength(3, {message: 'Enter Jira Number, e.g. REN-123', groups: [page1]})
  @MaxLength(10, {message: 'Enter Jira Number, e.g. REN-123', groups: [page1]})
  contractReference: string;

  @Expose()
  @ValidateIf(o => !!o.contractTplFileNo, {groups: [page1]})
  @MinLength(3, {message: 'Enter TPL (FILE NO.), e.g. TPL123456', groups: [page1]})
  @MaxLength(10, {message: 'Enter TPL (FILE NO.), e.g. TPL123456', groups: [page1]})
  contractTplFileNo: string;

  /**
   * MKN - Added to solve PGDT - 122
   */
  @Expose()
  @IsDefined()
  originalContractSummary: OriginalContractSummary;

  /**
   * get summary string for UI
   */
  // KN Altered condition as we are getting two types of replacement contracts
  get originalContractSummaryString() {
    if(!!this.originalContractSummary && !isEmpty(this.originalContractSummary.contractNo)){
      return `${this.originalContractSummary.contractNo.suffix}/${this.originalContractSummary?.contractNo?.number}/${this.originalContractSummary.contractNo.rev} - ${this.originalContractSummary.unitName}`;
    }else if(!!this.originalContractSummary && this.originalContractSummary.contractType == 'LEGACY-CONTRACT'){
      return `${this.originalContractSummary.leaseNo} - ${this.originalContractSummary.vin}`;
    }
    return '';
  }

  /** UI helped to map properties  to existing object*/
  @Expose({ toPlainOnly: true })
  get rentOptionSummary(): RentOptionSummaryBase {
    const r: RentOptionSummaryBase = {
      startAddress: this.startAddress,
      endAddress: this.endAddress,
      isOneWay: this.isOneWay,
      availStartDate: this.startDate,
      availEndDate: this.endDate,
      currency: this.currency,

      deposit: this.deposit,
      rentalPlans: this.rentalPlans

    };
    return r;
  }
  // @Expose()
  // @IsArray()
  // @ValidateNested({ each: true })
  // @Expose({ toPlainOnly: true })
  // get addOns(): SpecialItemTypeOptionalDb[] {
  //   if(!this.addOnsAvailable) {
  //     this.addOnsAvailable = [];
  //   }
  //   return this.addOnsAvailable.filter(f => f.isSelected).map(g => g.addOn);
  // }

  public static parse(obj) {
    if (obj == null) { return null; }
    const m = plainToInstance<NegoTermsAssigned, any>(NegoTermsAssigned,
      sanitizeDateIPoint(obj));
    m.sanitize();
    if (!m.termsType) {
      m.termsType = TermsType.negotiated;
    }
    return m;
  }
  sanitize() {
    super.sanitize();
    // if data was recieved from firebase, date is stored as snapshot.

    this.depositDate = sanitizeDate(this.depositDate);
    this.firstPaymentDate = sanitizeDate(this.firstPaymentDate);
  }
  clone() {
    const t = instanceToInstance(this);
    t.sanitize();
    return t;
  }
  /** set available plans when setting rent option based dated selected [used for publishing rent-option] */
  setRentalPlanAvailability() {
    if (!!this.startDate && !!this.endDate) {
      const days = differenceInDays(this.endDate, this.startDate);
      console.log({ days });
      for (const p of this.rentalPlans) {
        if (p.rentalTerm <= days) {
          p.isAvailable = true;
        } else {
          p.isAvailable = false;
          p.isSelected = false;
          p.ratePerDay = undefined;
          p.ratePerMile = undefined;
          p.milage = undefined;
          p.mileageType = MileageType.minimum;
        }
      }
      if(this.rentalPlans.findIndex(f => f.isSelected) === -1) {
        this.rentalPlans.find(f => f.rentalTerm === RentalTerm.daily).isSelected = true;
      }
    }
    logger.info('[nego terms assignment] plan avialabilty set ', this.rentalPlans);
  }
  /** Initializes NegoTerms for new contract */
  initRentalPlansForPlansForAssignment(prodType: ProductType, country: 'CA' | 'US') {
    if (!this.rentalPlans || this.rentalPlans.findIndex(f => f.isSelected) === -1) {
      this.rentalPlans = RentalPlan.initPlans();
      this.rentalPlans = this.rentalPlans.filter(f => f.rentalTerm <= RentalTerm.monthly);
      this.rentalPlans.forEach(e => e.isAvailable === true);
      this.rentalPlans.find(f => f.rentalTerm === RentalTerm.daily).isSelected = true;
      this.author = Author.vendor;
      this.bidResponse = NegoTermsResponse.acceptedByVendor;
      this.rentalAgreementId = getRentalAgreement(prodType, country);
      this.rentalAgreementRev = 0;
      this.currency = getCurrencyByCountry(country);
      this.taxApplicable = country === 'US' ? TaxApplicable.custom : TaxApplicable.default;

    }
  }
  /** Convert @param NegoTermBase to @param NegoTermsAssigned for modifying the bid submitted by customer*/
  setAssignedProperties(bid: BidBase, cInvoice: Invoice) {
    this.termsType = TermsType.assignedByVendor;
    this.author = Author.vendor;
    this.bidResponse = NegoTermsResponse.acceptedByVendor;
    this.rentalAgreementId = getRentalAgreement(bid.productSummary.productType, bid.vendorCompSummary.address?.country as 'US' | 'CA');
    this.rentalAgreementRev = 0;
    this.startAddress = bid.rentSummary.startAddress;
    this.endAddress = bid.rentSummary.endAddress;
    this.isOneWay = bid.rentSummary.isOneWay;
    this.currency = bid.rentSummary.currency;
    this.isLegacy = false;
    const reserveItem = cInvoice.invoiceItems.find(f => f.itemCat === InvoiceItemCat.SecurityDeposit && f.invoiceItemType === 'securityDeposit' && f.name === 'Reserve');
    const depositItem = cInvoice.invoiceItems.find(f => f.itemCat === InvoiceItemCat.SecurityDeposit && f.invoiceItemType === 'securityDeposit' && f.name === 'Security Deposit');
    this.depositDate = depositItem.dueDate;
    this.deposit = depositItem.amount + reserveItem.amount;
    const rentItems  = cInvoice.invoiceItems.filter(f => f.itemCat === InvoiceItemCat.Revenue && f.invoiceItemType === 'rent');
    // this.firstPaymentDate = rentItems.sort((a,b) => a.dueDate.valueOf() - b.dueDate.valueOf())[0].dueDate;
    this.firstPaymentDate = undefined;
    this.taxApplicable = bid.vendorCompSummary.address?.country === 'US' ? TaxApplicable.custom : TaxApplicable.default;

    this.mapRentalPlanFromBid(bid, cInvoice);
    // const rPlansFromBid = bid.rentSummary.rentalPlans.map(g => RentalPlan.parse(g)).filter(f => f.isSelected);
    // rPlansFromBid.forEach(e => e.isSelected = false);
    // const  selected = rPlansFromBid.find(f => f.rentalTerm === cInvoice.rentalPlan.rentalTerm);
    // selected.isSelected = true; 
    // this.rentalPlans = rPlansFromBid;
    // if(this.rentalPlans.findIndex(f => f.rentalTerm === RentalTerm.monthly) === -1) {
    //   const m = new RentalPlan();
    //   m.isAvailable = differenceInDays(this.endDate, this.startDate) >= RentalTerm.monthly;
    //   m.rentalTerm = RentalTerm.monthly;
    //   m.mileageType = MileageType.maximum;
    //   this.rentalPlans.push(m);
    // }
    

    if(!this.canDefaultTaxApply(bid.customerCompSummary.address, bid.vendorCompSummary.address)) {
      this.initTaxItems();
    }

  }
  initTaxItems() {
    this.taxItems = [Levy.initCustomTax()];

  }
  minStartDate(): Date {
    // if (this.isLegacy ) {
    //   throw new Error('cannot set Legacy contract for unit with active bids');

    // }
    if (this.isLegacy) {
      return addYears(new Date, -5);
    }
    return startOfDay(new Date());

  }
  maxStartDate(): Date {
    return addYears(new Date, 10);

  }
  validateSyncGroup(group: AssignmentValidationGroup): IValidationMsg {
    return this.validateSync({ groups: [group] });
  }

  validateSync(options: ValidatorOptions, rentalProd?: RentalProductBase, bid?: BidBase, isContractChange = false, otherReplacementVinBids? : BidBase[]): IValidationMsg {

    rentalProd = undefined;
    // bid = undefined;
    if (options.groups?.length || options.groups?.includes(page1)) {
      options.groups.push(Address.gName);
      options.groups.push(Levy.levyG);
      options.groups.push(RentalPlan.rPlanG);
    }
    const r = this.validateSyncBase(this, options);
    // KN - For validating dates for the Replacement contract
    if(!!this.replacementVin && (otherReplacementVinBids && otherReplacementVinBids?.length > 0)){
      for(let i = 0 ; i < otherReplacementVinBids.length; i++){
        if(isAfter(this.endDate, otherReplacementVinBids[i].bidNegoTerms?.startDate) && isBefore(this.startDate, otherReplacementVinBids[i].bidNegoTerms?.endDate)){ 
          r['FailedToReplace'] = ['This Unit is already Assigned'];
          break;
        }  
      }
    }
    if (isDate(this.endDate) && isDate(this.startDate)) {
      if (!isAfter(this.endDate, this.startDate) && !bid?.id) {
        r['startDate'] = ['Start Date invalid'];
      }
      if (!bid?.id && isBefore(this.startDate, this.minStartDate())) {
        r['startDate'] = ['Start Date invalid'];
      }
      if (!isBefore(this.startDate, this.maxStartDate())) {
        r['startDate'] = ['Start Date invalid'];
      }
      if (isBefore(this.endDate, this.minStartDate())) {
        r['endDate'] = ['Start Date invalid'];
      }
      if (!isBefore(this.endDate, this.maxStartDate())) {
        r['endDate'] = ['End Date invalid'];
      }
      if(this.numberOfDays > 7) {
        if (this.deposit > 0 && !isAfter(this.firstPaymentDate, this.depositDate)) {
          r['firstPaymentDate'] = ['First Payment Date invalid'];
        }
        if (!isAfter(this.firstPaymentDate, this.startDate)) {
          r['firstPaymentDate'] = ['First Payment Date invalid'];
        }
        if (!isBefore(this.firstPaymentDate, this.endDate)) {
          r['firstPaymentDate'] = ['First Payment Date invalid'];
        }
      }
      // if (this.deposit > 0 && isBefore(this.depositDate, this.startDate)) {
      //   r['depositDate'] = ['Deposit Date invalid'];
      // }
      if (this.deposit > 0 && isAfter(this.depositDate, this.endDate)) {
        r['depositDate'] = ['Deposit Date invalid'];
      }
      if (!!this.selectedPlans  && this.selectedPlans.find(f => !!f.isSelected ) && !isEmpty(this.selectedPlans.find(f => !!f.isSelected )?.validateSync())) {
        r['rentalPlan'] = ['Incomplete rental plan'];

      }
 
    }
    return r;


  }
  /** check if end date is before the bid which after the current. Required for updating contract
   * Not required for new assigned contract 
   */
  // isEndBeforeOtherBids(d: Date, otherBids: BidBase[]): boolean {
  //   if(!otherBids) {
  //     throw new Error('failed to get other bids, if now need to be empty array');

  //   }
  //   if(otherBids.length === 0 ){
  //     return true;
  //   } else {
  //     const sortedBids = otherBids.sort((a, b) => b.bidNegoTerms.endDate.valueOf() - a.bidNegoTerms.endDate.valueOf())

  //     return isBefore(d, sortedBids[0].bidNegoTerms.startDate);
  //   }
  // }
  contractStartedCheck(cOrder: Order, cInvoice: Invoice, origBid: BidBase, assignedContractData: IAssignedContractData): boolean {
    const isContractStarted = cOrder?.isContractStarted(cInvoice);
    /**  MKN - Added assign contract update validation */
    if (isContractStarted) {

      //rental details check starts
      if (assignedContractData.pid && origBid.productSummary.pid != assignedContractData.pid) { //Check unit is changed or not
        return false;
      }

      const origNegoTermsAssigned = NegoTermsAssigned.parse(origBid.bidNegoTerms);
      if (origNegoTermsAssigned.isLegacy != this.isLegacy) {//Check legacy type change or not
        if (!origNegoTermsAssigned.isLegacy) {//Legacy -> Regular = Allowed, Regular -> Legacy = Not allowed
          return false;
        }
      }

      if (origBid.customerCompSummary.cid != assignedContractData.cCid) {//Check customer company details
        return false;
      }

      if (origBid.vendorCompSummary.cid != assignedContractData.vCid) {//Check vendor company details
        return false;
      }

      if (origNegoTermsAssigned.startAddress?.geoLoc.geohash != this.startAddress?.geoLoc.geohash) {//Check address change or no
        return false;
      }

      if (origNegoTermsAssigned.isOneWay != this.isOneWay) {
        if (origNegoTermsAssigned.endAddress?.geoLoc.geohash != this.endAddress?.geoLoc.geohash) {//Check address change or no
          return false;
        }
      }

      if (!isEqual(this.startDate, origNegoTermsAssigned.startDate)) { //check contract start date
        return false;
      }

      const invoicedItemIdsString: string[] = cOrder.pymtSchedule.filter(f => !!f.accountingInvoicePath).map(g => g.invItemId);
      const invoiceItemsIds: number[] = Order.getInvoiceItemIdsForOrderInvItemId(invoicedItemIdsString);
      const invoicedItemsSecurityD = cInvoice.invoiceItems.filter(f => invoiceItemsIds.includes(f.itemId) && f.invoiceItemType === 'securityDeposit');

      if (invoicedItemsSecurityD.length > 0) {
        const reserve = cInvoice.invoiceItems.find(f => f.name === 'Reserve') ? cInvoice.invoiceItems.find(f => f.name === 'Reserve') .amount : 0;
        if (this.deposit !== invoicedItemsSecurityD[0].totalAmount + reserve) { //check Security deposit amount
          return false;
        }

        if (!isEqual(this.depositDate, origNegoTermsAssigned.depositDate)) { //check Security deposit date
          return false;
        }
      }
      //rental details check ends

      //rental Terms check starts
      if (!isEqual(this.firstPaymentDate, origNegoTermsAssigned.firstPaymentDate)) { //check Security deposit date
        return false;
      }

      //check rental plans
      let origSelectedTerm: RentalPlan = origNegoTermsAssigned.rentalPlans.find(f => f.isSelected);
      let selectedTerm: RentalPlan = this.rentalPlans.find(f => f.isSelected);

      if (origSelectedTerm.rentalTerm !== selectedTerm.rentalTerm) {
        return false;
      }

      if (origSelectedTerm.milage != selectedTerm.milage || origSelectedTerm.ratePerDay != selectedTerm.ratePerDay || origSelectedTerm.ratePerMile != selectedTerm.ratePerMile) {
        return false;
      }
      //rental Terms check ends
    }
    return true;
  }
  mapRentalPlanFromBid(bid: BidBase, cInvoice: Invoice) {
    const selectedPlan = cInvoice.rentalPlan;
    this.rentalPlans = RentalPlan.initPlans();
    this.rentalPlans = this.rentalPlans.filter(f => f.rentalTerm <= RentalTerm.monthly);
    this.rentalPlans.forEach(e => e.isAvailable = true);
    for (const nR of this.rentalPlans) {
        const oR = bid.rentSummary.rentalPlans.find(f => f.rentalTerm === nR.rentalTerm && f.isAvailable);
        if(!!oR){
          nR.ratePerDay = oR.ratePerDay;
          nR.ratePerMile = oR.ratePerMile;
          nR.mileageType = oR.mileageType;
          nR.milage = oR.milage;
        }
    }
    if(selectedPlan.rentalTerm > RentalTerm.monthly){
      const nR = this.rentalPlans.find(f => f.rentalTerm === RentalTerm.monthly);
      nR.isSelected = true;
    } else {
      const nR = this.rentalPlans.find(f => f.rentalTerm === selectedPlan.rentalTerm);
      nR.isSelected = true;
    }

  }

  /**
   * Reset time to 00:00:00:00
   */
  resetTime(date : Date){
    if(date){
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0);
      date.setMilliseconds(0);
    }
  }

  /**
   * Compare Date only(Ignore the time)
   * @param date1 
   * @param date2 
   * @returns 
   */
  compareDateOnly(date1 : Date, date2 : Date){
    if(date1 && date2){
      const dateR1 = new Date(date1.getTime());
      const dateR2 = new Date(date2.getTime());
      this.resetTime(dateR1);
      this.resetTime(dateR2);
      return isEqual(dateR1, dateR2);
    }else{
      return false;
    }
  }

}