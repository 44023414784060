import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, NgZone, PLATFORM_ID } from '@angular/core';
import { Observable, finalize, retry, take, tap } from 'rxjs';
// import { ZoneAwarePromise } from 'zone.js';
// Will be set by zone.js , added by angular [Default]
declare const Zone: any;


/**
 * A utility service to be used for fixing the observables and promises in Angular universal. * 
 */
@Injectable({
  providedIn: 'root'
})
export class ZoneUtlService {

  constructor(@Inject(PLATFORM_ID) private platformId: Object, private ngZone: NgZone) { }

  private nTask = 0;

  isPlatformBrowser() {
    return isPlatformBrowser(this.platformId);
  }

  /** Create a zone microtask that will lock or make the rendering on hold until the created task is invoked.  
   *  [HG] -> IMPORTANT: If you call this function, make sure task.invoke is called in try/catch/finally blocks.
   * If the task created is not invoked, It will not render on angular universal.
   * @param name 
   * @returns 
   */
  private createMacroTask(name?: string) {
    ++this.nTask;
    name = name || `custom-task-${Math.random()}`;
    // Return task obj.
    return {
      task: Zone.current
        .scheduleMacroTask(
          name,
          () => { /* console.log('[Macrotask] Started.')*/ },
          {},
          () => { }
        )
    };
  }

  private closeMacroTask(taskObj: { task: any }) {
    if (!!taskObj?.task) {
      taskObj.task.invoke();
      --this.nTask;
      taskObj.task = undefined;
      console.log(`[Macrotask] Closed. Current Pending tasks: ${this.nTask}`);
    }
  }
  /**
   * Adjust / convert the observable to a zone aware observable. Only useful for SSR that will put
   * SSR rendering to hold observable is executed for certain number of times.
   * @param ob$ input observable that need to be made zone aware.
   * @param nRetry max number of times observable need to be tried before giving up.
   * @returns zone aware observable.
   */
  zoneAwareOb$<T>(ob$: Observable<T>, nRetry = 3): Observable<T> {

    /** Only modify if this is for the SSR */
    if (this.isPlatformBrowser()) {
      return ob$;
    }
    // let counter = 0;
    let macroTask = this.createMacroTask();
    return ob$.pipe(
      // /** Try three times on failure */
      retry(nRetry),
      /** Tap and mark the zone task to be unlocked. if either a non null/undefined entry is received
       * or max count has reached. */
      tap(x => {
        console.log('[macrotask] invoked in tap.');
        this.closeMacroTask(macroTask);
        // if (!!x || ++counter > nRetry) {
        //   this.closeMacroTask(macroTask);
        // }      
      }),
      /** unlock the task once it is complete or throw an error. */
      finalize(() => {
        console.log('[microtask] invoked in finalize.');
        this.closeMacroTask(macroTask);
      })
    );
  }


  /**
   * Convert a promise wrapper to execute a zone aware promise. Only useful for angular universal. 
   * @param fnPromise function that will provide the promise to be executed 
   * @returns zone aware promise that will put the rendering of the angular universal page on hold until the 
   * given promise is executed.
   */
  async zoneAwarePromise<T>(fnPromise: () => Promise<T>) {

    //Just execute as is for the client application.
    if (this.isPlatformBrowser()) {
      return fnPromise();
    }
    /** create a zone aware task that will lock /pause the rendering on hold until the task is invoked. */
    const task = this.createMacroTask();

    //execute the promise or provide the zone aware wrapper
    return await this.ngZone.runOutsideAngular(async () => {
      try {
        return await fnPromise();
      } finally {
        this.closeMacroTask(task);
      }
    });
  }
}
