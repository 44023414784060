export enum InspectionStatus {

  initial = 0,

  vendorDropped = 10,

  customerPicked = 20,

  rentStartHandShake = 30,

  customerDropped = 40,

  vendorPicked = 50,

  finalHandShake = 60,

  vendorDisputed = 70,  // after finalHandShake

  customerDisputed = 80  // after finalHandShake
}

/**
 * Get Action Name for UI based on user action
 * @param action User Action
 */
export const actionName = (action: InspectionStatus): string => {
  switch (action) {
    case InspectionStatus.customerPicked:
    case InspectionStatus.vendorDropped:
      return 'Pre Delivery Inspection';
    case InspectionStatus.customerDropped:
    case InspectionStatus.vendorPicked:
      return 'Return Inspection';

    default:
      throw new Error(`[inspection-helper] Programing error cannot getUserAction, invalid action type ${action}`);

  }
};
