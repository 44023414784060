import { Paging } from '@trent/models/observable-util/paging';
import { TransactionType } from './invoice-type';
import { Invoice } from './invoice';
import { UserEnum } from '../../user/user-enum';
import { CollectionReference, limit, orderBy, query, Query, QueryConstraint, startAfter, where } from 'firebase/firestore';


/** Search params for fetching the orders by user settings. */
export interface IInvoiceParam {
  cid?: number | string;
  orderDirection?: 'desc' | 'asc';
  orderBy?: string;
  invoiceType?: TransactionType;
  refId?: string | number;
  invoiceCat?: UserEnum;
  vCid?:number | string;
  creditUserId?: string | number; // Credit User Id
  salesPersonId?: string | number;
}

/** Server filtering of firebase query building of query  */
export const invoiceSerachServerQuery = (ref: CollectionReference, param: IInvoiceParam, p: Paging) => {
  const cons: QueryConstraint[] = [];
  if (!!param.cid) {
    cons.push(where('cid', '==', param.cid));
  }
  if (!!param.refId) {
    cons.push(where('refId', '==', param.refId));
  }
  if (!!param.invoiceType) {
    cons.push(where('invoiceType', '==', param.invoiceType));
  }
  if (!!param.invoiceCat) {
    cons.push(where('invoiceCat', '==', param.invoiceCat));
  }
  if (!!param.vCid) {
    cons.push(where('vCid', '==', param.vCid));
  }
  if (!!param.invoiceCat) {
    cons.push(where('invoiceCat', '==', param.invoiceCat));
  }
  if (!!param.salesPersonId) {
    cons.push(where('customerAuthIds', 'array-contains', param.salesPersonId));
  }
  if (!!param.creditUserId) {
    cons.push(where('vendorAuthIds', 'array-contains', param.creditUserId));
  }
  cons.push(orderBy(param.orderBy, param.orderDirection));

  if (!!p.lastDoc) {
    console.log('Start After Query: ', p.lastDoc[param.orderBy]);
    cons.push(startAfter(p.lastDoc[param.orderBy]));
  }
  cons.push(limit(p.size));
  return query(ref, ...cons);
};

/** Client side filtering of the orders. */
export const invoiceSerachClientFilter = (p: Invoice[], o: IInvoiceParam) => {
  if (!!o.cid) {
    p = p.filter((val, idx) => val.cid === o.cid);
  }
  if (!!o.refId) {
    p = p.filter((val, idx) => val.refId === o.refId);
  }
  if (!!o.invoiceType) {
    p = p.filter((val, idx) => val.invoiceType === o.invoiceType);
  }
  if (!!o.invoiceCat) {
    p = p.filter((val, idx) => val.invoiceCat === o.invoiceCat);
  }

  return p;
};


/** Get the OR condition options. if there are more then one company id's are provided
 *  each cid makes up a unique OR condition query.
 * TBD function, see Pick-Drop imlementation */
export const getIInvoicesOptionOrChildren = (o: IInvoiceParam): { [key: string]: IInvoiceParam } => {
  // if (!!o.cids && o.cids.length > 1) {
  //   const r: { [key: string]: PickDropParam } = {};
  //   o.cids.forEach((cid) => {
  //     const c = DeepCopy.copy(o);
  //     c.cids = [cid];
  //     r[getObjKey(c)] = c;
  //   });
  //   return r;
  // }

  return undefined;
};