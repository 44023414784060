import { IUserClaim } from '../user/user-claim';
import { RoleCompany } from './../user/role-company';
import { UserProfile } from '../user/user-profile';
import { ContactCard } from '../user/contact-card';
import { FileInfo } from './../media/file-info';
import { CompanyUserProfile } from './company-user-profile';
// import { sanitizeInt, sanitizeIonicDate } from './../../utility/sanitize-helper';
import { IsEnum, Length, IsDefined, ValidateNested, Max, Min, Matches, IsInt, ValidateIf, ValidatorOptions, IsString, IsBoolean, length, IsNumber, IsOptional } from 'class-validator';
//  IsEnum, Length, IsDate, IsDefined, ValidateNested} from 'class-validator';
// import { IValidationMsg, IValidate, phoneRegex } from '../utility/validation-helper';
import { BaseModel } from '../base/base-model';
import { CompanyType } from './company-type';
import { Address } from '../address/address';
import { Exclude, Type, Expose } from 'class-transformer';
import { DbStatus } from '../base';
import { sanitizePhone } from '../utility/sanitize-helper';
import { IValidationMsg } from '../error-handling';
import { phoneRegex, toPascalCase, toSentence } from '../utility';
import { Task } from '../sys/task';
// import { getClassName } from '../utility';
import { TaskType } from '../sys/task-type';
import { EnumHelper } from '../utility/enum-helper';
import { TaskUtl } from '../sys/task-utl';
import { isEqual, isNil, keys } from 'lodash';
import { OwnershipType } from './ownership-type';
import { logger } from '../log/logger';
import { RoleGroup } from '../role-group/role-group';
import { CompanyStatus } from './company-status';

/** doc type used in the company definations, used to download
 * Important: values of this parameter must corrosponds to name of the FileInfoproperty in this class
*/
export type CompanyDocType = 'aOI' | 'carrierPackage' | 'insCert' | 'voidCheque' | 'officerPhotoId1' | 'officerPhotoId2' | 'companyCreditPackage' | 'bankStatements';

export type CompanyValidationGroup = 'authority' | 'documents' | 'legal' | 'insurance' | 'users' | 'misc' | 'accountDetails' | 'initial' | 'companayApprovedFor' | 'publicG' | 'privateG'
  | 'creditPackage' | 'fleetPackage' | 'officer1Id' | 'officer2Id' | 'aOI' |'bankStatements' | 'voidCheque';
const legal: CompanyValidationGroup = 'legal';
// const documents: CompanyValidationGroup = 'documents';
const accountDetails: CompanyValidationGroup = 'accountDetails';
const initial: CompanyValidationGroup = 'initial';
const companayApprovedFor: CompanyValidationGroup = 'companayApprovedFor';
const publicG: CompanyValidationGroup = 'publicG';
const privateG: CompanyValidationGroup = 'privateG';
const creditPackage: CompanyValidationGroup = 'creditPackage';
const fleetPackage: CompanyValidationGroup = 'fleetPackage';
const officer1Id: CompanyValidationGroup = 'officer1Id';
const officer2Id: CompanyValidationGroup = 'officer2Id';
const aOI: CompanyValidationGroup = 'aOI';
const bankStatements: CompanyValidationGroup = 'bankStatements';
const voidCheque: CompanyValidationGroup = 'voidCheque';


export interface IUserRole {
  displayName: string;
  email?: string
}

/**
 * @author - Cm
 * Salesperson information store in company
 */

export interface ISales extends IUserRole {
  isPrimary: boolean;
  percentage: number;
}


export interface IAuth {
  sales?: { [key: string]: ISales };
  branchMgr?: { [key: string]: IUserRole };
  credit?: { [key: string]: IUserRole };
}

export interface CompanySummaryBase {
  cid?: string;
  legalName: string;
  address?: Address;
  // officer: ContactCard[];
  dbStatus?: DbStatus;
  revId?: number;
  phone?: string;
  email?: string;
  name?: string;
}
const regex = {
  js: {
    nounHstNumber: /^([0-9]){9}([\-])([A-Z]){2}(\-)([0-9]){4}$/,
    nounEINNumber: /^[1-9]\d?-\d{7}$/
    ,
    // nounHstNumber: /^([0-9]){9}([A-Z]){2}$/,
  }
};

/**
 * @author - MKN
 * Maintain subsidiary information 
 */
export interface ISubsidiaryLinkUp {
  sId: number;
  name: string;
}

@Exclude()
export class CompanyBase extends BaseModel {



  public static readonly collectionName = 'company';


  @Expose()
  auth: IAuth;

/**
 * @author - Cm
 * Here ids taken from auth like sales,credit....etc
 */
  @Expose({ toPlainOnly: true })
  get authIds(): string[] {
    let r: string[] = [];
    for (const key in this.auth) {
      let keys = Object.keys(this.auth[key]);
      r.push(...keys);
    }
    return r;
  }


  /** Id of users with admin role in the company (Director / Officers only ). Ideally this informatino is
   * also stored in @property authUsers with role admin but admin role array id is needed for firestore
   * query purposes. Note: it is one way only i.e written to db, but not parsed from.*/
  @Expose({ toPlainOnly: true })
  get adminId(): string[] {
    let r: string[];
    for (const key in this.authUsers) {
      if (this.authUsers.hasOwnProperty(key)) {
        const ele = this.authUsers[key];
        if (!!ele.roles.a || !!ele.roles.o) {
          if (r === undefined) {
            r = [];
          }
          r.push(key);
        }
      }
    }
    return r;
  }
  /** Id of users with admin role in the compnay (Director / Officers only ). Ideally this informatino is
     * also stored in @property authUsers with role admin but admin role array id is needed for firestore
     * query purposes. Note: it is one way only i.e written to db, but not parsed from.*/
  @Expose({ toPlainOnly: true })
  get companyUsers(): string[] {
    let r: string[];
    for (const key in this.authUsers) {
      if (this.authUsers.hasOwnProperty(key)) {
        const ele = this.authUsers[key];
        if (!!ele.roles.a || !!ele.roles.o || ele.roles.e || ele.roles.r) {
          if (r === undefined) {
            r = [];
          }
          r.push(key);
        }
      }
    }
    return r;
  }

  /** MKN - Added new role group - to manage the company level access as per role group  **/
  @Expose()
  roleGroup:  { [key: string]: boolean };

  /** MKN - (Getter) - Maintain role group Ids for Getting companies data as per role group provided 
   *  Used in fetching company call and in firebase rules
   *  **/
  @Expose({ toPlainOnly: true })
  get roleGroupIds(): string[] {
    let r: string[] = [];
    if(this.roleGroup)
      r = Object.keys(this.roleGroup);

    return r;
  }

  /** Owner / Directors / Officers of the company  */
  // admin: CompanyUserProfile;

  /** Authorized Users, For a bigger company there can be many users who manage the account */
  @Expose()
  authUsers: { [key: string]: CompanyUserProfile };

  @Expose()
  @IsEnum(CompanyType)
  companyType: CompanyType;

  @Expose()
  @Length(5, 254, {
    message: 'Company Name must be $constraint1 - $constraint2 chars long',
    groups: [legal, initial]
  })
  name: string;


  @Expose()
  @Length(5, 254, {
    message: 'Legal Name must be $constraint1 - $constraint2 chars long',
    groups: [legal]
  })
  legalName: string;



  // @Expose()
  // @IsEnum(CompanyType)
  // cType: CompanyType;

  @Expose()
  @ValidateNested({ message: 'Address info is required', groups: [legal, Address.gName] })
  @IsDefined({ message: 'Address information is required', groups: [legal, Address.gName] })
  @Type(() => Address)
  address: Address;

  @Expose()
  //  @IsDefined()
  addressdrop: Address;

  @Expose()
  @IsEnum(OwnershipType)
  ownershipType: OwnershipType = OwnershipType.privateCorp;

  // @Length(5, 254, { message: 'Upload Article of Incorporation', groups: [documents] })
  @Expose()
  @ValidateNested({ message: 'Upload Article of Incorporation', groups: [aOI] })
  @IsDefined({ message: 'Upload Article of Incorporation', groups: [aOI] })
  @Type(() => FileInfo)
  aOI: FileInfo;

  // @Expose()
  // @ValidateIf(f => f.officer.length > 0 )
  // @ValidateNested({ message: 'Upload officer\'s Photo ID ', groups: [documents] })
  // @IsDefined({ message: 'Upload officer\'s Photo ID ', groups: [documents] })
  // @Type(() => FileInfo)
  // officerPhotoId1: FileInfo;

  // @Expose()
  // @ValidateIf(f => f.officer.length > 1 )
  // @ValidateNested({ message: 'Upload officer\'s Photo ID ', groups: [documents] })
  // @IsDefined({ message: 'Upload officer\'s Photo ID ', groups: [documents] })
  // @Type(() => FileInfo)
  // officerPhotoId2: FileInfo;

  @Expose()
  @Type(() => ContactCard)
  @ValidateNested({ message: 'Provide officer Company', groups: [privateG, ContactCard.photoId] }) // ContactCard.gName
  @IsDefined({ message: 'Provide officer contact', groups: [privateG, ContactCard.photoId] }) // ContactCard.gName
  officer: ContactCard[];


  /** contact information for the legal owner (for fleet company) */
  // @ValidateIf(f => f.companyType === CompanyType.DriverProvider || false, { groups: [documents, ContactCard.gName] })
  // @Expose()
  // @ValidateNested({ message: 'Provide the address of Company', groups: [documents, ContactCard.gName] })
  // @IsDefined({ message: 'Provide the address of Company', groups: [documents, ContactCard.gName] })
  // @Type(() => ContactCard)
  contact: ContactCard;

  /** company general contact information (for carrier company, driver company) */
  @Expose()
  @ValidateNested({ message: 'Provide the address of Company', groups: [publicG] }) // , ContactCard.gName
  @IsDefined({ message: 'Provide the address of Company', groups: [publicG] }) // , ContactCard.gName
  @Type(() => ContactCard)
  companyContact: ContactCard;


  // @sanitizeIonicDate
  // @Expose()
  // @IsDate({
  //   message: 'Insurance Expiry date is required',
  //   groups: [insurance]
  // })
  // insExpiryDate: Date;


  @Expose()
  @ValidateIf(o => o.address.country === 'CA', { groups: [accountDetails] })
  @IsInt()
  @Max(999999999, { message: 'Business Number should be 9 digit number', groups: [accountDetails] })
  @Min(99999999, { message: 'Business Number should be 9 digit number', groups: [accountDetails] })
  businessNumber: number;

  /**
   * @author - PT
   * make hstNumber field optional
   * match the regex if there is hstNumber
   */
  @Expose()
  @IsOptional()
  @ValidateIf(o => o.hstNumber, { groups: [accountDetails] })
  @Matches(regex.js.nounHstNumber, { message: 'enter valid HST number, e.g. 123456789-RT-0001', groups: [accountDetails] })
  hstNumber: string;

  @Expose()
  @ValidateIf(o => o.address.country === 'US', { groups: [accountDetails] })
  @Matches(regex.js.nounEINNumber, { message: 'enter valid EIN number, e.g. 12-3456789', groups: [accountDetails] })
  einNumber: string;

  @Expose()
  @IsDefined({ message: 'Upload Void Cheque', groups: [voidCheque] })
  @ValidateNested({ message: 'Upload Void Cheque', groups: [voidCheque] })
  @Type(() => FileInfo)
  voidCheque: FileInfo;

  @Expose()
  @ValidateIf(f => f.address.country === 'CA', { groups: [accountDetails] })
  @Length(5, 5, { message: 'Bank Transit Number should be 5 digit number', groups: [accountDetails] })
  bankTransitNo: string;

  @Expose()
  @ValidateIf(f => f.address.country === 'CA', { groups: [accountDetails] })
  // @Max(999, { message: 'Financial Institution Number should be 3 digit number', groups: [accountDetails] })
  // @Min(100, { message: 'Financial Institution Number should be 3 digit number', groups: [accountDetails] })
  @Length(3, 3, {
    message: 'Financial Institution Number should be 3 digit number',
    groups: [accountDetails]
  })
  financialInstNo: string;

  @Expose()
  @ValidateIf(f => f.address.country === 'CA', { groups: [accountDetails] })
  // @IsInt({groups: [accountDetails]})
  @Length(5, 12, { message: 'Account Number should be max 12 digit number', groups: [accountDetails] })
  bankAccNo: string;

  // @Length(5, 17, {
  //   message: 'Account Number should be 5 -17 digit number',
  //   groups: [accountDetails]
  // })
  @Expose()
  @ValidateIf(f => f.address.country === 'US', { groups: [accountDetails] })
  @Length(5, 15, { message: 'Account Number should be 5-15 digit number', groups: [accountDetails] })
  usAccountNo: string;

  @Expose()
  @ValidateIf(f => f.address.country === 'US', { groups: [accountDetails] })
  // @Length(9, 9, {
  //   message: 'Routing Number should be 9 digit number',
  //   groups: [accountDetails]
  // })
  @Length(9, 9, { message: 'Routing Number should be 9 digit number', groups: [accountDetails] })
  usRoutingNo: string;

  /** Trailer/Truck Vendor, Company renting out assets */
  @Expose({ toPlainOnly: true })
  @IsBoolean({ groups: [companayApprovedFor] })
  get isLessor() : boolean {
    return this.lessorStatus > CompanyStatus.notApproved;
  }
  /** Trailer/Truck Customer, Company get assets on rent */
  /**@deprecated */
  @Expose()
  // @IsBoolean({ groups: [companayApprovedFor] }) // companayApprovedFor when isLessee isLessor is set
  isLessee: boolean;

  @Expose()
  @IsEnum(CompanyStatus, { groups: [companayApprovedFor] })
  lessorStatus: CompanyStatus = CompanyStatus.notApproved;
  /** Trailer/Truck Customer, Company get assets on rent */
  @Expose()
  @IsEnum(CompanyStatus, { groups: [companayApprovedFor] })
  lesseeStatus: CompanyStatus = CompanyStatus.notApproved;
  @Expose()
  isDriverProvider: boolean;

  /** Depericated
   * @deprecated
   */
  // @Expose()
  isTruckRental = true;

  /**
   * @author - MKN
   * @purpose - Maintain netSuite Subsidiary Id at vendor company level
   */
  @Expose()
  @ValidateIf(f => f.isLessor, { groups: [companayApprovedFor] })
  subsidiaryId: ISubsidiaryLinkUp;


  // NO NEED TO CREAET STRING, The ISO will work as well. sanitizeDate was modified to accomodate HTML 5 date control.
  // get insExpiryDateStr(): string { return isDate(this.insExpiryDate) ? `${this.insExpiryDate}` : ''; }
  // set insExpiryDateStr(val) { this.insExpiryDate = sanitizeDate(val); } // sanitizeIonicDatePicker(val); }


  // get insExpiryDateIso(): string { return isDate(this.insExpiryDate) ? this.insExpiryDate.toISOString() : ''; }
  // set insExpiryDateIso(val) { this.insExpiryDate = sanitizeDate(val); } // sanitizeIonicDatePicker(val); }

  // @ValidateNested()
  // contact: ContactModel;

  constructor() {
    super();
    // this.cType = CompanyType.FleetProvider;
    // this.contact = new ContactModel();
    this.address = new Address();
    this.contact = new ContactCard();
    this.companyContact = new ContactCard();
    this.aOI = new FileInfo();
    this.voidCheque = new FileInfo();
    // this.officerPhotoId1 = new FileInfo();
    // this.officerPhotoId2 = new FileInfo();
    this.authUsers = {};
    // getClassName(ContactCard);
    // this.updateFileSecurity();
  }


  /** when read from jason or from plain to class, the date objects may be stored as string. convert them to date. */
  sanitize() {
    // if data was recieved from firebase, date is stored as snapshot.
    super.sanitize();
    // this.insExpiryDate = sanitizeDate(this.insExpiryDate);
    // return this;
    if (!!this.companyContact && !!this.companyContact.phone) {
      this.companyContact.phone = sanitizePhone(this.companyContact.phone);
    }
    if (!!this.contact && this.contact.phone) {
      this.contact.phone = sanitizePhone(this.contact.phone);
    }
  }
  validateSync(options?: ValidatorOptions): IValidationMsg {

    // for nested entry for address, add address group in it.
    if (!!options && !!options.groups && options.groups.indexOf(legal) > -1) {
      options.groups.push(Address.gName);
      options.groups.push(ContactCard.gName);
    }
    if (!!options && !!options.groups && options.groups.indexOf(privateG) > -1
      &&  (options.groups.indexOf(officer1Id) > -1 || options.groups.indexOf(officer2Id) > -1 )) {
      options.groups.push(ContactCard.photoId);
    }
    const r = this.validateSyncBase(this, options);
    if (this.ownershipType === OwnershipType.privateCorp && !!this.officer && !!this.officer[0]?.email && !this.officer[0].photoId) {
      r['officer_0_photoId'] = ['Upload Officer Phone ID'];
    }
    // if (!!this.companyContact && !!this.companyContact.phone && !phoneRegex(this.companyContact.phone)) {
    //   // companyContact_phone replaced by phone because valdiation fails when form is filled even when phone
    //   // r['phone'] = ['Enter valid company phone number'];
    //   r['companyContact_phone'] = ['Enter valid company phone number'];
    // }
    // if (!!this.contact && !!this.contact.phone && !phoneRegex(this.contact.phone)) {
    //   r['contact_phone'] = ['Enter valid contact phone number'];
    // }
    return r;
  }

  // #region Update User Status / Role

  updateRole(uid: string | number, userProfile: CompanyUserProfile) {
    if (this.authUsers == null) {
      this.authUsers = {};
    }
    this.authUsers[uid] = userProfile;
  }

  /**
   * @author - MKN
   * @purpose - Update group in role group
   */

  updateRoleGroup(roleGroup: RoleGroup) {
    if (this.roleGroup == null) {
      this.roleGroup = {};
    }
    this.roleGroup[roleGroup.shortName] = true;
    // this.roleGroup[roleGroup.id] = {
    //     rgId : roleGroup.id,
    //     shortName : roleGroup.shortName
    // };
  }

  /**
   * @author - MKN
   * @purpose - Remove group from role group
   */

  removeRoleGroup(roleGroup: RoleGroup) {
    delete this.roleGroup[roleGroup.shortName];
  }

  addUserByProfile(user: UserProfile, r: RoleCompany = { a: true }) {
    // Add contect
    this.contact = ContactCard.initByUserProfile(user);
    this.companyContact = ContactCard.initByUserProfile(user);

    const cp: CompanyUserProfile = {
      uid: user.id,
      email: user.email,
      displayName: user.displayName,
      roles: r
    };
    this.updateRole(user.id, cp);
  }
  /** Update Company Contact or Officer contact, called when user roles is added or updated */
  updateContact(user: UserProfile, newRole: RoleCompany) {
    switch (this.ownershipType) {
      case OwnershipType.privateCorp:
        if (!!newRole.o) {
          const o = this.officer.find(f => f.email === user.email && !f.contactUid); // !f.contactUid
          if (!!o) {
            o.contactUid = user.id;
            o.phone = user.phoneNumber;
          }
        }
        break;
      case OwnershipType.public:
        if (this.companyContact.email === user.email) {
          this.companyContact.contactUid = user.id;
          this.companyContact.phone = user.phoneNumber;
        }
        break;

      default:
        break;
    }
  }
  /** Remove Company Contact or Officer contact, called when user roles is added or updated */
  removeContact(user: UserProfile) {
    logger.info('role removed from company, company officer and contact updated pending');
    // switch (this.ownershipType) {
    //   case OwnershipType.privateCorp:
    //     const o = this.officer.find(f => f.email === user.email && !f.contactUid);
    //     o.contactUid = user.id;
    //     o.phone = user.phoneNumber;
    //     break;
    //   case OwnershipType.public:
    //     if (this.companyContact.email === user.email) {
    //       this.companyContact.contactUid = user.id;
    //       this.companyContact.phone = user.phoneNumber;
    //     }
    //     break;

    //   default:
    //     break;
    // }
  }

  addUserByClaim(user: IUserClaim, r: RoleCompany = { a: true }) {
    // Add contact
    this.contact = ContactCard.initByUserClaim(user);

    const cp: CompanyUserProfile = {
      uid: user.user_id,
      email: user.email,
      displayName: user.name,
      roles: r
    };
    this.updateRole(user.user_id, cp);
  }

  addUserByWithoutClaim(user: UserProfile, r: RoleCompany = { a: true }) {
    const cp: CompanyUserProfile = {
      uid: user.id,
      email: user.email,
      displayName: user.displayName,
      roles: r
    };
    this.updateRole(user.id, cp);
  }

  addUserAsSales(user:{id: string | number, displayName:string, isPrimary?:boolean, percentage?:number}) {
    // taking id of the auth user
    if (!this.auth) {
      this.auth = { sales: {} };
    }
    if (!this.auth?.sales) {
      this.auth.sales = {};
    }
    let sales: { [key: string]: ISales } = {
      [user.id]: {
        displayName: user.displayName,
        isPrimary: user?.isPrimary ? true : false,
        percentage: user?.percentage ? user.percentage : 0
      }
    };
    // Here adding sales person id 
    this.auth.sales = { ...this.auth.sales, ...sales };
  }

  /**
   * @author KS
   * @purpose Add credit User
   * @param user UserProfile
   */
  addUserAsCredit(user: UserProfile) {
    // taking id of the credit user
    if (!this.auth) {
      this.auth = { credit: {} };
    }
    if (!this.auth?.credit) {
      this.auth.credit = {};
    }
    let credit: { [key: string]: IUserRole } = {
      [user.id]: {
        displayName: user.displayName,
      }
    };
    // Here adding credit person id 
    this.auth.credit = { ...this.auth.credit, ...credit };
  }

  /** Does this user exist in the auth role. */
  hasUserWithRole(uid: string, role: RoleCompany) {
    const r = this.authUsers && this.authUsers[uid] && this.authUsers[uid].roles;
    if (!!r) {
      return r.a === role.a && r.e === role.e && r.r === role.r;
    }
    return false;
  }

  removeRole(uid: string | number) {
    if (Object.keys(this.authUsers).indexOf(`${uid}`) > -1) {
      delete this.authUsers[uid];
    }
  }
  get friendlyName() {
    if (this.legalName?.toLocaleLowerCase() === this.name?.toLocaleLowerCase()) {
      return this.name;
    }
    return `${toPascalCase(this.name)}(${this.legalName})`;
  }
  // #endregion

  //#region admin task
  setAdminTaskInputs(task: Task, websiteUrl: string) {
    task.senderCompanyName = this.companyFriendlyName();
    task.taskType = this.getTaskType();
    task.name = `${task.senderCompanyName} ${toSentence(EnumHelper.getName(TaskType, task.taskType))}`;
    task.data = { action: `${task.taskType}`, action_key: `${this.id}` };


    const urlSegment = TaskUtl.getUrlFromTaskType(task.taskType, task.data.action_key).url;

    task.notification = {
      title: `${task.name}`,
      body: 'Click to get company details',
      icon: 'https://placeimg.com/250/250/people',
      clickAction: `${websiteUrl}${urlSegment}`
    };
  }

  getTaskType(): TaskType {
    if (isNil(this.dbStatus)) {
      return TaskType.CompanyCreated;
    }
    switch (this.dbStatus) {
      case DbStatus.Initial:
        if (this.revId === 0) {
          return TaskType.CompanyCreated;
        } else {
          return TaskType.CompanyUpdated;
        }
      case DbStatus.SubmitedForApproval:
        return TaskType.RequestCompanyApproval;
      case DbStatus.ReleasedMod:
        return TaskType.CompanyUpdated;
      case DbStatus.Released:
        return TaskType.CompanyReleased;

      default:
        throw new Error(`Invalid dbStatus, ${this.dbStatus} is not programmed yet`);

    }
  }
  setCompReleaseTaskInputs(task: Task, websiteUrl: string) {
    task.taskType = this.getTaskType();
    task.name = `${this.companyFriendlyName()} ${toSentence(EnumHelper.getName(TaskType, task.taskType))}`;
    task.data = { action: `${task.taskType}`, action_key: `${this.id}` };


    const urlSegment = TaskUtl.getUrlFromTaskType(task.taskType, task.data.action_key).url;

    task.notification = {
      title: `${task.name}`,
      body: `Click to get Company details`,
      icon: 'https://placeimg.com/250/250/people',
      clickAction: `${websiteUrl}${urlSegment}`
    };
  }
  // #endregion
  companyFriendlyName(): string {
    if (!this.name) {
      return this.legalName;
    }
    if (this.legalName.toLowerCase() === this.name.toLowerCase()) {
      return this.name;
    }
    return `${toPascalCase(this.name)} (${this.legalName})`;
  }
  isLesseeLessorChanged(m: CompanyBase): boolean {
    if ((this.lessorStatus ===  m.lessorStatus) && (this.lesseeStatus === m.lesseeStatus)) {
      return false;
    } else {
      return true;
    }
  }

  /**
   * Return boolean based on subsidiary Id Is different
   * UI Validation
   * @param m - company Object
   * @returns boolean
   */
  isSubsidiaryIdChanged(m: CompanyBase): boolean {
    if (isEqual(this.subsidiaryId,m.subsidiaryId)) {
      return false;
    } else {
      return true;
    }
  }

 /**
   * return ture if contact card contactUid is included in company users
   * @param c Contact Card
   * @returns boolean
   */
  isContactResitered(c: ContactCard): boolean {
    if (!c || !c.contactUid) {
      return false;
    }
    return this.companyUsers && this.companyUsers.includes(`${c.contactUid}`);
  }
  public get companySummary(): CompanySummaryBase {
    return {
      // cid: `${this.id}`,
      name: this.name,
      legalName: this.legalName,
    };
  }
/**
 * @author Cm
 * @param uid 
 * Remove salesperson/Credit from company by admin
 */
  removeSalesOrCreditFromAuth(uid: string | number, isSale:boolean): void {
    // isSale is true remove from sale if id is available
    if(isSale){
      if (Object.keys(this.auth?.sales).indexOf(`${uid}`) > -1) {
        delete this.auth?.sales[uid];
      }
    }else{
      if (Object.keys(this.auth?.credit).indexOf(`${uid}`) > -1) {
        delete this.auth?.credit[uid];
      }
    }
  }

}