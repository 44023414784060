import { IOrderParam, orderSerachClientFilter, getIOrdersOptionOrChildren } from '@trent/models/finance/order/order-param';
import { OrderService } from '@trent/services/order.service';
import { StateBase } from '../state-base';
import * as entity from '../entity.state';
import { State, Selector, Action, StateContext } from '@ngxs/store';
import { map } from 'rxjs/operators';
import { noop, Subscription } from 'rxjs';
import { Paging, getObjKey } from '@trent/models/observable-util/paging';
import { IDataLoadStatus, buildDataRequest, LoadStatus, getRootLevelChildren, updatePaging } from '@trent/models/observable-util/data-status';
import { Order } from '@trent/models/finance/order/order';
import { PagingContainer } from '@trent/models/observable-util/paging-container';
import { Injectable } from '@angular/core';

// #region State Model
export interface OrderStateModel {

  paging: Paging;

  /** Which client side queries are complete */
  dataLoadStatus: {
    [key: string]: IDataLoadStatus<IOrderParam>;
  };

  /** Order Container. */
  data: { [id: string]: Order }; // entity.EntityState<ProductBase>;
}

function initOrderState(): OrderStateModel {
  return {
    paging: { size:10, offset: 0, full: false, currentPage: 0, count: 300, maxPageReached: 0 },
    dataLoadStatus: {},
    data: {}
  };
}

// #endregion

// #region actions
export class OrderByIdRequested {
  static readonly type = '[Order] Request a single Order entity';
  constructor(public payload: { id: string | number }) { }
}

export class OrdersByIdsRequested {
  static readonly type = '[Order] Request Multiple Order entities';
  constructor(public payload: { bidIds: (string | number)[] }) { }
}

export class OrderByIdLoaded {
  static readonly type = '[Order] Load a single Order entity';
  constructor(public payload: { data: Order, id: string | number }) { }
}
export class OrderByBidIdsLoaded {
  static readonly type = '[Order] Load multiple order entities';
  constructor(public payload: { data: Order[] }) { }
}

export class UpdateOrderListSelection {
  static readonly type = '[Order] Update List Selection';
  constructor(public payload: { pData: Paging }) { }
}
export class OrderStateReset {
  static readonly type = '[Order] Reset State';
}

export class OrdersRequested {
  static readonly type = '[Order] All orders Requested';
  constructor(public payload: { pData: Paging, param: IOrderParam }) { }
}

export class OrdersLoaded {
  static readonly type = '[Order] All orders Loaded';
  constructor(public payload: {
    data: { [id: string]: Order }, // Data
    key: string,
  }) { }
}

export class OrdersNextPageRequested {
  static readonly type = '[Order] All orders requested - Next Page';
  constructor(public payload: { option: IOrderParam, pData: Paging }) { }
}



// #endregion
@State<OrderStateModel>({
  name: 'Order',
  defaults: initOrderState()
})
@Injectable()
// #endregion
export class OrderState extends StateBase {

  dataReqSub: Subscription;

  /** Container that keep all of the subscription related to gettting data. */
  dataSubData: PagingContainer<Order, IOrderParam> = new PagingContainer();


  constructor(private os: OrderService) {
    super();
  }

  // #region Selectors

  @Selector()
  static selectDataById(state: OrderStateModel) {
    return entity.getByIdFn_new(state.data, Order.parse);
  }

  @Selector()
  static selectAllData(state: OrderStateModel) {
    //debugger;
    return (o: IOrderParam): Order[] => {

      if (state.data == null || Object.keys(state.data).length === 0) {
        return [];
      }

      // remove keys that have revision/draft ids, i.e that contain '~' in the id.
      const keys = Object.keys(state.data).filter(k =>
        k.indexOf('/') === -1 && state.data[k] != null);

      // object without the rev/draft.
      const filtered = keys.reduce((obj, k) => {
        obj[k] = state.data[k];
        return obj;
      }, {});

      let output = Object.values(filtered).map(x => Order.parse(x));
      output = orderSerachClientFilter(output, o);
      // output = output.filter(element => {
      //   return element.invoiceType == 10;
      // });
      // console.log(output,'output.filter........!');
      return output;
      // return entity.map(state.products, parseProduct);
    };
  }

  /** Is the Paging Full for this search. */
  @Selector()
  static selectAllDataFull(state: OrderStateModel) {
    return (o: IOrderParam): boolean => {
      const oKey = getObjKey(o);
      const r = state.dataLoadStatus && state.dataLoadStatus[oKey];
      return (!!r) ? r.pData.full : true;
    };
  }

  @Selector()
  static selectPaging(state: OrderStateModel) {
    console.log(state.paging,'state.paging......!');
    return state.paging;
  }

  // #endregion

  // #region Custom Functions and subscriptions

  public clearSubscriptions() {
    if (!!this.dataReqSub) {
      this.dataReqSub.unsubscribe();
      this.dataReqSub = null;
    }
    this.dataSubData.unsubscribeAll();
    super.clearSubscriptions();
  }

  // #endregion


  // #region Actions Single Entity


  @Action(OrderByIdRequested)
  dataByIdRequested(context: StateContext<OrderStateModel>, action: OrderByIdRequested) {
    const state = context.getState();
    if (Object.keys(state.data).indexOf(`${action.payload.id}`) === -1) {
      // console.log('requested Rental-Product is not in the store. sending request to server', action.payload.id);
      this.os.getOrderById(action.payload.id)
        .pipe(
          map(data => {
            console.log('from server: retal map was called', data);
            return context.dispatch(new OrderByIdLoaded({ data, id: action.payload.id }));
          }
          )).subscribe(noop);
    } else { console.log('requested Rental-Product is available in the store'); }
  }

  @Action(OrdersByIdsRequested)
  dataByIdsRequested(context: StateContext<OrderStateModel>, action: OrdersByIdsRequested) {
    const state = context.getState();
    if (Object.keys(state.data).indexOf(`${action.payload.bidIds}`) === -1) {
      // console.log('requested Rental-Product is not in the store. sending request to server', action.payload.id);
      this.os.getOrdersByBidIDs(action.payload.bidIds)
        .pipe(
          map((data: Order[]) => {
            return context.dispatch(new OrderByBidIdsLoaded({ data }));
          }
          )).subscribe(noop);
    } else { console.log('requested Rental-Product is available in the store'); }
  }

  @Action(OrderByIdLoaded)
  dataByIdLoaded(context: StateContext<OrderStateModel>, action: OrderByIdLoaded) {
    const state = context.getState();
    const data = action.payload.data || null;
    const p = {};
    p[action.payload.id] = data;
    context.patchState({
      data: { ...state.data, ...p }
    });
  }
  @Action(OrderByBidIdsLoaded)
  dataByIdsLoaded(context: StateContext<OrderStateModel>, action: OrderByBidIdsLoaded) {
    const state = context.getState();
    const orders = action.payload.data;
    const p = {};
    orders.forEach(order => {
      p[order.id] = order;
    });
    console.log(p);
    context.patchState({
      data: { ...state.data, ...p }
    });
  }

  // #endregion Single Entity

  // #region All Orders
  @Action(OrdersRequested)
  dataRequested(context: StateContext<OrderStateModel>, action: OrdersRequested) {
    const oKey = getObjKey(action.payload.param);

    // Define function that return the data status object from the state.
    const getDataStatusStateFn = () => context.getState().dataLoadStatus;

    /** custom build the OR children query. */
    const buildOrQueryChildrenFn = (o: IOrderParam) => getIOrdersOptionOrChildren(o);
    const state = context.getState();

    // if data requested now, is already partially loaded by another query's child previously
    // but the number of the items are not enough (ex. as a child, it loaded only 5, but current
    // request ask for more, then next page of that query should be called instead.)
    const nextPageFn = (option: IOrderParam) => {
      context.dispatch(new OrdersNextPageRequested({ option, pData: state.paging }));
    };

    buildDataRequest(
      oKey, action.payload.param, action.payload.pData,
      getDataStatusStateFn,
      buildOrQueryChildrenFn,
      nextPageFn,
      (
        obj: { [key: string]: IDataLoadStatus<IOrderParam> },
        set: { key: string, node: IDataLoadStatus<IOrderParam> }[]
      ) => {

        if (!!obj) {
          context.patchState({
            dataLoadStatus: { ...state.dataLoadStatus, ...obj }
          });
        }

        // Process the query.
        set.forEach((val) => {
          // some of the nodes are already loaded. Only process that are loading... status.
          if (val.node.loadStatus !== LoadStatus.Loading) {
            return;
          }
          // if this request is just overwriting a stall or pending request, unsubscribe that observable
          this.dataSubData.unsubscribe(val.key);

          // create the paging observable and call db.
          const p = this.os.getOrders_PagingObservable();
          console.log(action.payload.param,'action.payload.param.....!');
          console.log(action.payload.pData,'action.payload.pData.....!');
          p.getCount(action.payload.pData, action.payload.param).subscribe(count => {
            context.patchState({
              paging: {
                ...state.paging,
                count
              }
            });
          const prod$ = p.getData(action.payload.pData, val.node.param)
            .pipe(
              map(pickDrops => {
                // if (!val.node.param.pdIdForHistory) {
                context.dispatch(new OrdersLoaded({
                  data: pickDrops,
                  key: val.key
                }));
                // } else {
                //   context.dispatch(new AllPickDropHistoryLoaded({
                //     data: pickDrops,
                //     key: val.key
                //   }));
                // }
                return pickDrops;
              }));
          const sub = this.subscribe(prod$, () => noop(), OrdersRequested.type);
          // save the observable call
          this.dataSubData.addData(val.key, sub, p);
        });
      }
    );
  });
  }

  @Action(UpdateOrderListSelection)
  updatePaging(context: StateContext<OrderStateModel>, action: UpdateOrderListSelection) {
    const state = context.getState();
    let pData: Paging = action.payload.pData;
    context.patchState(
      {
        paging: pData
      }
    );
  }

  @Action(OrdersNextPageRequested)
  dataNextPageRequested(context: StateContext<OrderStateModel>, action: OrdersNextPageRequested) {
    const oKey = getObjKey(action.payload.option);
    const state = context.getState();
    context.dispatch(new UpdateOrderListSelection({
      pData:
      {
        ...action.payload.pData,
        maxPageReached: action.payload.pData.currentPage
      }
    }));    // find the node. can be parent or child
    const statusObj = state.dataLoadStatus[oKey];
    // if no parent, treat is
    if (statusObj.children == null) {
      this.dataSubData.dispatchNextPagingUpdate(oKey);
    } else {
      const children = getRootLevelChildren(oKey, state.dataLoadStatus);
      children.forEach(c => {
        this.dataSubData.dispatchNextPagingUpdate(c.key);
      });
    }
  }
  @Action(OrdersLoaded)
  dataLoaded(context: StateContext<OrderStateModel>, action: OrdersLoaded) {
    const state = context.getState();
    const subData = this.dataSubData.getData(action.payload.key);
    const updatedLoadStatus = updatePaging(action.payload.key, state.dataLoadStatus, subData);
    context.patchState({
      dataLoadStatus: updatedLoadStatus,
      data: { ...state.data, ...action.payload.data }// entity.addMany(state.products, action.payload.products)
    });
  }


  // #endregion All Orders


  @Action(OrderStateReset)
  dataStateReset(context: StateContext<OrderStateModel>, action: OrderStateReset) {
    // unsubscribe the data
    this.clearSubscriptions();
    context.setState(initOrderState());
  }

}

// CREATE STATE RSET
