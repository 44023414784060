import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { BaseHttpService } from './base-http.service';
import { PtsEnquiry } from '@trent/models/pts/pts-enquiry';


@Injectable()
export class UserAnonymousService extends BaseHttpService {


  constructor(store: Store, private http: HttpClient) {
    super(store);
    this.apiName = 'gps';
  }
  /**
   * Get phone token for anonymous  user
   * @param data InspectionPayload, optional properties,  code, inpsectionData will be stripped
   */
  canResetPassword(email: string) {

    // const headers = this.addBearerToken(); // not requied for anonymous user
    // Server validation before creating the entry.
    return this.http.post<boolean>(this.getApiUrl('/user-anonymous/can-reset-password'),
      {
        email,
      },
      // { headers: headers }
    )
      .pipe(
        tap(r => console.log('response: ', r))
      );
  }

  
    /// Create new Enquiry for PTS
    public createEnquiryAsGuest(data: PtsEnquiry) {
      // Prepare the post data for create the pts Enquiry
      return this.http.post<{ status: boolean }>(this.getApiUrl('/guest-enquiry/create'),
        // Server validation before creating the entry.
        { data: data },
      ).pipe( tap(res => console.log('response: ', res)));
    }
  


}
