import { FileInfo, IFileMoveData } from './../media/file-info';
import { CompanyBase, CompanyValidationGroup, CompanySummaryBase } from './company-base';
import {
  ValidatorOptions, Matches, Length, IsBoolean, Max, IsNumber, ValidateNested, IsDefined,
  IsDate, ValidateIf, IsInt, MaxLength
} from 'class-validator';
import { CompanyType } from './company-type';
import { isDate, isAfter, addYears, addDays } from 'date-fns';
import { IValidationMsg, IValidate, IValDbStatusResult } from '../error-handling/validation-info';
import { instanceToInstance, plainToInstance, Type, Expose, Exclude } from 'class-transformer';
import { DbStatus } from '../base';
import { sanitizeDateIPoint, sanitizeDate } from '../utility';
import { ContactCard } from '../user/contact-card';
import { Address } from '../address/address';
import { addAYear } from '../utility/utl-datef';
import { phoneRegex } from '../utility/validation-helper';
import { logger } from '../log/logger';
import { CarrierCompanySummary } from './company-carrier';
import { OwnershipType } from './ownership-type';
import { CompanyStatus } from './company-status';


export interface CustomerCompanySummary extends CompanySummaryBase {
  cvorNumber?: string;
  usDotNumber?: number;
  mcNumber?: number;
  ifta: number;
  insCo: string;
  insDeductable: number;
  nonOwnedTrailerIns: boolean;
  nonOwnedTruckIns: boolean;
  insExpiryDate: Date;
  officer: ContactCard[];
}

export interface VendorCompanySummary extends CompanySummaryBase {
  insCo: string | number;
  insDeductable: number;
  insExpiryDate: Date;
  officer: ContactCard[];
}
/** https://safer.fmcsa.dot.gov/CompanySnapshot.aspx
 * https://saferwebapi.com/documentation/snapshots-usdot
 */
const regex = {
  js: {
    // tslint:disable-next-line:max-line-length

    nounCvor: /^([0-9]){3}([\-])([0-9]){3}(\-)([0-9]){3}$/,
    nounNir: /^([A-Z]){1}([\-])([0-9]){6}(\-)([0-9]){1}$/,
    // nounUsDot: /^([A-Z]){1}([\-])([0-9]){6}(\-)([0-9]){1}$/,
    nounUsDot: /^([0-9]){5,7}$/,
    nounMcNo: /^([0-9]){6,7}$/,
    // nounIfta: /^([0-9]){8,15}$/
  }
};

const legal: CompanyValidationGroup = 'legal';
// const documents: CompanyValidationGroup = 'documents';
const insurance: CompanyValidationGroup = 'insurance';
const authority: CompanyValidationGroup = 'authority';
const misc: CompanyValidationGroup = 'misc';
const accountDetails: CompanyValidationGroup = 'accountDetails';
const privateG: CompanyValidationGroup = 'privateG';
const publicG: CompanyValidationGroup = 'publicG';
const creditPackage: CompanyValidationGroup = 'creditPackage';
const fleetPackage: CompanyValidationGroup = 'fleetPackage';
const officer1Id: CompanyValidationGroup = 'officer1Id';
const officer2Id: CompanyValidationGroup = 'officer2Id';
const aOI: CompanyValidationGroup = 'aOI';
const bankStatements: CompanyValidationGroup = 'bankStatements';
const voidCheque: CompanyValidationGroup = 'voidCheque';


/** Fleet Providers */
@Exclude()
export class CompanyFleet extends CompanyBase implements IValidate {

  /** Not to be filled manually will be automatically updated as assets are added / deleted */
  @Expose()
  @IsNumber()
  nTruck = 0;

  /** Not to be filled manually will be automatically updated as assets are added / deleted */
  @Expose()
  @IsNumber()
  nDriver = 0;

  /** Not to be filled manually will be automatically updated as assets are added / deleted */
  @Expose()
  @IsNumber()
  nTrailer = 0;

  /** Ontario operating authorities required for all US companies hauling in Ontario*/
  // @ValidateIf(f => !!f.isTruckRental, { groups: [authority] })
  @ValidateIf(f => f.address.stateProv === 'ON', { groups: [authority] })
  @Expose()
  @Matches(regex.js.nounCvor, { message: 'enter valid CVOR number e.g. 123-325-763', groups: [authority] })
  cvorNumber?: string;  // different for different provices covr (ON) used as place holder for not, format "099-754-960"
  /** Local Operating Auth number, eg, CVOR for ON*/
  // @ValidateIf(f => !!f.isTruckRental, { groups: [authority] })
  @ValidateIf(f => f.address.stateProv !== 'ON' && f.address.country === 'CA', { groups: [authority] })
  @Expose()
  @MaxLength(20, { message: 'enter valid Local Operating Auth No.', groups: [authority] })
  localOpAuthNo?: string;  // different for different provices covr (ON) used as place holder for not, format "099-754-960"

  // @Matches(regex.js.nounNir, { message: 'enter valid NIR number' })
  // nirNumber?: string; // only for quebec format R-526672-2

  /** US FMCSA operating authorities */
  /** Carrier search @ FMCSA or download from https://ai.fmcsa.dot.gov/SMS/Tools/Downloads.aspx */
  // @ValidateIf(f => !!f.isTruckRental, { groups: [authority] })
  @Expose()
  @ValidateIf(f => f.address.country === 'US', { groups: [authority] })
  @Matches(regex.js.nounUsDot, { message: 'enter valid US DOT number, e.g. 891389', groups: [authority] })
  usDotNumber?: number; // format 5 digit to 7 digit

  /** US FMCSA operating authorities */
  // @ValidateIf(f => !!f.isTruckRental, { groups: [authority] })
  @Expose()
  @ValidateIf(f => f.address.country === 'US', { groups: [authority] })
  @Matches(regex.js.nounMcNo, { message: 'enter valid MC-number, e.g. 2556488', groups: [authority] })
  mcNumber?: number; // format mc# 6 digits

  /** International Fuel Tax Agreement */
  // @ValidateIf(f => !!f.isTruckRental, { groups: [authority] })
  @Expose()
  // @IsNumber()
  // @Matches(regex.js.nounIfta, { message: 'enter valid IFTA number, e.g. 000125785', groups: [authority] })
  @ValidateIf(f => !!f.ifta, { groups: [authority] })
  @Length(8, 15,  { message: 'enter valid IFTA number, e.g. 000125785', groups: [authority] })
  ifta: number; // format ?8-10 digits

  @Expose()
  @Length(5, 254, { message: 'Insurance Co. name must be $constraint1 - $constraint2 chars long', groups: [insurance] })
  insCo: string;

  // @IsDate({ message: 'Insurance Expiry date is required' })
  // insExpiryDate: Date;

  @Expose()
  @IsInt({ message: 'Insurance deductable must be integer number', groups: [insurance] })
  // @Max(15000, { message: 'Insurance deductable cannot be more than $constraint1', groups: [insurance] })
  insDeductable: number;

  @Expose()
  @IsBoolean({ message: 'Are non Owned Trailers covered under Insurance', groups: [insurance] })
  nonOwnedTrailerIns: boolean;

  @Expose()
  @IsBoolean({ message: 'Are non Owned Trucks covered under Insurance', groups: [insurance] })
  nonOwnedTruckIns: boolean;

  /** can get the ValidateIf, blocked validation. This property is required for trailer rental */
  @ValidateIf(f => !!f.isDriverProvider, { groups: [insurance] })
  @Expose()
  @IsBoolean({ message: 'Does Co has driver hiring discretion', groups: [insurance] })
  driverHiringDiscretion?: boolean;

  // @Expose()
  // @Length(5, 254, { message: 'GPS Co. name must be $constraint1 - $constraint2 chars long', groups: [misc] })
  // gpsCoTrucks?: string;

  // @Expose()
  // @Length(5, 254, { message: 'GPS Co. name must be $constraint1 - $constraint2 chars long', groups: [misc] })
  // gpsCoTrailers?: string;

  /** Carrier package file. */
  // @Length(5, 254, { message: 'Upload Article of Incorporation', groups: [documents] })
  @Expose()
  @ValidateNested({ message: 'Upload Carrier Package', groups: [fleetPackage] })
  @IsDefined({ message: 'Upload Carrier Package', groups: [fleetPackage] })
  @Type(() => FileInfo)
  carrierPackage: FileInfo;

  @Expose()
  @ValidateNested({ message: 'Upload Credit Package', groups: [creditPackage] })
  @IsDefined({ message: 'Upload Credit Package', groups: [creditPackage] })
  @Type(() => FileInfo)
  companyCreditPackage: FileInfo;

  // @Length(5, 254, { message: 'Upload insurance certificate', groups: [documents] })
  @Expose()
  @ValidateNested({ message: 'Upload Insurance Certificate', groups: [fleetPackage] })
  @IsDefined({ message: 'Upload Insurance Certificate', groups: [fleetPackage] })
  @Type(() => FileInfo)
  insCert: FileInfo;

  @Expose()
  @ValidateNested({ message: 'Upload Insurance Certificate', groups: [bankStatements] })
  @IsDefined({ message: 'Upload Insurance Certificate', groups: [bankStatements] })
  @Type(() => FileInfo)
  bankStatements: FileInfo;

  @Expose()
  @IsDate({
    message: 'Insurance Expiry date is required',
    groups: [insurance]
  })
  insExpiryDate: Date;
  /** UI Helper for Min Insurance expiry */
  get insExpiryDateMinDate() {
    return new Date();
  }
  /** UI Helper for Max Insurance expiry */
  get insExpiryDateMaxDate() {
    return addDays(addYears(new Date(), 1), -1);
  }
  /** UI Helper for isReefer Property */
  get nonOwnedTrailerInsStr() {
    if (!!this.nonOwnedTrailerIns) {
      return 'Non-owned trailers are insured';
    } else {
      return 'Non-owned trailers are not insured';
    }
  }
  /** UI Helper for isReefer Property */
  get nonOwnedTruckInsStr() {
    if (!!this.nonOwnedTruckIns) {
      return 'Non-owned trucks are insured';
    } else {
      return 'Non-owned trucks are not insured';
    }
  }
  get isLesseeStr() {
    if (!!this.isLessee) {
      return 'Approved Lessee';
    } else {
      return 'Not Approved as Lessee';
    }
  }
  get isLessorStr() {
    if (!!this.isLessor) {
      return 'Approved Lessor';
    } else {
      return 'Not Approved as Lessor';
    }
  }
  public get carrierCompanySummary(): CarrierCompanySummary {
    return {
      cid: `${this.id}`,
      name: this.name,
      legalName: this.legalName,
      address: this.address,
      dbStatus: this.dbStatus,
      revId: this.revId,
      phone: !!this.companyContact ? this.companyContact.phone : undefined,
      email: !!this.companyContact ? this.companyContact.email : undefined
    };
  }
  get insExpiryDateIso(): string { return isDate(this.insExpiryDate) ? this.insExpiryDate.toISOString() : ''; }
  set insExpiryDateIso(val) { this.insExpiryDate = sanitizeDate(val); } // sanitizeIonicDatePicker(val); }

  // #endregion
  constructor() {
    super();
    // this.companyType = CompanyType.FleetProvider;
    this.insCert = new FileInfo();
    this.carrierPackage = new FileInfo();
    this.companyCreditPackage = new FileInfo();
    this.bankStatements = new FileInfo();
    this.voidCheque = new FileInfo();
    this.companyType = CompanyType.Fleet;
    this.nonOwnedTrailerIns = false;
    this.nonOwnedTruckIns = false;
    FileInfo.updateFileSecurity(this, true);
  }

  /** Convert all GeoPoint class instances to IPoint. required before parsing data coming back
 * from firebase. This must be called BEFORE PARSE and not AFTER PARSE
  * @param data Data to be parsed
  * @param type type to be used to decipher latitude/longitude. This is needed because at client the type is : firebase.firestore.GeoPoint
  * and at function it is admin.firestore.GeoPoint they are two different types.
  * https://github.com/Microsoft/TypeScript/wiki/FAQ#why-cant-i-write-typeof-t-new-t-or-instanceof-t-in-my-generic-function
  */
  public static parse(obj) {
    // public static parse<T extends IPoint>(obj, ipointType?: { new(...args: any[]): T }) {
    try {
      if (obj == null) { return null; }
      // obj = sanitizeDateIPoint(obj, ipointType);
      obj = sanitizeDateIPoint(obj);
      if(obj.isLessor === true && obj.lesseeStatus === undefined){
        obj.lessorStatus = CompanyStatus.approved;
      }
      if(obj.isLessee === true && obj.lesseeStatus === undefined){
        obj.lesseeStatus = CompanyStatus.approved;
      }
      const m = plainToInstance<CompanyFleet, any>(CompanyFleet, obj);
      m.sanitize();
      if (m.contact == null) { m.contact = new ContactCard(); }
      if (m.aOI == null) { m.aOI = new FileInfo(); }
      if (m.insCert == null) { m.insCert = new FileInfo(); }
      if (m.carrierPackage == null) { m.carrierPackage = new FileInfo(); }
      if (m.companyCreditPackage == null) { m.companyCreditPackage = new FileInfo(); }
      if (m.bankStatements == null) { m.bankStatements = new FileInfo(); }
      if (m.voidCheque == null) { m.voidCheque = new FileInfo(); }
      if (m.officer?.length > 0) {
        m.officer = ContactCard.parseArray((<CompanyFleet>obj).officer);
      }
     
     
      // if (m.officerPhotoId1 == null) { m.officerPhotoId1 = new FileInfo(); }
      // if (m.officerPhotoId2 == null) { m.officerPhotoId2 = new FileInfo(); }
      if (m.address == null) { m.address = new Address(); }

      return m;
    } catch (error) {
      logger.log('Error happened during parse', error);
      return null;
    }
  }

  public getCustomerCompanySummary(c?: CompanyFleet): CustomerCompanySummary {
    return {
      cvorNumber: this.cvorNumber,
      usDotNumber: this.usDotNumber,
      mcNumber: this.mcNumber,
      ifta: this.ifta,
      insCo: this.insCo,
      insDeductable: this.insDeductable,
      insExpiryDate: this.insExpiryDate,
      nonOwnedTrailerIns: this.nonOwnedTrailerIns,
      nonOwnedTruckIns: this.nonOwnedTruckIns,
      cid: `${this.id}`,
      legalName: this.legalName,
      address: this.address,
      officer: this.officer,
      dbStatus: this.dbStatus,
      revId: this.revId,

    };
  }
  public getVendorCompanySummary(): VendorCompanySummary {
    return {
      insCo: this.insCo,
      insDeductable: this.insDeductable,
      insExpiryDate: this.insExpiryDate,
      cid: `${this.id}`,
      legalName: this.legalName,
      address: this.address,
      officer: this.officer,
      dbStatus: this.dbStatus,
      revId: this.revId,
      phone: this.companyContact?.phone,
      email: this.companyContact?.email,
    };
  }

  clone() {
    const t = instanceToInstance(this);
    t.sanitize();
    return t;
  }
  sanitize() {
    // if data was recieved from firebase, date is stored as snapshot.
    super.sanitize();
    this.insExpiryDate = sanitizeDate(this.insExpiryDate);
    // return this;
  }

  /** Update all attached documents paths from /draft/ to /rel/ */
  updateFilePathsToRelease(): IFileMoveData[] {
    const R: IFileMoveData[] = [];

    let r = FileInfo.updatePathToRelease(this.voidCheque); // this.aOI
    if (!!r) { R.push(r); }

    r = FileInfo.updatePathToRelease(this.insCert);
    if (!!r) { R.push(r); }

    r = FileInfo.updatePathToRelease(this.carrierPackage);
    if (!!r) { R.push(r); }
    r = FileInfo.updatePathToRelease(this.companyCreditPackage);
    if (!!r) { R.push(r); }
    r = FileInfo.updatePathToRelease(this.bankStatements);
    if (!!r) { R.push(r); }

    if (this.ownershipType === OwnershipType.privateCorp) {
      r = FileInfo.updatePathToRelease(this.aOI);
      if (!!r) { R.push(r); }
      for (const o of this.officer) {
        r = FileInfo.updatePathToRelease(o.photoId);
        if (!!r) { R.push(r); }
      }
    }
    return R;
  }

  // /** Update all attached documents paths from /temp/ to /draft/ */
  // updateFilePathsToDraft(): IFileMoveData[] {
  //   const R: IFileMoveData[] = [];
  //   let r = FileInfo.updatePathTempToDraft(this.aOI);
  //   if (!!r) { R.push(r); }

  //   r = FileInfo.updatePathTempToDraft(this.insCert);
  //   if (!!r) { R.push(r); }

  //   r = FileInfo.updatePathTempToDraft(this.carrierPackage);
  //   if (!!r) { R.push(r); }

  //   return R;
  // }


  validateSyncGroup(group: CompanyValidationGroup ): IValidationMsg {
    return this.validateSync({ groups: [group] });
  }

  validateSync(options?: ValidatorOptions): IValidationMsg {

    // for nested entry for address, add address group in it.
    if (!!options && !!options.groups && options.groups.indexOf(legal) > -1) {
      options.groups.push(Address.gName);
      // options.groups.push(ContactCard.gName);
    }

    const r = super.validateSync(options);
    // const r = this.validateSyncBase(this, options);

    // Insurance expiry data must be in the future.
    // if (!!options) {

    // if (isDate(this.insExpiryDate) && options.groups.indexOf(insurance) !== -1) {
    if (isDate(this.insExpiryDate)) {

      if (isAfter(new Date(), this.insExpiryDate)) {
        if (!!options?.groups && !!options.groups.includes('insurance')) {
          r['insExpiryDate'] = ['Insurance expiry date must be in future!'];
        }
      }
      // insExpiryDate data must within a year
      if (isAfter(this.insExpiryDate, addAYear(1))) {
        if (!!options?.groups && !!options.groups.includes('insurance')) {
          r['insExpiryDate'] = ['Insurance expiry date must be within a year'];
        }
      }
    }

    return r;
  }

  /** Analyze company to see if more information is needed or suggest the user
   * to submit for approval.*/
  getValDbStatus(): IValDbStatusResult {
    const result: IValDbStatusResult = {
      pass: false,
      message: undefined,
      groupResult: {}
    };    //  { [group: string]: { result: boolean; message: string; } } = {};
    let x: { groupPass: boolean; message: string; };

    // Legal
    let r = this.validateSyncGroup(legal);
    x = { groupPass: null, message: '' };
    x.groupPass = Object.keys(r).length === 0;
    x.message = (x.groupPass) ? 'Manage Legal Information' : 'Legal information is required'; //  `${Object.values(r)[0]}`
    result.groupResult[legal] = x;

    // Documents
    let g: CompanyValidationGroup[] = [officer1Id, 'documents'];
    r = this.validateSync({ groups: g });
    x = { groupPass: null, message: '' };
    x.groupPass = Object.keys(r).length === 0;
    x.message = (x.groupPass) ? 'Manage Required Documents' : 'Documents information is required';
    result.groupResult[officer1Id] = x;

    g = [aOI, 'documents'];
    r = this.validateSync({ groups: g });
    x = { groupPass: null, message: '' };
    x.groupPass = Object.keys(r).length === 0;
    x.message = (x.groupPass) ? 'Manage Required Documents' : 'Documents information is required';
    result.groupResult[aOI] = x;

    g = [voidCheque, 'documents'];
    r = this.validateSync({ groups: g });
    x = { groupPass: null, message: '' };
    x.groupPass = Object.keys(r).length === 0;
    x.message = (x.groupPass) ? 'Manage Required Documents' : 'Documents information is required';
    result.groupResult[voidCheque] = x;

    g = [bankStatements, 'documents'];
    r = this.validateSync({ groups: g });
    x = { groupPass: null, message: '' };
    x.groupPass = Object.keys(r).length === 0;
    x.message = (x.groupPass) ? 'Manage Required Documents' : 'Documents information is required';
    result.groupResult[bankStatements] = x;

    // Ownership
    r = this.ownershipType === OwnershipType.public ? this.validateSyncGroup(publicG) : this.validateSyncGroup(privateG);
    x = { groupPass: null, message: '' };
    x.groupPass = Object.keys(r).length === 0;
    x.message = (x.groupPass) ? 'Manage Required Documents' : 'Documents information is required';
    if (this.ownershipType === OwnershipType.public) { result.groupResult[publicG] = x; } else { result.groupResult[privateG] = x; }


    // Authority
    r = this.validateSyncGroup(authority);
    x = { groupPass: null, message: '' };
    x.groupPass = Object.keys(r).length === 0;
    x.message = (x.groupPass) ? 'Manage Required Carrier Authorities' : 'Carrier Authorities information is required';
    result.groupResult[authority] = x;

    // Insurance
    if (false) {
      r = this.validateSyncGroup(insurance);
      x = { groupPass: null, message: '' };
      x.groupPass = Object.keys(r).length === 0;
      x.message = (x.groupPass) ? 'Manage Required Insurance' : 'Insurance information is required';
      result.groupResult[insurance] = x;
    }

    // Misc
    r = this.validateSyncGroup(misc);
    x = { groupPass: null, message: '' };
    x.groupPass = Object.keys(r).length === 0;
    x.message = (x.groupPass) ? 'Manage Required Fleet Information' : 'Fleet information is required';
    result.groupResult[misc] = x;

    // accountDetails
    r = this.validateSyncGroup(accountDetails);
    x = { groupPass: null, message: '' };
    x.groupPass = Object.keys(r).length === 0;
    x.message = (x.groupPass) ? 'Manage Accounting Information' : 'Accounting information is required';
    result.groupResult[accountDetails] = x;

    // is it passed all the tests? (True means it failed here)
    result.pass = !Object.keys(result.groupResult).some((k) => !result.groupResult[k].groupPass);

    // passed.
    if (!result.pass) {
      if (this.dbStatus === DbStatus.Initial || this.dbStatus === DbStatus.ReleasedMod) {
        result.message = 'Admin approval is pending!';
      }
    } else {
      result.message = 'More information is required!';
    }

    return result;
  }
  getValDbStatusToApprove(): IValDbStatusResult {


    let result: IValDbStatusResult = {
      pass: false,
      message: undefined,
      groupResult: {}
    };
    result = this.getValDbStatus();
    if (!result.pass) {
      return result;
    }
    let x: { groupPass: boolean; message: string; };
    const g: CompanyValidationGroup[] = [creditPackage];

    let r = this.validateSync({ groups: g });
    x = { groupPass: null, message: '' };
    x.groupPass = Object.keys(r).length === 0;
    x.message = (x.groupPass) ? 'Manage Required Documents' : 'Credit package is required'; //  `${Object.values(r)[0]}`
    result.groupResult[creditPackage] = x;

    result.pass = !Object.keys(result.groupResult).some((k) => !result.groupResult[k].groupPass);
    // passed.
    if (!result.pass) {

      result.message = 'Upload Credit Package';

    }

    return result;
  }
  validateSetLesseeLessor(options?: ValidatorOptions) {
    const r = super.validateSyncBase(this, options);
    // if (this.isDraft) {
    //   r['isLessee'] = ['Cannot change status for company in draft state'];
    // }
    // if (this.hasDraft) {
    //   r['isLessee'] = ['Cannot change status for company which has draft state'];
    // }
    if (this.dbStatus < DbStatus.Released && this.lesseeStatus === CompanyStatus.approved) {
      r['lesseeStatus'] = ['Cannot change status for company which is not in released state'];
    }
    if (this.dbStatus < DbStatus.Released && this.lessorStatus === CompanyStatus.approved) {
      r['lessorStatus'] = ['Cannot change status for company which is not in released state'];
    }
    return r;
  }
}
