import { ReValidateComponent } from './../app/account/re-validate/re-validate.component';
import { BaseForm } from '@trent/models/UI/base-form';
import { Store } from '@ngxs/store';
import { UserReValidate } from '@trent/models/user/user-re-validate';
import { SiteData } from '@trent/models/site-data';
import { MatDialog } from '@angular/material/dialog';
import { promiseWraper } from '@trent/models/utility';
import { DialogService } from '@trent/services/dialog/dialog.service';
import { EventService } from '@trent/services/event.service';
import { Directive } from "@angular/core";

/**
 * Extended Form that uses Re-Auth while changing the senstive data.
 */
@Directive()
export class BaseFormAuth<T> extends BaseForm<T> {


  constructor(public dialog: MatDialog, store: Store, ds: DialogService, eventService?: EventService, ) {
    super(store, ds, eventService);
  }


  /** Call Reauthorization if required. See SiteData class for the implementation logic */
  reAuth() {
    return new Promise(async (resolve, reject) => {
      if (!SiteData.isReAuthReqd) {
        return resolve(true);
      }

      const data: UserReValidate = {
        runValidation: true, email: '',
        password: '', credential: null, result: false
      };

      const dialogRef = this.dialog.open(ReValidateComponent, {
        width: '350px',
        data: data
      });

      const results = await promiseWraper(dialogRef.afterClosed().toPromise());

      if (results.success && !!results.data && !!results.data.result) {
        resolve(true);
      } else {
        reject(results.error);
      }

    });

  }

}
