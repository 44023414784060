import { Injectable, NgZone } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { DialogComponent } from '../../../projects/locus-loop/src/app/shared/dialog/dialog.component';
import { MessageInfo, DialogData, DialogLocType } from '@trent/models/error-handling';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MatBottomSheet, MatBottomSheetConfig } from '@angular/material/bottom-sheet';
import { UtilityService } from '../utility.service';
import { logger } from '@trentm/log/logger';


@Injectable()
export class DialogService {
  isHandSet = false;
  constructor(private us: UtilityService, private dialog: MatDialog, private snack: MatSnackBar, private bs: MatBottomSheet, private zone: NgZone) {
    this.us.isHandset$.subscribe(h => this.isHandSet = h);
  }

  private alertHandles: MatDialogRef<any, any>[] = [];

  closeAlert() {
    try {
      while (this.alertHandles.length > 0) {
        this.alertHandles.pop().close();
      }
    } catch (error) {
      logger.error('[dialog] service close alert failed', error);
    }
  }

  closeSnack() {
    try {
      this.snack.dismiss();
    } catch (error) {
      logger.error('[dialog] service close snackbar failed', error);
    }
  }

  alert<TData, TComp>(msg: TData, config?: MatDialogConfig, callbackFn?: (result) => void, dialogBodyComp?: TComp): void {

    const data: DialogData<TData> = {
      data: msg,
      boxType: 'alert'
    };
    const dcomp = (!!dialogBodyComp) ? dialogBodyComp : DialogComponent;
    const opt: MatDialogConfig = (!!config) ? config : {};
    opt.width = (!!opt.width) ? opt.width : '350px';
    opt.data = data;

    const dialogRef = this.dialog.open(dcomp as any, opt);
    this.alertHandles.push(dialogRef);

    if (typeof callbackFn === 'function') {
      dialogRef.afterClosed()
        .toPromise()
        .then(result => callbackFn(result))
        .catch(err => logger.error(err));
    }
  }

  bottomSheet<TData, TComp>(msg: TData, config?: MatBottomSheetConfig, callbackFn?: (result) => void, dialogBodyComp?: TComp): void {
    const data: DialogData<TData> = {
      data: msg,
      boxType: 'alert'
    };
    const dComp = (!!dialogBodyComp) ? dialogBodyComp : DialogComponent;
    // const opt: MatBottomSheetConfig = (!!config) ? config : ;

    // opt.width = (!!opt.width) ? opt.width : '350px';
    // opt.data = data;

    const bsRef = this.bs.open(dComp as any, config);
    // this.alertHandles.push(dialogRef);

    if (typeof callbackFn === 'function') {
      bsRef.afterDismissed()
        .toPromise()
        .then(result => callbackFn(result))
        .catch(err => logger.error(err));
    }
  }

  confirm<TData, TComp>(msg: TData, config?: MatDialogConfig, callbackFn?: (result) => void, dialogBodyComp?: TComp): void {
    const data: DialogData<TData> = {
      data: msg,
      boxType: 'confirm'
    };
    const opt: MatDialogConfig = (!!config) ? config : {};
    opt.width = (!!opt.width) ? opt.width : '350px';
    // opt.height = (!!opt.height) ? opt.height : '350px';
    opt.data = data;
    const dComp = (!!dialogBodyComp) ? dialogBodyComp : DialogComponent;

    const dialogRef = this.dialog.open(dComp as any, opt);

    if (typeof callbackFn === 'function') {
      dialogRef.afterClosed()
        .toPromise()
        .then(result => callbackFn(result))
        .catch(err => logger.error(err));
    }
  }

  async confirmP<TData, TComp>(msg: TData, config?: MatDialogConfig, dialogBodyComp?: TComp): Promise<boolean> {
    return new Promise<boolean>(async (resolve, reject) => {
      const data: DialogData<TData> = {
        data: msg,
        boxType: 'confirm'
      };
      const opt: MatDialogConfig = (!!config) ? config : {};
      opt.width = (!!opt.width) ? opt.width : '350px';
      opt.data = data;
      const dComp = (!!dialogBodyComp) ? dialogBodyComp : DialogComponent;

      const dialogRef = this.dialog.open(dComp as any, opt);
      dialogRef.afterClosed()
        .toPromise()
        .then(result => resolve(result))
        .catch(err => reject(err));
    });
  }


  openSnackBar(msg: MessageInfo, config?: MatSnackBarConfig) {
    this.zone.run(() => {
      const opt: MatSnackBarConfig = {
        politeness: 'polite',
        duration: 10000,
        verticalPosition: 'bottom',
        panelClass: (!!msg.msgCss) ? `snack-${msg.msgCss}` : undefined,
        ...config
      };
      this.snack.open(msg.description, 'ok', opt);
    });
  }

  getFullScreenConfig(isHandset?: boolean, preset: DialogLocType = 'info-right'): MatDialogConfig {
    const h = (isHandset == null) ? this.isHandSet : isHandset;
    switch (preset) {
      case 'center-video':
        if (h) {
          return {
            // width: 'auto', // '80vw',
            // height: 'auto', //  '80vh',
            // minWidth: '60vw',
            // minHeight: '60vh',
            width: '100vw',
            maxWidth: '100vw',
            maxHeight: '100vh',
            hasBackdrop: true,
            panelClass: preset + '-mobile',
            disableClose: true
          };
        }
        return {
          // width: 'auto', // '80vw',
          // height: 'auto', //  '80vh',
          minWidth: '60vw',
          minHeight: '60vh',
          // maxWidth: '90vw',
          // maxHeight: '90vh',
          hasBackdrop: true,
          panelClass: preset,
          disableClose: true
        };
      case 'info-center':
        if (h) {
          return {
            width: '90vw',
            // height: '100vh',
            // maxWidth: '100vw',
            // maxHeight: '100vh',
            hasBackdrop: false
          };

        }
        return {
          width: '400px',
          hasBackdrop: false

        };
      case 'info-right':
      default:
        if (h) {
          return {
            width: '100vw',
            height: '100vh',
            maxWidth: '100vw',
            maxHeight: '100vh',
            hasBackdrop: false
          };
        }
        return {
          width: '50vw',
          height: '100vh',
          maxWidth: '50vw',
          maxHeight: '100vh',
          hasBackdrop: false,
          position: { right: '0' }
        };
    }
  }
  /** Open Help/info page */
  openInfoPage<TComp>(d: { url: string, styleInfo?: DialogLocType }, comp: TComp, callbackFn?: (result) => void) {
    if (this.dialog.openDialogs.length > 0) {
      // logger.log('[dialog-service] dialog already open', this.dialog.openDialogs);
      this.dialog.closeAll();
    }
    const config = this.getFullScreenConfig(null, d.styleInfo);
    const data: DialogData<string> = {
      data: d.url,
      boxType: d.styleInfo
    };
    config.data = data;
    const dialogRef = this.dialog.open(comp as any, config);
    this.alertHandles.push(dialogRef);

    if (typeof callbackFn === 'function') {
      dialogRef.afterClosed()
        .toPromise()
        .then(result => callbackFn(result))
        .catch(err => logger.error(err));
    }
  }
}
