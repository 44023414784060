import { PtsHomeImage } from "../pts/pts-home-image";
import { PtsMiddleSection } from "../pts/pts-middle-section";
import { PageHtml, PageHtmlType } from "./page-html";
import { DpcHome } from "../dpc/dpc-home";
import { ContractHtml } from "./contract-html";
import { TprHomePageHtml } from "./tpr-home-page-html";
import { TprContactPageHtml } from "./tpr-contact-page-html";
import { TprRentOptionListPageHtml } from "./tpr-rent-option-list-page-html";
import { TprBidDetailPageHtml } from "./tpr-bid-detail-page-html";
import { PtsLocationImage } from "../pts/pts-location-image";
import { PtsSalesOptionDetailPageHtml } from "./pts-sales-option-detail-page-html";
import { PtsAboutUsPageHtml } from "./pts-about-us-page-html";
import { PtsContactUsPageHtml } from "./pts-contact-us-page-html";
import { PtsHomePageHtml } from "./pts-home-page-html";
import { PtsInventoryListPageHtml } from "./pts-inventory-list-html";


export const parseCms = (obj: any): PageHtml => {
  if (obj == null) { return null; }

  const r = <PageHtml>obj; // cast it to base, remember it is still a javascript obj at run time.
  switch (r.pageHtmlType) {
    case PageHtmlType.ptsHomeMiddleSection:
      return PageHtml.parse(r, PtsMiddleSection); //  PtsMiddleSection.parse(r);
    case PageHtmlType.ptsHomeCarousel:
      return PageHtml.parse(r, PtsHomeImage); //  PtsHomeImage.parse(r);
    case PageHtmlType.ptsLocation:
      return PageHtml.parse(r, PtsLocationImage);
    case PageHtmlType.dpcHome:
      return PageHtml.parse(r, DpcHome); //  DpcHome.parse(r);
    case PageHtmlType.contract:
      return PageHtml.parse(r, ContractHtml); //  PtsMiddleSection.parse(r);
    default:
      return PageHtml.parse(r);
  }
};
/**
 * 
 * @param obj 
 * @returns PageHtml []
 */
export const parseCmsArray = (obj: any[]): PageHtml[] => {
  const r = !!obj ? obj.map(o => <PageHtml>parseCms(o)) : null;
  return r;
};

export function getPageHtmlClazz(template: PageHtmlType) {
  switch (+template) {
    case PageHtmlType.pageHtml:
      return PageHtml;
    case PageHtmlType.contract:
      return ContractHtml;
    case PageHtmlType.taskMessaging:
      return PageHtml; // ?? TaskMessagingPageHtml;
    case PageHtmlType.ptsHomeReviews:
      return PageHtml; // ??  PtsHomeReviewsPageHtml;
    case PageHtmlType.ptsHomeCarousel:
      return PtsHomeImage;
    case PageHtmlType.ptsHomeMiddleSection:
      return PtsMiddleSection;
    case PageHtmlType.ptsWelcome:
      return PageHtml; // ?? PtsWelcomePageHtml;
    case PageHtmlType.dpcHome:
      return DpcHome;
    case PageHtmlType.tprHome:
      return TprHomePageHtml;
    case PageHtmlType.tprContact:
      return TprContactPageHtml;
    case PageHtmlType.tprRentOptionList:
      return TprRentOptionListPageHtml;
    case PageHtmlType.tprBidDetail:
      return TprBidDetailPageHtml;
    case PageHtmlType.ptsSalesOptionDetail:
      return PtsSalesOptionDetailPageHtml;
    case PageHtmlType.ptsHome:
      return PtsHomePageHtml;
    case PageHtmlType.ptsContactUs:
      return PtsContactUsPageHtml;
    case PageHtmlType.ptsAboutUs:
      return PtsAboutUsPageHtml;
    case PageHtmlType.ptsInventoryList:
      return PtsInventoryListPageHtml;
    default:
      return PageHtml;
  }
}
