import { ICameraPicData } from './icamera-data';
import { Injectable } from '@angular/core';
import { Camera, CameraOptions } from '@awesome-cordova-plugins/camera/ngx';
import { Platform } from '@ionic/angular';
import { promiseWraper } from '@trent/models/utility';
import { logger } from '@trentm/log/logger';


@Injectable()
export class CameraCdvaService {

  option: CameraOptions;

  constructor(private camera: Camera, private platform: Platform) {

  }

  get isCameraAvailable() { return true; }

  get isCapacitor() { return false; }
  
  get isCdva() { return true; }
  /**
   * 
   * @param o Ensure that returned url is prefixed if destinationtype of the option is data_url. 
   * 'data:image/jpeg;base64,' + result output of this function call.;
   * 
   */
  async takePicture(o: CameraOptions): Promise<ICameraPicData> {
    let options: CameraOptions = {
      quality: 50,
      destinationType: this.camera.DestinationType.FILE_URI,
      encodingType: this.camera.EncodingType.JPEG,
      mediaType: this.camera.MediaType.PICTURE,
      correctOrientation: true
    };

    options = {
      ...options,
      ...o
    };

    /** Android can not have native url */
    if (this.platform.is('android') &&
      options.destinationType === this.camera.DestinationType.NATIVE_URI) {
      options.destinationType = this.camera.DestinationType.FILE_URI;
    }

    /** ios can not have FileURI */
    if (
      (this.platform.is('ios') || this.platform.is('ipad') || this.platform.is('iphone'))
      &&
      options.destinationType === this.camera.DestinationType.FILE_URI
    ) {
      options.destinationType = this.camera.DestinationType.NATIVE_URI;
    }

    const urlD = await promiseWraper(this.camera.getPicture(options));
    if (!urlD.success) {
      logger.log('[camera-cdva] Camera take pic error occoured', urlD.error);
      throw Error(urlD.error);
    }

    return {
      path: urlD.data,
      webPath: urlD.data
    };



    // this.camera.getPicture(options).then((imageData) => {
    //   // imageData is either a base64 encoded string or a file URI
    //   // If it's base64 (DATA_URL):
    //   const base64Image = 'data:image/jpeg;base64,' + imageData;

    //   logger.log('Image URL is: ', imageData);
    //   return imageData;

    // }, (err) => {
    //   // Handle error
    //   logger.error('Camera Error: ', err);

    // });

  }
}
