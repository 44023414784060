import { KeyValueOrder } from './../../../../../../libs/custom-pipe/key-value-order';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CkEditorCtrlComponent } from './ck-editor-ctrl.component';
import { CkInlineDirective } from './ck-inline.directive';
import { MaterialModule } from '../share-module/material.module';
import { FormsModule } from '@angular/forms';
import {MatSelectModule} from '@angular/material/select';
import { CustomPipeModule } from '@trent/custom-pipe/custom-pipe.module';
import { FileUploadModule } from '../file-upload/file-upload.module';
import { MatDatepickerModule } from '@angular/material/datepicker';



@NgModule({
  declarations: [
    CkInlineDirective,
    CkEditorCtrlComponent,
    KeyValueOrder
  ],
  imports: [
    CommonModule,
    MaterialModule,
    MatCheckboxModule,
    MatDatepickerModule,
    FormsModule,
    MatSelectModule,
    CustomPipeModule,
    FileUploadModule

  ],
  exports: [
    CkInlineDirective,
    CkEditorCtrlComponent
  ]
})
export class CkEditorModule { }
