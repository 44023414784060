import { routesBase, setSiteRoutes } from '../ws-common/app-routing-base-data';
import { CustomPreloadingStrategy } from '@trent/core/router-preload';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotfoundComponent } from '../notfound/notfound.component';
import { SubPageComponent } from '../shared/sub-page/sub-page.component';
import { FormDisplayMode } from '@trent/models/UI/form-display-mode';


const routeAllPts: Routes = [
  {
    path: '', 
    loadChildren: () => import('../dpc/dpc.module').then(m => m.DpcModule),
  },

  ...routesBase,
  /** ADD Dpc UNIQUE ROUTES - START. */
  {
    path: 'privacy-policy', component: SubPageComponent, data: { mode: FormDisplayMode.read, pid: 'dpc-privacy-policy' }
  },


  /** ADD Dpc UNIQUE ROUTES - END. */
  /** THIS SHOULD BE LAST as not found */

  { path: '**', component: NotfoundComponent }
];

function getRoutes() {
  setSiteRoutes(routeAllPts);
  return routeAllPts;
}

@NgModule({
  imports: [RouterModule.forRoot(getRoutes(), {
    preloadingStrategy: CustomPreloadingStrategy,
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled',
    // relativeLinkResolution: 'legacy'
  })],
  exports: [RouterModule]
})
export class AppDpcRoutingModule { }
