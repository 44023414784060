import { Component, Input, OnInit } from '@angular/core';
import { SparePart } from '@trent/models/spare-part/spare-part';
import { MessageInfo } from '@trent/models/error-handling';
import { logger } from '@trent/models/log/logger';
import { ShareOn, SocialShareComponent } from '../../shared/social-share/social-share.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Router } from '@angular/router';
import { UtilityService } from '@trent/services/utility.service';
import { AuthState } from '@trent/store/auth-store/auth.state';
import { Store } from '@ngxs/store';
import { webSiteUrl } from '@trent/core/environments/fb-env';

@Component({
  selector: 'trent-spare-parts-card',
  templateUrl: './spare-parts-card.component.html',
  styleUrls: ['../dpc-common.scss']
})
export class SparePartsCardComponent implements OnInit {

  @Input() item: SparePart;
  isPriceShow: boolean;

  constructor(
    private _bottomSheet: MatBottomSheet,
    private router: Router,
    private us: UtilityService,
    public store: Store,
  ) { }

  ngOnInit(): void {
    /// Here checking person is SalesOperationDpc/admin person
    this.store.select(AuthState.user).subscribe(u => {
      this.isPriceShow = u?.roles?.salesOperationsDpc || u?.roles?.admin || u?.roles?.editor ? true : false;
    });
  }

  /** PT
   * description - share the spare parts with the user
   * @param id - spare part id
   */
  share(id: string | number) {
    // let url: string = `${webSiteUrl.production}/catalog/${id}`;
    let url: string = `https://dixiepartscentre.com/catalog/${id}`;

    const desc = `Details for spare part ${this.item.name}`;
    logger.log(this.item);
    if (!!(<any>navigator) && (<any>navigator).share) {
      (<any>navigator)
        .share({
          title: 'Spare Part',
          text: desc,
          url,
        })
        .then(() => {
          logger.log('Thanks for sharing!');
        })
        .catch((err) => {
          logger.log("Couldn't share because of", err.message);
        });
    } else {
      logger.log('web share not supported');
      // fall back
      const msg = new MessageInfo({
        msgCss: 'primary',
        header: desc,
        description: url,
      });
      this._bottomSheet.open(SocialShareComponent, {
        data: {
          title: 'Spare Part',
          text: desc,
          url,
          shareOn: [ShareOn.email, ShareOn.whatsapp],
        },
      });
    }
  }

  /**
   * @author KS
   * @purpose Redirect to details page
   * @param id 
   */
  viewDetails(id: string | number) {
    this.router.navigate(['/', 'catalog', 'details', id]);
  }

  routeM(e, id: string | number) {
    if (!this.us.isHandset) {
      this.router.navigate(['/', 'catalog', 'details', id]);
    } else {
      e.stopPropagation();
    }
  }
}
