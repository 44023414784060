<form #form="ngForm" class="p-rel" *ngIf="!isPhoneVerificationRequired">
    <ng-container >
        <p *ngIf="!isLoggedIn">Please provide Contact information to go as Guest User</p>
        <!-- <p class="p-tag" *ngIf="productDetails?.typeName">Subject: Enquiry about <strong>{{productDetails?.typeName}} of {{productDetails.unitName}}</strong></p>
        <p class="p-tag">Dear Pride Team,</p>
        <p class="p-tag">Please send me more details about
            <span *ngIf="productDetails?.productType;else noProduct;">the inventory of {{productDetails.productType === 1 ? 'Trailer' : 'Truck'}}</span>.
            <ng-template #noProduct>
                Product
            </ng-template>
        </p>
        <p class="p-tag">Request you to revert within 2 days with more detail, so that I can approach you for further process. </p> -->
    </ng-container>

    <ng-container>
        <mat-form-field class="full-width">
            <mat-label>Phone Number</mat-label>
            <input [disabled]='isReadOnly' matInput [(ngModel)]="em.phoneNumber" type="text" name="phoneNumber"
                #phoneNumber="ngModel" phone />
             <mat-hint align="end">Optional</mat-hint>
            <!--    <mat-error align='end' *ngIf="showError(phoneNumber)">{{phoneNumber.errors.msg}}</mat-error> -->
        </mat-form-field>

        <mat-form-field class="full-width top-10" *ngIf="!(isLoggedIn || guest)">
            <mat-label>Email</mat-label>
            <input matInput [(ngModel)]="em.userDetails.email" type="text" name="email" #email="ngModel" />
            <span matPrefix class='icon'>
                <mat-icon>email</mat-icon>
            </span>
        </mat-form-field>
        <button *ngIf="!(isLoggedIn || guest)" (click)="onGuest()" class="btn-2 w-190p"
            [disabled]="!(em.phoneNumber || em.userDetails.email)">Guest user</button>

    </ng-container>

    <ng-container *ngIf="(guest || isLoggedIn)">

        <div *ngIf="!!(phoneNo && isLoggedIn)" style="position: absolute;top: 0;right: 0;">
            <a href="tel:{{phoneNo}}" class="phone-icon">
                <mat-icon>phone_enabled</mat-icon>
            </a>
        </div>
        <mat-checkbox *ngIf="!isReadOnly && isLoggedIn" [(ngModel)]="em.isPhoneNumberSavedInUserProfile"
            name="isPhoneNumberSavedInUserProfile">Set the phone number</mat-checkbox>

        <mat-form-field class="full-width" *ngIf="isLoggedIn">
            <mat-label>Alt Phone Number</mat-label>
            <input matInput type="text" phone name="altPhoneNumber" [(ngModel)]="em.altPhoneNumber"
                #altPhoneNumber="ngModel">
            <mat-hint *ngIf="!showError(altPhoneNumber)" align="end">Optional</mat-hint>
            <!-- <mat-error align='end' *ngIf="showError(altPhoneNumber)">{{altPhoneNumber.errors.msg}}</mat-error> -->
        </mat-form-field>

        <mat-form-field class="full-width">
            <mat-label>Please enter your Specific query / info you looking for</mat-label>
            <textarea matInput type="text" name="description" [(ngModel)]="em.description" #description="ngModel"
                cols="35" rows="3"></textarea>
            <mat-hint *ngIf="!showError(description)" align="end">Required</mat-hint>
            <mat-error align='end' *ngIf="showError(description)">{{description.errors.msg}}</mat-error>
        </mat-form-field>
        <div style="display:flex; align-items: center; margin-bottom: 24px;" *ngIf="isLoggedin">
            <mat-checkbox [(ngModel)]="em.isSubscribe" matInput name="isSubscribe"
                #isSubscribe="ngModel"></mat-checkbox>
            <p style="margin-top:0;margin-bottom:0;margin-left: 5px;font-size: 14px;">I agree to receive periodical
                offers, newsletter, safety and recall updates from the dealership. Consent
                can be withdrawn at any time.</p>
        </div>
        <ng-container>
            <p class="p-tag">Regards,</p>
            <p class="p-tag">{{ em?.userDetails?.displayName ? em.userDetails.displayName : 'Guest user'}}</p>
            <p class="p-tag" *ngIf="em?.phoneNumber">Contact - {{ (em.phoneNumber && isReadOnly) ? em.phoneNumber : phoneNumber.value | slice:0:14}}</p>
            <p class="p-tag" *ngIf="em?.userDetails?.email">Email - {{em.userDetails.email}}</p>
        </ng-container>
        <button class="btn-2" (click)="submit()">Send</button>

    </ng-container>


    <!-- <p style="font-size: 14px;">By submitting this information, you are accepting that, it may be collected, used and disclose by described in
        our privacy policy.</p> -->
</form>
<trent-phone-change *ngIf="isPhoneVerificationRequired" [(phoneNumberParam)]="em.phoneNumber" (cancelEvt)="cancel()"
    (phoneChanged)="phoneChanged()" #ph> </trent-phone-change>