
import { State, Selector, Action, StateContext } from '@ngxs/store';
import { StateBase } from '../state-base';
import * as entity from '../entity.state';
import { noop, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { Paging, getObjKey } from '@trent/models/observable-util/paging';
import { IDataLoadStatus, buildDataRequest, LoadStatus, getRootLevelChildren, updatePaging } from '@trent/models/observable-util/data-status';
import { PagingContainer } from '@trent/models/observable-util/paging-container';
import { Injectable } from '@angular/core';
import { logger } from '@trent/models/log/logger';
import { getNetSuiteProductSyncLogsOptOrChildren, INetSuiteProductSyncLogsParam, netSuiteProductSyncLogsSearchClientFilter } from '@trent/models/netSuite/netSuite-product-sync-logs-param';
import { NetSuiteProductSyncLogs } from '@trent/models/netSuite/netsuite-product-sync-logs';
import { NetSuiteProductSyncLogsService } from '@trent/services/netsuite-product-sync-logs.service';

// #region State Model

export interface NetSuiteProductSyncLogsStateModel {
  options?: INetSuiteProductSyncLogsParam;
  paging: Paging;
  allNetSuiteProductSyncLogsLoaded: boolean;
  netSuiteProductSyncLogsByCompIdLoaded: boolean;
  allNetSuiteProductSyncLogsLoadStatus: {
    [key: string]: IDataLoadStatus<INetSuiteProductSyncLogsParam>;
  };
  netSuiteProductSyncLogs: { [id: string]: NetSuiteProductSyncLogs };
}

function initNetSuiteProductSyncLogsState() {
  return {
    options: null,
    paging: { size: 10, offset: 0, full: false },
    allNetSuiteProductSyncLogsLoaded: false,
    netSuiteProductSyncLogsByCompIdLoaded: false,
    allNetSuiteProductSyncLogsLoadStatus: {},
    netSuiteProductSyncLogs: {},
  };
}
// #endregion

// #region actions
export class AllNetSuiteProductSyncLogsRequested {
  static readonly type = '[NetSuiteProductSyncLogs] All NetSuiteProductSyncLogs Requested';
  constructor(public payload: { pData: Paging, param: INetSuiteProductSyncLogsParam }) { }
}
export class AllNetSuiteProductSyncLogsLoaded {
  static readonly type = '[NetSuiteProductSyncLogs] All NetSuiteProductSyncLogs Loaded';
  constructor(public payload: {
    data: { [id: string]: NetSuiteProductSyncLogs }, // Data
    key: string,
  }) { }
}
export class AllNetSuiteProductSyncLogsNextPageRequested {
  static readonly type = '[NetSuiteProductSyncLogs] All NetSuiteProductSyncLogs Requested - Next Page';
  constructor(public payload: { option: INetSuiteProductSyncLogsParam }) { }
}

export class NetSuiteProductSyncLogsByIdRequested {
  static readonly type = '[NetSuiteProductSyncLogs] Get NetSuiteProductSyncLogs by id - requested ( initial page)';
  constructor(public payload: { id: string }) { }
}

export class NetSuiteProductSyncLogsByIdLoaded {
  static readonly type = '[NetSuiteProductSyncLogs] Get NetSuiteProductSyncLogs by id - loaded';
  constructor(public payload: { id: string, data: NetSuiteProductSyncLogs }) { }
}

export class NetSuiteProductSyncLogsRemoveById {
  static readonly type = '[NetSuiteProductSyncLogs] Remove by Id';
  constructor(public payload: { id: string | number }) { }
}

export class NetSuiteProductSyncLogsStateReset {
  static readonly type = '[NetSuiteProductSyncLogs] Reset State';
}

// #endregion


@State<NetSuiteProductSyncLogsStateModel>({
  name: 'netSuiteProductSyncLogs',
  defaults: initNetSuiteProductSyncLogsState()
})
@Injectable()
export class NetSuiteProductSyncLogsState extends StateBase {

  allNetSuiteProductSyncLogsReqSub: Subscription;
  /** Container that keep all of the subscription related to getting allNetSuiteProductSyncLogs. */
  allNetSuiteProductSyncLogsSubData: PagingContainer<NetSuiteProductSyncLogs, INetSuiteProductSyncLogsParam> = new PagingContainer();

  constructor(private ps: NetSuiteProductSyncLogsService) {
    super();
  }


  // #region Static Selectors
  @Selector()
  static selectAllNetSuiteProductSyncLogs(state: NetSuiteProductSyncLogsStateModel) {
    return (o: INetSuiteProductSyncLogsParam): NetSuiteProductSyncLogs[] => {

      if (state.netSuiteProductSyncLogs == null || Object.keys(state.netSuiteProductSyncLogs).length === 0) {
        return [];
      }

      // remove keys that have revision/draft ids, i.e that contain '~' in the id.
      const keys = Object.keys(state.netSuiteProductSyncLogs).filter(k =>
        k.indexOf('/') === -1 && state.netSuiteProductSyncLogs[k] != null);

      // object without the rev/draft.
      const filtered = keys.reduce((obj, k) => {
        obj[k] = state.netSuiteProductSyncLogs[k];
        return obj;
      }, {});

      let output = Object.values(filtered).map(x => NetSuiteProductSyncLogs.parse(x));
      output = netSuiteProductSyncLogsSearchClientFilter(output, o);

      return output;
      // return entity.map(state.products, parseProduct);
    };
  }
  /** Is the Paging Full for this search. */
  @Selector()
  static selectAllNetSuiteProductSyncLogsFull(state: NetSuiteProductSyncLogsStateModel) {
    return (o: INetSuiteProductSyncLogsParam): boolean => {
      const oKey = getObjKey(o);
      const r = state.allNetSuiteProductSyncLogsLoadStatus && state.allNetSuiteProductSyncLogsLoadStatus[oKey];
      return (!!r) ? r.pData.full : true;
    };
  }
  @Selector()
  static selectNetSuiteProductSyncLogsById(state: NetSuiteProductSyncLogsStateModel) {
    // new approach, use dictionary of company.
    return entity.getByIdFn_new(state.netSuiteProductSyncLogs, NetSuiteProductSyncLogs.parse);
  }
  // #endregion
  
  // #region Custom Functions and subscriptions

  public clearSubscriptions() {
    if (!!this.allNetSuiteProductSyncLogsReqSub) {
      this.allNetSuiteProductSyncLogsReqSub.unsubscribe();
      this.allNetSuiteProductSyncLogsReqSub = null;
    }
    this.allNetSuiteProductSyncLogsSubData.unsubscribeAll();
    super.clearSubscriptions();
  }

  @Action(NetSuiteProductSyncLogsStateReset)
  NetSuiteProductSyncLogsStateReset(context: StateContext<NetSuiteProductSyncLogsStateModel>, action: NetSuiteProductSyncLogsStateReset) {
    // unsubscribe the data
    console.log('NetSuiteProductSyncLogs action called');
    this.clearSubscriptions();
    context.setState(initNetSuiteProductSyncLogsState());
  }

  // #endregion

  // #region actions
  @Action(AllNetSuiteProductSyncLogsRequested)
  allNetSuiteProductSyncLogsRequested(context: StateContext<NetSuiteProductSyncLogsStateModel>, action: AllNetSuiteProductSyncLogsRequested) {

    const oKey = getObjKey(action.payload.param);

    // Define function that return the data status object from the state.
    const getDataStatusStateFn = () => context.getState().allNetSuiteProductSyncLogsLoadStatus;

    /** custom build the OR children query. */
    const buildOrQueryChildrenFn = (o: INetSuiteProductSyncLogsParam) => getNetSuiteProductSyncLogsOptOrChildren(o);

    // if data requested now, is already partially loaded by another query's child previously
    // but the number of the items are not enough (ex. as a child, it loaded only 5, but current
    // request ask for more, then next page of that query should be called instead.)
    const nextPageFn = (option: INetSuiteProductSyncLogsParam) => {
      context.dispatch(new AllNetSuiteProductSyncLogsNextPageRequested({ option }));
    };

    buildDataRequest(
      oKey, action.payload.param, action.payload.pData,
      getDataStatusStateFn,
      buildOrQueryChildrenFn,
      nextPageFn,
      (
        obj: { [key: string]: IDataLoadStatus<INetSuiteProductSyncLogsParam> },
        set: { key: string, node: IDataLoadStatus<INetSuiteProductSyncLogsParam> }[]
      ) => {

        if (!!obj) {
          // Patch the state.
          const state = context.getState();
          context.patchState({
            allNetSuiteProductSyncLogsLoadStatus: { ...state.allNetSuiteProductSyncLogsLoadStatus, ...obj }
          });
        }

        // Process the query.
        set.forEach((val) => {
          // some of the nodes are already loaded. Only process that are loading... status.
          if (val.node.loadStatus !== LoadStatus.Loading) {
            return;
          }
          // if this request is just overwriting a stall or pending request, unsubscribe that observable
          this.allNetSuiteProductSyncLogsSubData.unsubscribe(val.key);

          // create the paging observable and call db.
          const p = this.ps.getAllNetSuiteProductSyncLogs1_PagingObservable();
          const prod$ = p.getData(action.payload.pData, val.node.param)
            .pipe(
              map(netSuiteProductSyncLogsData => {
                context.dispatch(new AllNetSuiteProductSyncLogsLoaded({
                  data: netSuiteProductSyncLogsData,
                  key: val.key
                }));
                return netSuiteProductSyncLogsData;
              }));
          const sub = this.subscribe(prod$, () => noop(), AllNetSuiteProductSyncLogsRequested.type);
          // save the observable call
          this.allNetSuiteProductSyncLogsSubData.addData(val.key, sub, p);
        });
      }
    );
  }
  @Action(AllNetSuiteProductSyncLogsNextPageRequested)
  allNetSuiteProductSyncLogssNextPageRequested(context: StateContext<NetSuiteProductSyncLogsStateModel>, action: AllNetSuiteProductSyncLogsNextPageRequested) {
    const oKey = getObjKey(action.payload.option);
    const state = context.getState();
    // find the node. can be parent or child
    const statusObj = state.allNetSuiteProductSyncLogsLoadStatus[oKey];
    // if no parent, treat is
    if (statusObj.children == null) {
      this.allNetSuiteProductSyncLogsSubData.dispatchNextPagingUpdate(oKey);
    } else {
      const children = getRootLevelChildren(oKey, state.allNetSuiteProductSyncLogsLoadStatus);
      children.forEach(c => {
        this.allNetSuiteProductSyncLogsSubData.dispatchNextPagingUpdate(c.key);
      });
    }
  }
  @Action(AllNetSuiteProductSyncLogsLoaded)
  allNetSuiteProductSyncLogsLoaded(context: StateContext<NetSuiteProductSyncLogsStateModel>, action: AllNetSuiteProductSyncLogsLoaded) {
    const state = context.getState();
    const subData = this.allNetSuiteProductSyncLogsSubData.getData(action.payload.key);
    const updatedLoadStatus = updatePaging(action.payload.key, state.allNetSuiteProductSyncLogsLoadStatus, subData);
    context.patchState({
      allNetSuiteProductSyncLogsLoaded: true,
      allNetSuiteProductSyncLogsLoadStatus: updatedLoadStatus,
      netSuiteProductSyncLogs: { ...state.netSuiteProductSyncLogs, ...action.payload.data }// entity.addMany(state.products, action.payload.products)
    });
  }
  
  @Action(NetSuiteProductSyncLogsByIdRequested)
  netSuiteProductSyncLogsByIdRequested(context: StateContext<NetSuiteProductSyncLogsStateModel>, action: NetSuiteProductSyncLogsByIdRequested) {
    const state = context.getState();
    const id = action.payload.id;
    if (state.netSuiteProductSyncLogs[action.payload.id] === undefined) {
      // console.log('comp by id, not found in the store, gettting from server....');
      const s = this.ps.getNetSuiteProductSyncLogsById(action.payload.id)
        .pipe(
          map(data => {
            return context.dispatch(new NetSuiteProductSyncLogsByIdLoaded({ id, data }));
          }
          )); // .subscribe(noop);
      this.subscribe(s, (x) => noop(), NetSuiteProductSyncLogsByIdRequested.type);
    }
  }
  @Action(NetSuiteProductSyncLogsByIdLoaded)
  netSuiteProductSyncLogsByIdLoaded(context: StateContext<NetSuiteProductSyncLogsStateModel>, action: NetSuiteProductSyncLogsByIdLoaded) {
    const state = context.getState();
    context.patchState({
      netSuiteProductSyncLogs: {
        ...state.netSuiteProductSyncLogs,
        ...{ [action.payload.id]: action.payload.data }
      }
    });
  }
 
 
}
