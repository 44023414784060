import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { logger } from '@trent/models/log/logger';
import { StoreLocation } from '@trent/models/store-location/store-location';

@Component({
	selector: 'trent-footer-location-selector',
	templateUrl: './footer-location-selector.component.html',
	styleUrls: ['../footer-styles.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class FooterLocationSelectorComponent implements OnInit {

	@Input() stores: StoreLocation[];
	@Input() selectedStore: StoreLocation;
	@ViewChild('form', { static: false }) form: NgForm;
	@Output() _store: EventEmitter<StoreLocation> = new EventEmitter<StoreLocation>();
	@Input() hideShow: boolean;
	timings = [];
	presentDays = [];
	days = [
		'monday',
		'tuesday',
		'wednesday',
		'thursday',
		'friday',
		'saturday',
		'sunday',
	];

	constructor() { }

	ngOnInit(): void {
		this.init(this.selectedStore);
	}

	/**
	 * Initialize
	 * @param store StoreLocation
	 */
	init(store: StoreLocation) {
		// this.selectedStore = store;
		this.setStore(store);
		setTimeout(() => {
			this.groupAndDisplayDays();
		}, 4000);
	}

	/**
	 * @author KS
	 * @purpose Emit store
	 * @param store StoreLocation
	 */
	setStore(store: StoreLocation) {
		this._store.emit(store);
	}

	/**
	 * @author PT
	 * @purpose Group similar store location timings
	 * @returns 
	 */
	groupAndDisplayDays() {
		if (!this.selectedStore?.timings) return;
		this.timings = [];

		const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
		const groupedDays = {};

		// Group days with the same timing
		for (const day of days) {
			const timing = this.selectedStore?.timings[day] == undefined ? 'undefined' : JSON.stringify({ start: this.selectedStore?.timings[day].start, end: this.selectedStore?.timings[day].end });
			if (!groupedDays[timing]) {
				groupedDays[timing] = [];
			}

			groupedDays[timing].push(day);
			// if (groupedDays[timing].length > 0) {
			let weekdays = [];
			for (let i = 0; i < groupedDays[timing].length; i++) {
				weekdays.push(this.getDayInNumber(groupedDays[timing][i]));
			}
			//if days are not consecutive, then delete the last one and add the same obj in the next key of same timing
			if (!this.areWeekdaysConsecutive(weekdays)) {
				groupedDays[timing].pop(day);
				//adding the popped one obj into the other key with same timing but format is different just to create the differnt key
				const newTiming = this.selectedStore?.timings[day] == undefined ? 'undefined' : JSON.stringify({ end: this.selectedStore?.timings[day].end, start: this.selectedStore?.timings[day].start });
				if (!groupedDays[newTiming]) {
					groupedDays[newTiming] = [];
				}
				groupedDays[newTiming].push(day);
			}
			// }
		}

		// Convert groupedDays object into an array for displaying purpose
		for (const timing in groupedDays) {
			const days = groupedDays[timing];
			const obj = {
				days,
				timing: timing == 'undefined' ? 'Closed' : JSON.parse(timing)
			};
			this.timings.push(obj);
		}
	}

	/**
	 * 
	 * @param weekdays - passing the days' numeric values in an array
	 * @returns boolean 
	 */
	areWeekdaysConsecutive(weekdays) {
		for (let i = 0; i < weekdays.length - 1; i++) {
			// Check if the next weekday is one day greater than the current weekday
			if ((weekdays[i + 1] - weekdays[i] + 7) % 7 !== 1) {
				return false; // Not consecutive
			}
		}

		return true; // All weekdays are consecutive
	}

	//Changing the days into their number values. So, we can check them either they are consecutive or not
	getDayInNumber(day) {
		let num: Number;
		switch (day) {
			case 'sunday':
				num = 0;
				break;
			case 'monday':
				num = 1;
				break;
			case 'tuesday':
				num = 2;
				break;
			case 'wednesday':
				num = 3;
				break;
			case 'thursday':
				num = 4;
				break;
			case 'friday':
				num = 5;
				break;
			case 'saturday':
				num = 6;
				break;
			default:
				break;
		}
		return num;
	}

	//to show the days in the html
	getDays(days) {
		if (days?.length > 1) {
			return `${days[0]} - ${days[days.length - 1]}`;
		} else {
			return days[0];
		}
	}
}