import { bingConfig, googleConfig } from '../../core/config';
import { MapBing } from '../../services/map/map-bing';
import { MapProvider } from './map-provider';
import { IMap } from './imap';

export type MapName = 'home-bing' | 'home-google' | 'trip-bing' | 'trip-google';

export abstract class MapBase {

  /** if map div is in the application: true, otherwise false. */
  isVisible = false;

  provider: MapProvider;

  /** Actual map html element that contain the map. */
  // mapEle: HTMLElement;

  /** ID of the map div inside the Application Html */
  mapId = 'mapDiv';

  /** Div id, place where map will be stored when not needed (i.e away from home page) */
  hideMapContainer = 'mapBackup';

  /** dive id where map will be shown, in the home page */
  showMapContainer = 'mapShowHolder';
  

  public promise: Promise<any>;

  setMapContainerId(id: string) {
    this.showMapContainer = id;
  }

  get url(): string {
    switch (this.provider) {
      case MapProvider.Bing:
        return bingConfig.url;

      case MapProvider.GMap:
        return googleConfig.url;

      default:
        throw Error('Invalid Map Provider! ' + this.provider + ' is not supported');
    }
  }

  get loadCallback(): string {
    switch (this.provider) {
      case MapProvider.Bing:
        return bingConfig.loadCallback;

      case MapProvider.GMap:
        return googleConfig.loadCallback;

      default:
        throw Error('Invalid Map Provider! ' + this.provider + ' is not supported');
    }
  }

  // #region Manage display show/hide of map

  public importMapDiv() {
    if (!this.isVisible) {
      const m = document.getElementById(this.mapId);
      const c = document.getElementById(this.showMapContainer);
      c.appendChild(m);
      this.isVisible = true;
    }
  }

  public exportMapDiv() {
    if (this.isVisible) {
      const m = document.getElementById(this.mapId);
      const c = document.getElementById(this.hideMapContainer);
      c.appendChild(m);
      this.isVisible = false;
    }
  }
  // #endregion

}
