import { UserRef } from '@trentm/user/user-ref';
import { TaskType } from '@trentm/sys/task-type';
import { logger } from '../log/logger';
import { DbRule } from '../base/db-rule';
import { Author } from '../bid/author';


/** Task Utilities */
export class TaskUtl {

  // public static readonly WebsiteUrl = 'http://localhost:4500';

  /**
   * Message that will be displayed at website action page.
   * @param t type of the test
   */
  public static getClientMsg(t: TaskType) {
    switch (+t) {
      case TaskType.RequestCompanyApproval:
        return 'Admin approval is requested on this record';

      default:
        return null;
    }
  }

  /**
   * Convert http://localhost:4500/company/detail/ZLhCQvUUOaBgXUUeFV4d
   * to /company/detail/ZLhCQvUUOaBgXUUeFV4d
   * @param u absolute url
   */
  public static getRelativeUrl(u: string) {
    let url = u;
    if (url.indexOf(`//`) >= 0) {
      url = url.substr(url.indexOf(`//`) + 2);
      url = url.substr(url.indexOf(`/`) + 0);
      logger.log('fixed url is: ', url);
      return url;
    }
    return '';

  }
  public static getUrlFromTaskType(taskType: TaskType, actionKey: string): { url: string, q?: {} } {
    let url: string;
    let q: {};
    actionKey = DbRule.getEncodedURLSegment(actionKey);

    switch (taskType) {
      case TaskType.TripTrackerUpdate:
        url = `/track-delivery/list/${actionKey}`;
        q = { rb: true };
        return { url, q };
      case TaskType.TripAssignedToDriver:
        url = `/trip/workflow/${actionKey}`;
        return { url };
      case TaskType.RequestAddPromoDriver:
        const userRef: UserRef = 'driver-sign';
        q = { userRef, refId: `${actionKey}` };
        url = '/account';
        return { url, q };
      case TaskType.RequestAddPromoDriverNewUser:
        url = '/account';
        return { url };
      case TaskType.RequestBidApprovalVendor:
        url = `/bid/counter-vendor/${actionKey}`;
        q = { author: Author.vendor };  //MKN - Pass author flag in query params to make constancy
        return { url, q };
      case TaskType.BidRejectedByVendor:
        url = `/bid/customer/${actionKey}`;
        return { url };
      case TaskType.BidApprovedByVendor:
        url = `/bid/rental-contract-customer/${actionKey}`;
        q = { author: Author.customer };  //MKN - Pass author flag in query params to make constancy
        return { url, q };
        case TaskType.RequestBidApprovalVendorForSales:
          url = `/bid/sales-person/${actionKey}`;
          q = { author: Author.vendor };  //MKN - Pass author flag in query params to make constancy
          return { url, q };
        case TaskType.BidApprovedByVendorForSales:
          url = `/bid/rental-contract-customer/${actionKey}`;
          q = { author: Author.customer };  //MKN - Pass author flag in query params to make constancy
          return { url, q };
        case TaskType.BidRejectedByVendorForSales:
        url = `/bid/sales-person/${actionKey}`;
        return { url };
      case TaskType.CompanyCreated:
      case TaskType.CompanyUpdated:
      case TaskType.RequestCompanyApproval:
      case TaskType.CompanyReleased:
        url = `/company/detail/${actionKey}`;
        return { url };
      case TaskType.SalesTruckCreated:
      case TaskType.SalesTruckUpdated:
      case TaskType.RequestSalesTruckApproval:
      case TaskType.SalesTruckReleased:
        url = `/sales-options/truck-details/${actionKey}`;
        return { url };
      case TaskType.SalesTrailerCreated:
      case TaskType.SalesTrailerUpdated:
      case TaskType.RequestSalesTrailerApproval:
      case TaskType.SalesTrailerReleased:
        url = `/sales-options/trailer-details/${actionKey}`;
        return { url };
      case TaskType.RequestReleaseTrailer:
      case TaskType.RequestReleaseTruck:
      case TaskType.TrailerReleased:
      case TaskType.TruckReleased:
        url = `/trailers/trailer-detail/${actionKey}`;
        return { url };
      case TaskType.VendorCompetedPDI:
      case TaskType.VendorPDIExpired:
        url = `/inspection/customer/pdi/${actionKey}`;
        return { url };
      case TaskType.CustomerCompetedPDI:
      case TaskType.CustomerPDIExpired:
        url = `/inspection/vendor/pdi/${actionKey}`;
        return { url };
      case TaskType.CustomerCompetedReturnIspection:
      case TaskType.CustomerReturnInspectionExpired:
        url = `/inspection/vendor/return/${actionKey}`;
        return { url };
      case TaskType.VendorCompetedReturnIspection:
      case TaskType.VendorReturnIspectionExpired:
        url = `/inspection/customer/return/${actionKey}`;
        return { url };
      case TaskType.DraftBidCreated:
        url = `/bid/customer/${actionKey}`;
        return { url };
      default:
        return { url: null };
    }
  }
}
