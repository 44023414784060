import { IsDefined, Length, ValidatorOptions, validateSync } from 'class-validator';
import { Exclude, instanceToInstance, plainToInstance, Expose, Type } from 'class-transformer';
import { IValidationMsg } from '../error-handling';
import { sanitizeDateIPoint, toValidationError } from '../utility';
import { BaseModel } from '../base';
import { logger } from '../log/logger';

/**
 * 
 * @author - MKN
 * Interface create/update api call
 * 
 */
export interface IPromotionData {
  data: Promotions;
  promotionId?: string | number;
  options: ValidatorOptions;
}


/**
 * @author - MKN
 * @purpose - 
 */
@Exclude()
export class Promotions extends BaseModel {
  
  public static readonly collectionName = 'promotions';

  @Expose()
  @Length(3, 50, { message: 'Promotion Name must be $constraint1 - $constraint2 chars long' })
  name: string;

  @Expose()
  @Length(3, 150, { message: 'Promotion header must be $constraint1 - $constraint2 chars long' })
  header: string;

  @Expose()
  @IsDefined({ message: 'email Template must be defined' })
  emailTemplate: string;

  @Expose()
  country: 'USA' | 'CA' = 'CA';

  @Expose()
  // @Length(1, 500, { message: 'SMS Template must be $constraint1 - $constraint2 chars long' })
  @IsDefined({ message: 'SMS Template must be defined' })
  smsTemplate: string;

  constructor() {
    super();
  }

  public static parse(obj) {
    if (obj instanceof (Promotions)) { return <Promotions>obj; }

    const m = plainToInstance<Promotions, any>(Promotions, sanitizeDateIPoint(obj));
    m.sanitize();
    return m;
  }

  sanitize() {
    // super.sanitize();
  }

  clone() {
    const t = instanceToInstance(this);
    t.sanitize();
    return t;
  }

  validateSync(options?: any): IValidationMsg {

    const m = validateSync(this, options);
    const r = toValidationError(m);
    return r;
  }

  updatePromotionData(iUpdateData : Promotions){
    this.name = iUpdateData.name;
    this.header = iUpdateData.header;
    this.emailTemplate = iUpdateData.emailTemplate;
    this.smsTemplate = iUpdateData.smsTemplate;
  }

}