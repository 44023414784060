import { Component, OnInit, Inject } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';

export enum ShareOn {
  email = 1,
  facebook = 2,
  whatsapp = 3
}
@Component({
  selector: 'trent-social-share',
  templateUrl: './social-share.component.html',
  styleUrls: ['./social-share.component.scss']
})
export class SocialShareComponent implements OnInit {
  subject = 'Subject';
  body = 'Body';
  constructor(private _bottomSheetRef: MatBottomSheetRef<SocialShareComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: { title: string, text: string, url: string, shareOn: ShareOn[] }) { }

  ngOnInit(): void {
  }
  openLink(event: MouseEvent): void {
    this._bottomSheetRef.dismiss();
    // event.preventDefault();
  }
  get shareOnEmail() {
    return this.data.shareOn.includes(ShareOn.email);
  }
  get shareOnFacebook() {
    return this.data.shareOn.includes(ShareOn.facebook);
  }
  get shareOnWhatsapp() {
    return this.data.shareOn.includes(ShareOn.whatsapp);
  }
}
