<mat-dialog-actions align="end" class="form-head">
    <div class="head-title">
        <h1>Start searching</h1>
    </div>


    <div class="close-btn">
        <button mat-button mat-dialog-close class="cancel">
            <mat-icon class="clear-icon">clear</mat-icon>
        </button>
    </div>
</mat-dialog-actions>

<mat-dialog-content class="mat-typography">
    <div class="search-wrpr">
        <ais-instantsearch [config]="config">
            <div class="search-form">
                <ais-search-box placeholder="Search for spare parts..." class=""></ais-search-box>
            </div>
            <div class="search-result">
                <ais-hits>
                    <ng-template let-results="results">
                        <div class="ais-Hits-list search-container" *ngIf="results?.query">
                            <ng-container *ngFor="let _result of results?.hits">
                                <a class="spare-part" (click)="clickOnSparePart( _result?.objectID)">
                                    <div class="main">
                                        <img class="img-prod" [src]="_result?.pictures?.[0]?.url" />
                                        <div class="desc">
                                            <p>
                                                <ais-highlight attribute="name" [hit]="_result"></ais-highlight>
                                            </p>
                                            <span *ngIf="isPriceShow">$ {{ _result.price }}</span>
                                        </div>
                                    </div>
                                </a>
                            </ng-container>

                        </div>
                        <div *ngIf="results?.hits?.length === 0 && results?.query">
                            <p class="d-text">No result found for your search '{{results?.query}}'</p>
                        </div>
                    </ng-template>
                </ais-hits>
            </div>
        </ais-instantsearch>
    </div>
</mat-dialog-content>