import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { logger } from '@trent/models/log/logger';
// import { algoliaConfig } from '../../../environments/environment';
import { Router } from '@angular/router';
import { AuthState } from '@trent/store/auth-store/auth.state';
import { Store } from '@ngxs/store';
import { algoliaConfig } from '../../../environments/environment.prod';

@Component({
  selector: 'trent-dpc-global-search-popup',
  templateUrl: './dpc-global-search-popup.component.html',
  styleUrls: ['./dpc-global-search-popup.component.scss']
})
export class DpcGlobalSearchPopupComponent implements OnInit {

  config = algoliaConfig; //algolia configuration
  isPriceShow:boolean;
  
  constructor(private dialogRef: MatDialogRef<DpcGlobalSearchPopupComponent>,
    public router: Router, public store:Store) { }

  ngOnInit(): void {
    		//Price show only admin,dps sales operation

		this.store.select(AuthState.user).subscribe(u => {
			this.isPriceShow = u?.roles?.salesOperationsDpc || u?.roles?.admin || u?.roles?.editor ? true : false;
		});
  }

  /** @author - PT
   * purpose -  redirect to the specific catalog page and close the search DPC popup
   */
  clickOnSparePart(id: string | number) {
    this.router.navigate([`/catalog/details/${id}`]);
    this.dialogRef.close();
  }

}
