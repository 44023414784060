import { AuthState } from '../store/auth-store/auth.state';
import { ValidatorOptions } from 'class-validator';
import { Injectable } from '@angular/core';
import { FirestoreService } from '@trent/services/firestore.service';
import { map, tap } from 'rxjs/operators';
import { instanceToPlain } from 'class-transformer';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { BaseHttpService } from '@trent/services/base-http.service';
import { Store } from '@ngxs/store';
import { logger } from '@trent/models/log/logger';
import { Paging } from '@trent/models/observable-util/paging';
import { PagingObesrvable } from '@trent/models/observable-util/paging-obesrvable';
import { Observable } from 'rxjs';
import { AddOnBase } from '@trent/models/addon/add-on-base';
import { AddOnParam, addOnSearchServerQuery } from '@trent/models/addon/add-on-param';

@Injectable()
export class AddOnService extends BaseHttpService {

	constructor(store: Store, private db: FirestoreService, private http: HttpClient) {
		super(store);
		this.apiName = 'api';
	}

	public create(addOn: AddOnBase) {
		// Prepare the post data
		const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.token });
		return this.http.post<{ id: string | number, salesProduct: AddOnBase }>(this.getApiUrl('/addOn/create'),
			// Server validation before creating the entry.
			{
				addOn: addOn,
			},
			{ headers: headers })
			.pipe(
				tap(res => console.log('response: ', res))
			);
	}

	public update(addOn: AddOnBase, option?: ValidatorOptions) {
		// Prepare the post data
		const headers = this.addBearerToken();
		// Server validation before creating the entry.
		return this.http.post<{ AddOn: AddOnBase }>(this.getApiUrl('/addOn/update'),
			{
				addOn: addOn,
				option: option
			},
			{ headers: headers })
			.pipe(
				tap(r => logger.log('response: ', r)),
			);
	}

	getNewDocId() {
		return this.db.docId(AddOnBase.collectionName);
	}

	getAddOnById(id: string | number) {
		return this.db.docWithInjectedId$<AddOnBase>(
			`${AddOnBase.collectionName}/${id}`, id);
	}
	
	public getAllAddOns_PagingObservable() {
		const p: PagingObesrvable<AddOnBase, AddOnParam> =
      new PagingObesrvable<AddOnBase, AddOnParam>(this.db, this.getAllAddOns_batch);
    return p;
	}

	/**
	* Get the next batch of the data from server.
	* @param p paging information.
	*/
	private getAllAddOns_batch(p: Paging, o: AddOnParam): Observable<{ [key: string]: AddOnBase }> {
		console.log('AddOn Server called with', p.offset, p.size);
		const col = AddOnBase.collectionName ;
		return this.db
		  .colWithIdsInjectedNew$<AddOnBase>(col,
			ref => addOnSearchServerQuery(ref, o, p))
			.pipe(
			tap(arr => {
			  if (arr == null || arr.length === 0) {
				console.log('All data is received, Paging is FULL');
				p.full = true;
			  } else {
				p.lastDoc = arr[arr.length - 1];
			  }
			}),
			map(arr => {
			  return arr.reduce((acc, cur) => {
				const id = cur.id;
				const data = cur;
				return { ...acc, [id]: data };
			  }, {});
			})
		  );
	  }
}