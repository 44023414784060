import { IUserClaim, IUserAuthClaim } from '@trent/models/user/user-claim';
import { UserProfile } from './user-profile';
import { logger } from '../log/logger';
/** Keep it in sync with Role Names. IMPORTANT */

/**
 * Must be in-sync with @interface RoleCompany
 */
export enum RoleCompanyEnum {

  /** Owner of the company */
  owner = 'o',

  /** Administrator of the company */
  admin = 'a',

  /** Person who can execute business on company's behalf */
  Executer = 'e',

  /** User who can only view the business details of the company */
  reader = 'r'
}

/** Auth level of various kinds of users of the compan, higher the rank, higher the authority. */
export enum RoleCompanyAuthRank {

  /** Global Site super user who can edit any company. */
  siteAdmin = 100,

  /** Owner of the company */
  owner = 4,

  /** Administrator of the company */
  admin = 3,

  /** Person who can execute business on company's behalf */
  executer = 2,

  /** User who can only view the business details of the company */
  reader = 1,

  unAuthorized = 0
}


export interface RoleCompany {

  /** Owner of the company */
  o?: boolean;

  /** Admin: Strongest after owner */
  a?: boolean;

  /** Executer: Assigned User by the Admin (director) who can bind the contrats on behalf of the company.
  * He/She can not add any user to the company.  */
  e?: boolean;

  /** Reader: Can only view the bids on behalf of the company. Only Amdin can assign.  */
  r?: boolean;
}

/** Owner is 4, company admin is 3, executer is 2 and reader is 1 */
export const getCompRoleAuthLevel = (rs: RoleCompany | string): RoleCompanyAuthRank => {
  if (rs == null) { return RoleCompanyAuthRank.unAuthorized; }
  let r: RoleCompany;
  if (typeof (rs) === 'string') {
    r = {};
    r[rs] = true;
  } else {
    r = <RoleCompany>rs;
  }

  if (!!r.o) { return RoleCompanyAuthRank.owner; }
  if (!!r.a) { return RoleCompanyAuthRank.admin; }
  if (!!r.e) { return RoleCompanyAuthRank.executer; }
  if (!!r.r) { return RoleCompanyAuthRank.reader; }
  return RoleCompanyAuthRank.unAuthorized;
};


export const getSanitizedCompanyRole = (role: RoleCompany): RoleCompany => {
  if (role == null) { return null; }
  const r: RoleCompany = {};
  if (!!role.o) { r.o = true; }
  if (role.a === true) { r.a = true; }
  if (!!role.e) { r.e = true; }
  if (!!role.r) { r.r = true; }
  return r;
};


// #region Company Role Authentications from User Profile

/** Get the Max Authority Role of a user in the company */
export const getMaxCompanyUserRole = (u: UserProfile, cid: string): RoleCompanyEnum => {
  if (u == null || u.cRolesAll == null
    || u.cRolesAll[cid] == null || u.cRolesAll[cid].roles == null) {
    return null;
  }

  const r = u.cRolesAll[cid].roles;

  if (r == null) {
    return null;
  }

  // first owner
  if (r.o) { return RoleCompanyEnum.owner; }

  // 2nd admin
  if (r.a) { return RoleCompanyEnum.admin; }

  // 3rd executer
  if (r.e) { return RoleCompanyEnum.Executer; }

  // 4th reader
  if (r.r) { return RoleCompanyEnum.reader; }

  return null;
};

export const checkCompanyAuthP = (u: UserProfile, cid: string, allowedRoles: RoleCompanyEnum[]): boolean => {
  if (u == null || u.cRolesAll == null
    || u.cRolesAll[cid] == null || u.cRolesAll[cid].roles == null) {
    return false;
  }

  const r = u.cRolesAll[cid].roles;

  for (const role of allowedRoles) {
    if (r[role]) {
      return true;
    }
  }
  return false;
};

export const isCompanyOwnerP = (u: UserProfile, cid: string): boolean => {
  return checkCompanyAuthP(u, cid, [RoleCompanyEnum.owner]);
};

export const isCompanyAdminP = (u: UserProfile, cid: string): boolean => {
  return checkCompanyAuthP(u, cid, [RoleCompanyEnum.owner, RoleCompanyEnum.admin]);
};

export const isCompanyExecuterP = (u: UserProfile, cid: string): boolean => {
  const allowed = [RoleCompanyEnum.owner, RoleCompanyEnum.admin, RoleCompanyEnum.Executer];
  return checkCompanyAuthP(u, cid, allowed);
};

export const isCompanyReaderP = (u: UserProfile, cid: string): boolean => {
  const allowed = [RoleCompanyEnum.owner, RoleCompanyEnum.admin, RoleCompanyEnum.Executer, RoleCompanyEnum.reader];
  return checkCompanyAuthP(u, cid, allowed);
};

// #endregion

// #region Company Role Authentications from User Claim

export const checkCompanyAuthC = (u: IUserAuthClaim, cid: string, allowedRoles: RoleCompanyEnum[]): boolean => {
  if (u == null || u.cRoles == null ||
    u.cRoles[cid] == null || u.cRoles[cid].r == null) {
    return false;
  }
  const r = u.cRoles[cid].r;
  for (const role of allowedRoles) {
    if (r[role]) {
      return true;
    }
  }
  return false;
};


export const isCompanyOwnerC = (u: IUserAuthClaim, cid: string): boolean => {
  return checkCompanyAuthC(u, cid, [RoleCompanyEnum.owner]);
};

export const isCompanyAdminC = (u: IUserAuthClaim, cid: string): boolean => {
  return checkCompanyAuthC(u, cid, [RoleCompanyEnum.owner, RoleCompanyEnum.admin]);
};

export const isCompanyExecuterC = (u: IUserAuthClaim, cid: string): boolean => {
  const allowed = [RoleCompanyEnum.owner, RoleCompanyEnum.admin, RoleCompanyEnum.Executer];
  return checkCompanyAuthC(u, cid, allowed);
};

export const isCompanyReaderC = (u: IUserAuthClaim, cid: string): boolean => {
  const allowed = [RoleCompanyEnum.owner, RoleCompanyEnum.admin, RoleCompanyEnum.Executer, RoleCompanyEnum.reader];
  return checkCompanyAuthC(u, cid, allowed);
};

// #endregion
/** For given company roles return highest role in company
 * @param roles: RoleCompany
 */
export const getHighestCRole = (roles: RoleCompany): RoleCompany => {
  // logger.log('getHighestCRole', roles);
  let aLevel: RoleCompanyAuthRank = RoleCompanyAuthRank.unAuthorized;
  for (const k in roles) {
    if (roles.hasOwnProperty(k)) {
      const e = roles[k];
      if (!!e) {
        aLevel = getCompRoleAuthLevel(k) > aLevel ? getCompRoleAuthLevel(k) : aLevel;
      }
    }
  }
  switch (aLevel) {
    case RoleCompanyAuthRank.owner:
      return { o: true };
    case RoleCompanyAuthRank.admin:
      return { a: true };
    case RoleCompanyAuthRank.executer:
      return { e: true };
    case RoleCompanyAuthRank.reader:
      return { r: true };

    default:
      return { o: false, a: false, e: false, r: false};
  }
};

export const getCRoleString = (role: RoleCompany): string => {
  const k = Object.keys(role)[0];
  switch (k) {
    case 'o':
      return 'Company Owner';
    case 'a':
      return 'Company Admin';
    case 'e':
      return 'Company Editor';
    case 'r':
      return 'Company reader';

    default:
throw new Error('Role not defined');

  }
};