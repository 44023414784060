import { MenuData } from '@trent/models/UI/menu-data';

export const getMenuDataDpc = () => {
  const menu: MenuData[] = [
    {
      title: 'About us', url: 'about-us', auth: 'anonymous', micon: '',
      iClass: '', description: ''
    },
    {
      title: 'Catalog', url: 'catalog', auth: 'anonymous', micon: '',
      iClass: '', description: ''
    },
    {
      title: 'Contact', url: 'contact-us', auth: 'anonymous', micon: '',
      iClass: '', description: ''
    },
    {
      title: 'Admin', auth: 'editor', micon: 'home',
      iClass: 'fa fa-home color-green-dark', description: 'Manage Admin Actions',
      subMenu: [
        {
          title: 'Spare Parts', url: '/spare-part/list', auth: 'auth', micon: 'settings',
          iClass: 'fas fa-dollar-sign color-green-dark', description: 'Manage Spare Parts'
        },
        {
          title: 'Store Locations', url: '/store-locations', auth: 'auth', micon: 'domain',
          iClass: 'fas fa-dollar-sign color-green-dark', description: 'Manage Store Locations'
        },
      ]
    },
    {
      title: 'Operation', auth: 'operation', micon: 'home',
      iClass: 'fa fa-home color-green-dark', description: 'Manage Admin Actions',
      subMenu: [
        {
          title: 'Spare Parts', url: '/spare-part/list', auth: 'operation', micon: 'settings',
          iClass: 'fas fa-dollar-sign color-green-dark', description: 'Manage Spare Parts'
        },
        {
          title: 'Store Locations', url: '/store-locations', auth: 'operation', micon: 'domain',
          iClass: 'fas fa-dollar-sign color-green-dark', description: 'Manage Store Locations'
        },
      ]
    }

  ];

  return menu;
};
