import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { BaseForm } from '@trent/models/UI/base-form';
import { Author } from '@trent/models/bid/author';
import { BidBase } from '@trent/models/bid/bid-base';
import { getServType } from '@trent/models/bid/bid-helper';
import { BidTrailer } from '@trent/models/bid/bid-trailer';
import { CompanyBase, CompanyFleet } from '@trent/models/company';
import { logger } from '@trent/models/log/logger';
import { MapLabel } from '@trent/models/map/map-label';
import { NegoTermsBase } from '@trent/models/nego-terms-base/nego-terms-base';
import { PromoListBase } from '@trent/models/promo';
import { VendorPortfolio } from '@trent/models/promo/vendor-portfolio';
import { rentalMapLabels } from '@trent/models/rental/rental-helper';
import { RentalProductBase } from '@trent/models/rental/rental-product-base';
import { RentalTruck } from '@trent/models/rental/rental-truck';
import { DialogService } from '@trent/services/dialog/dialog.service';
import { CompanyRequested, CompanyState } from '@trent/store/company-store';
import { PromoListByIdRequested, PromoListState } from '@trent/store/promo-list-store';
import { RentalProductRequested, RentalProductState } from '@trent/store/rental-product-store';
import { isEqual } from 'date-fns';
import { Observable, asapScheduler, combineLatest, map, scheduled } from 'rxjs';
import { AppState1, ScreenDisplayMode } from '@trent/store/root-store';
import { FormDisplayMode, MessageType } from '@trent/models/UI/form-display-mode';
import { ActivatedRoute } from '@angular/router';
import { RentalTrailer } from '@trent/models/rental/rental-trailer';

@Component({
  selector: 'trent-truck-detail',
  templateUrl: './truck-detail.component.html',
  styleUrls: ['./truck-detail.component.scss']
})
export class TruckDetailComponent extends BaseForm<NegoTermsBase> implements OnInit {
  isMobile: boolean;
  bid$: Observable<BidBase[]>;
  rentalProd$: Observable<(RentalProductBase)>;
  comp$: Observable<CompanyBase>;
  bid: BidBase;
  portfolioList$: Observable<PromoListBase>;
  customerCompany: CompanyFleet;
  rentalProduct: RentalProductBase;
  rentalId: string | number;
  rentalRootId: string | number;
  contractAuthor: Author;
  summaryString: string;
  author = Author;
  minDate: Date;
  maxDate: Date;
  mapLabels: MapLabel[];
  cannotRent = false;
  formDisplayMode = FormDisplayMode;
  mapHeight: number;
  statusMsg = 'test';
  constructor(
    store: Store,
    dialogService: DialogService,
    private activatedRoute: ActivatedRoute,

  ){
    super(store, dialogService);
  }


  ngOnInit() {
    this.isMobile = window.innerWidth < 960;
    this.rentalId = this.activatedRoute.snapshot.params.id;
    this.setObservables();
    const sub = combineLatest([this.user$, this.store.select(AppState1.appSettings)])
    .subscribe(([u]) => {
      if (!!u) {
        // this.bidReview();

      }
      // }
    });
    this.initializeState();

  this.mSubscription.push(sub);
  }

  setObservables() {
    this.store.dispatch(new RentalProductRequested({ id: this.rentalId }));
    this.rentalProd$ = this.store.select(RentalProductState.selectProductById)
      .pipe(map(filterFn => filterFn(this.rentalId)));
    // get company
    // let comp$: Observable<CompanyBase> = of({ name: 'TBD', cid: 'TBD' } as CompanyBase);
    if (this.defaultCompany) {
      this.store.dispatch(new CompanyRequested({ id: this.defaultCompany.cid }));
      this.comp$ = this.store.select(CompanyState.selectCompanyById)
        .pipe(map(filterFn => filterFn(this.defaultCompany.cid)));
    } else {
      this.comp$ = scheduled([{ name: 'TBD', cid: 'TBD' } as CompanyBase], asapScheduler);
    }
    if (this.contractAuthor === Author.vendor) {
      let m: VendorPortfolio;
      this.store.dispatch(new PromoListByIdRequested({ id: PromoListBase.masterCompListId }));
      this.portfolioList$ = <Observable<PromoListBase>>this.store.select(PromoListState.selectDataById)
        .pipe(map(filterFn => filterFn(PromoListBase.masterCompListId)));
    }
  }

  private initializeState() {
    this.store.dispatch(new RentalProductRequested({ id: this.rentalId }));
    const rentalProd$ = this.store.select(RentalProductState.selectProductById)
      .pipe(map(filterFn => filterFn(this.rentalId)));
    // get company
    // let comp$: Observable<CompanyBase> = of({ name: 'TBD', cid: 'TBD' } as CompanyBase);
    let comp$: Observable<CompanyBase> = scheduled([{ name: 'TBD', cid: 'TBD' } as CompanyBase], asapScheduler);
    if (this.defaultCompany) {
      this.store.dispatch(new CompanyRequested({ id: this.defaultCompany.cid }));
      comp$ = this.store.select(CompanyState.selectCompanyById)
        .pipe(map(filterFn => filterFn(this.defaultCompany.cid)));

    }

    // Also dispatch root as required.
    const sub = combineLatest([rentalProd$, comp$]).subscribe(x => {


      if (!!x[0]) {
        this.rentalProduct = x[0];
        if (this.rentalProduct.getOpenRentalDates().length === 0) {
          // this.notAvailableMsg();
        } else {
          this.summaryString = (<RentalTruck>this.rentalProduct).summary;
          logger.log('[bid-detail] rentalProduct', this.rentalProduct);
          this.m = new NegoTermsBase();
          this.m.author = this.author.customer;
          this.em = this.m.clone();
          this.minDate = this.rentalProduct.getMinDate(); //  this.rentalProduct.openDateRange[0].start;
          this.maxDate = this.rentalProduct.openDateRange[this.rentalProduct.openDateRange.length - 1].end;
          this.mapRentalProps();
          this.mapLabels = rentalMapLabels([this.rentalProduct]);
          logger.log('[bid-detail] m a load', this.m);
          // this.setRentalAgreement();

        }

      }
      if (!!x[1] && this.bid) {
        this.customerCompany = x[1] as CompanyFleet;
        this.bid.customerCompSummary = !!this.defaultCompany ? this.customerCompany.getCustomerCompanySummary() : null;
        if (!!this.customerCompany.address) {
          this.em.initAddOns(getServType(this.rentalProduct.productSummary.productType), this.customerCompany.address.stateProv);
        }
        // if (this.rentalProduct.vendorCompSummary.address.country !== this.customerCompany.address.country) {
        //   this.cannotRent = true;
        // }
      }



    });
    this.mSubscription.push(sub);
  }

  getMapHeight() {
    if (!!this.us.isPlatformBrowser) {
      const statusBarHeight = this.statusMsg === '' ? 0 : 30;
      this.mSubscription.push(this.store.select(AppState1.currentScreenDisplay)
        .subscribe(x => {
          const browserHeight = x.height;
          if (x.screen === ScreenDisplayMode.desktop) {
            this.mapHeight = x.height - 120 - 72 - 70 - statusBarHeight;
            // this.paymentTableHt = this.mapHeight - 130 - 44;
            // this.cardHeight = this.mapHeight + 26;
          } else {
            this.mapHeight = x.height - 60 - 40 - statusBarHeight;
            // this.paymentTableHt = undefined;

          }
        }));
    }
  }

  // bidReview() {
  //   this.store.dispatch(new BidRequested({ id: `${this.bidId}` }));
  //   // Pull root, if needed.
  //   if (this.bidId !== this.bidRootId) {
  //     this.store.dispatch(new BidRequested({ id: `${this.bidRootId}` }));
  //   }
  //   this.bid$ = this.store.select(BidState.selectBidById)
  //     .pipe(map(filterFn => [filterFn(this.bidId), filterFn(this.bidRootId)]));
  //   let r$: Observable<RentalProductBase>;
  //   let c$: Observable<CompanyBase> = scheduled([{} as CompanyBase], asapScheduler);

  //   const rentalProd$ = this.store.select(BidState.selectBidById)
  //     .pipe(map(filterFn => [filterFn(this.bidId), filterFn(this.bidRootId)]),
  //       switchMap(b => {
  //         if (!!b[0]) {
  //           this.rentalId = `${b[0].rentSummary.rid}`;
  //           this.store.dispatch(new RentalProductRequested({ id: this.rentalId }));
  //           r$ = this.store.select(RentalProductState.selectProductById)
  //             .pipe(map(filterFn => filterFn(this.rentalId)));
  //         } else {
  //           r$ = of();
  //         }
  //         return r$;
  //       }));
  //   // const comp$ = this.store.select(BidState.selectBidById)
  //   //   .pipe(map(filterFn => [filterFn(this.bidId), filterFn(this.bidRootId)]),
  //   //     switchMap(b => {
  //   //       if (!!b[0]) {
  //   //         // for draft bid if user has default company and default company is same
  //   //         // as customerCompSummary or customerComp does not exist in cid.
  //   //         // (customerCompSummary is added when user creates when prompted after draft is created)
  //   //         if (b[0].dbStatus === DbStatus.Initial && this.defaultCompany.cid &&
  //   //           (!b[0].customerCompSummary || this.defaultCompany.cid === b[0].customerCompSummary.cid)) {
  //   //           this.store.dispatch(new CompanyRequested({ id: this.defaultCompany.cid }));
  //   //           c$ = this.store.select(CompanyState.selectCompanyById)
  //   //             .pipe(map(filterFn => filterFn(this.defaultCompany.cid)));
  //   //         }
  //   //       }
  //   //       return c$;
  //   //     }));
  //   let inv$: Observable<Invoice[]>;
  //   let order$: Observable<OrderRec[]>;
  //   if (this.defaultCompany || this.user.isAdmin || this.user.isEditor) {
  //     const invParm: IInvoiceParam = { cid: !this.isDraft ? this.defaultCompany?.cid : undefined, refId: this.bidRootId, invoiceType: TransactionType.credit };
  //     // this.store.dispatch(new InvoicesRequested({ id: this.bidId }));
  //     const invOrderPdata: Paging = { size: 1, offset: 0, full: false };
  //     this.store.dispatch(new InvoicesRequested({ pData: invOrderPdata, param: invParm }));
  //     inv$ = this.store.select(InvoiceState.selectAllData)
  //       .pipe(map(clientFilterFn => clientFilterFn(invParm)));
  //     const orderParm: IOrderParam = { cid: !this.isDraft ? this.defaultCompany?.cid : undefined, refId: this.bidRootId, invoiceType: TransactionType.credit };
  //     this.store.dispatch(new OrdersRequested({ pData: invOrderPdata, param: orderParm }));
  //     order$ = this.store.select(OrderState.selectAllData)
  //       .pipe(map(clientFilterFn => clientFilterFn(orderParm)));
  //   } else {
  //     inv$ = scheduled([{} as InvoiceRec[]], asapScheduler);
  //     order$ = scheduled([{} as OrderRec[]], asapScheduler);
  //   }

  //   this.mSubscription.push(combineLatest([this.bid$, rentalProd$, inv$, order$]).subscribe(d => {
  //     if (!!d[0][0] && !isEqual(this.bid, d[0][0])) {
  //       logger.info('[Bid-detail] bid', d[0][0]);
  //       this.bid = d[0][0];
  //       if (this.bid.bidStatus < BidStatus.rejected) {
  //         this.m = this.bid.bidNegoTerms;
  //         this.em = this.m.clone();
  //         if (this.isDraft && this.user.isEditor || this.user.isAdmin) {
  //           this.getDraftInfo();
  //         }
  //       } else {
  //         this.m = this.bid.bidPrevious.bidSnapshot;
  //         this.em = this.m.clone();
  //       }

  //     }
  //     if (!!d[0][1]) {
  //       logger.info('[Bid-detail] bid root', d[0][1]);
  //       this.bidRoot = d[0][1];
  //     }
  //     if (!!d[1]) {
  //       logger.info('[Bid-detail] rental root', d[1]);
  //       this.rentalProduct = d[1];
  //       this.mapLabels = rentalMapLabels([this.rentalProduct]);
  //     }
  //     if (!!d[2] && d[2].length > 0) {
  //       logger.info('[Bid-detail] invoice root', d[2][0]);
  //       this.invoice = d[2][0];
  //     }
  //     if (!!d[3] && d[3].length > 0) {
  //       logger.info('[Bid-detail] order root', d[3][0]);
  //       this.order = d[3][0];
  //     }
  //     // if (this.bid.dbStatus === DbStatus.Initial && this.rentalProduct) {
  //     //   if (!!d[4] && !isEmpty(d[4]) && !isEqual(this.customerCompany, d[4])) {
  //     //     this.customerCompany = d[4] as CompanyFleet;
  //     //     if (this.customerCompany.dbStatus === DbStatus.Released) {
  //     //       this.displayMode = FormDisplayMode.edit;
  //     //     }
  //     //   }
  //     //   this.invoice = getRentalRecInvoice(this.rentalProduct, this.em, this.bid.customerCompSummary, undefined, undefined, undefined, !this.rentalProduct.isReplacementUnit);
  //     //   this.order = this.invoice.buildOrders('?');
  //     // }
  //     if (this.bid && (this.bid.dbStatus === DbStatus.Released || !!this.customerCompany)) {
  //       this.bid.getStatusInfo(UserEnum.customer, this.customerCompany);
  //       this.getStepperHeight();
  //     }
  //   }));
  // }

  mapRentalProps() {
    this.bid = new BidBase();
    this.bid.productSummary = this.rentalProduct.productSummary;
    this.bid.rentSummary = this.rentalProduct.getRentOptionSummary();
    this.bid.vendorCompSummary = this.rentalProduct.vendorCompSummary;
    this.bid.bidNegoTerms = this.em;
  }

  bl(a) {
    return a as BidTrailer;
  }

  tl(a) {
    return a as RentalTrailer;
  } 
}
