import { BidBase } from './../bid/bid-base';
import { Invoice } from "../finance/invoice/invoice";
import { RentalPlan } from "../rental/rental-plan";
import { UserEnum } from "../user/user-enum";
import { Order } from '../finance/order/order';
import { IInvoiceSummary } from '../finance/invoice/i-item';

export interface IPdfInvoiceData {
  status: {
    receiptNo: string | number;
    dateIssued: Date;
    status: string;
  },
  from: {
    companyName: string;
    issuerId: string | number;
    address: {
      line1: string;
      line2?: string;
      line3?: string;
    };
  };
  to: {
    companyName: string;
    receiver: string | number;
    address: {
      line1: string;
      line2?: string;
      line3?: string;
    };
  };
  items: IInvoiceSummary[];
}

/** Temporary ONLY. for debugging. */
export function getBid_DEBUG_ONLY() {
  return BidBase.parse(
    {
      "bidPrevious": {
        "bidSnapshot": {
          "deposit": 0,
          "isRentAccepted": true,
          "isDatesAccepted": true,
          "isDepositAccepted": true,
          "signedTerms": false
        }
      },
      "productSummary": {
        "vin": "44444444444444444",
        "productType": 2,
        "safetyExpiryDate": "2023-01-20T05:00:00.000Z",
        "prodIcon": "local_shipping",
        "isDayCab": false,
        "summaryString": " Sleeper Truck,  IRP Plated, Automatic Transmission",
        "plateExpiryDate": "2023-04-13T04:00:00.000Z",
        "pRevid": 2,
        "title": "Sleeper Truck",
        "isAuto": true,
        "isIRP": true,
        "unitName": "DDD444",
        "modelYear": 2019,
        "plateNo": "on",
        "make": "volvo",
        "pid": "RHYrHjDhSRv1hwsSVhJD"
      },
      "rentSummary": {
        "rid": "D5un4nQpMlxeJZqiPXAd",
        "rRevid": 1,
        "startAddress": {
          "streetNumber": "5850",
          "streetName": "Dixie Road",
          "city": "Mississauga",
          "stateProv": "ON",
          "postalZipCode": "L4W 1E7",
          "country": "CA",
          "lat": 43.65176580000001,
          "long": -79.643807,
          "geoLoc": {
            "geopoint": {
              "latitude": 43.65176580000001,
              "longitude": -79.643807
            },
            "geohash": "dpz27dt1uy"
          },
          "addressFormated": "5850 Dixie Rd, Mississauga, ON L4W 1E7, Canada"
        },
        "startLoc": {
          "geohash": "dpz27dt1uy",
          "geopoint": {
            "latitude": 43.65176580000001,
            "longitude": -79.643807
          }
        },
        "isOneWay": false,
        "availStartDate": "2022-09-30T04:00:00.000Z",
        "availEndDate": "2023-12-31T05:00:00.000Z",
        "currency": "CAD",
        "deposit": 4999,
        "rentalPlans": [
          {
            "mileageType": 1,
            "ratePerDay": 299,
            "ratePerMile": 0.37,
            "milage": 500,
            "rentalTerm": 1,
            "isAvailable": true,
            "isSelected": true
          },
          {
            "mileageType": 1,
            "ratePerDay": 199,
            "ratePerMile": 0.37,
            "milage": 2000,
            "rentalTerm": 7,
            "isAvailable": true,
            "isSelected": true
          },
          {
            "mileageType": 1,
            "ratePerDay": 149,
            "ratePerMile": 0.37,
            "milage": 5000,
            "rentalTerm": 30,
            "isAvailable": true,
            "isSelected": true
          },
          {
            "mileageType": 1,
            "ratePerDay": 99,
            "ratePerMile": 0.37,
            "milage": 7000,
            "rentalTerm": 90,
            "isAvailable": true,
            "isSelected": true
          },
          {
            "mileageType": 1,
            "ratePerDay": 99,
            "ratePerMile": 0.37,
            "milage": 7000,
            "rentalTerm": 180,
            "isAvailable": true,
            "isSelected": true
          },
          {
            "mileageType": 1,
            "ratePerDay": 49,
            "ratePerMile": 0.37,
            "milage": 9000,
            "rentalTerm": 360,
            "isAvailable": true,
            "isSelected": true
          }
        ]
      },
      "vendorCompSummary": {
        "insExpiryDate": "2023-09-05T04:00:00.000Z",
        "legalName": "My company1",
        "address": {
          "zoneString": "America/Toronto",
          "geoLoc": {
            "geopoint": {
              "latitude": 43.6552843,
              "longitude": -79.6502544
            },
            "geohash": "dpz27e0qqw"
          },
          "timezone": "EDT",
          "streetNumber": "6050",
          "addressFormated": "6050 Dixie Rd, Mississauga, ON L5T 1A6, Canada",
          "stateProv": "ON",
          "utcOffset": -240,
          "country": "CA",
          "postalZipCode": "L5T 1A6",
          "lat": 43.6552843,
          "streetName": "Dixie Road",
          "city": "Mississauga",
          "long": -79.6502544
        },
        "revId": 3,
        "cid": "uqv4NdJNJBv0YHSLokmR",
        "dbStatus": 3,
        "officer": [
          {
            "contactUid": "waz9IaumjMfNrZreHOdzUtwYka32",
            "fullName": "customer owner1",
            "photoId": {
              "name": "officerPhotoId1.pdf",
              "path": "company/uqv4NdJNJBv0YHSLokmR/release/1662580790968_officerPhotoId1.pdf"
            },
            "email": "customersowner@yahoo.com",
            "phone": "14379885993"
          }
        ],
        "insDeductable": 500,
        "insCo": "Insurance1"
      },
      "bidNegoTerms": {
        "deposit": 0,
        "isRentAccepted": true,
        "isDatesAccepted": true,
        "isDepositAccepted": true,
        "signedTerms": false,
        "startDate": "2022-11-10T05:00:00.000Z",
        "endDate": "2022-11-29T05:00:00.000Z",
        "author": 1,
        "bidResponse": 3
      },
      "customerCompSummary": {
        "cvorNumber": "123-325-763",
        "usDotNumber": "1234567",
        "mcNumber": "1234567",
        "ifta": "0001257855",
        "insCo": "Ins CO",
        "insDeductable": 5000,
        "insExpiryDate": "2023-02-10T05:00:00.000Z",
        "nonOwnedTrailerIns": true,
        "nonOwnedTruckIns": false,
        "cid": "te86oD0gC9kSRfNkd4D7",
        "legalName": "Test Lessor Co.",
        "address": {
          "streetNumber": "7080",
          "streetName": "Dixie Road",
          "city": "Mississauga",
          "stateProv": "ON",
          "postalZipCode": "L5S 1B7",
          "country": "CA",
          "lat": 43.6766405,
          "long": -79.6774863,
          "geoLoc": {
            "geopoint": {
              "latitude": 43.6766405,
              "longitude": -79.6774863
            },
            "geohash": "dpz26zhdu5"
          },
          "addressFormated": "7080 Dixie Rd, Mississauga, ON L5S 1B7, Canada"
        },
        "officer": [
          {
            "photoId": {
              "isSecureUrl": false,
              "name": "officerPhotoId1 v2.pdf",
              "path": "company/te86oD0gC9kSRfNkd4D7/release/1662588799057_officerPhotoId1 v2.pdf"
            },
            "fullName": "Lessor Singh",
            "phone": "14164501525",
            "contactUid": "zYlWdkYiU2cSnhiYgr2TgckAZhS2",
            "email": "lessorvendor@yahoo.com"
          }
        ],
        "dbStatus": 3,
        "revId": 3
      }
    }
  );
}

export function getInvRec_DEBUG_ONLY() {

  const invoice = Invoice.parse(
    {
      "invoiceStatus": -10,
      "invoiceItems": [
        {
          "itemCat": -100,
          "quantity": 1,
          "itemStatus": -10,
          "unitPrice": 499.9,
          "tax": {
            "HST": 0
          },
          "subItems": [],
          "minMileageAmount": 0,
          "invoiceItemType": "rent",
          "name": "Security Deposit",
          "dueDate": "2022-11-09T23:49:09.082Z",
          "taxJuris": "ON",
          "invoiceType": 10,
          "totalAmount": 499.9,
          "itemId": 0,
          "orderId": "?"
        },
        {
          "itemCat": 0,
          "quantity": 1,
          "itemStatus": -10,
          "unitPrice": 295,
          "tax": {
            "HST": 38.35
          },
          "subItems": [],
          "minMileageAmount": 0,
          "invoiceItemType": "rent",
          "name": "Admin Fee (One Time)",
          "period": {
            "from": "2022-11-10T05:00:00.000Z",
            "to": "2022-11-16T05:00:00.000Z"
          },
          "dueDate": "2022-11-16T05:00:00.000Z",
          "taxJuris": "ON",
          "invoiceType": 10,
          "totalAmount": 333.35,
          "itemId": 1,
          "orderId": "?"
        },
        {
          "itemCat": 0,
          "quantity": 7,
          "itemStatus": -10,
          "unitPrice": 199,
          "tax": {
            "HST": 205.05
          },
          "subItems": [
            {
              "amount": 84.65,
              "type": "serviceFee",
              "name": "Service Fee",
              "isTaxable": true
            },
            {
              "amount": 69.65,
              "type": "environmentFee",
              "name": "Environmental Fee",
              "isTaxable": true
            },
            {
              "amount": 30,
              "type": "vehicleLicensingFee",
              "name": "Vehicle Licensing Fee",
              "isTaxable": true
            }
          ],
          "minMileageAmount": 0,
          "invoiceItemType": "rent",
          "serviceType": "truckRental",
          "name": "Rent Weekly # 1",
          "period": {
            "from": "2022-11-10T05:00:00.000Z",
            "to": "2022-11-16T05:00:00.000Z"
          },
          "dueDate": "2022-11-16T05:00:00.000Z",
          "taxJuris": "ON",
          "invoiceType": 10,
          "totalAmount": 1782.35,
          "itemId": 2,
          "orderId": "?"
        },
        {
          "itemCat": 0,
          "quantity": 7,
          "itemStatus": -10,
          "unitPrice": 199,
          "tax": {
            "HST": 205.05
          },
          "subItems": [
            {
              "amount": 84.65,
              "type": "serviceFee",
              "name": "Service Fee",
              "isTaxable": true
            },
            {
              "amount": 69.65,
              "type": "environmentFee",
              "name": "Environmental Fee",
              "isTaxable": true
            },
            {
              "amount": 30,
              "type": "vehicleLicensingFee",
              "name": "Vehicle Licensing Fee",
              "isTaxable": true
            }
          ],
          "minMileageAmount": 0,
          "invoiceItemType": "rent",
          "serviceType": "truckRental",
          "name": "Rent Weekly # 2",
          "period": {
            "from": "2022-11-17T05:00:00.000Z",
            "to": "2022-11-23T05:00:00.000Z"
          },
          "dueDate": "2022-11-23T05:00:00.000Z",
          "taxJuris": "ON",
          "invoiceType": 10,
          "totalAmount": 1782.35,
          "itemId": 3,
          "orderId": "?"
        },
        {
          "itemCat": 0,
          "quantity": 5,
          "itemStatus": -10,
          "unitPrice": 199,
          "tax": {
            "HST": 147.49
          },
          "subItems": [
            {
              "amount": 64.75,
              "type": "serviceFee",
              "name": "Service Fee",
              "isTaxable": true
            },
            {
              "amount": 49.75,
              "type": "environmentFee",
              "name": "Environmental Fee",
              "isTaxable": true
            },
            {
              "amount": 25,
              "type": "vehicleLicensingFee",
              "name": "Vehicle Licensing Fee",
              "isTaxable": true
            }
          ],
          "minMileageAmount": 0,
          "invoiceItemType": "rent",
          "serviceType": "truckRental",
          "name": "Rent Weekly # 3",
          "period": {
            "from": "2022-11-24T05:00:00.000Z",
            "to": "2022-11-28T05:00:00.000Z"
          },
          "dueDate": "2022-11-28T05:00:00.000Z",
          "taxJuris": "ON",
          "invoiceType": 10,
          "totalAmount": 1281.99,
          "itemId": 4,
          "orderId": "?"
        },
        {
          "itemCat": -95,
          "quantity": 1,
          "itemStatus": -10,
          "unitPrice": -499.9,
          "tax": {
            "HST": 0
          },
          "subItems": [],
          "minMileageAmount": 0,
          "invoiceItemType": "rent",
          "name": "Security Refund",
          "dueDate": "2022-11-29T05:00:00.000Z",
          "taxJuris": "ON",
          "invoiceType": 10,
          "totalAmount": -499.9,
          "itemId": 5,
          "orderId": "?"
        }
      ],
      "depositItems": [
        4999
      ],
      "numId": 39318659961518,
      "invoiceType": 10,
      "rentalPlan": {
        "mileageType": 1,
        "ratePerDay": 199,
        "ratePerMile": 0.37,
        "milage": 2000,
        "rentalTerm": 7,
        "isAvailable": true,
        "isSelected": true
      },
      "paymentMethod": "creditCard",
      "currencyCode": 0,
      "total": {
        "name": "",
        "tax": {
          "HST": 595.94
        },
        "amount": 4075.9999999999995,
        "totalAmount": 5180.04
      },
      "cid": "te86oD0gC9kSRfNkd4D7",
      "invoiceCat": 1
    }

  );

  const rentalPlan = RentalPlan.parse(
    {
      "mileageType": 1,
      "ratePerDay": 199,
      "ratePerMile": 0.37,
      "milage": 2000,
      "rentalTerm": 7,
      "isAvailable": true,
      "isSelected": true
    });

  invoice.cid = `cid1`;
  invoice.refId = 'bid1';  //
  invoice.invoiceCat = UserEnum.customer;
  // if (!!rentOption.deposit) {
  //   inv.depositItems = [rentOption.deposit];
  // }
  invoice.createdAt = new Date();
  return invoice;
}

export function getOrder_DEBUG_ONLY() {
  return Order.parse(
    {
      "orderItems": [],
      "pymtIndex": 0,
      "transactions": [],
      "id": "?",
      "pendingPymnt": {
        "name": "",
        "amount": 4584.1,
        "taxableAmount": 4584.1,
        "totalAmount": 5180.04,
        "tax": {
          "HST": 595.94
        }
      },
      "pymtSchedule": [
        {
          "orderType": 0,
          "dueDate": "2022-11-09T23:49:09.082Z",
          "pendingPymnt": {
            "name": "Payment Due on 09-Nov-2022",
            "amount": 499.9,
            "taxableAmount": 0,
            "totalAmount": 499.9,
            "tax": {
              "HST": 0
            }
          },
          "invItemId": "0"
        },
        {
          "orderType": 20,
          "dueDate": "2022-11-16T05:00:00.000Z",
          "pendingPymnt": {
            "name": "Payment Due on 16-Nov-2022",
            "amount": 1872.3,
            "taxableAmount": 1872.3,
            "totalAmount": 2115.7,
            "tax": {
              "HST": 243.4
            }
          },
          "invItemId": "1,2"
        },
        {
          "orderType": 20,
          "dueDate": "2022-11-23T05:00:00.000Z",
          "pendingPymnt": {
            "name": "Payment Due on 23-Nov-2022",
            "amount": 1577.3,
            "taxableAmount": 1577.3,
            "totalAmount": 1782.35,
            "tax": {
              "HST": 205.05
            }
          },
          "invItemId": "3"
        },
        {
          "orderType": 20,
          "dueDate": "2022-11-28T05:00:00.000Z",
          "pendingPymnt": {
            "name": "Payment Due on 28-Nov-2022",
            "amount": 1134.5,
            "taxableAmount": 1134.5,
            "totalAmount": 1281.99,
            "tax": {
              "HST": 147.49
            }
          },
          "invItemId": "4"
        },
        {
          "orderType": 20,
          "dueDate": "2022-11-29T05:00:00.000Z",
          "pendingPymnt": {
            "name": "Payment Due on 29-Nov-2022",
            "amount": -499.9,
            "taxableAmount": 0,
            "totalAmount": -499.9,
            "tax": {
              "HST": 0
            }
          },
          "invItemId": "5"
        }
      ],
      "nextPaymentDate": "2022-11-09T23:49:09.082Z",
      "currencyCode": 0,
      "invoiceType": 10,
      "cid": "te86oD0gC9kSRfNkd4D7",
      "orderCat": 1
    }
  );
}


