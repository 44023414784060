import { BehaviorSubject, Observable } from 'rxjs';
import { throttleTime, mergeMap, scan } from 'rxjs/operators';
import type { FirebaseRTDBService } from '@trent/services/firebase-rtdb.service';
import { Paging } from './paging';
import type { FirestoreService } from '@trent/services/firestore.service';
import { logger } from '../log/logger';

/**
 * An observable that keeps tracks of paging using Subject and provided function.
 * https://angularfirebase.com/lessons/infinite-virtual-scroll-angular-cdk/
 */
export class PagingObesrvable<T, paramType> {

  offset = new BehaviorSubject(null);

  infinite: Observable<any[]>;

  /** Paging Data used for getting the data. */
  pData: Paging;

  param: paramType;

  /**
   * @param db Database ref. to hit when data need to be fetched
   * @param dataFn Function to be called when next set of data is required.
   */
  constructor(
    private db: FirestoreService | FirebaseRTDBService,
    dataFn: (p: Paging, param?: paramType) => Observable<{ [key: string]: T }>,
    countFn?: (p: Paging, param?: paramType) => Observable<number>) {
    this.getBatch = dataFn;
    this.getTotalCount = countFn;
  }
  /**
   * Function that will fetch the data from db. This will be assigned by the
   * calling function.
   */
  private getBatch: (p: Paging, param?: paramType) => Observable<{ [key: string]: T }>;

  private getTotalCount: (p: Paging, param?: paramType) => Observable<number>;

  public getData(p: Paging, param?: paramType) {
    // logger.log('service function called');
    this.pData = { ...p };
    this.param = param;

    const batchMap: Observable<{ [key: string]: T }> =
      this.offset.pipe(
        throttleTime(500),
        mergeMap(n => this.getBatch(this.pData, param)),
        // scan((acc, batch) => {
        //   logger.log('paging accum is: ', Object.keys(acc).length, acc);
        //   logger.log('current page batch is: ', Object.keys(batch).length, batch);
        //   const r = { ...acc, ...batch };
        //   logger.log('result is: ', Object.keys(r).length, r);
        //   return r;
        // }, {})
      );
    return batchMap;
    // const r = batchMap.pipe(map(v => Object.values(v)));
  }

  public getCount(p: Paging, param: paramType): Observable<number> {
    this.pData = { ...p };
    return this.getTotalCount(p, param);
  }

  nextBatch(p?: Paging) {
    if (p == null) {
      p = this.pData;
    }
    // store full info in 'this', copy everything else.
    if (this.pData.full) {
      logger.log('paging.ts, Data is FULL');
      p.full = true;
      return;
    }
    this.pData.offset = p.offset;
    logger.log('next batch was called.');
    this.offset.next(p.offset);
  }

  unsubscribe() {
    if (!!this.offset) {
      this.offset.unsubscribe();
    }
  }

}
