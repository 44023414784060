import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SlidePanelComponent } from './slide-panel.component';



@NgModule({
    declarations: [
        SlidePanelComponent
    ],
    imports: [
        CommonModule
    ],
    exports: [
        SlidePanelComponent
    ]
})
export class SlidePanelModule { }
