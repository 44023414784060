import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsModule } from '@ngxs/store';
import { OrderState } from './order.state';
import { OrderService } from '@trent/services/order.service';


@NgModule({
  declarations: [],
  imports: [
    HttpClientModule,
    CommonModule,
    NgxsModule.forFeature([OrderState])
  ],
  providers: [
    OrderService
  ]
})
export class OrderStoreModule { }
