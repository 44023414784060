import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';
import { BasePage } from '@trent/models/UI/base.page';
import { SparePart } from '@trent/models/spare-part/spare-part';
import { SparePartParam } from '@trent/models/spare-part/spare-part-param';
import { StoreLocation } from '@trent/models/store-location/store-location';
import { DialogService } from '@trent/services/dialog/dialog.service';
import { EventService } from '@trent/services/event.service';
import { AllSparePartsRequested, SparePartRequested, SparePartState } from '@trent/store/spare-part';
import { StoreLocationState, StoreLocationsRequested } from '@trent/store/store-location-store';
import { map } from 'rxjs';

@Component({
	selector: 'trent-dpc-our-locations',
	templateUrl: './dpc-our-locations.component.html',
	styleUrls: ['../dpc-common.scss'],
})
export class DpcOurLocationsComponent extends BasePage<SparePart> implements OnInit {

	// Through this id capture page-html collection document
	pid: string = 'dpc-our-locations';
	pid1: string = 'dpc-our-locations-1';
	pid2: string = 'dpc-our-locations-2';

	spareParts: SparePart[];
	sParam: SparePartParam = {};
	city: string;
	address: string;
	phone: string;
	sparePartsArrLength: number;

	constructor(
		public store: Store,
		dialogService: DialogService,
		es: EventService,
		public activatedRoute: ActivatedRoute,
	) {
		super(store, dialogService, es);
	}

	ngOnInit(): void {
		/// store location id capture
		this.activatedRoute.params.subscribe(p => {
			this.sParam = { sid: p.id };
			this.getSpareParts();
		});

	}

	/**
	* @author KS
	* @purpose Get Spare Parts
	*/
	getSpareParts() {
		// Set Params
		const pageData = { size: 300, offset: 0, full: false };
		// Get Spare Parts from data store
		this.store.dispatch(new AllSparePartsRequested({ pData: pageData, param: this.sParam }));
		this.mSubscription.push(this.store.select(SparePartState.selectAllSpareParts)
			.pipe(map(clientFilterFn => clientFilterFn({ ...this.sParam })))
			.subscribe((s: SparePart[]) => {
				if (s.length > 0) {
					this.spareParts = s;
					this.city = s[0].storeLocationSummary?.address?.city;
					this.phone = s[0]?.storeLocationSummary?.phone;
					this.address = s[0]?.storeLocationSummary.address?.addressFormated;
					this.sparePartsArrLength = this.spareParts.length;
				} else {
					// the salesOption not having showing alternate address
					this.getStoreLocations();
					this.spareParts = null;
				}
			}));
	}
   /**
   * @author KS
   * @purpose Get Store Locations
   */
	getStoreLocations() {
		// Set Params
		const pageData = { size: 10, offset: 0, full: false };
		// Get Store Locations from data store
		this.store.dispatch(new StoreLocationsRequested({ pData: pageData, param: this.sParam }));
		this.mSubscription.push(this.store.select(StoreLocationState.selectAllStoreLocations)
			.pipe(map(clientFilterFn => clientFilterFn({ ...this.sParam })))
			.subscribe((s: StoreLocation[]) => {
				this.city = s[0]?.address?.city;
				this.phone = s[0]?.phone;
				this.address = s[0]?.address?.addressFormated;
			}));
	}

}