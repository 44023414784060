import { RentalPlan } from './rental-plan';


export enum RentalTerm {

  daily = 1,
  weekly = 7,
  monthly = 30,
  threeMonth = 90,
  sixMonth = 180,
  oneYear = 360
}






