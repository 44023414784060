import { Exclude, Expose, Type, instanceToInstance } from 'class-transformer';
import { BaseModel } from '../base/base-model';
import { PromoListType } from './promo-list-type';
import { IsEnum, ValidatorOptions } from 'class-validator';
import { IValidationMsg } from '../error-handling/validation-info';
import { CarrierCompanySummary } from '../company/company-carrier';
import { toPascalCase } from '../utility/helper';


export type ListValidationGroup = 'selectMode' | 'addMode' | 'updateMode';

@Exclude()
export class PromoListBase extends BaseModel {

  public static readonly collectionName = 'promo';
  public static readonly masterCompListId = 'master-company-aggregate';

  @Expose()
  @IsEnum(PromoListType)
  listType: PromoListType;

  carrierCompanySummary?: CarrierCompanySummary;

  constructor() {
    super();
  }

  /** when read from jason or from plain to class, the date objects may be stored as string. convert them to date. */
  sanitize() {
    // if data was recieved from firebase, date is stored as snapshot.
    super.sanitize();

  }
  validateSyncGroup(group: ListValidationGroup): IValidationMsg {
    return this.validateSync({ groups: [group] });
  }
  validateSync(options?: ValidatorOptions): IValidationMsg {
    const r = this.validateSyncBase(this, options);

    return r;
  }
  get companyFriendlyName() {
    if (this.carrierCompanySummary.legalName.toLocaleLowerCase() === this.carrierCompanySummary.name.toLocaleLowerCase()) {
      return this.carrierCompanySummary.name;
    }
    return `${toPascalCase(this.carrierCompanySummary.name)} (${this.carrierCompanySummary.legalName})`;
  }
}
