import { Order } from '@trent/models/finance/order/order';
import { Injectable } from '@angular/core';
import { BaseHttpService } from './base-http.service';
import { Store } from '@ngxs/store';
import { FirestoreService } from './firestore.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
// import { PagingObesrvable, Paging } from '@trent/models';
import { Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { IOrderParam, orderSearchServerCountQuery, orderSerachServerQuery } from '@trent/models/finance/order/order-param';
import { PagingObesrvable } from '@trent/models/observable-util/paging-obesrvable';
import { Paging } from '@trent/models/observable-util/paging';
import { query, where } from '@angular/fire/firestore';

@Injectable()
export class OrderService extends BaseHttpService {

  // orders: PagingObesrvable<Order, IOrderParam>;

  constructor(store: Store, private db: FirestoreService, private http: HttpClient) {
    super(store);
    this.apiName = 'api';
    // this.orders = new PagingObesrvable<Order, IOrderParam>(this.db, this.getOrders_getBatch);

  }

  public getOrderById(id: string | number) {
    return this.db.docWithInjectedId$<Order>(`${Order.collectionName}/${id}`);
  }

  public getOrdersByBidIDs(ids: (string | number)[]) {
    return this.db.colWithIdsInjected$<Order>(`${Order.collectionName}`, ref => query(ref, where('refId', 'in', ids)));
  }

  // #region Paging Load

  public getOrders_PagingObservable() {
    const p: PagingObesrvable<Order, IOrderParam> =
      new PagingObesrvable<Order, IOrderParam>(this.db, this.getOrders_getBatch, this.getCount);
    return p;
  }

  public getCount(p: Paging, param: IOrderParam): Observable<number> {
    return this.db
      .getDocumentCount$<Order>(Order.collectionName,
        ref => orderSearchServerCountQuery(ref, param));
  }
  // public getOrders(p: Paging, param: IOrderParam) {
  //   return this.orders.getData(p, param);
  // }

  /**
   * Get the next batch of the data from server.
   * @param p paging information.
   */
  private getOrders_getBatch(p: Paging, param: IOrderParam): Observable<{ [key: string]: Order }> {
    if (param.orderBy == null) { param.orderBy = 'updatedAt'; }
    if (param.orderDirection == null) { param.orderDirection = 'desc'; }
    return this.db
      .colWithIdsInjectedNew$<Order>(`${Order.collectionName}`, ref => orderSerachServerQuery(ref, param, p))
      .pipe(
        tap(arr => {
          if (arr == null || arr.length === 0) {
            console.log('All data is recevied, Paging is FULL');
            p.full = true;
          } else {
            // HG TODO: Fix the usage of lastDoc in the server query functin insdie order-param.
            p.lastDoc = arr[arr.length - 1];
          }
        }),
        map(arr => {
          // console.log('From Server, before mapping is applied: ', arr);

          // convert array to { [id: string] = CompanyFleet} object.
          const arrayAsObj = arr.reduce((acc, cur) => {
            const id = cur.id;
            const data = cur;
            return { ...acc, [id]: data };
          }, {});

          // console.log('after converting to array object dic ', arrayAsObj);
          return arrayAsObj;
        })
      );
  }

  // #endregion
}
