<div [class]="'primary ' + className">
    <div *ngIf="!isFullPage && showHeader" class="fixed-header" [ngClass]="{'fixed-header-mobile': !!isHandset, 'fixed-header-dtop': !isHandset}">
      <span class="closebtn" (click)="dialogRef.close()">&times;</span>
      <div class="header">
        {{header}}
      </div>
    <!-- mat-dialog-title  -->
  </div>
    <mat-card appearance="outlined" *ngIf="!!page && !!page.sectionsOrdered && !dataNotFound else notFound">
      <span *ngIf="!showHeader" class="closebtn" (click)="dialogRef.close()">&times;</span>
      <mat-card-content style="padding:10px !important">
        <trent-ck-editor-ctrl [displayMode]="displayMode" [showAuto] = "showAuto" [readSection]="readSection" [page] = 'page' [isCenterVideo]="data?.boxType==='center-video'"></trent-ck-editor-ctrl>      
      </mat-card-content>
    </mat-card>
</div>


<!-- <ng-template #fullPageHder>
  <h3>Full Page Header</h3>
</ng-template> -->

<ng-template #notFound>
  <mat-card appearance="outlined" *ngIf="!showBusy">
    <mat-card-header>
      <div mat-card-avatar>
        <mat-icon color='warn' matSuffix>error</mat-icon>
      </div>
      <mat-card-title>No Information Found</mat-card-title>
      <mat-card-subtitle>No data exists in the database for this page.</mat-card-subtitle>
    </mat-card-header>
  </mat-card>
</ng-template>




<!-- <div mat-dialog-actions>
    <button mat-stroked-button (click)="dialogRef.close(false)" type="button" [color]="className" class="md-18 right">
      <mat-icon>close</mat-icon> Cancel
    </button>
    <button mat-button (click)="dialogRef.close(true)" type="button" [color]="className" class="mat-raised-button md-18 right">
      <mat-icon>done</mat-icon> OK
    </button>
  </div> -->
