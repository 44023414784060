import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsModule } from '@ngxs/store';
import { RentalProductState } from './rental-product.state';
import { RentalService } from '@trent/services/rental.service';


@NgModule({
    imports: [
      CommonModule,
      NgxsModule.forFeature([RentalProductState])
    ],
    providers: [
      RentalService,
      ]
  })
  export class RentalProductStoreModule { }
