import { IsDefined, IsEnum, validateSync, IsArray, ValidateNested, ValidateIf, IsBoolean, MinLength, MaxLength, Equals, ArrayNotEmpty } from 'class-validator';
import { IPointGeo } from '../product-search/interfaces';
import { plainToInstance, instanceToInstance, Exclude, Expose, Type } from 'class-transformer';
import { sanitizeDateIPoint, toValidationError } from '../utility';
import { IValDbStatusResult, IValidationMsg } from '../error-handling/validation-info';
import { Picture } from './picture';
import * as _ from 'lodash';
import { cleanupUndefined } from '../utility/helper';
import { IncidentalCharges } from './Incidental-charges';
import { isAfter } from 'date-fns';
import { Inspection, } from './inspection';
import { InspectionStatus, actionName } from './inspection-status';
// import { checkDeletedPictures } from './inspection-helper';
import { FileInfo, IFileMoveData } from '../media/file-info';
import { DbStatus } from '../base/db-status';
import { ProductBase } from '../product/product-base';
import { ProductType } from '../product/product-type';
import { InspectionDataTruck } from './inspection-data-truck';
import { InspectionDataTrailer } from './inspection-data-trailer';
import { checkPicturesDeleted, hasPicArrayChanged } from '../utility/compare-pics-utl';

export type InspectionValidationGroup = 'data' | 'pictures' | 'videos' | 'incidental' | 'unitDetails' | 'interior' | 'tiresSteer' | 'tiresDrive' | 'tiresTrailerDS' | 'tiresTrailerPS';
export const data: InspectionValidationGroup = 'data';
export const pictures: InspectionValidationGroup = 'pictures';
export const videos: InspectionValidationGroup = 'videos';
export const incidental: InspectionValidationGroup = 'incidental';
export const unitDetails: InspectionValidationGroup = 'unitDetails';
export const interior: InspectionValidationGroup = 'interior';
export const tiresSteer: InspectionValidationGroup = 'tiresSteer';
export const tiresDrive: InspectionValidationGroup = 'tiresDrive';
export const tiresTrailerDS: InspectionValidationGroup = 'tiresTrailerDS';
export const tiresTrailerPS: InspectionValidationGroup = 'tiresTrailerPS';



export enum InspectionResponse {
  initialize = 10,
  accept = 20, //
  update = 30, //
  dispute = 40,
  customerExpired = 50, // to be set by system when customer does not respond
  vendorExpired = 60 // to be set by system when vendor does not respond
}

@Exclude()
export class InspectionData {


  constructor() {
    // super();
    this.pictures = [];
    this.location = { geopoint: { latitude: null, longitude: null }, geohash: null };
    this.location.geopoint = { latitude: null, longitude: null };
    this.isIncidentalCharges = false;
    // if (!this.incidentalCharges) {
    //   this.incidentalCharges = new IncidentalCharges();
    // }
  }
  /** property used on verify form */
  @Expose()
  @IsBoolean()
  showVerify = false;

  @Expose()
  @IsEnum(ProductType)
  productType: ProductType;

  @Expose()
  // @IsDefined()
  location: IPointGeo;

  @Expose()
  @IsArray({ message: 'Upload Pictures', groups: [pictures] })
  @ArrayNotEmpty({ message: 'Upload Pictures', groups: [pictures] })
  @Type(() => Picture)
  pictures: Picture[] = [];

/**
 * @author - PT
 * @purpose - key to store the videos which are uploaded during the inspection
 */
  @Expose()
  // @IsArray({ message: 'Upload Videos', groups: [videos] })
  // @ArrayNotEmpty({ message: 'Upload Videos', groups: [videos] })
  @Type(() => FileInfo)
  videos: FileInfo[] = [];


  // @Expose()
  // @IsInt({ message: 'Enter milage', groups: [data] })
  // milage = 0;

  // @Expose()
  // @Min(0, { message: 'Invalid fuel level', groups: [data] })
  // @Max(1, { message: 'Invalid fuel level', groups: [data] })
  // @IsNumber(undefined, { message: 'Invalid fuel level', groups: [data] })
  // fuelLevel = 0;

  // @Expose()
  // @Min(0, { message: 'Invalid DEF level', groups: [data] })
  // @Max(1, { message: 'Invalid DEF level', groups: [data] })
  // @IsNumber(undefined, { message: 'Invalid DEF level', groups: [data] })
  // dpfLevel = 0;

  // @Expose()
  // @IsString({ message: 'Enter Plate No.', groups: [data] })
  // plateNo: string;

  @Expose()
  @IsBoolean({ message: 'VerifyPlate No.', groups: [unitDetails] })
  @Equals(true, { message: 'Verify Plate No.', groups: [unitDetails] })
  isPlateNoVerified = false;


  @Expose()
  @IsBoolean({ message: 'Verify Vin No.', groups: [unitDetails] })
  @Equals(true, { message: 'Verify Vin No.', groups: [unitDetails] })
  isVinVerified = false;



  // @Expose()
  // @Max(999999, { message: 'Enter Last 6 digits of VIN', groups: [data] })
  // @Min(99999, { message: 'Enter Last 6 digits of VIN', groups: [data] })
  // vinLastSix: number;

  notes?: string;



  @Expose()
  @IsEnum(InspectionResponse, { message: 'Select Accept or Update Inspection Response', groups: [data] })
  inspectionResponse: InspectionResponse;

  // @ValidateIf(o => !!o.isincidentalCharges)
  // @IsDefined({ message: 'IncidentalCharges information is required', groups: [data, IncidentalCharges.gName] })
  // @ValidateNested({ message: 'IncidentalCharges info is required', groups: [data, IncidentalCharges.gName] })
  // @Expose()
  // @Type(() => IncidentalCharges)
  // incidentalCharges: IncidentalCharges;

  @Expose()
  @IsBoolean({ message: 'IncidentalCharges info is required', groups: [data, IncidentalCharges.gName] })
  isIncidentalCharges = false;


  @Expose()
  @ValidateIf(o => !!o.exteriorInspectionComments)
  @MaxLength(200)
  exteriorInspectionComments;

  picturesAdded: Picture[] = [];
  picturesDeleted: Picture[] = [];

  // tslint:disable-next-line:max-line-length
  inspectionType: InspectionStatus.vendorDropped | InspectionStatus.customerPicked | InspectionStatus.customerDropped | InspectionStatus.vendorPicked;

  get inspectionTypeString() {
    switch (this.inspectionType) {
      case InspectionStatus.vendorDropped:
      case InspectionStatus.customerPicked:
        return 'PDI';
      case InspectionStatus.customerDropped:
      case InspectionStatus.vendorPicked:
        return 'Return';
      default:
        return null;
    }
  }


  public static parse(obj) {
    // if (obj instanceof InspectionDataTruck) {
    //   return <InspectionDataTruck>obj;
    // } else if (obj instanceof InspectionDataTrailer) {
    //   return <InspectionDataTrailer>obj;

    // }

    // if (obj == null) { return null; }
    // switch ((obj as InspectionData).productType) {
    //   case ProductType.truck:
    //     return InspectionDataTruck.parse(obj) as InspectionData;
    //   case ProductType.trailer:
    //     return InspectionDataTrailer.parse(obj) as InspectionData;


    //   default:
    //     return null;
    //     break;
    // }

    if (obj instanceof (InspectionData)) { return <InspectionData>obj; }

    const m = plainToInstance<InspectionData, any>(InspectionData, sanitizeDateIPoint(obj));
    m.sanitize();
    return m;
  }
  canChargeInscidental() {

  }
  sanitize() {
    this.pictures.forEach(element => {
      element.sanitize();
    });
    // super.sanitize();

  }
  clone() {
    const t = instanceToInstance(this);
    t.sanitize();
    return t;
  }

  validateSyncGroup(group: InspectionValidationGroup, inspection: Inspection, action: InspectionStatus ): IValidationMsg {
    return this.validateSync({ groups: [group], inspection, action });
  }

  validateSync(options?: any, inspection?: Inspection, action?: InspectionStatus): IValidationMsg {
    // const r = this.validateSyncBase(this, options);
    // for nested entry for address, add address group in it.
    if (!!options && !!options.groups && options.groups.indexOf(data) > -1 && !!this.isIncidentalCharges) {
      options.groups.push(IncidentalCharges.gName);
    }
    const m = validateSync(this, options);
    const r = toValidationError(m);
    if (!options) {

      if (inspection?.canEdit(action) === false) {
        r['action'] = [`${actionName(action)} is locked`];
      }
      if (this.isPlateNoVerified === false) {
        r['isPlateNoVerified'] = ['Verify Plate number'];
      }
      if (this.isVinVerified === false) {
        r['isVinVerified'] = ['Verify VIN'];
      }
      if (this.pictures.length === 0) {
        r['pictures'] = ['Add pictures'];
      }
      if (!!inspection) {
        if (!!inspection.isExpired) {
          r['expired'] = ['Pick Drop is expired'];
        }
        // if (this.plateNo !== (<TruckSummary>pickDrop.productSummary).plateNo) {
        //   r['plateNo'] = ['Plate number does not match the contract'];
        // }
        // if (this.vinLastSix !== +(<TruckSummary>pickDrop.productSummary).vin.substr(-6)) {
        //   r['vinLastSix'] = ['Vin number does not match the contract'];
        // }
      }
      if (!!action) {
        // check action against the PickDropStatus, check at save and at the functions
        switch (inspection.status) {
          case InspectionStatus.rentStartHandShake:
            if (action <= InspectionStatus.rentStartHandShake) {
              r['inspectionResponse'] = ['Record is locked cannot update'];
            }
            break;
          case InspectionStatus.finalHandShake:
            if (action <= InspectionStatus.finalHandShake) {
              r['inspectionResponse'] = ['Record is locked cannot update'];
            }
            break;
          case InspectionStatus.customerDropped:
          case InspectionStatus.vendorPicked:
            if (action <= InspectionStatus.rentStartHandShake) {
              r['inspectionResponse'] = ['Record is locked cannot update'];
            }
            break;
          case InspectionStatus.customerDisputed:
            if (action !== InspectionStatus.customerDropped) {
              r['inspectionResponse'] = ['Record is locked cannot update'];
            }
            break;
          default:
            break;
        }
        // if submitted by customer incidental charges cannot be altered
        // if (action !== InspectionStatus.vendorPicked) {
        //   if (!!inspection.vendorPick) {
        //     if (this.isIncidentalCharges !== inspection.vendorPick.isIncidentalCharges) {
        //       r['isIncidentalCharges'] = ['Record is locked for incidental Charges cannot be update'];
        //     }
        //     console.log('_.flatMap(this.incidentalCharges)', _.flatMap(this.incidentalCharges));
        //     console.log('_.flatMap(pickDrop.vendorPick.incidentalCharges)', _.flatMap(inspection.vendorPick.incidentalCharges));

        //     if (!_.isMatch(_.flatMap(this.incidentalCharges), _.flatMap(inspection.vendorPick.incidentalCharges))) {
        //       r['incidentalCharges'] = ['Record is locked for incidental Charges cannot be update'];
        //     }
        //     if (!!inspection.vendorPick.incidentalCharges && !this.isIncidentalCharges
        //       && _.isMatch(_.flatMap(this.incidentalCharges), _.flatMap(inspection.vendorPick.incidentalCharges))) {
        //       r['incidentalCharges'] = ['Incidental Charges should be true when customer accepted incidental charges'];
        //     }
        //   }
        //   if (action <= InspectionStatus.rentStartHandShake || action >= InspectionStatus.finalHandShake) {
        //     if (!!this.isIncidentalCharges) {
        //       r['isIncidentalCharges'] = ['isIncidentalCharges cannot be true for PDI'];
        //     }
        //     if (!!this.incidentalCharges) {
        //       r['incidentalCharges'] = ['incidentalCharges cannot be set for PDI'];
        //     }
        //   }
        // }
        // if (!!this.isIncidentalCharges && !this.incidentalCharges) {
        //   r['isIncidentalCharges'] = ['isIncidentalCharges should be false if incidentalCharges are not added'];
        // }
        // if (!this.isIncidentalCharges && !!this.incidentalCharges) {
        //   r['isIncidentalCharges'] = ['if incidentalCharges are added, isIncidentalCharges should be true'];
        // }

      }
      if (action === InspectionStatus.customerPicked && !inspection?.checkDeletedPictures(this, action)) {
        r['pictures'] = ['Unauthorized to remove pictures'];
      }
    }
    return r;
  }


  /** validate inspection response against the properties set by client
   * for 'accept' response there should be no difference in properites db and set by client
   */
  validateInspResponse(pd: Inspection): IValidationMsg {
    // const r = this.validateSyncBase(this);
    const m = validateSync(this);
    const r = toValidationError(m);
    let d: any;
    switch (pd.status) {
      case InspectionStatus.customerPicked:
        d = pd.clone().customerPick;
        break;
      case InspectionStatus.vendorDropped:
        d = pd.clone().vendorDrop;
        break;
      case InspectionStatus.customerDropped:
        d = pd.clone().customerDrop;
        break;
      case InspectionStatus.vendorPicked:
        d = pd.clone().vendorPick;
        break;
      default:
        break;
    }
    let c = this.clone();
    // remove location and inspectionResponse before properties comparison. Location can be different for vendor and customer.
    delete c.location;
    delete c.inspectionResponse;
    c = <any>cleanupUndefined(InspectionData.parse(c));
    console.log('client data', c);

    if (!!d) {
      // remove location before properties comparison. Location can be different for vendor and customer.
      delete d.location;
      delete d.inspectionResponse;
      d = cleanupUndefined(InspectionData.parse(d));
      console.log('db data', d);
      console.log(_.flatMap(c));
      console.log(_.flatMap(d));

      if (_.isMatch(_.flatMap(c), _.flatMap(d)) && this.inspectionResponse === InspectionResponse.update) {
        r['inspectionResponse'] = ['No change in data, select Accept as response'];
      }
      if (!_.isMatch(_.flatMap(c), _.flatMap(d)) && this.inspectionResponse === InspectionResponse.accept) {
        r['inspectionResponse'] = ['changes in data, cannot have Accept as response'];
      }
    } else {
      if (this.inspectionResponse !== InspectionResponse.initialize) {
        r['inspectionResponse'] = ['incorrect response'];
      }
    }
    return r;
  }

  /**
   * @deprecated
   * sets user response when completing inspection.
   * compares to inspection to previously completed inspection by other party and sets reponse @param accept or @param updated
   * @param inspection Inspection
   */
  setResponse(action: InspectionStatus, inspection: Inspection, d: InspectionData) {
    switch (action) {
      case InspectionStatus.customerPicked:
        if (inspection.status === InspectionStatus.vendorDropped) {
          const tempPDI = inspection.clone();
          tempPDI.customerPick = d;
          const b: boolean = this.isInspectionChanged(tempPDI);
          if (!!b) {
            this.inspectionResponse = InspectionResponse.update;
          } else {
            this.inspectionResponse = InspectionResponse.accept;
          }
        } else {
          throw new Error(`[inspection-data] invalid action ${action} for ${inspection.status}`);
        }
        break;
      case InspectionStatus.vendorDropped:
        if (inspection.status === InspectionStatus.initial) {
          this.inspectionResponse = InspectionResponse.initialize;
        } else if (inspection.status < InspectionStatus.rentStartHandShake) {
          this.inspectionResponse = InspectionResponse.accept;
        } else {
          throw new Error(`[inspection-data] invalid action ${action} for ${inspection.status}`);
        }
        break;
      case InspectionStatus.customerDropped:
      case InspectionStatus.vendorPicked:
        const tempReturn = inspection.clone();
        const b: boolean = this.isInspectionChanged(tempReturn);

        if (inspection.status === InspectionStatus.rentStartHandShake) {

          this.inspectionResponse = InspectionResponse.accept;
          // this.inspectionResponse = InspectionResponse.initialize;
        } else if (inspection.status > InspectionStatus.rentStartHandShake && inspection.status < InspectionStatus.customerDisputed) {
          this.inspectionResponse = InspectionResponse.accept;
        } else {
          throw new Error(`[inspection-data] invalid action ${action} for ${inspection.status}`);
        }
        break;
      default:
        throw new Error(`[inspection-data] invalid action ${action} for ${inspection.status}`);
    }
  }
  /**
   * update file path. From delegate's folder to inspection folder 
   * @param phone delegate's Phone
   */
  // `inspection-media/${this.delegatePh}/${this.inspection.productSummary.pid}/${this.iRootId}`
  // `inspection-media/${this.inspection.productSummary.pid}/${this.iRootId}`
  updateFilePathsDelegatedInspection(phone: string): IFileMoveData[] {
    const R: IFileMoveData[] = [];

    for (const p of this.pictures) {
      p.url = null; // set url to null. URL will change when file is moved
      const r = this.updatePathToRemoveDelegate(p, phone);
      if (!!r) { R.push(r); }
    }
    for (const iP of (this as any).interiorPictures) {
      iP.url = null; // set url to null. URL will change when file is moved
      const r = this.updatePathToRemoveDelegate(iP, phone);
      if (!!r) { R.push(r); }
    }
    for (const v of this.videos) {
      v.url = null; // set url to null. URL will change when file is moved
      const r = this.updatePathToRemoveDelegate(v, phone);
      if (!!r) { R.push(r); }
    }
    return R;

  }
  /**
   * Removes "delegateInspection/@param phone" from the path
   * @param p Picture
   * @param phone delegate's phone
   */
  // /inspection-media/delegateInspection/${phone}/${pid}/${inspectionId}
  // /inspection-media/${pid}/${inspectionId}
  updatePathToRemoveDelegate(p: FileInfo, phone: string): IFileMoveData {
    if (!!p && !!p.path && p.path.trim().length > 0) {
      const r: IFileMoveData = { oldPath: p.path, newPath: null, file: p };
      p.path = p.path.replace(`/delegateInspection/${phone}/`, `/`);
      r.newPath = p.path;
      return (r.newPath === r.oldPath) ? null : r;
    } else {
      return null;
    }

  }
  /** Analyze product to see if more information is needed or suggest the user
   * to submit for approval.*/
  getValDbStatus(inspection: Inspection, action: InspectionStatus): IValDbStatusResult {
    const result: IValDbStatusResult = {
      pass: false,
      message: undefined,
      groupResult: {}
    };

    let x: { groupPass: boolean; message: string; };
    // unitDetails
    let r = this.validateSyncGroup(unitDetails, inspection, action);
    x = { groupPass: null, message: '' };
    x.groupPass = Object.keys(r).length === 0;
    x.message = (x.groupPass) ? 'Unit Details' : 'Unit information is required'; //  `${Object.values(r)[0]}`
    result.groupResult[unitDetails] = x;

    // // rate
    // r = this.validateSyncGroup(interior, inspection, action);
    // x = { groupPass: null, message: '' };
    // x.groupPass = Object.keys(r).length === 0;
    // x.message = (x.groupPass) ? 'Interior Inspection' : 'Interior inspection is required';
    // result.groupResult[interior] = x;
    // // steer tires
    // r = this.validateSyncGroup(tiresSteer, inspection, action);
    // x = { groupPass: null, message: '' };
    // x.groupPass = Object.keys(r).length === 0;
    // x.message = (x.groupPass) ? 'Steer Tire Inspection' : 'Steer Tire inspection is required';
    // result.groupResult[tiresSteer] = x;
    // // drive tires
    // r = this.validateSyncGroup(tiresDrive, inspection, action);
    // x = { groupPass: null, message: '' };
    // x.groupPass = Object.keys(r).length === 0;
    // x.message = (x.groupPass) ? 'Drive Tire Inspection' : 'Drive Tire inspection is required';
    // result.groupResult[tiresDrive] = x;
    // pictures
    r = this.validateSyncGroup(pictures, inspection, action);
    x = { groupPass: null, message: '' };
    x.groupPass = Object.keys(r).length === 0;
    x.message = (x.groupPass) ? 'Exterior Inspection' : 'Exterior pictures are required';
    result.groupResult[pictures] = x;

    // r = this.validateSyncGroup(videos, inspection, action);
    // x = { groupPass: null, message: '' };
    // x.groupPass = Object.keys(r).length === 0;
    // x.message = (x.groupPass) ? 'Record videos' : 'Videos are required';
    // result.groupResult[videos] = x;


    // is it passed all the tests? (True means it failed here)
    result.pass = !Object.keys(result.groupResult).some((k) => !result.groupResult[k].groupPass);
    // passed.

    return result;
  }
  setPropertiesToReview() {
    this.isPlateNoVerified = false;
    this.isVinVerified = false;
  }
  /**
   * @deprecated
   */
  isInspectionChanged(inspection: Inspection): boolean {
    if (!!inspection.additionalPDICustomerExtComments) {
      return true;
    }
    if (!!inspection.additionalPDICustomerIntComments) {
      return true;
    }
    inspection.addPictureComments();
    const idxCustomerExtPicsWithComments = inspection.customerPick.pictures.findIndex(f => !!f.comment);
    if (idxCustomerExtPicsWithComments > -1) {
      return true;
    }
    inspection.addPictureComments();
    const idxCustomerIntPicsWithComments = (inspection.customerPick as InspectionDataTruck).interiorPictures.findIndex(f => !!f.comment);
    if (idxCustomerIntPicsWithComments > -1) {
      return true;
    }
    return false;
  }
  setInspectionType(action: InspectionStatus) {
    switch (action) {
      case InspectionStatus.vendorDropped:
        this.inspectionType = InspectionStatus.vendorDropped;
        break;
      case InspectionStatus.customerPicked:
        this.inspectionType = InspectionStatus.customerPicked;
        break;
      case InspectionStatus.customerDropped:
        this.inspectionType = InspectionStatus.customerDropped;
        break;
      case InspectionStatus.vendorPicked:
        this.inspectionType = InspectionStatus.vendorPicked;
        break;

      default:
        break;
    }
  }

  /** return true if exterior inspection comments have changed */
  hasExtCommentsChanged(ref: InspectionData) {
    return ref.exteriorInspectionComments !== this.exteriorInspectionComments;
  }
  hasExtPicChanged(ref: InspectionData) {
    console.log({ this: this }, { ref });
    return hasPicArrayChanged(ref.pictures, this.pictures);
  }
  hasExtPicDeleted(ref: InspectionData) {
    console.log({ this: this }, { ref });
    if (!ref) {
      return false;
    }
    return checkPicturesDeleted(ref.pictures, this.pictures);
  }



}
