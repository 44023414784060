export enum TaskCategory {
  Syestem = 0,
  Group = 1,
  Company = 2,
  Individual = 3
}

export enum TaskType {
  // Company actions
  CompanyCreated = 10,
  CompanyUpdated = 20,
  RequestCompanyApproval = 100,
  CompanyReleased = 110,
  // Product actions
  RequestReleaseTrailer = 200,
  TrailerReleased = 201,
  RequestReleaseTruck = 210,
  TruckReleased = 211,

  // Bid actions
  /** Vendor task when customer submits bid */
  DraftBidCreated = 30,
  RequestBidApprovalVendor = 300,
  RequestBidApprovalVendorForSales = 351,
  BidApprovedByVendor = 400,
  BidApprovedByVendorForSales = 400,
  BidRejectedByVendor = 500,
  BidRejectedByVendorForSales = 551,

  // Inspection actions
  /** Vendor task when customer submits bid */
  VendorCompetedPDI = 301,
  VendorPDIExpired = 302,
  CustomerCompetedPDI = 311,
  CustomerPDIExpired = 312,

  CustomerCompetedReturnIspection = 321,
  CustomerReturnInspectionExpired = 322,
  VendorCompetedReturnIspection = 331,
  VendorReturnIspectionExpired = 332,

  RequestAddPromoDriver = 5,
  RequestAddPromoDriverNewUser = 8,
  PromoDriverRemoved = 9,
  TripAssignedToDriver = 6,
  TripTrackerUpdate = 7,

  ptsContactEnquiry = 501,
  ptsFinanceDepartmentEnquiry = 502,
  ptsFinanceApplicationEnquiry = 503,
  ptsGetAQuoteEnquiry = 504,
  ptsBookAViewingEnquiry = 505,
  ptsApplyForFinancingEnquiry = 506,
  ptsTradeInAppraisalEnquiry = 507,
  ptsSpecialEnquiry = 508,
  SalesTruckCreated = 509,
  SalesTruckUpdated = 510,
  RequestSalesTruckApproval = 511,
  SalesTruckReleased = 512,
  SalesTrailerCreated = 513,
  SalesTrailerUpdated = 514,
  RequestSalesTrailerApproval = 515,
  SalesTrailerReleased = 516,


  ContactUs = 700, //MKN - Contact us enquiry
}

// Payload of JSON data to send to Cloud Tasks, will be received by the HTTP callback
export enum GCTaskType {
  expirePDI = 10,
  expireReturnInspection = 11,
  expireRentOption = 20,

}
