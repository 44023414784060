import { Exclude, plainToInstance, instanceToInstance } from 'class-transformer';
import { FileInfo } from '../media/file-info';
import { Picture } from '../inspection/picture';
import { sanitizeDateIPoint } from '../utility';
import { IValidationMsg } from '../error-handling';
import { validateSync, ValidationError } from 'class-validator';
import { toValidationError } from '../utility/validation-helper';
import { TripBase } from './trip-base';
import { TripActivityType } from './trip-activity-type';
import { TripTransaction } from './trip-transaction';
import { TransactionStatus } from './transaction-status';
import { IPoint } from '../product-search/interfaces';

export interface LocStaticMapMatadataI {
  tripId: string | number;

  activity: TripActivityType;

  trackingNosStg: string;

  iPoint: IPoint;
}
@Exclude()
export class LocStaticMap extends FileInfo {
  // public static readonly nonTriggerBucket = 'lloop-debug';
  public static readonly folder = 'staticMap';


  constructor() {
    super();
  }
  // tripId: string | number;

  // tripTransactionIndex: number;

  // activity: TripActivityType;

  // trackingNosStg: string;

  metadata: LocStaticMapMatadataI;

  public static parse(obj) {
    if (obj == null) { return null; }

    if (obj instanceof (Picture)) { return <Picture>obj; }

    const m = plainToInstance<Picture, any>(Picture, sanitizeDateIPoint(obj));
    m.sanitize();
    return m;
  }
  sanitize() {
  }

  clone() {
    const t = instanceToInstance(this);
    // t.sanitize();
    return t;
  }
  validateSync(): IValidationMsg {
    const r = this.validateSyncBase(this);
    return r;
  }

  /** Helper Method  to convert validation errors to easy display messages messages */
  protected toValidationError(ve: ValidationError[], prefix?: string): IValidationMsg {
    return toValidationError(ve);
  }

  createLocMapImage(tripId: string | number, tripTrans: TripTransaction, imgUrl?: string): LocStaticMap {
    if ((tripTrans.activity === TripActivityType.pickUp || tripTrans.activity === TripActivityType.delivery)
    && !!tripTrans.autoCompleteTime && !!tripTrans.autoCompleteTime.leftAt
    && tripTrans.transactionStatus === TransactionStatus.autoCompleted) {
      // tslint:disable-next-line:max-line-length
      const tnos = tripTrans.activity === TripActivityType.pickUp ? tripTrans.trackingNos : tripTrans.activity === TripActivityType.delivery ?
        Object.keys(tripTrans.delTrackingNos) : null;

      this.name = `${tripTrans.address.geoLoc.geopoint.latitude}_${tripTrans.address.geoLoc.geopoint.longitude}`;
      this.metadata = {
        tripId: tripId,
        trackingNosStg: tnos.join(),
        activity: tripTrans.activity,
        iPoint: tripTrans.address.geoLoc.geopoint
      };
      this.url = imgUrl;
      this.path = `${LocStaticMap.folder}/${tripId}`;
      return this;
    } else {
      return null;
    }
  }



}
