import { instanceToInstance, Exclude, Expose, plainToInstance } from 'class-transformer';
import { ValidatorOptions } from 'class-validator';
import { CustomerCompanySummary } from '../company/company-fleet';
import { IValidationMsg } from '../error-handling/validation-info';
import { IPointGeo } from '../product-search/interfaces';
import { sanitizeDateIPoint } from '../utility/sanitize-helper';
import { ItemStatus } from './item-status';
import { PromoListBase } from './promo-list-base';
import { PromoListItem } from './promo-list-item';
import { toSentence } from '../utility/helper';
import { companyFriendlyName } from '../company/company-helper';
import { CompanyBase } from '../company/company-base';
import { logger } from '../log/logger';
import { DbRule } from '../base/db-rule';
import { CompanyStatus } from '../company/company-status';

@Exclude()
export class VendorPortfolioItem extends PromoListItem {

  constructor() {
    super();
  }

  @Expose()
  name: string;
  @Expose()
  legalName: string;
  @Expose()
  addressFormated: string;
  @Expose()
  geohash: string;
  @Expose()
  isLessee: boolean;
  @Expose()
  isLessor: boolean;

  @Expose({ toClassOnly: true })
  get companyFriendlyName() {
    return companyFriendlyName(this.name, this.legalName);
  }
  @Expose({ toClassOnly: true })
  get uiDescription() {
    return `${this.companyFriendlyName}, ${this.addressFormated}`;
  }


  public static parse(obj) {
    if (obj == null) { return null; }
    const m = plainToInstance<VendorPortfolioItem, any>(VendorPortfolioItem, sanitizeDateIPoint(obj));
    // m.sanitize();
    return m;
  }
}

@Exclude()
export class VendorPortfolio extends PromoListBase {
  public static readonly idName = 'rental-trailer-aggregate';
  @Expose()
  /** Retnal Trailer aggregate list, @param key | Customer company id */
  list: { [key: string]: VendorPortfolioItem };

  /** Vendor compnay Id */
  @Expose()
  cid: string | number;

  @Expose()
  companiesWithAccess: string[];

  @Expose()
  isMasterList: boolean;

  public static parse(obj) {
    if (obj == null) { return null; }
    const m = plainToInstance<VendorPortfolio, any>(VendorPortfolio, sanitizeDateIPoint(obj));
    for (const key in m.list) {
      if (Object.prototype.hasOwnProperty.call(m.list, key)) {
        const element = m.list[key];
        m.list[key] = VendorPortfolioItem.parse(element);
      }
    }
    m.sanitize();
    return m;
  }
  validateSync(options?: ValidatorOptions): IValidationMsg {

    const r = this.validateSyncBase(this);
    return r;

  }
  clone() {
    const t = instanceToInstance(this);
    t.sanitize();
    return t;
  }

  /**
  * adds and updates VendorPortfolio list when new bid is created by customer, which adds customer to vendor's portfolio

  */
  updateVendorPortfolio(cCompanySummary: CustomerCompanySummary): {
    changeRequired: boolean, uPortfolio?: VendorPortfolio
  } {
    // clone from the doc from db
    const uPortfolio = this.clone();
    if (!uPortfolio.list) {
      uPortfolio.list = {};
    }

    if (Object.keys(uPortfolio.list).includes(cCompanySummary.cid)) {
      return { changeRequired: false, uPortfolio: null };
    }
    uPortfolio.list[cCompanySummary.cid] = new VendorPortfolioItem();
    uPortfolio.list[cCompanySummary.cid].name = cCompanySummary.name;
    uPortfolio.list[cCompanySummary.cid].legalName = cCompanySummary.legalName;
    uPortfolio.list[cCompanySummary.cid].addressFormated = cCompanySummary.address?.addressFormated;
    uPortfolio.list[cCompanySummary.cid].geohash = cCompanySummary.address.geoLoc.geohash;
    return { changeRequired: true, uPortfolio };
  }
  /**
* adds and updates Master Company List  when new company is created or updated

*/
  updateMasterCompanyList(cid: string | number, d: CompanyBase): {
    changeRequired: boolean, uPortfolio?: VendorPortfolio
  } {
    // clone from the doc from db
    const uPortfolio = this.clone();
    if (!uPortfolio.list) {
      uPortfolio.list = {};
    }

    if (Object.keys(uPortfolio.list).includes(cid as string) && !this.checkForChg(d)) {

      return { changeRequired: false, uPortfolio: null };
    }
    uPortfolio.list[cid] = new VendorPortfolioItem();
    uPortfolio.list[cid].name = d.name;
    uPortfolio.list[cid].legalName = d.legalName;
    uPortfolio.list[cid].addressFormated = d.address?.addressFormated;
    uPortfolio.list[cid].geohash = d.address.geoLoc.geohash;
    uPortfolio.list[cid].isLessee = d.isLessee;
    uPortfolio.list[cid].isLessor = d.isLessor;

    return { changeRequired: true, uPortfolio };
  }
  checkForChg(d: CompanyBase): boolean {
    const rootId = DbRule.getRootId(d.id);

    if (this.list[rootId].name !== d.name) {
      return true;
    }
    if (this.list[rootId].legalName !== d.legalName) {
      return true;
    }
    if (this.list[rootId].addressFormated !== d.address?.addressFormated) {
      return true;
    }
    if (this.list[rootId].geohash !== d.address?.geoLoc.geohash) {
      return true;
    }
    return false;

  }
  /** transform list from dB no change required for vehcile list */
  get listForUI(): { [key: string]: VendorPortfolioItem; } {
    return this.list;
  }
  changeAccess(cid: string, access: boolean): VendorPortfolio {
    const updated = this.clone();
    if (!this.list || !Object.keys(this.list).includes(cid)) {
      logger.error(`${cid} does not exist`);
      return null;
    }
    if (!updated.companiesWithAccess) {
      updated.companiesWithAccess = [];
    }
    if (access) {
      updated.companiesWithAccess.push(cid);
    } else if (updated.companiesWithAccess.includes(cid)) {
      updated.companiesWithAccess = updated.companiesWithAccess.filter(f => f !== cid);
    } else {
      logger.error(`${cid} does not exist in companiesWithAccess`);
      return null;
    }
    return updated;
  }
}
