import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'keyValueOrder'
})
export class KeyValueOrder implements PipeTransform {
  transform(value: { [key: string]: any }): { key: string, value: any }[] {
    return Object.entries(value)
      .map(([key, value]) => ({ key, value }))
      .sort((a, b) => a.value.order - b.value.order);
  }
}