import { Paging } from '../observable-util/paging';
import { UserProfile } from './user-profile';
import { logger } from '../log/logger';
import { CollectionReference, limit, orderBy, query, Query, QueryConstraint, startAfter, where } from 'firebase/firestore';
export interface IUserParam {
  orderDirection?: 'desc' | 'asc';
  orderBy?: string;
  displayName?: string;
  email?: string;
  phoneNumber?: string;
  roles?:string;
}
export const userParamInit = (): IUserParam => {
  return {
    orderBy: 'updatedAt',
    orderDirection: 'desc',
  };
};
/** Server filtering of firebase query building of query  */
export const userSerachServerQuery = (ref: CollectionReference, param: IUserParam, p: Paging) => {
  let q: Query = <any>ref;
  const cons: QueryConstraint[] = [];
  if (!!param.displayName) {
    cons.push(where('displayName', '==', param.displayName.toLowerCase()));
  }
  if (!!param.email) {
    cons.push(where('email', '==', param.email));
  }
  if (!!param.phoneNumber) {
    cons.push(where('phoneNumber', '==', param.phoneNumber));
  }
  if (!!param.roles) {
    cons.push(where(`roles.${param.roles}`, '==', !null));
  }


  cons.push(where('updatedAt', '<=', new Date()));
  cons.push(orderBy('updatedAt', 'desc'));

  if (!!p.lastDoc) {
    logger.log('Start After Query: ', p.lastDoc[param.orderBy]);
    cons.push(startAfter(p.lastDoc[param.orderBy]));
  }
  cons.push(limit(p.size));
  q = query(ref, ...cons);
  return q;
};
/** Client side filtering of the orders. */
export const userSerachClientFilter = (p: UserProfile[], o: IUserParam) => {

  if (!!o.displayName) {
    p = p.filter(val => val.displayName === o.displayName.toLowerCase());
  }
  if (!!o.email) {
    p = p.filter(val => val.email === o.email);
  }
  if (!!o.phoneNumber) {
    p = p.filter(val => val.phoneNumber === o.phoneNumber);
  }
  if (!!o.roles) {               //// Cm- Search as by RoleEnum role
    p = p.filter(val => {
      if( val.roles && val.roles[o.roles]) {
        return val;
      }
    });
  }
  return p;
};
export const getIUsersOptionOrChildren = (o: IUserParam): { [key: string]: IUserParam } => {

  return undefined;
};
