import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { HttpClientModule } from '@angular/common/http';
import { CoreLazyModule } from '../../shared/core-lazy/core-lazy.module';
import { CustomPipeModule } from '@trent/custom-pipe/custom-pipe.module';
import { PtsReviewAreaComponent } from './pts-review-area/pts-review-area.component';
import { CkEditorModule } from '../../shared/ck-editor/ck-editor.module';
import { Common2Module } from '../../shared/common2/common2.module';
import { StoreLocationStoreModule } from '@trent/store/store-location-store/store-location-store.module';
import { MaterialModule } from '../../shared/share-module/material.module';
import { FormsModule } from '@angular/forms';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import {MatGridListModule} from '@angular/material/grid-list';
import { PtsEnquiryFormComponent } from '../pts-common/pts-enquiry-form/pts-enquiry-form.component';
import { PtsService } from '@trent/services/pts.service';
import { ReviewsStoreModule } from '@trent/store/reviews-store/reviews-store.module';
import { PtsReviewFormComponent } from './pts-review-form/pts-review-form.component';
import { PtsReviewListComponent } from './pts-review-list/pts-review-list.component';
// import { SalesDetailComponent } from './sales-detail/sales-detail.component';
import { SalesOptionCardComponent } from './sales-option-card/sales-option-card.component';
import { SubPageModule } from '../../shared/sub-page/sub-page.module';
import { CarouselModule } from '../../shared/carousel/carousel.module';
import { CarouselViewerModule } from '../../shared/carousel-viewer/carousel-viewer.module';
import { PtsEnquiryDialogComponent } from '../pts-common/pts-enquiry-dialog/pts-enquiry-dialog.component';
import { PhoneChangeModule } from '../../shared/phone-change/phone-change.module';
import { PhoneMaskModule } from '../../directive/phone-mask.module';
import { PtsPriceQuoteComponent } from './pts-price-quote/pts-price-quote.component';
import { SelectCompanyModule } from '../../shared/select-company/select-company.module';
import { RentalHeaderModule } from '../../shared/rental-header/rental-header.module';
import { PriceQuoteService } from '@trent/services/price-quote.service';
// import { AddOnStoreModule } from '@trent/store/addon-store';
import { AddOnService } from '@trent/services/add-on.service';
import { ProductService } from '@trent/services/product.service';
import { UserAnonymousService } from '@trent/services/user-anonymous.service';


@NgModule({
  declarations: [
    PtsReviewAreaComponent,
    PtsEnquiryFormComponent,
    PtsReviewFormComponent,
    PtsReviewListComponent,
    SalesOptionCardComponent,
    // SalesDetailComponent,
    PtsEnquiryDialogComponent,
    PtsPriceQuoteComponent,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    // CoreLazyModule,
    MatIconModule,
    CustomPipeModule,
    MaterialModule,
    CkEditorModule,
    Common2Module,
    StoreLocationStoreModule,
    FormsModule,
    StoreLocationStoreModule,
    RouterModule,
    MatProgressBarModule,
    MatTableModule,
    MatGridListModule,
    ReviewsStoreModule,
    SubPageModule,
    CarouselModule,
		CarouselViewerModule,
    PhoneChangeModule,
    PhoneMaskModule,
    SelectCompanyModule,
    RentalHeaderModule,
    // AddOnStoreModule,
  ],
  providers: [
    PtsService,
    AddOnService,
    PriceQuoteService,
    ProductService,
    UserAnonymousService
  ],
  exports:[
    PtsReviewAreaComponent,
    PtsEnquiryFormComponent,
    PtsReviewFormComponent,
    PtsReviewListComponent,
    SalesOptionCardComponent,
    // SalesDetailComponent,
    PtsEnquiryDialogComponent
  ]
})
export class PtsCommonModule {}
