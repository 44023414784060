import {
  IsEnum, IsInt, MaxLength, Matches, Max, Min, MinLength,
  IsDate, Length, ValidatorOptions, IsDefined, ValidateNested, Equals, ValidateIf, IsBoolean
} from 'class-validator';

import { Exclude, Expose, Type } from 'class-transformer';
import { IValidationMsg } from '../error-handling/validation-info';
import { PhysicalLocationType, ProductType } from './product-type';
import { ProductBase } from './product-base';
import { sanitizeDate } from '../utility/sanitize-helper';
import { isDate, isAfter, lastDayOfMonth, addYears, addMonths } from 'date-fns';
import { addAYear } from '../utility/utl-datef';
import { FileInfo, IFileMoveData } from '../media/file-info';
import { VendorCompanySummary } from '../company/company-fleet';
import { CustomFirebaseMetadata, Picture } from '../inspection/picture';
import { IInspectionPersistentData } from '../inspection/i-inpection-presistent-data';
import { InspectionData } from '../inspection/inspection-data';
import { InspectionDataTruck } from '../inspection/inspection-data-truck';
import { NetSuiteProductData, OdometerUnitsType } from './netsuite-product-data';
import { Address } from '../address/address';
import { ImageType } from '../sales-option';


export type VehicleValidationGroup = 'unitDetails' | 'specificationDetails' | 'physicalLocationDetails' |'documents' | 'plating' | 'misc' | 'pics';
const unitDetails: VehicleValidationGroup = 'unitDetails';
const specificationDetails: VehicleValidationGroup = 'specificationDetails';//MKN
const physicalLocationDetails: VehicleValidationGroup = 'physicalLocationDetails';//MKN
const documents: VehicleValidationGroup = 'documents';
const plating: VehicleValidationGroup = 'plating';
const misc: VehicleValidationGroup = 'misc';

export enum GpsType { // Cm- Different gps tracking used 
  wialon = 3,
	pointer = 2, 
	geoTab = 1, 
}

export const pathTempProductDocs = (cid: string, pid: string): string => {
  return `product/${cid}/${pid}/documents/release`;

};
export const pathRentalMedia = (cid: string, pid: string): string => {
  return `product/${cid}/${pid}/rental-media`;

};

export const pathSalesMedia = (cid: string, pid: string): string => {
  return `product/${cid}/${pid}/sales-media`;
};


const regex = {
  js: {
    // tslint:disable-next-line:max-line-length

    nounVin: /^(([a-h,A-H,j-n,J-N,p-z,P-Z,0-9]{9})([a-h,A-H,j-n,J-N,p,P,r-t,R-T,v-z,V-Z,0-9])([a-h,A-H,j-n,J-N,p-z,P-Z,0-9]{7}))$/,
  }
};
export const vinRegex = {
  js: {
    // tslint:disable-next-line:max-line-length

    nounVin: /^(([a-h,A-H,j-n,J-N,p-z,P-Z,0-9]{9})([a-h,A-H,j-n,J-N,p,P,r-t,R-T,v-z,V-Z,0-9])([a-h,A-H,j-n,J-N,p-z,P-Z,0-9]{7}))$/,
  }
};
@Exclude()
export class Vehicle extends ProductBase {

  // to set max date that can be set

  @Expose()
  @IsEnum(ProductType)
  productType: ProductType;

  @Expose()
  @MaxLength(12, { message: 'Unit Name can have Maximum $constraint1 chars', groups: [unitDetails] })
  @MinLength(1, { message: 'Unit Name can have Minimum $constraint1 chars', groups: [unitDetails] })
  unitName: string;  // fleet specific unit number

  @Expose()
  @Matches(regex.js.nounVin, { message: 'enter valid vin', groups: [unitDetails] })
  vin: string;

  @Expose() //MKN - Maintain Product active/inactive status
  isActive : boolean = true;

  @Expose() 
  isUsed : boolean = true;

  @Expose()
  @MinLength(4, { message: 'Body Type needs Minimum $constraint1 chars', groups: [unitDetails] })
  bodyType: string;

  @Expose()
  @MinLength(2, { message: 'Make needs Minimum $constraint1 chars', groups: [unitDetails] })
  make: string;

  @Expose()
  @MinLength(1, { message: 'Make needs Minimum $constraint1 chars', groups: [unitDetails] })
  model: string;

  @Expose()
  @IsInt()
  @Max(2040)
  @Min(1995, { groups: [unitDetails] })
  modelYear: number;

  @Expose()
  @ValidateIf(o => !o.unplated, { groups: [plating]})
  @IsDefined({ groups: [plating] })
  stateProv: string;

  @Expose()
  @ValidateIf(o => !o.unplated, { groups: [plating]})
  @MaxLength(8, { message: 'Plate Number should have Maximum $constraint1 chars', groups: [plating] })
  @MinLength(2, { message: 'Plate Number should have Minimum $constraint1 chars', groups: [plating] })
  plateNo: string;

  @Expose()
  @ValidateIf(o => !o.asIs, { groups: [plating]})
  @IsDate({ message: 'Safety Expiry date is required', groups: [plating] })
  safetyExpiryDate: Date;

  @Expose()
  // @ValidateIf(o => !!o.gpsProvider, { groups: [misc]})
  // @Length(1, 254, { message: 'GPS Co. name must be $constraint1 - $constraint2 chars long', groups: [misc] })
  gpsProvider: GpsType;

  @Expose()
  @ValidateIf(o => !!o.gpsSrNo, { groups: [misc]})
  @Length(1, 254, { message: 'GPS Serial No. must be $constraint1 - $constraint2 chars long', groups: [misc] })
  gpsSrNo: string;

  // @Length(5, 254, { message: 'Upload Document for Vehicle Registration', groups: [documents]})
  @Expose()
  @ValidateIf(o => !o.asIs, { groups: [documents]})
  @ValidateNested({ message: 'Upload Vehicle Registration', groups: [documents] })
  @IsDefined({ message: 'Upload Vehicle Registration', groups: [documents] })
  @Type(() => FileInfo)
  vehicleReg: FileInfo;

  // @Length(5, 254, { message: 'Upload Document for Vehicle Safety', groups: [documents] })
  @Expose()
  @ValidateIf(o => !o.unplated, { groups: [documents]})
  @ValidateNested({ message: 'Upload Truck Safety', groups: [documents] })
  @IsDefined({ message: 'Upload Truck Safety', groups: [documents] })
  @Type(() => FileInfo)
  safety: FileInfo;

  /** pictures added when rental is added to vehicle. Update to this property does not required product approval*/
  @Expose()
  pictures: Picture[];

  /** inspectionPersistentData added when inspection is completed for a vehicle.*/
  @Expose()
  inspectionPersistentData: IInspectionPersistentData;

  @Expose()
  @IsBoolean( {message: 'Validate As Is condition', groups: [unitDetails] })
  asIs = true;

  @Expose()
  @IsBoolean( {message: 'Validate Unplated condition', groups: [unitDetails] })
  unplated = false;

  @Expose()
  @ValidateIf(o => !o.unplated && !o.asIs, { groups: [plating]})
  @IsDate( { groups: [plating]})
  pmDate: Date;

  //MKN -  Maintain net suite history in product(Product are fetched from netSuite API)

  @Expose()
  @ValidateNested({ message: 'Invalid netSuite history' })
  @Type(() => NetSuiteProductData)
  netSuiteProductData: NetSuiteProductData;
  
  //MKN -  stock Id field
  // @Expose()
  //@ValidateIf(o => !!o.vin, { groups: [specificationDetails]})
  //@MinLength(2, { message: 'Stock Id can have Minimum $constraint1 chars', groups: [specificationDetails] })
  // stockId: number;

  //MKN -  unit Details field
  // @Expose()
  // @ValidateIf(o => !!o.vin, { groups: [specificationDetails]})
  // fuelType: string;

  // //MKN -  Specification field
  // @Expose()
  // @ValidateIf(o => !!o.vin, { groups: [specificationDetails]})
  // horsepower: number;
  
  //MKN -  Specification field
  // @Expose()
  // @ValidateIf(o => !!o.vin, { groups: [specificationDetails]})
  // suspension : string;

  //MKN -  Specification field
  // @Expose()
  // @ValidateIf(o => !!o.vin, { groups: [specificationDetails]})
  // doors: number;
  
  //MKN -  Specification field
  // @Expose()
  // @ValidateIf(o => !!o.vin, { groups: [specificationDetails]})
  // tires: number;

  //MKN -  Specification field
  // @Expose()
  // @ValidateIf(o => !!o.vin, { groups: [specificationDetails]})
  // ratio: number;
  
  //MKN -  Specification field
  // @Expose()
  // @ValidateIf(o => !!o.vin, { groups: [specificationDetails]})
  // wheels: string;

  //MKN -  Specification field
  // @Expose()
  // @ValidateIf(o => !!o.vin, { groups: [specificationDetails]})
  // wheelBase: number;

  //MKN -  Specification field
  // @Expose()
  // @ValidateIf(o => !!o.vin, { groups: [specificationDetails]})
  // sleeperType : string;

  //MKN -  Specification field
  // @Expose()
  // @ValidateIf(o => !!o.vin, { groups: [specificationDetails]})
  // sleeperSize: number;
  
  get pmDateMaxDate(): Date {
    return addMonths(new Date(), 1);
  }
  get pmDateMinDate(): Date {
    return addYears(new Date(), -2);
  }
  get safetyExpiryDateMinDate() {
    return new Date();
  }
  get safetyExpiryDateMaxDate() {
    return lastDayOfMonth(addYears(new Date(), 2));
  }
  constructor() {
    super();
    this.vehicleReg = new FileInfo();
    this.safety = new FileInfo();
    this.physicalLocationAddress = new Address();
    this.physicalLocationSummary = {
      sid : ''
    };
  }

  get safetyExpiryDateIso(): string { return isDate(this.safetyExpiryDate) ? this.safetyExpiryDate.toISOString() : ''; }
  set safetyExpiryDateIso(val) { this.safetyExpiryDate = sanitizeDate(val); } // sanitizeIonicDatePicker(val); }


  // validateSync(): IValidationMsg {
  //   const r = validateSync(this);
  //   const m = this.toValidationError(r);
  //   return m;
  // }
  validateSyncGroup(group: VehicleValidationGroup): IValidationMsg {
    return this.validateSync({ groups: [group] });
  }

  validateSync(options?: ValidatorOptions): IValidationMsg {
    const r = this.validateSyncBase(this, options);
    // mergeValidation(r, this.contact.validateSyncBase(this.contact, options), 'contact');
    // mergeValidation(r, this.address.validateSyncBase(this.address, options), 'a');

    if (!!options && !!options.groups.includes(physicalLocationDetails)) {
      if (this.physicalLocationType == PhysicalLocationType.prideLocation && !this.physicalLocationSummary.sid) 
          r['physicalLocation'] = ['Physical Location is required'];
      if (this.physicalLocationType == PhysicalLocationType.other && !this.physicalLocationAddress.addressFormated) 
          r['physicalLocation'] = ['Physical Location Address is required'];
    }

    if (!options || !!options.groups.includes(plating)) {
      // safetyExpiryDate  data must be in the future.
      if (isDate(this.safetyExpiryDate)) {
        if (isAfter(new Date(), this.safetyExpiryDate)) {
          r['safetyExpiryDate'] = ['Safety expiry date must be in future'];
        }
        // safetyExpiryDate data must within a year, end of the month.
        if (isAfter(lastDayOfMonth(this.safetyExpiryDate), lastDayOfMonth(addAYear(2)))) {
          r['safetyExpiryDate'] = ['Safety expiry date must be within two years'];
        }
      }
      if(options?.groups.indexOf(documents) !== -1){
        if(!this.unplated && !!this.asIs) {
          r['unPlated'] = ['As is Vehicle cannot be plated'];

        }
      }
      // Add any additional validation here if/as required.
      // for documents group, even a single uploaded file should be enough.
      // there are 3 documents.
      if (options?.groups.indexOf(documents) !== -1 && Object.keys(r).length < 3) {
        return {};
      }
    }
    return r;

  }
  /** when read from jason or from plain to class, the date objects may be stored as string. convert them to date. */
  sanitize() {
    // if data was recieved from firebase, date is stored as snapshot.
    super.sanitize();
    this.safetyExpiryDate = sanitizeDate(this.safetyExpiryDate);
    this.pmDate = sanitizeDate(this.pmDate);
    // return this;
  }
  /** Analyze product to see if more information is needed or suggest the user
 * to submit for approval.*/
  // getValDbStatus(): IValDbStatusResult {
  //   const result: IValDbStatusResult = {
  //     pass: false,
  //     message: undefined,
  //     groupResult: {}
  //   };
  //   let x: { groupPass: boolean; message: string; };
  //   // unitDetails
  //   let r = this.validateSyncGroup(unitDetails);
  //   x = { groupPass: null, message: '' };
  //   x.groupPass = Object.keys(r).length === 0;
  //   x.message = (x.groupPass) ? 'Manage unitDetails Information' : 'unitDetails information is required';
  //   result.groupResult[unitDetails] = x;
  //   // Documents

  //   r = this.validateSyncGroup(documents);
  //   x = { groupPass: null, message: '' };
  //   x.groupPass = Object.keys(r).length === 0;
  //   x.message = (x.groupPass) ? 'Manage Required Documents' : 'Documents information is required';
  //   result.groupResult[documents] = x;

  //   // is it passed all the tests? (True means it failed here)
  //   result.pass = !Object.keys(result.groupResult).some((k) => !result.groupResult[k].groupPass);
  //   // passed.
  //   if (!result.pass) {
  //     if (this.dbStatus === DbStatus.Initial || this.dbStatus === DbStatus.ReleasedMod) {
  //       result.message = 'Admin approval is pending!';
  //     }
  //   } else {
  //     result.message = 'More information is required!';
  //   }
  //   return result;
  // }

  /** Update all attached documents paths from /draft/ to /rel/ */
  updateFilePathsToRelease(): IFileMoveData[] {
    const R: IFileMoveData[] = [];
    let r = FileInfo.updatePathToRelease(this.vehicleReg);
    if (!!r) { R.push(r); }

    r = FileInfo.updatePathToRelease(this.safety);
    if (!!r) { R.push(r); }

    return R;
  }

  /**
   * Create pictures using firebase storage files
   * @param pictures 
   */
  updatePicturesFromStorage(pictures) {
    this.pictures = [];
		for (const o of pictures) {
			const pict = new Picture();
			pict.path = o.name;
			pict.name = o.name.replace(/^.*[\\\/]/, '');
			pict.uploadedOn = new Date(o.metadata?.updated);
			const customMetadata = o.metadata && o.metadata?.metadata;
      if(customMetadata){
        customMetadata['imageType'] = ImageType.original;
        if(customMetadata['order']) customMetadata['order'] = Number(customMetadata['order']);
        if(customMetadata['visibility']) customMetadata['visibility'] = JSON.parse(customMetadata['visibility']);
        pict.customFirebaseMetadata = customMetadata as CustomFirebaseMetadata;  
      }
      this.pictures.push(pict);
		}
	}

  setInspectionPersistentData(ins: InspectionDataTruck) {
    this.inspectionPersistentData = ins.getPersistentData();
  }
}
