import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubPageComponent } from './sub-page.component';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from '../share-module/material.module';
import { CkEditorModule } from '../ck-editor/ck-editor.module';
import { HttpClientModule } from '@angular/common/http';



@NgModule({
  declarations: [
    SubPageComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    CkEditorModule,
    HttpClientModule
  ],
  exports: [
    SubPageComponent
  ]
})
export class SubPageModule { }
