import { cleanupUndefined } from '../utility';
import { cloneDeep, pickBy, isEqual } from 'lodash';
import { logger } from '../log/logger';

/** Convert an object to a JSON query string after removing null and undefined and filtered by an ignore object
 * INPUT : o = { a: null, b: undefined, c: 1, d: 2}, ignoreObj: {c:1}
 * Output: JSON.stringyfy({d:1})
 */
export const toQueryStr = (o: object, ignoreObj: object): string => {

  if (o == null) {
    return null;
  }
  const copy = cleanupUndefined(cloneDeep(o));
  if (copy == null) {
    return null;
  }

  for (const key in ignoreObj) {
    if (ignoreObj.hasOwnProperty(key)) {
      const ele = ignoreObj[key];
      if (!!copy[key] && isEqual(ele, copy[key])) {
        delete copy[key];
      }
    }
  }
  return JSON.stringify(copy);
};


/** Parse the query string back to the object 
 * INPUT JSON.stringyfy({d:1})
 * OUPUT:  {d:1}
*/

export const fromQueryStr = <T>(s: string): T => {
  try {
    if (typeof s === 'string' && s.trim().length > 0) {
      return JSON.parse(s) as T;
    }
  } catch (err) {
    logger.error('[query-helper] reading object from query failed for string: ', s);
    return {} as T;
  }
  return {} as T;
};

/*
 * 
 * @param objectData- pass any object
 * @returns - will return the string with & added after every key-value pair
 */
export const toMakeQueryParams = (objectData:object) => {
  let queryString = "";
  for (let key in objectData) {
     queryString += encodeURIComponent(key) + '='
     + encodeURIComponent(objectData[key]) + '&';
  }
  queryString = queryString.substr(0, queryString.length - 1);
   return queryString;
 };

/**
 * PT
 * @param url - which need to alter the https from the frontend
 * @returns return the url without https://
 */
export const removeHttp = (url: string) => {
  if(url){
    return url.replace(/^https?:\/\//, '');
  }
};