import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PtsFooterComponent } from '../pts-footer/pts-footer.component';
import { GooglePLacesAutoDirectiveModule } from '../../directive/google-places-auto.module';
import { CoreLazyModule } from '../../shared/core-lazy/core-lazy.module';
import { MaterialModule } from '../../shared/share-module/material.module';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { StoreLocationStoreModule } from '@trent/store/store-location-store';
import { ReleaseStatusModule } from '../../shared/release-status/release-status.module';
import { RouterModule } from '@angular/router';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MapWrapperModule } from '../../shared/map-wrapper/map-wrapper.module';
import { MapContainerModule } from '../../shared/map-container/map-container.module';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faIcons, faPlus } from '@fortawesome/free-solid-svg-icons';
import {MatGridListModule} from '@angular/material/grid-list';
import { FooterLocationSelectorComponent } from '../pts-footer/footer-location-selector/footer-location-selector.component';
import { FooterVisitStoreComponent } from '../pts-footer/footer-visit-store/footer-visit-store.component';
import { TimezoneService } from '@trent/services/timezone.service';
import { faInstagram, faLinkedin, faTiktok, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { MapGenService } from '@trent/services/map/map-gen.service';
import { SubPageModule } from '../../shared/sub-page/sub-page.module';


@NgModule({
  declarations: [
    PtsFooterComponent,
    FooterLocationSelectorComponent,
    FooterVisitStoreComponent,
  ],
  imports: [
    SubPageModule,
    CommonModule,
    GooglePLacesAutoDirectiveModule,
    // CoreLazyModule,
    MaterialModule,
    FormsModule,
    HttpClientModule,
    StoreLocationStoreModule,
    ReleaseStatusModule,
    RouterModule,
    MatProgressBarModule,
    MatTableModule,
    MapWrapperModule,
    MapContainerModule,
    MatGridListModule
  ],
  providers: [
    TimezoneService,
    MapGenService
  ],
  exports: [
    PtsFooterComponent,
    FooterLocationSelectorComponent,
  ]
})
export class PtsFooterModule {
  constructor(library: FaIconLibrary) {
    // https://github.com/FortAwesome/angular-fontawesome/blob/master/docs/usage/using-other-styles.md
    library.addIcons(faPlus, faIcons, faInstagram, faYoutube, faTiktok, faLinkedin);
  }
 }
