import { Router } from '@angular/router';
import { isDate } from 'lodash';
import { promiseWraper } from '@trentm/utility/helper';
import { BehaviorSubject, Subscription } from 'rxjs';
import { LocalStorage } from '../local-storage/local-storage.service';
import { FirestoreService } from '../firestore.service';
import { Store } from '@ngxs/store';
import { IAuthCoreService } from '../auth/iauth-core.service';
import { addDays } from 'date-fns';
import { IDeviceIdComponents, IFcmTokenData, getFcmTokenData } from '@trent/models/fcm/fcm-token-data';
import { IdeviceIdService } from '../device/idevice-id.service';
import { SetDeviceId } from '@trent/store/root-store';
// import { Platform } from '@ionic/angular';
import { TaskUtl } from '../../models/sys/task-utl';
import { logger } from '@trentm/log/logger';

// @Injectable()
export class CloudMessageCoreService {
  // static get deviceFingerPrintKey() {
  //   return "browserFingerPrint";
  // }

  private deviceData: IDeviceIdComponents;

  msgSub: Subscription;

  userSub: Subscription;

  tokenRefreshSub: Subscription;

  isActivated = false;

  fcmToken: string;

  uid: string;

  currentMessage = new BehaviorSubject(null);

  constructor(
    public auth: IAuthCoreService,
    private store: Store,
    public localStorage: LocalStorage,
    private afs: FirestoreService, // private platform: Platform
    protected deviceIdService: IdeviceIdService,
    private router: Router
  ) {
    // Bind the User ID to be updated.
    this.userSub = this.auth.user$.subscribe(u => {
      logger.log(`[fcm] user subscription changed. New User is: ${(!!u) ? u.uid : 'not logged user'}`);
      if (!!u && !!u.uid) {
        this.uid = u.uid;
        this.updateFcmToken();
      } else {
        this.uid = null;
      }
    });

    // logger.log('Platform ionic name is: ', this.platform.platforms());
  }

  // /** to be defined by the derived class cdva or web. */
  // public getDeviceIdFn(): Promise<IDeviceIdComponents> {
  //   throw Error("Programming Error! Function is not defined in the inherited class");
  // }

  public onTokenRefresh(t) {
    this.fcmToken = t;
    this.updateFcmToken();
    logger.log('[fcm] Token Refreshed!', t);
  }

  public onMessageReceived(msg: MessageEvent) {
    let msgTxt = 'Message Received. ';
    // let url: string;
    logger.log('[fcm] message Received!', msg);
    if (msg instanceof MessageEvent) {
      const m = msg.data && msg.data.firebaseMessagingData && msg.data.firebaseMessagingData.notification;
      logger.log('[fcm] message Details are:', m);
      msgTxt += JSON.stringify(m);
    }
    const taskType = +(<any>msg).action;
    const id: string = (<any>msg).action_key;
    const { url, q } =  TaskUtl.getUrlFromTaskType(taskType, id);
    logger.log({ url });
    if (url && (<any>msg).tap) {
      this.router.navigate([url], { queryParams: q});
      // this.router.navigateByUrl(url, { queryParams: q});
    }
    // alert(msgTxt);


    // -- TO DO, Process the message here
    // msg, we can add two properties
    // "action": "some action type-nofif",
    // "action_key": "xxxyyyyzzzzKey-notif"
    // Based upon above two, we can use router to navigate the user.
    // this.router.navigateByUrl('some url based off some action');

  }

  async updateFcmToken() {
    if (this.uid == null || this.fcmToken == null) {
      logger.log(`[fcm] uid: '${this.uid}', fcm token: '${this.fcmToken}'`);
      logger.log('[fcm] core update was called but either uid or fcm token was null. Skipping fcm update');
      return;
    }
    const cacheKey = `${this.uid}-fcm-token`;
    const cacheData = await promiseWraper<IFcmTokenData>(this.localStorage.get(cacheKey));
    if (!!cacheData.data && typeof cacheData.data.updatedAt === 'string') {
      cacheData.data.updatedAt = new Date(cacheData.data.updatedAt);
    }
    const yesterday = addDays(new Date(), -1);

    // tslint:disable-next-line:max-line-length
    if (cacheData.data == null || cacheData.data.token !== this.fcmToken || !isDate(cacheData.data.updatedAt) || cacheData.data.updatedAt < yesterday) {
      logger.log('updating fcm-token at db.');

      /** retreive the device id */
      if (this.deviceData == null) {
        this.deviceData = await this.deviceIdService.getDeviceId();
        this.store.dispatch(new SetDeviceId(this.deviceData));
      }

      const data = getFcmTokenData(this.deviceData, this.fcmToken);

      const dataObj = {};
      dataObj[data.deviceId] = { ...data };
      dataObj[data.deviceId].updatedAt = this.afs.timestamp;
      this.afs
        .set(`fcm-token/${this.uid}`, dataObj, true, { merge: true })
        .then(async () => {
          // this.localStorage.set(cacheKey, this.fcmToken);
          data.updatedAt = new Date();
          this.localStorage.set(cacheKey, data).catch(err => {
            logger.error('could not save fcm token in local storage', err);
          });
        })
        .catch(err => {
          logger.error('fcm-token upate failed', err);
          // since db failed, invalidate local cache as well.
          this.localStorage.remove(cacheKey);
        });
    } else {
      logger.log('fcm-token matches with stored in cache. so no update was made to db.');
    }
  }
}
