import { ICameraPicData } from './icamera-data';
import { Injectable } from '@angular/core';
// import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { Camera, CameraResultType, CameraSource, ImageOptions } from '@capacitor/camera';
import { Platform } from '@ionic/angular';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { logger } from '@trentm/log/logger';

@Injectable()
export class CameraCapService {

  option: ImageOptions;

  constructor(private sanitizer: DomSanitizer) {

  }

  get isCameraAvailable() { return true; }

  get isCapacitor() { return true; }
  get isCdva() { return false; }
  /**
   * 
   * @param o Ensure that returned url is prefixed if destinationtype of the option is data_url. 
   * 'data:image/jpeg;base64,' + result output of this function call.;
   * 
   */
  async takePicture(o: ImageOptions): Promise<ICameraPicData> {
    const options: ImageOptions = {
      quality: 30,
      allowEditing: false,
      resultType: CameraResultType.Uri,
      source: CameraSource.Camera,
      // ...o
      // destinationType: this.camera.DestinationType.FILE_URI,
      // encodingType: this.camera.EncodingType.JPEG,
      // mediaType: this.camera.MediaType.PICTURE,
      // correctOrientation: true
    };

    // For ios, destination type must be 1 or 2.
    // tslint:disable-next-line:triple-equals
    // if ((options as any).destinationType != 1 && (options as any).destinationType.destinationType != 2) {
    //   (options as any).destinationType = 1;
    // }

    const image = await Camera.getPhoto(options);
    logger.log('[camera-cap] take pic result - path', image.path);
    logger.log('[camera-cap] take pic result - webpath', image.webPath);
    logger.log('[camera-cap] take pic result - webpath (sanitized)',
      this.sanitizer.bypassSecurityTrustResourceUrl(image.webPath) as string);

    return {
      path: image.path,
      webPath: this.sanitizer.bypassSecurityTrustResourceUrl(image.webPath) as string
    };
  }






  /** Android can not have native url */

}
