import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsModule } from '@ngxs/store';
import { SparePartState } from './spare-part.state';
import { SparePartsService } from '@trent/services/spare-parts.service';

/**
 * @author - MKN
 * Spare Part Store
 */

@NgModule({
  imports: [
    CommonModule,
    NgxsModule.forFeature([SparePartState])
  ],
  providers: [
    SparePartsService,
  ]
})
export class SparePartStoreModule { }
