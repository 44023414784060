import { ProductState } from './product.state';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ProductService } from '@trent/services/product.service';
import { NgxsModule } from '@ngxs/store';
import { VinService } from '@trent/services/vin.service';
import { AlgoliaSearchService } from '@trent/services/algolia-search.service';

@NgModule({
  imports: [
    CommonModule,
    NgxsModule.forFeature([ProductState])
  ],
  providers: [
    ProductService,
    VinService,
    AlgoliaSearchService
  ]
})
export class ProductStoreModule { }
