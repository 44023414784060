import { Component, OnInit, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs/internal/Subscription';
import { UtilityService } from '@trent/services/utility.service';
import { CmsPageRequested, CmsState } from '@trent/store/cms-store';
import { map } from 'rxjs/operators';
import { PageHtml } from '@trent/models/cms/page-html';
import { DialogData } from '@trent/models/error-handling';
import { getEnumsMembers, toSentence, getCommaSepString } from '@trent/models/utility';
import { ActivatedRoute, Data } from '@angular/router';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { DialogService } from '@trent/services/dialog/dialog.service';
import { SubPageComponent } from '../sub-page/sub-page.component';
import { saveCookiePolicyInStorage } from '@trent/models/user-agreement';
import { logger } from '@trent/models/log/logger';
import { LocalStorage } from '@trent/services/local-storage/local-storage.service';

export interface PolicyData {
  url: string;
  header: string;
}

@Component({
  selector: 'trent-policy',
  templateUrl: './policy.component.html',
  styleUrls: ['./policy.component.scss']
})
export class PolicyComponent implements OnInit {

  agreements; //  = any; //  AgreementType;
  header = '';
  private subs: Subscription[] = [];
  pid: string;
  readSection: string;
  page: PageHtml;
  agreePPolicy = false;
  showPolicy = false;
  aIds: string[] = [];

  constructor(
    private store: Store,
    public us: UtilityService,
    private aroute: ActivatedRoute,
    public bottomSheetRef: MatBottomSheetRef<PolicyComponent>,
    private storage: LocalStorage,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: { title: string, pid: string, rev: number },
    public dialogService: DialogService) {

  }

  ngOnInit() {
    const routeData = this.aroute.snapshot.data;
    // this.getAgreements(routeData);
    this.pid = this.data.pid;

    this.loadPageHtml();
  }
  openPolicy(pid: string) {
    this.dialogService.openInfoPage({ url: pid }, SubPageComponent);
  }
  loadPageHtml() {
    if (this.us.isPlatformBrowser) {
      this.store.dispatch(new CmsPageRequested({ id: this.pid }));
      this.subs.push(
        this.store
          .select(CmsState.selectPageById)
          .pipe(map(pageFn => PageHtml.parse(pageFn(this.pid))))
          .subscribe(p => {
            this.page = p;
            if (!!this.page) {
              const val = Object.values(this.page.sections);
              this.header = val[0].header;
            }
          }, (err) => {
            logger.error(err);

          })

      );
    }
  }
  agreementsText() { return getCommaSepString(getEnumsMembers(this.agreements)); }

  setShowPolicy() {
    event.preventDefault();
    this.showPolicy = true;
  }
  async saveCPolicy() {
    const c = await saveCookiePolicyInStorage(this.data.pid, this.data.rev, this.storage);
    this.bottomSheetRef.dismiss();
  }
  // getAgreements(rData: Data) {
  //   logger.log('[policy-comp] ', { rData });
  //   if (rData) {
  //     switch (rData.agreementType) {
  //       case AgreementType.privacyPolicy:

  //         break;

  //       default:
  //         break;
  //     }
  //   }
  // }
}
