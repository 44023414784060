import { IPoint } from '../product-search/interfaces';
import { round } from 'lodash';
import { logger } from '../log/logger';


// ** Find Distance between to two geopoints  */
export function findDistance(lat1: number, lon1: number, lat2: number, lon2: number, param?: 'miles' | 'km'): number {

  let dist = 0;
  if ((lat1 === lat2) && (lon1 === lon2)) {
    dist = 0;
  } else {
    const radlat1 = Math.PI * lat1 / 180;
    const radlat2 = Math.PI * lat2 / 180;
    const theta = lon1 - lon2;
    const radtheta = Math.PI * theta / 180;
    dist = dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = dist * 180 / Math.PI;
    dist = dist * 60 * 1.1515;
    // ** conver to KM if required*/
    dist = param === 'km' ? dist * 1.609344 : dist;
  }
  return dist;
}
/**
 * Checks id point is with the given @param radius for the given @param center
 * @param center IPoint
 * @param point IPoint
 * @param radius number
 */
export const isWithinRadius = (center: IPoint, point: IPoint, radius: number): boolean => {
  if (typeof center.latitude  !== 'number' || typeof center.longitude  !== 'number' 
  || typeof point.latitude  !== 'number' || typeof point.longitude  !== 'number') {
    throw new Error('invalid input for ipoint');
  }
  if (findDistance(center.latitude, center.longitude,
    point.latitude, point.longitude) > radius) {
    return false;
  } else {
    return true;
  }
};
// /** estimated drive time (in hours) between two points based on crow fly distance
//  * @param lat1 point 1 lat
//  * @param lon1 point 1 long
//  * @param lat2 point 2 lat
//  * @param lon2 point 2 long
//  * @param isHos boolean. Estimate for HOS
//  */
// export function estimatedDriveTime(lat1: number, lon1: number, lat2: number, lon2: number, isHos = true): number {
//   // actual distance is 2 twice the crowfly
//   const estDist = findDistance(lat1, lon1, lat2, lon2) * 2;
//   // average speed 40 miles/hour
//   const estDrv = estDist / 40;
//   // estimated break
//   const estBrk = !!isHos ? Math.floor(estDrv / 12) * 10 : 0;
//   return estDrv + estBrk;

// }


export function randomIPointInCircle(iPoint: IPoint, radius: number, param: 'miles' | 'km' = 'km') {
  // Convert radius from meters to degrees.
  const radiusInDegrees = radius * 1000 / 111320;

  // Get a random distance and a random angle.
  const u = Math.random();
  const v = Math.random();
  const w = radiusInDegrees * Math.sqrt(u);
  const t = 2 * Math.PI * v;
  const x = w * Math.cos(t);
  const y = w * Math.sin(t);
  // Compensate the x value.
  // convert lat degrees to radians
  const newX = x / Math.cos(iPoint.latitude * Math.PI / 180);

  const foundLatitude = round((iPoint.latitude + y), 6);
  const foundLongitude = round((iPoint.longitude + newX), 6);
  const randomIPoint: IPoint = { latitude: foundLatitude, longitude: foundLongitude };
  return randomIPoint;
}
/**
 * get point inside outer radius and outsude the inner radius of target location.
 * @param iPoint IPoint of target location
 * @param iRadius inner radius
 * @param oRadius outer raius
 * @param param distance unit miles|km, km is default
 */
export function randomIPointBetweenTwoCircles(iPoint: IPoint, iRadius: number, oRadius: number, param: 'miles' | 'km' = 'km') {
  const p = randomIPointInCircle(iPoint, oRadius, param);
  let result: IPoint;
  const d = findDistance(p.latitude, p.longitude, iPoint.latitude, iPoint.longitude);
  logger.log(`[distf] randomIPointBetweenTwoCircles ${d}`);
  if (d > iRadius) {
    result = p;
  } else {
    randomIPointBetweenTwoCircles(iPoint, iRadius, oRadius, param);
  }
  return result;
}
