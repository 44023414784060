import { Exclude, Expose } from "class-transformer";
import { IValidationMsg } from "../error-handling";
import { validateSync, ValidatorOptions } from 'class-validator';
import { toValidationError } from "../utility";
import { EnumHelper } from "../utility/enum-helper";
import { TruckType } from "../product";
export interface Specification {
	value: string | number;
	displayOnPage: boolean;
	displayOnCard: boolean;
}

@Exclude()
export class Specs {

	@Expose()
	unitName: Specification;

	@Expose()
	make: Specification;

	@Expose()
	model: Specification;

	@Expose()
	modelYear: Specification;

	// @Expose()
	// stockId: Specification;

	@Expose()
	odometer: Specification;

	@Expose()
	unitColor: Specification;

	@Expose()
	truckType: Specification;

	@Expose()
	engineModel: Specification;

	@Expose()
	engineManufacturer: Specification;
	
	@Expose()
	horsepower: Specification;

	@Expose()
	fuelType: Specification;

	@Expose()
	suspension: Specification;

	@Expose()
	doors: Specification;

	@Expose()
	tires: Specification;

	@Expose()
	ratio: Specification;

	@Expose()
	wheels: Specification;

	@Expose()
	wheelBase: Specification;

	@Expose()
	sleeperType: Specification;

	@Expose()
	sleeperSize: Specification;

	@Expose()
	torque: Specification;

	@Expose()
	gearRatio: Specification;
	
	constructor() { }

	validateSpecs(r: IValidationMsg) { }

	validateSync(options: ValidatorOptions): IValidationMsg {
		const m = validateSync(this, options);
		const r = toValidationError(m);
		this.validateSpecs(r);
		return r;
	}
}