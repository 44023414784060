import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapWrapperComponent } from './map-wrapper.component';
import { MapGenService } from '@trent/services/map/map-gen.service';



@NgModule({
  declarations: [MapWrapperComponent],
  imports: [
    CommonModule
  ],

  exports: [
    MapWrapperComponent,
  ],
  providers: [
    MapGenService
  ]

})
export class MapWrapperModule { }
