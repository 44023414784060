function decimalPlaces(number) {
  let numberOfPlaces = 0;
  let foundFirstZero = false;
  let foundDot = false;
  
  for (let i = 0; i < number.toString().length; i++) {
    if (number.toString()[i] === '0' && !foundFirstZero) {
      foundFirstZero = true;
    } else if (number.toString()[i] === '.') {
      foundDot = true;
    }  else if (number.toString()[i].match(/\d+/g) != null && foundDot) {
      numberOfPlaces++;
    } 
  }
  
  return numberOfPlaces;
}

function simplify(numerator, denominator) {
  const fraction = {
    numerator: numerator,
    denominator: denominator
  };
  
  for (let i = fraction.denominator; i > 0; i--) {
    if (fraction.denominator % i === 0 && fraction.numerator % i === 0) {
      fraction.numerator /= i;
      fraction.denominator /= i;
    }
  }
  
  return fraction;
}
export const decimalToFraction = (decimal: number): string => {
  const ans = simplify(decimal.toString().substring(2), Math.pow(10, decimalPlaces(decimal)));
  return `${ans.numerator + ' / ' + ans.denominator}`;
};
export const sanitizeAsDecimal = (decimalStr: any): number => {
  decimalStr =+ decimalStr;
  decimalStr = decimalStr.toFixed(2);
  decimalStr =+ decimalStr;
  return decimalStr;
};
