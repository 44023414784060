import { IcloudMessageService } from '@trent/services/fcm/icloud-message.service';
import { Component, OnInit } from '@angular/core';
import { logger } from '@trent/models/log/logger';

@Component({
  selector: 'trent-core-lazy',
  template: `
    <p>
      core-lazy works!
    </p>
  `,
  styles: []
})
export class CoreLazyComponent implements OnInit {

  constructor(public ms: IcloudMessageService) {
    // this.ms.requestPermission();
    logger.log('[fcm] core lazy comp constructor called!');
  }

  ngOnInit() {
  }

}
