import { TripParam, getTripSearchOptOrChildren } from '@trent/models/trip/trip-param';
import { State, Selector, Action, StateContext } from '@ngxs/store';
import { StateBase } from '../state-base';
import { Subscription, noop } from 'rxjs';
import { TripService } from '@trent/services/trip.service';
import * as entity from '@trent/store/entity.state';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Paging, getObjKey } from '@trent/models/observable-util/paging';
import { TripBase } from '@trent/models/trip';
import { PagingContainer } from '@trent/models/observable-util/paging-container';
// tslint:disable-next-line:max-line-length
import { IDataLoadStatus, buildDataRequest, LoadStatus, getRootLevelChildren, updatePaging } from '@trent/models/observable-util/data-status';
import { tripSearchClientFilter } from '@trent/models/trip/trip-query';
import { ITripGpsStatus, copyGpsStatus } from '@trent/models/trip/trip-gps-status';
import { logger } from '@trentm/log/logger';


// #region State Model
export interface TripStateModel {

  paging: Paging;

  /** Which client side queries are complete */
  dataLoadStatus: {
    [key: string]: IDataLoadStatus<TripParam>;
  };

  /** Order Container. */
  data: { [id: string]: TripBase }; // entity.EntityState<ProductBase>;
  gpsStatus: { [id: string]: ITripGpsStatus };
}

function initTripState(): TripStateModel {
  return {
    paging: { size: 10, offset: 0, full: false },
    dataLoadStatus: {},
    data: {},
    gpsStatus: {}
  };
}

// #endregion
// #region actions
export class TripByIdRequested {
  static readonly type = '[Trip] Request a single trip entity';
  constructor(public payload: { id: string | number }) { }
}
export class TripByIdLoaded {
  static readonly type = '[Trip] Load a single trip entity';
  constructor(public payload: { data: TripBase, id: string | number }) { }
}

export class TripStateReset {
  static readonly type = '[Trip] Reset State';
}

export class TripsRequested {
  static readonly type = '[Trip] All trips Requested';
  constructor(public payload: { pData: Paging, param: TripParam }) { }
}

export class TripsLoaded {
  static readonly type = '[Trip] All trips Loaded';
  constructor(public payload: {
    data: { [id: string]: TripBase }, // Data
    key: string,
  }) { }
}
export class TripsNextPageRequested {
  static readonly type = '[Trip] All Trip requested - Next Page';
  constructor(public payload: { param: TripParam }) { }
}

export class TripsGpsStatusLoaded {
  static readonly type = '[Trip] All trip Gps Loaded';
  constructor(public payload: {
    data: ITripGpsStatus,
    id: string
  }) { }
}
export class TripsGpsStatusRequested {
  static readonly type = '[Trip] All trip GPS Requested';
  constructor(public payload: { id: string }) { }
}
export class TripRemoveById {
  static readonly type = '[Trip] Remove by Id';
  constructor(public payload: { id: string | number }) { }
}


@State<TripStateModel>({
  name: 'Trip',
  defaults: initTripState()
})
@Injectable()
export class TripState extends StateBase {

  dataReqSub: Subscription;

  /** Container that keep all of the subscription related to gettting data. */
  dataSubData: PagingContainer<TripBase, TripParam> = new PagingContainer();


  constructor(private ts: TripService) {
    super();
  }
  // #region Selectors
  @Selector()
  static selectDataById(state: TripStateModel) {
    return entity.getByIdFn_new(state.data, TripBase.parse);
  }

  @Selector()
  static selectAllData(state: TripStateModel) {
    return (o: TripParam): TripBase[] => {

      if (state.data == null || Object.keys(state.data).length === 0) {
        return [];
      }

      // remove keys that have revision/draft ids, i.e that contain '~' in the id.
      const keys = Object.keys(state.data).filter(k =>
        k.indexOf('/') === -1 && state.data[k] != null);

      // object without the rev/draft.
      const filtered = keys.reduce((obj, k) => {
        obj[k] = state.data[k];
        return obj;
      }, {});

      let output = Object.values(filtered).map(x => TripBase.parse(x));
      output = tripSearchClientFilter(output, o);

      return output;
    };
  }

  /** Is the Paging Full for this search. */
  @Selector()
  static selectAllDataFull(state: TripStateModel) {
    return (o: TripParam): boolean => {
      const oKey = getObjKey(o);
      const r = state.dataLoadStatus && state.dataLoadStatus[oKey];
      return (!!r) ? r.pData.full : true;
    };
  }

  @Selector()
  static selectTripGpsStatusById(state: TripStateModel) {
    return entity.getByIdFn_new(state.gpsStatus, copyGpsStatus);
  }

  // #endregion

  // #region Custom Functions and subscriptions

  public clearSubscriptions() {
    if (!!this.dataReqSub) {
      this.dataReqSub.unsubscribe();
      this.dataReqSub = null;
    }
    this.dataSubData.unsubscribeAll();
    super.clearSubscriptions();
  }

  // #endregion
  // #region Actions Single Entity


  @Action(TripByIdRequested)
  dataByIdRequested(context: StateContext<TripStateModel>, action: TripByIdRequested) {
    const state = context.getState();
    if (Object.keys(state.data).indexOf(`${action.payload.id}`) === -1) {
      // logger.log('requested Rental-Product is not in the store. sending request to server', action.payload.id);
      this.ts.getTripById(action.payload.id)
        .pipe(
          map(data => {
            logger.log('from server: retal map was called', data);
            return context.dispatch(new TripByIdLoaded({ data, id: action.payload.id }));
          }
          )).subscribe(noop);
    } else { logger.log('requested Rental-Product is available in the store'); }
  }

  @Action(TripByIdLoaded)
  dataByIdLoaded(context: StateContext<TripStateModel>, action: TripByIdLoaded) {
    // if (!this.hasDeletedKey(action.payload.id)) {
      const state = context.getState();
      const data = action.payload.data || null;
      const p = {};
      p[action.payload.id] = data;
      context.patchState({
        data: { ...state.data, ...p }
      });
   // }
  }

  // #endregion Single Entity
  // #region All Trip
  @Action(TripsRequested)
  dataRequested(context: StateContext<TripStateModel>, action: TripsRequested) {

    const oKey = getObjKey(action.payload.param);

    // Define function that return the data status object from the state.

    const getDataStatusStateFn = () => context.getState().dataLoadStatus;

    /** custom build the OR children query. */
    const buildOrQueryChildrenFn = (o: TripParam) => getTripSearchOptOrChildren(o);

    // if data requested now, is already partially loaded by another query's child previously
    // but the number of the items are not enough (ex. as a child, it loaded only 5, but current
    // request ask for more, then next page of that query should be called instead.)
    const nextPageFn = (param: TripParam) => {
      context.dispatch(new TripsNextPageRequested({ param }));
    };

    buildDataRequest(
      oKey, action.payload.param, action.payload.pData,
      getDataStatusStateFn,
      buildOrQueryChildrenFn,
      nextPageFn,
      (
        obj: { [key: string]: IDataLoadStatus<TripParam> },
        set: { key: string, node: IDataLoadStatus<TripParam> }[]
      ) => {

        if (!!obj) {
          // Patch the state.
          const state = context.getState();
          context.patchState({
            dataLoadStatus: { ...state.dataLoadStatus, ...obj }
          });
        }

        // Process the query.
        set.forEach((val) => {
          // some of the nodes are already loaded. Only process that are loading... status.
          if (val.node.loadStatus !== LoadStatus.Loading) {
            return;
          }
          // if this request is just overwriting a stall or pending request, unsubscribe that observable
          this.dataSubData.unsubscribe(val.key);

          // create the paging observable and call db.
          // const p = this.ts.getTasks_PagingObservable();
          const p = this.ts.getAllTrips_PagingObservable();
          const prod$ = p.getData(action.payload.pData, val.node.param)
            .pipe(
              map(tasks => {
                // if (!val.node.param.pdIdForHistory) {
                context.dispatch(new TripsLoaded({
                  data: tasks as any,
                  key: val.key
                }));
                // } else {
                //   context.dispatch(new AllPickDropHistoryLoaded({
                //     data: pickDrops,
                //     key: val.key
                //   }));
                // }
                return tasks;
              }));
          const sub = this.subscribe(prod$, () => noop(), TripsRequested.type);
          // save the observable call
          this.dataSubData.addData(val.key, sub, p);
        });
      }
    );
  }
  @Action(TripsNextPageRequested)
  dataNextPageRequested(context: StateContext<TripStateModel>, action: TripsNextPageRequested) {
    const oKey = getObjKey(action.payload.param);
    const state = context.getState();
    // find the node. can be parent or child
    const statusObj = state.dataLoadStatus[oKey];
    // if no parent, treat is
    if (statusObj.children == null) {
      this.dataSubData.dispatchNextPagingUpdate(oKey);
    } else {
      const children = getRootLevelChildren(oKey, state.dataLoadStatus);
      children.forEach(c => {
        this.dataSubData.dispatchNextPagingUpdate(c.key);
      });
    }
  }
  @Action(TripsLoaded)
  dataLoaded(context: StateContext<TripStateModel>, action: TripsLoaded) {
    const state = context.getState();
    const subData = this.dataSubData.getData(action.payload.key);
    const updatedLoadStatus = updatePaging(action.payload.key, state.dataLoadStatus, subData);
    context.patchState({
      dataLoadStatus: updatedLoadStatus,
      // data: { ...state.data, ...this.filterDeletedContent(action.payload.data) }
      data: { ...state.data, ...action.payload.data }
    });
  }
  @Action(TripStateReset)
  tripStateReset(context: StateContext<TripStateModel>, action: TripStateReset) {
    // unsubscribe the data
    this.clearSubscriptions();
    context.setState(initTripState());
  }


  @Action(TripsGpsStatusRequested)
  tripsGpsStatusRequested(context: StateContext<TripStateModel>, action: TripsGpsStatusRequested) {
    const state = context.getState();
    if (!state.gpsStatus[`${action.payload.id}`]) {
      // logger.log('requested Rental-Product is not in the store. sending request to server', action.payload.id);
      this.ts.getGpsStatus(action.payload.id)
        .pipe(
          map(data => {
            // logger.log('from server: retal map was called', data);
            return context.dispatch(new TripsGpsStatusLoaded({ data, id: action.payload.id }));
          }
          )).subscribe(noop, error => {
            logger.log('[gps] Gps Data Error handled here!', error);
          });
    } else { logger.log('requested Rental-Product is available in the store'); }
  }

  @Action(TripsGpsStatusLoaded)
  tripsGpsStatusLoaded(context: StateContext<TripStateModel>, action: TripsGpsStatusLoaded) {
    const state = context.getState();
    const data = action.payload.data || null;
    const p = {};
    p[action.payload.id] = data;
    context.patchState({
      gpsStatus: { ...state.gpsStatus, ...p }
    });
  }
  @Action(TripRemoveById)
  dataByIdRemoved(context: StateContext<TripStateModel>, action: TripRemoveById) {
    setTimeout(() => {
      // this.storeDeletedKey(action.payload.id);

      const state = context.getState();
      if (Object.keys(state.data).indexOf(`${action.payload.id}`) > -1) {
        const currState = context.getState();
        const newData = { ...currState.data };
        delete newData[action.payload.id];
        context.patchState({ data: newData });
        const state1 = context.getState();
        logger.log('[Trip-State], item removed by id', action.payload.id, state1);

      } else { logger.log('[Trip-State], item to be removed id is not available in the store'); }
    }, 1000);
  }
}
