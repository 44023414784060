import { Length, IsDate, IsDefined } from 'class-validator';
import { BidStatus } from './bid-status';

export class BindingBid {
    constructor() {
    }

    @Length(20, 20, { message: 'Database Key needs to be $constraint1 chars' })
    bindingBidId: string | number;

    @IsDate({ message: 'Binding Start date is required' })
    bindingStartD: Date;

    @IsDate({ message: 'Binding End date is required' })
    bindingEndD: Date;

    @IsDefined()
    bidStatus: BidStatus;

    @IsDefined()
    revId: number;
  }
