
<ng-container>
  <mat-card appearance="outlined" class="mat-card">
    <mat-card-header>
      <!-- <div mat-card-avatar class="example-header-image"></div>
      <mat-card-title>Shiba Inu</mat-card-title>
      <mat-card-subtitle>Dog Breed</mat-card-subtitle> -->
      <div mat-card-avatar class="example-header-image ">
        <mat-icon *ngIf="type === 'CUSTOMER'">person</mat-icon>
        <mat-icon *ngIf="type === 'DEALER'">business</mat-icon>
      </div>
      <mat-card-title *ngIf="type === 'CUSTOMER' ">Clients</mat-card-title>
      <mat-card-subtitle class="mat-sub-title-c" *ngIf="type === 'CUSTOMER'">Get industry's best interest rates in 24 hours</mat-card-subtitle>
      <mat-card-title *ngIf="type === 'DEALER' ">Dealers & Vendor Partners</mat-card-title>
      <mat-card-subtitle class="mat-sub-title-c" *ngIf="type === 'DEALER'">Select TPINE as your Leasing & financing solution provider. Complete the inquiry form and account executive will connect you immediately.</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <form #enquiryForm="ngForm" class="p-rel">
        <ng-container>

            <mat-form-field class="full-width">
              <mat-label>Contact Name</mat-label>
              <input  matInput [(ngModel)]="em.fullName" type="text" name="fullName" #fullName="ngModel" />
              <mat-hint *ngIf="!showError(fullName)" align="end">Required</mat-hint>
              <mat-error align='end' *ngIf="showError(fullName)">{{fullName.errors.msg}}</mat-error>
            </mat-form-field>
            <mat-form-field class="full-width">
                <mat-label>Phone Number</mat-label>
                <input matInput [(ngModel)]="em.phoneNumber" type="text" name="phoneNumber" #phoneNumber="ngModel" phone />
                <mat-hint *ngIf="!showError(phoneNumber)" align="end">Optional</mat-hint>
                <mat-error align='end' *ngIf="showError(phoneNumber)">{{phoneNumber.errors.msg}}</mat-error>
            </mat-form-field>
            <mat-form-field class="full-width">
              <mat-label>Email</mat-label>
              <input matInput [(ngModel)]="em.email" type="text" name="email" #email="ngModel" />
              <mat-hint *ngIf="!showError(email)" align="end">Required</mat-hint>
              <mat-error align='end' *ngIf="showError(email)">{{email.errors.msg}}</mat-error>
            </mat-form-field>
  
            <mat-form-field class="full-width" *ngIf="type === 'CUSTOMER'">
              <mat-label>Equipment eg. 2020 Volvo 760</mat-label>
              <input matInput [(ngModel)]="em.equipment" type="text" name="equipment" #equipment="ngModel" />
              <mat-hint *ngIf="!showError(equipment)" align="end">Optional</mat-hint>
              <mat-error align='end' *ngIf="showError(equipment)">{{equipment.errors.msg}}</mat-error>
            </mat-form-field>

            <mat-form-field class="full-width" *ngIf="type === 'DEALER'">
              <mat-label>Approx retail numbers / month</mat-label>
              <input matInput [(ngModel)]="em.retailNumbers" type="number" name="retailNumbers" #retailNumbers="ngModel" />
              <mat-hint *ngIf="!showError(retailNumbers)" align="end">Optional</mat-hint>
              <mat-error align='end' *ngIf="showError(retailNumbers)">{{retailNumbers.errors.msg}}</mat-error>
            </mat-form-field>

        </ng-container>
    
        <button type="button" mat-flat-button color="primary" focus="true" (click)="submit()">Submit</button>
        <p style="font-size: 12px;">By submitting you are accepting that the information may be collected, used and disclosed as per
            our privacy policy.</p>
      </form>
    </mat-card-content>
  </mat-card>
</ng-container>      

