import { DistanceQuery } from './distance-query';
import { IPoint } from '../product-search/interfaces';

export class Distance {
  start: IPoint;

  end: IPoint;

  /** Bing default is km */
  distnace: number;

  /** Bing default is mintues */
  time: number;

  /** Get Distance from bing query. Distance will be in miles and time in hours. */
  public static fromBingDistanceQuery(q: DistanceQuery, result: any): Distance {
    const r: Distance = new Distance();
    r.start = q.origins[0];
    r.end = q.destinations[0];
    const x = result.resourceSets[0].resources[0].results[0];
    r.distnace = x.travelDistance;
    r.time = x.travelDuration;

    if (q.timeUnit === 'minute') {
      r.time /= 60.0;
    } else if (q.timeUnit === 'second') {
      r.time /= 3600.0;
    } else {
      throw Error('Invalid time unit in the query was provided.');
    }
    return r;
  }

}