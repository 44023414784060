import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Carousel2Component } from './carousel2/carousel2.component';
import { SliderCarouselComponent } from './slider-carousel/slider-carousel.component';
import { MatIconModule } from '@angular/material/icon';
import { PercentageCircleComponent } from './percentage-circle/percentage-circle.component';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [
    Carousel2Component,
    SliderCarouselComponent,
    PercentageCircleComponent,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule
  ],
  providers: [
  ],
  exports:[
    Carousel2Component,
    SliderCarouselComponent,
    PercentageCircleComponent,
  ]
})
export class Common2Module { }
