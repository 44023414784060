<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <title>Promotion HTML</title>
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Lora&family=Overpass:ital,wght@0,100;0,300;0,400;0,500;0,600;1,100&display=swap" rel="stylesheet">
    <style>
        
        body{
            margin: 0;
            padding: 0;
            overflow: hidden;
            font-family: 'Overpass', sans-serif;
        }
        
        .promotion-html{
            display: block;
            position: relative;
        }
        
        .promotion-html > .container{
            width:100%;
            height: 100%;
            display: block;
            position: relative;
            overflow: hidden;
            padding: 0;
            margin: 0;
        }
        
        
        .promotion-html > .container > .row{
            display: flex;
            flex-direction: row;
            padding: 0;
            margin: 0;
            overflow: hidden;
        }
        

        .promotion-html > .container > .row > div{
            width:50%;
            display: block;
            position: relative;
            overflow: hidden;
        }
        
        
        .promotion-html > .container > .row > div img{
            width:100%;
            object-fit: cover;
        }
        
        
        .promotion-html > .container > .row > div.left-side-coloum{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            padding: 0 0 0 200px;
            box-sizing: border-box;
        }
        
        
        .promotion-html > .container > .row > div.left-side-coloum h1{
            text-align: left;
            font-size: 48px;
            margin: 0;
            padding: 0;
        }
        
        
        .promotion-html > .container > .row > div.left-side-coloum p {
            font-size: 24px;
            color: #000;
            margin: 0;
            padding: 0px 0 0 0;
        }
        
        
        .promotion-html > .container > .row > div.left-side-coloum p.infromation{
            font-size: 16px;
            color: #848484;
        }
        
        .promotion-html > .container > .row > div.left-side-coloum #action-form{
            width: 484px;
            height: auto;
            display: block;
            position: relative;
            margin-top:20px;
        }
        
        
        .promotion-html > .container > .row > div.left-side-coloum #action-form input {
            width: 100%;
            height: 61px;
            padding: 15px;
            border-radius: 5px;
            color: #1C1C1C;
            box-sizing: border-box;
            border: 1px solid #848484;
            margin-bottom: 15px;
            font-size: 14px;
        }
        
        
        .promotion-html > .container > .row > div.left-side-coloum #action-form input[type="submit"]{
            background: #E51523;
            color: #fff;
            font-size: 22px;
            font-weight: 600;
            border: 1px solid #E51523;
            cursor: pointer;
        }
        
        @media screen and (max-width: 1600px) and (min-width: 1200px){
            
            body{
                overflow: hidden;
            }
            
            .promotion-html > .container > .row > div.left-side-coloum{
                padding: 25px 20px;                
            }
            
            
            .promotion-html > .container > .row > div.left-side-coloum h1{
                font-size: 28px;
            }
            
            .promotion-html > .container > .row > div.left-side-coloum p{
                font-size: 14px;
            }
            
            
            .promotion-html > .container > .row > div.left-side-coloum #action-form{
                width:100%;
            }
            
            
            .promotion-html > .container > .row > div.left-side-coloum p.infromation{
                font-size: 12px;
            }
            
        }
        
        @media screen and (max-width: 1200px) and (min-width: 1000px) {
            
            body{
                overflow: hidden;
            }
            
            .promotion-html > .container > .row > div.left-side-coloum{
                padding: 25px 20px;                
            }
            
            
            .promotion-html > .container > .row > div.left-side-coloum h1{
                font-size: 28px;
            }
            
            .promotion-html > .container > .row > div.left-side-coloum p{
                font-size: 14px;
            }
            
            
            .promotion-html > .container > .row > div.left-side-coloum #action-form{
                width:100%;
            }
            
            
            .promotion-html > .container > .row > div.left-side-coloum p.infromation{
                font-size: 12px;
            }
            
            
        }
        
        
        @media(max-width:1000px){
            
            body{
                overflow: scroll;
            }
            
            .promotion-html > .container > .row{
                display: flex;
                flex-direction: column-reverse
            }
            
            
            .promotion-html > .container > .row > div{
                width:100%;
            }
            
            
            .promotion-html > .container > .row > div img{
                width: 100%;
            }
            
            
            .promotion-html > .container > .row > div.left-side-coloum{
                padding: 25px 20px;
                
            }
            
            
            .promotion-html > .container > .row > div.left-side-coloum h1{
                font-size: 28px;
            }
            
            .promotion-html > .container > .row > div.left-side-coloum p{
                font-size: 14px;
            }
            
            
            .promotion-html > .container > .row > div.left-side-coloum #action-form{
                width:100%;
            }
            
            
            .promotion-html > .container > .row > div.left-side-coloum p.infromation{
                font-size: 12px;
            }
            
        }
    
    </style>
</head>
<body>
   
<section class="promotion-html">
    <div class="container">
        
        <div class="row">
            
            <div class="left-side-coloum">
               
               <h1>Clients</h1>
               <p>Get industry's best interest rate in 24 hours</p>
               
                <form #enquiryForm="ngForm" id="action-form" class="p-rel">                 
                    <div class="full_name">
                        <input type="text" [(ngModel)]="em.fullName" class="input_text" name="fullName" #fullName="ngModel" placeholder="Contact name *" />
                    </div>
                    
                    <div class="phone_number">
                        <input type="text" [(ngModel)]="em.phoneNumber" class="input_text" name="phoneNumber" #phoneNumber="ngModel" placeholder="Phone number" />
                    </div>
                    
                     <div class="email">
                        <input type="text" [(ngModel)]="em.email" class="input_text" name="email" #email="ngModel" placeholder="E-mail address *" />
                    </div>
                    
                     <div class="email">
                        <input type="text" [(ngModel)]="em.equipment" class="input_text" name="equipment" #equipment="ngModel" placeholder="Equipment eg. 2020 Volvo 760" />
                    </div>
                    
                    <input type="submit" (click)="submit()" value="SUBMIT"/>
                    
                    <p class="infromation">By submitting you are accepting that the information may be collected, 
used and disclosed as per our privacy policy.</p>
                     
                    
                </form>
            </div>
            
            
            <div class="right-side-coloum">
                <img src="https://mukeshmehta.info/HTML-Work/Promotion/promotion-bg-2.png" alt=""/>
            </div>
            
        </div>
        
    </div>
</section>

    
</body>
</html>