import { SingletonService } from './../../services/singleton.service';
import { AppSetting } from '@trent/models/sys/app-setting';
import { State, Selector, Action, StateContext } from '@ngxs/store';
import { IDeviceIdComponents } from '@trent/models/fcm/fcm-token-data';
import { cloneDeep } from 'lodash';
import { Injectable } from '@angular/core';
import { logger } from '@trentm/log/logger';

/** Screen state of the application. */
export enum ScreenDisplayMode {
  mobile = 1,
  tablet = 2,
  desktop = 3
}

const MOBILE_MAX_WIDTH = 425; // Adjust as needed
const TABLET_MAX_WIDTH = 1024; // Adjust as needed

export class SetScreenDisplay {
  static readonly type = '[AppState] SetScreenDisplay';
  constructor(public payload: { screen: ScreenDisplayMode; width: number; height: number }) { }
}

export class SetDeviceId {
  static readonly type = '[AppState] SetDeviceId';
  constructor(public payload: IDeviceIdComponents) { }
}

export class GetAppSettingsRequested {
  static readonly type = '[AppState] Get App settings - Requested';
  constructor() { }
}

export class GetAppSettingsLoaded {
  static readonly type = '[AppState] Get App settings - Loaded';
  constructor(public payload: { data: AppSetting }) { }
}

const initAppState = (): AppState1Model => {
  return {
    name: 'TRent',
    screen: ScreenDisplayMode.desktop,
    deviceId: {
      deviceId: '????',
      components: [],
      updatedOn: new Date()
    }
  };
};

export interface AppState1Model {
  name: string;
  screen: ScreenDisplayMode;
  deviceId: IDeviceIdComponents;
  width?: number;
  height?: number;
  appSetting?: AppSetting;
}

/**
 * Actions
 */



// for ngxs

@State<AppState1Model>({
  name: 'appState',
  defaults: initAppState()
})
@Injectable()
export class AppState1 {
  @Selector()
  static currentScreenDisplay(state: AppState1Model) {
    return { screen: state.screen, width: state.width, height: state.height };
  }

  @Selector()
  static deviceId(state: AppState1Model) {
    return cloneDeep(state.deviceId);
  }

  @Selector()
  static appSettings(state: AppState1Model) {
    return cloneDeep(state.appSetting);
  }

  constructor(private singleton: SingletonService) {

  }


  @Action(SetScreenDisplay)
  screenDisplay(context: StateContext<AppState1Model>, action: SetScreenDisplay) {
    // if (action.payload.screen !== context.getState().screen) {
    context.patchState({ screen: action.payload.screen, width: action.payload.width, height: action.payload.height });
    // }
  }

  @Action(SetDeviceId)
  setDeviceId(context: StateContext<AppState1Model>, action: SetDeviceId) {
    const state = context.getState();
    if (state.deviceId.deviceId !== action.payload.deviceId) {
      context.patchState({ deviceId: action.payload });
    }
  }

  @Action(GetAppSettingsRequested)
  getAppSettingsRequested(context: StateContext<AppState1Model>) {
    if (context.getState().appSetting == null && !!this.singleton.firebaseRTDBService) {
      const sub = this.singleton.firebaseRTDBService.obj$('app-setting').subscribe(a => {
        console.log('[AppState] Loaded App setting page from database.', a);
        if (!!a) {
          context.dispatch(new GetAppSettingsLoaded({ data: a as AppSetting }));
        }
      });
    }
  }

  @Action(GetAppSettingsLoaded)
  getAppSettingsLoaded(context: StateContext<AppState1Model>, action: GetAppSettingsLoaded) {
    context.patchState({
      appSetting: action.payload.data
    });
  }







}
