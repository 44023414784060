import { IPointGeo } from '../product-search/interfaces';
import { PromoListBase } from './promo-list-base';
import { Exclude, Expose, plainToInstance, instanceToInstance } from 'class-transformer';
import { sanitizeDateIPoint } from '../utility/sanitize-helper';
import { ValidatorOptions } from 'class-validator';
import { IValidationMsg } from '../error-handling/validation-info';
import { RentalTrailer } from '../rental/rental-trailer';
import { logger } from '../log/logger';
import { isEqual, filter, omitBy, pickBy, isNil, sortBy, map, forEach } from 'lodash';
import { RentOptionStatus } from '../rental/rent-option-status';
import { PromoListItem } from './promo-list-item';
import { Currency } from '../rental/rental-helper';
import { RentOptionParam } from '../rental/rent-option-param';
import { findDistance, isWithinRadius } from '../utility/distf';
import { startOfToday, isBefore, differenceInDays, addDays, isWithinInterval } from 'date-fns';
import { RentalVehicalListItem } from './rental-vehical-list-item';
import { RentalTruck } from '../rental/rental-truck';
import { ProductType } from '../product/product-type';
import { RentalProductBase } from '../rental/rental-product-base';
import { PromoListType } from './promo-list-type';
import { TruckSummary } from '../product';

@Exclude()
export class RentalTruckItem extends RentalVehicalListItem {

  constructor() {
    super();
  }

  @Expose()
  isDayCab: boolean;
  @Expose()
  isIRP: boolean;
  @Expose()
  isAuto: boolean;
  get summary() {
    return (
      `${this.isDayCab ? 'Day Cab Truck, ' : ' Sleeper Truck, '} ${this.isIRP ? 'IRP Plated, ' : 'Local Plated, '}` +
      `${this.isAuto ? 'Automatic Transmission' : 'Manual Transmission'}`
    );
  }
  public static parse(obj) {
    if (obj == null) { return null; }
    const m = plainToInstance<RentalTruckItem, any>(RentalTruckItem, sanitizeDateIPoint(obj));
    // m.sanitize();
    return m;
  }
}
@Exclude()
export class RentalTrailerItem extends RentalVehicalListItem {

  constructor() {
    super();
  }
  @Expose()
  nAxle: number;
  @Expose()
  trailerLength: number;
  @Expose()
  isReefer: boolean;

  // @Expose()
  // geoLoc: IPointGeo;
  // @Expose()
  // modelYear: number;
  // @Expose()
  // unitName: string;
  // @Expose()
  // cid: string;
  // @Expose()
  // rateDaily?: number;
  // @Expose()
  // rateWeekly?: number;
  // @Expose
  // rateMonthly?: number;
  // @Expose()
  // currency: Currency;
  // @Expose()
  // isOneWay: boolean;
  // @Expose()
  // targetCCids: string[];
  // @Expose()
  // availStartDate: Date;
  // @Expose()
  // availEndDate: Date;
  // @Expose()
  // bindingBids: { startD: Date, endD: Date }[];

  get summary() {
    return `${this.trailerLength}' ${this.nAxle}-Axle ` +
      `${(this.isReefer) ? 'Reefer' : 'Dry-Van'} Trailer`;
  }
  public static parse(obj) {
    if (obj == null) { return null; }
    const m = plainToInstance<RentalTrailerItem, any>(RentalTrailerItem, sanitizeDateIPoint(obj));
    // m.sanitize();
    return m;
  }
  // isAvailable() {
  //   if (isBefore(this.availEndDate, startOfToday())) {
  //     return false;
  //   }
  //   const startDate = isBefore(this.availStartDate, startOfToday()) ? startOfToday() : this.availStartDate;
  //   for (let s = startDate; isBefore(s, this.availEndDate); s = addDays(s, 1)) {
  //     if (!this.bindingBids) {
  //       return true;
  //     }
  //     for (const b of this.bindingBids) {
  //       if (!isWithinInterval(s, {start: b.startD, end: b.endD})) {
  //         return true;
  //       }
  //       // if (!isWithinRange(s, b.startD, b.endD)) {
  //       //   return true;
  //       // }
  //     }
  //   }
  //   return false;
  // }
}


/** List of Carriers registered for trip tracking promo */
@Exclude()
export class RentalTrailerAggregate extends PromoListBase {

  public static readonly rentalTrailerAggreateID = 'rental-trailer-aggregate';
  public static readonly rentalTruckAggreateID = 'rental-truck-aggregate';


  @Expose()
  /** Retnal Trailer aggregate list, @param key | Rent-option id */
  list: { [key: string]: RentalVehicalListItem };

  public static parse(obj) {
    if (obj == null) { return null; }
    const m = plainToInstance<RentalTrailerAggregate, any>(RentalTrailerAggregate, sanitizeDateIPoint(obj));
    for (const key in m.list) {
      if (Object.prototype.hasOwnProperty.call(m.list, key)) {
        const element = m.list[key];
        switch (m.listType) {
          case PromoListType.rentalTrailer:
            m.list[key] = RentalTrailerItem.parse(element);
            break;
          case PromoListType.rentalTruck:
            m.list[key] = RentalTruckItem.parse(element);
            break;

          default:
            break;
        }
        m.list[key].key = !m.list[key].key ? key : m.list[key].key;
      }
    }
    m.sanitize();
    return m;
  }
  public static rentalAggId(p: ProductType) {
    switch (p) {
      case ProductType.trailer:
        return RentalTrailerAggregate.rentalTrailerAggreateID;
      case ProductType.truck:
        return RentalTrailerAggregate.rentalTruckAggreateID;
      default:
        throw new Error(`Product Type ${p}`);
    }
  }

  validateSync(options?: ValidatorOptions): IValidationMsg {

    const r = this.validateSyncBase(this);
    return r;

  }
  clone() {
    const t = instanceToInstance(this);
    t.sanitize();
    return t;
  }
  /**
   * adds and updates RentalTrailerAggregate list when new rent-option is added or updated
   * @param rentalProduct updated or new rental-option
   * @param id new or updated rent option id
   * @param returns RentalTrailerAggregate when item is added or updated or deleted
   * @param returns RentalTrailerAggregate when item is added or updated
   */
  updateRentalAggregate(rentalProduct: RentalProductBase, id: string | number): {
    changeRequired: boolean, uAggregate?: RentalTrailerAggregate
  } | null {
    // clone from the doc from db
    const uAggregate = this.clone();
    try {
      // check if rent option status is closed or obsolated, if so remove from the list
      if ((rentalProduct.rentOptionStatus === RentOptionStatus.closed ||
        rentalProduct.rentOptionStatus === RentOptionStatus.obsolete || rentalProduct.isExclusive || !rentalProduct.isActive) && !!uAggregate.list[id] ) {
        uAggregate.list[id] = null;
      } else {
        // intialize list
        if (!uAggregate.list) { uAggregate.list = {} as { [key: string]: RentalTrailerItem; }; }
        // intialize dictionary item
        if (!uAggregate.list[id]) {
          switch (rentalProduct.productType) {
            case ProductType.trailer:
              uAggregate.list[id] = new RentalTrailerItem();
              break;
            case ProductType.truck:
              uAggregate.list[id] = new RentalTruckItem();
              break;
            default:
              break;
          }
        }
        if (rentalProduct.isExclusive) {
          uAggregate.list[id].targetCCids = rentalProduct.targetCCids;
        }else{
          uAggregate.list[id].bestRateString = rentalProduct.getLowestRentString(false);
        }
        uAggregate.list[id].geoLoc = rentalProduct.startAddress.geoLoc;
        uAggregate.list[id].unitName = (rentalProduct as RentalTrailer).productSummary.unitName;
        uAggregate.list[id].cid = rentalProduct.vendorCompSummary.cid;
        uAggregate.list[id].currency = rentalProduct.currency;
        uAggregate.list[id].isOneWay = (rentalProduct as RentalTruck).isOneWay;
        uAggregate.list[id].make = (rentalProduct as RentalTruck).productSummary.make;
        uAggregate.list[id].modelYear = (rentalProduct as RentalTrailer).productSummary.modelYear;
        uAggregate.list[id].availStartDate = rentalProduct.availStartDate;
        uAggregate.list[id].availEndDate = rentalProduct.availEndDate;
        uAggregate.list[id].bindingBids = rentalProduct.getBindingBidDates();
        uAggregate.list[id].isActive = rentalProduct.isActive;

        uAggregate.addSpecificProps(`${id}`, (rentalProduct as RentalTrailer));
      }
      // check if change is required. Compare before and after dictionary item.
      if (!!this.list && (!!this.list[id] && isEqual(uAggregate.list[id], this.list[id]))) {
        return { changeRequired: false };
      } else {
        return { changeRequired: true, uAggregate };
      }
    } catch (error) {
      logger.info('[RentalTrailerAggregate] failed to getRentalTrailerItem ', error);
      return null;
    }
  }
  addSpecificProps(id: string, rentalVeh: RentalTrailer | RentalTruck) {
    switch (rentalVeh.productType) {
      case ProductType.trailer:
        (this.list[id] as RentalTrailerItem).nAxle = (rentalVeh as RentalTrailer).productSummary.nAxle;
        (this.list[id] as RentalTrailerItem).trailerLength = (rentalVeh as RentalTrailer).productSummary.trailerLength;
        (this.list[id] as RentalTrailerItem).isReefer = (rentalVeh as RentalTrailer).productSummary.isReefer;

        break;
      case ProductType.truck:
        (this.list[id] as RentalTruckItem).isDayCab = (rentalVeh as RentalTruck).productSummary.isDayCab;
        (this.list[id] as RentalTruckItem).isAuto = (rentalVeh as RentalTruck).productSummary.isAuto;
        (this.list[id] as RentalTruckItem).isIRP = (rentalVeh as RentalTruck).productSummary.isIRP;
        break;
      default:
        throw new Error(`${rentalVeh.productType} is not implemented`);
    }
  }
  filterAggList(param: RentOptionParam, radius = 100): {
    filtered: {[key: string]: RentalVehicalListItem}, alternate?: {[key: string]: RentalVehicalListItem};
  } {
    let filtered = this.clone().list;
    let alternate: {[key: string]: RentalVehicalListItem} = {};
    if (!!param.center) {
      filtered = pickBy(filtered, (val, key) => isWithinRadius(param.center, val.geoLoc.geopoint, radius));
      if (Object.keys(filtered).length === 0) {
      // tslint:disable-next-line:max-line-length
         const a = sortBy(this.clone().list, (val, key) => findDistance(param.center.latitude, param.center.longitude, val.geoLoc.geopoint.latitude, val.geoLoc.geopoint.longitude));
         a.forEach(element => {
          alternate[element.key] = element;
         });
      }
    }
    if (typeof param.rid === 'string') {
      filtered = pickBy(filtered, (val, key) => key === param.rid);
    }
    if (param.type === ProductType.trailer) {
      if (typeof param.isReefer === 'boolean') {
        filtered = pickBy(filtered, (val, key) => (val as RentalTrailerItem).isReefer === param.isReefer);
      }
      if (typeof param.nAxle === 'number') {
        filtered = pickBy(filtered, (val, key) => (val as RentalTrailerItem).nAxle === param.nAxle);
      }
      if (Object.keys(filtered).length === 0) {
        alternate = this.clone().list;
      }
    }
    if (param.type === ProductType.truck) {
      if (typeof param.isDayCab === 'boolean') {
        filtered = pickBy(filtered, (val, key) => (val as RentalTruckItem).isDayCab === param.isDayCab);
      }
      if (param.make != null && param.make !== '*') {
        filtered = pickBy(filtered, (val, key) => (val as RentalTruckItem).make?.toLowerCase() === param.make.toLowerCase());
      }
      if (Object.keys(filtered).length === 0) {
        alternate = this.clone().list;
      }
    }
    return {filtered, alternate};
  }

  removeExlusive(cid?: string): RentalTrailerAggregate {
    const r = this.clone();
    if (!cid) {
      r.list = pickBy(r.list, (val, key) => isNil(val.targetCCids));
    } else {
      r.list = pickBy(r.list, (val, key) => isNil(val.targetCCids) || val.targetCCids.includes(cid));
    }
    return r;
  }
  removeNotAvail(): RentalTrailerAggregate {
    const r = this.clone();
    r.list = pickBy(r.list, (val, key) => !!val.isAvailable() && !!val.isActive);
    return r;
  }

}
