import { UrlDownloader } from '@trent/models/UI/url-downloader';
import { Component, OnInit, Input, OnDestroy, Output, ElementRef, ViewChild } from '@angular/core';
// import { AbstractValueAccessor, MakeProvider, readErrorMessage } from '@trent/models';
import { FileInfo } from '@trent/models/media/file-info';
import { Observable, Subscription, noop, Subject } from 'rxjs';
// import 'firebase/storage';
import { finalize, tap } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { MakeProvider, AbstractValueAccessor } from '@trent/models/abstract-value-accessor';
import { readErrorMessage } from '@trent/models/error-handling';
import { ShowAlert } from '@trent/store/notification-store';
import { logger } from '@trent/models/log/logger';
import { UtilityService } from '@trent/services/utility.service';
import { AppState1 } from '@trent/store/root-store';
import { Clipboard } from '@angular/cdk/clipboard';
import { FirestoreService } from '@trent/services/firestore.service';
import { percentage, getDownloadURL, getStorage, ref, Storage, StorageReference, uploadBytesResumable, UploadMetadata, getMetadata, UploadTaskSnapshot, UploadTask } from '@angular/fire/storage';


@Component({
  selector: 'trent-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  providers: [MakeProvider(FileUploadComponent)]
})
export class FileUploadComponent extends AbstractValueAccessor<FileInfo> implements OnInit, OnDestroy {

  hasError = false;

  errorStr = '!!Error String';

  showProgress = false;

  // if the control must upload a file. otherwise, it is optional.
  required = false;

  pctComplete = 0;

  /** Mb Limit */
  @Input() sizeLimit = 50; //15;  //5

  @Input() fileType = ['image', 'application']; //type of file which we want to upload

  // @Input() fileformat = ['jpg', 'jpeg', 'png', 'pdf']; // valid file formats

  @Input() uploadPath: string;
  isHandset$: Observable<boolean>;
  sub: Subscription;

  /** Fire get secure link, if @property useSecureUrl is true */
  // @Output() getSecureLink = new EventEmitter<any>();

  get useSecureUrl() { return this.secureUrl != null; }

  @Input() secureUrl: UrlDownloader;
  /**
   * Default Name of file, if defined (and refFName is not defined ),
   * it will use assigned to the uploaded file. wil lbe used if no file was specified.
   */
  @Input() defaultFName = '';

  /**
   * If ref is defined, new file name will be an incremental to this name.
   */
  @Input() refFName = '';

  // Input subcription, that will instruct uploader to start upload.
  @Input() upload$: Observable<string>;
  private uploadSub: Subscription;

  // Dispatcher of the outcome of results. true if success, false if there is an error
  private uploadResultSub: Subject<boolean>;
  @Output() uploadResult$: Observable<boolean>;

  //  @ViewChild('filePicker') someInput: ElementRef;
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
  private _editMode = false;
  ref: StorageReference;
  @Input() set isEditMode(v: boolean) {
    this._editMode = v;
    if (!v) {
      setTimeout(() => this.hasError = false, 100);
    }
  }
  get isEditMode() { return this._editMode; }

  isHovering: boolean;

  fileData: FileList;
  // file: File;

  task: UploadTask;
  // percentage: Observable<number>;
  snapshot: Observable<UploadTaskSnapshot>;
  private downloadUrlSub: Subscription;
  downloadURL: string;
  prevPath: string;

  /// downloadLink anchor tag element reference
  @ViewChild('downloadLink', { static: false })
  downloadLink: ElementRef;



  constructor(private storage: Storage, private db: FirestoreService, private store: Store, private us: UtilityService, private clipboard: Clipboard) {
    super();

    // setup the output
    this.uploadResultSub = new Subject();
    this.uploadResult$ = this.uploadResultSub.asObservable();

  }

  get hasFile() { return (!!this.fileData && this.fileData.length > 0); }

  get hasValue() {
    return !!this.value && this.value.name && this.value.name.trim().length > 0;
  }

  toggleHover(event: boolean) {
    this.isHovering = event;
    // logger.log(this.isHovering);
  }

  selectFiles(event: FileList) {
    logger.log('startupload fired', event, this.checkFile(event.item(0)));
    this.hasError = false;
    if (event.length > 0 && this.checkFile(event.item(0))) {
      this.fileData = event;
      if (!!this.refFName && this.refFName !== '' && typeof this.value === 'object') {
        this.value.name = FileInfo.getNextRev(this.refFName);
      } else if (!!this.defaultFName && this.defaultFName !== '' && typeof this.value === 'object') {
        this.value.name = this.defaultFName;
      } else if (typeof this.value === 'object') {
        this.value.name = this.fileData.item(0).name;
      }
      if (typeof this.value === 'object') {
        this.value.uploadedOn = new Date();
      }
    }
  }

  ngOnInit() {
    // logger.log('cons called ', this.refFName);
    this.isHandset$ = this.us.isHandset$;
    if (this.upload$ != null) {
      this.uploadSub = this.upload$.subscribe(path => this.uploadFile(path));
    }
    if (!!this.value && this.value.path) {
      this.updateUrl();
    }
  }

  ngOnDestroy(): void {
    if (!!this.uploadSub) {
      this.uploadSub.unsubscribe();
    }
    if (!!this.downloadUrlSub) {
      this.downloadUrlSub.unsubscribe();
    }
    if (!!this.sub) {
      this.sub.unsubscribe();
    }
  }

  updateUrl() {
    // ULR update is only valid, if it is a non-secure url, i.e. in secure cases,
    // urls are handled on demand.
    if (this.useSecureUrl) {
      return;
    }
    if (!!this.value && this.prevPath !== this.value.path) {
      this.prevPath = this.value.path;

      if (!!this.downloadUrlSub) {
        this.downloadUrlSub.unsubscribe();
      }

      if (!!this.value.path && this.value.path.length > 0) {
        var r = ref(this.storage, this.value.path);
        getDownloadURL(r)
          .then(url => {
            this.downloadURL = url;
          })
          .catch(err => {
            logger.log('Error happened while fetching uploaded file', err);
          });

        // this.downloadUrlSub = getDownloadURL(r)          // .pipe(take(1))
        //   .subscribe(p => {
        //     // logger.log('download url was called with path: ', this.value.path, p);
        //     this.downloadURL = p;
        //   }, (error) => {
        //     logger.log('Error happened while fethching uploaded file', error);
        //   });
      }
    }
  }

  uploadFile(path: string): void {
    if (this.fileData == null) {
      return;
    }
    logger.log('upload file was called on path', path);
    this.hasError = false;
    for (let i = 0; i < this.fileData.length; i++) {
      if (!this.checkFile(this.fileData.item(i))) {
        this.uploadResultSub.next(false);
        return;
      }
    }
    // Start updating file.
    this.showProgress = true;
    const file = this.fileData.item(0);

    // The storage path
    path = `${path}/${new Date().getTime()}_${this.value.name}`;

    const storage = getStorage(this.storage.app); // , `${fireProject.projectId}.${bname}`);  ?? No bucket url???

    // Reference to storage bucket
    this.ref = ref(storage, path);
    // this.ref = this.afStorage.ref(path);

    // Totally optional metadata
    const customMetadata = { app: 'Company ID to populated from claim' };

    // The main task
    this.task = uploadBytesResumable(this.ref, file, { customMetadata });
    // this.task = this.afStorage.upload(path, file, { customMetadata });


    // Progress monitoring
    // this.percentage = this.task.percentageChanges();
    const obs = percentage(this.task).pipe(
      tap(x => {
        var snap = x.snapshot;
        var progress = x.progress;
        logger.log('snap: ', snap.bytesTransferred, snap.totalBytes);
        this.pctComplete = snap.bytesTransferred / snap.totalBytes * 100;
        logger.log('Percentage: ', this.pctComplete);
        if (snap.bytesTransferred === snap.totalBytes) {
          // Update firestore on completion
          this.db.add(`file-uploads/${path}`, { size: snap.totalBytes });
        }
      }),
      finalize(async () => {
        this.value.path = path;
        this.fileData = null;
        this.showProgress = false;
        console.log(this.useSecureUrl, 'useSecureUrl:::');
        if (!this.useSecureUrl) {
          try {
            this.downloadURL = await getDownloadURL(this.ref);
            logger.log('downloadURL:::', this.downloadURL);
            this.value.url = this.downloadURL;
          } catch (error) {
            logger.log('Download URL ERROR', error);
          }
        }
        this.uploadResultSub.next(true);
      })).subscribe(noop);


    // this.snapshot = this.task.snapshotChanges();
    // const obs = this.snapshot.pipe(
    //   tap(snap => {
    //     logger.log('snap: ', snap.bytesTransferred, snap.totalBytes);
    //     this.pctComplete = snap.bytesTransferred / snap.totalBytes * 100;
    //     logger.log('Percentage: ', this.pctComplete);
    //     if (snap.bytesTransferred === snap.totalBytes) {
    //       // Update firestore on completion
    //       this.db.add(`file-uploads/${path}`, {size: snap.totalBytes });
    //     }
    //   }),
    //   finalize(async () => {
    //     this.value.path = path;
    //     this.fileData = null;
    //     this.showProgress = false;
    //     if (!this.useSecureUrl) {
    //       try {
    //         this.downloadURL = await this.ref.getDownloadURL().toPromise();
    //         logger.log('downloadURL', this.downloadURL);
    //         this.value.url = this.downloadURL;
    //       } catch (error) {
    //         logger.log('Download URL ERROR', error);
    //       }
    //     }
    //     this.uploadResultSub.next(true);
    //   })).subscribe(noop);


    logger.log('downloadURL', this.downloadURL);
  }

  checkFile(f: File): boolean {
    if(this.refFName === 'Sales Options File' || this.refFName === 'Legacy Contract File' || this.refFName === 'Promotion Lead File') {
      return true;
    }
    // if (f.type.split('/')[0] !== 'image' && f.type !== 'application/pdf' && f.type.split('/')[0] !== 'video') {
    if (!this.fileType.includes(f.type.split('/')[0])) { // checking the valid file type
      this.errorStr = 'Error! only image or pdf or video files are allowed.';
      this.hasError = true;
      return false;
    }
    if (f.size / 1024 / 1024 > this.sizeLimit) {
      this.errorStr = `File is too big. Size limit is : ${this.sizeLimit}mb`;
      this.hasError = true;
      return false;
    }
    return true;
  }

  removeFile() {
    logger.log('yes it was cl');

    if (!!this.value) {
      this.value.name = undefined;
      // this.value = undefined;
      this.value.path = undefined;
      this.value.uploadedOn = undefined;
      this.fileData = null;
    }
  }

  // Get secure download url
  async getSecureUrl(anchor) {
    try {
      await this.secureUrl.download(this.value.name, anchor, false);
    } catch (error) {
      const stateOpt = readErrorMessage(error);
      this.store.dispatch(new ShowAlert({ messageInfo: stateOpt }));
    }
  }
  async copy() {
    try {
      const url = await this.secureUrl.download(this.value.name, this.downloadLink.nativeElement, true);
      this.clipboard.copy(url.toString());

    } catch (error) {
      const stateOpt = readErrorMessage(error);
      this.store.dispatch(new ShowAlert({ messageInfo: stateOpt }));
    }


  }
  openLocal() {
    if (!!this.fileData) {

      this.sub = this.store.select(AppState1.currentScreenDisplay).subscribe(x => {
        const h = x.height;
        const w = x.width;
        const windowFeatures = `left=${w / 2},top=${0}, height=${h * 4 / 4}, width=${w * 1 / 2}`;

        const fileURL = window.URL.createObjectURL(this.fileData[0]);
        let tab = window.open(undefined, undefined, windowFeatures);
        tab.location.href = fileURL;
      });

    }

  }
}
