import { Injectable } from '@angular/core';
import { ILocalStorage } from '@trent/models/local-storage/local-storage';
import { Storage } from '@ionic/storage';
import { Platform } from '@ionic/angular';

/**
 * Do not use this service calss directly, USE @class LocalStorage
 * This will be used on cordova and web
 */
@Injectable()
export class LocalStorageCdvaWeb implements ILocalStorage {

  get(key: string): Promise<any> {
    return this.storage.get(key);

  }
  set(key: string, value: any): Promise<any> {
    return this.storage.set(key, value);
  }
  remove(key: string): Promise<any> {
    return this.storage.remove(key);
  }
  clear(): Promise<void> {
    return this.storage.clear();
  }
  length(): Promise<number> {
    return this.storage.length();
  }
  keys(): Promise<string[]> {
    return this.storage.keys();
  }

  constructor(private storage: Storage, platform: Platform) {

  }

}
