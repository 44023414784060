import { BidBase } from './bid-base';
import { plainToInstance, instanceToInstance } from 'class-transformer';
import { TruckSummary } from '../product/truck';
import { sanitizeDateIPoint } from '../utility';
import { parseNegoTerms } from '../nego-terms-base/nego-terms-helper';
import { TermsType } from '../nego-terms-base/terms-type';
import { Address } from '../address/address';

export interface TruckProdSummary {
  unitName: string;
  make: string;
  modelYear: number;
  isDayCab: boolean;
  isAuto: boolean;
  isIRP: boolean;
}

export class BidTruck extends BidBase {

  constructor() {
    super();

  }
  productSummary: TruckSummary;



  public static parse(obj) {
    if (obj == null) { return null; }
    const m = plainToInstance<BidTruck, any>(BidTruck, sanitizeDateIPoint(obj));
    m.bidNegoTerms = parseNegoTerms(obj.bidNegoTerms);
    if(m?.rentSummary?.startAddress){
      m.rentSummary.startAddress = Address.parse(m.rentSummary.startAddress);
    }
    if(m?.rentSummary?.endAddress){
      m.rentSummary.endAddress = Address.parse(m.rentSummary.endAddress);
    }

   m.sanitize();
    return m;
  }
  clone() {
    const t = instanceToInstance(this);
    t.bidNegoTerms = this.bidNegoTerms.clone();

    t.sanitize();
    return t;
  }

  sanitize() {
    super.sanitize();
  }
  get summary() {
    return `${(this.productSummary.isDayCab) ? 'Day Cab Truck, ' : ' Sleeper Truck, '}
      ${(this.productSummary.isIRP) ? 'IRP Plated, ' : 'ON Plated, '}` +
      `${(this.productSummary.isAuto) ? 'Automatic Transmission' : 'Manual Transmission'}`;
  }
}
