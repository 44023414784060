import { Order } from './order';
import { Paging } from '../../observable-util/paging';
import { TransactionType } from '../invoice/invoice-type';
import { UserEnum } from '../../user/user-enum';
import { isNil } from 'lodash';
import { CollectionReference, limit, orderBy, query, Query, QueryConstraint, startAfter, where } from 'firebase/firestore';
/** Search params for fetching the orders by user settings. */
export interface IOrderParam {
  cid?: number | string;
  orderDirection?: 'desc' | 'asc';
  orderBy?: string;
  refId?: string | number;
  invoiceType?: TransactionType;
  invoiceId?: string | number;
  orderCat?: UserEnum;
  vCid?: number | string;
  nextPaymentDateStart?: Date;
  nextPaymentDateEnd?: Date;
  creditUserId?: string | number; // Credit User Id
  customerAuthId?: string | number;
}

/** Server filtering of firebase query building of query  */
export const orderSerachServerQuery = (ref: CollectionReference, param: IOrderParam, p: Paging) => {
  const cons: QueryConstraint[] = [];
  if (!!param.cid) {
    cons.push(where('cid', '==', param.cid));
  }
  if (!!param.refId) {
    cons.push(where('refId', '==', param.refId));
  }
  if (!!param.invoiceType) {
    cons.push(where('invoiceType', '==', param.invoiceType));
  }
  if (!!param.invoiceId) {
    cons.push(where('invoiceId', '==', param.invoiceId));
  }
  if (!isNil(param.orderCat)) {
    cons.push(where('orderCat', '==', param.orderCat));
  }
  if (!isNil(param.refId)) {
    cons.push(where('refId', '==', param.refId));
  }
  if (!isNil(param.vCid)) {
    cons.push(where('vCid', '==', param.vCid));
  }
  if (!isNil(param.nextPaymentDateStart)) {
    cons.push(where('nextPaymentDate', '>=', param.nextPaymentDateStart));
    cons.push(orderBy('nextPaymentDate', 'desc'));
  }
  if (!isNil(param.nextPaymentDateEnd)) {
    cons.push(where('nextPaymentDate', '<=', param.nextPaymentDateEnd));
    if(!param.nextPaymentDateStart) {
      cons.push(orderBy('nextPaymentDate', 'desc'));
    }

  }
  if (!!param.customerAuthId) {
    cons.push(where('customerAuthIds', 'array-contains', param.customerAuthId));
  }
  if (!!param.creditUserId) {
    cons.push(where('vendorAuthIds', 'array-contains', param.creditUserId));
  }
  cons.push(orderBy(param.orderBy, param.orderDirection));

  if (!!p.lastDoc) {
    console.log('Start After Query: ', p.lastDoc[param.orderBy]);
    cons.push(startAfter(p.lastDoc[param.orderBy]));
  }
  cons.push(limit(p.size));
  return query(ref, ...cons);
};

export const orderSearchServerCountQuery = (ref: CollectionReference, param: IOrderParam) => {

  const cons: QueryConstraint[] = [];

  if (!!param.cid) {
    cons.push(where('cid', '==', param.cid));
  }
  // if (!!param.refId) {
  //   cons.push(where('refId', '==', param.refId));
  // }
  if (!!param.invoiceType) {
    cons.push(where('invoiceType', '==', param.invoiceType));
  }
  if (!!param.invoiceId) {
    cons.push(where('invoiceId', '==', param.invoiceId));
  }
  if (!isNil(param.orderCat)) {
    cons.push(where('orderCat', '==', param.orderCat));
  }
  if (!isNil(param.refId)) {
    cons.push(where('refId', '==', param.refId));
  }
  if (!isNil(param.vCid)) {
    cons.push(where('vCid', '==', param.vCid));
  }
  if (!!param.customerAuthId) {
    cons.push(where('customerAuthIds', 'array-contains', param.customerAuthId));
  }
  if (!!param.creditUserId) {
    cons.push(where('vendorAuthIds', 'array-contains', param.creditUserId));
  }
  if (!isNil(param.nextPaymentDateStart)) {
    cons.push(where('nextPaymentDate', '>=', param.nextPaymentDateStart));
    cons.push(orderBy('nextPaymentDate', 'desc'));
  }
  if (!isNil(param.nextPaymentDateEnd)) {
    cons.push(where('nextPaymentDate', '<=', param.nextPaymentDateEnd));
    if(!param.nextPaymentDateStart) {
      cons.push(orderBy('nextPaymentDate', 'desc'));
    }

  }

  return query(ref, ...cons);
};


/** Client side filtering of the orders. */
export const orderSerachClientFilter = (p: Order[], o: IOrderParam) => {

  if (!!o.cid) {
    p = p.filter((val, idx) => val.cid === o.cid);
  }
  // if (!!o.refId) {
  //   p = p.filter((val, idx) => val.refId === o.refId);
  // }
  if (!!o.invoiceType) {
    p = p.filter((val, idx) => val.invoiceType === o.invoiceType);
  }
  if (!!o.invoiceId) {
    p = p.filter((val, idx) => val.invoiceId === o.invoiceId);
  }
  if (!isNil(o.orderCat)) {
    p = p.filter((val, idx) => val.orderCat === o.orderCat && !isNil(val.orderCat));
  }
  if (!isNil(o.refId)) {
    p = p.filter((val, idx) => val.refId === o.refId && !isNil(val.refId));
  }
  if (!isNil(o.vCid)) {
    p = p.filter((val, idx) => val.vCid === o.vCid && !isNil(val.vCid));
  }
  if (!isNil(o.nextPaymentDateStart)) {
    p = p.filter((val, idx) => val.nextPaymentDate.valueOf() >= o.nextPaymentDateStart.valueOf());
  }
  if (!isNil(o.nextPaymentDateEnd)) {
    p = p.filter((val, idx) => val.nextPaymentDate.valueOf() <= o.nextPaymentDateEnd.valueOf());
  }
  return p;
};



/** Get the OR condition options. if there are more then one company id's are provided
 *  each cid makes up a unique OR condition query.
 * TBD function, see Pick-Drop imlementation */
export const getIOrdersOptionOrChildren = (o: IOrderParam): { [key: string]: IOrderParam } => {
  // if (!!o.cids && o.cids.length > 1) {
  //   const r: { [key: string]: PickDropParam } = {};
  //   o.cids.forEach((cid) => {
  //     const c = DeepCopy.copy(o);
  //     c.cids = [cid];
  //     r[getObjKey(c)] = c;
  //   });
  //   return r;
  // }

  return undefined;
};
