import { isIPoint, sanitizeIPoint } from './../product-search/interfaces';
import { LibSetting } from './../lib-setting';
import { keysIn, isString, isNumber, isArray, isBoolean, isObject, isDate, concat, omitBy, isNil, identity, pickBy } from 'lodash';

import { filter } from 'lodash';
import { IValidationMsg } from '../error-handling/validation-info';
import { instanceToPlain } from 'class-transformer';
import { IEventListener } from '@trent/services/event.service';
import { Subscription } from 'rxjs';
import { logger } from '../log/logger';
import { isNaN } from 'lodash';
import { Timestamp } from 'firebase/firestore';

/** Result data from a promise. */
export interface PResult<T> {
  id?: string | number;
  data: T;
  error: any;
  success: boolean;
}

/**
 * @param promise simple promise wrapper to get data in async await pattern.
 */
export const promiseWraper = <T>(p: Promise<T>) => {
  const r: PResult<T> = { data: null, error: null, success: true };
  // const d: T = null;
  // const r = { data: d, error: null };
  return p
    .then(data => {
      // logger.log('Success from wrapper', data);
      r.data = data;
      return r;
    })
    .catch(error => {
      // logger.log('error from wrapper', error);
      r.error = error;
      r.success = false;
      return r;
    });
};

/** concate all the errors from validation in a simple string list */
export const getErrorList = function (msg: IValidationMsg) {
  let a: string[] = [];
  keysIn(msg).forEach(val => {
    a = concat(a, msg[val]);
  });
  return a;
};

/**
 *
 * @param data Data to be converted.
 * @param ignoreKeys keys of the Data, that need to be ignored.
 * @param createGeoPoint if true, it create GeoPoint object instead of plain object.
 * @param getGeoPointFn Use GeoPoint create function. must pass if caller is from firebase functions.
 * for client side it can be null.
 */
export function firestoreMap(data, ignoreKeys: string[] = []): any {
  // export function firestoreMap(data, ignoreKeys: string[] = [], getGeoPointFn?: (obj: any) => any) {

  // const get LibSetting
  if (isString(data)) {
    return data;
  }
  if (isNumber(data)) {
    return +data;
  }
  if (data == null || data === undefined) {
    return null;
  }
  if (isDate(data)) {
    return new Date(Date.parse(`${data}`));
  }
  if (isBoolean(data)) {
    return data;
  }

  const getGeoPointFn = LibSetting.geoPointType;
  if (isArray(data)) {
    const x = [];
    for (let i = 0; i < data.length; i++) {
      x.push(firestoreMap(data[i], ignoreKeys));
    }
    return x;
  }
  if (isIPoint(data)) {
    sanitizeIPoint(data);
    return new getGeoPointFn(data.latitude, data.longitude);
  }

  if (isObject(data)) {
    const okeysUnFilt = keysIn(data);
    const okeys = <string[]>filter(okeysUnFilt, key => typeof data[key] !== 'function' && data[key] != null); // remove the function keys

    // logger.log('type of geoPointFun: ', (typeof getGeoPointFn));

    // // handle special case for firestore GeoPoint type
    // if (okeys.length === 2) {

    //   const g1 = ((typeof getGeoPointFn) === 'function') ? new getGeoPointFn(data) : getGeoPoint(data);
    //   if (!!g1) {
    //     // logger.log('data has two keys and a valid geopoint');
    //     return g1;
    //   }
    // }

    const r = {};
    for (const k of okeys) {
      if (ignoreKeys.indexOf(k) === -1) {
        // if (k === 'geopoint') {
        //   // Use GeoPoint create function, if call is made from the functions.
        //   const g2 = ((typeof getGeoPointFn) === 'function') ? getGeoPointFn(data[k]) : getGeoPoint(data[k]);
        //   // logger.log('got here, found geopoint1: ', data, g2);

        //   r[k] = g2;
        // } else {
        r[k] = firestoreMap(data[k], ignoreKeys);
        // }
      }
    }
    return r;
  }
  logger.log('input object could not convert to firebase map', data);

  /** unable to do it */
  throw Error('input object could not convert to firebase map');
}

/** If the Given object is a firebase server timestamp. */
export const isServerTimeStamp = data => {
  if (!!data && (data['_methodName'] === 'FieldValue.serverTimestamp' || data['methodName'] === 'FieldValue.serverTimestamp')) {
    return true;
  }
  return false;
};
/**
 *
 * @param data Cleaup up object by removing null/undefined.
 */
export function cleanupUndefined<T>(data: T) {
  /** ensure that Server.FieldTimestam value is not removed here as Server.FieldTimestamp is a class with
   * no properties/keys in it. */
  if (isServerTimeStamp(data)) {
    return data;
  }

  if (isNaN(data)) {
    return undefined;
  }

  if (data instanceof Date) {
    if (isNaN(<any>data)) {
      return undefined;
    } else {
      return data;
    }
  }

  if (data instanceof LibSetting.geoPointType) {
    return data;
  }

  if (isArray(data) && (data as Array<any>).length > 0) {
    const newA = [];
    for (let i = 0; i < (data as Array<any>).length; i++) {
      const v = cleanupUndefined(data[i]);
      data[i] = v;
      if (!!v) {
        newA.push(v);
      }
    }
    if (newA.length < (data as Array<any>).length) {
      for (let i = 0; i < newA.length; i++) {
        data[i] = newA[i];
      }
      while ((data as Array<any>).length > newA.length) {
        (data as Array<any>).pop();
      }
    }
    return data;
  }
  if (isObject(data)) {
    // data = pickBy(data, identity) as T;
    data = pickBy(data, (x) => (identity(x) || typeof x === 'boolean' || typeof x === 'number') && !isNaN(x)) as T;
    const okeys = keysIn(data);
    // logger.log(`[cleanup updef] Keys are : ${JSON.stringify(okeys)}`);

    for (const k of okeys) {
      // if (k === 'draftId') {
      //   logger.log('got here!!');
      // }
      // if (data[k] == null) {
      //   data[k] = undefined;
      //   delete data[k];
      // }
      data[k] = cleanupUndefined(data[k]);
      if (isObject(data[k]) && keysIn(data[k]).length === 0 && !isDate(data[k]) && !isServerTimeStamp(data[k])) {
        delete data[k];
      }
    }
    return data;
  }
  return data;
}

/** alterntate, to cleanup undefined. 
 * Not fully tested YET
*/
export function cleanUp(data: object) {
  if (
    typeof data === 'bigint' ||
    typeof data === 'boolean' ||
    typeof data === 'function' ||
    typeof data === 'bigint' ||
    typeof data === 'string' ||
    typeof data === 'symbol' ||
    typeof data === 'undefined' ||
    data == null ||
    isDate(data)
  ) {
    return data;
  }

  if (isArray(data) && (data as Array<any>).length > 0) {
    const newA = [];
    for (let i = 0; i < (data as Array<any>).length; i++) {
      const v = cleanUp(data[i]);
      data[i] = v;
      if (!!v) {
        newA.push(v);
      }
    }
    if (newA.length < (data as Array<any>).length) {
      for (let i = 0; i < newA.length; i++) {
        data[i] = newA[i];
      }
      while ((data as Array<any>).length > newA.length) {
        (data as Array<any>).pop();
      }
    }
    return data;
  }

  if (isObject(data)) {
    data = omitBy(data, isNil);
    const okeys = keysIn(data);
    if (okeys.length === 0) {
      return null;
    }
    for (const k of okeys) {
      data[k] = cleanUp(data[k]);
      if (isObject(data[k]) && keysIn(data[k]).length === 0 && !isDate(data[k]) && !isServerTimeStamp(data[k])) {
        delete data[k];
      }
    }
    return data;
  }
  return data;
}

/** Clean the subscriptions and Event listeners */
export function cleanListeners(evtListener: IEventListener[], subs: Subscription[], timers?: any[]) {
  let l: IEventListener;
  while ((l = evtListener.pop()) !== undefined) {
    l.ignore();
  }
  let s: Subscription;
  while ((s = subs.pop()) !== undefined) {
    s.unsubscribe();
  }
  if (!!timers) {
    let t = null;
    while ((t = timers.pop()) !== undefined) {
      clearTimeout(t);
    }
  }

}

export function getClassName(clazz): string {
  try {
    if (!!clazz && !!clazz.name) {
      return clazz.name;
    }
    if (typeof clazz === 'function') {
      const instance = new clazz();
      return instance.constructor.name;
    }
  } catch (error) {
    return '?';
  }
  return '?';
  // logger.log(MyClass.name);
}

export function round(value, decimals) {
  return Number(Math.round(<any>(value + 'e' + decimals)) + 'e-' + decimals);
}

// export function firestoreMapOld(data, ignoreKeys: string[] = []) {
//   return mapValues(data, (v) => {
//     logger.log('passed v is : ', v);
//     if (isString(v)) { return v; }
//     if (isNumber(v)) { return +v; }
//     if (v == null || v === undefined) { return null; }
//     if (isDate(v)) { return new Date(Date.parse(`${v}`)); }
//     if (isBoolean(v)) { return v; }
//     if (isObject(v)) {
//       if (isNumber(v.latitude) && isNumber(v.longitude)) {
//         return new firestore.GeoPoint(+v.latitude, +v.longitude);
//       }
//       if (isNumber(v.lat) && isNumber(v.lng)) {
//         return new firestore.GeoPoint(+v.lat, +v.lng);
//       }
//       return firestoreMap(v);
//     }

//     if (isArray(v)) {
//       const x = [];
//       for (let i = 0; i < v.length; i++) {
//         x.push(firestoreMap(v[i]));
//       }
//       return x;
//     }

//     return `Unable to map: ${v}`;
//   });

// }

export const copyObject = <T>(obj: T): T => {
  if (obj === undefined) {
    return undefined;
  }
  if (obj === null) {
    return null;
  }
  return <T>JSON.parse(JSON.stringify(obj));
};

/** if two object are equal by value.  */
export const areEqualByVal = (left, right): boolean => {
  if (left == null || right == null) {
    return false;
  }
  const l = typeof left === 'function' ? instanceToPlain(left) : left;
  const r = typeof right === 'function' ? instanceToPlain(right) : right;
  return JSON.stringify(cleanupUndefined(l)) === JSON.stringify(cleanupUndefined(r));
};

/**
 * if given object is null / undefined or false
 */
export const isNullUndefFalse = (o): boolean => {
  return o === null || o === undefined || o === false;
};

/** To convert string camelCase to a sentence
 *  titlePresident will be returned as Title President */
export const toSentence = (str: string): string => {
  if (typeof str !== 'string') {
    return str;
  }
  let result = str;
  result = result.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
};
/** To get first word of sentence e.g.  WABASH NATIONAL CORPORATION will return WABASH*/
export const toFirstWord = (str: string): string => {
  if (typeof str !== 'string') {
    return str;
  }
  const reg = /(.+?)(?:\s|$)/;
  const match = str.match(reg);
  if (match) {
    return match[1]; // Your match
  } else {
    return str;
  }
};
/** To convert minutes to hours and minutes
 *  90 minutes will be returned as 01:30 */
export const minsToHours = (minutes: number): string => {
  let h: any;
  let m: any;
  if (minutes > 0) {
    h = Math.floor(minutes / 60);
    m = minutes % 60;
    h = h < 10 ? '0' + h : h;
    m = m < 10 ? '0' + m : m;
    return h + ':' + m;
  } else {
    return null;
  }
};
/** To number alpha char
 *  65 will be returned as A */
export const alphaChar = (i: number): string => {
  if (i > 64) {
    return String.fromCharCode(i);
  } else {
    return null;
  }
};

/** get domain from email address
 * abc@email.com will return email.com
 * @param e email string
 *  returns null if @param e is null or not a string or does not include @
 */
export const getEmailDomain = (e: string) => {
  if (!e || typeof e !== 'string' || !e.includes('@')) {
    return null;
  }
  const i = e.indexOf('@');
  return e.substr(i + 1);
};
/** To get first character of string */
export const firstChar = (str: string): string => {
  if (typeof str !== 'string') {
    return str;
  }
  let result = str;
  // set to Camel Case
  result = result.replace(/([A-Z])/g, ' $1');
  // trip leading spaces
  result = result.trim();
  return result.charAt(0).toUpperCase();
};

export const getRandomId = (container, max = 1000, prefix = 'p'): string => {
  let c = Math.floor(Math.random() * max);
  while (!!container[`${prefix}${c}`]) {
    c = Math.floor(Math.random() * max);
  }
  return `${prefix}${c}`;
};

export function cleanupGenericProperties<T>(data: T) {
  let obj = <any>instanceToPlain(data);
  obj.id = undefined;
  obj.createdAt = undefined;
  obj.updatedAt = undefined;
  obj.createdByUid = undefined;
  obj.updatedByUid = undefined;
  obj.dbStatus = undefined;
  obj.revId = undefined;
  obj.draftId = undefined;
  obj = cleanupUndefined(obj);
  return obj;
}
export function isUpdateReqdDocArray(a: any[], b: any[]) {
  if (!a || !b) {
    return true;
  }
  if (a.length !== b.length) {
    return true;
  }
  for (const itr of a) {
    const i = b.findIndex(f => f.id !== itr.id);
    if (i > -1) {
      return true;
    }
  }
  for (const itr of a) {
    const i = b.findIndex(f => f.id === itr.id && f.revId !== itr.revId);
    if (i > -1) {
      return true;
    }
  }
  return false;
}
export function isUpdateReqdDoc(a: any, b: any) {
  if (!a || !b) {
    return true;
  }
  if (!a.id || !b.id || a.revId !== b.revId) {
    return true;
  }

  return false;
}

/** Convert url string query params to param object. */
export function getParamObjFromUrl(url: string): {} {
  try {
    if (!!url) {
      const query = url.split('?');
      if (!!query && query.length > 1) {
        const p = query[1].split('&');
        if (!!p && p.length > 0) {
          const params = {};
          for (const itr of p) {
            const k = itr.split('=');
            params[k[0]] = k[1];
          }
          return params;
        }
      }
    }
    return null;
  } catch (error) {
    logger.log('Register reading params failed');
    return null;
  }
}
/**
 * converts string to pascal case (example user name to pascal case)
 * abc def will become Abc Def
 * @param s string
 */
export function toPascalCase(s: string): string {
  if (!!s) {
    s = s.replace(/(\w)(\w*)/g, (g0, g1, g2) => g1.toUpperCase() + g2.toLowerCase());
  }
  return s;
}


export const getEnumsMembers = <T>(enums: T): string[] => {
  const keys = [];
  for (const enumMember in enums) {
    if (isNaN(parseInt(enumMember, 10))) {
      // logger.log('is number: ', parseInt(enumMember, 10));
      keys.push(enumMember);
    }
  }
  return keys;
};
/** return comma separated string for string for array, [tom, dick, harry] => Tom, Dick and Harry */
export const getCommaSepString = (array: string[]): string => {
  if (array.find(f => typeof f !== 'string')) {
    logger.log(`[helper getCommaSepString], invalid input`);
    return null;
  }
  const a = array.map(e => toSentence(e));
  const last = a.pop();
  return a.join(', ') + ' and ' + last;
};
/** return encoded URL replaces / by %2F, 69UpgrdcdQjbFwPok7gp/product-draft/2 => 69UpgrdcdQjbFwPok7gp%2Fproduct-draft%2F2 */
export const getUrlEncodedSlashToPercentTwoF = (string: string): string => {
  if (typeof string !== 'string') {
    logger.log(`[helper getUrlEncodedSlashToPercentTwoF], invalid input`);
    return null;
  }
  string = string.replace(new RegExp('/', 'g'), '%2F');
  return string;
};

export const getRandomNumber = (digits: number) => {
  return +Math.random().toFixed(digits).toString().replace('0.', '');
};

/** padLeft(123,4) = '01234'. last parameter is the separator */
export const padLeft = (n, width, z = '0'): string => {
  z = z || '0';
  n = n + '';
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
};
export const covertMatTableToCSV = (headerRow: Array<any>, rows: Array<any> ) => {
  let csvData: string;
  csvData += headerRow.join(',') + '\n';

  rows.forEach((row: string[]) => {
    csvData += row.join(',') + '\n';
  });

  const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(blob);
  return url;
  // const link = document.createElement('a');
  // if (link.download !== undefined) {
  //   const url = URL.createObjectURL(blob);
  //   return url;
  //   link.setAttribute('href', url);
  //   link.setAttribute('download', 'data.csv');
  //   link.style.visibility = 'hidden';
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // }
};
export const reverseString = (str: string) => {
  return str.split('').reverse().join('');
};

/**
 * Converts timestamp properties to milliseconds in the given data object.
 * @param {any} data - The data object to be modified.
 * @param {string[]} properties - The list of properties to convert.
 */
export function convertTimestamps(data: any, properties: string[]) {
  properties.forEach(property => {
      const propArr = property.split('.');
      let obj = data;
  
      for (let i = 0; i < propArr.length; i++) {
        const prop = propArr[i];
        if (obj?.[prop]?.seconds) {
          obj[prop] = new Timestamp(obj[prop].seconds, obj[prop].nanoseconds).toMillis();
          break;
        }
        obj = obj?.[prop];
      }
  });
};

/**
 * Convert Algolia time to FireStore Timestamp
 * @param algoliaTimestamp 
 * @returns 
 */
export function convertAlgoliaToFireStoreTimestamps(algoliaTimestamp) {
  let firestoreTimestamp: Timestamp;
  let seconds: number;
let nanoseconds: number;
  if(typeof algoliaTimestamp === 'object') {
     seconds = algoliaTimestamp._seconds;
     nanoseconds = algoliaTimestamp._nanoseconds;
     firestoreTimestamp = new Timestamp(seconds, nanoseconds);
    return firestoreTimestamp;
  } else if( typeof algoliaTimestamp === 'number') {
    seconds = algoliaTimestamp / 1000;
    nanoseconds = 0;
    firestoreTimestamp = new Timestamp(seconds, nanoseconds);
    return firestoreTimestamp;
  } else {
    return algoliaTimestamp;
  }

  // return new Timestamp(
  //   Math.floor(algoliaTimestamp / 1000), // Convert to seconds
  //   (algoliaTimestamp % 1000) * 1000 // Convert milliseconds to microseconds
  // ).toDate();

};

/**
 * Update date value(nested property also) to firebase timeStamp
 * @param obj 
 * @param dateFields array 
 */
export function updateNestedDateValueToFirebaseTimeStamp(obj : any, dateField: string[]) {

  if(!obj) return undefined;
  if(!dateField) return obj;

  for(let j = 0 ; j < dateField.length ; j++){
    const pathParts = dateField[j].split('.');
    let current = obj;
  
    for (let i = 0; i < pathParts.length - 1; i++) {
      const pathPart = pathParts[i];
      if (!current[pathPart]) {
        current[pathPart] = {};
      }
      current = current[pathPart];
    }
  
    current[pathParts[pathParts.length - 1]] = convertAlgoliaToFireStoreTimestamps(current[pathParts[pathParts.length - 1]]);
  }
  
  return obj;
}
/**
 * Get property value from object where property name includes dot notation
 * @param obj 
 * @param propName 
 * @returns 
 */
 export const getProp = (obj: Object, propName: string): string => {
  // split prop name by .
  const propNames = propName.split('.');
  let val: any = obj;
  // iterate over propNames 
  for (let i = 0; i < propNames.length; i++) {  
    // if object is defined then 
    // update obj = obj[propNames[i]] 
    val = val[propNames[i]]; 

  }
  return val; 
  

};
