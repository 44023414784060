import { Injectable } from '@angular/core';
import { IdeviceIdService } from './idevice-id.service';
import { IDeviceIdComponents } from '@trent/models/fcm/fcm-token-data';
import { DeviceIdCoreService } from './device-id-core.service';
import { promiseWraper } from '@trent/models/utility';
import { Platform } from '@ionic/angular';
import { logger } from '@trentm/log/logger';

import { Device, DeviceId, DeviceInfo, } from '@capacitor/device';


/** Do not inject this service in any component. Use @class IdeviceIdService instead.
 * Or better yet, use the @class SingletonService's public property. This this class is singlton also but 
 * it is not imported at the app level to reduce the laods. Singlton class has the only copy in it.
 * 
 * Note: This class is used by @class device-id-cdva to keep a common code base between cordova and capacitor.
 */
@Injectable()
export class DeviceIdCapService extends DeviceIdCoreService implements IdeviceIdService {

  deviceInfo: DeviceInfo = null;
  deviceId: DeviceId = null;

  constructor() {
    super();
  }

  private async loadInfo() {
    if (this.deviceInfo == null) {
      const d = await promiseWraper(Device.getInfo());
      const id = await promiseWraper(Device.getId());
      if(id.success && !!id.data) {
        this.deviceId = id.data;
      }

      if (d.success && !!d.data) {
        this.deviceInfo = d.data;
      } else {
        return false;
      }
    }
    return true;
  }

  async platformInfo(): Promise<string> {
    if (await this.loadInfo()) {
      return this.deviceInfo.platform;
    }
    return 'not-available-cap';
  }


  public async getDeviceId() {
    if (await this.loadInfo()) {
      logger.log('Device UUID is: ' + this.deviceId?.uuid);
      const result: IDeviceIdComponents = {
        deviceId: this.deviceId?.uuid,
        components: [
          { key: 'model', value: this.deviceInfo.model },
          { key: 'platform', value: this.deviceInfo.platform },
          { key: 'manufacturer', value: this.deviceInfo.manufacturer },
          { key: 'serial', value: 'not-available-cap' }
        ],
        updatedOn: new Date()
      };
      return result;
    }
    // not found.
    return {
      deviceId: 'not-available-cap',
      components: [],
      updatedOn: new Date()
    };
  }
}
