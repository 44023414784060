import { IDataLoadStatus, getRootLevelChildren, buildDataRequest, LoadStatus, updatePaging } from './../../models/observable-util/data-status';
import { State, Selector, Action, StateContext } from '@ngxs/store';
import { ProductService } from '@trent/services/product.service';
import { map } from 'rxjs/operators';
import { noop, Subscription } from 'rxjs';
import * as entity from '@trent/store/entity.state';
import { ProductBase } from '@trent/models/product/product-base';

import { StateBase } from '../state-base';
import { ProductSearchParam, parseProduct, productSerachClientFilter, getProdSearchOptOrChildren, ProductSearchAlgolia, ProductDates } from '@trent/models/product';
import { Paging, getObjKey } from '@trent/models/observable-util/paging';
import { PagingContainer } from '@trent/models/observable-util/paging-container';
import { Injectable } from '@angular/core';
import { logger } from '@trent/models/log/logger';
import { AlgoliaSearchService } from '@trent/services/algolia-search.service';
import { updateNestedDateValueToFirebaseTimeStamp } from '@trent/models/utility';

// #region State Model
export interface ProductStateModel {
  options?: ProductSearchParam;
  paging: Paging;
  allProductsLoaded: boolean;
  productsByCompIdLoaded: boolean;

  allProductsLoadStatus: {
    [key: string]: IDataLoadStatus<ProductSearchParam>;
  };


  products: { [id: string]: ProductBase }; // entity.EntityState<ProductBase>;
  totalCount?: number;
}

function initProductState(): ProductStateModel {
  return {
    options: null, //  productOptionInit(),
    paging: { size: 10, offset: 0, full: false },
    allProductsLoaded: false,
    productsByCompIdLoaded: false,
    allProductsLoadStatus: {},
    products: {}
  };
}

// #endregion

// #region actions
export class AllProductsRequested {
  static readonly type = '[Products] All Products Requested';
  constructor(public payload: { pData: Paging, option: ProductSearchParam }) { }
}
export class AllProductsLoaded {
  static readonly type = '[Products] All Products Loaded';
  constructor(public payload: {
    data: { [id: string]: ProductBase }, // Data
    key: string, // primary key
    // cKey: string, // child Key if applicable
    // param: ProductSearchOption, // key, belogns to child, unless cKey is null.
    // pData: Paging // belong to child, unless cKey is null
  }) { }
}

export class AllProductNextPageRequested {
  static readonly type = '[Products] All Products Requested - Next Page';
  constructor(public payload: { option: ProductSearchParam }) { }
}


export class ProductRequested {
  static readonly type = '[Products] Request a single product entity';
  constructor(public payload: { id: string | number }) { }
}
export class ProductLoaded {
  static readonly type = '[Products] Load a single product entity';
  constructor(public payload: { data: ProductBase }) { }
}

export class ProductStateReset {
  static readonly type = '[Products] Reset State';
}

export class CustomProductsRequested {
  static readonly type = '[Products] Custom Products Requested';
  constructor(public payload: { pData: Paging, options: ProductSearchParam }) { }
}

export class ProductsRequestedByCompId {
  static readonly type = '[Products] Products Requested by Company ID';
  constructor(public payload: { pData: Paging, cid: string | number }) { }
}

export class ProductsByCompIdLoaded {
  static readonly type = '[Products] Products by Company ID Loaded';
  constructor(public payload: { products: any }) { } // ref may need to use any instead of ProductBase
}

export class ProductUpdated {
  static readonly type = '[Products] Product Updated';
  constructor(public payload: { pid: string | number, product: ProductBase }) { }
}

export class ProductOptionUpdated {
  static readonly type = '[Products] Product Search Options Updated';
  constructor(public payload: { option: ProductSearchParam }) { }
}

export class ProductRemoveById {
  static readonly type = '[Product] Remove by Id';
  constructor(public payload: { pid: string | number }) { }
}

/**
 * @author - PT
 * @purpose - to get all the products using Algolia
 */
export class FetchProductsUsingAlgolia {
  static readonly type = '[Products] Fetch using Algolia';
  constructor(public payload: { pData: Paging, param: ProductSearchParam }) { }
}

// #endregion

@State<ProductStateModel>({
  name: 'product',
  defaults: initProductState()
})
@Injectable()
export class ProductState extends StateBase {

  allProdReqSub: Subscription;

  // allProductPagingContainer:
  /** Container that keep all of the subscription related to gettting allProducts. */
  allProductSubData: PagingContainer<ProductBase, ProductSearchParam> = new PagingContainer();

  // allProductSubData: { [key: string]: ISubscriptionNode<ProductBase, ProductSearchOption> } = {};

  constructor(private productService: ProductService, private algoliaSearchService: AlgoliaSearchService) { super(); }

  // #region Static Selectors
  @Selector()
  static selectAllProducts(state: ProductStateModel) {
    return (o: ProductSearchParam): ProductBase[] => {

      if (state.products == null || Object.keys(state.products).length === 0) {
        return [];
      }

      // remove keys that have revision/draft ids, i.e that contain '~' in the id.
      const keys = Object.keys(state.products).filter(k =>
        k.indexOf('/') === -1 && state.products[k] != null);

      // object without the rev/draft.
      const filtered = keys.reduce((obj, k) => {
        obj[k] = state.products[k];
        return obj;
      }, {});

      let output = Object.values(filtered).map(x => parseProduct(x));
      output = productSerachClientFilter(output, o);

      return output;
      // return entity.map(state.products, parseProduct);
    };
  }

  /** Is the Paging Full for this search. */
  @Selector()
  static selectAllProductsFull(state: ProductStateModel) {
    return (o: ProductSearchParam): boolean => {
      const oKey = getObjKey(o);
      const r = state.allProductsLoadStatus && state.allProductsLoadStatus[oKey];
      return (!!r) ? r.pData.full : true;
    };
  }

  @Selector()
  static selectProductById(state: ProductStateModel) {
    return entity.getByIdFn_new(state.products, parseProduct);
  }
  @Selector()
  static selectProductsByCompId(state: ProductStateModel) {
    return (cid: string | number): ProductBase[] => {
      return Object.values(state.products)
        .filter(p => p.cid === cid)
        .map(m => parseProduct(m));
    };
    // console.log('product  Select-STORE', state.products);
    // return entity.map(state.products, parseProduct);
  }

  @Selector()
  static selectProductSearchOptions(state: ProductStateModel) {
    return {
      ...state.options
    };
  }

  
  @Selector()
  static selectAllProductsUsingAlgolia(state: ProductStateModel) {
    return (o: ProductSearchParam): ProductBase[] => {
      if (state?.products == null || Object.keys(state.products).length === 0) {
        return [];
      }

      let output = Object.values(state.products).map(x => parseProduct(x));
      return output;
    };
  }

  @Selector()
  static selectTotalCount(state: ProductStateModel) {
    return state.totalCount;;
  }

  // #endregion

  // #region Custom Functions and subscriptions

  public clearSubscriptions() {
    if (!!this.allProdReqSub) {
      this.allProdReqSub.unsubscribe();
      this.allProdReqSub = null;
    }
    this.allProductSubData.unsubscribeAll();
    super.clearSubscriptions();
  }

  // #endregion

  // #region actions
  @Action(AllProductsRequested)
  allProductsRequested(context: StateContext<ProductStateModel>, action: AllProductsRequested) {

    const oKey = getObjKey(action.payload.option);

    // Define function that return the data status object from the state.
    const getDataStatusStateFn = () => context.getState().allProductsLoadStatus;

    /** custom build the OR children query. */
    const buildOrQueryChildrenFn = (o: ProductSearchParam) => getProdSearchOptOrChildren(o);

    // if data requested now, is already partially loaded by another query's child previously
    // but the number of the items are not enough (ex. as a child, it loaded only 5, but current
    // request ask for more, then next page of that query should be called instead.)
    const nextPageFn = (option: ProductSearchParam) => {
      context.dispatch(new AllProductNextPageRequested({ option }));
    };

    buildDataRequest(
      oKey, action.payload.option, action.payload.pData,
      getDataStatusStateFn,
      buildOrQueryChildrenFn,
      nextPageFn,
      (
        obj: { [key: string]: IDataLoadStatus<ProductSearchParam> },
        set: { key: string, node: IDataLoadStatus<ProductSearchParam> }[]
      ) => {

        if (!!obj) {
          // Patch the state.
          const state = context.getState();
          context.patchState({
            allProductsLoadStatus: { ...state.allProductsLoadStatus, ...obj }
          });
        }

        // Process the query.
        set.forEach((val) => {
          // some of the nodes are already loaded. Only process that are loading... status.
          if (val.node.loadStatus !== LoadStatus.Loading) {
            return;
          }
          // if this request is just overwriting a stall or pending request, unsubscribe that observable
          this.allProductSubData.unsubscribe(val.key);

          // create the paging observable and call db.
          const p = this.productService.getAllProducts1_PagingObservable();
          const prod$ = p.getData(action.payload.pData, val.node.param)
            .pipe(
              map(products => {
                context.dispatch(new AllProductsLoaded({
                  data: products as any,
                  key: val.key
                }));
                return products;
              }));
          const sub = this.subscribe(prod$, () => noop(), AllProductsRequested.type);
          // save the observable call
          this.allProductSubData.addData(val.key, sub, p);
        });
      }
    );
  }

  // @Action(AllProductsRequested)
  // allProductsRequested(context: StateContext<ProductStateModel>, action: AllProductsRequested) {

  //   const oKey = getObjKey(action.payload.option);

  //   // Define function that return the data status object from the state.
  //   const getDataStatusStateFn = () => context.getState().allProductsLoadedData;

  //   /** custom build the OR children query. */
  //   const buildOrQueryChildrenFn = (o: ProductSearchOption) => getProdSearchOptOrChildren(o);

  //   // if data requested now, is already partially loaded by another query's child previously
  //   // but the number of the items are not enough (ex. as a child, it loaded only 5, but current
  //   // request ask for more, then next page of that query should be called instead.)
  //   const nextPageFn = (option: ProductSearchOption) => {
  //     context.dispatch(new AllProductNextPageRequested({ option }));
  //   };

  //   this.buildDataRequestAlt(
  //     oKey, action.payload.option, action.payload.pData,
  //     getDataStatusStateFn,
  //     buildOrQueryChildrenFn,
  //     nextPageFn,
  //     (
  //       obj: { [key: string]: IDataStatus<ProductSearchOption> },
  //       set: { key: string, node: IDataStatus<ProductSearchOption> }[]
  //     ) => {

  //       // Patch the state.
  //       const state = context.getState();
  //       context.patchState({
  //         allProductsLoadedData: { ...state.allProductsLoadedData, ...obj }
  //       });

  //       // Process the query.
  //       set.forEach((val) => {
  //         // some of the nodes are already loaded. Only process that are loading... status.
  //         if (val.node.loadStatus !== LoadStatus.Loading) {
  //           return;
  //         }
  //         // if this request is just overwriting a stall or pending request, unsubscribe that observable
  //         this.allProductSubData.unsubscribe(val.key);

  //         // create the paging observable and call db.
  //         const p = this.productService.getAllProducts1_PagingObservable();
  //         const prod$ = p.getData(action.payload.pData, val.node.param)
  //           .pipe(
  //             map(products => {
  //               context.dispatch(new AllProductsLoaded({
  //                 data: products,
  //                 key: val.key
  //               }));
  //               return products;
  //             }));
  //         const sub = this.subscribe(prod$, (x) => noop(), AllProductsRequested.type);
  //         // save the observable call
  //         this.allProductSubData.addData(val.key, sub, p);
  //       });
  //     }
  //   );
  // }



  @Action(AllProductNextPageRequested)
  allProductNextPageRequested(context: StateContext<ProductStateModel>, action: AllProductNextPageRequested) {
    const oKey = getObjKey(action.payload.option);
    const state = context.getState();
    // find the node. can be parent or child
    const statusObj = state.allProductsLoadStatus[oKey];  // getNode(state.allProductsLoadedData, oKey);

    // if no parent, treat is
    if (statusObj.children == null) {
      this.allProductSubData.dispatchNextPagingUpdate(oKey);
    } else {
      const children = getRootLevelChildren(oKey, state.allProductsLoadStatus);
      children.forEach(c => {
        this.allProductSubData.dispatchNextPagingUpdate(c.key);
      });
    }
  }

  @Action(AllProductsLoaded)
  allProductsloaded(context: StateContext<ProductStateModel>, action: AllProductsLoaded) {
    const state = context.getState();
    const subData = this.allProductSubData.getData(action.payload.key);
    const updatedLoadStatus = updatePaging(action.payload.key, state.allProductsLoadStatus, subData);
    context.patchState({
      allProductsLoaded: true,
      allProductsLoadStatus: updatedLoadStatus,
      products: { ...state.products, ...action.payload.data }// entity.addMany(state.products, action.payload.products)
    });
  }

  @Action(ProductRequested)
  prodcutRequested(context: StateContext<ProductStateModel>, action: ProductRequested) {
    const state = context.getState();
    if (Object.keys(state.products).indexOf(`${action.payload.id}`) === -1) {
      // console.log('requested Rental-Product is not in the store. sending request to server', action.payload.id);
      this.productService.getProductById(action.payload.id)
        .pipe(
          map(data => {
            console.log('from server: retal map was called', data);
            return context.dispatch(new ProductLoaded({ data }));
          }
          )).subscribe(noop);
    } else { console.log('requested Rental-Product is available in the store'); }

  }

  @Action(ProductLoaded)
  productLoaded(context: StateContext<ProductStateModel>, action: ProductLoaded) {
    const state = context.getState();
    const p = {};
    p[action.payload.data?.id] = action.payload.data;
    context.patchState({
      products: { ...state.products, ...p }
    });
  }

  @Action(ProductStateReset)
  productStateReset(context: StateContext<ProductStateModel>) {
    // unsubscribe the data
    console.log('reset action called');
    this.clearSubscriptions();
    context.setState(initProductState());
  }

  @Action(ProductOptionUpdated)
  productOptionUpdated(context: StateContext<ProductStateModel>, action: ProductOptionUpdated) {
    context.patchState({
      options: { ...action.payload.option }
    });
  }

  @Action(ProductRemoveById)
  dataByIdRemoved(context: StateContext<ProductStateModel>, action: ProductRemoveById) {
      const state = context.getState();
      if (Object.keys(state.products).indexOf(`${action.payload.pid}`) > -1) {
        const currState = context.getState();
        const newData = { ...currState.products };
        delete newData[action.payload.pid];
        context.patchState({ products: newData });
        const state1 = context.getState();
        logger.log('[Product-State], item removed by id', action.payload.pid, state1);

      } else { logger.log('[Product-State], item to be removed id is not available in the store'); }
  }
  // @Action(ProductsRequestedByCompId)
  // rentalProductsRequestedByCompId(context: StateContext<ProductStateModel>, action: ProductsRequestedByCompId) {
  //   if (!context.getState().productsByCompIdLoaded || !context.getState().allProductsLoaded) {
  //     this.productService.getProductsByCompId(action.payload.cid)
  //       .pipe(
  //         map(products => {
  //           // console.log('map was called');
  //           return context.dispatch(new ProductsByCompIdLoaded({ products }));
  //         }
  //         )).subscribe(noop);
  //   }
  // }
  // @Action(ProductsByCompIdLoaded)
  // rentalProductsByCompLoaded(context: StateContext<ProductStateModel>, action: ProductsByCompIdLoaded) {
  //   console.log('rent option loaded ', action.payload.products);
  //   const state = context.getState();
  //   context.patchState({
  //     productsByCompIdLoaded: true,
  //     products: entity.addMany(state.products, action.payload.products)
  //   });
  // }

  @Action(FetchProductsUsingAlgolia)
  fetchProductUsingAlgolia(context: StateContext<ProductStateModel>, action: FetchProductsUsingAlgolia) {
    const { query, indexName } = ProductSearchAlgolia(action.payload.param, action.payload.pData);
    const rAlgolia = this.algoliaSearchService.getDataUsingAlgolia(query, indexName);
    rAlgolia.subscribe(rProducts => {
      const data = {};
      rProducts.hits.forEach(hit => {
        hit.id = hit.objectID; 
        hit = updateNestedDateValueToFirebaseTimeStamp(hit,ProductDates);//modified dates to firestore timestamp
        data[hit.objectID] = hit;
      });
      context.patchState({
        allProductsLoaded: true,
        products: data,
        totalCount: rProducts.nbHits
      });
    });
  }

  // #endregion
}
