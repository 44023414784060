import { Component, OnInit, OnDestroy, Output, EventEmitter, Inject, ViewChild } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { rentOptionParamInit, RentOptionParam, rentOptionSearchOrderBy } from '@trent/models/rental/rent-option-param';
import { SetSettings, AuthState } from '@trent/store/auth-store/auth.state';
import { ProductType } from '@trent/models/product';
import { UserSetting } from '@trent/models/setting/user-setting';
import { DeepCopy } from '@trent/models/utility/deep-copy';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Address } from '@trent/models/address/address';
import { cloneDeep } from 'lodash';
import { googlePlaceToAddress } from '@trent/models/address/address-helper';
import { logger } from '@trent/models/log/logger';
import { RentalSearchComponent } from '../../shared/search-rental/rental-search.component';

@Component({
  selector: 'trent-rent-option-search',
  templateUrl: './rent-option-search.component.html',
  styleUrls: ['./rent-option-search.component.scss']
})
export class RentOptionSearchComponent implements OnInit, OnDestroy {

  pType = ProductType;

  rentOptionSearchOrderBy = rentOptionSearchOrderBy;

  option$: Observable<RentOptionParam>;

  

  @ViewChild('rentSearchCtrl', { static: false })
  rentSearchCtrl: RentalSearchComponent;


  optionSub: Subscription;
  setting: UserSetting;
  /** cancel submission for approval request. */
  @Output() updatedEvent: EventEmitter<any> = new EventEmitter();
  eo: RentOptionParam;
  searchTxt: string;
  lat: number;
  long: number;
  city: string;
  refeerDryVan: 'reefer' | 'dry-van' | 'all';
  refeerDryVan1: any;
  nAxle: 2 | 3 | 4 | '*';
  param: RentOptionParam;

  constructor(public store: Store, public dialogRef: MatDialogRef<any, any>,
    @Inject(MAT_DIALOG_DATA) public data: {
      cid: string, param: RentOptionParam
    }) { }

  reset() {
    if (!!this.setting) {
      if (this.setting.rentOptionParam == null) {
        this.setting.rentOptionParam = rentOptionParamInit();
      }
      // console.log('got updated option copy in option component.');
      // this.eo = DeepCopy.copy(this.setting.rentOptionParam);
      console.log('eo after reset', this.eo);
    }
  }

  ngOnInit() {
    // this.optionSub = this.store.select(AuthState.userSettings)
    //   .subscribe(s => {
    //     if (!!s) {
    //       this.setting = s;
    //       // this.reset();
    //     }
    //   });
    // this.eo = cloneDeep(this.data.param);
    // if (!this.eo.startAddress) { this.eo.startAddress = new Address(); }
    // this.setReeferDryVanFilter();
    // this.setnAxleFilter();
    logger.info('[rent-option-search.comp] dialog data ', this.data);
    this.param = this.data.param;
  }
  ngOnDestroy(): void {
    if (!!this.optionSub) {
      this.optionSub.unsubscribe();
    }
  }
  updateOptions() {
    this.rentSearchCtrl.searchParmEmit();
  }
  onPlaceLoaded(place: any) {  // google.maps.places.PlaceResult
    if (place === undefined || place === null) {
      return;
    }
    this.searchTxt = place.formatted_address;
    // // this.m = []; // this resets the display
    // console.log('recieved address data from Google: ', place.address_components);
    // console.log('check: ', place.address_components[0].types[0]);
    // console.log(place.geometry.location.lng());
    // console.log(place.geometry.location.lat());
    // this.eo.startLat = place.geometry.location.lat();
    // this.eo.startLong = place.geometry.location.lng();
    // // this.eo.startaddress = place.formatted_address;
    // this.eo.country = place.address_components[3].short_name;
    // this.city = place.address_components[0].long_name;
    // console.log(this.eo);
    this.eo.startAddress = googlePlaceToAddress(place);
    this.eo.center = this.eo.startAddress.geoLoc.geopoint;
  }

  remove() {
    this.eo.startAddress = new Address();
  }
  reeferDrySelect() {
    switch (this.refeerDryVan) {
      case 'reefer':
        this.eo.isReefer = true;
        break;
      case 'dry-van':
        this.eo.isReefer = false;
        break;
      case 'all':
        this.eo.isReefer = null;
        break;
      default:
        break;
    }
  }
  setReeferDryVanFilter() {
    if (this.data.param.isReefer === true) {
      this.refeerDryVan = 'reefer';
    } else if (this.data.param.isReefer === false) {
      this.refeerDryVan = 'reefer';
    } else {
      this.refeerDryVan = 'all';
    }
  }
  nAxleSelect() {
    switch (this.nAxle) {
      case '*':
        this.eo.nAxle = undefined;
        break;
      default:
        this.eo.nAxle = this.nAxle;
        break;
    }
  }
  setnAxleFilter() {
    if (!this.data.param.nAxle) {
      this.nAxle = '*';
    } else {
      this.nAxle = this.data.param.nAxle as any;
    }
  }
  searchRental(param: RentOptionParam) {
    this.dialogRef.close(param);

  }
}
