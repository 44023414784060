import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PolicyComponent } from './policy.component';
import { MaterialModule } from '../share-module/material.module';
import { CkEditorModule } from '../ck-editor/ck-editor.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { PolicyListComponent } from './policy-list/policy-list.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';


@NgModule({
  declarations: [PolicyComponent, PolicyListComponent],
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    CkEditorModule,
    MatCheckboxModule,
    MatDialogModule,
    MatExpansionModule,
    // RootStoreModule
  ]
})
export class PolicyModalModule { }
