import { PageHtml } from '../models/cms/page-html';
import { Injectable, inject } from '@angular/core';
import { Title, Meta, TransferState, makeStateKey, StateKey } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { BaseHttpService } from '@trent/services/base-http.service';
import { FirestoreService } from '@trent/services/firestore.service';
import { HttpClient } from '@angular/common/http';
import { Observable, map, of } from 'rxjs';
import { UtilityService } from './utility.service';
import { CmsPageRequested, CmsState } from '@trent/store/cms-store';
import { PageMetaTag } from '@trent/models/cms/page-meta-tag';
import { isArray } from 'lodash';
import { logger } from '@trent/models/log/logger';

/** SEO Class, Only used in server side rendering. */
@Injectable({
  providedIn: 'root'
})
export class SeoService {

  us: UtilityService = inject(UtilityService);

  constructor(
    private store: Store,
    private db: FirestoreService,
    private title: Title,
    private meta: Meta,
    private router: Router,
    private state: TransferState) {
  }

  /**
   * https://www.concretepage.com/angular/angular-meta-service-for-meta-tags
   * Inject the meta tags for SEO. Only works when the given page is loaded from the server SSR
   * @param m PageHtml or its extended class that contain the meta data.
   * @param dynamicMetaTags Additional dynamic tags generated by the pages. Example: on a truck sale list page, 
   * there may be 20 truck models in a given location, that data can be dynamically injected in to the meta tags.
   * Or, there may be a detail page of a given truck, that truck's model or other characteristics can be injected
   * in to the page.
   */
  generateTags(m: PageHtml, dynamicMetaTags = []) {
    // tags to be rendered only at the server
    if (!this.us.isPlatformBrowser) {
      console.log('[SSR] Page tags were rendered at the server');
      this.title.setTitle('[SSR] ' + m.title);
      const tags = dynamicMetaTags; //  PageMetaTag[] = (typeof dynamicMetaTagsFn === 'function') ? dynamicMetaTagsFn(m) : [];
      logger.log('@@@', tags);
      // Push standard tags from page properties
      // tags.push({ name: 'description', content: m.description });
      // tags.push({ name: 'og:url', content: this.us.getFullUrl(this.router.url) }); // `https://locusloop.com${this.router.url}` },
      // tags.push({ name: 'og:title', content: m.title });
      // tags.push({ name: 'og:description', content: m.description });
      
      // Additional tags defined in the database.
      if(isArray(m.metaTags)) {
        for (const t of m.metaTags) {
          tags.push(t);
        }
      }
      // { name: 'twitter:card', content: 'summary' },
      // { name: 'twitter:site', content: '@locusloop' }
      this.meta.addTags(tags, true);
      // for (const tag of m.metaTags) {
      //   logger.warn('Meta tag insertion code is TBD', tag);
      // }
    }
  }

  initMetaTags(obj: any, type: string) {
    const tag: PageMetaTag[] = (type === 'small') ? [
      { name: 'keywords', content: '?' },
      { name: 'robots', content: 'INDEX, FOLLOW' }
    ] : [
      { name: "author", content: "Your Business Name" },
      { name: "robots", content: "index, follow" },
      { name: "revisit-after", content: "7 days" },
      { name: "geo.region", content: "CA-ON" },
      { name: "geo.placename", content: "Toronto" },
      { name: "geo.position", content: "43.653225,-79.383186" },
      { name: "ICBM", content: "43.653225, -79.383186" },
      { name: "og:title", content: "Truck Rental and Sales in Toronto | Your Business Name" },
      // { name: "og:description", content: "Your Business Name offers reliable truck rental and sales services in Toronto." },
      { name: "og:type", content: "website" },
      { name: "og:url", content: "https://www.yourwebsite.com" },
      { name: "og:image", content: "https://www.yourwebsite.com/images/your-business-logo.png" },
      { name: "twitter:card", content: "summary_large_image" },
      { name: "twitter:title", content: "Truck Rental and Sales in Toronto | Your Business Name" },
      { name: "twitter:description", content: "Your Business Name offers reliable truck rental and sales services in Toronto." },
      { name: "twitter:image", content: "https://www.yourwebsite.com/images/your-business-logo.png" },
    ];
    obj.val = tag;
  }


  /**
   * Get Data from database instead of the store. This is meant for fetching pageHtml data
   * to perform the SEO.
   * @param colName collection name e.g. 'page-html'
   * @param id Id of the record to be fetched.
   * @param idField field name that will be assigned the ID. for 99% of the cases, it is 'id' itself.
   * @returns Observable data from the database or the stateKey.
   */
  loadPageHtml<T extends PageHtml>(id: string, getDynamicMetaTagsFn?: (p: PageHtml) => PageMetaTag[], clazz?: new () => T, colName = PageHtml.collectionName, idField = 'id') {
    let r: Observable<T>;
    const key: StateKey<T> = makeStateKey(`${id}`);
    let data: T = this.state.get<T>(key, null!);

    if (!!data) {
      r = of(data);
    } else {
      if (this.us.isPlatformServer) {
        r = this.db.docWithInjectedId$<T>(`${colName}/${id}`, idField);
      } else {
        this.store.dispatch(new CmsPageRequested({ id: id, useCache: false }));
        r = this.store.select(CmsState.selectPageById)
          .pipe(map(pageFn => pageFn(id) as T));
      }
    }

    r = r.pipe(map(x => {
      if (!!x) {
        return PageHtml.parse(x, clazz);
      }
      return x;
    }));

    if (this.us.isPlatformServer) {
      return r.pipe(map(x => {
        // Just intercept and generate tags for server.
        if (!!x) {
          this.generateTags(x, typeof getDynamicMetaTagsFn === 'function' ? getDynamicMetaTagsFn(x) : []);
        }
        return x;
      }));
    }
    return r;
  }
}