import { Exclude, Expose } from "class-transformer";
import { PageHtml, PageHtmlSection } from "./page-html";
import { globalToInstance, sectionDef } from "./page-html-decorator";

/** Debug Only for now. the properties are here for demonstration purpose only */
@Exclude()
export class TprHomePageHtml extends PageHtml {
  @Expose()
  @sectionDef({ type: 'date', order: 3})
  someDate: Date;

  @Expose()
  @sectionDef({ type: 'date', order: 4})
  otherDate: Date;

  @Expose()
  @sectionDef({ type: 'html', order: 5})
  otherHtml: string;


  @Expose()
  @sectionDef({ type: 'date', order: 5})
  otherHtml1: string;
  
  
  /** auto section will keep the track of all custom properties that have @sectionDef attribute applied to them.
   * This is used for auto generating the html control in the cms page. This value will NOT be saved in the database. */
  autoSection: Record<string, PageHtmlSection> = {};
  /**
   *
   */
  
  constructor() {
    super();
    /** Copy global property structure to local in the @autoSection */
    globalToInstance(TprHomePageHtml, this);
  }

}