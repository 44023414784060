import { Injectable } from '@angular/core';
import { FirestoreService } from '@trent/services/firestore.service';
import { tap } from 'rxjs/operators';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { BaseHttpService } from '@trent/services/base-http.service';
import { Store } from '@ngxs/store';
import { logger } from '@trent/models/log/logger';

@Injectable()
export class UrlShortenService extends BaseHttpService {

  constructor(store: Store, private db: FirestoreService, private http: HttpClient) {
    super(store);
    this.apiName = 'gps';  //PT - its name is changed from 'api' to 'gps' because we wanted to access this as a loggedin or without loggedin user
  }

  /** PT
   * @purpose - create shorten url
   * @param - payload - url and rId
   * @returns 
   */

  createShortenUrl(long_url: string, rId: string | number) {
    // creating payload
    const payload = {
        long_url,
        rId
    };
    // creating headers
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + this.token });

   // hitting the create short url api
   return this.http.post<any>(this.getApiUrl('/shorten/create-short-url'),
      {
        ...payload
      },
      { headers: headers })
      .pipe(
        tap(r => logger.log('response: ', r)),
      );
 }
}