import { IsNumber, IsEnum } from 'class-validator';


export enum PaymentType {
  firstPayment = 1,
  recurring = 2,
  deposit = 3,
  creditCheckFee = 4,
}

export class CreditCardPayment {
  constructor() {
    this.currency = 'CAD';
  }

  @IsNumber()
  paymentValue: number;

  currency: 'CAD' | 'USD';

  @IsEnum(PaymentType)
  paymentType: PaymentType;

  refId: string | number; // bidId: firstPayment, recurring, deposit, creditCheckFee may be different for other use cases

  isCapture?: boolean;

  authorizationDate?: Date;

  validUntil?: Date;

  captureDate?: Date;

  releaseDate?: Date;

}


