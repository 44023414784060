import { AlertBarData } from './../../models/UI/menu-data';
import { EventService } from '@trent/services/event.service';
import { Injectable } from '@angular/core';
import { AuthCoreService } from './auth-core.service';
import { IUserClaim } from '@trent/models/user/user-claim';
import { Subscription } from 'rxjs';
import { Store } from '@ngxs/store';
import { Logout1, SetToken, SetClaim, ProfileFromDbRequested } from '@trent/store/auth-store';
import { take } from 'rxjs/operators';
import { DeepCopy } from '@trent/models/utility/deep-copy';

// import 'firebase/database';
import { MessageInfo } from './../../models/error-handling/message-info';
import { Router } from '@angular/router';
import { logger } from '@trentm/log/logger';
import { Auth, User, authState, idToken } from '@angular/fire/auth';
import { FirebaseRTDBService } from '../firebase-rtdb.service';

/** Full Auth Class, This is lazy loaded */
@Injectable()
export class AuthService {

  /** certain urls do not need to show the top alert bar. */
  private alertBarIgnoreUrl = [
    'userRef=driver-sign',
    '/policy-update',
    'carrier-detail'
  ];

  private currToken = '';
  private currClaim: IUserClaim = null;

  private tokenSub: Subscription;
  private lastUser: User; // uid that is binded to token. Used for checking duplicating subs.

  userSubscription: Subscription;
  userAccessSubscription: Subscription;
  // metadataRef: firebase.database.Reference = null;

  msgIds: { [id: string]: string } = {};

  metadataRefCallback: (snapshot: any) => Promise<void>; // (snapshot): any => void;

  constructor(
    public store: Store,
    private authCore: AuthCoreService,
    public auth: Auth,
    private db: FirebaseRTDBService,
    private router: Router,
    private es: EventService) { }

  // #region Initialize Linkage to sync token / claim and user access


  rebindTokenRefresh(u: User) {
    /** avoid duplicate subscriptions. */
    if (!!u && !!this.lastUser && u.uid === this.lastUser.uid) {
      return;
    }

    this.lastUser = u;

    /** unsubscribe existing */
    if (!!this.tokenSub) {
      this.tokenSub.unsubscribe();
      this.tokenSub = null;
    }
    /** Re-subscribe to token refresh from realtime database.*/
    if (!!u) {
      this.tokenSub = this.db.obj$('metadata/' + u.uid + '/refreshTime')
        .subscribe((y) => {
          // Force refresh to pick up the latest custom claims changes.
          try {
            // get new token, with forced refresh.
            logger.log('[Auth Service] Token Refresh required. ....requesting ');
            this.updateUserState(this.lastUser);
          } catch (error) {
            logger.error('ID Toke result from Firebase authentication metadata callback was not properly coded. ERROR: ', error);
            this.store.dispatch(new SetToken({ token: '' }));
            this.store.dispatch(new SetClaim(null));
          }
        }, (err) => {
          logger.error('[auth service] Token Refresh subscription failed! Token may not update', err);
        });
    }
  }

  /** Set up token / roles and user auth data synchronization to ensure user key
   * information is always in the sync.
   */
  initUserAuthSync() {
    // // Set up Token synchronization.
    idToken(this.auth).subscribe(t => {
      logger.log('auth Service: idToken 1: token updated. (idToken subs): ', new Date());
      this.updateTokenState(t);
    });

    // get refresh token as well, i.e if claim change, that should notify the app
    authState(this.auth)
    this.userSubscription = authState(this.auth).subscribe(async user => {

      this.rebindTokenRefresh(user);

      // unsubscribe old user as uid is changed or user has logged out.
      if (this.userAccessSubscription != null) {
        this.userAccessSubscription.unsubscribe();
      }

      if (user == null) {
        this.store.dispatch(new Logout1());
        return;
      }

      this.updateUserState(user);

      /** OLD CODE used to subscribe to token refresh. Replaced by Rxjs rebindTokenRefresh */

      // if (this.metadataRef != null && this.metadataRefCallback != null) {
      //   this.metadataRef.off('value', this.metadataRefCallback);
      //   this.metadataRefCallback = null;
      // }
      // this.metadataRef = firebase.database().ref('metadata/' + user.uid + '/refreshTime');
      // this.metadataRefCallback = async () => {
      //   // Force refresh to pick up the latest custom claims changes.
      //   try {
      //     // get new token, with forced refresh.
      //     logger.log('Meta Ref call back', new Date());
      //     this.updateUserState(user);
      //   } catch (error) {
      //     logger.error('ID Toke result from Firebase authentication metadata callback was not properly coded. ERROR: ', error);
      //     this.store.dispatch(new SetToken({ token: '' }));
      //     this.store.dispatch(new SetClaim(null));
      //   }
      // };
      // this.metadataRef.on('value', snapshot => this.metadataRefCallback(snapshot));
    });
  }
  private async updateUserState(user: User) {
    logger.log('update UserState called', user);
    const token = await user.getIdToken(true);
    this.updateTokenState(token, user);
  }

  private async updateTokenState(token: string, user?: User) {
    if (token === this.currToken && this.authCore.user != null) {
      // Token is same as what is in the store. Just skip
      logger.log('Skipping Token update! duplicate call');
      return;
    }
    try {
      this.currToken = token;
      this.store.dispatch(new SetToken({ token }));

      // logger.log('curr Token: ', this.currToken);
      // logger.log('new Token: ', token);

      const tokenResult = user == null ?  this.authCore.token$.pipe(take(1)).toPromise() : user.getIdTokenResult();

      // Handle Token Result as well.
      const r = await tokenResult;
      // logger.log('auth Service idToken 2: new token result received. Trying to update claim', new Date());
      const c = r == null || (r as any).claims == null ? null : (r as any).claims;
      const newClaim = c as IUserClaim;
      if (DeepCopy.areEqual(newClaim, this.currClaim)) {
        // logger.log('Skipping Claim Update! Duplicate call');
        return;
      }

      this.handleClaimAlertBar(newClaim);

      this.currClaim = newClaim;
      this.store.dispatch(new SetClaim(newClaim));
      // Also if claim was not complete, dispatch user profile load
      if (!!newClaim && !!newClaim.cClaim && !newClaim.cClaim.full) {
        // logger.log('auth Service idToken 3: Claim is not comple, dispatching the user profile data from db.', newClaim);
        this.store.dispatch(new ProfileFromDbRequested({ uid: newClaim.user_id }));
      }
    } catch (error) {
      logger.error('ID Toke result from Firebase authentication was not properly coded. ERROR: ', error);
      this.store.dispatch(new SetToken({ token: '' }));
      this.store.dispatch(new SetClaim(null));
    }
  }

  /** Show Hide the alert bar once claim is changed. */
  handleClaimAlertBar(newClaim: IUserClaim) {

    /** Do not show the alert for for ignore list. */
    for (const url of this.alertBarIgnoreUrl) {
      if (this.router.url.includes(url)) {
        return;
      }
    }

    const claimMsgId = 'claimMsgId';
    const isAlertDisplayed = !!this.msgIds[claimMsgId];
    const isAlertReqd = !!newClaim && !newClaim.email_verified && newClaim.firebase.sign_in_provider !== 'facebook.com' && newClaim.firebase.sign_in_provider !== 'microsoft.com';

    if (isAlertDisplayed && isAlertReqd) {
      logger.log('[Claim Changed] Displayed and is required');
      return;
    }

    if (!isAlertReqd && isAlertDisplayed) {
      logger.log('[Claim Changed] Displayed and !required');
      this.hideAlertBar(claimMsgId);
      return;
    }

    if (isAlertReqd && !isAlertDisplayed) {
      logger.log('[Claim Changed] !Displayed and required');
      this.es.emit<AlertBarData>(this.es.showAlertBar, {
        msg: new MessageInfo({
          msgCss: 'warning',
          header: 'Email Verification',
          description: 'Please verify your email address!'
        }),
        show: true,
        showClose: true,
        moreLinkUrl: '/account?emailverify=1',
        showCallBackFn: (id) => {
          logger.log('[Claim Changed] Show Completed');
          this.msgIds[claimMsgId] = id;
        },
        closeClickFn: (id) => {
          logger.log('[Claim Changed] Close clicked on Alert bar');
          delete this.msgIds[id];
        }
      });
    }
    //   logger.log('[Claim Changed] Show started');
    //   // setTimeout(() => {
    //   /** Handle Logout */
    //   if ({
    //     if(newClaim == null || newClaim.email_verified) {
    //     return;
    //   }
    // }
    // /** handle claim changes. */
    // if(!newClaim.email_verified) {

    // }
    // }, 500);
  }

  hideAlertBar(id) {
    logger.log('[Claim Changed] Close Alert bar called');
    this.es.emit<string>(this.es.hideAlertBar, this.msgIds[id]);
    delete this.msgIds[id];
  }

}
