import { VendorCompanySummary } from '../company/company-fleet';
import { BaseModel, DbRule } from '../base';
import { Exclude, plainToInstance, instanceToInstance, Expose, Type, plainToClass, instanceToPlain } from 'class-transformer';
import { IsEnum, validateSync, IsDefined, ValidateNested, ValidateIf } from 'class-validator';
import { ProductType } from '../product/product-type';
import { IValidationMsg } from '../error-handling/validation-info';
import { sanitizeDateIPoint, toSentence } from '../utility';
import { CustomerCompanySummary } from '../company/company-fleet';
import { ProductSummary } from '../product/product-helper';
import { RoleCompanyAuthRank } from '../user/role-company';
import { BidBase, BidSummary } from '../bid/bid-base';
import { RentOptionSummary } from '../rental/rent-option-summary';
import { Picture } from './picture';
import { InspectionData, InspectionResponse } from './inspection-data';
import { addHours, isAfter, startOfDay } from 'date-fns';
import { CronActions, UpdateDocRef } from '../cron-jobs/cron-jobs-log';
import { UserEnum } from '../user/user-enum';
// import { webSiteUrl } from '@trent/core/environments/fb-env';
import { InspectionStatus } from './inspection-status';
import { logger } from '../log/logger';
import { IInspectionDelegate } from './i-inspection-payload';
import { IFileMoveData, FileInfo } from '../media/file-info';
import { InspectionDelegate } from './inspection-delegate';
import { Task } from '../sys/task';
import { GCTaskType, TaskType } from '../sys/task-type';
import { EnumHelper } from '../utility/enum-helper';
import { TaskUtl } from '../sys/task-utl';
import { companyFriendlyName } from '../company/company-helper';
import { isNil, merge } from 'lodash';
import { InspectionDataTruck } from './inspection-data-truck';
import { ProductBase } from '../product/product-base';
import { Truck } from '../product/truck';
import { InspectionDataTrailer } from './inspection-data-trailer';
import { Trailer } from '../product/trailer';
import { parseInspectionData } from './inspection-helper';
import { Vehicle } from '../product/vehicle';
import { UserProfile } from '../user/user-profile';

// export enum PickDropStatus {

//   intitial = 0,

//   vendorDropped = 1,

//   customerPicked = 2,

//   rentStartHandShake = 3,

//   customerDropped = 4,

//   vendorPicked = 5,

//   finalHandShake = 6
// }
/** Inspection Type not stored in DB used for UI */
// export enum InspectionType {
//   vendorDrop = 1,
//   customerPick = 2,
//   customerDrop = 3,
//   vendorPick = 4,

// }
export enum InspectionDecision {
  /** Approve the inspection completed by other party */
  approve = 1,
  /** Reject the inspection completed by other party which delete the inspection completed */
  reject = 2,
  /** reset the inspection original state; Before @initial reset to Initial; After @rentStartHandShake reset to @rentStartHandShake */
  reset = 3,
}


/** Pick-up / Drop-off inspection data model for the bids.
 * This object should be created when bid contract is finalized and hand shake is done. */
@Exclude()
export class Inspection extends BaseModel {





  public static readonly collectionName = 'inspection';

  @Expose()
  @ValidateIf(o => o.status > InspectionStatus.rentStartHandShake)
  unlockReturn: boolean;

  @Expose()
  @IsDefined()
  customerCompanySummary: CustomerCompanySummary;

  @Expose()
  @IsDefined()
  vendorCompanySummary: VendorCompanySummary;

  @Expose()
  @IsDefined()
  productSummary: ProductSummary;

  @Expose()
  @IsDefined()
  rentSummary: RentOptionSummary;

  @Expose()
  @IsEnum(InspectionStatus)
  status = InspectionStatus.initial;


  // tslint:disable-next-line:max-line-length
  // @Type((f) => { if (f.newObject.ProductSummary.productType === ProductType.truck) { return InspectionDataTruck; } else { return InspectionDataTrailer; } })
  // @Type(() => InspectionData)
  @ValidateNested()
  @Type(() => InspectionData, {
    discriminator: {
      property: 'vehType',
      subTypes: [
        { value: InspectionDataTruck, name: 'truck' },
        { value: InspectionDataTrailer, name: 'trailer' },
      ],
    },
  })
  @Expose()
  vendorDrop: InspectionData;

  // @Type(() => InspectionData)
  @ValidateNested()
  @Type(() => InspectionData, {
    discriminator: {
      property: 'vehType',
      subTypes: [
        { value: InspectionDataTruck, name: 'truck' },
        { value: InspectionDataTrailer, name: 'trailer' },
      ],
    },
  })
  @Expose()
  customerPick: InspectionData;

  // @Type(() => InspectionData)
  @ValidateNested()
  @ValidateNested()
  @Type(() => InspectionData, {
    discriminator: {
      property: 'vehType',
      subTypes: [
        { value: InspectionDataTruck, name: 'truck' },
        { value: InspectionDataTrailer, name: 'trailer' },
      ],
    },
  })
  @Expose()
  customerDrop: InspectionData;

  // @Type(() => InspectionData)
  @ValidateNested()
  @Type(() => InspectionData, {
    discriminator: {
      property: 'vehType',
      subTypes: [
        { value: InspectionDataTruck, name: 'truck' },
        { value: InspectionDataTrailer, name: 'trailer' },
      ],
    },
  })
  @Expose()
  vendorPick: InspectionData;

  @Expose()
  customerPickDelegate: InspectionDelegate; // { ph: string, delegateBy: string | number };
  @Expose()
  vendorDropDelegate: InspectionDelegate;

  @Expose()
  customerDropDelegate: InspectionDelegate;
  @Expose()
  vendorPickDelegate: InspectionDelegate;

  @Expose()
  revAtRentalHandShake: number;

  // @Expose()
  // timeAtRentalHandShake: Date;

  @Expose()
  bidSummary: BidSummary;

  @Expose()
  expiry: Date;

  /** not stored in DB, call sent as API payload */
  pdiDecision: InspectionDecision;

  get isExpired() {
    return false;
    return isAfter(new Date(), this.expiry);
  }

  currentInspectionReport: InspectionData; // propery for pick drop history. not save in db.

  displayString: string;

  /** UI helper */
  // tslint:disable-next-line:max-line-length
  inspectionStatusInfo: { pdiInfo: string; pdiMissing: boolean; returnInfo: string; returnMissing: boolean; isPdiApprovalPending?: boolean };

  /** UI helper to compare pictures */
  picturesAtStart: Picture[] = [];
  /** UI helper to compare videos */
  videosAtStart: FileInfo[];

  get isReturnDisabled() {
    // manully turn on return inspection ass && false
    if (!!this.unlockReturn) {
      return false;
    }
    if (this.status < InspectionStatus.rentStartHandShake || isAfter(this.bidSummary.endDate, new Date())) {
      return true;
    }
    return false;
  }

  get displayStatus(): string {
    let r: string;
    switch (this.status) {
      case InspectionStatus.initial:
        r = 'PDI Pending';
        break;
      case InspectionStatus.customerPicked:
        r = 'Vendor Delivery Inspection Pending';
        break;
      case InspectionStatus.vendorDropped:
        r = 'Customer Pickup Inspection Pending';
        break;
      case InspectionStatus.rentStartHandShake:
        r = 'PDI Complete';
        break;
      case InspectionStatus.customerDropped:
        r = 'Customer Drop-off Inspection Pending';
        break;
      case InspectionStatus.vendorPicked:
        r = 'Vendor Receiving Inspection Pending';
        break;
      case InspectionStatus.finalHandShake:
        r = 'Return Inspection Complete';
        break;
      case InspectionStatus.customerDisputed:
        r = 'Return Inspection Complete, customer disputed';
        break;
      default:
        break;
    }
    return r;
  }


  // bidId: string;
  constructor() {
    super();
  }

  public static parse(obj) {
    if (obj == null) { return null; }

    if (obj instanceof (Inspection)) { return <Inspection>obj; }

    const m = plainToInstance<Inspection, any>(Inspection, sanitizeDateIPoint(obj));
    // m.vendorDrop = parseInspectionData(m.vendorDrop, m.productSummary.productType);
    // m.customerPick = parseInspectionData(m.customerPick, m.productSummary.productType);
    // m.customerDrop = parseInspectionData(m.customerDrop, m.productSummary.productType);
    // m.vendorPick = parseInspectionData(m.vendorPick, m.productSummary.productType);
    m.sanitize();
    m.setInspectionTypes();
    return m;
  }
  public static getNextNotifyAt(currentN: Date, lastN: Date) {
    let nextN = addHours(currentN, 1);
    nextN = isAfter(addHours(nextN, 1), lastN) ? lastN : nextN;
    return nextN;
  }
  isUserVendor(u: UserProfile, delegatePh?: string) {
    if (!!u) {
      return u.getCompanyAuthLevel(`${this.vendorCompanySummary.cid}`) !== RoleCompanyAuthRank.unAuthorized;
    } else if (!!delegatePh) {
      return delegatePh === `${this.vendorPickDelegate?.phone}` || delegatePh === `${this.vendorDropDelegate?.phone}` ? true : false;;
    } else {
      return false;
    }
  }

  isUserCustomer(u: UserProfile, delegatePh?: string) {
    if (!!u) {
      return u.getCompanyAuthLevel(`${this.customerCompanySummary.cid}`) !== RoleCompanyAuthRank.unAuthorized;
    } else if (!!delegatePh) {
      return delegatePh === `${this.customerPickDelegate?.phone}` || delegatePh === `${this.customerDropDelegate?.phone}` ? true : false;
    } else {
      return false;
    }
  }

  /** If the status of the record can be changed to the given action. */
  /** to be deprecated replace by canExecute2 and validation in InspectionData class */
  canExecute(action: InspectionStatus, user: UserProfile) {
    const status = this.status;
    switch (action) {
      case InspectionStatus.vendorDropped:
        // if (status >= PickDropStatus.rentStartHandShake || (!this.isUserVendor(user) && !AuthState1.isAdmin)) {
        if (status >= InspectionStatus.rentStartHandShake || !this.isUserVendor(user)) {

          return false;
        }
        break;
      case InspectionStatus.customerPicked:
        if (status >= InspectionStatus.rentStartHandShake || !this.isUserCustomer(user)) {
          return false;
        }
        break;
      case InspectionStatus.customerDropped:
        if (status < InspectionStatus.rentStartHandShake || status >= InspectionStatus.finalHandShake
          || !this.isUserCustomer(user)) {
          return false;
        }
        break;
      case InspectionStatus.vendorPicked:
        if (status < InspectionStatus.rentStartHandShake || status >= InspectionStatus.finalHandShake
          || !this.isUserVendor(user)) {
          return false;
        }
        break;
      default:
        throw Error('Invalid ACTION');
    }
    return true;
  }

  canExecute2(action: InspectionStatus, user: UserProfile, delegatePh?: string) {
    switch (action) {
      case InspectionStatus.vendorDropped:
      case InspectionStatus.initial:
        if (!this.isUserVendor(user, delegatePh)) {

          return false;
        }
        break;
      case InspectionStatus.customerPicked:
        if (!this.isUserCustomer(user, delegatePh)) {
          return false;
        }
        if (this.status < InspectionStatus.vendorDropped) {
          return false;
        }
        break;
      case InspectionStatus.customerDropped:
        if (!this.isUserCustomer(user, delegatePh)) {
          return false;
        }
        break;
      case InspectionStatus.vendorPicked:
        if (!this.isUserVendor(user, delegatePh)) {
          return false;
        }
        break;
      default:
        throw Error('Invalid ACTION');
    }
    return true;
  }

  sanitize() {
    super.sanitize();
  }
  clone() {
    const temp = instanceToPlain(this);
    const t = plainToInstance(Inspection, temp);
    // const t = instanceToInstance<Inspection>(this);
    t.sanitize();
    t.setInspectionTypes();
    return t;
  }
  validateSync(): IValidationMsg {
    const r = this.validateSyncBase(this);
    return r;
  }
  createPickDrop(bid: BidBase, product: Vehicle) {
    const pd = new Inspection();
    pd.customerCompanySummary = bid.customerCompSummary;
    pd.vendorCompanySummary = bid.vendorCompSummary;
    pd.productSummary = bid.productSummary;
    pd.rentSummary = bid.rentSummary;
    pd.bidSummary = bid.getBidSummary();
    if (!!product && !!product.inspectionPersistentData) {
      pd.setInspectionDataVendorDrop(product);
    }

    // set expiry 6PM for bid start date;
    // pd.expiry = addHours(startOfDay(bid.bidNegoTerms.startDate), 18);
    pd.expiry = pd.getExpiry();
    return pd;
  }


  setInspectionStatus(insData: InspectionData, action: InspectionStatus, delegatePh?: string) {
    // const updatedIns = this.clone();
    switch (action) {
      case InspectionStatus.vendorDropped:
        this.vendorDrop = insData;
        if (insData.inspectionResponse === InspectionResponse.initialize) {
          this.status = action;
          // updatedIns.status = InspectionStatus.rentStartHandShake;
          // updatedIns.revAtRentalHandShake = this.revId + 1;
        } else {
          throw new Error('logic change vendorDrop should happen customer');
        }
        if (!!delegatePh) {
          this.vendorDropDelegate.phone = null;
          this.vendorDropDelegate.completedBy = delegatePh;
        }
        break;
      case InspectionStatus.customerPicked:
        this.customerPick = insData;
        if (insData.inspectionResponse === InspectionResponse.accept) {
          this.status = InspectionStatus.rentStartHandShake;
          this.revAtRentalHandShake = this.revId + 1;
        } else {
          this.status = action;
        }
        if (!!delegatePh) {
          this.customerPickDelegate.phone = null;
          this.customerPickDelegate.completedBy = delegatePh;
        }
        break;
      case InspectionStatus.customerDropped:
        this.customerDrop = insData;
        // if (insData.inspectionResponse === InspectionResponse.accept) {
        //   updatedIns.status = InspectionStatus.finalHandShake;
        // } else {
        this.status = action;
        // }
        if (!!delegatePh) {
          this.customerDropDelegate.phone = null;
          this.customerDropDelegate.completedBy = delegatePh;
        }
        break;
      case InspectionStatus.vendorPicked:
        this.vendorPick = insData;
        if (insData.inspectionResponse === InspectionResponse.accept) {
          this.status = InspectionStatus.finalHandShake;
        } else {
          // *** For now internal rental program status set to final handshake once vendor completes return inspection
          this.status = InspectionStatus.finalHandShake;
          // this.status = action;
        }
        if (!!delegatePh) {
          this.vendorPickDelegate.phone = null;
          this.vendorPickDelegate.completedBy = delegatePh;
        }
        break;
      default:
        throw Error('Invalid ACTION');
    }
    this.expiry = this.getExpiry();
    // return updatedIns;
  }
  /** compare two arrays of pictures and return added and deleted pictures.
   * @param a pictures after
   * @param b pictures before
   */
  comparePics(a: Picture[], b: Picture[]): { pAdded: Picture[], pDeleted: Picture[] } {
    const aa: string[] = [];
    const bb: string[] = [];
    const pAdded: Picture[] = [];
    const pDeleted: Picture[] = [];
    a.forEach(x => aa.push(x.url));
    b.forEach(x => bb.push(x.url));

    b.forEach(x => {
      if (aa.includes(x.url)) { } else { pDeleted.push(x); }
    });
    a.forEach(x => {
      if (bb.includes(x.url)) { } else { pAdded.push(x); }
    });

    return { pAdded: pAdded, pDeleted: pDeleted };
  }

  get summary() {
    if (this.productSummary.productType === ProductType.truck) {
      return `${(<any>this.productSummary).isDayCab ? 'Day Cab Truck, ' : ' Sleeper Truck, '}
        ${(<any>this.productSummary).isIRP ? 'IRP Plated, ' : 'ON Plated, '}` +
        `${(<any>this.productSummary).isAuto ? 'Automatic Transmission' : 'Manual Transmission'}`;
    } else if (this.productSummary.productType === ProductType.trailer) {
      return `${(<any>this.productSummary).trailerLength}' ${(<any>this.productSummary).nAxle}-Axle ` +
        `${(<any>this.productSummary).isReefer ? 'Reefer Trailer' : 'Dry Van Trailer'}`;
    } else {
      return null;
    }
  }

  /** check if Admin can rest action. 
   * Only Admin can rest. Calling function need to make sure check user is Admin.
  */
  canResetAction(action: InspectionStatus): boolean {
    if (!DbRule.isRootBranch(this.id)) {
      return false;
    }
    // if (action < InspectionStatus.rentStartHandShake && this.status > InspectionStatus.rentStartHandShake) {
    //   return false;
    // }
    // if (action >= InspectionStatus.finalHandShake) {
    //   return false;
    // }
    switch (action) {
      case InspectionStatus.vendorDropped:
        if (this.status <= InspectionStatus.rentStartHandShake && !isNil(this.vendorDrop)) {
          return true;
        } else {
          return false;
        }
      case InspectionStatus.customerPicked:
        if (this.status <= InspectionStatus.rentStartHandShake && !isNil(this.customerPick)) {
          return true;
        } else {
          return false;
        }
      case InspectionStatus.customerDropped:
        if (this.status >= InspectionStatus.rentStartHandShake && !isNil(this.customerDrop)) {
          return true;
        } else {
          return false;
        }
      case InspectionStatus.vendorPicked:
        if (this.status >= InspectionStatus.rentStartHandShake && !isNil(this.vendorPick)) {
          return true;
        } else {
          return false;
        }

      default:
        return false;
    }
  }
  getPageUserType(action: InspectionStatus): UserEnum {
    let uType: UserEnum;
    switch (action) {
      case InspectionStatus.customerPicked:
      case InspectionStatus.customerDropped:
      case InspectionStatus.customerDisputed:
        uType = UserEnum.customer;
        break;
      case InspectionStatus.vendorDropped:
      case InspectionStatus.vendorPicked:
        uType = UserEnum.vendor;
        break;
      default:
        uType = null;
        break;
    }
    return uType;
  }
  // *

  /**
   * ADMIN ONLY fn. Reset inspection action.
   * Remove the object releated requested action (WILL NOT CHECK). Calling fn in check if admin is user and can action be reset.
   *
   * @param action Action to be reset
   */
  updatePickDropResetAction(action: InspectionStatus) {
    switch (action) {
      case InspectionStatus.customerPicked:
        this.customerPick = undefined;
        this.customerPickDelegate  = undefined;
        if (!!this.vendorDrop) {
          this.status = InspectionStatus.vendorDropped;
        } else {
          this.status = InspectionStatus.initial;
        }
        this.revAtRentalHandShake = null;
        break;
      case InspectionStatus.vendorDropped:
        this.vendorDrop = undefined;
        this.vendorDropDelegate = undefined;
        if (!!this.customerPick) {
          this.status = InspectionStatus.customerPicked;
        } else {
          this.status = InspectionStatus.initial;
        }
        this.revAtRentalHandShake = null;
        break;
      case InspectionStatus.customerDropped:
        this.customerDrop = undefined;
        this.customerDropDelegate = undefined;
        if (!!this.vendorPick) {
          this.status = InspectionStatus.vendorPicked;
        } else {
          this.status = InspectionStatus.rentStartHandShake;
        }
        break;
      case InspectionStatus.vendorPicked:
        this.vendorPick = undefined;
        this.vendorPickDelegate = undefined;
        if (!!this.customerDrop) {
          this.status = InspectionStatus.customerDropped;
        } else {
          this.status = InspectionStatus.rentStartHandShake;
        }
        break;
      case InspectionStatus.customerDisputed:
        this.status = InspectionStatus.vendorPicked;
        break;
      default:
        throw new Error(`Invalid action, ${action} is not programmed`);
    }
    this.expiry = this.getExpiry();
  }
  /** returns latest inspection report for any revsion of pick-drop document, e.g. customerPick or VendorDrop */
  getCurrInspReport(): { currIns: InspectionData, display: string } {
    let r: InspectionData;
    let s1: string;
    switch (this.status) {
      case InspectionStatus.initial:
        r = null;
        s1 = 'PDI Inspection Pending';
        break;
      case InspectionStatus.customerPicked:
        r = this.customerPick;
        if (!this.vendorDrop) {
          s1 = `Customer completed PDI inspection`;
        } else {
          s1 = `Customer updated PDI inspection`;
        }
        break;
      case InspectionStatus.vendorDropped:
        r = this.vendorDrop;
        if (!this.customerPick) {
          s1 = `Vendor completed PDI inspection`;
        } else {
          s1 = `Vendor updated PDI inspection`;
        }
        break;
      case InspectionStatus.rentStartHandShake:
        if (this.customerPick.inspectionResponse === InspectionResponse.accept) {
          r = this.customerPick;
        } else {
          r = this.vendorDrop;
        }
        s1 = `PDI complete`;
        break;
      case InspectionStatus.customerDropped:
        r = this.customerDrop;
        if (!this.vendorPick) {
          s1 = `Customer completed drop-off inspection`;
        } else {
          s1 = `Customer updated drop-off inspection`;
        }
        break;
      case InspectionStatus.vendorPicked:
        r = this.vendorPick;
        if (!this.customerDrop) {
          s1 = `Vendor completed drop-off inspection`;
        } else {
          s1 = `Vendor updated drop-off inspection`;
        }
        break;
      case InspectionStatus.finalHandShake:
        if (this.customerDrop.inspectionResponse === InspectionResponse.accept) {
          r = this.customerDrop;
        } else {
          r = this.vendorPick;
        }
        s1 = `Drop-off complete`;
        break;
      case InspectionStatus.customerDisputed:
        r = this.vendorPick;
        s1 = `Customer disputed incidental charges`;
        break;
      default:
        break;
    }
    return { currIns: r, display: s1 };
  }
  getNavPath(action: InspectionStatus) {
    let path: string;
    switch (action) {
      case InspectionStatus.customerPicked:
        path = '/pickup-drop-of/bid/customer/pickup/';
        break;
      case InspectionStatus.vendorDropped:
        path = '/pickup-drop-of/bid/vendor/drop-off/';
        break;
      case InspectionStatus.customerDropped:
        path = '/pickup-drop-of/bid/customer/drop-off/';
        break;
      case InspectionStatus.vendorPicked:
        path = '/pickup-drop-of/bid/vendor/pickup/';
        break;
      default:
        break;
    }
    return path;
  }
  getExpiry() {
    let e: Date;
    switch (this.status) {
      case InspectionStatus.initial:
        e = addHours(startOfDay(this.bidSummary.startDate), 18);
        break;
      case InspectionStatus.vendorDropped:
      case InspectionStatus.customerPicked:
      case InspectionStatus.customerDropped:
      case InspectionStatus.vendorPicked:
        e = addHours(new Date(), 3);

        break;
      case InspectionStatus.rentStartHandShake:
        e = addHours(this.bidSummary.endDate, 3);
        break;
      default:
        e = null;
        break;
    }
    return e;
  }
  /**
   * @deprecated
   */
  setPickDropStatusOnExpiry(): { pd: Inspection, chgDoc: UpdateDocRef } {
    const updatedPD = this.clone();
    updatedPD.implementExpiredPD();
    // switch (this.status) {
    //   case PickDropStatus.customerPicked:
    //     updatedPD.vendorDrop = this.customerPick;
    //     updatedPD.vendorDrop.inspectionResponse = InspectionResponse.vendorExpired;
    //     updatedPD.status = PickDropStatus.rentStartHandShake;
    //     break;
    //   case PickDropStatus.vendorDropped:
    //     updatedPD.customerPick = this.vendorDrop;
    //     updatedPD.customerPick.inspectionResponse = InspectionResponse.customerExpired;
    //     updatedPD.status = PickDropStatus.rentStartHandShake;
    //     break;
    //   case PickDropStatus.customerDropped:
    //     updatedPD.vendorPick = this.customerDrop;
    //     updatedPD.vendorPick.inspectionResponse = InspectionResponse.vendorExpired;
    //     updatedPD.status = PickDropStatus.finalHandShake;
    //     break;
    //   case PickDropStatus.vendorPicked:
    //     updatedPD.customerDrop = this.vendorPick;
    //     updatedPD.customerDrop.inspectionResponse = InspectionResponse.customerExpired;
    //     updatedPD.status = PickDropStatus.finalHandShake;
    //     break;
    //   default:
    //     break;
    // }
    updatedPD.expiry = null;   // expiry is set null, expiry is only when there is open action for vendor/customer
    const u = this.getCronJobLog(updatedPD);
    return { pd: updatedPD, chgDoc: u };
  }
  getCronJobLog(updatedPD: Inspection): UpdateDocRef {

    let chgDoc: UpdateDocRef;
    const chgMap1 = new Map();
    const chgMap2 = new Map();
    const chgMap3 = new Map();

    switch (this.status) {
      case InspectionStatus.customerPicked:
        chgMap1.set('vendorDrop', { before: this.vendorDrop, after: updatedPD.vendorDrop });
        chgMap2.set('status', { before: this.status, after: updatedPD.status });
        break;
      case InspectionStatus.vendorDropped:
        chgMap1.set('customerPick', { before: this.customerPick, after: updatedPD.customerPick });
        chgMap2.set('status', { before: this.status, after: updatedPD.status });
        break;
      case InspectionStatus.customerDropped:
        chgMap1.set('vendorPick', { before: this.vendorPick, after: updatedPD.vendorPick });
        chgMap2.set('status', { before: this.status, after: updatedPD.status });
        break;
      case InspectionStatus.vendorPicked:
        chgMap1.set('customerDrop', { before: this.customerDrop, after: updatedPD.customerDrop });
        chgMap2.set('status', { before: this.status, after: updatedPD.status });
        break;
      default:
        break;
    }
    chgMap3.set('expiry', { before: this.expiry, after: updatedPD.expiry });

    chgDoc = {
      did: this.id,
      collection: Inspection.collectionName,
      revCreated: this.revId + 1,
      action: CronActions.setPickDropOnExpiry,
      change: [chgMap1, chgMap2, chgMap3]
    };
    return chgDoc;
  }
  isOpenAction(): boolean {
    let b: boolean;
    switch (this.status) {
      case InspectionStatus.vendorDropped:
      case InspectionStatus.customerPicked:
      case InspectionStatus.customerDropped:
      case InspectionStatus.vendorPicked:
        b = true;
        break;
      case InspectionStatus.initial:
      case InspectionStatus.rentStartHandShake:
      case InspectionStatus.finalHandShake:
      case InspectionStatus.customerDisputed:
        b = false;
        break;
      default:
        break;
    }
    return b;
  }
  // getUrlSegment(action: InspectionStatus) {
  //   let urlSeg: string;
  //   switch (action) {
  //     case InspectionStatus.customerPicked:
  //       urlSeg = `${webSiteUrl}/pickup-drop-of/bid/vendor/drop-off/`;
  //       break;
  //     case InspectionStatus.vendorDropped:
  //       urlSeg = `${webSiteUrl}/pickup-drop-of/bid/customer/pickup/`;
  //       break;
  //     case InspectionStatus.customerDropped:
  //       urlSeg = `${webSiteUrl}/pickup-drop-of/bid/vendor/pickup/`;
  //       break;
  //     case InspectionStatus.vendorPicked:
  //       urlSeg = `${webSiteUrl}/pickup-drop-of/bid/customer/drop-off/`;
  //       break;
  //     default:
  //       break;
  //   }
  //   return urlSeg;
  // }
  /**
 * @deprecated
 */
  implementExpiredPD() {
    switch (this.status) {
      case InspectionStatus.customerPicked:
        this.status = InspectionStatus.rentStartHandShake;
        this.vendorDrop = this.customerPick;
        this.vendorDrop.location = null;
        this.vendorDrop.inspectionResponse = InspectionResponse.vendorExpired;
        break;
      case InspectionStatus.vendorDropped:
        this.status = InspectionStatus.rentStartHandShake;
        this.customerPick = this.vendorDrop;
        this.customerPick.location = null;
        this.customerPick.inspectionResponse = InspectionResponse.customerExpired;
        break;
      case InspectionStatus.customerDropped:
        this.status = InspectionStatus.finalHandShake;
        this.vendorPick = this.customerDrop;
        this.vendorPick.location = null;
        this.vendorPick.inspectionResponse = InspectionResponse.vendorExpired;
        break;
      case InspectionStatus.vendorPicked:
        this.status = InspectionStatus.finalHandShake;
        this.customerDrop = this.vendorPick;
        this.customerDrop.location = null;
        this.customerDrop.inspectionResponse = InspectionResponse.customerExpired;
        break;

      default:
        break;
    }
  }
  getInspectionStatusInfo(u: UserEnum) {

    let pdiInfo: string;
    let returnInfo: string;
    let pdiMissing: boolean;
    let returnMissing: boolean;
    let isPdiApprovalPending: boolean;
    switch (this.status) {
      case InspectionStatus.initial:
        pdiInfo = u === UserEnum.vendor ? 'Pre Delivery Inspection Pending' : 'Pre Delivery Inspection Locked';
        returnInfo = 'Return Inspection Locked';
        pdiMissing = u === UserEnum.vendor;
        break;
      case InspectionStatus.customerPicked:
        isPdiApprovalPending = true;
        pdiInfo = 'PDI approval pending'; // 'Customer Completed PDI';
        pdiMissing = u === UserEnum.vendor ? true : false;
        returnInfo = 'Return Inspection Locked';
        break;
      case InspectionStatus.vendorDropped:
        pdiInfo = 'Vendor Completed PDI';
        pdiMissing = u === UserEnum.customer ? true : false;
        returnInfo = 'Return Inspection Locked';
        break;
      case InspectionStatus.rentStartHandShake:
        pdiInfo = 'Pre Delivery Inspection Complete';
        pdiMissing = false;
        returnInfo = !this.isReturnDisabled ? 'Return Inspection Pending' : `Return Inspection Locked`;
        returnMissing = !this.isReturnDisabled ? true : false;

        break;
      case InspectionStatus.customerDropped:
        pdiInfo = 'Pre Delivery Inspection Complete';
        returnInfo = 'Customer Completed Return Inspection';
        pdiMissing = false;
        returnMissing = u === UserEnum.vendor ? true : false;
        break;
      case InspectionStatus.vendorPicked:
        pdiInfo = 'Pre Delivery Inspection Complete';
        returnInfo = 'Vendor Completed Return Inspection';
        pdiMissing = false;
        returnMissing = u === UserEnum.customer ? true : false;

        break;
      case InspectionStatus.finalHandShake:
        pdiInfo = 'Pre Delivery Inspection Complete';
        returnInfo = 'Return Inspection Complete';
        pdiMissing = false;
        returnMissing = false;
        break;
      case InspectionStatus.customerDisputed:
        pdiInfo = 'Pre Delivery Inspection Complete';
        returnInfo = 'Return Inspection Complete, customer disputed';
        pdiMissing = false;
        returnMissing = false;
        break;

      default:
        break;
    }

    this.inspectionStatusInfo = { pdiInfo, pdiMissing, returnInfo, returnMissing, isPdiApprovalPending };
  }
  /**
   * get inspection data based on user action
   * @param action User Action
   */
  getInspectionData(action: InspectionStatus) {
    switch (action) {
      case InspectionStatus.customerPicked:
        if (this.status >= InspectionStatus.rentStartHandShake) {
          return !!this.customerPick ? this.customerPick : this.vendorDrop;
        } else {
          return this.customerPick;
        }
      case InspectionStatus.vendorDropped:
        let d: InspectionData;
        if (this.customerPick?.inspectionResponse === InspectionResponse.update) {
          d = this.customerPick;
        } else {
          d = this.vendorDrop;
        }
        return d;
      case InspectionStatus.customerDropped:
        if (this.status >= InspectionStatus.vendorPicked) {
          return this.vendorPick;
        } else {
          return this.customerDrop;
        }
      case InspectionStatus.vendorPicked:
        return this.vendorPick;

      default:
        throw new Error(`[inspection] getInspectionData invalid action ${action} `);
    }
  }
  /**
 * copy inspection data based on user action
 * @param action User Action
 */
  copyInspectionData(action: InspectionStatus) {
    // if (this.getInspectionData(action)) {
    //   return this.getInspectionData(action);
    // }
    switch (action) {
      case InspectionStatus.customerPicked:
        if (this.status === InspectionStatus.vendorDropped) {
          const d = this.vendorDrop.clone();
          (d as InspectionDataTruck).setPropertiesToReview();
          return d;
        } else {
          throw new Error('Not authorized');
          return this.productSummary.productType === ProductType.truck ? new InspectionDataTruck() : new InspectionData();
        }
      case InspectionStatus.vendorDropped:
        if (this.status === InspectionStatus.customerPicked) {
          throw new Error('Not authorized');
          return this.customerPick.clone();
        } else {
          if (!this.vendorDrop) { this.setInspectionDataVendorDrop(); } else {
            const d = this.vendorDrop;
            (d as InspectionDataTruck).tires.forEach(t => {
              if (typeof t.isReturn !== 'boolean') {  t.isReturn = false; }
            });
            return d;
          }
          return this.productSummary.productType === ProductType.truck ? new InspectionDataTruck() : new InspectionData();
        }
      case InspectionStatus.customerDropped:
        if (this.status === InspectionStatus.vendorPicked) {
          const d = this.vendorPick.clone();
          this.resetPropsForReturnWhenReturnExists(d);
          return d;
        } else {
          const d = this.vendorDrop.clone();
          this.resetPropsForReturn(d);
          return d;
        }
      case InspectionStatus.vendorPicked:
        if (this.status === InspectionStatus.customerDropped) {
          const d = this.customerDrop.clone();
          this.resetPropsForReturnWhenReturnExists(d);
          return d;
        } else {
          const d = this.vendorDrop.clone();
          this.resetPropsForReturn(d);
          return d;
        }
      default:
        throw new Error(`[inspection] getInspectionData invlid action ${action} `);
    }
  }
  resetPropsForReturn(d: InspectionData) {
    switch (this.productSummary.productType) {
      case ProductType.truck:
        (d as InspectionDataTruck).resetPropsForReturn();
        break;
      case ProductType.trailer:
        (d as InspectionDataTrailer).resetPropsForReturn();
        break;

      default:
        break;
    }
  }
  resetPropsForReturnWhenReturnExists(d: InspectionData) {
    switch (this.productSummary.productType) {
      case ProductType.truck:
        (d as InspectionDataTruck).resetPropsForReturnWhenReturnExists();
        break;
      case ProductType.trailer:
        (d as InspectionDataTrailer).resetPropsForReturnWhenReturnExists();
        break;

      default:
        break;
    }
  }
  canEdit = (action: InspectionStatus) => {
    if (this.status === action) {
      return true;
    }
    switch (action) {
      case InspectionStatus.customerPicked:
      case InspectionStatus.vendorDropped:
        if (this.status >= InspectionStatus.customerPicked) {
          return false;
        } else {
          return true;
        }
      case InspectionStatus.customerDropped:
      case InspectionStatus.vendorPicked:
        if (this.status >= InspectionStatus.rentStartHandShake && this.status < InspectionStatus.finalHandShake) {
          return true;
        } else {
          return false;
        }
      default:
        throw new Error(`[inspection-helper] Programing error cannot get canEdit, invalid action type ${action}`);
    }
  }
  canRead = (action: InspectionStatus) => {
    if (this.status === action) {
      return true;
    }
    switch (action) {
      case InspectionStatus.customerPicked:
      case InspectionStatus.vendorDropped:
        return true;
      case InspectionStatus.customerDropped:
      case InspectionStatus.vendorPicked:
        if (this.status >= InspectionStatus.rentStartHandShake) {
          return true;
        } else {
          return false;
        }
      default:
        throw new Error(`[inspection-helper] Programing error cannot get canEdit, invalid action type ${action}`);
    }
  }
  /**
 * compares two picture array, retures false if @param updated does not have any picture in @param orig
 * based on @param path
 * @param updated updated Picture Array
 * @param orig Orginal Pciture Array
 * @param action User Action
 */
  checkDeletedPictures = (d: InspectionData, action: InspectionStatus) => {
    // let orig = this.copyInspectionData(action).pictures;
    let orig: InspectionData;
    switch (action) {
      case InspectionStatus.customerPicked:
        orig = this.vendorDrop;
        break;
      default:
        return true;
        break;
    }
    if (!orig || !orig.pictures) {
      throw new Error('Vendor Drop cannot be null');

    }
    for (const p of orig.pictures) {
      if (d.pictures.findIndex(f => f.path === p.path) === -1) {
        return false;
      }
    }
    if (this.productSummary.productType === ProductType.truck) {
      for (const p of (orig as InspectionDataTruck).interiorPictures) {
        if ((d as InspectionDataTruck).interiorPictures.findIndex(f => f.path === p.path) === -1) {
          return false;
        }
      }
    }
    return true;
  }
  setPicturesAtStart(action: InspectionStatus) {
    if (action > InspectionStatus.rentStartHandShake) {
      if (this.customerPick?.inspectionResponse === InspectionResponse.accept) {
        this.picturesAtStart = this.customerPick.pictures;
        this.videosAtStart = this.customerPick.videos;
      } else {
        this.picturesAtStart = this.vendorDrop.pictures;
        this.videosAtStart = this.vendorDrop.videos;

      }
    }
  }
  /**
   * get open action based userprofile and inspection status
   * @param user UserProfile
   */
  getOpenAction(user: UserProfile) {
    if (this.status < InspectionStatus.rentStartHandShake) {
      if (this.isUserCustomer(user)) {
        return InspectionStatus.customerPicked;
      } else if (this.isUserVendor(user)) {
        return InspectionStatus.vendorDropped;
      } else {
        return null;
      }
    } else if (this.status >= InspectionStatus.rentStartHandShake && this.status < InspectionStatus.finalHandShake) {
      if (this.isUserCustomer(user)) {
        return InspectionStatus.customerDropped;
      } else if (this.isUserVendor(user)) {
        return InspectionStatus.vendorPicked;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }
  /**
   * Adds and remove delegate for give inspeciton action
   * @param addRemove boolean, @param true for adding a delegate and @param false for removing delegate
   * @param action InspectionStatus, action that needs delgate change
   * @param u UserProfile, userprofile of user make request to delegate change
   */
  addRemoveDelegate(addRemove: boolean, action: InspectionStatus, u: UserProfile, phone: string): Inspection {
    const currOpenAction = this.getOpenAction(u);
    // if open action by user matches the requested action for delegation
    if (action !== currOpenAction) {
      return null;
    }
    // if phone is to be removed verify the phone provided in the request matches the db phone based on delgated action
    if (addRemove === false && this.getDelegatedPhone(action) !== phone) {
      return null;
    }
    // now updated
    const updated = this.clone();
    let d: InspectionDelegate;
    if (!!addRemove) {
      d = new InspectionDelegate();
      d.phone = phone;
      d.delegateBy = u.id;
    } else {
      d = null;
    }
    switch (action) {
      case InspectionStatus.customerPicked:
        updated.customerPickDelegate = d;
        break;
      case InspectionStatus.vendorDropped:
        updated.vendorDropDelegate = d;
        break;
      case InspectionStatus.customerDropped:
        updated.customerDropDelegate = d;
        break;
      case InspectionStatus.vendorPicked:
        updated.vendorPickDelegate = d;
        break;

      default:
        logger.info('[inspection] invalid action', action);
        return null;
    }
    return updated;
  }
  getDelegatedPhone(currOpenAction: InspectionStatus) {
    switch (currOpenAction) {
      case InspectionStatus.customerPicked:
        return !!this.customerPickDelegate.phone ? this.customerPickDelegate.phone : this.customerPickDelegate.completedBy;
      case InspectionStatus.vendorDropped:
        return !!this.vendorDropDelegate.phone ? this.vendorDropDelegate.phone : this.vendorDropDelegate.completedBy;
      case InspectionStatus.customerDropped:
        return !!this.customerDropDelegate.phone ? this.customerDropDelegate.phone : this.customerDropDelegate.completedBy;
      case InspectionStatus.vendorPicked:
        return !!this.vendorPickDelegate.phone ? this.vendorPickDelegate.phone : this.vendorPickDelegate.completedBy;

      default:
        return null;
    }
  }
  getDelegatedBy(action: InspectionStatus): string | number {
    switch (action) {
      case InspectionStatus.customerPicked:
        return this.customerPickDelegate.delegateBy;
      case InspectionStatus.vendorDropped:
        return this.vendorDropDelegate.delegateBy;
      case InspectionStatus.customerDropped:
        return this.customerDropDelegate.delegateBy;
      case InspectionStatus.vendorPicked:
        return this.vendorPickDelegate.delegateBy;
      default:
        throw new Error(`[inspection] Programing error cannot getUserAction, invalid action type ${action}`);

    }
  }
  /** return cid when action is completed. If Customer completes action return vendor cid
   * and when vendor completes action return customer cid
   * @param action InspectionStatus
   */
  getCidFromInspectionAction(action: InspectionStatus): string {
    switch (action) {
      case InspectionStatus.customerPicked:
      case InspectionStatus.customerDropped:
        return this.vendorCompanySummary.cid;
      case InspectionStatus.vendorDropped:
      case InspectionStatus.vendorPicked:
        return this.customerCompanySummary.cid;

      default:
        throw new Error(`[inspection] Programing error cannot getCidFromInspectionAction, invalid action type ${action}`);

    }
  }
  /** return company namw when action is completed. If Customer completes action returns Customer company
 * and when vendor completes action return vendor company
 * @param action InspectionStatus
 */
  getCompNameFromInspectionAction(action: InspectionStatus): string {
    switch (action) {
      case InspectionStatus.customerPicked:
      case InspectionStatus.customerDropped:
        return companyFriendlyName(this.customerCompanySummary?.name, this.customerCompanySummary?.legalName);
      case InspectionStatus.vendorDropped:
      case InspectionStatus.vendorPicked:
        return companyFriendlyName(this.vendorCompanySummary?.name, this.vendorCompanySummary?.legalName);

      default:
        throw new Error(`[inspection] Programing error cannot getCidFromInspectionAction, invalid action type ${action}`);

    }
  }
  /**
   * set task input when inspection is completed by custome or vendor or expired by system
   * @param task Task
   * @param action Inspection Action
   * @param websiteUrl website url (debug / prod)
   * @param gCTaskType Google Cloud Task optional param, only required when inspection is expired by system
   */
  setTaskInputs(task: Task, action: InspectionStatus, websiteUrl: string, gCTaskType?: GCTaskType) {

    task.senderCompanyName = this.getCompNameFromInspectionAction(action);
    task.taskType = this.getTaskType(action, gCTaskType);
    task.name = `${task.senderCompanyName} ${toSentence(EnumHelper.getName(TaskType, task.taskType))}`;
    task.data = { action: `${task.taskType}`, action_key: `${this.id}` };


    const urlSegment = TaskUtl.getUrlFromTaskType(task.taskType, task.data.action_key).url;

    task.notification = {
      title: `${task.name}`,
      body: `Click to get Inspection details`,
      icon: 'https://placeimg.com/250/250/people',
      clickAction: `${websiteUrl}${urlSegment}`
    };
  }
  getTaskType(action: InspectionStatus, gCTaskType?: GCTaskType): TaskType {
    switch (action) {
      case InspectionStatus.vendorDropped:
        if (isNil(gCTaskType)) {
          return TaskType.VendorCompetedPDI;
        } else {
          return TaskType.VendorPDIExpired;
        }
      case InspectionStatus.customerPicked:
        if (isNil(gCTaskType)) {
          return TaskType.CustomerCompetedPDI;
        } else {
          return TaskType.CustomerPDIExpired;
        }
      case InspectionStatus.customerDropped:
        if (isNil(gCTaskType)) {
          return TaskType.CustomerCompetedReturnIspection;
        } else {
          return TaskType.CustomerReturnInspectionExpired;
        }
      case InspectionStatus.vendorPicked:
        if (isNil(gCTaskType)) {
          return TaskType.VendorCompetedReturnIspection;
        } else {
          return TaskType.VendorReturnIspectionExpired;
        }
      default:
        throw new Error(`[inspection] Programing error cannot getTaskType, invalid action type ${action}`);

    }
  }

  addPicturesForReview(action: InspectionStatus, insData: InspectionData) {
    let pdiPictures: Picture[] = [];
    let pdiVideos: FileInfo[] = [];
    let returnInspectionPictures: Picture[] = [];
    let returnInspectionVideos: Picture[] = [];
    if (this.status >= InspectionStatus.rentStartHandShake) {
      pdiPictures = merge(this.customerPick?.pictures, this.vendorDrop?.pictures);
      pdiVideos = merge(this.customerPick?.videos, this.vendorDrop?.videos);
      // pdiPictures = this.customerPick.pictures.slice();
      // pdiPictures.map(item => {
      //   const key = Object.keys(item)[0];
      //   return this.vendorDrop.pictures.find(v => v[key]) || item;
      // });
    }
    if (this.status >= InspectionStatus.finalHandShake) {
      returnInspectionPictures = merge(this.customerDrop?.pictures, this.vendorPick?.pictures);
      returnInspectionVideos = merge(this.customerDrop?.pictures, this.vendorPick?.pictures);
    }
    switch (action) {
      case InspectionStatus.vendorDropped:
      case InspectionStatus.customerPicked:
        if (this.status >= InspectionStatus.rentStartHandShake) {
          insData.pictures = pdiPictures;
          insData.videos = pdiVideos;
        }
        break;
      case InspectionStatus.customerDropped:
      case InspectionStatus.vendorPicked:
        this.picturesAtStart = pdiPictures;
        this.picturesAtStart = pdiPictures;
        if (!isNil(returnInspectionPictures)) {
          insData.pictures = returnInspectionPictures;
        }
        if (!isNil(returnInspectionVideos)) {
          insData.videos = returnInspectionVideos;
        }
        break;
      default:
        throw new Error(`[inspection] Programing error cannot getTaskType, invalid action type ${action}`);

    }
  }
  isReadOnly(action: InspectionStatus) {
    switch (action) {
      case InspectionStatus.vendorDropped:
      case InspectionStatus.customerPicked:
        if (this.status >= InspectionStatus.rentStartHandShake) {
          return true;
        } else {
          return false;
        }
      case InspectionStatus.customerDropped:
      case InspectionStatus.vendorPicked:
        if (this.status >= InspectionStatus.finalHandShake) {
          return true;
        } else {
          return false;
        }
      default:
        throw new Error(`[inspection] Programing error cannot getTaskType, invalid action type ${action}`);

    }
  }
  expireInspection(gcTaskType: GCTaskType) {
    let action: InspectionStatus;
    const uIns = this.clone();
    switch (gcTaskType) {
      case GCTaskType.expirePDI:
        if (uIns.status === InspectionStatus.customerPicked && isNil(uIns.vendorDrop)) {
          action = InspectionStatus.vendorDropped;
          uIns.vendorDrop = uIns.copyInspectionData(action);
          uIns.status = InspectionStatus.rentStartHandShake;
          uIns.revAtRentalHandShake = uIns.revId + 1;
        } else if (uIns.status === InspectionStatus.vendorDropped && isNil(uIns.customerPick)) {
          action = InspectionStatus.customerPicked;
          uIns.customerPick = uIns.copyInspectionData(action);
          uIns.status = InspectionStatus.rentStartHandShake;
          uIns.revAtRentalHandShake = uIns.revId + 1;
        } else {
          throw new Error('[inspection] inspection cannot be expired');
        }
        break;
      case GCTaskType.expireReturnInspection:
        if (uIns.status === InspectionStatus.customerDropped && isNil(uIns.vendorPick)) {
          action = InspectionStatus.vendorPicked;
          uIns.vendorPick = uIns.copyInspectionData(action);
          uIns.status = InspectionStatus.finalHandShake;
        } else if (uIns.status === InspectionStatus.vendorDropped && isNil(uIns.customerDrop)) {
          action = InspectionStatus.customerDropped;
          uIns.customerDrop = uIns.copyInspectionData(action);
          uIns.status = InspectionStatus.finalHandShake;
        } else {
          throw new Error('[inspection] inspection cannot be expired');
        }
        break;

      default:
        throw new Error('[inspection] invalid action');

    }
    uIns.expiry = uIns.getExpiry();
    return uIns;
  }

  getCanAdminReset(action: InspectionStatus) {
    switch (action) {
      case InspectionStatus.vendorDropped:
        if (this.status <= InspectionStatus.rentStartHandShake && !isNil(this.vendorDrop)) {
          return true;
        } else {
          return false;
        }
      case InspectionStatus.customerPicked:
        if (this.status <= InspectionStatus.rentStartHandShake && !isNil(this.customerPick)) {
          return true;
        } else {
          return false;
        }
      case InspectionStatus.customerDropped:
        if (this.status >= InspectionStatus.rentStartHandShake && !isNil(this.customerDrop)) {
          return true;
        } else {
          return false;
        }
      case InspectionStatus.vendorPicked:
        if (this.status >= InspectionStatus.rentStartHandShake && !isNil(this.vendorPick)) {
          return true;
        } else {
          return false;
        }

      default:
        return false;
    }
  }

  canResetExpiry(updatedExpiry: Date): boolean {
    if (isAfter(updatedExpiry, this.bidSummary.endDate)) {
      return false;
    }
    switch (this.status) {
      case InspectionStatus.initial:
        if (!!isNil(this.customerPick) && !!isNil(this.vendorDrop)) {
          return true;
        } else {
          return false;
        }
      case InspectionStatus.vendorDropped:
        if (!!isNil(this.customerPick)) {
          return true;
        } else {
          return false;
        }
      case InspectionStatus.customerPicked:
        if (!!isNil(this.vendorDrop)) {
          return true;
        } else {
          return false;
        }
      case InspectionStatus.rentStartHandShake:
        if (!!isNil(this.customerDrop) && !!isNil(this.vendorPick)) {
          return true;
        } else {
          return false;
        }
      case InspectionStatus.customerDropped:
        if (!!isNil(this.customerDrop)) {
          return true;
        } else {
          return false;
        }
      case InspectionStatus.vendorPicked:
        if (!!isNil(this.vendorPick)) {
          return true;
        } else {
          return false;
        }

      default:
        return false;
    }
  }

  updateExpiry(updatedExpiry: Date) {
    this.expiry = updatedExpiry;
  }
  /**
   * sets Initial Inspection data
   * @param product Product Base
   */
  setInspectionDataVendorDrop(product?: Vehicle) {
   
    switch (this.productSummary.productType) {
      case ProductType.truck:
        this.vendorDrop = new InspectionDataTruck();
        // tslint:disable-next-line:max-line-length
        if (!!product && !!product.inspectionPersistentData) {
          (this.vendorDrop as InspectionDataTruck).setTruckTireData(product as Truck);
        };
        break;
      case ProductType.trailer:
        this.vendorDrop = new InspectionDataTrailer();
        // tslint:disable-next-line:max-line-length
        if (!!product && !!product.inspectionPersistentData) { (this.vendorDrop as InspectionDataTrailer).setTruckTireData(product as Trailer) };
        break;

      default:
        throw new Error(`${ProductType[product.productType]} is implemented`);

        break;
    }
  }
  addPictureComments() {
    this.customerPick?.pictures?.map(p => {
      const vPaths = this.vendorDrop?.pictures?.map(f => f.path);
      if (!vPaths.includes(p.path)) {
        p.comment = 'Customer added PDI picture';
      }

    });
    this.customerDrop?.pictures?.map(p => {
      const vPaths = this.vendorPick?.pictures?.map(f => f.path);
      if (!!vPaths && !vPaths.includes(p.path)) {
        p.comment = 'Vendor removed return picture';
      }
    });
  }
  addIntPictureComments() {
    if (this.productSummary.productType !== ProductType.truck) {
      return;
    }
    const c = this.customerPick as InspectionDataTruck;
    const v = this.vendorDrop as InspectionDataTruck;
    c?.interiorPictures?.map(p => {
      const vPaths = v?.interiorPictures?.map(f => f.path);
      if (!vPaths.includes(p.path)) {
        p.comment = 'Customer added PDI Picture';
      }
    });
    const cR = this.customerDrop as InspectionDataTruck;
    const vR = this.vendorPick as InspectionDataTruck;
    cR?.interiorPictures?.map(p => {
      const vPaths = vR?.interiorPictures?.map(f => f.path);
      if (!!vPaths && !vPaths.includes(p.path)) {
        p.comment = 'Vendor removed return picture';
      }
    });
  }

  get additionalPDICustomerExtComments() {
    if (!this.customerPick || !this.customerPick.exteriorInspectionComments) {
      return null;
    }
    if (this.customerPick.exteriorInspectionComments === this.vendorDrop.exteriorInspectionComments) {
      return null;
    }
    return `Vendor Comments: ${this.vendorDrop.exteriorInspectionComments} | Customer Comments: ${this.customerPick.exteriorInspectionComments}`;
  }
  get additionalPDICustomerIntComments() {
    if (!this.customerPick || !(this.customerPick as InspectionDataTruck).innerInspectionComments) {
      return {vendorCmts :(this.vendorDrop as InspectionDataTruck).innerInspectionComments};
      //return null;
    }
    // tslint:disable-next-line:max-line-length
    if ((this.customerPick as InspectionDataTruck).innerInspectionComments === (this.vendorDrop as InspectionDataTruck).innerInspectionComments) {
      return {vendorCmts :(this.vendorDrop as InspectionDataTruck).innerInspectionComments};
      //return null;
    }
    // tslint:disable-next-line:max-line-length
    let vendorCmts = (this.vendorDrop as InspectionDataTruck).innerInspectionComments;
    let customerCmts = (this.customerPick as InspectionDataTruck).innerInspectionComments;
    return { vendorCmts ,customerCmts };

    //return `Vendor Comments: ${(this.vendorDrop as InspectionDataTruck).innerInspectionComments} | Customer Comments: ${(this.customerPick as InspectionDataTruck).innerInspectionComments}`;
  }

  get pdiCombinedCommentsExt() {
    if (!this.customerPick) {
      return {vendorCmts :(this.vendorDrop as InspectionDataTruck).exteriorInspectionComments};
    }
    if (this.customerPick?.exteriorInspectionComments === this.vendorDrop.exteriorInspectionComments) {
      return {vendorCmts :this.vendorDrop.exteriorInspectionComments};
    } else {
      let vendorCmts = (this.vendorDrop as InspectionDataTruck).exteriorInspectionComments;
      let customerCmts = (this.customerPick as InspectionDataTruck).exteriorInspectionComments?.substring(vendorCmts?.length, (this.customerPick as InspectionDataTruck).exteriorInspectionComments?.length);
      return { vendorCmts ,customerCmts };
      //return `Vendor Comments: ${(this.vendorDrop as InspectionDataTruck).exteriorInspectionComments} | Customer Comments: ${customerCmts}`;
    }
  }
  get combinedPdiIntComments() {
    if (!this.customerPick) {
      return {vendorCmts :(this.vendorDrop as InspectionDataTruck).innerInspectionComments};
    }
    // tslint:disable-next-line:max-line-length
    if ((this.customerPick as InspectionDataTruck)?.innerInspectionComments === (this.vendorDrop as InspectionDataTruck).innerInspectionComments) {
      return {vendorCmts :(this.vendorDrop as InspectionDataTruck).innerInspectionComments};
    } else {
      let vendorCmts = (this.vendorDrop as InspectionDataTruck).innerInspectionComments;
      let customerCmts = (this.customerPick as InspectionDataTruck).innerInspectionComments?.substring(vendorCmts?.length, (this.customerPick as InspectionDataTruck).innerInspectionComments?.length);
      return { vendorCmts ,customerCmts };
     // return `Vendor Comments: ${(this.vendorDrop as InspectionDataTruck).innerInspectionComments} | Customer Comments: ${customerCmts}`;
    }
  }
  get combinedReturnIntComments() {
    if (!this.customerDrop) {
      return {vendorCmts :(this.vendorPick as InspectionDataTruck).innerInspectionComments};
    }
    if (!this.vendorPick) {
      return {customerCmts:(this.customerDrop as InspectionDataTruck).innerInspectionComments};
    }
    // tslint:disable-next-line:max-line-length
    if ((this.customerDrop as InspectionDataTruck)?.innerInspectionComments === (this.vendorPick as InspectionDataTruck)?.innerInspectionComments) {
      return {vendorCmts:(this.vendorDrop as InspectionDataTruck).innerInspectionComments};
    } else {
      let vendorCmts = this.vendorPick.exteriorInspectionComments;
      let customerCmts = this.customerDrop?.exteriorInspectionComments?.substring(vendorCmts.length, this.customerDrop?.exteriorInspectionComments?.length);
      return { vendorCmts ,customerCmts };
     // return `Vendor Comments: ${this.vendorPick?.exteriorInspectionComments} | Customer Comments: ${customerCmts}`;
    }
  }
  get returnCombinedCommentsExt(): { comments: object, chg: boolean } {
    if (!this.customerDrop) {
      return { comments:{vendorCmts: this.vendorPick.exteriorInspectionComments}, chg: false };
    }
    if (!this.vendorPick) {
      return { comments: {customerCmts:this.customerDrop.exteriorInspectionComments}, chg: false };
    }
    if (this.customerDrop?.exteriorInspectionComments === this.vendorPick?.exteriorInspectionComments) {
      return { comments: {vendorCmts:this.vendorPick.exteriorInspectionComments}, chg: false };
    } else {
      let vendorCmts = (this.vendorPick as InspectionDataTruck).exteriorInspectionComments;
      let customerCmts = (this.customerDrop as InspectionDataTruck).exteriorInspectionComments?.substring(vendorCmts.length, (this.customerPick as InspectionDataTruck).exteriorInspectionComments?.length)
      return {
       // comments: `Vendor Comments: ${(this.vendorPick as InspectionDataTruck)?.exteriorInspectionComments} | Customer Comments: ${customerCmts}`,
       comments: { vendorCmts ,customerCmts },
        chg: true
      };
    }
  }
  canMakePdiDecision(user: UserProfile): boolean {
    if (this.isUserVendor(user) && this.status === InspectionStatus.customerPicked) {
      return true;
    } else {
      return false;
    }
  }
  canStartRental(user: UserProfile): boolean {
    if (this.isUserVendor(user) && this.status === InspectionStatus.vendorDropped) {
      return true;
    } else {
      return false;
    }
  }
  canResetStartRental(user: UserProfile): boolean {
    if (this.isUserVendor(user) && this.status === InspectionStatus.rentStartHandShake && !this.customerPick) {
      return true;
    } else {
      return false;
    }
  }
  canExecutePDIDecision(user: UserProfile) {
    switch (this.status) {
      case InspectionStatus.customerPicked:
        return this.canMakePdiDecision(user);
      case InspectionStatus.vendorDropped:
        return this.canStartRental(user);
      case InspectionStatus.rentStartHandShake:
        return this.canResetStartRental(user);
      default:
        throw new Error('canExecutePDIDecision, programming error');
    }
  }

  executeDecision() {
    switch (this.pdiDecision) {
      case InspectionDecision.approve:
        this.revAtRentalHandShake = this.revId + 1;
        this.status = InspectionStatus.rentStartHandShake;
        break;
      case InspectionDecision.reject:
        this.customerPick = undefined;
        this.status = InspectionStatus.vendorDropped;
        break;
      case InspectionDecision.reset:
        this.customerPick = undefined;
        this.vendorDrop = undefined;
        this.status = InspectionStatus.initial;
        break;
      default:
        throw new Error(`${InspectionDecision[this.pdiDecision]} is not implemented`);
    }
  }

  setInspectionTypes() {
    if (this.vendorDrop && !this.vendorDrop.inspectionType) {
      this.vendorDrop.inspectionType = InspectionStatus.vendorDropped;
    }
    if (this.customerPick && !this.customerPick.inspectionType) {
      this.customerPick.inspectionType = InspectionStatus.customerPicked;
    }
    if (this.customerDrop && !this.customerDrop.inspectionType) {
      this.customerDrop.inspectionType = InspectionStatus.customerDropped;
    }
    if (this.vendorPick && !this.vendorPick.inspectionType) {
      this.vendorPick.inspectionType = InspectionStatus.vendorPicked;
    }

  }
  baselineReturnInspection(action: InspectionStatus) {
    switch (action) {
      case InspectionStatus.customerDropped:
        return this.vendorPick;
      case InspectionStatus.vendorPicked:
        return this.customerDrop;

      default:
        return null;

    }
  }
  getPDIData() {
    return !!this.customerPick ? this.customerPick : this.vendorDrop;
  }
}
