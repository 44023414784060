import { CmsState } from './../cms-store/cms.state';
import { RouterState } from './router.state';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { environment } from '../../core/environments/environment';

import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { AppState1 } from './app.state';
import { AuthState } from '../auth-store/auth.state';
import { MaterialModule } from '../../../projects/locus-loop/src/app/shared/share-module/material.module';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule } from '@angular/forms';

const sharedComps = [
  // CkEditorCtrlComponent,
  // SubPageComponent,

];

const sharedModule = [
  MaterialModule,
  MatSelectModule,

];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgxsModule.forRoot([RouterState, AppState1, CmsState, AuthState], { developmentMode: !environment.production }),
        !environment.production ? NgxsReduxDevtoolsPluginModule.forRoot() : [],
        // !environment.production ? NgxsReduxDevtoolsPluginModule.forRoot({ logger: console, collapsed: false }) : []
        ...sharedModule
    ],
    declarations: [
        ...sharedComps
    ],
    exports: [
        CommonModule,
        FormsModule,
        ...sharedComps,
        // ...sharedModule
    ]
})
export class RootStoreModule { }
