import { FinanceInput } from './../finance-input/finance-input-handler';
import { InvoiceItem } from './../invoice/invoice-item';
import { logger } from '@trentm/log/logger';
import { round } from 'lodash';
import { TransactionType } from '../invoice/invoice-type';
import { IMethodInfo, methodFn } from '../finance-input/fn-lib';
import { PaymentMethod } from '../payment-method/i-payment-method';
import { IServiceItem, ServiceType } from './i-service-item';
import { BaseFee } from '../invoice/base-fee';


// tslint:disable-next-line:max-line-length
export const calculateServiceFee = (/*baseFee: BaseFee, */item: InvoiceItem, service: ServiceType, invoiceType: TransactionType, paymentMethod: PaymentMethod) => {
  const baseFee = new BaseFee();
  baseFee.type = 'serviceFee';
  baseFee.name = 'Service Fee';
  let m: IMethodInfo;
  let method: IMethodInfo;
  let feeType = FinanceInput.fin.specialFeeNN[service].jurisdiction[item.taxJuris].serviceFee;
  if(!feeType){
    return undefined;
  }
  switch (invoiceType) {
    case TransactionType.credit:
      // m = FinanceInput.fin.specialFee.serviceFee[service][item.taxJuris].customer;
      method = FinanceInput.fin.specialFeeNN[service].data.serviceFee[feeType].customer;
      break;
    case TransactionType.debit:
      // m = FinanceInput.fin.specialFee.serviceFee[service][item.taxJuris].vendor;
      method = FinanceInput.fin.specialFeeNN[service].data.serviceFee[feeType].vendor;
      break;
    default:
      throw new Error(`Invalid Invoice Type provided, [${invoiceType}] is invalid or not yet implemented.`);
  }
  // baseFee.amount = round(methodFn(m, item), FinanceInput.fin.rdigit);
  baseFee.amount = round(methodFn(method, item), FinanceInput.fin.rdigit);
  // baseFee.name = FinanceInput.fin.specialFee.serviceFee[service][item.taxJuris].name;
  baseFee.name = FinanceInput.fin.specialFeeNN[service].data.serviceFee[feeType].name;;
  // baseFee.isTaxable = m.isTaxable;
  baseFee.isTaxable = method.isTaxable;
  return baseFee;
};

// tslint:disable-next-line:max-line-length
export const calculateEnvFee = (stateProv: string, item: InvoiceItem, service: ServiceType, invoiceType: TransactionType, paymentMethod: PaymentMethod) => {
  try {
    const baseFee = new BaseFee();
    baseFee.type = 'environmentFee';
    baseFee.name = 'Environment Fee';
    let m: IMethodInfo;
    let method: IMethodInfo;
    let serviceItem: IServiceItem;
    let feeType = FinanceInput.fin.specialFeeNN[service].jurisdiction[item.taxJuris].environmentFee;
    switch (invoiceType) {
      case TransactionType.credit:
        // m = FinanceInput.fin.specialFee[baseFee.type][service][stateProv].customer;
        method = FinanceInput.fin.specialFeeNN[service].data.environmentFee[feeType].customer;

        // m = FinanceInput.fin.environmentalFee[service].customer;
        break;
      case TransactionType.debit:
        // m = FinanceInput.fin.specialFee[baseFee.type][service][stateProv].vendor;
        method = FinanceInput.fin.specialFeeNN[service].data.environmentFee[feeType].vendor;

        // m = FinanceInput.fin.environmentalFee[service].vendor;
        break;
      default:
        throw new Error(`Invalid Invoice Type provided, [${invoiceType}] is invalid or not yet implemented.`);
    }
    // baseFee.amount = round(methodFn(m, item), FinanceInput.fin.rdigit);
    baseFee.amount = round(methodFn(method, item), FinanceInput.fin.rdigit);
    // baseFee.name = FinanceInput.fin.specialFee[baseFee.type][service][stateProv].name; // FinanceInput.fin.environmentalFee[service].name;
    baseFee.name = FinanceInput.fin.specialFeeNN[service].data.environmentFee[feeType].name;;
    // baseFee.isTaxable = m.isTaxable;
    baseFee.isTaxable = method.isTaxable;
    return baseFee;
  } catch (error) {
    logger.error(`Error updating the environmental fee calculation! Check financial input data against stateProv: ${stateProv}`, error);
    throw error;
  }
};


// tslint:disable-next-line:max-line-length
export const calculateVehLicFee = (stateProv: string, item: InvoiceItem, service: ServiceType, invoiceType: TransactionType, paymentMethod: PaymentMethod) => {
  try {
    const baseFee = new BaseFee();
    baseFee.type = 'vehicleLicensingFee'; // 'vehLicLicensingFee';
    baseFee.name = 'Vehicle Licensing Fee';
    let m: IMethodInfo;
    let method: IMethodInfo;
    let feeType = FinanceInput.fin.specialFeeNN[service].jurisdiction[item.taxJuris].vehicleLicensingFee;

    switch (invoiceType) {
      case TransactionType.credit:
        // m = FinanceInput.fin.specialFee[baseFee.type][service][stateProv].customer;
        // m = FinanceInput.fin.vehicleLicensingFee[service].customer;
        method = FinanceInput.fin.specialFeeNN[service].data.vehicleLicensingFee[feeType].customer;
        break;
      case TransactionType.debit:
        // m = FinanceInput.fin.specialFee[baseFee.type][service][stateProv].vendor;
        method = FinanceInput.fin.specialFeeNN[service].data.vehicleLicensingFee[feeType].vendor;
        // m = FinanceInput.fin.vehicleLicensingFee[service].vendor;
        break;
      default:
        throw new Error(`Invalid Invoice Type provided, [${invoiceType}] is invalid or not yet implemented.`);
    }
    // baseFee.amount = round(methodFn(m, item), FinanceInput.fin.rdigit);
    baseFee.amount = round(methodFn(method, item), FinanceInput.fin.rdigit);
    // baseFee.name = FinanceInput.fin.specialFee[baseFee.type][service][stateProv].name; // FinanceInput.fin.vehicleLicensingFee[service].name;
    baseFee.name = FinanceInput.fin.specialFeeNN[service].data.vehicleLicensingFee[feeType].name;;

    // baseFee.isTaxable = m.isTaxable;
    baseFee.isTaxable = method.isTaxable;
    return baseFee;
    // return round(methodFn(baseAmount, m), FinanceInput.fin.rdigit);
  } catch (error) {
    // tslint:disable-next-line:max-line-length
    logger.error(`Error updating the Vehicle License Fee. fee calculation! Check financial input data against stateProv: ${stateProv}`, error);
    throw error;
  }
};
export const calculateGapInsurance = (stateProv: string, item: InvoiceItem, service: ServiceType, invoiceType: TransactionType, paymentMethod: PaymentMethod) => {
  try {
    const baseFee = new BaseFee();
    baseFee.type = 'gapInsurance'; // 'vehLicLicensingFee';
    baseFee.name = 'Gap Insurance';
    let m: IMethodInfo;
    let method: IMethodInfo;
    let feeType = FinanceInput.fin.specialFeeNN[service].jurisdiction[item.taxJuris].gapInsurance;

    switch (invoiceType) {
      case TransactionType.credit:
        method = FinanceInput.fin.specialFeeNN[service].data.gapInsurance[feeType].customer;
        break;
      case TransactionType.debit:
        method = FinanceInput.fin.specialFeeNN[service].data.gapInsurance[feeType].vendor;
        break;
      default:
        throw new Error(`Invalid Invoice Type provided, [${invoiceType}] is invalid or not yet implemented.`);
    }
    baseFee.amount = round(methodFn(method, item), FinanceInput.fin.rdigit);
    baseFee.name = FinanceInput.fin.specialFeeNN[service].data.gapInsurance[feeType].name;;

    baseFee.isTaxable = method.isTaxable;
    return baseFee;
  } catch (error) {
    logger.error(`Error updating the Vehicle License Fee. fee calculation! Check financial input data against stateProv: ${stateProv}`, error);
    throw error;
  }
};
export const calculateGapInsuranceComb = (stateProv: string, item: InvoiceItem, service: ServiceType, invoiceType: TransactionType, paymentMethod: PaymentMethod) => {
  try {
    const baseFee = new BaseFee();
    baseFee.type = 'gapInsuranceComb'; // 'vehLicLicensingFee';
    baseFee.name = 'Gap Insurance';
    let m: IMethodInfo;
    let method: IMethodInfo;
    let feeType = FinanceInput.fin.specialFeeNN[service].jurisdiction[item.taxJuris].gapInsuranceComb;

    switch (invoiceType) {
      case TransactionType.credit:
        method = FinanceInput.fin.specialFeeNN[service].data.gapInsuranceComb[feeType].customer;
        break;
      case TransactionType.debit:
        method = FinanceInput.fin.specialFeeNN[service].data.gapInsuranceComb[feeType].vendor;
        break;
      default:
        throw new Error(`Invalid Invoice Type provided, [${invoiceType}] is invalid or not yet implemented.`);
    }
    baseFee.amount = round(methodFn(method, item), FinanceInput.fin.rdigit);
    baseFee.name = FinanceInput.fin.specialFeeNN[service].data.gapInsuranceComb[feeType].name;;

    baseFee.isTaxable = method.isTaxable;
    return baseFee;
  } catch (error) {
    logger.error(`Error updating the Vehicle License Fee. fee calculation! Check financial input data against stateProv: ${stateProv}`, error);
    throw error;
  }
};
export const calculateWalkAwayInsurance = (stateProv: string, item: InvoiceItem, service: ServiceType, invoiceType: TransactionType, paymentMethod: PaymentMethod) => {
  try {
    const baseFee = new BaseFee();
    baseFee.type = 'walkAwayInsurance'; // 'vehLicLicensingFee';
    baseFee.name = 'Walk Away Insurance';
    let m: IMethodInfo;
    let method: IMethodInfo;
    let feeType = FinanceInput.fin.specialFeeNN[service].jurisdiction[item.taxJuris].walkAwayInsurance;

    switch (invoiceType) {
      case TransactionType.credit:
        method = FinanceInput.fin.specialFeeNN[service].data.walkAwayInsurance[feeType].customer;
        break;
      case TransactionType.debit:
        method = FinanceInput.fin.specialFeeNN[service].data.walkAwayInsurance[feeType].vendor;
        break;
      default:
        throw new Error(`Invalid Invoice Type provided, [${invoiceType}] is invalid or not yet implemented.`);
    }
    baseFee.amount = round(methodFn(method, item), FinanceInput.fin.rdigit);
    baseFee.name = FinanceInput.fin.specialFeeNN[service].data.walkAwayInsurance[feeType].name;;

    baseFee.isTaxable = method.isTaxable;
    return baseFee;
  } catch (error) {
    logger.error(`Error updating the Vehicle License Fee. fee calculation! Check financial input data against stateProv: ${stateProv}`, error);
    throw error;
  }
};
export const calculateWalkAwayInsuranceComb = (stateProv: string, item: InvoiceItem, service: ServiceType, invoiceType: TransactionType, paymentMethod: PaymentMethod) => {
  try {
    const baseFee = new BaseFee();
    baseFee.type = 'walkAwayInsurance'; // 'vehLicLicensingFee';
    baseFee.name = 'Walk Away Insurance';
    let m: IMethodInfo;
    let method: IMethodInfo;
    let feeType = FinanceInput.fin.specialFeeNN[service].jurisdiction[item.taxJuris].walkAwayInsuranceComb;

    switch (invoiceType) {
      case TransactionType.credit:
        method = FinanceInput.fin.specialFeeNN[service].data.walkAwayInsuranceComb[feeType].customer;
        break;
      case TransactionType.debit:
        method = FinanceInput.fin.specialFeeNN[service].data.walkAwayInsuranceComb[feeType].vendor;
        break;
      default:
        throw new Error(`Invalid Invoice Type provided, [${invoiceType}] is invalid or not yet implemented.`);
    }
    baseFee.amount = round(methodFn(method, item), FinanceInput.fin.rdigit);
    baseFee.name = FinanceInput.fin.specialFeeNN[service].data.walkAwayInsuranceComb[feeType].name;;

    baseFee.isTaxable = method.isTaxable;
    return baseFee;
  } catch (error) {
    logger.error(`Error updating the Vehicle License Fee. fee calculation! Check financial input data against stateProv: ${stateProv}`, error);
    throw error;
  }
};
export const calculateWaiverInsurance = (stateProv: string, item: InvoiceItem, service: ServiceType, invoiceType: TransactionType, paymentMethod: PaymentMethod) => {
  try {
    const baseFee = new BaseFee();
    baseFee.type = 'waiverInsurance'; // 'vehLicLicensingFee';
    baseFee.name = 'Waiver Insurance';
    let m: IMethodInfo;
    let method: IMethodInfo;
    let feeType = FinanceInput.fin.specialFeeNN[service].jurisdiction[item.taxJuris].waiverInsurance;

    switch (invoiceType) {
      case TransactionType.credit:
        method = FinanceInput.fin.specialFeeNN[service].data.waiverInsurance[feeType].customer;
        break;
      case TransactionType.debit:
        method = FinanceInput.fin.specialFeeNN[service].data.waiverInsurance[feeType].vendor;
        break;
      default:
        throw new Error(`Invalid Invoice Type provided, [${invoiceType}] is invalid or not yet implemented.`);
    }
    baseFee.amount = round(methodFn(method, item), FinanceInput.fin.rdigit);
    baseFee.name = FinanceInput.fin.specialFeeNN[service].data.waiverInsurance[feeType].name;;

    baseFee.isTaxable = method.isTaxable;
    return baseFee;
  } catch (error) {
    logger.error(`Error updating the Vehicle License Fee. fee calculation! Check financial input data against stateProv: ${stateProv}`, error);
    throw error;
  }
};
export const calculateWaiverInsuranceComb = (stateProv: string, item: InvoiceItem, service: ServiceType, invoiceType: TransactionType, paymentMethod: PaymentMethod) => {
  try {
    const baseFee = new BaseFee();
    baseFee.type = 'waiverInsurance'; // 'vehLicLicensingFee';
    baseFee.name = 'Waiver Insurance';
    let m: IMethodInfo;
    let method: IMethodInfo;
    let feeType = FinanceInput.fin.specialFeeNN[service].jurisdiction[item.taxJuris].waiverInsuranceComb;

    switch (invoiceType) {
      case TransactionType.credit:
        method = FinanceInput.fin.specialFeeNN[service].data.waiverInsuranceComb[feeType].customer;
        break;
      case TransactionType.debit:
        method = FinanceInput.fin.specialFeeNN[service].data.waiverInsuranceComb[feeType].vendor;
        break;
      default:
        throw new Error(`Invalid Invoice Type provided, [${invoiceType}] is invalid or not yet implemented.`);
    }
    baseFee.amount = round(methodFn(method, item), FinanceInput.fin.rdigit);
    baseFee.name = FinanceInput.fin.specialFeeNN[service].data.waiverInsuranceComb[feeType].name;;

    baseFee.isTaxable = method.isTaxable;
    return baseFee;
  } catch (error) {
    logger.error(`Error updating the Vehicle License Fee. fee calculation! Check financial input data against stateProv: ${stateProv}`, error);
    throw error;
  }
};
