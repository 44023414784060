import { BaseHttpService } from '@trent/services/base-http.service';
import { FirestoreService } from '@trent/services/firestore.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { instanceToPlain } from 'class-transformer';
import { tap, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { PagingObesrvable } from '../models/observable-util/paging-obesrvable';
import { Paging } from '../models/observable-util/paging';
import { Observable } from 'rxjs';
import { SparePart } from '@trent/models/spare-part/spare-part';
import { ISparePartsData } from '@trent/models/spare-part/i-spare-parts-data';
import { SparePartParam, sparePartSearchServerQuery } from '@trent/models/spare-part/spare-part-param';

/**
 * @author - MKN
 * Http service for Backend api call
 */

@Injectable()
export class SparePartsService extends BaseHttpService {


  constructor(store: Store, private db: FirestoreService, private http: HttpClient) {
    super(store);
    this.apiName = 'api';
  }

  /**
   * create API
   */
  public create(sparePartsData: ISparePartsData) {

    // Prepare the post data
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.token });
    return this.http.post<{ id: string | number, data: SparePart }>(this.getApiUrl('/spare-part/create'),
      // Server validation before creating the entry.
      {
        data: sparePartsData
      },
      { headers: headers })
      .pipe(
        tap(res => console.log('response: ', res))
      );
  }
 
  /**
   * Update API
   */
  update(sparePartsData: ISparePartsData) {
 
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.token });
    return this.http.post<{ id: string | number, data: SparePart }>(this.getApiUrl('/spare-part/update'),
      // Server validation before creating the entry.
      {
        data : sparePartsData
      },
      { headers: headers })
      .pipe(
        tap(res => console.log('response: ', res))
      );
  }
 
  /**
   * Get Spare part by Id 
   * Call from State(Store)
   */
  public getSparePartById(id: string | number) {
    return this.db.docWithInjectedId$<SparePart>(
      // `SparePart/${id}`
      `${SparePart.collectionName}/${id}`, id);
  }
  
   /**
   * Getting all spare parts Paging Observable 
   * Call from State(Store)
   */
  public getAllSpareParts_PagingObservable() {
    const p: PagingObesrvable<SparePart, SparePartParam> =
      new PagingObesrvable<SparePart, SparePartParam>(this.db, this.getAllSpareParts_batch);
    return p;
  }

  /**
   * Getting all spare parts from db
   */
  private getAllSpareParts_batch(p: Paging, o: SparePartParam): Observable<{ [key: string]: SparePart }> {
    console.log('SparePart Server called with', p.offset, p.size);
    const col = !o.sparePartIdForHistory ? SparePart.collectionName : `${SparePart.collectionName}/${o.sparePartIdForHistory}/rev`;
    return this.db
      .colWithIdsInjectedNew$<SparePart>(col,
        ref => sparePartSearchServerQuery(ref, o, p))
      .pipe(
        tap(arr => {
          if (arr == null || arr.length === 0) {
            console.log('All data is received, Paging is FULL');
            p.full = true;
          } else {
            p.lastDoc = arr[arr.length - 1];
          }
        }),
        map(arr => {
          return arr.reduce((acc, cur) => {
            const id = cur.id;
            const data = cur;
            return { ...acc, [id]: data };
          }, {});
        })
      );
  }
}