import { Injectable } from '@angular/core';
import { Subscription, Subject } from 'rxjs';

// https://stackoverflow.com/questions/49128377/angular-2-emit-event-to-application-root-component

export interface IEventListener {
  ignore(): void;
}

interface IEventBase {
  name: string;
  emit(data: any): void;
  listen(next: (data: any) => void): IEventListener;
}

interface IEvent<T> extends IEventBase {
  emit(data: any): void;
  listen(next: (data: any) => void): IEventListener;
}

class EventListener implements IEventListener {
  constructor(private _subscription: Subscription) { }
  public ignore(): void {
    this._subscription.unsubscribe();
  }
}

class Event<T> implements IEvent<T> {
  private _subject: Subject<T>;
  constructor(public name: string) {
    this._subject = new Subject<T>();
  }
  public emit(data: T): void {
    this._subject.next(data);
  }
  public listen(next: (value: T) => void): IEventListener {
    return new EventListener(this._subject.subscribe(next));
  }
}
@Injectable({ providedIn: 'root' })
export class EventService {
  constructor() {
    this._events = {};
  }
  private _events: { [name: string]: IEventBase };



  /** shell menu, back button click button */
  public get menuBackBtnClickCust() { return 'menuBackBtnClickCust'; }
  /** shell menu, save click */
  public get menuSaveBtnClick() { return 'menuSaveBtnClick'; }
  /** shell menu cancel click */
  public get menuCancelClick() { return 'menuCancelClick'; }
  public get menuShowSaveCancel() { return 'menuShowSaveCancel'; }
  public get menuFireSaveCancel() { return 'menuFireSaveCancel'; }
  public get menuShowEdit() { return 'menuShowEdit'; }
  public get menuFireEdit() { return 'menuFireEdit'; }
  public get menuShowNextCancel() { return 'menuShowNextCancel'; }
  public get menuFireNextCancel() { return 'menuFireNextCancel'; }
  public get menuShowSearch() { return 'menuShowSearch'; }
  public get menuFireSearch() { return 'menuFireSearch'; }
  public get menuShowGPSOn() { return 'menuShowGPSOn'; }

  /** Show edit html menus on the phages where PageHtml is defined. */
  public get menuShowHtmlEdit() { return 'menuShowHtmlEdit'; }

  /** Restore Router configuration to default */
  public get restoreRouterConfig() { return 'restoreRouterConfig'; }


  public get showHidePageBackBtn() { return 'showHidePageBackBtn'; }

  // alert bar
  public get showAlertBar() { return 'showAlertBar'; }
  public get hideAlertBar() { return 'hideAlertBar'; }


  public get app_setTitle() { return 'app_setTitle'; }
  public get app_setSubTitle() { return 'app_setSubTitle'; }

  public get app_showBusy() { return 'app_showBusy'; }
  public get app_hideBusy() { return 'app_hideBusy'; }

  //#region Event Names
  public get loadPageOptions() { return 'loadPageOptions'; }

  public get loadTaskNumbers() { return 'loadTaskNumbers'; }

  /** Back Button event on the menu */

  /** Left -> Right slider panel event */
  public get setActivePan() { return 'setActivePan'; }


  // Event Names as a simple utility to keep track of the event names used in the application.
  public get loadGooglePlaces() { return 'loadGooglePlaces'; }

  //
  public get compDetail_dismissModal() { return 'compDetail_dismissModal'; }

  public get company_Delete() { return 'company_Delete'; }
  public get product_Delete() { return 'product_Delete'; }

  public get address_saved() { return 'address_saved'; }
  public get address_canceled() { return 'address_canceled'; }

  /** id of the link or html control that has the data-* attribute is defined, should be passed to this event bus
   * to ensure proper menu is shown.  */
  public get defaultScriptDirective_menuClick() { return 'defaultScriptDirective_menuClick'; }

  public get mapClick() { return 'mapClick'; }
  public get mapDblClick() { return 'mapDblClick'; }
  public get mapLabelActionById() { return 'mapLabelActionById'; }
  public get mapClusterAction() { return 'mapClusterAction'; }
  public get showOnMapById() { return 'showOnMapById'; }
  public get showMapLabels() { return 'showMapLabels'; }
  public get clearFilter() { return 'clearFilter'; }

  /** TRIP Transaction Update */
  public get tripTransactionUpdated() { return 'tripTransactionUpdated'; }

  /** TRIP Update */
  public get tripUpdated() { return 'tripUpdated'; }

  /** error occured */

  public get errorOccured() { return 'errorOccured'; }

  public get boundingBox() { return 'boundingBox'; }
  public get clusterClick() { return 'clusterClick'; }
  public get routeSummary() { return 'routeSummary'; }


  // Events for ck-inline
  public get ckInlineReset() { return 'ckInlineReset'; }
  public get fireCkContentChg() { return 'fireCkContentChg'; }

  // changeDetect
  public get changeDetect() { return 'changeDetect'; }
  // bidTermsUpdated
  public get bidTermsUpdated() { return 'bidTermsUpdated'; }
  // picturessUpdated
  public get picturesUpdated() { return 'picturesUpdated'; }
  // adjInvoiceItemInput
  public get adjInvoiceItemInput() { return 'adjInvoiceItemInput'; }

  //#endregion

  public register<T>(eventName: string): Event<T> {
    let event = this._events[eventName];
    if (typeof event === 'undefined') {
      event = this._events[eventName] = new Event<T>(eventName);
    }
    return event as Event<T>;
  }
  public listen<T>(eventName: string, next: (value: T) => void): IEventListener {
    return this.register<T>(eventName).listen(next);
  }
  public emit<T>(eventName: string, data: T): void {
    return this.register<T>(eventName).emit(data);
  }
}
