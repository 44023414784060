import { Hook } from './hook';
import { TripTransaction } from './trip-transaction';
import { TripActivityType } from './trip-activity-type';
import { Pickup } from './pickup';
import { Delivery } from './delivery';
import { Drop } from './drop';
import { WayPoint } from './wayPoint';


export const parseTripTransaction = (obj: any): TripTransaction => {
  if (obj == null) { return null; }

  const r = <TripTransaction>obj;
  switch (r.activity) {
    case TripActivityType.hookUp:
      return Hook.parse(r);
    case TripActivityType.pickUp:
      return Pickup.parse(r);
    case TripActivityType.delivery:
      return Delivery.parse(r);
    case TripActivityType.drop:
      return Drop.parse(r);
    case TripActivityType.wayPoint:
      return WayPoint.parse(r);
    default:
      return TripTransaction.parse(r);
    // throw new Error(`Invalid Trip Activity in the Trip. Producty Type: ${r.activity} is invalid or not yet implemented.`);
  }
};
export const cloneTripTransaction = (obj: any): TripTransaction => {
  if (obj == null) { return null; }

  const r = parseTripTransaction(obj);
  return r.clone();
};

export const parseTripTransactionArray = (obj: any[]): TripTransaction[] => {
  const r = !!obj ? obj.map(o => <TripTransaction>parseTripTransaction(o)) : null;
  return r;
};
export const cloneTripTransactionArray = (obj: any[]): TripTransaction[] => {
  const r = !!obj ? obj.map(o => <TripTransaction>cloneTripTransaction(o)) : null;
  return r;
};

