import { CmsService } from '@trent/services/cms.service';
import { DialogService } from '../../services/dialog/dialog.service';
import { AuthState } from './../../store/auth-store/auth.state';
import { RouterState, Navigate, SetTitle } from '../../store/root-store/router.state';
import { MessageInfo, readErrorMessage } from '../error-handling/message-info';
import { Observable, Subscription } from 'rxjs';
import { EventService, IEventListener } from '../../services/event.service';
import { AlertOptions } from '../utility/ionic-interface';   // '@ionic/core';
import { keysIn, concat, isEqual } from 'lodash';
import { IValidationMsg } from '../error-handling/validation-info';
import { Store } from '@ngxs/store';
import { ShowPopover } from '../../store/notification-store';
// import { AngularFirestoreDocument } from '@angular/fire/firestore';
import * as v from '../error-handling/message-info';
import { IDefaultCompanyData } from '../user/company-user-profile';
import { UserProfile } from '../user/user-profile';
import { Router, ActivatedRoute } from '@angular/router';
import { SiteData } from '../site-data';
import { BaseModel } from '../base';
import { cleanListeners } from '../utility/helper';
import { instanceToPlain } from 'class-transformer';
import { logger } from '../log/logger';
import { UtilityService } from '@trent/services/utility.service';
import { inject } from '@angular/core';



export class BasePage<T> {
  us = inject(UtilityService);
  // page: PageHtml; //  PageHtml; use it in the component 
  showEmpty = null;
  serverReqInProcess = false;

  _dataNotFound = false;
  get dataNotFound() { return this._dataNotFound; }
  set dataNotFound(v1) {
    this._dataNotFound = v1;
    this.hideLoading();
  }

  constructor(public store: Store, public dialogService: DialogService,
    public es?: EventService, public cms?: CmsService) {

    // this.canNavigateBack$ = this.store.select(RouterState.canGoBack);
    // this.mSubscription.push(
    //   this.canNavigateBack$.subscribe(flag => this._canNavigateBack = flag)
    // );

    // subscribe to the default company.
    this.mSubscription.push(
      this.defaultCompany$.subscribe(c => {
        if (!!c && !!c.cid) {
          // logger.log('Default Company is: ', c.cid);
          this.defaultCompany = c;
          this.defaultCompanyId = c.cid;
        }
      })
    );
  }

  private _title: string;

  public get title(): string { return this._title; }
  public set title(t: string) {
    this._title = t;
    // show hide back button
    this.es.emit<string>(this.es.app_setTitle, this._title);
  }
  private _subTitle: string;

  public get subTitle(): string { return this._subTitle; }
  public set subTitle(t: string) {
    this._subTitle = t;
    // show hide back button
    this.es.emit<string>(this.es.app_setSubTitle, this._subTitle);
  }

  /** Auth Message to be shown */
  // public authMsg: string;
  // public hasAuthError = false;


  // private canNavigateBack$: Observable<boolean>;
  private _isGoBackCust = false;
  get isGoBackCust() { return this._isGoBackCust; }
  set isGoBackCust(f) {
    this._isGoBackCust = f;
    this.es.emit<boolean>(this.es.showHidePageBackBtn, f);
  }


  /** Get an object to be passed to router query param, rb => record back button navigation. */
  get rb() { return { queryParams: { rb: true } }; }


  /** Event Listener container. Only need to store so that ngDestroy can remove the linkages of events. */
  public eventListeners: IEventListener[] = [];

  m$: Observable<T>;
  mSubscription: Subscription[] = [];

  // mref: AngularFirestoreDocument<T>;

  /** Model that contain data for the page */
  m: T; //  = new T();

  /** Read only model use to restore the data if save operation is cancelled. */
  em: T;

  defaultCompany$ = this.store.select(AuthState.defaultCompany);
  defaultCompany: IDefaultCompanyData;
  defaultCompanyId: string | number;

  user$ = this.store.select(AuthState.user);
  get user(): UserProfile { return SiteData.user; }

  /** Validator Set. */
  public validatorSet = false;

  /** Messages related to the user tasks as they have to shown to the user. */
  public taskMsg: string[] = [];

  get showTaskMsg(): boolean { return this.taskMsg.length > 0; }

  /**
* Before data is requested, set showEmpty = null.
* Empty data is received from server:  dispatch this function to set the showEmpty=true.
* if during the delay, data is received from the server, it will reset it to false. It will then be simply ignored by this call.
* @param delay delay to wait before setting up the flag to show empty = true
*/
  setShowEmpty(delay = 3000) {
    setTimeout(() => {
      if (this.showEmpty == null) {
        this.showEmpty = true;
      }
    }, delay);
  }

  setTitle(t: string) { this.store.dispatch(new SetTitle(t)); }

  /** Fire show/hide flag to manage the display of search button on mobile top menu.
*/
  showHideSearch(show: boolean) {
    this.es.emit<boolean>(this.es.menuShowSearch, show);
  }
  /** Clean up all the listeners associated with the component
   * @param | clearCustomGoBack: boolean
   * @param | clearSearch: boolean
   */
  public cleanListeners(clearCustomGoBack = true, clearSearch = true) {
    if (clearCustomGoBack) {
      this.isGoBackCust = false;
    }
    if (clearSearch) {
      this.showHideSearch(false);
    }
    cleanListeners(this.eventListeners, this.mSubscription);
    this.subTitle = null;
  }

  /** Used by drop downs to match value with NgModel
 * <select [compareWith]="idComparer" [(ngModel)]="em.country" .......
*/
  public idComparer(c1, c2) {
    if (c1 === undefined && c2 === undefined) { return true; }
    if (c1 === null && c2 === null) { return true; }
    if (c1 && c1.id && c2 && c2.id) { return c1.id === c2.id; }
    return false;
  }

  /** concat all the errors from validation in a simple string list */
  getErrorList(msg: IValidationMsg) {
    let a: string[] = [];
    keysIn(msg).forEach((val) => {
      a = concat(a, msg[val]);
    });
    return a;
  }


  // #region Manage Notifications

  public showLoading(delay = 300) {
    if (this.us.isPlatformBrowser) {
      this.serverReqInProcess = true;
      this.es.emit(this.es.app_showBusy, delay);
    }
  }
  public hideLoading() {
    if (this.us.isPlatformBrowser) {
      this.serverReqInProcess = false;
      this.es.emit(this.es.app_hideBusy, null);
    }
  }


  public showPopover(msgInfo: MessageInfo, zIndex?: number, fn?: () => any) {
    if (this.us.isPlatformBrowser) {
      this.dialogService.openSnackBar(msgInfo);
      // this.store.dispatch(new ShowPopover({ messageInfo: msgInfo, zIndex: zIndex, callBack: fn }));
    }
  }
  public hidePopover() {
    if (this.us.isPlatformBrowser) {
      this.store.dispatch(new ShowPopover(null));
    }
  }


  public getAlertOption(): AlertOptions {
    return {
      header: 'Alert!',
      subHeader: 'Last Operation failed!',
      message: 'An unknown error has happened.'
      // buttons: ['OK']
    };
  }
  public async showAlert(msgInfo: MessageInfo) {
    if (this.us.isPlatformBrowser) {
      this.dialogService.alert(msgInfo);
    }
  }

  /** Hide the displayed Alert. */
  public async hideAlert() {
    if (this.us.isPlatformBrowser) {
      this.dialogService.closeAlert();
      // this.store.dispatch(new ShowAlert(null));
    }
  }


  public navigate(url: string) {
    this.store.dispatch(new Navigate({ url }));
  }

  /**
   * Go back to the history.
   * @param router to be used for changing the url from history.
   * Note: In past, it was used to be optional and router in the router State was used.
   * that was throwing a warning. So now, routing is handled right in the base class and
   * state is just used to update/keep the history.
   */
  public navigateBack(router: Router, toHomeIfFailed = false) {
    const url = this.store.selectSnapshot(RouterState.navigateBackUrl); //  =>
    if (!!url) {
      router.navigateByUrl(url);
    } else if (toHomeIfFailed) {
      router.navigateByUrl('/');
    }
  }

  /**
   * Utility function to manage the subscription. It also manage
   * show/hide of busy signal after a delay as well as run the
   * customize function upon success and error as well
   * @param o  : Observable
   * @param fnSuccess  : run after subscription receive the data
   * @param delay  : delay in showing the busy signal
   * @param fnFailure : custom method , if error is thrown.
   */
  public subscribe<Z>(o: Observable<Z>, fnSuccess: (z: Z) => void, delay?: number, fnFailure?: (err) => void, alertNoData = false) {
    const d = (isNaN(+delay)) ? 300 : delay;
    this.showLoading(d);
    const s = o.subscribe({
      next: (c) => {
        this.hideLoading();
        this.hideAlert();
        this.hidePopover();
        if (c != null) {
          this.dataNotFound = false;
        } else if (alertNoData) {
          // data no longer exists.
          this.dataNotFound = true;
          const msg = v.fromDataNotFound('');
          msg.messageInfo.delay = 5000;
          this.showAlert(msg.messageInfo);
        }
        fnSuccess(c);
      },
      error: err => {
        logger.log('error from subscribe: ', err);
        this.hideLoading();
        if (typeof (fnFailure) === 'function') {
          fnFailure(err);
        } else {
          this.showAlert(readErrorMessage(err));
        }
      }
    });
    this.mSubscription.push(s);
    return s;
  }

  /** Redirects to a correct version if required.
   * Example: user is on Released branch, must go to route.
   * Call before editing the record.
   */
  public redirectToCorrectVersion(x: BaseModel, router: Router, aroute: ActivatedRoute) {
    if (!!x) {
      if (x.isFrozenReleaseBranch) {
        this.showAlert(v.fromRedirectInfo('This version of record is a frozen for reference. You ' +
          'are being redirected shortly to the editable version of this record! '));
        setTimeout(() => {
          this.hideAlert();
          // this.router.navigate(['./', this.m.rootId]);
          router.navigate([`../${x.rootId}`], { relativeTo: aroute });
        }, 2000);
        return true;
      }
      if (!x.isDraft && x.hasDraft) {
        this.showAlert(v.fromRedirectInfo('This version of record is a released version. You ' +
          'are being redirected shortly to the editable version of this record! '));
        setTimeout(() => {
          this.hideAlert();
          router.navigate([`../`, `${x.draftIdUrl}`], { relativeTo: aroute });
        }, 2000);
        return true;
      }
    }
    return false;
  }

  /** Method to detect if update is required after receiving the data from subscription subscript  */
  public isUpdateReqd(newObj, currObj) {
    if (newObj == null) { return false; }
    return !isEqual(instanceToPlain(newObj), instanceToPlain(currObj));
    // return !areEqualByVal(newObj, currObj);
  }

  /** @deprecated Use SeoService load pageHtml instead.*/
  // async loadPageHtml(id: string) {
  //   const pageData = await promiseWraper(this.cms.getPageHtmlById_comp(id));
  //   if (pageData.success && !!pageData.data) {
  //     this.page = pageData.data;
  //     return;
  //   }

  //   // Only get data from store if it is a client.
  //   if (this.cms.isPlatformBrowser) {
  //     this.store.dispatch(new CmsPageRequested({ id }));
  //     this.mSubscription.push(
  //       this.store
  //         .select(CmsState.selectPageById)
  //         .pipe(map(pageFn => pageFn(id)))
  //         .subscribe(p => this.page = p)
  //     );
  //   }
  // }

}
// #endregion
