import { BaseHttpService } from '@trent/services/base-http.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { FirestoreService } from '@trent/services/firestore.service';
import { instanceToPlain } from 'class-transformer';
import { tap, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
// import * as geofirex from 'geofirex';
import { RentOptionParam, rentOptionSearchServerQuery } from '../models/rental/rent-option-param';
import { PagingObesrvable } from '../models/observable-util/paging-obesrvable';
import { Paging } from '../models/observable-util/paging';
import { Observable } from 'rxjs';
import { TripBase } from '../models/trip/trip-base';
// import { Utl } from '../../apps/functions/src/model/utl';   // to be removed once functions are back running June 12th, 2019
import { sanitizeDateIPoint } from '@trent/models/utility'; // to be removed once functions are back running June 12th, 2019
// import * as admin from 'firebase-admin'; // to be removed once functions are back running June 12th, 2019
// import { AuthService } from './auth/auth.service';
import { RentalProductBase } from '@trent/models/rental/rental-product-base';
import { query, where } from '@angular/fire/firestore';

@Injectable()
export class RentalService extends BaseHttpService {


  constructor(store: Store, private db: FirestoreService, private http: HttpClient) {
    super(store);
    this.apiName = 'api';
  }
  public requestApproval(cid: string | number, rid: string | number) {
    const headers = this.addBearerToken();
    return this.http.post<RentalProductBase>(this.getApiUrl('/rent-options/requestApproval'),
      {
        cid: cid,
        rid: rid
        // company: instanceToPlain(c),
        // option: option
      },
      { headers: headers })
      .pipe(
        tap(r => console.log('response: ', r)),
      );
  }

  /** Send request to approve the record. */
  public admin_approveRentalProduct(rid: string | number) { // :  CompanyFleet) {
    // Prepare the post data
    const headers = this.addBearerToken();
    return this.http.post<{ rid: string | number, rentalProduct: RentalProductBase }>(this.getApiUrl('/rent-options/adminApprove'),
      {
        rid: rid
      },
      { headers: headers })
      .pipe(
        tap(r => console.log('response: ', r)),
      );
  }
  public create(r: RentalProductBase, cid: string | number, newDocId: string) {
    // Prepare the post data
    console.log('rentalProduct at service', r);

    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.token });
    return this.http.post<{ id: string | number, rentalProduct: RentalProductBase }>(this.getApiUrl('/rent-options/create'),
      // Server validation before creating the entry.
      {
        rentalProduct: instanceToPlain(r),
        cid,
        newDocId
      },
      { headers: headers })
      .pipe(
        tap(res => console.log('response: ', res))
      );
  }

  public update(rid: string | number, r: RentalProductBase) {
    // Prepare the post data
    console.log('at service call', r);

    const headers = this.addBearerToken();

    // Server validation before creating the entry.
    return this.http.post<{ id: string | number, rentalProduct: RentalProductBase }>(this.getApiUrl('/rent-options/update'),
      {
        rentalProduct: instanceToPlain(r),
        rid: rid
      },
      { headers: headers })
      .pipe(
        tap(res => console.log('response: ', res))
      );
  }
  getNewDocId() {
    return this.db.docId(RentalProductBase.collectionName);
  }
  public getAllRentalProducts() {
    return this.db.colWithIdsInjected$<RentalProductBase>('rent-options');
    // .pipe(map(x => {
    //   if (!!x && x.length > 0) {
    //     for (let i = 0; i < x.length; i++) {
    //       const ele = x[i];
    //     }
    //   }
    //   return x;
    // }));
  }

  public getRentalProductById(id: string | number) {
    return this.db.docWithInjectedId$<RentalProductBase>(
      // `rent-options/${id}`
      `${RentalProductBase.collectionName}/${id}`, id);
  }
  public getRentalProductsByProdId(pid: string | number) {
    return this.db.colWithIdsInjected$<RentalProductBase>('rent-options', ref =>
      query(ref, where('productId', '==', `${pid}`)));

    // .where('productType', '==', pType)

  }
  // public withinRadRentalProducts(pType: ProductType, lat: number, long: number, radius: number, field: string) {
  //   const query = this.db.colWithIdsInjected$('rent-options', ref => ref.where('productType', '==', pType));
  //   const geoQuery = this.geo.query(query);
  //   // const collection = this.geo.collection('rent-options', ref => ref.where('productType', '==', pType));
  //   const center = this.geo.point(lat, long);
  //   const t = geoQuery.within(center, radius, field);
  //   // const t = collection.within(center, radius, field);
  //   return t;
  // }
  public getRentalProductsByCompId(cid: string | number) {
    return this.db.colWithIdsInjected$<RentalProductBase>('rent-options',
      ref => query(ref, where(`vendorCompSummary.cid`, '==', `${cid}`)));
  }
  public getAllRentalProducts1_PagingObservable() {
    const p: PagingObesrvable<RentalProductBase, RentOptionParam> =
      new PagingObesrvable<RentalProductBase, RentOptionParam>(this.db, this.getAllRentalProducts1_batch);
    return p;
  }
  private getAllRentalProducts1_batch(p: Paging, param: RentOptionParam): Observable<{ [key: string]: RentalProductBase }> {

    console.log('Product Server called with', p.offset, p.size);
    console.log('RentOptionParam', param);
    let r: Observable<RentalProductBase[]>;

    if (false) {

      // r = this.db.colGeoQueryWithIdsInjected$(RentalProductBase.collectionName,
      //  ref => rentOptionSearchServerQuery(ref, param, p), { center: param.center, radius: param.radius, field: 'startLoc' });

    } else {
      r = this.db.colWithIdsInjectedNew$<RentalProductBase>(RentalProductBase.collectionName,
        ref => rentOptionSearchServerQuery(ref, param, p));
    }

    // // if radius is less 99 you geofirex to get documents by radius if radius is greater than 99 use avialEndDate to search documents
    // if (!param.isGeoServerSearchReq) {
    //   r = this.db
    //     .colWithIdsInjected$<RentalProductBase>(RentalProductBase.collectionName,
    //       ref => rentOptionSearchServerQuery(ref, param, p));
    // } else if (!!param.isGeoServerSearchReq) {
    //   const geofx = geofirex.init(firebase);
    //   const collection = geofx.collection(RentalProductBase.collectionName,
    //     ref => rentOptionSearchServerQuery(ref, o, p)
    //   );
    //   const center = geofx.point((<RentOptionParam>o).startLat, (<RentOptionParam>o).startLong);
    //   if (o.incRadius == null) {
    //     o.incRadius = 0;
    //   }
    //   const radius = o.radius + o.incRadius;
    //   console.log('radius', radius, 'o.incRadius', o.incRadius);

    //   const field = 'startLoc'; // startLoc is geoPoint in database
    //   r = collection.within(center, radius, field);
    // }



    return r
      .pipe(
        tap(arr => {
          if (arr == null || arr.length === 0) {
            console.log('All data is recevied, Paging is FULL');
            p.full = true;
          } else {
            p.lastDoc = arr[arr.length - 1];
          }
        }),
        map(o => {
          const arr = sanitizeDateIPoint(o);
          console.log('Got here 148', arr);
          return arr.reduce((acc, cur) => {
            const id = cur.id;
            const data = cur;
            return { ...acc, [id]: data };
          }, {});
        })
      );
  }
  public getRentalProductsByProdId_PagingObservable() {
    const p: PagingObesrvable<RentalProductBase, RentOptionParam> =
      new PagingObesrvable<RentalProductBase, RentOptionParam>(this.db, this.getRentalProductsByProdId_batch);
    return p;
  }
  private getRentalProductsByProdId_batch(p: Paging, o: RentOptionParam): Observable<{ [key: string]: RentalProductBase }> {
    console.log('Product Server called with', p.offset, p.size);
    return this.db
      .colWithIdsInjectedNew$<RentalProductBase>(RentalProductBase.collectionName,
        ref => rentOptionSearchServerQuery(ref, o, p))
      .pipe(
        tap(arr => {
          if (arr == null || arr.length === 0) {
            console.log('All data is recevied, Paging is FULL');
            p.full = true;
          } else {
            p.lastDoc = arr[arr.length - 1];
          }
        }),
        map(arr => {
          // console.log('Got here 137', arr);
          return arr.reduce((acc, cur) => {
            const id = cur.id;
            const data = cur;
            return { ...acc, [id]: data };
          }, {});
        })
      );
  }
  // #region cron jobs
  // public setObsoleteStatus(cJob: CronJob) {
  //   // Prepare the post data
  //   const headers = this.addBearerToken();

  //   // Server validation before creating the entry.
  //   return this.http.post<{ cJob: CronJob }>(this.getApiUrl('/rent-options/setObsoleteStatus'),
  //     {
  //       cJob: cJob
  //     },
  //     { headers: headers })
  //     .pipe(
  //       tap(res => console.log('response: ', res))
  //     );
  // }

  // public updateTripTransaction (cJob: CronJob) {
  //   // Prepare the post data
  //   const headers = this.addBearerToken();

  //   // Server validation before creating the entry.
  //   return this.http.post<{ cJob: CronJob }>(this.getApiUrl('/rent-options/updateTripTransaction'),
  //     {
  //       cJob: cJob
  //     },
  //     { headers: headers })
  //     .pipe(
  //       tap(res => console.log('response: ', res))
  //     );
  // }
  // #endregion
}
