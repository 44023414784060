
import { State, Selector, Action, StateContext } from '@ngxs/store';
import { StateBase } from '../state-base';
import * as entity from '../entity.state';
import { noop, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { IInspectionParam, inspectionSerachClientFilter, getInspectionOptOrChildren } from '@trent/models/inspection/inspection-param';
import { Paging, getObjKey } from '@trent/models/observable-util/paging';
import { IDataLoadStatus, buildDataRequest, LoadStatus, getRootLevelChildren, updatePaging } from '@trent/models/observable-util/data-status';
import { Inspection } from '@trent/models/inspection/inspection';
import { PagingContainer } from '@trent/models/observable-util/paging-container';
import { InspectionService } from '@trent/services/inspection.service';
import { Injectable } from '@angular/core';
import { logger } from '@trent/models/log/logger';

// #region State Model

export interface InspectionStateModel {
  options?: IInspectionParam;
  paging: Paging;
  allInspectionsLoaded: boolean;
  inspectionByCompIdLoaded: boolean;
  allInspectionLoadStatus: {
    [key: string]: IDataLoadStatus<IInspectionParam>;
  };
  inspections: { [id: string]: Inspection };
  inspectionHistory: { [id: string]: Inspection };
}

function initInspectionState() {
  return {
    options: null,
    paging: { size: 10, offset: 0, full: false },
    allInspectionsLoaded: false,
    inspectionByCompIdLoaded: false,
    allInspectionLoadStatus: {},
    inspections: {},
    inspectionHistory: {}
  };
}
// #endregion

// #region actions
export class AllInspectionsRequested {
  static readonly type = '[Inspection] All Inspections Requested';
  constructor(public payload: { pData: Paging, param: IInspectionParam }) { }
}
export class AllInspectionsLoaded {
  static readonly type = '[Inspection] All Inspections Loaded';
  constructor(public payload: {
    data: { [id: string]: Inspection }, // Data
    key: string,
  }) { }
}
export class AllInspectionsNextPageRequested {
  static readonly type = '[Inspection] All Inspections Requested - Next Page';
  constructor(public payload: { option: IInspectionParam }) { }
}

export class InspectionByIdRequested {
  static readonly type = '[Inspection] Get Inspection by id - requested ( initial page)';
  constructor(public payload: { id: string }) { }
}

export class InspectionByIdLoaded {
  static readonly type = '[Inspection] Get Inspection by id - loaded';
  constructor(public payload: { id: string, data: Inspection }) { }
}
export class AllInspectionHistoryLoaded {
  static readonly type = '[Inspection] All Inspection history loaded';
  constructor(public payload: {
    data: { [id: string]: Inspection },
    key: string,
  }) { }
}
export class InspectionRemoveById {
  static readonly type = '[Inspection] Remove by Id';
  constructor(public payload: { id: string | number }) { }
}

export class InspectionStateReset {
  static readonly type = '[Inspection] Reset State';
}

// #endregion


@State<InspectionStateModel>({
  name: 'inspection',
  defaults: initInspectionState()
})
@Injectable()
export class InspectionState extends StateBase {

  allInspectionReqSub: Subscription;
  // allInspectionPagingContainer:
  /** Container that keep all of the subscription related to gettting allInspections. */
  allInspectionSubData: PagingContainer<Inspection, IInspectionParam> = new PagingContainer();

  constructor(private ps: InspectionService) {
    super();
  }


  // #region Static Selectors
  @Selector()
  static selectAllInspections(state: InspectionStateModel) {
    return (o: IInspectionParam): Inspection[] => {

      if (state.inspections == null || Object.keys(state.inspections).length === 0) {
        return [];
      }

      // remove keys that have revision/draft ids, i.e that contain '~' in the id.
      const keys = Object.keys(state.inspections).filter(k =>
        k.indexOf('/') === -1 && state.inspections[k] != null);

      // object without the rev/draft.
      const filtered = keys.reduce((obj, k) => {
        obj[k] = state.inspections[k];
        return obj;
      }, {});

      let output = Object.values(filtered).map(x => Inspection.parse(x));
      output = inspectionSerachClientFilter(output, o);

      return output;
      // return entity.map(state.products, parseProduct);
    };
  }
  /** Is the Paging Full for this search. */
  @Selector()
  static selectAllInspectionsFull(state: InspectionStateModel) {
    return (o: IInspectionParam): boolean => {
      const oKey = getObjKey(o);
      const r = state.allInspectionLoadStatus && state.allInspectionLoadStatus[oKey];
      return (!!r) ? r.pData.full : true;
    };
  }
  @Selector()
  static selectInspectionById(state: InspectionStateModel) {
    // new approach, use dictionary of company.
    return entity.getByIdFn_new(state.inspections, Inspection.parse);
  }
  @Selector()
  static selectAllInspectionHistory(state: InspectionStateModel) {
    return (o: IInspectionParam): Inspection[] => {

      if (state.inspectionHistory == null || Object.keys(state.inspectionHistory).length === 0) {
        return [];
      }
      // remove keys that have revision/draft ids, i.e that contain '~' in the id.
      const keys = Object.keys(state.inspectionHistory).filter(k =>
        k.includes('/') && state.inspectionHistory[k] != null);
      // object without the rev/draft.
      const filtered = keys.reduce((obj, k) => {
        obj[k] = state.inspectionHistory[k];
        return obj;
      }, {});

      let output = Object.values(filtered).map(x => Inspection.parse(x));
      output = inspectionSerachClientFilter(output, o);

      return output;
    };
  }
  // #endregion
  // #region Custom Functions and subscriptions

  public clearSubscriptions() {
    if (!!this.allInspectionReqSub) {
      this.allInspectionReqSub.unsubscribe();
      this.allInspectionReqSub = null;
    }
    this.allInspectionSubData.unsubscribeAll();
    super.clearSubscriptions();
  }

  @Action(InspectionStateReset)
  InspectionStateReset(context: StateContext<InspectionStateModel>, action: InspectionStateReset) {
    // unsubscribe the data
    console.log('RentalProductStateReset action called');
    this.clearSubscriptions();
    context.setState(initInspectionState());
  }

  // #endregion

  // #region actions
  @Action(AllInspectionsRequested)
  allInspectionsRequested(context: StateContext<InspectionStateModel>, action: AllInspectionsRequested) {

    const oKey = getObjKey(action.payload.param);

    // Define function that return the data status object from the state.
    const getDataStatusStateFn = () => context.getState().allInspectionLoadStatus;

    /** custom build the OR children query. */
    const buildOrQueryChildrenFn = (o: IInspectionParam) => getInspectionOptOrChildren(o);

    // if data requested now, is already partially loaded by another query's child previously
    // but the number of the items are not enough (ex. as a child, it loaded only 5, but current
    // request ask for more, then next page of that query should be called instead.)
    const nextPageFn = (option: IInspectionParam) => {
      context.dispatch(new AllInspectionsNextPageRequested({ option }));
    };

    buildDataRequest(
      oKey, action.payload.param, action.payload.pData,
      getDataStatusStateFn,
      buildOrQueryChildrenFn,
      nextPageFn,
      (
        obj: { [key: string]: IDataLoadStatus<IInspectionParam> },
        set: { key: string, node: IDataLoadStatus<IInspectionParam> }[]
      ) => {

        if (!!obj) {
          // Patch the state.
          const state = context.getState();
          context.patchState({
            allInspectionLoadStatus: { ...state.allInspectionLoadStatus, ...obj }
          });
        }

        // Process the query.
        set.forEach((val) => {
          // some of the nodes are already loaded. Only process that are loading... status.
          if (val.node.loadStatus !== LoadStatus.Loading) {
            return;
          }
          // if this request is just overwriting a stall or pending request, unsubscribe that observable
          this.allInspectionSubData.unsubscribe(val.key);

          // create the paging observable and call db.
          const p = this.ps.getAllInspections1_PagingObservable();
          const prod$ = p.getData(action.payload.pData, val.node.param)
            .pipe(
              map(Inspections => {
                if (!val.node.param.pdIdForHistory) {
                  context.dispatch(new AllInspectionsLoaded({
                    data: Inspections,
                    key: val.key
                  }));
                } else {
                  context.dispatch(new AllInspectionHistoryLoaded({
                    data: Inspections,
                    key: val.key
                  }));
                }
                return Inspections;
              }));
          const sub = this.subscribe(prod$, () => noop(), AllInspectionsRequested.type);
          // save the observable call
          this.allInspectionSubData.addData(val.key, sub, p);
        });
      }
    );
  }
  @Action(AllInspectionsNextPageRequested)
  allInspectionsNextPageRequested(context: StateContext<InspectionStateModel>, action: AllInspectionsNextPageRequested) {
    const oKey = getObjKey(action.payload.option);
    const state = context.getState();
    // find the node. can be parent or child
    const statusObj = state.allInspectionLoadStatus[oKey];
    // if no parent, treat is
    if (statusObj.children == null) {
      this.allInspectionSubData.dispatchNextPagingUpdate(oKey);
    } else {
      const children = getRootLevelChildren(oKey, state.allInspectionLoadStatus);
      children.forEach(c => {
        this.allInspectionSubData.dispatchNextPagingUpdate(c.key);
      });
    }
  }
  @Action(AllInspectionsLoaded)
  allInspectionsloaded(context: StateContext<InspectionStateModel>, action: AllInspectionsLoaded) {
    const state = context.getState();
    const subData = this.allInspectionSubData.getData(action.payload.key);
    const updatedLoadStatus = updatePaging(action.payload.key, state.allInspectionLoadStatus, subData);
    context.patchState({
      allInspectionsLoaded: true,
      allInspectionLoadStatus: updatedLoadStatus,
      inspections: { ...state.inspections, ...action.payload.data }// entity.addMany(state.products, action.payload.products)
    });
  }
  
  @Action(InspectionByIdRequested)
  inspectionByIdRequested(context: StateContext<InspectionStateModel>, action: InspectionByIdRequested) {
    const state = context.getState();
    const id = action.payload.id;
    if (state.inspections[action.payload.id] === undefined) {
      // console.log('comp by id, not found in the store, gettting from server....');
      const s = this.ps.getInspectionById(action.payload.id)
        .pipe(
          map(data => {
            return context.dispatch(new InspectionByIdLoaded({ id, data }));
          }
          )); // .subscribe(noop);
      this.subscribe(s, (x) => noop(), InspectionByIdRequested.type);
    }
  }
  @Action(InspectionByIdLoaded)
  inspectionByIdLoaded(context: StateContext<InspectionStateModel>, action: InspectionByIdLoaded) {
    const state = context.getState();
    context.patchState({
      inspections: {
        ...state.inspections,
        ...{ [action.payload.id]: action.payload.data }
      }
    });
  }
  @Action(AllInspectionHistoryLoaded)
  allInspectionHistoryLoaded(context: StateContext<InspectionStateModel>, action: AllInspectionHistoryLoaded) {
    const state = context.getState();
    const subData = this.allInspectionSubData.getData(action.payload.key);
    const updatedLoadStatus = updatePaging(action.payload.key, state.allInspectionLoadStatus, subData);
    context.patchState({
      allInspectionsLoaded: true,
      allInspectionLoadStatus: updatedLoadStatus,
      inspectionHistory: { ...state.inspectionHistory, ...action.payload.data } // entity.addMany(state.bids, action.payload.bids)
    });
  }
  @Action(InspectionRemoveById)
  dataByIdRemoved(context: StateContext<InspectionStateModel>, action: InspectionRemoveById) {
    setTimeout(() => {
      // this.storeDeletedKey(action.payload.id);

      const state = context.getState();
      if (Object.keys(state.inspections).indexOf(`${action.payload.id}`) > -1) {
        const currState = context.getState();
        const newData = { ...currState.inspections };
        delete newData[action.payload.id];
        context.patchState({ inspections: newData });
        const state1 = context.getState();
        logger.log('[Inspection-State], item removed by id', action.payload.id, state1);

      } else { logger.log('[Inspection-State], item to be removed id is not available in the store'); }
    }, 1000);
  }
}
